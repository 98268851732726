import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { I18nModule } from '../../../core/i18n';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { BadgeModule } from './../badge/badge.module';
import { RadioModule } from './../radio/radio.module';
import { ReactiveFormInputFieldModule } from './../reactive-forms-input/reactive-form-input-field/reactive-form-input-field.module';
import { SpinnerModule } from './../spinner/spinner.module';
import { UserDropdownComponent } from './user-dropdown.component';

@NgModule({
  declarations: [UserDropdownComponent],
  exports: [UserDropdownComponent],
  imports: [
    CommonModule,
    NzIconModule,
    NgbDropdownModule,
    NgbTooltipModule,
    I18nModule,
    RadioModule,
    InfiniteScrollModule,
    ReactiveFormInputFieldModule,
    ReactiveFormsModule,
    SpinnerModule,
    BadgeModule,
    CheckboxModule,
  ],
})
export class UserDropdownModule {}
