<ng-container *pyContainerSize="{ min: breakpoint }; ref: elementRef; else: list">
  <table [class]="tableClass">
    <ng-content select="thead"></ng-content>
    <ng-container *ngIf="!omitTbody; else rows">
      <tbody>
        <ng-container *ngTemplateOutlet="rows"></ng-container>
      </tbody>
    </ng-container>
    <ng-template #rows>
      <ng-container *ngTemplateOutlet="content; context: { $implicit: rowTemplate }"></ng-container>
    </ng-template>
    <ng-content select="tfoot"></ng-content>
  </table>
</ng-container>

<ng-template #list>
  <ng-content select="[slot=list-header]"></ng-content>
  <ul>
    <ng-container *ngTemplateOutlet="content; context: { $implicit: listItemTemplate }"></ng-container>
  </ul>
  <ng-content select="[slot=list-footer]"></ng-content>
</ng-template>

<ng-template #content let-template>
  <ng-container *ngFor="let entry of entries; trackBy: trackByFn">
    <ng-container *ngTemplateOutlet="template; context: { $implicit: entry }"></ng-container>
  </ng-container>
</ng-template>
