import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbCarouselModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { KeyboardFocusModule } from '@spartacus/storefront';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../../core/i18n';
import { LaunchDialogService } from '../../../../core/modal';
import { IconThemeReverseModule } from '../../../directives';
import { ListInsertModule } from '../../list-insert';
import { MediaModule } from '../../media';
import { ModalModule } from '../../modal';
import { orderReturnUploadedFilesGalleryModalLayoutConfig } from './order-return-uploaded-files-gallery-modal-layout.config';
import { OrderReturnUploadedFilesGalleryModalComponent } from './order-return-uploaded-files-gallery-modal.component';

@NgModule({
  imports: [
    CommonModule,
    MediaModule,
    NgbCarouselModule,
    NzIconModule,
    I18nModule,
    NgbTooltipModule,
    ListInsertModule,
    MediaModule,
    IconThemeReverseModule,
    ModalModule,
    KeyboardFocusModule,
  ],
  declarations: [OrderReturnUploadedFilesGalleryModalComponent],
  exports: [OrderReturnUploadedFilesGalleryModalComponent],
})
export class OrderReturnUploadedFilesGalleryModalModule {
  constructor(private launchDialogService: LaunchDialogService) {
    this.launchDialogService.registerModalLayoutConfig(orderReturnUploadedFilesGalleryModalLayoutConfig);
  }
}
