import { ClientAuthActions } from '@spartacus/core';

export enum ClientTokenActionTypes {
  LoadClientToken = '[Token] Load Client Token',
  LoadClientTokenSuccess = '[Token] Load Client Token Success',
  LoadClientTokenFail = '[Token] Load Client Token Fail',
}
export const LoadClientToken = ClientAuthActions.LoadClientToken;
export type LoadClientToken = ClientAuthActions.LoadClientToken;

export const LoadClientTokenFail = ClientAuthActions.LoadClientTokenFail;
export type LoadClientTokenFail = ClientAuthActions.LoadClientTokenFail;

export const LoadClientTokenSuccess = ClientAuthActions.LoadClientTokenSuccess;
export type LoadClientTokenSuccess = ClientAuthActions.LoadClientTokenSuccess;

export type ClientTokenActions = ClientAuthActions.ClientTokenAction;
