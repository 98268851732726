import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RemoveHostDirective } from './remove-host.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [RemoveHostDirective],
  exports: [RemoveHostDirective],
})
export class RemoveHostModule {}
