import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { CxCurrencyPipe } from '../../../core/i18n/pipe/currency.pipe';

@NgModule({
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        orderStatus: {
          module: () => import('./order-status.module').then((m) => m.OrderStatusModule),
          cmsComponents: ['CmsOrderHistoryComponent'],
          dependencies: ['orderApprovals'],
        },
      },
    }),
    CxCurrencyPipe,
  ],
})
export class OrderStatusFeatureModule {}
