import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Category } from '../../../../../core/model';
import { prepareUrlForLink } from '../../../../../core/util';

@Component({
  selector: 'py-menu-area-of-use-sub-categories',
  templateUrl: './menu-area-of-use-sub-categories.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuAreaOfUseSubCategoriesComponent {
  @Input() category: Category;

  constructor() {}

  getUrl(category: Category): string {
    const url = category.url.replace('{category-name}', category.name);
    return prepareUrlForLink(url);
  }
}
