import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], filter: string, key?: string): any {
    if (!items || !filter) {
      return items;
    }
    return items.filter((item) => (key ? item[key] : item)?.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
  }
}
