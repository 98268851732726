import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { DebugKey } from '../../model';
import { USER_BASE_FEATURE } from '../store/user-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: DebugFacade,
      feature: USER_BASE_FEATURE,
      async: true,
      methods: ['setDebugFeatureValue', 'isDebugFeatureEnabled', 'enabledFeaturesCount', 'resetDebugFeatureValues'],
    }),
})
export abstract class DebugFacade {
  abstract setDebugFeatureValue(key: DebugKey, value: boolean): void;
  abstract isDebugFeatureEnabled(key: DebugKey): Observable<boolean>;
  abstract enabledFeaturesCount(): Observable<number>;
  abstract resetDebugFeatureValues(): void;
}
