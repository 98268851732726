import { Injectable } from '@angular/core';
import { CxEvent, WindowRef } from '@spartacus/core';
import { TmsCollector, TmsCollectorConfig, WindowObject } from '@spartacus/tracking/tms/core';
import { of } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { AnalyticsMetadataFacade, UserConsentFacade } from '../../../core/user';
import { AnalyticsMetadata, AnalyticsTracker, TrackerLifecycle } from '../models/tracking.model';

export const gaProperty = 'googleAnalytics';

/**
 * Custom Google Analytics collector.
 *
 * Initializes once per session; any changes to analyticsConfig in the background are not intended to be respected.
 */
@Injectable({ providedIn: 'root' })
export class GaCollectorService implements TmsCollector {
  constructor(
    protected winRef: WindowRef,
    private metadataService: AnalyticsMetadataFacade,
    private userConsentService: UserConsentFacade
  ) {}

  // I'm expecting some flux, and this is horrible mutable state with HTTP requests,
  // so I'll leave in the option to have detailed debugging on tap.
  static debug = false;

  private installed: boolean;
  private enabled: boolean;

  private trackedTrackers: TrackerLifecycle[] = [];
  private queue = [];

  static areMetadataIdentical(a: AnalyticsMetadata, b: AnalyticsMetadata): boolean {
    // comparison function, i.e. return "false" on change when we want emission.
    GaCollectorService.log('GA: comparing metadatas, change detection. ', a, b);
    // only care about google analytics-related things.
    if (a.trackers?.length !== b.trackers?.length || a.userCustomerId !== b.userCustomerId || a.siteName !== b.siteName) {
      GaCollectorService.log('GA: returning false');
      return false;
    }
    // will somewhat erroneously return true if tracker order changes; that's good enough for this.
    GaCollectorService.log(
      'GA: returning ',
      a.trackers.map((t) => t.trackerId).join('|') !== b.trackers.map((t) => t.trackerId).join('|')
    );
    return a.trackers.map((t) => t.trackerId).join('|') === b.trackers.map((t) => t.trackerId).join('|');
  }

  static log(...data: any[]): void {
    if (this.debug) {
      console.log(...data);
    }
  }

  init(config: TmsCollectorConfig, windowObject: WindowObject): void {
    GaCollectorService.log('GA: ========== INIT CALLED =============');
    this.userConsentService
      .isTrackingAllowed()
      .pipe(
        distinctUntilChanged(),
        tap((consentAllowed) => GaCollectorService.log('GA: tapping consent bool. allowed ? ', consentAllowed)),
        switchMap((consentAllowed) =>
          !consentAllowed
            ? of({ trackers: [], userCustomerId: '', siteName: '', googleTagManagers: [] } as AnalyticsMetadata)
            : this.metadataService.getAnalyticsMetadata()
        ),
        tap((meta) =>
          GaCollectorService.log('GA: analyticsMetadata emitted, installing scripts and / or updating trackers! ', meta.trackers)
        ),
        distinctUntilChanged(GaCollectorService.areMetadataIdentical), // WAIT HOW IS MULTI-EMISSION POSSIBLE
        tap((meta) => GaCollectorService.log('DEBUG: got past the second change boi', meta?.trackers?.length)),
        tap((meta) => !this.installed && meta?.trackers?.length > 0 && this.installScript(windowObject)), // side effect install! sick
        filter((it) => Boolean(it))
      )
      .subscribe(
        (meta) => {
          this.enabled = meta?.trackers?.length > 0; // could just be zero-tracking, but unlikely and has same outcome.
          GaCollectorService.log('GA: in main setup subscribe method. calculating enabled: ', this.enabled);

          if (this.enabled) {
            this.initializeAndOrUpdateTrackers(meta, windowObject);
            // if we were re-enabled we need to send the current page.
            // if this is the first load, we either have queued the original or we missed it and should send a synth.
            if (this.queue.length === 0) {
              GaCollectorService.log("GA: sending synthetic page event, expecting we've been re-enabled");
              // we've most likely been re-enabled
              this.pushEvent({}, windowObject, {
                url: windowObject.location.pathname + windowObject.location.search,
                isSyntheticEvent: true,
              });
            }
            this.drainQueue(windowObject);
          } else {
            // If first run: we'll likely have a queued event from before we could load the script.
            // we're not going to track it, ever. Disabling trackers come at no cost.
            // If actively disabled: stop tracking over all.
            GaCollectorService.log('GA: no tracking allowed. Emptying event queue and disabling all trackers for now');
            this.queue = [];
            this.disableAllTrackers(windowObject);
          }
        },
        (error) => GaCollectorService.log('GA: aw, error in getAnalyticsMeta', error),
        () => GaCollectorService.log('GA: completion in getAnalyticsMeta')
      );
    config = config; // *sigh*
  }

  private installScript(windowObject: WindowObject): void {
    GaCollectorService.log('GA: installscript. will i?', !this.isInstalled(windowObject));
    if (this.isInstalled(windowObject)) {
      return;
    }

    const now: any = new Date();
    (function (win, doc, script, url, propName, scriptNode, firstScript) {
      win['GoogleAnalyticsObject'] = propName;
      (win[propName] =
        win[propName] ||
        function () {
          (win[propName].q = win[propName].q || []).push(arguments);
        }),
        (win[propName].l = 1 * now);
      (scriptNode = doc.createElement(script)), (firstScript = doc.getElementsByTagName(script)[0]);
      scriptNode.async = 1;
      scriptNode.src = url;
      firstScript.parentNode.insertBefore(scriptNode, firstScript);
    })(windowObject, this.winRef.document, 'script', 'https://www.google-analytics.com/analytics.js', gaProperty);
    this.installed = true; // because we don't always have the windowObject when we want to check installation state.
  }

  private isInstalled(windowObject: WindowObject) {
    return !!windowObject[gaProperty];
  }

  private initializeAndOrUpdateTrackers(meta: AnalyticsMetadata, windowObject: WindowObject, retry: number = 0): void {
    GaCollectorService.log('GA: installing / updating trackers.');

    if (this.enabled && !this.isInstalled(windowObject) && retry < 10) {
      // wait for script to download.
      GaCollectorService.log('GA: waiting for ga script to download..., rescheduling tracker installation');
      setTimeout(() => this.initializeAndOrUpdateTrackers(meta, windowObject, ++retry), 250);
    }

    this.updateTrackerLifecycles(meta.trackers, windowObject);
    this.initializeTrackers(meta, windowObject);
  }

  private initializeTrackers(meta: AnalyticsMetadata, windowObject: WindowObject) {
    const ga = windowObject[gaProperty];
    const customDimensions = {
      dimension1: meta.userCustomerId ? 'Logged-in user' : 'Anonymous User', // TODO perhaps check userId instead? bit of a hack, this.
      dimension2: meta.siteName,
      dimension3: meta.userCustomerId || '',
    };
    this.trackedTrackers
      .filter((_) => this.enabled)
      .filter((it) => !it.initialized)
      .forEach((trackerLifecycle) => {
        const tracker = trackerLifecycle.tracker;
        GaCollectorService.log('GA: initializing tracker ', tracker.trackerId);
        ga('create', tracker.trackerId, {
          cookieDomain: tracker.cookieDomain,
          name: tracker.trackerName,
        });
        ga(tracker.trackerName + '.set', 'anonymizeIp', true);
        ga(tracker.trackerName + '.set', 'forceSSL', true);

        if (tracker.trackPageviewsOnly == null || !tracker.trackPageviewsOnly) {
          // this.eventTrackers.push(tracker);
          // the distinction between event tracking and page tracking is more about *not* sending
          // certain info to the "dumb" page tracker.
          // see also ecom.js for additional logic related to tracker.type
          ga(tracker.trackerName + '.set', customDimensions);
          ga(tracker.trackerName + '.require', 'ec', 'ec.js'); // ecommerce plugin
        } else {
          // this.pageTrackers.push(tracker);
        }
        trackerLifecycle.initialized = true;
        trackerLifecycle.active = true;
        this.allowTracking(true, tracker, windowObject);
      });
  }

  private updateTrackerLifecycles(candidateTrackers: AnalyticsTracker[], windowObject: WindowObject): void {
    // if an existing tracker is missing in the update, we need to mark it as deactivated.
    // we can't simply consume a stream because a new tracker needs initialization,
    // and an old tracker that becomes new should not be initialized. So we do some manual work.
    GaCollectorService.log('GA: updateTrackerLifecycles. candidates: ', candidateTrackers.length);
    this.trackedTrackers.forEach((existing) => {
      const activeStatus = candidateTrackers.some((candidate) => candidate.trackerId === existing.tracker.trackerId);
      if (activeStatus && !existing.active) {
        // reactivate
        this.allowTracking(true, existing.tracker, windowObject);
        existing.active = true;
      } else if (!activeStatus && existing.active) {
        //deactivate
        this.allowTracking(false, existing.tracker, windowObject);
        existing.active = false;
      }
    });

    // if the update contains unseen trackers ( as will be the case especially on 1st load )
    // then we must add it to the list of trackedTrackers, marked uninitialized.
    candidateTrackers
      .filter((candidate) => !this.trackedTrackers.some((existing) => existing.tracker.trackerId === candidate.trackerId))
      .map(
        (newTracker) =>
          ({
            tracker: newTracker,
            active: false,
            initialized: false,
          } as TrackerLifecycle)
      )
      .forEach((newTracker) => this.trackedTrackers.push(newTracker));
  }

  pushEvent<T extends CxEvent>(config: TmsCollectorConfig, windowObject: WindowObject, event: T | any): void {
    GaCollectorService.log('GA: received event: ', event, '. enabled? ', this.enabled);
    if (this.installed && !this.enabled) {
      return; // don't enqueue events while having been actively disabled during session.
    }

    if (event != null) {
      this.queue.push(event);
    }

    this.drainQueue(windowObject);
    config = config; // *sigh*
  }

  /**
   * Returns a snapshot copy of the current tracker state.
   *
   * This exists to facilitate testing, because the class is a black hole in general.
   */
  trackers(): TrackerLifecycle[] {
    return this.trackedTrackers.map((lifecycle) => {
      const copy = { ...lifecycle };
      copy.tracker = { ...lifecycle.tracker };
      return copy;
    });
  }

  private drainQueue(windowObject: WindowObject, retry: number = 0): void {
    GaCollectorService.log('attempting to drain queue, size ', this.queue.length);
    const ga = windowObject[gaProperty];

    if (!ga && this.queue.length > 0 && retry < 10) {
      // 'not ga' means we have never sucessfully loaded.
      GaCollectorService.log('waiting for script; rescheduling.');
      setTimeout(() => this.drainQueue(windowObject, ++retry), 250);
      return;
    }

    while (this.queue.length > 0) {
      const evt = this.queue.shift();
      if (evt.url) {
        GaCollectorService.log('GA: got page event. it is: ', evt);
        this.getActiveTrackers().forEach((tracker) => {
          GaCollectorService.log('GA: tracking event using tracker ', tracker.trackerId);
          ga(tracker.trackerName + '.send', {
            hitType: 'pageview',
            location: windowObject.location,
            path: evt.url,
          });
        });
      }
    }
  }

  private getActiveTrackers(...types: string[]) {
    return this.trackedTrackers
      .filter((it) => it.active)
      .map((it) => it.tracker)
      .filter((it) => types.length === 0 || (it.type && types.some((acceptedType) => acceptedType === it.type)));
  }

  private allowTracking(enabled: boolean, tracker: AnalyticsTracker, windowObject: WindowObject) {
    windowObject['ga-disable-' + tracker.trackerId] = !enabled;
    GaCollectorService.log(`I ${enabled ? 'enabled' : 'disabled'} ${tracker.trackerId} (${tracker.type})`);
  }

  private disableAllTrackers(windowObject: WindowObject) {
    GaCollectorService.log('GA: taking action to disable all trackers, if any: ', this.trackedTrackers.length);
    this.trackedTrackers.forEach((trackerCycle) => {
      this.allowTracking(false, trackerCycle.tracker, windowObject);
      trackerCycle.active = false;
    });
  }
}
