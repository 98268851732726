//The old style-class naming is not appropriate anymore. We need more generic class names. For example "services-list" maybe makes sense as a classname for the procurator brand, but it will probably be confusing for other brands.

// The mapping is also documented here:
// https://iaa42.papyrus.com/confluence/display/ES5/New+style-classes+mapping

export const legacyStyleClassesMap = new Map()
  .set('text-header-1', 'h1')
  .set('text-header-2', 'h2')
  .set('text-header-3', 'h3')
  .set('text-header-4', 'h4')
  .set('text-header-5', 'h5')
  .set('text-header-6', 'h6')
  .set('cta-link', 'btn-link');

export function transformLegacyCmsStyleClasses(styleClasses) {
  let targetStyleClasses = styleClasses.split(' ');

  targetStyleClasses = targetStyleClasses.map(
    (targetStyleClass) => legacyStyleClassesMap.get(targetStyleClass) || targetStyleClass
  );

  return targetStyleClasses.join(' ');
}
