import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../core/i18n';
import { GenericLinkModule } from '../generic-link';
import { FileUploadComponent } from './file-upload.component';
import { FileUploadDirective } from './file-upload.directive';

@NgModule({
  imports: [CommonModule, I18nModule, NzIconModule, GenericLinkModule],
  declarations: [FileUploadComponent, FileUploadDirective],
  exports: [FileUploadComponent],
})
export class FileUploadModule {}
