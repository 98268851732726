import { InjectionToken } from '@angular/core';
import { IconDefinition } from '@ant-design/icons-angular';
import {
  ArrowDownOutline,
  ArrowLeftOutline,
  ArrowRightOutline,
  ArrowUpOutline,
  BarcodeOutline,
  CalendarOutline,
  CaretDownOutline,
  CaretRightOutline,
  CheckCircleFill,
  CheckCircleOutline,
  CheckOutline,
  CheckSquareOutline,
  CloseCircleFill,
  CloseCircleOutline,
  CloseOutline,
  CloseSquareOutline,
  CloudUploadOutline,
  CommentOutline,
  CopyOutline,
  DeleteOutline,
  DownCircleOutline,
  DownOutline,
  DownloadOutline,
  EditOutline,
  EllipsisOutline,
  EnvironmentOutline,
  ExclamationCircleOutline,
  ExportOutline,
  EyeFill,
  EyeInvisibleFill,
  FileDoneOutline,
  FileExcelOutline,
  FileOutline,
  FilePdfFill,
  FilePdfOutline,
  FileProtectOutline,
  FileSyncOutline,
  FileZipOutline,
  FilterOutline,
  GlobalOutline,
  HeartOutline,
  HomeOutline,
  IdcardOutline,
  InfoCircleFill,
  InfoCircleOutline,
  InstagramOutline,
  LeftCircleOutline,
  LikeOutline,
  LinkedinOutline,
  LoadingOutline,
  LoginOutline,
  MailOutline,
  MenuOutline,
  MinusCircleOutline,
  MinusOutline,
  PaperClipOutline,
  PauseOutline,
  PercentageOutline,
  PhoneOutline,
  PictureOutline,
  PlayCircleOutline,
  PlusCircleFill,
  PlusCircleOutline,
  PlusOutline,
  PrinterOutline,
  ProfileOutline,
  QuestionCircleOutline,
  ReadOutline,
  RightCircleOutline,
  RightOutline,
  RollbackOutline,
  SafetyOutline,
  SaveOutline,
  ScissorOutline,
  SearchOutline,
  SendOutline,
  SettingOutline,
  ShareAltOutline,
  ShoppingCartOutline,
  ShoppingOutline,
  SolutionOutline,
  StopOutline,
  SwapOutline,
  SwitcherOutline,
  SyncOutline,
  TagOutline,
  TagsOutline,
  TranslationOutline,
  TrophyFill,
  UndoOutline,
  UpOutline,
  UploadOutline,
  UserAddOutline,
  UserDeleteOutline,
  UserOutline,
  WalletOutline,
  WarningFill,
  WarningOutline,
} from '@ant-design/icons-angular/icons';

export const USED_LIBRARY_ICONS = [
  ArrowDownOutline,
  ArrowLeftOutline,
  ArrowRightOutline,
  ArrowUpOutline,
  BarcodeOutline,
  CalendarOutline,
  CaretDownOutline,
  CaretRightOutline,
  CheckCircleOutline,
  CheckCircleFill,
  CheckOutline,
  CheckSquareOutline,
  CloseCircleOutline,
  CloseCircleFill,
  CloseOutline,
  CloseSquareOutline,
  CloudUploadOutline,
  CommentOutline,
  CopyOutline,
  DeleteOutline,
  DownCircleOutline,
  DownloadOutline,
  DownOutline,
  EditOutline,
  EllipsisOutline,
  EnvironmentOutline,
  ExclamationCircleOutline,
  ExportOutline,
  EyeInvisibleFill,
  EyeFill,
  FileDoneOutline,
  FileExcelOutline,
  FileOutline,
  FilePdfFill,
  FilePdfOutline,
  FileProtectOutline,
  FileSyncOutline,
  FileZipOutline,
  FilterOutline,
  GlobalOutline,
  HeartOutline,
  HomeOutline,
  IdcardOutline,
  InfoCircleOutline,
  InfoCircleFill,
  InstagramOutline,
  LeftCircleOutline,
  LinkedinOutline,
  LikeOutline,
  LoadingOutline,
  LoginOutline,
  MailOutline,
  MenuOutline,
  MinusCircleOutline,
  MinusOutline,
  PaperClipOutline,
  PauseOutline,
  PercentageOutline,
  PhoneOutline,
  PictureOutline,
  PlayCircleOutline,
  PlusCircleOutline,
  PlusCircleFill,
  PlusOutline,
  PrinterOutline,
  ProfileOutline,
  QuestionCircleOutline,
  ReadOutline,
  RightCircleOutline,
  RightOutline,
  RollbackOutline,
  SafetyOutline,
  SaveOutline,
  ScissorOutline,
  SearchOutline,
  SendOutline,
  SettingOutline,
  ShareAltOutline,
  ShoppingCartOutline,
  ShoppingOutline,
  SolutionOutline,
  StopOutline,
  SwapOutline,
  SwitcherOutline,
  SyncOutline,
  TagOutline,
  TagsOutline,
  TranslationOutline,
  TrophyFill,
  UndoOutline,
  UploadOutline,
  UpOutline,
  UserAddOutline,
  UserDeleteOutline,
  UserOutline,
  WalletOutline,
  WarningOutline,
  WarningFill,
];

export const BRAND_ICONS = new InjectionToken<IconDefinition[]>('BrandIcons');
