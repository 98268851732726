import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '../../../../../core/i18n';
import { MegamenuListItemsModule } from '../../../../../shared';
import { NoResultsModule } from '../../../../../shared/components/no-results/no-results.module';
import { MenuAreaOfUseCategoriesComponent } from './menu-area-of-use-categories.component';

@NgModule({
  imports: [CommonModule, I18nModule, NoResultsModule, MegamenuListItemsModule],
  declarations: [MenuAreaOfUseCategoriesComponent],
  exports: [MenuAreaOfUseCategoriesComponent],
})
export class MenuAreaOfUseCategoriesModule {}
