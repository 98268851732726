import { Action } from '@ngrx/store';
import { UserActions } from '../actions';
import { UserConsentsActionTypes } from '../actions/user-consents.action';

export const initialState = false;

export function reducer(state = initialState, action: Action): boolean {
  switch (action.type) {
    case UserConsentsActionTypes.SetConsentsBannerVisible: {
      const _action = <UserActions.SetConsentsBannerVisible>action;
      return _action.visible;
    }
  }

  return state;
}
