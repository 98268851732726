import { Injectable } from '@angular/core';
import { CmsComponent } from '../../../../model/cms.model';
import { Converter } from '../../../../util/converter.service';
import { transformLegacyCmsStyleClasses } from './transform-legacy-cms-style-classes';

@Injectable({ providedIn: 'root' })
export class OccCmsComponentNormalizer implements Converter<CmsComponent, CmsComponent> {
  convert(source: CmsComponent): CmsComponent {
    if (!source?.styleClasses?.split) {
      return source;
    }

    const styleClasses = transformLegacyCmsStyleClasses(source.styleClasses);

    return { ...source, styleClasses };
  }
}
