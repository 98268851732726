import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ComponentFactoryResolver, NgModule } from '@angular/core';
import { ConfigModule } from '@spartacus/core';
import { OutletPosition, OutletService, defaultSkipLinkConfig } from '@spartacus/storefront';
import { provideDefaultConfig } from '../../../core/config';
import { I18nModule } from '../../../core/i18n';
import { KeyboardFocusModule } from '../keyboard-focus/keyboard-focus.module';
import { SkipLinkComponent } from './component/skip-link.component';
import { SkipLinkDirective } from './directive/skip-link.directive';

@NgModule({
  imports: [CommonModule, I18nModule, ConfigModule.withConfig(defaultSkipLinkConfig), KeyboardFocusModule],
  declarations: [SkipLinkComponent, SkipLinkDirective],
  exports: [SkipLinkDirective],

  providers: [
    provideDefaultConfig(defaultSkipLinkConfig),
    {
      provide: APP_INITIALIZER,
      useFactory: skipLinkFactory,
      deps: [ComponentFactoryResolver, OutletService],
      multi: true,
    },
  ],
})
export class SkipLinkModule {}

/**
 * Adds the skip link component before the cx-storefront.
 */
export function skipLinkFactory(
  componentFactoryResolver: ComponentFactoryResolver,
  outletService: OutletService
): () => Promise<void> {
  const isReady = async () => {
    const factory = componentFactoryResolver.resolveComponentFactory(SkipLinkComponent);
    outletService.add('cx-storefront', <any>factory, OutletPosition.BEFORE);
  };
  return isReady;
}
