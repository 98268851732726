import { CxEvent } from '@spartacus/core';
import { PageEvent } from '@spartacus/storefront';
import { Product, ProductRelation } from '../../core/model';

export class GenerateLeadEvent extends CxEvent {
  constructor(public formData: any) {
    super();
  }
}

export class EnterPDPEvent extends CxEvent {
  constructor(public product: Product) {
    super();
  }
}

export class ViewRelatedItemsEvent extends CxEvent {
  constructor(public relatedItems: ProductRelation[]) {
    super();
  }
}

export class PaymentSuccessfulEvent extends PageEvent {
  static readonly type = 'PaymentSuccessfulEvent';
  constructor(public sapOrderNumber: string) {
    super();
  }
}
