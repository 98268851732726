import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        paymentProcessing: {
          module: () => import('./payment-processing.module').then((m) => m.PaymentProcessingModule),
          cmsComponents: ['CmsPaymentProcessingFlexComponent'],
        },
      },
    }),
  ],
})
export class PaymentProcessingFeatureModule {}
