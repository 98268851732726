<div class="drop-area py-15" pyFileDnd (fileDropped)="onFileDropped($event)" [ngClass]="{ disabled: onDisabled() }">
  <ng-container *ngIf="!displayCustomContent; else customContent">
    <input
      #fileDropRef
      enctype="multipart/form-data"
      type="file"
      id="fileDropRef"
      [attr.accept]="accept"
      [attr.multiple]="multiple ? 'multiple' : null"
      [attr.disabled]="onDisabled() ? true : null"
      (change)="fileBrowseHandler($event.target.files)"
      class="position-absolute w-100 h-100"
    />
    <ng-container [ngSwitch]="headerType">
      <ng-container *ngSwitchCase="'STANDARD'" [ngTemplateOutlet]="standard"></ng-container>
      <ng-container *ngSwitchCase="'USER_HEADER'" [ngTemplateOutlet]="userHeader"></ng-container>
    </ng-container>
  </ng-container>
  <ng-template #customContent>
    <ng-content></ng-content>
  </ng-template>
</div>
<ng-container *ngIf="files?.length > 0">
  <div class="files-list pt-4">
    <h4>{{ 'common.files_heading' | cxTranslate }}</h4>
    <div class="py-2 d-flex info" *ngFor="let file of files; let i = index">
      <i nz-icon class="file" nzType="file" nzTheme="outline"></i>
      <div class="d-flex d-flex-row pl-2 pt-1 w-100">
        <h6 class="files-name">
          {{ file?.name }}
        </h6>
        <button class="delete button pl-3" (click)="deleteFile(i)">
          <i nz-icon nzType="close-circle" nzTheme="fill"></i>
        </button>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #standard>
  <section class="default-header">
    <i nz-icon class="link" nzType="upload" nzTheme="outline"></i>
    <h6 class="py-3 standard-header">
      {{ 'common.dragAndDropFile_action' | cxTranslate }}
    </h6>
    <button for="fileDropRef" class="link browse-link">{{ 'common.browseForFile_action' | cxTranslate }}</button>
    <div class="d-flex justify-content-center" *ngIf="showDownloadTemplateButton">
      <button class="btn btn-link link download-link" (click)="onDownloadTemplate()">
        <i nz-icon nzType="download"></i>
        {{ 'common.downloadFileTemplate_action' | cxTranslate }}
      </button>
    </div>
  </section>
</ng-template>

<ng-template #userHeader>
  <section class="user-header">
    <i nz-icon class="link users-import-icon" nzType="cloud-upload" nzTheme="outline"></i>
    <h5 class="py-2 user-header">
      {{ 'users.batchUpload_title' | cxTranslate }}
    </h5>
    <p class="py-3 px-8">
      <span class="d-block d-sm-none">
        {{ 'common.browseForFile_hint' | cxTranslate }}
      </span>
      <span class="d-none d-sm-block">
        {{ 'common.dropOrBrowseForFile_hint' | cxTranslate }}
      </span>
    </p>
    <button class="btn btn-link link download-link" (click)="onDownloadTemplate()">
      <i nz-icon nzType="download"></i>
      {{ 'users.templateDownload_hint' | cxTranslate }}
    </button>
  </section>
</ng-template>
