import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { PageComponentModule } from '../../../../../cms-structure';
import { I18nModule } from '../../../../../core/i18n';
import { GenericLinkModule, MegamenuModule } from '../../../../../shared';
import { HeaderNavigationItemModule } from '../../header-navigation-item';
import { MenuAreaOfUseCategoriesModule } from '../menu-area-of-use-categories';
import { MenuAreaOfUseSubCategoriesModule } from '../menu-area-of-use-sub-categories';
import { MenuAreaOfUseDropdownComponent } from './menu-area-of-use-dropdown.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    PageComponentModule.forRoot(),
    HeaderNavigationItemModule,
    NgbDropdownModule,
    GenericLinkModule,
    NzIconModule,
    MenuAreaOfUseCategoriesModule,
    MenuAreaOfUseSubCategoriesModule,
    MegamenuModule,
  ],
  declarations: [MenuAreaOfUseDropdownComponent],
  exports: [MenuAreaOfUseDropdownComponent],
})
export class MenuAreaOfUseDropdownModule {}
