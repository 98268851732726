import { NgModule } from '@angular/core';
import { CmsConfig } from '@spartacus/core';
import { provideConfig } from '../../core/config/config-providers';
import { CART_CORE_FEATURE } from './base/store/cart-state';

@NgModule({
  imports: [],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        cartPage: {
          module: () => import('./containers/cart-page/cart-page.module').then((m) => m.CartPageModule),
          dependencies: [
            () =>
              import('../../features/purchase-limits-base/purchase-limits-base.module').then((m) => m.PurchaseLimitsBaseModule),
          ],
          cmsComponents: ['CmsCartFlexComponent'],
        },
        [CART_CORE_FEATURE]: {
          module: () => import('./base/cart-base.module').then((m) => m.CartBaseModule),
        },
      },
    }),
  ],
})
export class CartFeatureModule {}
