import { Injectable } from '@angular/core';
import { SearchScoreFactor, SolrResultEntityRef } from '../../model';
import { Occ } from '../../occ';
import { Converter } from '../../util/converter.service';

@Injectable({ providedIn: 'root' })
export class SolrSearchResultRefNormalizer implements Converter<any, SolrResultEntityRef> {
  convert(source: Occ.SolrResultEntityRef, target?: SolrResultEntityRef): SolrResultEntityRef {
    if (source) {
      target = {
        ...source,
        explanation: {
          ...source.explanation,
          factors: this.convertFactors(source.explanation?.factors),
          lineBoosts: this.convertLineBoosts(source.explanation?.factors),
        },
      };
    }
    return target;
  }

  convertFactors(source: Occ.SearchScoreFactor[]): SearchScoreFactor[] {
    return source?.filter((factor) => !factor.lineBoost);
  }

  convertLineBoosts(source: Occ.SearchScoreFactor[]): SearchScoreFactor[] {
    return source
      ?.filter((factor) => !!factor.lineBoost)
      .map((factor) => {
        return {
          ...factor,
          field: `${factor.field}:${factor.word}`,
        };
      });
  }
}
