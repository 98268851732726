import { StateUtils } from '@spartacus/core';
import { PagedSearchAction } from '../../../features/store/actions/search.actions';
import { ShipTosSearchParams } from '../../../model';
import { PyStateUtils } from '../../../state/utils';
import { SHIP_TOS } from '../user-state';

export const LOAD_USER_SHIPTOS = '[User] Load User Ship Tos';
export const LOAD_USER_SHIPTOS_FAIL = '[User] Load User Ship Tos Fail';
export const LOAD_USER_SHIPTOS_SUCCESS = '[User] Load User Ship Tos Success';

export class LoadUserShipTos extends StateUtils.LoaderLoadAction implements PagedSearchAction {
  readonly type = LOAD_USER_SHIPTOS;
  readonly searchType = SHIP_TOS;

  constructor(public searchParams: ShipTosSearchParams, public userId: string) {
    super(SHIP_TOS);
  }
}

export class LoadUserShipTosFail extends PyStateUtils.LoaderFailAction implements PagedSearchAction {
  readonly type = LOAD_USER_SHIPTOS_FAIL;
  readonly searchType = SHIP_TOS;

  constructor(public searchParams: ShipTosSearchParams, public payload?: any) {
    super(SHIP_TOS, payload);
  }
}

export class LoadUserShipTosSuccess extends StateUtils.LoaderSuccessAction implements PagedSearchAction {
  readonly type = LOAD_USER_SHIPTOS_SUCCESS;
  readonly searchType = SHIP_TOS;

  constructor(public payload: any, public searchParams: ShipTosSearchParams) {
    super(SHIP_TOS);
  }
}

export type ShipTosAction = LoadUserShipTos | LoadUserShipTosFail | LoadUserShipTosSuccess;
