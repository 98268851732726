<ng-container *ngIf="{ label: label$ | async, placeholder: placeholder$ | async } as data">
  <div tabindex="0" class="wrapper prevent-blur-submit" *ngIf="showButton; else displayTemplate">
    <ng-container *ngIf="value || isShowingInput" [ngTemplateOutlet]="headingTemplate"></ng-container>
    <ng-container *ngIf="isShowingInput" [ngTemplateOutlet]="formTemplate"></ng-container>
    <ng-container *ngIf="!isShowingInput" [ngTemplateOutlet]="addOrEditButtonTemplate"></ng-container>
    <div *ngIf="invalid" [id]="'errors-' + key" class="invalid-feedback">{{ errors | inputError }}</div>
  </div>

  <ng-template #headingTemplate>
    <div class="label" [class.disabled]="disabled">
      {{ data.label }}
    </div>
  </ng-template>

  <ng-template #formTemplate>
    <form [formGroup]="formGroup" class="form-add px-2" [class.invalid]="invalid" (ngSubmit)="onSubmit()">
      <i nz-icon [nzType]="icon" nzTheme="fill" class="mr-2"></i>

      <input
        class="w-100 h-100"
        [placeholder]="data.placeholder"
        [formControl]="control"
        cxAutoFocus
        (blur)="onBlurSubmit($event)"
      />

      <div class="ml-2 d-flex">
        <button type="button" class="icon-holder delete prevent-blur-submit mr-2" (click)="clear()" [disabled]="!value">
          <i nz-icon nzType="delete" nzTheme="outline"></i>
        </button>
        <button type="submit" class="icon-holder save prevent-blur-submit" [disabled]="invalid">
          <i nz-icon nzType="check" nzTheme="outline"></i>
        </button>
      </div>
    </form>
  </ng-template>

  <ng-template #addOrEditButtonTemplate>
    <button
      class="add-or-edit-button p-2"
      [ngClass]="!!value ? 'edit' : 'add'"
      [disabled]="disabled"
      [class.invalid]="invalid"
      (click)="isShowingInput = true"
    >
      <span class="d-flex py-1 w-100">
        <i nz-icon [nzType]="icon" nzTheme="fill" class="mr-2"></i>
        <span class="text-left">{{ !!value ? value : data.label }}</span>
      </span>

      <div class="icon-holder edit ml-2" *ngIf="!disabled && !!value" [ngbTooltip]="'common.edit_tooltip' | cxTranslate">
        <i nz-icon nzType="edit" nzTheme="outline"></i>
      </div>
    </button>
  </ng-template>

  <ng-template #displayTemplate>
    <div class="text-wrapper d-flex align-items-start">
      <i nz-icon [nzType]="icon" nzTheme="fill" class="mx-2"></i>
      <div class="d-flex flex-column w-100">
        <span class="text-label">{{ data.label }}</span>
        <span class="text-break">{{ initValue }}</span>
      </div>
    </div>
  </ng-template>
</ng-container>
