import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import {
  B2BUser,
  B2BUserSearchOptimizationOptions,
  B2BUserSearchParams,
  CustomerStatus,
  CustomerStatusList,
  OrgCustomerCreation,
  OrgCustomerModification,
  OrgCustomerShipToAssignment,
  OrgUnitUserPage,
  UpdateStatusValue,
  UserRoleList,
} from '../../../../core/model';
import { ORGANIZATION_CORE_FEATURE } from '../../store/organization-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: B2BUserAdapter,
      feature: ORGANIZATION_CORE_FEATURE,
      async: true,
      methods: [
        'load',
        'getB2BUsers',
        'create',
        'update',
        'updateRole',
        'updateSoldTos',
        'updateShipToAssignment',
        'loadB2BUserStatuses',
        'loadUserRoles',
        'updateStatus',
        'deleteB2BUsers',
      ],
    }),
})
export abstract class B2BUserAdapter {
  /**
   * Abstract method used to load orgUnitCustomerManagement's details data.
   * orgUnitCustomer's data can be loaded from alternative sources, as long as the structure
   * converts to the `B2BUser`.
   *
   * @param userId The `userId` for given orgUnitCustomerManagement
   * @param orgCustomerId The `orgUnitCustomerId` for given orgUnitCustomerManagement
   */
  abstract load(userId: string, orgCustomerId: string): Observable<B2BUser>;

  abstract getB2BUsers(
    userId: string,
    params?: B2BUserSearchParams,
    optimizationOptions?: B2BUserSearchOptimizationOptions
  ): Observable<OrgUnitUserPage>;

  abstract create(userId: string, orgCustomerCreation: OrgCustomerCreation): Observable<B2BUser>;

  abstract update(userId: string, orgCustomerId: string, orgCustomerModification: OrgCustomerModification): Observable<B2BUser>;

  abstract updateRole(userId: string, orgCustomerId: string, roleId: string): Observable<B2BUser>;

  abstract updateSoldTos(userId: string, orgCustomerId: string, soldToIds: string[]): Observable<B2BUser>;

  abstract updateShipToAssignment(
    userId: string,
    orgCustomerId: string,
    orgCustomerShipToAssignment: OrgCustomerShipToAssignment
  ): Observable<B2BUser>;

  abstract loadB2BUserStatuses(): Observable<CustomerStatusList>;

  abstract loadUserRoles(userId: string, configurations?: string, configurationsConjunction?: boolean): Observable<UserRoleList>;

  abstract updateStatus(userId: string, orgCustomerIds: string[], status: UpdateStatusValue): Observable<CustomerStatus>;

  abstract deleteB2BUsers(userId: string, orgCustomerIds: string[]): Observable<any>;
}
