import { Injectable, OnDestroy } from '@angular/core';
import { ConfigInitializerService, SiteContextConfig } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { getContextParameterDefault } from '../config/context-config-utils';
import { SitePrefixService } from '../facade';
import { SITE_PREFIX_CONTEXT_ID } from '../providers/context-ids';

@Injectable({ providedIn: 'root' })
export class SitePrefixInitializer implements OnDestroy {
  constructor(protected sitePrefixService: SitePrefixService, protected configInit: ConfigInitializerService) {}

  protected subscription: Subscription;

  /**
   * Initializes the value of the site prefix
   */
  initialize(): void {
    this.subscription = this.configInit
      .getStable('context')
      .pipe(
        // TODO(#12351): <--- plug here explicitly SiteContextRoutesHandler
        switchMap(() => this.setFallbackValue())
      )
      .subscribe();
  }

  /**
   * On subscription to the returned observable:
   *
   * Sets the default value taken from config, unless the active base site has been already initialized.
   */
  protected setFallbackValue(): Observable<unknown> {
    return this.configInit.getStable('context').pipe(tap((config: SiteContextConfig) => this.setDefaultFromConfig(config)));
  }

  /**
   * Sets the active site prefix value based on the default value from the config,
   * unless the active site prefix has been already initialized.
   */
  protected setDefaultFromConfig(config: SiteContextConfig): void {
    if (!this.sitePrefixService.isInitialized()) {
      this.sitePrefixService.setActive(getContextParameterDefault(config, SITE_PREFIX_CONTEXT_ID));
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
