<ng-container *ngIf="!viewMode">
  <ng-container *ngIf="!shouldDisplayEditMode" [ngTemplateOutlet]="addButtonTemplate"></ng-container>
  <ng-container *ngIf="shouldDisplayEditMode" [ngTemplateOutlet]="editButtonTemplate"></ng-container>
</ng-container>

<ng-template #addButtonTemplate>
  <button (click)="openModal()" class="add-or-edit-button add px-2" [disabled]="disabled">
    <i nz-icon nzType="tag" nzTheme="fill" class="mr-2"></i>
    <span>{{ 'cart.palletFlag.addPalletFlag_action' | cxTranslate }}</span>
  </button>
</ng-template>

<ng-template #editButtonTemplate>
  <div class="wrapper">
    <div class="label" [class.disabled]="disabled">
      {{ 'cart.palletFlag.palletFlag_heading' | cxTranslate }}
    </div>

    <button class="add-or-edit-button edit p-2" (click)="openModal()" [disabled]="disabled">
      <span class="d-flex py-1 w-100">
        <i nz-icon nzType="tag" nzTheme="fill" class="mr-2"></i>
        <span class="text-left">
          <span>
            {{ 'cart.palletFlag.customer_hint' | cxTranslate : { customer: palletFlags.customer }
            }}<ng-container *ngIf="palletFlags.customer">,</ng-container>
          </span>
          <br />
          <span>
            {{ 'cart.palletFlag.machine_hint' | cxTranslate : { machine: palletFlags.machine }
            }}<ng-container *ngIf="palletFlags.machine">,</ng-container>
          </span>
          <br />
          <span>{{ 'cart.palletFlag.object_hint' | cxTranslate : { object: palletFlags.object } }}</span>
          <br />
        </span>
      </span>
      <div class="icon-holder edit ml-2" [ngbTooltip]="'common.edit_tooltip' | cxTranslate">
        <i nz-icon nzType="edit" nzTheme="outline"></i>
      </div>
    </button>
  </div>
</ng-template>

<ng-container *ngIf="viewMode">
  <div class="text-wrapper d-flex align-items-start" *ngIf="initValue?.customer || initValue?.machine || initValue?.object">
    <i nz-icon nzType="tag" nzTheme="fill" class="mx-2"></i>
    <div class="d-flex flex-column w-100">
      <span class="text-label mb-1">{{ 'orderHistory.palletFlag.palletFlag_heading' | cxTranslate }}</span>
      <ul>
        <li>{{ 'cart.palletFlag.customer_hint' | cxTranslate : { customer: initValue.customer } }}</li>
        <li>{{ 'cart.palletFlag.machine_hint' | cxTranslate : { machine: initValue.machine } }}</li>
        <li>{{ 'cart.palletFlag.object_hint' | cxTranslate : { object: initValue.object } }}</li>
      </ul>
    </div>
  </div>
</ng-container>
