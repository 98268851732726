import { NgModule } from '@angular/core';
import { AccountingCodesFeatureModule } from './accounting-codes/accounting-codes-feature.module';
import { AreaOfUseAdminFeatureModule } from './area-of-use-admin/area-of-use-admin-feature.module';
import { DashboardFeatureModule } from './dashboard/dashboard-feature.module';
import { DeliveryAddressesFeatureModule } from './delivery-addresses/delivery-addresses-feature.module';
import { InvoiceHistoryFeatureModule } from './invoice-history/invoice-history-feature.module';
import { MyAssortmentFeatureModule } from './my-assortment/my-assortment-feature.module';
import { OrderApprovalFeatureModule } from './order-approval/order-approval-feature.module';
import { OrderCardsFeatureModule } from './order-cards/order-cards-feature.module';
import { OrderStatusFeatureModule } from './order-status/order-status-feature.module';
import { PriceFilesFeatureModule } from './price-files/price-files-feature.module';
import { PurchaseLimitsAdminFeatureModule } from './purchase-limits/purchase-limits-admin-feature.module';
import { PurchaseLimitsUserFeatureModule } from './purchase-limits/purchase-limits-user-feature.module';
import { SettingsFeatureModule } from './settings/settings-feature.module';
import { ShoppingListsFeatureModule } from './shopping-lists/shopping-lists-feature.module';
import { StatisticsfeatureModule } from './statistics/statistics-feature.module';
import { TrackAndTraceFeatureModule } from './track-and-trace/track-and-trace-feature.module';
import { UsersFeatureModule } from './users/users-feature.module';

@NgModule({
  imports: [
    DashboardFeatureModule,
    MyAssortmentFeatureModule,
    TrackAndTraceFeatureModule,
    OrderCardsFeatureModule,
    AccountingCodesFeatureModule,
    ShoppingListsFeatureModule,
    DeliveryAddressesFeatureModule,
    SettingsFeatureModule,
    StatisticsfeatureModule,
    InvoiceHistoryFeatureModule,
    PriceFilesFeatureModule,
    UsersFeatureModule,
    PurchaseLimitsAdminFeatureModule,
    PurchaseLimitsUserFeatureModule,
    OrderApprovalFeatureModule,
    OrderStatusFeatureModule,
    AreaOfUseAdminFeatureModule,
  ],
})
export class MyPageFeatureModule {}
