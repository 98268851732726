import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { ContainerSizeModule } from '../../directives/container-size/container-size.module';
import { ResponsiveTableComponent } from './responsive-table.component';

@NgModule({
  imports: [CommonModule, NzIconModule, ContainerSizeModule],
  declarations: [ResponsiveTableComponent],
  exports: [ResponsiveTableComponent],
})
export class ResponsiveTableModule {}
