import { Injectable } from '@angular/core';
import { CxEvent, WindowRef } from '@spartacus/core';
import { TmsCollector, TmsCollectorConfig, WindowObject } from '@spartacus/tracking/tms/core';
import { filter, take } from 'rxjs/operators';
import { AnalyticsMetadataFacade } from '../../../core/user';
import { AnalyticsMetadata, UmamiConfiguration } from '../models/tracking.model';

/**
 * Custom Umami  collector.
 *
 * Initializes once per session; any changes to analyticsConfig in the background are not intended to be respected. yet.
 */
@Injectable({ providedIn: 'root' })
export class UmamiCollectorService implements TmsCollector {
  constructor(protected winRef: WindowRef, private metadataService: AnalyticsMetadataFacade) {}

  private configurations: UmamiConfiguration[];
  private queue = [];

  init(config: TmsCollectorConfig, windowObject: WindowObject): void {
    this.metadataService
      .getAnalyticsMetadata()
      .pipe(
        filter((it) => Boolean(it)),
        take(1)
      )
      .subscribe((foundMeta: AnalyticsMetadata) => {
        this.installScript(foundMeta.umamiConfigurations);
      });
    this.noOp(config);
    this.noOp(windowObject);
  }

  map<T extends CxEvent>(event: T): object | T {
    return event;
  }

  pushEvent<T extends CxEvent>(config: TmsCollectorConfig, windowObject: WindowObject, event: any | T): void {
    if (event != null && event['url']) {
      this.queue.push(event);
    }

    if (!windowObject['umami']) {
      // low-effort 80/20 solution for unloaded tracking script.
      // we're not interacting with the main app or any data,
      // so a setTimeout should be... not great, but OK, right?
      windowObject.setTimeout(() => this.pushEvent(null, windowObject, null), 500);
      return event;
    }

    this.sendQueue(windowObject);
    this.noOp(config);
    return event;
  }

  private sendQueue(windowObject: WindowObject) {
    while (this.queue.length > 0) {
      const evt = this.queue.shift();
      this.configurations.forEach((cfg) => {
        this.track(windowObject, evt['url'], evt?.context?.referrer || '', cfg.code);
      });
    }
  }

  private track(windowObject: WindowObject, url: string, referrer: string, code: string) {
    windowObject['umami'].trackView(url, referrer, code);
  }

  // typical simple NavigationEvent structure:
  // {
  //     "context": {
  //         "type": "ContentPage",
  //         "id": "/c"
  //     },
  //     "url": "svSE/c/Biobaserade-P%C3%A5sar-och-S%C3%A4ckar?c=13_04&mya=0",
  //     "params": {
  //         "category": "Biobaserade-Påsar-och-Säckar"
  //     }
  // }

  private installScript(configurations: UmamiConfiguration[]) {
    if (configurations?.length > 0) {
      const node = this.winRef.document.createElement('script');
      const domainParts = this.winRef.document.domain.split('.');
      const domain = domainParts[domainParts.length - 2]; // I don't expect co. domains...
      const url = domain === 'pacson' ? `international.${domain}.se` : `${domain}.com`;

      // we always go for .com or .se because the umami host should always be in .com or .se. we don't have it e.g. locally
      node.src = `https://${configurations[0].subdomain}.${url}/umami.js`;
      node.type = 'text/javascript';
      node.async = true;
      node.defer = true;
      // we handle tracking with our queue + multi-property, so auto-track is out.
      node.setAttribute('data-auto-track', 'false');
      // !!! umami won't install without *some* code value. darn it.
      node.setAttribute('data-website-id', 'dummy-code');
      this.winRef.document.getElementsByTagName('body')[0].appendChild(node);
    }

    this.configurations = configurations;
  }

  private noOp(it: any) {
    // shameful way of avoiding unused-lint. but I want to keep the original calling structure for improvements later.
    // and it's legitimately necessary for some framework stuff.
    return it;
  }
}
