import { Provider } from '@angular/core';
import arialBold from '@capsizecss/metrics/arial/700';
import arial from '@capsizecss/metrics/arial/regular';
import helveticaNeueLight from '@capsizecss/metrics/helveticaNeue/300';
import helveticaNeueBold from '@capsizecss/metrics/helveticaNeue/700';
import helveticaNeue from '@capsizecss/metrics/helveticaNeue/regular';
import nunitoSansLight from '@capsizecss/metrics/nunitoSans/200';
import nunitoSansSemiBold from '@capsizecss/metrics/nunitoSans/600';
import nunitoSansBold from '@capsizecss/metrics/nunitoSans/700';
import nunitoSans from '@capsizecss/metrics/nunitoSans/regular';
import { BRAND_FONTS, FontUtilsTypes } from '../../../storefrontlib/src/layout/main/font-utils';

export const brandFontsProvider: Provider = {
  provide: BRAND_FONTS,
  useValue: <FontUtilsTypes.BrandFonts>{
    primary: {
      fallback: [helveticaNeue, arial],
      fontFaceProperties: { fontDisplay: 'optional', fontWeight: 400 },
      src: {
        woff2: '/assets/fonts/nunitosans-regular.woff2',
        woff: '/assets/fonts/nunitosans-regular.woff',
      },
      metrics: nunitoSans,
    },
    'primary-light': {
      fallback: [helveticaNeueLight, arial],
      fontFaceProperties: { fontDisplay: 'optional', fontWeight: 200 },
      src: {
        woff2: '/assets/fonts/nunitosans-light.woff2',
        woff: '/assets/fonts/nunitosans-light.woff',
      },
      metrics: nunitoSansLight,
    },
    secondary: {
      fallback: [helveticaNeue, arial],
      fontFaceProperties: { fontDisplay: 'optional', fontWeight: 600 },
      src: {
        woff2: '/assets/fonts/nunitosans-semi-bold.woff2',
        woff: '/assets/fonts/nunitosans-semi-bold.woff',
      },
      metrics: nunitoSansSemiBold,
    },
    'secondary-bold': {
      fallback: [helveticaNeueBold, arialBold],
      fontFaceProperties: { fontDisplay: 'swap', fontWeight: 700 },
      metrics: nunitoSansBold,
      src: {
        woff2: '/assets/fonts/nunitosans-bold.woff2',
        woff: '/assets/fonts/nunitosans-bold.woff',
      },
    },
  },
};
