import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule } from '../../../core/i18n';
import { PipesModule } from '../../pipes';
import { CounterInputModule } from '../counter-input/counter-input.module';
import { DropdownModule } from '../dropdown';
import { QuantityAndUnitSelectorComponent } from './quantity-and-unit-selector.component';

@NgModule({
  imports: [CommonModule, I18nModule, DropdownModule, CounterInputModule, ReactiveFormsModule, PipesModule, NgbPopoverModule],
  declarations: [QuantityAndUnitSelectorComponent],
  exports: [QuantityAndUnitSelectorComponent],
})
export class QuantityAndUnitSelectorModule {}
