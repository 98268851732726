import { MemoizedSelector, MemoizedSelectorWithProps, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { Category, CategoryRef, LoaderError } from '../../../../model';
import { PyStateUtils } from '../../../../state';
import { loaderErrorDetailsSelector } from '../../../../state/utils/loader';
import { CatalogState, CategoryEntries, StateWithCatalog } from '../../../store/catalog-state';
import { getCatalogKey } from '../../../store/selectors/catalog-keys.selector';
import { getCatalogState } from '../../../store/selectors/feature.selector';
import { DEFAULT_CATEGORY_LEVEL } from '../../model';

export const getCategoriesState: MemoizedSelectorWithProps<
  StateWithCatalog,
  any,
  StateUtils.LoaderState<CategoryEntries>
> = createSelector(
  getCatalogState,
  getCatalogKey,
  (state: CatalogState, key: string) => (state.categories && state.categories[key]) || PyStateUtils.initialLoaderState
);

export const getCategoryEntriesState: MemoizedSelectorWithProps<StateWithCatalog, any, CategoryEntries> = createSelector(
  getCategoriesState,
  (state) => (state && StateUtils.loaderValueSelector(state)) || undefined
);

export const getCategoriesLoading: MemoizedSelectorWithProps<StateWithCatalog, any, boolean> = createSelector(
  getCategoriesState,
  (state) => (state && StateUtils.loaderLoadingSelector(state)) || false
);

export const areCategoriesEmpty: MemoizedSelectorWithProps<StateWithCatalog, any, boolean> = createSelector(
  getCategoriesState,
  (state) => (state && StateUtils.loaderSuccessSelector(state) && state.value.areCategoriesEmpty) || false
);

export const getCategoriesSuccess: MemoizedSelectorWithProps<StateWithCatalog, any, boolean> = createSelector(
  getCategoriesState,
  (state) => (state && StateUtils.loaderSuccessSelector(state)) || false
);

export const getCategoriesError: MemoizedSelector<StateWithCatalog, LoaderError | boolean> = createSelector(
  getCatalogState,
  (state) => (state && loaderErrorDetailsSelector(state.categories)) || false
);

export const categoryByRefSelector: MemoizedSelectorWithProps<StateWithCatalog, any, Category> = createSelector(
  getCategoriesSuccess,
  getCategoryEntriesState,
  (loaded, entriesState, props) => (loaded && entriesState && entriesState.entities[props.ref]) || undefined
);

export const categoriesForRefsSelector: MemoizedSelectorWithProps<StateWithCatalog, any, Category[]> = createSelector(
  getCategoriesSuccess,
  getCategoryEntriesState,
  (loaded, entriesState, props) =>
    (loaded && entriesState && props.refs.map((ref) => entriesState.entities[ref]).filter((x) => x)) || undefined
);

// Temporary quick fix. It should be handled in the backend.
export const categoriesForRefsAndLevelSelector: MemoizedSelectorWithProps<StateWithCatalog, any, Category[]> = createSelector(
  getCategoriesSuccess,
  getCategoryEntriesState,
  (loaded, entriesState, props) => {
    const categoriesByRef =
      (loaded && entriesState && props.refs.map((ref) => entriesState.entities[ref]).filter((x) => x)) || [];
    const allCategories = (loaded && entriesState && entriesState.entities && Object.values(entriesState.entities)) || [];
    const selectedCategories = [];

    categoriesByRef.forEach((category) => {
      const superCategory = getSuperCategory(allCategories, category, props.level);
      if (superCategory && !selectedCategories.some((selectedCategory) => selectedCategory.code === superCategory.code)) {
        selectedCategories.push(superCategory);
      }
    });
    return selectedCategories;
  }
);

// Temporary quick fix. It should be handled in the backend.
export function getSuperCategory(allCategories: Category[], category: Category, level: number): Category {
  if (category.level === level) {
    return category;
  }
  const superCategory = allCategories.find((ac) => ac.code === category.superCategoryRef);
  if (superCategory && superCategory?.level >= level) {
    return getSuperCategory(allCategories, superCategory, level);
  }
  return undefined;
}

export const categoryRefsByLevelSelector: MemoizedSelectorWithProps<StateWithCatalog, any, CategoryRef[]> = createSelector(
  getCategoryEntriesState,
  (state, props) => {
    return (state && state.tree && state.tree[(props && props.level) || DEFAULT_CATEGORY_LEVEL]) || undefined;
  }
);
