import { Action } from '@ngrx/store';
import { SolrSearchResult } from '../../../model/solr-search-config';
import { SolrSearchActions } from '../actions';

export const initialState: SolrSearchResult = {};

export function reducer(type): (state: SolrSearchResult, action: Action) => SolrSearchResult {
  function isSameQuery(state: SolrSearchResult, payload: SolrSearchResult) {
    return state.currentQuery?.query?.value === payload.currentQuery?.query?.value;
  }
  function isSamePageSize(state: SolrSearchResult, payload: SolrSearchResult) {
    return state.pagination?.pageSize === payload.pagination?.pageSize;
  }
  function onNewPage(state: SolrSearchResult, payload: SolrSearchResult) {
    return state.pagination?.currentPage !== payload.pagination?.currentPage;
  }

  return (state = initialState, action: Action) => {
    switch (action.type) {
      case SolrSearchActions.SolrSearchActionTypes.LoadSolrSearchSuccess:
        {
          const successAction: SolrSearchActions.LoadSolrSearchSuccess = <SolrSearchActions.LoadSolrSearchSuccess>action;
          if (successAction.searchType === type) {
            const searchResult = successAction.payload;
            if (isSameQuery(state, searchResult)) {
              if (isSamePageSize(state, searchResult)) {
                if (onNewPage(state, searchResult)) {
                  // Just add new page types
                  return {
                    ...state,
                    articleRefs: state.articleRefs ? [...state.articleRefs, ...searchResult.articleRefs] : undefined,
                    productRefs: state.productRefs ? [...state.productRefs, ...searchResult.productRefs] : undefined,
                    articleResultRefs: state.articleResultRefs
                      ? [...state.articleResultRefs, ...searchResult.articleResultRefs]
                      : undefined,
                    productResultRefs: state.productResultRefs
                      ? [...state.productResultRefs, ...searchResult.productResultRefs]
                      : undefined,
                    pagination: { ...searchResult.pagination },
                    searchQuery: successAction.query,
                    lastUpdateTime: new Date().toISOString(),
                  };
                }
              }
            }
            return { ...searchResult, searchQuery: successAction.query, lastUpdateTime: new Date().toISOString() };
          }
        }
        break;
      case SolrSearchActions.SolrSearchActionTypes.ClearSearch: {
        return initialState;
      }
    }
    return state;
  };
}
