<ul *ngIf="contentTagUids && contentTagUids.length > 0">
  <ng-container *ngFor="let uid of contentTagUids">
    <ng-container *ngIf="getContentTag(uid) | async as contentTag">
      <li
        *ngIf="contentTag.displayInUI"
        (click)="toggleActiveTags(contentTag.uid)"
        [ngClass]="{ active: isActive(contentTag.uid) }"
      >
        {{ contentTag.displayName }}
      </li>
    </ng-container>
  </ng-container>
</ul>
