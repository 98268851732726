import { MemoizedSelector, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { Feed, OperationalMessageWrapper } from '../../../model';
import { StateWithUser, UserState } from '../user-state';
import { getUserState } from './feature.selector';

export const getFeedState: MemoizedSelector<StateWithUser, StateUtils.LoaderState<Feed> | undefined> = createSelector(
  getUserState,
  (state: UserState) => state && state.feed
);

export const getFeedData: MemoizedSelector<StateWithUser, Feed | undefined> = createSelector(
  getUserState,
  (state: UserState) => state && StateUtils.loaderValueSelector(state.feed)
);

export const getFeedDataLoading: MemoizedSelector<StateWithUser, boolean | undefined> = createSelector(
  getUserState,
  (state: UserState) => state && StateUtils.loaderLoadingSelector(state.feed)
);

export const getFeedOperationalMessageWrapper: MemoizedSelector<StateWithUser, OperationalMessageWrapper | undefined> =
  createSelector(getFeedData, (state: Feed) => state && state.operationalMessage);
