import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UuidService {
  // returns UUID in xxxx-xxxx format
  getUniqueId(parts: number): string {
    const stringArr = [];
    for (let i = 0; i < parts; i++) {
      // eslint-disable-next-line no-bitwise
      const part = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(part);
    }
    return stringArr.join('-');
  }
}
