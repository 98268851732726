import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AuthConfigService, OAuthLibWrapperService as CxOAuthLibWrapperService, OAuthTryLoginResult } from '@spartacus/core';
import { LoginOptions, OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { filter, take } from 'rxjs/operators';
import { WindowRef } from '../../../window/window-ref';

/**
 * Extension of @spartacus OAuthLibWrapperService class with modified tryLogin() method that additionaly accepts 'options' parameter.
 *
 * Wrapper service on the library OAuthService. Normalizes the lib API for services.
 * Use this service when you want to access low level OAuth library methods.
 */
@Injectable({
  providedIn: 'root',
})
export class OAuthLibWrapperService extends CxOAuthLibWrapperService {
  constructor(
    protected oAuthService: OAuthService,
    protected authConfigService: AuthConfigService,
    @Inject(PLATFORM_ID) protected platformId: Object,
    protected winRef: WindowRef
  ) {
    super(oAuthService, authConfigService, platformId, winRef);
  }

  /**
   * Tries to login user based on `code` or `token` present in the url.
   *
   * @param result The result returned by `OAuthService.tryLogin()`.
   *
   * @param tokenReceived Whether the event 'token_received' is emitted during `OAuthService.tryLogin()`.
   * We can use this identify that we have returned from an external authorization page to Spartacus).
   * In cases where we don't receive this event, the token has been obtained from storage.
   */
  tryLogin(options?: LoginOptions): Promise<OAuthTryLoginResult> {
    return new Promise((resolve) => {
      // We use the 'token_received' event to check if we have returned
      // from the auth server.
      let tokenReceivedEvent: OAuthEvent | undefined;
      const subscription = this.events$
        .pipe(
          filter((event) => event.type === 'token_received'),
          take(1)
        )
        .subscribe((event) => (tokenReceivedEvent = event));

      this.oAuthService
        .tryLogin({
          ...options,
          // We don't load discovery document, because it doesn't contain revoke endpoint information
          disableOAuth2StateCheck: true,
        })
        .then((result: boolean) => {
          resolve({
            result: result,
            tokenReceived: !!tokenReceivedEvent,
          });
        })
        .finally(() => {
          subscription.unsubscribe();
        });
    });
  }
}
