import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PrincipalConfigurationDirective } from './principal-configuration.directive';

@NgModule({
  declarations: [PrincipalConfigurationDirective],
  imports: [CommonModule],
  exports: [PrincipalConfigurationDirective],
})
export class PrincipalConfigurationDirectiveModule {}
