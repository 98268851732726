import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { PageLayoutComponent } from '../../cms-structure';
import { LoginGuard } from './login.guard';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: null,
        canActivate: [LoginGuard],
        component: PageLayoutComponent,
        data: { cxRoute: 'login' },
      },
    ]),
  ],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        login: {
          module: () => import('./login.module').then((m) => m.LoginModule),
          cmsComponents: ['DesktopLoginComponent'],
        },
      },
    }),
  ],
})
export class LoginFeatureModule {}
