<span class="search-hint-info mr-2">
  {{ text ?? ('catalog.searchHints_heading' | cxTranslate) }}
</span>

<div class="position-relative flex-shrink-1" style="min-width: 0">
  <div class="search-hint-holder side-scroll" pyNavSideScroll [skipNavScrollArrows]="true">
    <button *ngFor="let hint of hints" (click)="onHintClick(hint)" class="d-flex align-items-center search-hint">
      <i nz-icon nzType="search" class="mr-1"></i>
      <span [innerHTML]="hint"></span>
    </button>
  </div>
</div>
