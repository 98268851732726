import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavSideScrollDirective } from './nav-side-scroll.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [NavSideScrollDirective],
  exports: [NavSideScrollDirective],
})
export class NavSideScrollModule {}
