import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CategoryNormalizer } from './connector/category-normalizer';
import { CATEGORY_NORMALIZER } from './connector/converters';

@NgModule({
  imports: [CommonModule],
})
export class CategoryModule {
  static forRoot(): ModuleWithProviders<CategoryModule> {
    return {
      ngModule: CategoryModule,
      providers: [
        {
          provide: CATEGORY_NORMALIZER,
          useClass: CategoryNormalizer,
          multi: true,
        },
      ],
    };
  }
}
