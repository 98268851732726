import { ViewportScroller } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { Router, RouterModule, Scroll } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ExternalRoutesModule } from '@spartacus/core';
import { SmartEditRootModule } from '@spartacus/smartedit/root';
import { filter, map, pairwise, startWith } from 'rxjs/operators';
import { provideConfig } from './core/config/config-providers';
import { OccModule } from './core/occ/occ.module';
import { SiteContextModule } from './core/site-context';
import { MainModule } from './layout/main/main.module';
import { StorefrontConfig } from './storefront-config';
import { StorefrontFoundationModule } from './storefront-foundation.module';

@NgModule({
  imports: [
    RouterModule.forRoot([], {
      scrollPositionRestoration: 'disabled',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking',
    }),
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictStateSerializability: true,
          strictActionImmutability: true,
          strictActionSerializability: true,
        },
      }
    ),
    EffectsModule.forRoot([]),
    SiteContextModule.forRoot(),
    StorefrontFoundationModule,
    MainModule,
    SmartEditRootModule,
    OccModule.forRoot(),
    ExternalRoutesModule.forRoot(),
  ],
  exports: [MainModule, StorefrontFoundationModule],
})
export class StorefrontModule {
  static withConfig(config?: StorefrontConfig): ModuleWithProviders<StorefrontModule> {
    return {
      ngModule: StorefrontModule,
      providers: [provideConfig(config)],
    };
  }

  constructor(router: Router, viewportScroller: ViewportScroller) {
    // Custom scrollPositionRestoration bevaviour to avoid scroll to top on catalog/pdp when using e.g. facets and sorting.
    router.events
      .pipe(
        map((e) => e as Scroll),
        filter((e) => e instanceof Scroll),
        startWith(undefined),
        pairwise()
      )
      .subscribe(([previous, current]) => {
        const preserveScrollPosition = router.getCurrentNavigation()?.extras?.state?.preserveScrollPosition;

        if (current.position) {
          // backward navigation
          viewportScroller.scrollToPosition(current.position);
        } else if (current.anchor) {
          // anchor navigation
          viewportScroller.scrollToAnchor(current.anchor);
        } else {
          // forward navigation.
          const isNewRoute = previous?.routerEvent?.url?.split('?')?.[0] !== current.routerEvent?.url?.split('?')?.[0];
          if (isNewRoute && !preserveScrollPosition) {
            viewportScroller.scrollToPosition([0, 0]);
          }
        }
      });
  }
}
