<div class="modal-content">
  <div
    class="modal-header cx-modal-header justify-content-between align-items-center"
    [class.p-md-7]="hasWidePaddings"
    [ngClass]="{ 'empty-title': !title, 'pb-0': !title }"
  >
    <div>
      <h3 *ngIf="!showDismissTextInHeader; else h4Title">
        {{ title }}
      </h3>

      <ng-template #h4Title>
        <h4>{{ title }}</h4>
      </ng-template>

      <p *ngIf="subtitle" class="subtitle">{{ subtitle }}</p>
    </div>
    <ng-container *ngTemplateOutlet="templateDismissButton"></ng-container>
  </div>

  <div class="modal-body" [class.p-md-7]="hasWidePaddings" pyScrollState (scrolledToBottom)="scrolledToBottom.emit()">
    <ng-content></ng-content>
  </div>

  <div *ngIf="!hideFooter" class="modal-footer cx-modal-footer w-100" [class.p-md-7]="hasWidePaddings">
    <ng-container *ngIf="showCancelButton">
      <button class="btn btn-plain-secondary" (click)="onDismiss('Cancel')">
        {{ cancelButtonText || ('common.noAndCancel_action' | cxTranslate) }}
      </button>
    </ng-container>
    <ng-container *ngTemplateOutlet="actions"></ng-container>
  </div>

  <p class="error mt-2 p-7" *ngIf="error && errorText">{{ errorText }}</p>
</div>

<ng-template #templateDismissButton>
  <button
    *ngIf="dismissable || forceShowDismissButton"
    type="button"
    class="ml-4"
    [ngClass]="{ 'link with-text': showDismissTextInHeader, close: !showDismissTextInHeader }"
    [attr.aria-label]="'common.cancel_action' | cxTranslate"
    (click)="onDismiss('Cross click')"
  >
    <span class="mr-2" *ngIf="dismissText && showDismissTextInHeader">{{ dismissText }}</span>
    <i nz-icon pyIconThemeReverse nzType="close-circle" nzTheme="outline"></i>
  </button>
</ng-template>
