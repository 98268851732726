import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { Article, ColumnAttribute } from '../../model';
import { USER_BASE_FEATURE } from '../store/user-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: ArticleInfoAttributesFacade,
      feature: USER_BASE_FEATURE,
      async: true,
      methods: ['getColumnAttributes'],
    }),
})
export abstract class ArticleInfoAttributesFacade {
  abstract getColumnAttributes(article: Article, enableSecondaryArticleRowVariant: boolean): Observable<ColumnAttribute[]>;
}
