import { EntriesState } from '../../../../core/features/store/base-feature-state';
import {
  Address,
  B2BComment,
  CartSimulation,
  DeliveryDateInfo,
  OpeningSchedule,
  Order,
  OrderEntry,
  OrderProcessStatus,
  PaymentOption,
  PaymentSetup,
  Reinvoicing,
  ServiceOption,
  ShippingOption,
} from '../../../../core/model';

export const CHECKOUT_CORE_FEATURE = 'checkoutCore';
export const CHECKOUT_FEATURE = 'checkout';

export const CHECKOUT_STEPS = '[Checkout] Checkout steps';
export const CHECKOUT_HOLIDAY = '[Checkout] Holiday';
export const CHECKOUT_ACTIVE_ORDER_PROCESS = '[Checkout] Active order process';

export const PLACE_ORDER_PROCESS = 'placeOrder';
export const SIMULATE_CART_PROCESS = 'simulateCart';
export const SET_RESOLVE_OPTION_PROCESS = 'setResolveOption';
export const SET_DELIVERY_ADDRESS_PROCESS = 'setDeliveryAddress';
export const GET_DELIVERY_DATE_INFO_PROCESS = 'getDeliveryDateInfo';
export const SET_ORDER_NOTE_PROCESS = 'setOrderNote';
export const SET_DELIVERY_NOTE_PROCESS = 'setDeliveryNote';
export const SET_MESSAGE_PROCESS = 'setMessage';
export const SET_GOODS_MARKING_PROCESS = 'setGoodsMarking';
export const SET_CUSTOMER_REFERENCE_PROCESS = 'setCustomerReference';
export const SET_PHONE_NUMBER_PROCESS = 'setPhoneNumber';
export const SET_CART_OWNER_DETAILS_PROCESS = 'setCartOwnerDetails';
export const SET_REINVOICING_PROCESS = 'setReinvoicing';
export const SET_PAYMENT_OPTION_PROCESS = 'setPaymentOption';
export const SET_INVOICE_NOTE_PROCESS = 'setInvoiceNote';
export const SET_COST_PLACE_PROCESS = 'setCostPlace';
export const GET_PAYMENT_SETUP_PROCESS = 'getPaymentSetup';
export const PROCEED_PAYMENT_PROCESS = 'proceedPayment';
export const SET_SHIPPING_OPTION_PROCESS = 'setShippingOption';
export const SET_SERVICE_OPTION_PROCESS = 'setServiceOption';
export const GET_ORDER_PROCESS_STATUS_PROCESS = 'getOrderProcessStatus';
export const LOAD_PAYMENT_OPTIONS_PROCESS = 'loadPaymentOptions';
export const SET_COMMENT_PROCESS = 'setComment';
export const DELETE_COMMENT_PROCESS = 'deleteComment';

export interface StateWithCheckout {
  [CHECKOUT_FEATURE]: CheckoutState;
}

export interface CheckoutState {
  holidays: OpeningSchedule;
  steps: EntriesState<CheckoutStepsState>;
  activeOrderProcess?: Order;
}

export interface LastFullPalletUpgradeEntry {
  quantityAdded: number;
  entry: OrderEntry;
}

export interface CheckoutStepsState {
  cartId: string;
  delivery?: CheckoutDeliveryStepState;
  payment?: CheckoutPaymentStepState;
  shipping?: CheckoutShippingStepState;
  review?: CheckoutReviewStepState;
  cartSimulation?: CartSimulation;
  order?: Order;
  orderProcessStatus?: OrderProcessStatus;
  orderProcessPolling?: boolean;
  earliestDeliveryDate?: string;
  isOrderReviewed?: boolean;
  lastFullPalletUpgradeEntry?: LastFullPalletUpgradeEntry;
}

export interface CheckoutDeliveryStepState {
  selectedAddress?: Address;
  deliveryDateInfo?: DeliveryDateInfo;
}

export interface CheckoutPaymentStepState {
  paymentOptions?: PaymentOption[];
  selectedPaymentOption?: PaymentOption;
  paymentSetup?: PaymentSetup;
}

export interface CheckoutShippingStepState {
  isShippingOptionConfirmed: boolean;
  selectedShippingOption?: ShippingOption;
  serviceOptions?: ServiceOptionsState;
}

export interface CheckoutReviewStepState {
  orderNote?: string;
  deliveryNote?: string;
  message?: string;
  goodsMarking?: string;
  customerReference?: string;
  phoneNumber?: string;
  reinvoicing?: Reinvoicing;
  invoiceNote?: string;
  costPlace?: string;
  approverComment?: B2BComment;
  buyerComment?: B2BComment;
}

export interface ServiceOptionsState {
  [type: string]: ServiceOption;
}
