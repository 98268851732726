import {
  CmsBannerComponent,
  CmsBannerComponentMedia,
  CmsBreadcrumbsComponent,
  CmsComponent,
  CmsLinkComponent,
  CmsNavigationComponent,
  CmsNavigationEntry,
  CmsNavigationNode,
  CmsParagraphComponent,
  CmsProductCarouselComponent,
  CmsProductFacetNavigationComponent,
  CmsSearchBoxComponent,
  CmsSiteContextSelectorComponent,
  PageType,
} from '@spartacus/core';
import { Category } from './catalog.model';
import { Image } from './image.model';

export {
  CmsBannerComponent,
  CmsBannerComponentMedia,
  CmsBreadcrumbsComponent,
  CmsComponent,
  CmsLinkComponent,
  CmsNavigationComponent,
  CmsNavigationEntry,
  CmsNavigationNode,
  CmsParagraphComponent,
  CmsProductCarouselComponent,
  CmsProductFacetNavigationComponent,
  CmsSearchBoxComponent,
  CmsSiteContextSelectorComponent,
  PageType,
};

// The rest of this file contains custom CMS components

export interface CMSLinkComponents {
  /**
   * If this links to an internal content page, this will be populated
   */
  contentPageLabel?: string;
  external?: boolean;
  linkName?: string;
  url?: string;
}

export interface CMSLinkComponentsList {
  cmsLinkComponents?: CMSLinkComponents[];
}

export interface CMSLinkComponentsForCategory {
  category?: Category;
  linkComponents?: CMSLinkComponentsList;
}

export interface CMSLinkComponentsProductRef {
  code?: string;
}

export interface CMSLinkComponentsForProduct {
  productRef?: CMSLinkComponentsProductRef;
  linkComponents?: CMSLinkComponentsList;
}

export interface CMSLinkComponentsForProductList {
  list?: CMSLinkComponentsForProduct[];
}

export interface CmsLinkCollectionComponent extends CmsComponent {
  links?: string;
}

export interface CmsLinkCollectionItemComponent extends CmsComponent {
  url?: string;
  linkName?: string;
  name?: string;
  target?: string | boolean;
}

export interface CmsEmbeddedVideoComponent extends CmsComponent {
  url?: string;
  videoOrigin?: string;
  name?: string;
}

export interface ProductsTileComponent extends CmsComponent {
  uid?: string;
  typeCode?: string;
  tileTitle?: string;
  displayMode?: string;
}

// TODO: Refactor to use ProductReferences from @spartacus/core ?
export interface ProductReferences {
  productRefs?: ProductReference[];
}

// TODO: Refactor to use ProductReference from @spartacus/core ?
export interface ProductReference {
  code?: string;
}

export enum ProductsTilesDisplayModes {
  Slider = 'SLIDER',
  Grid = 'GRID',
}

export interface ArticlesTileComponent extends CmsComponent {
  uid?: string;
  typeCode?: string;
  tileTitle?: string;
  displayMode: ProductsTilesDisplayModes;
}

export interface ArticleReferences {
  articleRefs?: ArticleReference[];
}

export interface ArticleReference {
  code?: string;
}

export interface CmsImageComponent extends CmsComponent {
  media?: Image;
}

export interface CmsAreaOfUseDetailsComponent extends CmsComponent {
  titleLoc?: string;
  bodyLoc?: string;
  media?: Image;
  priority?: number;
  linkLoc?: string;
  linkTarget?: string | boolean;
  mediaLoc?: string;
}

export interface CmsMediaLoc {
  altText?: string;
  code?: string;
  mime?: string;
  url?: string;
}

export interface CmsMediaLinkComponent extends CmsComponent {
  linkText?: string;
  mediaLoc?: CmsMediaLoc;
  type?: string;
}

export interface CmsAreaOfUseOverviewComponent extends CmsComponent {
  components?: Array<any>;
}

export interface CmsPuffComponent extends CmsComponent {
  titleLoc?: string;
  bodyLoc?: string;
  media?: Image;
  priority?: number;
  linkLoc?: string;
  linkTarget?: string | boolean;
  mediaLoc?: string;
  leadTextLoc?: string;
  publishedDate?: Date;
  contentTags?: string;
}

export interface CmsPuffWithLinksComponent extends CmsComponent {
  links?: CmsLinkCollectionComponent;
  puff: CmsPuffComponent;
}

export interface CmsPuffWithLinksCollectionComponent extends CmsComponent {
  components?: string;
}

export interface CmsPuffCollectionComponent extends CmsComponent {
  maxShown?: number;
  components?: string;
  pagingEnabled?: string;
}

export interface CmsDynamicPuffCollectionComponent extends CmsComponent {
  pageSize?: number;
  pagingEnabled?: string;
  searchEnabled?: string;
  masterTag?: string;
  contentTags?: string;
  tagsOnThumbnails?: string;
}

export interface CmsNewsComponent extends CmsComponent {
  catalogVersion?: string;
  container?: string;
  listingSize?: string;
  onlyOneRestrictionMustApply?: string;
  slots?: string;
  uuid?: string;
  visible?: string;
  showMorePageUrl?: string;
}

export interface CmsTabContainerComponent extends CmsComponent {
  tabName?: string;
  components?: string;
}

export enum CmsBannerCarouselEffect {
  FADE = 'FADE',
  ZOOM = 'ZOOM',
  CURTAIN = 'CURTAINX',
  TURNDOWN = 'TURNDOWN',
}

export interface CmsBannerCarouselComponent extends CmsComponent {
  banners?: string;
  effect?: CmsBannerCarouselEffect;
}

export interface CmsTimedBannerCarouselComponent extends CmsComponent {
  banners?: string;
  duration?: string;
  catalogVersion?: string;
  container?: string;
  listingSize?: string;
  type?: string;
  onlyOneRestrictionMustApply?: string;
  slots?: string;
  uuid?: string;
  visible?: string;
}

export interface CmsMailFormComponent extends CmsComponent {
  actionUrl?: string;
  catalogVersion?: string;
  container?: string;
  emailTo?: string;
  labelSubmit?: string;
  onlyOneRestrictionMustApply?: string;
  restricted?: string;
  slots?: string;
  successPageLabelOrId?: string;
  textFail?: string;
  textSuccess?: string;
  type?: string;
  visible?: string;
  fields?: string;
}

export enum CmsMailFormType {
  EMAIL = 'EMAIL',
  CHECKBOX = 'CHECKBOX',
  RADIOBUTTON = 'RADIOBUTTON',
  SELECT = 'SELECT',
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  LONGTEXT = 'LONGTEXT',
  LABEL = 'LABEL',
  FILE = 'FILE',
}

export interface CmsMailFormFieldComponent extends CmsComponent {
  form?: string;
  label?: string;
  name?: string;
  number?: number;
  optional?: boolean;
  subject?: boolean;
  type?: CmsMailFormType;
  uid?: string;
  value?: string;
}

export interface CMSMailFormFieldListComponent {
  fields?: CmsMailFormFieldComponent[];
}

export interface CmsFlexComponent extends CmsComponent {
  flexType?: string;
  container?: string;
}

export interface CmsFlexNavigationComponent extends CmsNavigationComponent {
  flexType?: string;
  container?: string;
}

export interface CmsSiteSelectorHeaderBoxComponent extends CmsComponent {
  uid?: string;
  uuid?: string;
  typeCode?: string;
  name?: string;
  container?: string;
  flexType?: string;
}

export interface CmsMenuLoginComponent extends CmsComponent {
  uid?: string;
  uuid?: string;
  typeCode?: string;
  name?: string;
  container?: string;
  flexType?: string;
}

export interface CmsMenuAccountComponent extends CmsComponent {
  uid?: string;
  uuid?: string;
  typeCode?: string;
  name?: string;
  container?: string;
  flexType?: string;
}

export interface CmsDesktopLoginComponent extends CmsComponent {
  uid?: string;
  uuid?: string;
  typeCode?: string;
  name?: string;
  container?: string;
  flexType?: string;
}

export interface CmsSalesOrgAlertMessageComponent extends CmsComponent {
  alertType?: string;
  dismissible?: string;
  body?: string;
  header?: string;
}

export interface CmsFooterNavigationComponent extends CmsComponent {
  column1Text?: string;
  column2Text?: string;
  column3Text?: string;
  column4Text?: string;
  column0Banner?: CmsBannerComponent;
  column1Navigation?: CmsNavigationComponent;
  column2Navigation?: CmsNavigationComponent;
  column3Navigation?: CmsNavigationComponent;
  column4Navigation?: CmsNavigationComponent;
  row2Navigation?: CmsNavigationComponent;
  socialNavigation?: CmsLinkCollectionComponent;
  flexNavigation?: CmsComponent;
}

export interface ContentTag {
  displayInUI?: boolean;
  displayName?: string;
  uid?: string;
}

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface CmsBlpMainContentComponent extends CmsFlexNavigationComponent {}

export interface CmsBlpFooterContentComponent extends CmsFlexNavigationComponent {}

/* eslint-enable @typescript-eslint/no-empty-interface */
