import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '../../../core/i18n';
import { ArticleCarouselModule } from '../article-carousel';
import { NotificationModule } from '../notification';
import { OrderEntrySubstitutesArticlesComponent } from './order-entry-substitutes-articles.component';

@NgModule({
  imports: [CommonModule, I18nModule, NotificationModule, ArticleCarouselModule],
  declarations: [OrderEntrySubstitutesArticlesComponent],
  exports: [OrderEntrySubstitutesArticlesComponent],
})
export class OrderEntrySubstitutesArticlesModule {}
