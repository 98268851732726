import { MemoizedSelector, MemoizedSelectorWithProps, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { A4SampleColor, AlternativeProducts, LoaderError, Product } from '../../../../model';
import { PyStateUtils } from '../../../../state';
import { loaderErrorDetailsSelector } from '../../../../state/utils/loader';
import { CatalogState, ProductsState, StateWithCatalog } from '../../../store/catalog-state';
import { getCatalogKey } from '../../../store/selectors/catalog-keys.selector';
import { getCatalogState } from '../../../store/selectors/feature.selector';

export const getProductsRootState: MemoizedSelector<StateWithCatalog, ProductsState> = createSelector(
  getCatalogState,
  (state: CatalogState) => state && state.products
);

export const getProductsState: MemoizedSelectorWithProps<
  StateWithCatalog,
  any,
  StateUtils.EntityLoaderState<Product>
> = createSelector(
  getProductsRootState,
  getCatalogKey,
  (state: ProductsState, key) => state && state.products && state.products[key]
);

export const getProductLoaderState = (
  code: string,
  catalogUsageKey: string
): MemoizedSelector<StateWithCatalog, StateUtils.LoaderState<Product>> => {
  return createSelector(
    (state) => getProductsState(state, { catalogUsageKey: catalogUsageKey }),
    (details) => (details && PyStateUtils.entityLoaderStateSelector(details, code)) || PyStateUtils.initialLoaderState
  );
};

export const getProductLoading = (code: string, catalogUsageKey: string): MemoizedSelector<StateWithCatalog, boolean> => {
  return createSelector(getProductLoaderState(code, catalogUsageKey), (productState) =>
    StateUtils.loaderLoadingSelector(productState)
  );
};

export const getProductFailure = (code: string, catalogUsageKey: string): MemoizedSelector<StateWithCatalog, LoaderError> => {
  return createSelector(getProductLoaderState(code, catalogUsageKey), (productState) => loaderErrorDetailsSelector(productState));
};

export const getProduct = (code: string, catalogUsageKey: string): MemoizedSelector<StateWithCatalog, Product> => {
  return createSelector(getProductLoaderState(code, catalogUsageKey), (productState) =>
    StateUtils.loaderValueSelector(productState)
  );
};

export const searchProductCodes = (
  codes: string[],
  searchText: string,
  catalogUsageKey: string
): MemoizedSelector<StateWithCatalog, string[] | undefined> =>
  createSelector(
    (state) => getProductsState(state, { catalogUsageKey: catalogUsageKey }),
    (details) => {
      let productStates = (codes && details?.entities && codes.map((i) => details?.entities[i])) || [];

      if (searchText) {
        productStates = productStates.filter((productState) => {
          const name = (productState?.value?.name || '').toLowerCase();
          return name.search(searchText.toLowerCase()) !== -1;
        });
      }

      return productStates.map((productState) => productState.value?.code);
    }
  );

export const getSubstituteProductsState: MemoizedSelector<
  StateWithCatalog,
  StateUtils.EntityLoaderState<AlternativeProducts>
> = createSelector(getProductsRootState, (state: ProductsState) => state && state.substituteProducts);

export const getSubstituteProductsLoaderState = (
  code: string
): MemoizedSelector<StateWithCatalog, StateUtils.LoaderState<AlternativeProducts>> => {
  return createSelector(
    getSubstituteProductsState,
    (details) => PyStateUtils.entityLoaderStateSelector(details, code) || PyStateUtils.initialLoaderState
  );
};

export const getSubstituteProductsLoading = (code: string): MemoizedSelector<StateWithCatalog, boolean> => {
  return createSelector(getSubstituteProductsLoaderState(code), (substituteProductsState) =>
    StateUtils.loaderLoadingSelector(substituteProductsState)
  );
};

export const getSubstituteProducts = (code: string): MemoizedSelector<StateWithCatalog, AlternativeProducts> => {
  return createSelector(getSubstituteProductsLoaderState(code), (substituteProductsState) =>
    StateUtils.loaderValueSelector(substituteProductsState)
  );
};

export const getA4SampleColorsLoaderState = (
  productCode: string,
  grammage: number
): MemoizedSelector<StateWithCatalog, StateUtils.LoaderState<A4SampleColor[]>> => {
  return createSelector(
    getProductsRootState,
    (state) =>
      (state &&
        state.a4SampleColors &&
        PyStateUtils.entityLoaderStateSelector(state.a4SampleColors, `${productCode}#${grammage}`)) ||
      PyStateUtils.initialLoaderState
  );
};

export const getA4SampleColorsLoading = (productCode: string, grammage: number): MemoizedSelector<StateWithCatalog, boolean> => {
  return createSelector(getA4SampleColorsLoaderState(productCode, grammage), (state) => StateUtils.loaderLoadingSelector(state));
};

export const getA4SampleColors = (productCode: string, grammage: number): MemoizedSelector<StateWithCatalog, A4SampleColor[]> => {
  return createSelector(getA4SampleColorsLoaderState(productCode, grammage), (state) => StateUtils.loaderValueSelector(state));
};
