import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SolrSearchResult } from '../../../../../core/model';
import { CatalogTabTypes } from '../../../../../features/catalog/container/catalog/catalog-search.service';
import { SearchResultsContentBaseComponent } from '../search-results-content-base/search-results-content-base.component';

@Component({
  selector: 'py-search-results-content-secondary',
  templateUrl: './search-results-content-secondary.component.html',
  styleUrls: ['./search-results-content-secondary.component.scss'],
})
export class SearchResultsContentSecondaryComponent extends SearchResultsContentBaseComponent {
  @Input() isLoading: boolean;
  @Input() showResults: boolean;
  @Input() activeTab: CatalogTabTypes;
  @Input() searchResults: SolrSearchResult;

  @Input() set initialActiveTab(tab: CatalogTabTypes) {
    if (!!this.activeTab) {
      this.activeTab = tab;
    }
  }

  @Output() selectTab = new EventEmitter<CatalogTabTypes>();

  catalogTabTypes = CatalogTabTypes;

  constructor() {
    super();
  }
}
