import { Injectable, OnDestroy } from '@angular/core';
import { ConfigInitializerService } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CurrencyStatePersistenceService } from './currency-state-persistence.service';

@Injectable({ providedIn: 'root' })
export class CurrencyInitializer implements OnDestroy {
  protected subscription = new Subscription();

  constructor(
    protected currencyStatePersistenceService: CurrencyStatePersistenceService,
    protected configInit: ConfigInitializerService
  ) {}

  /**
   * Initializes the value of the active currency.
   */
  initialize(): void {
    this.subscription.add(
      this.configInit
        .getStable('context')
        .pipe(
          // TODO(#12351): <--- plug here explicitly SiteContextRoutesHandler
          switchMap(() => this.currencyStatePersistenceService.initSync())
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
