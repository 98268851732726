import { StateUtils } from '@spartacus/core';
import { PagedSearchAction } from '../../../features/store/actions/search.actions';
import {
  CreateOrUpdateOrderCardConfiguration,
  HttpErrorModel,
  OrderCard,
  OrderCardConfiguration,
  OrderCardPage,
  SearchParams,
} from '../../../model';
import { PyStateUtils } from '../../../state/utils';
import { ORDER_CARDS, ORDER_CARD_CONFIGURATION, ORDER_CARD_CONFIGURATIONS } from '../user-state';

interface SearchOrderCardsPayload {
  userId: string;
  searchParams?: SearchParams;
}

interface SearchOrderCardsFailPayload extends SearchOrderCardsPayload {
  error?: HttpErrorModel;
}

interface SearchOrderCardsSuccessPayload extends SearchOrderCardsPayload {
  searchResult: OrderCardPage;
}

export enum OrderCardsActionTypes {
  LoadOrderCard = '[User] Load order card',
  LoadOrderCardFail = '[User] Load order card fail',
  LoadOrderCardSuccess = '[User] Load order card success',

  SearchOrderCards = '[User] Search order cards',
  SearchOrderCardsFail = '[User] Search order cards fail',
  SearchOrderCardsSuccess = '[User] Search order cards success',

  LoadOrderCardConfigurations = '[User] Load order card configurations',
  LoadOrderCardConfigurationsFail = '[User] Load order card configurations fail',
  LoadOrderCardConfigurationsSuccess = '[User] Load order card configurations success',

  LoadOrderCardConfiguration = '[User] Load order card configuration',
  LoadOrderCardConfigurationFail = '[User] Load order card configuration fail',
  LoadOrderCardConfigurationSuccess = '[User] Load order card configuration success',

  UpdateOrderCardConfiguration = '[User] Update order card configuration',
  UpdateOrderCardConfigurationFail = '[User] Update order card configuration fail',
  UpdateOrderCardConfigurationSuccess = '[User] Update order card configuration success',
}

export class LoadOrderCard extends StateUtils.EntityLoadAction {
  readonly type = OrderCardsActionTypes.LoadOrderCard;

  constructor(public code: string, public userId: string) {
    super(ORDER_CARDS, code);
  }
}

export class LoadOrderCardFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderCardsActionTypes.LoadOrderCardFail;

  constructor(public code: string, public payload: any) {
    super(ORDER_CARDS, code, payload);
  }
}

export class LoadOrderCardSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderCardsActionTypes.LoadOrderCardSuccess;

  constructor(public code: string, public payload: OrderCard) {
    super(ORDER_CARDS, code, payload);
  }
}

export class SearchOrderCards extends StateUtils.LoaderLoadAction implements PagedSearchAction {
  readonly type = OrderCardsActionTypes.SearchOrderCards;
  readonly searchType = ORDER_CARDS;
  public searchParams;

  constructor(public payload: SearchOrderCardsPayload) {
    super(ORDER_CARDS);
    this.searchParams = payload.searchParams;
  }
}

export class SearchOrderCardsFail extends PyStateUtils.LoaderFailAction implements PagedSearchAction {
  readonly type = OrderCardsActionTypes.SearchOrderCardsFail;
  readonly searchType = ORDER_CARDS;
  public searchParams;

  constructor(public payload?: SearchOrderCardsFailPayload) {
    super(ORDER_CARDS, payload);
    this.searchParams = payload?.searchParams;
  }
}

export class SearchOrderCardsSuccess extends StateUtils.LoaderSuccessAction implements PagedSearchAction {
  readonly type = OrderCardsActionTypes.SearchOrderCardsSuccess;
  readonly searchType = ORDER_CARDS;
  public searchParams;

  constructor(public payload: SearchOrderCardsSuccessPayload) {
    super(ORDER_CARDS);
    this.searchParams = payload.searchParams;
  }
}

export class LoadOrderCardConfigurations extends StateUtils.EntityLoadAction {
  readonly type = OrderCardsActionTypes.LoadOrderCardConfigurations;

  constructor(public code: string, public userId: string) {
    super(ORDER_CARD_CONFIGURATIONS, code);
  }
}

export class LoadOrderCardConfigurationsFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderCardsActionTypes.LoadOrderCardConfigurationsFail;

  constructor(public code: string, public payload: any) {
    super(ORDER_CARD_CONFIGURATIONS, code, payload);
  }
}

export class LoadOrderCardConfigurationsSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderCardsActionTypes.LoadOrderCardConfigurationsSuccess;

  constructor(public code: string, public payload: OrderCardConfiguration[]) {
    super(ORDER_CARD_CONFIGURATIONS, code);
  }
}

export class LoadOrderCardConfiguration extends StateUtils.EntityLoadAction {
  readonly type = OrderCardsActionTypes.LoadOrderCardConfiguration;

  constructor(public code: string, public userId: string, public productCode: string) {
    super(ORDER_CARD_CONFIGURATION, `${code}|${productCode}`);
  }
}

export class LoadOrderCardConfigurationFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderCardsActionTypes.LoadOrderCardConfigurationFail;

  constructor(public code: string, public productCode: string, public payload: any) {
    super(ORDER_CARD_CONFIGURATION, `${code}|${productCode}`, payload);
  }
}

export class LoadOrderCardConfigurationSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderCardsActionTypes.LoadOrderCardConfigurationSuccess;

  constructor(public code: string, public productCode: string, public payload: OrderCardConfiguration) {
    super(ORDER_CARD_CONFIGURATION, `${code}|${productCode}`, payload);
  }
}

export class UpdateOrderCardConfiguration extends StateUtils.EntityLoadAction {
  readonly type = OrderCardsActionTypes.UpdateOrderCardConfiguration;

  constructor(
    public code: string,
    public userId: string,
    public productCode: string,
    public params: CreateOrUpdateOrderCardConfiguration
  ) {
    super(ORDER_CARD_CONFIGURATION, `${code}|${productCode}`);
  }
}

export class UpdateOrderCardConfigurationFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderCardsActionTypes.UpdateOrderCardConfigurationFail;

  constructor(public code: string, public productCode: string, public payload: any) {
    super(ORDER_CARD_CONFIGURATION, `${code}|${productCode}`, payload);
  }
}

export class UpdateOrderCardConfigurationSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderCardsActionTypes.UpdateOrderCardConfigurationSuccess;

  constructor(public code: string, public productCode: string, public payload: OrderCardConfiguration) {
    super(ORDER_CARD_CONFIGURATION, `${code}|${productCode}`, payload);
  }
}

export type OrderCardsActions =
  | LoadOrderCard
  | LoadOrderCardFail
  | LoadOrderCardSuccess
  | SearchOrderCards
  | SearchOrderCardsFail
  | SearchOrderCardsSuccess
  | LoadOrderCardConfiguration
  | LoadOrderCardConfigurationFail
  | LoadOrderCardConfigurationSuccess
  | LoadOrderCardConfigurations
  | LoadOrderCardConfigurationsFail
  | LoadOrderCardConfigurationsSuccess
  | UpdateOrderCardConfiguration
  | UpdateOrderCardConfigurationFail
  | UpdateOrderCardConfigurationSuccess;
