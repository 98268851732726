import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InterceptorUtil, OCC_USER_ID_ANONYMOUS, OccEndpointsService, USE_CLIENT_TOKEN } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Category } from '../../../model/catalog.model';
import { Occ } from '../../../occ/occ-models';
import { ConverterService } from '../../../util/converter.service';
import { CATEGORY_NORMALIZER } from '../../category/connector/converters';

@Injectable({
  providedIn: 'root',
})
export class AreaOfUseConnector {
  constructor(
    private converterService: ConverterService,
    private httpClient: HttpClient,
    private occEndpointsService: OccEndpointsService
  ) {}

  load(userId: string): Observable<Array<Category>> {
    let headers = new HttpHeaders();
    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }
    return this.httpClient
      .get<Occ.CategoryList>(this.occEndpointsService.buildUrl('areasOfUse', { urlParams: { userId: userId } }), { headers })
      .pipe(map((response) => this.converterService.convertMany(response['categories'], CATEGORY_NORMALIZER)));
  }
}
