import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../core/i18n';
import { IconThemeReverseModule } from '../../directives';
import { PrincipalConfigurationDirectiveModule } from '../../directives/principal-configuration';
import { PipesModule } from '../../pipes';
import { RadioModule } from '../radio/radio.module';
import { ReactiveFormInputFieldModule } from '../reactive-forms-input/reactive-form-input-field/reactive-form-input-field.module';
import { OrderEntryAccountingCodesComponent } from './order-entry-accounting-codes.component';

@NgModule({
  imports: [
    CommonModule,
    NzIconModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    I18nModule,
    PrincipalConfigurationDirectiveModule,
    ReactiveFormInputFieldModule,
    NgbTooltipModule,
    RadioModule,
    PipesModule,
    IconThemeReverseModule,
  ],
  declarations: [OrderEntryAccountingCodesComponent],
  exports: [OrderEntryAccountingCodesComponent],
})
export class OrderEntryAccountingCodesModule {}
