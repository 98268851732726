<ng-container
  *ngIf="{
    hasAddons: hasAddons$ | async,
    hasTendered: hasTendered$ | async,
    showCampaignMenu: showCampaignMenu$ | async,
    hasUniqueArticles: hasUniqueArticles$ | async,
    hasCustomerAssortment: hasCustomerAssortment$ | async,
    enableTogglingOfAddons: enableTogglingOfAddons$ | async,
    enableTogglingOfCustomerAssortment: enableTogglingOfCustomerAssortment$ | async,
    enableDisplayOutgoingArea: enableDisplayOutgoingArea$ | async,
    categories: categories$ | async,
    myaCategories: myaCategories$ | async,
    addonGroups: addonGroups$ | async,
    success: success$ | async,
    error: error$ | async,
    activeSoldTo: activeSoldTo$ | async,
    areCategoriesEmpty: areCategoriesEmpty$ | async
  } as data"
>
  <ng-container
    *ngIf="
      (data.categories && !data.areCategoriesEmpty) ||
        data.hasAddons ||
        data.showCampaignMenu ||
        data.hasUniqueArticles ||
        data.hasTendered ||
        (data.enableDisplayOutgoingArea && (data.activeSoldTo?.outgoingAssortmentAllowed || !data.activeSoldTo));
      else loadingTemplate
    "
  >
    <ng-container *ngIf="!data.enableTogglingOfCustomerAssortment && !data.enableTogglingOfAddons; else assortmentTemplate">
      <ng-container *ngIf="data.hasAddons" [ngTemplateOutlet]="addonGroupsTemplate"></ng-container>
      <ng-container [ngTemplateOutlet]="showAllCategoriesLink"></ng-container>
      <ng-container *ngIf="data.showCampaignMenu" [ngTemplateOutlet]="campaigns"></ng-container>

      <ng-container *ngTemplateOutlet="allCategoriesTemplate"></ng-container>

      <ng-container *ngTemplateOutlet="myAssortment"></ng-container>
    </ng-container>

    <ng-template #assortmentTemplate>
      <div class="mb-3">
        <div *ngIf="data.hasCustomerAssortment && data.enableTogglingOfCustomerAssortment" class="d-flex customer-assortment">
          <!-- <div class="d-flex customer-assortment"> -->
          <py-reactive-form-checkbox-slider
            class="d-block my-2"
            [addMargin]="false"
            [rightMargin]="true"
            [rightLabel]="'navigation.customerAssortment_tab' | cxTranslate"
            [group]="assortmentForm"
            [fieldName]="'customerAssortment'"
          ></py-reactive-form-checkbox-slider>
          <i
            nz-icon
            nzType="contracted-article"
            class="ml-2 d-flex align-self-center"
            [ngbTooltip]="'navigation.customerAssortment_tab' | cxTranslate"
            placement="bottom auto"
          ></i>
        </div>

        <py-reactive-form-checkbox-slider
          *ngIf="data.hasAddons"
          class="d-block my-2"
          [addMargin]="false"
          [rightMargin]="true"
          [rightLabel]="'navigation.myAddons_tab' | cxTranslate"
          [group]="assortmentForm"
          [fieldName]="'addons'"
        ></py-reactive-form-checkbox-slider>
      </div>

      <ng-container *ngIf="activeTabIndex === 0">
        <ul>
          <ng-container [ngTemplateOutlet]="showAllCategoriesLink"></ng-container>
          <ng-container *ngIf="data.showCampaignMenu" [ngTemplateOutlet]="campaigns"></ng-container>
          <ng-container *ngTemplateOutlet="allCategoriesTemplate"></ng-container>
          <ng-container *ngTemplateOutlet="myAssortment"></ng-container>
        </ul>
      </ng-container>
      <ng-container *ngIf="activeTabIndex === 1">
        <ul>
          <ng-container [ngTemplateOutlet]="showAllCategoriesLink"></ng-container>
          <li
            py-megamenu-list-item
            *ngFor="let category of data.myaCategories || []"
            (click)="setActiveCategoryCode.emit({ item: category, mya: true })"
            [ngClass]="{
              active: !showCampaignsSubMenu && !showMyAssortmentSubmenu && category.code === activeCategoryCode
            }"
          >
            <a>{{ category.name }}</a>
          </li>
          <ng-container *ngTemplateOutlet="myAssortment"></ng-container>
        </ul>
      </ng-container>
      <ng-container *ngIf="activeTabIndex === 2">
        <ul class="p-0">
          <ng-container *ngTemplateOutlet="addonGroupsTemplate"></ng-container>
        </ul>
      </ng-container>
    </ng-template>

    <ng-template #myAssortment>
      <ng-container *ngIf="data.hasUniqueArticles">
        <li
          py-megamenu-list-item
          *ngIf="data.hasTendered"
          [class.active]="showMyAssortmentSubmenu"
          (click)="setShowMyAssortmentSubmenu.emit(true)"
        >
          <a>
            <i nz-icon nzType="tag-o"></i>
            {{ 'navigation.myAssortment_action' | cxTranslate }}
          </a>
        </li>

        <li py-megamenu-list-item *ngIf="!data.hasTendered" [isDirectLink]="true">
          <cx-generic-link
            [routerUrl]="{ cxRoute: 'catalog' } | cxUrl"
            [queryParams]="{
              tab: 'a',
              query: '::allCategories:' + customer_unique_category_code + ':productCode:CUST_UNIQ',
              mya: myaValue
            }"
          >
            <i nz-icon nzType="tag-o"></i>{{ 'navigation.customizedArticles_action' | cxTranslate }}
          </cx-generic-link>
        </li>
      </ng-container>

      <li py-megamenu-list-item *ngIf="!data.hasUniqueArticles && data.hasTendered" [isDirectLink]="true">
        <cx-generic-link [routerUrl]="{ cxRoute: 'catalog' } | cxUrl" [queryParams]="{ tab: 'a', t: '1', mya: myaValue }">
          <i nz-icon nzType="like"></i>{{ 'navigation.tenderedArticles_action' | cxTranslate }}
        </cx-generic-link>
      </li>

      <ng-container *pyPrincipalConfiguration="'enableDisplayOutgoingArea'">
        <li
          py-megamenu-list-item
          *ngIf="data.activeSoldTo?.outgoingAssortmentAllowed || !data.activeSoldTo"
          [isDirectLink]="true"
        >
          <cx-generic-link
            [routerUrl]="{ cxRoute: 'catalog' } | cxUrl"
            [queryParams]="{ tab: 'a', o: '1', mya: myaValue }"
            class="outlet"
          >
            <i nz-icon nzType="percentage"></i>{{ 'navigation.outlet_action' | cxTranslate }}
          </cx-generic-link>
        </li>
      </ng-container>
    </ng-template>
  </ng-container>

  <ng-template #loadingTemplate>
    <ng-container *ngIf="!data.success && !data.error">
      <div>
        <div class="skeleton mb-2 w-100"></div>
        <div class="skeleton mb-2 w-100"></div>
        <div class="skeleton mb-2 w-100"></div>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #addonGroupsTemplate>
    <li
      py-megamenu-list-item
      *ngFor="let addonGroup of data.addonGroups || []"
      (click)="setActiveAddonGroup.emit(addonGroup)"
      [ngClass]="{ active: addonGroup.id === activeAddonGroupId }"
    >
      <a>{{ addonGroup.title }}</a>
    </li>
  </ng-template>

  <ng-template #showAllCategoriesLink>
    <li py-megamenu-list-item [isDirectLink]="true">
      <cx-generic-link [routerUrl]="{ cxRoute: 'catalog' } | cxUrl" [queryParams]="{ mya: myaValue }">
        {{ 'navigation.viewAllCategories_action' | cxTranslate }}
      </cx-generic-link>
    </li>
  </ng-template>

  <ng-template #campaigns>
    <li
      class="campaigns-link"
      py-megamenu-list-item
      [class.active]="showCampaignsSubMenu"
      (click)="setShowCampaignsSubMenu.emit(true)"
    >
      <a>
        <i nz-icon nzType="percentage" nzTheme="outline"></i>
        {{ 'navigation.campaigns_action' | cxTranslate }}
      </a>
    </li>
  </ng-template>

  <ng-template #allCategoriesTemplate>
    <ng-container *ngIf="!data.areCategoriesEmpty && !getCategoriesByRefsLoading; else loadingTemplate">
      <li
        py-megamenu-list-item
        *ngFor="let category of data.categories || []"
        (click)="setActiveCategoryCode.emit({ item: category, mya: false })"
        [ngClass]="{
          active: !showCampaignsSubMenu && !showMyAssortmentSubmenu && category.code === activeCategoryCode
        }"
      >
        <a>{{ category.name }}</a>
      </li>
    </ng-container>
  </ng-template>
</ng-container>
