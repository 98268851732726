import { Injectable, OnDestroy } from '@angular/core';
import { ConfigInitializerService } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LanguageStatePersistenceService } from './language-state-persistence.service';

@Injectable({ providedIn: 'root' })
export class LanguageInitializer implements OnDestroy {
  constructor(
    protected languageStatePersistenceService: LanguageStatePersistenceService,
    protected configInit: ConfigInitializerService
  ) {}

  protected subscription = new Subscription();

  /**
   * Initializes the value of the active language.
   */
  initialize(): void {
    this.subscription.add(
      this.configInit
        .getStable('context')
        .pipe(
          // TODO(#12351): <--- plug here explicitly SiteContextRoutesHandler
          switchMap(() => this.languageStatePersistenceService.initSync())
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
