import { Component, ComponentRef, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subject, Subscription, timer } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { BaseAbstractModalComponent, LAUNCH_CALLER, LaunchDialogService } from '../../../core/modal';

export interface ReloadModalBodyData {
  onAccept: () => void;
  onReject: () => void;
  modalRejected$: Subject<void>;
}

@Component({
  selector: 'py-reload-modal-body',
  templateUrl: './reload-modal-body.component.html',
  styleUrls: ['./reload-modal-body.component.scss'],
})
export class ReloadModalBodyComponent extends BaseAbstractModalComponent implements OnInit, OnDestroy {
  private readonly ACCEPT_TIMEOUT_SECONDS = 30;
  private subscriptions = new Subscription();

  onAccept: () => void;
  onReject: () => void;

  isReloading = false;

  timeout$ = timer(0, 1000).pipe(
    map((value) => this.ACCEPT_TIMEOUT_SECONDS - value),
    tap((value) => value === 0 && this.accept())
  );

  modalRejected$: Subject<void>;

  constructor(protected launchDialogService: LaunchDialogService, protected el: ElementRef, protected renderer: Renderer2) {
    super(el, launchDialogService, renderer);
    this.handlerForDismissOnClickOutside = this.reject;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.launchDialogService.data$.pipe(take(1)).subscribe((data: ReloadModalBodyData) => {
        this.onAccept = data.onAccept;
        this.onReject = data.onReject;
        this.modalRejected$ = data.modalRejected$;
      })
    );
  }

  accept(): void {
    this.isReloading = true;
    this.onAccept();
  }

  reject(): void {
    this.onReject();
    this.modalRejected$.next();

    this.subscriptions.add(
      this.launchDialogService.lastOpenedModal$
        .pipe(
          take(1),
          map((componentRef) => componentRef as ComponentRef<ReloadModalBodyComponent>)
        )
        .subscribe((lastOpenedModalRef) => {
          this.launchDialogService.clear(LAUNCH_CALLER.RELOAD_MODAL_BODY);
          lastOpenedModalRef.destroy();
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
