import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScreenSizeModule } from '../../directives';
import { MediaModule } from '../media/media.module';
import { ImageThumbnailsComponent } from './image-thumbnails.component';

@NgModule({
  imports: [CommonModule, MediaModule, ScreenSizeModule],
  declarations: [ImageThumbnailsComponent],
  exports: [ImageThumbnailsComponent],
})
export class ImageThumbnailsModule {}
