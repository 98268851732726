import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ConfigInitializerService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ViewLoadedService } from '../../core/auth';
import { BaseSiteService } from '../../core/site-context';
import { ThemeService } from '../theme';

@Component({
  selector: 'py-super-storefront',
  templateUrl: './super-storefront.component.html',
  styleUrls: ['./super-storefront.component.scss'],
})
export class SuperStorefrontComponent implements OnInit, AfterViewInit {
  public brandLandingSite: string;
  public loaded$: Observable<boolean>;
  public site: string;

  public get isLocaleSet(): boolean {
    return this.brandLandingSite !== this.site;
  }

  constructor(
    private readonly baseSiteService: BaseSiteService,
    private readonly configInitializer: ConfigInitializerService,
    private themeService: ThemeService,
    private viewLoadedService: ViewLoadedService
  ) {
    this.brandLandingSite = `ng_${this.themeService.getTheme()}`;
  }

  ngOnInit() {
    this.loaded$ = this.configInitializer.getStable('context').pipe(
      switchMap(() => {
        return this.baseSiteService.getActive();
      }),
      map((site) => {
        this.site = site;
        return true;
      })
    );
  }

  ngAfterViewInit(): void {
    this.viewLoadedService.setLoaded();
  }
}
