import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        orderCardsAdminManagement: {
          module: () => import('./manage-order-cards/manage-order-cards.module').then((m) => m.CmsManageOrderCardsModule),
          cmsComponents: ['CmsOrderCardsAdminManagementComponent'],
        },
        userOrderCards: {
          module: () => import('./user-order-cards/user-order-cards.module').then((m) => m.CmsUserOrderCardsModule),
          cmsComponents: ['CmsOrderCardsComponent'],
        },
      },
    }),
  ],
})
export class OrderCardsFeatureModule {}
