import { StateUtils } from '@spartacus/core';
import {
  EntriesState,
  IndexedState,
  SearchPageResultState,
  SearchParamsState,
  SearchState,
} from '../../features/store/base-feature-state';
import {
  AccountingCode,
  AccountingCodeUserInfo,
  ArticlePrice,
  ArticleStockInfo,
  Consent,
  ConsentTemplate,
  ConsignmentArticleStock,
  Country,
  DeliveryAddress,
  MiniShoppingList,
  OrderApproval,
  OrderCard,
  OrderCardConfiguration,
  PrincipalConfiguration,
  PrincipalSharedShoppingListFlags,
  RecommendationPanel,
  ShoppingList,
  ShoppingListAccessType,
  ShoppingListPermission,
  SiteFamilyConfig,
  StockInfoDetails,
  User,
} from '../../model';
import { Feed } from '../../model/feed.model';
import { AnalyticsMetadata } from '../../occ/occ-models/occ-generated.models';

export const USER_FEATURE = 'user';
export const USER_BASE_FEATURE = 'user base';

export const ANALYTICS_METADATA = '[User] Analytics metadata';
export const PRINCIPAL_CONFIGURATION = '[User] Principal configuration';
export const UPDATE_PRINCIPAL_CONFIGURATION_PROCESS = 'updatePrincipalConfiguration';
export const DELIVERY_ADDRESS = '[User] Delivery address';
export const ORDER_APPROVALS = '[User] Order approvals';
export const ORDER_APPROVALS_ADMIN = '[User] Order approvals admin';
export const ORDER_APPROVAL_PENDING_COUNT = '[User] Order approval pending count';
export const ORDER_APPROVAL_ADMIN_PENDING_COUNT = '[User] Order approval admin pending count';
export const ORDER_APPROVAL_BUYERS = '[User] Order order approval buyers';
export const ORDERS_RETURNED_COUNT = '[User] Orders returned count';
export const DELIVERY_ADDRESS_SAVE = '[User] Delivery address save';
export const DELIVERY_ADDRESS_DELETE = '[User] Delivery address deletion';
export const DELIVERY_COUNTRY = '[User] Delivery country';
export const ARTICLE_PRICE = '[User] Price';
export const ARTICLE_STOCK_INFO = '[User] Stock info';
export const ARTICLE_STOCK_INFO_DETAILS = '[User] Stock info details';
export const PROFILE_COUNTRY = '[User] Profile country';
export const ONBOARDING_COUNTRY = '[User] Onboarding country';
export const MINI_SHOPPING_LIST = '[User] Mini shopping list';
export const WRITABLE_SHOPPING_LIST = '[User] Writable shopping list';
export const SHOPPING_LIST = '[User] Shopping list';
export const SHOPPING_LIST_PRINCIPALS = '[User] Shopping list principals';
export const CONSENTS_NOT_GIVEN = '[User] Consents not given';
export const RECOMMENDATIONS = '[User] Recommendations';
export const SITE_FAMILY_CONFIG = '[User] Site family config';
export const ACCOUNTING_CODES_ADMIN = '[User] Accounting code admin';

export const ORDER_CARDS = '[User] Order cards';
export const ORDER_CARD_CONFIGURATIONS = '[User] Order card configurations';
export const ORDER_CARD_CONFIGURATION = '[User] Order card configuration';
export const ORDER_CARDS_ADMIN = '[User] Order cards admin';
export const ORDER_CARD_ADMIN_CONFIGURATIONS = '[User] Order card admin configurations';
export const ORDER_CARD_ADMIN_CONFIGURATION = '[User] Order card admin configuration';

export const UPDATE_CREDENTIALS_PROCESS = 'updateCredentials';
export const FORGOT_PASSWORD_PROCESS = 'forgotPassword';
export const FORGOT_USERNAME_PROCESS = 'forgotUsername';
export const UPDATE_PASSWORD_PROCESS = 'updatePassword';
export const UPDATE_USER_DETAILS_PROCESS = 'updateUserDetails';
export const REGISTER_USER_PROCESS = 'registerUser';
export const SEARCH_COMPANY_PROCESS = 'searchCompany';
export const LOAD_COMPANY_PROCESS = 'loadCompany';
export const REMOVE_USER_PROCESS = 'removeUser';
export const UPDATE_SHOPPING_LIST_NAME_PROCESS = 'updateShoppingListName';
export const UPDATE_SHOPPING_LIST_DESCRIPTION_PROCESS = 'updateShoppingListDescription';
export const UPDATE_SHOPPING_LIST_PERMISSIONS_PROCESS = 'updateShoppingListPermissions';
export const UPDATE_SHOPPING_LIST_CONTACT_PERSON_PROCESS = 'updateShoppingListContactPerson';
export const DELETE_SHOPPING_LIST_PROCESS = 'deleteShoppingList';
export const DELETE_ORDER_CARD_PROCESS = 'deleteOrderCard';
export const CREATE_ORDER_CARD_PROCESS = 'createOrderCard';
export const CONTACTS_ORDER_CARD_PROCESS = 'contactsOrderCard';
export const ORDER_CARD_ADMIN_ROLES_PROCESS = 'adminRolesOrderCard';
export const ORDER_CARD_USER_ROLES_PROCESS = 'userRolesOrderCard';

export const ORDER_APPROVAL_MAKE_DECISION_PROCESS = 'orderApproval.makeDecision';
export const ORDER_APPROVAL_PROCESS = 'orderApproval';
export const B2B_COMMENTS_PROCESS = 'orderApproval.b2bcomments';
export const ORDER_APPROVAL_PROCESSING_PROCESS = 'orderApproval.processing';
export const ORDER_APPROVAL_RECOVER_PROCESS = 'orderApproval.recover';
export const ORDER_APPROVAL_ABORT_HIJACK_PROCESS = 'orderApproval.abortHijack';
export const ORDER_APPROVAL_DELETE_PROCESS = 'orderApproval.delete';
export const APPROVED_ORDER_APPROVAL_DETAILS_PROCESS = 'approvedOrderApproval.details';
export const ORDER_APPROVAL_DETAILS_PROCESS = 'orderApproval.details';
export const ORDER_APPROVAL_SIMULATION_PROCESS = 'orderApproval.simulation';
export const PUNCHOUT_DATA_PROCESS = 'punchOut.data';
export const PUNCHOUT_PROCESS = 'punchOut';
export const SHIP_TOS = 'ship-tos';
export const FEED_DATA = '[User] Feed Data';
export const CONSIGNMENT_STOCK_ARTICLES = 'consignmentStockArticles';
export const CONSIGNMENT_STOCK_ARTICLE_STOCK_INFO = 'consignmentStockArticleStockInfo';

export interface StateWithUser {
  [USER_FEATURE]: UserState;
}

export interface UserState {
  account: UserDetailsState;
  principalConfiguration: StateUtils.LoaderState<IndexedState<PrincipalConfiguration>>;
  deliveryAddresses: DeliveryAddressState;
  resetPassword: boolean;
  profileCountries: StateUtils.LoaderState<EntriesState<Country>>;
  onboardingCountries: StateUtils.LoaderState<EntriesState<Country>>;
  shoppingList: ShoppingListState;
  consents: StateUtils.LoaderState<ConsentTemplate[]>;
  consentsNotGiven: StateUtils.LoaderState<Consent[]>;
  consentsBanner?: boolean;
  consentsSettingsModal?: boolean;
  prices: StateUtils.EntityLoaderState<ArticlePriceState>;
  stockInfos: StateUtils.EntityLoaderState<StockInfoState>;
  stockInfoDetails: StateUtils.EntityLoaderState<StockInfoDetailsState>;
  recommendations: StateUtils.EntityLoaderState<RecommendationPanel>;
  orderApproval: OrderApprovalsState;
  orderApprovalAdmin: OrderApprovalsAdminState;
  analyticsMetadata: StateUtils.LoaderState<AnalyticsMetadata>;
  siteFamilyConfig: StateUtils.LoaderState<SiteFamilyConfig>;
  orderCard: OrderCardState;
  accountingCode: AccountingCodeState;
  feed: StateUtils.LoaderState<Feed>;
  shipTos: ShipTosState;
  assortment: AssortmentState;
}

export interface AccountingCodeState {
  accountingCodesAdmin: AccountingCodesAdminState;
}

export interface AccountingCodesAdminState {
  search: SearchState<SearchPageResultState>;
  searchEntities: EntriesState<AccountingCode>;
  entities: StateUtils.EntityLoaderState<AccountingCode>;
  info: AccountingCodeUserInfo;
}

export interface OrderCardState {
  orderCards: OrderCardsState;
  orderCardsAdmin: OrderCardsAdminState;
}

export interface OrderCardsState {
  search: SearchState<SearchPageResultState>;
  searchEntities: EntriesState<OrderCard>;
  entities: StateUtils.EntityLoaderState<OrderCard>;
  orderCardConfiguration: OrderCardConfigurationState;
}

export interface OrderCardsAdminState {
  search: SearchState<SearchPageResultState>;
  searchEntities: EntriesState<OrderCard>;
  entities: StateUtils.EntityLoaderState<OrderCard>;
  orderCardConfiguration: OrderCardConfigurationState;
}

export interface OrderCardConfigurationState {
  list: StateUtils.EntityLoaderState<any>;
  entities: StateUtils.EntityLoaderState<OrderCardConfiguration>;
}

export interface DeliveryAddressState {
  search: SearchState<SearchPageResultState>;
  searchParams: AddressSearchParamsState;
  entities: EntriesState<DeliveryAddress>;
  save: StateUtils.LoaderState<any>;
  delete: StateUtils.LoaderState<string>;
  countries: StateUtils.LoaderState<Country[]>;
}

export interface UserDetailsState {
  details: StateUtils.LoaderState<User>;
}

export interface ShoppingListState {
  shoppingLists: StateUtils.EntityProcessesLoaderState<ShoppingList>;
  miniShoppingLists: MiniShoppingListState;
  writableShoppingLists: WritableShoppingListState;
  principals: ShoppingListsPrincipalsState;
}

export interface ShoppingListsPrincipalsState {
  search: SearchState<SearchPageResultState>;
  entities: EntriesState<PrincipalSharedShoppingListFlags>;
  update: StateUtils.LoaderState<ShoppingListPermission[]>;
}

export interface MiniShoppingListState {
  search: SearchState<SearchPageResultState>;
  entities: EntriesState<MiniShoppingList>;
}

export interface WritableShoppingListState {
  search: SearchState<SearchPageResultState>;
  entities: EntriesState<MiniShoppingList>;
}

export interface MiniShoppingListSearchParamsState extends SearchParamsState {
  name?: string;
  accessType?: ShoppingListAccessType;
}

export interface WritableShoppingListSearchParamsState extends SearchParamsState {
  name?: string;
}

export interface AddressSearchParamsState extends SearchParamsState {
  text?: string;
}

export interface ArticlePriceState {
  price?: ArticlePrice;
  attempts?: number;
  lastUpdateTime?: string;
}

export interface StockInfoState {
  stockInfo?: ArticleStockInfo;
  lastUpdateTime?: string;
}

export interface StockInfoDetailsState {
  stockInfo?: StockInfoDetails;
  lastUpdateTime?: string;
}

export interface OrderApprovalsSearchParamsState extends SearchParamsState {
  searchText?: string;
}

export interface OrderApprovalsState {
  search: SearchState<SearchPageResultState>;
  searchParams: OrderApprovalsSearchParamsState;
  entities: EntriesState<OrderApproval>;
  pending: StateUtils.LoaderState<number>;
  buyers: AvailableBuyerState;
  returned: StateUtils.LoaderState<number>;
}

export interface OrderApprovalsAdminState {
  search: SearchState<SearchPageResultState>;
  searchParams: OrderApprovalsSearchParamsState;
  entities: EntriesState<OrderApproval>;
  pending: StateUtils.LoaderState<number>;
}

export interface AvailableBuyerState {
  search: SearchState<SearchPageResultState>;
  searchParams: AvailableSearchParamsState;
  entities: EntriesState<any>;
}

export interface AvailableSearchParamsState extends SearchParamsState {
  text?: string;
}

export interface ShipTosSearchParamsState extends SearchParamsState {
  text?: string;
  onlyConnected?: boolean;
  soldTos?: string[];
}

export interface ShipTosState {
  search: SearchState<SearchPageResultState>;
  searchParams: ShipTosSearchParamsState;
  entities: EntriesState<DeliveryAddress>;
}

export interface ConsignmentStockArticleStockInfoState {
  stockInfo?: ConsignmentArticleStock;
}

export interface ConsignmentStockState {
  articles: StateUtils.LoaderState<string[]>;
  stockInfos: StateUtils.EntityLoaderState<ConsignmentStockArticleStockInfoState>;
}

export interface AssortmentState {
  consignmentStock: ConsignmentStockState;
}
