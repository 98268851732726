import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        showcase: {
          module: () => import('./showcase.module').then((m) => m.ShowCaseModule),
          cmsComponents: ['CmsShowcaseFlexComponent'],
        },
      },
    }),
  ],
})
export class ShowcaseFeatureModule {}
