import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { UserIdService } from '../../auth/user-auth/facade/user-id.service';
import { Country } from '../../model/address.model';
import { shallowEqualObjects } from '../../util';
import { SiteContextActions } from '../store/actions/index';
import { SiteContextSelectors } from '../store/selectors/index';
import { StateWithSiteContext } from '../store/site-context-state';

@Injectable({ providedIn: 'root' })
export class SiteCountryService {
  private countryOverride$ = new BehaviorSubject<Country>(undefined);
  constructor(protected store: Store<StateWithSiteContext>, protected userIdService: UserIdService) {}

  loadCountry(countryCode: string): void {
    this.userIdService
      .getUserId()
      .pipe(take(1))
      .subscribe((userId) => this.store.dispatch(new SiteContextActions.LoadSiteCountry(userId, countryCode)));
  }

  getActiveCountry(): Observable<Country> {
    return combineLatest([
      this.store.pipe(
        select(SiteContextSelectors.getActiveCountry),
        filter((active) => Boolean(active)),
        distinctUntilChanged((previous, current) => {
          return shallowEqualObjects(previous, current);
        })
      ),
      this.countryOverride$,
    ]).pipe(
      map(([activeCountry, countryOverride]) =>
        countryOverride && countryOverride?.isocode === activeCountry?.isocode
          ? {
              ...(activeCountry ?? {}),
              ...(countryOverride.countryFlagSprite ? { countryFlagSprite: countryOverride.countryFlagSprite } : {}),
              ...(countryOverride.name ? { name: countryOverride.name } : {}),
            }
          : activeCountry
      )
    );
  }

  overrideCountry(isocode: string, country: Country): void {
    const countryOverride = { ...(country ?? {}), isocode };
    if (!shallowEqualObjects(this.countryOverride$.getValue(), countryOverride)) {
      this.countryOverride$.next(countryOverride);
    }
  }
}
