import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InterceptorUtil, OCC_USER_ID_ANONYMOUS, OccEndpointsService, USE_CLIENT_TOKEN } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { A4SampleColor, AlternativeProducts, KeyedResultArray, Product } from '../../../model';
import { Occ } from '../../../occ/occ-models';
import { ConverterService } from '../../../util/converter.service';
import { PRODUCT_NORMALIZER, SUBSTITUTE_PRODUCTS_NORMALIZER } from './converters';

@Injectable({
  providedIn: 'root',
})
export class ProductConnector {
  constructor(
    private converterService: ConverterService,
    private httpClient: HttpClient,
    private occEndpointsService: OccEndpointsService
  ) {}

  loadProducts(userId: string, codes: Array<string>, myAssortment?: string): Observable<KeyedResultArray<Product>> {
    const queryParams = { codes: codes.join(',') };
    if (!!myAssortment) {
      queryParams['mya'] = myAssortment;
    }
    let headers = new HttpHeaders();
    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }
    return this.httpClient
      .get<Array<Product>>(
        this.occEndpointsService.buildUrl('products', {
          urlParams: { userId: userId },
          queryParams,
        }),
        {
          headers,
          observe: 'response',
        }
      )
      .pipe(
        map((response) => {
          return {
            key: response.headers.get('x-opti-catalog-key'),
            result: this.converterService.convertMany(response.body['products'], PRODUCT_NORMALIZER),
          };
        })
      );
  }

  loadSubstituteProducts(userId: string, productCode: string): Observable<AlternativeProducts> {
    let headers = new HttpHeaders();
    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }
    return this.httpClient
      .get<Occ.AlternativeProducts>(
        this.occEndpointsService.buildUrl('substituteProducts', {
          urlParams: {
            userId,
            productCode,
          },
        }),
        { headers }
      )
      .pipe(this.converterService.pipeable(SUBSTITUTE_PRODUCTS_NORMALIZER));
  }

  loadA4SampleColors(userId: string, productCode: string, grammage: number): Observable<A4SampleColor[]> {
    let headers = new HttpHeaders();
    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }
    return this.httpClient
      .get<Occ.A4SampleColorList>(
        this.occEndpointsService.buildUrl('a4SampleColors', {
          urlParams: {
            userId,
            productCode,
          },
          queryParams: {
            grammage,
          },
        }),
        { headers }
      )
      .pipe(pluck('colors'));
  }
}
