import { StateUtils } from '@spartacus/core';
import { AddonGroup } from '../../../../model';
import { PyStateUtils } from '../../../../state/utils';
import { ADDON_GROUP } from '../../../store/catalog-state';

export enum AddonGroupActionTypes {
  LoadAddonGroups = '[Catalog] Load Addon groups',
  LoadAddonGroupsSuccess = '[Catalog] Load Addon groups Success',
  LoadAddonGroupsFail = '[Catalog] Load Addon groups Fail',

  LoadAddonGroup = '[Catalog] Load Addon group',
  LoadAddonGroupSuccess = '[Catalog] Load Addon group Success',
  LoadAddonGroupFail = '[Catalog] Load Addon group Fail',
}

export class LoadAddonGroups extends StateUtils.LoaderLoadAction {
  readonly type = AddonGroupActionTypes.LoadAddonGroups;

  constructor(public userId: string) {
    super(ADDON_GROUP);
  }
}

export class LoadAddonGroupsSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = AddonGroupActionTypes.LoadAddonGroupsSuccess;

  constructor(public payload: AddonGroup[]) {
    super(ADDON_GROUP);
  }
}

export class LoadAddonGroupsFail extends PyStateUtils.LoaderFailAction {
  readonly type = AddonGroupActionTypes.LoadAddonGroupsFail;

  constructor(public payload: any) {
    super(ADDON_GROUP, payload);
  }
}

export class LoadAddonGroup extends StateUtils.LoaderLoadAction {
  readonly type = AddonGroupActionTypes.LoadAddonGroup;

  constructor(public userId: string, public addonGroupId: string, public sort: string) {
    super(ADDON_GROUP);
  }
}

export class LoadAddonGroupSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = AddonGroupActionTypes.LoadAddonGroupSuccess;

  constructor(public payload: AddonGroup) {
    super(ADDON_GROUP);
  }
}

export class LoadAddonGroupFail extends PyStateUtils.LoaderFailAction {
  readonly type = AddonGroupActionTypes.LoadAddonGroupFail;

  constructor(public payload: any) {
    super(ADDON_GROUP, payload);
  }
}

export type AddonGroupsActions =
  | LoadAddonGroups
  | LoadAddonGroupsSuccess
  | LoadAddonGroupsFail
  | LoadAddonGroup
  | LoadAddonGroupSuccess
  | LoadAddonGroupFail;
