import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { SearchTermFacetMappingHint } from '../../../model/solr-search-config';
import { SOLR_SEARCH_FEATURE, SolrSearchState, StateWithSolrSearch } from '../solr-search-state';

export const getSolrSearchState: MemoizedSelector<StateWithSolrSearch, SolrSearchState> =
  createFeatureSelector<SolrSearchState>(SOLR_SEARCH_FEATURE);

export const getHintsState: MemoizedSelector<
  StateWithSolrSearch,
  StateUtils.LoaderState<SearchTermFacetMappingHint[]>
> = createSelector(getSolrSearchState, (state: SolrSearchState) => state?.hints);

export const getHintsValue: MemoizedSelector<StateWithSolrSearch, SearchTermFacetMappingHint[]> = createSelector(
  getHintsState,
  (state: StateUtils.LoaderState<SearchTermFacetMappingHint[]>) => StateUtils.loaderValueSelector(state) || []
);

export const getHintsLoading: MemoizedSelector<StateWithSolrSearch, boolean> = createSelector(
  getHintsState,
  (state: StateUtils.LoaderState<SearchTermFacetMappingHint[]>) => StateUtils.loaderLoadingSelector(state) || false
);

export const getHintsSuccess: MemoizedSelector<StateWithSolrSearch, boolean> = createSelector(
  getHintsState,
  (state) => (state && StateUtils.loaderSuccessSelector(state)) || false
);
