import { Injectable } from '@angular/core';
import { BASE_SITE_CONTEXT_ID, LANGUAGE_CONTEXT_ID, SiteContextConfig, StatePersistenceService } from '@spartacus/core';
import { Observable, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { LanguageService } from '../facade/language.service';
import { SiteContextParamsService } from './site-context-params.service';

@Injectable({ providedIn: 'root' })
export class LanguageStatePersistenceService {
  constructor(
    protected statePersistenceService: StatePersistenceService,
    protected languageService: LanguageService,
    protected config: SiteContextConfig,
    protected siteContextParamsService: SiteContextParamsService
  ) {}

  protected initialized$ = new ReplaySubject<void>(1);

  /**
   * Initializes the synchronization of the active language with the local storage.
   *
   * @returns Observable that emits and completes when the value is read from the storage.
   */
  public initSync(): Observable<unknown> {
    this.statePersistenceService.syncWithStorage({
      key: LANGUAGE_CONTEXT_ID,
      state$: this.languageService.getActive(),
      context$: this.siteContextParamsService.getValues([BASE_SITE_CONTEXT_ID]),
      onRead: (state) => this.onRead(state),
    });
    return this.initialized$;
  }

  protected onRead(valueFromStorage: string): void {
    this.languageService
      .isInitialized$()
      .pipe(take(1))
      .subscribe((isInitialized) => {
        if (!isInitialized) {
          this.languageService.setActive(
            valueFromStorage || this.siteContextParamsService.getParamDefaultValue(LANGUAGE_CONTEXT_ID)
          );
        }

        if (!this.initialized$.closed) {
          this.initialized$.next();
          this.initialized$.complete();
        }
      });
  }
}
