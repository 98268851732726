import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../../core/i18n';
import { UrlModule } from '../../../../core/routing';
import { GenericLinkModule, MegamenuListItemsModule, PrincipalConfigurationDirectiveModule } from '../../../../shared';
import { ReactiveFormCheckboxSliderModule } from '../../../../shared/components/reactive-forms-input/reactive-form-checkbox-slider/reactive-form-checkbox-slider.module';
import { CategoriesNavigationComponent } from './categories-navigation.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    NzIconModule,
    GenericLinkModule,
    NgbNavModule,
    PrincipalConfigurationDirectiveModule,
    UrlModule,
    MegamenuListItemsModule,
    ReactiveFormCheckboxSliderModule,
    NgbTooltipModule,
  ],
  declarations: [CategoriesNavigationComponent],
  exports: [CategoriesNavigationComponent],
})
export class CategoriesNavigationModule {}
