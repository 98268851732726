import { Action } from '@ngrx/store';
import { UserActions as CxUserActions } from '@spartacus/core';
import { Consent } from '../../../model/consent.model';
import { UserActions } from '../actions/index';
import { UserConsentsActionTypes } from '../actions/user-consents.action';

export const initialState: Consent[] = [];

export function reducer(state = initialState, action: Action): Consent[] {
  switch (action.type) {
    case UserConsentsActionTypes.RequiredConsentsNotGiven: {
      const _action = <UserActions.RequiredConsentsNotGiven>action;
      return _action.payload ? [..._action.payload] : [];
    }
    case CxUserActions.RESET_LOAD_USER_CONSENTS: {
      return [];
    }
    case CxUserActions.GIVE_USER_CONSENT_SUCCESS: {
      const _action = <CxUserActions.GiveUserConsentSuccess>action;
      return state.filter((consent) => _action.consentTemplate.id !== consent.templateId);
    }
  }

  return state;
}
