<div class="d-flex full-pallet-upgrade mt-2">
  <py-notification *ngIf="!isAlreadyUpdated; else successNotification" [type]="'info'" [icon]="false" class="w-100">
    <div class="content">
      <p>{{ 'cart.fullPalletUpgrade_text' | cxTranslate : { remainingQuantity: fullPalletUpgrade?.remainingQuantity } }}</p>
      <div class="d-flex actions mt-2">
        <button class="btn btn-primary btn-sm px-2" (click)="onPalletUpgrade()">
          {{ 'cart.fullPalletUpgradeAddAmount_action' | cxTranslate }}
        </button>
        <button class="ml-2 btn btn-ternary btn-sm p-2" (click)="onPalletUpgradeSkip()">
          {{ 'cart.fullPalletUpgradeNoSkip_action' | cxTranslate }}
        </button>
      </div>
    </div>
  </py-notification>

  <ng-template #successNotification>
    <py-notification [type]="'success'" [icon]="true" class="w-100 p-3">
      <div class="content">
        <p>
          {{
            'cart.fullPalletUpgradeSuccess_message' | cxTranslate : { remainingQuantity: fullPalletUpgrade?.remainingQuantity }
          }}
        </p>
      </div>
    </py-notification>
  </ng-template>
</div>
