import { Action } from '@ngrx/store';
import { PriceFileListPage } from '../../../../model/price-file.model';
import { loaderReducer } from '../../../../state/utils/loader';
import { PaginationState, SearchPageResultState } from '../../../store/base-feature-state';
import { SearchPaginationUtil } from '../../../store/util/search-pagination.util';
import { PriceFileRequestActionTypes, RequestPriceFileSuccess } from '../actions/price-file-request.actions';
import { DeletePriceFileSuccess, PriceFileActionTypes } from '../actions/price-file.actions';
import { LoadPriceFilesActionSuccess, PriceFilesActionTypes, PriceFilesActions } from '../actions/price-files.action';
import { PRICE_FILE } from '../price-file-state';
import { reducer as priceFileEntriesReducer } from './price-files.reducer';

export const initialState: PaginationState<SearchPageResultState> = {
  lastUpdateTime: undefined,
  pages: {},
  pagination: undefined,
  sorts: [],
};

export function reducer(state = initialState, action: Action): any {
  switch (action.type) {
    case PriceFilesActionTypes.LoadPriceFiles:
    case PriceFilesActionTypes.LoadPriceFilesFail: {
      const priceFileAction = <PriceFilesActions>action;
      const searchParams = priceFileAction.searchParams;
      const loaderState = loaderReducer<SearchPageResultState>(PRICE_FILE, priceFileEntriesReducer)(undefined, priceFileAction);
      return {
        ...state,
        pages: {
          ...state.pages,
          [searchParams.page]: loaderState,
        },
      };
    }
    case PriceFilesActionTypes.LoadPriceFilesSuccess: {
      const successAction = <LoadPriceFilesActionSuccess>action;
      const payload: PriceFileListPage = successAction.payload;
      const searchParams = successAction.searchParams;
      const loaderState = loaderReducer<SearchPageResultState>(PRICE_FILE, priceFileEntriesReducer)(
        { ...state.pages[searchParams.page] },
        successAction
      );
      return {
        ...state,
        lastUpdateTime: new Date().toISOString(),
        pagination: payload.pagination,
        sorts: payload.sorts,
        pages: {
          ...state.pages,
          [searchParams.page]: loaderState,
        },
      };
    }
    case PriceFileRequestActionTypes.RequestPriceFileSuccess: {
      const successAction = <RequestPriceFileSuccess>action;
      return SearchPaginationUtil.pushEntry<SearchPageResultState>(successAction.payload.code, state);
    }
    case PriceFileActionTypes.DeletePriceFileSuccess: {
      const successAction = <DeletePriceFileSuccess>action;
      const id = [].concat(successAction.meta.entityId).join();
      return SearchPaginationUtil.popEntry(id, state);
    }
    default:
      return state;
  }
}
