import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { DebugKey, Product, SolrResultEntityRef, SubstituteType } from '../../../core/model';
import { DebugFacade, PrincipalConfigurationService } from '../../../core/user';
import { prepareUrlForLink } from '../../../core/util';

@Component({
  selector: 'py-catalog-product-list-item',
  templateUrl: './catalog-product-list-item.component.html',
  styleUrls: ['./catalog-product-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogProductListItemComponent implements OnInit {
  substituteTypes: typeof SubstituteType = SubstituteType;

  enableProductOutletBadgeOnlyIfAllArticlesAreOutlet$: Observable<boolean>;
  hasSearchScore$: Observable<boolean>;

  @Input() product?: Product;
  @Input() substituteBadgeType: SubstituteType;
  @Input() isSecondaryVersion: boolean;
  @Input() queryParams: {
    [k: string]: any;
  };
  @Input() productResultRef: SolrResultEntityRef;

  getUrl(): string {
    return prepareUrlForLink(this.product?.url, this.queryParams);
  }

  constructor(
    private principalConfigurationService: PrincipalConfigurationService,
    private debugService: DebugFacade,
    private launchDialogService: LaunchDialogService
  ) {}

  ngOnInit(): void {
    this.hasSearchScore$ = this.debugService.isDebugFeatureEnabled(DebugKey.SEARCH_SCORE);
    this.enableProductOutletBadgeOnlyIfAllArticlesAreOutlet$ = this.principalConfigurationService.isEnabled(
      'enableProductOutletBadgeOnlyIfAllArticlesAreOutlet'
    );
  }

  openSearchScoreModal(): void {
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.SEARCH_SCORE, undefined, this.productResultRef);
  }
}
