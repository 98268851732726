import { Observable } from 'rxjs';
import { B2BPermissionOrderListItem, EntitiesModel, Permission, PermissionUsage, SearchConfig, ShipToList } from '../../../model';

export abstract class BuyerPermissionAdapter {
  abstract loadBuyerPermission(userId: string, permissionCode: string): Observable<Permission>;

  abstract loadBuyerPermissionsList(userId: string, params?: SearchConfig): Observable<EntitiesModel<Permission>>;

  abstract getBuyerPermissionUsage(userId: string, permissionCode: string): Observable<PermissionUsage>;

  abstract getPermissionGeneralUsage(userId: string, permissionCode: string): Observable<PermissionUsage>;

  abstract getBuyerPermissionOrders(
    userId: string,
    permissionCode: string,
    params?: SearchConfig
  ): Observable<EntitiesModel<B2BPermissionOrderListItem>>;

  abstract getBuyerPermissionShipTos(userId: string, permissionCode: string): Observable<ShipToList>;
}
