import { ViewportScroller } from '@angular/common';
import { ElementRef, Injectable } from '@angular/core';
import { Router } from '@angular/router';

// Copy and Paste from https://gitlab.com/service-work/is-loading/-/tree/master/projects/scroll-position/src/scroll-position

@Injectable({ providedIn: 'root' })
export class ScrollPositionStore extends Map<string, number> {}

@Injectable()
export class ScrollPositionService {
  constructor(private store: ScrollPositionStore, private router: Router, private viewportScroller: ViewportScroller) {}

  get(key: string | string[]) {
    return this.store.get(this.getPositionKey(key)) || 0;
  }

  save(
    key: string | string[],
    /**
     * If an `ElementRef` or `HTMLElement` is provided,
     * the `scrollTop` is extracted and saved with the provided key(s)
     */
    value: ElementRef<HTMLElement> | HTMLElement | number
  ) {
    const _value =
      value instanceof ElementRef ? value.nativeElement.scrollTop : typeof value === 'number' ? value : value.scrollTop;
    this.store.set(this.getPositionKey(key), _value || 0);
  }

  refresh(key: string | string[]) {
    this.viewportScroller.scrollToPosition([0, this.get(key)]);
  }

  private getPositionKey(base: string | string[]) {
    if (Array.isArray(base)) {
      base = base.sort().join('::');
    }

    return `${base}::` + this.router.url;
  }
}
