import { StateUtils } from '@spartacus/core';
import { PriceFileListPage } from '../../../../model/price-file.model';
import { SearchPageResultState } from '../../../store/base-feature-state';
import { PriceFilesActionTypes, PriceFilesActions } from '../actions/price-files.action';

export const initialState: SearchPageResultState = {
  lastUpdateTime: undefined,
  results: [],
};

export function reducer(state = initialState, action: PriceFilesActions | StateUtils.LoaderAction): any {
  switch (action.type) {
    case PriceFilesActionTypes.LoadPriceFilesSuccess: {
      const payload: PriceFileListPage = action.payload;

      const results = payload.results.map((file) => file.code);

      return {
        ...state,
        lastUpdateTime: new Date().toISOString(),
        results,
      };
    }
    default: {
      return state;
    }
  }
}
