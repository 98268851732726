import { Injectable, TemplateRef, Type } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BREAKPOINT, BreakpointService } from '@spartacus/storefront';
import { NzDrawerOptions, NzDrawerService } from 'ng-zorro-antd/drawer';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { RoutingHelperService } from '../../../core/routing';
import { WindowRef } from '../../../core/window';
import { WindowUtils } from '../../utils/window-utils';
import { DrawerOptions, DrawerPlacement, DrawerWidth } from './drawer-options';
import { DrawerRef } from './drawer-ref';

const defaultDrawerOptions: Partial<NzDrawerOptions> = {
  nzClosable: false,
  nzWidth: DrawerWidth.Default,
  nzZIndex: 1052,
  nzCloseOnNavigation: true, // nzCloseOnNavigation doesn't seem to work
  nzPlacement: DrawerPlacement.Default,
  nzOffsetY: 0,
  nzHeight: 'auto',
};

/**
 * A service to handle drawers
 */
@Injectable({
  providedIn: 'root',
})
export class DrawerService {
  private openDrawer: DrawerRef | null;

  constructor(
    private nzDrawerService: NzDrawerService,
    router: Router,
    private breakpointService: BreakpointService,
    private windowUtils: WindowUtils,
    private routingHelperService: RoutingHelperService,
    private windowRef: WindowRef
  ) {
    router.onSameUrlNavigation = 'reload';
    router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationStart) {
          if (this.openDrawer?.nzCloseOnNavigation) {
            this.closeOpenDrawer();
          }
        }
      },
    });
  }

  getDrawerTopOffsetY(): Observable<number | null> {
    const MOBILE_WITH_SEARCH_HEADER_HEIGHT = 128;
    const DESKTOP_OR_NO_SEARCH_HEADER_HEIGHT = 90;

    if (!this.windowRef.isBrowser()) {
      return of(null);
    }

    return combineLatest([this.routingHelperService.searchBoxVisible(), this.breakpointService.isDown(BREAKPOINT.lg)]).pipe(
      map(([searchBoxVisible, isDown]) =>
        isDown && searchBoxVisible ? MOBILE_WITH_SEARCH_HEADER_HEIGHT : DESKTOP_OR_NO_SEARCH_HEADER_HEIGHT
      ),
      map((offsetY) => {
        const headerElementTop = this.windowRef.document?.getElementById('header')?.getBoundingClientRect()?.top;

        if (headerElementTop) {
          offsetY += headerElementTop;
        }

        return offsetY;
      })
    );
  }

  isDrawerOpen(template: TemplateRef<any> | Type<any>) {
    return template === this.openDrawer?.nzContent;
  }

  /**
   * Opens a drawer if there is no drawer currently opening
   */
  open(options: DrawerOptions): Observable<DrawerRef | null> {
    if (this.isDrawerOpen(options?.template)) {
      this.closeOpenDrawer();
      return of(null);
    }

    this.closeOpenDrawer();
    return this.getDrawerTopOffsetY().pipe(
      map((offsetY) => {
        if (options.placement === DrawerPlacement.Top) {
          options.offsetY = offsetY;
          options.zIndex = 9; //The header is 10, and we need it to be underneath the header
        }

        const newDrawer = this.nzDrawerService.create({
          ...defaultDrawerOptions,
          nzContent: options.template,
          nzContentParams: options.params,
          nzWidth: options.width ?? defaultDrawerOptions.nzWidth,
          nzCloseOnNavigation:
            options.closeOnNavigation !== undefined ? options.closeOnNavigation : defaultDrawerOptions.nzCloseOnNavigation,
          nzPlacement: options.placement ?? defaultDrawerOptions.nzPlacement,
          nzWrapClassName: options.wrapClassName,
          nzHeight: options.height ?? defaultDrawerOptions.nzHeight,
          nzOffsetY: options.offsetY ?? defaultDrawerOptions.nzOffsetY,
          nzZIndex: options.zIndex ?? defaultDrawerOptions.nzZIndex,
          nzBodyStyle: { '--py-header-offset': `${offsetY}px` },
        });
        this.openDrawer = newDrawer;
        this.lockBody();

        this.handleDrawerEvents(newDrawer);
        return newDrawer;
      })
    );
  }

  unlockBody(): void {
    const bodyTag = this.windowUtils.getElement('body') as HTMLElement;

    if (bodyTag) {
      this.windowUtils.removeClassPreserveWidth(bodyTag, 'locked');
    }
  }

  lockBody(): void {
    const bodyTag = this.windowUtils.getElement('body') as HTMLElement;

    if (bodyTag) {
      this.windowUtils.addClassPreserveWidth(bodyTag, 'locked');
    }
  }

  private handleDrawerEvents(drawer: DrawerRef): void {
    drawer.afterClose
      .pipe(
        filter(() => this.isDrawerOpen(drawer?.nzContent)),
        take(1)
      )
      .subscribe(() => {
        this.openDrawer = null;
        this.unlockBody();
      });

    drawer.afterOpen
      .pipe(
        filter(() => !this.openDrawer),
        take(1)
      )
      .subscribe(() => (this.openDrawer = drawer));
  }

  closeOpenDrawer(): void {
    this.openDrawer?.close();
  }

  getOpenDrawer(): DrawerRef {
    return this.openDrawer;
  }
}
