import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@spartacus/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { exhaustMap, filter, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { LAUNCH_CALLER, LaunchDialogService, ModalCloseReason } from '../../../core/modal';
import { RoutingHelperService } from '../../../core/routing';
import { CheckoutFacade, CheckoutProcessingFacade } from '../base';

@Injectable({
  providedIn: 'root',
})
export class ActiveOrderProcessService implements OnDestroy {
  canShowActiveOrderProcessModal$: Observable<boolean>;
  subscriptions = new Subscription();

  constructor(
    private launchDialogService: LaunchDialogService,
    private checkoutService: CheckoutFacade,
    private checkoutProcessingService: CheckoutProcessingFacade,
    private routingHelperService: RoutingHelperService,
    private authService: AuthService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  init(): void {
    this.canShowActiveOrderProcessModal$ = this.routingHelperService.canShowActiveOrderProcessModal();

    this.subscriptions.add(
      this.authService
        .isUserLoggedIn()
        .pipe(
          filter(Boolean),
          take(1),
          switchMap(() =>
            combineLatest([this.checkoutService.hasActiveOrderProcess(), this.canShowActiveOrderProcessModal$]).pipe(
              tap(([hasActiveOrderProcess, _canShowActiveOrderProcessModal]) => {
                if (!hasActiveOrderProcess) {
                  this.checkoutProcessingService.dismissActiveOrderProcessModalIfExists();
                }
              }),
              filter(
                ([hasActiveOrderProcess, canShowActiveOrderProcessModal]) =>
                  hasActiveOrderProcess && canShowActiveOrderProcessModal
              ),
              exhaustMap(() => {
                this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.ACTIVE_ORDER_PROCESS);

                return this.launchDialogService.dialogClose.pipe(
                  filter((reason) => reason !== undefined),
                  filter(
                    (reason) =>
                      reason === ModalCloseReason.ACTIVE_ORDER_PROCESS_PROCEEDED ||
                      reason === ModalCloseReason.ACTIVE_ORDER_PROCESS_CANCELLED ||
                      reason === ModalCloseReason.ACTIVE_ORDER_PROCESS_DISMISSED_AUTOMATICALLY
                  ),
                  take(1),
                  // Active order data is cleared from store when language changes. This happens after modal is opened on page refresh.
                  // So here once the modal is closed, we get this data again (it will fetch it if it's not there)
                  withLatestFrom(this.checkoutService.getActiveOrderProcess()),
                  take(1)
                );
              })
            )
          )
        )
        .subscribe(([_reason, _order]) => {
          // actions are handled inside the modal itself
        })
    );
  }
}
