import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ArticleInfoAttributesComponent } from './article-info-attributes.component';

@NgModule({
  declarations: [ArticleInfoAttributesComponent],
  imports: [CommonModule, NgbTooltipModule],
  exports: [ArticleInfoAttributesComponent],
})
export class ArticleInfoAttributesModule {}
