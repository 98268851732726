import { Action } from '@ngrx/store';
import { EntriesState } from '../../../features/store/base-feature-state';
import { AccountingCode, AccountingCodePage } from '../../../model';
import { UserActions } from '../actions';
import { AccountingCodesAdminActionTypes } from '../actions/accounting-codes-admin.action';

export const initialState: EntriesState<AccountingCode> = {};

export function reducer(state = initialState, action: Action): EntriesState<AccountingCode> {
  switch (action.type) {
    case AccountingCodesAdminActionTypes.SearchAccountingCodesAdminSuccess: {
      const _action = <UserActions.SearchAccountingCodesAdminSuccess>action;
      const payload: AccountingCodePage = _action.payload.searchResult;
      const results = payload.results;
      return results.reduce(
        (entities: { [id: number]: AccountingCode }, accountingCode: AccountingCode) => {
          return {
            ...entities,
            [accountingCode.code]: accountingCode,
          };
        },
        {
          ...state,
        }
      );
    }
    case AccountingCodesAdminActionTypes.CreateAccountingCodeAdminSuccess: {
      const _action = <UserActions.CreateAccountingCodeAdminSuccess>action;
      const accountingCode: AccountingCode = _action.payload.accountingCode;
      return {
        ...state,
        [accountingCode.code]: accountingCode,
      };
    }
    case AccountingCodesAdminActionTypes.UpdateAccountingCodeAdminSuccess: {
      const _action = <UserActions.UpdateAccountingCodeAdminSuccess>action;
      const accountingCode: AccountingCode = _action.payload.accountingCode;
      return {
        ...state,
        [accountingCode.code]: accountingCode,
      };
    }
    case AccountingCodesAdminActionTypes.DeleteAccountingCodeAdminSuccess: {
      const _action = <UserActions.DeleteAccountingCodeAdminSuccess>action;
      const code = _action.payload.code;
      const newState = { ...state };
      delete newState[code];
      return newState;
    }
    default:
      return state;
  }
}
