import { Injectable } from '@angular/core';
import { BASE_SITE_CONTEXT_ID, CURRENCY_CONTEXT_ID, SiteContextConfig, StatePersistenceService } from '@spartacus/core';
import { Observable, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { CurrencyService } from '../facade/currency.service';
import { SiteContextParamsService } from './site-context-params.service';

@Injectable({ providedIn: 'root' })
export class CurrencyStatePersistenceService {
  constructor(
    protected statePersistenceService: StatePersistenceService,
    protected currencyService: CurrencyService,
    protected config: SiteContextConfig,
    protected siteContextParamsService: SiteContextParamsService
  ) {}

  protected initialized$ = new ReplaySubject<void>(1);

  public initSync(): Observable<unknown> {
    this.statePersistenceService.syncWithStorage({
      key: CURRENCY_CONTEXT_ID,
      state$: this.currencyService.getActive(),
      context$: this.siteContextParamsService.getValues([BASE_SITE_CONTEXT_ID]),
      onRead: (state) => this.onRead(state),
    });
    return this.initialized$;
  }

  protected onRead(valueFromStorage: string): void {
    this.currencyService
      .isInitialized$()
      .pipe(take(1))
      .subscribe((isInitialized) => {
        if (!isInitialized) {
          this.currencyService.setActive(
            valueFromStorage || this.siteContextParamsService.getParamDefaultValue(CURRENCY_CONTEXT_ID)
          );
        }

        if (!this.initialized$.closed) {
          this.initialized$.next();
          this.initialized$.complete();
        }
      });
  }
}
