<ng-container *ngIf="!loading">
  <ng-container *ngIf="hasImages; else placeholder">
    <ul class="images">
      <li *ngFor="let image of images" (click)="openImage()">
        <img [pyMedia]="image" [alt]="altText" />
      </li>
    </ul>
  </ng-container>

  <ng-template #placeholder>
    <img pyMedia />
  </ng-template>
</ng-container>
