<div>
  <py-header-navigation-item [active]="show" (click)="toggleDrawer()"
    >{{ title }}<i class="ml-2" [class.rotate]="show" nz-icon nzType="down"></i
  ></py-header-navigation-item>

  <ng-template
    #drawerTemplate
    *ngIf="{ activeCategoryCode: activeCategoryCode$ | async, activeAddonGroupId: activeAddonGroupId$ | async } as observables"
  >
    <div
      #megamenuContainer
      py-megamenu
      [templateColumnLeft]="megamenuColumnLeft"
      [templateColumnRight]="megamenuColumnRight"
    ></div>

    <ng-template #megamenuColumnLeft>
      <ul
        py-categories-navigation
        [activeCategoryCode]="observables.activeCategoryCode"
        [showMyAssortmentSubmenu]="showMyAssortmentSubmenu"
        [showCampaignsSubMenu]="showCampaignsSubMenu"
        (setActiveCategoryCode)="setActiveCategoryCode($event)"
        (setShowMyAssortmentSubmenu)="showMyAssortmentSubmenu = $event"
        (setShowCampaignsSubMenu)="showCampaignsSubMenu = $event"
        (setActiveAddonGroup)="setActiveAddonGroupId($event)"
        [activeAddonGroupId]="observables.activeAddonGroupId"
      ></ul>
    </ng-template>

    <ng-template #megamenuColumnRight>
      <!--CATEGORIES-->
      <ng-container *ngIf="!showCampaignsSubMenu && !showMyAssortmentSubmenu && !observables.activeAddonGroupId">
        <ul py-sub-categories-navigation [activeCategoryCode]="observables.activeCategoryCode" [mya]="mya"></ul>
      </ng-container>

      <!--ASSORTMENT-->
      <ng-container *ngIf="showMyAssortmentSubmenu">
        <ul py-my-assortment-sub-navigation [mya]="mya"></ul>
      </ng-container>

      <!--ADDON-->
      <ng-container *ngIf="observables.activeAddonGroupId">
        <ul py-sub-addon-group-navigation [addonGroupId]="observables.activeAddonGroupId"></ul>
      </ng-container>

      <!--CAMPAIGN-->
      <ng-container *ngIf="showCampaignsSubMenu">
        <ul py-campaigns-sub-navigation [mya]="mya"></ul>
      </ng-container>
    </ng-template>
  </ng-template>
</div>
