import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ChangeSoldToActionTypes } from '../../../../../features/sold-to-base/store/actions/sold-to-change.action';
import { LoginLogoutActionTypes } from '../../../../auth/user-auth/store/actions/login-logout.action';
import { normalizeHttpError, withdrawOn } from '../../../../util';
import { PriceCampaignConnector } from '../../connector/price-campaign.connector';
import { PriceCampaignActions } from '../actions';
import { PriceCampaignActionTypes } from '../actions/price-campaign.action';

@Injectable()
export class PriceCampaignEffects {
  private contextChange$ = this.actions$.pipe(ofType(ChangeSoldToActionTypes.ChangeSoldToSuccess));

  loadPriceCampaignDetails$: Observable<
    PriceCampaignActions.LoadPriceCampaignDetailsSuccess | PriceCampaignActions.LoadPriceCampaignDetailsFail
  > = createEffect(() =>
    this.actions$.pipe(
      ofType<PriceCampaignActions.LoadPriceCampaignDetails>(PriceCampaignActionTypes.LoadPriceCampaignDetails),
      mergeMap((action) =>
        this.priceCampaignConnector.loadPriceCampaignDetails(action.code).pipe(
          map((campaign) => new PriceCampaignActions.LoadPriceCampaignDetailsSuccess(campaign)),
          catchError((error) => of(new PriceCampaignActions.LoadPriceCampaignDetailsFail(action.code, normalizeHttpError(error))))
        )
      ),
      withdrawOn(this.contextChange$)
    )
  );

  loadAvailablePriceCampaigns$: Observable<
    PriceCampaignActions.LoadAvailablePriceCampaignsSuccess | PriceCampaignActions.LoadAvailablePriceCampaignsFail
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(PriceCampaignActions.PriceCampaignActionTypes.LoadAvailablePriceCampaigns),
      switchMap((_) => {
        return this.priceCampaignConnector.loadAvailablePriceCampaigns().pipe(
          map((data) => new PriceCampaignActions.LoadAvailablePriceCampaignsSuccess(data)),
          catchError((error) => of(new PriceCampaignActions.LoadAvailablePriceCampaignsFail(normalizeHttpError(error))))
        );
      }),
      withdrawOn(this.contextChange$)
    )
  );

  clearPriceCampaignsStateOnLoginLogout$: Observable<PriceCampaignActions.ClearPriceCampaigns> = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginLogoutActionTypes.Login, LoginLogoutActionTypes.Logout),
      map(() => new PriceCampaignActions.ClearPriceCampaigns())
    )
  );

  constructor(private actions$: Actions, private priceCampaignConnector: PriceCampaignConnector) {}
}
