import { MemoizedSelector, MemoizedSelectorWithProps, createFeatureSelector, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { SortModel } from '../../../model/misc.model';
import { SolrSearchResult } from '../../../model/solr-search-config';
import { PyStateUtils } from '../../../state';
import { SOLR_SEARCH_FEATURE, SolrSearchState, StateWithSolrSearch, TotalCountState } from '../solr-search-state';

export const getSolrSearchState: MemoizedSelector<StateWithSolrSearch, SolrSearchState> =
  createFeatureSelector<SolrSearchState>(SOLR_SEARCH_FEATURE);

export const getTotalCountState: MemoizedSelectorWithProps<StateWithSolrSearch, any, TotalCountState> = createSelector(
  getSolrSearchState,
  (state: SolrSearchState, props: any) => state.search[props.searchQuery]?.totalCount
);

export const getArticleTotalCount: MemoizedSelectorWithProps<StateWithSolrSearch, any, number> = createSelector(
  getTotalCountState,
  (totalCountState: TotalCountState) => totalCountState?.articles
);

export const getProductTotalCount: MemoizedSelectorWithProps<StateWithSolrSearch, any, number> = createSelector(
  getTotalCountState,
  (totalCountState: TotalCountState) => totalCountState?.products
);

export const getDefaultSearchLoaderState: MemoizedSelectorWithProps<
  StateWithSolrSearch,
  any,
  StateUtils.LoaderState<SolrSearchResult>
> = createSelector(
  getSolrSearchState,
  (state: SolrSearchState, props: any) => state.search[props.searchQuery]?.default || PyStateUtils.initialLoaderState
);

export const getDefaultSearch: MemoizedSelectorWithProps<StateWithSolrSearch, any, SolrSearchResult> = createSelector(
  getDefaultSearchLoaderState,
  (state: StateUtils.LoaderState<SolrSearchResult>) => StateUtils.loaderValueSelector(state) || {}
);

export const getDefaultSearchLoading: MemoizedSelectorWithProps<StateWithSolrSearch, any, boolean> = createSelector(
  getDefaultSearchLoaderState,
  (state: StateUtils.LoaderState<SolrSearchResult>) => StateUtils.loaderLoadingSelector(state) || false
);

export const getDefaultSearchLoaded: MemoizedSelectorWithProps<StateWithSolrSearch, any, boolean> = createSelector(
  getDefaultSearchLoaderState,
  (state: StateUtils.LoaderState<SolrSearchResult>) =>
    StateUtils.loaderSuccessSelector(state) && !StateUtils.loaderLoadingSelector(state)
);

export const getArticleSearchLoaderState: MemoizedSelectorWithProps<
  StateWithSolrSearch,
  any,
  StateUtils.LoaderState<SolrSearchResult>
> = createSelector(
  getSolrSearchState,
  (state: SolrSearchState, props: any) => state.search[props.searchQuery]?.article || PyStateUtils.initialLoaderState
);

export const getArticleSearch: MemoizedSelectorWithProps<StateWithSolrSearch, any, SolrSearchResult> = createSelector(
  getArticleSearchLoaderState,
  (state: StateUtils.LoaderState<SolrSearchResult>) => StateUtils.loaderValueSelector(state) || {}
);

export const getArticleSearchLoading: MemoizedSelectorWithProps<StateWithSolrSearch, any, boolean> = createSelector(
  getArticleSearchLoaderState,
  (state: StateUtils.LoaderState<SolrSearchResult>) => StateUtils.loaderLoadingSelector(state) || false
);

export const getArticleSearchLoaded: MemoizedSelectorWithProps<StateWithSolrSearch, any, boolean> = createSelector(
  getArticleSearchLoaderState,
  (state: StateUtils.LoaderState<SolrSearchResult>) =>
    StateUtils.loaderSuccessSelector(state) && !StateUtils.loaderLoadingSelector(state)
);

export const getProductSearchLoaderState: MemoizedSelectorWithProps<
  StateWithSolrSearch,
  any,
  StateUtils.LoaderState<SolrSearchResult>
> = createSelector(
  getSolrSearchState,
  (state: SolrSearchState, props: any) => state.search[props.searchQuery]?.product || PyStateUtils.initialLoaderState
);

export const getProductSearch: MemoizedSelectorWithProps<StateWithSolrSearch, any, SolrSearchResult> = createSelector(
  getProductSearchLoaderState,
  (state: StateUtils.LoaderState<SolrSearchResult>) => StateUtils.loaderValueSelector(state) || {}
);

export const getProductSearchLoading: MemoizedSelectorWithProps<StateWithSolrSearch, any, boolean> = createSelector(
  getProductSearchLoaderState,
  (state: StateUtils.LoaderState<SolrSearchResult>) => {
    return StateUtils.loaderLoadingSelector(state) || false;
  }
);

export const getProductSearchLoaded: MemoizedSelectorWithProps<StateWithSolrSearch, any, boolean> = createSelector(
  getProductSearchLoaderState,
  (state: StateUtils.LoaderState<SolrSearchResult>) =>
    StateUtils.loaderSuccessSelector(state) && !StateUtils.loaderLoadingSelector(state)
);

export const getSuggestions: MemoizedSelector<StateWithSolrSearch, SolrSearchResult> = createSelector(
  getSolrSearchState,
  (state: SolrSearchState) => state && state.suggestions
);

export const getArticleSorts: MemoizedSelectorWithProps<StateWithSolrSearch, any, SortModel[]> = createSelector(
  getArticleSearchLoaderState,
  (state: StateUtils.LoaderState<SolrSearchResult>) => StateUtils.loaderValueSelector(state)?.sorts
);

export const getProductSorts: MemoizedSelectorWithProps<StateWithSolrSearch, any, SortModel[]> = createSelector(
  getProductSearchLoaderState,
  (state: StateUtils.LoaderState<SolrSearchResult>) => StateUtils.loaderValueSelector(state)?.sorts
);
