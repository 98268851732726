import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ReinvoicingFieldTypes } from '../../../core/model';

export enum InformationTabType {
  INFORMATION = 'INFORMATION',
  NOTES = 'NOTES',
  PAYMENT = 'PAYMENT',
}

export interface InformationTabField {
  name?: string;
  value?: string | any;
}

export interface InformationTabContent {
  tabType?: InformationTabType;
  fields?: { [id: string]: any };
}

@Component({
  selector: 'py-information-tab',
  templateUrl: './information-tab.component.html',
  styleUrls: ['./information-tab.component.scss'],
})
export class InformationTabComponent {
  @Input() loading = false;
  @Input() tabsContent: InformationTabContent[];

  @Input() translationKeyPrefix: string;

  showDeliverySection: boolean = true;
  showNotesSection: boolean = true;
  showPaymentSection: boolean = true;

  reinvoicingFieldTypes: typeof ReinvoicingFieldTypes = ReinvoicingFieldTypes;

  constructor(private cd: ChangeDetectorRef) {}

  get hasDeliveryTab(): boolean {
    return this.tabsContent.filter((tab) => tab.tabType === InformationTabType.INFORMATION).length > 0;
  }

  get deliveryTab(): InformationTabContent {
    return this.tabsContent.find((tab) => tab.tabType === InformationTabType.INFORMATION);
  }

  get hasNotesTab(): boolean {
    return this.tabsContent.filter((tab) => tab.tabType === InformationTabType.NOTES).length > 0;
  }

  get notesTab(): InformationTabContent {
    return this.tabsContent.find((tab) => tab.tabType === InformationTabType.NOTES);
  }

  get hasPaymentTab(): boolean {
    return this.tabsContent.filter((tab) => tab.tabType === InformationTabType.PAYMENT).length > 0;
  }

  get paymentTab(): InformationTabContent {
    return this.tabsContent.find((tab) => tab.tabType === InformationTabType.PAYMENT);
  }

  onShowDeliveryToggle(): void {
    this.showDeliverySection = !this.showDeliverySection;
    this.cd.markForCheck();
  }

  onShowNotesToggle(): void {
    this.showNotesSection = !this.showNotesSection;
    this.cd.markForCheck();
  }

  onShowPaymentToggle(): void {
    this.showPaymentSection = !this.showPaymentSection;
    this.cd.markForCheck();
  }
}
