<ng-template [cxOutlet]="position" [cxOutletContext]="{ components$: components$ }">
  <ng-template
    *ngFor="let component of components"
    [cxOutlet]="component.flexType"
    [cxOutletContext]="{ component: component }"
    [cxOutletDefer]="getComponentDeferOptions(component.flexType)"
    (loaded)="isLoaded($event)"
  >
    <ng-container [cxComponentWrapper]="component"></ng-container>
  </ng-template>
</ng-template>
