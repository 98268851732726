import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { PrincipalConfiguration } from '../../core/model';
import { PrincipalConfigurationService } from '../../core/user';

@Pipe({ name: 'principalConfigValue', pure: false })
export class PrincipalConfigValuePipe implements PipeTransform, OnDestroy {
  private value: string | number | boolean | null;
  private subscription = new Subscription();

  constructor(private principalConfigurationService: PrincipalConfigurationService) {}

  transform(principalConfigurationCode: string): string | number | boolean | null {
    if (!principalConfigurationCode) {
      return null;
    }

    this.subscription.add(
      this.principalConfigurationService
        .getValue(principalConfigurationCode)
        .pipe(map((config: PrincipalConfiguration) => config?.value))
        .subscribe((value) => {
          if (!!value) {
            this.value = value;
          }
        })
    );

    if (!!this.value) {
      return this.value;
    }

    return null;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
