<div ngbDropdown #myDrop="ngbDropdown" (openChange)="onOpenChange($event)" [container]="container" [placement]="placement">
  <div class="overlay d-lg-none" [class.show]="!isCollapsed"></div>

  <button
    type="button"
    [attr.id]="id"
    ngbDropdownToggle
    class="form-control"
    [class.show-active-item]="showActiveItem"
    [disabled]="disabled || readonly"
  >
    <span class="label mr-2" [class.placeholder]="showActiveItem && !activeItem?.label">
      <span
        class="d-flex align-items-center"
        *ngIf="showActiveItem && ((activeItem && activeItem?.label !== ALL) || hasInvisibleActiveItems); else titleTemplate"
      >
        <ng-container *ngIf="activeItem">
          <span class="text-clip">{{ activeItem?.label || placeholder }}</span>
          <span *ngIf="showCountBadge && multi && valueLength > 1" class="badge badge-count badge-pill p-2 ml-1 font-weight-bold">
            +{{ valueLength - 1 }}
          </span>
        </ng-container>
        <ng-container *ngIf="hasInvisibleActiveItems">
          <span class="text-clip">{{ 'common.selectedItems_hint' | cxTranslate }}</span>
          <span class="badge badge-count badge-pill p-2 ml-1 font-weight-bold">{{ valueLength }}</span>
        </ng-container>
      </span>

      <ng-template #titleTemplate>
        <span class="title">{{ title }}</span>
      </ng-template>

      <ng-content></ng-content>
    </span>
    <py-badge *ngIf="badge" [tag]="true" [ngbTooltip]="badgeTooltip">{{ badge }}</py-badge>
    <i
      nz-icon
      nzType="{{ icon ? icon : isCollapsed ? collapsedDirection.DOWN : collapsedDirection.UP }}"
      nzTheme="outline"
      [class.ml-2]="showActiveItem"
      [class.mr-2]="!showActiveItem"
    ></i>
  </button>

  <div
    ngbDropdownMenu
    [attr.aria-labelledby]="id"
    class="py-0"
    infiniteScroll
    (scrolled)="onScrolled()"
    [scrollWindow]="false"
    [class.dropdown-menu-multicolumn]="additionalColumns > 0"
  >
    <py-reactive-form-input-field
      *ngIf="searchable"
      [group]="form"
      [fieldName]="'searchInput'"
      [placeholder]="filterPlaceholder"
      [icon]="'search'"
      [resettable]="true"
      (handleReset)="onResetSearchInput()"
      class="search-input"
    ></py-reactive-form-input-field>

    <div class="d-lg-none header">
      {{ showTitleForMobile ? (title ? title : activeItem?.label) : activeItem?.label || title || placeholder }}
      <button class="btn btn-icon text-black" type="button" (click)="myDrop.close()">
        <i nz-icon nzType="close" nzTheme="outline"></i>
      </button>
    </div>

    <button *ngIf="resetable && (options?.length > 1 || searchable)" class="reset" (click)="resetValue.emit(); myDrop.close()">
      {{ 'common.clearAll_action' | cxTranslate }}
    </button>

    <table>
      <tbody>
        <tr
          *ngFor="let option of options"
          [ngClass]="{ 'active-option': activeItem?.value === option.value, disabled: option.disabled }"
          (click)="
            !option.disabled && (!multi ? updateValue(option) : updateMultiValue(option));
            !option.disabled && !multi && myDrop.close()
          "
        >
          <td class="checkbox-cell">
            <py-radio *ngIf="!multi">
              <input type="radio" [checked]="activeItem?.value === option.value" [disabled]="option.disabled" />
            </py-radio>
            <py-checkbox *ngIf="multi">
              <input type="checkbox" [checked]="isActive(option.value)" [disabled]="option.disabled" />
            </py-checkbox>
          </td>
          <td class="users-section">
            <div *ngIf="option.label !== ALL" class="d-flex align-items-center">
              <i
                class="d-flex align-items-center"
                nz-icon
                [nzType]="option.disabled ? 'user-profile-inactive' : 'user-profile'"
              ></i>
              <div class="d-inline-block ml-2">
                <div class="label">{{ option.label }}</div>
                <div class="value">{{ option.email }}</div>
              </div>
            </div>
            <div *ngIf="option.label === ALL" class="d-flex align-items-center">
              <span class="label all">{{ 'common.all_hint' | cxTranslate }}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="py-2 text-center spinner-wrapper" *ngIf="loading">
      <cx-spinner></cx-spinner>
    </div>

    <div *ngIf="searchable && !loading && options.length === 0" class="p-2 text-center">
      {{ 'common.noResultsFound_hint' | cxTranslate }}
    </div>
  </div>
</div>
