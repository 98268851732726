import { AddonGroupEffects } from '../../addon/store/effects/addon-group.effect';
import { AddonNodeEffects } from '../../addon/store/effects/addon-node.effect';
import { AreaOfUseEffects } from '../../area-of-use/store/effects/area-of-use.effect';
import { ArticleEffects } from '../../article/store/effects/article.effect';
import { CategoryEffects } from '../../category/store/effects/category.effect';
import { NonSellableProductEffects } from '../../non-sellable-product/store/effects/non-sellable-product.effect';
import { PriceCampaignEffects } from '../../price-campaign/store/effects/price-campaign.effect';
import { ProductEffects } from '../../product/store/effects/product.effect';
import { CatalogEffects } from './catalog-keys.effect';

export const effects: any[] = [
  AreaOfUseEffects,
  ArticleEffects,
  ProductEffects,
  CategoryEffects,
  CatalogEffects,
  AddonGroupEffects,
  AddonNodeEffects,
  PriceCampaignEffects,
  NonSellableProductEffects,
];

export * from '../../addon/store/effects/addon-group.effect';
export * from '../../addon/store/effects/addon-node.effect';
export * from '../../area-of-use/store/effects/area-of-use.effect';
export * from '../../article/store/effects/article.effect';
export * from '../../category/store/effects/category.effect';
export * from '../../price-campaign/store/effects/price-campaign.effect';
export * from '../../product/store/effects/product.effect';
