<div class="container">
  <div class="row">
    <div class="col-12">
      <h4 class="banner-title">{{ 'anonymousConsents.banner_title' | cxTranslate }}</h4>
    </div>
  </div>
  <div class="row mt-2 mb-6">
    <div class="col-12">
      <p class="banner-description">
        {{ 'anonymousConsents.banner_text' | cxTranslate }}
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-12 d-flex justify-content-end flex-column-reverse flex-md-row">
      <button class="btn btn-plain-secondary mr-0 mr-md-2" (click)="onViewSettings()">
        {{ 'anonymousConsents.viewDetails_action' | cxTranslate }}
      </button>

      <button class="btn btn-secondary mb-2 mb-md-0" (click)="onAllowAll()">
        {{ 'anonymousConsents.allowAll_action' | cxTranslate }}
      </button>
    </div>
  </div>
</div>
