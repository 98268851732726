import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PreventDoubleClickDirective } from './prevent-double-click.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [PreventDoubleClickDirective],
  exports: [PreventDoubleClickDirective],
})
export class PreventDoubleClickModule {}
