import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RadioModule } from '../radio/radio.module';
import { SelectableOptionComponent } from './selectable-option.component';

@NgModule({
  imports: [CommonModule, RadioModule],
  declarations: [SelectableOptionComponent],
  exports: [SelectableOptionComponent],
})
export class SelectableOptionModule {}
