import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { AuthConfigService, AuthService, OAuthFlow } from '@spartacus/core';
import { CmsPageGuard } from '@spartacus/storefront';
import { EMPTY, Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

/**
 * Guards the _login_ route.
 *
 * Takes care of routing the user to a auth server login page (if implicit or code flow is used).
 * In case of Resource Owner Password Flow just renders the page as normal CMS page.
 */
@Injectable({
  providedIn: 'root',
})
export class LoginGuard {
  constructor(
    protected authService: AuthService,
    protected authConfigService: AuthConfigService,
    protected csAgentAuthService: CsAgentAuthService,
    protected cmsPageGuard: CmsPageGuard,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.isUserLoggedIn().pipe(
      take(1),
      switchMap((isUserLoggedIn) => {
        if (this.authConfigService.getOAuthFlow() === OAuthFlow.ResourceOwnerPasswordFlow || isUserLoggedIn) {
          return this.csAgentAuthService.isCustomerSupportAgentLoggedIn().pipe(take(1), switchMap(this.getNext(route, state)));
        } else {
          // This method can trigger redirect to OAuth server that's why we don't return anything in this case
          const redirected = this.authService.loginWithRedirect();
          if (!redirected) {
            return of(false);
          }
          return EMPTY;
        }
      })
    );
  }

  private getNext(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return (isAsm) => (isAsm ? of(this.router.parseUrl('/')) : this.cmsPageGuard.canActivate(route, state));
  }
}
