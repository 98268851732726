import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { UsersBaseModule } from './base/users-base.module';

@NgModule({
  imports: [UsersBaseModule],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        users: {
          module: () => import('./containers/cms-users.module').then((m) => m.CmsUsersModule),
          dependencies: [
            () =>
              import('../../../features/purchase-limits-base/purchase-limits-base.module').then(
                (m) => m.PurchaseLimitsBaseModule
              ),
          ],
          cmsComponents: ['CmsUsersComponent'],
        },
      },
    }),
  ],
})
export class UsersFeatureModule {}
