import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        areaOfUseAdmin: {
          module: () => import('./containers/cms-area-of-use-admin.module').then((m) => m.CmsAreaOfUseAdminModule),
          dependencies: [
            () => import('../../area-of-use-admin-base/area-of-use-admin-base.module').then((m) => m.AreaOfUseAdminBaseModule),
          ],
          cmsComponents: ['CmsAreaOfUseAdminComponent'],
        },
      },
    }),
  ],
})
export class AreaOfUseAdminFeatureModule {}
