import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OCC_USER_ID_ANONYMOUS, withdrawOn } from '@spartacus/core';
import { Observable, merge, of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap } from 'rxjs/operators';
import { LoginLogoutActionTypes } from '../../../../auth/user-auth';
import { bufferUntilTimeWithLimit, normalizeHttpError } from '../../../../util';
import { ArticleConnector } from '../../connector/article-connector';
import { ArticleActions } from '../actions';
import { ArticleActionTypes } from '../actions/article.action';

@Injectable()
export class ArticleEffects {
  // private contextChange$ = this.actions$.pipe(
  //   ofType(
  //     RouterActionTypes.Go,
  //     RouterActionTypes.Back,
  //     RouterActionTypes.Forward,
  //     RouterActionTypes.GoByUrl,
  //     LanguagesActionTypes.LanguageChange,
  //     ChangeSoldToActionTypes.ChangeSoldToSuccess
  //   )
  // );
  private contextChange$ = this.actions$.pipe(ofType(LoginLogoutActionTypes.Logout));

  loadArticle$: Observable<ArticleActions.LoadArticleSuccess | ArticleActions.LoadArticleFail> = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticleActionTypes.LoadArticle),
      map((action: ArticleActions.LoadArticle) => ({
        code: action.payload,
        userId: action.userId,
      })),
      bufferUntilTimeWithLimit(10, 200),
      filter((inputs: Array<any>) => inputs.length > 0),
      concatMap((inputs: Array<any>) =>
        this.articleConnector
          .loadArticles(
            inputs[0]?.userId,
            inputs.map((a) => a.code)
          )
          .pipe(
            mergeMap((articles: Array<any>) => {
              return inputs.map((i) => {
                const a = articles.find((article) => article.code === i.code);
                if (a) {
                  return new ArticleActions.LoadArticleSuccess(a);
                } else {
                  return new ArticleActions.LoadArticleFail(i.code, undefined);
                }
              });
            }),
            catchError((error) => {
              return merge(...inputs.map((i) => of(new ArticleActions.LoadArticleFail(i.code, normalizeHttpError(error)))));
            })
          )
      )
      // TODO: ESVCX-1472 If withdrawOn should happen, then this must clear/reset products in 'inputs'
      //    with loading state since this aborts in progress downloads.
      //withdrawOn(this.contextChange$),
    )
  );

  loadSimilarArticles$: Observable<ArticleActions.LoadSimilarArticlesSuccess | ArticleActions.LoadSimilarArticlesFail> =
    createEffect(() =>
      this.actions$.pipe(
        ofType<ArticleActions.LoadSimilarArticles>(ArticleActionTypes.LoadSimilarArticles),
        filter(({ userId }) => userId !== OCC_USER_ID_ANONYMOUS),
        mergeMap((action) =>
          this.articleConnector.loadSimilarArticles(action.userId, action.code).pipe(
            map((alternativeArticles) => new ArticleActions.LoadSimilarArticlesSuccess(alternativeArticles)),
            catchError((error) => {
              return of(new ArticleActions.LoadSimilarArticlesFail(action.code, normalizeHttpError(error)));
            })
          )
        ),
        withdrawOn(this.contextChange$)
      )
    );

  loadSubstitutesArticles$: Observable<
    ArticleActions.LoadSubstitutesArticlesSuccess | ArticleActions.LoadSubstitutesArticlesFail
  > = createEffect(() =>
    this.actions$.pipe(
      ofType<ArticleActions.LoadSubstitutesArticles>(ArticleActionTypes.LoadSubstitutesArticles),
      mergeMap((action) =>
        this.articleConnector.loadSubstitutesArticles(action.userId, action.code).pipe(
          map((substitutes) => new ArticleActions.LoadSubstitutesArticlesSuccess(substitutes)),
          catchError((error) => {
            return of(new ArticleActions.LoadSubstitutesArticlesFail(action.code, normalizeHttpError(error)));
          })
        )
      ),
      withdrawOn(this.contextChange$)
    )
  );

  constructor(private actions$: Actions, private articleConnector: ArticleConnector) {}
}
