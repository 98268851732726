import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserIdService } from '../../auth';
import { CMSLinkComponents } from '../../model';
import { CmsLinkComponentsConnector } from '../connectors';

@Injectable({ providedIn: 'root' })
export class CmsLinkComponentsService {
  constructor(private cmslinkComponentsConnector: CmsLinkComponentsConnector, private userIdService: UserIdService) {}

  getLinksByProduct(code: string): Observable<CMSLinkComponents[]> {
    return this.userIdService
      .takeUserId()
      .pipe(switchMap((userId) => this.cmslinkComponentsConnector.getLinksByProduct(userId, code)));
  }
}
