import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { AlertType } from '../../../core/model';

@Component({
  selector: 'py-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent {
  @Input() type: AlertType = AlertType.Info;
  @Input() message?: string;
  @Input() icon = true;
  @Input() themeType: string = 'fill';
  @Input() customIcon?: string;

  @HostBinding('class.bordered')
  @Input()
  showBorder = true;

  @HostBinding('class.tiny')
  @Input()
  tiny = false;

  get hasMessage(): boolean {
    return !!this.message;
  }

  @HostBinding('class.danger')
  get applyDangerClass(): boolean {
    return this.type === AlertType.Danger;
  }

  @HostBinding('class.warning')
  get applyWarningClass(): boolean {
    return this.type === AlertType.Warning;
  }

  @HostBinding('class.info')
  get applyInfoClass(): boolean {
    return this.type === AlertType.Info;
  }

  @HostBinding('class.success')
  get applySuccessClass(): boolean {
    return this.type === AlertType.Success;
  }

  @HostBinding('class.notification')
  get applyNotificationClass(): boolean {
    return this.type === AlertType.Notification;
  }

  get iconType(): string {
    switch (this.type) {
      case AlertType.Info:
        return 'info-circle';
      case AlertType.Notification:
        return 'bell';
      case AlertType.Danger:
        return 'warning';
      case AlertType.Warning:
        return 'exclamation-circle';
      case AlertType.Success:
        return 'check-circle';
      default:
        return 'warning';
    }
  }
}
