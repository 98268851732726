import { ChangeDetectionStrategy, Component, ElementRef, OnInit } from '@angular/core';
import { EventService } from '@spartacus/core';
import { BREAKPOINT, BreakpointService, CmsComponentData } from '@spartacus/storefront';
import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { CmsNavigationComponent } from '../../../../core/model';
import { RoutingHelperService } from '../../../../core/routing';
import { UserHeaderMenuNavigationEvent } from '../../../../core/user';
import { MenuService } from '../../services/menu.service';
import { NavigationService } from '../navigation';
import { NavigationNode } from '../navigation/navigation-node.model';

@Component({
  selector: 'py-header-main-navigation',
  templateUrl: './header-main-navigation.component.html',
  styleUrls: ['./header-main-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMainNavigationComponent implements OnInit {
  readonly directServicesLinkStyleClass = 'direct-services-link';

  links$: Observable<NavigationNode[]>;
  activeLinkUid$: Observable<string>;
  hasActiveLinkUid$: Observable<boolean>;
  toggleSearch: boolean = false;
  searchBoxVisible$: Observable<boolean>;
  tabletVisible$: Observable<boolean>;
  desktopVisible$: Observable<boolean>;

  constructor(
    private componentData: CmsComponentData<CmsNavigationComponent>,
    private navigationService: NavigationService,
    private menuService: MenuService,
    public elementRef: ElementRef,
    private routingHelperService: RoutingHelperService,
    private eventService: EventService,
    private breakpointService: BreakpointService
  ) {}

  ngOnInit() {
    this.searchBoxVisible$ = this.routingHelperService.searchBoxVisible();
    this.tabletVisible$ = combineLatest([
      this.breakpointService.isUp(BREAKPOINT.md),
      this.breakpointService.isDown(BREAKPOINT.lg),
    ]).pipe(map(([isMinContraintFulfilled, isMaxConstraintFulfilled]) => isMinContraintFulfilled && isMaxConstraintFulfilled));
    this.desktopVisible$ = this.breakpointService.isUp(BREAKPOINT.xl);

    this.links$ = this.navigationService.createNavigation(this.componentData.data$).pipe(
      filter((node) => node?.children.length === 1),
      map((node) => node.children[0].children),
      distinctUntilChanged((previousLinks, currentLinks) => {
        const isSame =
          previousLinks.length === currentLinks.length &&
          previousLinks.every((previousLink) =>
            currentLinks.some((currentLink) => currentLink.uid === previousLink.uid && currentLink.title === previousLink.title)
          );

        return isSame;
      })
    );
    this.activeLinkUid$ = this.menuService.getActiveLinkUid();
    this.hasActiveLinkUid$ = this.menuService.hasActiveLinkUid();
  }

  onSelectLink(uid: string, value: boolean): void {
    if (value === true) {
      this.menuService.setActiveLinkUid(uid);
      this.eventService.dispatch(new UserHeaderMenuNavigationEvent([uid]));
    } else {
      this.activeLinkUid$.pipe(take(1)).subscribe((activeLinkUid) => {
        if (activeLinkUid === uid) {
          this.menuService.setActiveLinkUid(null);
        }
      });
    }
  }
}
