import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import { User } from '../../../model';
import { HttpErrorModel, UserSignUp } from '../../../model/misc.model';
import { PyStateUtils } from '../../../state';
import { REGISTER_USER_PROCESS, REMOVE_USER_PROCESS } from '../user-state';

export enum RegisterActionTypes {
  RegisterUser = '[User] Register user',
  RegisterUserSuccess = '[User] Register users success',
  RegisterUserFail = '[User] Register user fail',
  ResetRegisterUser = '[User] Reset register user',

  RemoveUser = '[User] Remove user',
  RemoveUserSuccess = '[User] Remove users success',
  RemoveUserFail = '[User] Remove user fail',
  ResetRemoveUser = '[User] Reset remove user',
}

export class RegisterUser extends StateUtils.EntityLoadAction {
  readonly type = RegisterActionTypes.RegisterUser;
  constructor(public payload: UserSignUp) {
    super(PROCESS_FEATURE, REGISTER_USER_PROCESS);
  }
}

export class RegisterUserFail extends PyStateUtils.EntityFailAction {
  readonly type = RegisterActionTypes.RegisterUserFail;
  constructor(public payload?: any) {
    super(PROCESS_FEATURE, REGISTER_USER_PROCESS, payload);
  }
}

export class RegisterUserSuccess extends StateUtils.EntitySuccessAction {
  readonly type = RegisterActionTypes.RegisterUserSuccess;
  constructor(public payload: User) {
    super(PROCESS_FEATURE, REGISTER_USER_PROCESS, payload);
  }
}

export class ResetRegisterUserProcess extends StateUtils.EntityLoaderResetAction {
  readonly type = RegisterActionTypes.ResetRegisterUser;
  constructor() {
    super(PROCESS_FEATURE, REGISTER_USER_PROCESS);
  }
}

export class RemoveUser extends StateUtils.EntityLoadAction {
  readonly type = RegisterActionTypes.RemoveUser;
  constructor(public payload: any) {
    super(PROCESS_FEATURE, REMOVE_USER_PROCESS);
  }
}

export class RemoveUserFail extends PyStateUtils.EntityFailAction {
  readonly type = RegisterActionTypes.RemoveUserFail;
  constructor(public payload?: HttpErrorModel) {
    super(PROCESS_FEATURE, REMOVE_USER_PROCESS, payload);
  }
}

export class RemoveUserSuccess extends StateUtils.EntitySuccessAction {
  readonly type = RegisterActionTypes.RemoveUserSuccess;
  constructor() {
    super(PROCESS_FEATURE, REMOVE_USER_PROCESS);
  }
}

export class RemoveUserReset extends StateUtils.EntityLoaderResetAction {
  readonly type = RegisterActionTypes.ResetRemoveUser;
  constructor() {
    super(PROCESS_FEATURE, REMOVE_USER_PROCESS);
  }
}

export type RegisterActions =
  | RegisterUser
  | RegisterUserFail
  | RegisterUserSuccess
  | ResetRegisterUserProcess
  | RemoveUser
  | RemoveUserFail
  | RemoveUserSuccess
  | RemoveUserReset;
