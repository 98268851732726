import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../../core/i18n';
import { UrlModule } from '../../../../core/routing';
import { OverlayMessageModule } from '../../overlay-message';
import { CatalogArticleOverlayMessageComponent } from './catalog-article-overlay-message.component';

@NgModule({
  imports: [CommonModule, I18nModule, NzIconModule, OverlayMessageModule, UrlModule, RouterModule],
  declarations: [CatalogArticleOverlayMessageComponent],
  exports: [CatalogArticleOverlayMessageComponent],
})
export class CatalogArticleOverlayMessageModule {}
