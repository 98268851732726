import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { Cart, OrderDetails, SimulatedCart } from '../../../core/model';

type OrderWithPriceData = Pick<
  OrderDetails,
  | 'totalEntryPrice'
  | 'totalFeePrice'
  | 'totalEntryPriceIncludingFees'
  | 'conditions'
  | 'totalPrice'
  | 'totalTax'
  | 'totalPriceWithTax'
>;

/**
 * @param template Template should take properties: label, price & emphasize
 */
@Component({
  selector: 'py-order-price-summary',
  templateUrl: './order-price-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderPriceSummaryComponent {
  @Input() order: OrderWithPriceData & Pick<OrderDetails, 'paymentTerms'>;
  @Input() template: TemplateRef<any>;
  @Input() emphasizeProperties: (keyof OrderWithPriceData)[] = [
    'totalEntryPriceIncludingFees',
    'totalPrice',
    'totalPriceWithTax',
  ];
  @Input() cart?: Cart | SimulatedCart;

  get showFees(): boolean {
    return this.order.totalFeePrice?.value > 0;
  }

  get showTax(): boolean {
    const isCreditCardPayment = this.order.paymentTerms === 'PYCC';
    const hasTax = this.order.totalTax?.value > 0;
    return isCreditCardPayment && hasTax;
  }

  shouldEmphasizeProperty(property: keyof OrderWithPriceData): boolean {
    return this.emphasizeProperties?.includes(property);
  }
}
