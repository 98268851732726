export function stringToHash(str: string): string {
  let hash = 0;

  if (!str?.length || str.length === 0) {
    return '' + hash;
  }

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = hash * 32 - hash + char;
    // eslint-disable-next-line no-bitwise
    hash &= hash;
  }

  return '' + hash;
}

// Maps a given string to one of the values from provided array. Will always return the same value for the same arguments.
// Example usage: mapping string with category ID into a color value from provided array of colors
export function mapStringToValueFromArray(string: string, array: string[]): string {
  const hash = stringToHash(string);

  // Reducing generated hash into a valid index from the given array
  const index = ((parseInt(hash, 10) % array.length) + array.length) % array.length;

  return array[index];
}
