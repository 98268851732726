import { Injectable } from '@angular/core';
import { ScriptPlacement } from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SiteFamilyConfigFacade } from '../../../core/user';
import { ScriptLoader } from '../../../core/util';
import { WindowRef } from '../../../core/window';

@Injectable({ providedIn: 'root' })
export class OnlineChatService {
  constructor(
    private siteFamilyConfigService: SiteFamilyConfigFacade,
    private scriptLoader: ScriptLoader,
    private winRef: WindowRef
  ) {}

  initOnlineChat(): Observable<boolean> {
    return this.siteFamilyConfigService.getSiteFamilyConfig().pipe(
      filter((config) => Boolean(config?.onlineChatConfig)),
      map((config) => config.onlineChatConfig),
      map((onlineChatConfig) => {
        const url = `${onlineChatConfig.baseUrl}/CS/javascript/chat.js`;
        if (this.scriptLoader.hasScript(url)) {
          return;
        }
        this.scriptLoader.embedScript({
          src: url,
          params: undefined,
          attributes: {
            id: 'superoffice',
            type: 'text/javascript',
            'data-topicid': onlineChatConfig.topicId,
            'data-title': onlineChatConfig.title,
            'data-theme': onlineChatConfig.theme,
            'data-color': onlineChatConfig.color,
            'data-font': onlineChatConfig.font,
            'data-delay': onlineChatConfig.delay,
          },
          callback: () => {
            this.initOnlineChatSession();
          },
          errorCallback: undefined,
          placement: ScriptPlacement.BODY,
        });
      }),
      map(() => true)
    );
  }

  private initOnlineChatSession() {
    const { SuperOffice_setupChat } = this.winRef.nativeWindow as any;
    SuperOffice_setupChat();
    return SuperOffice_setupChat;
  }
}
