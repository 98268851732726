import { ChangeDetectionStrategy, Component, ElementRef, Input, TemplateRef } from '@angular/core';

/**
 * Conditionally render list or table.
 * If host width of this component exceeds breakpoint, render table; else render list.
 * A list/row template will be rendered for each entry in entries.
 * The implicit context of these templates will be the entry itself.
 */
@Component({
  selector: 'py-responsive-table',
  templateUrl: './responsive-table.component.html',
  styleUrls: ['./responsive-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResponsiveTableComponent<T = any> {
  @Input() entries: T[];
  /**
   * Render as table if width of element exceeds breakpoint, else render as list
   */
  @Input() breakpoint = 600;
  /**
   * If false, wrap table rows in tbody
   */
  @Input() omitTbody = false;
  /**
   * Optimize performance by using a key which is provided to the *ngFor track-by function.
   * Read more: https://netbasal.com/angular-2-improve-performance-with-trackby-cc147b5104e5
   */
  @Input() key?: keyof T;

  @Input() rowTemplate: TemplateRef<any>;
  @Input() listItemTemplate: TemplateRef<any>;

  @Input() tableClass?: string;

  constructor(public elementRef: ElementRef) {}

  /**
   * If no key is provided, use default implementation
   */
  trackByFn = (_index, entry: T) => {
    return this.key && entry ? entry[this.key] : entry;
  };
}
