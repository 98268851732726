import { Action } from '@ngrx/store';
import { EntityMap } from '../../../model';
import { CatalogActions } from '../actions';

export const initialState: EntityMap<string> = {};

export function reducer(state = initialState, action: Action): EntityMap<string> {
  if (action.type === CatalogActions.CatalogKeyActionTypes.LoadKeysSuccess) {
    return {
      ...(<CatalogActions.LoadCatalogKeysSuccess>action).payload,
    };
  }

  return state;
}
