import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { KeyboardFocusModule } from '@spartacus/storefront';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../../core/i18n';
import { LaunchDialogService } from '../../../../core/modal';
import { BadgeModule } from '../../../../shared/components/badge/badge.module';
import { IconThemeReverseModule } from '../../../directives';
import { PipesModule } from '../../../pipes';
import { ActionButtonModule } from '../../action-button';
import { ModalModule } from '../../modal';
import { NotificationModule } from '../../notification';
import { ReactiveFormCheckboxSliderModule } from '../../reactive-forms-input/reactive-form-checkbox-slider/reactive-form-checkbox-slider.module';
import { ReactiveFormInputFieldModule } from '../../reactive-forms-input/reactive-form-input-field/reactive-form-input-field.module';
import { nameMarkingModalLayoutConfig } from './name-marking-modal-layout.config';
import { NameMarkingModalComponent } from './name-marking-modal.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    NzIconModule,
    ReactiveFormInputFieldModule,
    ReactiveFormCheckboxSliderModule,
    ReactiveFormsModule,
    PipesModule,
    BadgeModule,
    NotificationModule,
    IconThemeReverseModule,
    ActionButtonModule,
    ModalModule,
    KeyboardFocusModule,
  ],
  declarations: [NameMarkingModalComponent],
  exports: [NameMarkingModalComponent],
})
export class NameMarkingModalModule {
  constructor(private launchDialogService: LaunchDialogService) {
    this.launchDialogService.registerModalLayoutConfig(nameMarkingModalLayoutConfig);
  }
}
