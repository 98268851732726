<div class="content">
  <div class="d-flex w-100">
    <button
      *ngIf="showBackButton"
      (click)="onBackButtonClick()"
      aria-label="Back"
      class="d-flex justify-content-center align-items-center"
    >
      <i class="reset" nz-icon nzType="arrow-left" nzTheme="outline"></i>
    </button>
    <div class="drawer-header-title px-3 w-100 d-flex align-items-center justify-content-between">
      <ng-container *ngIf="title">
        <h4 class="d-block d-xl-none">{{ title }}</h4>
        <h3 class="d-none d-xl-block">{{ title }}</h3>
      </ng-container>
      <i nz-icon pyIconThemeReverse nzType="close-circle" nzTheme="outline" (click)="closeDrawer()" class="ml-auto"></i>
    </div>
  </div>
  <ng-content></ng-content>
</div>
