import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { UserIdService } from '../../../core/auth';
import {
  EcConformityProductDocument,
  EcConformityProductDocumentsSearchParams,
  Pagination,
  ProductBrand,
  ProductDocument,
} from '../../../core/model';
import { DocumentActions } from '../store/actions';
import { StateWithDocuments } from '../store/documents-state';
import { DocumentsSelectors } from '../store/selectors';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  constructor(private userIdService: UserIdService, private store: Store<StateWithDocuments>) {}

  // Product Documents
  loadProductDocuments(productCode: string, children: boolean = true): void {
    this.userIdService
      .takeUserId()
      .pipe(withLatestFrom(this.store.select(DocumentsSelectors.getProductDocumentsShouldLoad(productCode))))
      .subscribe(([userId, shouldLoad]) => {
        if (shouldLoad) {
          this.store.dispatch(new DocumentActions.LoadProductDocuments(userId, productCode, children));
        }
      });
  }

  getProductDocuments(productCode: string, articleCode?: string): Observable<ProductDocument[]> {
    return this.store.select(DocumentsSelectors.getProductDocuments(productCode, articleCode));
  }

  // Declaration Of Conformity
  updateConformanceEcSearchParams(newSearchParams: EcConformityProductDocumentsSearchParams): void {
    this.store
      .select(DocumentsSelectors.getConformanceEcSearchParams)
      .pipe(take(1))
      .subscribe((oldSearchParams) => {
        this.store.dispatch(
          new DocumentActions.SearchConformanceEcSearchParams({ searchParams: newSearchParams, oldSearchParams: oldSearchParams })
        );
      });
  }

  getConformanceEcSearchResult(): Observable<EcConformityProductDocument[] | undefined> {
    return this.store.select(DocumentsSelectors.getConformanceEcSearchResultEntries);
  }

  getConformanceEcSearchResultPagination(): Observable<Pagination | undefined> {
    return this.store.select(DocumentsSelectors.getConformanceEcSearchPagination);
  }

  getConformanceEcSearchPageResultsLoading(): Observable<boolean> {
    return combineLatest([
      this.store.select(DocumentsSelectors.getConformanceEcSearchPageResultsLoading),
      this.store.select(DocumentsSelectors.getConformanceEcSearchPageResultsLoaded),
    ]).pipe(map(([loading, loaded]) => loading || !loaded));
  }

  loadConformanceEcBrands(): void {
    this.store
      .select(DocumentsSelectors.getShouldLoadConformanceEcBrands)
      .pipe(take(1))
      .subscribe((shouldLoad) => {
        if (shouldLoad) {
          this.store.dispatch(new DocumentActions.LoadConformanceEcBrands());
        }
      });
  }

  getConformanceEcBrands(): Observable<ProductBrand[]> {
    return this.store.select(DocumentsSelectors.getConformanceEcBrands);
  }
}
