<py-overlay-message>
  <i nz-icon nzType="info-circle" nzTheme="fill"></i>
  <h5 class="my-2">
    {{ 'catalog.articleNoLongerAvailable_text' | cxTranslate : { articleRef: articleRef } }}
  </h5>

  <button
    *ngIf="isUserLoggedIn$ | async; else loginLinkTemplate"
    class="link"
    [class.expanded]="expanded"
    (click)="onToggleExpanded()"
  >
    <span>{{ 'catalog.showSimilarArticles_action' | cxTranslate }}</span>
    <i class="ml-2" nz-icon nzType="down-circle" nzTheme="outline" [class.rotate]="expanded"></i>
  </button>

  <ng-template #loginLinkTemplate>
    <a [routerLink]="{ cxRoute: 'login' } | cxUrl">
      <span>{{ 'catalog.loginToShowSimilarArticles_action' | cxTranslate }}</span>
      <i class="ml-2" nz-icon nzType="arrow-right"></i>
    </a>
  </ng-template>
</py-overlay-message>
