<label class="d-flex align-items-center" *ngIf="showLabel; else withoutLabelTemplate">
  <ng-container *ngTemplateOutlet="radioTemplate"></ng-container>
  <div class="label-wrapper ml-2">
    <ng-content select="[slot=label]"></ng-content>
  </div>
</label>

<ng-template #withoutLabelTemplate>
  <label class="radio-wrapper">
    <ng-container *ngTemplateOutlet="radioTemplate"></ng-container>
  </label>
</ng-template>

<ng-template #radioTemplate>
  <ng-content select="input[type=radio]"></ng-content>
  <span class="radiomark"></span>
</ng-template>
