import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import { HttpErrorModel, ServiceOption, ShippingOption } from '../../../../../core/model';
import { PyStateUtils } from '../../../../../core/state';
import { SET_SERVICE_OPTION_PROCESS, SET_SHIPPING_OPTION_PROCESS } from '../checkout-state';
import { CheckoutStepsEntityAction, PayloadWithCheckoutKey } from './checkout-steps-entity.action';

export enum CheckoutShippingActionTypes {
  SetShippingOption = '[Checkout] Set shipping option',
  SetShippingOptionFail = '[Checkout] Set shipping option fail',
  SetShippingOptionSuccess = '[Checkout] Set shipping option success',
  ResetSetShippingOption = '[Checkout] Reset set shipping option',

  SetServiceOption = '[Checkout] Set service option',
  SetServiceOptionFail = '[Checkout] Set service option fail',
  SetServiceOptionSuccess = '[Checkout] Set service option success',
  ResetSetServiceOption = '[Checkout] Reset set service option',

  ClearShippingStep = '[Checkout] Clear shipping step',
  ConfirmShippingOption = '[Checkout] Confirm shipping option',
}

interface SetShippingOptionPayload extends PayloadWithCheckoutKey {
  userId: string;
  shippingOption: ShippingOption;
  deliveryDate?: string;
}

interface SetShippingOptionFailPayload extends SetShippingOptionPayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SetShippingOptionSuccessPayload extends SetShippingOptionPayload {}

interface SetServiceOptionPayload extends PayloadWithCheckoutKey {
  userId: string;
  serviceOption: ServiceOption;
}

interface SetServiceOptionFailPayload extends SetServiceOptionPayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SetServiceOptionSuccessPayload extends SetServiceOptionPayload {}

export class SetShippingOption extends StateUtils.EntityLoadAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutShippingActionTypes.SetShippingOption;

  constructor(public payload: SetShippingOptionPayload) {
    super(PROCESS_FEATURE, SET_SHIPPING_OPTION_PROCESS);
  }
}

export class SetShippingOptionFail extends PyStateUtils.EntityFailAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutShippingActionTypes.SetShippingOptionFail;

  constructor(public payload: SetShippingOptionFailPayload) {
    super(PROCESS_FEATURE, SET_SHIPPING_OPTION_PROCESS, payload.error);
  }
}

export class SetShippingOptionSuccess extends StateUtils.EntitySuccessAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutShippingActionTypes.SetShippingOptionSuccess;

  constructor(public payload: SetShippingOptionSuccessPayload) {
    super(PROCESS_FEATURE, SET_SHIPPING_OPTION_PROCESS);
  }
}

export class ResetSetShippingOption extends StateUtils.EntityLoaderResetAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutShippingActionTypes.ResetSetShippingOption;

  constructor(public payload: PayloadWithCheckoutKey) {
    super(PROCESS_FEATURE, SET_SHIPPING_OPTION_PROCESS);
  }
}

export class SetServiceOption extends StateUtils.EntityLoadAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutShippingActionTypes.SetServiceOption;

  constructor(public payload: SetServiceOptionPayload) {
    super(PROCESS_FEATURE, SET_SERVICE_OPTION_PROCESS);
  }
}

export class SetServiceOptionFail extends PyStateUtils.EntityFailAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutShippingActionTypes.SetServiceOptionFail;

  constructor(public payload: SetServiceOptionFailPayload) {
    super(PROCESS_FEATURE, SET_SERVICE_OPTION_PROCESS, payload.error);
  }
}

export class SetServiceOptionSuccess extends StateUtils.EntitySuccessAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutShippingActionTypes.SetServiceOptionSuccess;

  constructor(public payload: SetServiceOptionSuccessPayload) {
    super(PROCESS_FEATURE, SET_SERVICE_OPTION_PROCESS);
  }
}

export class ResetSetServiceOption extends StateUtils.EntityLoaderResetAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutShippingActionTypes.ResetSetServiceOption;

  constructor(public payload: PayloadWithCheckoutKey) {
    super(PROCESS_FEATURE, SET_SERVICE_OPTION_PROCESS);
  }
}

export class ClearShippingStep implements CheckoutStepsEntityAction {
  readonly type = CheckoutShippingActionTypes.ClearShippingStep;

  constructor(public payload: PayloadWithCheckoutKey) {}
}

export class ConfirmShippingOption implements CheckoutStepsEntityAction {
  readonly type = CheckoutShippingActionTypes.ConfirmShippingOption;

  constructor(public payload: PayloadWithCheckoutKey) {}
}

export type CheckoutShippingActions =
  | SetShippingOption
  | SetShippingOptionFail
  | SetShippingOptionSuccess
  | ResetSetShippingOption
  | SetServiceOption
  | SetServiceOptionFail
  | SetServiceOptionSuccess
  | ResetSetServiceOption
  | ClearShippingStep
  | ConfirmShippingOption;
