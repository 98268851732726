import { Injectable } from '@angular/core';
import { B2BPermissionOrderListItem, EntitiesModel } from '../../../../model';
import { PERMISSION_ORDER_NORMALIZER } from '../../../../permission/connectors';
import { Converter, ConverterService } from '../../../../util/converter.service';
import { Occ } from '../../../occ-models';
@Injectable({
  providedIn: 'root',
})
export class OccPermissionOrdersListNormalizer
  implements Converter<Occ.B2BPermissionOrdersPage, EntitiesModel<B2BPermissionOrderListItem>>
{
  constructor(private converter: ConverterService) {}

  convert(
    source: Occ.B2BPermissionOrdersPage,
    target?: EntitiesModel<B2BPermissionOrderListItem>
  ): EntitiesModel<B2BPermissionOrderListItem> {
    if (target === undefined) {
      target = { ...(source as any) };
    }

    target.values = (source.results || []).map((permissionOrder) => ({
      ...this.converter.convert(permissionOrder, PERMISSION_ORDER_NORMALIZER),
    }));

    return target;
  }
}
