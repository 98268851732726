import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { Subject, fromEvent } from 'rxjs';
import { auditTime, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[pyScrollState]',
})
export class ScrollStateDirective implements OnDestroy, OnInit {
  @HostBinding('class.can-scroll-up') canScrollUp = false;
  @HostBinding('class.can-scroll-down') canScrollDown = false;

  @Input() auditTimeMs: number = 125;

  @Output() scrolledToBottom = new EventEmitter<void>();

  private resizeObserver: ResizeObserver;

  private readonly destroy$: Subject<void> = new Subject();
  private readonly hostElement: HTMLElement;

  constructor(readonly vcRef: ViewContainerRef, private cd: ChangeDetectorRef) {
    this.hostElement = vcRef.element.nativeElement;
    fromEvent(this.hostElement, 'scroll')
      .pipe(auditTime(this.auditTimeMs), takeUntil(this.destroy$))
      .subscribe(() => {
        this.setClasses();
      });
  }

  public ngOnInit(): void {
    this.resizeObserver = new ResizeObserver((_entries) => {
      this.setClasses();
    });

    this.resizeObserver.observe(this.hostElement);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.resizeObserver.unobserve(this.hostElement);
  }

  private setClasses(): void {
    this.canScrollUp = this.hostElement.scrollTop > 0;
    this.canScrollDown = Math.abs(this.hostElement.scrollHeight - this.hostElement.clientHeight - this.hostElement.scrollTop) > 1;
    if (!this.canScrollDown) {
      this.scrolledToBottom.emit();
    }
    this.cd.markForCheck();
  }
}
