import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ModalCloseReason, isModalCloseReasonWithPayload } from '../../../../core/modal';
import { NameMarkingModalCloseReason, NameMarkingModalData } from '../name-marking-modal';

@Component({
  selector: 'py-name-marking-button',
  templateUrl: './name-marking-button.component.html',
  styleUrls: ['./name-marking-button.component.scss'],
})
export class NameMarkingButtonComponent {
  @Input() articleTitle: string = '';
  @Input() inEcommerceQuantity: number;
  @Input() disabled: boolean = false;
  @Input() showButton: boolean = false;
  @Input() names: string[];

  @Output() handleSave = new EventEmitter<string[]>();

  subscriptions = new Subscription();

  constructor(private launchDialogService: LaunchDialogService) {}

  get namesCount(): number {
    return this.names.length;
  }

  get buttonLabel(): string | undefined {
    if (this.namesCount > 1) {
      return `${this.names[0]} (+${this.namesCount - 1})`;
    } else if (this.namesCount === 1) {
      return `${this.names[0]}`;
    }

    return undefined;
  }

  open(): void {
    const modalData: NameMarkingModalData = {
      articleTitle: this.articleTitle,
      inEcommerceQuantity: this.inEcommerceQuantity,
      names: this.names,
    };

    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.NAME_MARKING, undefined, modalData);

    this.subscriptions.add(
      this.launchDialogService.dialogClose
        .pipe(
          filter((reason) => reason !== undefined),
          filter((reason) => isModalCloseReasonWithPayload(reason)),
          map((reasonWithPayload) => reasonWithPayload as NameMarkingModalCloseReason),
          take(1)
        )
        .subscribe(({ reason, payload: nameMarkingValue }) => {
          if (reason === ModalCloseReason.NAME_MARKING_SAVED) {
            this.handleSave.emit(nameMarkingValue);
          }
        })
    );
  }
}
