import { StateUtils } from '@spartacus/core';
import { cartWithOrderEntryIds } from '../../../../shared';
import { ShoppingList } from '../../../model';
import { UserActions } from '../actions';
import { ShoppingListActionTypes } from '../actions/shopping-list.action';

export const initialState: ShoppingList = <ShoppingList>{};

export function reducer(
  state: ShoppingList = initialState,
  action: UserActions.ShoppingListActions | StateUtils.LoaderAction
): ShoppingList {
  switch (action.type) {
    case ShoppingListActionTypes.LoadShoppingListSuccess:
    case ShoppingListActionTypes.CreateShoppingListSuccess:
    case ShoppingListActionTypes.UpdateTempShoppingList: {
      return cartWithOrderEntryIds(action.payload?.shoppingList || initialState, state);
    }
    case ShoppingListActionTypes.UpdateShoppingListNameSuccess: {
      const payload = action.payload;
      if (state.code === payload.code) {
        return {
          ...state,
          name: payload.name,
        };
      }
      break;
    }
    case ShoppingListActionTypes.UpdateShoppingListDescriptionSuccess: {
      const payload = action.payload;
      if (state.code === payload.code) {
        return {
          ...state,
          description: payload.description,
        };
      }
      break;
    }
    case ShoppingListActionTypes.UpdateShoppingListPermissionsSuccess: {
      const _action = <UserActions.UpdateShoppingListPermissionsSuccess>action;

      if (!state.writers) {
        state = {
          ...state,
          writers: [],
        };
      }

      _action.payload.permissions.forEach((permission) => {
        if (permission.writer && !state.writers.some((writer) => writer.uid === permission.uid)) {
          state = {
            ...state,
            writers: [...state.writers, { uid: permission.uid }],
          };
        } else if (!permission.writer && state.writers.some((writer) => writer.uid === permission.uid)) {
          state = {
            ...state,
            writers: state.writers.filter((writer) => writer.uid !== permission.uid),
          };
        }
      });

      return state;
    }
  }

  return state;
}
