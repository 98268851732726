import { Action } from '@ngrx/store';
import { EntriesState } from '../../../features/store/base-feature-state';
import { OrderApproval } from '../../../model';
import { UserActions } from '../actions';

export const initialState: EntriesState<OrderApproval> = undefined;

export function reducer(state = initialState, action: Action): EntriesState<OrderApproval> {
  switch (action.type) {
    case UserActions.OrderApprovalActionTypes.LOAD_ORDER_APPROVALS_SUCCESS: {
      const successAction = <UserActions.LoadOrderApprovalsSuccess | UserActions.LoadOrderApprovalsAdminSuccess>action;
      const results = successAction.payload.results;

      return results.reduce(
        (entities: { [id: string]: OrderApproval }, orderApproval: OrderApproval) => {
          return {
            ...entities,
            [orderApproval.code]: orderApproval,
          };
        },
        {
          ...state,
        }
      );
    }
    case UserActions.OrderApprovalActionTypes.LOAD_ORDER_APPROVALS_RESET: {
      return initialState;
    }

    case UserActions.OrderApprovalActionTypes.LOAD_ORDER_APPROVAL_DETAILS_SUCCESS: {
      const successAction = <UserActions.LoadOrderApprovalDetailsSuccess>action;
      const payload = successAction.payload;

      if (!state || !state[payload.code]) {
        return state;
      }

      return {
        ...state,
        [payload.code]: payload,
      };
    }

    default:
      return state;
  }
}
