import { StateUtils } from '@spartacus/core';
import { ActionTokenPayload, CartType, HttpErrorModel, OrderEntry } from '../../../../../core/model';
import { CART } from '../cart-state';

export enum CartEntryReamingActionTypes {
  AddCartEntryReaming = '[Cart] Add reaming for cart entry',
  AddCartEntryReamingSuccess = '[Cart] Add reaming for cart entry success',
  AddCartEntryReamingFail = '[Cart] Add reaming for cart entry fail',

  RemoveCartEntryReaming = '[Cart] Remove reaming for cart entry',
  RemoveCartEntryReamingSuccess = '[Cart] Remove reaming for cart entry success',
  RemoveCartEntryReamingFail = '[Cart] Remove reaming for cart entry fail',
}

interface AddCartEntryReamingPayloadBase {
  cartType: CartType;
  cartId: string;
  userId: string;
  entry: OrderEntry;
}

interface AddCartEntryReamingPayload extends AddCartEntryReamingPayloadBase, ActionTokenPayload<boolean> {}

interface AddCartEntryReamingFailPayload extends AddCartEntryReamingPayloadBase {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AddCartEntryReamingSuccessPayload extends AddCartEntryReamingPayloadBase {}

interface RemoveCartEntryReamingPayloadBase {
  cartType: CartType;
  cartId: string;
  userId: string;
  entry: OrderEntry;
}

interface RemoveCartEntryReamingPayload extends RemoveCartEntryReamingPayloadBase, ActionTokenPayload<boolean> {}

interface RemoveCartEntryReamingFailPayload extends RemoveCartEntryReamingPayloadBase {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RemoveCartEntryReamingSuccessPayload extends RemoveCartEntryReamingPayloadBase {}

export class AddCartEntryReaming extends StateUtils.EntityProcessesIncrementAction {
  readonly type = CartEntryReamingActionTypes.AddCartEntryReaming;

  constructor(public payload: AddCartEntryReamingPayload) {
    super(CART, payload.cartId);
  }
}

export class AddCartEntryReamingFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryReamingActionTypes.AddCartEntryReamingFail;

  constructor(public payload: AddCartEntryReamingFailPayload) {
    super(CART, payload.cartId);
  }
}

export class AddCartEntryReamingSuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryReamingActionTypes.AddCartEntryReamingSuccess;

  constructor(public payload: AddCartEntryReamingSuccessPayload) {
    super(CART, payload.cartId);
  }
}

export class RemoveCartEntryReaming extends StateUtils.EntityProcessesIncrementAction {
  readonly type = CartEntryReamingActionTypes.RemoveCartEntryReaming;

  constructor(public payload: RemoveCartEntryReamingPayload) {
    super(CART, payload.cartId);
  }
}

export class RemoveCartEntryReamingFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryReamingActionTypes.RemoveCartEntryReamingFail;

  constructor(public payload: RemoveCartEntryReamingFailPayload) {
    super(CART, payload.cartId);
  }
}

export class RemoveCartEntryReamingSuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryReamingActionTypes.RemoveCartEntryReamingSuccess;

  constructor(public payload: RemoveCartEntryReamingSuccessPayload) {
    super(CART, payload.cartId);
  }
}

export type CartEntryReamingActions =
  | AddCartEntryReaming
  | AddCartEntryReamingFail
  | AddCartEntryReamingSuccess
  | RemoveCartEntryReaming
  | RemoveCartEntryReamingFail
  | RemoveCartEntryReamingSuccess;
