<ng-container
  *ngIf="{
    article: article$ | async,
    featuredCertificates: featuredCertificates$ | async,
    enableCuttingOrEnableReelCutting: enableCuttingOrEnableReelCutting$ | async,
    enableDisplayPrePrintingSymbolOnArticle: enableDisplayPrePrintingSymbolOnArticle$ | async,
    enableReaming: enableReaming$ | async,
    enableBreakable: enableBreakable$ | async,
    columnAttributes: columnAttributes$ | async,
    isLoggedIn: isUserLoggedIn$ | async,
    enableContractedArticleIcon: enableContractedArticleIcon$ | async,
    showBadges: showBadges$ | async,
    tenderedEnabled: tenderedEnabled$ | async,
    enableDisplayOutgoingArea: enableDisplayOutgoingArea$ | async,
    prePrintedLabelContryCode: prePrintedLabelContryCode$ | async,
  } as data"
>
  <div class="d-flex flex-column">
    <div class="mb-1">
      <div class="d-flex flex-row align-items-center wrap">
        <ng-container
          *ngIf="
            url && data.article?.productCode !== 'CUST_UNIQ' && data.article?.hideLinkToProductPage !== true && !isDiscontinued;
            else displayNameTemplate
          "
        >
          <cx-generic-link [url]="url">
            <ng-container *ngTemplateOutlet="displayNameTemplate"></ng-container>
          </cx-generic-link>
        </ng-container>

        <ng-container *ngIf="showSplit">
          <div [ngbTooltip]="'checkout.review.orderItemsSplit_tooltip' | cxTranslate" class="article-info-split d-flex flex-row">
            <i nz-icon nzType="warning" nzTheme="fill"></i>
            <div class="ml-2">{{ 'checkout.review.orderItemsSplit_badge' | cxTranslate }}</div>
          </div>
        </ng-container>
      </div>
      <h6
        class="article-brand-and-identifier"
        *ngIf="data?.article?.displayBrandAndIdentifier"
        [innerHTML]="data?.article?.brandAndIdentifier"
      ></h6>
    </div>

    <span class="skeleton article-code">
      <ng-container *ngIf="articleNumber || data.article?.articleNumber">
        {{ 'product.articleNumber_heading' | cxTranslate }}
        <span class="article-code-label">
          {{ articleNumber || data.article?.articleNumber }}
          <i
            *ngIf="!enableSecondaryArticleRowVariant && data.enableContractedArticleIcon && data.article.contracted"
            nz-icon
            nzType="contracted-article"
            class="ml-1"
            container="body"
            [ngbTooltip]="'article.contracted_tooltip' | cxTranslate"
          ></i>
        </span>

        <i
          *ngIf="enableSecondaryArticleRowVariant && data.enableContractedArticleIcon && data.article.contracted"
          nz-icon
          class="ml-1"
          nzType="contracted-article"
          container="body"
          nzTheme="outline"
          [ngbTooltip]="'article.contracted_tooltip' | cxTranslate"
        ></i>
      </ng-container>
    </span>

    <span class="article-code mt-1" *ngIf="data.article?.displayOldArticleNumber && data.article?.oldArticleNumber">
      {{ 'article.oldArticleNumber_hint' | cxTranslate : { articleNumber: data.article?.oldArticleNumber } }}
    </span>

    <ng-container *ngIf="data.isLoggedIn && data.article?.nonReturnable">
      <py-non-returnable [outOfStock]="outOfStock" [articleStatus]="data.article?.articleStatus">
        <span class="d-block mt-2">{{ 'article.nonReturnable_text' | cxTranslate }}</span>
      </py-non-returnable>
    </ng-container>

    <div
      *ngIf="
        data.showBadges ||
        (data.columnAttributes?.length > 0 && !enableSecondaryArticleRowVariant) ||
        data.featuredCertificates?.length > 0 ||
        data.article?.packaging ||
        (data.article?.reamable && data.enableReaming) ||
        (data.article?.cuttable && data.enableCuttingOrEnableReelCutting) ||
        !!data.article?.safetyDatasheetUrl ||
        !!data.article?.datasheet?.url ||
        (data.article?.prePrintable && data.enableDisplayPrePrintingSymbolOnArticle && showPrePrintedLabelIconForSpecialArticle)
      "
      class="d-flex flex-wrap my-2 attributes-section"
      [ngClass]="enableSecondaryArticleRowVariant ? 'flex-column align-items-start flex-sm-row' : 'align-items-center'"
    >
      <div class="d-flex align-items-center items-wrapper">
        <ng-container *ngIf="data.showBadges" [ngTemplateOutlet]="badgesTemplate"></ng-container>

        <ng-container
          *ngIf="
            enableSecondaryArticleRowVariant && data.article?.packaging && data.article?.packaging !== packagingTypes.UNKNOWN
          "
          [ngTemplateOutlet]="packagingTemplate"
        ></ng-container>

        <ng-container *ngIf="data.columnAttributes?.length > 0 && !enableSecondaryArticleRowVariant">
          <py-article-info-attributes
            [columnAttributes]="data.columnAttributes"
            [enableSecondaryArticleRowVariant]="enableSecondaryArticleRowVariant"
          ></py-article-info-attributes>
        </ng-container>
      </div>

      <div class="d-flex items-wrapper">
        <div
          *ngIf="
            ((data.article?.reamable && data.enableReaming) ||
              (data.article?.cuttable && data.enableCuttingOrEnableReelCutting) ||
              (data.article?.breakable && data.enableBreakable) ||
              (data.article?.prePrintable &&
                data.enableDisplayPrePrintingSymbolOnArticle &&
                showPrePrintedLabelIconForSpecialArticle)) &&
            !(enableSecondaryArticleRowVariant && isDiscontinued)
          "
          class="services-section"
        >
          <div
            *ngIf="
              data.article?.prePrintable &&
              data.enableDisplayPrePrintingSymbolOnArticle &&
              showPrePrintedLabelIconForSpecialArticle
            "
            class="mr-2"
          >
            <i
              class="py-color-black"
              nz-icon
              nzType="tags"
              nzTheme="outline"
              container="body"
              [ngbTooltip]="'article.prePrintingAvailable_tooltip' | cxTranslate"
            ></i>
            <span class="ml-1 py-color-black">{{ data.prePrintedLabelContryCode }}</span>
          </div>
          <ng-container *ngIf="data.article?.cuttable && data.enableCuttingOrEnableReelCutting">
            <i
              class="mr-2 py-color-black"
              nz-icon
              nzType="scissor"
              nzTheme="outline"
              container="body"
              [ngbTooltip]="'article.cuttingAvailable_tooltip' | cxTranslate"
            ></i>
          </ng-container>

          <ng-container *ngIf="data.article?.reamable && data.enableReaming">
            <i
              class="mr-2 py-color-black"
              nz-icon
              nzType="reaming-available"
              nzTheme="outline"
              container="body"
              [ngbTooltip]="'article.reamingAvailable_tooltip' | cxTranslate"
            ></i>
          </ng-container>

          <ng-container *ngIf="data.article?.breakable && data.enableBreakable && enableSecondaryArticleRowVariant">
            <i
              class="mr-2"
              nz-icon
              nzType="breakable"
              nzTheme="outline"
              container="body"
              [ngbTooltip]="'article.breakable_tooltip' | cxTranslate"
            ></i>
          </ng-container>
        </div>

        <div *ngIf="!isDiscontinued && (!!data.article?.safetyDatasheetUrl || !!data.article?.datasheet?.url)">
          <cx-generic-link
            *ngIf="!!data.article?.safetyDatasheetUrl"
            [url]="data.article?.safetyDatasheetUrl"
            target="_blank"
            class="mr-2"
            container="body"
            [ngbTooltip]="'article.safetyDatasheet_tooltip' | cxTranslate"
          >
            <i class="text-success" nz-icon nzType="file-protect" nzTheme="outline"></i>
          </cx-generic-link>

          <cx-generic-link
            *ngIf="!!data.article?.datasheet?.url"
            [url]="getMediaUrl(data.article?.datasheet?.url)"
            target="_blank"
            class="mr-2"
            container="body"
            [ngbTooltip]="'article.datasheet_tooltip' | cxTranslate"
          >
            <i class="text-success" nz-icon nzType="file-pdf" nzTheme="outline"></i>
          </cx-generic-link>
        </div>

        <div *ngIf="data.featuredCertificates?.length > 0 && !isDiscontinued">
          <ng-container *ngFor="let certificate of data.featuredCertificates">
            <img
              *ngIf="certificate?.icon"
              class="certificate mr-2"
              [pyMedia]="certificate.icon"
              [ngbTooltip]="certificate.name"
              (click)="openCertificate(certificate.icon)"
            />
          </ng-container>
        </div>
      </div>
    </div>

    <ng-container *ngIf="data.article?.specialMessage?.mainMessage" [ngTemplateOutlet]="specialMessageTemplate"></ng-container>
  </div>

  <ng-template #displayNameTemplate>
    <h6 class="mb-1 skeleton article-name" [innerText]="data.article?.displayName"></h6>
  </ng-template>

  <ng-template #specialMessageTemplate>
    <py-notification class="my-1" [type]="'info'" [tiny]="true">
      <p [innerHTML]="data.article?.specialMessage?.mainMessage"></p>
      <cx-generic-link
        *ngIf="data.article?.specialMessage?.url && data.article?.specialMessage?.linkText"
        class="special-message-link"
        [url]="data.article?.specialMessage?.url"
      >
        {{ data.article?.specialMessage?.linkText }}
      </cx-generic-link>
    </py-notification>
  </ng-template>

  <ng-template #badgesTemplate>
    <ng-container
      *ngIf="enableSecondaryArticleRowVariant && isDiscontinued"
      [ngTemplateOutlet]="discontinuedBadgeTemplate"
    ></ng-container>

    <ng-container *ngIf="showOrderReturnEntryBadge" [ngTemplateOutlet]="orderReturnEntryBadgeTemplate"></ng-container>

    <ng-container *ngIf="!(enableSecondaryArticleRowVariant && isDiscontinued)">
      <py-badge *ngIf="data.article?.overlayNew" type="new" [tag]="true" large="true"
        >{{ 'article.new_badge' | cxTranslate }}
      </py-badge>

      <py-badge *ngIf="data.article?.tendered && data.tenderedEnabled" type="tendered" [tag]="true" large="true"
        ><i class="reset" nz-icon nzType="like" nzTheme="outline"></i>
      </py-badge>

      <py-badge
        *ngIf="data.article?.breakable && data.enableBreakable && !enableSecondaryArticleRowVariant"
        type="breakable"
        [tag]="true"
        large="true"
      >
        {{ 'article.breakable_badge' | cxTranslate }}
      </py-badge>

      <py-badge
        *ngIf="!enableSecondaryArticleRowVariant && data.article?.outgoingOnSale && data.enableDisplayOutgoingArea"
        type="outlet"
        [tag]="true"
        large="true"
        >{{ 'article.outlet_badge' | cxTranslate }}
      </py-badge>

      <py-badge *ngIf="substituteBadgeType === substituteTypes?.Alternative" type="alternative" [tag]="true" large="true">{{
        'catalog.substitute_alternative_badge' | cxTranslate
      }}</py-badge>
      <py-badge *ngIf="substituteBadgeType === substituteTypes?.Replacement" type="replacement" [tag]="true" large="true">{{
        'catalog.substitute_replacement_badge' | cxTranslate
      }}</py-badge>
      <py-badge *ngIf="substituteBadgeType === substituteTypes?.Recommendation" type="recommended" [tag]="true" large="true">
        {{ 'catalog.substitute_recommendation_badge' | cxTranslate }}</py-badge
      >

      <ng-container *ngIf="isDiscontinued" [ngTemplateOutlet]="discontinuedBadgeTemplate"></ng-container>
    </ng-container>
  </ng-template>

  <ng-template #packagingTemplate>
    <div class="d-flex mr-2" [ngSwitch]="data.article.packaging">
      <ng-container *ngSwitchCase="packagingTypes.REAMED">
        <i nz-icon nzTheme="outline" nzType="reamed" container="body" [ngbTooltip]="'article.reamed_tooltip' | cxTranslate"></i>
      </ng-container>
      <ng-container *ngSwitchCase="packagingTypes.UNREAMED">
        <i
          nz-icon
          nzTheme="outline"
          nzType="bulk_on_flex"
          container="body"
          [ngbTooltip]="'article.unreamed_tooltip' | cxTranslate"
        ></i>
      </ng-container>
      <ng-container *ngSwitchCase="packagingTypes.BULKED">
        <i nz-icon nzTheme="outline" nzType="bulk" container="body" [ngbTooltip]="'article.bulked_tooltip' | cxTranslate"></i>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #discontinuedBadgeTemplate>
    <py-badge [type]="'discontinued'" [tag]="true" [large]="'true'">
      {{ (data.article?.invisible ? 'common.notAvailable_badge' : 'common.discontinued_badge') | cxTranslate }}
    </py-badge>
  </ng-template>

  <ng-template #orderReturnEntryBadgeTemplate>
    <py-badge [type]="'warning'" [tag]="false" [showCircle]="true" [large]="true">
      {{ 'common.returnRequested_badge' | cxTranslate }}
    </py-badge>
  </ng-template>
</ng-container>
