import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { SiteFamilyConfig } from '../../model/misc.model';
import { USER_BASE_FEATURE } from '../store/user-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: SiteFamilyConfigFacade,
      feature: USER_BASE_FEATURE,
      async: true,
      methods: ['getSiteFamilyConfig', 'getSiteFamilyConfigLoading', 'getSiteFamilyConfigLoaded'],
    }),
})
export abstract class SiteFamilyConfigFacade {
  abstract getSiteFamilyConfig(): Observable<SiteFamilyConfig | undefined>;
  abstract getSiteFamilyConfigLoading(): Observable<boolean>;
  abstract getSiteFamilyConfigLoaded(): Observable<boolean>;
}
