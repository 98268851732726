import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../../core/i18n';
import { NameMarkingTextComponent } from './name-marking-text.component';

@NgModule({
  imports: [CommonModule, NzIconModule, I18nModule, NgbTooltipModule],
  declarations: [NameMarkingTextComponent],
  exports: [NameMarkingTextComponent],
})
export class NameMarkingTextModule {}
