import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../core/i18n';
import { UrlModule } from '../../../core/routing';
import { ArticleCarouselModule } from '../article-carousel';
import { ArticleImageModule } from '../article/image/image.module';
import { ArticleInfoModule } from '../article/info/info.module';
import { MessageBannerModule } from '../message-banner/message-banner.module';
import { SpinnerModule } from '../spinner';
import { OrderEntryResolveOptionsComponent } from './order-entry-resolve-options.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    NzIconModule,
    ArticleInfoModule,
    ArticleImageModule,
    SpinnerModule,
    MessageBannerModule,
    ArticleCarouselModule,
    RouterModule,
    UrlModule,
  ],
  declarations: [OrderEntryResolveOptionsComponent],
  exports: [OrderEntryResolveOptionsComponent],
})
export class OrderEntryResolveOptionsModule {}
