import { StateUtils } from '@spartacus/core';
import { AccountingCodePage } from '../../../model';
import { UserActions } from '../actions';
import { AccountingCodesAdminActionTypes } from '../actions/accounting-codes-admin.action';

export const initialState: any = {
  lastUpdateTime: undefined,
  results: [],
};

export function reducer<T>(
  state: T = initialState,
  action: UserActions.AccountingCodesAdminActions | StateUtils.LoaderAction
): T {
  switch (action.type) {
    case AccountingCodesAdminActionTypes.SearchAccountingCodesAdminSuccess: {
      const _action = <UserActions.SearchAccountingCodesAdminSuccess>action;
      const payload: AccountingCodePage = _action.payload.searchResult;
      const results = payload.results.map((r) => r.code);

      return {
        ...state,
        lastUpdateTime: new Date().toISOString(),
        results,
      };
    }
    case AccountingCodesAdminActionTypes.SearchAccountingCodesAdminFail: {
      return {
        ...state,
        lastUpdateTime: new Date().toISOString(),
      };
    }
  }

  return state;
}
