import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InterceptorUtil, OCC_USER_ID_ANONYMOUS, OccEndpointsService, USE_CLIENT_TOKEN } from '@spartacus/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SolrSearchConfig, SolrSearchResult, SolrSearchType } from '../../model/solr-search-config';
import { ConverterService } from '../../util';
import { SOLR_SEARCH_NORMALIZER } from './converters';

@Injectable({
  providedIn: 'root',
})
export class SolrSearchConnector {
  constructor(
    private converterService: ConverterService,
    private httpClient: HttpClient,
    private occEndpointsService: OccEndpointsService
  ) {}

  private static mapConfigToEndpoint(config: SolrSearchConfig) {
    switch (config.searchType) {
      case SolrSearchType.PRODUCT:
        return 'searchProducts';
      case SolrSearchType.ARTICLE:
        return 'searchArticles';
      default:
        return 'search';
    }
  }

  query(query: string, config: SolrSearchConfig, userId: string): Observable<{ totalCount: number; result: SolrSearchResult }> {
    const endpoint = SolrSearchConnector.mapConfigToEndpoint(config);
    const { searchType, ...queryParams } = { query: query, ...config };
    let headers = new HttpHeaders();
    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }
    return this.httpClient
      .get<SolrSearchResult>(
        this.occEndpointsService.buildUrl(endpoint, {
          urlParams: {
            userId: userId,
          },
          queryParams,
        }),
        {
          headers,
          observe: 'response',
        }
      )
      .pipe(
        map((response) => ({
          totalCount: Number.parseInt(response.headers.get('X-Total-Count'), 10) || 0,
          result: this.converterService.convert(response.body, SOLR_SEARCH_NORMALIZER),
        }))
      );
  }

  queryTotalCount(query: string, config: SolrSearchConfig, userId: string): Observable<number> {
    const endpoint = SolrSearchConnector.mapConfigToEndpoint(config);
    const { searchType, ...queryParams } = { query: query, ...config };
    let headers = new HttpHeaders();
    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }
    return this.httpClient
      .head(
        this.occEndpointsService.buildUrl(endpoint, {
          urlParams: {
            userId: userId,
          },
          queryParams,
        }),
        {
          headers,
          observe: 'response',
        }
      )
      .pipe(
        catchError((error) => throwError(error)),
        map((response) => Number.parseInt(response.headers.get('X-Total-Count'), 10))
      );
  }
}
