import { DIALOG_TYPE, LAUNCH_CALLER, LayoutConfig } from '@spartacus/storefront';
import { CreateShoppingListModalComponent } from './create-shopping-list-modal.component';

export const createShoppingListModalLayoutConfig: LayoutConfig = {
  launch: {
    [LAUNCH_CALLER.CREATE_SHOPPING_LIST]: {
      inlineRoot: true,
      component: CreateShoppingListModalComponent,
      dialogType: DIALOG_TYPE.DIALOG,
      dialogOptions: {
        onTop: true,
      },
    },
  },
};
