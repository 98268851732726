import { Action } from '@ngrx/store';
import { PaginationState, SearchPageResultState } from '../../../features/store/base-feature-state';
import { loaderReducer } from '../../../state/utils/loader';
import { UserActions } from '../actions';
import { reducer as availableEntriesReducer } from './order-approval-available-buyers-entries.reducer';

export const initialState: PaginationState<SearchPageResultState> = {
  lastUpdateTime: undefined,
  pages: {},
  pagination: undefined,
  sorts: [],
};

export function reducer(state = initialState, action: Action): any {
  switch (action.type) {
    case UserActions.OrderApprovalActionTypes.LOAD_AVAILABLE_ORDER_APPROVALS_BUYERS:
    case UserActions.OrderApprovalActionTypes.LOAD_AVAILABLE_ORDER_APPROVALS_BUYERS_FAIL: {
      const availableBuyersAction = <
        | UserActions.LoadAvailableOrderApprovalsBuyers
        | UserActions.LoadAvailableOrderApprovalsBuyersFail
        | UserActions.LoadAvailableOrderApprovalsBuyersSuccess
      >action;
      const searchParams = availableBuyersAction.searchParams;
      const loaderState = loaderReducer<SearchPageResultState>(availableBuyersAction.searchType, availableEntriesReducer)(
        undefined,
        availableBuyersAction
      );
      return {
        ...state,
        pages: {
          ...state.pages,
          [searchParams.page]: loaderState,
        },
      };
    }
    case UserActions.OrderApprovalActionTypes.LOAD_AVAILABLE_ORDER_APPROVALS_BUYERS_SUCCESS: {
      const successAction = <UserActions.LoadAvailableOrderApprovalsBuyersSuccess>action;
      const payload = successAction.payload;
      const searchParams = successAction.searchParams;
      const loaderState = loaderReducer<SearchPageResultState>(successAction.searchType, availableEntriesReducer)(
        { ...state.pages[searchParams.page] },
        successAction
      );

      return {
        ...state,
        lastUpdateTime: new Date().toISOString(),
        pagination: payload.pagination,
        sorts: payload.sorts,
        pages: {
          ...state.pages,
          [searchParams.page]: loaderState,
        },
      };
    }
    default:
      return state;
  }
}
