import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class OrderReturnEntriesNotifierService {
  notifySubject$: Subject<void> = new Subject<void>();

  notifyOrderReturnEntries(): void {
    this.notifySubject$.next();
  }

  listenOnNotify(): Observable<void> {
    return this.notifySubject$.asObservable();
  }
}
