import { Pipe, PipeTransform } from '@angular/core';
import { JavaRegExpConverter } from '@spartacus/core';
import { SearchTermFacetMappingHint } from '../../core/model';

@Pipe({ name: 'searchHints' })
export class SearchHintsPipe implements PipeTransform {
  constructor(private javaRegExpConverter: JavaRegExpConverter) {}

  transform(input: string, hints: SearchTermFacetMappingHint[]): string[] {
    if (!input || !hints?.length) {
      return [];
    }

    return hints
      .map((hint) => {
        const hideIfMatchingRegEx = this.javaRegExpConverter.toJsRegExp(hint.hideIfMatchingRegEx);
        const matchingRegEx = this.javaRegExpConverter.toJsRegExp(hint.matchingRegEx);

        if (!hideIfMatchingRegEx || !matchingRegEx) {
          return undefined;
        }
        if (!hideIfMatchingRegEx.test(input)) {
          const value = input.replace(matchingRegEx, hint.hintFormat);
          if (value !== input) {
            return value;
          }
        }

        return undefined;
      })
      .filter((hint) => !!hint);
  }
}
