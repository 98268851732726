import { StateUtils } from '@spartacus/core';
import { PyStateUtils } from '../../../state/utils';
import { PROFILE_COUNTRY } from '../user-state';

export enum ProfileCountriesActionTypes {
  LoadProfileCountries = '[User] Load Profile Countries',
  LoadProfileCountriesSuccess = '[User] Load Profile Countries Success',
  LoadProfileCountriesFail = '[User] Load Profile Countries Fail',
}

export class LoadProfileCountries extends StateUtils.LoaderLoadAction {
  readonly type = ProfileCountriesActionTypes.LoadProfileCountries;

  constructor(public userId: string) {
    super(PROFILE_COUNTRY);
  }
}

export class LoadProfileCountriesSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = ProfileCountriesActionTypes.LoadProfileCountriesSuccess;

  constructor(public payload: any) {
    super(PROFILE_COUNTRY);
  }
}

export class LoadProfileCountriesFail extends PyStateUtils.LoaderFailAction {
  readonly type = ProfileCountriesActionTypes.LoadProfileCountriesFail;

  constructor(public payload?: any) {
    super(PROFILE_COUNTRY, payload);
  }
}

export type ProfileCountriesActions = LoadProfileCountries | LoadProfileCountriesSuccess | LoadProfileCountriesFail;
