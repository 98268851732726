import { Component, ElementRef, Renderer2 } from '@angular/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { BaseAbstractModalComponent, ModalCloseReason } from '../../../core/modal';

@Component({
  selector: 'py-order-approval-abort-hijack-modal',
  templateUrl: './order-approval-abort-hijack-modal.component.html',
})
export class OrderApprovalAbortHijackModalComponent extends BaseAbstractModalComponent {
  constructor(protected launchDialogService: LaunchDialogService, protected el: ElementRef, protected renderer: Renderer2) {
    super(el, launchDialogService, renderer);
  }

  confirmDecision(): void {
    this.launchDialogService.closeDialog(ModalCloseReason.ORDER_APPROVAL_ABORT_HIJACK_CONFIRMED);
  }

  dismissModal(): void {
    this.launchDialogService.closeDialog(ModalCloseReason.ORDER_APPROVAL_ABORT_HIJACK_DISMISSED);
  }
}
