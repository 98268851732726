import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HeaderResizeService {
  headerElement: HTMLElement;

  resizeObserver: ResizeObserver;

  constructor(@Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: string) {
    this.initialize();
  }

  initialize(): void {
    const isSSR = isPlatformServer(this.platformId);
    if (isSSR) {
      return;
    }
    this.resizeObserver = new ResizeObserver(
      this.debounce((entries: any) => {
        entries.forEach(() => {
          this.setHeightOffsetForHeader();
        });
      }, 50)
    );
  }

  observe(element: HTMLElement): void {
    if (!!this.resizeObserver && !!element) {
      this.resizeObserver.observe(element);
    }
  }

  unobserve(element: HTMLElement): void {
    if (!!this.resizeObserver && !!element) {
      this.resizeObserver.unobserve(element);
    }
  }

  private debounce(f: any, delay: number) {
    let timer: ReturnType<typeof setTimeout> | number = 0;
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => f.apply(this, args), delay);
    };
  }

  private setHeightOffsetForHeader() {
    const headerElement = this.document.getElementById('header');
    const currentCustomerBannerElement = this.document.getElementById('current-customer-banner');
    const asmMainUiElement = this.document.querySelector('cx-asm-main-ui');

    if (!asmMainUiElement) {
      return;
    }

    const offset = asmMainUiElement.clientHeight;

    // We only want to change the "top" value if the ASM element is visible, else the default styling should handle it.
    if (asmMainUiElement.classList?.contains('hidden')) {
      // Reset to default CSS styles.
      headerElement.style.top = '';
      currentCustomerBannerElement.style.top = '';
    } else {
      currentCustomerBannerElement.style.top = `${offset}px`;
      // media-breakpoint-up(lg)
      if (currentCustomerBannerElement.clientWidth > 992) {
        headerElement.style.top = `${offset + currentCustomerBannerElement.clientHeight}px`;
      } else {
        // 5px only makes the "border" sticky. It should be for anonymous users and mobile devices.
        headerElement.style.top = `${offset + 5}px`;
      }
    }
  }
}
