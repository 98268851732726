import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '../../core/model';

/** Outputs "Company name, Östra Hamngatan 24, 12345 Gothenburg, Sweden" **/
@Pipe({
  name: 'address',
})
export class AddressPipe implements PipeTransform {
  transform(input?: Address, showCompanyName: boolean = true): string | undefined {
    if (!input) {
      return undefined;
    }

    const { name1, name2, name3, name4, street, postalCode, city, country, computedDescription } = input;
    if (!!computedDescription) {
      return computedDescription;
    }
    let value = '';

    if (showCompanyName) {
      if (name1) {
        value = `${value}${value ? ', ' : ''}${name1}`;
      }
    }

    if (name2) {
      value = `${value}${value ? ', ' : ''}${name2}`;
    }
    if (name3) {
      value = `${value}${value ? ', ' : ''}${name3}`;
    }
    if (name4) {
      value = `${value}${value ? ', ' : ''}${name4}`;
    }
    if (street) {
      value = `${value}${value ? ', ' : ''}${street}`;
    }
    if (postalCode && city) {
      value = `${value}${value ? ', ' : ''}${postalCode} ${city}`;
    } else if (postalCode && !city) {
      value = `${value}${value ? ', ' : ''}${postalCode}`;
    } else if (!postalCode && city) {
      value = `${value}${value ? ', ' : ''}${city}`;
    }
    if (country) {
      value = `${value}${value ? ', ' : ''}${country.name}`;
    }

    return value;
  }
}
