import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { USER_ACCOUNT_CORE_FEATURE } from '@spartacus/user/account/root';

@NgModule({
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        [USER_ACCOUNT_CORE_FEATURE]: {
          module: () => import('./user-account.module').then((m) => m.UserAccountModule),
        },
      },
    }),
  ],
})
export class UserAccountFeatureModule {}
