import { Action } from '@ngrx/store';
import { SolrSearchType } from '../../../model/solr-search-config';
import { SolrSearchActions } from '../actions';
import { TotalCountState } from '../solr-search-state';

export const initialState: TotalCountState = {
  articles: undefined,
  products: undefined,
};

export function reducer(): (state: TotalCountState, action: Action) => TotalCountState {
  return (state = initialState, action: Action) => {
    switch (action.type) {
      case SolrSearchActions.SolrSearchActionTypes.LoadSolrSearchTotalCountSuccess: {
        const successAction: SolrSearchActions.LoadSolrSearchTotalCountSuccess = <
          SolrSearchActions.LoadSolrSearchTotalCountSuccess
        >action;
        switch (successAction.searchType) {
          case SolrSearchType.ARTICLE:
            return {
              ...state,
              articles: successAction.payload,
            };
          case SolrSearchType.PRODUCT:
            return {
              ...state,
              products: successAction.payload,
            };
        }
        return state;
      }
      case SolrSearchActions.SolrSearchActionTypes.LoadSolrSearchTotalCountFail: {
        const failAction = <SolrSearchActions.LoadSolrSearchTotalCountFail>action;
        switch (failAction.searchType) {
          case SolrSearchType.ARTICLE:
            return {
              ...state,
              articles: 0,
            };
          case SolrSearchType.PRODUCT:
            return {
              ...state,
              products: 0,
            };
        }
        return state;
      }
    }
    return state;
  };
}
