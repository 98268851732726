import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { I18nModule } from '../../../core/i18n';
import { IconThemeReverseModule } from '../../directives';
import { PipesModule } from '../../pipes';
import { BadgeModule } from '../badge';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { LoadMoreComponent } from '../load-more/load-more.component';
import { RadioModule } from '../radio/radio.module';
import { ReactiveFormInputFieldModule } from '../reactive-forms-input/reactive-form-input-field/reactive-form-input-field.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { DropdownComponent } from './dropdown.component';

@NgModule({
  imports: [
    CommonModule,
    NzIconModule,
    NgbDropdownModule,
    I18nModule,
    CheckboxModule,
    NgbTooltipModule,
    ReactiveFormInputFieldModule,
    RadioModule,
    InfiniteScrollModule,
    SpinnerModule,
    BadgeModule,
    LoadMoreComponent,
    PipesModule,
    IconThemeReverseModule,
  ],
  declarations: [DropdownComponent],
  exports: [DropdownComponent],
})
export class DropdownModule {}
