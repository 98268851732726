import { StateUtils } from '@spartacus/core';
import {
  ActionTokenPayload,
  CartType,
  HttpErrorModel,
  OrderEntry,
  ReelCutting,
  ReelCuttingRequest,
} from '../../../../../core/model';
import { PyStateUtils } from '../../../../../core/state';
import { CART, REEL_CUTTING_PRICE } from '../cart-state';

export enum CartEntryReelCuttingActionTypes {
  AddCartEntryReelCutting = '[Cart] Add reel cutting for cart entry',
  AddCartEntryReelCuttingSuccess = '[Cart] Add reel cutting for cart entry success',
  AddCartEntryReelCuttingFail = '[Cart] Add reel cutting for cart entry fail',

  RemoveCartEntryReelCutting = '[Cart] Remove reel cutting for cart entry',
  RemoveCartEntryReelCuttingSuccess = '[Cart] Remove reel cutting for cart entry success',
  RemoveCartEntryReelCuttingFail = '[Cart] Remove reel cutting for cart entry fail',

  CalculateReelCuttingPrice = '[Cart] Calculate reel cutting price for cart entry',
  CalculateReelCuttingPriceSuccess = '[Cart] Calculate reel cutting price for cart entry success',
  CalculateReelCuttingPriceFail = '[Cart] Calculate reel cutting price for cart entry fail',
}

interface AddCartEntryReelCuttingPayloadBase {
  cartType: CartType;
  cartId: string;
  userId: string;
  entry: OrderEntry;
  reelCutting: ReelCuttingRequest;
}

interface AddCartEntryReelCuttingPayload extends AddCartEntryReelCuttingPayloadBase, ActionTokenPayload<boolean> {}

interface AddCartEntryReelCuttingFailPayload extends AddCartEntryReelCuttingPayloadBase {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AddCartEntryReelCuttingSuccessPayload extends AddCartEntryReelCuttingPayloadBase {}

interface RemoveCartEntryReelCuttingPayloadBase {
  cartType: CartType;
  cartId: string;
  userId: string;
  entry: OrderEntry;
}

interface RemoveCartEntryReelCuttingPayload extends RemoveCartEntryReelCuttingPayloadBase, ActionTokenPayload<boolean> {}

interface RemoveCartEntryReelCuttingFailPayload extends RemoveCartEntryReelCuttingPayloadBase {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RemoveCartEntryReelCuttingSuccessPayload extends RemoveCartEntryReelCuttingPayloadBase {}

interface CalculateReelCuttingPricePayload {
  cartType: CartType;
  cartId: string;
  userId: string;
  entry: OrderEntry;
  reelCutting: ReelCuttingRequest;
  key: string;
}

interface CalculateReelCuttingPriceFailPayload extends CalculateReelCuttingPricePayload {
  error?: HttpErrorModel;
}

interface CalculateReelCuttingPriceSuccessPayload extends CalculateReelCuttingPricePayload {
  result: ReelCutting;
}

export class AddCartEntryReelCutting extends StateUtils.EntityProcessesIncrementAction {
  readonly type = CartEntryReelCuttingActionTypes.AddCartEntryReelCutting;

  constructor(public payload: AddCartEntryReelCuttingPayload) {
    super(CART, payload.cartId);
  }
}

export class AddCartEntryReelCuttingFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryReelCuttingActionTypes.AddCartEntryReelCuttingFail;

  constructor(public payload: AddCartEntryReelCuttingFailPayload) {
    super(CART, payload.cartId);
  }
}

export class AddCartEntryReelCuttingSuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryReelCuttingActionTypes.AddCartEntryReelCuttingSuccess;

  constructor(public payload: AddCartEntryReelCuttingSuccessPayload) {
    super(CART, payload.cartId);
  }
}

export class RemoveCartEntryReelCutting extends StateUtils.EntityProcessesIncrementAction {
  readonly type = CartEntryReelCuttingActionTypes.RemoveCartEntryReelCutting;

  constructor(public payload: RemoveCartEntryReelCuttingPayload) {
    super(CART, payload.cartId);
  }
}

export class RemoveCartEntryReelCuttingFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryReelCuttingActionTypes.RemoveCartEntryReelCuttingFail;

  constructor(public payload: RemoveCartEntryReelCuttingFailPayload) {
    super(CART, payload.cartId);
  }
}

export class RemoveCartEntryReelCuttingSuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryReelCuttingActionTypes.RemoveCartEntryReelCuttingSuccess;

  constructor(public payload: RemoveCartEntryReelCuttingSuccessPayload) {
    super(CART, payload.cartId);
  }
}

export class CalculateReelCuttingPrice extends StateUtils.EntityLoadAction {
  readonly type = CartEntryReelCuttingActionTypes.CalculateReelCuttingPrice;

  constructor(public payload: CalculateReelCuttingPricePayload) {
    super(REEL_CUTTING_PRICE, payload.key);
  }
}

export class CalculateReelCuttingPriceFail extends PyStateUtils.EntityFailAction {
  readonly type = CartEntryReelCuttingActionTypes.CalculateReelCuttingPriceFail;

  constructor(public payload: CalculateReelCuttingPriceFailPayload) {
    super(REEL_CUTTING_PRICE, payload.key, payload.error);
  }
}

export class CalculateReelCuttingPriceSuccess extends StateUtils.EntitySuccessAction {
  readonly type = CartEntryReelCuttingActionTypes.CalculateReelCuttingPriceSuccess;

  constructor(public payload: CalculateReelCuttingPriceSuccessPayload) {
    super(REEL_CUTTING_PRICE, payload.key, payload);
  }
}

export type CartEntryReelCuttingActions =
  | AddCartEntryReelCutting
  | AddCartEntryReelCuttingFail
  | AddCartEntryReelCuttingSuccess
  | RemoveCartEntryReelCutting
  | RemoveCartEntryReelCuttingFail
  | RemoveCartEntryReelCuttingSuccess
  | CalculateReelCuttingPrice
  | CalculateReelCuttingPriceFail
  | CalculateReelCuttingPriceSuccess;
