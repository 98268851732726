import { Injectable } from '@angular/core';
import { Permission } from '../../../../model';
import { Converter } from '../../../../util/converter.service';
import { Occ } from '../../../occ-models';

@Injectable({
  providedIn: 'root',
})
export class OccPermissionNormalizer implements Converter<Occ.Permission, Permission> {
  convert(source: any, target?: Permission): Permission {
    if (target === undefined) {
      target = {
        ...(source as any),
        orderRulePermissionType: source.permissionType,
      };
    }
    return target;
  }
}
