import { Action } from '@ngrx/store';
import { Country } from '../../../model/address.model';

export enum SiteCountryActionTypes {
  LoadSiteCountry = '[Site-context] Load site country',
  LoadSiteCountryFail = '[Site-context] Load site country fail',
  LoadSiteCountrySuccess = '[Site-context] Load site country success',
}

export class LoadSiteCountry implements Action {
  readonly type = SiteCountryActionTypes.LoadSiteCountry;

  constructor(public userId: string, public countryCode: string) {}
}

export class LoadSiteCountryFail implements Action {
  readonly type = SiteCountryActionTypes.LoadSiteCountryFail;
  constructor(public payload: any) {}
}

export class LoadSiteCountrySuccess implements Action {
  readonly type = SiteCountryActionTypes.LoadSiteCountrySuccess;
  constructor(public payload: Country) {}
}

export type SiteCountryActions = LoadSiteCountry | LoadSiteCountryFail | LoadSiteCountrySuccess;
