<ng-container *ngIf="url; else button">
  <cx-generic-link [url]="url" [target]="target">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </cx-generic-link>
</ng-container>

<ng-template #button>
  <button>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
