import { Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'py-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnDestroy {
  @HostBinding('class.modal-dialog') modalDialogClass = true;
  @HostBinding('class.visible') isVisible: boolean = false;

  @Input() centered: boolean = false;
  @HostBinding('class.modal-dialog-centered') get isCentered(): boolean {
    return this.centered;
  }

  @Input() scrollable: boolean = false;
  @HostBinding('class.modal-dialog-scrollable') get isScrollable(): boolean {
    return this.scrollable;
  }

  @Input() size?: 'sm' | 'md' | 'lg' | 'xl';
  @HostBinding('class') get classBySize(): string {
    switch (this.size) {
      case 'sm':
        return 'modal-sm';
      case 'md':
        return 'modal-md';
      case 'lg':
        return 'modal-lg';
      case 'xl':
        return 'modal-xl';
      default:
        return 'modal-md';
    }
  }

  @Input() title: string;
  @Input() subtitle: string;
  @Input() actions?: TemplateRef<any>;
  @Input() dismissText?: string;
  @Input() dismissable = true;
  @Input() forceShowDismissButton = false;
  @Input() blockDismissCoreAction = false;
  @Input() showDismissTextInHeader = false;
  @Input() showCancelButton = false;
  @Input() cancelButtonText?: string;
  @Input() error = false;
  @Input() errorText: string;
  @Input() hideFooter = false;
  @Input() hasWidePaddings = true;

  @Output() dismiss = new EventEmitter<string>();
  @Output() scrolledToBottom = new EventEmitter<void>();

  subscriptions = new Subscription();

  constructor(private launchDialogService: LaunchDialogService, protected el: ElementRef) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.launchDialogService?.dialogClose?.pipe(filter((reason) => reason !== undefined)).subscribe((reason) => {
        this.dismiss.emit(reason || this.dismissText);
      })
    );
  }

  onDismiss(reason?: string): void {
    if (this.blockDismissCoreAction) {
      this.dismiss.emit();
    } else {
      const dismissReason = this.dismissText || reason || '';
      this.launchDialogService.closeDialog(dismissReason);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
