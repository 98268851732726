import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, AuthStorageService, ForbiddenHandler as CxForbiddenHandler, OccEndpointsService } from '@spartacus/core';
import { WindowRef } from '../../../../window';
import { GlobalMessageService } from '../../../facade/global-message.service';
import { StopRequests } from '../../stop-requests.semaphore';

@Injectable({
  providedIn: 'root',
})
export class ForbiddenHandler extends CxForbiddenHandler {
  constructor(
    protected globalMessageService: GlobalMessageService,
    protected authService: AuthService,
    protected authStorageService: AuthStorageService,
    protected occEndpoints: OccEndpointsService,
    protected winRef: WindowRef,
    protected stopRequests: StopRequests
  ) {
    super(globalMessageService, authService, occEndpoints);
  }

  handleError(request: HttpRequest<any>, response?: HttpErrorResponse): void {
    if (response && response.headers.has('x-site-url')) {
      if (this.stopRequests.WILL_REDIRECT.isRaised()) {
        return;
      }
      const window = this.winRef.nativeWindow;

      if (window && window.location) {
        this.stopRequests.WILL_REDIRECT.raise();
        const token = this.authStorageService.getItem('access_token');
        window.location.href = response.headers.get('x-site-url') + (!!token ? '/#' + token : '');
      }
    } else {
      super.handleError(request);
    }
  }
}
