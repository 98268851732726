import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { AreaOfUseService } from '../../../../../core/catalog';
import { Category } from '../../../../../core/model';
import { prepareUrlForLink } from '../../../../../core/util';

@Component({
  selector: 'py-menu-area-of-use-categories',
  templateUrl: './menu-area-of-use-categories.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuAreaOfUseCategoriesComponent implements OnInit {
  @Input() activeCategory: Category;

  @Output() setActiveCategory = new EventEmitter<Category>();

  loading$: Observable<boolean>;
  categories$: Observable<Category[]>;

  constructor(private areaOfUseService: AreaOfUseService) {}

  ngOnInit() {
    this.loading$ = this.areaOfUseService.getAreasOfUseLoading();
    this.categories$ = this.areaOfUseService.getAreasOfUse();
  }

  getUrl(url: string): string {
    return prepareUrlForLink(url);
  }
}
