<div #searchHolder class="d-flex flex-column search-holder">
  <div class="d-flex input-group input-type-effects-group">
    <form
      [formGroup]="form"
      class="search-form d-flex align-items-center justify-content-between"
      (ngSubmit)="onEnterPressed()"
      (keyup.escape)="onEscapePressed()"
    >
      <i class="d-flex pl-3 pr-2 position-absolute search-icon input-icon" nzType="search" nz-icon (click)="onSubmit()"></i>
      <input
        #searchInput
        type="text"
        [id]="'search' | randomId"
        [formControlName]="'search'"
        class="form-control w-100 h-100 pl-8"
        (focus)="focus()"
        [placeholder]="'catalog.search_placeholder' | cxTranslate"
        [attr.aria-label]="'common.search_heading' | cxTranslate"
      />

      <ng-container *pyPrincipalConfiguration="'enableDisplaySearchHint'">
        <span *ngIf="searchHints$ | async as hints" class="hint-tooltip d-flex align-items-center justify-content-center">
          <i
            nz-icon
            nzType="question-circle"
            [ngbTooltip]="hintTooltipTemplate"
            [tooltipClass]="'hint-tooltip-wrapper'"
            class="d-flex"
            container="body"
          ></i>
          <ng-template #hintTooltipTemplate>
            <div class="text-center" [innerHTML]="'catalog.searchHints_tooltip' | cxTranslate"></div>
          </ng-template>
        </span>
      </ng-container>

      <button
        *ngIf="resettable && !!searchInput.value"
        type="button"
        class="btn-icon resettable-button"
        (click)="onResetSearchInput()"
      >
        <i nz-icon class="input-icon" nzType="close-circle"></i>
      </button>
    </form>
  </div>

  <ng-container
    *ngIf="{
      showSearchResults: showSearchResults$ | async,
      searchLoading: searchLoading$ | async,
      searchLoaded: searchLoaded$ | async,
      catalogItemQueryParam: catalogItemQueryParam$ | async,
      searchResults: searchResults$ | async,
      articleResultRefs: articleResultRefs$ | async,
      products: products$ | async,
      categories: categories$ | async,
      enableTogglingOfCustomerAssortment: enableTogglingOfCustomerAssortment$ | async,
      substituteRefsLoading: substituteRefsLoading$ | async,
      substituteRefsLoaded: substituteRefsLoaded$ | async,
      maxSearchResultsContainerHeight: maxSearchResultsContainerHeight$ | async
    } as observables"
  >
    <ng-template #drawerTemplate>
      <py-search-results
        class="search-results d-block"
        [style.max-height.px]="observables.maxSearchResultsContainerHeight"
        [searchFormValue]="searchFormValue"
        [searchHints]="searchHints$ | async"
        [hasDiscontinuedArticle]="hasDiscontinuedArticle"
        [substituteRefs]="substituteRefs"
        [discontinuedArticle]="discontinuedArticle"
        [discontinuedArticleViewType]="discontinuedArticleViewType"
        [form]="form"
        [enableTogglingOfCustomerAssortment]="enableTogglingOfCustomerAssortment$ | async"
        [searchLoading]="searchLoading$ | async"
        [searchLoaded]="searchLoaded$ | async"
        [substituteRefsLoading]="substituteRefsLoading$ | async"
        [substituteRefsLoaded]="substituteRefsLoaded$ | async"
        [searchResults]="searchResults$ | async"
        [discontinuedArticleViewType]="discontinuedArticleViewType"
        [catalogItemQueryParam]="catalogItemQueryParam$ | async"
        [articleResultRefs]="articleResultRefs$ | async"
        [products$]="products$ | async"
        [categories]="categories$ | async"
        [searchResultsSecondaryVariant]="searchResultsSecondaryVariant$ | async"
        [activeTab]="activeTab$ | async"
        [initialActiveTab]="searchResultsTabType$ | async"
        [minSearchLength]="minSearchLength"
        [userInputSearchInProgress]="userInputSearchInProgress$ | async"
        (discontinuedArticleSubmit)="onDiscontinuedArticleSubmit()"
        (submitSearch)="onSubmit()"
        (selectTab)="activeTab$.next($event)"
      >
      </py-search-results
    ></ng-template>
  </ng-container>
</div>
