import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '@spartacus/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { Article, ArticlePrice, CartType, HorizontalAlignment } from '../../../../core/model';
import { PrincipalConfigurationService } from '../../../../core/user';
import { getTextToDisplayInsteadOfPrice } from '../../../utils/price-utility';
import { BadgeType } from '../../badge';

@Component({
  selector: 'py-catalog-article-price',
  templateUrl: './catalog-article-price.component.html',
  styleUrls: ['./catalog-article-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogArticlePriceComponent implements OnInit {
  readonly badgeType = BadgeType;
  private articlePrice$ = new BehaviorSubject<ArticlePrice>(undefined);

  @Input() article: Article;
  @Input() quantityAndUnitValue: { quantity: number; unitCode: string };
  @Input() set articlePrice(articlePrice: ArticlePrice) {
    this.articlePrice$.next(articlePrice);
  }
  @Input() loadingPrice: boolean;
  @Input() hasActiveCampaign?: boolean = true;
  @Input() horizontalAlignment: HorizontalAlignment = HorizontalAlignment.RIGHT;
  @Input() set cartType(cartType: CartType) {
    this.textInsteadOfPrice = getTextToDisplayInsteadOfPrice(cartType);
  }
  @Input() showOutgoingBadge: boolean = false;

  @Output() loadPrice = new EventEmitter<void>();

  get articlePrice(): ArticlePrice {
    return this.articlePrice$.value;
  }

  isUserLoggedIn$: Observable<boolean>;
  enableOneTimeCustomers$: Observable<boolean>;

  enableDisplayOfPrices$: Observable<boolean>;
  showLoginToSeeYourPricesTooltip$: Observable<boolean>;
  horizontalAlignments = HorizontalAlignment;
  textInsteadOfPrice: string | undefined;

  constructor(private authService: AuthService, private principalConfigurationService: PrincipalConfigurationService) {}

  get inEcommerceQuantity(): number {
    const unit = this.article?.units?.find((u) => u.code === this.quantityAndUnitValue?.unitCode);
    return this.quantityAndUnitValue?.quantity * unit?.inEcommerceUnit || 0;
  }

  ngOnInit(): void {
    this.isUserLoggedIn$ = this.authService
      .isUserLoggedIn()
      .pipe(startWith(false), shareReplay({ refCount: true, bufferSize: 1 }));
    this.enableDisplayOfPrices$ = this.principalConfigurationService
      .isEnabled('enableDisplayOfPrices')
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }));
    this.enableOneTimeCustomers$ = this.principalConfigurationService
      .isEnabled('enableOneTimeCustomers')
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }));
    this.showLoginToSeeYourPricesTooltip$ = combineLatest([this.isUserLoggedIn$, this.enableOneTimeCustomers$]).pipe(
      map(([isUserLoggedIn, enableOneTimeCustomers]) => !isUserLoggedIn && enableOneTimeCustomers)
    );
  }
}
