<ng-container
  *ngIf="{
    bannerVisible: bannerVisible$ | async,
    settingsVisible: settingsVisible$ | async,
    consentTemplates: consentTemplates$ | async,
    getConsentsResultSuccess: getConsentsResultSuccess$ | async,
    hasRequiredConsentsNotGiven: hasRequiredConsentsNotGiven$ | async,
  } as data"
>
  <ng-container *ngIf="data.getConsentsResultSuccess">
    <div
      *ngIf="(data.bannerVisible && !data.hasRequiredConsentsNotGiven) || data.settingsVisible"
      class="overlay-body visible"
    ></div>
    <ng-container *ngIf="data.bannerVisible && !data.hasRequiredConsentsNotGiven">
      <py-consent-banner
        class="anonymous-container"
        (viewSettings)="onViewSettings()"
        (allowAll)="onAllowAll(data.consentTemplates)"
      ></py-consent-banner>
    </ng-container>
    <ng-container *ngIf="data.settingsVisible && data.consentTemplates">
      <py-consent-settings
        class="anonymous-container mh-100"
        [showCloseButton]="false"
        [consentTemplates]="data.consentTemplates"
        (saveSettings)="saveSettings($event)"
        (showBanner)="hideSettingsAndShowBanner()"
        (hideSettings)="hideSettings()"
      ></py-consent-settings>
    </ng-container>
  </ng-container>
</ng-container>
