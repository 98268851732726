import { Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { PageMetaService, PageRobotsMeta, WindowRef } from '@spartacus/core';
import { PageMetaLinkService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AltHrefLang, OpengraphMeta, PageMeta, TwitterMeta } from '../../core/cms';

@Injectable({
  providedIn: 'root',
})
export class SeoMetaService implements OnDestroy {
  constructor(
    protected ngTitle: Title,
    protected ngMeta: Meta,
    protected pageMetaService: PageMetaService,
    protected pageMetaLinkService: PageMetaLinkService,
    protected winRef: WindowRef,
    protected rendererFactory: RendererFactory2
  ) {}

  private subscription: Subscription;

  init() {
    this.subscription = this.pageMetaService
      .getMeta()
      .pipe(filter((meta): meta is PageMeta => Boolean(meta)))
      .subscribe((meta: PageMeta) => (this.meta = meta));
  }

  setAltHrefLangs(hrefLangs: AltHrefLang[] | undefined): void {
    const hrefLinks: NodeList = this.winRef.document.querySelectorAll('link[rel="alternate"]') as NodeList;
    hrefLinks?.forEach((link) => link?.parentElement.removeChild(link));

    if (!hrefLangs?.length) {
      return;
    }

    const links: HTMLLinkElement[] = hrefLangs.map((hrefLang) => {
      const link: HTMLLinkElement = this.renderer.createElement('link');
      link.rel = 'alternate';
      link.hreflang = hrefLang.hreflang;
      link.href = hrefLang.url;

      return link;
    });

    links.forEach((link) => this.renderer.appendChild(this.winRef.document.head, link));
  }

  protected set meta(meta: PageMeta) {
    this.title = meta.title;
    this.description = meta.description;
    this.robots = meta.robots;
    this.canonicalUrl = meta.canonicalUrl;
    this.opengraph = meta.opengraph;
    this.twitter = meta.twitter;
    this.altHrefLangs = meta.altHrefLangs;
  }

  protected set title(value: string | undefined) {
    this.ngTitle.setTitle(value || '');
  }

  protected set description(value: string | undefined) {
    if (value) {
      this.addTag({ name: 'description', content: value });
    } else {
      this.removeTag("name='description'");
    }
  }

  protected set robots(value: PageRobotsMeta[]) {
    if (value && value.length > 0) {
      this.addTag({ name: 'robots', content: value.join(', ') });
    } else {
      this.removeTag("name='robots'");
    }
  }

  protected set canonicalUrl(url: string | undefined) {
    this.pageMetaLinkService?.setCanonicalLink(url);
  }

  protected set altHrefLangs(hrefLangs: AltHrefLang[] | undefined) {
    this.setAltHrefLangs(hrefLangs);
  }

  protected set opengraph(value: OpengraphMeta) {
    if (value) {
      this.addTag({ property: 'og:type', content: value.type });
      this.addTag({ property: 'og:site_name', content: value.siteName });
      if (value.title) {
        this.addTag({ property: 'og:title', content: value.title });
      } else {
        this.removeTag("property='og:title'");
      }
      if (value.description) {
        this.addTag({ property: 'og:description', content: value.description });
      } else {
        this.removeTag("property='og:description'");
      }
      if (value.image) {
        this.addTag({ property: 'og:image', content: value.image });
      } else {
        this.removeTag("property='og:image'");
      }
      if (value.altImage) {
        this.addTag({ property: 'og:image:alt', content: value.altImage });
      } else {
        this.removeTag("property='og:image:alt'");
      }
      if (value.url) {
        this.addTag({ property: 'og:url', content: value.url });
      } else {
        this.removeTag("property='og:url'");
      }
    } else {
      this.removeTag("property='og:type'");
      this.removeTag("property='og:site_name'");
      this.removeTag("property='og:title'");
      this.removeTag("property='og:description'");
      this.removeTag("property='og:image'");
      this.removeTag("property='og:image:alt'");
      this.removeTag("property='og:url'");
    }
  }

  protected set twitter(value: TwitterMeta) {
    if (value) {
      this.addTag({ name: 'twitter:card', content: value.card });
      this.addTag({ name: 'twitter:site', content: value.site });
      this.addTag({ name: 'twitter:creator', content: value.creator });
      if (value.title) {
        this.addTag({ name: 'twitter:title', content: value.title });
      } else {
        this.removeTag("name='twitter:title'");
      }
      if (value.description) {
        this.addTag({ name: 'twitter:description', content: value.description });
      } else {
        this.removeTag("name='twitter:description'");
      }
      if (value.image) {
        this.addTag({ name: 'twitter:image', content: value.image });
      } else {
        this.removeTag("name='twitter:image'");
      }
      if (value.altImage) {
        this.addTag({ name: 'twitter:image:alt', content: value.altImage });
      } else {
        this.removeTag("name='twitter:image:alt'");
      }
    } else {
      this.removeTag("name='twitter:card'");
      this.removeTag("name='twitter:site'");
      this.removeTag("name='twitter:creator'");
      this.removeTag("name='twitter:title'");
      this.removeTag("name='twitter:description'");
      this.removeTag("name='twitter:image'");
      this.removeTag("name='twitter:image:alt'");
    }
  }

  protected addTag(meta: MetaDefinition) {
    if (meta.content) {
      this.ngMeta.updateTag(meta);
    }
  }

  protected removeTag(selector: string) {
    this.ngMeta.removeTag(selector);
  }

  protected get renderer(): Renderer2 {
    return this.rendererFactory.createRenderer(null, null);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
