import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AsmAuthStorageService, TokenTarget } from '@spartacus/asm/root';
import { AuthHttpHeaderService, InterceptorUtil, USE_CLIENT_TOKEN, USE_CUSTOMER_SUPPORT_AGENT_TOKEN } from '@spartacus/core';
import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { UserIdService } from '../../auth';
import { REMOVE_AUTH_HEADER } from '../../remove-auth-header';

/**
 * Add x-opti-asm-user http header to requests which do not have user in path, so backend knows who is emulated customer
 */
@Injectable({ providedIn: 'root' })
export class AsmHeaderInterceptor implements HttpInterceptor {
  constructor(
    protected userIdService: UserIdService,
    protected authHttpHeaderService: AuthHttpHeaderService,
    protected asmAuthStorageService: AsmAuthStorageService
  ) {}

  // Avoid CORS errors by not including x-opti-asm-user on certain endpoints (example: ESVCX-4609)
  private readonly regexp: RegExp = new RegExp('/[^/]+/(users|orgUsers)/[^/]+|/[^/]+/(basesites)\\?|/authorizationserver/');

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const request$ = combineLatest([this.userIdService.isEmulated(), this.asmAuthStorageService.getTokenTarget()]).pipe(
      take(1),
      switchMap(([isCustomerEmulated, tokenTarget]) => {
        if (this.shouldAddAsmUserHeader(request, tokenTarget, isCustomerEmulated)) {
          return this.userIdService.getUserId().pipe(
            map((userId) => {
              const updatedHeaders = request.headers.set('x-opti-asm-user', userId);
              return request.clone({ headers: updatedHeaders });
            })
          );
        }
        if (this.shouldRemoveAuthHeader(request, tokenTarget, isCustomerEmulated)) {
          const headers = InterceptorUtil.createHeader(REMOVE_AUTH_HEADER, true, request.headers);
          request = request.clone({ headers });
        }
        return of(request);
      })
    );

    return request$.pipe(switchMap((req) => next.handle(req)));
  }

  private shouldAddAsmUserHeader(request: HttpRequest<any>, tokenTarget: TokenTarget, isEmulated: boolean): boolean {
    // The client token does not have the necessary roles to impersonate other users,
    // so we should not add x-opti-asm-user in this case.
    return (
      isEmulated && tokenTarget === TokenTarget.CSAgent && !this.isClientTokenRequest(request) && !this.regexp.test(request.url)
    );
  }

  private shouldRemoveAuthHeader(request: HttpRequest<any>, tokenTarget: TokenTarget, isEmulated: boolean): boolean {
    // There are cases when spartacus adds Authorization headers when we don't want it, such as when logged in
    // as agent in ASM without impersonating users.  See ESVCX-7375.
    return (
      this.authHttpHeaderService.shouldAddAuthorizationHeader(request) &&
      !this.isCSAgentTokenRequest(request) &&
      tokenTarget === TokenTarget.CSAgent &&
      !isEmulated
    );
  }

  protected isClientTokenRequest(request: HttpRequest<any>): boolean {
    const isRequestMapping = InterceptorUtil.getInterceptorParam(USE_CLIENT_TOKEN, request.headers);
    return Boolean(isRequestMapping);
  }

  protected isCSAgentTokenRequest(request: HttpRequest<any>): boolean {
    const isRequestWithCSAgentToken = InterceptorUtil.getInterceptorParam(USE_CUSTOMER_SUPPORT_AGENT_TOKEN, request.headers);
    return Boolean(isRequestWithCSAgentToken);
  }
}
