import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import {
  Article,
  CartType,
  LoaderError,
  MiniShoppingList,
  MiniShoppingListsSearchParams,
  OrderEntry,
  Pagination,
  PrincipalSharedShoppingListFlags,
  PrincipalSharedShoppingListSearchParams,
  SearchParams,
  ShoppingList,
  ShoppingListPermission,
  Unit,
  UpdatePermissionType,
  UpdateShoppingListPermissionsParams,
  WritableShoppingListsSearchParams,
} from '../../model';
import { MiniShoppingListSearchParamsState, USER_BASE_FEATURE, WritableShoppingListSearchParamsState } from '../store/user-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: ShoppingListFacade,
      feature: USER_BASE_FEATURE,
      async: true,
      methods: [
        'searchMiniShoppingLists',
        'updateSearchParamsForMiniShoppingLists',
        'getMiniShoppingListsSearchResult',
        'getMiniShoppingListsSearchParams',
        'getMiniShoppingListsSearchResultPagination',
        'getMiniShoppingListsSearching',
        'resetMiniShoppingListsState',
        'searchWritableShoppingLists',
        'updateSearchParamsForWritableShoppingLists',
        'getWritableShoppingListsSearchResult',
        'getWritableShoppingListsSearchParams',
        'getWritableShoppingListsSearchResultPagination',
        'getWritableShoppingListsSearching',
        'getShoppingList',
        'getShoppingListLoading',
        'getShoppingListLoaded',
        'updateShoppingListName',
        'updateShoppingListNameSuccess',
        'updateShoppingListNameLoading',
        'resetUpdateShoppingListNameState',
        'updateShoppingListDescription',
        'updateShoppingListDescriptionSuccess',
        'updateShoppingListDescriptionLoading',
        'resetUpdateShoppingListDescriptionState',
        'updateShoppingListContactPerson',
        'deleteShoppingList',
        'deleteShoppingListLoading',
        'updateShoppingListContactPersonLoading',
        'updateShoppingListContactPersonSuccess',
        'resetUpdateShoppingListContactPerson',
        'deleteShoppingListError',
        'deleteShoppingListSuccess',
        'resetDeleteShoppingListState',
        'createShoppingList',
        'createShoppingListFromCart',
        'createShoppingListFromAnotherShoppingList',
        'createShoppingListSuccess',
        'createShoppingListAndAddEntry',
        'searchShoppingListPrincipals',
        'getShoppingListPrincipals',
        'getShoppingListPrincipalsSearchResultsLoading',
        'getShoppingListPrincipalsSearchResultsSuccess',
        'updatePermissions',
        'getUpdatePermissionsSuccess',
        'getUpdatePermissionsLoading',
        'resetUpdateShoppingListPermissionsState',
        'resetShoppingListsState',
        'resetShoppingListState',
        'isStable',
        'addEntry',
        'updateEntry',
        'updateEntryNote',
        'updateEntryStatisticsCode',
        'replaceEntry',
        'removeEntry',
        'removeEntries',
        'exportShoppingListAsPdf',
        'exportShoppingListAsXlsx',
      ],
    }),
})
export abstract class ShoppingListFacade {
  abstract searchMiniShoppingLists(searchParams: MiniShoppingListsSearchParams): void;
  abstract updateSearchParamsForMiniShoppingLists(searchParams: MiniShoppingListsSearchParams): void;
  abstract getMiniShoppingListsSearchResult(key: string): Observable<MiniShoppingList[] | undefined>;
  abstract getMiniShoppingListsSearchParams(key: string): Observable<MiniShoppingListSearchParamsState | undefined>;
  abstract getMiniShoppingListsSearchResultPagination(key: string): Observable<Pagination | undefined>;
  abstract getMiniShoppingListsSearching(key: string): Observable<boolean>;
  abstract resetMiniShoppingListsState(): void;
  abstract searchWritableShoppingLists(searchParams: WritableShoppingListsSearchParams): void;
  abstract updateSearchParamsForWritableShoppingLists(searchParams: WritableShoppingListsSearchParams): void;
  abstract getWritableShoppingListsSearchResult(key: string): Observable<MiniShoppingList[] | undefined>;
  abstract getWritableShoppingListsSearchParams(key: string): Observable<WritableShoppingListSearchParamsState | undefined>;
  abstract getWritableShoppingListsSearchResultPagination(key: string): Observable<Pagination | undefined>;
  abstract getWritableShoppingListsSearching(key: string): Observable<boolean>;
  abstract getShoppingList(code: string, shoppingListKey?: string): Observable<ShoppingList | undefined>;
  abstract getShoppingListLoading(code: string): Observable<boolean>;
  abstract getShoppingListLoaded(code: string): Observable<boolean>;
  abstract updateShoppingListName(code: string, name: string): void;
  abstract updateShoppingListNameSuccess(): Observable<boolean>;
  abstract updateShoppingListNameLoading(): Observable<boolean>;
  abstract resetUpdateShoppingListNameState(): void;
  abstract updateShoppingListDescription(code: string, description: string): void;
  abstract updateShoppingListDescriptionSuccess(): Observable<boolean>;
  abstract updateShoppingListDescriptionLoading(): Observable<boolean>;
  abstract resetUpdateShoppingListDescriptionState(): void;
  abstract updateShoppingListContactPerson(code: string, customerId: string): void;
  abstract deleteShoppingList(code: string): void;
  abstract deleteShoppingListLoading(): Observable<boolean>;
  abstract updateShoppingListContactPersonLoading(): Observable<boolean>;
  abstract updateShoppingListContactPersonSuccess(): Observable<boolean>;
  abstract resetUpdateShoppingListContactPerson(): void;
  abstract deleteShoppingListError(): Observable<LoaderError | undefined>;
  abstract deleteShoppingListSuccess(): Observable<boolean>;
  abstract createShoppingListSuccess(id: string): Observable<boolean>;
  abstract resetDeleteShoppingListState(): void;
  abstract createShoppingList(name: string, description: string): Observable<string>;
  abstract createShoppingListFromCart(cartType: CartType, name: string, description: string): Observable<string>;
  abstract createShoppingListFromAnotherShoppingList(code: string, name: string, description: string): Observable<string>;
  abstract createShoppingListAndAddEntry(
    name: string,
    description: string,
    articleCode: string,
    quantity: number,
    unit: Unit,
    displayNumber?: string
  ): Observable<string>;
  abstract searchShoppingListPrincipals(code: string, searchParams: PrincipalSharedShoppingListSearchParams): void;
  abstract getShoppingListPrincipals(
    searchParams: PrincipalSharedShoppingListSearchParams
  ): Observable<PrincipalSharedShoppingListFlags[]>;
  abstract getShoppingListPrincipalsSearchResultsLoading(searchParams: SearchParams): Observable<boolean>;
  abstract getShoppingListPrincipalsSearchResultsSuccess(searchParams: SearchParams): Observable<boolean>;
  abstract updatePermissions(
    code: string,
    permissions: ShoppingListPermission[],
    params?: UpdateShoppingListPermissionsParams,
    updatePermissionType?: UpdatePermissionType
  ): void;
  abstract getUpdatePermissionsSuccess(): Observable<boolean>;
  abstract getUpdatePermissionsLoading(): Observable<boolean>;
  abstract resetUpdateShoppingListPermissionsState(): void;
  abstract resetShoppingListsState(): void;
  abstract resetShoppingListState(code: string): void;
  abstract isStable(code: string): Observable<boolean>;
  abstract addEntry(code: string, articleCode: string, quantity: number, unit: Unit, note?: string, displayNumber?: string): void;
  abstract updateEntry(code: string, entry: OrderEntry, quantity: number, unit: Unit): void;
  abstract updateEntryNote(code: string, entry: OrderEntry, note: string): void;
  abstract updateEntryStatisticsCode(code: string, entry: OrderEntry, statisticsCode: string): void;
  abstract replaceEntry(code: string, entry: OrderEntry, replacementArticle: Article): void;
  abstract removeEntry(code: string, entry: OrderEntry): void;
  abstract removeEntries(code: string, entries: OrderEntry[]): void;
  abstract exportShoppingListAsPdf(code: string, displayPrices: boolean): Observable<Blob>;
  abstract exportShoppingListAsXlsx(code: string, displayPrices: boolean): Observable<Blob>;
}
