<py-responsive-table
  key="ref"
  [entries]="articleResultRefs || []"
  [rowTemplate]="row"
  [listItemTemplate]="listItem"
  [omitTbody]="true"
  [breakpoint]="850"
>
  <thead *ngIf="showHeader && !(enableSecondaryArticleRowVariant$ | async)">
    <!-- Table heading is not displayed when using secondary article row variant -->
    <tr>
      <th></th>
      <th>{{ 'catalog.article_heading' | cxTranslate }}</th>
      <th>{{ 'catalog.quantityAndUnit_heading' | cxTranslate }}</th>
      <th class="text-right">{{ 'catalog.price_heading' | cxTranslate }}</th>
      <th></th>
    </tr>
  </thead>

  <thead *ngIf="showDiscontinuedArticleHeader">
    <tr>
      <th class="px-0 pt-4 pb-2" colspan="5" style="font-size: 16px">
        <ng-container *ngTemplateOutlet="headerTitle"></ng-container>
      </th>
    </tr>
  </thead>

  <ng-container slot="list-header">
    <div *ngIf="showDiscontinuedArticleHeader" class="border-bottom px-0 py-2">
      <ng-container *ngTemplateOutlet="headerTitle"></ng-container>
    </div>
  </ng-container>
</py-responsive-table>

<ng-template #row let-entry>
  <tbody
    #catalogArticleListComponent
    py-catalog-article-row
    [expandedByDefault]="expandedByDefault"
    [attr.data-articleref]="entry?.ref"
    [articleResultRef]="entry"
    [substituteBadgeType]="getSubstituteBadgeType(entry)"
    [queryParams]="queryParams"
    [partialRenderingEnabled]="partialRenderingEnabled"
    [additionalArticleActions]="additionalArticleActions"
    [hideAddToShoppingListButton]="hideAddToShoppingListButton"
    [showConsignmentStockLevel]="showConsignmentStockLevel"
    [cartType]="cartType"
    [useArticleCartTypeInsteadOfGivenToAddToCart]="useArticleCartTypeInsteadOfGivenToAddToCart"
    [enableSecondaryArticleRowVariant]="enableSecondaryArticleRowVariant$ | async"
    [showAttributesInSeparateColumn]="showAttributesInSeparateColumn"
    [freeTextSearch]="freeTextSearch"
    class="w-100"
  ></tbody>
</ng-template>

<ng-template #listItem let-entry>
  <li
    #catalogArticleListComponent
    py-catalog-article-item
    [expandedByDefault]="expandedByDefault"
    [attr.data-articleref]="entry?.ref"
    [articleResultRef]="entry"
    [substituteBadgeType]="getSubstituteBadgeType(entry)"
    [queryParams]="queryParams"
    [partialRenderingEnabled]="partialRenderingEnabled"
    [additionalArticleActions]="additionalArticleActions"
    [hideAddToShoppingListButton]="hideAddToShoppingListButton"
    [showConsignmentStockLevel]="showConsignmentStockLevel"
    [cartType]="cartType"
    [useArticleCartTypeInsteadOfGivenToAddToCart]="useArticleCartTypeInsteadOfGivenToAddToCart"
    [enableSecondaryArticleRowVariant]="enableSecondaryArticleRowVariant$ | async"
    [freeTextSearch]="freeTextSearch"
  ></li>
</ng-template>

<ng-template #headerTitle>
  {{ 'catalog.replacementAlternatives_title' | cxTranslate }}
</ng-template>
