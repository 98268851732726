import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../core/i18n';
import { UrlModule } from '../../../core/routing';
import { NavSideScrollModule, PrincipalConfigurationDirectiveModule, ScreenSizeModule } from '../../directives';
import { PipesModule } from '../../pipes';
import { ActionButtonModule } from '../action-button';
import { BadgeModule } from '../badge';
import { CatalogArticleListModule } from '../catalog-article';
import { GenericLinkModule } from '../generic-link';
import { NoResultsModule } from '../no-results/no-results.module';
import { NotificationModule } from '../notification';
import { ProductListItemLoaderModule } from '../product-list-item-loader/product-list-item-loader.module';
import { ReactiveFormCheckboxSliderModule } from '../reactive-forms-input/reactive-form-checkbox-slider/reactive-form-checkbox-slider.module';
import { SpinnerModule } from '../spinner';
import { DiscontinuedArticleHeaderComponent } from './discontinued-article-header/discontinued-article-header.component';
import { SearchHintsComponent } from './search-results/search-hints/search-hints.component';
import { SearchResultsContentPrimaryComponent } from './search-results/search-results-content-primary/search-results-content-primary.component';
import { SearchResultsContentSecondaryComponent } from './search-results/search-results-content-secondary/search-results-content-secondary.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { SearchComponent } from './search.component';

@NgModule({
  declarations: [
    SearchComponent,
    DiscontinuedArticleHeaderComponent,
    SearchResultsComponent,
    SearchHintsComponent,
    SearchResultsContentPrimaryComponent,
    SearchResultsContentSecondaryComponent,
  ],
  imports: [
    CommonModule,
    NzIconModule,
    I18nModule,
    UrlModule,
    ReactiveFormsModule,
    GenericLinkModule,
    RouterModule,
    NoResultsModule,
    ProductListItemLoaderModule,
    SpinnerModule,
    CatalogArticleListModule,
    ReactiveFormCheckboxSliderModule,
    NotificationModule,
    PipesModule,
    PrincipalConfigurationDirectiveModule,
    NgbTooltipModule,
    NgbNavModule,
    BadgeModule,
    ActionButtonModule,
    ScreenSizeModule,
    NavSideScrollModule,
  ],
  exports: [SearchComponent],
})
export class SearchModule {}
