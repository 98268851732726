import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig } from '@spartacus/core';
import { provideConfig } from '../../core/config/config-providers';
import { ActiveOrderProcessModule } from './active-order-process/active-order-process.module';
import { CHECKOUT_CORE_FEATURE, CHECKOUT_FEATURE } from './base/store/checkout-state';

@NgModule({
  imports: [ActiveOrderProcessModule],
  providers: [
    DatePipe,
    provideConfig(<CmsConfig>{
      featureModules: {
        [CHECKOUT_FEATURE]: {
          module: () => import('./checkout.module').then((m) => m.CheckoutModule),
          cmsComponents: ['CmsCheckoutFlexComponent'],
        },
        [CHECKOUT_CORE_FEATURE]: {
          module: () => import('./base/checkout-base.module').then((m) => m.CheckoutBaseModule),
        },
      },
    }),
  ],
})
export class CheckoutFeatureModule {}
