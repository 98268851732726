import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { B2BPermissionOrderListItem, EntitiesModel, Permission, PermissionUsage, SearchConfig, ShipToList } from '../../../model';
import { PERMISSIONS_NORMALIZER, PERMISSION_NORMALIZER, PERMISSION_ORDERS_NORMALIZER } from '../../../permission/connectors';
import { BuyerPermissionAdapter } from '../../../permission/connectors/buyer-permission';
import { ConverterService } from '../../../util';

@Injectable()
export class OccBuyerPermissionAdapter implements BuyerPermissionAdapter {
  constructor(protected http: HttpClient, protected occEndpoints: OccEndpointsService, protected converter: ConverterService) {}

  loadBuyerPermission(userId: string, permissionCode: string): Observable<Permission> {
    const url = this.occEndpoints.buildUrl('buyerOrderRule', { urlParams: { userId, permissionCode } });
    return this.http.get<Permission>(url).pipe(this.converter.pipeable(PERMISSION_NORMALIZER));
  }

  loadBuyerPermissionsList(userId: string, params?: SearchConfig): Observable<EntitiesModel<Permission>> {
    const url = this.occEndpoints.buildUrl('buyerOrderRules', {
      urlParams: { userId },
      queryParams: params,
    });
    return this.http.get<EntitiesModel<Permission>>(url).pipe(this.converter.pipeable(PERMISSIONS_NORMALIZER));
  }

  getBuyerPermissionUsage(userId: string, permissionCode: string): Observable<PermissionUsage> {
    const url = this.occEndpoints.buildUrl('buyerOrderRuleUsage', { urlParams: { userId, permissionCode } });
    return this.http.get<PermissionUsage>(url);
  }

  getPermissionGeneralUsage(userId: string, permissionCode: string): Observable<PermissionUsage> {
    const url = this.occEndpoints.buildUrl('buyerOrderRuleGeneralUsage', { urlParams: { userId, permissionCode } });
    return this.http.get<PermissionUsage>(url);
  }

  getBuyerPermissionOrders(
    userId: string,
    permissionCode: string,
    params?: SearchConfig
  ): Observable<EntitiesModel<B2BPermissionOrderListItem>> {
    const url = this.occEndpoints.buildUrl('buyerOrderRuleOrders', {
      urlParams: { userId, permissionCode },
      queryParams: params,
    });
    return this.http
      .get<EntitiesModel<B2BPermissionOrderListItem>>(url)
      .pipe(this.converter.pipeable(PERMISSION_ORDERS_NORMALIZER));
  }

  getBuyerPermissionShipTos(userId: string, permissionCode: string): Observable<ShipToList> {
    const url = this.occEndpoints.buildUrl('userOrderRuleShipTos', { urlParams: { userId, permissionCode } });
    return this.http.get<ShipToList>(url);
  }
}
