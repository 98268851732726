import { DIALOG_TYPE, LAUNCH_CALLER, LayoutConfig } from '@spartacus/storefront';
import { PalletFlagFormModalComponent } from './pallet-flag-form-modal.component';

export const palletFlagFormModalComponentlLayoutConfig: LayoutConfig = {
  launch: {
    [LAUNCH_CALLER.PALLET_FLAG_FORM]: {
      inlineRoot: true,
      component: PalletFlagFormModalComponent,
      dialogType: DIALOG_TYPE.DIALOG,
      dialogOptions: {
        onTop: true,
      },
    },
  },
};
