import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  ɵstringify as stringify,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ResizeSensorService } from '../../services';
import { ResizeEvent } from '../../services/resize-sensor/resize-event';

interface ContainerSizeOptions {
  min?: number;
  max?: number;
}

type ContainerSizeContext = any;

/**
 * Add the template content to the DOM if ref width fits in given constraints.
 *
 * NOTE: Providing a ref is mandatory.
 *
 * <ng-container *pyContainerSize="{ min: 200, max: 800 }; ref templateOrElement; else otherTemplate">
 *   <!-- content goes here -->
 * </ng-container>
 *
 * <ng-template #otherTemplate></ng-template>
 */
@Directive({ selector: '[pyContainerSize]' })
export class ContainerSizeDirective implements OnInit, OnDestroy {
  @Input()
  public set pyContainerSize(options: ContainerSizeOptions) {
    this.containerSizeOptions = options;
  }

  @Input()
  public set pyContainerSizeRef(ref: TemplateRef<any> | ElementRef | HTMLElement) {
    this.ref = ref;
    this.attachResizeSensor();
  }

  @Input()
  public set pyContainerSizeElse(templateRef: TemplateRef<ContainerSizeContext> | undefined) {
    assertTemplate('pyContainerSizeElse', templateRef);
    this.elseTemplateRef = templateRef;
  }

  @Input()
  public set pyContainerSizeThen(templateRef: TemplateRef<ContainerSizeContext> | undefined) {
    assertTemplate('pyContainerSizeThen', templateRef);
    this.thenTemplateRef = templateRef;
  }

  private resize$ = new Subject<ResizeEvent>();
  private subscriptions = new Subscription();
  private ref: TemplateRef<any> | ElementRef | HTMLElement;
  private containerSizeOptions?: ContainerSizeOptions;
  private resizeSensor?: ResizeObserver;
  private thenTemplateRef: TemplateRef<ContainerSizeContext> | null = null;
  private elseTemplateRef: TemplateRef<ContainerSizeContext> | null = null;

  private get element(): HTMLElement {
    if (this.ref instanceof HTMLElement) {
      return this.ref;
    }
    const elementRef = this.ref instanceof TemplateRef ? this.ref.elementRef : this.ref;
    return elementRef.nativeElement;
  }

  constructor(
    templateRef: TemplateRef<ContainerSizeContext>,
    private viewContainer: ViewContainerRef,
    private resizeSensorService: ResizeSensorService,
    private cd: ChangeDetectorRef
  ) {
    this.thenTemplateRef = templateRef;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.resize$
        .pipe(
          map((event) => event.width),
          distinctUntilChanged(),
          filter((width) => width > 0),
          map((width) => this.isMinConstraintFulfilled(width) && this.isMaxConstraintFulfilled(width)),
          distinctUntilChanged()
        )
        .subscribe((shouldRenderMainTemplate) => {
          this.updateView(shouldRenderMainTemplate ? this.thenTemplateRef : this.elseTemplateRef);
        })
    );
  }

  ngOnDestroy(): void {
    this.resizeSensor?.disconnect();
    this.subscriptions.unsubscribe();
  }

  private attachResizeSensor(): void {
    this.resizeSensor?.disconnect();
    this.resizeSensor = this.resizeSensorService.initialize(this.element, (event) => {
      this.resize$.next(event);
    });
  }

  private isMinConstraintFulfilled(width: number): boolean {
    return this.containerSizeOptions?.min ? width >= this.containerSizeOptions.min : true;
  }

  private isMaxConstraintFulfilled(width: number): boolean {
    return this.containerSizeOptions?.max ? width <= this.containerSizeOptions.max : true;
  }

  private updateView(ref?: TemplateRef<any>): void {
    this.viewContainer.clear();
    if (ref != null) {
      this.viewContainer.createEmbeddedView(ref);
    }
    this.cd.detectChanges();
  }
}

function assertTemplate(property: string, templateRef: TemplateRef<any> | null): void {
  const isTemplateRefOrNull = !!(!templateRef || templateRef.createEmbeddedView);
  if (!isTemplateRefOrNull) {
    throw new Error(`${property} must be a TemplateRef, but received '${stringify(templateRef)}'.`);
  }
}
