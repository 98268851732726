import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { B2BPermissionOrderListItem, EntitiesModel, Permission, PermissionUsage, SearchConfig, ShipToList } from '../../../model';
import { BuyerPermissionAdapter } from './buyer-permission.adapter';

@Injectable({
  providedIn: 'root',
})
export class BuyerPermissionConnector {
  constructor(protected adapter: BuyerPermissionAdapter) {}

  getBuyerPermission(userId: string, permissionCode: string): Observable<Permission> {
    return this.adapter.loadBuyerPermission(userId, permissionCode);
  }

  getBuyerPermissionsList(userId: string, params?: SearchConfig): Observable<EntitiesModel<Permission>> {
    return this.adapter.loadBuyerPermissionsList(userId, params);
  }

  getBuyerPermissionUsage(userId: string, permissionCode: string): Observable<PermissionUsage> {
    return this.adapter.getBuyerPermissionUsage(userId, permissionCode);
  }

  getPermissionGeneralUsage(userId: string, permissionCode: string): Observable<PermissionUsage> {
    return this.adapter.getPermissionGeneralUsage(userId, permissionCode);
  }

  getBuyerPermissionOrders(
    userId: string,
    permissionCode: string,
    params?: SearchConfig
  ): Observable<EntitiesModel<B2BPermissionOrderListItem>> {
    return this.adapter.getBuyerPermissionOrders(userId, permissionCode, params);
  }

  getBuyerPermissionShipTos(userId: string, permissionCode: string): Observable<ShipToList> {
    return this.adapter.getBuyerPermissionShipTos(userId, permissionCode);
  }
}
