import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InterceptorUtil, OCC_USER_ID_ANONYMOUS, OccEndpointsService, USE_CLIENT_TOKEN } from '@spartacus/core';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { PrincipalConfiguration, PrincipalConfigurationUpdateValue } from '../../model';
import { Occ } from '../../occ/occ-models';
import { ConverterService } from '../../util';
import { PRINCIPAL_CONFIGURATION_NORMALIZER } from './converters';

@Injectable({
  providedIn: 'root',
})
export class PrincipalConfigurationConnector {
  constructor(
    private httpClient: HttpClient,
    private occEndpointsService: OccEndpointsService,
    private converterService: ConverterService
  ) {}

  getValues(userId: string): Observable<PrincipalConfiguration[]> {
    let headers = new HttpHeaders();
    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }
    const url = this.occEndpointsService.buildUrl('principalConfigurations', { urlParams: { userId } });
    return this.httpClient
      .get<Occ.PrincipalConfigurationList>(url, { headers })
      .pipe(pluck('configurations'), this.converterService.pipeableMany(PRINCIPAL_CONFIGURATION_NORMALIZER));
  }

  updateValue(userId: string, configValue: PrincipalConfigurationUpdateValue): Observable<PrincipalConfiguration> {
    const url = this.occEndpointsService.buildUrl('principalConfigurations', {
      urlParams: { userId },
    });
    return this.httpClient
      .post<Occ.PrincipalConfiguration>(url, configValue)
      .pipe(this.converterService.pipeable(PRINCIPAL_CONFIGURATION_NORMALIZER));
  }
}
