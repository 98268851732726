import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PuffCollectionContentTagsComponent } from './puff-collection-content-tags.component';

@NgModule({
  imports: [CommonModule],
  declarations: [PuffCollectionContentTagsComponent],
  exports: [PuffCollectionContentTagsComponent],
})
export class CmsPuffCollectionContentTagsModule {}
