import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'py-reactive-form-checkbox-slider',
  templateUrl: './reactive-form-checkbox-slider.component.html',
})
export class ReactiveFormCheckboxSliderComponent {
  @Input()
  group: UntypedFormGroup;

  @Input()
  fieldName: string;

  @Input()
  leftLabel?: string;

  @Input()
  rightLabel?: string;

  @Input()
  addMargin?: boolean = true;

  @Input()
  disabled?: boolean;

  @Input()
  rightMargin?: boolean = false;

  @Input()
  centerLabel?: boolean = false;

  @Input()
  tooltip?: string;
}
