<py-modal
  [actions]="templateActions"
  [showCancelButton]="true"
  [cancelButtonText]="'common.cancel_action' | cxTranslate"
  [title]="
    isEditMode ? ('cart.palletFlag.editPalletFlags_title' | cxTranslate) : ('cart.palletFlag.addPalletFlags_title' | cxTranslate)
  "
  [scrollable]="true"
  [centered]="true"
  [cxFocus]="focusConfig"
  (esc)="closeModal('escape pressed')"
>
  <py-pallet-flag-form [form]="form"></py-pallet-flag-form>
</py-modal>

<ng-template #templateActions>
  <py-action-button
    [type]="'button'"
    [disabled]="!isEditMode"
    [buttonClass]="'btn btn-danger'"
    (click)="onClear()"
    [buttonLabel]="'common.remove_action' | cxTranslate"
  ></py-action-button>
  <py-action-button
    [type]="'button'"
    [buttonClass]="'btn btn-primary w-100'"
    (click)="onSave()"
    [disabled]="!form.dirty || form.invalid"
    [buttonLabel]="'common.save_action' | cxTranslate"
  >
  </py-action-button>
</ng-template>
