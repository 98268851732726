<py-responsive-table
  [entries]="loading ? [].constructor(3) : tableData"
  [rowTemplate]="loading ? rowLoading : rowTemplate"
  [listItemTemplate]="loading ? listItemLoading : listItemTemplate"
  [omitTbody]="true"
>
  <thead [ngClass]="additionalStyleClass">
    <tr>
      <th colspan="4">
        <div class="h6 skeleton" *ngIf="!deliveryDate && loading"></div>
        <div *ngIf="!loading" class="d-flex align-items-center">
          <ng-container [ngTemplateOutlet]="tableHeader"></ng-container>
        </div>
      </th>
    </tr>
  </thead>

  <div slot="list-header" class="list-header d-flex align-items-center" [ngClass]="additionalStyleClass">
    <div class="h6 skeleton" *ngIf="!deliveryDate && loading"></div>
    <ng-container *ngIf="!loading">
      <ng-container [ngTemplateOutlet]="tableHeader"></ng-container>
    </ng-container>
  </div>

  <ng-template #tableHeader>
    <i nz-icon [nzType]="additionalStyleClass === SECONDARY_STYLING_CLASS_NAME ? 'delivery-truck-moving' : 'calendar'"></i>
    <h6>
      {{ deliveryDate ? (deliveryDate | cxDate) : '-' }}
    </h6>
  </ng-template>

  <tfoot *ngIf="order && (showPrice || order.totalWeight)" [ngClass]="additionalStyleClass">
    <ng-container *ngIf="additionalStyleClass === SECONDARY_STYLING_CLASS_NAME">
      <ng-container [ngTemplateOutlet]="orderWeightTemplate"></ng-container>
    </ng-container>

    <ng-container *pyPrincipalConfiguration="'enableDisplayOfPrices'">
      <ng-container *ngIf="showPrice">
        <py-order-price-summary
          [order]="order"
          [template]="additionalStyleClass === SECONDARY_STYLING_CLASS_NAME ? secondaryPriceRowTemplate : primaryPriceRowTemplate"
          pyRemoveHost
        ></py-order-price-summary>

        <ng-template #primaryPriceRowTemplate let-label="label" let-price="price" let-emphasize="emphasize">
          <tr [class.discount]="price?.value < 0" [class.h6]="emphasize">
            <td colspan="2">{{ label }}</td>
            <td colspan="2">
              {{ textInsteadOfPrice ? (textInsteadOfPrice | cxTranslate) : (price | price : 'symbol-narrow' : true) }}
            </td>
          </tr>
        </ng-template>

        <ng-template
          #secondaryPriceRowTemplate
          let-label="label"
          let-price="price"
          let-emphasize="emphasize"
          let-tooltipText="tooltipText"
        >
          <tr class="pb-2">
            <td colspan="2" class="label py-3">
              {{ label }}
              <ng-container *ngIf="tooltipText">
                <i
                  class="ml-2 py-color-black order-condition-icon"
                  nz-icon
                  nzType="question-circle"
                  [ngbTooltip]="tooltipText"
                ></i>
              </ng-container>
            </td>
            <td colspan="2" class="value py-3" [class.discount]="price?.value < 0" [class.emphasize]="emphasize">
              {{
                textInsteadOfPrice ? (textInsteadOfPrice | cxTranslate) : (price || { value: 0 } | price : 'symbol-narrow' : true)
              }}
            </td>
          </tr>
        </ng-template>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!additionalStyleClass">
      <ng-container [ngTemplateOutlet]="orderWeightTemplate"></ng-container>
    </ng-container>

    <ng-template #orderWeightTemplate>
      <tr *ngIf="order.totalWeight">
        <td colspan="2" class="label">{{ 'checkout.summary.totalWeight_heading' | cxTranslate }}</td>
        <td colspan="2" class="value">
          <span>{{ order.totalWeight.value | cxNumeric : '1.2-2' }}</span>
          <span class="ml-1">{{ order.totalWeight.unit?.code }}</span>
        </td>
      </tr>
    </ng-template>
  </tfoot>

  <ul slot="list-footer" *ngIf="order && (showPrice || order.totalWeight)" [ngClass]="additionalStyleClass">
    <ng-container *ngIf="additionalStyleClass === SECONDARY_STYLING_CLASS_NAME">
      <ng-container [ngTemplateOutlet]="orderWeightListTemplate" [ngTemplateOutletContext]="{ columnView: true }"></ng-container>
    </ng-container>

    <ng-container *ngIf="showPrice">
      <py-order-price-summary
        [order]="order"
        [template]="
          additionalStyleClass === SECONDARY_STYLING_CLASS_NAME ? secondaryPriceListItemTemplate : primaryPriceListItemTemplate
        "
        pyRemoveHost
      ></py-order-price-summary>

      <ng-template #primaryPriceListItemTemplate let-label="label" let-price="price" let-emphasize="emphasize">
        <li class="d-flex justify-content-between" [class.h6]="emphasize">
          <span>{{ label }}</span>
          <span class="text-right">{{
            textInsteadOfPrice ? (textInsteadOfPrice | cxTranslate) : (price | price : 'symbol-narrow' : true)
          }}</span>
        </li>
      </ng-template>

      <ng-template
        #secondaryPriceListItemTemplate
        let-label="label"
        let-price="price"
        let-emphasize="emphasize"
        let-tooltipText="tooltipText"
      >
        <li class="d-flex flex-column py-0 py-md-3">
          <div class="label pt-3 pt-md-0">
            {{ label }}
            <ng-container *ngIf="tooltipText">
              <i class="ml-2 py-color-black order-condition-icon" nz-icon nzType="question-circle" [ngbTooltip]="tooltipText"></i>
            </ng-container>
          </div>
          <div
            class="text-left value pt-1 pt-md-0 pb-3 pb-md-0"
            [class.discount]="price?.value < 0"
            [class.emphasize]="emphasize"
          >
            {{ textInsteadOfPrice ? (textInsteadOfPrice | cxTranslate) : (price | price : 'symbol-narrow' : true) }}
          </div>
        </li>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="!additionalStyleClass">
      <ng-container [ngTemplateOutlet]="orderWeightListTemplate"></ng-container>
    </ng-container>

    <ng-template #orderWeightListTemplate let-columnView="columnView">
      <li
        *ngIf="order.totalWeight"
        class="d-flex"
        [ngClass]="{ 'justify-content-between': !columnView, 'flex-column py-0 py-md-3': columnView }"
      >
        <span [ngClass]="{ label: columnView }">{{ 'checkout.summary.totalWeight_heading' | cxTranslate }}</span>
        <span [ngClass]="{ 'text-right': !columnView, 'text-left pt-1 pt-md-0 pb-3 pb-md-0 value': columnView }">
          <span>{{ order.totalWeight.value | cxNumeric : '1.2-2' }}</span>
          <span class="ml-1">{{ order.totalWeight.unit?.code }}</span>
        </span>
      </li>
    </ng-template>
  </ul>
</py-responsive-table>

<ng-template #rowTemplate let-entry>
  <tbody
    py-order-entry-row
    [entry]="entry.orderLine"
    [article]="entry.article$ | async"
    [showPrice]="showPrice"
    [showSubstitutesArticles]="false"
    [showBrowseReplacementButton]="false"
    [enableSecondaryArticleRowVariant]="enableSecondaryArticleRowVariant$ | async"
    [cartType]="cartType"
  ></tbody>
</ng-template>

<ng-template #listItemTemplate let-entry>
  <li
    py-order-entry-list-item
    [entry]="entry.orderLine"
    [article]="entry.article$ | async"
    [showSubstitutesArticles]="false"
    [showBrowseReplacementButton]="false"
    [enableSecondaryArticleRowVariant]="enableSecondaryArticleRowVariant$ | async"
    [cartType]="cartType"
  ></li>
</ng-template>

<ng-template #rowLoading>
  <tbody py-order-entry-row-loading></tbody>
</ng-template>

<ng-template #listItemLoading>
  <li py-order-list-item-loading></li>
</ng-template>
