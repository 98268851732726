import { Pipe, PipeTransform } from '@angular/core';

/*
 * A pipe to sort a list by object key. The object key can be either a string or a boolean (default: string)
 */
@Pipe({
  name: 'orderBy',
})
export class ArrayOrderByPipe implements PipeTransform {
  transform(array: any[], field: string, type: 'string' | 'boolean' | 'number' = 'string', descending: boolean = true): any[] {
    if (!array?.length || !Array.isArray(array)) {
      return;
    }

    if (!field || field === 'none') {
      return array;
    }

    if (type === 'string') {
      return this.sortString(array, field, descending);
    } else if (type === 'number') {
      return this.sortNumber(array, field, descending);
    } else {
      return this.sortBoolean(array, field, descending);
    }
  }

  sortNumber(array: any[], field, descending) {
    return array.sort((a: any, b: any) => {
      {
        return descending ? b[field] - a[field] : a[field] - b[field];
      }
    });
  }

  sortBoolean(array: any[], field, descending) {
    return array.sort((a: any, b: any) => {
      if (a[field] === true) {
        return descending ? -1 : 1;
      } else if (b[field] === true) {
        return descending ? 1 : -1;
      } else {
        return 0;
      }
    });
  }

  sortString(array: any[], field, descending) {
    return array.sort((a: any, b: any) => {
      if (a[field]?.toLowerCase() < b[field]?.toLowerCase()) {
        return descending ? 1 : -1;
      } else if (a[field]?.toLowerCase() > b[field]?.toLowerCase()) {
        return descending ? -1 : 1;
      } else {
        return 0;
      }
    });
  }
}
