import { StateUtils } from '@spartacus/core';
import { SiteFamilyConfig } from '../../../model';
import { UserActions } from '../actions/index';
import { SiteFamilyConfigActionTypes } from '../actions/site-family-config.action';

export const initialState: SiteFamilyConfig = undefined;

export function reducer(
  state = initialState,
  action: UserActions.SiteFamilyConfigActions | StateUtils.LoaderAction
): SiteFamilyConfig {
  switch (action.type) {
    case SiteFamilyConfigActionTypes.LoadSiteFamilyConfigSuccess: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}
