import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Subject, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Article, OrderEntry, ReelCuttingRequest } from '../../../../core/model';
import { ReelCuttingModalData } from '../reel-cutting-modal/reel-cutting-modal.component';

@Component({
  selector: 'py-reel-cutting-button',
  templateUrl: './reel-cutting-button.component.html',
  styleUrls: ['./reel-cutting-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReelCuttingButtonComponent implements OnDestroy {
  @Input() article: Article;
  @Input() entry: OrderEntry;
  @Input() disabled: boolean = false;
  @Input() showButton: boolean = false;
  @Input() showPrice: boolean = false;
  @Input() translationKeyPrefix: string = 'cart';

  @Output() handleSave = new EventEmitter<ReelCuttingRequest>();
  @Output() handleRemove = new EventEmitter<void>();

  subscriptions = new Subscription();

  constructor(private launchDialogService: LaunchDialogService) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async open(): Promise<void> {
    const modalSaved$ = new Subject<ReelCuttingRequest>();
    const modalRemoved$ = new Subject<void>();

    const modalData: ReelCuttingModalData = {
      article: this.article,
      entry: this.entry,
      modalSaved$: modalSaved$,
      modalRemoved$: modalRemoved$,
    };

    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.REEL_CUTTING, undefined, modalData);

    this.subscriptions.add(
      modalSaved$
        .pipe(
          filter((reelCuttingRequest) => reelCuttingRequest !== undefined),
          take(1)
        )
        .subscribe((reelCuttingRequest) => {
          this.handleSave.emit(reelCuttingRequest);
        })
    );

    this.subscriptions.add(
      modalRemoved$.pipe(take(1)).subscribe(() => {
        this.handleRemove.emit();
      })
    );
  }
}
