import { CurrencyPipe, getCurrencySymbol } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform, inject, isDevMode } from '@angular/core';
import { LoggerService } from '@spartacus/core';
import { CurrencyService } from '../../site-context/facade';

@Pipe({
  name: 'cxCurrency',
})
export class CxCurrencyPipe extends CurrencyPipe implements PipeTransform {
  protected logger = inject(LoggerService);
  constructor(@Inject(LOCALE_ID) protected localeId: string, protected currencyService: CurrencyService) {
    super(null);
  }

  transform(
    value: number | string,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string
  ): string | null;
  transform(
    value: null | undefined,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string
  ): null;
  transform(value: any, digitsInfo = '1.2-2'): string | null {
    return super.transform(value, this.getCurrency(), this.getDisplay(), digitsInfo, this.localeId);
  }

  private getDisplay() {
    return this.getCurrency() === 'CHF' ? 'code' : 'symbol-narrow';
  }

  private getCurrency() {
    const currency = this.getActiveCurrency();
    try {
      this.getCurrencySymbol(currency);
      return currency;
    } catch {
      this.reportMissingCurrencyData(currency);
      return 'EUR';
    }
  }

  private getCurrencySymbol(currency: string) {
    return getCurrencySymbol(currency, 'narrow');
  }

  private getActiveCurrency(): string {
    let activeCurrency: string;
    this.currencyService
      .getActive()
      .subscribe((currency) => (activeCurrency = currency))
      .unsubscribe();
    return activeCurrency;
  }

  private reportMissingCurrencyData(currency: string) {
    if (isDevMode()) {
      this.logger.warn(`pyCurrency pipe: No currency data registered for '${currency}'.`);
    }
  }
}
