import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../../core/i18n';
import { PipesModule } from '../../../pipes';
import { MediaModule } from '../../media';
import { OrderReturnUploadedFilesGalleryModalModule } from '../order-return-uploaded-files-gallery-modal/order-return-uploaded-files-gallery-modal.module';
import { OrderReturnEntryInfoComponent } from './order-return-entry-info.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    NzIconModule,
    NgbTooltipModule,
    PipesModule,
    MediaModule,
    OrderReturnUploadedFilesGalleryModalModule,
  ],
  declarations: [OrderReturnEntryInfoComponent],
  exports: [OrderReturnEntryInfoComponent],
})
export class OrderReturnEntryInfoModule {}
