import { CurrencyPipe, getCurrencySymbol } from '@angular/common';
import { ChangeDetectorRef, Inject, LOCALE_ID, Optional, Pipe, PipeTransform, inject, isDevMode } from '@angular/core';
import { LoggerService } from '@spartacus/core';
import { take } from 'rxjs/operators';
import { TranslationService } from '../../core/i18n';
import { Price } from '../../core/model';
import { CurrencyService } from '../../core/site-context';
import { PrincipalConfigurationService } from '../../core/user';

export enum CurrencyDisplay {
  None = '',
  Code = 'code',
  Symbol = 'symbol',
  SymbolNarrow = 'symbol-narrow',
}

@Pipe({
  name: 'price',
})
export class PricePipe implements PipeTransform {
  private cachedTranslations = new Map<string, string>();
  protected logger = inject(LoggerService);

  constructor(
    @Inject(LOCALE_ID) private localeId: string,
    private translationService: TranslationService,
    private currencyService: CurrencyService,
    private principalConfigurationService: PrincipalConfigurationService,
    private currencyPipe: CurrencyPipe,
    @Optional() private cd?: ChangeDetectorRef
  ) {
    this.getTranslation('catalog.priceNotAvailableAbbreviation_hint');
    this.getTranslation('catalog.priceNotAvailable_hint');
  }

  transform(
    input?: Price,
    display: string | CurrencyDisplay = CurrencyDisplay.None,
    useFormattedValueIfExists = false,
    digitsInfo = '1.2-2'
  ): string {
    if (!input) {
      return '';
    }

    if (!this.enableDisplayOfPrices()) {
      return this.cachedTranslations.get('catalog.priceNotAvailable_hint');
    }

    const { value, currencyIso } = input;

    if (value === 0) {
      return this.cachedTranslations.get('catalog.priceNotAvailableAbbreviation_hint');
    }

    if (useFormattedValueIfExists && input.formattedValue) {
      return input.formattedValue;
    }

    return this.currencyPipe.transform(
      value,
      this.getCurrencySymbol(!!currencyIso ? currencyIso : undefined),
      display,
      digitsInfo,
      this.localeId
    );
  }

  private getCurrencySymbol(currencyIso: string = this.getActiveCurrency()) {
    try {
      getCurrencySymbol(currencyIso, 'narrow');
      return currencyIso;
    } catch {
      this.reportMissingCurrencyData(currencyIso);
      return 'EUR';
    }
  }

  private getActiveCurrency(): string {
    let activeCurrency: string;
    this.currencyService
      .getActive()
      .subscribe((currency) => (activeCurrency = currency))
      .unsubscribe();
    return activeCurrency;
  }

  private enableDisplayOfPrices(): boolean {
    let isEnabled: boolean;
    this.principalConfigurationService
      .isEnabled('enableDisplayOfPrices')
      .subscribe((value) => (isEnabled = value))
      .unsubscribe();
    return isEnabled;
  }

  private reportMissingCurrencyData(currency: string) {
    if (isDevMode()) {
      this.logger.warn(`No currency data registered for '${currency}'.`);
    }
  }

  private getTranslation(key: string = 'catalog.priceNotAvailableAbbreviation_hint') {
    if (!this.cachedTranslations.get(key)) {
      this.translationService
        .translate(key, undefined, true)
        .pipe(take(1))
        .subscribe((val) => {
          this.cachedTranslations.set(key, val);
          this.cd?.markForCheck();
        })
        .unsubscribe();
    }
  }
}
