import { APP_INITIALIZER, NgModule } from '@angular/core';
import { DirectionService } from '@spartacus/storefront';
import { provideDefaultConfig } from '../../core/config';
import { defaultDirectionConfig } from './config/default-direction.config';

export function initHtmlDirAttribute(directionService: DirectionService): () => Promise<void> {
  return () => directionService.initialize();
}

/**
 * Provides a configuration and APP_INITIALIZER to add the correct (language drive) html direction.
 */
@NgModule({
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: initHtmlDirAttribute,
      deps: [DirectionService],
    },
    provideDefaultConfig(defaultDirectionConfig),
  ],
})
export class DirectionModule {}
