import { Injectable } from '@angular/core';
import { UserEventBuilder as CxUserEventBuilder, StateEventService } from '@spartacus/core';
import { UpdateCredentialsActionTypes } from '../store/actions/credentials-action';
import { ShoppingListEntryActionTypes } from '../store/actions/shopping-list-entry.action';
import { RemoveShoppingListEntriesSuccess, UpdateCredentialsSuccessEvent } from './user.events';

@Injectable({
  providedIn: 'root',
})
export class UserEventBuilder extends CxUserEventBuilder {
  constructor(protected stateEventService: StateEventService) {
    super(stateEventService);
    this.registerAdditionalEvents();
  }

  private registerAdditionalEvents(): void {
    this.registerUpdateCredentialsSuccess();
    this.registerRemoveShoppingListEntriesSuccess();
  }

  private registerUpdateCredentialsSuccess(): void {
    this.stateEventService.register({
      action: UpdateCredentialsActionTypes.UpdateCredentialsSuccess,
      event: UpdateCredentialsSuccessEvent,
    });
  }

  private registerRemoveShoppingListEntriesSuccess(): void {
    this.stateEventService.register({
      action: ShoppingListEntryActionTypes.RemoveShoppingListEntriesSuccess,
      event: RemoveShoppingListEntriesSuccess,
    });
  }
}
