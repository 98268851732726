import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { PROCESS_FEATURE, StateModule } from '@spartacus/core';
import { metaReducers, reducerProvider, reducerToken } from './reducers/index';

@NgModule({
  imports: [StateModule, StoreModule.forFeature(PROCESS_FEATURE, reducerToken, { metaReducers })],
  providers: [reducerProvider],
})
export class ProcessStoreModule {}
