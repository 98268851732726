import { StateUtils } from '@spartacus/core';
import { OrderCardPage } from '../../../model';
import { UserActions } from '../actions';
import { OrderCardsAdminActionTypes } from '../actions/order-cards-admin.action';

export const initialState: any = {
  lastUpdateTime: undefined,
  results: [],
};

export function reducer<T>(state: T = initialState, action: UserActions.OrderCardsAdminActions | StateUtils.LoaderAction): T {
  switch (action.type) {
    case OrderCardsAdminActionTypes.SearchOrderCardsAdminSuccess: {
      const _action = <UserActions.SearchOrderCardsAdminSuccess>action;
      const payload: OrderCardPage = _action.payload.searchResult;
      const results = payload.results.map((r) => r.code);

      return {
        ...state,
        lastUpdateTime: new Date().toISOString(),
        results,
      };
    }
    case OrderCardsAdminActionTypes.SearchOrderCardsAdmin:
    case OrderCardsAdminActionTypes.SearchOrderCardsAdminFail: {
      return {
        ...state,
        lastUpdateTime: new Date().toISOString(),
      };
    }
  }

  return state;
}
