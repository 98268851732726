<py-modal
  [actions]="templateActions"
  [showCancelButton]="false"
  [title]="'orderApproval.abortHijackModal_title' | cxTranslate"
  [scrollable]="true"
  [centered]="true"
  [cxFocus]="focusConfig"
  (esc)="closeModal('escape pressed')"
>
  <p>{{ 'orderApproval.abortHijackModal_text' | cxTranslate }}</p>
</py-modal>

<ng-template #templateActions>
  <button pyPreventDoubleClick type="button" class="btn btn-plain-secondary" (click)="dismissModal()">
    {{ 'common.noAndCancel_action' | cxTranslate }}
  </button>
  <button pyPreventDoubleClick type="button" class="btn btn-danger" (click)="confirmDecision()">
    {{ 'common.yesAndAbort_action' | cxTranslate }}
  </button>
</ng-template>
