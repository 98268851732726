import { Inject, Injectable, Optional } from '@angular/core';
import { JSONLD_PRODUCT_BUILDER, JsonLdBuilder, SchemaBuilder } from '@spartacus/storefront';
import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Product } from '../../../../../core/model';
import { SitePrefixService } from '../../../../../core/site-context';
import { CurrentProductService } from '../../../../../shared/services/product/current-product.service';

export interface JsonLdProductData {
  product: Product;
  sitePrefix: string;
}

/**
 * Adds the minimal structured data for the product, see https://schema.org/product.
 * The actual data collection is delegated to `JsonLdBuilder`s, which can be injected
 * using the `JSONLD_PRODUCT_BUILDER` token.
 */
@Injectable({
  providedIn: 'root',
})
export class ProductSchemaBuilder implements SchemaBuilder {
  constructor(
    private currentProductService: CurrentProductService,
    @Optional()
    @Inject(JSONLD_PRODUCT_BUILDER)
    protected builders: JsonLdBuilder<JsonLdProductData>[],
    protected sitePrefixService: SitePrefixService
  ) {}

  build(): Observable<any> {
    return combineLatest([this.currentProductService.getProduct(), this.sitePrefixService.getActive()]).pipe(
      switchMap(([product, activeSitePrefix]) => {
        if (product) {
          return combineLatest(this.collect(product, activeSitePrefix)).pipe(map((res: {}[]) => Object.assign({}, ...res)));
        }
        return of({});
      })
    );
  }

  protected collect(product: Product, activeSitePrefix: string): Observable<any>[] {
    if (!product || !product.code) {
      return [];
    }
    const builders = this.builders
      ? this.builders.map((builder) => builder.build({ product, sitePrefix: activeSitePrefix }))
      : [];

    return [
      of({
        '@context': 'http://schema.org',
        '@type': 'Product',
      }),
      ...builders,
    ];
  }
}
