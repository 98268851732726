import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '@spartacus/core';
import { EMPTY, Observable, Subject, merge, of, timer } from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  filter,
  map,
  mapTo,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { UserIdService } from '../../../../auth';
import { LoginLogoutActionTypes } from '../../../../auth/user-auth/store/actions/login-logout.action';
import { GlobalMessageType, isErrorGloballyHandled } from '../../../../global-message';
import { GlobalMessageActions } from '../../../../global-message/store';
import { ErrorModel } from '../../../../model';
import { PriceFileEntry, PriceFileStatus } from '../../../../model/price-file.model';
import { FeedFacade, PrincipalConfigurationService } from '../../../../user';
import { UserActions } from '../../../../user/store/actions';
import { PrincipalConfigurationActionTypes } from '../../../../user/store/actions/principal-configuration.action';
import { normalizeHttpError, withdrawOn } from '../../../../util';
import { AppActions } from '../../../../util/store/actions';
import { NoOpAction } from '../../../../util/store/actions/app.action';
import { PriceFileConfig } from '../../config/price-file-config';
import { PriceFileConnector } from '../../connector/price-file.connector';
import { PriceFileService } from '../../services';
import { PriceFileActions, PriceFilesActions } from '../actions';
import {
  PriceFileRequestActionTypes,
  PriceFileRequestActions,
  RequestPriceFile,
  RequestPriceFileFail,
  RequestPriceFileSuccess,
} from '../actions/price-file-request.actions';
import {
  AutoOrderPriceFileFail,
  AutoOrderPriceFileSuccess,
  CancelPriceFile,
  DeletePriceFile,
  PriceFileActionTypes,
  SetPriceFileStatusSuccess,
} from '../actions/price-file.actions';
import { PriceFilesActionTypes } from '../actions/price-files.action';

@Injectable()
export class PriceFileEffects {
  private contextChange$ = this.actions$.pipe(ofType(LoginLogoutActionTypes.Logout));

  searchPriceFiles$: Observable<PriceFilesActions.LoadPriceFilesActionSuccess | PriceFilesActions.LoadPriceFilesActionFail> =
    createEffect(() =>
      this.actions$.pipe(
        ofType(PriceFilesActionTypes.LoadPriceFiles),
        map((action: PriceFilesActions.LoadPriceFilesAction) => action),
        mergeMap((action) =>
          this.connector.loadPage(action.userId, action.searchParams).pipe(
            map((data) => new PriceFilesActions.LoadPriceFilesActionSuccess(data, action.searchParams)),
            catchError((error) =>
              of(new PriceFilesActions.LoadPriceFilesActionFail(action.searchParams, normalizeHttpError(error)))
            )
          )
        ),
        withdrawOn(this.contextChange$)
      )
    );

  requestPriceFile$: Observable<PriceFileRequestActions> = createEffect(() =>
    this.actions$.pipe(
      ofType(PriceFileRequestActionTypes.RequestPriceFile),
      switchMap((action: RequestPriceFile) =>
        this.connector.request(action.userId, action.payload).pipe(
          debounceTime(100), // TODO: Remove, needed this for now because of the connector
          map((data) => new RequestPriceFileSuccess(data, action.searchParams)),
          catchError((error) => of(new RequestPriceFileFail(normalizeHttpError(error))))
        )
      ),
      withdrawOn(this.contextChange$)
    )
  );

  handleSuccessForRequestPriceFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PriceFileRequestActionTypes.RequestPriceFileSuccess),
      mapTo(
        new GlobalMessageActions.AddMessage({
          text: {
            key: 'priceFiles.order.createdSuccess_message',
          },
          type: GlobalMessageType.MSG_TYPE_CONFIRMATION,
        })
      )
    )
  );

  handleFailureForRequestPriceFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PriceFileRequestActionTypes.RequestPriceFileFail),
      mapTo(
        new GlobalMessageActions.AddMessage({
          text: {
            key: 'priceFiles.order.createdFailure_message',
          },
          type: GlobalMessageType.MSG_TYPE_ERROR,
        })
      )
    )
  );

  deletePriceFile$: Observable<PriceFileActions.DeletePriceFileSuccess | PriceFileActions.DeletePriceFileFail> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PriceFileActionTypes.DeletePriceFile),
        switchMap((action: DeletePriceFile) => {
          const code = [].concat(action.meta.entityId).join();
          return this.connector.delete(action.userId, code).pipe(
            map(() => new PriceFileActions.DeletePriceFileSuccess(code, action.searchParams)),
            catchError((error) => of(new PriceFileActions.DeletePriceFileFail(code, normalizeHttpError(error))))
          );
        }),
        withdrawOn(this.contextChange$)
      )
  );

  cancelPriceFile$: Observable<PriceFileActions.CancelPriceFileSuccess | PriceFileActions.CancelPriceFileFail> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PriceFileActionTypes.CancelPriceFile),
        switchMap((action: CancelPriceFile) => {
          const code = [].concat(action.meta.entityId).join();
          return this.connector.cancel(action.userId, code).pipe(
            map(() => new PriceFileActions.CancelPriceFileSuccess(code, action.searchParams)),
            catchError((error) => of(new PriceFileActions.CancelPriceFileFail(code, normalizeHttpError(error))))
          );
        }),
        withdrawOn(this.contextChange$)
      )
  );

  handleSuccessForCancelPriceFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PriceFileActionTypes.CancelPriceFileSuccess),
      tap(() => this.feedService.loadFeedData()),
      map(() => new AppActions.NoOpAction())
    )
  );

  loadPriceFile$: Observable<PriceFileActions.LoadPriceFileSuccess | PriceFileActions.LoadPriceFileFail> = createEffect(() =>
    this.actions$.pipe(
      ofType(PriceFileActionTypes.SetPriceFileStatusSuccess),
      switchMap((action: SetPriceFileStatusSuccess) => {
        const code = [].concat(action.meta.entityId).join();
        return this.connector.load(action.payload.userId, code).pipe(
          map((data) => new PriceFileActions.LoadPriceFileSuccess(code, data)),
          catchError((error) => of(new PriceFileActions.LoadPriceFileFail(code, normalizeHttpError(error))))
        );
      }),
      withdrawOn(this.contextChange$)
    )
  );

  autoOrderPriceFile$: Observable<PriceFileActions.AutoOrderPriceFileSuccess | PriceFileActions.AutoOrderPriceFileFail> =
    createEffect(() =>
      this.actions$.pipe(
        ofType(PriceFileActionTypes.AutoOrderPriceFile),
        switchMap((action: PriceFileActions.AutoOrderPriceFile) =>
          this.connector.autoOrderPriceFile(action.userId).pipe(
            map(() => new AutoOrderPriceFileSuccess()),
            catchError((error) => of(new AutoOrderPriceFileFail(normalizeHttpError(error))))
          )
        ),
        withdrawOn(this.contextChange$)
      )
    );

  handleSuccessForAutoOrderPriceFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PriceFileActionTypes.AutoOrderPriceFileSuccess),
      switchMap(() => [
        new PriceFileActions.ClearPriceFile(),
        new UserActions.UpdateHasOngoingSoldToPriceFileRequestForFeedData(true),
      ])
    )
  );

  handleFailForAutoOrderPriceFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PriceFileActionTypes.AutoOrderPriceFileFail),
      map((action: PriceFileActions.AutoOrderPriceFileFail) => action.payload),
      switchMap((payload) => {
        if (!isErrorGloballyHandled(payload?.status)) {
          if (Array.isArray(payload?.details)) {
            const messageActionsArray: GlobalMessageActions.AddMessage[] = payload.details
              .filter((errorDetail: ErrorModel) => errorDetail.message)
              .map((errorDetail: ErrorModel) => {
                return new GlobalMessageActions.AddMessage({
                  text: { raw: errorDetail.message },
                  type: GlobalMessageType.MSG_TYPE_ERROR,
                });
              });

            if (messageActionsArray.length) {
              return messageActionsArray;
            }
          }
          return [
            new GlobalMessageActions.AddMessage({
              text: { key: 'priceFiles.automaticPriceFileOrderFailed_message' },
              type: GlobalMessageType.MSG_TYPE_ERROR,
            }),
          ];
        } else {
          return [new AppActions.NoOpAction()];
        }
      })
    )
  );

  public stopPollingPriceFileStatus$ = new Subject<void>();
  checkPriceFileStatus$: Observable<
    | PriceFileActions.SetPriceFileStatusFail
    | PriceFileActions.SetPriceFileStatusSuccess
    | GlobalMessageActions.AddMessage
    | NoOpAction
  > = isPlatformBrowser(this.platformId)
    ? createEffect(() =>
        this.actions$.pipe(
          ofType(
            PrincipalConfigurationActionTypes.LoadPrincipalConfigurationValuesSuccess,
            PriceFileRequestActionTypes.RequestPriceFileSuccess
          ),
          withLatestFrom(
            this.userIdService.getUserId(),
            this.authService.isUserLoggedIn(),
            this.principalConfigurationService.isEnabled('enablePriceFiles')
          ),
          filter(([, , loggedIn, isEnabled]) => loggedIn && isEnabled),
          switchMap(([, userId]) =>
            this.service
              .getPriceFilesByStatus([PriceFileStatus.PROCESSING, PriceFileStatus.REQUESTED, PriceFileStatus.CREATED])
              .pipe(switchMap((priceFiles) => this.getPriceFilePollingTimer(userId, priceFiles)))
          ),
          withdrawOn(this.contextChange$)
        )
      )
    : EMPTY;

  getPriceFilePollingTimer(userId, priceFiles: PriceFileEntry[]) {
    return timer(
      this.config.priceFile.priceFileStatusInitialDelay * 1000,
      this.config.priceFile.priceFileStatusInterval * 1000
    ).pipe(
      takeUntil(this.stopPollingPriceFileStatus$),
      tap(() => {
        if (!priceFiles || priceFiles?.length === 0) {
          this.stopPollingPriceFileStatus$.next();
        }
      }),
      switchMap(() =>
        merge(
          ...priceFiles?.map((priceFile) =>
            this.getPriceFileAction(userId, priceFile.code).pipe(
              tap((action) => {
                if (action.payload?.status === PriceFileStatus.FINISHED) {
                  priceFiles = priceFiles.filter((p) => p.code !== priceFile.code);
                }
              })
            )
          )
        )
      )
    );
  }

  private getPriceFileAction(userId, code) {
    return this.connector.getPriceFileStatus(userId, code).pipe(
      concatMap((status) => {
        const actions = [];
        if (status === PriceFileStatus.FINISHED) {
          actions.push(
            new GlobalMessageActions.AddMessage({
              text: {
                key: 'priceFiles.fileCreatedSuccess_message',
              },
              type: GlobalMessageType.MSG_TYPE_CONFIRMATION,
              timeout: 6000,
            })
          );
          actions.push(new PriceFileActions.SetPriceFileStatusSuccess(code, { userId, status }));
        } else {
          actions.push(new NoOpAction());
        }

        return actions;
      }),
      catchError((error) => {
        this.stopPollingPriceFileStatus$.next();
        return of(new PriceFileActions.SetPriceFileStatusFail(code, normalizeHttpError(error)));
      })
    );
  }

  constructor(
    private actions$: Actions,
    private connector: PriceFileConnector,
    private service: PriceFileService,
    private authService: AuthService,
    private userIdService: UserIdService,
    private config: PriceFileConfig,
    private principalConfigurationService: PrincipalConfigurationService,
    private feedService: FeedFacade,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}
}
