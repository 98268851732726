<ng-container *ngIf="isDirectServicesLink; else menuServicesDropdownTemplate">
  <py-header-navigation-item
    [active]="(isServicesActive$ | async) && !(hasActiveMenuItem$ | async)"
    [routerLink]="{ cxRoute: 'services' } | cxUrl"
    routerLinkActive
    #rla="routerLinkActive"
  >
    {{ 'navigation.services_title' | cxTranslate }}
  </py-header-navigation-item>
</ng-container>

<ng-template #menuServicesDropdownTemplate>
  <div>
    <py-header-navigation-item id="services" [active]="show" (click)="toggleDrawer()">
      {{ 'navigation.services_title' | cxTranslate }}
      <i class="ml-2" [class.rotate]="show" nz-icon nzType="down"></i>
    </py-header-navigation-item>
  </div>
</ng-template>

<ng-template #drawerTemplate>
  <div
    py-megamenu
    [templateColumnLeft]="megamenuColumnLeft"
    [templateColumnRight]="megamenuColumnRight"
    [showBorder]="false"
    [columnsAreEqualWidth]="true"
  ></div>

  <ng-template #megamenuColumnLeft>
    <ng-container *ngIf="taggedServices$ | async as taggedServices">
      <h4 class="mb-2">{{ 'navigation.services_title' | cxTranslate }}</h4>

      <ul py-menu-services-items [uids]="taggedServices">
        <li py-megamenu-list-item [isDirectLink]="true">
          <cx-generic-link [routerUrl]="{ cxRoute: 'services' } | cxUrl">
            {{ 'navigation.viewAllServices_action' | cxTranslate }}
          </cx-generic-link>
        </li>
      </ul>
    </ng-container>
  </ng-template>

  <ng-template #megamenuColumnRight>
    <ng-container *ngIf="taggedEducations$ | async as taggedEducations">
      <h4 class="mb-2">{{ 'navigation.educations_title' | cxTranslate }}</h4>

      <ul py-menu-services-items [uids]="taggedEducations">
        <li py-megamenu-list-item [isDirectLink]="true">
          <cx-generic-link [routerUrl]="{ cxRoute: 'educations' } | cxUrl">
            {{ 'navigation.viewAllEducations_action' | cxTranslate }}
          </cx-generic-link>
        </li>
      </ul>
    </ng-container>
  </ng-template>
</ng-template>
