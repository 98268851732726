import { Injectable } from '@angular/core';
import { WindowRef } from '../../../core/window';

@Injectable({ providedIn: 'root' })
export class BlobService {
  constructor(private windowRef: WindowRef) {}

  download(filename: string, blob: Blob): void {
    const a = document.createElement('a');
    const objectUrl = URL.createObjectURL(blob);
    a.href = objectUrl;
    a.download = filename;
    a.target = '_blank';
    a.rel = 'nofollow';
    a.click();
    URL.revokeObjectURL(objectUrl);
  }

  open(blob: Blob): void {
    if (this.windowRef.isBrowser()) {
      const objectUrl = URL.createObjectURL(blob);
      this.windowRef.nativeWindow.open(objectUrl, '_blank');
    }
  }
}
