import { PagedSearchAction } from '../../../features/store/actions/search.actions';
import { PaginationState, SearchPageResultState } from '../../../features/store/base-feature-state';
import { OrderCardPage } from '../../../model';
import { loaderReducer } from '../../../state/utils/loader';
import { UserActions } from '../actions';
import { OrderCardsActionTypes } from '../actions/order-cards.action';
import { ORDER_CARDS } from '../user-state';
import { reducer as orderCardEntryReducer } from './order-card-entries.reducer';

export const initialSearchState: PaginationState<SearchPageResultState> = {
  lastUpdateTime: undefined,
  pages: {},
  pagination: undefined,
  sorts: [],
};
export function reducer(state: PaginationState<SearchPageResultState> = initialSearchState, action: PagedSearchAction) {
  switch (action.type) {
    case OrderCardsActionTypes.SearchOrderCards:
    case OrderCardsActionTypes.SearchOrderCardsFail: {
      const _action = <UserActions.SearchOrderCards>action;
      const searchParams = _action.payload.searchParams;
      const loaderState = loaderReducer<SearchPageResultState>(ORDER_CARDS, orderCardEntryReducer)(undefined, _action);

      return {
        ...state,
        pages: {
          ...state.pages,
          [searchParams.page]: loaderState,
        },
      };
    }
    case OrderCardsActionTypes.SearchOrderCardsSuccess: {
      const _action = <UserActions.SearchOrderCardsSuccess>action;
      const payload: OrderCardPage = _action.payload.searchResult;
      const loaderState = loaderReducer<SearchPageResultState>(ORDER_CARDS, orderCardEntryReducer)(
        { ...state.pages[payload.pagination.page] },
        _action
      );

      return {
        ...state,
        lastUpdateTime: new Date().toISOString(),
        pagination: payload.pagination,
        sorts: payload.sorts,
        pages: {
          ...state.pages,
          [payload.pagination.page]: loaderState,
        },
      };
    }
  }
  return state;
}
