import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { KeyboardFocusModule } from '@spartacus/storefront';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { LaunchDialogService } from '../../../core/modal';
import { IconThemeReverseModule } from '../../directives';
import { MediaModule } from '../media';
import { ModalModule } from '../modal';
import { imageModalLayoutConfig } from './image-modal-layout.config';
import { ImageModalComponent } from './image-modal.component';

@NgModule({
  imports: [CommonModule, MediaModule, NgbCarouselModule, NzIconModule, ModalModule, IconThemeReverseModule, KeyboardFocusModule],
  declarations: [ImageModalComponent],
  exports: [ImageModalComponent],
})
export class ImageModalModule {
  constructor(private launchDialogService: LaunchDialogService) {
    this.launchDialogService.registerModalLayoutConfig(imageModalLayoutConfig);
  }
}
