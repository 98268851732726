import { NgModule } from '@angular/core';
import { ASM_FEATURE } from '@spartacus/asm/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { AsmRootModule } from './root/asm-root.module';

@NgModule({
  imports: [AsmRootModule],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        [ASM_FEATURE]: {
          module: () => import('./asm.module').then((m) => m.AsmModule),
        },
      },
    }),
  ],
})
export class AsmFeatureModule {}
