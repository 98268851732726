import { StateUtils } from '@spartacus/core';
import { Country, HttpErrorModel } from '../../../model';
import { PyStateUtils } from '../../../state/utils';
import { DELIVERY_COUNTRY } from '../user-state';

export enum DeliveryCountriesActionTypes {
  LoadDeliveryCountries = '[User] Load Delivery Countries',
  LoadDeliveryCountriesSuccess = '[User] Load Delivery Countries Success',
  LoadDeliveryCountriesFail = '[User] Load Delivery Countries Fail',
}

export class LoadDeliveryCountries extends StateUtils.LoaderLoadAction {
  readonly type = DeliveryCountriesActionTypes.LoadDeliveryCountries;

  constructor(public userId: string) {
    super(DELIVERY_COUNTRY);
  }
}

export class LoadDeliveryCountriesSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = DeliveryCountriesActionTypes.LoadDeliveryCountriesSuccess;

  constructor(public payload: Country[]) {
    super(DELIVERY_COUNTRY);
  }
}

export class LoadDeliveryCountriesFail extends PyStateUtils.LoaderFailAction {
  readonly type = DeliveryCountriesActionTypes.LoadDeliveryCountriesFail;

  constructor(public error?: HttpErrorModel) {
    super(DELIVERY_COUNTRY, error);
  }
}

export type DeliveryCountriesActions = LoadDeliveryCountries | LoadDeliveryCountriesSuccess | LoadDeliveryCountriesFail;
