import { PageMetaConfig } from '@spartacus/core';

export const defaultPageMetaConfig: PageMetaConfig = {
  pageMeta: {
    enableInDevMode: true,
    resolvers: [
      {
        property: 'title',
        method: 'resolveTitle',
      },
      {
        property: 'description',
        method: 'resolveDescription',
      },
      {
        property: 'breadcrumbs',
        method: 'resolveBreadcrumbs',
      },
      {
        property: 'robots',
        method: 'resolveRobots',
      },
      {
        property: 'canonicalUrl',
        method: 'resolveCanonicalUrl',
      },
      {
        property: 'altHrefLangs',
        method: 'resolveAltHrefLangs',
      },
      {
        property: 'opengraph',
        method: 'resolveOpengraph',
      },
      {
        property: 'twitter',
        method: 'resolveTwitter',
      },
    ],
    canonicalUrl: {
      forceHttps: true,
      forceWww: true,
      removeQueryParams: false,
      forceTrailingSlash: true,
    },
  },
};
