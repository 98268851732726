import { Component } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'py-page-time-created',
  templateUrl: './page-time-created.component.html',
  styleUrls: ['./page-time-created.component.scss'],
})
export class PageTimeCreatedComponent {
  publishedDate$: Observable<Date>;

  constructor(private cmsService: CmsService) {
    this.publishedDate$ = this.cmsService.getCurrentPage().pipe(map((p) => p?.publishedDate));
  }
}
