import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BrowserService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  isEdgeBrowser(): boolean {
    const edgeKey = 'edge';
    const chromiumEdgeKey = 'edg/';

    if (!isPlatformBrowser(this.platformId) || !Boolean(navigator?.userAgent)) {
      return false;
    }

    const userAgent = navigator.userAgent.toLocaleLowerCase();

    return userAgent.includes(edgeKey) || userAgent.includes(chromiumEdgeKey);
  }
}
