import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AuthService } from '@spartacus/core';
import { Observable, from } from 'rxjs';
import { filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { PunchOutData } from '../../core/model';
import { SemanticPathService } from '../../core/routing';
import { PunchOutFacade } from '../../core/user';

/**
 * Guards the _logout_ route.
 *
 * Takes care of routing the user to a logout page (if available) or redirects to
 * the homepage. If the homepage is protected, the user is redirected
 * to the login route instead.
 */
@Injectable({
  providedIn: 'root',
})
export class LogoutGuard {
  constructor(
    protected auth: AuthService,
    protected semanticPathService: SemanticPathService,
    protected punchOutService: PunchOutFacade,
    protected router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    /**
     * First we want to make sure that if current session is a PunchOut session
     * then we cancel that session. After that we make sure to complete logout
     * process before redirecting to either the PunchOut cancel URL or the logout page
     * We want to avoid errors like `token is no longer valid`
     */
    return this.punchOutService.getPunchOutEntity().pipe(
      take(1),
      withLatestFrom(this.auth.isUserLoggedIn().pipe(take(1))),
      switchMap(([punchOut, isUserLoggedIn]) => {
        if (isUserLoggedIn) {
          if (punchOut) {
            return this.clearPunchOutAndLogOut(punchOut);
          }
        }
        // Always logout, otherwise ESVCX-5508 happens
        return from(this.logout());
      }),
      map(() => true)
    );
  }

  protected logout(): Promise<any> {
    return this.auth.coreLogout();
  }

  private clearPunchOutAndLogOut(punchOut: PunchOutData) {
    return this.punchOutService.cancelPunchOut().pipe(
      tap(() => this.punchOutService.punchOutReset()),
      switchMap(() => this.punchOutService.getPunchOutEntity()),
      filter((e) => !e),
      tap(() => {
        this.logout().finally(() => this.punchOutService.logOut(punchOut));
      }),
      filter(() => this.noCancelUrl(punchOut))
    );
  }

  private noCancelUrl(punchOut: PunchOutData): boolean {
    return !punchOut['cancel'];
  }
}
