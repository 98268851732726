import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RouterOutletWrapperComponent } from './router-outlet-wrapper.component';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [RouterOutletWrapperComponent],
  exports: [RouterOutletWrapperComponent],
})
export class RouterOutletWrapperModule {}
