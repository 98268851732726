import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        lastMostOrderedArticles: {
          module: () =>
            import('./last-most-ordered-articles/last-most-ordered-articles.module').then((m) => m.LastMostOrderedArticlesModule),
          cmsComponents: ['CmsDashboardLastMostOrderedArticlesComponent'],
        },
        favouriteShoppingList: {
          module: () =>
            import('./favourite-shopping-list/favourite-shopping-list.module').then((m) => m.FavouriteShoppingListModule),
          cmsComponents: ['CmsDashboardFavouriteShoppingListComponent'],
        },
        dashboardOrderCards: {
          module: () => import('./dashboard-order-cards/dashboard-order-cards.module').then((m) => m.DashboardOrderCardsModule),
          cmsComponents: ['CmsDashboardOrderCardsComponent'],
        },
        dashboardApprovalNotifications: {
          module: () =>
            import('./dashboard-approval-notifications/dashboard-approval-notifications.module').then(
              (m) => m.DashboardApprovalNotificationsModule
            ),
          cmsComponents: ['CmsDashboardApprovalNotificationsComponent'],
        },
        dashboardUserNotifications: {
          module: () =>
            import('./dashboard-user-notifications/dashboard-user-notifications.module').then(
              (m) => m.DashboardUserNotificationsModule
            ),
          cmsComponents: ['CmsDashboardUserNotificationsComponent'],
        },
      },
    }),
  ],
})
export class DashboardFeatureModule {}
