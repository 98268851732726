import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export enum MessageBannerType {
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  DANGER = 'DANGER',
  WARNING = 'WARNING',
}

@Component({
  selector: 'py-message-banner',
  templateUrl: './message-banner.component.html',
  styleUrls: ['./message-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageBannerComponent {
  info = MessageBannerType.INFO;
  success = MessageBannerType.SUCCESS;
  danger = MessageBannerType.DANGER;
  warning = MessageBannerType.WARNING;

  @Input() type: MessageBannerType = this.info;
  @Input() title?: string;
}
