import { StateUtils } from '@spartacus/core';
import { PagedSearchAction } from '../../../../core/features/store/actions/search.actions';
import { AvailableShipTosSearchParams } from '../../../../core/model';
import { PyStateUtils } from '../../../../core/state/utils';
import { AVAILABLE_SHIP_TOS } from '../sold-to-state';

export enum AvailableShipTosActionTypes {
  LoadAvailableShipTos = '[SoldTo] Load available Ship Tos',
  LoadAvailableShipTosSuccess = '[SoldTo] Load available Ship Tos success',
  LoadAvailableShipTosFail = '[SoldTo] Load available Ship Tos fail',
}

export class LoadAvailableShipTos extends StateUtils.LoaderLoadAction implements PagedSearchAction {
  readonly type = AvailableShipTosActionTypes.LoadAvailableShipTos;
  readonly searchType = AVAILABLE_SHIP_TOS;

  constructor(public searchParams: AvailableShipTosSearchParams, public userId: string) {
    super(AVAILABLE_SHIP_TOS);
  }
}

export class LoadAvailableShipTosFail extends PyStateUtils.LoaderFailAction implements PagedSearchAction {
  readonly type = AvailableShipTosActionTypes.LoadAvailableShipTosFail;
  readonly searchType = AVAILABLE_SHIP_TOS;

  constructor(public searchParams: AvailableShipTosSearchParams, public payload?: any) {
    super(AVAILABLE_SHIP_TOS, payload);
  }
}

export class LoadAvailableShipTosSuccess extends StateUtils.LoaderSuccessAction implements PagedSearchAction {
  readonly type = AvailableShipTosActionTypes.LoadAvailableShipTosSuccess;
  readonly searchType = AVAILABLE_SHIP_TOS;

  constructor(public payload: any, public searchParams: AvailableShipTosSearchParams) {
    super(AVAILABLE_SHIP_TOS);
  }
}

export type AvailableShipTosActions = LoadAvailableShipTos | LoadAvailableShipTosFail | LoadAvailableShipTosSuccess;
