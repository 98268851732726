import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { translationChunksConfig } from '@kasbah/assets';
import { SmartEditModule } from '@spartacus/smartedit';
import { defaultCmsContentProviders } from '@spartacus/storefront';
import { CmsLibModule } from './cms-components/cms-lib.module';
import { defaultRoutingConfig } from './cms-structure/routing/default-routing-config';
import { provideConfig, provideDefaultConfig } from './core/config/config-providers';
import { defaultLayoutConfig } from './layout/config/default-layout-config';
import { defaultMediaConfig } from './shared/components/media/default-media.config';
import { StorefrontModule } from './storefront.module';

@NgModule({
  imports: [CmsLibModule, StorefrontModule, BrowserAnimationsModule, SmartEditModule],
  providers: [
    provideConfig({
      i18n: {
        chunks: translationChunksConfig,
        backend: {
          loadPath: '{{ns}}',
        },
      },
      authentication: {
        client_id: 'kasbah',
        client_secret: 'Z41GhuY9TU9EOsTkST0xRQWK',
      },
    }),
    provideConfig(defaultRoutingConfig),
    provideConfig(defaultLayoutConfig),
    provideDefaultConfig(defaultMediaConfig),
    ...defaultCmsContentProviders,
  ],
  exports: [StorefrontModule],
})
export class DefaultStorefrontModule {}
