import { InjectionToken, Provider } from '@angular/core';
import { ActionReducer, ActionReducerMap, MetaReducer, combineReducers } from '@ngrx/store';
import { USER_CONSENTS } from '@spartacus/core';
import { SoldToActions } from '../../../../features/sold-to-base/store/actions';
import { LoginLogoutActionTypes } from '../../../auth/user-auth/store/actions/login-logout.action';
import { IndexedState } from '../../../features/store/base-feature-state';
import { reducer as searchParamsReducer } from '../../../features/store/reducers/search-params.reducer';
import { reducer as searchReducer } from '../../../features/store/reducers/search.reducer';
import {
  AccountingCode,
  Consent,
  ConsentTemplate,
  Country,
  Feed,
  OrderCard,
  OrderCardConfiguration,
  PrincipalConfiguration,
  ShoppingList,
  ShoppingListPermissions,
  SiteFamilyConfig,
  User,
} from '../../../model';
import { AnalyticsMetadata } from '../../../occ/occ-models/occ-generated.models';
import { CurrenciesActionTypes } from '../../../site-context/store/actions/currencies.action';
import { LanguagesActionTypes } from '../../../site-context/store/actions/languages.action';
import { PyStateUtils } from '../../../state';
import { loaderReducer } from '../../../state/utils/loader';
import { UserActions } from '../actions';
import {
  ACCOUNTING_CODES_ADMIN,
  ANALYTICS_METADATA,
  ARTICLE_PRICE,
  ARTICLE_STOCK_INFO,
  ARTICLE_STOCK_INFO_DETAILS,
  ArticlePriceState,
  CONSENTS_NOT_GIVEN,
  CONSIGNMENT_STOCK_ARTICLES,
  CONSIGNMENT_STOCK_ARTICLE_STOCK_INFO,
  ConsignmentStockArticleStockInfoState,
  DELIVERY_ADDRESS,
  DELIVERY_ADDRESS_DELETE,
  DELIVERY_ADDRESS_SAVE,
  DELIVERY_COUNTRY,
  FEED_DATA,
  MINI_SHOPPING_LIST,
  ONBOARDING_COUNTRY,
  ORDERS_RETURNED_COUNT,
  ORDER_APPROVALS,
  ORDER_APPROVALS_ADMIN,
  ORDER_APPROVAL_ADMIN_PENDING_COUNT,
  ORDER_APPROVAL_BUYERS,
  ORDER_APPROVAL_PENDING_COUNT,
  ORDER_CARDS,
  ORDER_CARDS_ADMIN,
  ORDER_CARD_ADMIN_CONFIGURATION,
  ORDER_CARD_ADMIN_CONFIGURATIONS,
  ORDER_CARD_CONFIGURATION,
  ORDER_CARD_CONFIGURATIONS,
  PRINCIPAL_CONFIGURATION,
  PROFILE_COUNTRY,
  RECOMMENDATIONS,
  SHIP_TOS,
  SHOPPING_LIST,
  SHOPPING_LIST_PRINCIPALS,
  SITE_FAMILY_CONFIG,
  StockInfoDetailsState,
  StockInfoState,
  UPDATE_SHOPPING_LIST_PERMISSIONS_PROCESS,
  UPDATE_USER_DETAILS_PROCESS,
  UserState,
  WRITABLE_SHOPPING_LIST,
} from '../user-state';
import * as fromAccountingCodeAdminEntitiesReducer from './accounting-code-admin-entities.reducer';
import * as fromAccountingCodeAdminInfoReducer from './accounting-code-admin-info.reducer';
import * as fromAccountingCodeAdminSearchEntitiesReducer from './accounting-code-admin-search-entities.reducer';
import * as fromAccountingCodeAdminReducer from './accounting-code-admin.reducer';
import { reducer as fromAnalyticsMetadataReducer } from './analytics-metadata.reducer';
import { reducer as fromConsentsBanner } from './consents-banner.reducer';
import { reducer as fromConsentsNotGivenReducer } from './consents-not-given.reducer';
import { reducer as fromConsentsSettingsModal } from './consents-settings-modal.reducer';
import { reducer as deliveryAddressEntitiesReducer } from './delivery-address-entities.reducer';
import { reducer as deliveryAddressSearchParamsReducer } from './delivery-address-search-params.reducer';
import { reducer as deliveryAddressReducer } from './delivery-address.reducer';
import { reducer as feedReducer } from './feed.reducer';
import { reducer as fromMiniShoppingListSearchEntitiesReducer } from './mini-shopping-list-search-entities.reducer';
import { reducer as fromMiniShoppingListReducer } from './mini-shopping-lists.reducer';
import { reducer as onboardingCountryEntityReducer } from './onboarding-country-entity.reducer';
import * as fromOrderApprovalAvailableBuyersEntitiesReducer from './order-approval-available-buyers-entities.reducer';
import { reducer as availableSearchParamsReducer } from './order-approval-available-buyers-search-params.reducer';
import * as fromOrderApprovalAvailableBuyersReducer from './order-approval-available-buyers.reducer';
import { reducer as orderApprovalEntriesReducer } from './order-approval.reducer';
import { reducer as orderApprovalsAdminEntitiesReducer } from './order-approvals-admin-entities-reducer';
import { reducer as orderApprovalsEntitiesReducer } from './order-approvals-entities-reducer';
import * as fromOrderCardAdminEntitiesReducer from './order-card-admin-entities.reducer';
import * as fromOrderCardAdminSearchEntitiesReducer from './order-card-admin-search-entities.reducer';
import * as fromOrderCardAdminReducer from './order-card-admin.reducer';
import * as fromOrderCardConfigurationEntitiesReducer from './order-card-configuration-entities.reducer';
import * as fromOrderCardConfigurationReducer from './order-card-configuration.reducer';
import * as fromOrderCardEntitiesReducer from './order-card-entities.reducer';
import * as fromOrderCardSearchEntitiesReducer from './order-card-search-entities.reducer';
import * as fromOrderCardReducer from './order-card.reducer';
import { reducer as fromPriceReducer } from './price.reducer';
import { reducer as fromPrincipalConfigurationReducer } from './principal-configuration.reducer';
import { reducer as profileCountryEntityReducer } from './profile-country-entity.reducer';
import { reducer as fromRecommendationsReducer } from './recommendations.reducer';
import { reducer as fromResetPasswordReducer } from './reset-password.reducer';
import * as fromShipTosEntitiesReducer from './ship-tos-entities.reducer';
import { reducer as shipTosSearchParamsReducer } from './ship-tos-search-params.reducer';
import * as fromShipTosReducer from './ship-tos.reducer';
import * as fromShoppingListsPrincipalsSearchEntitiesReducer from './shopping-list-principals-search-entities.reducer';
import * as fromShoppinglistPrincipalsReducer from './shopping-list-principals.reducer';
import { reducer as fromShoppingListsReducer } from './shopping-lists.reducer';
import { reducer as fromSiteFamilyConfigReducer } from './site-family-config.reducer';
import { reducer as fromStockInfoDetailsReducer } from './stock-info-details.reducer';
import { reducer as fromStockInfoReducer } from './stock-info.reducer';
import { reducer as fromUserConsentsReducer } from './user-consents.reducer';
import { reducer as fromUserDetailsReducer } from './user-details.reducer';
import { reducer as fromWritableShoppingListSearchEntitiesReducer } from './writable-shopping-list-search-entities.reducer';
import { reducer as fromWritableShoppingListReducer } from './writable-shopping-lists.reducer';

export function getReducers(): ActionReducerMap<UserState, any> {
  return {
    account: combineReducers({
      details: loaderReducer<User>(UPDATE_USER_DETAILS_PROCESS, fromUserDetailsReducer),
    }),
    principalConfiguration: PyStateUtils.loaderReducer<IndexedState<PrincipalConfiguration>>(
      PRINCIPAL_CONFIGURATION,
      fromPrincipalConfigurationReducer
    ),
    deliveryAddresses: combineReducers({
      search: searchReducer(DELIVERY_ADDRESS, deliveryAddressReducer),
      searchParams: deliveryAddressSearchParamsReducer,
      entities: deliveryAddressEntitiesReducer,
      save: PyStateUtils.loaderReducer(DELIVERY_ADDRESS_SAVE),
      delete: PyStateUtils.loaderReducer<string>(DELIVERY_ADDRESS_DELETE),
      countries: PyStateUtils.loaderReducer<Country[]>(DELIVERY_COUNTRY),
    }),
    resetPassword: fromResetPasswordReducer,
    profileCountries: PyStateUtils.loaderReducer(PROFILE_COUNTRY, profileCountryEntityReducer),
    onboardingCountries: PyStateUtils.loaderReducer(ONBOARDING_COUNTRY, onboardingCountryEntityReducer),
    shoppingList: clearStateOnContextChange(
      combineReducers(
        {
          shoppingLists: PyStateUtils.entityProcessesLoaderReducer<ShoppingList>(SHOPPING_LIST, fromShoppingListsReducer),
          miniShoppingLists: clearMiniShoppingListsSearchState(
            combineReducers({
              search: searchReducer(MINI_SHOPPING_LIST, fromMiniShoppingListReducer),
              entities: fromMiniShoppingListSearchEntitiesReducer,
            })
          ),
          writableShoppingLists: clearWritableShoppingListsSearchState(
            combineReducers({
              search: searchReducer(WRITABLE_SHOPPING_LIST, fromWritableShoppingListReducer),
              entities: fromWritableShoppingListSearchEntitiesReducer,
            })
          ),
          principals: clearShoppingListPrincipalsState(
            combineReducers({
              search: searchReducer(SHOPPING_LIST_PRINCIPALS, fromShoppinglistPrincipalsReducer.reducer),
              entities: fromShoppingListsPrincipalsSearchEntitiesReducer.reducer,
              update: PyStateUtils.loaderReducer<ShoppingListPermissions>(UPDATE_SHOPPING_LIST_PERMISSIONS_PROCESS),
            })
          ),
        },
        {}
      )
    ),
    consents: PyStateUtils.loaderReducer<ConsentTemplate[]>(USER_CONSENTS, fromUserConsentsReducer),
    consentsNotGiven: PyStateUtils.loaderReducer<Consent[]>(CONSENTS_NOT_GIVEN, fromConsentsNotGivenReducer),
    consentsBanner: fromConsentsBanner,
    consentsSettingsModal: fromConsentsSettingsModal,
    prices: clearStateOnContextChange(PyStateUtils.entityLoaderReducer<ArticlePriceState>(ARTICLE_PRICE, fromPriceReducer)),
    stockInfos: clearStateOnContextChange(
      PyStateUtils.entityLoaderReducer<StockInfoState>(ARTICLE_STOCK_INFO, fromStockInfoReducer)
    ),
    stockInfoDetails: clearStateOnContextChange(
      PyStateUtils.entityLoaderReducer<StockInfoDetailsState>(ARTICLE_STOCK_INFO_DETAILS, fromStockInfoDetailsReducer)
    ),
    recommendations: PyStateUtils.entityLoaderReducer(RECOMMENDATIONS, fromRecommendationsReducer),
    feed: PyStateUtils.loaderReducer<Feed>(FEED_DATA, feedReducer),
    orderApproval: combineReducers({
      search: searchReducer(ORDER_APPROVALS, orderApprovalEntriesReducer),
      searchParams: searchParamsReducer(ORDER_APPROVALS),
      entities: orderApprovalsEntitiesReducer,
      pending: PyStateUtils.loaderReducer<number>(ORDER_APPROVAL_PENDING_COUNT),
      buyers: combineReducers({
        search: searchReducer(ORDER_APPROVAL_BUYERS, fromOrderApprovalAvailableBuyersReducer.reducer),
        searchParams: availableSearchParamsReducer(ORDER_APPROVAL_BUYERS),
        entities: fromOrderApprovalAvailableBuyersEntitiesReducer.reducer(ORDER_APPROVAL_BUYERS),
      }),
      returned: PyStateUtils.loaderReducer<number>(ORDERS_RETURNED_COUNT),
    }),
    orderApprovalAdmin: combineReducers({
      search: searchReducer(ORDER_APPROVALS_ADMIN, orderApprovalEntriesReducer),
      searchParams: searchParamsReducer(ORDER_APPROVALS_ADMIN),
      entities: orderApprovalsAdminEntitiesReducer,
      pending: PyStateUtils.loaderReducer<number>(ORDER_APPROVAL_ADMIN_PENDING_COUNT),
    }),
    analyticsMetadata: PyStateUtils.loaderReducer<AnalyticsMetadata>(ANALYTICS_METADATA, fromAnalyticsMetadataReducer),
    siteFamilyConfig: PyStateUtils.loaderReducer<SiteFamilyConfig>(SITE_FAMILY_CONFIG, fromSiteFamilyConfigReducer),
    orderCard: combineReducers({
      orderCards: combineReducers({
        search: searchReducer(ORDER_CARDS, fromOrderCardReducer.reducer),
        searchEntities: fromOrderCardSearchEntitiesReducer.reducer,
        entities: PyStateUtils.entityLoaderReducer<OrderCard>(ORDER_CARDS, fromOrderCardEntitiesReducer.reducer),
        orderCardConfiguration: clearOrderCardConfigurationsState(
          combineReducers({
            list: PyStateUtils.entityLoaderReducer<any>(ORDER_CARD_CONFIGURATIONS, fromOrderCardConfigurationReducer.reducer),
            entities: PyStateUtils.entityLoaderReducer<OrderCardConfiguration>(
              ORDER_CARD_CONFIGURATION,
              fromOrderCardConfigurationEntitiesReducer.reducer
            ),
          })
        ),
      }),
      orderCardsAdmin: combineReducers({
        search: searchReducer(ORDER_CARDS_ADMIN, fromOrderCardAdminReducer.reducer),
        searchEntities: fromOrderCardAdminSearchEntitiesReducer.reducer,
        entities: PyStateUtils.entityLoaderReducer<OrderCard>(ORDER_CARDS_ADMIN, fromOrderCardAdminEntitiesReducer.reducer),
        orderCardConfiguration: clearOrderCardConfigurationsState(
          combineReducers({
            list: PyStateUtils.entityLoaderReducer<any>(
              ORDER_CARD_ADMIN_CONFIGURATIONS,
              fromOrderCardConfigurationReducer.reducer
            ),
            entities: PyStateUtils.entityLoaderReducer<OrderCardConfiguration>(
              ORDER_CARD_ADMIN_CONFIGURATION,
              fromOrderCardConfigurationEntitiesReducer.reducer
            ),
          })
        ),
      }),
    }),
    accountingCode: combineReducers({
      accountingCodesAdmin: combineReducers({
        search: searchReducer(ACCOUNTING_CODES_ADMIN, fromAccountingCodeAdminReducer.reducer),
        searchEntities: fromAccountingCodeAdminSearchEntitiesReducer.reducer,
        entities: PyStateUtils.entityLoaderReducer<AccountingCode>(
          ACCOUNTING_CODES_ADMIN,
          fromAccountingCodeAdminEntitiesReducer.reducer
        ),
        info: fromAccountingCodeAdminInfoReducer.reducer,
      }),
    }),
    shipTos: combineReducers({
      search: searchReducer(SHIP_TOS, fromShipTosReducer.reducer),
      searchParams: shipTosSearchParamsReducer,
      entities: fromShipTosEntitiesReducer.reducer,
    }),
    assortment: combineReducers({
      consignmentStock: combineReducers({
        articles: PyStateUtils.loaderReducer<string[]>(CONSIGNMENT_STOCK_ARTICLES),
        stockInfos: PyStateUtils.entityLoaderReducer<ConsignmentStockArticleStockInfoState>(CONSIGNMENT_STOCK_ARTICLE_STOCK_INFO),
      }),
    }),
  };
}

export const reducerToken = new InjectionToken<ActionReducerMap<UserState>>('UserReducers');

export const reducerProvider: Provider = {
  provide: reducerToken,
  useFactory: getReducers,
};

export function clearUserState(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (action.type === LoginLogoutActionTypes.Logout) {
      state = undefined;
    }
    if (action.type === CurrenciesActionTypes.CurrencyChange || action.type === LanguagesActionTypes.LanguageChange) {
      state = undefined;
    }

    return reducer(state, action);
  };
}

export function clearStateOnContextChange(reducer: ActionReducer<any>, transformer?: (state: any) => any): ActionReducer<any> {
  return function (state, action) {
    if (action.type === SoldToActions.ChangeSoldToActionTypes.ChangeSoldToSuccess) {
      state = transformer ? transformer(state) : undefined;
    }
    return reducer(state, action);
  };
}

export function clearOrderCardConfigurationsState(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (action.type === UserActions.OrderCardsAdminActionTypes.ClearOrderCardAdminConfigurations) {
      state = {
        list: undefined,
        entities: undefined,
      };
    }

    return reducer(state, action);
  };
}

export function clearMiniShoppingListsSearchState(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (action.type === UserActions.ShoppingListActionTypes.DeleteShoppingListSuccess) {
      state = {
        ...state,
        search: undefined,
      };
    }

    if (action.type === UserActions.ShoppingListActionTypes.ResetMiniShoppingLists) {
      state = {
        ...state,
        entities: undefined,
      };
    }

    return reducer(state, action);
  };
}

export function clearWritableShoppingListsSearchState(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (action.type === UserActions.ShoppingListActionTypes.DeleteShoppingListSuccess) {
      state = {
        ...state,
        search: undefined,
      };
    }

    return reducer(state, action);
  };
}

export function clearShoppingListPrincipalsState(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (action.type === UserActions.ShoppingListActionTypes.ResetUpdateShoppingListPermissions) {
      state = {
        ...state,
        update: undefined,
      };
    }

    if (action.type === UserActions.ShoppingListActionTypes.UpdateShoppingListPermissions) {
      state = {
        ...state,
        search: undefined,
        entities: undefined,
      };
    }

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [clearUserState];
