import { Action } from '@ngrx/store';

export enum AnonymousConsentsActionTypes {
  SetAnonymousConsentsSettingsVisible = '[AnonymousConsents] Set anonymous consents settings visible',
}

export class SetAnonymousConsentsSettingsVisible implements Action {
  readonly type = AnonymousConsentsActionTypes.SetAnonymousConsentsSettingsVisible;
  constructor(public visible: boolean, public showCloseButton: boolean) {}
}

export type AnonymousConsentsActions = SetAnonymousConsentsSettingsVisible;
