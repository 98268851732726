import { Component, Input, TemplateRef } from '@angular/core';
import { ConsignmentArticleStock, LoaderError } from '../../../../core/model';

@Component({
  selector: 'py-consignment-stock-article-stock-info',
  templateUrl: './consignment-stock-article-stock-info.component.html',
  styleUrls: ['./consignment-stock-article-stock-info.component.scss'],
})
export class ConsignmentStockArticleStockInfoComponent {
  @Input() consignmentStockArticleStockInfo: ConsignmentArticleStock;
  @Input() loadingConsignmentStockArticleStockInfo: boolean;
  @Input() consignmentStockArticleStockInfoSuccess: boolean;
  @Input() consignmentStockArticleStockInfoError: LoaderError;
  @Input() stockStatusTemplate: TemplateRef<any>;
  @Input() errorTemplate: TemplateRef<any>;
}
