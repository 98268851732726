import { StateUtils } from '@spartacus/core';
import { SoldTo } from '../../../../core/model/user.model';
import { PyStateUtils } from '../../../../core/state/utils';
import { SOLD_TO } from '../sold-to-state';

export enum ChangeSoldToActionTypes {
  ChangeSoldTo = '[SoldTo] Change sold-to',
  ChangeSoldToSuccess = '[SoldTo] Change sold-to success',
  ChangeSoldToFail = '[SoldTo] Change sold-to fail',
}

export class ChangeSoldTo extends StateUtils.LoaderLoadAction {
  readonly type = ChangeSoldToActionTypes.ChangeSoldTo;

  constructor(
    public payload: {
      userId: string;
      soldTo: string;
    }
  ) {
    super(SOLD_TO);
  }
}

export class ChangeSoldToSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = ChangeSoldToActionTypes.ChangeSoldToSuccess;

  constructor(public payload: SoldTo) {
    super(SOLD_TO);
  }
}

export class ChangeSoldToFail extends PyStateUtils.LoaderFailAction {
  readonly type = ChangeSoldToActionTypes.ChangeSoldToFail;

  constructor(public payload?: any) {
    super(SOLD_TO, payload);
  }
}

export type SoldToChangeActions = ChangeSoldTo | ChangeSoldToSuccess | ChangeSoldToFail;
