import { StateUtils } from '@spartacus/core';
import { Category } from '../../../../model';
import { PyStateUtils } from '../../../../state/utils';
import { AREAS_OF_USE } from '../../../store/catalog-state';

export enum AreaOfUseActionTypes {
  LoadAreasOfUse = '[Catalog] Load areas-of-use',
  LoadAreasOfUseSuccess = '[Catalog] Load areas-of-use success',
  LoadAreasOfUseFail = '[Catalog] Load areas-of-use fail',
}

export class LoadAreasOfUse extends StateUtils.LoaderLoadAction {
  readonly type = AreaOfUseActionTypes.LoadAreasOfUse;

  constructor(public userId: string) {
    super(AREAS_OF_USE);
  }
}

export class LoadAreasOfUseSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = AreaOfUseActionTypes.LoadAreasOfUseSuccess;

  constructor(public payload: Category[]) {
    super(AREAS_OF_USE);
  }
}

export class LoadAreasOfUseFail extends PyStateUtils.LoaderFailAction {
  readonly type = AreaOfUseActionTypes.LoadAreasOfUseFail;

  constructor(public payload?: any) {
    super(AREAS_OF_USE, payload);
  }
}

export type AreaOfUseActions = LoadAreasOfUse | LoadAreasOfUseSuccess | LoadAreasOfUseFail;
