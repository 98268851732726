import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MegamenuListItemsModule } from './components/megamenu-list-item';
import { MegamenuComponent } from './megamenu.component';

@NgModule({
  imports: [CommonModule, MegamenuListItemsModule],
  declarations: [MegamenuComponent],
  exports: [MegamenuComponent],
})
export class MegamenuModule {}
