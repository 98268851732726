<div class="return-order-notification py-3 px-4 px-lg-0" *ngIf="orderReturnDraftCode$ | async as orderCode">
  <div class="mr-3 text-wrapper">
    {{ 'orderHistory.orderReturn.creatingReturnForOrder_hint' | cxTranslate }} <u>{{ orderCode }}</u>
  </div>

  <div class="d-flex pl-3 action-wrapper">
    <button type="button" class="link return-order-notification__link" (click)="onCancelOrderReturnDraft(orderCode)">
      <i nz-icon nzType="close-square" nzTheme="outline" class="d-flex"></i>
      <span class="ml-2">{{ 'orderHistory.orderReturn.cancelReturn_action' | cxTranslate }}</span>
    </button>
  </div>
</div>
