import { Action } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { BaseSite, SiteConfig } from '../../../model/misc.model';
import { PyStateUtils } from '../../../state/utils';
import { SITE_CONFIG } from '../site-context-state';

export enum BaseSiteActionTypes {
  LoadBaseSite = '[Site-context] Load BaseSite',
  LoadBaseSiteSuccess = '[Site-context] Load BaseSite Success',
  LoadBaseSiteFail = '[Site-context] Load BaseSite Fail',

  LoadBaseSites = '[Site-context] Load BaseSites',
  LoadBaseSitesSuccess = '[Site-context] Load BaseSites Success',
  LoadBaseSitesFail = '[Site-context] Load BaseSites Fail',

  SetActiveBaseSite = '[Site-context] Set Active BaseSite',
  BaseSiteChange = '[Site-context] BaseSite Change',

  SetIsFallbackBaseSite = '[Site-context] Set is fallback base site',

  LoadSiteConfig = '[Site-context] Load site config',
  LoadSiteConfigSuccess = '[Site-context] Load site config success',
  LoadSiteConfigFail = '[Site-context] Load site config fail',
}

export class LoadBaseSite implements Action {
  readonly type = BaseSiteActionTypes.LoadBaseSite;
}

export class LoadBaseSiteFail implements Action {
  readonly type = BaseSiteActionTypes.LoadBaseSiteFail;

  constructor(public payload: any) {}
}

export class LoadBaseSiteSuccess implements Action {
  readonly type = BaseSiteActionTypes.LoadBaseSiteSuccess;

  constructor(public payload: BaseSite) {}
}

export class LoadBaseSites implements Action {
  readonly type = BaseSiteActionTypes.LoadBaseSites;
}

export class LoadBaseSitesFail implements Action {
  readonly type = BaseSiteActionTypes.LoadBaseSitesFail;

  constructor(public payload: any) {}
}

export class LoadBaseSitesSuccess implements Action {
  readonly type = BaseSiteActionTypes.LoadBaseSitesSuccess;

  constructor(public payload: BaseSite[]) {}
}

export class SetActiveBaseSite implements Action {
  readonly type = BaseSiteActionTypes.SetActiveBaseSite;

  constructor(public payload: string) {}
}

export class BaseSiteChange implements Action {
  readonly type = BaseSiteActionTypes.BaseSiteChange;
}

export class SetIsFallbackBaseSite implements Action {
  readonly type = BaseSiteActionTypes.SetIsFallbackBaseSite;

  constructor(public payload: boolean) {}
}

export class LoadSiteConfig extends StateUtils.LoaderLoadAction {
  readonly type = BaseSiteActionTypes.LoadSiteConfig;
  constructor() {
    super(SITE_CONFIG);
  }
}

export class LoadSiteConfigFail extends PyStateUtils.LoaderFailAction {
  readonly type = BaseSiteActionTypes.LoadSiteConfigFail;
  constructor(public payload: any) {
    super(SITE_CONFIG, payload);
  }
}

export class LoadSiteConfigSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = BaseSiteActionTypes.LoadSiteConfigSuccess;
  constructor(public payload: SiteConfig) {
    super(SITE_CONFIG);
  }
}

export type BaseSiteActions =
  | LoadBaseSite
  | LoadBaseSiteFail
  | LoadBaseSiteSuccess
  | LoadBaseSites
  | LoadBaseSitesFail
  | LoadBaseSitesSuccess
  | SetActiveBaseSite
  | BaseSiteChange
  | SetIsFallbackBaseSite
  | LoadSiteConfig
  | LoadSiteConfigFail
  | LoadSiteConfigSuccess;
