<py-modal
  [title]="'checkout.orderInProgress_title' | cxTranslate"
  [dismissable]="dismissable"
  [actions]="templateActions"
  [blockDismissCoreAction]="true"
  [cxFocus]="focusConfig"
>
  <p>
    {{ 'checkout.orderInProgress_text' | cxTranslate }}
  </p>
</py-modal>

<ng-template #templateActions>
  <ng-container
    *ngIf="{
    inProgress: inProgress$ | async,
    cancelInProgress: cancelInProgress$ | async,
    proceedInProgress: proceedInProgress$ | async,
  } as data"
  >
    <button [disabled]="data.inProgress" class="btn btn-plain-secondary" (click)="onCancel()">
      <cx-spinner *ngIf="data.cancelInProgress; else cancelTextTemplate"></cx-spinner>
      <ng-template #cancelTextTemplate>{{ 'common.noAndCancel_action' | cxTranslate }}</ng-template>
    </button>
    <button [disabled]="data.inProgress" class="btn btn-primary" (click)="onProceed()">
      <cx-spinner *ngIf="data.proceedInProgress; else proceedTextTemplate"></cx-spinner>
      <ng-template #proceedTextTemplate>{{ 'common.confirm_action' | cxTranslate }}</ng-template>
    </button>
  </ng-container>
</ng-template>
