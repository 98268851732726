import { StateUtils } from '@spartacus/core';
import { SapPriceCampaignDetails, SapPriceCampaignList } from '../../../../model';
import { PyStateUtils } from '../../../../state/utils';
import { PRICE_CAMPAIGNS, PRICE_CAMPAIGN_DETAILS } from '../../../store/catalog-state';

export enum PriceCampaignActionTypes {
  LoadPriceCampaignDetails = '[Catalog] Load Price Campaign Details',
  LoadPriceCampaignDetailsSuccess = '[Catalog] Load Price Campaign Details Success',
  LoadPriceCampaignDetailsFail = '[Catalog] Load Price Campaign Details Fail',
  LoadAvailablePriceCampaigns = '[Catalog] Load Available Price Campaigns Navigation',
  LoadAvailablePriceCampaignsSuccess = '[Catalog] Load Available Price Campaigns Success',
  LoadAvailablePriceCampaignsFail = '[Catalog] Load Available Price Campaigns Fail',
  ClearPriceCampaigns = '[Catalog] Clear Price Campaigns',
}

export class LoadPriceCampaignDetails extends StateUtils.EntityLoadAction {
  readonly type = PriceCampaignActionTypes.LoadPriceCampaignDetails;

  constructor(public code: string) {
    super(PRICE_CAMPAIGN_DETAILS, code);
  }
}

export class LoadPriceCampaignDetailsSuccess extends StateUtils.EntitySuccessAction {
  readonly type = PriceCampaignActionTypes.LoadPriceCampaignDetailsSuccess;

  constructor(public payload: SapPriceCampaignDetails) {
    super(PRICE_CAMPAIGN_DETAILS, payload.code, payload);
  }
}

export class LoadPriceCampaignDetailsFail extends PyStateUtils.EntityFailAction {
  readonly type = PriceCampaignActionTypes.LoadPriceCampaignDetailsFail;

  constructor(public code: string, public payload: any) {
    super(PRICE_CAMPAIGN_DETAILS, code, payload);
  }
}

export class LoadAvailablePriceCampaigns extends StateUtils.LoaderLoadAction {
  readonly type = PriceCampaignActionTypes.LoadAvailablePriceCampaigns;

  constructor() {
    super(PRICE_CAMPAIGNS);
  }
}

export class LoadAvailablePriceCampaignsSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = PriceCampaignActionTypes.LoadAvailablePriceCampaignsSuccess;

  constructor(public payload: SapPriceCampaignList) {
    super(PRICE_CAMPAIGNS);
  }
}

export class LoadAvailablePriceCampaignsFail extends PyStateUtils.LoaderFailAction {
  readonly type = PriceCampaignActionTypes.LoadAvailablePriceCampaignsFail;

  constructor(public payload?: any) {
    super(PRICE_CAMPAIGNS, payload);
  }
}

export class ClearPriceCampaigns extends StateUtils.LoaderResetAction {
  readonly type = PriceCampaignActionTypes.ClearPriceCampaigns;

  constructor() {
    super(PRICE_CAMPAIGNS);
  }
}

export type PriceCampaignActions =
  | LoadPriceCampaignDetails
  | LoadPriceCampaignDetailsSuccess
  | LoadPriceCampaignDetailsFail
  | LoadAvailablePriceCampaigns
  | LoadAvailablePriceCampaignsSuccess
  | LoadAvailablePriceCampaignsFail
  | ClearPriceCampaigns;
