import { Component, Input } from '@angular/core';
import { B2BUser, B2BUserRole, CustomerStatusEnum } from '../../../core/model';

@Component({
  selector: 'py-user-icon',
  templateUrl: './user-icon.component.html',
  styleUrls: ['./user-icon.component.scss'],
})
export class UserIconComponent {
  @Input() b2bUser: B2BUser;

  public b2bUserStatusEnum = CustomerStatusEnum;
  public adminRole = B2BUserRole.ADMIN;
}
