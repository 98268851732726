import { ModuleWithProviders, NgModule } from '@angular/core';
import { BasePageMetaResolver, CmsService, ContentPageMetaResolver, TranslationService } from '@spartacus/core';
import { provideDefaultConfig } from '../config/config-providers';
import { TranslationService as PYTranslationService } from '../i18n/services/translation.service';
import { defaultOccCmsModuleConfig } from '../occ/adapters/cms/default-occ-cms-config';
import { BasePageMetaResolver as PYBasePageMetaResolver } from './page/base-page-meta.resolver';
import { ContentPageMetaResolver as PYContentPageMetaResolver } from './page/content-page-meta.resolver';
import { PageMetaModule } from './page/page-meta.module';
import { CmsStoreModule } from './store/cms-store.module';

@NgModule({
  imports: [CmsStoreModule, PageMetaModule.forRoot()],
})
export class CmsModule {
  static forRoot(): ModuleWithProviders<CmsModule> {
    return {
      ngModule: CmsModule,
      providers: [
        CmsService,
        provideDefaultConfig(defaultOccCmsModuleConfig),
        {
          provide: TranslationService,
          useExisting: PYTranslationService,
        },
        {
          provide: ContentPageMetaResolver,
          useExisting: PYContentPageMetaResolver,
          multi: true,
        },
        {
          provide: BasePageMetaResolver,
          useExisting: PYBasePageMetaResolver,
          multi: true,
        },
      ],
    };
  }
}
