import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  B2BUser,
  B2BUserSearchOptimizationOptions,
  B2BUserSearchParams,
  CustomerStatus,
  CustomerStatusList,
  OrgCustomerCreation,
  OrgCustomerModification,
  OrgCustomerShipToAssignment,
  OrgUnitUserPage,
  UpdateStatusValue,
  UserRoleList,
} from '../../../../core/model';
import { B2BUserAdapter } from './b2b-user.adapter';

@Injectable({
  providedIn: 'root',
})
export class B2BUserConnector {
  constructor(protected adapter: B2BUserAdapter) {}

  get(userId: string, orgUnitCustomerId: string): Observable<B2BUser> {
    return this.adapter.load(userId, orgUnitCustomerId);
  }

  create(userId: string, orgCustomerCreation: OrgCustomerCreation): Observable<B2BUser> {
    return this.adapter.create(userId, orgCustomerCreation);
  }

  update(userId: string, orgCustomerId: string, orgCustomerModification: OrgCustomerModification): Observable<B2BUser> {
    return this.adapter.update(userId, orgCustomerId, orgCustomerModification);
  }

  updateRole(userId: string, orgCustomerId: string, roleId: string): Observable<B2BUser> {
    return this.adapter.updateRole(userId, orgCustomerId, roleId);
  }

  updateSoldTos(userId: string, orgCustomerId: string, soldToIds: string[]): Observable<B2BUser> {
    return this.adapter.updateSoldTos(userId, orgCustomerId, soldToIds);
  }

  updateShipToAssignment(
    userId: string,
    orgCustomerId: string,
    orgCustomerShipToAssignment: OrgCustomerShipToAssignment
  ): Observable<B2BUser> {
    return this.adapter.updateShipToAssignment(userId, orgCustomerId, orgCustomerShipToAssignment);
  }

  getB2BUsers(
    userId: string,
    params?: B2BUserSearchParams,
    optimizationOptions?: B2BUserSearchOptimizationOptions
  ): Observable<OrgUnitUserPage> {
    return this.adapter.getB2BUsers(userId, params, optimizationOptions);
  }

  loadB2BUserStatuses(): Observable<CustomerStatusList> {
    return this.adapter.loadB2BUserStatuses();
  }

  loadUserRoles(userId: string, configurations?: string, configurationsConjunction?: boolean): Observable<UserRoleList> {
    return this.adapter.loadUserRoles(userId, configurations, configurationsConjunction);
  }

  updateStatus(userId: string, orgCustomerIds: string[], status: UpdateStatusValue): Observable<CustomerStatus> {
    return this.adapter.updateStatus(userId, orgCustomerIds, status);
  }

  deleteB2BUsers(userId: string, orgCustomerIds: string[]): Observable<any> {
    return this.adapter.deleteB2BUsers(userId, orgCustomerIds);
  }
}
