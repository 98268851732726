import { StateUtils } from '@spartacus/core';
import { EntriesState } from '../../../../core/features/store/base-feature-state';
import {
  AccountingCode,
  AccountingCodeList,
  Cart,
  Cutting,
  PrePrintedLabel,
  ReelCutting,
  TinyCart,
} from '../../../../core/model';

export const CART_CORE_FEATURE = 'cartCore';
export const CART_FEATURE = 'cart';

export const CART = '[Cart] Cart';
export const TINY_CART = '[Cart] Tiny cart';
export const CUTTING_PRICE = '[Cart] Cutting price';
export const CUTTING_TEMPLATE = '[Cart] Cutting template';
export const REEL_CUTTING_PRICE = '[Cart] Reel cutting price';
export const CART_ACCOUNTING_CODES = '[Cart] Accounting codes';
export const PRE_PRINTED_LABEL = '[Cart] Pre-printed label';

export const UPDATE_PRE_PRINTED_LABEL_PROCESS = 'updatePrePrintedLabel';
export const DELETE_PRE_PRINTED_LABEL_PROCESS = 'deletePrePrintedLabel';
export interface StateWithCart {
  [CART_FEATURE]: CartState;
}

export interface CartState {
  tinyCarts: StateUtils.LoaderState<EntriesState<TinyCart>>;
  carts: StateUtils.EntityProcessesLoaderState<Cart>;
  active: EntriesState<string>;
  cutting: CuttingState;
  reelCutting: ReelCuttingState;
  accountingCodes?: AccountingCodesState;
  prePrintedLabels?: PrePrintedLabelsState;
}

export interface CuttingState {
  prices: StateUtils.EntityLoaderState<Cutting>;
  templates: StateUtils.EntityLoaderState<Cutting>;
}

export interface ReelCuttingState {
  prices: StateUtils.EntityLoaderState<ReelCutting>;
}

export interface AccountingCodesState {
  active?: AccountingCode;
  isModalOpen?: boolean;
  isModalDismissed?: boolean;
  accountingCodeList?: StateUtils.LoaderState<AccountingCodeList>;
}

export interface PrePrintedLabelsState {
  [entryNumber: string]: {
    options: StateUtils.LoaderState<PrePrintedLabel[]>;
    innerPackageQuantityRequired: StateUtils.LoaderState<boolean>;
  };
}
