import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import { AccountingCodeList } from '../../../../../core/model';
import { CartType } from '../../../../../core/model/cart.model';
import { PyStateUtils } from '../../../../../core/state';
import { AccountingCodesState, CART_ACCOUNTING_CODES } from '../cart-state';

export enum CartAccountingCodesActionTypes {
  SetAccountingCodesModalOpen = '[Cart] Accounting codes modal open',
  SetAccountingCodesModalDismissed = '[Cart] set accounting codes modal dismissed',
  SetAccountingCodeForAllEntries = '[Cart] set accounting code for all cart entries',
  SetAccountingCodeForAllEntriesAndRemoveIneligibleEntries = '[Cart] set accounting code for all cart entries and remove ineligible entries',
  LoadAccountingCodeList = '[Cart] Load accounting code list',
  LoadAccountingCodeListSuccess = '[Cart] Load accounting code list success',
  LoadAccountingCodeListFail = '[Cart] Load accounting code list fail',
}

export interface PayloadWithCartTypeKey {
  key: CartType;
}

export interface SetAccountingCodeForAllEntriesPayload extends PayloadWithCartTypeKey {
  userId: string;
  cartId: string;
  key: CartType;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AccountingCodesStatePayload extends PayloadWithCartTypeKey, AccountingCodesState {}

export class SetAccountingCodesModalOpen extends StateUtils.EntityLoadAction {
  readonly type = CartAccountingCodesActionTypes.SetAccountingCodesModalOpen;

  constructor(public payload: AccountingCodesStatePayload) {
    super(PROCESS_FEATURE, CART_ACCOUNTING_CODES);
  }
}

export class SetAccountingCodeForAllEntries extends StateUtils.EntityLoadAction {
  readonly type = CartAccountingCodesActionTypes.SetAccountingCodeForAllEntries;

  constructor(public payload: SetAccountingCodeForAllEntriesPayload) {
    super(PROCESS_FEATURE, CART_ACCOUNTING_CODES);
  }
}

export class SetAccountingCodeForAllEntriesAndRemoveIneligibleEntries extends StateUtils.EntityLoadAction {
  readonly type = CartAccountingCodesActionTypes.SetAccountingCodeForAllEntriesAndRemoveIneligibleEntries;

  constructor(public payload: SetAccountingCodeForAllEntriesPayload) {
    super(PROCESS_FEATURE, CART_ACCOUNTING_CODES);
  }
}

export class SetAccountingCodesModalDismissed extends StateUtils.EntityLoadAction {
  readonly type = CartAccountingCodesActionTypes.SetAccountingCodesModalDismissed;

  constructor(public payload: AccountingCodesStatePayload) {
    super(PROCESS_FEATURE, CART_ACCOUNTING_CODES);
  }
}

export class LoadAccountingCodeList extends StateUtils.LoaderLoadAction {
  readonly type = CartAccountingCodesActionTypes.LoadAccountingCodeList;

  constructor(public payload: string) {
    super(CART_ACCOUNTING_CODES);
  }
}

export class LoadAccountingCodeListSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = CartAccountingCodesActionTypes.LoadAccountingCodeListSuccess;

  constructor(public payload: AccountingCodeList) {
    super(CART_ACCOUNTING_CODES);
  }
}

export class LoadAccountingCodeListFail extends PyStateUtils.LoaderFailAction {
  readonly type = CartAccountingCodesActionTypes.LoadAccountingCodeListFail;

  constructor(public payload?: any) {
    super(CART_ACCOUNTING_CODES, payload);
  }
}

export type CartAccountingCodesActions =
  | SetAccountingCodesModalOpen
  | SetAccountingCodeForAllEntries
  | SetAccountingCodeForAllEntriesAndRemoveIneligibleEntries
  | SetAccountingCodesModalDismissed
  | LoadAccountingCodeList
  | LoadAccountingCodeListSuccess
  | LoadAccountingCodeListFail;
