import { Action } from '@ngrx/store';

export enum SitePrefixActionTypes {
  SetActiveSitePrefix = '[Site-context] Set active site prefix',
}

export class SetActiveSitePrefix implements Action {
  readonly type = SitePrefixActionTypes.SetActiveSitePrefix;
  constructor(public payload: string) {}
}

export type SitePrefixActions = SetActiveSitePrefix;
