import { StateUtils } from '@spartacus/core';
import { PagedSearchAction } from '../../../../core/features/store/actions/search.actions';
import { AddressSearchParams } from '../../../../core/model';
import { PyStateUtils } from '../../../../core/state/utils';
import { DELIVERY_ADDRESS } from '../sold-to-state';

export enum DeliveryAddressActionTypes {
  LoadDeliveryAddresses = '[SoldTo] Load Delivery Addresses',
  LoadDeliveryAddressesSuccess = '[SoldTo] Load Delivery Addresses Success',
  LoadDeliveryAddressesFail = '[SoldTo] Load Delivery Addresses Fail',
}

export class LoadDeliveryAddresses extends StateUtils.LoaderLoadAction implements PagedSearchAction {
  readonly type = DeliveryAddressActionTypes.LoadDeliveryAddresses;
  readonly searchType = DELIVERY_ADDRESS;

  constructor(public searchParams: AddressSearchParams, public userId: string) {
    super(DELIVERY_ADDRESS);
  }
}

export class LoadDeliveryAddressesFail extends PyStateUtils.LoaderFailAction implements PagedSearchAction {
  readonly type = DeliveryAddressActionTypes.LoadDeliveryAddressesFail;
  readonly searchType = DELIVERY_ADDRESS;

  constructor(public searchParams: AddressSearchParams, public payload?: any) {
    super(DELIVERY_ADDRESS, payload);
  }
}

export class LoadDeliveryAddressesSuccess extends StateUtils.LoaderSuccessAction implements PagedSearchAction {
  readonly type = DeliveryAddressActionTypes.LoadDeliveryAddressesSuccess;
  readonly searchType = DELIVERY_ADDRESS;

  constructor(public payload: any, public searchParams: AddressSearchParams) {
    super(DELIVERY_ADDRESS);
  }
}

export type DeliveryAddressActions = LoadDeliveryAddresses | LoadDeliveryAddressesSuccess | LoadDeliveryAddressesFail;
