import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DrawerService } from '../../../services/drawer/drawer.service';

@Component({
  selector: 'py-drawer-header',
  templateUrl: './drawer-header.component.html',
  styleUrls: ['./drawer-header.component.scss'],
})
export class DrawerHeaderComponent {
  @Input() showBackButton = false;
  @Input() title?: string;

  @Output() backButtonClick = new EventEmitter();

  constructor(private drawerService: DrawerService) {}

  onBackButtonClick(): void {
    this.backButtonClick.emit();
  }

  closeDrawer(): void {
    this.drawerService.closeOpenDrawer();
  }
}
