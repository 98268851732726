import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostListener,
  NgZone,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { ThemeType } from '@ant-design/icons-angular';
import { NzIconDirective, NzIconPatchService, NzIconService } from 'ng-zorro-antd/icon';

interface ThemeTypeConfig {
  defaultThemeType: ThemeType;
  oppositeThemeType: ThemeType;
}
/**
 * Directive that sets opposite ant icon theme if icon is hovered
 */
@Directive({
  selector: '[pyIconThemeReverse]',
})
export class IconThemeReverseDirective extends NzIconDirective implements OnInit, OnChanges {
  private themeTypeConfig: ThemeTypeConfig;

  constructor(
    zone: NgZone,
    changeDetectorRef: ChangeDetectorRef,
    elementRef: ElementRef,
    iconService: NzIconService,
    renderer: Renderer2,
    iconPatch: NzIconPatchService
  ) {
    super(zone, changeDetectorRef, elementRef, iconService, renderer, iconPatch);
  }

  ngOnInit(): void {
    const defaultTheme = this.theme || 'outline';
    this.themeTypeConfig = {
      defaultThemeType: defaultTheme,
      oppositeThemeType: defaultTheme === 'outline' ? 'fill' : 'outline',
    };
  }

  @HostListener('mouseenter') onMouseEnter(): void {
    this.setThemeType(this.themeTypeConfig.oppositeThemeType);
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    this.setThemeType(this.themeTypeConfig.defaultThemeType);
  }

  private setThemeType(newThemeType: ThemeType): void {
    if (this.theme !== newThemeType) {
      this.theme = newThemeType;
      this._changeIcon();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }
}
