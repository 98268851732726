import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        checkoutConfirmation: {
          module: () => import('./container/checkout-confirmation.module').then((m) => m.CheckoutConfirmationModule),
          cmsComponents: ['CmsOrderConfirmationFlexComponent'],
        },
      },
    }),
  ],
})
export class CheckoutConfirmationFeatureModule {}
