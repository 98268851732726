<py-modal
  [actions]="templateActions"
  [showCancelButton]="true"
  [cancelButtonText]="'common.cancel_action' | cxTranslate"
  [title]="'common.nameMarking.nameMarking_title' | cxTranslate"
  [subtitle]="articleTitle | ellipsis"
  [scrollable]="true"
  [centered]="true"
  [size]="'sm'"
  [cxFocus]="focusConfig"
  (esc)="closeModal('escape pressed')"
>
  <py-notification type="warning" class="mb-2">
    {{ 'common.nameMarking.nameMarking_text' | cxTranslate }}
  </py-notification>

  <form [formGroup]="formGroup" class="d-flex pt-2 flex-column">
    <py-reactive-form-checkbox-slider
      *ngIf="inEcommerceQuantity > 1"
      class="mb-4"
      [addMargin]="false"
      [rightMargin]="true"
      [group]="formGroup"
      [rightLabel]="'common.nameMarking.sameForAll_heading' | cxTranslate"
      fieldName="sameNameOnAll"
    >
    </py-reactive-form-checkbox-slider>

    <ng-container formArrayName="names">
      <div *ngFor="let nameGroup of nameControls; let i = index" class="d-flex flex-row align-items-start">
        <div class="mr-2 mb-2 d-flex flex-row align-self-center">
          <py-badge [tag]="true" [large]="true" *ngIf="nameControls.length > 1">{{ i + 1 }}</py-badge>
        </div>

        <py-reactive-form-input-field
          class="w-100"
          inputType="text"
          [placeholder]="'common.nameMarking.name_placeholder' | cxTranslate"
          [fieldName]="'name'"
          [group]="nameGroup"
          [resettable]="true"
          (handleReset)="nameGroup.get('name').reset()"
        ></py-reactive-form-input-field>
      </div>
    </ng-container>
  </form>
</py-modal>

<ng-template #templateActions>
  <py-action-button
    [buttonClass]="'btn btn-primary'"
    [type]="'button'"
    [disabled]="!formGroup.valid"
    [buttonLabel]="'common.save_action' | cxTranslate"
    (click)="save()"
  >
  </py-action-button>
</ng-template>
