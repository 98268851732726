import { APP_BOOTSTRAP_LISTENER, NgModule } from '@angular/core';
import { WindowRef } from '../../core/window';
import { OnlineChatFeatureService } from './online-chat-feature.service';

export function checkoutFeatureServiceFactory(
  onlineChatFeatureService: OnlineChatFeatureService,
  windowRef: WindowRef
): () => void {
  return windowRef.isBrowser() ? () => onlineChatFeatureService.init() : () => {};
}

@NgModule({
  providers: [
    {
      provide: APP_BOOTSTRAP_LISTENER,
      useFactory: checkoutFeatureServiceFactory,
      deps: [OnlineChatFeatureService, WindowRef],
      multi: true,
    },
  ],
})
export class OnlineChatFeatureModule {}
