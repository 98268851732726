import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

export type DrawerTemplateRef = string;
export interface DrawerTemplate {
  key: DrawerTemplateRef;
  body: TemplateRef<any>;
  header?: TemplateRef<any> | null;
  footer?: TemplateRef<any> | null;
  parent?: DrawerTemplateRef;
}

@Component({
  selector: 'py-drawer-template',
  templateUrl: './drawer-template.component.html',
  styleUrls: ['./drawer-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawerTemplateComponent {
  @Input() activeTemplateKey?: DrawerTemplateRef;
  @Input() body: TemplateRef<any>;
  @Input() header?: TemplateRef<any>;
  @Input() footer?: TemplateRef<any>;
  @Input() children?: DrawerTemplate[];
  @Input() title?: string;

  /* Deprecated, use children instead */
  @Input() rootShowBackButton = false;

  @Output() activeTemplateKeyChange = new EventEmitter<DrawerTemplateRef | undefined>();

  /* Deprecated, use children instead */
  @Output() backButtonClick = new EventEmitter<void>();

  public get activeTemplate(): DrawerTemplate | undefined {
    return this.activeTemplateKey && this.children?.find((child) => child.key === this.activeTemplateKey);
  }

  public get activeHeader(): TemplateRef<any> | undefined {
    return this.activeTemplate ? this.activeTemplate.header : this.header;
  }

  public get activeBody(): TemplateRef<any> {
    return this.activeTemplate ? this.activeTemplate.body : this.body;
  }

  public get activeFooter(): TemplateRef<any> | undefined {
    return this.activeTemplate ? this.activeTemplate.footer : this.footer;
  }

  public get showBackButton(): boolean {
    return !!this.activeTemplate || this.rootShowBackButton;
  }

  public onBackButtonClick() {
    this.backButtonClick.emit();
    this.activeTemplateKeyChange.emit(this.activeTemplate?.parent);
  }
}
