import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../core/i18n/i18n.module';
import { PrincipalConfigurationDirectiveModule } from '../../shared';
import { ReactiveFormCheckboxSliderModule } from '../../shared/components/reactive-forms-input/reactive-form-checkbox-slider/reactive-form-checkbox-slider.module';
import { ReactiveFormInputFieldModule } from '../../shared/components/reactive-forms-input/reactive-form-input-field/reactive-form-input-field.module';
import { AnonymousConsentSettingsComponent } from './components/anonymous-consent-settings/anonymous-consent-settings.component';
import { ConsentBannerComponent } from './components/consent-banner/consent-banner.component';
import { ConsentSettingsComponent } from './components/consent-settings/consent-settings.component';
import { AnonymousConsentManagementComponent } from './containers/anonymous-consent-management/anonymous-consent-management.component';
import { ConsentManagementComponent } from './containers/consent-management/consent-management.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    PrincipalConfigurationDirectiveModule,
    FormsModule,
    ReactiveFormsModule,
    ReactiveFormInputFieldModule,
    ReactiveFormCheckboxSliderModule,
    PrincipalConfigurationDirectiveModule,
    NzIconModule,
  ],
  declarations: [
    AnonymousConsentManagementComponent,
    ConsentManagementComponent,
    ConsentSettingsComponent,
    ConsentBannerComponent,
    AnonymousConsentSettingsComponent,
  ],
  exports: [AnonymousConsentManagementComponent, ConsentManagementComponent],
})
export class ConsentManagementModule {}
