import { CxEvent } from '@spartacus/core';

export enum ConsentCode {
  MARKETING_THIRD_PARTY = 'MARKETING_THIRD_PARTY',
  TRACKING = 'TRACKING',
  PERSONALIZATION = 'PERSONALIZATION',
}

/**
 * Indicates that a user has given or revoked consent.
 */
export class ConsentEvent extends CxEvent {
  /**
   *  Event's type
   */
  static readonly type = 'ConsentEvent';
  /**
   *
   */
  code: ConsentCode;
  /**
   * Status of the consent (given, withdrawn)
   */
  isGiven: boolean;
}
