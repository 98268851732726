import {
  ANONYMOUS_CONSENT_STATUS,
  AnonymousConsent,
  AnonymousConsentsActions as CxAnonymousConsentsActions,
} from '@spartacus/core';
export const initialState: AnonymousConsent[] = [];

export function toggleConsentStatus(
  consents: AnonymousConsent[],
  templateCode: string,
  status: ANONYMOUS_CONSENT_STATUS
): AnonymousConsent[] {
  if (!consents) {
    return [];
  }

  return consents.map((consent) => {
    if (consent.templateCode === templateCode) {
      consent = {
        ...consent,
        consentState: status,
      };
    }
    return consent;
  });
}

export function reducer(state = initialState, action: CxAnonymousConsentsActions.AnonymousConsentsActions): AnonymousConsent[] {
  switch (action.type) {
    case CxAnonymousConsentsActions.GIVE_ANONYMOUS_CONSENT: {
      return toggleConsentStatus(state, action.templateCode, ANONYMOUS_CONSENT_STATUS.GIVEN);
    }

    case CxAnonymousConsentsActions.WITHDRAW_ANONYMOUS_CONSENT: {
      return toggleConsentStatus(state, action.templateCode, ANONYMOUS_CONSENT_STATUS.WITHDRAWN);
    }

    case CxAnonymousConsentsActions.SET_ANONYMOUS_CONSENTS: {
      return action.payload;
    }
  }

  return state;
}
