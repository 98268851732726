import { Action } from '@ngrx/store';
import { EntriesState } from '../../../features/store/base-feature-state';
import {
  OrderApproval,
  OrderApprovalDecision,
  OrderApprovalDecisionValue,
  OrderApprovalStatus,
  OrderStatus,
} from '../../../model';
import { UserActions } from '../actions';

export const initialState: EntriesState<OrderApproval> = undefined;

export function reducer(state = initialState, action: Action): EntriesState<OrderApproval> {
  function getNewStatus(payload: {
    orderApprovalCode: string;
    orderApprovalDecision: OrderApprovalDecision;
  }): OrderApprovalStatus {
    switch (payload.orderApprovalDecision.decision) {
      case OrderApprovalDecisionValue.APPROVE:
        return OrderApprovalStatus.APPROVED;
      case OrderApprovalDecisionValue.REJECT:
        return OrderApprovalStatus.REJECTED;
      case OrderApprovalDecisionValue.RETURN_TO_USER:
        return OrderApprovalStatus.RETURNED_TO_USER;
      case OrderApprovalDecisionValue.HIJACK_ORDER:
        return OrderApprovalStatus.APPROVER_EDITING;
      default:
        return;
    }
  }

  switch (action.type) {
    case UserActions.OrderApprovalActionTypes.LOAD_ORDER_APPROVALS_ADMIN_SUCCESS: {
      const successAction = <UserActions.LoadOrderApprovalsSuccess | UserActions.LoadOrderApprovalsAdminSuccess>action;
      const results = successAction.payload.results;

      return results.reduce(
        (entities: { [id: string]: OrderApproval }, orderApproval: OrderApproval) => {
          return {
            ...entities,
            [orderApproval.code]: orderApproval,
          };
        },
        {
          ...state,
        }
      );
    }
    case UserActions.OrderApprovalActionTypes.LOAD_ORDER_APPROVALS_ADMIN_RESET: {
      return initialState;
    }
    case UserActions.OrderApprovalActionTypes.MAKE_DECISION_SUCCESS: {
      if (!state) {
        return state;
      }
      const decisionAction: UserActions.MakeDecisionSuccess = <UserActions.MakeDecisionSuccess>action;
      const payload = decisionAction.payload;
      const stateElement: OrderApproval = { ...state[payload.orderApprovalCode] };
      stateElement.approvalDecisionRequired = false;
      stateElement.completionDate = new Date().toUTCString();
      //TODO: stateElement.customerOrderApprovalRecords
      stateElement.status = getNewStatus(payload) ?? stateElement.status;
      stateElement.approvalStatus = getNewStatus(payload) ?? stateElement.approvalStatus;
      return {
        ...state,
        [payload.orderApprovalCode]: stateElement,
      };
    }
    case UserActions.OrderApprovalActionTypes.ABORT_ORDER_APPROVAL_HIJACK_FAIL:
    case UserActions.OrderApprovalActionTypes.ABORT_ORDER_APPROVAL_HIJACK_SUCCESS: {
      if (!state) {
        return state;
      }
      const abortAction: UserActions.AbortOrderApprovalHijackSuccess = <UserActions.AbortOrderApprovalHijackSuccess>action;
      const stateElement: OrderApproval = { ...state[abortAction.payload] };
      stateElement.approvalDecisionRequired = true;
      stateElement.completionDate = null;
      stateElement.status = OrderStatus.PENDING_APPROVAL;
      return {
        ...state,
        [abortAction.payload]: stateElement,
      };
    }
    case UserActions.OrderApprovalActionTypes.LOAD_ORDER_APPROVAL_DETAILS_SUCCESS: {
      const successAction = <UserActions.LoadOrderApprovalDetailsSuccess>action;
      const payload = successAction.payload;

      if (!state || !state[payload.code]) {
        return state;
      }

      return {
        ...state,
        [payload.code]: payload,
      };
    }
    default:
      return state;
  }
}
