import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { LaunchDialogService } from '../../core/modal';
import { legalModalLayoutConfig } from '../../shared/components/legal-modal/legal-modal-layout.config';

@NgModule({
  imports: [],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        brandLandingPageFooterContent: {
          module: () => import('./components/footer-content/footer-content.module').then((m) => m.FooterContentModule),
          cmsComponents: ['CmsBrandLandingPageFooterContentComponent'],
        },
        brandLandingPageMainContent: {
          module: () => import('./components/main-content/main-content.module').then((m) => m.MainContentModule),
          cmsComponents: ['CmsBrandLandingPageMainContentComponent'],
        },
      },
    }),
  ],
})
export class BrandLandingPageFeatureModule {
  constructor(private launchDialogService: LaunchDialogService) {
    this.launchDialogService.registerModalLayoutConfig(legalModalLayoutConfig);
  }
}
