import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Article,
  ArticleCarouselActionMode,
  ArticleCarouselItem,
  ColumnAttribute,
  SubstituteRef,
  SubstituteType,
} from '../../../core/model';
import { ArticleInfoAttributesFacade } from '../../../core/user';

@Component({
  selector: 'py-article-carousel',
  templateUrl: './article-carousel.component.html',
  styleUrls: ['./article-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleCarouselComponent {
  carouselActionModes: typeof ArticleCarouselActionMode = ArticleCarouselActionMode;

  @Input() carouselItems?: ArticleCarouselItem[];
  @Input() actionLabel: string;
  @Input() disabled = false;
  @Input() loading = false;
  @Input() substituteRefs?: SubstituteRef[];
  @Input() tooltipMessage?: string;
  @Input() iconType?: string;
  @Input() hideOverflow = false;
  @Input() enableSecondaryArticleRowVariant = false;
  @Input() showPaginationAtTheBottom = false;
  @Input() showAlternativeArticleBadge = false; // alternative badge should be displayed for similar articles
  @Input() useDefaultArticleQuantityAndUnit = false;
  @Input() carouselActionMode: ArticleCarouselActionMode = this.carouselActionModes.ENTRY_INTERACTION_MODE;

  @Output() articleInteraction = new EventEmitter<ArticleCarouselItem>();

  constructor(private articleInfoAttributesService: ArticleInfoAttributesFacade) {}

  onArticleInteraction(data: ArticleCarouselItem): void {
    this.articleInteraction.emit(data);
  }

  getSubstituteBadgeType(substituteCode: string): SubstituteType {
    const substituteRef = this.substituteRefs?.find((substitute) => substitute.code === substituteCode);
    if (!!substituteRef?.substituteType) {
      return substituteRef?.substituteType;
    }
    return this.showAlternativeArticleBadge ? SubstituteType.Alternative : undefined;
  }

  getColumnAttributesForArticle(article: Article): Observable<ColumnAttribute[]> {
    return this.articleInfoAttributesService.getColumnAttributes(article, this.enableSecondaryArticleRowVariant);
  }
}
