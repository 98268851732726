import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { bufferCount, catchError, exhaustMap, filter, map } from 'rxjs/operators';
import { normalizeHttpError } from '../../../util';
import { SiteConnector } from '../../connectors/site.connector';
import { SiteContextActions } from '../actions/index';
import { LanguagesActionTypes } from '../actions/languages.action';
import { getActiveLanguage } from '../selectors/languages.selectors';
import { StateWithSiteContext } from '../site-context-state';

@Injectable()
export class LanguagesEffects {
  loadLanguages$: Observable<SiteContextActions.LoadLanguagesSuccess | SiteContextActions.LoadLanguagesFail> = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguagesActionTypes.LoadLanguages),
      exhaustMap(() => {
        return this.siteConnector.getLanguages().pipe(
          map((languages) => new SiteContextActions.LoadLanguagesSuccess(languages)),
          catchError((error) => of(new SiteContextActions.LoadLanguagesFail(normalizeHttpError(error))))
        );
      })
    )
  );

  activateLanguage$: Observable<SiteContextActions.LanguageChange> = createEffect(() =>
    this.state.select(getActiveLanguage).pipe(
      bufferCount(2, 1),

      // avoid dispatching `change` action when we're just setting the initial value:
      filter(([previous]) => !!previous),
      map(([previous, current]) => new SiteContextActions.LanguageChange({ previous, current }))
    )
  );

  constructor(private actions$: Actions, private siteConnector: SiteConnector, private state: Store<StateWithSiteContext>) {}
}
