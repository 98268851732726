import { Action } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { SolrSearchConfig, SolrSearchResult, SolrSearchType } from '../../../model/solr-search-config';
import { PyStateUtils } from '../../../state/utils';
import { SOLR_SEARCH_FEATURE } from '../solr-search-state';

export enum SolrSearchActionTypes {
  LoadSolrSearch = '[SolrSearch] Load SolrSearch',
  LoadSolrSearchSuccess = '[SolrSearch] Load SolrSearch Success',
  LoadSolrSearchFail = '[SolrSearch] Load SolrSearch Fail',
  LoadSolrSearchTotalCount = '[SolrSearch] Load SolrSearchTotalCount',
  ClearSearch = '[SolrSearch] Clear search',
  LoadSolrSearchTotalCountSuccess = '[SolrSearch] Load SolrSearchTotalCount Success',
  LoadSolrSearchTotalCountFail = '[SolrSearch] Load SolrSearchTotalCount Fail',
}

export class LoadSolrSearch extends StateUtils.LoaderLoadAction {
  readonly type = SolrSearchActionTypes.LoadSolrSearch;

  constructor(
    public query: string,
    public config: SolrSearchConfig,
    public userId: string,
    public loadAllProductArticles?: boolean
  ) {
    super(`${SOLR_SEARCH_FEATURE}:${config.searchType}`);
  }
}

export class LoadSolrSearchSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = SolrSearchActionTypes.LoadSolrSearchSuccess;

  constructor(
    public payload: SolrSearchResult,
    public searchType: SolrSearchType,
    public query: string,
    public userId?: string,
    public searchConfig?: SolrSearchConfig,
    public loadAllProductArticles?: boolean
  ) {
    super(`${SOLR_SEARCH_FEATURE}:${searchType}`);
  }
}

export class LoadSolrSearchFail extends PyStateUtils.LoaderFailAction {
  readonly type = SolrSearchActionTypes.LoadSolrSearchFail;

  constructor(public payload: any, public searchType: SolrSearchType, public query: string) {
    super(`${SOLR_SEARCH_FEATURE}:${searchType}`);
  }
}

export class LoadSolrSearchTotalCount implements Action {
  readonly type = SolrSearchActionTypes.LoadSolrSearchTotalCount;

  constructor(public query: string, public config: SolrSearchConfig, public userId: string) {}
}

export class ClearSearch implements Action {
  readonly type = SolrSearchActionTypes.ClearSearch;

  constructor() {}
}

export class LoadSolrSearchTotalCountSuccess implements Action {
  readonly type = SolrSearchActionTypes.LoadSolrSearchTotalCountSuccess;

  constructor(public payload: number, public query: string, public searchType: SolrSearchType) {}
}

export class LoadSolrSearchTotalCountFail implements Action {
  readonly type = SolrSearchActionTypes.LoadSolrSearchTotalCountFail;

  constructor(public payload: any, public query: string, public searchType: SolrSearchType) {}
}

export type SolrSearchActions =
  | LoadSolrSearch
  | LoadSolrSearchSuccess
  | LoadSolrSearchFail
  | ClearSearch
  | LoadSolrSearchTotalCount
  | LoadSolrSearchTotalCountSuccess
  | LoadSolrSearchTotalCountFail;
