<div class="text-center" [ngClass]="{ 'small-loader': small }">
  <div class="wrapper">
    <div class="pagination-wrapper mb-4">
      {{
        'common.pagination_hint'
          | cxTranslate
            : {
                itemCount: itemCount,
                totalItemCount: totalItemCount || 0,
                itemTypeName: totalItemCountName || ('common.defaultItemTypeName_hint' | cxTranslate)
              }
      }}
    </div>
    <div class="progressbar-wrapper mb-4">
      <ngb-progressbar type="primary" [value]="percentage" height="5px"></ngb-progressbar>
    </div>
  </div>
  <py-action-button
    *ngIf="showLoadMoreButton"
    [buttonClass]="small ? 'btn btn-secondary btn-sm' : 'btn btn-secondary'"
    (click)="handleClick()"
    [disabled]="disabled || totalPageCount <= currentPageNumber + 1"
    [loading]="loading"
    [buttonLabel]="'common.loadMore_action' | cxTranslate"
    [buttonId]="buttonId"
  >
  </py-action-button>
</div>
