import { StateUtils } from '@spartacus/core';
import { PagedSearchAction } from '../../../../core/features/store/actions/search.actions';
import { AvailableSoldTosSearchParams } from '../../../../core/model';
import { PyStateUtils } from '../../../../core/state/utils';
import { SOLD_TOS, SOLD_TOS_ON_USER_DETAILS } from '../sold-to-state';

export enum AvailableSoldToActionTypes {
  LoadAvailableSoldTos = '[SoldTo] Load available sold-tos',
  LoadAvailableSoldTosSuccess = '[SoldTo] Load available sold-tos success',
  LoadAvailableSoldTosFail = '[SoldTo] Load available sold-tos fail',
  LoadAvailableSoldTosOnUserDetails = '[SoldTo] Load available sold-tos on user details page',
  LoadAvailableSoldTosOnUserDetailsSuccess = '[SoldTo] Load available sold-tos on user details page success',
  LoadAvailableSoldTosOnUserDetailsFail = '[SoldTo] Load available sold-tos on user details page fail',
}

export class LoadAvailableSoldTos extends StateUtils.LoaderLoadAction implements PagedSearchAction {
  readonly type = AvailableSoldToActionTypes.LoadAvailableSoldTos;
  readonly searchType = SOLD_TOS;

  constructor(public searchParams: AvailableSoldTosSearchParams, public userId: string) {
    super(SOLD_TOS);
  }
}

export class LoadAvailableSoldTosFail extends PyStateUtils.LoaderFailAction implements PagedSearchAction {
  readonly type = AvailableSoldToActionTypes.LoadAvailableSoldTosFail;
  readonly searchType = SOLD_TOS;

  constructor(public searchParams: AvailableSoldTosSearchParams, public payload?: any) {
    super(SOLD_TOS, payload);
  }
}

export class LoadAvailableSoldTosSuccess extends StateUtils.LoaderSuccessAction implements PagedSearchAction {
  readonly type = AvailableSoldToActionTypes.LoadAvailableSoldTosSuccess;
  readonly searchType = SOLD_TOS;

  constructor(public payload: any, public searchParams: AvailableSoldTosSearchParams) {
    super(SOLD_TOS);
  }
}

export class LoadAvailableSoldTosOnUserDetails extends StateUtils.LoaderLoadAction implements PagedSearchAction {
  readonly type = AvailableSoldToActionTypes.LoadAvailableSoldTosOnUserDetails;
  readonly searchType = SOLD_TOS_ON_USER_DETAILS;

  constructor(public searchParams: AvailableSoldTosSearchParams, public userId: string) {
    super(SOLD_TOS_ON_USER_DETAILS);
  }
}

export class LoadAvailableSoldTosOnUserDetailsFail extends PyStateUtils.LoaderFailAction implements PagedSearchAction {
  readonly type = AvailableSoldToActionTypes.LoadAvailableSoldTosOnUserDetailsFail;
  readonly searchType = SOLD_TOS_ON_USER_DETAILS;

  constructor(public searchParams: AvailableSoldTosSearchParams, public payload?: any) {
    super(SOLD_TOS_ON_USER_DETAILS, payload);
  }
}

export class LoadAvailableSoldTosOnUserDetailsSuccess extends StateUtils.LoaderSuccessAction implements PagedSearchAction {
  readonly type = AvailableSoldToActionTypes.LoadAvailableSoldTosOnUserDetailsSuccess;
  readonly searchType = SOLD_TOS_ON_USER_DETAILS;

  constructor(public payload: any, public searchParams: AvailableSoldTosSearchParams) {
    super(SOLD_TOS_ON_USER_DETAILS);
  }
}

export type AvailableSoldTosActions =
  | LoadAvailableSoldTos
  | LoadAvailableSoldTosSuccess
  | LoadAvailableSoldTosFail
  | LoadAvailableSoldTosOnUserDetails
  | LoadAvailableSoldTosOnUserDetailsSuccess
  | LoadAvailableSoldTosOnUserDetailsFail;
