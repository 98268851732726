import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  AnonymousConsent,
  AuthService,
  AnonymousConsentsActions as CxAnonymousConsentsActions,
  AnonymousConsentsSelectors as CxAnonymousConsentsSelectors,
  AnonymousConsentsService as CxAnonymousConsentsService,
} from '@spartacus/core';
import { Observable, combineLatest, iif } from 'rxjs';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { ConsentTemplate } from '../../model/index';
import { ConsentsConfig } from '../config/consents-config';
import { AnonymousConsentsActions } from '../store/actions/index';
import { StateWithAnonymousConsents } from '../store/anonymous-consents-state';
import { AnonymousConsentsSelectors } from '../store/selectors/index';
@Injectable({ providedIn: 'root' })
export class AnonymousConsentsService extends CxAnonymousConsentsService {
  constructor(
    protected store: Store<StateWithAnonymousConsents>,
    protected authService: AuthService,
    private config: ConsentsConfig,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    super(store, authService);
  }

  /**
   * Conditionally triggers the load of the anonymous consent templates if:
   *   - `loadIfMissing` parameter is set to `true`
   *   - the `templates` in the store are `undefined`
   *
   * Otherwise it just returns the value from the store.
   *
   * @param loadIfMissing setting to `true` will trigger the load of the templates if the currently stored templates are `undefined`
   */
  getTemplates(loadIfMissing = false): Observable<ConsentTemplate[]> {
    return iif(
      () => loadIfMissing,
      this.store.pipe(
        select(CxAnonymousConsentsSelectors.getAnonymousConsentTemplatesValue),
        withLatestFrom(this.getLoadTemplatesLoading()),
        filter(([_templates, loading]) => !loading),
        tap(([templates, _loading]) => {
          if (!Boolean(templates)) {
            this.loadTemplates();
          }
        }),
        filter(([templates, _loading]) => Boolean(templates)),
        map(([templates, _loading]) => templates),
        map((templates) => templates.filter((template) => this.config.consents.anonymous.consents.includes(template.id)))
      ),
      this.store.pipe(
        select(CxAnonymousConsentsSelectors.getAnonymousConsentTemplatesValue),
        map(
          (templates) =>
            templates && templates.filter((template) => this.config.consents.anonymous.consents.includes(template.id))
        )
      )
    );
  }

  /**
   * Returns all the anonymous consents.
   */
  getConsents(): Observable<AnonymousConsent[]> {
    return this.store.pipe(
      select(CxAnonymousConsentsSelectors.getAnonymousConsents),
      map((consents) => consents.filter((consent) => this.config.consents.anonymous.consents.includes(consent.templateCode)))
    );
  }

  /**
   * Give a consent for the given `templateCode`
   * @param templateCode for which to give the consent
   */
  giveConsent(templateCode: string): void {
    if (this.config.consents.anonymous.consents.includes(templateCode)) {
      this.store.dispatch(new CxAnonymousConsentsActions.GiveAnonymousConsent(templateCode));
    }
  }

  /**
   * Sets all the anonymous consents' state to given.
   */
  giveAllConsents(): Observable<ConsentTemplate[]> {
    return this.getTemplates(true).pipe(
      map((templates) => templates.filter((t) => this.config.consents.anonymous.consents.includes(t.id))),
      tap((templates) => templates.forEach((template) => this.giveConsent(template.id)))
    );
  }

  /**
   * Withdraw a consent for the given `templateCode`
   * @param templateCode for which to withdraw the consent
   */
  withdrawConsent(templateCode: string): void {
    if (this.config.consents.anonymous.consents.includes(templateCode)) {
      this.store.dispatch(new CxAnonymousConsentsActions.WithdrawAnonymousConsent(templateCode));
    }
  }

  /**
   * Sets all the anonymous consents' state to withdrawn.
   */
  withdrawAllConsents(): Observable<ConsentTemplate[]> {
    return this.getTemplates(true).pipe(
      map((templates) => templates.filter((t) => this.config.consents.anonymous.consents.includes(t.id))),
      tap((templates) => templates.forEach((template) => this.withdrawConsent(template.id)))
    );
  }

  /**
   * Toggles the visible state of the anonymous consents settings banner.
   * @param visible the banner will be shown if `true` is passed, otherwise it will be hidden.
   */
  toggleSettingsVisible(visible: boolean, showCloseButton?: boolean): void {
    this.store.dispatch(
      new AnonymousConsentsActions.SetAnonymousConsentsSettingsVisible(visible, visible ? showCloseButton : false)
    );
  }

  /**
   * Returns `true` if we should show close button and hide the back button, `false` if we should hide close button and show the back button.
   */
  showCloseButton(): Observable<boolean> {
    return this.store.pipe(select(AnonymousConsentsSelectors.getAnonymousConsentsShowCloseButtonVisible));
  }

  /**
   * Returns `true` if the banner is visible, `false` otherwise.
   */
  isSettingsVisible(): Observable<boolean> {
    return this.store.pipe(select(AnonymousConsentsSelectors.getAnonymousConsentsSettingsVisible));
  }

  /**
   * Returns `true` if either the banner is not dismissed or if the templates were updated on the back-end.
   * Otherwise, it returns `false`.
   */
  isBannerVisible(): Observable<boolean> {
    return combineLatest([this.isBannerDismissed(), this.getTemplatesUpdated()]).pipe(
      tap(() => this.checkUpdatedConsentVersions()),
      map(([dismissed, updated]) => (!isPlatformServer(this.platformId) && !dismissed) || updated)
    );
  }

  private checkUpdatedConsentVersions(): void {
    this.store.dispatch(new CxAnonymousConsentsActions.AnonymousConsentCheckUpdatedVersions());
  }

  isConsentRequired(templateCode: string) {
    return this.config.consents.anonymous.requiredConsents.includes(templateCode);
  }

  getRequiredConsents(): string[] {
    return this.config.consents.anonymous.requiredConsents;
  }

  getHiddenConsents(): string[] {
    return this.config.consents.anonymous.hiddenConsents;
  }
}
