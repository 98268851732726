<ng-container *ngIf="loading; else mainContainer">
  <div class="main">
    <div class="skeleton"></div>
  </div>
</ng-container>

<ng-template #mainContainer>
  <ng-container *ngIf="isDescription; else isTitleTemplate">
    <p class="description">
      <ng-container *ngTemplateOutlet="main"></ng-container>
    </p>
  </ng-container>

  <ng-template #isTitleTemplate>
    <ng-container *ngIf="isTitle; else main">
      <h2 class="name">
        <ng-container *ngTemplateOutlet="main"></ng-container>
      </h2>
    </ng-container>
  </ng-template>
</ng-template>

<ng-template #main>
  <div class="main" [class.disabled]="disableEditing">
    <ng-container *ngIf="shouldEdit; else heading">
      <span class="input-wrapper">
        <input
          #inputRef
          autofocus
          [(ngModel)]="value"
          [placeholder]="placeholder"
          [maxlength]="maxlength"
          (blur)="secondaryVariant && onSave()"
        />
      </span>

      <span #hiddenTextRef class="hidden">{{ value || placeholder }}</span>

      <button *ngIf="!secondaryVariant && cancellable" (click)="onCancel()">
        <i nz-icon nzType="close-circle" [ngbTooltip]="'common.cancel_action' | cxTranslate"></i>
      </button>

      <button *ngIf="!secondaryVariant" (click)="onSave()" [disabled]="!value?.trim()">
        <i nz-icon nzType="save" [ngbTooltip]="'common.save_action' | cxTranslate"></i>
      </button>
    </ng-container>

    <ng-template #heading>
      <span
        class="heading"
        [ngbTooltip]="'common.edit_action' | cxTranslate"
        [disableTooltip]="disableEditing"
        (click)="secondaryVariant && !disableEditing && onEdit()"
        >{{ content || placeholder }}</span
      >
      <button *ngIf="!secondaryVariant" (click)="onEdit()" [disabled]="disableEditing">
        <i nz-icon nzType="edit" [ngbTooltip]="'common.edit_action' | cxTranslate"></i>
      </button>

      <cx-spinner *ngIf="saving" class="ml-1"></cx-spinner>
    </ng-template>
  </div>
</ng-template>
