import { Injectable } from '@angular/core';
import { AlternativeArticle, AlternativeArticles } from '../../../model';
import { Occ } from '../../../occ/occ-models';
import { Converter } from '../../../util';

@Injectable({ providedIn: 'root' })
export class SimilarArticlesNormalizer implements Converter<Occ.AlternativeArticles, AlternativeArticles> {
  convert(source: Occ.AlternativeArticles, target?: AlternativeArticles): AlternativeArticles {
    if (source) {
      target = this.convertSimilarArticles(source);
    }

    return target;
  }

  private convertSimilarArticles(source: Occ.AlternativeArticles): AlternativeArticles {
    if (source) {
      return {
        alternativesTo: source.alternativeTo,
        alternatives: this.convertSimilarArticlesAlternatives(source.articles),
      };
    } else {
      return undefined;
    }
  }

  private convertSimilarArticlesAlternatives(source: Occ.ArticleRef[]): AlternativeArticle[] {
    return source ? source.map((alternative) => this.convertSimilarArticlesAlternative(alternative)).filter(Boolean) : [];
  }

  private convertSimilarArticlesAlternative(source: Occ.ArticleRef): AlternativeArticle {
    return (
      source && {
        articleRef: source.articleNumber,
      }
    );
  }
}
