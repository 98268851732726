import { PagedSearchAction } from '../../../features/store/actions/search.actions';
import { EntriesState } from '../../../features/store/base-feature-state';
import { SimpleCustomer } from '../../../model';
import { UserActions } from '../actions';

export const initialState: EntriesState<SimpleCustomer> = {};

export function reducer(searchType: string) {
  return (state = initialState, action: PagedSearchAction): EntriesState<any> => {
    if (searchType !== action.searchType) {
      return state;
    }
    switch (action.type) {
      case UserActions.OrderApprovalActionTypes.LOAD_AVAILABLE_ORDER_APPROVALS_BUYERS_SUCCESS: {
        const buyersSuccess = <UserActions.LoadAvailableOrderApprovalsBuyersSuccess>action;
        const payload = buyersSuccess.payload;
        const results = payload.results;
        return results.reduce(
          (entities: { [uid: string]: SimpleCustomer }, entry: SimpleCustomer) => {
            return {
              ...entities,
              [entry.uid]: entry,
            };
          },
          {
            ...state,
          }
        );
      }
      default:
        return state;
    }
  };
}
