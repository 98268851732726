import { Action } from '@ngrx/store';
import { AccountingCodeUserInfo } from '../../../model/accounting-code.model';
import { AccountingCodesAdminActionTypes } from '../actions/accounting-codes-admin.action';
import { UserActions } from '../actions/index';

export const initialState: AccountingCodeUserInfo = null;

export function reducer(state = initialState, action: Action): AccountingCodeUserInfo {
  switch (action.type) {
    case AccountingCodesAdminActionTypes.LoadAccountingCodeUserInfoSuccess: {
      const _action = <UserActions.LoadAccountingCodeUserInfoSuccess>action;
      const payload: AccountingCodeUserInfo = _action.payload;
      return {
        articlesLastChanged: payload.articlesLastChanged,
        nrOfArticleLinks: payload.nrOfArticleLinks,
      };
    }
    default:
      return state;
  }
}
