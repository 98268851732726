import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import { Address, DeliveryDateInfo, HttpErrorModel } from '../../../../../core/model';
import { PyStateUtils } from '../../../../../core/state';
import { GET_DELIVERY_DATE_INFO_PROCESS, SET_DELIVERY_ADDRESS_PROCESS } from '../checkout-state';
import { CheckoutStepsEntityAction, PayloadWithCheckoutKey } from './checkout-steps-entity.action';

export enum CheckoutDeliveryActionTypes {
  SetDeliveryAddress = '[Checkout] Set delivery address',
  SetDeliveryAddressFail = '[Checkout] Set delivery address fail',
  SetDeliveryAddressSuccess = '[Checkout] Set delivery address success',
  ResetSetDeliveryAddress = '[Checkout] Reset set delivery address',

  GetDeliveryDateInfo = '[Checkout] Get next delivery date info',
  GetDeliveryDateInfoFail = '[Checkout] Get next delivery date info fail',
  GetDeliveryDateInfoSuccess = '[Checkout] Get next delivery date info success',

  ClearDeliveryStep = '[Checkout] Clear delivery step',
}

interface SetDeliveryAddressPayload extends PayloadWithCheckoutKey {
  userId: string;
  address: Address;
}

interface SetDeliveryAddressFailPayload extends SetDeliveryAddressPayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SetDeliveryAddressSuccessPayload extends SetDeliveryAddressPayload {}

interface GetDeliveryDateInfoPayload extends PayloadWithCheckoutKey {
  userId: string;
  addressId: string;
}

interface GetDeliveryDateInfoFailPayload extends GetDeliveryDateInfoPayload {
  error?: HttpErrorModel;
}

interface GetDeliveryDateInfoSuccessPayload extends GetDeliveryDateInfoPayload {
  deliveryDateInfo: DeliveryDateInfo;
}

export class SetDeliveryAddress extends StateUtils.EntityLoadAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutDeliveryActionTypes.SetDeliveryAddress;

  constructor(public payload: SetDeliveryAddressPayload) {
    super(PROCESS_FEATURE, SET_DELIVERY_ADDRESS_PROCESS);
  }
}

export class SetDeliveryAddressFail extends PyStateUtils.EntityFailAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutDeliveryActionTypes.SetDeliveryAddressFail;

  constructor(public payload: SetDeliveryAddressFailPayload) {
    super(PROCESS_FEATURE, SET_DELIVERY_ADDRESS_PROCESS, payload.error);
  }
}

export class SetDeliveryAddressSuccess extends StateUtils.EntitySuccessAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutDeliveryActionTypes.SetDeliveryAddressSuccess;

  constructor(public payload: SetDeliveryAddressSuccessPayload) {
    super(PROCESS_FEATURE, SET_DELIVERY_ADDRESS_PROCESS);
  }
}

export class ResetSetDeliveryAddress extends StateUtils.EntityLoaderResetAction {
  readonly type = CheckoutDeliveryActionTypes.ResetSetDeliveryAddress;

  constructor() {
    super(PROCESS_FEATURE, SET_DELIVERY_ADDRESS_PROCESS);
  }
}

export class GetDeliveryDateInfo extends StateUtils.EntityLoadAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutDeliveryActionTypes.GetDeliveryDateInfo;

  constructor(public payload: GetDeliveryDateInfoPayload) {
    super(PROCESS_FEATURE, GET_DELIVERY_DATE_INFO_PROCESS);
  }
}

export class GetDeliveryDateInfoFail extends PyStateUtils.EntityFailAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutDeliveryActionTypes.GetDeliveryDateInfoFail;

  constructor(public payload: GetDeliveryDateInfoFailPayload) {
    super(PROCESS_FEATURE, GET_DELIVERY_DATE_INFO_PROCESS, payload.error);
  }
}

export class GetDeliveryDateInfoSuccess extends StateUtils.EntitySuccessAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutDeliveryActionTypes.GetDeliveryDateInfoSuccess;

  constructor(public payload: GetDeliveryDateInfoSuccessPayload) {
    super(PROCESS_FEATURE, GET_DELIVERY_DATE_INFO_PROCESS);
  }
}

export class ClearDeliveryStep implements CheckoutStepsEntityAction {
  readonly type = CheckoutDeliveryActionTypes.ClearDeliveryStep;

  constructor(public payload: PayloadWithCheckoutKey) {}
}

export type CheckoutDeliveryActions =
  | SetDeliveryAddressFail
  | GetDeliveryDateInfoFail
  | GetDeliveryDateInfoSuccess
  | ClearDeliveryStep;
