import { ComponentRef, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigatedAction, routerNavigatedAction } from '@ngrx/router-store';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { LoginLogoutActionTypes, Logout } from '../../../auth/user-auth/store/actions/login-logout.action';
import { BaseAbstractModalComponent } from '../../abstract-components/base-abstract-modal';
import { LaunchDialogService } from '../../services/launch-dialog.service';

@Injectable()
export class ModalEffects {
  dismissActiveModal$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Logout | RouterNavigatedAction>(LoginLogoutActionTypes.Logout, routerNavigatedAction),
        withLatestFrom(this.launchDialogService.lastOpenedModal$),
        map(
          ([action, component]) =>
            [action, component] as [Logout | RouterNavigatedAction, ComponentRef<BaseAbstractModalComponent>]
        ),
        filter(([action, componentRef]) => {
          if (componentRef) {
            if (action.type === ROUTER_NAVIGATED && componentRef.instance?.dialogOptions?.autoDismissOnRouteChange) {
              return true;
            }
            if (action.type === LoginLogoutActionTypes.Logout && componentRef.instance?.dialogOptions?.autoDismissOnLogout) {
              return true;
            }
          }
          return false;
        }),
        map(([action, _component]) => action),
        tap(() => this.launchDialogService.closeDialog('navigate'))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private launchDialogService: LaunchDialogService) {}
}
