<form [formGroup]="form">
  <div class="container px-0">
    <py-notification class="mb-5" [type]="'info'" [message]="'cart.palletFlag.form_text' | cxTranslate"></py-notification>

    <div class="row">
      <div class="col-12">
        <py-reactive-form-input-field
          [fieldName]="'customer'"
          [label]="'cart.palletFlag.customer_heading' | cxTranslate"
          [placeholder]="'cart.palletFlag.customer_placeholder' | cxTranslate"
          [group]="form"
          [showMaxLengthHint]="false"
          [resettable]="true"
          [maxLength]="255"
          (handleReset)="clearField('customer')"
        ></py-reactive-form-input-field>
      </div>
    </div>

    <div class="row pt-2">
      <div class="col-12">
        <py-reactive-form-input-field
          [fieldName]="'machine'"
          [label]="'cart.palletFlag.machine_heading' | cxTranslate"
          [placeholder]="'cart.palletFlag.machine_placeholder' | cxTranslate"
          [group]="form"
          [maxLength]="255"
          [showMaxLengthHint]="false"
          [resettable]="true"
          (handleReset)="clearField('machine')"
        ></py-reactive-form-input-field>
      </div>
    </div>

    <div class="row pt-2">
      <div class="col-12">
        <py-reactive-form-input-field
          [fieldName]="'object'"
          [label]="'cart.palletFlag.object_heading' | cxTranslate"
          [placeholder]="'cart.palletFlag.object_placeholder' | cxTranslate"
          [group]="form"
          [maxLength]="255"
          [showMaxLengthHint]="false"
          [resettable]="true"
          (handleReset)="clearField('object')"
        ></py-reactive-form-input-field>
      </div>
    </div>
  </div>
</form>
