import { MemoizedSelector, MemoizedSelectorWithProps, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { PriceFileEntry } from '../../../../model/price-file.model';
import { Pagination } from '../../../../model/search.model';
import {
  EntriesState,
  PaginationState,
  SearchPageResultState,
  SearchParamsState,
  SearchState,
} from '../../../store/base-feature-state';
import { PriceFileState, PriceFilesState, StateWithPriceFile } from '../price-file-state';
import { getPriceFileState } from './feature.selector';

export const getPriceFilesState: MemoizedSelector<StateWithPriceFile, PriceFilesState> = createSelector(
  getPriceFileState,
  (state: PriceFileState) => state && state.priceFiles
);

export const getSearchResultEntities: MemoizedSelector<StateWithPriceFile, EntriesState<PriceFileEntry>> = createSelector(
  getPriceFilesState,
  (state: PriceFilesState) => state && state.entities
);

export const getSearchParamsSelector: MemoizedSelector<StateWithPriceFile, SearchParamsState> = createSelector(
  getPriceFilesState,
  (state: PriceFilesState) => state && state.searchParams
);

export const getSearchSelector: MemoizedSelector<StateWithPriceFile, SearchState<SearchPageResultState>> = createSelector(
  getPriceFilesState,
  (state: PriceFilesState) => state && state.search
);

export const getSearchPaginationStateSelector: MemoizedSelector<
  StateWithPriceFile,
  PaginationState<SearchPageResultState>
> = createSelector(
  getSearchSelector,
  getSearchParamsSelector,
  (state: SearchState<SearchPageResultState>, searchParams: SearchParamsState) => {
    return searchParams && state && state[searchParams.key];
  }
);

export const getSearchPaginationSelector: MemoizedSelector<StateWithPriceFile, Pagination> = createSelector(
  getSearchPaginationStateSelector,
  (state) => state && state.pagination
);

export const getSearchPageResultsSelector: MemoizedSelector<
  StateWithPriceFile,
  StateUtils.LoaderState<SearchPageResultState>
> = createSelector(
  getSearchParamsSelector,
  getSearchPaginationStateSelector,
  (queryParams: SearchParamsState, paginationState: PaginationState<SearchPageResultState>) => {
    return queryParams && paginationState && paginationState.pages && paginationState.pages[queryParams.page];
  }
);

export const getSearchResultEntries: MemoizedSelectorWithProps<StateWithPriceFile, any, PriceFileEntry[]> = createSelector(
  getSearchResultEntities,
  (entries: EntriesState<PriceFileEntry>, props) => {
    return props.keys && entries && props.keys.map((i) => entries[i]);
  }
);
