import { CxEvent } from '@spartacus/core';
import {
  TrackingEventAddEntriesToCart,
  TrackingEventAddToWishlist,
  TrackingEventLogout,
  TrackingEventPayment,
  TrackingEventRemoveFromWishlist,
  TrackingEventSelectItem,
} from '../events';

export class ApptusNotificationEventHandler {
  static send(notifyApptus, event: CxEvent) {
    if (event instanceof TrackingEventAddEntriesToCart) {
      return this.onTrackingEventAddEntriesToCart(notifyApptus, event);
    }
    if (event instanceof TrackingEventAddToWishlist) {
      return this.onTrackingEventAddToWishlist(notifyApptus, event);
    }
    if (event instanceof TrackingEventRemoveFromWishlist) {
      return this.onTrackingEventRemoveFromWishlist(notifyApptus, event);
    }
    if (event instanceof TrackingEventSelectItem) {
      return this.onTrackingEventSelectItem(notifyApptus, event);
    }
    if (event instanceof TrackingEventPayment) {
      return this.onTrackingEventPayment(notifyApptus, event);
    }
    if (event instanceof TrackingEventLogout) {
      return this.onTrackingEventLogout(notifyApptus);
    }

    return undefined;
  }

  private static onTrackingEventSelectItem(notifyApptus, event: TrackingEventSelectItem) {
    if (event.ticket) {
      notifyApptus.click(event.ticket);
    } else if (event.articleRef) {
      notifyApptus.nonEsalesClick({ variantKey: event.articleRef });
    } else if (event.productRef) {
      notifyApptus.nonEsalesClick({ productKey: event.productRef });
    }
  }

  private static onTrackingEventAddEntriesToCart(notifyApptus, event: TrackingEventAddEntriesToCart) {
    event.articles?.forEach((entry) => {
      if (entry?.ticket) {
        notifyApptus.addToCart(entry.ticket);
      } else {
        notifyApptus.nonEsalesAddToCart({ variantKey: event.articles[0].code });
      }
    });
  }

  private static onTrackingEventAddToWishlist(notifyApptus, event: TrackingEventAddToWishlist) {
    notifyApptus.addFavorite({ variantKey: event.articles[0].code });
  }

  private static onTrackingEventRemoveFromWishlist(notifyApptus, event: TrackingEventRemoveFromWishlist) {
    notifyApptus.removeFavorite({ variantKey: event.articles[0].code });
  }

  /*
   * We dont send the payment notification for now, because it is handeld in backend
   */
  private static onTrackingEventPayment(_notifyApptus, _event: TrackingEventPayment) {
    console.log('Apptus payment event is not sent from FE');
    // var payment = notifyApptus.payment();
    // event.cart.entries?.forEach((entry) => {
    //   payment.add({
    //     variantKey: entry.articleRef,
    //     quantity: entry.quantity,
    //     unitSellingPrice: entry.totalPrice,
    //   });
    // });
    // payment.send();
  }

  private static onTrackingEventLogout(_notifyApptus) {
    // Because we are using high-level personalisation we dont end the session by logout
    // https://docs.apptus.com/elevate/3/concepts/sessions-notifications/#high-level-of-personalisation
    // notifyApptus.end();
  }
}
