import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../../core/i18n';
import { GenericLinkModule, MegamenuListItemsModule, PrincipalConfigurationDirectiveModule } from '../../../../shared';
import { NoResultsModule } from '../../../../shared/components/no-results/no-results.module';
import { SubCategoriesNavigationComponent } from './sub-categories-navigation.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    NzIconModule,
    GenericLinkModule,
    PrincipalConfigurationDirectiveModule,
    MegamenuListItemsModule,
    NoResultsModule,
  ],
  declarations: [SubCategoriesNavigationComponent],
  exports: [SubCategoriesNavigationComponent],
})
export class SubCategoriesNavigationModule {}
