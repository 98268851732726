import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  BASE_SITE_CONTEXT_ID,
  CONFIG_INITIALIZER,
  ConfigInitializer,
  BaseSiteInitializer as CxBaseSiteInitializer,
  BaseSiteService as CxBaseSiteService,
  CurrencyInitializer as CxCurrencyInitializer,
  CurrencyService as CxCurrencyService,
  LanguageInitializer as CxLanguageInitializer,
  LanguageService as CxLanguageService,
  LanguageStatePersistenceService as CxLanguageStatePersistenceService,
  SiteContextConfig,
  SiteContextEventModule,
  StateModule,
  provideConfigValidator,
  provideDefaultConfigFactory,
} from '@spartacus/core';
import { SiteContextConfigInitializer } from './config';
import { baseSiteConfigValidator } from './config/base-site-config-validator';
import { defaultSiteContextConfigFactory } from './config/default-site-context-config';
import {
  BaseSiteService as PyBaseSiteService,
  CurrencyService as PyCurrencyService,
  LanguageService as PyLanguageService,
} from './facade';
import { contextInitializerProviders } from './providers/context-initializer-providers';
import { contextServiceMapProvider } from './providers/context-service-map';
import { contextServiceProviders } from './providers/context-service-providers';
import { siteContextParamsProviders } from './providers/site-context-params-providers';
import { BaseSiteInitializer as PyBaseSiteInitializer } from './services/base-site-initializer';
import { CurrencyInitializer as PyCurrencyInitializer } from './services/currency-initializer';
import { LanguageInitializer as PyLanguageInitializer } from './services/language-initializer';
import { LanguageStatePersistenceService as PyLanguageStatePersistenceService } from './services/language-state-persistence.service';
import { SiteContextStoreModule } from './store/site-context-store.module';

export function initSiteContextConfig(
  configInitializer: SiteContextConfigInitializer,
  config: SiteContextConfig
): ConfigInitializer | null {
  /**
   * Load config for `context` from backend only when there is no static config for `context.baseSite`
   */
  if (!config.context || !config.context[BASE_SITE_CONTEXT_ID]) {
    return configInitializer;
  }
  return null;
}

@NgModule({
  imports: [StateModule, SiteContextStoreModule, SiteContextEventModule],
})
export class SiteContextModule {
  static forRoot(): ModuleWithProviders<SiteContextModule> {
    return {
      ngModule: SiteContextModule,
      providers: [
        provideDefaultConfigFactory(defaultSiteContextConfigFactory),
        contextServiceMapProvider,
        ...contextServiceProviders,
        ...siteContextParamsProviders,
        provideConfigValidator(baseSiteConfigValidator),
        {
          provide: CONFIG_INITIALIZER,
          useFactory: initSiteContextConfig,
          deps: [SiteContextConfigInitializer, SiteContextConfig],
          multi: true,
        },
        ...contextInitializerProviders,
        { provide: CxBaseSiteInitializer, useExisting: PyBaseSiteInitializer },
        { provide: CxLanguageInitializer, useExisting: PyLanguageInitializer },
        { provide: CxCurrencyInitializer, useExisting: PyCurrencyInitializer },
        { provide: CxBaseSiteService, useExisting: PyBaseSiteService },
        { provide: CxCurrencyService, useExisting: PyCurrencyService },
        { provide: CxLanguageService, useExisting: PyLanguageService },
        { provide: CxLanguageStatePersistenceService, useExisting: PyLanguageStatePersistenceService },
      ],
    };
  }
}
