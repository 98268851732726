import { getLocaleId } from '@angular/common';
import { Injectable, inject, isDevMode } from '@angular/core';
import { LoggerService } from '@spartacus/core';
import { LanguageService } from '../site-context';

/**
 * Extends string constructor and returns registered locale of active language at runtime
 *
 * https://github.com/angular/angular/issues/15039#issuecomment-456345066
 */
@Injectable()
export class DynamicLocaleId extends String {
  protected logger = inject(LoggerService);
  constructor(private languageService: LanguageService) {
    super('');
  }

  toString() {
    const language = this.getActiveLanguage();
    try {
      getLocaleId(language);
      return language;
    } catch {
      this.reportMissingLocaleData(language);
      return 'en';
    }
  }

  private getActiveLanguage(): string {
    let activeLanguage: string;
    this.languageService
      .getActive()
      .subscribe((language) => (activeLanguage = language))
      .unsubscribe();
    return activeLanguage;
  }

  private reportMissingLocaleData(language: string) {
    if (isDevMode()) {
      this.logger.warn(
        `cxNumber pipe: No locale data registered for '${language}' (see https://angular.io/api/common/registerLocaleData).`
      );
    }
  }
}
