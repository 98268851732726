import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { OrderEntry, PrePrintedLabel } from '../../../../core/model';
import { CartType } from '../../../../core/model/cart.model';
import { CART_CORE_FEATURE } from '../store/cart-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: CartPrePrintedLabelsFacade,
      feature: CART_CORE_FEATURE,
      async: true,
      methods: [
        'getOptions',
        'getLoadingOptions',
        'getInnerPackageQuantityRequired',
        'getLoadingInnerPackageQuantityRequired',
        'updateLabel',
        'getUpdateLabelLoading',
        'getUpdateLabelResult',
        'getUpdateLabelError',
        'reseUpdateLabelProcessingStates',
        'deleteLabel',
        'getDeleteLabelLoading',
        'getDeleteLabelResult',
        'getDeleteLabelError',
        'reseDeleteLabelProcessingStates',
      ],
    }),
})
export abstract class CartPrePrintedLabelsFacade {
  abstract getOptions(cartType: CartType, entry: OrderEntry): Observable<PrePrintedLabel[]>;
  abstract getLoadingOptions(cartType: CartType, entry: OrderEntry): Observable<boolean>;
  abstract getInnerPackageQuantityRequired(cartType: CartType, entry: OrderEntry): Observable<boolean>;
  abstract getLoadingInnerPackageQuantityRequired(cartType: CartType, entry: OrderEntry): Observable<boolean>;
  abstract updateLabel(cartType: CartType, entry: OrderEntry, option: PrePrintedLabel): void;
  abstract getUpdateLabelLoading(): Observable<boolean>;
  abstract getUpdateLabelResult(): Observable<any>;
  abstract getUpdateLabelError(): Observable<any>;
  abstract reseUpdateLabelProcessingStates(): void;
  abstract deleteLabel(cartType: CartType, entry: OrderEntry): void;
  abstract getDeleteLabelLoading(): Observable<boolean>;
  abstract getDeleteLabelResult(): Observable<any>;
  abstract getDeleteLabelError(): Observable<any>;
  abstract reseDeleteLabelProcessingStates(): void;
}
