import { StateUtils } from '@spartacus/core';
import { SearchPageResultState } from '../../../features/store/base-feature-state';
import { UserActions } from '../actions';

export const initialState: SearchPageResultState = {
  lastUpdateTime: undefined,
  results: [],
};

export function reducer(
  state = initialState,
  action:
    | UserActions.LoadAvailableOrderApprovalsBuyers
    | UserActions.LoadAvailableOrderApprovalsBuyersFail
    | UserActions.LoadAvailableOrderApprovalsBuyersSuccess
    | StateUtils.LoaderAction
): any {
  switch (action.type) {
    case UserActions.OrderApprovalActionTypes.LOAD_AVAILABLE_ORDER_APPROVALS_BUYERS_SUCCESS: {
      const payload = action.payload;
      const results = payload.results.map((r) => r.uid);
      return {
        ...state,
        lastUpdateTime: new Date().toISOString(),
        results,
      };
    }
    default: {
      return state;
    }
  }
}
