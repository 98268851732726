import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { SMART_EDIT_FEATURE, SmartEditConfig, SmartEditRootModule } from '@spartacus/smartedit/root';

@NgModule({
  declarations: [],
  imports: [SmartEditRootModule],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        [SMART_EDIT_FEATURE]: {
          module: () => import('@spartacus/smartedit').then((m) => m.SmartEditModule),
        },
      },
    }),
    provideConfig(<SmartEditConfig>{
      smartEdit: {
        storefrontPreviewRoute: 'cx-preview',
        allowOrigin:
          'optigroup.local:443,*.optigroup.com:443,*.superoffice.com:443,*.google.com:443,*.apptus.com:443,*.youtube.com:443',
      },
    }),
  ],
})
export class SmartEditFeatureModule {}
