import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SpinnerType } from '../../../core/model';

export enum BadgeType {
  Info = 'info',
  Warning = 'warning',
  Danger = 'danger',
  Success = 'success',
  Processing = 'processing',
  Notification = 'notification',
  Campaign = 'campaign',
  Replacement = 'replacement',
  Recommended = 'recommended',
  Tendered = 'tendered',
  Discontinued = 'discontinued',
  Outlet = 'outlet',
  Default = '',
}

//Badge position Y_X
export enum BadgePosition {
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  CENTER_LEFT = 'center-left',
}

@Component({
  selector: 'py-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  readonly spinnerType = SpinnerType;

  @Input() showCircle: Boolean = false;
  @Input() type: BadgeType;
  @Input() large: boolean = false;
  @Input() xlarge: boolean = false;
  @Input() tag: boolean = false;
  @Input() loading: boolean = false;
  @Input() selected: boolean = false;
  @Input() disabled: boolean = false;
  @Input() selectable: boolean = false;
  @Input() isNotification: boolean = false;
  @Input() static: boolean = false;
  @Input() position: string = BadgePosition.TOP_LEFT;
}
