import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'py-no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss'],
})
export class NoResultsComponent implements OnInit {
  @Input() title = 'common.noResultsFound_hint';
  @Input() description?: string;
  @Input() iconType: string = 'no-results';

  @HostBinding('class.no-results-secondary-variant')
  @Input()
  noResultsSecondaryVariant: boolean = false;

  constructor() {}

  ngOnInit() {}
}
