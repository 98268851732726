import { Injectable } from '@angular/core';
import { Config, OccConfig } from '@spartacus/core';

@Injectable({
  providedIn: 'root',
  useExisting: Config,
})
export abstract class PriceFileConfig extends OccConfig {
  priceFile?: {
    /**
     * Specify the time in seconds before the pricefile status is checked the first time.
     */
    priceFileStatusInitialDelay: number;
    /**
     * Specify the time in seconds how often pricefile status is checked.
     */
    priceFileStatusInterval: number;
  };
}
