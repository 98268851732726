import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { AsmAuthStorageService, CsAgentAuthService } from '@spartacus/asm/root';
import { AuthService, EventService, RoutingService, WindowRef, createFrom } from '@spartacus/core';
import { Observable, combineLatest, forkJoin, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { LoginLogoutActionTypes } from '../../../core/auth';
import {
  ArticleActionTypes,
  ArticleActions,
  ArticleService,
  CatalogRouterStateService,
  ProductService,
} from '../../../core/catalog';
import { OrderTypeToCartTypeMapper } from '../../../core/misc';
import { Article, CartType, OrderEntry, Product, ProductRelationTargetType, SubstituteRefType } from '../../../core/model';
import { SolrSearchActionTypes, SolrSearchActions } from '../../../core/search';
import {
  SelectEcommerceItemEvent,
  UserSelectContentEvent,
  ViewCatalogArticlesEvent,
  ViewLastOrderedArticlesEvent,
  ViewMostOrderedArticlesEvent,
} from '../../../core/user';
import {
  RecommendationActionTypes,
  RegisterActionTypes,
  ShoppingListEntryActionTypes,
  UserActions,
} from '../../../core/user/store/actions';
import { shallowEqualObjects } from '../../../core/util';
import { EnterPDPEvent, GenerateLeadEvent, PaymentSuccessfulEvent, ViewRelatedItemsEvent } from '../../../events/misc';
import { ActiveCartFacade, UpdateCartEntrySuccessEvent } from '../../cart/base';
import { CartEntryActionTypes } from '../../cart/base/store/actions';
import {
  CheckoutActionTypes,
  CheckoutActions,
  CheckoutPaymentActionTypes,
  CheckoutShippingActionTypes,
} from '../../checkout/base/store/actions';
import {
  ItemListType,
  LoginMethod,
  TrackingEventAddEntriesToCart,
  TrackingEventAddPaymentInfo,
  TrackingEventAddShippingInfo,
  TrackingEventAddToWishlist,
  TrackingEventBeginCheckout,
  TrackingEventGenerateLead,
  TrackingEventLogin,
  TrackingEventLogout,
  TrackingEventPayment,
  TrackingEventPurchase,
  TrackingEventRemoveEntriesFromCart,
  TrackingEventRemoveFromWishlist,
  TrackingEventSearch,
  TrackingEventSelectContent,
  TrackingEventSelectItem,
  TrackingEventTutorialBegin,
  TrackingEventTutorialComplete,
  TrackingEventViewCart,
  TrackingEventViewItem,
  TrackingEventViewItemList,
} from './tracking-events';

@Injectable({
  providedIn: 'root',
})
export class TrackingEventsBuilder {
  contextChange$ = this.authService.logoutInProgress$.pipe(filter((logout) => !!logout));

  constructor(
    protected eventService: EventService,
    protected authService: AuthService,
    private activeCartService: ActiveCartFacade,
    private actionsSubject: ActionsSubject,
    private orderTypeToCartTypeMapper: OrderTypeToCartTypeMapper,
    private productService: ProductService,
    private articleService: ArticleService,
    private catalogRouterStateService: CatalogRouterStateService,
    private routingService: RoutingService,
    private csAgentAuthService: CsAgentAuthService,
    private asmAuthStorageService: AsmAuthStorageService,
    private router: Router,
    private windowRef: WindowRef
  ) {
    this.register();
  }

  protected register(): void {
    this.registerLogin();
    this.registerLogout();
    this.registerSearch();
    this.registerBeginCheckout();
    this.registerPurchase();
    this.registerLead();
    this.registerViewItem();
    this.registerViewList();
    this.registerSelectContent();
    this.registerSelectItem();
    this.registerTutorialBegin();
    this.registerTutorialComplete();
    this.registerCart();
    this.registerAddPaymentInfo();
    this.registerAddShippingInfo();
    this.registerSuccessfulPayment();
    this.registerAddToShoppingList();
    this.registerRemoveFromShoppingList();
  }

  /*
   * Adding articles data to a event, we only add data that is already loaded from BE.
   */
  private withArticles(data: any = {}, articles: Article[]): Observable<any[]> {
    return this.authService.isUserLoggedIn().pipe(
      distinctUntilChanged(),
      debounceTime(1000),
      switchMap((isUserLoggedIn) => {
        if (!articles?.length) {
          return of([data, undefined]);
        } else if (!isUserLoggedIn) {
          return of([data, articles]);
        } else {
          return this.articleService.getArticles(articles.map(({ code }) => code)).pipe(
            switchMap((articlesData: Array<Observable<Article>>) => {
              return forkJoin(articlesData.map((article$) => article$.pipe(take(1))));
            }),
            map((articlesData: Article[]) =>
              articles.map((article) => articlesData.find((articleData) => articleData?.code === article.code) || article)
            ),
            map((articles) => [data, articles])
          );
        }
      }),
      takeUntil(this.contextChange$),
      take(1)
    );
  }

  /*
   * Adding products data to a event, we only add data that is already loaded from BE.
   */
  private withProducts(data: any = {}, products: Product[]): Observable<any[]> {
    return this.authService.isUserLoggedIn().pipe(
      distinctUntilChanged(),
      debounceTime(1000),
      switchMap((isUserLoggedIn) => {
        if (!products?.length) {
          return of([data, undefined]);
        } else if (!isUserLoggedIn) {
          return of([data, products]);
        } else {
          return this.productService.getProducts(products.map(({ code }) => code)).pipe(
            switchMap((productsData: Array<Observable<Product>>) => {
              return forkJoin(productsData.map((product$) => product$.pipe(take(1))));
            }),
            map((productsData: Product[]) =>
              products.map((product) => productsData.find((productData) => productData?.code === product.code) || product)
            ),
            map((products) => [data, products])
          );
        }
      }),
      takeUntil(this.contextChange$),
      take(1)
    );
  }

  private withCart(data: any = {}, cartType: any = CartType.stock): Observable<any[]> {
    return this.authService.isUserLoggedIn().pipe(
      distinctUntilChanged(),
      switchMap((isUserLoggedIn) => {
        if (!isUserLoggedIn) {
          return of([data, undefined]);
        } else {
          return this.activeCartService.getActiveCart(cartType).pipe(
            filter((cart) => !!cart),
            take(1),
            map((cart) => [data, cart])
          );
        }
      }),
      takeUntil(this.contextChange$),
      take(1)
    );
  }

  private withLoginMethod(): Observable<LoginMethod> {
    return combineLatest([
      this.csAgentAuthService.isCustomerEmulated(),
      this.csAgentAuthService.isCustomerSupportAgentLoggedIn(),
      this.actionsSubject.pipe(
        ofType(LoginLogoutActionTypes.Login),
        map(() => Boolean(this.asmAuthStorageService.getEmulatedUserToken()))
      ),
      // hasAccessTokenInUrl - check if we have an access_token in the URL fragment
      // windowRef.location.hash might look something like this during OCI login:
      //   #access_token=abcdefgh-ABCDEFGH0123456789&token_type=bearer&expires_in=14399&scope=basic%20openid
      this.router.events.pipe(
        map(() => this.windowRef.location?.hash?.slice(1)),
        map(
          (hash) =>
            hash
              ?.split('&')
              ?.map((part) => part?.split('=')?.[0])
              ?.includes('access_token') ?? false
        ),
        distinctUntilChanged()
      ),
    ]).pipe(
      map(([isCustomerEmulated, isCustomerSupportAgentLoggedIn, csAgentHasEmulatedUserToken, hasAccessTokenInUrl]) =>
        isCustomerEmulated && !csAgentHasEmulatedUserToken
          ? LoginMethod.WITH_CUSTOMER_EMULATION_SESSION
          : isCustomerSupportAgentLoggedIn
          ? LoginMethod.WITH_CUSTOMER_SUPPORT_AGENT
          : hasAccessTokenInUrl
          ? LoginMethod.WITH_AUTH_PARAMS_IN_URL
          : LoginMethod.WITH_CREDENTIALS
      ),
      distinctUntilChanged()
    );
  }

  protected registerLogin(): void {
    const trackingEventLogin$ = combineLatest([
      this.actionsSubject.pipe(ofType(LoginLogoutActionTypes.Login)),
      this.withLoginMethod(),
    ]).pipe(
      distinctUntilKeyChanged(0),
      switchMap(([_, method]) => this.withCart(method)),
      map(([method, cart]) => createFrom(TrackingEventLogin, { method, cart }))
    );
    this.eventService.register(TrackingEventLogin, trackingEventLogin$);
  }

  protected registerLogout(): void {
    const trackingEventLogout$ = this.actionsSubject.pipe(
      ofType(LoginLogoutActionTypes.Logout),
      map(() => createFrom(TrackingEventLogout, { cart: undefined }))
    );
    this.eventService.register(TrackingEventLogout, trackingEventLogout$);
  }

  private registerSearch(): void {
    const trackingEventSearch$ = this.actionsSubject.pipe(
      ofType(SolrSearchActionTypes.LoadSolrSearchSuccess),
      map((action: SolrSearchActions.LoadSolrSearchSuccess) => action.payload),
      filter(({ freeTextSearch }) => !!freeTextSearch?.length),
      switchMap((payload) => this.withCart(payload)),
      switchMap(([payload, cart]) =>
        this.withArticles(
          [payload, cart],
          payload.articleRefs?.map((ref) => ({ code: ref }))
        )
      ),
      switchMap(([[payload, cart], articles]) =>
        this.withProducts(
          [payload, cart, articles],
          payload.productRefs?.map((ref) => ({ code: ref }))
        )
      ),
      map(([[payload, cart, articles], products]) =>
        createFrom(TrackingEventSearch, {
          cart,
          freeTextSearch: payload.freeTextSearch,
          articles: articles?.filter((a: Article) => a.productName)?.length ? articles : undefined,
          products: products?.filter((p: Product) => p.name)?.length ? products : undefined,
        })
      )
    );
    this.eventService.register(TrackingEventSearch, trackingEventSearch$);
  }

  private registerBeginCheckout(): void {
    const cartEventStream$ = this.actionsSubject.pipe(
      ofType(CheckoutActionTypes.SetCartId),
      map((action: CheckoutActions.SetCartId) => action.payload),
      switchMap((payload) => this.withCart(payload, payload.key)),
      map(([_, cart]) => createFrom(TrackingEventBeginCheckout, { cart, code: cart?.code }))
    );

    this.eventService.register(TrackingEventBeginCheckout, cartEventStream$);
  }

  private registerPurchase() {
    const eventStream$ = this.actionsSubject.pipe(
      ofType(CheckoutActionTypes.PlaceOrderSuccess),
      map((action: CheckoutActions.PlaceOrderSuccess) => action.payload),
      switchMap(({ order }) => this.withCart(order, this.orderTypeToCartTypeMapper.getCartTypeFromOrderType(order.orderType))),
      switchMap(([order, cart]) =>
        this.withArticles(
          [order, cart],
          cart.entries.map((e) => ({ code: e.articleRef }))
        )
      ),
      map(([[order, cart], articles]) => createFrom(TrackingEventPurchase, { order, cart, articles }))
    );
    this.eventService.register(TrackingEventPurchase, eventStream$);
  }

  private registerLead(): void {
    const eventStream$ = this.eventService.get(GenerateLeadEvent).pipe(
      switchMap(({ formData }) => this.withCart(formData)),
      map(([formData, cart]) => createFrom(TrackingEventGenerateLead, { cart, formData }))
    );
    this.eventService.register(TrackingEventGenerateLead, eventStream$);
  }

  private registerViewItem(): void {
    const registerViewItem$ = this.eventService.get(EnterPDPEvent).pipe(
      switchMap(({ product }) => this.withCart(product)),
      map(([product, cart]) => createFrom(TrackingEventViewItem, { product, cart, id: ItemListType.CATALOG }))
    );

    this.eventService.register(TrackingEventViewItem, registerViewItem$);
  }

  private registerViewList(): void {
    const cartTrackingEventCatalog$ = this.actionsSubject.pipe(
      ofType(SolrSearchActionTypes.LoadSolrSearchSuccess),
      map((action: SolrSearchActions.LoadSolrSearchSuccess) => action.payload),
      filter(({ freeTextSearch }) => !freeTextSearch?.length),
      switchMap((payload) => this.withCart(payload)),
      switchMap(([payload, cart]) =>
        this.withArticles(
          [payload, cart],
          payload.articleRefs?.map((ref) => ({ code: ref }))
        )
      ),
      switchMap(([[payload, cart], articles]) =>
        this.withProducts(
          [payload, cart, articles],
          payload.productRefs?.map((ref) => ({ code: ref }))
        )
      ),
      map(([[_, cart, articles], products]) =>
        createFrom(TrackingEventViewItemList, {
          cart,
          articles,
          products,
          id: ItemListType.CATALOG,
        })
      )
    );

    const cartTrackingEventViewSimilarArticles$ = this.actionsSubject.pipe(
      ofType(ArticleActionTypes.LoadSimilarArticlesSuccess),
      map((action: ArticleActions.LoadSimilarArticlesSuccess) => action.payload),
      map(({ alternatives }) => alternatives?.map((alternative) => ({ code: alternative?.articleRef }))),
      switchMap((articles: Article[]) => this.withCart(articles)),
      switchMap(([articles, cart]) => this.withArticles(cart, articles)),
      map(([cart, articles]) => createFrom(TrackingEventViewItemList, { articles, cart, id: ItemListType.SIMILAR_ARTICLES }))
    );

    const cartTrackingEventViewRecommendedArticles$ = this.actionsSubject.pipe(
      ofType(RecommendationActionTypes.LoadRecommendationSuccess),
      map((action: UserActions.LoadRecommendationSuccess) => {
        const items = [];
        action.payload?.panels?.forEach((panel) =>
          panel.items.forEach((item) => {
            if (item.type === 'article') {
              return items.push({ code: item.ref, ticket: item.ticket });
            }
          })
        );
        return items;
      }),
      filter((items) => !!items.length),
      switchMap((articles: Article[]) => this.withCart(articles)),
      switchMap(([articles, cart]) => this.withArticles(cart, articles)),
      map(([cart, articles]) => createFrom(TrackingEventViewItemList, { articles, cart, id: ItemListType.RECOMMENDED_ARTICLES }))
    );

    const cartTrackingEventViewRecommendedProducts$ = this.actionsSubject.pipe(
      ofType(RecommendationActionTypes.LoadRecommendationSuccess),
      map((action: UserActions.LoadRecommendationSuccess) => {
        const items = [];
        action.payload?.panels?.forEach((panel) =>
          panel.items.forEach((item) => {
            if (item.type === 'product') {
              return items.push({ code: item.ref, ticket: item.ticket });
            }
          })
        );
        return items;
      }),
      filter((items) => !!items.length),
      switchMap((products: Product[]) => this.withCart(products)),
      switchMap(([products, cart]) => this.withProducts(cart, products)),
      map(([cart, products]) => createFrom(TrackingEventViewItemList, { products, cart, id: ItemListType.RECOMMENDED_PRODUCTS }))
    );

    const cartTrackingEventViewSubstituteArticles$ = this.actionsSubject.pipe(
      ofType(ArticleActionTypes.LoadSubstitutesArticlesSuccess),
      map((action: ArticleActions.LoadSubstitutesArticlesSuccess) => action.payload),
      map(({ refs }) => refs?.filter((ref) => ref.refType === SubstituteRefType.Article)),
      filter((refs) => !!refs.length),
      map((refs) => refs?.map((alternative) => ({ code: alternative.code }))),
      switchMap((articles: Article[]) => this.withCart(articles)),
      switchMap(([articles, cart]) => this.withArticles(cart, articles)),
      map(([cart, articles]) => createFrom(TrackingEventViewItemList, { articles, cart, id: ItemListType.SUBSTITUTES_ARTICLES }))
    );

    const cartTrackingEventViewRelatedItems$ = this.eventService.get(ViewRelatedItemsEvent).pipe(
      switchMap(({ relatedItems }) => this.withCart(relatedItems)),
      map(([relatedItems, cart]) => [
        relatedItems.map((relatedItem) => ({ code: relatedItem.target, type: relatedItem.targetType })),
        cart,
      ]),
      filter(([items]) => !!items?.length),
      distinctUntilChanged(shallowEqualObjects),
      switchMap(([items, cart]) =>
        this.withArticles(
          [cart, items],
          items.filter((item) => item.type === ProductRelationTargetType.ARTICLE)
        )
      ),
      switchMap(([[cart, items], articles]) =>
        this.withProducts(
          [cart, articles],
          items.filter((item) => item.type === ProductRelationTargetType.PRODUCT)
        )
      ),
      map(([[cart, articles], products]) =>
        createFrom(TrackingEventViewItemList, {
          cart,
          id: ItemListType.RELATED_ARTICLES_AND_PRODUCTS,
          products,
          articles,
        })
      )
    );

    const cartTrackingEventViewLastOrderedItems$ = this.eventService.get(ViewLastOrderedArticlesEvent).pipe(
      distinctUntilChanged(shallowEqualObjects),
      filter(({ articleRefs }) => !!articleRefs?.length),
      switchMap(({ articleRefs }) => this.withCart(articleRefs)),
      switchMap(([articleRefs, cart]) =>
        this.withArticles(
          cart,
          articleRefs?.map((res) => ({ code: res }))
        )
      ),
      filter(([, articles]) => !!articles?.length),
      map(([cart, articles]) =>
        createFrom(TrackingEventViewItemList, {
          cart,
          id: ItemListType.LAST_ORDERED_ARTICLES,
          articles,
        })
      )
    );

    const cartTrackingEventViewMostOrderedItems$ = this.eventService.get(ViewMostOrderedArticlesEvent).pipe(
      distinctUntilChanged(shallowEqualObjects),
      filter(({ articleRefs }) => !!articleRefs?.length),
      switchMap(({ articleRefs }) => this.withCart(articleRefs)),
      switchMap(([articleRefs, cart]) =>
        this.withArticles(
          cart,
          articleRefs?.map((res) => ({ code: res }))
        )
      ),
      filter(([, articles]) => !!articles?.length),
      map(([cart, articles]) =>
        createFrom(TrackingEventViewItemList, {
          cart,
          id: ItemListType.MOST_ORDERED_ARTICLES,
          articles,
        })
      )
    );

    const cartTrackingEventViewArticles$ = this.eventService.get(ViewCatalogArticlesEvent).pipe(
      debounceTime(3000),
      distinctUntilChanged(shallowEqualObjects),
      filter(({ articleRefs }) => !!articleRefs?.length),
      switchMap(({ articleRefs }) => this.withCart(articleRefs)),
      switchMap(([articleRefs, cart]) =>
        this.withArticles(
          cart,
          articleRefs?.map((res) => ({ code: res }))
        )
      ),
      filter(([, articles]) => !!articles?.length),
      map(([cart, articles]) =>
        createFrom(TrackingEventViewItemList, {
          cart,
          id: ItemListType.PDP_ARTICLES_LIST,
          articles,
        })
      )
    );

    this.eventService.register(TrackingEventViewItemList, cartTrackingEventViewLastOrderedItems$);
    this.eventService.register(TrackingEventViewItemList, cartTrackingEventViewMostOrderedItems$);
    this.eventService.register(TrackingEventViewItemList, cartTrackingEventViewRelatedItems$);
    this.eventService.register(TrackingEventViewItemList, cartTrackingEventCatalog$);
    this.eventService.register(TrackingEventViewItemList, cartTrackingEventViewRecommendedArticles$);
    this.eventService.register(TrackingEventViewItemList, cartTrackingEventViewRecommendedProducts$);
    this.eventService.register(TrackingEventViewItemList, cartTrackingEventViewSimilarArticles$);
    this.eventService.register(TrackingEventViewItemList, cartTrackingEventViewSubstituteArticles$);
    this.eventService.register(TrackingEventViewItemList, cartTrackingEventViewArticles$);
  }

  private registerSelectContent() {
    const eventSelectContent$ = this.eventService.get(UserSelectContentEvent).pipe(
      switchMap((payload) => this.withCart(payload)),
      map(([payload, cart]) => createFrom(TrackingEventSelectContent, { ...payload, cart }))
    );

    this.eventService.register(TrackingEventSelectContent, eventSelectContent$);
  }

  private registerSelectItem(): void {
    const registerSelectItem$ = this.eventService.get(SelectEcommerceItemEvent).pipe(
      switchMap((payload) => this.withCart(payload)),
      map(([payload, cart]) => createFrom(TrackingEventSelectItem, { cart, ...payload }))
    );

    this.eventService.register(TrackingEventSelectItem, registerSelectItem$);
  }

  private registerTutorialBegin(): void {
    const trackingEventTutorialBegin$ = this.actionsSubject.pipe(
      ofType(RegisterActionTypes.ResetRegisterUser),
      switchMap(() => this.withCart()),
      map(([_, cart]) => createFrom(TrackingEventTutorialBegin, { cart }))
    );
    this.eventService.register(TrackingEventTutorialBegin, trackingEventTutorialBegin$);
  }

  private registerTutorialComplete(): void {
    const trackingEventTutorialComplete$ = this.actionsSubject.pipe(
      ofType(RegisterActionTypes.RegisterUser),
      switchMap(() => this.withCart()),
      map(([_, cart]) => createFrom(TrackingEventTutorialComplete, { cart }))
    );
    this.eventService.register(TrackingEventTutorialComplete, trackingEventTutorialComplete$);
  }

  private registerCart(): void {
    const trackingEventViewCart$ = this.actionsSubject.pipe(
      ofType(CartEntryActionTypes.ViewCartEntries),
      switchMap(({ payload }) => this.withCart(payload)),
      switchMap(([{ entries }, cart]) =>
        this.withArticles(
          cart,
          entries.map((entry) => ({ code: entry.articleRef }))
        )
      ),
      map(([cart, articles]) => createFrom(TrackingEventViewCart, { cart, articles }))
    );
    this.eventService.register(TrackingEventViewCart, trackingEventViewCart$);

    const trackingEventRemoveEntriesFromCart$ = this.actionsSubject.pipe(
      ofType(CartEntryActionTypes.RemoveCartEntriesSuccess),
      switchMap(({ payload }) => this.withCart(payload)),
      switchMap(([{ entries }, cart]) =>
        this.withArticles(
          cart,
          entries.map((entry) => ({ code: entry.articleRef }))
        )
      ),
      map(([cart, articles]) => createFrom(TrackingEventRemoveEntriesFromCart, { cart, articles }))
    );
    this.eventService.register(TrackingEventRemoveEntriesFromCart, trackingEventRemoveEntriesFromCart$);

    const trackingEventAddEntryToCart$ = this.actionsSubject.pipe(
      ofType(CartEntryActionTypes.AddCartEntrySuccess, CartEntryActionTypes.QuickAddCartEntrySuccess),
      switchMap(({ payload }) => this.withCart(payload)),
      switchMap(([{ entry }, cart]) => this.withArticles(cart, [{ code: entry.articleRef }])),
      switchMap(([cart, articles]) => {
        return this.routingService.getRouterState().pipe(
          take(1),
          map(({ state }) => [state, cart, articles])
        );
      }),
      map(([routerState, cart, articles]) => {
        const ticket = routerState?.queryParams?.ticket;
        const product = this.catalogRouterStateService.getProductCode(routerState.params, routerState.queryParams);

        if (product && ticket) {
          articles = [{ ...articles[0], ticket }];
        }
        return createFrom(TrackingEventAddEntriesToCart, { cart, articles });
      })
    );
    this.eventService.register(TrackingEventAddEntriesToCart, trackingEventAddEntryToCart$);

    const trackingEventAddCartEntriesSuccess$ = this.actionsSubject.pipe(
      ofType(CartEntryActionTypes.AddCartEntriesSuccess),
      switchMap(({ payload }) => this.withCart(payload)),
      switchMap(([{ entries }, cart]) =>
        this.withArticles(
          cart,
          entries.map((entry) => ({ code: entry.articleRef }))
        )
      ),
      map(([cart, articles]) => createFrom(TrackingEventAddEntriesToCart, { cart, articles }))
    );
    this.eventService.register(TrackingEventAddEntriesToCart, trackingEventAddCartEntriesSuccess$);

    const eventRemoveCartEntriesSuccess$ = this.actionsSubject.pipe(
      ofType(CartEntryActionTypes.RemoveCartEntrySuccess),
      switchMap(({ payload }) => this.withCart(payload)),
      switchMap(([{ entry }, cart]) =>
        this.withArticles(
          cart,
          [entry].map((entry) => ({ code: entry.articleRef }))
        )
      ),
      map(([cart, articles]) => createFrom(TrackingEventRemoveEntriesFromCart, { cart, articles }))
    );
    this.eventService.register(TrackingEventRemoveEntriesFromCart, eventRemoveCartEntriesSuccess$);

    const eventRemoveAllCartEntriesSuccess$ = this.actionsSubject.pipe(
      ofType(CartEntryActionTypes.RemoveAllCartEntriesSuccess),
      switchMap(({ payload }) => this.withCart(payload)),
      switchMap(([{ entries }, cart]) =>
        this.withArticles(
          cart,
          entries.map((entry) => ({ code: entry.articleRef }))
        )
      ),
      map(([cart, articles]) => createFrom(TrackingEventRemoveEntriesFromCart, { cart, articles }))
    );
    this.eventService.register(TrackingEventRemoveEntriesFromCart, eventRemoveAllCartEntriesSuccess$);

    const trackingEventUpdateEntryAdd$ = this.eventService.get(UpdateCartEntrySuccessEvent).pipe(
      filter((event) => this.getUpdateEntryQuantity(event) > 0),
      map((event) => this.withUpdatedEntriesWasIncreased(event)),
      switchMap((entries) => this.withCart(entries)),
      switchMap(([entries, cart]) =>
        this.withArticles(
          cart,
          entries.map((entry) => ({ code: entry.articleRef }))
        )
      ),
      map(([cart, articles]) => createFrom(TrackingEventAddEntriesToCart, { articles, cart }))
    );
    this.eventService.register(TrackingEventAddEntriesToCart, trackingEventUpdateEntryAdd$);

    const trackingEventUpdateEntryRemove$ = this.eventService.get(UpdateCartEntrySuccessEvent).pipe(
      filter((event) => this.getUpdateEntryQuantity(event) < 0),
      map((event) => this.withUpdatedEntriesWasDecreased(event)),
      switchMap((entries) => this.withCart(entries)),
      switchMap(([entries, cart]) =>
        this.withArticles(
          cart,
          entries.map((entry) => ({ code: entry.articleRef }))
        )
      ),
      map(([cart, articles]) => createFrom(TrackingEventRemoveEntriesFromCart, { articles, cart }))
    );
    this.eventService.register(TrackingEventRemoveEntriesFromCart, trackingEventUpdateEntryRemove$);
  }

  private registerAddPaymentInfo(): void {
    const eventStream$ = this.actionsSubject.pipe(
      ofType(CheckoutPaymentActionTypes.SetPaymentOptionSuccess),
      map((action: CheckoutActions.SetPaymentOptionSuccess) => action.payload),
      switchMap((payload) => this.withCart(payload)),
      map(([payload, cart]) => createFrom(TrackingEventAddPaymentInfo, { cart, paymentOption: payload.paymentOption }))
    );
    this.eventService.register(TrackingEventAddPaymentInfo, eventStream$);
  }

  private registerAddShippingInfo(): void {
    const eventStream$ = this.actionsSubject.pipe(
      ofType(CheckoutShippingActionTypes.SetShippingOptionSuccess),
      map((action: CheckoutActions.SetShippingOptionSuccess) => action.payload),
      switchMap(({ shippingOption }) => this.withCart(shippingOption)),
      map(([shippingOption, cart]) => createFrom(TrackingEventAddShippingInfo, { shippingOption, cart }))
    );
    this.eventService.register(TrackingEventAddShippingInfo, eventStream$);
  }

  private registerSuccessfulPayment(): void {
    const eventStream$ = this.eventService.get(PaymentSuccessfulEvent).pipe(
      switchMap(({ sapOrderNumber }) => this.withCart(sapOrderNumber)),
      map(([sapOrderNumber, cart]) => createFrom(TrackingEventPayment, { sapOrderNumber, cart }))
    );
    this.eventService.register(TrackingEventPayment, eventStream$);
  }

  private registerAddToShoppingList(): void {
    const trackingEventAddToWishlist$ = this.actionsSubject.pipe(
      ofType(ShoppingListEntryActionTypes.AddShoppingListEntrySuccess),
      map((action: UserActions.AddShoppingListEntrySuccess) => action.payload),
      switchMap(({ entry }) => this.withCart(entry)),
      switchMap(([entry, cart]) => this.withArticles(cart, [{ code: entry.articleRef }])),
      map(([cart, articles]) => createFrom(TrackingEventAddToWishlist, { articles, cart }))
    );
    this.eventService.register(TrackingEventAddToWishlist, trackingEventAddToWishlist$);
  }

  private registerRemoveFromShoppingList(): void {
    const trackingEventRemoveFromWishlist$ = this.actionsSubject.pipe(
      ofType(ShoppingListEntryActionTypes.RemoveShoppingListEntrySuccess),
      map((action: UserActions.RemoveShoppingListEntrySuccess) => action.payload),
      switchMap(({ code }) => this.withCart(code)),
      switchMap(([code, cart]) => this.withArticles(cart, [{ code }])),
      map(([cart, articles]) => createFrom(TrackingEventRemoveFromWishlist, { articles, cart }))
    );
    this.eventService.register(TrackingEventRemoveFromWishlist, trackingEventRemoveFromWishlist$);
  }

  private withUpdatedEntriesWasIncreased(event: UpdateCartEntrySuccessEvent): OrderEntry[] {
    const quantity = this.getUpdateEntryQuantity(event);
    const newEntry = event.cartModification?.entry;

    return [{ ...newEntry, quantity }];
  }

  private withUpdatedEntriesWasDecreased(event: UpdateCartEntrySuccessEvent): OrderEntry[] {
    const quantity = this.getUpdateEntryQuantity(event) || 0;
    const newEntry = event.cartModification?.entry;

    return [{ ...newEntry, quantity: -quantity }];
  }

  private getUpdateEntryQuantity(event: UpdateCartEntrySuccessEvent): number {
    const oldEntry = event.entry;
    const newEntry = event.cartModification?.entry;

    if (!oldEntry || !newEntry) {
      return undefined;
    }

    return newEntry.quantity - oldEntry.quantity;
  }
}
