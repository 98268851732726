import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../core/i18n';
import { ScreenSizeModule } from '../../directives/screen-size/screen-size.module';
import { PipesModule } from '../../pipes/pipes.module';
import { SearchFieldComponent } from './search-field.component';

@NgModule({
  imports: [CommonModule, I18nModule, ReactiveFormsModule, NzIconModule, ScreenSizeModule, PipesModule],
  declarations: [SearchFieldComponent],
  exports: [SearchFieldComponent],
})
export class SearchFieldModule {}
