import { InjectionToken } from '@angular/core';
import { SearchTermFacetMappingHint, SolrResultEntityRef, SolrSearchResult } from '../../model/solr-search-config';
import { Occ } from '../../occ';
import { Converter } from '../../util';

export const SOLR_SEARCH_NORMALIZER = new InjectionToken<Converter<any, SolrSearchResult>>('SolrSearchNormalizer');
export const SOLR_SEARCH_RESULT_REF_NORMALIZER = new InjectionToken<Converter<Occ.SolrResultEntityRef, SolrResultEntityRef>>(
  'SolrSearchResultRefNormalizer'
);

export const SEARCH_HINT_NORMALIZER = new InjectionToken<Converter<Occ.SearchTermFacetMappingHint, SearchTermFacetMappingHint>>(
  'SearchHintNormalizer'
);
