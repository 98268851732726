import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import {
  CartType,
  Cutting,
  CuttingOptions,
  CuttingRequest,
  CuttingTemplate,
  CuttingTemplateRequest,
  OrderEntry,
} from '../../../../core/model';
import { CART_CORE_FEATURE } from '../store/cart-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: CuttingFacade,
      feature: CART_CORE_FEATURE,
      async: true,
      methods: [
        'getOptions',
        'calculateTemplate',
        'calculateTemplateLoading',
        'calculateTemplateLoaded',
        'calculatePrice',
        'calculatePriceLoading',
        'calculatePriceLoaded',
      ],
    }),
})
export abstract class CuttingFacade {
  abstract getOptions(cartType: CartType): Observable<CuttingOptions>;
  abstract calculateTemplate(
    entry: OrderEntry,
    request: CuttingTemplateRequest,
    cartType: CartType
  ): Observable<CuttingTemplate | undefined>;
  abstract calculateTemplateLoading(entry: OrderEntry, request: CuttingTemplateRequest, cartType: CartType): Observable<boolean>;
  abstract calculateTemplateLoaded(entry: OrderEntry, request: CuttingTemplateRequest, cartType: CartType): Observable<boolean>;
  abstract calculatePrice(entry: OrderEntry, request: CuttingRequest, cartType: CartType): Observable<Cutting | undefined>;
  abstract calculatePriceLoading(entry: OrderEntry, request: CuttingRequest, cartType: CartType): Observable<boolean>;
  abstract calculatePriceLoaded(entry: OrderEntry, request: CuttingRequest, cartType: CartType): Observable<boolean>;
}
