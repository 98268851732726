import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MediaDirective } from './media.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [MediaDirective],
  exports: [MediaDirective],
})
export class MediaModule {
  static forRoot(): ModuleWithProviders<MediaModule> {
    return {
      ngModule: MediaModule,
    };
  }
}
