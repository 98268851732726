import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../../core/i18n';
import { PrincipalConfigurationDirectiveModule } from '../../../directives';
import { BadgeModule } from '../../badge';
import { GenericLinkModule } from '../../generic-link';
import { ImageModalModule } from '../../image-modal/image-modal.module';
import { MediaModule } from '../../media';
import { NonReturnableModule } from '../../non-returnable/non-returnable.module';
import { NotificationModule } from '../../notification';
import { ArticleInfoAttributesModule } from '../article-info-attributes/article-info-attributes.module';
import { ArticleInfoComponent } from './info.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    NzIconModule,
    GenericLinkModule,
    NgbTooltipModule,
    MediaModule,
    ImageModalModule,
    PrincipalConfigurationDirectiveModule,
    NonReturnableModule,
    BadgeModule,
    NotificationModule,
    ArticleInfoAttributesModule,
  ],
  declarations: [ArticleInfoComponent],
  exports: [ArticleInfoComponent],
})
export class ArticleInfoModule {}
