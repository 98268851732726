import { StateUtils } from '@spartacus/core';
import { loaderReducer } from '../../../state/utils/loader';
import { UserActionState } from '../base-feature-state';

export const initialState: any = {};

export function reducer(entityType: string): (state: UserActionState, action: StateUtils.EntityLoaderAction) => UserActionState {
  return (state = initialState, action: StateUtils.EntityLoaderAction): UserActionState => {
    if (action.meta && entityType === action.meta.entityType) {
      const id = [].concat(action.meta.entityId).join();
      return {
        ...state,
        [id]: loaderReducer<void>(action.meta.entityType)(undefined, action),
      };
    }
    return state;
  };
}
