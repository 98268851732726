import { InjectionToken, Provider } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { ClientToken } from '@spartacus/core';
import { loaderReducer } from '../../../../state/utils/loader/loader.reducer';
import { CLIENT_TOKEN, ClientAuthState } from '../client-auth-state';

export function getReducers(): ActionReducerMap<ClientAuthState> {
  return {
    clientToken: loaderReducer<ClientToken>(CLIENT_TOKEN),
  };
}

export const reducerToken: InjectionToken<ActionReducerMap<ClientAuthState>> = new InjectionToken<
  ActionReducerMap<ClientAuthState>
>('ClientAuthReducers');

export const reducerProvider: Provider = {
  provide: reducerToken,
  useFactory: getReducers,
};
