import { CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { AddressPipe } from './address.pipe';
import { EllipsisPipe } from './ellipsis.pipe';
import { FilterPipe } from './filter.pipe';
import { IncludesPipe } from './includes.pipe';
import { InputErrorPipe } from './input-error.pipe';
import { JoinPipe } from './join.pipe';
import { ArrayOrderByPipe } from './order-by.pipe';
import { PricePipe } from './price.pipe';
import { PrincipalConfigValuePipe } from './principal-config-value.pipe';
import { RandomIdPipe } from './random-id.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { SearchHintsPipe } from './searchHints.pipe';
import { TimeCodeLabelPipe } from './time-code-label.pipe';
import { TrimPipe } from './trim.pipe';
import { UnitPipe } from './unit.pipe';

const pipes = [
  TrimPipe,
  InputErrorPipe,
  JoinPipe,
  AddressPipe,
  UnitPipe,
  PricePipe,
  SafeUrlPipe,
  SafeHtmlPipe,
  ArrayOrderByPipe,
  EllipsisPipe,
  PrincipalConfigValuePipe,
  FilterPipe,
  IncludesPipe,
  SearchHintsPipe,
  RandomIdPipe,
  TimeCodeLabelPipe,
];

@NgModule({
  declarations: pipes,
  providers: [...pipes, CurrencyPipe],
  exports: pipes,
})
export class PipesModule {}
