import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CartType, TinyCart } from '../../../../core/model';
import { CART_CORE_FEATURE } from '../store/cart-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: TinyCartFacade,
      feature: CART_CORE_FEATURE,
      async: true,
      methods: ['hasMultipleCarts', 'getTinyCarts', 'getTinyCartsLoading', 'getTinyCartsLoaded', 'getTinyCart'],
    }),
})
export abstract class TinyCartFacade {
  abstract hasMultipleCarts(): Observable<boolean>;
  abstract getTinyCarts(): Observable<TinyCart[] | undefined>;
  abstract getTinyCartsLoading(): Observable<boolean>;
  abstract getTinyCartsLoaded(): Observable<boolean>;
  abstract getTinyCart(cartType: CartType): Observable<TinyCart | undefined>;
}
