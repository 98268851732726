<ng-container *ngIf="{ loading: loading$ | async, categories: categories$ | async } as data">
  <ul *ngIf="!data.loading && data.categories?.length > 0; else loadingTemplate">
    <ng-container *ngFor="let category of data.categories || []">
      <li
        py-megamenu-list-item
        (click)="setActiveCategory.emit(category)"
        [ngClass]="{ active: category.code === activeCategory?.code }"
      >
        <a>{{ category.name }}</a>
      </li>
    </ng-container>
  </ul>

  <ng-template #loadingTemplate>
    <div class="mt-6 px-5" *ngIf="data.loading; else noResultsTemplate">
      <div class="skeleton w-100 mb-2"></div>
      <div class="skeleton w-100 mb-2"></div>
      <div class="skeleton w-100 mb-2"></div>
      <div class="skeleton w-100 mb-2"></div>
      <div class="skeleton w-100"></div>
    </div>
  </ng-template>

  <ng-template #noResultsTemplate>
    <py-no-results [title]="'navigation.noAreaOfUseFound_hint'"></py-no-results>
  </ng-template>
</ng-container>
