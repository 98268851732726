import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Observable, combineLatest, distinctUntilChanged, map, shareReplay } from 'rxjs';
import { SoldTo, User } from '../../../core/model';
import { PrincipalConfigurationService, UserFacade } from '../../../core/user';
import { shallowEqualObjects } from '../../../core/util';
import { SoldToFacade } from '../../../features/sold-to-base';

@Component({
  selector: 'py-current-customer-banner',
  templateUrl: './current-customer-banner.component.html',
  styleUrls: ['./current-customer-banner.component.scss'],
  standalone: true,
  imports: [CommonModule],
  providers: [],
})
export class CurrentCustomerBannerComponent implements OnInit {
  displayCurrentSoldToInTopMenu$: Observable<boolean>;
  activeUser$: Observable<User>;
  activeSoldTo$: Observable<SoldTo>;

  constructor(
    private userService: UserFacade,
    private principalConfigurationService: PrincipalConfigurationService,
    private soldToService: SoldToFacade
  ) {}

  ngOnInit(): void {
    this.activeUser$ = this.userService.get().pipe(shareReplay({ bufferSize: 1, refCount: true }));
    this.activeSoldTo$ = this.soldToService.getActiveSoldTo().pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.displayCurrentSoldToInTopMenu$ = combineLatest([
      this.principalConfigurationService.isEnabled('enableDisplayCurrentSoldToInTopMenu'),
      this.activeUser$,
      this.activeSoldTo$,
    ]).pipe(
      map(
        ([isEnabled, activeUser, activeSoldTo]) =>
          isEnabled && !!activeUser && !!Object.keys(activeUser).length && !!activeSoldTo && !!Object.keys(activeSoldTo)?.length
      ),
      distinctUntilChanged(shallowEqualObjects),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
}
