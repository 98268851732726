import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { StopRequests } from './stop-requests.semaphore';

@Injectable({ providedIn: 'root' })
export class HttpStopRequestsInterceptor implements HttpInterceptor {
  constructor(protected stopRequests: StopRequests) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.stopRequests.WILL_REDIRECT.isRaised()) {
      return of(null);
    }
    return next.handle(request);
  }
}
