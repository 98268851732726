import { StateUtils } from '@spartacus/core';
import { UserActions } from '../actions/index';

export const initialState: any | undefined = undefined;

export function reducer(
  state = initialState,
  _action: UserActions.OrderCardsActions | UserActions.OrderCardsAdminActions | StateUtils.LoaderAction
): any {
  return state;
}
