import { MemoizedSelector, MemoizedSelectorWithProps, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { ConsignmentArticleStock, LoaderError } from '../../../model';
import { PyStateUtils } from '../../../state/utils';
import { loaderErrorDetailsSelector } from '../../../state/utils/loader';
import {
  AssortmentState,
  ConsignmentStockArticleStockInfoState,
  ConsignmentStockState,
  StateWithUser,
  UserState,
} from '../user-state';
import { getUserState } from './feature.selector';

export const getAssortmentState: MemoizedSelector<StateWithUser, AssortmentState> = createSelector(
  getUserState,
  (state: UserState) => state.assortment
);

export const getConsignmentStockState: MemoizedSelector<StateWithUser, ConsignmentStockState> = createSelector(
  getAssortmentState,
  (state: AssortmentState) => state.consignmentStock
);

export const getConsignmentStockArticlesState: MemoizedSelector<StateWithUser, StateUtils.LoaderState<string[]>> = createSelector(
  getConsignmentStockState,
  (state: ConsignmentStockState) => state.articles
);

export const getConsignmentStockArticlesLoading: MemoizedSelector<StateWithUser, boolean> = createSelector(
  getConsignmentStockArticlesState,
  (state) => (state && StateUtils.loaderLoadingSelector(state)) || false
);

export const getConsignmentStockArticlesSuccess: MemoizedSelector<StateWithUser, boolean> = createSelector(
  getConsignmentStockArticlesState,
  (state) => (state && StateUtils.loaderSuccessSelector(state)) || false
);

export const getConsignmentStockArticlesError: MemoizedSelector<StateWithUser, LoaderError> = createSelector(
  getConsignmentStockArticlesState,
  (state) => (state ? loaderErrorDetailsSelector(state) : undefined)
);

export const getConsignmentStockArticlesCodes: MemoizedSelector<StateWithUser, string[] | undefined> = createSelector(
  getConsignmentStockArticlesState,
  (state) => state && StateUtils.loaderValueSelector(state)
);

export const getConsignmentStockArticlesStockInfos: MemoizedSelector<
  StateWithUser,
  StateUtils.EntityLoaderState<ConsignmentStockArticleStockInfoState>
> = createSelector(getConsignmentStockState, (state: ConsignmentStockState) => state && state.stockInfos);

export const getConsignmentStockArticleStockInfoLoaderState: MemoizedSelectorWithProps<
  StateWithUser,
  any,
  StateUtils.LoaderState<ConsignmentArticleStock>
> = createSelector(
  getConsignmentStockArticlesStockInfos,
  (state: StateUtils.EntityLoaderState<ConsignmentStockArticleStockInfoState>, props) =>
    PyStateUtils.entityLoaderStateSelector(state, props.key) || PyStateUtils.initialLoaderState
);

export const getConsignmentStockArticleStockInfoStateLoading: MemoizedSelectorWithProps<StateWithUser, any, boolean> =
  createSelector(
    getConsignmentStockArticlesStockInfos,
    (state: StateUtils.EntityLoaderState<ConsignmentStockArticleStockInfoState>, props) =>
      StateUtils.entityLoadingSelector(state, props.key)
  );

export const getConsignmentStockArticleStockInfoStateSuccess: MemoizedSelectorWithProps<StateWithUser, any, boolean> =
  createSelector(
    getConsignmentStockArticlesStockInfos,
    (state: StateUtils.EntityLoaderState<ConsignmentStockArticleStockInfoState>, props) =>
      StateUtils.entitySuccessSelector(state, props.key)
  );

export const getConsignmentStockArticleStockInfoStateError: MemoizedSelectorWithProps<
  StateWithUser,
  any,
  LoaderError | undefined
> = createSelector(
  getConsignmentStockArticlesStockInfos,
  (state: StateUtils.EntityLoaderState<ConsignmentStockArticleStockInfoState>, props) =>
    PyStateUtils.entityErrorDetailsSelector(state, props.key)
);
