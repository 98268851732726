import { StateUtils } from '@spartacus/core';
import { ConsignmentArticleStock } from '../../../model';
import { PyStateUtils } from '../../../state/utils';
import { CONSIGNMENT_STOCK_ARTICLES, CONSIGNMENT_STOCK_ARTICLE_STOCK_INFO } from '../user-state';

export enum AssortmentActionTypes {
  LoadConsignmentStockArticles = '[Assortment] Load consignment stock articles',
  LoadConsignmentStockArticlesFail = '[Assortment] Load consignment stock articles fail ',
  LoadConsignmentStockArticlesSuccess = '[Assortment] Load consignment stock articles success ',
  ResetConsignmentStockArticles = '[Assortment] Reset consignment stock articles',

  LoadConsignmentStockArticleStockInfo = '[Assortment] Load consignment stock article stock info',
  LoadConsignmentStockArticleStockInfoSuccess = '[Assortment] Load consignment stock article stock info Success',
  LoadConsignmentStockArticleStockInfoFail = '[Assortment] Load consignment stock article stock info Fail',
  ResetConsignmentStockArticlesStockInfo = '[Assortment] Reset consignment stock articles stock info',
}

export class LoadConsignmentStockArticles extends StateUtils.LoaderLoadAction {
  readonly type = AssortmentActionTypes.LoadConsignmentStockArticles;

  constructor(public payload: string) {
    super(CONSIGNMENT_STOCK_ARTICLES);
  }
}

export class LoadConsignmentStockArticlesSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = AssortmentActionTypes.LoadConsignmentStockArticlesSuccess;

  constructor(public payload: string[]) {
    super(CONSIGNMENT_STOCK_ARTICLES);
  }
}

export class LoadConsignmentStockArticlesFail extends PyStateUtils.LoaderFailAction {
  readonly type = AssortmentActionTypes.LoadConsignmentStockArticlesFail;

  constructor(public payload?: any) {
    super(CONSIGNMENT_STOCK_ARTICLES, payload);
  }
}

export class ResetConsignmentStockArticles extends StateUtils.LoaderResetAction {
  readonly type = AssortmentActionTypes.ResetConsignmentStockArticles;

  constructor() {
    super(CONSIGNMENT_STOCK_ARTICLES);
  }
}

export class LoadConsignmentStockArticleStockInfo extends StateUtils.EntityLoadAction {
  readonly type = AssortmentActionTypes.LoadConsignmentStockArticleStockInfo;

  constructor(public payload: { userId: string; articleRef: string }) {
    super(CONSIGNMENT_STOCK_ARTICLE_STOCK_INFO, payload.articleRef);
  }
}

export class LoadConsignmentStockArticleStockInfoSuccess extends StateUtils.EntitySuccessAction {
  readonly type = AssortmentActionTypes.LoadConsignmentStockArticleStockInfoSuccess;

  constructor(public articleRef: string, public payload: ConsignmentArticleStock) {
    super(CONSIGNMENT_STOCK_ARTICLE_STOCK_INFO, articleRef);
  }
}

export class LoadConsignmentStockArticleStockInfoFail extends PyStateUtils.EntityFailAction {
  readonly type = AssortmentActionTypes.LoadConsignmentStockArticleStockInfoFail;

  constructor(public articleRef: string, public payload?: any) {
    super(CONSIGNMENT_STOCK_ARTICLE_STOCK_INFO, articleRef, payload);
  }
}

export class ResetConsignmentStockArticlesStockInfo extends StateUtils.LoaderResetAction {
  readonly type = AssortmentActionTypes.ResetConsignmentStockArticlesStockInfo;

  constructor() {
    super(CONSIGNMENT_STOCK_ARTICLE_STOCK_INFO);
  }
}

export type AssortmentActions =
  | LoadConsignmentStockArticles
  | LoadConsignmentStockArticlesFail
  | LoadConsignmentStockArticlesSuccess
  | ResetConsignmentStockArticles
  | LoadConsignmentStockArticleStockInfo
  | LoadConsignmentStockArticleStockInfoFail
  | LoadConsignmentStockArticleStockInfoSuccess
  | ResetConsignmentStockArticlesStockInfo;
