import { Action } from '@ngrx/store';
import { EntriesState } from '../../../features/store/base-feature-state';
import { MiniShoppingList, MiniShoppingListSearchResult } from '../../../model';
import { UserActions } from '../actions';
import { ShoppingListActionTypes } from '../actions/shopping-list.action';

export const initialState: EntriesState<MiniShoppingList> = {};

export function reducer(state = initialState, action: Action): EntriesState<MiniShoppingList> {
  switch (action.type) {
    case ShoppingListActionTypes.SearchMiniShoppingListsSuccess: {
      const _action = <UserActions.SearchMiniShoppingListsSuccess>action;
      const payload: MiniShoppingListSearchResult = _action.payload.searchResult;
      const results = payload.results;

      return results.reduce(
        (entities: { [id: number]: MiniShoppingList }, miniShoppingList: MiniShoppingList) => {
          return {
            ...entities,
            [miniShoppingList.code]: miniShoppingList,
          };
        },
        {
          ...state,
        }
      );
    }

    case ShoppingListActionTypes.UpdateShoppingListNameSuccess: {
      const _action = <UserActions.UpdateShoppingListNameSuccess>action;
      const payload = _action.payload;

      if (state[payload.code]) {
        return {
          ...state,
          [state[payload.code].code]: { ...state[payload.code], name: payload.name },
        };
      }
      break;
    }

    case ShoppingListActionTypes.UpdateShoppingListDescriptionSuccess: {
      const _action = <UserActions.UpdateShoppingListDescriptionSuccess>action;
      const payload = _action.payload;

      if (state[payload.code]) {
        return {
          ...state,
          [state[payload.code].code]: { ...state[payload.code], description: payload.description },
        };
      }
      break;
    }
    default:
      return state;
  }
}
