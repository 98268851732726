import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'py-confirmation-overlay-message',
  templateUrl: './confirmation-overlay-message.component.html',
  styleUrls: ['./confirmation-overlay-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationOverlayMessageComponent implements OnInit {
  @Input() message: string;
  @Output() confirm = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  onConfirm() {
    this.confirm.emit();
  }
}
