import { InjectionToken, Provider } from '@angular/core';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import { LoginLogoutActionTypes } from '../../../auth/user-auth/store/actions/login-logout.action';
import { entityLoaderReducer } from '../../../state/utils/entity-loader/entity-loader.reducer';

export function getReducers<T>(): ActionReducer<StateUtils.EntityLoaderState<T>> {
  return entityLoaderReducer(PROCESS_FEATURE);
}

export const reducerToken: InjectionToken<ActionReducerMap<StateUtils.EntityLoaderState<any>>> = new InjectionToken<
  ActionReducerMap<StateUtils.EntityLoaderState<any>>
>('ProcessReducers');

export const reducerProvider: Provider = {
  provide: reducerToken,
  useFactory: getReducers,
};

export function clearProcessState(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (action.type === LoginLogoutActionTypes.Logout) {
      state = undefined;
    }

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [clearProcessState];
