import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import {
  AuthStorageService,
  ConfigInitializerService,
  AuthGuard as CxAuthGuard,
  AuthRedirectService as CxAuthRedirectService,
  AuthStatePersistenceService as CxAuthStatePersistenceService,
  NotAuthGuard as CxNotAuthGuard,
  OAuthLibWrapperService as CxOAuthLibWrapperService,
  UserAuthEventModule,
  provideConfigValidator,
} from '@spartacus/core';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { tap } from 'rxjs/operators';
import { provideDefaultConfig } from '../../config/config-providers';
import { baseUrlConfigValidator } from './config/base-url-config-validator';
import { defaultAuthConfig } from './config/default-auth-config';
import { AuthWrapperService } from './facade/auth-wrapper.service';
import { AuthGuard as PyAuthGuard, NotAuthGuard as PyNotAuthGuard } from './guards';
import { interceptors } from './http-interceptors/index';
import { AuthRedirectService as PyAuthRedirectService, OAuthLibWrapperService as PyOAuthLibWrapperService } from './services';
import { AuthStatePersistenceService } from './services/auth-state-persistence.service';

/**
 * Initialize the check for `token` or `code` in the url returned from the OAuth server.
 */
export function checkOAuthParamsInUrl(authWrapperService: AuthWrapperService, configInit: ConfigInitializerService) {
  const result = () =>
    configInit
      .getStable()
      .pipe(
        tap(() => {
          // Wait for stable config is used, because with auth redirect would kick so quickly that the page would not be loaded correctly
          authWrapperService.checkOAuthParamsInUrl();
        })
      )
      .toPromise();

  return result;
}

export function authStatePersistenceFactory(authStatePersistenceService: AuthStatePersistenceService) {
  const result = () => authStatePersistenceService.initSync();
  return result;
}

/**
 * Authentication module for a user. Handlers requests for logged in users,
 * provides authorization services and storage for tokens.
 */
@NgModule({
  imports: [CommonModule, OAuthModule.forRoot(), UserAuthEventModule],
})
export class UserAuthModule {
  static forRoot(): ModuleWithProviders<UserAuthModule> {
    return {
      ngModule: UserAuthModule,
      providers: [
        provideDefaultConfig(defaultAuthConfig),
        provideConfigValidator(baseUrlConfigValidator),
        ...interceptors,
        {
          provide: OAuthStorage,
          useExisting: AuthStorageService,
        },
        {
          provide: APP_INITIALIZER,
          useFactory: authStatePersistenceFactory,
          deps: [AuthStatePersistenceService],
          multi: true,
        },
        {
          provide: APP_INITIALIZER,
          useFactory: checkOAuthParamsInUrl,
          deps: [AuthWrapperService, ConfigInitializerService],
          multi: true,
        },
        { provide: CxAuthGuard, useExisting: PyAuthGuard },
        { provide: CxNotAuthGuard, useExisting: PyNotAuthGuard },
        { provide: CxAuthRedirectService, useExisting: PyAuthRedirectService },
        { provide: CxAuthStatePersistenceService, useExisting: AuthStatePersistenceService },
        { provide: CxOAuthLibWrapperService, useExisting: PyOAuthLibWrapperService },
      ],
    };
  }
}
