import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { ORGANIZATION_CORE_FEATURE } from './store/organization-state';

@NgModule({
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        [ORGANIZATION_CORE_FEATURE]: {
          module: () => import('./organization-base.module').then((m) => m.OrganizationBaseModule),
        },
      },
    }),
  ],
})
export class OrganizationBaseFeatureModule {}
