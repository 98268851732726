import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { WindowRef } from '../../../core/window';

@Component({
  selector: 'py-unsupported-message',
  templateUrl: './unsupported-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnsupportedMessageComponent implements OnInit {
  constructor(protected winRef: WindowRef) {}

  unsupportedBrowser() {
    if (this.winRef.isBrowser()) {
      const ua = window.navigator.userAgent;
      const msie = ua.indexOf('MSIE '); // IE 10 or older
      const trident = ua.indexOf('Trident/'); // IE 11
      const edge = ua.indexOf('Edge/'); // Legacy Edge
      return msie > 0 || trident > 0 || edge > -1;
    } else {
      return false;
    }
  }

  ngOnInit(): void {}
}
