import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Image } from '../../../../core/model';
import { ImageModalData } from '../../image-modal/image-modal.component';

@Component({
  selector: 'py-article-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleImageComponent implements OnInit {
  @HostBinding('class.skeleton') @Input() loading = false;
  @HostBinding('class.small') @Input() small = false;

  @Input() images?: Image[];
  @Input() altText: string;

  get hasImages(): boolean {
    return this.images?.length > 0;
  }

  constructor(private launchDialogService: LaunchDialogService) {}

  ngOnInit(): void {}

  openImage(): void {
    if (this.hasImages) {
      const modalData: ImageModalData = {
        alternativeText: this.altText,
        images: this.images,
      };

      this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.IMAGE, undefined, modalData);
    }
  }
}
