import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { AlertType } from '../../../core/model';

@Component({
  selector: 'py-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertMessageComponent implements OnInit {
  @Input() title: string;
  @Input() body?: string | TemplateRef<any>;
  @Input() dismissable = false;

  @HostBinding('class')
  @Input()
  type = AlertType.Info;

  @Output()
  dismiss = new EventEmitter<void>();

  get isBodyTemplate(): boolean {
    return typeof this.body !== 'string';
  }

  constructor() {}

  ngOnInit(): void {}

  onDismiss() {
    this.dismiss.emit();
  }
}
