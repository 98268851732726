import { Page, PageMeta } from '@spartacus/core';
import { Image } from '../../model';
import { Occ } from '../../occ';

declare module '@spartacus/core' {
  interface Page {
    publishedDate?: Date;
    featuredImage?: Image;
  }
  interface PageMeta {
    altHrefLangs?: AltHrefLang[];

    opengraph?: OpengraphMeta;
    twitter?: TwitterMeta;
  }
}

export interface OpengraphMeta {
  type?: string;
  title?: string;
  siteName?: string;
  description?: string;
  image?: string;
  altImage?: string;
  url?: string;
}

export interface TwitterMeta {
  card?: string;
  site?: string;
  creator?: string;
  title?: string;
  description?: string;
  image?: string;
  altImage?: string;
}

export enum TwitterCardType {
  Summary = 'summary',
  SummaryLargeImage = 'summary_large_image',
}

// eslint-disable-next-line
export interface AltHrefLang extends Occ.AltHrefLang {}

export { Page, PageMeta };
