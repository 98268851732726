import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InterceptorUtil, OCC_USER_ID_ANONYMOUS, OccEndpointsService, USE_CLIENT_TOKEN } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CMSLinkComponents, CMSLinkComponentsForProductList } from '../../../model';

@Injectable({ providedIn: 'root' })
export class CmsLinkComponentsConnector {
  constructor(private httpClient: HttpClient, private occEndpointsService: OccEndpointsService) {}

  /**
   * Get links by product code from api
   * @param userId the user id
   * @param codes product codes
   */
  getLinksByProduct(userId, code: string): Observable<CMSLinkComponents[]> {
    let headers = new HttpHeaders();
    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }
    return this.httpClient
      .get<CMSLinkComponentsForProductList>(
        this.occEndpointsService.buildUrl('linkComponentsProducts', {
          urlParams: {
            userId,
          },
          queryParams: {
            codes: [code],
          },
        }),
        { headers }
      )
      .pipe(
        map((data) => {
          const item = data.list.find((l) => l.productRef.code === code);
          return item?.linkComponents?.cmsLinkComponents || [];
        })
      );
  }
}
