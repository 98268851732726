import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../core/i18n';
import { PipesModule } from '../../pipes';
import { BadgeModule } from '../badge';
import { NotificationModule } from '../notification';
import { ConsignmentStockArticleStockInfoModule } from './consignment-stock-article-stock-info/consignment-stock-article-stock-info.module';
import { DetailedStockInfoComponent } from './detailed-stock-info.component';
import { ExtendedDetailedStockInfoModule } from './extended-detailed-stock-info/extended-detailed-stock-info.module';

@NgModule({
  imports: [
    CommonModule,
    ExtendedDetailedStockInfoModule,
    ConsignmentStockArticleStockInfoModule,
    I18nModule,
    NzIconModule,
    PipesModule,
    BadgeModule,
    NotificationModule,
    NgbTooltipModule,
  ],
  declarations: [DetailedStockInfoComponent],
  exports: [DetailedStockInfoComponent],
})
export class DetailedStockInfoModule {}
