import { Action } from '@ngrx/store';
import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import { PagedSearchAction } from '../../../features/store/actions/search.actions';
import {
  HttpErrorModel,
  MiniShoppingListSearchResult,
  PrincipalSharedShoppingListFlagsPage,
  PrincipalSharedShoppingListSearchParams,
  SearchParams,
  ShoppingList,
  ShoppingListPermission,
  Unit,
  UpdatePermissionType,
  UpdateShoppingListPermissionsParams,
  WritableShoppingListSearchResult,
} from '../../../model';
import { PyStateUtils } from '../../../state/utils';
import {
  DELETE_SHOPPING_LIST_PROCESS,
  MINI_SHOPPING_LIST,
  SHOPPING_LIST,
  SHOPPING_LIST_PRINCIPALS,
  UPDATE_SHOPPING_LIST_CONTACT_PERSON_PROCESS,
  UPDATE_SHOPPING_LIST_DESCRIPTION_PROCESS,
  UPDATE_SHOPPING_LIST_NAME_PROCESS,
  UPDATE_SHOPPING_LIST_PERMISSIONS_PROCESS,
  WRITABLE_SHOPPING_LIST,
} from '../user-state';

export enum ShoppingListActionTypes {
  SearchMiniShoppingLists = '[User] Search mini shopping lists',
  SearchMiniShoppingListsSuccess = '[User] Search mini shopping lists success',
  SearchMiniShoppingListsFail = '[User] Search mini shopping lists fail',
  ResetMiniShoppingLists = '[User] Reset mini shopping lists',

  SearchWritableShoppingLists = '[User] Search writable shopping lists',
  SearchWritableShoppingListsSuccess = '[User] Search writable shopping lists success',
  SearchWritableShoppingListsFail = '[User] Search writable shopping lists fail',

  CreateShoppingList = '[User] Create shopping list',
  CreateShoppingListSuccess = '[User] Create shopping list success',
  CreateShoppingListFail = '[User] Create shopping list fail',

  CreateShoppingListAndAddEntry = '[User] Create shopping list and add entry',

  LoadShoppingList = '[User] Load shopping list',
  LoadShoppingListSuccess = '[User] Load shopping list success',
  LoadShoppingListFail = '[User] Load shopping list fail',

  UpdateShoppingListName = '[User] Update shopping list name',
  UpdateShoppingListNameSuccess = '[User] Update shopping list name success',
  UpdateShoppingListNameFail = '[User] Update shopping list name fail',
  ResetUpdateShoppingListName = '[User] Reset update shopping list name',

  UpdateShoppingListDescription = '[User] Update shopping list description',
  UpdateShoppingListDescriptionSuccess = '[User] Update shopping list description success',
  UpdateShoppingListDescriptionFail = '[User] Update shopping list description fail',
  ResetUpdateShoppingListDescription = '[User] Reset update shopping list description',

  UpdateShoppingListContactPerson = '[User] Update shopping list contact person',
  UpdateShoppingListContactPersonSuccess = '[User] Update shopping list contact person success',
  UpdateShoppingListContactPersonFail = '[User] Update shopping list contact person fail',
  ResetUpdateShoppingListContactPerson = '[User] Reset shopping list contact person',

  DeleteShoppingList = '[User] Delete shopping list',
  DeleteShoppingListSuccess = '[User] Delete shopping list success',
  DeleteShoppingListFail = '[User] Delete shopping list fail',
  ResetDeleteShoppingList = '[User] Reset delete shopping list',

  UpdateShoppingListPermissions = '[User] Update shopping list permissions',
  UpdateShoppingListPermissionsSuccess = '[User] Update shopping list permissions success',
  UpdateShoppingListPermissionsFail = '[User] Update shopping list permissions fail',
  ResetUpdateShoppingListPermissions = '[User] Reset update shopping list permissions',

  ResetShoppingLists = '[User] Reset shopping lists',
  UpdateTempShoppingList = '[User] Update temporary shopping list',
  RemoveShoppingList = '[User] Remove shopping list',

  LoadShoppingListPrincipals = '[User] Load shopping list principals',
  LoadShoppingListPrincipalsSuccess = '[User] Load shopping list principals success',
  LoadShoppingListPrincipalsFail = '[User] Load shopping list principals fail',
}

interface SearchMiniShoppingListsPayload {
  userId: string;
  code?: string;
  searchParams?: SearchParams;
}

interface SearchMiniShoppingListsFailPayload extends SearchMiniShoppingListsPayload {
  error?: HttpErrorModel;
}

interface SearchMiniShoppingListsSuccessPayload extends SearchMiniShoppingListsPayload {
  searchResult: MiniShoppingListSearchResult;
}

interface SearchWritableShoppingListsPayload {
  userId: string;
  code?: string;
  searchParams?: SearchParams;
}

interface SearchWritableShoppingListsFailPayload extends SearchWritableShoppingListsPayload {
  error?: HttpErrorModel;
}

interface SearchWritableShoppingListsSuccessPayload extends SearchWritableShoppingListsPayload {
  searchResult: WritableShoppingListSearchResult;
}

interface CreateShoppingListPayload {
  userId: string;
  tempCode: string;
  cartCode?: string;
  cloneCode?: string;
  name?: string;
  description?: string;
}

interface CreateShoppingListFailPayload extends CreateShoppingListPayload {
  error?: HttpErrorModel;
}

interface CreateShoppingListSuccessPayload extends CreateShoppingListPayload {
  shoppingList: ShoppingList;
}

interface CreateShoppingListAndEntryPayload {
  userId: string;
  tempCode: string;
  name: string;
  description: string;
  articleCode: string;
  quantity: number;
  unit: Unit;
  displayNumber?: string;
}

interface LoadShoppingListPayload {
  userId: string;
  code: string;
}

interface LoadShoppingListFailPayload extends LoadShoppingListPayload {
  error?: HttpErrorModel;
}

interface LoadShoppingListSuccessPayload extends LoadShoppingListPayload {
  shoppingList: ShoppingList;
}

interface UpdateShoppingListNamePayload {
  userId: string;
  code: string;
  name: string;
}

interface UpdateShoppingListDescriptionPayload {
  userId: string;
  code: string;
  description: string;
}

interface UpdateShoppingListContactPersonPayload {
  userId: string;
  code: string;
  customerId: string;
}

interface UpdateShoppingListNameFailPayload extends UpdateShoppingListNamePayload {
  error?: HttpErrorModel;
}

interface UpdateShoppingListDescriptionFailPayload extends UpdateShoppingListDescriptionPayload {
  error?: HttpErrorModel;
}

interface UpdateShoppingListContactPersonFailPayload extends UpdateShoppingListContactPersonPayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UpdateShoppingListNameSuccessPayload extends UpdateShoppingListNamePayload {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UpdateShoppingListDesciptionSuccessPayload extends UpdateShoppingListDescriptionPayload {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UpdateShoppingListContactPersonSuccessPayload extends UpdateShoppingListContactPersonPayload {}
interface DeleteShoppingListPayload {
  userId: string;
  code: string;
}

interface DeleteShoppingListFailPayload extends DeleteShoppingListPayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DeleteShoppingListSuccessPayload extends DeleteShoppingListPayload {}

interface UpdateShoppingListPermissionsPayload {
  userId: string;
  code: string;
  permissions: ShoppingListPermission[];
  updatePermissionType?: UpdatePermissionType;
  params?: UpdateShoppingListPermissionsParams;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UpdateShoppingListPermissionsSuccessPayload extends UpdateShoppingListPermissionsPayload {}

interface UpdateTempShoppingListPayload {
  shoppingList: ShoppingList;
  tempCode: string;
}

interface RemoveShoppingListPayload {
  code: string;
}

export class SearchMiniShoppingLists extends StateUtils.LoaderLoadAction implements PagedSearchAction {
  readonly type = ShoppingListActionTypes.SearchMiniShoppingLists;
  readonly searchType = MINI_SHOPPING_LIST;
  public searchParams;

  constructor(public payload: SearchMiniShoppingListsPayload) {
    super(MINI_SHOPPING_LIST);
    this.searchParams = payload.searchParams;
  }
}

export class SearchMiniShoppingListsFail extends PyStateUtils.LoaderFailAction implements PagedSearchAction {
  readonly type = ShoppingListActionTypes.SearchMiniShoppingListsFail;
  readonly searchType = MINI_SHOPPING_LIST;
  public searchParams;

  constructor(public payload: SearchMiniShoppingListsFailPayload) {
    super(MINI_SHOPPING_LIST, payload.error);
    this.searchParams = payload.searchParams;
  }
}

export class SearchMiniShoppingListsSuccess extends StateUtils.LoaderSuccessAction implements PagedSearchAction {
  readonly type = ShoppingListActionTypes.SearchMiniShoppingListsSuccess;
  readonly searchType = MINI_SHOPPING_LIST;
  public searchParams;

  constructor(public payload: SearchMiniShoppingListsSuccessPayload) {
    super(MINI_SHOPPING_LIST);
    this.searchParams = payload.searchParams;
  }
}

export class ResetMiniShoppingLists extends StateUtils.LoaderResetAction {
  readonly type = ShoppingListActionTypes.ResetMiniShoppingLists;

  constructor() {
    super(MINI_SHOPPING_LIST);
  }
}

export class SearchWritableShoppingLists extends StateUtils.LoaderLoadAction implements PagedSearchAction {
  readonly type = ShoppingListActionTypes.SearchWritableShoppingLists;
  readonly searchType = WRITABLE_SHOPPING_LIST;
  public searchParams;

  constructor(public payload: SearchWritableShoppingListsPayload) {
    super(WRITABLE_SHOPPING_LIST);
    this.searchParams = payload.searchParams;
  }
}

export class SearchWritableShoppingListsFail extends PyStateUtils.LoaderFailAction implements PagedSearchAction {
  readonly type = ShoppingListActionTypes.SearchWritableShoppingListsFail;
  readonly searchType = WRITABLE_SHOPPING_LIST;
  public searchParams;

  constructor(public payload: SearchWritableShoppingListsFailPayload) {
    super(WRITABLE_SHOPPING_LIST, payload.error);
    this.searchParams = payload.searchParams;
  }
}

export class SearchWritableShoppingListsSuccess extends StateUtils.LoaderSuccessAction implements PagedSearchAction {
  readonly type = ShoppingListActionTypes.SearchWritableShoppingListsSuccess;
  readonly searchType = WRITABLE_SHOPPING_LIST;
  public searchParams;

  constructor(public payload: SearchWritableShoppingListsSuccessPayload) {
    super(WRITABLE_SHOPPING_LIST);
    this.searchParams = payload.searchParams;
  }
}

export class CreateShoppingList extends StateUtils.EntityLoadAction {
  readonly type = ShoppingListActionTypes.CreateShoppingList;

  constructor(public payload: CreateShoppingListPayload) {
    super(SHOPPING_LIST, payload.tempCode);
  }
}

export class CreateShoppingListFail extends PyStateUtils.EntityFailAction {
  readonly type = ShoppingListActionTypes.CreateShoppingListFail;

  constructor(public payload: CreateShoppingListFailPayload) {
    super(SHOPPING_LIST, payload.tempCode, payload.error);
  }
}

export class CreateShoppingListSuccess extends StateUtils.EntitySuccessAction {
  readonly type = ShoppingListActionTypes.CreateShoppingListSuccess;

  constructor(public payload: CreateShoppingListSuccessPayload) {
    super(SHOPPING_LIST, payload.shoppingList.code);
  }
}

export class CreateShoppingListAndAddEntry extends StateUtils.EntityLoadAction {
  readonly type = ShoppingListActionTypes.CreateShoppingListAndAddEntry;

  constructor(public payload: CreateShoppingListAndEntryPayload) {
    super(SHOPPING_LIST, payload.tempCode);
  }
}

export class LoadShoppingList extends StateUtils.EntityLoadAction {
  readonly type = ShoppingListActionTypes.LoadShoppingList;

  constructor(public payload: LoadShoppingListPayload) {
    super(SHOPPING_LIST, payload.code);
  }
}

export class LoadShoppingListFail extends PyStateUtils.EntityFailAction {
  readonly type = ShoppingListActionTypes.LoadShoppingListFail;

  constructor(public payload: LoadShoppingListFailPayload) {
    super(SHOPPING_LIST, payload.code, payload.error);
  }
}

export class LoadShoppingListSuccess extends StateUtils.EntitySuccessAction {
  readonly type = ShoppingListActionTypes.LoadShoppingListSuccess;

  constructor(public payload: LoadShoppingListSuccessPayload) {
    super(SHOPPING_LIST, payload.code);
  }
}

export class UpdateShoppingListName extends StateUtils.EntityLoadAction {
  readonly type = ShoppingListActionTypes.UpdateShoppingListName;

  constructor(public payload: UpdateShoppingListNamePayload) {
    super(PROCESS_FEATURE, UPDATE_SHOPPING_LIST_NAME_PROCESS);
  }
}

export class UpdateShoppingListNameFail extends PyStateUtils.EntityFailAction {
  readonly type = ShoppingListActionTypes.UpdateShoppingListNameFail;

  constructor(public payload: UpdateShoppingListNameFailPayload) {
    super(PROCESS_FEATURE, UPDATE_SHOPPING_LIST_NAME_PROCESS);
  }
}

export class UpdateShoppingListNameSuccess extends StateUtils.EntitySuccessAction {
  readonly type = ShoppingListActionTypes.UpdateShoppingListNameSuccess;

  constructor(public payload: UpdateShoppingListNameSuccessPayload) {
    super(PROCESS_FEATURE, UPDATE_SHOPPING_LIST_NAME_PROCESS);
  }
}

export class ResetUpdateShoppingListName extends StateUtils.EntityLoaderResetAction {
  readonly type = ShoppingListActionTypes.ResetUpdateShoppingListName;

  constructor() {
    super(PROCESS_FEATURE, UPDATE_SHOPPING_LIST_NAME_PROCESS);
  }
}

export class UpdateShoppingListDescription extends StateUtils.EntityLoadAction {
  readonly type = ShoppingListActionTypes.UpdateShoppingListDescription;

  constructor(public payload: UpdateShoppingListDescriptionPayload) {
    super(PROCESS_FEATURE, UPDATE_SHOPPING_LIST_DESCRIPTION_PROCESS);
  }
}

export class UpdateShoppingListDescriptionFail extends PyStateUtils.EntityFailAction {
  readonly type = ShoppingListActionTypes.UpdateShoppingListDescriptionFail;

  constructor(public payload: UpdateShoppingListDescriptionFailPayload) {
    super(PROCESS_FEATURE, UPDATE_SHOPPING_LIST_DESCRIPTION_PROCESS);
  }
}

export class UpdateShoppingListDescriptionSuccess extends StateUtils.EntitySuccessAction {
  readonly type = ShoppingListActionTypes.UpdateShoppingListDescriptionSuccess;

  constructor(public payload: UpdateShoppingListDesciptionSuccessPayload) {
    super(PROCESS_FEATURE, UPDATE_SHOPPING_LIST_DESCRIPTION_PROCESS);
  }
}

export class ResetUpdateShoppingListDescription extends StateUtils.EntityLoaderResetAction {
  readonly type = ShoppingListActionTypes.ResetUpdateShoppingListDescription;

  constructor() {
    super(PROCESS_FEATURE, UPDATE_SHOPPING_LIST_DESCRIPTION_PROCESS);
  }
}

export class UpdateShoppingListContactPerson extends StateUtils.EntityLoadAction {
  readonly type = ShoppingListActionTypes.UpdateShoppingListContactPerson;

  constructor(public payload: UpdateShoppingListContactPersonPayload) {
    super(PROCESS_FEATURE, UPDATE_SHOPPING_LIST_CONTACT_PERSON_PROCESS);
  }
}

export class UpdateShoppingListContactPersonFail extends PyStateUtils.EntityFailAction {
  readonly type = ShoppingListActionTypes.UpdateShoppingListContactPersonFail;

  constructor(public payload: UpdateShoppingListContactPersonFailPayload) {
    super(PROCESS_FEATURE, UPDATE_SHOPPING_LIST_CONTACT_PERSON_PROCESS);
  }
}

export class UpdateShoppingListContactPersonSuccess extends StateUtils.EntitySuccessAction {
  readonly type = ShoppingListActionTypes.UpdateShoppingListContactPersonSuccess;

  constructor(public payload: UpdateShoppingListContactPersonSuccessPayload) {
    super(PROCESS_FEATURE, UPDATE_SHOPPING_LIST_CONTACT_PERSON_PROCESS);
  }
}

export class ResetUpdateShoppingListContactPerson extends StateUtils.EntityLoaderResetAction {
  readonly type = ShoppingListActionTypes.ResetUpdateShoppingListContactPerson;

  constructor() {
    super(PROCESS_FEATURE, UPDATE_SHOPPING_LIST_CONTACT_PERSON_PROCESS);
  }
}

export class DeleteShoppingList extends StateUtils.EntityLoadAction {
  readonly type = ShoppingListActionTypes.DeleteShoppingList;

  constructor(public payload: DeleteShoppingListPayload) {
    super(PROCESS_FEATURE, DELETE_SHOPPING_LIST_PROCESS);
  }
}

export class DeleteShoppingListFail extends PyStateUtils.EntityFailAction {
  readonly type = ShoppingListActionTypes.DeleteShoppingListFail;

  constructor(public payload: DeleteShoppingListFailPayload) {
    super(PROCESS_FEATURE, DELETE_SHOPPING_LIST_PROCESS, payload.error);
  }
}

export class DeleteShoppingListSuccess extends StateUtils.EntitySuccessAction {
  readonly type = ShoppingListActionTypes.DeleteShoppingListSuccess;

  constructor(public payload: DeleteShoppingListSuccessPayload) {
    super(PROCESS_FEATURE, DELETE_SHOPPING_LIST_PROCESS);
  }
}

export class ResetDeleteShoppingList extends StateUtils.EntityLoaderResetAction {
  readonly type = ShoppingListActionTypes.ResetDeleteShoppingList;

  constructor() {
    super(PROCESS_FEATURE, DELETE_SHOPPING_LIST_PROCESS);
  }
}

export class UpdateShoppingListPermissions extends StateUtils.LoaderLoadAction {
  readonly type = ShoppingListActionTypes.UpdateShoppingListPermissions;

  constructor(public payload: UpdateShoppingListPermissionsPayload) {
    super(UPDATE_SHOPPING_LIST_PERMISSIONS_PROCESS);
  }
}

export class UpdateShoppingListPermissionsFail extends PyStateUtils.LoaderFailAction {
  readonly type = ShoppingListActionTypes.UpdateShoppingListPermissionsFail;

  constructor(public error?: HttpErrorModel) {
    super(UPDATE_SHOPPING_LIST_PERMISSIONS_PROCESS, error);
  }
}

export class UpdateShoppingListPermissionsSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = ShoppingListActionTypes.UpdateShoppingListPermissionsSuccess;

  constructor(public payload: UpdateShoppingListPermissionsSuccessPayload) {
    super(UPDATE_SHOPPING_LIST_PERMISSIONS_PROCESS);
  }
}

export class ResetUpdateShoppingListPermissions implements Action {
  readonly type = ShoppingListActionTypes.ResetUpdateShoppingListPermissions;

  constructor() {}
}

export class UpdateTempShoppingList extends StateUtils.EntitySuccessAction {
  readonly type = ShoppingListActionTypes.UpdateTempShoppingList;

  constructor(public payload: UpdateTempShoppingListPayload) {
    super(SHOPPING_LIST, payload.tempCode, payload.shoppingList);
  }
}

export class ResetShoppingLists extends StateUtils.ProcessesLoaderResetAction {
  readonly type = ShoppingListActionTypes.ResetShoppingLists;

  constructor() {
    super(SHOPPING_LIST);
  }
}

/**
 * Used for cleaning shopping list in local state.
 */
export class RemoveShoppingList extends StateUtils.EntityRemoveAction {
  readonly type = ShoppingListActionTypes.RemoveShoppingList;

  constructor(public payload: RemoveShoppingListPayload) {
    super(SHOPPING_LIST, payload.code);
  }
}

export class LoadShoppingListPrincipals extends StateUtils.LoaderLoadAction implements PagedSearchAction {
  readonly type = ShoppingListActionTypes.LoadShoppingListPrincipals;
  readonly searchType = SHOPPING_LIST_PRINCIPALS;
  constructor(public userId: string, public code: string, public searchParams: PrincipalSharedShoppingListSearchParams) {
    super(SHOPPING_LIST_PRINCIPALS);
  }
}

export class LoadShoppingListPrincipalsFail extends PyStateUtils.LoaderFailAction implements PagedSearchAction {
  readonly type = ShoppingListActionTypes.LoadShoppingListPrincipalsFail;
  readonly searchType = SHOPPING_LIST_PRINCIPALS;

  constructor(
    public userId: string,
    public code: string,
    public error: HttpErrorModel,
    public searchParams: PrincipalSharedShoppingListSearchParams
  ) {
    super(SHOPPING_LIST_PRINCIPALS, error);
  }
}

export class LoadShoppingListPrincipalsSuccess extends StateUtils.LoaderSuccessAction implements PagedSearchAction {
  readonly type = ShoppingListActionTypes.LoadShoppingListPrincipalsSuccess;
  readonly searchType = SHOPPING_LIST_PRINCIPALS;

  constructor(
    public userId: string,
    public code: string,
    public payload: PrincipalSharedShoppingListFlagsPage,
    public searchParams: PrincipalSharedShoppingListSearchParams
  ) {
    super(SHOPPING_LIST_PRINCIPALS);
  }
}

export type ShoppingListActions =
  | SearchMiniShoppingLists
  | SearchMiniShoppingListsFail
  | SearchMiniShoppingListsSuccess
  | SearchWritableShoppingLists
  | SearchWritableShoppingListsFail
  | SearchWritableShoppingListsSuccess
  | CreateShoppingList
  | CreateShoppingListFail
  | CreateShoppingListSuccess
  | CreateShoppingListAndAddEntry
  | LoadShoppingList
  | LoadShoppingListFail
  | LoadShoppingListSuccess
  | UpdateShoppingListName
  | UpdateShoppingListNameFail
  | UpdateShoppingListNameSuccess
  | ResetUpdateShoppingListName
  | UpdateShoppingListDescription
  | UpdateShoppingListDescriptionFail
  | UpdateShoppingListDescriptionSuccess
  | ResetUpdateShoppingListDescription
  | DeleteShoppingList
  | DeleteShoppingListFail
  | DeleteShoppingListSuccess
  | UpdateShoppingListPermissions
  | UpdateShoppingListPermissionsFail
  | UpdateShoppingListPermissionsSuccess
  | ResetUpdateShoppingListPermissions
  | UpdateTempShoppingList
  | ResetMiniShoppingLists
  | ResetShoppingLists
  | RemoveShoppingList
  | UpdateShoppingListContactPerson
  | UpdateShoppingListContactPersonSuccess
  | UpdateShoppingListContactPersonFail
  | ResetUpdateShoppingListContactPerson
  | LoadShoppingListPrincipals
  | LoadShoppingListPrincipalsFail
  | LoadShoppingListPrincipalsSuccess;
