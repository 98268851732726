import { ChangeDetectionStrategy, Component, ElementRef, OnInit } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnonymousConsentsService } from '../../../../core/anonymous-consents';
import { CmsNavigationComponent } from '../../../../core/model';
import { NavigationService } from '../../services/navigation.service';
import { NavigationNode } from './navigation-node.model';

@Component({
  selector: 'cx-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent implements OnInit {
  styleClasses: string;
  node$: Observable<NavigationNode> = this.service.createNavigation(this.componentData.data$);

  styleClass$: Observable<string> = this.componentData.data$.pipe(map((d) => d?.styleClass));

  consentsUrl: string = '/consents';

  constructor(
    protected componentData: CmsComponentData<CmsNavigationComponent>,
    protected service: NavigationService,
    private anonymousConsentsService: AnonymousConsentsService,
    private elementRef: ElementRef
  ) {}

  get isHorizontalNavigation(): boolean {
    return this.styleClasses?.split && this.styleClasses?.split(' ').includes('navigation-horizontal');
  }

  ngOnInit(): void {
    this.styleClasses = this.elementRef.nativeElement.className;
  }

  openConsents($event): void {
    $event?.preventDefault();
    this.anonymousConsentsService.toggleSettingsVisible(true, true);
  }

  parseIcon(styleClasses: string = ''): string {
    switch (styleClasses) {
      case 'icon-phone':
        return 'phone';

      case 'icon-envelope':
        return 'mail';

      case 'icon-environment':
        return 'environment';

      default:
        return undefined;
    }
  }
}
