import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AsmStatePersistenceService } from '@spartacus/asm/core';
import { AsmAuthHttpHeaderService, AsmAuthService, AsmAuthStorageService } from '@spartacus/asm/root';
import { AuthHttpHeaderService, AuthService, AuthStorageService } from '@spartacus/core';
import { provideDefaultConfig } from '../../../core/config/config-providers';
import { AsmLoaderModule } from './asm-loader.module';
import { defaultAsmConfig } from './config/default-asm-config';

export function asmStatePersistenceFactory(asmStatePersistenceService: AsmStatePersistenceService) {
  const result = () => asmStatePersistenceService.initSync();
  return result;
}

@NgModule({
  imports: [AsmLoaderModule],
  providers: [
    provideDefaultConfig(defaultAsmConfig),
    {
      provide: AuthStorageService,
      useExisting: AsmAuthStorageService,
    },
    {
      provide: AuthService,
      useExisting: AsmAuthService,
    },
    {
      provide: AuthHttpHeaderService,
      useExisting: AsmAuthHttpHeaderService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: asmStatePersistenceFactory,
      deps: [AsmStatePersistenceService],
      multi: true,
    },
  ],
})
export class AsmRootModule {}
