import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InterceptorUtil, OCC_USER_ID_ANONYMOUS, OccEndpointsService, USE_CLIENT_TOKEN } from '@spartacus/core';
import { Observable } from 'rxjs';
import { SearchTermFacetMappingHint } from '../../model/solr-search-config';
import { Occ } from '../../occ';
import { ConverterService } from '../../util';
import { SEARCH_HINT_NORMALIZER } from './converters';

@Injectable({
  providedIn: 'root',
})
export class SearchHintsConnector {
  constructor(
    private converterService: ConverterService,
    private httpClient: HttpClient,
    private occEndpointsService: OccEndpointsService
  ) {}

  load(userId: string): Observable<SearchTermFacetMappingHint[]> {
    let headers = new HttpHeaders();
    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }

    return this.httpClient
      .get<Occ.SearchTermFacetMappingHint[]>(
        this.occEndpointsService.buildUrl('searchHints', {
          urlParams: {
            userId: userId,
          },
        }),
        { headers }
      )
      .pipe(this.converterService.pipeableMany(SEARCH_HINT_NORMALIZER));
  }
}
