import { SearchAction, SearchActionTypes } from '../actions/search.actions';
import { SearchParamsState } from '../base-feature-state';

export const initialState: SearchParamsState = {
  key: undefined,
  page: 0,
  count: 10,
};

export function reducer(searchType: string): (state: SearchParamsState, action: SearchAction) => SearchParamsState {
  return (state = initialState, action: SearchAction): SearchParamsState => {
    if (searchType !== action.searchType) {
      return state;
    }
    switch (action.type) {
      case SearchActionTypes.SearchAction:
        return action.searchParams;
      default:
        return state;
    }
  };
}
