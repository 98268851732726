import { Component, Input } from '@angular/core';

@Component({
  selector: 'py-media-icon',
  templateUrl: './media-icon.component.html',
  styleUrls: ['./media-icon.component.scss'],
})
export class MediaIconComponent {
  constructor() {}

  @Input()
  url: string;

  @Input()
  type: string;

  @Input()
  theme: string = 'outline';

  @Input()
  target: string;

  @Input()
  label: string;

  @Input()
  tooltipText?: string;
}
