import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { IconThemeReverseModule } from '../../../../../shared';
import { CartMessageModalHeaderComponent } from './cart-message-modal-header.component';

@NgModule({
  declarations: [CartMessageModalHeaderComponent],
  imports: [CommonModule, NzIconModule, IconThemeReverseModule],
  exports: [CartMessageModalHeaderComponent],
})
export class CartMessageModalHeaderModule {}
