import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InterceptorUtil, OCC_USER_ID_ANONYMOUS, OccEndpointsService, USE_CLIENT_TOKEN } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CmsPublishedDateConnector {
  constructor(private httpClient: HttpClient, private occEndpointsService: OccEndpointsService) {}

  /**
   * Get publishedDate from api
   * @param userId the user id
   * @param label the cms-page label
   */
  get(userId, label: string): Observable<string> {
    let headers = new HttpHeaders();
    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }
    return this.httpClient
      .get<any>(
        this.occEndpointsService.buildUrl('publishedDate', {
          urlParams: {
            userId,
          },
          queryParams: {
            labels: label,
          },
        }),
        { headers }
      )
      .pipe(map((d) => d?.values[0]?.publishedDate));
  }
}
