import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { USER_BASE_FEATURE } from '../store/user-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: DataImportsFacade,
      feature: USER_BASE_FEATURE,
      async: true,
      methods: ['loadDataImportXlsReport', 'handleErrorWhileDownloadingErrorReport'],
    }),
})
export abstract class DataImportsFacade {
  abstract loadDataImportXlsReport(code: string): Observable<Blob>;
  abstract handleErrorWhileDownloadingErrorReport(): void;
}
