<ul>
  <ng-container *ngIf="category">
    <li py-megamenu-list-item [isDirectLink]="true">
      <cx-generic-link [url]="getUrl(category)">
        {{ 'navigation.viewAllInCategory_action' | cxTranslate : { category: category.name } }}
        <i nz-icon nzType="arrow-right"></i>
      </cx-generic-link>
    </li>
  </ng-container>
  <ng-container *ngFor="let subCategory of category?.subCategories || []">
    <li py-megamenu-list-item [showLinkArrow]="false">
      <cx-generic-link [url]="getUrl(subCategory)">
        {{ subCategory.name }}
      </cx-generic-link>
    </li>
  </ng-container>
</ul>
