import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserIdService } from '../../auth';
import { CmsPublishedDateConnector } from '../connectors';

@Injectable({ providedIn: 'root' })
export class CmsPublishedDateService {
  constructor(private cmsPublishedDateConnector: CmsPublishedDateConnector, private userIdService: UserIdService) {}

  get(label: string): Observable<string> {
    return this.userIdService.takeUserId().pipe(switchMap((userId) => this.cmsPublishedDateConnector.get(userId, label)));
  }
}
