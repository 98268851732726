import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AuthService, NotAuthGuard as CxNotAuthGuard } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SemanticPathService } from '../../../routing/configurable-routes/url-translation/semantic-path.service';

/**
 * Checks if there isn't any logged in user.
 * Use to protect pages dedicated only for guests (eg. login page).
 */
@Injectable({
  providedIn: 'root',
})
export class NotAuthGuard extends CxNotAuthGuard {
  constructor(protected authService: AuthService, protected semanticPathService: SemanticPathService, protected router: Router) {
    super(authService, semanticPathService, router);
  }

  canActivate(): Observable<boolean | UrlTree> {
    return this.authService.isUserLoggedIn().pipe(
      map((isLoggedIn) => {
        if (isLoggedIn) {
          return this.router.parseUrl(this.semanticPathService.get('dashboard'));
        }
        return !isLoggedIn;
      })
    );
  }
}
