import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export abstract class WhiteSpacesValidator {
  public static validator: ValidatorFn = (control: AbstractControl): ValidationErrors => {
    if (!control?.value) {
      return null;
    }

    const isWhitespace = (control.value.toString() || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  };
}
