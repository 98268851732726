import { Article, Unit } from '../../core/model';

export function resolveDefaultArticleUnit(article?: Article, useArticleUnitAsDefault: boolean = false): Unit | undefined {
  if (!article) {
    return undefined;
  }
  if (useArticleUnitAsDefault) {
    return article?.unit;
  }
  return (
    [...(article.units || [])]
      .sort((x, y) => x.minimumPurchaseQuantity - y.minimumPurchaseQuantity)
      .find(
        (u) =>
          (u.minimumPurchaseQuantity ? u.minimumPurchaseQuantity * u.inEcommerceUnit : u.inEcommerceUnit) ===
          article?.minimumOrderQuantity
      ) || article?.unit
  );
}
