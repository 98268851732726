import { StateUtils } from '@spartacus/core';
import { Category, CategoryRef } from '../../../../model';
import { AreaOfUseActions } from '../../../area-of-use/store/actions';
import { CategoryEntries } from '../../../store/catalog-state';

export const initialState: CategoryEntries = {
  areCategoriesEmpty: false,
  entities: undefined,
  tree: {},
  lastUpdateTime: undefined,
};

export function reducer(
  state = initialState,
  action: AreaOfUseActions.AreaOfUseActions | StateUtils.LoaderAction
): CategoryEntries {
  function unpackPayloadEntities(categories: Category[]): StateUtils.EntityState<Category> {
    return {
      entities: categories.reduce(
        (entities: { [id: string]: Category }, category: Category) => {
          return {
            ...entities,
            [category.code]: {
              ...category,
            },
          };
        },
        {
          // Always replace since this is loaded once
        }
      ),
    };
  }

  function getCategories(categories: Category[]): Category[] {
    const categoryList = new Array<Category>();
    categories.forEach((category) => {
      if (!!category.subCategories) {
        categoryList.push(...getCategories(category.subCategories));
      }
      categoryList.push(category);
    });
    return categoryList;
  }

  function unpackTree(categories: Category[]): { tree: { [level: string]: Array<CategoryRef> } } {
    return {
      tree: categories.reduce(
        (entities: { [id: string]: CategoryRef[] }, category: Category) => {
          if (!!entities[category.level]) {
            return {
              ...entities,
              [category.level]: [...entities[category.level], category.code],
            };
          } else {
            return {
              ...entities,
              [category.level]: [category.code],
            };
          }
        },
        {
          // Always replace since this is loaded once
        }
      ),
    };
  }

  switch (action.type) {
    case AreaOfUseActions.AreaOfUseActionTypes.LoadAreasOfUseFail: {
      // Just return state. Perhaps explicitly set tree to {}?
      return {
        ...state,
        lastUpdateTime: new Date().toISOString(),
      };
    }
    case AreaOfUseActions.AreaOfUseActionTypes.LoadAreasOfUseSuccess: {
      const categories = getCategories(action.payload);
      return {
        ...state,
        ...{ areCategoriesEmpty: !Boolean(categories?.length) },
        ...unpackPayloadEntities(categories),
        ...unpackTree(categories),
        lastUpdateTime: new Date().toISOString(),
      };
    }
  }
  return state;
}
