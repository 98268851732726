<ng-container *ngIf="!loading; else loadingTemplate">
  <py-notification *ngIf="showNotification" type="danger">
    <div class="content">
      <p>
        {{ (hasArticles ? 'cart.outOfStockAndReplace_text' : 'cart.outOfStockAndNoReplace_text') | cxTranslate }}
      </p>
      <button class="btn btn-primary btn-sm" [disabled]="!canDelete" (click)="onDelete()">
        {{ 'common.remove_action' | cxTranslate }}
      </button>
    </div>
  </py-notification>

  <ng-container *ngIf="hasArticles">
    <py-article-carousel
      class="mt-4"
      [actionLabel]="action"
      [carouselItems]="carouselItems || []"
      [disabled]="!canReplace"
      [loading]="!canReplace"
      [enableSecondaryArticleRowVariant]="enableSecondaryArticleRowVariant"
      [showPaginationAtTheBottom]="showPaginationAtTheBottom"
      [useDefaultArticleQuantityAndUnit]="useDefaultArticleQuantityAndUnit"
      [carouselActionMode]="carouselActionMode"
      [showAlternativeArticleBadge]="showAlternativeArticleBadge"
      (articleInteraction)="onReplace($event)"
    >
      <h3 *ngIf="showTitle">{{ 'cart.similarArticles_title' | cxTranslate }}</h3>
    </py-article-carousel>
  </ng-container>
</ng-container>

<ng-template #loadingTemplate>
  <div class="skeleton py-6"></div>
</ng-template>
