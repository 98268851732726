import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { MegamenuListItemComponent } from './megamenu-list-item.component';

@NgModule({
  imports: [CommonModule, NzIconModule],
  declarations: [MegamenuListItemComponent],
  exports: [MegamenuListItemComponent],
})
export class MegamenuListItemsModule {}
