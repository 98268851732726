import { InjectionToken } from '@angular/core';

export const ORDER_ENTRY_CONFIG = new InjectionToken<OrderEntryConfig>('OrderEntryConfig');

export interface OrderEntryConfig {
  selectable: boolean;
  deletable: boolean;
  enableQuantityControl: boolean;
  enableNoteControl: boolean;
  enableFullPalletNoteControl?: boolean;
  enableMandatoryNoteControlOption: boolean;
  enableAccountingCodes: boolean;
  enableStatisticsCodeControl: boolean;
  enableMandatoryStatisticsCodeControl: boolean;
  enableStockInfo: boolean;
  showPalletFlags: boolean;
  enableFullPalletUpgrade: boolean;
  showWarehouseName: boolean;
  outOfStockAction?: string;
  enableNameMarking?: boolean;
  showNameMarkingButton?: boolean;
  enableCutting?: boolean;
  showCuttingButton?: boolean;
  showCuttingPerpendicular?: boolean;
  showCuttingDustFree?: boolean;
  enableReelCutting?: boolean;
  showReelCuttingButton?: boolean;
  enableReaming?: boolean;
  showReamingButton?: boolean;
  showOrderReturnEntryBadge?: boolean;
  loadAndShowArticlePriceInsteadOfEntryPrice?: boolean;
  translationKeyPrefix?: string; // Should be "cart" or "checkout" or something like that.
  enablePrePrintedLabels?: boolean;
  showPrePrintedLabelsButton?: boolean;
}
