import { MemoizedSelector, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { EntriesState, PaginationState, SearchPageResultState, SearchState } from '../../../features/store/base-feature-state';
import { AccountingCode, AccountingCodeUserInfo, Pagination, SearchParams } from '../../../model';
import { AccountingCodeState, AccountingCodesAdminState, StateWithUser, UserState } from '../user-state';
import { getUserState } from './feature.selector';

export const getAccountingCodeState: MemoizedSelector<StateWithUser, AccountingCodeState> = createSelector(
  getUserState,
  (state: UserState) => state && state.accountingCode
);

export const getAccountingCodesAdminState: MemoizedSelector<StateWithUser, AccountingCodesAdminState> = createSelector(
  getAccountingCodeState,
  (state: AccountingCodeState) => state.accountingCodesAdmin || undefined
);

export const getAdminSearchResultEntities: MemoizedSelector<StateWithUser, EntriesState<AccountingCode> | undefined> =
  createSelector(
    getAccountingCodesAdminState,
    (state: AccountingCodesAdminState) => (state && state.searchEntities) || undefined
  );

export const getAdminSearchSelector: MemoizedSelector<StateWithUser, SearchState<SearchPageResultState> | undefined> =
  createSelector(getAccountingCodesAdminState, (state: AccountingCodesAdminState) => (state && state.search) || undefined);

export const getAdminSearchPaginationStateSelector = (
  searchParams: SearchParams
): MemoizedSelector<StateWithUser, PaginationState<SearchPageResultState> | undefined> =>
  createSelector(getAdminSearchSelector, (state: SearchState<SearchPageResultState>) => state[searchParams.key] || undefined);

export const getAdminSearchPaginationSelector = (
  searchParams: SearchParams
): MemoizedSelector<StateWithUser, Pagination | undefined> =>
  createSelector(getAdminSearchPaginationStateSelector(searchParams), (state: PaginationState<SearchPageResultState>) => {
    return state?.pagination;
  });

export const getSearchPageResultsLoading = (searchParams: SearchParams): MemoizedSelector<StateWithUser, boolean> =>
  createSelector(
    getAdminSearchPageResultsSelectorFactory(searchParams),
    (loaderState) => (loaderState && StateUtils.loaderLoadingSelector(loaderState)) || false
  );

export const getAdminSearchPageResultsSelectorFactory = (
  searchParams: SearchParams
): MemoizedSelector<StateWithUser, StateUtils.LoaderState<SearchPageResultState> | undefined> =>
  createSelector(
    getAdminSearchPaginationStateSelector(searchParams),
    (paginationState: PaginationState<SearchPageResultState>) => {
      return (searchParams && paginationState && paginationState.pages && paginationState.pages[searchParams.page]) || undefined;
    }
  );

export const getAdminSearchResultFactory = (
  searchParams: SearchParams
): MemoizedSelector<StateWithUser, SearchPageResultState | undefined> =>
  createSelector(
    getAdminSearchPageResultsSelectorFactory(searchParams),
    (loaderState: StateUtils.LoaderState<SearchPageResultState>) => {
      return (loaderState && StateUtils.loaderValueSelector(loaderState)) || undefined;
    }
  );

export const getAdminSearchResultPage = (
  searchParams: SearchParams
): MemoizedSelector<StateWithUser, Array<string> | undefined> =>
  createSelector(getAdminSearchResultFactory(searchParams), (state: SearchPageResultState) => {
    return (state && state.results) || undefined;
  });

export const getAdminSearchResultEntries = (
  searchParams: SearchParams
): MemoizedSelector<StateWithUser, AccountingCode[] | undefined> =>
  createSelector(
    getAdminSearchResultPage(searchParams),
    getAdminSearchResultEntities,
    (codes: Array<string>, entries: EntriesState<AccountingCode>) => {
      return (codes && entries && codes.map((i) => entries[i])) || undefined;
    }
  );

export const getAccountingCodeUserInfo: MemoizedSelector<StateWithUser, AccountingCodeUserInfo | undefined> = createSelector(
  getAccountingCodesAdminState,
  (state: AccountingCodesAdminState) => (state && state.info) || undefined
);
