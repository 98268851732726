import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ArticleService, ProductService } from '../../../../core/catalog';
import {
  Article,
  Category,
  DebugKey,
  DiscontinuedArticleViewType,
  EntityMap,
  Product,
  SearchTermFacetMappingHint,
  SolrResultEntityRef,
  SolrSearchResult,
  SubstituteRef,
} from '../../../../core/model';
import { DebugFacade } from '../../../../core/user';
import { CatalogTabTypes } from '../../../../features/catalog/container/catalog/catalog-search.service';

@Component({
  selector: 'py-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultsComponent implements OnInit, OnDestroy {
  @Input() searchFormValue: string;
  @Input() searchHints: SearchTermFacetMappingHint[];
  @Input() discontinuedArticle: Article;
  @Input() hasDiscontinuedArticle: boolean;
  @Input() discontinuedArticleViewType: DiscontinuedArticleViewType;
  @Input() substituteRefs: SubstituteRef[] = [];
  @Input() form: UntypedFormGroup;
  @Input() enableTogglingOfCustomerAssortment: boolean;
  @Input() searchLoading: boolean;
  @Input() searchLoaded: boolean;
  @Input() substituteRefsLoading: boolean;
  @Input() substituteRefsLoaded: boolean;
  @Input() searchResults: SolrSearchResult;
  @Input() searchResultsSecondaryVariant: boolean;
  @Input() catalogItemQueryParam: EntityMap<string>;
  @Input() articleResultRefs: SolrResultEntityRef[];
  @Input() products$: Observable<Product>[];
  @Input() categories: Category[];
  @Input() activeTab: CatalogTabTypes;
  @Input() initialActiveTab: CatalogTabTypes;
  @Input() minSearchLength: number;
  @Input() userInputSearchInProgress: boolean;
  @Output() discontinuedArticleSubmit = new EventEmitter<void>();
  @Output() submitSearch = new EventEmitter<void>();
  @Output() selectTab = new EventEmitter<CatalogTabTypes>();

  discontinuedArticleViewTypes: typeof DiscontinuedArticleViewType = DiscontinuedArticleViewType;
  private subscriptions = new Subscription();
  private isSuggestionsEnabled: boolean;

  get searchTextLengthSufficient(): boolean {
    return this.searchFormValue?.length >= this.minSearchLength;
  }

  get areSearchResultsLoading(): boolean {
    return (this.searchLoading && !this.searchLoaded) || this.userInputSearchInProgress;
  }

  get areDiscontinuedArticleSubstitutesLoading(): boolean {
    return (this.substituteRefsLoading && !this.substituteRefsLoaded) || this.userInputSearchInProgress;
  }

  get showDiscontinuedArticleSection(): boolean {
    return (
      this.hasDiscontinuedArticle &&
      this.substituteRefs.length > 0 &&
      !this.userInputSearchInProgress &&
      this.searchTextLengthSufficient
    );
  }

  get showSearchResultsContentPrimarySection(): boolean {
    return (
      !this.searchResultsSecondaryVariant &&
      !this.hasDiscontinuedArticle &&
      !this.areSearchResultsLoading &&
      this.searchTextLengthSufficient
    );
  }

  get showLoadingSectionForPrimarySearchResults(): boolean {
    return (
      ((!this.hasDiscontinuedArticle && this.areSearchResultsLoading) ||
        (this.hasDiscontinuedArticle && this.areDiscontinuedArticleSubstitutesLoading)) &&
      !this.searchResultsSecondaryVariant &&
      this.searchTextLengthSufficient
    );
  }

  get isSearchResultsContentSecondaryLoading(): boolean {
    return (
      ((!this.hasDiscontinuedArticle && this.areSearchResultsLoading) ||
        (this.hasDiscontinuedArticle && this.areDiscontinuedArticleSubstitutesLoading)) &&
      this.searchTextLengthSufficient
    );
  }

  get showResultsForSearchResultsContentSecondary(): boolean {
    return !this.hasDiscontinuedArticle && !this.areSearchResultsLoading && this.searchTextLengthSufficient;
  }

  get showNoResultsSection(): boolean {
    return (
      ((!this.searchLoading &&
        this.searchLoaded &&
        this.searchResults?.articleResultRefs?.length === 0 &&
        this.searchResults?.productRefs?.length === 0) ||
        (!this.substituteRefsLoading &&
          this.substituteRefsLoaded &&
          this.hasDiscontinuedArticle &&
          this.substituteRefs.length === 0)) &&
      this.searchTextLengthSufficient &&
      !this.userInputSearchInProgress
    );
  }

  get showSpellcheck(): boolean {
    return this.searchResults?.suggestions?.spellcheckCollations?.length > 0;
  }

  get spellcheckSuggestion(): string {
    return this.searchResults?.suggestions?.spellcheckCollations?.[0]?.value;
  }

  get showSuggestions(): boolean {
    return this.searchResults?.suggestions?.suggestions?.length > 0;
  }

  get suggestions(): string[] {
    return this.searchResults?.suggestions?.suggestions?.map(({ value }) => value);
  }

  get showSuggestionsSection(): boolean {
    return this.isSuggestionsEnabled && (this.showSpellcheck || this.showSuggestions);
  }

  constructor(
    private articleService: ArticleService,
    private productService: ProductService,
    private debugService: DebugFacade
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.debugService
        .isDebugFeatureEnabled(DebugKey.SEARCH_SUGGESTIONS)
        .subscribe((isSuggestionsEnabled) => (this.isSuggestionsEnabled = isSuggestionsEnabled))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onDiscontinuedArticleSubmit(): void {
    this.discontinuedArticleSubmit.emit();
  }

  getArticlesCodesFromSubstitutes(substituteRefs: SubstituteRef[]): string[] {
    return this.articleService.getArticlesCodesFromSubstitutes(substituteRefs);
  }

  getProductsFromSubstitutes(substituteRefs: SubstituteRef[]): Observable<Product>[] {
    return this.articleService
      .getProductsCodesFromSubstitutes(substituteRefs)
      .map((code) => this.productService.getProduct(code));
  }

  onSubmit(): void {
    this.submitSearch.emit();
  }
}
