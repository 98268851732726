import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { LoginLogoutActionTypes } from '../../../../auth/user-auth/store/actions/auth-group.actions';
import { normalizeHttpError, withdrawOn } from '../../../../util';
import { AddonGroupConnector } from '../../connector/addon-group-connector';
import { AddonGroupsActions } from '../actions';
import { AddonGroupActionTypes } from '../actions/addon-group.action';

@Injectable({ providedIn: 'root' })
export class AddonGroupEffects {
  private contextChange$ = this.actions$.pipe(ofType(LoginLogoutActionTypes.Logout));

  loadAddonGroups$: Observable<AddonGroupsActions.LoadAddonGroupsSuccess | AddonGroupsActions.LoadAddonGroupsFail> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AddonGroupActionTypes.LoadAddonGroups),
        mergeMap((action: AddonGroupsActions.LoadAddonGroups) => {
          return this.connector.loadAddonGroups(action.userId).pipe(
            map((data) => new AddonGroupsActions.LoadAddonGroupsSuccess(data)),
            catchError((error) => of(new AddonGroupsActions.LoadAddonGroupsFail(normalizeHttpError(error))))
          );
        }),
        withdrawOn(this.contextChange$)
      )
  );

  loadAddonGroup$: Observable<AddonGroupsActions.LoadAddonGroupSuccess | AddonGroupsActions.LoadAddonGroupFail> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AddonGroupActionTypes.LoadAddonGroup),
        switchMap((action: AddonGroupsActions.LoadAddonGroup) => {
          return this.connector.loadAddonGroup(action.userId, action.addonGroupId, action.sort).pipe(
            map((data) => new AddonGroupsActions.LoadAddonGroupSuccess({ ...data, sort: action.sort })),
            catchError((error) => of(new AddonGroupsActions.LoadAddonGroupFail(normalizeHttpError(error))))
          );
        }),
        withdrawOn(this.contextChange$)
      )
  );

  constructor(private actions$: Actions, private connector: AddonGroupConnector) {}
}
