import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CONFIG_INITIALIZER, WithCredentialsInterceptor, occConfigValidator, provideConfigValidator } from '@spartacus/core';
import { provideDefaultConfig } from '../config/config-providers';
import { AsmOccModule } from './adapters/asm/asm-occ.module';
import { CmsOccModule } from './adapters/cms/cms-occ.module';
import { SiteContextOccModule } from './adapters/site-context/site-context-occ.module';
import { OccConfigInitializer } from './config/config-loader/occ-config-initializer';
import { defaultOccConfig } from './config/default-occ-config';
import { DetectNewDeployInterceptor } from './interceptors';

@NgModule({
  imports: [AsmOccModule, CmsOccModule, SiteContextOccModule],
})
export class OccModule {
  static forRoot(): ModuleWithProviders<OccModule> {
    return {
      ngModule: OccModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useExisting: WithCredentialsInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useExisting: DetectNewDeployInterceptor,
          multi: true,
        },
        {
          provide: CONFIG_INITIALIZER,
          useFactory: (occConfigInitializer: OccConfigInitializer) => occConfigInitializer,
          deps: [OccConfigInitializer],
          multi: true,
        },
        provideDefaultConfig(defaultOccConfig),
        provideConfigValidator(occConfigValidator),
      ],
    };
  }
}
