import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../core/i18n';
import { IconThemeReverseModule } from '../../directives';
import { SpinnerModule } from '../spinner';
import { DownloadDropdownComponent } from './download-dropdown.component';

@NgModule({
  declarations: [DownloadDropdownComponent],
  imports: [CommonModule, NgbPopoverModule, NzIconModule, I18nModule, SpinnerModule, IconThemeReverseModule],
  exports: [DownloadDropdownComponent],
})
export class DownloadDropdownModule {}
