<div class="wrapper" *ngIf="showButton; else textTemplate">
  <div class="label" [class.disabled]="disabled" *ngIf="hasPrePrintedLabel">
    {{ translationKeyPrefix + '.prePrintedLabel.prePrintedButton_heading' | cxTranslate }}
  </div>

  <button
    (click)="open()"
    [disabled]="disabled"
    class="add-or-edit-button px-2"
    [ngClass]="{
      add: !hasPrePrintedLabel,
      edit: !!hasPrePrintedLabel,
      'px-2': !hasPrePrintedLabel,
      'p-2': !!hasPrePrintedLabel
    }"
  >
    <i nz-icon nzType="tags" nzTheme="fill" class="mr-2" [class.mt-1]="!!hasPrePrintedLabel"></i>
    <div class="d-flex flex-column w-100">
      <div class="d-flex flex-row justify-content-between w-100">
        <ng-container [ngTemplateOutlet]="buttonLabel"></ng-container>
        <div
          class="icon-holder edit"
          *ngIf="!disabled && !!hasPrePrintedLabel"
          [ngbTooltip]="translationKeyPrefix + '.prePrintedLabel.editCutting_tooltip' | cxTranslate"
        >
          <i nz-icon nzType="edit" nzTheme="outline"></i>
        </div>
      </div>
      <ng-container [ngTemplateOutlet]="cuttingDataTemplate"></ng-container>
    </div>
  </button>
</div>

<ng-template #textTemplate>
  <div class="text-wrapper d-flex align-items-start" *ngIf="!!hasPrePrintedLabel">
    <i nz-icon nzType="tags" nzTheme="fill" class="mx-2"></i>
    <div class="d-flex flex-column w-100">
      <span class="text-label">{{ translationKeyPrefix + '.prePrintedLabel.prePrintedButton_heading' | cxTranslate }}</span>
      <ng-container [ngTemplateOutlet]="buttonValueLabel"></ng-container>
      <ng-container [ngTemplateOutlet]="cuttingDataTemplate"></ng-container>
    </div></div
></ng-template>

<ng-template #cuttingDataTemplate>
  <ul *ngIf="!!prePrintedLabel?.part || prePrintedLabel?.innerPackageQuantity > 0" class="mt-1">
    <li class="d-flex justify-content-between align-items-center" *ngIf="!!prePrintedLabel?.part">
      <span>{{ translationKeyPrefix + '.prePrintedLabel.partNumber_heading' | cxTranslate }}</span>
      <span>{{ prePrintedLabel?.part }} </span>
    </li>
    <li class="d-flex justify-content-between align-items-center" *ngIf="prePrintedLabel?.innerPackageQuantity > 0">
      <span>{{ translationKeyPrefix + '.prePrintedLabel.innerPackageQuantity_heading' | cxTranslate }}</span>
      <span>{{ prePrintedLabel?.innerPackageQuantity }} </span>
    </li>
  </ul>
</ng-template>

<ng-template #buttonLabel>
  <span *ngIf="!hasPrePrintedLabel; else buttonValueLabel">
    {{ translationKeyPrefix + '.prePrintedLabel.addPrePrintedButton_action' | cxTranslate }}
  </span>
</ng-template>

<ng-template #buttonValueLabel>
  <span class="text-left mt-1">{{ prePrintedLabel?.label }}</span>
</ng-template>
