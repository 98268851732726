import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subscription, combineLatest, tap } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { OrderCard, OrderEntry, Pagination } from '../../../core/model';
import { OrderCardsAdminFacade } from '../../../core/user';
import { infinitelyScrolling } from '../../utils/infinitely-scrolling';

@Component({
  selector: 'py-add-to-order-card',
  templateUrl: './add-to-order-card.component.html',
  styleUrls: ['./add-to-order-card.component.scss'],
})
export class AddToOrderCardComponent implements OnInit, OnDestroy {
  @ViewChild('popOver', { static: true }) popover: NgbPopover;

  params = { key: 'latest', page: 0, count: 10 };

  @Input() placement?: string[] | string = 'auto';
  @Input() productCode: string;
  @Input() entries: OrderEntry[];
  @Input() disabled: boolean;
  @Input() showButton?: boolean = false;
  @Input() disableTooltip?: boolean = false;

  public orderCards$: Observable<OrderCard[]>;
  public pagination$: Observable<Pagination>;
  public loading$: Observable<boolean>;

  private subscriptions = new Subscription();
  currentPage$ = new BehaviorSubject<number>(0);

  subscription = new Subscription();

  constructor(private orderCardsAdminService: OrderCardsAdminFacade) {}

  ngOnInit() {
    const page$: Observable<number> = combineLatest([this.currentPage$, this.popover.shown]).pipe(
      filter(([_currentPage, _popoverShown]) => Boolean(this.popover.isOpen())),
      map(([currentPage, _popoverShown]) => currentPage),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.subscription.add(
      page$.subscribe((currentPage) => {
        this.orderCardsAdminService.searchOrderCards({ ...this.params, page: currentPage });
      })
    );

    const orderCards$ = page$.pipe(
      switchMap((currentPage) => this.orderCardsAdminService.getOrderCardsSearchResult({ ...this.params, page: currentPage }))
    );

    this.orderCards$ = infinitelyScrolling(orderCards$, page$, 'code').pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.pagination$ = page$.pipe(
      switchMap((currentPage) => this.orderCardsAdminService.getSearchResultPagination({ ...this.params, page: currentPage }))
    );

    this.loading$ = page$.pipe(
      switchMap((currentPage) => this.orderCardsAdminService.getSearchPageResultsLoading({ ...this.params, page: currentPage }))
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onScroll(nextPage: number): void {
    this.currentPage$.next(nextPage);
  }

  addProduct(orderCardCode: string) {
    this.orderCardsAdminService.addOrderCardProduct(orderCardCode, this.productCode);
    this.popover.close();
  }

  addProductToNewOrderCard() {
    this.subscription.add(
      this.orderCardsAdminService
        .createOrderCard()
        .pipe(
          switchMap((tempId) =>
            this.orderCardsAdminService.createOrderCardsWithTempId().pipe(
              tap((orderCards) => {
                const newOrderCardSuccess = orderCards.find((o) => o.tempId === tempId);
                if (newOrderCardSuccess) {
                  this.orderCardsAdminService.addOrderCardProduct(newOrderCardSuccess.code, this.productCode);
                }
                this.popover.close();
              })
            )
          )
        )
        .subscribe()
    );
  }

  addArticlesToNewOrderCard() {
    this.subscription.add(
      this.orderCardsAdminService
        .createOrderCard()
        .pipe(
          switchMap((tempId) =>
            this.orderCardsAdminService.createOrderCardsWithTempId().pipe(
              tap((orderCards) => {
                const newOrderCardSuccess = orderCards.find((o) => o.tempId === tempId);
                if (newOrderCardSuccess) {
                  this.orderCardsAdminService.addOrderCardArticles(
                    newOrderCardSuccess.code,
                    this.entries?.map((entry) => entry.articleRef)
                  );
                }
                this.popover.close();
              })
            )
          )
        )
        .subscribe()
    );
  }

  addArticles(orderCardCode: string) {
    this.orderCardsAdminService.addOrderCardArticles(
      orderCardCode,
      this.entries?.map((entry) => entry.articleRef)
    );
    this.popover.close();
  }

  initializeDropdown() {
    this.onScroll(0);
  }
}
