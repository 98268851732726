import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ANONYMOUS_CONSENTS_HEADER,
  ANONYMOUS_CONSENT_NORMALIZER,
  AnonymousConsent,
  CONSENT_TEMPLATE_NORMALIZER,
  OccEndpointsService,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConsentTemplate } from '../../model';
import { Occ } from '../../occ';
import { ConverterService } from '../../util';

@Injectable({ providedIn: 'root' })
export class AnonymousConsentTemplatesConnector {
  constructor(protected http: HttpClient, protected occEndpoints: OccEndpointsService, protected converter: ConverterService) {}

  loadAnonymousConsentTemplates(): Observable<ConsentTemplate[]> {
    const url = this.occEndpoints.buildUrl('anonymousConsentTemplates');
    return this.http.get<Occ.ConsentTemplateList>(url).pipe(
      map((consentList) => consentList.consentTemplates),
      this.converter.pipeableMany(CONSENT_TEMPLATE_NORMALIZER)
    );
  }

  loadAnonymousConsents(): Observable<AnonymousConsent[]> {
    // using the endpoint that doesn't set caching headers
    const url = this.occEndpoints.buildUrl('anonymousConsentTemplates');
    return this.http.head<Occ.ConsentTemplateList>(url, { observe: 'response' }).pipe(
      map((response) => response.headers.get(ANONYMOUS_CONSENTS_HEADER)),
      this.converter.pipeable(ANONYMOUS_CONSENT_NORMALIZER)
    );
  }
}
