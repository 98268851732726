<div class="container">
  <div class="row mb-6">
    <div class="col-12 d-flex justify-content-between align-items-baseline">
      <h4 class="settings-title">{{ 'anonymousConsents.consentsPreferences_title' | cxTranslate }}</h4>
      <div *ngIf="showCloseButton" class="close-settings" (click)="onClose()">
        <i nz-icon nzType="close" nzTheme="outline"></i>
      </div>
    </div>
  </div>

  <div class="row no-gutters scroll-body">
    <ng-container *ngFor="let consentTemplate of consentTemplates">
      <div class="row mt-4 no-gutters">
        <div class="col-12 d-flex flex-column border-bottom">
          <div class="d-flex">
            <py-reactive-form-checkbox-slider
              [group]="form"
              [addMargin]="false"
              fieldName="{{ consentTemplate.id }}"
            ></py-reactive-form-checkbox-slider>
            <div class="px-3">
              <h5 class="consent-title" [innerHTML]="consentTemplate.name"></h5>
            </div>
          </div>
          <div class="py-3">
            <p class="consent-description">{{ consentTemplate.description }}</p>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="row mt-4 mb-2">
      <div class="col-12 d-flex">
        <p class="settings-ingress" [innerHTML]="'anonymousConsents.ingress_text' | cxTranslate"></p>
      </div>
    </div>
    <div class="col-12 d-flex w-100 scroll-shadow"></div>
  </div>

  <div class="row mt-4">
    <div class="col-12 d-flex justify-content-end flex-column-reverse flex-md-row">
      <button *ngIf="!showCloseButton" class="btn btn-plain-secondary mr-0 mr-md-2" (click)="onBack()">
        {{ 'anonymousConsents.back_action' | cxTranslate }}
      </button>

      <button class="btn btn-secondary mb-2 mb-md-0" [class.mb-2]="!showCloseButton" (click)="onDone()">
        {{ 'anonymousConsents.done_action' | cxTranslate }}
      </button>
    </div>
  </div>
</div>
