<div ngbDropdown #myDrop="ngbDropdown" (openChange)="onOpenChange($event)" [container]="container" [placement]="placement">
  <div class="overlay d-lg-none" [class.show]="!isCollapsed"></div>
  <button
    type="button"
    [attr.id]="id"
    ngbDropdownToggle
    class="form-control dropdown-button"
    [class.show-active-item]="showActiveItem"
    [disabled]="disabled || readonly"
  >
    <span class="label mr-2" [class.placeholder]="showActiveItem && !activeItem?.label">
      <span
        class="d-flex align-items-center"
        *ngIf="showActiveItem && (activeItem || hasInvisibleActiveItems); else titleTemplate"
      >
        <div *ngIf="hasConstantVisibleLabel && (placeholder || activeItem); else volatileLabel" class="constant-label text-clip">
          <span class="placeholder text-clip">{{ placeholder }}</span>
          <span class="active-item text-clip">{{ activeItem?.labelActive || activeItem?.label }}</span>
        </div>

        <ng-template #volatileLabel>
          <ng-container *ngIf="activeItem">
            <span *ngIf="heading" class="heading text-clip mr-2">{{ heading }}</span>
            <span class="text-clip">
              <i *ngIf="!!titleIcon" nz-icon class="mr-1" [nzType]="titleIcon" nzTheme="outline"></i>
              {{ activeItem?.labelActive || activeItem?.label || placeholder }}
            </span>
            <span *ngIf="showCountBadge && multi && valueLength > 1" class="badge badge-count badge-pill ml-1 font-weight-bold">
              +{{ valueLength - 1 }}
            </span>
          </ng-container>
          <ng-container *ngIf="hasInvisibleActiveItems">
            <span class="text-clip">{{ 'common.selectedItems_hint' | cxTranslate }}</span>
            <span class="badge badge-count badge-pill ml-1 font-weight-bold">{{ valueLength }}</span>
          </ng-container>
        </ng-template>
      </span>
      <ng-template #titleTemplate>
        <span>
          <i *ngIf="!!titleIcon" nz-icon class="mr-1" [nzType]="titleIcon" nzTheme="outline"></i>
          {{ title }}{{ activeItem?.label && !multi ? ': ' + activeItem?.label : '' }}
        </span>
      </ng-template>

      <ng-content></ng-content>
    </span>
    <py-badge *ngIf="badge" [tag]="true" [ngbTooltip]="badgeTooltip">{{ badge }}</py-badge>
    <i
      nz-icon
      nzType="{{ icon ? icon : isCollapsed ? 'down' : 'up' }}"
      nzTheme="outline"
      [class.ml-2]="showActiveItem"
      [class.mr-2]="!showActiveItem"
    ></i>
  </button>
  <div
    ngbDropdownMenu
    [attr.aria-labelledby]="id"
    class="py-0"
    infiniteScroll
    (scrolled)="onScrolled()"
    [scrollWindow]="false"
    [class.dropdown-menu-multicolumn]="additionalColumns > 0"
    [class.body-dropdown]="container"
  >
    <py-reactive-form-input-field
      *ngIf="searchable"
      [group]="form"
      [fieldName]="'searchInput'"
      [placeholder]="'common.filter_placeholder' | cxTranslate"
      [icon]="'search'"
      [resettable]="true"
      (handleReset)="onResetSearchInput()"
      class="search-input"
    ></py-reactive-form-input-field>
    <ng-container *ngIf="!multi; else multiList">
      <div class="d-lg-none header">
        <span>
          <i *ngIf="!!titleIcon" nz-icon class="mr-1" [nzType]="titleIcon" nzTheme="outline"></i>
          {{ showTitleForMobile ? (title ? title : activeItem?.label) : activeItem?.label || title || placeholder }}
        </span>
        <button class="px-2" type="button" (click)="myDrop.close()">
          <i nz-icon pyIconThemeReverse nzType="close-circle" nzTheme="outline"></i>
        </button>
      </div>
      <button ngbDropdownItem *ngIf="resetable && (options?.length > 0 || searchable)" class="reset" (click)="resetValue.emit()">
        {{ resetLabel ? resetLabel : ('common.clearAll_action' | cxTranslate) }}
      </button>
      <ul *ngIf="additionalColumns === 0; else tableList">
        <li
          ngbDropdownItem
          *ngFor="let option of options"
          class="hover d-flex align-items-center"
          [class.active]="option.value === activeItem?.value"
          [class.disabled]="option.disabled"
          (click)="updateValue(option)"
        >
          <py-radio>
            <input
              [disabled]="option.disabled"
              [id]="'option-' + option.value | randomId"
              type="radio"
              [checked]="option.value === activeItem?.value"
              (change)="updateValue(option); myDrop.close()"
            />
          </py-radio>
          <div class="d-flex justify-content-between align-items-center ml-3 w-100">
            <span class="label" [class.small]="option.description">{{ option.label }}</span>
            <span *ngIf="option.description" class="description ml-2">{{ option.description }}</span>
            <span *ngIf="!option.description && option.disabled" class="ml-2">
              {{ 'common.notApplicable_hint' | cxTranslate }}
            </span>
          </div>
        </li>
      </ul>
    </ng-container>
    <ng-container *ngTemplateOutlet="templateLoadMore"></ng-container>
    <div class="py-2 text-center spinner-wrapper" *ngIf="loading">
      <cx-spinner></cx-spinner>
    </div>
    <div *ngIf="searchable && !loading && options.length === 0" class="p-2 text-center">
      {{ 'common.noResultsFound_hint' | cxTranslate }}
    </div>
    <div *ngIf="unselectableOption" class="d-flex justify-content-between align-items-center unselectable-option">
      <span class="label small">{{ unselectableOption.key }}</span>
      <span class="description ml-2">{{ unselectableOption.value }}</span>
    </div>
  </div>
</div>

<ng-template #multiList>
  <div class="d-lg-none header justify-content-between">
    <span>{{ title ? title : ('common.select_action' | cxTranslate) }}</span>
    <button class="px-2" type="button" (click)="myDrop.close()">
      <i nz-icon pyIconThemeReverse nzType="close-circle" nzTheme="outline"></i>
    </button>
  </div>
  <button *ngIf="resetable && (options?.length > 0 || searchable)" class="reset" (click)="resetValue.emit()">
    {{ 'common.clearAll_action' | cxTranslate }}
  </button>
  <ul *ngIf="additionalColumns === 0; else tableList">
    <li
      *ngFor="let option of options"
      [class.active]="isActive(option.value)"
      [class.disabled]="option.disabled"
      class="hover d-flex align-items-center"
      (click)="updateMultiValue(option)"
    >
      <py-checkbox>
        <input
          type="checkbox"
          [disabled]="option.disabled"
          [checked]="isActive(option.value)"
          (change)="updateMultiValue(option)"
        />
      </py-checkbox>
      <div class="d-flex flex-column ml-3 w-100">
        <span class="d-flex align-items-center">
          <span class="label">{{ option.label }}</span>
          <span *ngIf="option.disabled" class="ml-2">{{ 'common.notApplicable_hint' | cxTranslate }}</span>
        </span>
        <span *ngIf="option.description" class="description">{{ option.description }}</span>
      </div>
    </li>
    <li *ngIf="allOption && options.length > 1">
      <ng-container *ngIf="options.length === valueLength; else deselect">
        <ng-container *ngTemplateOutlet="deselectAll"></ng-container>
      </ng-container>
      <ng-template #deselect>
        <ng-container *ngTemplateOutlet="selectAll"></ng-container>
      </ng-template>
    </li>
  </ul>
</ng-template>

<ng-template #tableList>
  <table>
    <tbody>
      <ng-container *ngIf="multi; else singleChoiceTable">
        <tr
          *ngFor="let option of options"
          [class.disabled]="option.disabled"
          [class.active]="isActive(option.value)"
          class="hover"
          (click)="updateMultiValue(option)"
        >
          <ng-container *ngIf="multi">
            <td class="checkbox-cell" *ngIf="!option.disabled">
              <py-checkbox>
                <input
                  type="checkbox"
                  [checked]="isActive(option.value)"
                  [disabled]="option.disabled"
                  (change)="updateMultiValue(option)"
                />
              </py-checkbox>
            </td>
            <td *ngIf="option.disabled">
              <span>{{ 'common.notApplicable_hint' | cxTranslate }}</span>
            </td>
          </ng-container>
          <td>
            <span class="label">{{ option.label }}</span>
          </td>
          <ng-container>
            <td *ngFor="let e of [].constructor(additionalColumns); let i = index">
              <span *ngIf="option.additionalLabels" class="label">{{ option.additionalLabels[i] }}</span>
            </td>
          </ng-container>
        </tr>
        <tr *ngIf="allOption && options.length > 1">
          <td>
            <ng-container *ngIf="options.length === valueLength; else deselect">
              <ng-container *ngTemplateOutlet="deselectAll"></ng-container>
            </ng-container>
            <ng-template #deselect>
              <ng-container *ngTemplateOutlet="selectAll"></ng-container>
            </ng-template>
          </td>
        </tr>
      </ng-container>
      <ng-template #singleChoiceTable>
        <tr
          *ngFor="let option of options"
          [class.disabled]="option.disabled"
          [class.active]="option.value === activeItem?.value"
          class="hover"
          (click)="updateValue(option); myDrop.close()"
        >
          <td>
            <span class="label">{{ option.label }}</span>
          </td>
          <ng-container>
            <td *ngFor="let e of [].constructor(additionalColumns); let i = index">
              <span *ngIf="option.additionalLabels" class="label">{{ option.additionalLabels[i] }}</span>
            </td>
          </ng-container>
        </tr>
      </ng-template>
    </tbody>
  </table>
</ng-template>

<ng-template #templateLoadMore>
  <py-load-more
    *ngIf="loadMore?.pagination"
    class="p-4 d-block"
    [totalItemCount]="loadMore.pagination?.totalCount"
    [totalPageCount]="loadMore.pagination?.totalPages"
    [currentPageNumber]="loadMore.page"
    [itemCount]="options?.length || 0"
    (dispatch)="onNextPage()"
    [loading]="loadMore.loading"
    [totalItemCountName]="loadMore.totalItemCountName"
  ></py-load-more
></ng-template>

<ng-template #selectAll>
  <button class="btn btn-icon text-black d-inline-flex align-items-center p-0" type="button" (click)="toggleAll()">
    <i nz-icon nzType="plus" nzTheme="outline" class="w-auto h-auto mr-2"></i>
    <span class="label pl-1">{{ 'common.selectAll_action' | cxTranslate }}</span>
  </button>
</ng-template>

<ng-template #deselectAll>
  <button class="btn btn-icon text-black d-inline-flex align-items-center p-0" type="button" (click)="toggleAll()">
    <i nz-icon nzType="minus" nzTheme="outline" class="w-auto h-auto mr-2"></i>
    <span class="label pl-1">{{ 'common.clearSelection_action' | cxTranslate }}</span>
  </button>
</ng-template>
