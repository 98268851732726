import { Provider } from '@angular/core';
import { BASE_SITE_CONTEXT_ID, CURRENCY_CONTEXT_ID, ContextServiceMap, LANGUAGE_CONTEXT_ID } from '@spartacus/core';
import { BaseSiteService } from '../facade/base-site.service';
import { CurrencyService } from '../facade/currency.service';
import { LanguageService } from '../facade/language.service';
import { SitePrefixService } from '../facade/site-prefix.service';
import { SITE_PREFIX_CONTEXT_ID } from './context-ids';

export function serviceMapFactory() {
  return {
    [LANGUAGE_CONTEXT_ID]: LanguageService,
    [CURRENCY_CONTEXT_ID]: CurrencyService,
    [BASE_SITE_CONTEXT_ID]: BaseSiteService,
    [SITE_PREFIX_CONTEXT_ID]: SitePrefixService,
  };
}

export const contextServiceMapProvider: Provider = {
  provide: ContextServiceMap,
  useFactory: serviceMapFactory,
};
