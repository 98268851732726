import { InjectionToken } from '@angular/core';
import { B2BPermissionOrderListItem, EntitiesModel } from '../../../model';
import { Occ } from '../../../occ';
import { Converter } from '../../../util';

export const PERMISSION_ORDER_NORMALIZER = new InjectionToken<
  Converter<Occ.B2BPermissionOrderListItem, B2BPermissionOrderListItem>
>('PermissionOrderNormalizer');

export const PERMISSION_ORDERS_NORMALIZER = new InjectionToken<
  Converter<Occ.B2BPermissionOrdersPage, EntitiesModel<B2BPermissionOrderListItem>>
>('PermissionOrdersListNormalizer');
