import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        myAssortment: {
          module: () => import('./containers/cms-my-assortment.module').then((m) => m.CmsMyAssortmentModule),
          cmsComponents: ['CmsMyAssortmentComponent'],
        },
        customerInvoiceStock: {
          module: () =>
            import('./containers/my-assortment-invoiced-stock/my-assortment-invoiced-stock.module').then(
              (m) => m.MyAssortmentInvoicedStockModule
            ),
          cmsComponents: ['CmsCustomerInvoiceStockComponent'],
        },
        customerConsignmentStock: {
          module: () =>
            import('./containers/my-assortment-consignment-stock/my-assortment-consignment-stock.module').then(
              (m) => m.MyAssortmentConsignmentStockModule
            ),
          cmsComponents: ['CmsCustomerConsignmentStockComponent'],
        },
        myAssortmentFreeStock: {
          module: () =>
            import('./containers/my-assortment-free-stock/my-assortment-free-stock.module').then(
              (m) => m.MyAssortmentFreeStockModule
            ),
          cmsComponents: ['CmsCustomerFreeStockComponent'],
        },
      },
    }),
  ],
})
export class MyAssortmentFeatureModule {}
