import { Injectable } from '@angular/core';
import { SearchTermFacetMappingHint } from '../../model';
import { Occ } from '../../occ/occ-models';
import { Converter } from '../../util/converter.service';

@Injectable({ providedIn: 'root' })
export class SearchHintNormalizer implements Converter<Occ.SearchTermFacetMappingHint, SearchTermFacetMappingHint> {
  convert(source: Occ.SearchTermFacetMappingHint, target?: SearchTermFacetMappingHint): SearchTermFacetMappingHint {
    if (source) {
      target = {
        ...source,
        tooltipExample:
          !!source.indexedProperty && !!source.exampleValue ? `${source.indexedProperty}: ${source.exampleValue}` : undefined,
      };
    }
    return target;
  }
}
