import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '../../../core/i18n';
import { NotificationModule } from '../notification';
import { SpinnerModule } from '../spinner';
import { UserIconModule } from '../user-icon/user-icon.module';
import { MessagesTabComponent } from './messages-tab.component';

@NgModule({
  declarations: [MessagesTabComponent],
  imports: [CommonModule, I18nModule, SpinnerModule, UserIconModule, NotificationModule],
  exports: [MessagesTabComponent],
})
export class MessagesTabModule {}
