import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  ANONYMOUS_CONSENT_NORMALIZER,
  AnonymousConsentNormalizer,
  AnonymousConsentsService as CxAnonymousConsentsService,
} from '@spartacus/core';
import { provideDefaultConfig } from '../config/config-providers';
import { defaultConsentsConfig } from './config/default-consents-config';
import { AnonymousConsentsService as PyAnonymousConsentsService } from './facade/anonymous-consents.service';
import { interceptors } from './http-interceptors/index';
import { AnonymousConsentsStoreModule } from './store/anonymous-consents-store.module';

@NgModule({
  imports: [AnonymousConsentsStoreModule],
})
export class AnonymousConsentsModule {
  static forRoot(): ModuleWithProviders<AnonymousConsentsModule> {
    return {
      ngModule: AnonymousConsentsModule,
      providers: [
        ...interceptors,
        provideDefaultConfig(defaultConsentsConfig),
        {
          provide: ANONYMOUS_CONSENT_NORMALIZER,
          useExisting: AnonymousConsentNormalizer,
          multi: true,
        },
        {
          provide: CxAnonymousConsentsService,
          useExisting: PyAnonymousConsentsService,
        },
      ],
    };
  }
}
