import { Action } from '@ngrx/store';
import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import { HttpErrorModel, User } from '../../../model';
import { PyStateUtils } from '../../../state/utils';
import { UPDATE_USER_DETAILS_PROCESS } from '../user-state';

export enum UserDetailsActionTypes {
  LoadUserDetails = '[User] Load user details',
  LoadUserDetailsSuccess = '[User] Load user details success',
  LoadUserDetailsFail = '[User] Load user details fail',

  UpdateUserDetails = '[User] Update user details',
  UpdateUserDetailsSuccess = '[User] Update user details success',
  UpdateUserDetailsFail = '[User] Update user details fail',

  ResetUserDetails = '[User] Reset user details',
  RequiredConsentsNotGiven = '[User] Required consent is not given',

  ChangeCustomerSessionLanguage = '[User] Change customer session language',
}

export class LoadUserDetails extends StateUtils.LoaderLoadAction {
  readonly type = UserDetailsActionTypes.LoadUserDetails;
  constructor(public payload: string) {
    super(UPDATE_USER_DETAILS_PROCESS);
  }
}

export class LoadUserDetailsFail extends PyStateUtils.LoaderFailAction {
  readonly type = UserDetailsActionTypes.LoadUserDetailsFail;
  constructor(public payload: any) {
    super(UPDATE_USER_DETAILS_PROCESS, payload);
  }
}

export class LoadUserDetailsSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = UserDetailsActionTypes.LoadUserDetailsSuccess;
  constructor(public payload: User) {
    super(UPDATE_USER_DETAILS_PROCESS);
  }
}

export class UpdateUserDetails extends StateUtils.EntityLoadAction {
  readonly type = UserDetailsActionTypes.UpdateUserDetails;
  constructor(public payload: { username: string; userDetails: User }) {
    super(PROCESS_FEATURE, UPDATE_USER_DETAILS_PROCESS);
  }
}

export class UpdateUserDetailsFail extends PyStateUtils.EntityFailAction {
  readonly type = UserDetailsActionTypes.UpdateUserDetailsFail;
  constructor(public payload?: HttpErrorModel) {
    super(PROCESS_FEATURE, UPDATE_USER_DETAILS_PROCESS, payload);
  }
}

export class UpdateUserDetailsSuccess extends StateUtils.EntitySuccessAction {
  readonly type = UserDetailsActionTypes.UpdateUserDetailsSuccess;
  constructor(public payload: User) {
    super(PROCESS_FEATURE, UPDATE_USER_DETAILS_PROCESS);
  }
}

export class ResetUpdateUserDetails extends StateUtils.EntityLoaderResetAction {
  readonly type = UserDetailsActionTypes.ResetUserDetails;
  constructor() {
    super(PROCESS_FEATURE, UPDATE_USER_DETAILS_PROCESS);
  }
}

export class ChangeCustomerSessionLanguage implements Action {
  readonly type = UserDetailsActionTypes.ChangeCustomerSessionLanguage;
  constructor(public payload: { userId: string; languageCode: string }) {}
}

export type UserDetailsActions =
  | LoadUserDetails
  | LoadUserDetailsFail
  | LoadUserDetailsSuccess
  | UpdateUserDetails
  | UpdateUserDetailsFail
  | UpdateUserDetailsSuccess
  | ResetUpdateUserDetails
  | ChangeCustomerSessionLanguage;
