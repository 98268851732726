<div class="extended-stock-info-wrapper mt-3">
  <ng-container *ngIf="loadingStockInfoDetails" [ngTemplateOutlet]="stockInfoDetailsLoadingTemplate"></ng-container>

  <ng-container *ngIf="!!stockInfoDetailsError && !loadingStockInfoDetails" [ngTemplateOutlet]="errorTemplate"></ng-container>

  <ng-container *ngIf="stockInfoDetails && !loadingStockInfoDetails">
    <div class="mb-3" *ngIf="warehouses?.length > 0">
      <div class="section-title">
        {{ 'common.stockStatus_heading' | cxTranslate }}
      </div>

      <div class="section-body">
        <div class="d-flex justify-content-between item" *ngFor="let warehouse of warehouses">
          <div class="d-flex align-items-center">
            <ng-container *ngIf="warehouse">
              <ng-container
                *ngTemplateOutlet="
                  stockStatusTemplate;
                  context: {
                    $implicit: { warehouse: warehouse, warehouseName: warehouse.warehouseName }
                  }
                "
              ></ng-container>
            </ng-container>
          </div>

          <span *ngIf="!!warehouse">
            {{ warehouse.quantity | cxNumeric : '1.0-3' }}
            {{ warehouse.unit | unit : warehouse.quantity }}
          </span>
        </div>
      </div>
    </div>

    <div class="mb-3">
      <div class="section-title">
        {{ 'common.unitsAndWeight_heading' | cxTranslate }}
      </div>

      <div class="section-body container">
        <div class="row item" *ngFor="let unit of article.units; first as isFirst">
          <ng-container *ngIf="unit.inEcommerceUnit">
            <div class="col-7 px-0">
              <span>{{ unit.packageLevelName }}</span>
            </div>

            <!-- Sales/Default Unit -->
            <div class="col-5 px-0 text-right" *ngIf="isFirst">
              <span>{{ unit | unit : 1 }}</span>
            </div>

            <!-- Higher Level Unit -->
            <div class="col-5 container" *ngIf="!isFirst">
              <div class="row">
                <div class="col-6 px-0">
                  <span>{{ unit | unit : 1 }}</span>
                </div>

                <div class="col-6 px-0 text-right">
                  <span *ngIf="!isFirst">
                    {{ unit.inEcommerceUnit | cxNumeric : '1.0-0' }}
                    {{ article.unit | unit : unit.inEcommerceUnit }}
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="row item">
          <div class="col-7 px-0">
            <span>{{ 'common.weight_heading' | cxTranslate }}</span>
          </div>

          <div class="col-5 px-0 text-right">
            <span>
              {{
                'common.weightValue_hint'
                  | cxTranslate
                    : {
                        weight: stockInfoDetails.weight | cxNumeric : '1.0-3',
                        weightUnit: (stockInfoDetails.weightUnit | unit : stockInfoDetails.weight),
                        weightAmount: stockInfoDetails.weightAmount | cxNumeric : '1.0-0',
                        weightAmountUnit: (stockInfoDetails.weightAmountUnit | unit : stockInfoDetails.weightAmount)
                      }
              }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-3" *ngIf="stockInfoDetails.comparativePrices?.length > 0">
      <div class="section-title">
        {{ 'common.comparativePrices_heading' | cxTranslate }}
      </div>

      <div class="section-body container">
        <div class="row item" *ngFor="let comparativePrice of stockInfoDetails.comparativePrices">
          <div class="col-7 px-0">
            <span>{{ comparativePrice.label }}</span>
          </div>

          <div class="col-5 px-0 text-right">
            <span>
              {{ comparativePrice.formattedValue }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container
    [ngTemplateOutlet]="similarArticlesTemplate"
    [ngTemplateOutletContext]="{
      carouselData: {
        showTitle: false,
        showNotification: false,
        showAlternativeArticleBadge: true,
        showPaginationAtTheBottom: true,
        useDefaultArticleQuantityAndUnit: true
      }
    }"
  ></ng-container>
</div>

<ng-template #stockInfoDetailsLoadingTemplate>
  <div class="mb-3">
    <div class="section-title px-0">
      <div class="skeleton small w-25"></div>
    </div>

    <div class="section-body">
      <div class="skeleton my-1 small"></div>
      <div class="skeleton my-1 small"></div>
      <div class="skeleton my-1 small"></div>
    </div>
  </div>
</ng-template>
