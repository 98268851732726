import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { Category, EntityMap, Product, SearchTermFacetMappingHint, SolrResultEntityRef } from '../../../../../core/model';
import { prepareUrlForLink } from '../../../../../core/util';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class SearchResultsContentBaseComponent {
  @Input() searchFormValue: string;
  @Input() searchHints: SearchTermFacetMappingHint[];
  @Input() articleResultRefs: SolrResultEntityRef[];
  @Input() products$: Observable<Product>[];
  @Input() enableTogglingOfCustomerAssortment: boolean;
  @Input() form: UntypedFormGroup;
  @Input() catalogItemQueryParam: EntityMap<string>;
  @Input() categories: Category[];
  @Output() submitSearch = new EventEmitter<void>();

  onSubmit(): void {
    this.submitSearch.emit();
  }

  getUrl(category: Category, queryParams?: EntityMap<string>): string {
    return prepareUrlForLink(category.url, {
      ...queryParams,
      query: `${this.searchFormValue}::allCategories:${category.code}`,
    });
  }
}
