import { StateUtils } from '@spartacus/core';
import { ActionTokenPayload, CartType, HttpErrorModel, OrderEntry } from '../../../../../core/model';
import { CART } from '../cart-state';

export enum CartEntryFullPalletNoteActionTypes {
  AddCartEntryFullPalletNote = '[Cart] Add full pallet note for cart entry',
  AddCartEntryFullPalletNoteSuccess = '[Cart] Add full pallet note  for cart entry success',
  AddCartEntryFullPalletNoteFail = '[Cart] Add full pallet note  for cart entry fail',

  RemoveCartEntryFullPalletNote = '[Cart] Remove full pallet note  for cart entry',
  RemoveCartEntryFullPalletNoteSuccess = '[Cart] Remove full pallet note  for cart entry success',
  RemoveCartEntryFullPalletNoteFail = '[Cart] Remove full pallet note  for cart entry fail',
}

interface AddCartEntryFullPalletNotePayloadBase {
  cartType: CartType;
  cartId: string;
  userId: string;
  entry: OrderEntry;
}

interface AddCartEntryFullPalletNotePayload extends AddCartEntryFullPalletNotePayloadBase, ActionTokenPayload<boolean> {}

interface AddCartEntryFullPalletNoteFailPayload extends AddCartEntryFullPalletNotePayloadBase {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AddCartEntryFullPalletNoteSuccessPayload extends AddCartEntryFullPalletNotePayloadBase {}

interface RemoveCartEntryFullPalletNotePayloadBase {
  cartType: CartType;
  cartId: string;
  userId: string;
  entry: OrderEntry;
}

interface RemoveCartEntryFullPalletNotePayload extends RemoveCartEntryFullPalletNotePayloadBase, ActionTokenPayload<boolean> {}

interface RemoveCartEntryFullPalletNoteFailPayload extends RemoveCartEntryFullPalletNotePayloadBase {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RemoveCartEntryFullPalletNoteSuccessPayload extends RemoveCartEntryFullPalletNotePayloadBase {}

export class AddCartEntryFullPalletNote extends StateUtils.EntityProcessesIncrementAction {
  readonly type = CartEntryFullPalletNoteActionTypes.AddCartEntryFullPalletNote;

  constructor(public payload: AddCartEntryFullPalletNotePayload) {
    super(CART, payload.cartId);
  }
}

export class AddCartEntryFullPalletNoteFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryFullPalletNoteActionTypes.AddCartEntryFullPalletNoteFail;

  constructor(public payload: AddCartEntryFullPalletNoteFailPayload) {
    super(CART, payload.cartId);
  }
}

export class AddCartEntryFullPalletNoteSuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryFullPalletNoteActionTypes.AddCartEntryFullPalletNoteSuccess;

  constructor(public payload: AddCartEntryFullPalletNoteSuccessPayload) {
    super(CART, payload.cartId);
  }
}

export class RemoveCartEntryFullPalletNote extends StateUtils.EntityProcessesIncrementAction {
  readonly type = CartEntryFullPalletNoteActionTypes.RemoveCartEntryFullPalletNote;

  constructor(public payload: RemoveCartEntryFullPalletNotePayload) {
    super(CART, payload.cartId);
  }
}

export class RemoveCartEntryFullPalletNoteFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryFullPalletNoteActionTypes.RemoveCartEntryFullPalletNoteFail;

  constructor(public payload: RemoveCartEntryFullPalletNoteFailPayload) {
    super(CART, payload.cartId);
  }
}

export class RemoveCartEntryFullPalletNoteSuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryFullPalletNoteActionTypes.RemoveCartEntryFullPalletNoteSuccess;

  constructor(public payload: RemoveCartEntryFullPalletNoteSuccessPayload) {
    super(CART, payload.cartId);
  }
}

export type CartEntryFullPalletNoteActions =
  | AddCartEntryFullPalletNote
  | AddCartEntryFullPalletNoteFail
  | AddCartEntryFullPalletNoteSuccess
  | RemoveCartEntryFullPalletNote
  | RemoveCartEntryFullPalletNoteFail
  | RemoveCartEntryFullPalletNoteSuccess;
