import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CmsComponentChildRoutesConfig, CmsRoute, PageContext, deepMerge } from '@spartacus/core';
import { CmsComponentsService } from '@spartacus/storefront';
import { PageType } from '../../core/model';
import { PageLayoutComponent } from '../page/page-layout/page-layout.component';

// This service should be exposed in public API only after the refactor planned in https://github.com/SAP/spartacus/issues/7070
@Injectable({ providedIn: 'root' })
export class CmsRoutesImplService {
  constructor(private router: Router, private cmsComponentsService: CmsComponentsService) {}

  private cmsRouteExists(url: string): boolean {
    const isCmsDrivenRoute = url.startsWith('/');

    if (!isCmsDrivenRoute) {
      return false;
    }

    const routePath = url.substr(1);

    return (
      isCmsDrivenRoute &&
      !!this.router.config.find((route: CmsRoute) => route.data && route.data.cxCmsRouteContext && route.path === routePath)
    );
  }

  /**
   * Contains Cms driven routing logic intended for use use in guards, especially in canActivate method.
   *
   * Will return true, when logic wont have to modify routing (so canActivate could be easily resolved to true)
   * or will return false, when routing configuration was updated and redirection to newly generated route was initiated.
   *
   * @param pageContext
   * @param currentUrl
   */
  handleCmsRoutesInGuard(
    pageContext: PageContext,
    componentTypes: string[],
    currentUrl: string,
    currentPageLabel: string
  ): boolean {
    if (this.cmsRouteExists(currentPageLabel)) {
      return true;
    }

    const childRoutesConfig = this.cmsComponentsService.getChildRoutes(componentTypes);

    if (childRoutesConfig?.children?.length) {
      if (this.updateRouting(pageContext, currentPageLabel, childRoutesConfig)) {
        this.router.navigateByUrl(currentUrl);
        return false;
      }
    }
    return true;
  }

  private updateRouting(pageContext: PageContext, pageLabel: string, childRoutesConfig: CmsComponentChildRoutesConfig): boolean {
    if (pageContext.type === PageType.CONTENT_PAGE && pageLabel.startsWith('/') && pageLabel.length > 1) {
      const newRoute: CmsRoute = {
        path: pageLabel.substr(1),
        component: PageLayoutComponent,
        children: childRoutesConfig.children,
        data: deepMerge({}, childRoutesConfig?.parent?.data ?? {}, {
          cxCmsRouteContext: {
            type: pageContext.type,
            id: pageLabel,
          },
        }),
      };

      this.router.resetConfig([newRoute, ...this.router.config]);
      return true;
    }

    return false;
  }
}
