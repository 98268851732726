<div class="mt-8 d-block return-entry-info-wrapper">
  <h5>{{ 'orderHistory.orderReturn.reasonForReturn_title' | cxTranslate }}</h5>

  <!-- Quantity To Return -->
  <div class="my-5">
    <span class="d-flex label-text">
      {{ 'orderHistory.orderReturn.quantityToReturn_heading' | cxTranslate }}
    </span>
    <h6 class="mt-2">
      {{
        (orderReturnEntry.fullQuantity
          ? 'orderHistory.orderReturn.fullQuantity_hint'
          : 'orderHistory.orderReturn.partialQuantity_hint'
        ) | cxTranslate
      }}

      <ng-container *ngIf="orderReturnEntry.quantityText; else quantityAndUnitTemplate">
        <span class="text-break">
          {{ orderReturnEntry.quantityText }}
        </span>
      </ng-container>

      <ng-template #quantityAndUnitTemplate>
        <ng-container *ngIf="orderReturnEntry?.unit && orderReturnEntry?.quantity">
          {{ orderReturnEntry.quantity | cxNumeric : '1.0-0' }} {{ orderReturnEntry.unit | unit : orderReturnEntry.quantity }}

          <ng-container
            *ngIf="
              article.unit &&
              article.unit.code !== orderReturnEntry.unit.code &&
              getQuantityInSalesUnit(orderReturnEntry) as quantityInSalesUnit
            "
          >
            ({{ quantityInSalesUnit | cxNumeric : '1.0-0' }} {{ article.unit | unit : quantityInSalesUnit }})
          </ng-container>
        </ng-container>
      </ng-template>
    </h6>
  </div>

  <hr />

  <!-- Return Reason -->
  <div class="my-5">
    <span class="d-flex label-text">
      {{ 'orderHistory.orderReturn.reasonForReturn_heading' | cxTranslate }}
    </span>

    <h6 class="mt-2">
      {{ orderReturnEntry.reasonText }}
    </h6>
  </div>

  <hr />

  <!-- Comment -->
  <div class="my-5">
    <span class="d-flex label-text">
      {{ 'orderHistory.orderReturn.comment_heading' | cxTranslate }}
    </span>

    <h6 class="mt-2 text-break">
      {{ orderReturnEntry.comment }}
    </h6>
  </div>

  <!-- Uploaded Files -->
  <ng-container *ngIf="orderReturnEntry.uploadedFiles?.length > 0">
    <hr />

    <div class="my-5">
      <span class="d-flex label-text">
        {{ 'orderHistory.orderReturn.uploadedFiles_heading' | cxTranslate }}
      </span>

      <div class="mt-2">
        <ng-container *ngFor="let uploadedFile of orderReturnEntry.uploadedFiles; let fileIndex = index">
          <img
            [pyMedia]="uploadedFile.thumbnail"
            [alt]="uploadedFile.filename"
            [ngbTooltip]="
              uploadedFile.thumbnail
                ? uploadedFile.filename
                : ('orderHistory.orderReturn.convertingUploadedFile_tooltip' | cxTranslate : { filename: uploadedFile.filename })
            "
            [placement]="['right', 'bottom']"
            [class.p-2]="hasPdfType(uploadedFile.filename)"
            class="mr-2 mb-2 file-preview"
            (click)="openUploadedFilesGallery(orderReturnEntry.uploadedFiles, fileIndex)"
          />
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
