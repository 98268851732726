import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { StateUtils, StateWithProcess } from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { UserIdService } from '../../../auth/user-auth/facade/user-id.service';
import { IndexedState } from '../../../features/store/base-feature-state';
import { LoaderError, PrincipalConfiguration, PrincipalConfigurationUpdateValue } from '../../../model';
import {
  getProcessErrorFactory,
  getProcessLoadingFactory,
  getProcessSuccessFactory,
} from '../../../process/store/selectors/process.selectors';
import { UserActions } from '../../store/actions';
import { PrincipalConfigurationSelectors } from '../../store/selectors/user.selectors';
import { StateWithUser, UPDATE_PRINCIPAL_CONFIGURATION_PROCESS } from '../../store/user-state';

@Injectable({ providedIn: 'root' })
export class PrincipalConfigurationService {
  constructor(private store: Store<StateWithUser & StateWithProcess<any>>, private userIdService: UserIdService) {}

  private loadValues(): void {
    this.userIdService
      .takeUserId()
      .subscribe((userId) => this.store.dispatch(new UserActions.LoadPrincipalConfigurationValues(userId)));
  }

  getValues(): Observable<{ [p: string]: PrincipalConfiguration } | undefined> {
    return this.store.pipe(
      select(PrincipalConfigurationSelectors.getPrincipalConfigurationLoaderState),
      filter((loaderState) => {
        if (this.needsToLoad(loaderState)) {
          this.loadValues();
          return false;
        }
        return loaderState.success;
      }),
      map((loaderState) => loaderState.value?.entities)
    );
  }

  getValuesLoading(): Observable<boolean> {
    return this.store.pipe(select(PrincipalConfigurationSelectors.getPrincipalConfigurationLoading));
  }

  getValuesLoaded(): Observable<boolean> {
    return this.store.pipe(select(PrincipalConfigurationSelectors.getPrincipalConfigurationLoaded));
  }

  getValue(code: string): Observable<PrincipalConfiguration | undefined> {
    return this.store.pipe(select(PrincipalConfigurationSelectors.getSelectedPrincipalConfigurationValueFactory(code)));
  }

  isEnabled(code: string): Observable<boolean> {
    return this.getValue(code).pipe(
      filter((value) => !!value),
      map((value) => Boolean(value.value)),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  /** Requires the configuration to be enabled otherwise throws Error */
  require(code: string): Observable<boolean | Error> {
    return this.isEnabled(code).pipe(
      map((enabled) => {
        if (enabled) {
          return enabled;
        } else {
          throw new Error(code + ' not enabled');
        }
      })
    );
  }

  updateValue(configValue: PrincipalConfigurationUpdateValue): void {
    this.userIdService.takeUserId(true).subscribe(
      (userId) => this.store.dispatch(new UserActions.UpdatePrincipalConfigurationValue(userId, configValue)),
      () => {}
    );
  }

  /**
   * Method which allows to update principal configuration on store side only
   * - used when we receive continuous change of enableSapOnlineFunctions config from Feed Service
   */
  manuallyUpdateValue(configValue: PrincipalConfiguration): void {
    this.store.dispatch(new UserActions.ManuallyUpdatePrincipalConfigurationValue(configValue));
  }

  /**
   * Resets the update value processing state
   */
  resetUpdateValueProcessingState(): void {
    this.store.dispatch(new UserActions.UpdatePrincipalConfigurationValueReset());
  }

  /**
   * Returns the update value loading flag
   */
  getUpdateValueResultLoading(): Observable<boolean> {
    return this.store.pipe(select(getProcessLoadingFactory(UPDATE_PRINCIPAL_CONFIGURATION_PROCESS)));
  }

  /**
   * Returns the update value error flag
   */
  getUpdateValueResultError(): Observable<LoaderError | undefined> {
    return this.store.pipe(select(getProcessErrorFactory(UPDATE_PRINCIPAL_CONFIGURATION_PROCESS)));
  }

  /**
   * Returns the update value success flag
   */
  getUpdateValueResultSuccess(): Observable<boolean> {
    return this.store.pipe(select(getProcessSuccessFactory(UPDATE_PRINCIPAL_CONFIGURATION_PROCESS)));
  }

  private needsToLoad(loaderState: StateUtils.LoaderState<IndexedState<PrincipalConfiguration>>) {
    return !loaderState.success && !loaderState.error && !loaderState.loading;
  }
}
