import { Injectable } from '@angular/core';
import { BaseSite, ContentPageMetaResolver as CxContentPageMetaResolver } from '@spartacus/core';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslationService } from '../../i18n/services/translation.service';
import { Image } from '../../model';
import { BaseSiteService } from '../../site-context';
import { OpengraphMeta, TwitterCardType, TwitterMeta } from '../model/page.model';
import { BasePageMetaResolver } from './base-page-meta.resolver';
import { PageOpengraphResolver, PageTwitterResolver } from './page.resolvers';

@Injectable({
  providedIn: 'root',
})
export class ContentPageMetaResolver extends CxContentPageMetaResolver implements PageOpengraphResolver, PageTwitterResolver {
  constructor(
    protected basePageMetaResolver: BasePageMetaResolver,
    private translationService: TranslationService,
    private baseSiteService: BaseSiteService
  ) {
    super(basePageMetaResolver);
  }

  resolveOpengraph(): Observable<OpengraphMeta> {
    return combineLatest([
      this.resolveTitle().pipe(),
      this.resolveDescription().pipe(),
      this.translationService.translate('socialMeta.siteName_hint'),
      this.translationService.translate('socialMeta.siteDescription_text'),
      this.basePageMetaResolver.resolveFeaturedImage(),
      this.baseSiteService.get(),
    ]).pipe(
      map(
        ([title, description, siteName, siteDescription, image, baseSite]: [string, string, string, string, Image, BaseSite]) => {
          const cdnBaseUrl = baseSite.cdnBaseUrl ?? '';
          return {
            type: 'website',
            title: title,
            siteName: siteName,
            description: description ?? siteDescription,
            image: image?.url ? `${cdnBaseUrl}${image.url}` : undefined,
            altImage: image?.altText,
          };
        }
      )
    );
  }

  resolveTwitter(): Observable<TwitterMeta> {
    return combineLatest([
      this.resolveTitle().pipe(),
      this.resolveDescription().pipe(),
      this.translationService.translate('socialMeta.siteName_hint'),
      this.translationService.translate('socialMeta.siteCreator_hint'),
      this.translationService.translate('socialMeta.siteDescription_text'),
      this.basePageMetaResolver.resolveFeaturedImage(),
      this.baseSiteService.get(),
    ]).pipe(
      map(
        ([title, description, siteName, siteCreator, siteDescription, image, baseSite]: [
          string,
          string,
          string,
          string,
          string,
          Image,
          BaseSite
        ]) => {
          const cdnBaseUrl = baseSite.cdnBaseUrl ?? '';
          return {
            card: TwitterCardType.Summary,
            site: siteName,
            creator: siteCreator,
            title: title,
            description: description ?? siteDescription,
            image: image?.url ? `${cdnBaseUrl}${image.url}` : undefined,
            altImage: image?.altText,
          };
        }
      )
    );
  }
}
