<div class="navigation" *ngIf="node$ | async as node">
  <div *ngFor="let child of node?.children" class="navigation-wrapper">
    <ng-container *ngIf="!isHorizontalNavigation; else horizontalNavigation">
      <div class="navigation-header mb-4 mb-sm-2">{{ child.title }}</div>
      <div class="navigation-body" *ngFor="let grandChild of child?.children" [class]="grandChild?.styleClasses">
        <ng-container
          *ngIf="grandChild.url === consentsUrl; else showDefaultLink"
          [ngTemplateOutlet]="consentLink"
          [ngTemplateOutletContext]="{ title: grandChild.title }"
        >
        </ng-container>

        <ng-template #showDefaultLink>
          <ng-container *ngTemplateOutlet="defaultLink; context: { $implicit: grandChild }"></ng-container>
        </ng-template>

        <div *ngIf="grandChild?.children && grandChild?.children.length > 0" class="navigation-social-media-holder d-flex mt-4">
          <div class="navigation-social-media-item ml-2" *ngFor="let greatGrandChild of grandChild?.children">
            <py-media-icon
              *ngIf="greatGrandChild.url"
              [url]="greatGrandChild.url"
              [type]="greatGrandChild.title?.toLocaleLowerCase()"
              [target]="greatGrandChild.target"
            ></py-media-icon>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #horizontalNavigation>
      <div class="navigation-body-horizontal" *ngFor="let grandChild of child?.children" [class]="grandChild?.styleClasses">
        <div *ngIf="grandChild?.children && grandChild?.children.length > 0">
          <div *ngFor="let greatGrandChild of grandChild?.children">
            <ng-container *ngTemplateOutlet="defaultLink; context: { $implicit: greatGrandChild }"></ng-container>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #defaultLink let-item>
  <div class="link-item-wrapper">
    <div class="d-flex">
      <cx-generic-link *ngIf="item.url !== '#' && item.url !== consentsUrl; else noLink" [url]="item.url" [target]="item.target">
        <i
          nz-icon
          *ngIf="parseIcon(item?.styleClasses)"
          nzType="{{ parseIcon(item?.styleClasses) }}"
          nzTheme="outline"
          class="mr-2"
        ></i>
        <div [class.has-content]="item.title" [innerHTML]="item.title"></div>
      </cx-generic-link>
    </div>
  </div>

  <ng-template #noLink>
    <ng-container
      *ngIf="item.url === consentsUrl"
      [ngTemplateOutlet]="consentLink"
      [ngTemplateOutletContext]="{ title: item.title }"
    >
    </ng-container>
    <span *ngIf="item.url !== consentsUrl" class="no-link" [class.has-content]="item.title">{{ item.title }}</span>
  </ng-template>
</ng-template>

<ng-template #consentLink let-title="title">
  <a class="open-consent-link has-content" (click)="openConsents($event)">{{ title }}</a>
</ng-template>
