import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, Observable, combineLatest, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { UserIdService } from '../../../auth/user-auth/facade';
import { normalizeHttpError } from '../../../util';
import { SiteConnector } from '../../connectors/site.connector';
import { BaseSiteService } from '../../facade';
import { BaseSiteActionTypes } from '../actions/base-site.action';
import { SiteContextActions } from '../actions/index';
import { SiteCountryActionTypes } from '../actions/site-context-group.actions';

@Injectable()
export class SiteCountryEffects {
  loadSiteCountry$: Observable<SiteContextActions.LoadSiteCountrySuccess | SiteContextActions.LoadSiteCountryFail> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SiteCountryActionTypes.LoadSiteCountry),
        switchMap((action: SiteContextActions.LoadSiteCountry) => {
          return this.siteConnector.getCountries(action.userId, [action.countryCode]).pipe(
            map((countries) => new SiteContextActions.LoadSiteCountrySuccess(countries.length > 0 ? countries[0] : null)),
            catchError((error) => of(new SiteContextActions.LoadSiteCountryFail(normalizeHttpError(error))))
          );
        })
      )
  );

  loadActiveCountryOnBaseSiteChange$: Observable<SiteContextActions.LoadSiteCountry> = createEffect(() =>
    this.actions$.pipe(
      ofType(BaseSiteActionTypes.SetActiveBaseSite),
      mergeMap((_) =>
        combineLatest([this.userIdService.takeUserId(false).pipe(take(1)), this.baseSiteService.get().pipe(take(1))])
      ),
      map(([userId, baseSite]) => new SiteContextActions.LoadSiteCountry(userId, baseSite.baseStore?.countryCode)),
      catchError(() => EMPTY)
    )
  );

  constructor(
    private actions$: Actions,
    private siteConnector: SiteConnector,
    private baseSiteService: BaseSiteService,
    private userIdService: UserIdService
  ) {}
}
