import { Component, OnInit } from '@angular/core';
import { AuthService } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { User } from '../../../core/model';
import { UserFacade } from '../../../core/user';

@Component({
  selector: 'py-password-message',
  templateUrl: './password-message.component.html',
})
export class PasswordMessageComponent implements OnInit {
  passwordUpdateRequired$: Observable<boolean>;

  constructor(private authService: AuthService, private userService: UserFacade) {}

  ngOnInit(): void {
    this.passwordUpdateRequired$ = this.authService.isUserLoggedIn().pipe(
      switchMap((loggedIn) => {
        if (loggedIn) {
          return this.userService.get();
        }
        return of({} as User);
      }),
      map((u) => u?.passwordUpdateRequired ?? false)
    );
  }
}
