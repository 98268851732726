import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalMessageService, GlobalMessageType } from '../../../core/global-message';
import { Translatable, TranslatePipe } from '../../../core/i18n';
import { AlertType, ErrorModel } from '../../../core/model';

interface GlobalMessage {
  type: GlobalMessageType;
  status: AlertType;
  text: Translatable;
  icon: string;
  index: number;
}

function translateGlobalMessageTypeToAlertType(globalMessageType: GlobalMessageType): AlertType {
  switch (globalMessageType) {
    case GlobalMessageType.MSG_TYPE_CONFIRMATION:
      return AlertType.Success;
    case GlobalMessageType.MSG_TYPE_INFO:
      return AlertType.Info;
    case GlobalMessageType.MSG_TYPE_WARNING:
      return AlertType.Warning;
    case GlobalMessageType.MSG_TYPE_ERROR:
    default:
      return AlertType.Danger;
  }
}

function messageIconType(globalMessageType: GlobalMessageType): string {
  switch (globalMessageType) {
    case GlobalMessageType.MSG_TYPE_CONFIRMATION:
      return 'check-circle';
    case GlobalMessageType.MSG_TYPE_INFO:
      return 'info-circle';
    case GlobalMessageType.MSG_TYPE_WARNING:
    case GlobalMessageType.MSG_TYPE_ERROR:
    default:
      return 'warning';
  }
}

@Component({
  selector: 'cx-global-message',
  templateUrl: './global-message.component.html',
  styleUrls: ['./global-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalMessageComponent implements OnInit {
  messages$: Observable<GlobalMessage[]>;

  constructor(protected globalMessageService: GlobalMessageService, private translatePipe: TranslatePipe) {}

  ngOnInit(): void {
    this.messages$ = this.globalMessageService.get().pipe(
      map((entities) =>
        Object.entries(entities).flatMap(([type, messages]) =>
          messages.map((message, index) => ({
            type: type as GlobalMessageType,
            status: translateGlobalMessageTypeToAlertType(type as GlobalMessageType),
            text: message,
            details: this.getErrorDetails(message.errorDetails) || message.details,
            icon: messageIconType(type as GlobalMessageType),
            index,
          }))
        )
      )
    );
  }

  onClose(message: GlobalMessage): void {
    this.globalMessageService.remove(message.type, message.index);
  }

  getErrorDetails(details: ErrorModel[]): string[] {
    if (!details?.length) {
      return undefined;
    }

    return details.map(
      (detail) =>
        `${detail.subject || ''} ${detail.subject ? ': ' : ''} ${
          detail.translationKey ? this.translatePipe.transform(detail.translationKey) : detail.message
        }`
    );
  }
}
