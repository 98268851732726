<py-list-insert [title]="'dashboard.createNewList_title' | cxTranslate" [iconTemplate]="iconsTemplate">
  <p>{{ 'dashboard.createNewList_hint' | cxTranslate }}</p>

  <button class="btn btn-link" (click)="onCreate()" [disabled]="loading">
    <div class="d-flex align-items-center">
      <i class="mr-2" nz-icon nzType="plus"></i>
      {{ 'dashboard.createNewList_action' | cxTranslate }}
    </div>
  </button>
  <div class="spinner">
    <cx-spinner *ngIf="loading"></cx-spinner>
  </div>
</py-list-insert>

<ng-template #iconsTemplate>
  <i class="heart-icon mb-2" nz-icon nzType="heart" nzTheme="outline"></i>
</ng-template>
