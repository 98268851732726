import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe as CXTranslatePipe, CxNumericPipe } from '@spartacus/core';
import { LanguageService } from '../../site-context';
import { TranslationService } from '../services/translation.service';

@Pipe({ name: 'cxTranslate', pure: false })
export class TranslatePipe extends CXTranslatePipe implements PipeTransform {
  constructor(service: TranslationService, cd: ChangeDetectorRef) {
    super(service, cd);
  }
}

@Pipe({ name: 'cxNumeric', pure: false })
export class PyNumericPipe extends CxNumericPipe implements PipeTransform {
  constructor(service: LanguageService) {
    super(service);
  }
}
