import { StateUtils } from '@spartacus/core';
import { IndexedState } from '../../../features/store/base-feature-state';
import { PrincipalConfiguration } from '../../../model';
import { UserActions } from '../actions/index';
import {
  LoadPrincipalConfigurationValuesSuccess,
  ManuallyUpdatePrincipalConfigurationValue,
  PrincipalConfigurationActionTypes,
  UpdatePrincipalConfigurationValueSuccess,
} from '../actions/principal-configuration.action';

export const initialState: IndexedState<PrincipalConfiguration> = {
  lastUpdateTime: undefined,
  entities: undefined,
};

export function reducer(
  state = initialState,
  action: UserActions.PrincipalConfigurationActions | StateUtils.LoaderAction
): IndexedState<PrincipalConfiguration> {
  switch (action.type) {
    case PrincipalConfigurationActionTypes.LoadPrincipalConfigurationValuesSuccess: {
      const successAction = <LoadPrincipalConfigurationValuesSuccess>action;
      const newEntities: { [code: string]: PrincipalConfiguration } = {};
      if (successAction.payload) {
        successAction.payload.forEach(function (principalConfiguration) {
          newEntities[principalConfiguration.code] = principalConfiguration;
        });
      }
      return {
        lastUpdateTime: new Date().toISOString(),
        entities: newEntities,
      };
    }

    case PrincipalConfigurationActionTypes.UpdatePrincipalConfigurationValueSuccess: {
      const successAction = <UpdatePrincipalConfigurationValueSuccess>action;
      const principalConfiguration = successAction.payload;
      const entities = { ...state.entities };
      const oldPrincipalConfiguration = entities[principalConfiguration.code];

      entities[principalConfiguration.code] = { ...oldPrincipalConfiguration, ...principalConfiguration };

      return {
        lastUpdateTime: new Date().toISOString(),
        entities: entities,
      };
    }

    case PrincipalConfigurationActionTypes.ManuallyUpdatePrincipalConfigurationValue: {
      const successAction = <ManuallyUpdatePrincipalConfigurationValue>action;
      const principalConfiguration = successAction.payload;
      const entities = { ...state.entities };
      const oldPrincipalConfiguration = entities[principalConfiguration.code];

      entities[principalConfiguration.code] = { ...oldPrincipalConfiguration, ...principalConfiguration };

      return {
        lastUpdateTime: new Date().toISOString(),
        entities: entities,
      };
    }

    default: {
      return state;
    }
  }
}
