import { MemoizedSelector, MemoizedSelectorWithProps, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { AddonNode } from '../../../../model';
import { PyStateUtils } from '../../../../state';
import { CatalogState, StateWithCatalog } from '../../../store/catalog-state';
import { getCatalogState } from '../../../store/selectors/feature.selector';

export const getAddonNodeState: MemoizedSelectorWithProps<
  StateWithCatalog,
  any,
  StateUtils.EntityLoaderState<AddonNode>
> = createSelector(
  getCatalogState,
  (state: CatalogState, addonGroupId: string) => state && state.addonNodes && state.addonNodes[addonGroupId]
);

export const getAddonNodeLoaderState = (
  addonGroupId: string,
  addonNodeId: string
): MemoizedSelector<StateWithCatalog, StateUtils.LoaderState<AddonNode>> =>
  createSelector(
    (state) => getAddonNodeState(state, addonGroupId),
    (details) => (details && PyStateUtils.entityLoaderStateSelector(details, addonNodeId)) || PyStateUtils.initialLoaderState
  );

export const getAddonNodeLoading = (addonGroupId: string, addonNodeId: string): MemoizedSelector<StateWithCatalog, boolean> =>
  createSelector(getAddonNodeLoaderState(addonGroupId, addonNodeId), (addonNodeState) =>
    StateUtils.loaderLoadingSelector(addonNodeState)
  );

export const getAddonNodeLoaded = (addonGroupId: string, addonNodeId: string): MemoizedSelector<StateWithCatalog, boolean> =>
  createSelector(
    getAddonNodeLoaderState(addonGroupId, addonNodeId),
    (addonNodeState) => (addonNodeState && StateUtils.loaderSuccessSelector(addonNodeState)) || false
  );

export const getAddonNode = (addonGroupId: string, addonNodeId: string): MemoizedSelector<StateWithCatalog, AddonNode> =>
  createSelector(getAddonNodeLoaderState(addonGroupId, addonNodeId), (addonNodeState) =>
    StateUtils.loaderValueSelector(addonNodeState)
  );
