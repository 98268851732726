import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, finalize, groupBy, map, mergeMap, switchMap } from 'rxjs/operators';
import { ChangeSoldToActionTypes } from '../../../../features/sold-to-base/store/actions/sold-to-change.action';
import { LoginLogoutActionTypes } from '../../../auth/user-auth/store/actions/login-logout.action';
import { SolrSearchType } from '../../../model/solr-search-config';
import { LanguagesActionTypes } from '../../../site-context/store/actions/languages.action';
import { normalizeHttpError, withdrawOn } from '../../../util';
import { AppActions } from '../../../util/store/actions';
import { SolrSearchConnector } from '../../connectors/solr-search.connector';
import { SearchService } from '../../services/search.service';
import { SolrSearchActions } from '../actions';

@Injectable()
export class SolrSearchEffects {
  private clearSearchStateOnContextChange$ = this.actions$.pipe(
    ofType(
      LoginLogoutActionTypes.Login,
      LoginLogoutActionTypes.Logout,
      LanguagesActionTypes.LanguageChange,
      ChangeSoldToActionTypes.ChangeSoldToSuccess
    )
  );

  search$: Observable<
    | SolrSearchActions.LoadSolrSearchSuccess
    | SolrSearchActions.LoadSolrSearchFail
    | SolrSearchActions.LoadSolrSearchTotalCountSuccess
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(SolrSearchActions.SolrSearchActionTypes.LoadSolrSearch),
      groupBy((action: SolrSearchActions.LoadSolrSearch) => action.config.searchType),
      mergeMap((group) =>
        group.pipe(
          debounceTime(30), // TODO: ESVCX-858 Find root of this being needed (multiple subscribes?)
          switchMap((action: SolrSearchActions.LoadSolrSearch) => {
            return this.searchConnector.query(action.query, action.config, action.userId).pipe(
              mergeMap((data) => {
                const actions: Array<
                  SolrSearchActions.LoadSolrSearchSuccess | SolrSearchActions.LoadSolrSearchTotalCountSuccess
                > = [
                  new SolrSearchActions.LoadSolrSearchSuccess(
                    data.result,
                    action.config.searchType,
                    action.query,
                    action.userId,
                    action.config,
                    action.loadAllProductArticles
                  ),
                ];

                if (
                  [SolrSearchType.PRODUCT, SolrSearchType.ARTICLE].includes(action.config.searchType) &&
                  !action.loadAllProductArticles
                ) {
                  actions.push(
                    new SolrSearchActions.LoadSolrSearchTotalCountSuccess(data.totalCount, action.query, action.config.searchType)
                  );
                }

                return actions;
              }),
              catchError((error) =>
                of(new SolrSearchActions.LoadSolrSearchFail(normalizeHttpError(error), action.config.searchType, action.query))
              ),
              finalize(() => this.searchService.updateUserInputSearchInProgress$(false))
            );
          })
        )
      ),
      withdrawOn(this.clearSearchStateOnContextChange$)
    )
  );

  searchTotalCount$: Observable<
    SolrSearchActions.LoadSolrSearchTotalCountSuccess | SolrSearchActions.LoadSolrSearchTotalCountFail
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(SolrSearchActions.SolrSearchActionTypes.LoadSolrSearchTotalCount),
      groupBy((action: SolrSearchActions.LoadSolrSearchTotalCount) => action.config.searchType),
      mergeMap((group) =>
        group.pipe(
          debounceTime(30), // TODO: ESVCX-858 Find root of this being needed (multiple subscribes?)
          switchMap((action: SolrSearchActions.LoadSolrSearchTotalCount) => {
            return this.searchConnector.queryTotalCount(action.query, action.config, action.userId).pipe(
              map((data) => {
                return new SolrSearchActions.LoadSolrSearchTotalCountSuccess(data, action.query, action.config.searchType);
              }),
              catchError((error) =>
                of(
                  new SolrSearchActions.LoadSolrSearchTotalCountFail(
                    normalizeHttpError(error),
                    action.query,
                    action.config.searchType
                  )
                )
              )
            );
          })
        )
      ),
      withdrawOn(this.clearSearchStateOnContextChange$)
    )
  );

  loadAllProductArticles$: Observable<SolrSearchActions.LoadSolrSearch | AppActions.NoOpAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(SolrSearchActions.SolrSearchActionTypes.LoadSolrSearchSuccess),
      map((action: SolrSearchActions.LoadSolrSearchSuccess) => {
        if (action.loadAllProductArticles && action.payload.pagination?.currentPage + 1 < action.payload.pagination?.totalPages) {
          return new SolrSearchActions.LoadSolrSearch(
            action.query,
            {
              ...action.searchConfig,
              searchType: action.searchType,
              currentPage: action.payload.pagination.currentPage + 1,
            },
            action.userId,
            true
          );
        } else {
          return new AppActions.NoOpAction();
        }
      }),
      withdrawOn(this.clearSearchStateOnContextChange$)
    )
  );

  // loadAllProductArticles$: Observable<
  //   SolrSearchActions.LoadSolrSearchSuccess | SolrSearchActions.LoadSolrSearchFail | AppActions.NoOpAction
  // > = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(SolrSearchActions.SolrSearchActionTypes.LoadSolrSearchSuccess),
  //     concatMap((action: SolrSearchActions.LoadSolrSearchSuccess) => {
  //       if (action.loadAllProductArticles && action.payload.pagination?.currentPage + 1 < action.payload.pagination?.totalPages) {
  //         return this.searchConnector
  //           .query(
  //             action.query,
  //             {
  //               ...action.searchConfig,
  //               searchType: action.searchType,
  //               currentPage: action.payload.pagination.currentPage + 1,
  //             },
  //             action.userId
  //           )
  //           .pipe(
  //             map(
  //               (data) =>
  //                 new SolrSearchActions.LoadSolrSearchSuccess(
  //                   data.result,
  //                   action.searchType,
  //                   action.query,
  //                   action.userId,
  //                   action.searchConfig,
  //                   true
  //                 )
  //             ),
  //             catchError((error) =>
  //               of(new SolrSearchActions.LoadSolrSearchFail(normalizeHttpError(error), action.searchType, action.query))
  //             )
  //           );
  //       } else {
  //         return of(new AppActions.NoOpAction());
  //       }
  //     })
  //   )
  // );

  constructor(private actions$: Actions, private searchConnector: SolrSearchConnector, private searchService: SearchService) {}
}
