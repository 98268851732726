import { SimulatedCartMessage } from './cart.model';
import { B2BComment } from './misc.model';
import { Reinvoicing } from './order.model';

export enum CheckoutNotesMode {
  EditMode = 'EditMode',
  SavedMode = 'SavedMode',
}

export interface CheckoutReviewSaveEvent {
  orderNote?: string;
  deliveryNote?: string;
  message?: string;
  customerReference?: string;
  goodsMarking?: string;
  buyerComment?: B2BComment;
  approverComment?: B2BComment;
  reinvoicing?: Reinvoicing;
  phoneNumber?: string;
  invoiceReference?: string;
  costPlace?: string;
}

export interface MessagesForEntriesRecord {
  [sapOrderLineNumber: string]: SimulatedCartMessage[];
}
