import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CatalogRouterStateService } from '../../../../core/catalog';
import { CategoryRef } from '../../../../core/model';

@Injectable()
export class LeafCategoryCodeResolver {
  constructor(private catalogRouterStateService: CatalogRouterStateService) {}

  resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): CategoryRef {
    return this.catalogRouterStateService.getLeafCategoryCode(route.params, route.queryParams);
  }
}
