import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ColumnAttribute } from '../../../../core/model';

@Component({
  selector: 'py-article-info-attributes',
  templateUrl: './article-info-attributes.component.html',
  styleUrls: ['./article-info-attributes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleInfoAttributesComponent {
  @Input() columnAttributes: ColumnAttribute[];
  @Input() enableSecondaryArticleRowVariant = false;
  @Input() showAttributesInSeparateColumn = false;
  @Input() multipleAttributesPerRow = true;
}
