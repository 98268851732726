import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '../../../core/i18n';
import { OverlayMessageModule } from '../overlay-message';
import { ConfirmationOverlayMessageComponent } from './confirmation-overlay-message.component';

@NgModule({
  imports: [CommonModule, OverlayMessageModule, I18nModule],
  declarations: [ConfirmationOverlayMessageComponent],
  exports: [ConfirmationOverlayMessageComponent],
})
export class ConfirmationOverlayMessageModule {}
