import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  BreadcrumbMeta,
  CmsService,
  BasePageMetaResolver as CxBasePageMetaResolver,
  PageLinkService,
  RoutingPageMetaResolver,
  TranslationService,
} from '@spartacus/core';
import { Observable, combineLatest, defer } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Image } from '../../model';
import { SitePrefixService } from '../../site-context';

@Injectable({
  providedIn: 'root',
})
export class BasePageMetaResolver extends CxBasePageMetaResolver {
  constructor(
    protected cmsService: CmsService,
    protected translationService: TranslationService,
    protected routingPageMetaResolver: RoutingPageMetaResolver,
    protected router: Router,
    protected pageLinkService: PageLinkService,
    protected sitePrefixService: SitePrefixService
  ) {
    super(cmsService, translationService, routingPageMetaResolver, router, pageLinkService);
  }

  protected homeBreadcrumb$: Observable<BreadcrumbMeta[]> = combineLatest([
    this.translationService.translate('common.homeBreadcrumb_hint'),
    this.sitePrefixService.getActive(),
  ]).pipe(
    map(([label, activeSitePrefix]) => {
      return [{ label: label, link: activeSitePrefix ? `/${activeSitePrefix}/` : '/' }] as BreadcrumbMeta[];
    })
  );

  protected breadcrumb$: Observable<BreadcrumbMeta[]> = combineLatest([
    this.homeBreadcrumb$,
    defer(() => this.routingPageMetaResolver.resolveBreadcrumbs()),
  ]).pipe(
    map((breadcrumbs) => breadcrumbs.flat()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  protected featuredImage$: Observable<Image | undefined> = this.page$.pipe(map((p) => p.featuredImage));

  resolveFeaturedImage(): Observable<Image | undefined> {
    return this.featuredImage$;
  }
}
