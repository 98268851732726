import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CheckoutKey, LoaderError, NextOrderProcessStep } from '../../../../core/model';
import { CHECKOUT_CORE_FEATURE } from '../store/checkout-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: CheckoutProcessingFacade,
      feature: CHECKOUT_CORE_FEATURE,
      async: true,
      methods: [
        'getNextOrderProcessStep',
        'getNextOrderProcessStepLoading',
        'getNextOrderProcessStepError',
        'resetOrderProcess',
        'clearActiveOrderProcess',
        'dismissActiveOrderProcessModalIfExists',
      ],
    }),
})
export abstract class CheckoutProcessingFacade {
  abstract getNextOrderProcessStep(key: CheckoutKey): Observable<NextOrderProcessStep>;
  abstract getNextOrderProcessStepLoading(key: CheckoutKey): Observable<boolean>;
  abstract getNextOrderProcessStepError(key: CheckoutKey): Observable<LoaderError | undefined>;
  abstract resetOrderProcess(key: CheckoutKey): void;
  abstract clearActiveOrderProcess(): void;
  abstract dismissActiveOrderProcessModalIfExists(): void;
}
