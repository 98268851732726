import { ComponentRef, ElementRef, Inject, Injectable, ViewContainerRef } from '@angular/core';
import {
  LaunchDialogService as CxLaunchDialogService,
  LAUNCH_CALLER,
  LaunchRenderStrategy,
  LayoutConfig,
} from '@spartacus/storefront';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LaunchDialogService extends CxLaunchDialogService {
  private lastOpenedModalSubject = new BehaviorSubject<Observable<ComponentRef<any>>>(of(undefined));
  public lastOpenedModal$ = this.lastOpenedModalSubject.asObservable().pipe(switchMap((innerObservable) => innerObservable));

  constructor(
    @Inject(LaunchRenderStrategy)
    protected renderStrategies: LaunchRenderStrategy[],
    protected layoutConfig: LayoutConfig
  ) {
    super(renderStrategies, layoutConfig);
  }

  registerModalLayoutConfig(config: LayoutConfig): void {
    this.layoutConfig = {
      ...this.layoutConfig,
      launch: {
        ...this.layoutConfig.launch,
        ...config.launch,
      },
    };
  }

  openDialog(
    caller: LAUNCH_CALLER | string,
    openElement?: ElementRef,
    vcr?: ViewContainerRef,
    data?: any
  ): Observable<any> | undefined {
    const component = this.launch(caller, vcr, data);

    if (component) {
      // emitting reference to opened modal in Subject,
      // so it's possible to access it in other places,
      // e.g. to close only the specific modal
      this.lastOpenedModalSubject.next(component || of(undefined));

      return combineLatest([component, this.dialogClose]).pipe(
        filter(([, close]) => close !== undefined),
        tap(([comp]) => {
          openElement?.nativeElement.focus();
          this.clear(caller);
          comp?.destroy();
        }),
        map(([comp]) => comp)
      );
    }
  }

  clear(caller: LAUNCH_CALLER | string): void {
    super.clear(caller);

    // clearing the reference in Subject
    this.lastOpenedModalSubject.next(of(undefined));
  }
}
