import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../../core/i18n';
import { PrincipalConfigurationDirectiveModule } from '../../../directives';
import { PipesModule } from '../../../pipes';
import { PrePrintedModalModule } from '../pre-printed-modal';
import { PrePrintedButtonComponent } from './pre-printed-button.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    NzIconModule,
    NgbTooltipModule,
    PipesModule,
    PrincipalConfigurationDirectiveModule,
    PrePrintedModalModule,
  ],
  declarations: [PrePrintedButtonComponent],
  exports: [PrePrintedButtonComponent],
})
export class PrePrintedButtonModule {}
