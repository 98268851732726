import { StateUtils } from '@spartacus/core';
import { HttpErrorModel } from '../../../model';
import { failMeta } from '../loader/loader.action';

export function entityFailMeta(entityType: string, id: string | string[], error?: HttpErrorModel): StateUtils.EntityLoaderMeta {
  return {
    ...failMeta(entityType, error),
    ...StateUtils.entityMeta(entityType, id),
  };
}

export class EntityFailAction implements StateUtils.EntityLoaderAction {
  type = StateUtils.ENTITY_FAIL_ACTION;
  readonly meta: StateUtils.EntityLoaderMeta;
  constructor(entityType: string, id: string | string[], error?: HttpErrorModel) {
    this.meta = entityFailMeta(entityType, id, error);
  }
}
