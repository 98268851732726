import { StateUtils } from '@spartacus/core';
import { OrderCard } from '../../../model';
import { UserActions } from '../actions/index';
import { OrderCardsActionTypes } from '../actions/order-cards.action';

export const initialState: OrderCard | undefined = undefined;

export function reducer(
  state: OrderCard = initialState,
  action: UserActions.OrderCardsActions | StateUtils.LoaderAction
): OrderCard {
  switch (action.type) {
    case OrderCardsActionTypes.LoadOrderCardFail: {
      return initialState;
    }
    case OrderCardsActionTypes.LoadOrderCardSuccess: {
      return action.payload ? action.payload : initialState;
    }
  }

  return state;
}
