import DRange from 'drange';
import ret, { Char, Range, Root, Set, Token, types } from 'ret';

/**
 * Generates a regex example string.
 * Inspired by randexp.js (https://github.com/fent/randexp.js)
 *
 * @param regexp
 * regex string
 *
 * @param max
 * Repetitional tokens such as *, +, and {3,} have an infinite max range.
 * In this case max param will be added to the min value as a useable max value.
 *
 * @param drange
 * The default generated character range includes printable ASCII characters.
 * Change this param to specify what characters to be generated.
 *
 * @param ignoreCase
 * The regex ignore param (/i) makes the regex case insensitive
 */
export function getRegexExample(
  regexp: string,
  max: number = 10,
  drange = new DRange(32, 126),
  ignoreCase: boolean = false
): string {
  if (!regexp || regexp === '.*') {
    return '';
  }
  return gen(ret(regexp), [], max, drange, ignoreCase);
}

function gen(token: Root | Token, groups: any[], max: number, range: DRange, ignoreCase: boolean): string {
  let stack: Token[];
  let str: string;
  let n: number;
  let i: number;
  let l: number;
  let expandedSet: DRange;
  let code: number;

  switch (token.type) {
    case types.ROOT:
      stack = token.options ? randSelect(token.options) : token.stack;
      str = '';
      for (i = 0, l = stack.length; i < l; i++) {
        str += gen(stack[i], groups, max, range, ignoreCase);
      }
      return str;

    case types.GROUP:
      // Ignore lookaheads for now.
      if (token.followedBy || token.notFollowedBy) {
        return '';
      }

      // Insert placeholder until group string is generated.
      // Property 'groupNumber' does not exist on type 'Group'.
      // @ts-ignore: Unreachable code error
      if (token.remember && token.groupNumber === undefined) {
        // Property 'groupNumber' does not exist on type 'Group'.
        // @ts-ignore: Unreachable code error
        token.groupNumber = groups.push(null) - 1;
      }

      stack = token.options ? randSelect(token.options) : token.stack;

      str = '';
      for (i = 0, l = stack.length; i < l; i++) {
        str += gen(stack[i], groups, max, range, ignoreCase);
      }

      if (token.remember) {
        // Property 'groupNumber' does not exist on type 'Group'.
        // @ts-ignore: Unreachable code error
        groups[token.groupNumber] = str;
      }
      return str;

    case types.POSITION:
      // Do nothing for now.
      return '';

    case types.SET:
      expandedSet = expand(token, range, ignoreCase);
      if (!expandedSet.length) {
        return '';
      }
      return String.fromCharCode(randNumberSelect(expandedSet));

    case types.REPETITION:
      // Randomly generate number between min and max.
      n = randNumber(token.min, token.max === Infinity ? token.min + max : token.max);

      str = '';
      for (i = 0; i < n; i++) {
        str += gen(token.value, groups, max, range, ignoreCase);
      }

      return str;

    case types.REFERENCE:
      return groups[token.value - 1] || '';

    case types.CHAR:
      code = ignoreCase && randBool() ? toOtherCase(token.value) : token.value;
      return String.fromCharCode(code);
  }
}

function toOtherCase(code: number): number {
  return code + (97 <= code && code <= 122 ? -32 : 65 <= code && code <= 90 ? 32 : 0);
}

function randBool(): boolean {
  return !randNumber(0, 1);
}

function randNumber(a: number, b: number): number {
  return a + Math.floor(Math.random() * (1 + b - a));
}

function randSelect(arr: Token[][]): Token[] {
  return arr[randNumber(0, arr.length - 1)];
}

function randNumberSelect(arr: DRange): number {
  return arr.index(randNumber(0, arr.length - 1));
}

function expand(token: Char | Range | Set, range: DRange, ignoreCase: boolean): DRange {
  if (token.type === types.CHAR) {
    return new DRange(token.value);
  } else if (token.type === types.RANGE) {
    return new DRange(token.from, token.to);
  } else {
    const drange = new DRange();
    for (let i = 0; i < token.set.length; i++) {
      const subrange = expand(token.set[i], range, ignoreCase);
      drange.add(subrange);
      if (ignoreCase) {
        for (let j = 0; j < subrange.length; j++) {
          const code = subrange.index(j);
          const otherCaseCode = toOtherCase(code);
          if (code !== otherCaseCode) {
            drange.add(otherCaseCode);
          }
        }
      }
    }
    if (token.not) {
      return range.clone().subtract(drange);
    } else {
      return range.clone().intersect(drange);
    }
  }
}
