import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';

@Component({
  selector: 'py-counter-input',
  templateUrl: './counter-input.component.html',
  styleUrls: ['./counter-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CounterInputComponent),
      multi: true,
    },
  ],
})
export class CounterInputComponent implements ControlValueAccessor {
  @ViewChild(NgModel, { static: true }) inputRef: NgModel;

  @Input() min = 0;
  @Input() max = 999999;
  @Input() step = 1;
  @Input() isForceInvalid = false;
  @Input() minimumOrderIndication = false;
  @Input() minDisabledTooltipText: string;
  @Input() tooltipContainer: string = null;

  @HostBinding('class.read-only') @Input() readOnly = false;
  @HostBinding('class.disabled') @Input() disabled = false;

  value: number = 1;

  private onChange?: (value: number) => void;

  get canIncrement(): boolean {
    return this.max != null ? this.value + this.step <= this.max : true;
  }

  get canDecrement(): boolean {
    return this.min != null ? this.value - this.step >= this.min : true;
  }

  @HostBinding('class.invalid')
  get isInputValueInvalid(): boolean {
    return this.isForceInvalid || (this.inputValue != null && !this.isWithinConstraints(this.inputValue));
  }

  @HostBinding('class.focused') isFocused = false;

  private get inputValue(): number | null {
    return this.inputRef.value;
  }

  constructor(private cd: ChangeDetectorRef) {}

  writeValue(value: number): void {
    if (value != null) {
      this.value = value;
      this.cd.markForCheck();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(_fn: any): void {}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onIncrement() {
    if (this.canIncrement) {
      this.commitValue(this.value + this.step);
    }
  }

  onDecrement() {
    if (this.canDecrement) {
      this.commitValue(this.value - this.step);
    }
  }

  onInputChange(value: number) {
    if (this.isWithinConstraints(value) || this.minimumOrderIndication) {
      this.commitValue(value);
    }
  }

  onInputFocus(): void {
    this.isFocused = true;
  }

  onInputBlur() {
    this.isFocused = false;
    let value = this.getValidCount(this.inputValue);

    if (value === null && this.minimumOrderIndication) {
      value = this.min;
      this.commitValue(value);
    } else if (this.isInputValueInvalid) {
      this.commitValue(value);
    }
    this.setInputValue(value);
  }

  private commitValue(value: number): void {
    if (this.disabled || this.readOnly) {
      return;
    }
    const validValue = this.getValidCount(value);
    this.value = validValue;
    if (this.onChange) {
      this.onChange(validValue);
    }
  }

  /**
   * Validate that the given value is in between
   * the `min` and `max` value. If the value is out
   * of  the min/max range, it will be altered.
   *
   */
  private getValidCount(value: number): number {
    if (value === null && this.minimumOrderIndication) {
      return value;
    }

    if (!this.isMinConstraintMet(value) && !this.minimumOrderIndication) {
      return this.min;
    }
    if (!this.isMaxConstraintMet(value)) {
      return this.max;
    }
    return Math.floor(value);
  }

  private isWithinConstraints(value: number): boolean {
    return this.isMinConstraintMet(value) && this.isMaxConstraintMet(value);
  }

  private isMinConstraintMet(value: number): boolean {
    return this.min == null || value >= this.min;
  }

  private isMaxConstraintMet(value: number): boolean {
    return this.max == null || value <= this.max;
  }

  private setInputValue(value: number): void {
    this.inputRef.control.setValue(value, { emitEvent: false });
  }
}
