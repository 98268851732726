import { StateUtils } from '@spartacus/core';
import { ActionTokenPayload, CartType, HttpErrorModel, OrderEntry, PalletFlagsRequest } from '../../../../../core/model';
import { CART } from '../cart-state';

export enum CartEntryPalletFlagsActionTypes {
  UpdateCartEntryPalletFlags = '[Cart] Update pallet flags for cart entry',
  UpdateCartEntryPalletFlagsSuccess = '[Cart] Update pallet flags for cart entry success',
  UpdateCartEntryPalletFlagsFail = '[Cart] Update pallet flags for cart entry fail',
}

interface UpdateCartEntryPalletFlagsPayloadBase {
  cartType: CartType;
  cartId: string;
  userId: string;
  entry: OrderEntry;
  palletFlags: PalletFlagsRequest;
}

interface UpdateCartEntryPalletFlagsPayload extends UpdateCartEntryPalletFlagsPayloadBase, ActionTokenPayload<boolean> {}

interface UpdateCartEntryPalletFlagsFailPayload extends UpdateCartEntryPalletFlagsPayloadBase {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UpdateCartEntryPalletFlagsSuccessPayload extends UpdateCartEntryPalletFlagsPayloadBase {}

export class UpdateCartEntryPalletFlags extends StateUtils.EntityProcessesIncrementAction {
  readonly type = CartEntryPalletFlagsActionTypes.UpdateCartEntryPalletFlags;

  constructor(public payload: UpdateCartEntryPalletFlagsPayload) {
    super(CART, payload.cartId);
  }
}

export class UpdateCartEntryPalletFlagsFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryPalletFlagsActionTypes.UpdateCartEntryPalletFlagsFail;

  constructor(public payload: UpdateCartEntryPalletFlagsFailPayload) {
    super(CART, payload.cartId);
  }
}

export class UpdateCartEntryPalletFlagsSuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryPalletFlagsActionTypes.UpdateCartEntryPalletFlagsSuccess;

  constructor(public payload: UpdateCartEntryPalletFlagsSuccessPayload) {
    super(CART, payload.cartId);
  }
}

export type CartEntryPalletFlagsActions =
  | UpdateCartEntryPalletFlags
  | UpdateCartEntryPalletFlagsFail
  | UpdateCartEntryPalletFlagsSuccess;
