import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { Feed } from '../../model';
import { USER_BASE_FEATURE } from '../store/user-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: FeedFacade,
      feature: USER_BASE_FEATURE,
      async: true,
      methods: ['loadFeedData', 'getFeedData'],
    }),
})
export abstract class FeedFacade {
  abstract loadFeedData(): void;
  abstract getFeedData(): Observable<Feed>;
}
