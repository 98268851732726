import { Action } from '@ngrx/store';
import { EntriesState } from '../../../features/store/base-feature-state';
import { Country } from '../../../model';
import { UserActions } from '../actions';

export const initialState: EntriesState<Country> = {};

export function reducer(state = initialState, action: Action): EntriesState<Country> {
  switch (action.type) {
    case UserActions.ProfileCountriesActionTypes.LoadProfileCountriesSuccess: {
      const successAction: UserActions.LoadProfileCountriesSuccess = <UserActions.LoadProfileCountriesSuccess>action;

      return successAction.payload.reduce(
        (entities: { [id: string]: Country }, country: Country) => {
          return {
            ...entities,
            [country.isocode]: country,
          };
        },
        {
          ...state,
        }
      );
    }
    default:
      return state;
  }
}
