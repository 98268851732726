import { Injectable } from '@angular/core';
import { B2BPermissionOrderListItem } from '../../../../model';
import { Converter } from '../../../../util/converter.service';
import { Occ } from '../../../occ-models';

@Injectable({
  providedIn: 'root',
})
export class OccPermissionOrderNormalizer implements Converter<Occ.B2BPermissionOrderListItem, B2BPermissionOrderListItem> {
  convert(source: Occ.B2BPermissionOrderListItem, target?: B2BPermissionOrderListItem): B2BPermissionOrderListItem {
    if (target === undefined) {
      target = {
        ...(source as any),
      };
    }
    return target;
  }
}
