export function arrayEquals(a, b) {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
}

export function findLastIndex<T>(elements: T[], predicate: (el: T) => boolean) {
  for (let index = elements.length - 1; index >= 0; index--) {
    if (predicate(elements[index])) {
      return index;
    }
  }
  return -1;
}
