import { StateUtils } from '@spartacus/core';
import { SearchParams } from '../../../../model/search.model';
import { PyStateUtils } from '../../../../state/utils';
import { PagedSearchAction } from '../../../store/actions/search.actions';
import { PRICE_FILE } from '../price-file-state';

export enum PriceFilesActionTypes {
  LoadPriceFiles = '[PriceFile] Load Price Files',
  LoadPriceFilesSuccess = '[PriceFile] Load Price Files Success',
  LoadPriceFilesFail = '[PriceFile] Load Price Files Fail',
}

export class LoadPriceFilesAction extends StateUtils.LoaderLoadAction implements PagedSearchAction {
  readonly type = PriceFilesActionTypes.LoadPriceFiles;
  readonly searchType = PRICE_FILE;

  constructor(public userId: string, public searchParams: SearchParams) {
    super(PRICE_FILE);
  }
}

export class LoadPriceFilesActionFail extends PyStateUtils.LoaderFailAction implements PagedSearchAction {
  readonly type = PriceFilesActionTypes.LoadPriceFilesFail;
  readonly searchType = PRICE_FILE;

  constructor(public searchParams: SearchParams, public payload?: any) {
    super(PRICE_FILE, payload);
  }
}

export class LoadPriceFilesActionSuccess extends StateUtils.LoaderSuccessAction implements PagedSearchAction {
  readonly type = PriceFilesActionTypes.LoadPriceFilesSuccess;
  readonly searchType = PRICE_FILE;

  constructor(public payload: any, public searchParams: SearchParams) {
    super(PRICE_FILE);
  }
}

export type PriceFilesActions = LoadPriceFilesAction | LoadPriceFilesActionFail | LoadPriceFilesActionSuccess;
