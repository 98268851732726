import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ArticleService } from '../../../../core/catalog';
import { Article } from '../../../../core/model';
import { sanitizeArticleCode } from '../../../../core/util';

@Injectable()
export class GetArticleResolver {
  constructor(private articleService: ArticleService) {}

  resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<Article> {
    return this.articleService.getArticle(sanitizeArticleCode(route?.queryParams?.a));
  }
}
