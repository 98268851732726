import { StateUtils } from '@spartacus/core';
import { ArticleStockInfo, StockInfoDetails, StockLookup } from '../../../model';
import { PyStateUtils } from '../../../state';
import { ARTICLE_STOCK_INFO, ARTICLE_STOCK_INFO_DETAILS } from '../user-state';

export enum StockInfoActionTypes {
  LoadStockInfo = '[User] Load Article stock info',
  LoadStockInfoSuccess = '[User] Load Article stock info Success',
  LoadStockInfoFail = '[User] Load Article stock info Fail',
  LoadStockInfoDetails = '[User] Load Article stock info details',
  LoadStockInfoDetailsSuccess = '[User] Load Article stock info details Success',
  LoadStockInfoDetailsFail = '[User] Load Article stock info details Fail',
}

export class LoadStockInfo extends StateUtils.EntityLoadAction {
  readonly type = StockInfoActionTypes.LoadStockInfo;

  constructor(public lookup: StockLookup) {
    super(ARTICLE_STOCK_INFO, `${lookup.articleNumber}|${lookup.quantity}|${lookup.unit ?? 'x'}`);
  }
}

export class LoadStockInfoSuccess extends StateUtils.EntitySuccessAction {
  readonly type = StockInfoActionTypes.LoadStockInfoSuccess;

  constructor(public payload: ArticleStockInfo) {
    super(ARTICLE_STOCK_INFO, `${payload.articleRef}|${payload.quantity}|${payload.unitRef ?? 'x'}`);
  }
}

export class LoadStockInfoFail extends PyStateUtils.EntityFailAction {
  readonly type = StockInfoActionTypes.LoadStockInfoFail;

  constructor(public lookup: StockLookup, public payload?: any) {
    super(ARTICLE_STOCK_INFO, `${lookup.articleNumber}|${lookup.quantity}|${lookup.unit ?? 'x'}`, payload);
  }
}

export class LoadStockInfoDetails extends StateUtils.EntityLoadAction {
  readonly type = StockInfoActionTypes.LoadStockInfoDetails;

  constructor(public lookup: StockLookup) {
    super(ARTICLE_STOCK_INFO_DETAILS, `${lookup.articleNumber}|${lookup.quantity}`);
  }
}

export class LoadStockInfoDetailsSuccess extends StateUtils.EntitySuccessAction {
  readonly type = StockInfoActionTypes.LoadStockInfoDetailsSuccess;

  constructor(public payload: StockInfoDetails, public lookup: StockLookup) {
    super(ARTICLE_STOCK_INFO_DETAILS, `${lookup.articleNumber}|${lookup.quantity}`);
  }
}

export class LoadStockInfoDetailsFail extends PyStateUtils.EntityFailAction {
  readonly type = StockInfoActionTypes.LoadStockInfoDetailsFail;

  constructor(public lookup: StockLookup, public payload?: any) {
    super(ARTICLE_STOCK_INFO_DETAILS, `${lookup.articleNumber}|${lookup.quantity}`, payload);
  }
}

export type StockInfoActions =
  | LoadStockInfo
  | LoadStockInfoSuccess
  | LoadStockInfoFail
  | LoadStockInfoDetails
  | LoadStockInfoDetailsSuccess
  | LoadStockInfoDetailsFail;
