import { Component, HostBinding, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SearchTermFacetMappingHint } from '../../../../../core/model';

@Component({
  selector: 'py-search-hints',
  templateUrl: './search-hints.component.html',
  styleUrls: ['./search-hints.component.scss'],
})
export class SearchHintsComponent {
  @Input() form: UntypedFormGroup;
  @Input() text?: string;
  @Input() hints: SearchTermFacetMappingHint[];
  @Input() @HostBinding('class.single-row') singleRow: boolean = false;
  @Input() @HostBinding('class.side-scroll') sideScroll: boolean = true;

  onHintClick(hint: SearchTermFacetMappingHint): void {
    this.form.get('search').setValue(hint);
  }
}
