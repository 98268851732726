import { Action } from '@ngrx/store';
import { AnonymousConsentsActions } from '../actions';
import { AnonymousConsentsActionTypes } from '../actions/anonymous-consents.action';

export const initialState = false;

export function reducer(state = initialState, action: Action): boolean {
  switch (action.type) {
    case AnonymousConsentsActionTypes.SetAnonymousConsentsSettingsVisible: {
      const _action = <AnonymousConsentsActions.SetAnonymousConsentsSettingsVisible>action;
      return _action.visible;
    }
  }

  return state;
}
