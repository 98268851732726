import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

export interface DownloadItem {
  code: string;
  name: string;
  icon: string;
}

@Component({
  selector: 'py-download-dropdown',
  templateUrl: './download-dropdown.component.html',
  styleUrls: ['./download-dropdown.component.scss'],
})
export class DownloadDropdownComponent {
  @ViewChild('popOver', { static: false }) popover: NgbPopover;

  @Input() buttonText: string;
  @Input() dropdownTitle: string;
  @Input() downloadItems$: Observable<DownloadItem[]>;
  @Input() downloadEnabled$: Observable<boolean>;
  @Input() hasSingleItem: boolean;
  @Input() isActionInProgress: boolean;
  @Input() placement?: string[] | string = 'bottom';

  @Output() downloadItem = new EventEmitter<string>();

  clickedItem: DownloadItem;

  closeDropdown(): void {
    this.popover.close();
  }

  download(item: DownloadItem): void {
    this.clickedItem = item;
    this.downloadItem.emit(item?.code);
  }

  downloadSingleItem(): void {
    this.downloadItems$
      .pipe(
        take(1),
        filter((items) => items?.length === 1),
        map((items) => items[0])
      )
      .subscribe((item) => this.downloadItem.emit(item?.code));
  }
}
