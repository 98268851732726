import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Image } from '../../../core/model';

@Component({
  selector: 'py-image-thumbnails',
  templateUrl: './image-thumbnails.component.html',
  styleUrls: ['./image-thumbnails.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageThumbnailsComponent implements OnInit {
  @Input() images: Image[];
  @Input() itemsCount: number;

  ngOnInit(): void {}
}
