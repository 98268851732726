import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CartType, FullPalletUpgrade } from '../../../../core/model';
import { CART_CORE_FEATURE } from '../store/cart-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: FullPalletUpgradeFacade,
      feature: CART_CORE_FEATURE,
      async: true,
      methods: ['load'],
    }),
})
export abstract class FullPalletUpgradeFacade {
  abstract load(userId: string, cartType: CartType, entryNumber: string): Observable<FullPalletUpgrade>;
}
