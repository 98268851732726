export function hasCmsStyleClass(className: string, styleClass: string): boolean {
  return className?.split && !!className.split(' ').find((c) => styleClass === c);
}

export function hasNoCmsTitleClass(className: string): boolean {
  if (!className?.split) {
    return true;
  }
  return !className.split(' ').find((c) => 'h1' === c || 'h2' === c || 'h3' === c || 'h4' === c || 'h5' === c || 'h6' === c);
}
