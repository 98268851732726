import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'py-catalog-article-overlay-message',
  templateUrl: './catalog-article-overlay-message.component.html',
  styleUrls: ['./catalog-article-overlay-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogArticleOverlayMessageComponent implements OnInit {
  isUserLoggedIn$: Observable<boolean>;

  @Input() expanded: boolean;
  @Input() articleRef: string;
  @Output() expandedChange = new EventEmitter<boolean>();

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.isUserLoggedIn$ = this.authService.isUserLoggedIn().pipe(
      filter((isUserLoggedIn) => Boolean(isUserLoggedIn)),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  onToggleExpanded(): void {
    this.expandedChange.emit(!this.expanded);
  }
}
