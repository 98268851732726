import { StateUtils } from '@spartacus/core';
import { UserActions } from '../actions';
import { StockInfoState } from '../user-state';

export const initialState: StockInfoState = {
  lastUpdateTime: undefined,
  stockInfo: undefined,
};

export function reducer(state = initialState, action: StateUtils.LoaderAction): StockInfoState {
  switch (action.type) {
    case UserActions.StockInfoActionTypes.LoadStockInfoSuccess: {
      return {
        lastUpdateTime: new Date().toISOString(),
        stockInfo: action.payload,
      };
    }
  }
  return state;
}
