import { SearchAction, SearchActionTypes } from '../../../features/store/actions/search.actions';
import { AvailableSearchParamsState } from '../user-state';

export const initialState: AvailableSearchParamsState = {
  key: undefined,
  count: 0,
  page: 0,
  text: '',
};

export function reducer(searchType: string) {
  return (state = initialState, action: SearchAction): AvailableSearchParamsState => {
    if (searchType !== action.searchType) {
      return state;
    }
    switch (action.type) {
      case SearchActionTypes.SearchAction:
        return action.searchParams;
      default:
        return state;
    }
  };
}
