import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ChangeSoldToActionTypes } from '../../../../features/sold-to-base/store/actions/sold-to-change.action';
import { BaseSiteActionTypes } from '../../../site-context/store/actions/base-site.action';
import { LanguagesActionTypes } from '../../../site-context/store/actions/languages.action';
import { normalizeHttpError, withdrawOn } from '../../../util';
import { SearchHintsConnector } from '../../connectors/search-hints.connector';
import { SearchHintsActions } from '../actions';

@Injectable()
export class SearchHintsEffects {
  private contextChange$: Observable<Action> = this.actions$.pipe(
    ofType(BaseSiteActionTypes.BaseSiteChange, LanguagesActionTypes.LanguageChange, ChangeSoldToActionTypes.ChangeSoldToSuccess)
  );

  loadSearchHints$: Observable<SearchHintsActions.LoadSearchHintsSuccess | SearchHintsActions.LoadSearchHintsFail> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SearchHintsActions.SearchHintsActionTypes.LoadSearchHints),
        mergeMap((action: SearchHintsActions.LoadSearchHints) => {
          return this.searchHintsConnector.load(action.userId).pipe(
            map((data) => new SearchHintsActions.LoadSearchHintsSuccess(data)),
            catchError((error) => of(new SearchHintsActions.LoadSearchHintsFail(normalizeHttpError(error))))
          );
        }),
        withdrawOn(this.contextChange$)
      )
  );

  constructor(private actions$: Actions, private searchHintsConnector: SearchHintsConnector) {}
}
