import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Subject, Subscription } from 'rxjs';
import { exhaustMap, filter, take, withLatestFrom } from 'rxjs/operators';
import { ReloadRequest, ReloadService } from '../../../core/reload';
import { ReloadModalBodyData } from './reload-modal-body.component';

@Component({
  selector: 'py-reload-modal',
  template: '',
})
export class ReloadModalComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  private activeRequest: ReloadRequest;

  constructor(private reloadService: ReloadService, private launchDialogService: LaunchDialogService, private router: Router) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.reloadService
        .getReloadRequests()
        .pipe(
          withLatestFrom(this.router.events.pipe(filter((event) => event instanceof NavigationEnd))), // needed to avoid modal showing too early causing error
          exhaustMap(([request, _event]) => {
            this.activeRequest = request;

            const modalRejected$ = new Subject<void>();

            const modalData: ReloadModalBodyData = {
              onAccept: () => this.activeRequest.accept(),
              onReject: () => this.activeRequest.reject(),
              modalRejected$: modalRejected$,
            };

            this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.RELOAD_MODAL_BODY, undefined, modalData);

            return modalRejected$.pipe(take(1)); // preventing opening new modal unless the previous one was rejected
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
