import { Injectable } from '@angular/core';
import { Category } from '../../../model/catalog.model';
import { Occ } from '../../../occ/occ-models';
import { Converter } from '../../../util/converter.service';

@Injectable({ providedIn: 'root' })
export class CategoryNormalizer implements Converter<Occ.Category, Category> {
  convert(source: Occ.Category, target?: Category): Category {
    if (source) {
      target = {
        name: source.name,
        code: source.code,
        description: source.description,
        banner: source.banner,
        image: source.image,
        superCategoryRef: source.superCategoryCode,
        subCategories: source.subCategories.length > 0 ? [...source.subCategories.map((c) => this.convert(c, {}))] : [],
        url: source.url,
        level: source.level,
        longDescription: source.longDescription,
        headline: source.headline,
        seo: source.seo,
        notPriceFileEnabled: source.notPriceFileEnabled,
      };
    }
    return target;
  }
}
