import { Action } from '@ngrx/store';
import { PaginationState, SearchPageResultState } from '../../../features/store/base-feature-state';
import { OrderApprovalSearchResult } from '../../../model';
import { loaderReducer } from '../../../state/utils/loader';
import { UserActions } from '../actions/index';
import { ORDER_APPROVALS, ORDER_APPROVALS_ADMIN } from '../user-state';
import { reducer as orderApprovalsEntriesReducer } from './order-approval-entries-reducer';

export const initialState: PaginationState<SearchPageResultState> = {
  lastUpdateTime: undefined,
  pages: {},
  pagination: undefined,
  sorts: [],
};

export function reducer(state = initialState, action: Action): any {
  switch (action.type) {
    case UserActions.OrderApprovalActionTypes.LOAD_ORDER_APPROVALS_ADMIN:
    case UserActions.OrderApprovalActionTypes.LOAD_ORDER_APPROVALS_ADMIN_FAIL: {
      const loadAction: any = action;
      const searchParams = loadAction.searchParams;
      const loaderState = loaderReducer<SearchPageResultState>(ORDER_APPROVALS_ADMIN, orderApprovalsEntriesReducer)(
        undefined,
        loadAction
      );
      return {
        ...state,
        pagination: initialState.pagination,
        pages: {
          ...state.pages,
          [searchParams.page]: loaderState,
        },
      };
    }
    case UserActions.OrderApprovalActionTypes.LOAD_ORDER_APPROVALS:
    case UserActions.OrderApprovalActionTypes.LOAD_ORDER_APPROVALS_FAIL: {
      const loadAction: any = action;
      const searchParams = loadAction.searchParams;
      const loaderState = loaderReducer<SearchPageResultState>(ORDER_APPROVALS, orderApprovalsEntriesReducer)(
        undefined,
        loadAction
      );
      return {
        ...state,
        pagination: initialState.pagination,
        pages: {
          ...state.pages,
          [searchParams.page]: loaderState,
        },
      };
    }
    case UserActions.OrderApprovalActionTypes.LOAD_ORDER_APPROVALS_SUCCESS: {
      const successAction = <UserActions.LoadOrderApprovalsSuccess>action;
      const payload: OrderApprovalSearchResult = successAction.payload;
      const searchParams = successAction.searchParams;
      const loaderState = loaderReducer<SearchPageResultState>(ORDER_APPROVALS, orderApprovalsEntriesReducer)(
        { ...state.pages[searchParams.page] },
        successAction
      );
      return {
        ...state,
        lastUpdateTime: new Date().toISOString(),
        pagination: payload.pagination,
        sorts: payload.sorts,
        pages: {
          ...state.pages,
          [searchParams.page]: loaderState,
        },
      };
    }
    case UserActions.OrderApprovalActionTypes.LOAD_ORDER_APPROVALS_ADMIN_SUCCESS: {
      const successAction = <UserActions.LoadOrderApprovalsAdminSuccess>action;
      const payload: OrderApprovalSearchResult = successAction.payload;
      const searchParams = successAction.searchParams;
      const loaderState = loaderReducer<SearchPageResultState>(ORDER_APPROVALS_ADMIN, orderApprovalsEntriesReducer)(
        { ...state.pages[searchParams.page] },
        successAction
      );
      return {
        ...state,
        lastUpdateTime: new Date().toISOString(),
        pagination: payload.pagination,
        sorts: payload.sorts,
        pages: {
          ...state.pages,
          [searchParams.page]: loaderState,
        },
      };
    }
    case UserActions.OrderApprovalActionTypes.LOAD_ORDER_APPROVALS_RESET:
    case UserActions.OrderApprovalActionTypes.LOAD_ORDER_APPROVALS_ADMIN_RESET: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
