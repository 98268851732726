<ng-container *ngIf="{ timeout: timeout$ | async } as data">
  <py-modal
    [actions]="templateActions"
    [showCancelButton]="false"
    [title]="'common.applicationUpdated_title' | cxTranslate"
    [scrollable]="true"
    [centered]="true"
    [blockDismissCoreAction]="true"
    [cxFocus]="focusConfig"
    (dismiss)="reject()"
    (esc)="reject()"
  >
    <div>
      <p [innerHTML]="'common.applicationUpdated_text' | cxTranslate"></p>
    </div>
  </py-modal>

  <ng-template #templateActions>
    <py-action-button [disabled]="isReloading" [buttonClass]="'btn btn-primary'" (click)="accept()">
      <span *ngIf="!isReloading">{{ 'common.reloadApplicationNow_action' | cxTranslate : { timeout: data.timeout } }}</span>
      <span *ngIf="isReloading">{{ 'common.reloadApplicationNow_hint' | cxTranslate }}</span>
    </py-action-button>
    <py-action-button [disabled]="isReloading" [buttonClass]="'btn btn-ternary'" (click)="reject()">{{
      'common.reloadApplicationLater_action' | cxTranslate
    }}</py-action-button>
  </ng-template>
</ng-container>
