import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Country, CountryType } from '../../model/address.model';
import { BaseSite, Currency, Language, SiteConfig } from '../../model/misc.model';
import { SiteAdapter } from './site.adapter';

@Injectable({
  providedIn: 'root',
})
export class SiteConnector {
  constructor(protected adapter: SiteAdapter) {}

  getLanguages(): Observable<Language[]> {
    return this.adapter.loadLanguages();
  }

  getCurrencies(): Observable<Currency[]> {
    return this.adapter.loadCurrencies();
  }

  getCountriesByType(userId: string, type?: CountryType): Observable<Country[]> {
    return this.adapter.loadCountriesByType(userId, type);
  }

  getCountries(userId: string, countryCodes: string[]): Observable<Country[]> {
    return this.adapter.loadCountriesByCountryCodes(userId, countryCodes);
  }

  getBaseSite(siteUid?: string): Observable<BaseSite> {
    return this.adapter.loadBaseSite(siteUid);
  }

  getBaseSites(): Observable<BaseSite[]> {
    return this.adapter.loadBaseSites();
  }

  getSiteConfig(): Observable<SiteConfig> {
    return this.adapter.loadSiteConfig();
  }
}
