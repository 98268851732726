import { StateUtils } from '@spartacus/core';
import { UserActions } from '../actions';
import { ArticlePriceState } from '../user-state';

export const initialState: ArticlePriceState = {
  lastUpdateTime: undefined,
  price: undefined,
  attempts: 0,
};

export function reducer(state = initialState, action: StateUtils.LoaderAction): ArticlePriceState {
  switch (action.type) {
    case UserActions.PriceActionTypes.LoadArticlePriceSuccess: {
      return {
        ...state,
        lastUpdateTime: new Date().toISOString(),
        price: action.payload,
        attempts: 0,
      };
    }
    case UserActions.PriceActionTypes.LoadArticlePriceFail: {
      const failAction = <UserActions.LoadArticlePriceFail>action;
      if (state.attempts + 1 >= 5) {
        return {
          ...state,
          attempts: state.attempts + 1,
          price: failAction.articlePrice,
        };
      }
      if (state.price === undefined) {
        return { ...state, attempts: state.attempts + 1 };
      }
    }
  }

  return state;
}
