import { PagedSearchAction } from '../../../features/store/actions/search.actions';
import { PaginationState, SearchPageResultState } from '../../../features/store/base-feature-state';
import { AccountingCodePage } from '../../../model';
import { loaderReducer } from '../../../state/utils/loader';
import { UserActions } from '../actions';
import { AccountingCodesAdminActionTypes } from '../actions/accounting-codes-admin.action';
import { ACCOUNTING_CODES_ADMIN } from '../user-state';
import { reducer as accountingCodeAdminEntryReducer } from './accounting-code-admin-entries.reducer';

export const initialSearchState: PaginationState<SearchPageResultState> = {
  lastUpdateTime: undefined,
  pages: {},
  pagination: undefined,
  sorts: [],
};

export function reducer(state: PaginationState<SearchPageResultState> = initialSearchState, action: PagedSearchAction) {
  switch (action.type) {
    case AccountingCodesAdminActionTypes.SearchAccountingCodesAdmin:
    case AccountingCodesAdminActionTypes.SearchAccountingCodesAdminFail: {
      const _action = <UserActions.SearchAccountingCodesAdmin>action;
      const searchParams = _action.payload.searchParams;
      const loaderState = loaderReducer<SearchPageResultState>(ACCOUNTING_CODES_ADMIN, accountingCodeAdminEntryReducer)(
        undefined,
        _action
      );

      return {
        ...state,
        pages: {
          ...state.pages,
          [searchParams.page]: loaderState,
        },
      };
    }
    case AccountingCodesAdminActionTypes.SearchAccountingCodesAdminSuccess: {
      const _action = <UserActions.SearchAccountingCodesAdminSuccess>action;
      const payload: AccountingCodePage = _action.payload.searchResult;
      const loaderState = loaderReducer<SearchPageResultState>(ACCOUNTING_CODES_ADMIN, accountingCodeAdminEntryReducer)(
        { ...state.pages[payload.pagination.page] },
        _action
      );

      return {
        ...state,
        lastUpdateTime: new Date().toISOString(),
        pagination: payload.pagination,
        sorts: payload.sorts,
        pages: {
          ...state.pages,
          [payload.pagination.page]: loaderState,
        },
      };
    }
  }
  return state;
}
