import { Injectable } from '@angular/core';
import { ClassificationAttribute } from '../../../model';
import { Occ } from '../../../occ';
import { Converter } from '../../../util';

@Injectable({ providedIn: 'root' })
export class ClassificationAttributeNormalizer implements Converter<Occ.ClassificationAttribute, ClassificationAttribute> {
  convert(source: Occ.ClassificationAttribute, target?: ClassificationAttribute): ClassificationAttribute {
    return source
      ? {
          code: source.value?.code,
          rawValue: source.value?.rawValue,
          unitString: source.value?.unitString,
          value: source.value?.value,
          name: source.value?.name,
        }
      : target;
  }
}
