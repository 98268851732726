import { Action } from '@ngrx/store';
import { EcConformityProductDocumentsSearchParams } from '../../../../core/model';
import { DocumentActions } from '../actions';
import { DocumentActionTypes } from '../actions/documents.action';

export const initialConformanceEcStateSearchParamsState: EcConformityProductDocumentsSearchParams = {
  key: 'latest',
  page: 0,
  count: 10,
};

export function reducer(
  state = initialConformanceEcStateSearchParamsState,
  action: Action
): EcConformityProductDocumentsSearchParams {
  switch (action.type) {
    case DocumentActionTypes.SearchConformanceEcSearchParams:
      const _action = <DocumentActions.SearchConformanceEcSearchParams>action;
      return _action.payload.searchParams;
    default:
      return state;
  }
}
