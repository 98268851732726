import { APP_INITIALIZER, Provider } from '@angular/core';
import { BaseSiteInitializer, CurrencyInitializer, LanguageInitializer } from '@spartacus/core';
import { SitePrefixInitializer } from '../services/site-prefix-initializer';

export function initializeCurrency(currencyInitializer: CurrencyInitializer) {
  const result = () => {
    currencyInitializer.initialize();
  };
  return result;
}

export function initializeLanguage(languageInitializer: LanguageInitializer) {
  const result = () => {
    languageInitializer.initialize();
  };
  return result;
}

export function initializeSitePrefix(sitePrefixInitializer: SitePrefixInitializer) {
  const result = () => {
    sitePrefixInitializer.initialize();
  };
  return result;
}

export function initializeBaseSite(baseSiteInitializer: BaseSiteInitializer) {
  const result = () => {
    baseSiteInitializer.initialize();
  };
  return result;
}

export const contextInitializerProviders: Provider[] = [
  {
    provide: APP_INITIALIZER,
    useFactory: initializeLanguage,
    deps: [LanguageInitializer],
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: initializeCurrency,
    deps: [CurrencyInitializer],
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: initializeSitePrefix,
    deps: [SitePrefixInitializer],
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: initializeBaseSite,
    deps: [BaseSiteInitializer],
    multi: true,
  },
];
