import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StateConfig, StateModule, StateTransferType, provideDefaultConfigFactory } from '@spartacus/core';
import { effects } from './effects';
import { metaReducers, reducerProvider, reducerToken } from './reducers';
import { SOLR_SEARCH_FEATURE } from './solr-search-state';

export function searchStoreConfigFactory(): StateConfig {
  const config: StateConfig = {
    state: {
      ssrTransfer: {
        keys: {
          [SOLR_SEARCH_FEATURE]: StateTransferType.TRANSFER_STATE,
        },
      },
    },
  };
  return config;
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StateModule,
    StoreModule.forFeature(SOLR_SEARCH_FEATURE, reducerToken, { metaReducers }),
    EffectsModule.forFeature(effects),
  ],
  providers: [reducerProvider, provideDefaultConfigFactory(searchStoreConfigFactory)],
})
export class SearchStoreModule {}
