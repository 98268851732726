import { AnonymousConsentsActions as CxAnonymousConsentsActions } from '@spartacus/core';

export const initialState = false;

export function reducer(
  state = initialState,
  action: CxAnonymousConsentsActions.ToggleAnonymousConsentsBannerDissmissed
): boolean {
  switch (action.type) {
    case CxAnonymousConsentsActions.TOGGLE_ANONYMOUS_CONSENTS_BANNER_DISMISSED: {
      return action.dismissed;
    }
  }
  return state;
}
