import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PriceFileFormat, PriceFileFormatsSearchResult } from '../../../model/price-file.model';

@Injectable({
  providedIn: 'root',
})
export class PriceFileFormatConnector {
  constructor(private httpClient: HttpClient, private occEndpointsService: OccEndpointsService) {}

  load(userId: string): Observable<Array<PriceFileFormat>> {
    return this.httpClient
      .get<PriceFileFormatsSearchResult>(
        this.occEndpointsService.buildUrl('priceFileTemplateFormats', {
          urlParams: { userId: userId },
          queryParams: {
            fields: 'FULL',
            page: 0,
            count: 50,
          },
        })
      )
      .pipe(
        // There will only be one page for the foreseeable future.
        // So we just parse the returned page into a list of formats
        // which is a lot easier for the client to handle
        map((res: PriceFileFormatsSearchResult) => (!res ? [] : res.results))
      );
  }
}
