import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../core/i18n';
import { UrlModule } from '../../../core/routing';
import { OverlayMessageModule } from '../overlay-message';
import { CartOverlayMessageComponent } from './cart-overlay-message.component';

@NgModule({
  imports: [CommonModule, RouterModule, UrlModule, I18nModule, NzIconModule, OverlayMessageModule],
  declarations: [CartOverlayMessageComponent],
  exports: [CartOverlayMessageComponent],
})
export class CartOverlayMessageModule {}
