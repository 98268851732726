import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SoldToActions } from '../../../../../features/sold-to-base/store/actions';
import { UserIdService } from '../../../../auth';
import { LoginLogoutActionTypes } from '../../../../auth/user-auth/store/actions/login-logout.action';
import { GlobalMessageType } from '../../../../global-message';
import { GlobalMessageActions } from '../../../../global-message/store';
import { PrincipalConfiguration } from '../../../../model';
import { CurrenciesActionTypes } from '../../../../site-context/store/actions/currencies.action';
import { LanguagesActionTypes } from '../../../../site-context/store/actions/languages.action';
import { normalizeHttpError } from '../../../../util';
import { PrincipalConfigurationConnector } from '../../../connectors/principal-configuration-connector';
import { UserActions } from '../../../store/actions';
import { PrincipalConfigurationActions } from '../../../store/actions/principal-configuration.action';
import { UserModuleLoadedActions } from '../../../store/actions/user.actions';

@Injectable()
export class PrincipalConfigurationEffects {
  loadPrincipalConfigurationValues$: Observable<PrincipalConfigurationActions> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.PrincipalConfigurationActionTypes.LoadPrincipalConfigurationValues),
      map((action: UserActions.LoadPrincipalConfigurationValues) => action.payload),
      switchMap((payload) =>
        this.connector.getValues(payload).pipe(
          map((data) => new UserActions.LoadPrincipalConfigurationValuesSuccess(data)),
          catchError((error) => of(new UserActions.LoadPrincipalConfigurationValuesFail(normalizeHttpError(error))))
        )
      )
    )
  );

  loadPrincipalConfigurationValuesAfterContextChange$: Observable<PrincipalConfigurationActions> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SoldToActions.ChangeSoldToActionTypes.ChangeSoldToSuccess,
        UserModuleLoadedActions.USER_MODULE_LOADED,
        LoginLogoutActionTypes.Login,
        LoginLogoutActionTypes.Logout,
        LanguagesActionTypes.LanguageChange,
        CurrenciesActionTypes.CurrencyChange
      ),
      switchMap(() =>
        this.userIdService.takeUserId().pipe(
          map((userId) => new UserActions.LoadPrincipalConfigurationValues(userId)),
          catchError(() => EMPTY)
        )
      )
    )
  );

  updatePrincipalConfigurationValue$: Observable<
    UserActions.UpdatePrincipalConfigurationValueSuccess | UserActions.UpdatePrincipalConfigurationValueFail
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.PrincipalConfigurationActionTypes.UpdatePrincipalConfigurationValue),
      switchMap((payload: UserActions.UpdatePrincipalConfigurationValue) =>
        this.connector.updateValue(payload.userId, payload.configValue).pipe(
          map((data) => new UserActions.UpdatePrincipalConfigurationValueSuccess(data)),
          catchError((error) => of(new UserActions.UpdatePrincipalConfigurationValueFail(normalizeHttpError(error))))
        )
      )
    )
  );

  handleSuccessForPrincipalConfigurationValue$: Observable<GlobalMessageActions.AddMessage> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.PrincipalConfigurationActionTypes.UpdatePrincipalConfigurationValueSuccess),
      map(
        (payload: PrincipalConfiguration) =>
          new GlobalMessageActions.AddMessage({
            text: {
              key: 'common.nameMarking.displayNameUpdated_hint',
              params: { displayName: payload?.clientDisplayName || payload?.code },
            },
            type: GlobalMessageType.MSG_TYPE_CONFIRMATION,
          })
      )
    )
  );

  handleFailureForPrincipalConfigurationValue$: Observable<GlobalMessageActions.AddMessage> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.PrincipalConfigurationActionTypes.UpdatePrincipalConfigurationValueFail),
      map(
        (payload: PrincipalConfiguration) =>
          new GlobalMessageActions.AddMessage({
            text: {
              key: 'common.nameMarking.displayNameUpdateFailed_hint',
              params: { displayName: payload?.clientDisplayName || payload?.code },
            },
            type: GlobalMessageType.MSG_TYPE_ERROR,
          })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private connector: PrincipalConfigurationConnector,
    private userIdService: UserIdService
  ) {}
}
