import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Article, CuttingRequest, OrderEntry } from '../../../core/model';

@Component({
  selector: 'py-reaming-button',
  templateUrl: './reaming-button.component.html',
  styleUrls: ['./reaming-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReamingButtonComponent implements OnDestroy {
  @Input() article: Article;
  @Input() entry: OrderEntry;
  @Input() disabled: boolean = false;
  @Input() showButton: boolean = false;
  @Input() translationKeyPrefix: string = 'cart';

  @Output() handleSave = new EventEmitter<CuttingRequest>();
  @Output() handleRemove = new EventEmitter<void>();

  subscription = new Subscription();

  constructor() {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  save(): void {
    this.handleSave.emit();
  }

  remove(): void {
    this.handleRemove.emit();
  }
}
