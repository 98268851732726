import { Action } from '@ngrx/store';
import * as CartActions from './cart.actions';
import { CartEntryActionTypes } from './cart.actions';

export { CartActions, CartEntryActionTypes };

export enum CartModuleLoadedActions {
  CART_MODULE_LOADED = '[Cart] Module loaded',
}

export class CartModuleLoaded implements Action {
  readonly type = CartModuleLoadedActions.CART_MODULE_LOADED;

  constructor() {}
}
