import { Action } from '@ngrx/store';
import { PaginationState, SearchPageResultState } from '../../../features/store/base-feature-state';
import { SearchPaginationUtil } from '../../../features/store/util/search-pagination.util';
import { DeliveryAddressSearchResult } from '../../../model';
import { loaderReducer } from '../../../state/utils/loader';
import { UserActions } from '../actions';
import {
  DeleteDeliveryAddressSuccess,
  DeliveryAddressActionTypes,
  SaveDeliveryAddressSuccess,
} from '../actions/delivery-address.action';
import { DELIVERY_ADDRESS } from '../user-state';
import { reducer as deliveryAddressEntriesReducer } from './delivery-address-entries.reducer';

export const initialState: PaginationState<SearchPageResultState> = {
  lastUpdateTime: undefined,
  pages: {},
  pagination: undefined,
  sorts: [],
};

export function reducer(state = initialState, action: Action): any {
  switch (action.type) {
    case UserActions.DeliveryAddressActionTypes.LoadDeliveryAddresses:
    case UserActions.DeliveryAddressActionTypes.LoadDeliveryAddressesFail: {
      const deliveryAddressesAction = <UserActions.DeliveryAddressesActions>action;
      const searchParams = deliveryAddressesAction.searchParams;
      const loaderState = loaderReducer<SearchPageResultState>(DELIVERY_ADDRESS, deliveryAddressEntriesReducer)(
        undefined,
        deliveryAddressesAction
      );
      return {
        ...state,
        pages: {
          ...state.pages,
          [searchParams.page]: loaderState,
        },
      };
    }
    case UserActions.DeliveryAddressActionTypes.LoadDeliveryAddressesSuccess: {
      const successAction = <UserActions.LoadDeliveryAddressesSuccess>action;
      const payload: DeliveryAddressSearchResult = successAction.payload;
      const searchParams = successAction.searchParams;
      const loaderState = loaderReducer<SearchPageResultState>(DELIVERY_ADDRESS, deliveryAddressEntriesReducer)(
        { ...state.pages[searchParams.page] },
        successAction
      );
      return {
        ...state,
        lastUpdateTime: new Date().toISOString(),
        pagination: payload.pagination,
        sorts: payload.sorts,
        pages: {
          ...state.pages,
          [searchParams.page]: loaderState,
        },
      };
    }
    case DeliveryAddressActionTypes.SaveDeliveryAddressSuccess: {
      const successAction = <SaveDeliveryAddressSuccess>action;
      if (successAction.isNew) {
        return SearchPaginationUtil.pushEntry<SearchPageResultState>(successAction.payload.id, state);
      }
      return state;
    }
    case DeliveryAddressActionTypes.DeleteDeliveryAddressSuccess: {
      const successAction = <DeleteDeliveryAddressSuccess>action;
      const id = [].concat(successAction.payload).join();
      return SearchPaginationUtil.popEntry(id, state);
    }
    default:
      return state;
  }
}
