<ng-container *ngIf="{ campaignList: campaignList$ | async, mya: mya$ | async } as data">
  <ng-container *ngIf="data.campaignList?.entries?.length > 0 && data.campaignList?.allCampaignsLink">
    <li py-megamenu-list-item [isDirectLink]="true">
      <cx-generic-link [url]="getUrl(data.campaignList.allCampaignsLink, { mya: data.mya ? '1' : '0' })">
        {{ 'navigation.seeAllCampaigns_action' | cxTranslate }}
      </cx-generic-link>
    </li>
  </ng-container>

  <ng-container *ngFor="let campaign of data.campaignList?.entries || []">
    <li py-megamenu-list-item>
      <cx-generic-link [url]="getUrl(campaign.link, { mya: data.mya ? '1' : '0' })">
        {{ campaign?.description }}
      </cx-generic-link>
    </li>
  </ng-container>
</ng-container>
