import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';

@Component({
  selector: 'py-add-shopping-list',
  templateUrl: './add-shopping-list.component.html',
  styleUrls: ['./add-shopping-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddShoppingListComponent implements OnDestroy {
  @Output() create = new EventEmitter<void>();

  private subscriptions = new Subscription();
  loading: boolean;

  constructor(private launchDialogService: LaunchDialogService) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onCreate(): void {
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.CREATE_SHOPPING_LIST);
  }
}
