import { Action } from '@ngrx/store';
import { EntriesState } from '../../../features/store/base-feature-state';
import { DeliveryAddress, ShipToPage } from '../../../model/address.model';
import { UserActions } from '../actions';

export const initialState: EntriesState<DeliveryAddress> = {};

export function reducer(state = initialState, action: Action): EntriesState<DeliveryAddress> {
  switch (action.type) {
    case UserActions.LOAD_USER_SHIPTOS_SUCCESS: {
      const shipTosSuccess = <UserActions.LoadUserShipTosSuccess>action;
      const payload: ShipToPage = shipTosSuccess.payload;

      const results = payload.results;

      return results.reduce(
        (entities: { [id: string]: DeliveryAddress }, entry: DeliveryAddress) => {
          return {
            ...entities,
            [entry.id]: entry,
          };
        },
        {
          ...state,
        }
      );
    }
    default:
      return state;
  }
}
