import { DIALOG_TYPE, LAUNCH_CALLER, LayoutConfig } from '@spartacus/storefront';
import { PrePrintedModalComponent } from './pre-printed-modal.component';

export const prePrintedModalLayoutConfig: LayoutConfig = {
  launch: {
    [LAUNCH_CALLER.PRE_PRINTED]: {
      inlineRoot: true,
      component: PrePrintedModalComponent,
      dialogType: DIALOG_TYPE.DIALOG,
      dialogOptions: {
        onTop: true,
      },
    },
  },
};
