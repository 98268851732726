import { HttpResponseStatus } from '../models/response-status.model';

export function isErrorGloballyHandled(status: number): boolean {
  switch (status) {
    case HttpResponseStatus.GONE:
    case HttpResponseStatus.FORBIDDEN:
    case HttpResponseStatus.CONFLICT:
    case HttpResponseStatus.BAD_GATEWAY:
    case HttpResponseStatus.GATEWAY_TIMEOUT:
    case HttpResponseStatus.INTERNAL_SERVER_ERROR:
      return true;
    default:
      return false;
  }
}
