import { MemoizedSelector, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { LoaderError } from '../../../model';
import { Consent, ConsentTemplate } from '../../../model/consent.model';
import { PyStateUtils } from '../../../state/utils';
import { StateWithUser, UserState } from '../user-state';
import { getUserState } from './feature.selector';

export const getConsentsState: MemoizedSelector<StateWithUser, StateUtils.LoaderState<ConsentTemplate[]>> = createSelector(
  getUserState,
  (state: UserState) => state.consents
);

export const getConsentsNotGivenState: MemoizedSelector<StateWithUser, StateUtils.LoaderState<Consent[]>> = createSelector(
  getUserState,
  (state: UserState) => state.consentsNotGiven
);

export const getConsentsValue: MemoizedSelector<StateWithUser, ConsentTemplate[]> = createSelector(
  getConsentsState,
  StateUtils.loaderValueSelector
);

export const getConsentByTemplateId = (templateId: string): MemoizedSelector<StateWithUser, ConsentTemplate> =>
  createSelector(getConsentsValue, (templates) => templates && templates.find((template) => template.id === templateId));

export const getConsentsLoading: MemoizedSelector<StateWithUser, boolean> = createSelector(
  getConsentsState,
  StateUtils.loaderLoadingSelector
);

export const getConsentsSuccess: MemoizedSelector<StateWithUser, boolean> = createSelector(
  getConsentsState,
  StateUtils.loaderSuccessSelector
);

export const getConsentsErrorDetails: MemoizedSelector<StateWithUser, LoaderError> = createSelector(
  getConsentsState,
  PyStateUtils.loaderErrorDetailsSelector
);

export const getConsentsBanner: MemoizedSelector<StateWithUser, boolean> = createSelector(
  getUserState,
  (state: UserState) => state.consentsBanner
);

export const getConsentsSettingsVisible: MemoizedSelector<StateWithUser, boolean> = createSelector(
  getUserState,
  (state: UserState) => state.consentsSettingsModal
);
