import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PageLayoutService } from '@spartacus/storefront';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'cx-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageLayoutComponent {
  @Input() set section(value: string) {
    this.section$.next(value);
  }
  readonly section$: BehaviorSubject<string> = new BehaviorSubject(undefined);

  readonly templateName$: Observable<string> = this.pageLayoutService.templateName$;

  readonly layoutName$: Observable<string> = this.section$.pipe(
    switchMap((section) => (section ? of(section) : this.templateName$))
  );

  readonly slots$: Observable<string[]> = this.section$.pipe(switchMap((section) => this.pageLayoutService.getSlots(section)));

  readonly pageFoldSlot$: Observable<string> = this.templateName$.pipe(
    switchMap((templateName) => this.pageLayoutService.getPageFoldSlot(templateName)),
    distinctUntilChanged()
  );

  constructor(protected pageLayoutService: PageLayoutService) {}
}
