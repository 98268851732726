<div>
  <py-header-navigation-item [active]="show" (click)="toggleDrawer()"
    >{{ 'navigation.areaOfUse_title' | cxTranslate }}<i nz-icon nzType="down" class="ml-2" [class.rotate]="show"></i
  ></py-header-navigation-item>

  <ng-template #drawerTemplate *ngIf="{ activeCategory: activeCategory$ | async } as data">
    <div
      #megamenuContainer
      py-megamenu
      [templateColumnLeft]="megamenuColumnLeft"
      [templateColumnRight]="megamenuColumnRight"
    ></div>

    <ng-template #megamenuColumnLeft>
      <py-menu-area-of-use-categories [activeCategory]="data.activeCategory" (setActiveCategory)="setActiveCategory($event)">
      </py-menu-area-of-use-categories>
    </ng-template>

    <ng-template #megamenuColumnRight>
      <py-menu-area-of-use-sub-categories [category]="data.activeCategory"></py-menu-area-of-use-sub-categories>
    </ng-template>
  </ng-template>
</div>
