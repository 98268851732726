<ng-container *ngIf="addonGroup$ | async as addonGroup">
  <ng-container *ngIf="addonGroup">
    <li py-megamenu-list-item [isDirectLink]="true">
      <cx-generic-link
        [routerUrl]="{ cxRoute: 'addon', params: { title: addonGroup.title } } | cxUrl"
        [queryParams]="{ ag: addonGroup.id }"
        class="view-all"
      >
        {{ 'navigation.viewAllInAddonGroup_action' | cxTranslate : { addonGroup: addonGroup.title } }}
        <i nz-icon nzType="arrow-right"></i>
      </cx-generic-link>
    </li>
  </ng-container>
  <ng-container *ngFor="let child of addonGroup?.children || []">
    <li py-megamenu-list-item>
      <cx-generic-link
        [routerUrl]="{ cxRoute: 'addon', params: { title: addonGroup.title } } | cxUrl"
        [queryParams]="{ ag: child.id }"
        class="list-item-link"
      >
        {{ child.title }}
      </cx-generic-link>
    </li>
  </ng-container>
  <ng-container *ngFor="let node of addonGroup?.nodes || []">
    <li py-megamenu-list-item>
      <cx-generic-link
        [routerUrl]="{ cxRoute: 'addon', params: { title: addonGroup.title } } | cxUrl"
        [queryParams]="{ ag: addonGroup.id, an: node.id }"
        class="list-item-link"
      >
        {{ node.title }}
      </cx-generic-link>
    </li>
  </ng-container>
</ng-container>
