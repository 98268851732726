import { Injectable } from '@angular/core';
import {
  SiteContextConfigInitializer as CxSiteContextConfigInitializer,
  JavaRegExpConverter,
  SiteContextConfig,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { BaseSite } from '../../../model/misc.model';
import { WindowRef } from '../../../window/window-ref';
import { BaseSiteService } from '../../facade/base-site.service';
import { SITE_PREFIX_CONTEXT_ID } from '../../providers/context-ids';

@Injectable({ providedIn: 'root' })
export class SiteContextConfigInitializer extends CxSiteContextConfigInitializer {
  constructor(
    protected baseSiteService: BaseSiteService,
    protected javaRegExpConverter: JavaRegExpConverter,
    protected winRef: WindowRef
  ) {
    super(baseSiteService, javaRegExpConverter, winRef);
  }

  private get _currentUrl(): string {
    return this.winRef.location.href as string;
  }

  private get _currentUrlOrigin(): string {
    return this.winRef.location.origin as string;
  }

  /**
   * Emits the site context config basing on the current base site data.
   *
   * Completes after emitting the value.
   */
  protected resolveConfig(): Observable<SiteContextConfig> {
    return this.baseSiteService.getAll().pipe(
      map((baseSites) => {
        let matchedBaseSite = baseSites?.find((site) => this._isCurrentBaseSite(site));

        if (!matchedBaseSite) {
          matchedBaseSite = baseSites?.find((site) => this._hasGlobalSite(site));

          if (matchedBaseSite) {
            this.baseSiteService.setIsFallback(true);
          } else {
            throw new Error(
              `Error: Cannot get base site config! Current url (${this._currentUrl}) or url origin (${this._currentUrlOrigin}) doesn't match any of url patterns of any base sites.`
            );
          }
        }
        return matchedBaseSite;
      }),
      map((baseSite) => this.getConfig(baseSite)),
      take(1)
    );
  }

  protected getConfig(source: BaseSite): SiteContextConfig {
    const config = super.getConfig(source);
    const result = {
      context: {
        ...config.context,
        [SITE_PREFIX_CONTEXT_ID]: [source.sitePrefix],
      },
    } as SiteContextConfig;

    return result;
  }

  /**
   * Checks if current URL matches the base site url patterns.
   */
  private _isCurrentBaseSite(site: BaseSite): boolean {
    return this.matchesSiteUrlPattern(site, this._currentUrl);
  }

  /**
   * Checks if the current URL origin matches the base site url patterns.
   * Used to find the global base site to be used as a fallback.
   */
  private _hasGlobalSite(site: BaseSite): boolean {
    return this.matchesSiteUrlPattern(site, this._currentUrlOrigin);
  }

  /**
   * Checks if provided url string matches any of the url patterns from given base site
   */
  private matchesSiteUrlPattern(site: BaseSite, url: string) {
    const index = (site.urlPatterns || []).findIndex((javaRegexp: string) => {
      const jsRegexp = this.javaRegExpConverter.toJsRegExp(javaRegexp);
      if (jsRegexp) {
        const result = jsRegexp.test(url);
        return result;
      }
    });

    return index !== -1;
  }
}
