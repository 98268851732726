import { isPlatformServer } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  QueryList,
  Renderer2,
  Self,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { EventService } from '@spartacus/core';
import { BreakpointService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, shareReplay, skip } from 'rxjs/operators';
import { SelectEcommerceItemEvent } from '../../../core/user';

/**
 * TODO: Currently assumes items to be of equal width
 */
@Component({
  selector: 'py-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChildren('listItem') listItemElements: QueryList<ElementRef<HTMLLIElement>>;
  @ViewChild('list', { static: true }) listElementRef: ElementRef<HTMLUListElement>;
  @ViewChild('main', { static: true }) mainElementRef: ElementRef<HTMLElement>;
  @ViewChild('backPagination', { static: false }) backPaginationElementRef: ElementRef<HTMLButtonElement>;
  @ViewChild('forwardPagination', { static: false }) forwardPaginationElementRef: ElementRef<HTMLButtonElement>;

  @Input() items: any[];
  @Input() hideOverflow: boolean = false;
  @Input() browseReplacementUrl: string;
  @Input() showPaginationAtTheBottom: boolean = false;
  @Input() listItemContentTemplate: TemplateRef<HTMLLIElement>;

  readonly listItemMarginPx = 20;

  private currentIndex = 0;
  private subscription = new Subscription();

  private get listScrollPosition(): number {
    return Math.min(this.maxScrollPosition, this.currentIndex * this.scrollInterval);
  }

  private get listItemHeight(): number {
    if (isPlatformServer(this.platformId)) {
      return 0;
    }
    return this.listItemElementRef?.nativeElement.getBoundingClientRect().height;
  }

  private get listItemElementRef(): ElementRef<HTMLLIElement> {
    return this.listItemElements?.first;
  }

  private get numberOfItemsInView(): number {
    return this.listItemOuterWidth ? Math.floor(this.containerWidth / this.listItemOuterWidth) : 0;
  }

  private get scrollInterval(): number {
    return this.listItemOuterWidth;
  }

  private get itemsCount(): number {
    if (!!this.browseReplacementUrl) {
      return (this.items?.length || 0) + 1;
    }
    return this.items?.length || 0;
  }

  get canScrollBack(): boolean {
    return this.currentIndex > 0;
  }

  get canScrollForward(): boolean {
    return this.currentIndex < this.itemsCount - this.numberOfItemsInView;
  }

  private get listItemOuterWidth(): number | null {
    if (isPlatformServer(this.platformId)) {
      return 0;
    }
    const width = this.listItemElementRef?.nativeElement.offsetWidth;
    return width ? width + this.listItemMarginPx : null;
  }

  private get containerWidth(): number {
    return this.el.nativeElement.offsetWidth;
  }

  private get maxScrollPosition(): number {
    const overflowingItemsWidth = (this.itemsCount - this.numberOfItemsInView) * this.scrollInterval;
    const lastItemVisibleWidth = this.containerWidth - this.numberOfItemsInView * this.listItemOuterWidth;
    return Math.max(0, overflowingItemsWidth - lastItemVisibleWidth - this.listItemMarginPx);
  }

  constructor(
    @Self() private el: ElementRef,
    private renderer: Renderer2,
    private breakpointService: BreakpointService,
    @Inject(PLATFORM_ID) private platformId: string,
    private eventService: EventService
  ) {}

  ngOnInit(): void {
    this.breakpointService.breakpoint$
      .pipe(skip(1), distinctUntilChanged(), shareReplay({ bufferSize: 1, refCount: true }))
      .subscribe(() => this.setMainElementHeight());
  }

  ngAfterViewChecked(): void {
    setTimeout(() => {
      this.setMainElementHeight();
      this.updatePaginationState();
      this.listItemElements.toArray().forEach(
        (val) =>
          (val.nativeElement.onclick = () => {
            const ticket = val.nativeElement.getAttribute('ticket');
            if (ticket) {
              this.eventService.dispatch(new SelectEcommerceItemEvent(val.nativeElement.getAttribute('ticket')));
            }
          })
      );
    }, 200);
  }

  onScrollBack(): void {
    if (this.canScrollBack) {
      this.scrollToIndex(this.currentIndex - 1);
    }
  }

  onScrollForward(): void {
    if (this.canScrollForward) {
      this.scrollToIndex(this.currentIndex + 1);
    }
  }

  onSwipeLeft(): void {
    if (this.canScrollForward) {
      this.scrollToIndex(this.currentIndex + 1);
    }
  }

  onSwipeRight(): void {
    if (this.canScrollBack) {
      this.scrollToIndex(this.currentIndex - 1);
    }
  }

  private scrollToIndex(index: number): void {
    this.currentIndex = index;
    this.updatePaginationState();
  }

  private updatePaginationState(): void {
    this.renderer.setProperty(this.backPaginationElementRef.nativeElement, 'disabled', !this.canScrollBack);
    this.renderer.setProperty(this.forwardPaginationElementRef.nativeElement, 'disabled', !this.canScrollForward);
    this.renderer.setStyle(this.listElementRef.nativeElement, 'left', `${-this.listScrollPosition}px`);
  }

  private setMainElementHeight(): void {
    const elementBuffer = 20;
    const height = `${this.listItemHeight + elementBuffer}px`;
    this.renderer.setStyle(this.mainElementRef.nativeElement, 'height', height);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
