import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import { Credentials } from '../../../model';
import { PyStateUtils } from '../../../state';
import { UPDATE_CREDENTIALS_PROCESS } from '../user-state';

export enum UpdateCredentialsActionTypes {
  UpdateCredentials = '[User] Update credentials',
  UpdateCredentialsSuccess = '[User] Update credentials success',
  UpdateCredentialsFail = '[User] Update credentials fail',

  ResetCredentials = '[User] Reset credentials',
}

export class UpdateCredentials extends StateUtils.EntityLoadAction {
  readonly type = UpdateCredentialsActionTypes.UpdateCredentials;
  constructor(public payload: { credentials: Credentials; userId: string }) {
    super(PROCESS_FEATURE, UPDATE_CREDENTIALS_PROCESS);
  }
}

export class UpdateCredentialsFail extends PyStateUtils.EntityFailAction {
  readonly type = UpdateCredentialsActionTypes.UpdateCredentialsFail;
  constructor(public payload?: any) {
    super(PROCESS_FEATURE, UPDATE_CREDENTIALS_PROCESS, payload);
  }
}

export class UpdateCredentialsSuccess extends StateUtils.EntitySuccessAction {
  readonly type = UpdateCredentialsActionTypes.UpdateCredentialsSuccess;
  constructor(public payload: { credentials: Credentials; userId: string }) {
    super(PROCESS_FEATURE, UPDATE_CREDENTIALS_PROCESS);
  }
}

export class UpdateCredentialsReset extends StateUtils.EntityLoaderResetAction {
  readonly type = UpdateCredentialsActionTypes.ResetCredentials;
  constructor() {
    super(PROCESS_FEATURE, UPDATE_CREDENTIALS_PROCESS);
  }
}

export type UpdateCredentialsActions =
  | UpdateCredentials
  | UpdateCredentialsFail
  | UpdateCredentialsSuccess
  | UpdateCredentialsReset;
