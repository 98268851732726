import { Observable } from 'rxjs';
import { Occ } from '../occ';
import { AccountingCode } from './accounting-code.model';
import { Address, Addresses } from './address.model';
import { CartType, OrderLineMessage } from './cart.model';
import { A4SampleProduct, ArticleRef } from './catalog.model';
import { Image } from './image.model';
import { B2BComment, Price, ShippingCondition, Unit, ValueWithUnit, Warehouse } from './misc.model';
import { Pagination, SearchParams, Sort } from './search.model';
import { Principal, SoldTo } from './user.model';

export enum PrePrintedSpecial {
  VOLVO = '2028860',
}

export type CheckoutKey = CartType;

export enum OrderListColumn {
  WEBREFERENCE = 'WEBREFERENCE',
  DELIVERYADDRESS = 'DELIVERYADDRESS',
  ORDERNOTE = 'ORDERNOTE',
  DELIVERYDATES = 'DELIVERYDATES',
}

export enum OrderHistoryTab {
  DELIVERY = 'Delivery',
  INFORMATION = 'Information',
  MESSAGES = 'Messages',
}

// Service articles are special ERP articles linked to feature fees.
export enum ServiceArticle {
  CUTTING = '1710',
  REELCUTTING = '1711',
  REAMING = '1766',
  PRE_PRINTING_FEE = '1779',
  PRE_PRINTING_CONFIGURATION = '1011',
}

export enum ConditionType {
  YVS1 = 'YVS1', // Cutting condition
  YVS2 = 'YVS2', // Cutting min value condition
  YVS4 = 'YVS4', // Packaging condition
  YVS6 = 'YVS6', // Cutting discount condition
  YVS7 = 'YVS7', // Packaging min value condition
  YVS8 = 'YVS8', // REEL_CUTTING_COST_FEE
}

export enum PaymentTerms {
  PYCC = 'PYCC',
  HUCD = 'HUCD',
  HUCP = 'HUCP',
}

export enum OrderApprovalTabType {
  BUYER = 'BUYER',
  ADMIN = 'ADMIN',
}

export enum OrderApprovalsTypes {
  MY_APPROVALS = 'my-approvals',
  ALL_APPROVALS = 'all-approvals',
}

export enum OrderStatusTabType {
  MY_APPROVALS = 'my-approvals',
  HISTORY = 'history',
}

export enum OrderHistoryMode {
  USER = 'USER',
  SOLDTO = 'SOLDTO',
  ANY = 'ANY',
}

export enum InvoicePaymentStatus {
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
  UNPAID = 'UNPAID',
}

export import OrderDetailsStatusHeader = Occ.OrderDetailsStatusHeader;

export interface OrderDetailsHighLevelItems {
  [id: number]: OrderDetailsOrderLine;
}

export interface Cutting extends Omit<Occ.Cutting, 'resultingQuantity'> {
  resultingQuantity?: ValueWithUnit;
}

export interface ReelCutting extends Omit<Occ.ReelCutting, 'resultingLength'> {
  resultingLength?: ValueWithUnit;
}

export import Reaming = Occ.Reaming;

export import PalletFlags = Occ.PalletFlags;

export interface InnerPackageQuantityRequired {
  innerPackageQuantityRequired?: boolean;
}

export import PrePrintedLabel = Occ.PrePrintedLabel;

export interface OrderCondition extends Omit<Occ.SapOrderCondition, 'rate' | 'value'> {
  active?: boolean;
  group?: boolean;
  rate?: Price;
  value?: Price;
  clazz?: string;
  category?: string;
  description?: string;
}

export enum OrderCompletionStatus {
  NotDelivered = 'A',
  PartiallyCompleted = 'B',
  Completed = 'C',
}

export interface OrderHistory extends Occ.OrderHistoryItem {
  code?: string;
  status?: OrderCompletionStatus;
}

export interface OrderHistoryExportXlsxUrlParams {
  userId: string;
  orderCode?: string;
}

export interface OrderHistoryExportXlsxQueryParams {
  dateTo?: string;
  dateFrom?: string;
  shipToId?: string;
  userUid?: string;
  onlyReinvoicingOrders?: boolean;
}

export interface OrderHistorySearchResult extends Occ.OrderHistoryPage {
  results?: OrderHistory[];
}

export interface InvoiceHistoryExportXlsxUrlParams {
  userId: string;
  invoiceCode?: string;
}

export interface InvoiceHistoryExportXlsxQueryParams {
  dateTo: string;
  dateFrom: string;
  paymentStatus: InvoicePaymentStatus;
}

export interface InvoiceHistorySearchResult extends Occ.InvoiceHistoryPage {
  results?: InvoiceHistory[];
}

export interface InvoiceHistory extends Occ.InvoiceHistoryRow {
  paymentStatus?: InvoicePaymentStatus;
}

export interface InvoiceClickEvent {
  download: boolean;
  invoiceNumber: string;
}

export interface TrackedOrder {
  code?: string;
  numberOfUniqueArticles?: number;
  deliveries?: TrackedOrderDelivery[];
}

export interface TrackedOrderDelivery {
  code?: string;
  numberOfUniqueArticles?: number;
  orderCreatedDate?: Date;
  deliveryCreatedDate?: Date;
  overallPickingStatus?: TrackedOrderStatus;
  totalGoodsMovementStatus?: TrackedOrderStatus;
  transitStatus?: TrackedOrderStatus;
  deliveryStatus?: TrackedOrderStatus;
  billingStatus?: TrackedOrderStatus;
  overallPickingStatusDate?: Date;
  totalGoodsMovementStatusDate?: Date;
  transitStatusDate?: Date;
  deliveryStatusDate?: Date;
  billingStatusDate?: Date;
  handlingUnits?: TrackedOrderHandlingUnit[];
  items?: BasicOrderLine[];
  shipPoint?: TrackedOrderShipPoint;
  shipTo?: TrackedOrderShipTo;
}

export interface TrackedOrderShipTo {
  code?: string;
  description?: string;
}

export interface TrackedOrderShipPoint {
  code?: string;
  description?: string;
  location?: string;
}

export enum TrackedOrderStatus {
  NOT_STARTED = 'NOT_STARTED',
  PARTIALLY_PROCESSED = 'PARTIALLY_PROCESSED',
  COMPLETED = 'COMPLETED',
}

export interface TrackedOrderHandlingUnit {
  code?: string;
  trackingNumber?: string;
  trackingUrl?: string;
}

interface WithCommonOrderData<T extends DetailedOrderLine> {
  code: string;
  entries: T[];

  subTotal?: Price;
  totalPrice?: Price;
  totalPriceWithTax?: Price;
  totalTax?: Price;
}

interface WithDetailedOrderData<T extends DetailedOrderLine> extends WithCommonOrderData<T> {
  deliveryOrderGroups: DeliveryEntryGroup<T>[];

  totalEntryPrice?: Price;
  totalFeePrice?: Price;
  totalEntryPriceIncludingFees?: Price;
  totalDiscounts?: Price;
  totalWeight?: ValueWithUnit;
  deliveryAddress?: Address;
  paymentTerms?: PaymentTerms;
  carryBeyondLoadingBayOption?: boolean;

  orderNote?: string;
  costPlace?: string;
  deliveryNote?: string;
  invoiceNote?: string;
  message?: string;
  goodsMarking?: string;
  customerReference?: string;
  phoneNumber?: string;
  reinvoicing?: Reinvoicing;

  conditions: OrderCondition[];
}

export interface OrderDetails extends WithDetailedOrderData<OrderDetailsOrderLine> {
  roundingErrorValue?: Price;
  documentType?: string;
  soldTo?: SoldTo;
  shippingCondition?: ShippingCondition;
  receivedDate?: string;
  receivedTime?: string;
  sapReceivedDate?: string;
  customerAddress?: Address;
  invoiceNumbers?: string[];
  deliveryNoteNumbers?: string[];
  carryBeyondLoadingBayIncluded?: boolean;
  unpackingIncluded?: boolean;
  cuttingIncluded?: boolean;
  reelCuttingIncluded?: boolean;
  indentIncluded?: boolean;
  statusHeader?: OrderDetailsStatusHeader;
  addresses?: Addresses;
  highLevelItems?: OrderDetailsHighLevelItems;
  messages?: string | string[];
  soldToObject?: SoldTo;
  orderType?: OrderType;
  hasReturn?: boolean;
  canReturn?: boolean;
  carryBeyondLoadingBayOption?: boolean;
  timeCode?: string;
  sapTimeWindowTimeCode?: TimeWindowDeliveryOption;
}

export interface SapOrder extends WithCommonOrderData<OrderEntry> {
  orderType?: OrderType;
  name?: string;
  description?: string;
  permissionResults?: OrderApprovalPermissionResult[];

  status?: string;

  calculated?: boolean;
  net?: boolean;
  totalItems?: number;

  site?: string;
  store?: string;
  user?: Principal;

  deliveryAddress?: Address;
}

export interface SimulatedOrder extends SapOrder, WithDetailedOrderData<OrderEntry> {
  sapOrderNumber?: string;
  shippingCondition?: string;

  nonDeliverableEntries: OrderEntry[];

  requestedDeliveryDate?: string;
  agreedDeliveryDate?: string;

  orderMergeProposals: OrderMergeProposal[];
  serviceOptions: ServiceOption[];
  shippingOptions: ShippingOption[];
  hasRefiningRules?: boolean;
  timeCode?: string;
  sapTimeWindowTimeCode?: TimeWindowDeliveryOption;
}

export interface Order extends SimulatedOrder {
  created?: string;
}

export import OrderType = Occ.OrderOrderTypeEnum;

export interface OrderProcessStatus extends Occ.OrderProcessStatus {
  orderProcessState?: OrderProcessState;
}

export enum OrderProcessState {
  WAITING = 'WAITING',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
  RUNNING = 'RUNNING',
  ERROR = 'ERROR',
}

export enum OrderStatus {
  CANCELLING = 'CANCELLING',
  CHECKED_VALID = 'CHECKED_VALID',
  CREATED = 'CREATED',
  OPEN = 'OPEN',
  RETURNED_TO_USER = 'RETURNED_TO_USER',
  CHECKED_INVALID = 'CHECKED_INVALID',
  ON_VALIDATION = 'ON_VALIDATION',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  SUSPENDED = 'SUSPENDED',
  COMPLETED = 'COMPLETED',
  PAYMENT_AUTHORIZED = 'PAYMENT_AUTHORIZED',
  PENDING_APPROVAL_FROM_MERCHANT = 'PENDING_APPROVAL_FROM_MERCHANT',
  CANCELLED = 'CANCELLED',
  PAYMENT_NOT_AUTHORIZED = 'PAYMENT_NOT_AUTHORIZED',
  PENDING_QUOTE = 'PENDING_QUOTE',
  APPROVED_QUOTE = 'APPROVED_QUOTE',
  PAYMENT_AMOUNT_RESERVED = 'PAYMENT_AMOUNT_RESERVED',
  PAYMENT_AMOUNT_NOT_RESERVED = 'PAYMENT_AMOUNT_NOT_RESERVED',
  REJECTED_QUOTE = 'REJECTED_QUOTE',
  APPROVED = 'APPROVED',
  APPROVER_EDITING = 'APPROVER_EDITING',
  PAYMENT_CAPTURED = 'PAYMENT_CAPTURED',
  PAYMENT_NOT_CAPTURED = 'PAYMENT_NOT_CAPTURED',
  REJECTED = 'REJECTED',
  APPROVED_BY_MERCHANT = 'APPROVED_BY_MERCHANT',
  FRAUD_CHECKED = 'FRAUD_CHECKED',
  ORDER_SPLIT = 'ORDER_SPLIT',
  REJECTED_BY_MERCHANT = 'REJECTED_BY_MERCHANT',
  ASSIGNED_TO_ADMIN = 'ASSIGNED_TO_ADMIN',
  PROCESSING_ERROR = 'PROCESSING_ERROR',
  B2B_PROCESSING_ERROR = 'B2B_PROCESSING_ERROR',
  WAIT_FRAUD_MANUAL_CHECK = 'WAIT_FRAUD_MANUAL_CHECK',
  PAYMENT_NOT_VOIDED = 'PAYMENT_NOT_VOIDED',
  TAX_NOT_VOIDED = 'TAX_NOT_VOIDED',
  TAX_NOT_COMMITTED = 'TAX_NOT_COMMITTED',
  TAX_NOT_REQUOTED = 'TAX_NOT_REQUOTED',
}

export interface BasicOrderLine {
  articleRef?: ArticleRef;
  shortText?: string;
  quantity?: number;
  unit?: Unit;
}

export interface DetailedOrderLine extends BasicOrderLine {
  comparativePrice?: ComparativePrice;
  linePriceIncludingShareOfOrderFees?: Price;

  statisticsCode?: string;
  note?: string;
  itemCategory?: string;
  indent?: boolean;
  accountingCode?: AccountingCode;
  explodedOrderLines?: ExplodedOrderLine[];

  cutting?: Cutting;
  reaming?: Reaming;
  reelCutting?: ReelCutting;
  palletFlags?: PalletFlags;
  fullPalletNote?: boolean;
  prePrintedLabel?: PrePrintedLabel;
  conditions?: OrderCondition[];
  warehouse?: Warehouse;
  nameMarkingTextLines?: string[];
  showNameMarkingTextLines?: string[];
  delivered?: boolean;
}

export interface ExplodedOrderLine extends Occ.ExplodedOrderLine {
  nameMarkingTextLines?: string[];
  showNameMarkingTextLines?: boolean;
}

export interface OrderDetailsOrderLine extends DetailedOrderLine {
  itemNumber?: number;
  highLevelItem?: number;
  materialNumber?: string;
  netPrice?: Price;
  deliveryDate?: string;
  shortText?: string;
  doNotUseChildEntryForDisplay?: boolean;
}

export interface OrderEntry extends DetailedOrderLine {
  entryNumber?: number;
  id?: string;
  itemNumber?: number;
  type?: OrderEntryType;
  basePrice?: Price;
  totalPrice?: Price;
  cartPrice?: Price;
  netPrice?: Price;
  contractedPrice?: Price;
  originalSample?: boolean;
  a4Sample?: boolean;
  a4SampleDescription?: string;
  a4SampleProduct?: A4SampleProduct;
  flags?: OrderEntryFlag;
  prePrintedMaterialNumber?: string;
  configurationInfos?: ConfigurationInfo[];

  // Checkout stuff below
  sapOrderLineNumber?: string;
  requestedDeliveryDate?: string;
  agreedDeliveryDate?: string;
  fullDeliveryDate?: string;
  confirmedQuantity?: number;
  sapEarliestDate?: string;
  comparativePrice?: OrderEntryComparativePrice;
  totalPriceWithTax?: Price;
  totalTax?: Price;
  splitOrderEntry?: boolean;
  autoResolveChange?: AutoResolveChange;
  resolveOptions?: ResolveOption[];
  messages?: OrderLineMessage[];
  discount?: boolean;
  accountingCodes?: AccountingCode[];
  showCustomerSelectedUnit?: boolean;
  ticket?: string;
}

export import OrderEntryType = Occ.OrderEntryTypeEnum;

export import ConfigurationInfo = Occ.ConfigurationInfo;

export interface DeliveryEntryGroup<T extends DetailedOrderLine> {
  deliveryDate: string;
  entries: T[];
  totalPriceWithTax?: Price;
}

export import OrderEntryFlag = Occ.EntryFlag;

export interface ComparativePrice extends Omit<Occ.OrderEntryComparativePrice, 'price'> {
  price?: Price;
  quantity?: number;
  unit?: Unit;
}

export interface OrderEntryComparativePrice
  extends Omit<ComparativePrice, 'unit'>,
    Omit<Occ.OrderEntryComparativePrice, 'price'> {}

export interface OrderMergeProposal extends Omit<Occ.OrderMergeProposal, 'netValue'> {
  netValue?: Price;
}

export interface LastOrderedArticlesSearchResult {
  results?: string[];
  pagination?: Pagination;
  sorts?: Sort[];
}

export interface MostOrderedArticlesSearchResult {
  results?: string[];
  pagination?: Pagination;
  sorts?: Sort[];
}

export interface PaymentOption {
  type?: 'INVOICE' | 'CREDIT_CARD' | 'CASH_ON_DELIVERY' | 'CASH_ON_PICKUP';
  displayName?: string;
  tooltip?: string;
  infoText?: string;
  cost?: Price;
  icons?: Image[];
  paymentProvider?: PaymentProvider;
}

export interface PaymentProvider {
  id?: PaymentProviderType;
  icon?: Image;
  latestDeliveryDate?: string; // date-time
}

export enum PaymentProviderTypes {
  NETS = 'NETS',
  GPWEBPAY = 'GPWEBPAY',
  MOLLIE = 'MOLLIE',
  AUTOPAY = 'AUTOPAY',
  PAYREXX = 'PAYREXX',
  SIAVPOS = 'SIAVPOS',
  PAYTRAIL = 'PAYTRAIL',
}

export type PaymentProviderType = 'NETS' | 'GPWEBPAY' | 'MOLLIE' | 'AUTOPAY' | 'PAYREXX' | 'SIAVPOS' | 'PAYTRAIL';

export interface PaymentSetup {
  paymentProviderType: PaymentProviderType;
  paymentProviderUrl: string;
  params?: PaymentSetupParameters;
}

export interface NetsPaymentSetup extends PaymentSetup {
  paymentProviderType: PaymentProviderTypes.NETS;
  params: NetsPaymentSetupParameters;
}

export interface PaymentSetupParameters {
  [key: string]: any;
}

export interface NetsPaymentSetupParameters {
  checkoutKey: string;
  paymentId: string;
  partnerMerchantNumber?: string;
  language?: string;
}

export interface GpWebPayPaymentSetupParameters {
  MERCHANTNUMBER: string;
  OPERATION?: string;
  ORDERNUMBER?: string;
  AMOUNT?: string;
  CURRENCY?: string;
  DEPOSITFLAG?: string;
  MERORDERNUM?: string;
  URL?: string;
  DESCRIPTION?: string;
  MD?: string;
  USERPARAM1?: string;
  VRCODE?: string;
  FASTPAYID?: string;
  PAYMETHOD?: string;
  DISABLEPAYMETHOD?: string;
  PAYMETHODS?: string;
  EMAIL?: string;
  REFERENCENUMBER?: string;
  ADDINFO?: string;
  PANPATTERN?: string;
  TOKEN?: string;
  FASTTOKEN?: string;
  DIGEST: string;
  LANG?: string;
  PRCODE?: string;
  SRCODE?: string;
  RESULTTEXT?: string;
  DIGEST1?: string;
  ACCODE?: string;
}

export enum GpWebPayPaymentSetupParametersTypes {
  MERCHANTNUMBER = 'MERCHANTNUMBER',
  OPERATION = 'OPERATION',
  ORDERNUMBER = 'ORDERNUMBER',
  AMOUNT = 'AMOUNT',
  CURRENCY = 'CURRENCY',
  DEPOSITFLAG = 'DEPOSITFLAG',
  MERORDERNUM = 'MERORDERNUM',
  URL = 'URL',
  DESCRIPTION = 'DESCRIPTION',
  MD = 'MD',
  USERPARAM1 = 'USERPARAM1',
  VRCODE = 'VRCODE',
  FASTPAYID = 'FASTPAYID',
  PAYMETHOD = 'PAYMETHOD',
  DISABLEPAYMETHOD = 'DISABLEPAYMETHOD',
  PAYMETHODS = 'PAYMETHODS',
  EMAIL = 'EMAIL',
  REFERENCENUMBER = 'REFERENCENUMBER',
  ADDINFO = 'ADDINFO',
  PANPATTERN = 'PANPATTERN',
  TOKEN = 'TOKEN',
  FASTTOKEN = 'FASTTOKEN',
  DIGEST = 'DIGEST',
  LANG = 'LANG',
  PRCODE = 'PRCODE',
  SRCODE = 'SRCODE',
  RESULTTEXT = 'RESULTTEXT',
  DIGEST1 = 'DIGEST1',
  ACCODE = 'ACCODE',
}

export interface ServiceOption extends Occ.ServiceOption {
  type?: ServiceOptionType;
  optionId?: string;
}

export import ServiceOptionType = Occ.ServiceOptionTypeEnum;

export const NO_TIME_WINDOW_CODE = '/';

export import TimeWindowDeliveryOption = Occ.TimeWindowDeliveryOption;

export import TimeWindowDeliveryOptions = Occ.TimeWindowDeliveryOptions;

export interface ShippingOption extends Omit<Occ.ShippingOption, 'type'> {
  type?: ShippingOptionType;
  properties?: ShippingOptionPropertyMap[];
  allowTimeWindowCode?: boolean;
  allowYCBL?: boolean;
}

export interface ShippingOptionPropertyMap {
  key?: string;
  value?: ShippingOptionProperty;
}

export interface ShippingOptionProperty {
  type?: string;
  value?: any;
}

export enum ShippingOptionType {
  STANDARD = 'STANDARD',
  // TODO: Remove specific date
  SPECIFIC_DATE = 'SPECIFIC_DATE',
  COMPLETE = 'COMPLETE',
  COMPLETE_FORCED = 'COMPLETE_FORCED',
  SAME_DAY = 'SAME_DAY',
  TIME_WINDOW_LPRIO = 'TIME_WINDOW_LPRIO',
  PICKUP = 'PICKUP',
  PICKUP_FORCED = 'PICKUP_FORCED',
  EVENING_EXPRESS = 'EVENING_EXPRESS',
}

export interface AutoResolveChange extends Occ.AutoResolveChange {
  changeType?: AutoResolveChangeType;
}

export import AutoResolveChangeType = Occ.AutoResolveChangeChangeTypeEnum;

export import ScheduleItem = Occ.AtgScheduleItem;

export interface ResolveOption extends Omit<Occ.ResolveOption, 'action' | 'alternativeArticles'> {
  action?: ResolveOptionActionType;
  alternativeArticles?: OrderEntry[];
}

export enum AlternativeMaterialKey {
  ALTERNATIVE_MATERIAL = 'alternativeMaterial',
  ALTERNATIVE_MATERIAL2 = 'alternativeMaterial2',
  ALTERNATIVE_MATERIAL3 = 'alternativeMaterial3',
  ALTERNATIVE_MATERIAL4 = 'alternativeMaterial4',
}

export enum ResolveOptionActionType {
  ALTERNATIVE_ARTICLE = 'ALTERNATIVE_ARTICLE',
  ALTERNATIVE_ARTICLE1 = 'ALTERNATIVE_ARTICLE1',
  ALTERNATIVE_ARTICLE2 = 'ALTERNATIVE_ARTICLE2',
  ALTERNATIVE_ARTICLE3 = 'ALTERNATIVE_ARTICLE3',
  ALTERNATIVE_ARTICLE4 = 'ALTERNATIVE_ARTICLE4',
  ALTERNATIVE_DATE = 'ALTERNATIVE_DATE',
  PARTIAL_DELIVERY = 'PARTIAL_DELIVERY',
  ALTERNATIVE_AVAILABLE_AND_REST_ORDER = 'ALTERNATIVE_AVAILABLE_AND_REST_ORDER',
  PRE_PRINT = 'PRE_PRINT',
}

export import OrderApprovalDecisionValue = Occ.OrderApprovalDecisionValue;

export interface OrderApprovalDecision extends Occ.OrderApprovalDecision {
  decision: OrderApprovalDecisionValue;
  comment?: B2BComment;
}

export import OrderApprovalPermissionResult = Occ.PermissionResult;

export import OrderApprovalStatus = Occ.OrderApprovalApprovalStatusEnum;

export import OrderApproval = Occ.OrderApproval;

export import OrderApprovalSearchResult = Occ.OrderApprovalPage;

export import Reinvoicing = Occ.Reinvoicing;

export interface AvailableBuyersSearchParams extends SearchParams {
  text?: string;
}

export import OrderApprovalBuyerSearchResult = Occ.SimpleCustomerPage;

export interface ProcessedOrderApproval {
  isProcessed: boolean;
  code: string;
}

export interface ReinvoicingFields {
  [id: string]: Observable<string>;
}

export enum ReinvoicingFieldTypes {
  CONTRACT_NUMBER = 'contractNumber',
  CUSTOMER_NUMBER = 'customerNumber',
  REFERENCE_NUMBER_1 = 'referenceNumber1',
  REFERENCE_NUMBER_2 = 'referenceNumber2',
}

export const CHECKOUT_ORDER_QUERY_PARAM_KEY = 'order';
export const CHECKOUT_ORDER_MERGE_PARAM_KEY = 'merge';
export const CHECKOUT_IS_APPROVAL_ORDER = 'isApprovalOrder';
export const CHECKOUT_DEMO_ORDER_CODE = 'DEMO';
