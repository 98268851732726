import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { AnalyticsMetadata } from '../../../features/tracking';
import { USER_BASE_FEATURE } from '../store/user-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: AnalyticsMetadataFacade,
      feature: USER_BASE_FEATURE,
      async: true,
      methods: ['getAnalyticsMetadata', 'getAnalyticsMetadataLoading', 'getAnalyticsMetadataLoaded'],
    }),
})
export abstract class AnalyticsMetadataFacade {
  abstract getAnalyticsMetadata(): Observable<AnalyticsMetadata | undefined>;
  abstract getAnalyticsMetadataLoading(): Observable<boolean>;
  abstract getAnalyticsMetadataLoaded(): Observable<boolean>;
}
