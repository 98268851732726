import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CatalogRouterStateService } from '../../../../core/catalog';

@Injectable()
export class ProductCodeResolver {
  constructor(private catalogRouterStateService: CatalogRouterStateService) {}

  resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): string {
    return this.catalogRouterStateService.getProductCode(route.params, route.queryParams);
  }
}
