import { ActionReducer } from '@ngrx/store';
import { PriceFilesActionTypes } from '../actions/price-files.action';

export function clearUserInteractionState(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (action.type === PriceFilesActionTypes.LoadPriceFiles) {
      state = {
        ...state,
        cancel: {},
        delete: {},
      };
    }

    return reducer(state, action);
  };
}
