<py-modal
  [showCancelButton]="false"
  [centered]="true"
  [title]=""
  [hasWidePaddings]="false"
  [hideFooter]="true"
  [cxFocus]="focusConfig"
  (esc)="closeModal('escape pressed')"
>
  <ng-container *ngIf="images.length > 1; else singleImage">
    <div class="d-flex images">
      <ngb-carousel
        class="w-100"
        #carousel
        [interval]="0"
        [showNavigationArrows]="true"
        [showNavigationIndicators]="true"
        [activeId]="activeSlideId.toString()"
      >
        <ng-template ngbSlide *ngFor="let image of images; let i = index" [id]="i.toString()">
          <img [class.no-visual-search]="isEdgeBrowser" [pyMedia]="image" [draggable]="false" [alt]="alternativeText" />
        </ng-template>
      </ngb-carousel>
    </div>
  </ng-container>

  <ng-template #singleImage>
    <div class="single-image image-modal-carousel-media">
      <img [class.no-visual-search]="isEdgeBrowser" [draggable]="false" [pyMedia]="images[0]" [alt]="alternativeText" />
    </div>
  </ng-template>
</py-modal>
