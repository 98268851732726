import { StateUtils } from '@spartacus/core';
import { EntitiesModel, ListModel, SearchConfig } from '../../model';
import { PyStateUtils } from '../../state/utils';

const ALL = 'all';

export function serializeSearchConfig(config: SearchConfig, id?: string): string {
  let serializedString = `${id ?? ''}?`;

  for (const key in config) {
    if (config.hasOwnProperty(key) && config[key] !== undefined && config[key] !== null) {
      if (serializedString[serializedString.length - 1] !== '?') {
        serializedString += '&';
      }
      serializedString += `${key}=${config[key]}`;
    }
  }

  return serializedString;
}

export function denormalizeSearch<T>(
  state: StateUtils.EntityListState<T>,
  params?: SearchConfig
): StateUtils.LoaderState<EntitiesModel<T>> {
  return denormalizeCustomB2BSearch<T>(state.list, state.entities, params);
}

export function denormalizeCustomB2BSearch<T>(
  list: StateUtils.EntityLoaderState<ListModel>,
  entities: StateUtils.EntityLoaderState<T>,
  params?: SearchConfig,
  id?: string
): StateUtils.LoaderState<EntitiesModel<T>> {
  const serializedList: any = PyStateUtils.entityLoaderStateSelector(
    list,
    params ? serializeSearchConfig(params, id) : id ?? ALL
  );
  if (!serializedList.value || !serializedList.value.ids) {
    return serializedList;
  }
  const res: StateUtils.LoaderState<EntitiesModel<T>> = Object.assign({}, serializedList, {
    value: {
      values: serializedList.value.ids.map((code) => PyStateUtils.entityLoaderStateSelector(entities, code).value),
    },
  });
  if (params) {
    res.value.pagination = serializedList.value.pagination;
    res.value.sorts = serializedList.value.sorts;
  }
  return res;
}

export function normalizeListPage<T>(list: EntitiesModel<T>, id: string): { values: T[]; page: ListModel } {
  const values = list?.values || [];
  const page: ListModel = {
    ids: values.map((data) => (<any>data)[id]),
  };
  if (list.pagination) {
    page.pagination = list.pagination;
  }
  if (list.sorts) {
    page.sorts = list.sorts;
  }
  return { values, page };
}
