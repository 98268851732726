import { ModuleWithProviders, NgModule } from '@angular/core';
import { UsersImportExportAdapter } from './connectors';
import { OccUsersImportExportAdapter } from './occ/occ-users-import-export.adapter';

@NgModule({
  providers: [
    {
      provide: UsersImportExportAdapter,
      useClass: OccUsersImportExportAdapter,
    },
  ],
})
export class UsersBaseModule {
  static forRoot(): ModuleWithProviders<UsersBaseModule> {
    return {
      ngModule: UsersBaseModule,
    };
  }
}
