import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, forkJoin } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { Article, CartType, FullPalletUpgrade, HorizontalAlignment, OrderEntry, Price, Unit } from '../../../../../core/model';
import { PrincipalConfigurationService } from '../../../../../core/user';
import { CART_TYPE_PARAM_KEY } from '../../../../../shared';

export interface CartModalEntry {
  price: Price;
  article$: Observable<Article>;
  cartType?: CartType;
  hasNameMarking?: boolean;
  fullPalletUpgrade?: FullPalletUpgrade;
  entryNumber?: number;
  id: string;
  isAlreadyUpdated?: boolean;
  isSkipped?: boolean;
  isUpgradePending?: boolean;
  articleNumber?: string;
}

export interface UpdateEntry {
  entry: OrderEntry;
  quantity: number;
  unit: Unit;
  cartType: CartType;
  isUpgradePending: boolean;
}

@Component({
  selector: 'py-cart-message-modal',
  templateUrl: './cart-message-modal.component.html',
  styleUrls: ['./cart-message-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartMessageModalComponent implements OnInit {
  static MAX_ENTRIES = 3;

  queryParams = {};

  private entries$ = new BehaviorSubject<CartModalEntry[]>([]);
  private _entries: CartModalEntry[];

  @Input() set entries(entries: CartModalEntry[]) {
    this._entries = entries;
    if (this._entries?.length) {
      //this.queryParams = cartType of the last added entry
      this.queryParams = { [CART_TYPE_PARAM_KEY]: [...this._entries].pop().cartType };
    }
    this.entries$.next(entries);
  }
  @Output() update = new EventEmitter<UpdateEntry>();
  @Output() skipUpdate = new EventEmitter<OrderEntry>();
  @Output() closeModal = new EventEmitter<void>();

  get entries(): CartModalEntry[] {
    return this._entries;
  }

  cuttableOrReamable$: Observable<boolean>;
  horizontalAlignments = HorizontalAlignment;

  get numberOfEntriesNotDisplayed(): number {
    return this.entriesHasExceedingLength ? this.entriesExceedingLength + 1 : 0;
  }

  get hasNameMarking(): boolean {
    return this.entries?.some((entry) => entry.hasNameMarking);
  }

  get entriesHasExceedingLength(): boolean {
    return this.entriesExceedingLength > 0;
  }

  get entriesToDisplay(): CartModalEntry[] {
    return this.entriesHasExceedingLength ? this.entries?.slice(0, 2) : this.entries?.slice(0, 3);
  }

  private get entriesExceedingLength(): number {
    return (this.entries?.length || 0) - CartMessageModalComponent.MAX_ENTRIES;
  }

  constructor(private principalConfigurationService: PrincipalConfigurationService) {}

  ngOnInit(): void {
    this.cuttableOrReamable$ = combineLatest([
      this.principalConfigurationService.isEnabled('enableCutting'),
      this.principalConfigurationService.isEnabled('enableReelCutting'),
      this.principalConfigurationService.isEnabled('enableReaming'),
      this.entries$.pipe(switchMap((entries) => forkJoin(entries?.map((entry) => entry.article$.pipe(take(1)))))),
    ]).pipe(
      filter(([enableCutting, enableReelCutting, enableReaming]) => enableCutting || enableReelCutting || enableReaming),
      map(([enableCutting, enableReelCutting, enableReaming, articles]) => {
        return articles.some(
          (article) => ((enableCutting || enableReelCutting) && article?.cuttable) || (enableReaming && article?.reamable)
        );
      })
    );
  }

  onPalletUpgrade(entry: CartModalEntry, article: Article): void {
    this.update.emit({
      entry,
      quantity: entry.fullPalletUpgrade.fullPalletQuantity,
      unit: article.unit,
      cartType: entry.cartType,
      isUpgradePending: entry.isUpgradePending,
    });
  }

  onPalletUpgradeSkip(entry: CartModalEntry): void {
    this.skipUpdate.emit(entry);
  }

  onModalClose(): void {
    this.closeModal.emit();
  }
}
