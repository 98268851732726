import { Action } from '@ngrx/store';
import { EntriesState } from '../../../features/store/base-feature-state';
import { PrincipalSharedShoppingListFlags, PrincipalSharedShoppingListFlagsPage } from '../../../model';
import { UserActions } from '../actions';
import { ShoppingListActionTypes } from '../actions/shopping-list.action';

export const initialState: EntriesState<PrincipalSharedShoppingListFlags> = {};

export function reducer(state = initialState, action: Action): EntriesState<PrincipalSharedShoppingListFlags> {
  switch (action.type) {
    case ShoppingListActionTypes.LoadShoppingListPrincipalsSuccess: {
      const _action = <UserActions.LoadShoppingListPrincipalsSuccess>action;
      const payload: PrincipalSharedShoppingListFlagsPage = _action.payload;
      const results = payload.results;
      return results.reduce(
        (entities: { [id: number]: PrincipalSharedShoppingListFlags }, principalFlag: PrincipalSharedShoppingListFlags) => {
          return {
            ...entities,
            [principalFlag.principal.uid]: principalFlag,
          };
        },
        {
          ...state,
        }
      );
    }

    default:
      return state;
  }
}
