import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        purchaseLimitsUser: {
          module: () => import('./user/user-purchase-limits.module').then((m) => m.CmsUserPurchaseLimitsModule),
          dependencies: [
            () =>
              import('../../../features/purchase-limits-base/purchase-limits-base.module').then(
                (m) => m.PurchaseLimitsBaseModule
              ),
          ],
          cmsComponents: ['CmsPurchaseLimitsComponent'],
        },
      },
    }),
  ],
})
export class PurchaseLimitsUserFeatureModule {}
