import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddonGroup, AddonSortTypes } from '../../../model';
import { ConverterService } from '../../../util';
import { ADDON_GROUP_NORMALIZER } from './converters';

@Injectable({
  providedIn: 'root',
})
export class AddonGroupConnector {
  constructor(
    private httpClient: HttpClient,
    private converterService: ConverterService,
    private occEndpointsService: OccEndpointsService
  ) {}

  loadAddonGroups(userId: string): Observable<Array<AddonGroup>> {
    return this.httpClient
      .get<Array<AddonGroup>>(this.occEndpointsService.buildUrl('addonGroups', { urlParams: { userId } }))
      .pipe(
        map((body) => body['groups']),
        this.converterService.pipeableMany(ADDON_GROUP_NORMALIZER)
      );
  }

  loadAddonGroup(userId: string, addonGroupId: string, sort: string): Observable<AddonGroup> {
    const queryParams = {};
    if (sort && !sort.includes(AddonSortTypes.ADDONS_DEFAULT)) {
      queryParams['sort'] = sort;
    }

    return this.httpClient
      .get<AddonGroup>(this.occEndpointsService.buildUrl('addonGroup', { urlParams: { userId, addonGroupId }, queryParams }))
      .pipe(this.converterService.pipeable(ADDON_GROUP_NORMALIZER));
  }
}
