import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@spartacus/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Observable, ReplaySubject, Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, take, tap } from 'rxjs/operators';
import { MiniShoppingList, Pagination, Unit, WritableShoppingListsSearchParams } from '../../../core/model';
import { ShoppingListFacade } from '../../../core/user/facades';
import { WritableShoppingListSearchParamsState } from '../../../core/user/store/user-state';
import { shallowEqualObjects } from '../../../core/util';
import { infinitelyScrolling } from '../../utils/infinitely-scrolling';
import {
  CreateShoppingListModalData,
  CreateShoppingListModalType,
} from '../create-shopping-list-modal/create-shopping-list-modal.component';

@Component({
  selector: 'py-add-to-shopping-list',
  templateUrl: './add-to-shopping-list.component.html',
  styleUrls: ['./add-to-shopping-list.component.scss'],
})
export class AddToShoppingListComponent implements OnInit, OnDestroy {
  @ViewChild('popOver', { static: true }) popover: NgbPopover;

  private key: string = 'add-to-shopping-list';
  private _articleCode?: string;
  private _quantity?: number;
  private _unit?: Unit;
  showListItems = false;

  @Input() dark?: boolean;
  @Input() disabled?: boolean = false;
  @Input() placement?: string[] | string = 'auto';
  @Input() showButton?: boolean = false;
  @Input() disableTooltip?: boolean = false;
  @Input() set articleCode(articleCode: string) {
    this._articleCode = articleCode;
  }
  @Input() set quantity(quantity: number) {
    this._quantity = quantity;
  }
  @Input() set unit(unit: Unit) {
    this._unit = unit;
  }
  @Input() articleNumber?: string;

  get articleCode() {
    return this._articleCode;
  }

  get unit() {
    return this._unit;
  }

  get inEcommerceQuantity() {
    return this._quantity * this._unit?.inEcommerceUnit;
  }

  public items$: Observable<MiniShoppingList[]>;
  public pagination$: Observable<Pagination>;
  public loading$: Observable<boolean>;
  public writableShoppingListsSearchParams$: Observable<WritableShoppingListSearchParamsState>;

  private subscriptions = new Subscription();
  isUserLoggedIn$: Observable<boolean>;
  currentPage$ = new ReplaySubject<number>(0);

  constructor(
    private shoppingListService: ShoppingListFacade,
    private router: Router,
    private authService: AuthService,
    private launchDialogService: LaunchDialogService
  ) {}

  ngOnInit() {
    this.isUserLoggedIn$ = this.authService.isUserLoggedIn();

    this.pagination$ = this.shoppingListService
      .getWritableShoppingListsSearchResultPagination(this.key)
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.loading$ = this.shoppingListService.getWritableShoppingListsSearching(this.key);

    this.items$ = infinitelyScrolling(
      this.shoppingListService.getWritableShoppingListsSearchResult(this.key),
      this.currentPage$,
      'code'
    ).pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.writableShoppingListsSearchParams$ = this.shoppingListService.getWritableShoppingListsSearchParams(this.key);

    this.subscriptions.add(
      combineLatest([this.writableShoppingListsSearchParams$, this.popover.shown])
        .pipe(
          map(([writableShoppingListsSearchParams, _popoverShown]) => writableShoppingListsSearchParams),
          filter((searchParams) => !!searchParams && Boolean(this.popover.isOpen())),
          distinctUntilChanged(shallowEqualObjects)
        )
        .subscribe((searchParams) => {
          this.shoppingListService.searchWritableShoppingLists(searchParams as WritableShoppingListsSearchParams);
          this.showListItems = true; // used to prevent old list flickering when loading new one
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private updateSearchParams(page = 0): void {
    this.currentPage$.next(page);
    this.shoppingListService.updateSearchParamsForWritableShoppingLists({
      key: this.key,
      page,
      count: 5,
    });
  }

  onScroll(): void {
    this.pagination$
      .pipe(
        take(1),
        filter((pagination) => !!pagination && pagination.hasNext && pagination.page + 1 < pagination.totalPages),
        tap((pagination) => {
          this.updateSearchParams(pagination.page + 1);
        })
      )
      .subscribe();
  }

  openModal(): void {
    this.popover.close();

    const modalData: CreateShoppingListModalData = {
      modalType: CreateShoppingListModalType.CREATE_NEW_WITH_ENTRY,
      key: this.key,
      articleCode: this.articleCode,
      articleQuantity: this.inEcommerceQuantity,
      articleUnit: this.unit,
      articleNumber: this.articleNumber,
    };

    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.CREATE_SHOPPING_LIST, undefined, modalData);
  }

  addEntry(shoppinglistCode: string) {
    this.shoppingListService.addEntry(
      shoppinglistCode,
      this.articleCode,
      this.inEcommerceQuantity,
      this.unit,
      undefined,
      this.articleNumber
    );
    this.popover.close();
  }

  onOpen() {
    this.isUserLoggedIn$
      .pipe(
        take(1),
        tap((isUserLoggedIn) => {
          if (isUserLoggedIn && !this.popover.isOpen()) {
            this.updateSearchParams();
          } else if (!isUserLoggedIn) {
            this.router.navigateByUrl('/login');
          }
        })
      )
      .subscribe();
  }

  closePopover(): void {
    this.popover.close();
  }
}
