<py-modal
  *ngIf="component"
  [actions]="templateActions"
  [showCancelButton]="false"
  [blockDismissCoreAction]="true"
  [title]="component.name"
  [dismissable]="dismissable"
  [scrollable]="true"
  [centered]="true"
  [cxFocus]="focusConfig"
  (dismiss)="close()"
  (esc)="dismissable ? close() : undefined"
>
  <div [innerHTML]="component.content" class="legal-component-content"></div>
  <div *ngIf="component.readMore" [innerHTML]="component.readMore"></div>
</py-modal>

<ng-template #templateActions>
  <py-action-button
    [buttonClass]="'btn btn-primary'"
    [type]="'button'"
    [buttonLabel]="(forceConsent ? 'common.confirm_action' : 'common.close_action') | cxTranslate"
    (click)="close()"
  >
  </py-action-button>
</ng-template>
