<ng-container
  *ngIf="{
    displayCurrentSoldToInTopMenu: displayCurrentSoldToInTopMenu$ | async,
    activeUser: activeUser$ | async,
    activeSoldTo: activeSoldTo$ | async
  } as data"
>
  <div
    #currentCustomerBannerElement
    id="current-customer-banner"
    class="current-customer-banner"
    [class.display-current-sold-to]="data.displayCurrentSoldToInTopMenu"
  >
    <ng-container *ngIf="data.displayCurrentSoldToInTopMenu && data.activeSoldTo && data.activeUser">
      <span class="sold-to">
        {{ data.activeSoldTo.uid }}: {{ data.activeSoldTo.customerAddress?.name1
        }}{{ data.activeSoldTo.customerAddress?.name2 ? ', ' : ' ' }}{{ data.activeSoldTo.customerAddress?.name2 }}
      </span>
      <span class="divider"></span>
      <span class="sold-to" *ngIf="data.activeSoldTo.customerAddress as address">
        <span *ngIf="address.street">{{ address.street }}{{ address.city ? ', ' : '' }}</span>
        <span *ngIf="address.city">{{ address.city }}</span>
      </span>
      <span class="divider"></span>
      <span class="sold-to">{{ data.activeUser.name }}</span>
    </ng-container>
  </div>
</ng-container>
