import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, merge, of } from 'rxjs';
import { catchError, concatMap, groupBy, map, mergeMap } from 'rxjs/operators';
import { KeyedResultArray, Product } from '../../../../model';
import { normalizeHttpError } from '../../../../util';
import { bufferDebounceTime } from '../../../../util/rxjs/buffer-debounce-time';
import { ProductConnector } from '../../connector/product-connector';
import { ProductActions } from '../actions';
import { ProductActionTypes } from '../actions/product.action';

@Injectable()
export class ProductEffects {
  // private contextChange$ = this.actions$.pipe(
  //   ofType(
  //     RouterActionTypes.Go,
  //     RouterActionTypes.Back,
  //     RouterActionTypes.Forward,
  //     RouterActionTypes.GoByUrl,
  //     LanguagesActionTypes.LanguageChange,
  //     ChangeSoldToActionTypes.ChangeSoldToSuccess,
  //   ),
  // );

  loadProduct$: Observable<ProductActions.LoadProductSuccess | ProductActions.LoadProductFail> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActionTypes.LoadProduct),
      map((action) => <ProductActions.LoadProduct>action),
      groupBy((action) => action.key),
      mergeMap((group) => group.pipe(bufferDebounceTime(10))),
      concatMap((inputs: Array<ProductActions.LoadProduct>) =>
        this.connector
          .loadProducts(
            inputs[0].userId,
            inputs.map((a) => a.payload),
            inputs[0].myAssortment
          )
          .pipe(
            mergeMap((keyedResult: KeyedResultArray<Product>) => {
              return inputs.map((i) => {
                const product = keyedResult.result.find((p) => p.code === i.payload);
                if (product) {
                  // We send the success action with the key from the result
                  return new ProductActions.LoadProductSuccess(product, keyedResult.key);
                } else {
                  // If we do not get the product back, we send a failed action for that specific product
                  return new ProductActions.LoadProductFail(i.payload, i.key, undefined);
                }
              });
            }),
            catchError((error) => {
              return merge(
                ...inputs.map((i) => of(new ProductActions.LoadProductFail(i.payload, i.key, normalizeHttpError(error))))
              );
            })
          )
      )
      // TODO: ESVCX-1472 If withdrawOn should happen, then this must clear/reset products in 'inputs'
      //    with loading state since this aborts in progress downloads.
      //withdrawOn(this.contextChange$),
    )
  );

  loadSubstituteProducts$: Observable<ProductActions.LoadSubstituteProductsSuccess | ProductActions.LoadSubstituteProductsFail> =
    createEffect(() =>
      this.actions$.pipe(
        ofType<ProductActions.LoadSubstituteProducts>(ProductActionTypes.LoadSubstituteProducts),
        mergeMap((action) =>
          this.connector.loadSubstituteProducts(action.userId, action.code).pipe(
            map((substituteProducts) => new ProductActions.LoadSubstituteProductsSuccess(substituteProducts)),
            catchError((error) => of(new ProductActions.LoadSubstituteProductsFail(action.code, normalizeHttpError(error))))
          )
        )
      )
    );

  loadA4SampleColors$: Observable<ProductActions.LoadA4SampleColorsSuccess | ProductActions.LoadA4SampleColorsFail> =
    createEffect(() =>
      this.actions$.pipe(
        ofType(ProductActionTypes.LoadA4SampleColors),
        map((action) => <ProductActions.LoadA4SampleColors>action),
        concatMap((action) =>
          this.connector.loadA4SampleColors(action.userId, action.productCode, action.grammage).pipe(
            map((colors) => new ProductActions.LoadA4SampleColorsSuccess(action.productCode, action.grammage, colors)),
            catchError((error) =>
              of(new ProductActions.LoadA4SampleColorsFail(action.productCode, action.grammage, normalizeHttpError(error)))
            )
          )
        )
      )
    );

  constructor(private actions$: Actions, private connector: ProductConnector) {}
}
