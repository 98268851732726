import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { CxNumericPipe } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, filter, map, shareReplay, startWith } from 'rxjs/operators';
import { Article, Unit } from '../../../core/model';
import { PrincipalConfigurationService } from '../../../core/user';
import { UnitPipe } from '../../pipes/unit.pipe';
import { resolveDefaultArticleQuantity } from '../../utils/resolve-default-article-quantity';
import { resolveDefaultArticleUnit } from '../../utils/resolve-default-article-unit';
import { Option } from '../dropdown/dropdown.component';

@Component({
  selector: 'py-quantity-and-unit-selector',
  templateUrl: './quantity-and-unit-selector.component.html',
  styleUrls: ['./quantity-and-unit-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuantityAndUnitSelectorComponent implements OnInit, OnDestroy {
  @ViewChild('popOver', { static: false }) popover: NgbPopover;

  form = new UntypedFormGroup({
    quantity: new UntypedFormControl(1),
    unitCode: new UntypedFormControl(''),
  });
  units$: Observable<Option[]>;
  useArticleUnitAsDefault: boolean = false;
  showPopover = false;
  minimumOrderIndication: boolean = false;

  private subscriptions = new Subscription();
  private _article: Article;

  @Input() container: string = null;
  @Input() mobile: boolean = false;
  @Input() set article(article: Article) {
    this._article = article;
    this.calculateUnits();
  }
  @Input() set values(values: { quantity: number; unit?: string; unitCode?: string }) {
    this.form.setValue(
      {
        quantity: values?.quantity || 1,
        unitCode: values?.unitCode || values?.unit || '',
      },
      { emitEvent: false }
    );
  }
  @Input() minDisabledTooltipTextKey: string = 'common.minimumOrderQuantityIndication_tooltip';
  @Input() showUnselectableOption: boolean = true;
  @Input() initialMaximumQuantity?: number; // used in returns, max qty should be equal qty of article user ordered
  @Input() initialMinimumQuantity?: number; // used in returns, min qty to avoid calculations based on article config
  @Input() useMinimumQuantityStep: boolean = false; // qty step won't be calculated and will be equal 1

  @Output() valueChanges = new EventEmitter<{ quantity: number; unitCode: string }>();
  @Output() belowMinimum = new EventEmitter<boolean>();

  get unitCodeControl(): UntypedFormControl {
    return this.form.get('unitCode') as UntypedFormControl;
  }

  get quantityControl(): UntypedFormControl {
    return this.form.get('quantity') as UntypedFormControl;
  }

  get article(): Article {
    return this._article;
  }

  get selectedUnit(): Unit {
    return this.article.units?.find((u) => u.code === this.unitCodeControl.value);
  }

  get quantityStep(): number {
    if (this.useMinimumQuantityStep) {
      return 1;
    }

    let unit = this.selectedUnit;
    let qty = Math.ceil(this.article.deliveryQuantity / (unit?.inEcommerceUnit || 1));

    if (!unit) {
      unit = resolveDefaultArticleUnit(this.article, this.useArticleUnitAsDefault);
      qty = resolveDefaultArticleQuantity(this.article, unit, this.useArticleUnitAsDefault);
    }

    return qty || 1;
  }

  get maximumQuantity(): number {
    if (!this.initialMaximumQuantity) {
      return 999999;
    }

    let unit = this.selectedUnit;
    if (!unit) {
      unit = resolveDefaultArticleUnit(this.article, this.useArticleUnitAsDefault);
    }

    return Math.ceil(this.initialMaximumQuantity / (unit?.inEcommerceUnit || 1));
  }

  get minimumQuantity(): number | undefined {
    return this.initialMinimumQuantity
      ? this.initialMinimumQuantity
      : Math.ceil(this.article.minimumOrderQuantity / (this.selectedUnit?.inEcommerceUnit || 1));
  }

  constructor(
    private cxDecimalPipe: CxNumericPipe,
    private unitPipe: UnitPipe,
    private principalConfigurationService: PrincipalConfigurationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.calculateUnits();

    this.subscriptions.add(
      this.unitCodeControl.valueChanges.subscribe((value) => {
        if (this.initialMinimumQuantity) {
          this.quantityControl.setValue(this.initialMinimumQuantity);
          this.valueChanges.emit({ quantity: this.initialMinimumQuantity, unitCode: value });
        } else {
          const unit = this.article.units?.find((u) => u.code === value);
          const qty = Math.ceil(this.article.deliveryQuantity / (unit?.inEcommerceUnit || 1));

          this.quantityControl.setValue(qty || 1);
          this.valueChanges.emit({ quantity: qty || 1, unitCode: value });
        }
      })
    );

    this.subscriptions.add(
      this.quantityControl.valueChanges
        .pipe(
          debounceTime(300),
          filter((value) => !this.minimumOrderIndication || (this.minimumOrderIndication && value !== null))
        )
        .subscribe((value) => {
          this.valueChanges.emit({ quantity: value, unitCode: this.unitCodeControl.value });

          if (this.minimumOrderIndication) {
            this.togglePopover(value);
          }
        })
    );

    this.subscriptions.add(
      this.principalConfigurationService
        .isEnabled('useArticleUnitAsDefault')
        .pipe(shareReplay({ bufferSize: 1, refCount: true }))
        .subscribe((val) => {
          return (this.useArticleUnitAsDefault = val);
        })
    );

    this.subscriptions.add(
      this.principalConfigurationService
        .isEnabled('minimumOrderIndication')
        .pipe(shareReplay({ bufferSize: 1, refCount: true }))
        .subscribe((val) => {
          return (this.minimumOrderIndication = val);
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private togglePopover(value: number): void {
    if (value < this.minimumQuantity) {
      this.showPopover = true;
      this.popover.open();
      this.belowMinimum.emit(true);
    } else {
      this.popover.close();
      this.showPopover = false;
      this.belowMinimum.emit(false);
    }

    this.cd.detectChanges();
  }

  private calculateUnits(): void {
    this.units$ = this.quantityControl.valueChanges.pipe(startWith(this.quantityControl.value)).pipe(
      map((quantity) =>
        this.article.units
          ?.filter((unit) => (this.initialMaximumQuantity ? this.initialMaximumQuantity >= unit?.inEcommerceUnit : true))
          .map((unit) => ({
            value: unit.code,
            label: this.unitPipe.transform(unit, quantity),
            description: `${this.cxDecimalPipe.transform(unit?.inEcommerceUnit, '1.0-0')} ${this.unitPipe.transform(
              this.article.unit,
              unit?.inEcommerceUnit
            )}`,
          }))
      )
    );
  }
}
