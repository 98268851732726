import { TranslationService as CxTranslationService } from '@spartacus/core';
import { Observable } from 'rxjs';

export abstract class TranslationService extends CxTranslationService {
  /**
   * A non-observable way to get a translated string which already exists in the cache
   */
  translateFromCache: (key: string | string[], options?: any) => string | undefined;
  /**
   * Can be used as a placeholder before translation is implemented
   */
  translate2: (key: string, options?: any, whitespaceUntilLoaded?: boolean) => Observable<string>;
}
