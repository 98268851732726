import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { UserIdService } from '../../../core/auth';
import { ContentTag } from '../../../core/model';
import { CmsContentTagConnector } from '../connectors';
import { CmsContentTagsSelectors, ContentTagActions, StateWithCmsContentTag } from '../store';

@Injectable({ providedIn: 'root' })
export class CmsContentTagService {
  constructor(
    private store: Store<StateWithCmsContentTag>,
    private connector: CmsContentTagConnector,
    private userIdService: UserIdService
  ) {}

  findUIDsForMasterTagAndFilterTags(
    masterTag: string,
    componentType: string,
    sort?: string,
    filterTags?: string
  ): Observable<Array<string>> {
    return this.userIdService
      .takeUserId()
      .pipe(
        switchMap((userId) =>
          this.connector.findUIDsForMasterTagAndFilterTags(userId, masterTag, componentType, sort, filterTags)
        )
      );
  }

  private loadContentTag(uid: string) {
    this.userIdService.takeUserId().subscribe(
      (userId) => this.store.dispatch(new ContentTagActions.LoadContentTag({ userId, uid })),
      () => {}
    );
  }

  getContentTag(uid: string): Observable<ContentTag> {
    return this.store.select(CmsContentTagsSelectors.getSelectedContentTagState(uid)).pipe(
      filter((loaderState) => {
        if (this.needsToLoad(loaderState)) {
          this.loadContentTag(uid);
          return false;
        }
        return loaderState.success;
      }),
      map((loaderState) => loaderState.value),
      take(1)
    );
  }

  private needsToLoad(loaderState: StateUtils.LoaderState<ContentTag>) {
    return !loaderState.success && !loaderState.error && !loaderState.loading;
  }
}
