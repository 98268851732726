import { ModuleWithProviders, NgModule } from '@angular/core';
import { GlobalMessageModule as CxGlobalMessageModule, GlobalMessageService as CxGlobalMessageService } from '@spartacus/core';
import { provideDefaultConfig } from '../config/config-providers';
import { defaultGlobalMessageConfig } from './config/default-global-message-config';
import { GlobalMessageService as PyGlobalMessageService } from './facade';
import { errorHandlers, httpErrorInterceptors } from './http-interceptors/index';

@NgModule({
  imports: [CxGlobalMessageModule],
  providers: [provideDefaultConfig(defaultGlobalMessageConfig)],
})
export class GlobalMessageModule {
  static forRoot(): ModuleWithProviders<GlobalMessageModule> {
    return {
      ngModule: GlobalMessageModule,
      providers: [
        ...errorHandlers,
        ...httpErrorInterceptors,
        { provide: CxGlobalMessageService, useExisting: PyGlobalMessageService },
      ],
    };
  }
}
