import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../../../core/i18n';
import { UrlModule } from '../../../../../core/routing';
import { PrincipalConfigurationDirectiveModule } from '../../../../../shared';
import { ArticlePriceModule } from '../../../../../shared/components/article-price';
import { ArticleImageModule } from '../../../../../shared/components/article/image';
import { ArticleInfoModule } from '../../../../../shared/components/article/info';
import { FullPalletUpgradeModule } from '../../../../../shared/components/full-pallet-upgrade';
import { NotificationModule } from '../../../../../shared/components/notification';
import { PipesModule } from '../../../../../shared/pipes/pipes.module';
import { CartMessageModalHeaderModule } from '../cart-message-modal-header/cart-message-modal-header.module';
import { CartMessageModalComponent } from './cart-message-modal.component';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    NzIconModule,
    I18nModule,
    ArticleInfoModule,
    ArticleImageModule,
    ArticlePriceModule,
    FullPalletUpgradeModule,
    PrincipalConfigurationDirectiveModule,
    NotificationModule,
    CartMessageModalHeaderModule,
    RouterModule,
    UrlModule,
  ],
  declarations: [CartMessageModalComponent],
  exports: [CartMessageModalComponent],
})
export class CartMessageModalModule {}
