import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { KeyboardFocusModule } from '@spartacus/storefront';
import { I18nModule } from '../../../core/i18n';
import { LaunchDialogService } from '../../../core/modal';
import { UrlModule } from '../../../core/routing';
import { PipesModule, RemoveHostModule, ResponsiveTableModule, SpinnerModule } from '../../../shared';
import { ModalModule } from '../../../shared/components/modal';
import { OrderEntryModule } from '../../../shared/components/order-entry';
import { OrderPriceSummaryModule } from '../../../shared/components/order-price-summary';
import { activeOrderProcessModalLayoutConfig } from './active-order-process-modal-layout.config';
import { ActiveOrderProcessModalComponent } from './active-order-process-modal.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    I18nModule,
    PipesModule,
    ModalModule,
    ResponsiveTableModule,
    OrderEntryModule,
    OrderPriceSummaryModule,
    RemoveHostModule,
    KeyboardFocusModule,
    SpinnerModule,
  ],
  declarations: [ActiveOrderProcessModalComponent],
  exports: [ActiveOrderProcessModalComponent],
})
export class ActiveOrderProcessModalModule {
  constructor(private launchDialogService: LaunchDialogService) {
    this.launchDialogService.registerModalLayoutConfig(activeOrderProcessModalLayoutConfig);
  }
}
