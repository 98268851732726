import { TemplateRef, Type } from '@angular/core';
import { DrawerRef } from './drawer-ref';

export enum DrawerWidth {
  Default = '500px',
  NearFull = '90%',
}

export enum DrawerPlacement {
  Default = 'right',
  Top = 'top',
}

/**
 * Options available for drawer instance
 */
export interface DrawerOptions<T = any, D = any> {
  template:
    | TemplateRef<{
        $implicit: D;
        drawerRef: DrawerRef;
      }>
    | Type<T>;
  params?: Partial<T & D>;
  width?: DrawerWidth;
  height?: number | string;
  wrapClassName?: string;
  closeOnNavigation?: boolean;
  placement?: DrawerPlacement;
  offsetY?: number;
  zIndex?: number;
}
