import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KeyboardFocusModule } from '@spartacus/storefront';
import { I18nModule } from '../../../core/i18n';
import { LaunchDialogService } from '../../../core/modal';
import { PipesModule } from '../../../shared';
import { ModalModule } from '../../../shared/components/modal';
import { orderApprovalAbortHijackModalLayoutConfig } from './order-approval-abort-hijack-modal-layout.config';
import { OrderApprovalAbortHijackModalComponent } from './order-approval-abort-hijack-modal.component';

@NgModule({
  imports: [CommonModule, I18nModule, PipesModule, ModalModule, KeyboardFocusModule],
  declarations: [OrderApprovalAbortHijackModalComponent],
  exports: [OrderApprovalAbortHijackModalComponent],
})
export class OrderApprovalAbortHijackModalModule {
  constructor(private launchDialogService: LaunchDialogService) {
    this.launchDialogService.registerModalLayoutConfig(orderApprovalAbortHijackModalLayoutConfig);
  }
}
