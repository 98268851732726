import { StateUtils } from '@spartacus/core';
import { loaderReducer } from '../loader/loader.reducer';
import { EntityFailAction } from './entity-loader.action';

/**
 * Higher order reducer that wraps LoaderReducer and EntityReducer enhancing
 * single state reducer to support multiple entities with generic loading flags
 */
export function entityLoaderReducer<T>(
  entityType: string,
  reducer?: (state: T, action: StateUtils.LoaderAction) => T
): (
  state: StateUtils.EntityLoaderState<T>,
  action: StateUtils.EntityLoaderAction | EntityFailAction
) => StateUtils.EntityLoaderState<T> {
  return StateUtils.entityReducer(entityType, loaderReducer(entityType, reducer));
}
