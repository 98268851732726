<py-carousel
  [items]="items"
  [browseReplacementUrl]="browseReplacementUrl"
  [hideOverflow]="hideOverflow"
  [listItemContentTemplate]="item"
>
  <h2 *ngIf="!smallTitle; else substitutesTitle">
    <span *ngIf="title; else defaultTitle">{{ title }}</span>
  </h2>
</py-carousel>

<ng-template #substitutesTitle>
  <p class="title">
    <span *ngIf="title; else defaultTitle">{{ title }}</span>
  </p>
</ng-template>

<ng-template #defaultTitle>
  {{ 'catalog.mightAlsoBeInterestedIn_title' | cxTranslate }}
</ng-template>

<ng-template #item let-item>
  <ng-container
    *ngTemplateOutlet="
      !item.alternative && !item.replacement && !item.recommended
        ? item.link
          ? linkTemplate
          : noLinkTemplate
        : substitutesTemplate;
      context: { $implicit: item }
    "
  ></ng-container>
</ng-template>

<ng-template #linkTemplate let-item>
  <cx-generic-link [url]="item.link" [class.product-link-secondary]="catalogProductItemSecondary$ | async">
    <ng-container *ngTemplateOutlet="productTile; context: { $implicit: item }"></ng-container>
  </cx-generic-link>
</ng-template>

<ng-template #noLinkTemplate let-item>
  <button
    class="btn btn-link h-100 p-0"
    [class.product-button-secondary]="catalogProductItemSecondary$ | async"
    (click)="onItemSelect(item)"
  >
    <ng-container *ngTemplateOutlet="productTile; context: { $implicit: item }"></ng-container>
  </button>
</ng-template>

<ng-template #substitutesTemplate let-item>
  <ng-container
    *ngIf="{
      catalogProductItemSecondary: catalogProductItemSecondary$ | async
    } as observables"
  >
    <div class="product substitutes" [class.product-secondary]="observables.catalogProductItemSecondary">
      <div class="d-flex flex-column h-100 content-wrapper">
        <div class="d-flex flex-column h-100 item-content" [class.pb-4]="observables.catalogProductItemSecondary">
          <div class="image-container" [class.mb-4]="!observables.catalogProductItemSecondary">
            <img
              [class.product-image-secondary]="observables.catalogProductItemSecondary"
              [pyMedia]="item.product.thumbnail"
              [alt]="item.product.name"
            />
          </div>

          <ng-container *ngTemplateOutlet="productDescriptionTemplate"></ng-container>

          <div [class.ml-5]="observables.catalogProductItemSecondary">
            <py-badge *ngIf="item.replacement" class="mt-3" type="replacement" [tag]="true">
              {{ 'catalog.substitute_replacement_badge' | cxTranslate }}
            </py-badge>

            <py-badge *ngIf="item.alternative" class="mt-3" type="alternative" [tag]="true">
              {{ 'catalog.substitute_alternative_badge' | cxTranslate }}
            </py-badge>

            <py-badge *ngIf="item.recommendation" class="mt-3" type="recommended" [tag]="true">
              {{ 'catalog.substitute_recommendation_badge' | cxTranslate }}
            </py-badge>

            <ng-container *pyPrincipalConfiguration="'enableDisplayOutgoingArea'">
              <py-badge
                *ngIf="
                  item.product?.outgoing?.hasOutgoingArticles &&
                  ((enableProductOutletBadgeOnlyIfAllArticlesAreOutlet$ | async)
                    ? !item.product?.outgoing?.hasNonOutgoingArticles
                    : true)
                "
                class="mt-3"
                type="outlet"
                [tag]="true"
              >
                {{ 'catalog.outlet_badge' | cxTranslate }}
              </py-badge>
            </ng-container>
          </div>

          <div [class.mx-5]="observables.catalogProductItemSecondary">
            <button class="btn btn-primary btn-block mt-3" (click)="onItemSelect(item)">
              {{ 'common.chooseAndReplace_action' | cxTranslate }}
            </button>
            <button class="btn btn-ternary btn-block mt-3" (click)="goToProduct(item.product.url)">
              {{ 'catalog.viewProduct_action' | cxTranslate }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <ng-template #productDescriptionTemplate>
      <ng-container *ngIf="observables.catalogProductItemSecondary; else primaryProductItemTemplate">
        <div class="text-center flex-grow-1">
          <span class="product-name mt-2">{{ item.product?.name }}</span>
          <div class="product-description mt-2 px-4">
            {{ item.product?.description }}
          </div>
        </div>
      </ng-container>
      <ng-template #primaryProductItemTemplate>
        <h6>{{ item.product.name }}</h6>
      </ng-template>
    </ng-template>
  </ng-container>
</ng-template>

<ng-template #productTile let-item>
  <ng-container
    *ngIf="{
      catalogProductItemSecondary: catalogProductItemSecondary$ | async
    } as observables"
  >
    <div class="product" [class.product-secondary]="observables.catalogProductItemSecondary">
      <div class="d-flex flex-column h-100 content-wrapper">
        <div class="d-flex flex-column item-content" [class.pb-4]="observables.catalogProductItemSecondary">
          <div class="image-container" [class.mb-4]="!observables.catalogProductItemSecondary">
            <div class="badges">
              <ng-container *ngTemplateOutlet="outletBadgeTemplate"></ng-container>
            </div>
            <img
              [class.product-image-secondary]="observables.catalogProductItemSecondary"
              [pyMedia]="item.product.thumbnail"
              [alt]="item.product.name"
            />
          </div>

          <ng-container *ngTemplateOutlet="productDescriptionTemplate"></ng-container>
        </div>
      </div>
    </div>

    <ng-template #productDescriptionTemplate>
      <ng-container *ngIf="observables.catalogProductItemSecondary; else primaryProductItemTemplate">
        <div class="text-center flex-grow-1">
          <span class="product-name mt-2">{{ item.product?.name }}</span>
          <div class="product-description mt-2 px-4">
            {{ item.product?.description }}
          </div>
        </div>
      </ng-container>
      <ng-template #primaryProductItemTemplate>
        <h6>{{ item.product.name }}</h6>
      </ng-template>
    </ng-template>
  </ng-container>

  <ng-template #outletBadgeTemplate>
    <ng-container *pyPrincipalConfiguration="'enableDisplayOutgoingArea'">
      <py-badge
        *ngIf="
          item.product?.outgoing?.hasOutgoingArticles &&
          ((enableProductOutletBadgeOnlyIfAllArticlesAreOutlet$ | async) ? !item.product?.outgoing?.hasNonOutgoingArticles : true)
        "
        type="outlet"
        [tag]="true"
        [large]="true"
      >
        {{ 'catalog.outlet_badge' | cxTranslate }}
      </py-badge>
    </ng-container>
  </ng-template>
</ng-template>
