<ng-container *ngIf="hasUniqueArticles$ | async">
  <li py-megamenu-list-item>
    <cx-generic-link
      [routerUrl]="{ cxRoute: 'catalog' } | cxUrl"
      [queryParams]="{
        tab: 'a',
        query: '::allCategories:' + customer_unique_category_code + ':productCode:CUST_UNIQ',
        mya: mya ? '1' : 0
      }"
    >
      {{ 'navigation.customizedArticles_action' | cxTranslate }}
    </cx-generic-link>
  </li>
</ng-container>
<ng-container *ngIf="hasTendered$ | async">
  <li py-megamenu-list-item>
    <cx-generic-link [routerUrl]="{ cxRoute: 'catalog' } | cxUrl" [queryParams]="{ tab: 'a', t: '1', mya: mya ? '1' : 0 }">
      {{ 'navigation.tenderedArticles_action' | cxTranslate }}
    </cx-generic-link>
  </li>
</ng-container>
