import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'py-header-navigation-item',
  templateUrl: './header-navigation-item.component.html',
  styleUrls: ['./header-navigation-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderNavigationItemComponent implements OnInit {
  @HostBinding('class.active')
  @Input()
  active = false;

  @Input() url?: string | any[];
  @Input() target?: string;

  constructor() {}

  ngOnInit(): void {}
}
