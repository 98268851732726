import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../../core/i18n';
import { PipesModule } from '../../../pipes';
import { TogglePasswordVisibilityComponent } from '../toggle-password-visibility/toggle-password-visibility.component';
import { ReactiveFormInputFieldComponent } from './reactive-form-input-field.component';

@NgModule({
  declarations: [ReactiveFormInputFieldComponent, TogglePasswordVisibilityComponent],
  imports: [CommonModule, I18nModule, PipesModule, ReactiveFormsModule, NgbTooltipModule, NzIconModule],
  exports: [ReactiveFormInputFieldComponent],
})
export class ReactiveFormInputFieldModule {}
