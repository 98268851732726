import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import { PrincipalConfiguration, PrincipalConfigurationUpdateValue } from '../../../model';
import { PyStateUtils } from '../../../state/utils';
import { PRINCIPAL_CONFIGURATION, UPDATE_PRINCIPAL_CONFIGURATION_PROCESS } from '../user-state';

export enum PrincipalConfigurationActionTypes {
  LoadPrincipalConfigurationValues = '[User] Load principal configuration values',
  LoadPrincipalConfigurationValuesFail = '[User] Load configuration values fail',
  LoadPrincipalConfigurationValuesSuccess = '[User] Load principal configuration values success',

  UpdatePrincipalConfigurationValue = '[User] Update principal configuration value',
  UpdatePrincipalConfigurationValueFail = '[User] Update configuration value fail',
  UpdatePrincipalConfigurationValueSuccess = '[User] Update principal configuration value success',
  UpdatePrincipalConfigurationValueReset = '[User] Update principal configuration value reset',
  ManuallyUpdatePrincipalConfigurationValue = '[User] Manually update principal configuration value',
}

export class LoadPrincipalConfigurationValues extends StateUtils.LoaderLoadAction {
  readonly type = PrincipalConfigurationActionTypes.LoadPrincipalConfigurationValues;

  constructor(public payload: string) {
    super(PRINCIPAL_CONFIGURATION);
  }
}

export class LoadPrincipalConfigurationValuesFail extends PyStateUtils.LoaderFailAction {
  readonly type = PrincipalConfigurationActionTypes.LoadPrincipalConfigurationValuesFail;

  constructor(public payload?: any) {
    super(PRINCIPAL_CONFIGURATION, payload);
  }
}

export class LoadPrincipalConfigurationValuesSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = PrincipalConfigurationActionTypes.LoadPrincipalConfigurationValuesSuccess;

  constructor(public payload: PrincipalConfiguration[]) {
    super(PRINCIPAL_CONFIGURATION);
  }
}

export class ManuallyUpdatePrincipalConfigurationValue extends StateUtils.EntitySuccessAction {
  readonly type = PrincipalConfigurationActionTypes.ManuallyUpdatePrincipalConfigurationValue;

  constructor(public payload: PrincipalConfiguration) {
    super(PROCESS_FEATURE, UPDATE_PRINCIPAL_CONFIGURATION_PROCESS);
  }
}

export class UpdatePrincipalConfigurationValue extends StateUtils.EntityLoadAction {
  readonly type = PrincipalConfigurationActionTypes.UpdatePrincipalConfigurationValue;

  constructor(public userId: string, public configValue: PrincipalConfigurationUpdateValue) {
    super(PROCESS_FEATURE, UPDATE_PRINCIPAL_CONFIGURATION_PROCESS);
  }
}

export class UpdatePrincipalConfigurationValueFail extends PyStateUtils.EntityFailAction {
  readonly type = PrincipalConfigurationActionTypes.UpdatePrincipalConfigurationValueFail;

  constructor(public payload?: any) {
    super(PROCESS_FEATURE, UPDATE_PRINCIPAL_CONFIGURATION_PROCESS, payload);
  }
}

export class UpdatePrincipalConfigurationValueSuccess extends StateUtils.EntitySuccessAction {
  readonly type = PrincipalConfigurationActionTypes.UpdatePrincipalConfigurationValueSuccess;

  constructor(public payload: PrincipalConfiguration) {
    super(PROCESS_FEATURE, UPDATE_PRINCIPAL_CONFIGURATION_PROCESS);
  }
}

export class UpdatePrincipalConfigurationValueReset extends StateUtils.EntityLoaderResetAction {
  readonly type = PrincipalConfigurationActionTypes.UpdatePrincipalConfigurationValueReset;
  constructor() {
    super(PROCESS_FEATURE, UPDATE_PRINCIPAL_CONFIGURATION_PROCESS);
  }
}

export type PrincipalConfigurationActions =
  | LoadPrincipalConfigurationValues
  | LoadPrincipalConfigurationValuesFail
  | LoadPrincipalConfigurationValuesSuccess
  | UpdatePrincipalConfigurationValue
  | UpdatePrincipalConfigurationValueFail
  | UpdatePrincipalConfigurationValueSuccess
  | UpdatePrincipalConfigurationValueReset
  | ManuallyUpdatePrincipalConfigurationValue;
