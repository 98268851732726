import { MemoizedSelector, MemoizedSelectorWithProps, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { AddonGroup } from '../../../../model';
import { AddonGroupEntries, CatalogState, StateWithCatalog } from '../../../store/catalog-state';
import { getCatalogState } from '../../../store/selectors/feature.selector';

export const getAddonGroupsState: MemoizedSelector<StateWithCatalog, StateUtils.LoaderState<AddonGroupEntries>> = createSelector(
  getCatalogState,
  (state: CatalogState) => state && state.addonGroups
);

export const getAddonGroupsEntriesState: MemoizedSelectorWithProps<StateWithCatalog, any, AddonGroupEntries> = createSelector(
  getAddonGroupsState,
  (state) => (state && StateUtils.loaderValueSelector(state)) || undefined
);

export const getAddonGroupsLoading: MemoizedSelector<StateWithCatalog, boolean> = createSelector(
  getAddonGroupsState,
  (state) => (state && StateUtils.loaderLoadingSelector(state)) || false
);

export const getAddonGroupsLoaded: MemoizedSelector<StateWithCatalog, boolean> = createSelector(
  getAddonGroupsState,
  (state) => (state && StateUtils.loaderSuccessSelector(state)) || false
);

export const addonGroupByIdSelector: MemoizedSelectorWithProps<StateWithCatalog, any, AddonGroup> = createSelector(
  getAddonGroupsLoaded,
  getAddonGroupsEntriesState,
  (loaded, entriesState, props) => (loaded && entriesState && entriesState.entities[props.id]) || undefined
);
