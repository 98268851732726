import { StateUtils } from '@spartacus/core';
import { User } from '../../../model';
import { UpdateCredentialsActionTypes } from '../actions/credentials-action';
import { UserActions } from '../actions/index';
import { UserDetailsActionTypes } from '../actions/user-details.action';

export const initialState: User = <User>{};

export function reducer(state = initialState, action: UserActions.UserDetailsActions | StateUtils.LoaderAction): User {
  switch (action.type) {
    case UserDetailsActionTypes.LoadUserDetailsSuccess: {
      const userDetails = {
        ...action.payload,
      };
      if (action.payload.firstName) {
        userDetails.name = action.payload.firstName;
      }
      if (action.payload.lastName) {
        userDetails.name += ' ' + action.payload.lastName;
      }
      return userDetails;
    }

    case UserDetailsActionTypes.UpdateUserDetailsSuccess: {
      const updatedDetails: User = {
        ...state,
        ...action.payload,
      };
      return {
        ...updatedDetails,
        name: `${updatedDetails.firstName} ${updatedDetails.lastName}`,
      };
    }

    case UpdateCredentialsActionTypes.UpdateCredentialsSuccess: {
      if (action?.payload?.credentials?.email) {
        return {
          ...state,
          uid: action?.payload?.credentials?.userName || state.uid,
          displayUid: action?.payload?.credentials?.userName || state.displayUid,
          email: action?.payload?.credentials?.email || state.email,
        };
      } else {
        return state;
      }
    }
  }

  return state;
}
