<ng-container
  *ngIf="{
    bannerVisible: bannerVisible$ | async,
    settingsVisible: settingsVisible$ | async,
    hasPageSitePrefix: hasPageSitePrefix$ | async
  } as data"
>
  <div
    *ngIf="data.hasPageSitePrefix && (data.bannerVisible || data.settingsVisible)"
    @fadeInFadeOutAnimation
    class="overlay-body visible"
  ></div>
  <ng-container *ngIf="data.hasPageSitePrefix && data.bannerVisible">
    <py-consent-banner
      class="anonymous-container"
      (viewSettings)="onViewSettings()"
      (allowAll)="onAllowAll()"
    ></py-consent-banner>
  </ng-container>
  <ng-container *ngIf="data.settingsVisible">
    <ng-container *ngIf="anonymousConsentTemplates$ | async as consentTemplates">
      <py-anonymous-consent-settings
        class="anonymous-container mh-100"
        [showCloseButton]="showCloseButtin$ | async"
        [consentTemplates]="consentTemplates"
        [anonymousConsents]="anonymousConsents$ | async"
        (saveSettings)="saveSettings($event)"
        (showBanner)="hideSettingsAndShowBanner()"
        (hideSettings)="hideSettings()"
      ></py-anonymous-consent-settings>
    </ng-container>
  </ng-container>
</ng-container>
