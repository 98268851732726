import { StateUtils } from '@spartacus/core';
import { EntriesState, SearchPageResultState, SearchState } from '../../../core/features/store/base-feature-state';
import {
  DeliveryAddress,
  InvoiceHistory,
  InvoiceHistorySearchParams,
  OrderDetails,
  OrderHistory,
  OrderHistorySearchParams,
  OrderReturn,
  Price,
  ReturnReason,
  SimpleCustomer,
  TrackedOrder,
} from '../../../core/model';
import { OrderHistoryFiltersSearchParams } from '../../../core/model/search.model';

export const ORDER_CORE_FEATURE = 'orderCore';
export const ORDER_FEATURE = 'order';

export const ORDER_TRACKING = '[Order] Order tracking';
export const ORDER_DETAILS = '[Order] Order details';
export const ORDER_HISTORY = '[Order] Order history';
export const ORDER_HISTORY_USERS = '[Order] Order history users';
export const ORDER_HISTORY_SHIPTOS = '[Order] Order history shipTos';
export const INVOICE_HISTORY = '[Order] Invoice history';
export const LAST_ORDERED_ARTICLES = '[Order] Last ordered articles';
export const MOST_ORDERED_ARTICLES = '[Order] Most ordered articles';
export const ORDER_RETURN = '[Order] Order return';
export const ORDER_RETURN_DRAFT = '[Order] Order return draft';
export const ORDER_RETURN_REASONS = '[Order] Order return reasons';

export interface StateWithOrder {
  [ORDER_FEATURE]: OrderState;
}

export interface OrderState {
  orderTracking: StateUtils.EntityLoaderState<TrackedOrder>;
  orderDetails: StateUtils.EntityLoaderState<OrderDetails>;
  orderHistory: OrderHistoryState;
  orderHistoryUsers: OrderHistoryUsersState;
  orderHistoryShipTos: OrderHistoryShipTosState;
  invoiceHistory: InvoiceHistoryState;
  lastOrderedArticles: StateUtils.LoaderState<SearchPageResultState>;
  mostOrderedArticles: StateUtils.LoaderState<SearchPageResultState>;
  orderReturnState: OrderReturnState;
}

export interface OrderHistoryState {
  search: SearchState<SearchPageResultState>;
  searchParams: OrderHistorySearchParams;
  searchEntities: EntriesState<OrderHistory>;
  entities: StateUtils.EntityLoaderState<OrderHistory>;
}

export interface OrderHistoryUsersState {
  search: SearchState<SearchPageResultState>;
  searchEntities: EntriesState<SimpleCustomer>;
  searchParams: OrderHistoryFiltersSearchParams;
}

export interface OrderHistoryShipTosState {
  search: SearchState<SearchPageResultState>;
  searchEntities: EntriesState<DeliveryAddress>;
  searchParams: OrderHistoryFiltersSearchParams;
}

export interface OrderReturnState {
  orderReturn: StateUtils.LoaderState<OrderReturn>;
  orderReturnDraft: StateUtils.LoaderState<OrderReturn>;
  orderReturnReasons: StateUtils.LoaderState<ReturnReason[]>;
}

export interface InvoiceHistoryState {
  search: SearchState<InvoiceHistorySearchPageResultState>;
  searchParams: InvoiceHistorySearchParams;
  searchEntities: EntriesState<InvoiceHistory>;
  entities: StateUtils.EntityLoaderState<InvoiceHistory>;
}

export interface InvoiceHistorySearchPageResultState extends SearchPageResultState {
  invoiceListSumTotal?: Price;
}
