import { MemoizedSelector, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { AnalyticsMetadata } from '../../../occ/occ-models/occ-generated.models';
import { PyStateUtils } from '../../../state';
import { StateWithUser, UserState } from '../user-state';
import { getUserState } from './feature.selector';

export const getAnalyticsMetadataLoaderState: MemoizedSelector<
  StateWithUser,
  StateUtils.LoaderState<AnalyticsMetadata>
> = createSelector(getUserState, (state: UserState) => (state && state.analyticsMetadata) || PyStateUtils.initialLoaderState);

export const getAnalyticsMetadataLoading: MemoizedSelector<StateWithUser, boolean> = createSelector(
  getAnalyticsMetadataLoaderState,
  (state: StateUtils.LoaderState<AnalyticsMetadata>) => StateUtils.loaderLoadingSelector(state)
);

export const getAnalyticsMetadataLoaded: MemoizedSelector<StateWithUser, boolean> = createSelector(
  getAnalyticsMetadataLoaderState,
  (state: StateUtils.LoaderState<AnalyticsMetadata>) => StateUtils.loaderSuccessSelector(state)
);

export const getAnalyticsMetadata: MemoizedSelector<StateWithUser, AnalyticsMetadata> = createSelector(
  getAnalyticsMetadataLoaderState,
  (state: StateUtils.LoaderState<AnalyticsMetadata>) => StateUtils.loaderValueSelector(state)
);
