<ng-container *ngIf="showButton; else textTemplate" [ngTemplateOutlet]="buttonTemplate"></ng-container>

<ng-template #buttonTemplate>
  <div class="wrapper">
    <ng-container *ngIf="entry?.reaming?.reamed; else addReaming" [ngTemplateOutlet]="removeReaming"> </ng-container>
  </div>
</ng-template>

<ng-template #addReaming>
  <span
    [ngbTooltip]="translationKeyPrefix + '.reaming.bothCuttingAndReaming_tooltip' | cxTranslate"
    [disableTooltip]="!entry.reelCutting && !entry.cutting"
  >
    <button (click)="save()" [disabled]="disabled" class="add-or-remove-button add px-2">
      <i nz-icon nzType="reaming-available" nzTheme="outline" class="mr-2"></i>
      <span>
        {{ translationKeyPrefix + '.reaming.addReaming_action' | cxTranslate }}
      </span>
    </button>
  </span>
</ng-template>

<ng-template #removeReaming>
  <div class="label" [class.disabled]="disabled">
    {{ translationKeyPrefix + '.reaming.reamingButton_heading' | cxTranslate }}
  </div>
  <button (click)="remove()" [disabled]="disabled" class="add-or-remove-button remove px-2">
    <span>
      <i nz-icon nzType="reaming-available" class="mr-2"></i
      >{{ translationKeyPrefix + '.reaming.buttonValue_hint' | cxTranslate }}
    </span>
    <div
      class="icon-holder delete"
      *ngIf="!disabled"
      [ngbTooltip]="translationKeyPrefix + '.reaming.removeReaming_tooltip' | cxTranslate"
    >
      <i nz-icon nzType="delete" nzTheme="outline"></i>
    </div>
  </button>
</ng-template>

<ng-template #textTemplate>
  <div class="text-wrapper d-flex align-items-start" *ngIf="!!entry?.reaming?.reamed">
    <i nz-icon nzType="reaming-available" class="mx-2"></i>
    <div class="d-flex flex-column w-100">
      <span class="text-label">{{ translationKeyPrefix + '.reaming.reamingButton_heading' | cxTranslate }}</span>
      <ul>
        <li class="d-flex justify-content-start align-items-center mb-2">
          {{ translationKeyPrefix + '.reaming.buttonValue_hint' | cxTranslate }}
        </li>
      </ul>
    </div>
  </div>
</ng-template>
