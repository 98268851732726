import { Injectable } from '@angular/core';
import { Address, Country, facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { AddressSearchParams, DeliveryAddress, LoaderError, Pagination, UserInputCheck } from '../../model';
import { USER_BASE_FEATURE } from '../store/user-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: AddressFacade,
      feature: USER_BASE_FEATURE,
      async: true,
      methods: [
        'getDeliveryCountries',
        'getProfileCountries',
        'getOnboardingCountries',
        'search',
        'getSearchParameters',
        'updateSearchParameters',
        'getAddressPagination',
        'getDeliveryAddressByTempId',
        'getDeliveryAddresses',
        'getDeliveryAddressesLoading',
        'getDeliveryAddressesLoadedErrorDetails',
        'deleteDeliveryAddress',
        'deleteDeliveryAddressSuccess',
        'deleteDeliveryAddressReset',
        'saveDeliveryAddress',
        'saveDeliveryAddressReset',
        'saveDeliveryAddressLoading',
        'saveDeliveryAddressFailure',
        'saveDeliveryAddressSuccess',
        'validatePhoneNumber',
        'validatePostalCode',
      ],
    }),
})
export abstract class AddressFacade {
  abstract getProfileCountries(): Observable<Country[]>;
  abstract getOnboardingCountries(): Observable<Country[]>;
  abstract search(searchParams: AddressSearchParams): void;
  abstract getSearchParameters(): Observable<AddressSearchParams>;
  abstract updateSearchParameters(searchParams: Omit<AddressSearchParams, 'key'>): void;
  abstract getAddressPagination(): Observable<Pagination | undefined>;
  abstract getDeliveryAddressByTempId(tempId: string): Observable<Address | undefined>;
  abstract getDeliveryAddresses(): Observable<Array<DeliveryAddress>>;
  abstract getDeliveryAddressesLoading(): Observable<boolean>;
  abstract getDeliveryAddressesLoadedErrorDetails(): Observable<LoaderError>;
  abstract deleteDeliveryAddress(id: string): void;
  abstract deleteDeliveryAddressSuccess(): Observable<boolean>;
  abstract deleteDeliveryAddressReset(): void;
  abstract saveDeliveryAddress(address: Address, generateTempCode?: boolean): Observable<string | undefined>;
  abstract saveDeliveryAddressReset(): void;
  abstract saveDeliveryAddressLoading(): Observable<boolean>;
  abstract saveDeliveryAddressFailure(): Observable<LoaderError | undefined>;
  abstract saveDeliveryAddressSuccess(): Observable<boolean>;
  abstract validatePostalCode(postalCode: string, countryIsoCode?: string): Observable<UserInputCheck>;
  abstract validatePhoneNumber(phoneNumber: string): Observable<UserInputCheck>;
  abstract getDeliveryCountries(): Observable<Country[]>;
}
