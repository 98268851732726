import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, merge, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ContentTag } from '../../../../core/model';
import { bufferDebounceTime, normalizeHttpError } from '../../../../core/util';
import { CmsContentTagConnector } from '../../connectors/content-tag';
import { ContentTagActions } from '../actions';
import { ContentTagActionTypes } from '../actions/content-tag.action';

@Injectable()
export class ContentTagEffects {
  loadContentTag$: Observable<ContentTagActions.LoadContentTagSuccess | ContentTagActions.LoadContentTagFail> = createEffect(() =>
    this.actions$.pipe(
      ofType(ContentTagActionTypes.LoadContentTag),
      map((action: ContentTagActions.LoadContentTag) => ({
        uid: action.payload.uid,
        userId: action.payload.userId,
      })),
      bufferDebounceTime(10),
      switchMap((inputs: Array<{ uid: string; userId: string }>) =>
        this.connector
          .loadContentTags(
            inputs[0].userId,
            inputs.map((c) => c.uid)
          )
          .pipe(
            mergeMap((contentTags: ContentTag[]) => contentTags.map((c) => new ContentTagActions.LoadContentTagSuccess(c))),
            catchError((error) => {
              return merge(...inputs.map((c) => of(new ContentTagActions.LoadContentTagFail(c.uid, normalizeHttpError(error)))));
            })
          )
      )
    )
  );

  constructor(private actions$: Actions, private connector: CmsContentTagConnector) {}
}
