<ul *ngIf="!resolved; else resolvedTemplate">
  <py-message-banner
    class="mb-2 w-100"
    [type]="'WARNING'"
    [title]="stockQuantityWarning"
    *ngIf="defaultResolveOptions?.length > 0"
  >
    <li *ngFor="let resolveOption of defaultResolveOptions" class="d-flex flex-column align-items-left pt-3">
      <p class="resolve-option-text" [innerHTML]="resolveOption.text"></p>
      <div>
        <ng-container [ngSwitch]="resolveOption.action">
          <ng-container *ngSwitchCase="'PRE_PRINT'">
            <button
              class="mt-2 btn btn-secondary btn-sm resolve-option-btn"
              [routerLink]="{ cxRoute: 'cart' } | cxUrl"
              [queryParams]="{ cartType: stockCartType }"
            >
              {{ resolveOption.title$ | async }}
            </button>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <button
              class="mt-2 btn btn-secondary btn-sm resolve-option-btn"
              (click)="onDefaultResolveOptionSelect(resolveOption)"
            >
              {{ resolveOption.title$ | async }}
            </button>
          </ng-container>
        </ng-container>
      </div>
    </li>
  </py-message-banner>

  <ng-container *ngIf="replacementResolveOption">
    <button class="mb-2 more-options link align-self-start" (click)="onToggleResolveOptions()">
      <span>
        {{ 'checkout.review.moreResolveOptions_action' | cxTranslate }}
      </span>
      <i
        class="reset ml-2"
        nz-icon
        [nzType]="isResolveOptionsExpanded ? 'minus-circle' : 'plus-circle'"
        nzTheme="outline"
        [class.rotate]=""
      ></i>
    </button>

    <li class="pt-1 pb-3 overflow-hidden" *ngIf="isResolveOptionsExpanded">
      <py-article-carousel
        [actionLabel]="'checkout.review.selectAndReplaceResolveOption_action'"
        [carouselItems]="(replacementResolveOption.alternatives$ | async) || []"
        [enableSecondaryArticleRowVariant]="enableSecondaryArticleRowVariant"
        (articleInteraction)="onSelectReplacementArticle($event)"
      >
        <h6>{{ replacementResolveOption.title$ | async }}</h6>
      </py-article-carousel>
    </li>
  </ng-container>
</ul>

<ng-template #resolvedTemplate>
  <div class="my-4 d-flex justify-content-center">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
