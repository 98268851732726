import { Action } from '@ngrx/store';

export * from './accounting-codes-admin.action';
export * from './analytics-metadata.action';
export * from './assortment.action';
export * from './company-action';
export * from './credentials-action';
export * from './delivery-address.action';
export * from './delivery-countries.action';
export * from './feed.action';
export * from './forgot-password.action';
export * from './forgot-username.action';
export * from './onboarding-countries.action';
export * from './order-approval.action';
export * from './order-cards-admin.action';
export * from './order-cards.action';
export * from './price.action';
export * from './principal-configuration.action';
export * from './profile-countries.actions';
export * from './punch-out-action';
export * from './punch-out-data-action';
export * from './recommendation.action';
export * from './register.action';
export * from './reset-password.action';
export * from './ship-tos.action';
export * from './shopping-list-entry.action';
export * from './shopping-list.action';
export * from './site-family-config.action';
export * from './stock-info.action';
export * from './user-consents.action';
export * from './user-details.action';

export enum UserModuleLoadedActions {
  USER_MODULE_LOADED = '[User] Module loaded',
}

export class UserModuleLoaded implements Action {
  readonly type = UserModuleLoadedActions.USER_MODULE_LOADED;

  constructor() {}
}
