<ng-container *ngIf="hasEntries$ | async">
  <py-cart-message-modal
    [entries]="entries$ | async"
    (update)="onCartEntryUpdate($event)"
    (skipUpdate)="onCartEntryUpdateSkip($event)"
    (closeModal)="onModalClose()"
    class="mb-2"
  ></py-cart-message-modal>
</ng-container>

<ng-container *ngIf="showRemainingCartMessage$ | async">
  <py-remaining-cart-message-modal
    [queryParams]="queryParams$ | async"
    (skipModal)="onModalSkip()"
  ></py-remaining-cart-message-modal>
</ng-container>
