<div class="mt-4" [ngClass]="{ skeleton: loadingConsignmentStockArticleStockInfo }"></div>

<ng-container *ngIf="!!consignmentStockArticleStockInfoError" [ngTemplateOutlet]="errorTemplate"></ng-container>

<ng-container
  *ngIf="consignmentStockArticleStockInfoSuccess && consignmentStockArticleStockInfo.warehouses?.length === 0"
  [ngTemplateOutlet]="errorTemplate"
>
</ng-container>

<ng-container *ngIf="consignmentStockArticleStockInfoSuccess && consignmentStockArticleStockInfo.warehouses?.length > 0">
  <div class="mb-3">
    <div class="section-title">
      {{ 'common.stockStatus_heading' | cxTranslate }}
    </div>

    <div class="section-body">
      <div class="item" *ngFor="let warehouseData of consignmentStockArticleStockInfo.warehouses">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <ng-container
              *ngTemplateOutlet="
                stockStatusTemplate;
                context: { $implicit: { warehouse: warehouseData.stock, warehouseName: warehouseData.warehouse.name } }
              "
            ></ng-container>
          </div>

          <span>{{ warehouseData.stock.quantity | cxNumeric : '1.0-3' }} {{ warehouseData.stock.unitString }}</span>
        </div>
        <div class="mt-2 d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <span class="gap"></span>
            <span class="fillup">
              {{ 'catalog.lastFillup_hint' | cxTranslate : { date: warehouseData.lastFillupDate | cxDate } }}
            </span>
          </div>
          <span class="fillup">
            {{ warehouseData.lastFillupStock.quantity | cxNumeric : '1.0-3' }} {{ warehouseData.lastFillupStock.unitString }}
          </span>
        </div>
      </div>
    </div>
  </div>
</ng-container>
