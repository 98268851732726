import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { PrincipalConfigurationService } from '../../user';
import { shallowEqualObjects } from '../../util/compare-equal-objects';
import { StateWithCatalog } from '../store/catalog-state';
import { CatalogSelectors } from '../store/selectors';

@Injectable({
  providedIn: 'root',
})
export class CatalogKeysService {
  constructor(
    private store: Store<StateWithCatalog>,
    private principalConfigurationService: PrincipalConfigurationService,
    private activatedRoute: ActivatedRoute
  ) {}

  private enableTogglingOfCustomerAssortment$ = this.principalConfigurationService.isEnabled(
    'enableTogglingOfCustomerAssortment'
  );

  private defaultCustomerAssortmentToggleState$ = this.principalConfigurationService.isEnabled(
    'defaultCustomerAssortmentToggleState'
  );

  getCatalogKeysReady(): Observable<boolean> {
    return this.store.pipe(select(CatalogSelectors.getCatalogKeysReady));
  }

  /**
   * Get the catalogUsageKey using the current queryParams and the status of the principalConfiguration
   * 'enableTogglingOfCustomerAssortment'. If 'enableTogglingOfCustomerAssortment' is TRUE AND a
   * queryParameter 'mya' is present in the current URL then the key will be mya:true if the value
   * of the mya parameter is '1', and 'mya:false' otherwise.
   *
   * The default value is 'default' if 'enableTogglingOfCustomerAssortment' is FALSE or no 'mya' query
   * parameter is present.
   */
  getCatalogUsageKey(): Observable<string> {
    return this.activatedRoute.queryParamMap.pipe(
      switchMap((params) => this.getCatalogUsageKeyForParams(params.get('mya'))),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  getKey(name: string = 'default'): Observable<string> {
    return this.store.pipe(
      select(CatalogSelectors.getCatalogKey, { catalogUsageKey: name }),
      filter((key) => !!key),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  getKeysChanged(): Observable<boolean> {
    return this.store.select(CatalogSelectors.getCatalogKeys).pipe(
      distinctUntilChanged(shallowEqualObjects),
      map((_) => true),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  getCatalogUsageKeyForParams(myaParam: string): Observable<string> {
    return combineLatest([this.enableTogglingOfCustomerAssortment$, this.defaultCustomerAssortmentToggleState$]).pipe(
      map(([enableTogglingOfCustomerAssortment, defaultCustomerAssortmentToggleState]) => {
        if (enableTogglingOfCustomerAssortment && !!myaParam) {
          return 'mya:' + (myaParam === '1');
        } else if (enableTogglingOfCustomerAssortment) {
          return 'mya:' + defaultCustomerAssortmentToggleState;
        }
        return 'default';
      })
    );
  }
}
