import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'py-dropdown-list',
  templateUrl: './dropdown-list.component.html',
  styleUrls: ['./dropdown-list.component.scss'],
})
export class DropdownListComponent {
  @Input() title: string;
  @Input() noItemsText: string;
  @Input() options: any[];
  @Input() loading = false;
  @Input() optionIcon: string;
  @Input() optionIconClass: string = '';
  @Input() activeItem: string;

  @Output() changeValue = new EventEmitter<any>();
  @Output() optionIconAction = new EventEmitter<any>();

  isOpen: boolean = false;

  constructor() {}

  isActive(value): boolean {
    return this.activeItem === value;
  }

  onChange(option) {
    if (this.activeItem === option.value) {
      this.activeItem = undefined;
      this.changeValue.emit(undefined);
    } else {
      this.activeItem = option.value;
      this.changeValue.emit(option);
    }
  }

  reset() {
    this.activeItem = undefined;
  }

  onOptionIconAction(val) {
    this.optionIconAction.emit(val);
  }
}
