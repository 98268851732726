import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { GenericLinkModule } from '../generic-link';
import { MediaIconComponent } from './media-icon.component';

@NgModule({
  imports: [CommonModule, NzIconModule, GenericLinkModule, NgbTooltipModule],
  declarations: [MediaIconComponent],
  exports: [MediaIconComponent],
})
export class MediaIconModule {}
