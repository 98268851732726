import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InterceptorUtil, OCC_USER_ID_ANONYMOUS, OccEndpointsService, USE_CLIENT_TOKEN } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContentTag } from '../../../../core/model';
import { Occ } from '../../../../core/occ';
import { ConverterService } from '../../../../core/util';
import { COLLECTION_VALUE_NORMALIZER } from './converters';
import CollectionValue = Occ.CollectionValue;

@Injectable({ providedIn: 'root' })
export class CmsContentTagConnector {
  constructor(
    private converterService: ConverterService,
    private httpClient: HttpClient,
    private occEndpointsService: OccEndpointsService
  ) {}

  /**
   * Get component UIDs tagged with masterTag and anyOf filterTags
   * @param userId the userId
   * @param masterTag the masterTag uid
   * @param componentType the componentType to use, for example 'PuffComponent'
   * @param sort a string descibing the sort properties and order. For example name-desc. Default empty
   * @param filterTags a list of content tag UIDs
   */
  findUIDsForMasterTagAndFilterTags(
    userId: string,
    masterTag: string,
    componentType: string,
    sort: string = '',
    filterTags?: string
  ): Observable<Array<string>> {
    let headers = new HttpHeaders();
    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }
    return this.httpClient
      .get<CollectionValue<string>>(
        this.occEndpointsService.buildUrl('contentTagsTaggedItems', {
          urlParams: {
            userId: userId,
            uid: masterTag,
          },
          queryParams: {
            type: componentType,
            sort,
            filterTags,
          },
        }),
        { headers }
      )
      .pipe(this.converterService.pipeable(COLLECTION_VALUE_NORMALIZER));
  }

  loadContentTags(userId: string, uids: string[]): Observable<ContentTag[]> {
    let headers = new HttpHeaders();
    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }
    return this.httpClient
      .get<Occ.ContentTagList>(
        this.occEndpointsService.buildUrl('contentTags', {
          urlParams: {
            userId: userId,
          },
          queryParams: {
            uids: uids.join(','),
          },
        }),
        { headers }
      )
      .pipe(map((c) => c.values));
  }
}
