import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import {
  Article,
  ArticlePickerAttribute,
  ArticlePickerAttributeOption,
  ArticlePickerEvent,
  ClassificationAttribute,
  Product,
} from '../../../model';
import { ArticleService } from '../../article';

@Injectable({
  providedIn: 'root',
})
export class ArticlePickerService {
  constructor(private articleService: ArticleService) {}

  getArticles(product: Product, areaOfUseCode?: string): Observable<Article[]> {
    const aouCode = areaOfUseCode === '*' ? undefined : areaOfUseCode;
    return this.articleService.getArticlesFully(product?.articles || []).pipe(
      map((articles) => (aouCode ? articles?.filter((article) => article.areasOfUse?.includes(aouCode)) || [] : articles)),
      filter((articles) => !!articles && articles?.length > 0),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  getFilteredArticles(articles: Article[], selectedAttributes?: ArticlePickerEvent[]): Article[] {
    if (selectedAttributes.length === 0) {
      return articles;
    }

    return articles.filter((article) => {
      let matches = 0;
      selectedAttributes.forEach((selectedAttribute) => {
        if ((article?.classificationAttributes[selectedAttribute.attributeCode]?.value || '-') === selectedAttribute.value) {
          matches++;
        }
      });

      return matches === selectedAttributes?.length;
    });
  }

  getAttributes(product: Product, areaOfUseCode?: string): Observable<ArticlePickerAttribute[]> {
    const aouCode = areaOfUseCode === '*' ? undefined : areaOfUseCode;
    return this.getArticles(product, aouCode).pipe(
      map((articles) => {
        const attributes = [
          ...(product.articlePickerAttributes || []).map((apa) => ({ ...apa, options: [] })),
        ] as ArticlePickerAttribute[];
        attributes.forEach((attribute) => {
          const options = this.getOptions(attribute.code, articles);
          attribute.options = options;
        });
        return attributes.filter(
          (attribute) => attribute.options.length > 1 || (attribute.options.length === 1 && attribute.options[0].value !== '-')
        );
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  getOptions(attributeCode: string, articles: Article[]): ArticlePickerAttributeOption[] {
    const attributeOptions: ArticlePickerAttributeOption[] = [];
    articles
      .map((a) => a.classificationAttributes[attributeCode])
      .forEach((optionValue: ClassificationAttribute) => {
        if (!attributeOptions.find((o) => o.value === (optionValue?.value || '-'))) {
          attributeOptions.push({
            value: optionValue?.value || '-',
            label: optionValue?.value || '-',
            rawValue: optionValue?.rawValue || '-',
          });
        }

        attributeOptions.sort((a, b) => {
          return attributeCode === 'ZZPSIZE'
            ? a.rawValue.localeCompare(b.rawValue, undefined, { numeric: false })
            : a.label.localeCompare(b.label, undefined, { numeric: true });
        });
      });

    return attributeOptions;
  }

  disableOptions(
    attributeOptions: ArticlePickerAttributeOption[],
    attributeCode: string,
    limitedSelectedAttributes: ArticlePickerEvent[],
    articles: Article[]
  ): ArticlePickerAttributeOption[] {
    const options = [...attributeOptions.map((ap) => ({ ...ap }))];

    if (limitedSelectedAttributes?.length > 0) {
      const filteredArticles = this.getFilteredArticles(articles, limitedSelectedAttributes);

      const filteredOptions = this.getOptions(attributeCode, filteredArticles);

      options.forEach((ao) => {
        if (!filteredOptions.find((fo) => fo.value === ao.value)) {
          ao.disabled = true;
        }
      });

      options.sort((a, b) => {
        if ((a.disabled ? 1 : -1) - (b.disabled ? 1 : -1)) {
          return (a.disabled ? 1 : -1) - (b.disabled ? 1 : -1);
        }
        return attributeCode === 'ZZPSIZE'
          ? a.rawValue.localeCompare(b.rawValue, undefined, { numeric: false })
          : a.label.localeCompare(b.label, undefined, { numeric: true });
      });
    }

    return options;
  }
}
