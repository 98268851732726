import { GlobalMessageActions as CxGlobalMessageActions } from '@spartacus/core';

export const { ADD_MESSAGE, REMOVE_MESSAGE, REMOVE_MESSAGES_BY_TYPE, AddMessage, RemoveMessage, RemoveMessagesByType } =
  CxGlobalMessageActions;

export enum GlobalMessageActionTypes {
  AddMessage = '[Global-message] Add a Message',
  RemoveMessage = '[Global-message] Remove a Message',
  RemoveMessagesByType = '[Global-message] Remove messages by type',
}

export type AddMessage = CxGlobalMessageActions.AddMessage;
export type RemoveMessage = CxGlobalMessageActions.RemoveMessage;
export type RemoveMessagesByType = CxGlobalMessageActions.RemoveMessagesByType;

export type GlobalMessageActions = AddMessage | RemoveMessage | RemoveMessagesByType;
