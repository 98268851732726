import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        invoiceHistory: {
          module: () => import('./container/invoice-history.module').then((m) => m.InvoiceHistoryModule),
          cmsComponents: ['CmsInvoiceHistoryComponent'],
        },
      },
    }),
  ],
})
export class InvoiceHistoryFeatureModule {}
