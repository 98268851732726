import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../core/i18n';
import { ReadMoreComponent } from './read-more.component';

@NgModule({
  imports: [CommonModule, I18nModule, NzIconModule],
  declarations: [ReadMoreComponent],
  exports: [ReadMoreComponent],
})
export class ReadMoreModule {}
