import { DIALOG_TYPE, LAUNCH_CALLER, LayoutConfig } from '@spartacus/storefront';
import { OrderEntryAccountingCodesModalComponent } from './order-entry-accounting-codes-modal.component';

export const OrderEntryAccountingCodesModalComponentModalLayoutConfig: LayoutConfig = {
  launch: {
    [LAUNCH_CALLER.ORDER_ENTRY_ACCOUNTING_CODES]: {
      inlineRoot: true,
      component: OrderEntryAccountingCodesModalComponent,
      dialogType: DIALOG_TYPE.DIALOG,
      dialogOptions: {
        onTop: true,
      },
    },
  },
};
