import { Action } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import {
  ActionTokenPayload,
  CartActionSourceData,
  CartModification,
  CartType,
  FailedAddToCartArticle,
  FullPalletUpgrade,
  HttpErrorModel,
  OrderEntry,
  Unit,
} from '../../../../../core/model';
import { CART } from '../cart-state';

export enum CartEntryActionTypes {
  AddCartEntry = '[Cart] Add cart entry',
  AddCartEntrySuccess = '[Cart] Add cart entry success',
  AddCartEntryFail = '[Cart] Add cart entry fail',

  AddA4SampleCartEntry = '[Cart] Add A4 sample cart entry',
  AddA4SampleCartEntrySuccess = '[Cart] Add A4 sample cart entry success',
  AddA4SampleCartEntryFail = '[Cart] Add A4 sample cart entry fail',

  QuickAddCartEntry = '[Cart] Quick-add cart entry',
  QuickAddCartEntrySuccess = '[Cart] Quick-add cart entry success',
  QuickAddCartEntryFail = '[Cart] Quick-add cart entry fail',

  AddCartEntries = '[Cart] Add cart entries',
  AddCartEntriesSuccess = '[Cart] Add cart entries success',
  AddCartEntriesFail = '[Cart] Add cart entries fail',

  RemoveCartEntry = '[Cart] Remove cart entry',
  RemoveCartEntrySuccess = '[Cart] Remove cart entry success',
  RemoveCartEntryFail = '[Cart] Remove cart entry fail',

  RemoveCartEntries = '[Cart] Remove cart entries',
  RemoveCartEntriesSuccess = '[Cart] Remove cart entries success',
  RemoveCartEntriesFail = '[Cart] Remove cart entries fail',

  RemoveAllCartEntries = '[Cart] Remove all cart entries',
  RemoveAllCartEntriesSuccess = '[Cart] Remove all cart entries success',
  RemoveAllCartEntriesFail = '[Cart] Remove all cart entries fail',

  UpdateCartEntry = '[Cart] Update cart entry',
  UpdateCartEntryNote = '[Cart] Update cart entry note',
  UpdateCartEntryNoteSuccess = '[Cart] Update cart entry note success',
  UpdateCartEntryNameMarking = '[Cart] Update cart entry name marking',
  UpdateCartEntryNameMarkingSuccess = '[Cart] Update cart entry name marking success',
  UpdateCartEntryStatisticsCode = '[Cart] Update cart entry statistics code',
  UpdateCartEntryStatisticsCodeSuccess = '[Cart] Update cart entry statistics code success',
  UpdateCartEntryAccountingCode = '[Cart] Update cart entry accounting code',
  UpdateCartEntryAccountingCodeSuccess = '[Cart] Update cart entry accounting code success',
  UpdateCartEntrySuccess = '[Cart] Update cart entry success',
  UpdateCartEntryFail = '[Cart] Update cart entry fail',

  ViewCartEntries = '[Cart] View cart entries',

  ReplaceCartEntry = '[Cart] Replace cart entry',
  ReplaceCartEntrySuccess = '[Cart] Replace cart entry success',
  ReplaceCartEntryFail = '[Cart] Replace cart entry fail',
}

interface AddCartEntryPayloadBase {
  cartType: CartType;
  cartId: string;
  userId: string;
  articleCode: string;
  quantity: number;
  unit: Unit;
  note?: string;
  orderCardCode?: string;
  customerMaterialNumber?: string;
}

interface AddCartEntryPayload extends AddCartEntryPayloadBase, ActionTokenPayload<boolean> {}

interface AddCartEntryFailPayload extends AddCartEntryPayloadBase {
  error?: HttpErrorModel;
}

interface AddCartEntrySuccessPayload extends AddCartEntryPayloadBase {
  entry: OrderEntry;
  quantityAdded: number;
  statusCode: string;
  statusMessage: string;
  fullPalletUpgrade?: FullPalletUpgrade;
}

interface AddA4SampleCartEntryPayloadBase {
  cartType: CartType;
  cartId: string;
  userId: string;
  productCode: string;
  grammage: number;
  color: string;
  quantity: number;
}

interface AddA4SampleCartEntryPayload extends AddA4SampleCartEntryPayloadBase, ActionTokenPayload<boolean> {}

interface AddA4SampleCartEntryFailPayload extends AddA4SampleCartEntryPayloadBase {
  error?: HttpErrorModel;
}

interface AddA4SampleCartEntrySuccessPayload extends AddA4SampleCartEntryPayloadBase {
  entry: OrderEntry;
  quantityAdded: number;
  statusCode: string;
  statusMessage: string;
}

interface AddCartEntriesPayloadBase {
  cartType: CartType;
  cartId: string;
  userId: string;
  entries: OrderEntry[];
  sourceData?: CartActionSourceData;
}

interface AddCartEntriesPayload extends AddCartEntriesPayloadBase, ActionTokenPayload<boolean> {}

interface AddCartEntriesFailPayload extends AddCartEntriesPayloadBase {
  error?: HttpErrorModel;
}

interface AddCartEntriesSuccessPayload extends AddCartEntriesPayloadBase {
  message?: string;
  success?: boolean;
  itemsAdded?: number;
  failedArticlesData?: FailedAddToCartArticle[];
}

interface RemoveCartEntryPayloadBase {
  cartType: CartType;
  cartId: string;
  userId: string;
  entry: OrderEntry;
}

interface RemoveCartEntryPayload extends RemoveCartEntryPayloadBase, ActionTokenPayload<boolean> {}

interface RemoveCartEntryFailPayload extends RemoveCartEntryPayloadBase {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RemoveCartEntrySuccessPayload extends RemoveCartEntryPayloadBase {
  cartModification: CartModification;
}

interface RemoveCartEntriesPayloadBase {
  cartType: CartType;
  cartId: string;
  userId: string;
  entries: OrderEntry[];
}

interface RemoveCartEntriesPayload extends RemoveCartEntriesPayloadBase, ActionTokenPayload<boolean> {}

interface RemoveCartEntriesFailPayload extends RemoveCartEntriesPayloadBase {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RemoveCartEntriesSuccessPayload extends RemoveCartEntriesPayloadBase {
  cartModifications: CartModification[];
}

interface RemoveAllCartEntriesPayloadBase {
  cartType: CartType;
  cartId: string;
  userId: string;
  entries?: OrderEntry[];
}

interface RemoveAllCartEntriesPayload extends RemoveAllCartEntriesPayloadBase, ActionTokenPayload<boolean> {}

interface RemoveAllCartEntriesFailPayload extends RemoveAllCartEntriesPayloadBase {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RemoveAllCartEntriesSuccessPayload extends RemoveAllCartEntriesPayloadBase {}

interface UpdateCartEntryPayloadBase {
  cartType: CartType;
  cartId: string;
  userId: string;
  entry: OrderEntry;
  quantity?: number;
  unit?: Unit;
}

interface UpdateCartEntryNotePayload extends ActionTokenPayload<boolean> {
  cartType: CartType;
  cartId: string;
  userId: string;
  note?: string;
  entry: OrderEntry;
}

interface UpdateCartEntryNameMarkingPayload extends ActionTokenPayload<boolean> {
  cartType: CartType;
  cartId: string;
  userId: string;
  qualifier: string;
  ruleId: string;
  extendedIdentifier: string;
  values: string[];
  entry: OrderEntry;
}

interface UpdateCartEntryStatisticsCodePayload extends ActionTokenPayload<boolean> {
  cartType: CartType;
  cartId: string;
  userId: string;
  statisticsCode?: string;
  entry: OrderEntry;
}

interface UpdateCartEntryAccountingCodePayload extends ActionTokenPayload<boolean> {
  cartType: CartType;
  cartId: string;
  userId: string;
  accountingCode?: string;
  entry: OrderEntry;
}

interface UpdateCartEntryPayload extends UpdateCartEntryPayloadBase, ActionTokenPayload<boolean> {}

interface UpdateCartEntryFailPayload extends UpdateCartEntryPayloadBase {
  error?: HttpErrorModel;
}

interface UpdateCartEntrySuccessPayload extends UpdateCartEntryPayloadBase {
  cartModification?: CartModification;
}

interface ViewCartEntriesPayload {
  cartType: CartType;
  cartId: string;
  userId: string;
  entries: OrderEntry[];
}

interface ReplaceCartEntryPayloadBase {
  cartType: CartType;
  cartId: string;
  userId: string;
  entry: OrderEntry;
  articleNumber: string;
  quantity: number;
  unit: Unit;
}

interface ReplaceCartEntryPayload extends ReplaceCartEntryPayloadBase, ActionTokenPayload<boolean> {}

interface ReplaceCartEntryFailPayload extends ReplaceCartEntryPayloadBase {
  error?: HttpErrorModel;
}

interface ReplaceCartEntrySuccessPayload extends ReplaceCartEntryPayloadBase {
  cartModification?: CartModification;
}

export class AddCartEntry extends StateUtils.EntityProcessesIncrementAction {
  readonly type = CartEntryActionTypes.AddCartEntry;

  constructor(public payload: AddCartEntryPayload) {
    super(CART, payload.cartId);
  }
}

export class AddCartEntryFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryActionTypes.AddCartEntryFail;

  constructor(public payload: AddCartEntryFailPayload) {
    super(CART, payload.cartId);
  }
}

export class AddCartEntrySuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryActionTypes.AddCartEntrySuccess;

  constructor(public payload: AddCartEntrySuccessPayload) {
    super(CART, payload.cartId);
  }
}

export class AddA4SampleCartEntry extends StateUtils.EntityProcessesIncrementAction {
  readonly type = CartEntryActionTypes.AddA4SampleCartEntry;

  constructor(public payload: AddA4SampleCartEntryPayload) {
    super(CART, payload.cartId);
  }
}

export class AddA4SampleCartEntryFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryActionTypes.AddA4SampleCartEntryFail;

  constructor(public payload: AddA4SampleCartEntryFailPayload) {
    super(CART, payload.cartId);
  }
}

export class AddA4SampleCartEntrySuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryActionTypes.AddA4SampleCartEntrySuccess;

  constructor(public payload: AddA4SampleCartEntrySuccessPayload) {
    super(CART, payload.cartId);
  }
}

export class QuickAddCartEntry extends StateUtils.EntityProcessesIncrementAction {
  readonly type = CartEntryActionTypes.QuickAddCartEntry;

  constructor(public payload: AddCartEntryPayload) {
    super(CART, payload.cartId);
  }
}

export class QuickAddCartEntryFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryActionTypes.QuickAddCartEntryFail;

  constructor(public payload: AddCartEntryFailPayload) {
    super(CART, payload.cartId);
  }
}

export class QuickAddCartEntrySuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryActionTypes.QuickAddCartEntrySuccess;

  constructor(public payload: AddCartEntrySuccessPayload) {
    super(CART, payload.cartId);
  }
}

export class AddCartEntries extends StateUtils.EntityProcessesIncrementAction {
  readonly type = CartEntryActionTypes.AddCartEntries;

  constructor(public payload: AddCartEntriesPayload) {
    super(CART, payload.cartId);
  }
}

export class AddCartEntriesFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryActionTypes.AddCartEntriesFail;

  constructor(public payload: AddCartEntriesFailPayload) {
    super(CART, payload.cartId);
  }
}

export class AddCartEntriesSuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryActionTypes.AddCartEntriesSuccess;

  constructor(public payload: AddCartEntriesSuccessPayload) {
    super(CART, payload.cartId);
  }
}

export class RemoveCartEntry extends StateUtils.EntityProcessesIncrementAction {
  readonly type = CartEntryActionTypes.RemoveCartEntry;

  constructor(public payload: RemoveCartEntryPayload) {
    super(CART, payload.cartId);
  }
}

export class RemoveCartEntryFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryActionTypes.RemoveCartEntryFail;

  constructor(public payload: RemoveCartEntryFailPayload) {
    super(CART, payload.cartId);
  }
}

export class RemoveCartEntrySuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryActionTypes.RemoveCartEntrySuccess;

  constructor(public payload: RemoveCartEntrySuccessPayload) {
    super(CART, payload.cartId);
  }
}

export class RemoveCartEntries extends StateUtils.EntityProcessesIncrementAction {
  readonly type = CartEntryActionTypes.RemoveCartEntries;

  constructor(public payload: RemoveCartEntriesPayload) {
    super(CART, payload.cartId);
  }
}

export class RemoveCartEntriesFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryActionTypes.RemoveCartEntriesFail;

  constructor(public payload: RemoveCartEntriesFailPayload) {
    super(CART, payload.cartId);
  }
}

export class RemoveCartEntriesSuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryActionTypes.RemoveCartEntriesSuccess;

  constructor(public payload: RemoveCartEntriesSuccessPayload) {
    super(CART, payload.cartId);
  }
}

export class RemoveAllCartEntries extends StateUtils.EntityProcessesIncrementAction {
  readonly type = CartEntryActionTypes.RemoveAllCartEntries;

  constructor(public payload: RemoveAllCartEntriesPayload) {
    super(CART, payload.cartId);
  }
}

export class RemoveAllCartEntriesFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryActionTypes.RemoveAllCartEntriesFail;

  constructor(public payload: RemoveAllCartEntriesFailPayload) {
    super(CART, payload.cartId);
  }
}

export class RemoveAllCartEntriesSuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryActionTypes.RemoveAllCartEntriesSuccess;

  constructor(public payload: RemoveAllCartEntriesSuccessPayload) {
    super(CART, payload.cartId);
  }
}

export class UpdateCartEntry extends StateUtils.EntityProcessesIncrementAction {
  readonly type = CartEntryActionTypes.UpdateCartEntry;

  constructor(public payload: UpdateCartEntryPayload) {
    super(CART, payload.cartId);
  }
}

export class UpdateCartEntryNote extends StateUtils.EntityProcessesIncrementAction {
  readonly type = CartEntryActionTypes.UpdateCartEntryNote;

  constructor(public payload: UpdateCartEntryNotePayload) {
    super(CART, payload.cartId);
  }
}

export class UpdateCartEntryNoteSuccess implements Action {
  readonly type = CartEntryActionTypes.UpdateCartEntryNoteSuccess;

  constructor(public payload: string) {}
}

export class UpdateCartEntryNameMarking extends StateUtils.EntityProcessesIncrementAction {
  readonly type = CartEntryActionTypes.UpdateCartEntryNameMarking;

  constructor(public payload: UpdateCartEntryNameMarkingPayload) {
    super(CART, payload.cartId);
  }
}
export class UpdateCartEntryNameMarkingSuccess implements Action {
  readonly type = CartEntryActionTypes.UpdateCartEntryNameMarkingSuccess;

  constructor(public payload: string) {}
}

export class UpdateCartEntryStatisticsCode extends StateUtils.EntityProcessesIncrementAction {
  readonly type = CartEntryActionTypes.UpdateCartEntryStatisticsCode;

  constructor(public payload: UpdateCartEntryStatisticsCodePayload) {
    super(CART, payload.cartId);
  }
}

export class UpdateCartEntryStatisticsCodeSuccess implements Action {
  readonly type = CartEntryActionTypes.UpdateCartEntryStatisticsCodeSuccess;

  constructor(public payload: string) {}
}

export class UpdateCartEntryAccountingCode extends StateUtils.EntityProcessesIncrementAction {
  readonly type = CartEntryActionTypes.UpdateCartEntryAccountingCode;

  constructor(public payload: UpdateCartEntryAccountingCodePayload) {
    super(CART, payload.cartId);
  }
}

export class UpdateCartEntryAccountingCodeSuccess implements Action {
  readonly type = CartEntryActionTypes.UpdateCartEntryAccountingCodeSuccess;

  constructor(public payload: string) {}
}

export class UpdateCartEntryFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryActionTypes.UpdateCartEntryFail;

  constructor(public payload: UpdateCartEntryFailPayload) {
    super(CART, payload.cartId);
  }
}

export class UpdateCartEntrySuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryActionTypes.UpdateCartEntrySuccess;

  constructor(public payload: UpdateCartEntrySuccessPayload) {
    super(CART, payload.cartId);
  }
}

export class ViewCartEntries implements Action {
  readonly type = CartEntryActionTypes.ViewCartEntries;

  constructor(public payload: ViewCartEntriesPayload) {}
}

export class ReplaceCartEntry extends StateUtils.EntityProcessesIncrementAction {
  readonly type = CartEntryActionTypes.ReplaceCartEntry;

  constructor(public payload: ReplaceCartEntryPayload) {
    super(CART, payload.cartId);
  }
}

export class ReplaceCartEntryFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryActionTypes.ReplaceCartEntryFail;

  constructor(public payload: ReplaceCartEntryFailPayload) {
    super(CART, payload.cartId);
  }
}

export class ReplaceCartEntrySuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryActionTypes.ReplaceCartEntrySuccess;

  constructor(public payload: ReplaceCartEntrySuccessPayload) {
    super(CART, payload.cartId);
  }
}

export type CartEntryActions =
  | AddCartEntry
  | AddCartEntryFail
  | AddCartEntrySuccess
  | AddA4SampleCartEntry
  | AddA4SampleCartEntryFail
  | AddA4SampleCartEntrySuccess
  | QuickAddCartEntry
  | QuickAddCartEntryFail
  | QuickAddCartEntrySuccess
  | AddCartEntries
  | AddCartEntriesFail
  | AddCartEntriesSuccess
  | RemoveCartEntry
  | RemoveCartEntryFail
  | RemoveCartEntrySuccess
  | RemoveCartEntries
  | RemoveCartEntriesFail
  | RemoveCartEntriesSuccess
  | RemoveAllCartEntries
  | RemoveAllCartEntriesFail
  | RemoveAllCartEntriesSuccess
  | UpdateCartEntry
  | UpdateCartEntryNote
  | UpdateCartEntryNoteSuccess
  | UpdateCartEntryNameMarking
  | UpdateCartEntryNameMarkingSuccess
  | UpdateCartEntryStatisticsCode
  | UpdateCartEntryStatisticsCodeSuccess
  | UpdateCartEntryAccountingCode
  | UpdateCartEntryAccountingCodeSuccess
  | UpdateCartEntryFail
  | UpdateCartEntrySuccess
  | ReplaceCartEntry
  | ReplaceCartEntryFail
  | ReplaceCartEntrySuccess;
