import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { ArticlePrice, ArticleRef, Unit } from '../../model';
import { USER_BASE_FEATURE } from '../store/user-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: PriceFacade,
      feature: USER_BASE_FEATURE,
      async: true,
      methods: ['getArticlePrice', 'loadingPrice', 'loadedPrice', 'priceLoadingAttempts', 'loadPrice'],
    }),
})
export abstract class PriceFacade {
  abstract getArticlePrice(
    articleRef: ArticleRef,
    quantity: number,
    customerSelectedUnit?: Unit,
    async?: boolean,
    forceLoadPrice?: boolean
  ): Observable<ArticlePrice | undefined>;
  abstract loadingPrice(articleRef: ArticleRef, quantity: number, customerSelectedUnit?: Unit): Observable<boolean>;
  abstract loadedPrice(articleRef: ArticleRef, quantity: number, customerSelectedUnit?: Unit): Observable<boolean>;
  abstract priceLoadingAttempts(articleRef: ArticleRef, quantity: number, customerSelectedUnit?: Unit): Observable<number>;
  abstract loadPrice(articleRef: ArticleRef, quantity: number, customerSelectedUnit?: Unit, async?: boolean): void;
}
