import { DIALOG_TYPE, LAUNCH_CALLER, LayoutConfig } from '@spartacus/storefront';
import { ImageModalComponent } from './image-modal.component';

export const imageModalLayoutConfig: LayoutConfig = {
  launch: {
    [LAUNCH_CALLER.IMAGE]: {
      inlineRoot: true,
      component: ImageModalComponent,
      dialogType: DIALOG_TYPE.DIALOG,
      dialogOptions: {
        onTop: true,
      },
    },
  },
};
