import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule } from '../../../core/i18n/i18n.module';
import { ActionButtonModule } from '../action-button';

@Component({
  selector: 'py-load-more',
  templateUrl: './load-more.component.html',
  styleUrls: ['./load-more.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, I18nModule, NgbProgressbarModule, ActionButtonModule],
})
export class LoadMoreComponent implements OnInit {
  @Input() itemCount = 0;
  @Input() totalItemCount: number;
  @Input() totalPageCount: number;
  @Input() currentPageNumber: number;
  @Input() totalItemCountName?: string;
  @Input() showLoadMoreButton?: boolean = true;
  @Input() disabled?: boolean = false;
  @Input() loading?: boolean = false;
  @Input() buttonId?: string;
  @Input() small: boolean = false;
  @Output() dispatch = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}

  handleClick() {
    this.dispatch.emit(this.currentPageNumber + 1);
  }

  get percentage(): number {
    return (this.itemCount / this.totalItemCount || 0) * 100;
  }
}
