import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../core/i18n';
import { CreateShoppingListModalModule } from '../create-shopping-list-modal/create-shopping-list-modal.module';
import { ListInsertModule } from '../list-insert';
import { SpinnerModule } from '../spinner';
import { AddShoppingListComponent } from './add-shopping-list.component';

@NgModule({
  imports: [CommonModule, I18nModule, NzIconModule, ListInsertModule, SpinnerModule, CreateShoppingListModalModule],
  declarations: [AddShoppingListComponent],
  exports: [AddShoppingListComponent],
})
export class AddShoppingListModule {}
