import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { PageComponentModule } from '../../../../cms-structure';
import { I18nModule } from '../../../../core/i18n';
import { UrlModule } from '../../../../core/routing';
import { GenericLinkModule, MegamenuListItemsModule, MegamenuModule } from '../../../../shared';
import { HeaderNavigationItemModule } from '../header-navigation-item';
import { MenuServicesItemsModule } from './menu-services-item/menu-services-item.module';
import { MenuServicesComponent } from './menu-services.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    PageComponentModule.forRoot(),
    HeaderNavigationItemModule,
    NgbDropdownModule,
    GenericLinkModule,
    NzIconModule,
    RouterModule,
    UrlModule,
    MegamenuModule,
    MegamenuListItemsModule,
    MenuServicesItemsModule,
  ],
  declarations: [MenuServicesComponent],
  exports: [MenuServicesComponent],
})
export class MenuServicesModule {}
