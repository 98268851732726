export { Principal, User } from '@spartacus/core';
import { Occ } from '../occ';
import { Customer } from '../occ/occ-models/occ-generated.models';
import { Pagination, SearchParams, Sort } from './search.model';

export enum DebugKey {
  SEARCH_SCORE = 'SEARCH_SCORE',
  SEARCH_SCORE_EXPLANATION = 'SEARCH_SCORE_EXPLANATION',
  SEARCH_SUGGESTIONS = 'SEARCH_SUGGESTIONS',
  TRANSLATION_KEYS = 'TRANSLATION_KEYS',
}

export enum UserSelectContentTypes {
  BANNER = 'BANNER',
  PUFF = 'PUFF',
}

export import CustomerType = Occ.CustomerType;

export const PRICE_LOADING_MAX_ATTEMPTS = 5;

export enum UsersImportMode {
  INSERT = 'INSERT',
  UPDATE = 'UPDATE',
}

export interface Credentials {
  userName?: string;
  email?: string;
  password: string;
  oldPassword?: string;
  newPassword?: string;
}

export interface CredentialsValidation {
  uid?: string;
  password?: string;
}

export interface SoldTo extends Occ.SoldTo {
  salesOrg?: SalesOrg;
  defaultShipTo?: Occ.ShipTo;
}

export interface SoldToSearchResult {
  results?: SoldTo[];
  pagination?: Pagination;
  sorts?: Sort[];
}

export interface SalesOrg extends Occ.SalesOrg {
  uid?: string;
}

export interface AvailableSoldTosSearchParams extends SearchParams {
  text?: string;
  userUid?: string;
  connectedToPermissionCode?: boolean;
  permissionCode?: string;
  shoppingListCode?: string;
}

export interface UserList {
  users?: Customer[];
}
