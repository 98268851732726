import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { AccountingCode, AccountingCodeUserInfo, Pagination, SearchParams } from '../../model';
import { USER_BASE_FEATURE } from '../store/user-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: AccountingCodesAdminFacade,
      feature: USER_BASE_FEATURE,
      async: true,
      methods: [
        'search',
        'getSearchResult',
        'getSearchResultPagination',
        'getSearchPageResultsLoading',
        'create',
        'update',
        'delete',
        'exportAsExcel',
        'importViaExcel',
        'loadAccountingCodeUserInfo',
        'getAccountingCodeUserInfo',
      ],
    }),
})
export abstract class AccountingCodesAdminFacade {
  abstract search(searchParams: SearchParams): void;
  abstract getSearchResult(searchParams: SearchParams): Observable<AccountingCode[] | undefined>;
  abstract getSearchResultPagination(searchParams: SearchParams): Observable<Pagination | undefined>;
  abstract getSearchPageResultsLoading(searchParams: SearchParams): Observable<boolean>;
  abstract create(accountingCode: AccountingCode, searchParams: SearchParams): void;
  abstract update(accountingCode: AccountingCode): void;
  abstract delete(code: string, searchParams: SearchParams): void;
  abstract exportAsExcel(): Observable<Blob>;
  abstract importViaExcel(file: File): Observable<number>;
  abstract loadAccountingCodeUserInfo(): void;
  abstract getAccountingCodeUserInfo(): Observable<AccountingCodeUserInfo>;
}
