import { Injectable } from '@angular/core';
import { CmsStructureModel, OccCmsPageNormalizer as CxOccCmsPageNormalizer } from '@spartacus/core';
import { CMS_FLEX_NAVIGATION_COMPONENT_TYPE } from '../../../../cms/config/cms-config';
import { ContentSlotComponentData } from '../../../../cms/model/content-slot-component-data.model';
import { Page } from '../../../../cms/model/page.model';
import { Converter } from '../../../../util/converter.service';
import { Occ } from '../../../occ-models';
import { transformLegacyCmsStyleClasses } from './transform-legacy-cms-style-classes';

@Injectable({ providedIn: 'root' })
export class OccCmsPageNormalizer extends CxOccCmsPageNormalizer implements Converter<Occ.CMSPage, CmsStructureModel> {
  /**
   * Converts the OCC cms page model to the `Page` in the `CmsStructureModel`.
   */
  protected normalizePageData(source: Occ.CMSPage, target: CmsStructureModel): void {
    super.normalizePageData(source, target);
    if (!source) {
      return;
    }
    const page: Page = { ...(target.page ?? {}) };

    if (source.publishedDate) {
      page.publishedDate = source.publishedDate;
    }
    if (source.featuredImage) {
      page.featuredImage = source.featuredImage;
    }

    target.page = page;
  }

  /**
   * Registers the `ContentSlotComponentData` for each component.
   */
  protected normalizePageComponentData(source: Occ.CMSPage, target: CmsStructureModel): void {
    if (!source?.contentSlots?.contentSlot) {
      return;
    }
    for (const slot of source.contentSlots.contentSlot) {
      if (Array.isArray(slot.components?.component)) {
        for (const component of slot.components.component) {
          const comp: ContentSlotComponentData = {
            uid: component.uid,
            typeCode: component.typeCode,
          };
          if (component.properties) {
            comp.properties = component.properties;
          }
          if (component.styleClasses?.split) {
            comp.styleClasses = transformLegacyCmsStyleClasses(component.styleClasses);
          }

          comp.flexType = this.getFlexTypeFromComponent(component);

          if (!target.page.slots[slot.position].components) {
            target.page.slots[slot.position].components = [];
          }
          target.page.slots[slot.position].components.push(comp);
        }
      }
    }
  }

  protected getFlexTypeFromComponent(component: any): string {
    if (component.typeCode === CMS_FLEX_NAVIGATION_COMPONENT_TYPE) {
      return component.flexType;
    }
    return super.getFlexTypeFromComponent(component);
  }

  /**
   * Adds the actual component data whenever available in the CMS page data.
   *
   * If the data is not populated in this payload, it is loaded separately
   * (`OccCmsComponentAdapter`).
   */
  protected normalizeComponentData(source: Occ.CMSPage, target: CmsStructureModel): void {
    if (!source?.contentSlots?.contentSlot) {
      return;
    }

    for (const slot of source.contentSlots.contentSlot) {
      if (Array.isArray(slot.components?.component)) {
        for (const component of slot.components.component as any) {
          // while we're hoping to get this right from the backend api,
          // the OCC api stills seems out of sync with the right model.
          if (component.modifiedtime) {
            component.modifiedTime = component.modifiedtime;
            delete component.modifiedtime;
          }

          // we don't put properties into component state
          if (component.properties) {
            component.properties = undefined;
          }
          if (!target.components) {
            target.components = [];
          }

          if (component?.styleClasses?.split) {
            component.styleClasses = transformLegacyCmsStyleClasses(component.styleClasses);
          }
          target.components.push(component);
        }
      }
    }
  }
}
