import { CURRENCY_CONTEXT_ID, LANGUAGE_CONTEXT_ID, SiteContextConfig } from '@spartacus/core';

export function defaultSiteContextConfigFactory(): SiteContextConfig {
  return {
    context: {
      [LANGUAGE_CONTEXT_ID]: [
        'en',
        'cs',
        'da',
        'de_CH',
        'et',
        'fi',
        'fr',
        'fr_BE',
        'hu',
        'lt',
        'lv',
        'nl',
        'nl_BE',
        'no',
        'pl',
        'ro',
        'sv',
      ],
      [CURRENCY_CONTEXT_ID]: ['EUR', 'CHF', 'CZK', 'DKK', 'HUF', 'NOK', 'PLN', 'RON', 'SEK', 'SKK'],
    },
  };
}
