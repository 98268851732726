import { Directive, Inject, InjectionToken, Input, OnInit, Optional, TemplateRef, ViewContainerRef } from '@angular/core';

/**
 * Inject MOCK_CONTAINER_SIZE_STATE to toggle whether to render main template or not.
 */
export const MOCK_CONTAINER_SIZE_STATE = new InjectionToken<boolean>('MockContainerSizeState');

/**
 * Inject MOCK_CONTAINER_SIZE_STATE to toggle whether to render main template or not.
 */
@Directive({ selector: '[pyContainerSize]' })
export class MockContainerSizeDirective implements OnInit {
  @Input()
  public pyContainerSize;

  @Input()
  public pyContainerSizeTarget;

  @Input()
  public set pyContainerSizeElse(ref: TemplateRef<any> | undefined) {
    this.elseTemplateRef = ref;
  }

  private elseTemplateRef?: TemplateRef<any>;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    @Inject(MOCK_CONTAINER_SIZE_STATE) @Optional() private shouldRenderMainTemplate = true
  ) {}

  ngOnInit(): void {
    this.setContainerView(this.shouldRenderMainTemplate ? this.templateRef : this.elseTemplateRef);
  }

  private setContainerView(ref?: TemplateRef<any>): void {
    this.viewContainer.clear();
    if (ref !== undefined) {
      this.viewContainer.createEmbeddedView(ref);
    }
  }
}
