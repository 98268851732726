import { Observable } from 'rxjs';
import { filter, map, scan, withLatestFrom } from 'rxjs/operators';

/**
 * Concats unique new data to resulting observable and resets when current page = 0
 */
export function infinitelyScrolling<T>(data$: Observable<T[]>, currentPage$: Observable<number>, key: keyof T): Observable<T[]> {
  return data$.pipe(
    filter((value) => !!value),
    withLatestFrom(currentPage$),
    scan<[T[], number | undefined], any>(
      ([prevData], [currData, currPage]) => {
        if (!currPage) {
          return [currData, currPage];
        }
        const uniqueNewData = currData.filter((items) => !prevData.some((prevItem) => prevItem[key] === items[key]));

        if (!uniqueNewData.length) {
          return [prevData, currPage];
        }

        return [[...prevData, ...uniqueNewData], currPage];
      },
      [[], undefined]
    ),
    map(([items]) => items)
  );
}
