import { DOCUMENT } from '@angular/common';
import { ComponentFactoryResolver, ComponentRef, Inject, Injectable, Injector, RendererFactory2 } from '@angular/core';
import {
  InlineRootRenderStrategy as CxInlineRootRenderStrategy,
  LAUNCH_CALLER,
  LaunchInlineRootDialog,
} from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class InlineRootRenderStrategy extends CxInlineRootRenderStrategy {
  constructor(
    @Inject(DOCUMENT) protected document: any,
    protected rendererFactory: RendererFactory2,
    protected injector: Injector,
    protected componentFactoryResolver: ComponentFactoryResolver
  ) {
    super(document, rendererFactory, componentFactoryResolver, injector);
  }

  protected dialogClasses = ['d-block', 'fade', 'modal'];

  render(config: LaunchInlineRootDialog, caller: LAUNCH_CALLER | string): Observable<ComponentRef<any>> {
    // setting default autoDismiss... options in the dialogOptions if not provided explicitly in the launch config
    const { autoDismissOnRouteChange = true, autoDismissOnLogout = false } = config.dialogOptions ? config.dialogOptions : {};
    config.dialogOptions = { autoDismissOnRouteChange, autoDismissOnLogout, ...config.dialogOptions };

    return (super.render(config, caller) as Observable<ComponentRef<any>>).pipe(
      tap((component) => {
        // storing dialogOptions in the component instance, so it's possible to check these values for opened modal when necessary
        component.instance.dialogOptions = config.dialogOptions;

        if (config?.dialogOptions?.onTop) {
          // adds CSS class `on-top` to display the modal on top of other top items, e.g. cart drawer
          this.renderer.addClass(component.location.nativeElement, 'on-top');
        }

        if (config?.dialogOptions?.fixedHeight) {
          this.renderer.addClass(component.location.nativeElement, 'fixed-height');
        }
      }),
      delay(150),
      tap((component) => {
        // adds CSS class `show` after a delay to trigger the modal opening transition - see https://github.com/SAP/spartacus/issues/18489
        this.renderer.addClass(component.location.nativeElement, 'show');
      })
    );
  }
}
