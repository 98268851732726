import { MemoizedSelector, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { EntriesState, PaginationState, SearchPageResultState, SearchState } from '../../../features/store/base-feature-state';
import {
  MiniShoppingList,
  Pagination,
  PrincipalSharedShoppingListFlags,
  PrincipalSharedShoppingListSearchParams,
  ShoppingList,
} from '../../../model';
import { PyStateUtils } from '../../../state/utils';
import {
  MiniShoppingListSearchParamsState,
  MiniShoppingListState,
  ShoppingListState,
  ShoppingListsPrincipalsState,
  StateWithUser,
  UserState,
  WritableShoppingListSearchParamsState,
  WritableShoppingListState,
} from '../user-state';
import { getUserState } from './feature.selector';

export const getShoppingListState: MemoizedSelector<StateWithUser, ShoppingListState | undefined> = createSelector(
  getUserState,
  (state: UserState) => (state && state.shoppingList) || undefined
);

export const getMiniShoppingListsState = (): MemoizedSelector<StateWithUser, MiniShoppingListState | undefined> =>
  createSelector(getShoppingListState, (state: ShoppingListState) => {
    return (state && state.miniShoppingLists) || undefined;
  });

export const getSearchResultEntities = (): MemoizedSelector<StateWithUser, EntriesState<MiniShoppingList> | undefined> =>
  createSelector(getMiniShoppingListsState(), (state: MiniShoppingListState) => (state && state.entities) || undefined);

export const getSearchParamsSelector = (
  key: string = 'latest'
): MemoizedSelector<StateWithUser, MiniShoppingListSearchParamsState | undefined> =>
  createSelector(
    getMiniShoppingListsState(),
    (state: MiniShoppingListState) => (state && state.search[key]?.searchParams) || undefined
  );

export const getSearchSelector = (): MemoizedSelector<StateWithUser, SearchState<SearchPageResultState> | undefined> =>
  createSelector(getMiniShoppingListsState(), (state: MiniShoppingListState) => (state && state.search) || undefined);

export const getSearchPaginationStateSelector = (
  key: string
): MemoizedSelector<StateWithUser, PaginationState<SearchPageResultState> | undefined> =>
  createSelector(
    getSearchSelector(),

    (state: SearchState<SearchPageResultState>) => {
      return (state && state[key]) || undefined;
    }
  );

export const getSearchPaginationSelector = (key: string): MemoizedSelector<StateWithUser, Pagination | undefined> =>
  createSelector(getSearchPaginationStateSelector(key), (state) => (state && state.pagination) || undefined);

export const getSearchPageResultsSelectorFactory = (
  key: string
): MemoizedSelector<StateWithUser, StateUtils.LoaderState<SearchPageResultState> | undefined> =>
  createSelector(
    getSearchParamsSelector(key),
    getSearchPaginationStateSelector(key),
    (queryParams: MiniShoppingListSearchParamsState, paginationState: PaginationState<SearchPageResultState>) => {
      return (queryParams && paginationState && paginationState.pages && paginationState.pages[queryParams.page]) || undefined;
    }
  );

export const getSearchPageResultsSelector = (
  key: string
): MemoizedSelector<StateWithUser, StateUtils.LoaderState<SearchPageResultState> | undefined> =>
  createSelector(
    getSearchParamsSelector(key),
    getSearchPaginationStateSelector(key),
    (queryParams: MiniShoppingListSearchParamsState, paginationState: PaginationState<SearchPageResultState>) => {
      return (queryParams && paginationState && paginationState.pages && paginationState.pages[queryParams.page]) || undefined;
    }
  );

export const getSearchResultFactory = (key: string): MemoizedSelector<StateWithUser, SearchPageResultState | undefined> =>
  createSelector(getSearchPageResultsSelectorFactory(key), (loaderState: StateUtils.LoaderState<SearchPageResultState>) => {
    return (loaderState && StateUtils.loaderValueSelector(loaderState)) || undefined;
  });

export const getSearchResult = (key: string): MemoizedSelector<StateWithUser, SearchPageResultState | undefined> =>
  createSelector(getSearchPageResultsSelector(key), (loaderState: StateUtils.LoaderState<SearchPageResultState>) => {
    return (loaderState && StateUtils.loaderValueSelector(loaderState)) || undefined;
  });

export const getSearchPageResultsLoading = (key: string): MemoizedSelector<StateWithUser, boolean> =>
  createSelector(
    getSearchPageResultsSelector(key),
    (loaderState) => (loaderState && StateUtils.loaderLoadingSelector(loaderState)) || false
  );

export const getSearchPageResultsLoaded = (key: string): MemoizedSelector<StateWithUser, boolean> =>
  createSelector(
    getSearchPageResultsSelector(key),
    (loaderState) => (loaderState && StateUtils.loaderSuccessSelector(loaderState)) || false
  );

export const getSearchResultPage = (key: string): MemoizedSelector<StateWithUser, Array<string> | undefined> =>
  createSelector(getSearchResultFactory(key), (state: SearchPageResultState) => {
    return (state && state.results) || undefined;
  });

export const getSearchResultEntries = (key: string): MemoizedSelector<StateWithUser, MiniShoppingList[] | undefined> =>
  createSelector(
    getSearchResultPage(key),
    getSearchResultEntities(),
    (codes: Array<string>, entries: EntriesState<MiniShoppingList>) => {
      return (codes && entries && codes.map((i) => entries[i])) || undefined;
    }
  );

export const getShoppingListsState: MemoizedSelector<
  StateWithUser,
  StateUtils.EntityProcessesLoaderState<ShoppingList>
> = createSelector(getShoppingListState, (state: ShoppingListState) => state.shoppingLists);

// Writable shopping lists

export const getWritableShoppingListsState = (): MemoizedSelector<StateWithUser, WritableShoppingListState | undefined> =>
  createSelector(getShoppingListState, (state: ShoppingListState) => {
    return (state && state.writableShoppingLists) || undefined;
  });

export const getWritableSearchParamsSelector = (
  key: string = 'latest'
): MemoizedSelector<StateWithUser, WritableShoppingListSearchParamsState | undefined> =>
  createSelector(
    getWritableShoppingListsState(),
    (state: WritableShoppingListState) => (state && state.search[key]?.searchParams) || undefined
  );

export const getWritableSearchSelector = (): MemoizedSelector<StateWithUser, SearchState<SearchPageResultState> | undefined> =>
  createSelector(getWritableShoppingListsState(), (state: MiniShoppingListState) => (state && state.search) || undefined);

export const getWritableSearchPaginationStateSelector = (
  key: string
): MemoizedSelector<StateWithUser, PaginationState<SearchPageResultState> | undefined> =>
  createSelector(getWritableSearchSelector(), (state: SearchState<SearchPageResultState>) => {
    return (state && state[key]) || undefined;
  });

export const getWritableSearchPageResultsSelectorFactory = (
  key: string
): MemoizedSelector<StateWithUser, StateUtils.LoaderState<SearchPageResultState> | undefined> =>
  createSelector(
    getWritableSearchParamsSelector(key),
    getWritableSearchPaginationStateSelector(key),
    (queryParams: WritableShoppingListSearchParamsState, paginationState: PaginationState<SearchPageResultState>) => {
      return (queryParams && paginationState && paginationState.pages && paginationState.pages[queryParams.page]) || undefined;
    }
  );

export const getWritableSearchPageResultsSelector = (
  key: string
): MemoizedSelector<StateWithUser, StateUtils.LoaderState<SearchPageResultState> | undefined> =>
  createSelector(
    getWritableSearchParamsSelector(key),
    getWritableSearchPaginationStateSelector(key),
    (queryParams: MiniShoppingListSearchParamsState, paginationState: PaginationState<SearchPageResultState>) => {
      return (queryParams && paginationState && paginationState.pages && paginationState.pages[queryParams.page]) || undefined;
    }
  );

export const getWritableSearchResultFactory = (key: string): MemoizedSelector<StateWithUser, SearchPageResultState | undefined> =>
  createSelector(
    getWritableSearchPageResultsSelectorFactory(key),
    (loaderState: StateUtils.LoaderState<SearchPageResultState>) => {
      return (loaderState && StateUtils.loaderValueSelector(loaderState)) || undefined;
    }
  );

export const getWritableSearchResultPage = (key: string): MemoizedSelector<StateWithUser, Array<string> | undefined> =>
  createSelector(getWritableSearchResultFactory(key), (state: SearchPageResultState) => {
    return (state && state.results) || undefined;
  });

export const getWritableSearchResultEntities = (): MemoizedSelector<StateWithUser, EntriesState<MiniShoppingList> | undefined> =>
  createSelector(getWritableShoppingListsState(), (state: WritableShoppingListState) => (state && state.entities) || undefined);

export const getWritableSearchResultEntries = (key: string): MemoizedSelector<StateWithUser, MiniShoppingList[] | undefined> =>
  createSelector(
    getWritableSearchResultPage(key),
    getWritableSearchResultEntities(),
    (codes: Array<string>, entries: EntriesState<MiniShoppingList>) => {
      return (codes && entries && codes.map((i) => entries[i])) || undefined;
    }
  );

export const getWritableSearchPageResultsLoading = (key: string): MemoizedSelector<StateWithUser, boolean> =>
  createSelector(
    getWritableSearchPageResultsSelector(key),
    (loaderState) => (loaderState && StateUtils.loaderLoadingSelector(loaderState)) || false
  );

export const getWritableSearchPageResultsLoaded = (key: string): MemoizedSelector<StateWithUser, boolean> =>
  createSelector(
    getWritableSearchPageResultsSelector(key),
    (loaderState) => (loaderState && StateUtils.loaderSuccessSelector(loaderState)) || false
  );

export const getWritableSearchPaginationSelector = (key: string): MemoizedSelector<StateWithUser, Pagination | undefined> =>
  createSelector(getWritableSearchPaginationStateSelector(key), (state) => (state && state.pagination) || undefined);

// Principal state
export const getShoppingListPrincipalsState: MemoizedSelector<StateWithUser, ShoppingListsPrincipalsState> = createSelector(
  getShoppingListState,
  (state: ShoppingListState) => state.principals
);

export const getShoppingListsPrincipalSearchState: MemoizedSelector<
  StateWithUser,
  SearchState<SearchPageResultState> | undefined
> = createSelector(getShoppingListPrincipalsState, (state: ShoppingListsPrincipalsState) => (state && state.search) || undefined);

export const getShoppingListPrincipalsEntities: MemoizedSelector<
  StateWithUser,
  EntriesState<PrincipalSharedShoppingListFlags> | undefined
> = createSelector(
  getShoppingListPrincipalsState,
  (state: ShoppingListsPrincipalsState) => (state && state.entities) || undefined
);

export const getShoppingListPrincipalsSearchPaginationStateSelector = (
  searchParams: PrincipalSharedShoppingListSearchParams
): MemoizedSelector<StateWithUser, PaginationState<SearchPageResultState> | undefined> =>
  createSelector(getShoppingListsPrincipalSearchState, (state: SearchState<SearchPageResultState>) => {
    return state[searchParams.key] || undefined;
  });

export const getShoppingListPrincipalsSearchPaginationSelector = (
  searchParams: PrincipalSharedShoppingListSearchParams
): MemoizedSelector<StateWithUser, Pagination | undefined> =>
  createSelector(
    getShoppingListPrincipalsSearchPaginationStateSelector(searchParams),
    (state: PaginationState<SearchPageResultState>) => {
      return state?.pagination;
    }
  );

export const getPrincipalsSearchResultsLoading = (searchParams): MemoizedSelector<StateWithUser, boolean> =>
  createSelector(
    getShoppingListPrincipalsSearchPageResultsSelectorFactory(searchParams),
    (loaderState) => (loaderState && StateUtils.loaderLoadingSelector(loaderState)) || false
  );

export const getPrincipalsSearchResultsSuccess = (searchParams): MemoizedSelector<StateWithUser, boolean> =>
  createSelector(
    getShoppingListPrincipalsSearchPageResultsSelectorFactory(searchParams),
    (loaderState) => (loaderState && StateUtils.loaderSuccessSelector(loaderState)) || false
  );

export const getShoppingListPrincipalsSearchPageResultsSelectorFactory = (
  searchParams: PrincipalSharedShoppingListSearchParams
): MemoizedSelector<StateWithUser, StateUtils.LoaderState<SearchPageResultState> | undefined> =>
  createSelector(
    getShoppingListPrincipalsSearchPaginationStateSelector(searchParams),
    (paginationState: PaginationState<SearchPageResultState>) => {
      return (searchParams && paginationState && paginationState.pages && paginationState.pages[searchParams.page]) || undefined;
    }
  );

export const getShoppingListPrincipalsSearchResultFactory = (
  searchParams: PrincipalSharedShoppingListSearchParams
): MemoizedSelector<StateWithUser, SearchPageResultState | undefined> =>
  createSelector(
    getShoppingListPrincipalsSearchPageResultsSelectorFactory(searchParams),
    (loaderState: StateUtils.LoaderState<SearchPageResultState>) => {
      return (loaderState && StateUtils.loaderValueSelector(loaderState)) || undefined;
    }
  );

export const getShoppingListPrincipalsSearchResultPage = (
  searchParams: PrincipalSharedShoppingListSearchParams
): MemoizedSelector<StateWithUser, Array<string> | undefined> =>
  createSelector(getShoppingListPrincipalsSearchResultFactory(searchParams), (state: SearchPageResultState) => {
    return (state && state.results) || undefined;
  });

export const getShoppingListPrincipalsSearchResultEntries = (
  searchParams: PrincipalSharedShoppingListSearchParams
): MemoizedSelector<StateWithUser, PrincipalSharedShoppingListFlags[] | undefined> =>
  createSelector(
    getShoppingListPrincipalsSearchResultPage(searchParams),
    getShoppingListPrincipalsEntities,
    (codes: Array<string>, entries: EntriesState<PrincipalSharedShoppingListFlags>) => {
      return (codes && entries && codes.map((i) => entries[i])) || undefined;
    }
  );

export const getSelectedShoppingListStateFactory = (
  code: string
): MemoizedSelector<StateWithUser, StateUtils.ProcessesLoaderState<ShoppingList>> => {
  return createSelector(getShoppingListsState, (state) => PyStateUtils.entityProcessesLoaderStateSelector(state, code));
};

export const getSelectedShoppingListFactory = (code: string): MemoizedSelector<StateWithUser, ShoppingList | undefined> => {
  return createSelector(
    getSelectedShoppingListStateFactory(code),
    (shoppingListState) => (shoppingListState && StateUtils.loaderValueSelector(shoppingListState)) || undefined
  );
};

export const getSelectedShoppingListLoadingFactory = (code: string): MemoizedSelector<StateWithUser, boolean> => {
  return createSelector(getSelectedShoppingListStateFactory(code), (shoppingListState) =>
    StateUtils.loaderLoadingSelector(shoppingListState)
  );
};

export const getSelectedShoppingListLoadedFactory = (code: string): MemoizedSelector<StateWithUser, boolean> => {
  return createSelector(getSelectedShoppingListStateFactory(code), (shoppingListState) =>
    StateUtils.loaderSuccessSelector(shoppingListState)
  );
};

export const getShoppingListIsStableFactory = (code: string): MemoizedSelector<StateWithUser, boolean> => {
  return createSelector(getShoppingListsState, (state: StateUtils.EntityProcessesLoaderState<ShoppingList>) =>
    PyStateUtils.entityIsStableSelector(state, code)
  );
};

export const getShoppingListHasPendingProcessesFactory = (code: string): MemoizedSelector<StateWithUser, boolean> => {
  return createSelector(getShoppingListsState, (state: StateUtils.EntityProcessesLoaderState<ShoppingList>) =>
    PyStateUtils.entityHasPendingProcessesSelector(state, code)
  );
};

export const getUpdatePermissionsLoading: MemoizedSelector<StateWithUser, boolean> = createSelector(
  getShoppingListPrincipalsState,
  (shoppingListState) => StateUtils.loaderLoadingSelector(shoppingListState.update)
);

export const getUpdatePermissionsSuccess: MemoizedSelector<StateWithUser, boolean> = createSelector(
  getShoppingListPrincipalsState,
  (shoppingListState) => StateUtils.loaderSuccessSelector(shoppingListState.update)
);
