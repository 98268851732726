import { Action } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { HttpErrorModel, PriceFileEntry } from '../../../../model';
import { SearchParams } from '../../../../model/search.model';
import { PyStateUtils } from '../../../../state/utils';
import { PagedSearchAction } from '../../../store/actions/search.actions';
import { PRICE_FILE, PRICE_FILE_CANCEL, PRICE_FILE_DELETE, PRICE_FILE_STATUS } from '../price-file-state';

export enum PriceFileActionTypes {
  DeletePriceFile = '[PriceFile] Delete Price File',
  DeletePriceFileSuccess = '[PriceFile] Delete Price File Success',
  DeletePriceFileFail = '[PriceFile] Delete Price File Fail',
  DeletePriceFileReset = '[PriceFile] Delete Price File Reset',
  CancelPriceFile = '[PriceFile] Cancel Price File',
  CancelPriceFileSuccess = '[PriceFile] Cancel Price File Success',
  LoadPriceFileSuccess = '[PriceFile] Load Price File Success',
  LoadPriceFileFail = '[PriceFile] Load Price File Fail',
  CancelPriceFileFail = '[PriceFile] Cancel Price File Fail',
  CancelPriceFileReset = '[PriceFile] Cancel Price File Reset',
  SetPriceFileStatusSuccess = '[PriceFile] Set Price File Success',
  SetPriceFileStatusFail = '[PriceFile] Set Price File Fail',
  AutoOrderPriceFile = '[PriceFile] Auto Order Price File',
  AutoOrderPriceFileSuccess = '[PriceFile] Auto Order Price File Success',
  AutoOrderPriceFileFail = '[PriceFile] Auto Order Price File Fail',
  ClearPriceFile = '[PriceFile] Clear Price File',
}

export class DeletePriceFile extends StateUtils.EntityLoadAction {
  readonly type = PriceFileActionTypes.DeletePriceFile;

  constructor(public userId: string, id: string, public searchParams: SearchParams) {
    super(PRICE_FILE_DELETE, id);
  }
}

export class DeletePriceFileSuccess extends StateUtils.EntitySuccessAction implements PagedSearchAction {
  readonly type = PriceFileActionTypes.DeletePriceFileSuccess;
  readonly searchType = PRICE_FILE;

  constructor(id: string, public searchParams: SearchParams) {
    super(PRICE_FILE_DELETE, id);
  }
}

export class DeletePriceFileFail extends PyStateUtils.EntityFailAction {
  readonly type = PriceFileActionTypes.DeletePriceFileFail;

  constructor(id: string, public error?: HttpErrorModel) {
    super(PRICE_FILE_DELETE, id, error);
  }
}

export class DeletePriceFileReset extends StateUtils.EntityLoaderResetAction {
  readonly type = PriceFileActionTypes.DeletePriceFileReset;

  constructor(id: string) {
    super(PRICE_FILE_DELETE, id);
  }
}

export class CancelPriceFile extends StateUtils.EntityLoadAction {
  readonly type = PriceFileActionTypes.CancelPriceFile;

  constructor(public userId: string, id: string, public searchParams: SearchParams) {
    super(PRICE_FILE_CANCEL, id);
  }
}

export class CancelPriceFileSuccess extends StateUtils.EntitySuccessAction {
  readonly type = PriceFileActionTypes.CancelPriceFileSuccess;

  constructor(id: string, public searchParams: SearchParams) {
    super(PRICE_FILE_CANCEL, id);
  }
}

export class CancelPriceFileFail extends PyStateUtils.EntityFailAction {
  readonly type = PriceFileActionTypes.CancelPriceFileFail;

  constructor(id: string, public error?: HttpErrorModel) {
    super(PRICE_FILE_CANCEL, id, error);
  }
}

export class CancelPriceFileReset extends StateUtils.EntityLoaderResetAction {
  readonly type = PriceFileActionTypes.CancelPriceFileReset;

  constructor(id: string) {
    super(PRICE_FILE_CANCEL, id);
  }
}

export class SetPriceFileStatusSuccess extends StateUtils.EntitySuccessAction {
  readonly type = PriceFileActionTypes.SetPriceFileStatusSuccess;

  constructor(id: string, public payload: any) {
    super(PRICE_FILE_STATUS, id);
  }
}

export class SetPriceFileStatusFail extends PyStateUtils.EntityFailAction {
  readonly type = PriceFileActionTypes.SetPriceFileStatusFail;

  constructor(id: string, public error?: HttpErrorModel) {
    super(PRICE_FILE_STATUS, id, error);
  }
}

export class LoadPriceFileSuccess extends StateUtils.EntitySuccessAction {
  readonly type = PriceFileActionTypes.LoadPriceFileSuccess;

  constructor(id: string, data: PriceFileEntry) {
    super(PRICE_FILE, id, data);
  }
}

export class LoadPriceFileFail extends PyStateUtils.EntityFailAction {
  readonly type = PriceFileActionTypes.LoadPriceFileFail;

  constructor(id: string, public error?: HttpErrorModel) {
    super(PRICE_FILE, id, error);
  }
}

export class AutoOrderPriceFile extends StateUtils.LoaderLoadAction {
  readonly type = PriceFileActionTypes.AutoOrderPriceFile;

  constructor(public userId: string) {
    super(PRICE_FILE);
  }
}

export class AutoOrderPriceFileFail extends PyStateUtils.LoaderFailAction {
  readonly type = PriceFileActionTypes.AutoOrderPriceFileFail;

  constructor(public payload?: any) {
    super(PRICE_FILE, payload);
  }
}

export class AutoOrderPriceFileSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = PriceFileActionTypes.AutoOrderPriceFileSuccess;

  constructor() {
    super(PRICE_FILE);
  }
}

export class ClearPriceFile implements Action {
  readonly type = PriceFileActionTypes.ClearPriceFile;
}

export type PriceFileActions =
  | DeletePriceFile
  | DeletePriceFileSuccess
  | DeletePriceFileFail
  | DeletePriceFileReset
  | CancelPriceFile
  | CancelPriceFileSuccess
  | CancelPriceFileFail
  | CancelPriceFileReset
  | SetPriceFileStatusSuccess
  | LoadPriceFileSuccess
  | LoadPriceFileFail
  | SetPriceFileStatusFail
  | AutoOrderPriceFile
  | AutoOrderPriceFileFail
  | AutoOrderPriceFileSuccess
  | ClearPriceFile;
