<div
  ngbDropdown
  #myDrop="ngbDropdown"
  (openChange)="isCollapsed = !isCollapsed"
  *ngIf="originalOptions?.length > 0 || showButton; else readonlyTemplate"
>
  <div class="overlay d-lg-none" [class.show]="!isCollapsed"></div>
  <form class="wrapper" [formGroup]="entryFormGroup">
    <ng-container *ngIf="!!selectedOption" [ngTemplateOutlet]="headingTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="buttonTemplate"></ng-container>
    <div *ngIf="invalid" class="invalid-feedback">{{ errors | inputError }}</div>
  </form>

  <ng-template #headingTemplate>
    <div class="label" [class.disabled]="disabled">
      {{ ('accountingCodesLabel' | principalConfigValue) || ('checkout.orderLineAccountingCodes_heading' | cxTranslate) }}
    </div>
  </ng-template>

  <ng-template #buttonTemplate>
    <button
      class="px-2"
      type="button"
      [ngClass]="{ add: !selectedOption, edit: !!selectedOption }"
      [class.invalid]="invalid"
      [class.open]="!isCollapsed"
      [disabled]="disabled"
      ngbDropdownToggle
    >
      <span>
        <i nz-icon nzType="paper-clip" nzTheme="outline" class="mr-2"></i>
        <span
          >{{
            selectedOption
              ? selectedOption.label
              : ('accountingCodesLabel' | principalConfigValue) || ('checkout.orderLineAccountingCodes_heading' | cxTranslate)
          }}
          {{ !!selectedOption?.description ? selectedOption.description : '' }}
        </span>
      </span>
      <div class="icon-holder edit" *ngIf="!!selectedOption && !disabled">
        <i nz-icon nzType="edit" nzTheme="outline"></i>
      </div>
    </button>
    <ng-container *ngTemplateOutlet="dropdownContentTemplate"></ng-container>
  </ng-template>

  <ng-template #dropdownContentTemplate>
    <div ngbDropdownMenu class="py-0">
      <div class="d-lg-none header">
        {{
          selectedOption
            ? selectedOption.label
            : ('accountingCodesLabel' | principalConfigValue) || ('checkout.orderLineAccountingCodes_heading' | cxTranslate)
        }}
        <button class="btn btn-icon text-black" (click)="myDrop.close()">
          <i nz-icon pyIconThemeReverse nzType="close-circle" nzTheme="outline"></i>
        </button>
      </div>
      <py-reactive-form-input-field
        [group]="dropdownSearchForm"
        [fieldName]="'searchInput'"
        [placeholder]="'common.filter_placeholder' | cxTranslate"
        [icon]="'search'"
        [resettable]="true"
        (handleReset)="onResetSearchInput()"
        class="search-input"
      ></py-reactive-form-input-field>
      <button ngbDropdownItem *ngIf="!enableMandatoryAccountingCodes && !!control?.value" class="reset" (click)="onResetValue()">
        {{ 'common.clearSelection_action' | cxTranslate }}
      </button>
      <ul>
        <li
          ngbDropdownItem
          *ngFor="let option of options"
          class="d-flex align-items-center"
          [class.active]="option.value === selectedOption?.value"
          [class.disabled]="option.disabled"
          (click)="updateValue(option.value)"
        >
          <py-radio>
            <input
              [disabled]="option.disabled"
              [id]="'option-' + option.value"
              type="radio"
              [checked]="option.value === selectedOption?.value"
              (change)="updateValue(option.value); myDrop.close()"
            />
          </py-radio>
          <div class="d-flex justify-content-between align-items-center ml-3 w-100">
            <span class="label">{{ option.label }}</span>
            <span *ngIf="option.description" class="description ml-2">{{ option.description }}</span>
          </div>
        </li>
      </ul>
      <div *ngIf="!options?.length" class="p-2 text-center">
        {{ 'common.noResultsFound_hint' | cxTranslate }}
      </div>
    </div>
  </ng-template>
</div>

<ng-template #readonlyTemplate>
  <div class="text-wrapper d-flex align-items-start" *ngIf="!!entry.accountingCode">
    <i nz-icon nzType="paper-clip" nzTheme="outline" class="mx-2"></i>
    <div
      class="d-flex flex-column w-100"
      [ngbTooltip]="showTooltip && ('checkout.accountingCodes.assignInCart_tooltip' | cxTranslate)"
      *ngIf="readonlyValue$ | async as readonlyValue"
    >
      <span class="text-label">{{
        ('accountingCodesLabel' | principalConfigValue) || ('checkout.orderLineAccountingCodes_heading' | cxTranslate)
      }}</span>
      <span>{{ readonlyValue }}</span>
    </div>
  </div>
</ng-template>
