import { Component, Input, OnInit } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { Observable, zip } from 'rxjs';
import { CmsComponent } from '../../../../../core/model';
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ul[py-menu-services-items]',
  templateUrl: './menu-services-item.component.html',
  styleUrls: ['./menu-services-item.component.scss'],
})
export class MenuServicesItemComponent implements OnInit {
  @Input() uids: string[];
  components$: Observable<CmsComponent[]>;

  constructor(public componentService: CmsService) {}

  ngOnInit(): void {
    if (this.uids?.length) {
      this.components$ = zip(...this.uids.map((uid) => this.componentService.getComponentData(uid)));
    }
  }
}
