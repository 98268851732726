import { CmsComponentMapping, StandardCmsComponentConfig } from '@spartacus/core';

declare module '@spartacus/core' {
  interface StandardCmsComponentConfig {
    MenuServicesItemComponent?: CmsComponentMapping;
    SalesOrgAlertMessageComponent?: CmsComponentMapping;
    OperationalMessageComponent?: CmsComponentMapping;
    ProductsTileComponent?: CmsComponentMapping;
    ArticlesTileComponent?: CmsComponentMapping;
    TabContainerComponent?: CmsComponentMapping;
  }
}

export { StandardCmsComponentConfig };

export const CMS_FLEX_NAVIGATION_COMPONENT_TYPE = 'CMSFlexNavigationComponent';
