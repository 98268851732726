import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { FocusConfig, LaunchDialogService } from '@spartacus/storefront';
import { DialogOptions } from '../../models';

@Directive()
export abstract class BaseAbstractModalComponent {
  dismissable = true;
  isEventAlreadyHandled = false;
  dialogOptions: DialogOptions;
  focusConfig: FocusConfig = {
    trap: true,
    block: true,
    autofocus: 'button',
  };

  handlerForDismissOnClickOutside?: (...args: any[]) => void;
  dismissOnClickOutsideHandlerArgs: any[] = [];

  constructor(protected el: ElementRef, protected launchDialogService: LaunchDialogService, protected renderer: Renderer2) {}

  // Handles dismissing modal when clicking outside on the page background
  @HostListener('mousedown', ['$event'])
  handleMouseDown(event: UIEvent): void {
    if (this.isEventAlreadyHandled) {
      return;
    }

    if ((event.target as any).tagName === this.el.nativeElement.tagName) {
      if (!this.dismissable) {
        this.applyNonDismissableStylingEffect();
        return;
      }

      this.isEventAlreadyHandled = true;

      this.handlerForDismissOnClickOutside
        ? this.handlerForDismissOnClickOutside(...this.dismissOnClickOutsideHandlerArgs)
        : this.closeModal('clicked outside');
    }
  }

  // Adds CSS class to trigger styling effect indicating non-dismissable modal
  @HostListener('keydown.escape', ['$event'])
  applyNonDismissableStylingEffect(shouldApplyEffect = false) {
    if (!this.dismissable || shouldApplyEffect) {
      const effectClassName = 'modal-static';
      this.renderer.addClass(this.el.nativeElement, effectClassName);

      setTimeout(() => {
        this.renderer.removeClass(this.el.nativeElement, effectClassName);
      }, 100);
    }
  }

  closeModal(reason: string): void {
    this.launchDialogService.closeDialog(reason);
  }
}
