import { AREA_OF_USE_ALL_CATEGORIES_CODE, EntityMap } from '../model';

/**
 * For use with cx-generic-link since it is quite good at urlifying the actual url
 * when served one. This method begins with an url decode and
 * removes URI reserved characters ()*! because of Angular Router.
 *
 * @param url the url string, likely something like /c/Hello?c=01_01
 * @param queryParams an EntityMap<string> ( { [id: string] : string } ) which will be appended to any existing query parameters
 */
export function prepareUrlForLink(url: string, queryParams?: EntityMap<string>): string {
  const hasMainAreaOfUse =
    url?.includes('aou=' + AREA_OF_USE_ALL_CATEGORIES_CODE) || queryParams?.['aou'] === AREA_OF_USE_ALL_CATEGORIES_CODE;
  if (!!url && !!queryParams) {
    let decodedUrl = decodeAndRemoveUnwantedCharacters(url, hasMainAreaOfUse);
    let cleanedUrl = removeEmptyQueryParams(decodedUrl);

    Object.entries(queryParams).forEach(([param, value]) => {
      cleanedUrl = updateUrlParameter(cleanedUrl, param, value);
    });

    return cleanedUrl;
  }
  return !!url ? decodeAndRemoveUnwantedCharacters(url, hasMainAreaOfUse) : url;
}

function removeEmptyQueryParams(url: string): string {
  const indexOfQuestionMark = url.indexOf('?');
  if (indexOfQuestionMark === -1) {
    return url;
  }

  let params = new URLSearchParams(url.substring(indexOfQuestionMark + 1));
  const p = [];
  params.forEach((value, key) => {
    if (!!value) {
      p.push(`${key}=${value}`);
    }
  });

  return `${url.substring(0, indexOfQuestionMark + 1)}${p.join('&')}`;
}

/**
 * This method begins with an url decode and
 * removes URI reserved characters ()*!% because of Angular Router.
 *
 * @param url the url string, likely something like /c/Hello?c=01_01
 * @param hasMainAreaOfUse the flag, should be true when the main area of use is in param - /c?aou=* (in that case * should not be ommited)
 */
function decodeAndRemoveUnwantedCharacters(url: string, hasMainAreaOfUse?: boolean): string {
  const regExp = hasMainAreaOfUse ? new RegExp(/[\(\)\!\%]/g) : new RegExp(/[\(\)\*\!\%]/g);
  return decodeURI(url).replace(regExp, '');
}

/**
 * This method adds, updates, or removes query params.
 *
 * @param url the url string, likely something like "/c/Hello?c=01_01"
 * @param param the param string, likely something like "mya"
 * @param value the value string, likely something like "1"
 */
function updateUrlParameter(url: string, param: string, value: string) {
  const regex = new RegExp('[?&]' + param + '=([^&#]*)');
  const results = regex.exec(url);

  const urlHasQuery = url.indexOf('?') >= 0;
  if (results) {
    let newUrl = url;
    const replaceWith = !!value ? `&${param}=${value}` : '';
    newUrl = url.replace(results[0], replaceWith);

    if (newUrl.indexOf('?') === -1) {
      return newUrl.replace('&', '?');
    } else {
      return newUrl;
    }
  }

  if (!!value) {
    return `${url}${urlHasQuery ? '&' : '?'}${param}=${value}`;
  }
  return url;
}
