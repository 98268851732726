import { Injectable } from '@angular/core';
import { CmsService, PageMetaResolver, PageRobotsMeta, PageRobotsResolver, PageTitleResolver } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Page } from '../../../cms/model/page.model';
import { PageType } from '../../../model/cms.model';

@Injectable({
  providedIn: 'root',
})
export class MyPageMetaResolver extends PageMetaResolver implements PageTitleResolver, PageRobotsResolver {
  private cms$: Observable<Page> = this._cmsService.getCurrentPage().pipe(filter((page) => !!page));

  constructor(private _cmsService: CmsService) {
    super();
    this.pageType = PageType.CONTENT_PAGE;
    this.pageTemplate = 'MyPageTemplate';
  }

  resolveTitle(): Observable<string | undefined> {
    return this.cms$.pipe(map((p) => p.title));
  }

  resolveRobots(): Observable<PageRobotsMeta[] | undefined> {
    return of([PageRobotsMeta.NOFOLLOW, PageRobotsMeta.NOINDEX]);
  }
}
