import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { CartType } from '../../core/model';
import { SemanticPathService } from '../../core/routing';

export const CART_TYPE_PARAM_KEY = 'cartType';

/**
 * Checks for cart type in query param
 */
@Injectable({
  providedIn: 'root',
})
export class HasCartTypeParamGuard {
  constructor(protected router: Router, protected semanticPathService: SemanticPathService) {}

  private get notFoundUrl(): UrlTree {
    return this.router.parseUrl(this.semanticPathService.get('notFound'));
  }

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const param = route.queryParamMap.get(CART_TYPE_PARAM_KEY);
    const cartType = CartType[param];
    return Boolean(cartType) || this.notFoundUrl;
  }
}
