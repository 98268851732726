<div class="row container no-gutters m-auto pt-5 pb-10">
  <div class="megamenu-left-container" [ngClass]="{ 'col-4': !columnsAreEqualWidth, 'col-6': columnsAreEqualWidth }">
    <div class="pr-5">
      <ng-container *ngTemplateOutlet="templateColumnLeft"></ng-container>
    </div>
  </div>
  <div class="megamenu-right-container" [ngClass]="{ 'col-8': !columnsAreEqualWidth, 'col-6': columnsAreEqualWidth }">
    <div class="pl-5">
      <ng-container *ngTemplateOutlet="templateColumnRight"></ng-container>
    </div>
  </div>
</div>
