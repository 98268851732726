<ng-container *ngIf="articleStatus !== 'ZA'; else zaTemplate">
  <ng-container *ngTemplateOutlet="templateNonReturnable"></ng-container>
</ng-container>

<ng-template #zaTemplate>
  <ng-container *ngIf="outOfStock !== undefined">
    <ng-container *ngIf="outOfStock">
      <ng-container *ngTemplateOutlet="templateNonReturnable"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #templateNonReturnable>
  <span class="text"><ng-content></ng-content></span>
</ng-template>
