import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { ListInsertComponent } from './list-insert.component';

@NgModule({
  imports: [CommonModule, NzIconModule],
  declarations: [ListInsertComponent],
  exports: [ListInsertComponent],
})
export class ListInsertModule {}
