import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PriceFileEntry, PriceFileListPage, PriceFileRequest, PriceFileStatus } from '../../../model/price-file.model';
import { SearchParams } from '../../../model/search.model';

@Injectable({
  providedIn: 'root',
})
export class PriceFileConnector {
  constructor(private httpClient: HttpClient, private occEndpointsService: OccEndpointsService) {}

  loadPage(userId: string, searchParams: SearchParams): Observable<PriceFileListPage> {
    return this.httpClient.get<PriceFileListPage>(
      this.occEndpointsService.buildUrl('priceFiles', {
        urlParams: { userId: userId },
        queryParams: {
          fields: 'FULL',
          page: searchParams.page ?? 0,
          count: searchParams.count ?? 10,
          sort: 'creationtime:desc',
        },
      })
    );
  }

  load(userId: string, code: string): Observable<PriceFileEntry> {
    return this.httpClient.get<PriceFileEntry>(
      this.occEndpointsService.buildUrl('priceFile', {
        urlParams: {
          userId: userId,
          code: code,
        },
      })
    );
  }

  request(userId: string, priceFileRequest: PriceFileRequest): Observable<PriceFileEntry> {
    return this.httpClient.post<PriceFileEntry>(
      this.occEndpointsService.buildUrl('priceFiles', { urlParams: { userId: userId } }),
      priceFileRequest
    );
  }

  delete(userId: string, code: string): Observable<any> {
    return this.httpClient.delete(
      this.occEndpointsService.buildUrl('priceFileDelete', {
        urlParams: {
          userId: userId,
          code: code,
        },
      })
    );
  }

  cancel(userId: string, code: string): Observable<any> {
    return this.httpClient.patch(
      this.occEndpointsService.buildUrl('priceFileStatus', {
        urlParams: { userId: userId, code: code },
        queryParams: { status: 'CANCELED' },
      }),
      {}
    );
  }

  download(userId: string, code: string): Observable<Blob> {
    const url = this.occEndpointsService.buildUrl('priceFileDownload', { urlParams: { userId: userId, code: code } });
    return this.httpClient.get(url, { responseType: 'blob' });
  }

  getPriceFileStatus(userId: string, code: string): Observable<PriceFileStatus> {
    const url = this.occEndpointsService.buildUrl('priceFileStatus', { urlParams: { userId, code } });
    return this.httpClient.get(url).pipe(map((val) => (val as any).value));
  }

  autoOrderPriceFile(userId: string): Observable<PriceFileEntry> {
    return this.httpClient.post<PriceFileEntry>(
      this.occEndpointsService.buildUrl('priceFileAutoOrder', { urlParams: { userId } }),
      {}
    );
  }
}
