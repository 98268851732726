import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { Product } from '../../../core/model';
import { PrincipalConfigurationService } from '../../../core/user';

export interface ProductCarouselItem {
  product: Product;
  link?: string;
  alternative?: boolean;
  replacement?: boolean;
  recommendation?: boolean;
  ticket?: string;
  id?: string;
}
@Component({
  selector: 'py-product-carousel',
  templateUrl: './product-carousel.component.html',
  styleUrls: ['./product-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCarouselComponent implements OnInit {
  enableProductOutletBadgeOnlyIfAllArticlesAreOutlet$: Observable<boolean>;
  catalogProductItemSecondary$: Observable<boolean>;

  @Input() items: ProductCarouselItem[];
  @Input() title: string;
  @Input() smallTitle: boolean = false;
  @Input() hideOverflow: boolean = false;
  @Input() browseReplacementUrl: string;

  @Output() itemSelect = new EventEmitter<ProductCarouselItem>();

  constructor(private routingService: RoutingService, private principalConfigurationService: PrincipalConfigurationService) {}

  ngOnInit(): void {
    this.enableProductOutletBadgeOnlyIfAllArticlesAreOutlet$ = this.principalConfigurationService.isEnabled(
      'enableProductOutletBadgeOnlyIfAllArticlesAreOutlet'
    );

    this.catalogProductItemSecondary$ = this.principalConfigurationService
      .isEnabled('catalogProductItemSecondary')
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }));
  }

  onItemSelect(product: ProductCarouselItem): void {
    this.itemSelect.emit(product);
  }

  goToProduct(url: string): void {
    this.routingService.goByUrl(url);
  }
}
