import { MemoizedSelector, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { SapPriceCampaign, SapPriceCampaignDetails, SapPriceCampaignList } from '../../../../model';
import { PyStateUtils } from '../../../../state';
import { CatalogState, StateWithCatalog } from '../../../store/catalog-state';
import { getCatalogState } from '../../../store/selectors/feature.selector';

export const getPriceCampaignDetailsState: MemoizedSelector<
  StateWithCatalog,
  StateUtils.EntityLoaderState<SapPriceCampaignDetails>
> = createSelector(getCatalogState, (state: CatalogState) => state && state.priceCampaignDetails);

export const getPriceCampaignDetailsLoaderState = (
  code: string
): MemoizedSelector<StateWithCatalog, StateUtils.LoaderState<SapPriceCampaignDetails>> => {
  return createSelector(
    getPriceCampaignDetailsState,
    (details) => PyStateUtils.entityLoaderStateSelector(details, code) || PyStateUtils.initialLoaderState
  );
};

export const getPriceCampaignsState: MemoizedSelector<
  StateWithCatalog,
  StateUtils.LoaderState<SapPriceCampaignList>
> = createSelector(getCatalogState, (state: CatalogState) => state.priceCampaigns ?? PyStateUtils.initialLoaderState);

export const getPriceCampaignsLoading: MemoizedSelector<StateWithCatalog, boolean> = createSelector(
  getPriceCampaignsState,
  (state) => (state && StateUtils.loaderLoadingSelector(state)) || false
);

export const getPriceCampaignsLoaded: MemoizedSelector<StateWithCatalog, boolean> = createSelector(
  getPriceCampaignsState,
  (state) => (state && StateUtils.loaderSuccessSelector(state)) || false
);

export const getPriceCampaigns: MemoizedSelector<StateWithCatalog, SapPriceCampaignList | undefined> = createSelector(
  getPriceCampaignsLoaded,
  getPriceCampaignsState,
  (loaded, entriesState) => (loaded && StateUtils.loaderValueSelector(entriesState)) || undefined
);

export const getDefaultPriceCampaign: MemoizedSelector<StateWithCatalog, SapPriceCampaign | undefined> = createSelector(
  getPriceCampaigns,
  (campaigns) => (campaigns && campaigns.entries?.find((campaign) => campaign.fallbackCampaign)) || undefined
);
