import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EventService } from '@spartacus/core';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, merge, of } from 'rxjs';
import { filter, map, mapTo, shareReplay, switchMap, take } from 'rxjs/operators';
import { ArticleService } from '../../../../core/catalog';
import { Article, ArticleCarouselActionMode, ArticlePrice, Unit } from '../../../../core/model';
import { OrderCardsFacade, PriceFacade, PrincipalConfigurationService } from '../../../../core/user';
import { ActiveCartFacade, AddCartEntryFailEvent, AddCartEntrySuccessEvent } from '../../../../features/cart/base';
import { SoldToFacade } from '../../../../features/sold-to-base';
import { resolveDefaultArticleQuantity } from '../../../utils/resolve-default-article-quantity';
import { resolveDefaultArticleUnit } from '../../../utils/resolve-default-article-unit';

@Component({
  selector: 'py-article-carousel-item-customizable',
  templateUrl: './article-carousel-item-customizable.component.html',
  styleUrls: ['./article-carousel-item-customizable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleCarouselItemCustomizableComponent implements OnInit, OnDestroy {
  carouselActionModes: typeof ArticleCarouselActionMode = ArticleCarouselActionMode;

  quantityAndUnitValue$ = new BehaviorSubject<{ quantity: number; unitCode: string }>({
    quantity: undefined,
    unitCode: undefined,
  });

  articlePrice$: Observable<ArticlePrice>;
  articlePriceLoading$: Observable<boolean>;
  useArticleUnitAsDefault$: Observable<boolean>;
  disableActionButtons$: Observable<boolean>;
  emitLoadPrice$ = new Subject<void>();
  isAddToCartInProgress$ = new BehaviorSubject<boolean>(false);
  isMaxExceeded: boolean = false;
  initialMinimumQuantity$: Observable<number>;

  protected subscription = new Subscription();

  @Input() carouselActionMode: ArticleCarouselActionMode;

  private _article: Article;
  private articleFailure$: Observable<boolean>;

  @Input() set article(article: Article) {
    this._article = article;
    if (article) {
      this.setDefaultQuantityAndUnit();
      this.setErrorState(article);
    }
  }

  get article(): Article {
    return this._article;
  }

  constructor(
    private articleService: ArticleService,
    private priceService: PriceFacade,
    private principalConfigurationService: PrincipalConfigurationService,
    private eventService: EventService,
    private activeCartService: ActiveCartFacade,
    private orderCardsService: OrderCardsFacade,
    private soldToService: SoldToFacade
  ) {}

  ngOnInit(): void {
    this.useArticleUnitAsDefault$ = this.principalConfigurationService
      .isEnabled('useArticleUnitAsDefault')
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.articlePrice$ = combineLatest([
      of(this.article),
      this.useArticleUnitAsDefault$,
      merge(this.emitLoadPrice$.pipe(mapTo(true)), this.quantityAndUnitValue$.pipe(mapTo(false))),
    ]).pipe(
      filter(([article, _useArticleUnitAsDefault, _emitLoadPrice]) => !!article),
      switchMap(([article, useArticleUnitAsDefault, emitLoadPrice]) => {
        const value = this.quantityAndUnitValue$.value;

        let async = false;
        if (!emitLoadPrice) {
          const defaultUnit = resolveDefaultArticleUnit(article, useArticleUnitAsDefault);
          async = resolveDefaultArticleQuantity(article, defaultUnit, useArticleUnitAsDefault) === value.quantity;
        }

        return this.priceService.getArticlePrice(
          article?.code,
          value.quantity,
          this.resolveActiveUnit(article, value.unitCode),
          async,
          emitLoadPrice
        );
      })
    );

    this.articlePriceLoading$ = combineLatest([of(this.article), this.quantityAndUnitValue$]).pipe(
      filter(([article, _emitLoadPrice]) => !!article),
      switchMap(([article, quantityAndUnitValue]) => {
        return this.priceService.loadingPrice(
          article?.code,
          quantityAndUnitValue.quantity,
          this.resolveActiveUnit(article, quantityAndUnitValue.unitCode)
        );
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.disableActionButtons$ = combineLatest([
      of(this.article),
      this.orderCardsService.isOrderCardsUserEnabled(),
      this.quantityAndUnitValue$,
      this.articleFailure$,
    ]).pipe(
      map(
        ([article, isOrderCardsUserEnabled, quantityAndUnitValue, showArticleError]) =>
          this.articleService.isArticleDiscontinued(article) ||
          article?.salesBlocked ||
          isOrderCardsUserEnabled ||
          !quantityAndUnitValue ||
          quantityAndUnitValue?.quantity === 0 ||
          showArticleError
      ),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.initialMinimumQuantity$ = this.soldToService
      .getActiveSoldTo()
      .pipe(map((soldTo) => (soldTo.paperManagementEnabled ? 1 : undefined)));

    this.subscription.add(
      merge(
        this.eventService.get(AddCartEntrySuccessEvent).pipe(map((_) => false)),
        this.eventService.get(AddCartEntryFailEvent).pipe(map((_) => false))
      )
        .pipe(shareReplay({ refCount: true, bufferSize: 1 }))
        .subscribe((_) => {
          this.isAddToCartInProgress$.next(false);
        })
    );

    this.subscription.add(
      of(this.article)
        .pipe(
          filter((article) => !!article),
          take(1),
          switchMap((article: Article) => this.activeCartService.isMaxOrderLinesExceeded(article.cartType))
        )
        .subscribe((isMaxExceeded) => {
          if (isMaxExceeded) {
            this.isAddToCartInProgress$.next(false);
          }
          this.isMaxExceeded = isMaxExceeded;
        })
    );
  }

  updateQuantityAndUnit(values: { quantity: number; unitCode: string }): void {
    this.quantityAndUnitValue$.next(values);
  }

  addToCart(): void {
    this.subscription.add(
      of(this.article)
        .pipe(
          filter((article) => !!article),
          take(1)
        )
        .subscribe((article) => {
          const quantityAndUnitValue = this.quantityAndUnitValue$.value;
          if (!this.isMaxExceeded) {
            this.isAddToCartInProgress$.next(true);
          }
          this.activeCartService.addEntry(
            article.code,
            quantityAndUnitValue?.quantity,
            this.resolveActiveUnit(article, quantityAndUnitValue?.unitCode),
            article.cartType,
            undefined,
            article.articleNumber
          );
        })
    );
  }

  loadPrice(): void {
    this.emitLoadPrice$.next();
  }

  resolveActiveUnit(article: Article, unitCode: string): Unit {
    return article.units?.find((u) => u.code === unitCode);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setDefaultQuantityAndUnit(): void {
    const useArticleUnitAsDefault = true;
    const unit = resolveDefaultArticleUnit(this.article, useArticleUnitAsDefault);
    const quantity = resolveDefaultArticleQuantity(this.article, unit, useArticleUnitAsDefault);
    this.updateQuantityAndUnit({ quantity, unitCode: unit?.code });
  }

  private setErrorState(article: Article): void {
    this.articleFailure$ = this.articleService.getArticleFailure(article.code).pipe(
      map((articleFailure) => Boolean(!!articleFailure || article?.localDeletion || article?.invisible)),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
}
