import { Injectable } from '@angular/core';
import { SapPriceCampaignBannerEntry, SapPriceCampaignDetails } from '../../../model';
import { Occ } from '../../../occ/occ-models';
import { Converter } from '../../../util';

@Injectable({ providedIn: 'root' })
export class PriceCampaignDetailsNormalizer implements Converter<Occ.SapPriceCampaignDetails, SapPriceCampaignDetails> {
  convert(source: Occ.SapPriceCampaignDetails, target?: SapPriceCampaignDetails): SapPriceCampaignDetails {
    if (source) {
      target = {
        code: source?.code,
        description: source?.description,
        entries: source?.entries?.length > 0 ? this.convertBannerEntries(source.entries) : [],
        headerText: source?.headerText,
        headerTitle: source?.headerTitle,
        headerImage: source?.headerImage,
        link: source?.link,
      };
    }
    return target;
  }

  private convertBannerEntries(entries: Occ.SapPriceCampaignBannerEntry[]): SapPriceCampaignBannerEntry[] {
    return entries?.map((entry, index) => this.convertBannerEntry(entry, String(index)));
  }

  private convertBannerEntry(source: Occ.SapPriceCampaignBannerEntry, id: string): SapPriceCampaignBannerEntry {
    return {
      id: id,
      image: source?.image,
      link: source?.link,
      text: source?.text,
    };
  }
}
