<div [formGroup]="group" class="mb-2">
  <div *ngIf="tooltip || label" class="mb-2 d-flex align-items-baseline">
    <label class="input-label-content" *ngIf="label" [htmlFor]="'input-' + fieldName">
      {{ label }}
      {{ optional && showOptionalHint ? ('common.optionalWithinParentheses_hint' | cxTranslate) : '' }}
    </label>
    <div class="flex-grow-0"></div>
    <div class="ml-2 tooltip-icon input-label-content" [id]="'tooltip-' + fieldName" *ngIf="tooltip">
      <i nz-icon nzType="question-circle" [ngbTooltip]="tooltip"></i>
    </div>
  </div>
  <div
    class="form-control p-0 d-flex align-items-center justify-content-between reactive-form-textarea-holder input-type-effects-textarea"
    [class.is-valid]="showSuccessState && !isInvalid() && group.controls[fieldName].dirty"
    [class.is-invalid]="isInvalid()"
  >
    <textarea
      [id]="'input-' + fieldName"
      class="border-0 w-100 h-100 p-3"
      [class.is-invalid]="isInvalid()"
      [formControlName]="fieldName"
      [placeholder]="placeholder"
      [attr.maxLength]="maxLength || null"
      rows="4"
      [attr.aria-describedby]="description ? 'description-' + fieldName : null"
    ></textarea>
  </div>
  <small [id]="'description-' + fieldName" *ngIf="description" class="form-text text-muted">
    {{ description }}
  </small>
  <div *ngIf="!hideErrorMessages" [id]="'errors-' + fieldName" class="invalid-feedback">
    {{ errors() | inputError }}
  </div>
  <span *ngIf="maxLength && fieldValueLength" class="max-length"
    >{{ 'common.maxCharsCount_hint' | cxTranslate }} :
    <span [ngClass]="{ 'text-danger': fieldValueLength >= maxLength }">{{ fieldValueLength }}</span> ({{ maxLength }})</span
  >
</div>
