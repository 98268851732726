import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../../core/i18n';
import { PipesModule } from '../../../pipes';
import { ConsignmentStockArticleStockInfoComponent } from './consignment-stock-article-stock-info.component';

@NgModule({
  declarations: [ConsignmentStockArticleStockInfoComponent],
  imports: [CommonModule, I18nModule, PipesModule, NzIconModule],
  exports: [ConsignmentStockArticleStockInfoComponent],
})
export class ConsignmentStockArticleStockInfoModule {}
