import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { DeliveryAddress, Pagination, ShipTosSearchParams } from '../../model';
import { ShipTosSearchParamsState, USER_BASE_FEATURE } from '../store/user-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: ShipTosFacade,
      feature: USER_BASE_FEATURE,
      async: true,
      methods: [
        'searchForShipTos',
        'getShipTos',
        'getShipTosLoading',
        'getShipTosPagination',
        'getSearchParametersForShipTos',
        'updateSearchParametersForShipTos',
      ],
    }),
})
export abstract class ShipTosFacade {
  abstract searchForShipTos(searchParams: ShipTosSearchParams): void;
  abstract getShipTos(): Observable<Array<DeliveryAddress>>;
  abstract getShipTosLoading(): Observable<boolean>;
  abstract getShipTosPagination(): Observable<Pagination | undefined>;
  abstract getSearchParametersForShipTos(key?: string): Observable<ShipTosSearchParamsState>;
  abstract updateSearchParametersForShipTos(searchParams: ShipTosSearchParams): void;
}
