import { Injectable } from '@angular/core';
import { Config, OccConfig } from '@spartacus/core';

@Injectable({
  providedIn: 'root',
  useExisting: Config,
})
export abstract class ConsentsConfig extends OccConfig {
  consents?: {
    anonymous?: {
      requiredConsents?: string[];
      consents?: string[];
      hiddenConsents?: string[];
      trackingConsent?: string;
      personalizationConsent?: string;
      marketingThirdPartyConsent?: string;
    };
    user?: {
      requiredConsents?: string[];
      consents?: string[];
      transferredConsents?: string[];
      hiddenConsents?: string[];
      hiddenLoginConsents?: string[];
      trackingConsent?: string;
      personalizationConsent?: string;
      marketingThirdPartyConsent?: string;
    };
  };
}
