import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GenericLinkModule, MegamenuListItemsModule } from '../../../../../shared';
import { MenuServicesItemComponent } from './menu-services-item.component';
@NgModule({
  imports: [CommonModule, GenericLinkModule, MegamenuListItemsModule],
  declarations: [MenuServicesItemComponent],
  exports: [MenuServicesItemComponent],
})
export class MenuServicesItemsModule {}
