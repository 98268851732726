import { StateUtils } from '@spartacus/core';
import { SiteFamilyConfig } from '../../../model';
import { PyStateUtils } from '../../../state/utils';
import { SITE_FAMILY_CONFIG } from '../user-state';

export enum SiteFamilyConfigActionTypes {
  LoadSiteFamilyConfig = '[User] Load site family config',
  LoadSiteFamilyConfigFail = '[User] Load site family config fail',
  LoadSiteFamilyConfigSuccess = '[User] Load site family config success',
}

export class LoadSiteFamilyConfig extends StateUtils.LoaderLoadAction {
  readonly type = SiteFamilyConfigActionTypes.LoadSiteFamilyConfig;

  constructor(public userId: string) {
    super(SITE_FAMILY_CONFIG);
  }
}

export class LoadSiteFamilyConfigFail extends PyStateUtils.LoaderFailAction {
  readonly type = SiteFamilyConfigActionTypes.LoadSiteFamilyConfigFail;

  constructor(public payload?: any) {
    super(SITE_FAMILY_CONFIG, payload);
  }
}

export class LoadSiteFamilyConfigSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = SiteFamilyConfigActionTypes.LoadSiteFamilyConfigSuccess;

  constructor(public payload: SiteFamilyConfig) {
    super(SITE_FAMILY_CONFIG);
  }
}

export type SiteFamilyConfigActions = LoadSiteFamilyConfig | LoadSiteFamilyConfigFail | LoadSiteFamilyConfigSuccess;
