import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CxNumericPipe } from '@spartacus/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../../core/i18n';
import { PrincipalConfigurationDirectiveModule } from '../../../directives';
import { PipesModule } from '../../../pipes';
import { CuttingButtonComponent } from './cutting-button.component';

@NgModule({
  imports: [CommonModule, NzIconModule, I18nModule, NgbTooltipModule, PipesModule, PrincipalConfigurationDirectiveModule],
  declarations: [CuttingButtonComponent],
  exports: [CuttingButtonComponent],
  providers: [CxNumericPipe],
})
export class CuttingButtonModule {}
