export interface TranslationChunksConfig {
  [chunk: string]: string[];
}

export const translationChunksConfig: TranslationChunksConfig = {
  common: ['navigation', 'pwa', 'validation', 'errors', 'socialMeta'],
  cart: [],
  myPage: [
    'dashboard',
    'deliveryAddresses',
    'invoiceHistory',
    'myAssortment',
    'orderHistory',
    'priceFiles',
    'settings',
    'shoppingLists',
    'statistics',
    'trackAndTrace',
    'orderCards',
    'users',
    'purchaseLimits',
    'accountingCodes',
    'orderApproval',
    'permissions',
    'areaOfUse',
  ],
  checkout: [],
  catalog: ['product', 'article', 'addon', 'refiningRule', 'documents'],
  user: ['anonymousConsents', 'consents', 'login', 'logout', 'password', 'username', 'registration', 'onlineChat'],
  content: ['labels', 'mailForm', 'news', 'timedBannerCollection'],
  brandLandingPage: [],
  email: [],
  asm: [],
};
