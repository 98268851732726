import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../../../core/i18n';
import { UrlModule } from '../../../../../core/routing';
import { NotificationModule } from '../../../../../shared/components/notification';
import { PipesModule } from '../../../../../shared/pipes/pipes.module';
import { CartMessageModalHeaderModule } from '../cart-message-modal-header/cart-message-modal-header.module';
import { RemainingCartMessageModalComponent } from './remaining-cart-message-modal.component';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    NzIconModule,
    I18nModule,
    NotificationModule,
    RouterModule,
    UrlModule,
    CartMessageModalHeaderModule,
  ],
  declarations: [RemainingCartMessageModalComponent],
  exports: [RemainingCartMessageModalComponent],
})
export class RemainingCartMessageModalModule {}
