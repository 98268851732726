import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '../../../core/i18n';
import { AlertMessageModule } from '../alert-message/alert-message.module';
import { UnsupportedMessageComponent } from './unsupported-message.component';

@NgModule({
  imports: [CommonModule, AlertMessageModule, I18nModule],
  declarations: [UnsupportedMessageComponent],
  exports: [UnsupportedMessageComponent],
})
export class UnsupportedMessageModule {}
