import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { OutletPosition, OutletService } from '@spartacus/storefront';
import { PageTimeCreatedComponent } from './page-time-created.component';

@Injectable({
  providedIn: 'root',
})
export class PageTimeCreatedFeatureService {
  constructor(private outletService: OutletService, private componentFactoryResolver: ComponentFactoryResolver) {}

  init(): void {
    const factory = this.componentFactoryResolver.resolveComponentFactory(PageTimeCreatedComponent);
    this.outletService.add('ContentPageTemplate', factory, OutletPosition.BEFORE);
    this.outletService.add('ContentPageTemplate2Cols', factory, OutletPosition.BEFORE);
    this.outletService.add('ContentPageTemplate3Cols', factory, OutletPosition.BEFORE);
    this.outletService.add('ContentPageTemplateWide', factory, OutletPosition.BEFORE);
    this.outletService.add('ContentPageTemplate2ColsWide', factory, OutletPosition.BEFORE);
  }
}
