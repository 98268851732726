import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { CATALOG_BASE_FEATURE } from './base/feature-name';
import {
  GetArticleResolver,
  LeafCategoryCodeResolver,
  ProductCodeResolver,
  RestoreScrollPositionResolver,
} from './container/resolvers';

@NgModule({
  providers: [
    RestoreScrollPositionResolver,
    LeafCategoryCodeResolver,
    ProductCodeResolver,
    GetArticleResolver,
    provideConfig(<CmsConfig>{
      featureModules: {
        catalogAndPdp: {
          module: () => import('./container/cms-catalog-pdp.module').then((m) => m.CmsCatalogPdpModule),
          dependencies: [
            () => import('../area-of-use-admin-base/area-of-use-admin-base.module').then((m) => m.AreaOfUseAdminBaseModule),
            () => import('../documents-base/documents-base.module').then((m) => m.DocumentsBaseModule),
            CATALOG_BASE_FEATURE,
          ],
          cmsComponents: ['CmsBrowseCatalogFlexComponent', 'CmsCatalogFlexComponent'],
        },
        [CATALOG_BASE_FEATURE]: {
          module: () => import('./base/catalog-base.module').then((m) => m.CatalogBaseModule),
        },
      },
    }),
  ],
})
export class CatalogFeatureModule {}
