import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { LaunchDialogService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseAbstractModalComponent, ModalCloseReason, ModalCloseReasonWithPayload } from '../../../../core/modal';

export interface NameMarkingModalData {
  articleTitle: string;
  inEcommerceQuantity: number;
  names: string[];
}

export interface NameMarkingModalCloseReason extends ModalCloseReasonWithPayload {
  reason: string;
  payload: string[];
}

@Component({
  selector: 'py-name-marking-modal',
  templateUrl: './name-marking-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NameMarkingModalComponent extends BaseAbstractModalComponent implements OnInit, OnDestroy {
  articleTitle: string = '';
  inEcommerceQuantity: number;
  names: string[];
  formGroup: UntypedFormGroup;
  subscriptions = new Subscription();

  get nameControls(): AbstractControl[] {
    const controls = (this.formGroup.controls['names'] as UntypedFormArray).controls;
    if (this.formGroup.get('sameNameOnAll').value) {
      return [controls[0]];
    }
    return controls;
  }

  get sameNameOnAllInitialValue(): boolean {
    return this.names.length === 1 && this.inEcommerceQuantity > 1 ? true : false;
  }

  constructor(
    private fb: UntypedFormBuilder,
    protected launchDialogService: LaunchDialogService,
    protected el: ElementRef,
    protected renderer: Renderer2
  ) {
    super(el, launchDialogService, renderer);
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.launchDialogService.data$.pipe(take(1)).subscribe((data: NameMarkingModalData) => {
        this.articleTitle = data.articleTitle;
        this.inEcommerceQuantity = data.inEcommerceQuantity;
        this.names = data.names;

        this.formGroup = this.fb.group({
          sameNameOnAll: [this.sameNameOnAllInitialValue],
          names: this.createFromGroupNamesFields(),
        });
      })
    );

    this.subscriptions.add(
      this.formGroup.get('sameNameOnAll').valueChanges.subscribe((value) => {
        const controls = (this.formGroup.get('names') as UntypedFormArray).controls;
        const validators = this.getNameValidators(!value);

        controls.map((nameControl, index) => {
          const name = nameControl.get('name');
          name.setValidators(index === 0 ? this.getNameValidators(true) : validators);
          name.updateValueAndValidity();
        });
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  createFromGroupNamesFields(): UntypedFormArray {
    const validators = this.getNameValidators(!this.sameNameOnAllInitialValue);

    const nameForms = new Array(this.inEcommerceQuantity).fill(undefined).map((_val, index) => {
      return this.fb.group({
        name: [this.names[index] || '', index === 0 ? this.getNameValidators(true) : validators],
      });
    });

    return this.fb.array(nameForms);
  }

  getNameValidators(value: boolean): ValidatorFn[] {
    return value ? [Validators.required, Validators.maxLength(60)] : [Validators.maxLength(60)];
  }

  save(): void {
    let values: string[] = this.formGroup.value.names
      .map((value: { name: string }) => value.name)
      .filter((value: string) => !!value);

    const firstName = values[0];

    if (this.formGroup.value.sameNameOnAll && firstName) {
      values = [firstName];
    }

    const reasonWithPayload: NameMarkingModalCloseReason = {
      reason: ModalCloseReason.NAME_MARKING_SAVED,
      payload: values,
    };

    this.launchDialogService.closeDialog(reasonWithPayload);
  }
}
