import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InterceptorUtil, OCC_USER_ID_ANONYMOUS, OccEndpointsService, USE_CLIENT_TOKEN } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlternativeArticles, Article, Substitutes } from '../../../model';
import { Occ } from '../../../occ';
import { ConverterService } from '../../../util/converter.service';
import { ARTICLE_NORMALIZER, SIMILAR_ARTICLES_NORMALIZER, SUBSTITUTES_NORMALIZER } from './converters';

@Injectable({
  providedIn: 'root',
})
export class ArticleConnector {
  constructor(
    private converterService: ConverterService,
    private httpClient: HttpClient,
    private occEndpointsService: OccEndpointsService
  ) {}

  loadArticles(userId: string, codes: Array<string>): Observable<Array<Article>> {
    let headers = new HttpHeaders();
    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }
    return this.httpClient
      .get<Occ.Article[]>(
        this.occEndpointsService.buildUrl('articles', {
          urlParams: { userId: userId },
          queryParams: { codes: codes.join(',') },
        }),
        {
          headers,
        }
      )
      .pipe(
        map((data) => data['articles']),
        this.converterService.pipeableMany(ARTICLE_NORMALIZER)
      );
  }

  loadSimilarArticles(userId: string, code: string): Observable<AlternativeArticles> {
    let headers = new HttpHeaders();
    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }
    return this.httpClient
      .get<Occ.AlternativeArticles>(
        this.occEndpointsService.buildUrl('similarArticles', {
          urlParams: {
            userId: userId,
            articleNumber: code,
          },
        }),
        {
          headers,
        }
      )
      .pipe(this.converterService.pipeable(SIMILAR_ARTICLES_NORMALIZER));
  }

  loadSubstitutesArticles(userId: string, articleCode: string): Observable<Substitutes> {
    let headers = new HttpHeaders();
    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }

    return this.httpClient
      .get<Occ.Substitutes>(
        this.occEndpointsService.buildUrl('substitutesArticles', {
          urlParams: {
            userId: userId,
            articleCode: articleCode,
          },
        }),
        { headers }
      )
      .pipe(this.converterService.pipeable(SUBSTITUTES_NORMALIZER));
  }
}
