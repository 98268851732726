import { Action } from '@ngrx/store';
import { PriceFileRequestTemplateSearchResult } from '../../../../model/price-file.model';
import {
  DeletePriceFileRequestTemplateSuccess,
  LoadPriceFileRequestTemplateSuccess,
  LoadPriceFileRequestTemplatesSuccess,
  PriceFileRequestTemplateActionTypes,
  SavePriceFileRequestTemplateSuccess,
} from '../actions/price-file-request-template.actions';

export const initialState: any = {
  lastUpdateTime: undefined,
  results: undefined,
};

export function reducer(state = initialState, action: Action): any {
  switch (action.type) {
    case PriceFileRequestTemplateActionTypes.LoadPriceFileRequestTemplatesSuccess: {
      const successAction = <LoadPriceFileRequestTemplatesSuccess>action;
      const payload: PriceFileRequestTemplateSearchResult = successAction.payload;
      return {
        lastUpdateTime: new Date().toISOString(),
        results: state.results ? [...state.results, ...payload.results] : payload.results,
      };
    }
    case PriceFileRequestTemplateActionTypes.SavePriceFileRequestTemplateSuccess: {
      const successAction = <SavePriceFileRequestTemplateSuccess>action;
      return {
        ...state,
        results: state.results
          ? state.results.filter((res) => res.code === successAction.payload.code).length > 0
            ? [...state.results.map((item) => (item.code === successAction.payload.code ? successAction.payload : item))]
            : [...state.results, successAction.payload]
          : [successAction.payload],
      };
    }
    case PriceFileRequestTemplateActionTypes.LoadPriceFileRequestTemplateSuccess: {
      const successAction = <LoadPriceFileRequestTemplateSuccess>action;
      return {
        ...state,
        results: state.results
          ? [...state.results.map((item) => (item.code === successAction.payload.code ? successAction.payload : item))]
          : [successAction.payload],
      };
    }
    case PriceFileRequestTemplateActionTypes.DeletePriceFileRequestTemplateSuccess: {
      const successAction = <DeletePriceFileRequestTemplateSuccess>action;
      const id = [].concat(successAction.meta.entityId).join();
      return {
        ...state,
        results: state.results ? [...state.results.filter((res) => res.code !== id)] : [],
      };
    }
    default: {
      return state;
    }
  }
}
