<div class="switch">
  <label>
    <ng-container *ngIf="!!leftLabel">{{ leftLabel }}</ng-container>
    <ng-content select="input[type=checkbox]"></ng-content>
    <span class="lever" [ngClass]="{ 'add-margin': addMargin, 'right-margin': rightMargin, 'center-label': centerLabel }"></span>
    <ng-container *ngIf="!!rightLabel">
      {{ rightLabel }}
    </ng-container>
  </label>
  <i *ngIf="!!tooltip" nz-icon nzType="question-circle" [ngbTooltip]="tooltip" class="ml-2"></i>
</div>
