import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { EventService } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { delay, filter, switchMap, tap } from 'rxjs/operators';
import { UserIdService } from '../../../core/auth';
import { CartType, FullPalletUpgrade } from '../../../core/model';
import { ActiveCartFacade, FullPalletUpgradeFacade, UpdateCartEntryFailEvent } from '../../../features/cart/base';
import { CheckoutFacade } from '../../../features/checkout/base';
import { matchEntry } from '../../utils';
import { OrderEntryComponentEntry } from '../order-entry/order-entry.component';

@Component({
  selector: 'py-order-entry-full-pallet-upgrade-wrapper',
  templateUrl: './order-entry-full-pallet-upgrade-wrapper.component.html',
})
export class OrderEntryFullPalletUpgradeWrapperComponent implements OnInit, OnDestroy {
  @Input() entry: OrderEntryComponentEntry;
  @Input() cartType: CartType;
  @Output() fullPalletUpgraded = new EventEmitter<void>();

  isAlreadyUpdated = false;
  isUpdatePending = false;
  isSkipped = false;
  fullPalletUpgrade: FullPalletUpgrade = null;

  private readonly timeToSuccessNotificationDissapearInMs = 8000;
  private subscription = new Subscription();

  constructor(
    private activeCartService: ActiveCartFacade,
    private eventService: EventService,
    private fullPalletUpgradeService: FullPalletUpgradeFacade,
    private userIdService: UserIdService,
    private checkoutService: CheckoutFacade,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.checkIfAlreadyUpdated();
    this.listenToUpdateEvents();
    this.loadFullPalletUpgrade();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onPalletUpgrade(): void {
    if (this.isUpdatePending) {
      return;
    }

    this.isUpdatePending = true;
    this.activeCartService.updateEntry(this.entry, this.fullPalletUpgrade.fullPalletQuantity, this.entry.unit, this.cartType);

    this.fullPalletUpgraded.emit();

    this.checkoutService.setLastFullPalletUpgrade(this.fullPalletUpgrade.remainingQuantity, this.entry, this.cartType);
  }

  onPalletUpgradeSkip(): void {
    this.isSkipped = true;
  }

  private checkIfAlreadyUpdated(): void {
    this.subscription.add(
      this.checkoutService
        .getLastFullPalletUpgrade(this.cartType)
        .pipe(
          filter(() => !this.isUpdatePending),
          filter((entry) => matchEntry(entry?.entry, this.entry)),
          tap((entry) => {
            this.fullPalletUpgrade = { remainingQuantity: entry.quantityAdded };
            this.isAlreadyUpdated = true;
          }),
          delay(this.timeToSuccessNotificationDissapearInMs)
        )
        .subscribe(() => {
          this.checkoutService.resetLastFullPalletUpgrade(this.cartType);
          this.isSkipped = true;
          this.cd.detectChanges();
        })
    );
  }

  private loadFullPalletUpgrade(): void {
    if (this.isAlreadyUpdated) {
      return;
    }

    this.subscription.add(
      this.userIdService
        .takeUserId(true)
        .pipe(
          switchMap((userId) => this.fullPalletUpgradeService.load(userId, this.cartType, this.entry.entryNumber?.toString()))
        )
        .subscribe((fullPalletUpgrade) => {
          this.fullPalletUpgrade = fullPalletUpgrade;
          this.cd.detectChanges();
        })
    );
  }

  private listenToUpdateEvents(): void {
    this.subscription.add(
      this.eventService.get(UpdateCartEntryFailEvent).subscribe((payload) => {
        if (Number(payload.entry?.entryNumber) === this.entry.entryNumber) {
          this.checkoutService.resetLastFullPalletUpgrade(this.cartType);
          this.isUpdatePending = false;
        }
      })
    );
  }
}
