import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Subject, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Article, CartType, CuttingOptions, CuttingRequest, GrainDirectionType, OrderEntry } from '../../../../core/model';
import { CuttingFacade } from '../../../../features/cart/base';
import { CuttingModalData } from '../cutting-modal/cutting-modal.component';

@Component({
  selector: 'py-cutting-button',
  templateUrl: './cutting-button.component.html',
  styleUrls: ['./cutting-button.component.scss'],
})
export class CuttingButtonComponent implements OnInit, OnDestroy {
  @Input() article: Article;
  @Input() entry: OrderEntry;
  @Input() disabled: boolean = false;
  @Input() showPrice: boolean = false;
  @Input() showButton: boolean = false;
  @Input() showDustFree: boolean = true;
  @Input() showPerpendicular: boolean = true;
  @Input() translationKeyPrefix: string = 'cart';

  @Output() handleSave = new EventEmitter<CuttingRequest>();
  @Output() handleRemove = new EventEmitter<void>();

  private cuttingOptions: CuttingOptions;

  subscriptions = new Subscription();

  constructor(private cuttingService: CuttingFacade, private launchDialogService: LaunchDialogService) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.cuttingService
        .getOptions(CartType.stock)
        .pipe(filter((options) => Boolean(options?.packaging)))
        .subscribe((options) => {
          this.cuttingOptions = options;
        })
    );
  }

  get packagingTranslation(): string {
    const packaging = this.entry?.cutting?.packaging;
    const cuttingPackagingOptions = this.cuttingOptions?.packaging?.options;

    if (!packaging || !cuttingPackagingOptions) {
      return '';
    }

    const currentOption = cuttingPackagingOptions.find((option) => option.code === this.entry.cutting.packaging);

    return currentOption ? `${this.translationKeyPrefix}.${currentOption.translationKey}_hint` : '';
  }

  get grainDirection() {
    if (!this.article?.classificationAttributes || !this.entry?.cutting) {
      return '';
    }
    const motherGrainDirection = this.article.classificationAttributes['ZZLFR']?.rawValue as GrainDirectionType;
    switch (this.entry.cutting.grainDirection) {
      case '0':
        return motherGrainDirection === GrainDirectionType.LONG_GRAIN
          ? GrainDirectionType.SHORT_GRAIN
          : GrainDirectionType.LONG_GRAIN;
      case '1':
        return motherGrainDirection;
      default:
        return GrainDirectionType.ANY;
    }
  }

  async open(): Promise<void> {
    const modalSaved$ = new Subject<CuttingRequest>();
    const modalRemoved$ = new Subject<void>();

    const modalData: CuttingModalData = {
      article: this.article,
      entry: this.entry,
      modalSaved$: modalSaved$,
      modalRemoved$: modalRemoved$,
    };

    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.CUTTING, undefined, modalData);

    this.subscriptions.add(
      modalSaved$
        .pipe(
          filter((cuttingRequest) => cuttingRequest !== undefined),
          take(1)
        )
        .subscribe((cuttingRequest) => {
          this.handleSave.emit(cuttingRequest);
        })
    );

    this.subscriptions.add(
      modalRemoved$.pipe(take(1)).subscribe(() => {
        this.handleRemove.emit();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
