<ng-container
  *ngIf="{
    availableSoldTos: availableSoldTos$ | async,
    availableSoldTosLoading: availableSoldTosLoading$ | async,
    availableSearchResultEntitiesCount: availableSearchResultEntitiesCount$ | async
  } as data"
>
  <div
    ngbDropdown
    #dropdown="ngbDropdown"
    class="p-0"
    (openChange)="dropdownOpenChange($event)"
    [placement]="position"
    *ngIf="data.availableSearchResultEntitiesCount > 1"
  >
    <div class="overlay d-lg-none" [class.show]="dropdown.isOpen()"></div>
    <button class="link sold-to-selector-button" [class.px-2]="type === 'change-sold-to'" type="button" ngbDropdownToggle>
      {{ label }}
      <i nz-icon [nzType]="dropdown.isOpen() ? 'up' : 'down'" nzTheme="outline" class="ml-2"></i>
    </button>
    <div ngbDropdownMenu aria-labelledby="sold-to" class="pt-2 sold-to-selector-dropdown">
      <div class="d-flex align-items-center justify-content-between p-3 sold-to-selector-dropdown-header">
        <span class="">{{ label }}</span>
        <button type="button" (click)="$event.stopPropagation(); dropdown.close()">
          <i nz-icon nzType="close" nzTheme="outline"></i>
        </button>
      </div>
      <div class="p-2">
        <form [formGroup]="form">
          <input
            type="text"
            class="form-control"
            [formControlName]="'text'"
            [placeholder]="'common.search_placeholder' | cxTranslate"
            [size]="24"
          />
        </form>
      </div>
      <ng-container [ngSwitch]="type">
        <ul
          *ngSwitchCase="'select-sold-to'"
          infiniteScroll
          [alwaysCallback]="true"
          (scrolled)="fetchMoreSoldTos()"
          [scrollWindow]="false"
          class="select-sold-to"
        >
          <li
            *ngFor="let availableSoldTo of data.availableSoldTos"
            class="p-3"
            [attr.active]="soldTo === availableSoldTo.uid ? 'true' : null"
            (click)="changeSoldTo(availableSoldTo)"
          >
            <div>{{ availableSoldTo.name }}</div>
            <span *ngIf="availableSoldTo?.uid">{{ availableSoldTo?.uid }}</span>
          </li>
        </ul>
        <ul
          *ngSwitchDefault
          infiniteScroll
          [alwaysCallback]="true"
          (scrolled)="fetchMoreSoldTos()"
          [scrollWindow]="false"
          class="change-sold-to"
        >
          <li
            *ngFor="let availableSoldTo of data.availableSoldTos"
            class="p-3"
            [attr.active]="soldTo === availableSoldTo.uid ? 'true' : null"
            (click)="changeSoldTo(availableSoldTo)"
          >
            <div>{{ availableSoldTo.uid }} - {{ availableSoldTo.name }}</div>
            <ng-container
              *ngIf="{
                address:
                  ('enableDisplayDefaultShipToAsSoldToAddress' | principalConfigValue)
                    ? availableSoldTo?.shipTo
                    : availableSoldTo?.customerAddress
              } as soldToData"
            >
              <span *ngIf="soldToData?.address?.computedDescription">{{ soldToData.address.computedDescription }}</span>
            </ng-container>
          </li>
        </ul>
      </ng-container>
      <div class="py-2 text-center" *ngIf="data.availableSoldTosLoading">
        <cx-spinner></cx-spinner>
      </div>
    </div>
  </div>
</ng-container>
