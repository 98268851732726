<div
  *ngIf="!isNotification; else notificationBadgeTemplate"
  [class]="(type | lowercase) || ''"
  [ngClass]="{
    'show-circle': showCircle && !tag,
    loading: loading,
    tag: tag,
    large: large,
    xlarge: xlarge,
    selected: selected,
    disabled: disabled,
    selectable: selectable
  }"
>
  <cx-spinner *ngIf="loading" [type]="spinnerType.Dark"></cx-spinner>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>

<ng-template #notificationBadgeTemplate>
  <div class="notification-badge-container" [class]="position" [ngClass]="{ static: static }">
    <div class="notification-count-badge" [ngClass]="(type | lowercase) || ''">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
