<py-modal
  [actions]="templateActions"
  [showCancelButton]="true"
  [cancelButtonText]="'common.cancel_action' | cxTranslate"
  [title]="'cart.prePrintedLabel.prePrintedLabelModal_title' | cxTranslate"
  [subtitle]="articleTitle | ellipsis"
  [scrollable]="true"
  [centered]="true"
  [size]="'sm'"
  [cxFocus]="focusConfig"
  (esc)="closeModal('escape pressed')"
>
  <py-notification [type]="notificationType" class="mb-2">
    {{ 'cart.prePrintedLabel.information_text' | cxTranslate }}
  </py-notification>
  <div class="d-flex justify-content-center pb-4" *ngIf="loadingOptions$ | async; else formTemplate">
    <cx-spinner></cx-spinner>
  </div>

  <ng-template #formTemplate>
    <form [formGroup]="formGroup" class="d-flex pt-2 flex-column">
      <label class="input-label-content mb-2">
        {{ 'cart.prePrintedLabel.print_heading' | cxTranslate }}
      </label>
      <py-reactive-form-input-field
        *ngIf="showSearchField"
        [group]="formGroup"
        [fieldName]="'search'"
        [placeholder]="'common.search_placeholder' | cxTranslate"
        [icon]="'search'"
        class="search-input"
      ></py-reactive-form-input-field>
      <ul class="scroll-wrapper mb-3 border" pyScrollState>
        <li
          class="label-item"
          *ngFor="let option of options$ | async"
          [class.active]="option?.code === codeControl?.value"
          (click)="codeControl.setValue(option.code)"
        >
          <py-radio [showLabel]="true">
            <input type="radio" formControlName="code" name="code" [value]="option?.code" />
            <span slot="label">{{ option.label }}</span>
          </py-radio>
        </li>
        <div class="w-100 footer-shadow"></div>
      </ul>
      <div class="mb-1" *ngIf="showPartNumberField">
        <py-reactive-form-input-field
          [group]="formGroup"
          [inputType]="'number'"
          [fieldName]="'partNumber'"
          [label]="'cart.prePrintedLabel.partNumber_heading' | cxTranslate"
          [placeholder]="'cart.prePrintedLabel.partNumber_placeholder' | cxTranslate"
          class="search-input"
        ></py-reactive-form-input-field>
      </div>
      <div class="mb-1" *ngIf="showInnerPackageQuantity$ | async">
        <py-reactive-form-input-field
          [group]="formGroup"
          [inputType]="'number'"
          [fieldName]="'innerPackageQuantity'"
          [label]="'cart.prePrintedLabel.innerPackageQuantity_heading' | cxTranslate"
          [placeholder]="'cart.prePrintedLabel.innerPackageQuantity_placeholder' | cxTranslate"
          class="search-input"
        ></py-reactive-form-input-field>
      </div>
    </form>
  </ng-template>
</py-modal>

<ng-template #templateActions>
  <py-action-button
    class="d-flex"
    [type]="'button'"
    [buttonClass]="'btn btn-ternary w-100 w-md-auto'"
    [buttonLabel]="'common.delete_action' | cxTranslate"
    (click)="deleteLabel()"
    [disabled]="!entry?.prePrintedLabel?.code"
    [loading]="loadingDelete$ | async"
  >
  </py-action-button>
  <py-action-button
    class="d-flex"
    [type]="'button'"
    [buttonClass]="'btn btn-primary w-100 w-md-auto'"
    [buttonLabel]="'common.save_action' | cxTranslate"
    (click)="save()"
    [disabled]="!formGroup.valid"
    [loading]="loadingSave$ | async"
  >
  </py-action-button>
</ng-template>
