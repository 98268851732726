import { Injectable, inject, isDevMode } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  AuthService,
  AnonymousConsentsActions as CxAnonymousConsentsActions,
  UserActions as CxUserActions,
  LoggerService,
} from '@spartacus/core';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthActions, UserIdService } from '../../../auth/index';
import { LoginLogoutActionTypes } from '../../../auth/user-auth/store/actions/login-logout.action';
import { LanguagesActionTypes } from '../../../site-context/store/actions/languages.action';
import { UserConsentFacade } from '../../../user/facades';
import { UserUtilService } from '../../../user/services/user-util.service';
import { normalizeHttpError } from '../../../util/normalize-http-error';
import { withdrawOn } from '../../../util/rxjs';
import { ConsentsConfig } from '../../config/consents-config';
import { AnonymousConsentTemplatesConnector } from '../../connectors/anonymous-consent-templates.connector';
import { AnonymousConsentsService } from '../../facade/index';

@Injectable()
export class AnonymousConsentsEffects {
  private contextChange$ = this.actions$.pipe(ofType(LanguagesActionTypes.LanguageChange));
  protected logger = inject(LoggerService);

  checkConsentVersions$: Observable<
    | CxAnonymousConsentsActions.LoadAnonymousConsentTemplates
    | CxAnonymousConsentsActions.LoadAnonymousConsentTemplatesFail
    | Observable<never>
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(CxAnonymousConsentsActions.ANONYMOUS_CONSENT_CHECK_UPDATED_VERSIONS),
      withLatestFrom(this.anonymousConsentService.getConsents()),
      concatMap(([_, currentConsents]) => {
        return this.anonymousConsentTemplatesConnector.loadAnonymousConsents().pipe(
          map((newConsents) => {
            if (!newConsents) {
              if (isDevMode()) {
                this.logger.warn('No consents were loaded. This could be a back-end configuration issue.');
              }
              return false;
            }

            const currentConsentVersions =
              currentConsents &&
              currentConsents
                .filter((template) => this.consentsConfig.consents.anonymous.consents.includes(template.templateCode))
                .map((consent) => consent.templateVersion);
            const newConsentVersions =
              newConsents &&
              newConsents
                .filter((template) => this.consentsConfig.consents.anonymous.consents.includes(template.templateCode))
                .map((consent) => consent.templateVersion);

            return this.detectUpdatedVersion(currentConsentVersions, newConsentVersions);
          }),
          switchMap((updated) => (updated ? of(new CxAnonymousConsentsActions.LoadAnonymousConsentTemplates()) : EMPTY)),
          catchError((error) => of(new CxAnonymousConsentsActions.LoadAnonymousConsentTemplatesFail(normalizeHttpError(error))))
        );
      }),
      withdrawOn(this.contextChange$)
    )
  );

  loadAnonymousConsentTemplates$: Observable<CxAnonymousConsentsActions.AnonymousConsentsActions> = createEffect(() =>
    this.actions$.pipe(
      ofType(CxAnonymousConsentsActions.LOAD_ANONYMOUS_CONSENT_TEMPLATES),
      withLatestFrom(this.anonymousConsentService.getTemplates()),
      concatMap(([_, currentConsentTemplates]) =>
        this.anonymousConsentTemplatesConnector.loadAnonymousConsentTemplates().pipe(
          mergeMap((newConsentTemplates) => {
            let updated = false;
            if (newConsentTemplates && newConsentTemplates.length !== 0 && currentConsentTemplates) {
              const filteredNewConsentTemplates = newConsentTemplates.filter((template) =>
                this.consentsConfig.consents.anonymous.consents.includes(template.id)
              );
              updated = this.anonymousConsentService.detectUpdatedTemplates(currentConsentTemplates, filteredNewConsentTemplates);
            }

            return [
              new CxAnonymousConsentsActions.LoadAnonymousConsentTemplatesSuccess(newConsentTemplates),
              new CxAnonymousConsentsActions.ToggleAnonymousConsentTemplatesUpdated(updated),
            ];
          }),
          catchError((error) => of(new CxAnonymousConsentsActions.LoadAnonymousConsentTemplatesFail(normalizeHttpError(error))))
        )
      ),
      withdrawOn(this.contextChange$)
    )
  );

  giveRequiredConsentsToUser$: Observable<CxUserActions.GiveUserConsent | Observable<never>> = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthActions.Login>(LoginLogoutActionTypes.Login),
      filter(
        (action) =>
          Boolean(this.consentsConfig.consents) && Boolean(this.consentsConfig.consents.user.requiredConsents) && Boolean(action)
      ),
      switchMap(() =>
        this.userConsentService.getConsentsResultSuccess().pipe(
          withLatestFrom(
            this.userIdService.getUserId(),
            this.userConsentService.getConsents(),
            this.authService.isUserLoggedIn()
          ),
          filter(([, , , loggedIn]) => loggedIn),
          tap(([loaded, _userId, _templates, _loggedIn]) => {
            if (!loaded) {
              this.userConsentService.loadConsents();
            }
          }),
          map(([_loaded, userId, templates, _loggedIn]) => {
            return { userId, templates };
          }),
          concatMap(({ userId, templates }) => {
            if (templates) {
              const actions: CxUserActions.GiveUserConsent[] = [];
              for (const template of templates) {
                if (
                  this.userUtilService.isConsentWithdrawn(template.currentConsent) &&
                  this.consentsConfig.consents.user.transferredConsents.includes(template.id)
                ) {
                  actions.push(
                    new CxUserActions.GiveUserConsent({
                      userId,
                      consentTemplateId: template.id,
                      consentTemplateVersion: template.version,
                    })
                  );
                }
              }
              if (actions.length > 0) {
                return actions;
              }
            }
            return EMPTY;
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private anonymousConsentTemplatesConnector: AnonymousConsentTemplatesConnector,
    private authService: AuthService,
    private consentsConfig: ConsentsConfig,
    private anonymousConsentService: AnonymousConsentsService,
    private userConsentService: UserConsentFacade,
    private userUtilService: UserUtilService,
    private userIdService: UserIdService
  ) {}

  /**
   * Compares the given versions and determines if there's a mismatch,
   * in which case `true` is returned.
   *
   * @param currentVersions versions of the current consents
   * @param newVersions versions of the new consents
   */
  private detectUpdatedVersion(currentVersions: number[], newVersions: number[]): boolean {
    if (currentVersions.length !== newVersions.length) {
      return true;
    }

    for (let i = 0; i < newVersions.length; i++) {
      if (currentVersions[i] !== newVersions[i]) {
        return true;
      }
    }

    return false;
  }
}
