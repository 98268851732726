import { Injectable } from '@angular/core';
import { CxEvent, WindowRef } from '@spartacus/core';
import { TmsCollector, TmsCollectorConfig, WindowObject } from '@spartacus/tracking/tms/core';
import { map, take } from 'rxjs/operators';
import { AnalyticsMetadataFacade } from '../../../core/user';
import { GoogleTagManager } from '../models';
import { GtmEventMapper } from './gtm-event.mapper';
import { GtmEventUtils } from './gtm-event.utils';

/**
 * Google Tag Manager collector.
 *
 * Serverside must supply serverContainerUrl
 */
@Injectable({ providedIn: 'root' })
export class GtmCollectorService implements TmsCollector {
  constructor(protected winRef: WindowRef, private metadataService: AnalyticsMetadataFacade) {}

  /**
   * If the `TmsCollectorConfig.dataLayerProperty` is not specified, it uses the default `dataLayer`
   */
  init(config: TmsCollectorConfig, windowObject: WindowObject): void {
    this.setupDefaultConsent(config, windowObject);

    this.metadataService
      .getAnalyticsMetadata()
      .pipe(
        take(1),
        map((meta) => meta?.googleTagManagers)
      )
      .subscribe((googleTagManagers: GoogleTagManager[]) => {
        googleTagManagers
          ?.filter((gtmConfig) => gtmConfig.containerId && gtmConfig.serverContainerUrl)
          .forEach((gtmConfig) => {
            this.loadGtm(config, windowObject, gtmConfig);
          });
      });
  }

  private setupDefaultConsent(config: TmsCollectorConfig, windowObject: WindowObject) {
    this.pushEvent(
      config,
      windowObject,
      GtmEventUtils.fromGtag('consent', 'default', {
        ad_storage: 'denied', // Enables storage (such as cookies) related to advertising
        analytics_storage: 'denied', // Enables storage (such as cookies) related to analytics e.g. visit duration
        functionality_storage: 'granted', // Enables storage that supports the functionality of the website or app e.g. language settings
        personalization_storage: 'denied', // Enables storage related to personalization e.g. video recommendations
        security_storage: 'granted', // Enables storage related to security such as authentication functionality, fraud prevention, and other user protection
        ad_user_data: 'denied', // Sets consent for sending user data to Google for advertising purposes.
        ad_personalization: 'denied', // Sets consent for personalized advertising.
        wait_for_update: 500,
      })
    );
  }

  private loadGtm(config: TmsCollectorConfig, windowObject: WindowObject, gtmConfig: GoogleTagManager) {
    const dataLayerProperty = config.dataLayerProperty || 'dataLayer';
    windowObject[dataLayerProperty] = windowObject[dataLayerProperty] || [];
    (function (w: WindowObject, d: Document, s: string, l: string, c: GoogleTagManager) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      const f = d.getElementsByTagName(s)[0];
      const j = d.createElement(s) as HTMLScriptElement;
      const dl = l !== 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = c.serverContainerUrl + '/gtm.js?id=' + c.containerId + dl;
      f.parentNode?.insertBefore(j, f);
    })(windowObject, this.winRef.document, 'script', dataLayerProperty, gtmConfig);
  }

  pushEvent<T extends CxEvent>(config: TmsCollectorConfig, windowObject: WindowObject, event: T | any): void {
    if (!event) {
      return;
    }

    const dataLayerProperty = config.dataLayerProperty ?? 'dataLayer';
    windowObject[dataLayerProperty] = windowObject[dataLayerProperty] || [];

    if (event?.ecommerce) {
      // Clear the previous ecommerce event
      windowObject[dataLayerProperty].push({ ecommerce: null });
    }

    windowObject[dataLayerProperty].push(event);
  }

  map<T extends CxEvent>(event: T | any): object | T {
    if (!event) {
      return;
    }

    const gtmEvent = GtmEventMapper.mapEvent(event) || event;
    return gtmEvent;
  }
}
