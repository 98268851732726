<div class="wrapper" *ngIf="showButton; else textTemplate">
  <div class="label" [class.disabled]="disabled" *ngIf="!!entry?.cutting">
    {{ translationKeyPrefix + '.cutting.cuttingButton_heading' | cxTranslate }}
  </div>

  <span
    [ngbTooltip]="translationKeyPrefix + '.cutting.bothCuttingAndReaming_tooltip' | cxTranslate"
    [disableTooltip]="!entry?.reaming?.reamed"
  >
    <button
      (click)="open()"
      [disabled]="disabled"
      class="add-or-edit-button px-2"
      [ngClass]="{
        add: !entry?.cutting,
        edit: !!entry?.cutting,
        'px-2': !entry?.cutting,
        'p-2': !!entry?.cutting
      }"
    >
      <i nz-icon nzType="scissor" nzTheme="outline" class="mr-2" [class.mt-1]="!!entry?.cutting"></i>
      <div class="d-flex flex-column w-100">
        <div class="d-flex flex-row justify-content-between w-100">
          <ng-container [ngTemplateOutlet]="buttonLabel"></ng-container>

          <div
            class="icon-holder edit"
            *ngIf="!disabled && !!entry?.cutting"
            [ngbTooltip]="translationKeyPrefix + '.cutting.editCutting_tooltip' | cxTranslate"
          >
            <i nz-icon nzType="edit" nzTheme="outline"></i>
          </div>
        </div>
        <ng-container [ngTemplateOutlet]="cuttingDataTemplate"></ng-container>
      </div>
    </button>
  </span>
</div>

<ng-template #textTemplate>
  <div class="text-wrapper d-flex align-items-start" *ngIf="!!entry?.cutting">
    <i nz-icon nzType="scissor" nzTheme="outline" class="mx-2"></i>
    <div class="d-flex flex-column w-100">
      <span class="text-label">{{ translationKeyPrefix + '.cutting.cuttingButton_heading' | cxTranslate }}</span>
      <ng-container [ngTemplateOutlet]="buttonValueLabel"></ng-container>
      <ng-container [ngTemplateOutlet]="cuttingDataTemplate"></ng-container>
    </div></div
></ng-template>

<ng-template #cuttingDataTemplate>
  <ul *ngIf="!!entry?.cutting" class="mt-1">
    <li class="d-flex justify-content-between align-items-center">
      <span>{{ translationKeyPrefix + '.cutting.numberOfCuts_heading' | cxTranslate }}</span>
      <span>{{ (entry.cutting?.numberOfCuts | cxNumeric : '1.0-0') || '-' }} </span>
    </li>
    <li *ngIf="!showButton" class="d-flex justify-content-between align-items-center">
      <span>{{ translationKeyPrefix + '.cutting.numberOfDaughters_heading' | cxTranslate }}</span>
      <span
        >{{ (entry.cutting?.numberOfDaughters | cxNumeric : '1.0-0') || '-' }}
        <ng-container *ngIf="entry.cutting.numberOfDaughters && entry.cutting?.resultingQuantity?.unit">
          / {{ entry.cutting?.resultingQuantity?.unit | unit : 1 }}
        </ng-container>
      </span>
    </li>
    <li *ngIf="!showButton" class="d-flex justify-content-between align-items-center">
      <span>{{ translationKeyPrefix + '.cutting.totalNumberOfDaughters_heading' | cxTranslate }}</span>
      <span
        >{{ (entry.cutting?.resultingQuantity?.value | cxNumeric : '1.0-0') || '-' }}
        <ng-container *ngIf="entry.cutting?.resultingQuantity?.value">
          {{ entry.cutting?.resultingQuantity?.unit | unit : entry.cutting?.resultingQuantity?.value }}
        </ng-container>
      </span>
    </li>
    <li class="d-flex justify-content-between align-items-center" *ngIf="showDustFree">
      <span>{{ translationKeyPrefix + '.cutting.dustFree_heading' | cxTranslate }}</span>
      <span>{{ ((entry.cutting?.dustFree ? 'common.yes_hint' : 'common.no_hint') | cxTranslate) || '-' }}</span>
    </li>
    <li class="d-flex justify-content-between align-items-center" *ngIf="showPerpendicular">
      <span>{{ translationKeyPrefix + '.cutting.perpendicular_heading' | cxTranslate }}</span>
      <span>{{ ((entry.cutting?.perpendicular ? 'common.yes_hint' : 'common.no_hint') | cxTranslate) || '-' }}</span>
    </li>
    <li class="d-flex justify-content-between align-items-center">
      <span>{{ translationKeyPrefix + '.cutting.packaging_heading' | cxTranslate }}</span>
      <span>{{ (packagingTranslation | cxTranslate) || '-' }}</span>
    </li>
    <ng-container *pyPrincipalConfiguration="'enableNetPrices'">
      <li *ngIf="!showButton && showPrice" class="d-flex justify-content-between align-items-center mb-2">
        <span>{{ translationKeyPrefix + '.cutting.calculatedCuttingCost_heading' | cxTranslate }}</span>
        <span>{{ (entry.cutting?.cuttingPrice | price : 'symbol-narrow' : true) || '-' }}</span>
      </li>
      <li *ngIf="!showButton && showPrice" class="d-flex justify-content-between align-items-center">
        <span>{{ translationKeyPrefix + '.cutting.packagingCost_heading' | cxTranslate }}</span>
        <span>{{ (entry.cutting?.packagingPrice | price : 'symbol-narrow' : true) || '-' }}</span>
      </li>
    </ng-container>
  </ul>
</ng-template>

<ng-template #buttonLabel>
  <span *ngIf="!entry.cutting; else buttonValueLabel">
    {{ translationKeyPrefix + '.cutting.addCutting_heading' | cxTranslate }}
  </span>
</ng-template>

<ng-template #buttonValueLabel>
  <span
    class="text-left mt-1"
    [innerHTML]="
      translationKeyPrefix + '.cutting.cuttingValue_heading'
        | cxTranslate
          : {
              width: entry.cutting.width,
              length: entry.cutting.length,
              grainDirection:
                translationKeyPrefix + '.cutting.grainDirection'
                | cxTranslate : { context: grainDirection.toLowerCase() + '_hint' }
            }
    "
  ></span>
</ng-template>
