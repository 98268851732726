import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { AddressSearchParams, DeliveryAddress, Pagination } from '../../../core/model';
import { AvailableSoldTosSearchParams, SoldTo } from '../../../core/model/user.model';
import { SOLD_TO_CORE_FEATURE } from '../store/sold-to-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: SoldToFacade,
      feature: SOLD_TO_CORE_FEATURE,
      async: true,
      methods: [
        'searchForAvailableSoldTos',
        'getSearchParametersForAvailableSoldTos',
        'updateSearchParametersForAvailableSoldTos',
        'getAvailableSoldTosPagination',
        'getAvailableSoldTosLoading',
        'getAvailableSoldTos',
        'getAvailableSearchResultEntitiesCount',
        'getActiveSoldTo',
        'getActiveSoldToLoading',
        'getActiveSoldToLoaded',
        'clearActiveSoldTo',
        'changeSoldTo',
        'searchForDeliveryAddresses',
        'getSearchParametersForDeliveryAddresses',
        'updateSearchParametersForDeliveryAddresses',
        'getDeliveryAddressPagination',
        'getDeliveryAddressesLoading',
        'getDeliveryAddresses',
        'isCreditBlocked',
      ],
    }),
})
export abstract class SoldToFacade {
  abstract searchForAvailableSoldTos(searchParams: AvailableSoldTosSearchParams): void;

  abstract getSearchParametersForAvailableSoldTos(): Observable<AvailableSoldTosSearchParams>;

  abstract updateSearchParametersForAvailableSoldTos(searchParams: AvailableSoldTosSearchParams): void;

  abstract getAvailableSoldTosPagination(): Observable<Pagination | undefined>;

  abstract getAvailableSoldTosLoading(): Observable<boolean>;

  abstract getAvailableSoldTos(): Observable<Array<SoldTo>>;

  abstract getAvailableSearchResultEntitiesCount(): Observable<number>;

  abstract getActiveSoldTo(forceLoad?: boolean): Observable<SoldTo | undefined>;

  abstract getActiveSoldToLoading(): Observable<boolean>;

  abstract getActiveSoldToLoaded(): Observable<boolean>;

  abstract clearActiveSoldTo(): void;

  abstract changeSoldTo(soldTo: string): void;

  abstract searchForDeliveryAddresses(searchParams: AddressSearchParams): void;

  abstract getSearchParametersForDeliveryAddresses(): Observable<AddressSearchParams>;

  abstract updateSearchParametersForDeliveryAddresses(searchParams: Omit<AddressSearchParams, 'key'>): void;

  abstract getDeliveryAddressPagination(): Observable<Pagination | undefined>;

  abstract getDeliveryAddressesLoading(): Observable<boolean>;

  abstract getDeliveryAddresses(): Observable<Array<DeliveryAddress>>;

  abstract isCreditBlocked(): Observable<boolean>;
}
