import { Injectable } from '@angular/core';
import { EntityMap } from '../../model';
import { Occ } from '../../occ';
import { Converter } from '../../util/converter.service';

@Injectable({ providedIn: 'root' })
export class CatalogKeysNormalizer implements Converter<Occ.Map<string>, EntityMap<string>> {
  convert(source: Occ.Map<string>, target?: EntityMap<string>): EntityMap<string> {
    if (source) {
      return source.value.entry.reduce(
        (current: EntityMap<string>, entry: { key: string; value: string }) => {
          return {
            ...current,
            [entry.key]: entry.value,
          };
        },
        {
          // Always replace since this is loaded once
        }
      );
    }

    return target;
  }
}
