import { StateUtils } from '@spartacus/core';
import { PanelResponse, RecommendationPanel } from '../../../model';
import { RecommendationActionTypes } from '../actions/recommendation.action';

export const initialState: RecommendationPanel | undefined = undefined;

export function reducer(state = initialState, action: StateUtils.LoaderAction): RecommendationPanel {
  switch (action.type) {
    case RecommendationActionTypes.LoadRecommendationSuccess: {
      if (action?.payload?.panels?.length < 1) {
        return state;
      }
      const response: PanelResponse = action.payload;
      const panel1: RecommendationPanel = response.panels[0];

      return {
        ...state,
        ...panel1,
      };
    }
  }

  return state;
}
