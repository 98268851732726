import { Pipe, PipeTransform } from '@angular/core';

interface OptionsParams {
  max?: number; // Maximum number of characters on each side
  showLastString?: boolean; // Decide if last string should be displayed
}

@Pipe({ name: 'ellipsis' })
export class EllipsisPipe implements PipeTransform {
  transform(input?: string, options: OptionsParams = {}): string {
    if (!input) {
      return input;
    }

    const inputLength = input.length;
    const maxLength = options.max || 20;

    if (inputLength - maxLength > maxLength) {
      const showLast = options.showLastString !== undefined ? options.showLastString : true;
      const firstString = input.slice(0, maxLength).trim();
      const lastString = input.slice(inputLength - maxLength).trim();

      return showLast ? `${firstString}...${lastString}` : `${firstString}...`;
    }
    return input;
  }
}
