import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { AbstractReactiveFormInput } from '../abstract-reactive-form-input';

@Component({
  selector: 'py-reactive-form-input-field',
  templateUrl: './reactive-form-input-field.component.html',
  styleUrls: ['./reactive-form-input-field.component.scss'],
})
export class ReactiveFormInputFieldComponent extends AbstractReactiveFormInput implements OnInit {
  private _activeType: string;
  private _originalType: string;

  @HostBinding('class.focused') isFocused = false;

  @Input()
  label: string;

  @Input()
  subtext: string;

  @Input()
  placeholder = '';

  get inputType() {
    return this._activeType || 'text';
  }

  @Input()
  set inputType(type: string) {
    this._activeType = type;
    this._originalType = this._originalType || this._activeType;
  }

  @Input()
  optional?: boolean;

  @Input()
  fieldName: string;

  @Input()
  group: UntypedFormGroup;

  @Input()
  tooltip?: string;

  @Input()
  icon?: string;

  @Input()
  description?: string;

  @Input()
  showOptionalHint? = true;

  @Input()
  includeGroupErrors? = false;

  @Input()
  hideErrorMessages? = false;

  @Input()
  showSuccessState? = false;

  @Input()
  showInstantErrors? = false;

  @Input()
  showFocusState? = true;

  @Input()
  resettable? = false;

  @Input()
  maxLength?: number;

  @Input()
  showMaxLengthHint? = true;

  @Input()
  showTouchedFieldAsInvalid? = false;

  @Output()
  enterEvent = new EventEmitter<any>();

  @Output()
  handleReset = new EventEmitter<void>();

  get originalType() {
    return this._originalType;
  }

  get fieldValueLength(): number {
    return this.getField().value?.length;
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    this._originalType = this.inputType;
  }

  getField(): AbstractControl {
    return this.getGroup().get(this.fieldName);
  }

  getGroup(): UntypedFormGroup {
    return this.group;
  }

  isIncludeGroupErrors(): boolean {
    return this.includeGroupErrors;
  }

  enterPressed($event: any) {
    this.enterEvent.emit($event);
  }

  togglePasswordType() {
    if (this.inputType === 'password') {
      this.inputType = 'text';
    } else {
      this.inputType = 'password';
    }
  }

  /**
   * Checks validity of field and group if group check enabled
   */
  isInvalidForTouchedField(): boolean {
    const field = this.getField();
    return (
      field.touched && ((this.isIncludeGroupErrors() && Object.keys(this.getGroup().errors || {}).length > 0) || field.invalid)
    );
  }
}
