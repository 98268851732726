import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { Pagination } from '../../../core/model';
import { LoadMoreComponent } from '../load-more/load-more.component';

@Component({
  selector: 'py-puff-collection-pagination',
  templateUrl: './puff-collection-pagination.component.html',
  styleUrls: ['./puff-collection-pagination.component.scss'],
  standalone: true,
  imports: [CommonModule, I18nModule, LoadMoreComponent],
})
export class PuffCollectionPaginationComponent implements OnInit {
  @Input()
  puffComponentsArray: string[];

  @Input()
  maxShown: string;

  @Input()
  showPagination: boolean;

  @Output()
  setPuffUids = new EventEmitter<string[]>();

  pagination: Pagination;

  private maxShownValue: number;
  private allComponents: string[];

  constructor() {}

  ngOnInit(): void {
    this.maxShownValue = parseInt(this.maxShown, 10) || 0;
    this.allComponents = this.puffComponentsArray || [];
    this.showPagination = this.showPagination && this.maxShownValue > 0 && this.allComponents.length > 0;
    this.maxShownValue = this.maxShownValue === 0 ? this.allComponents.length : this.maxShownValue;
    this.setPage(1);
  }

  private setPaginationData(currentPage: number): void {
    this.pagination = {
      hasNext: false,
      hasPrevious: false,
      totalCount: this.allComponents.length,
      totalPages: this.showPagination ? Math.ceil(this.allComponents.length / this.maxShownValue) : 1,
      page: currentPage,
      count: this.getCount(currentPage),
    };
  }

  setPage(page: number): void {
    this.setPaginationData(page);
    const components = [...this.allComponents].slice(0, page * this.maxShownValue);
    this.setPuffUids.emit(components);
  }

  private getCount(currentPage: number): number {
    if (this.showPagination) {
      //If we are at the last page
      if (currentPage * this.maxShownValue > this.allComponents.length) {
        return this.allComponents.length;
      }

      //If not last page, return max count for this page
      return currentPage * this.maxShownValue;
    }

    return this.allComponents.length;
  }
}
