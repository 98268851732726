import { StateUtils } from '@spartacus/core';
import { PyStateUtils } from '../../../state/utils';
import { ONBOARDING_COUNTRY } from '../user-state';

export enum OnboardingCountriesActionTypes {
  LoadOnboardingCountries = '[User] Load Onboarding Countries',
  LoadOnboardingCountriesSuccess = '[User] Load Onboarding Countries Success',
  LoadOnboardingCountriesFail = '[User] Load Onboarding Countries Fail',
}

export class LoadOnboardingCountries extends StateUtils.LoaderLoadAction {
  readonly type = OnboardingCountriesActionTypes.LoadOnboardingCountries;

  constructor(public userId: string) {
    super(ONBOARDING_COUNTRY);
  }
}

export class LoadOnboardingCountriesSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = OnboardingCountriesActionTypes.LoadOnboardingCountriesSuccess;

  constructor(public payload: any) {
    super(ONBOARDING_COUNTRY);
  }
}

export class LoadOnboardingCountriesFail extends PyStateUtils.LoaderFailAction {
  readonly type = OnboardingCountriesActionTypes.LoadOnboardingCountriesFail;

  constructor(public payload?: any) {
    super(ONBOARDING_COUNTRY, payload);
  }
}

export type OnboardingCountriesActions = LoadOnboardingCountries | LoadOnboardingCountriesSuccess | LoadOnboardingCountriesFail;
