import { Action } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';

export const initialLoaderState: StateUtils.LoaderState<any> = {
  loading: false,
  success: false,
  error: false,
  errorDetails: undefined,
  value: undefined,
};

/**
 * Higher order reducer that adds generic loading flag to chunk of the state
 *
 * Utilizes "loader" meta field of actions to set specific flags for specific
 * action (LOAD, SUCCESS, FAIL, RESET)
 */
export function loaderReducer<T>(
  entityType: string,
  reducer?: (state: T, action: Action) => T
): (state: StateUtils.LoaderState<T> | undefined, action: StateUtils.LoaderAction) => StateUtils.LoaderState<T> {
  return (state: StateUtils.LoaderState<T> = initialLoaderState, action: StateUtils.LoaderAction): StateUtils.LoaderState<T> => {
    if (action.meta && action.meta.loader && action.meta.entityType === entityType) {
      const entity = action.meta.loader;
      const actionMeta = action.meta;

      if (entity.load) {
        return {
          ...state,
          loading: true,
          value: reducer ? reducer(state.value, action) : state.value,
        };
      } else if (entity.error) {
        return {
          ...state,
          loading: false,
          error: true,
          errorDetails: actionMeta.errorDetails || entity.error,
          success: false,
          value: reducer ? reducer(state.value, action) : undefined,
        };
      } else if (entity.success) {
        return {
          ...state,
          value: reducer ? reducer(state.value, action) : action.payload,
          loading: false,
          error: false,
          errorDetails: undefined,
          success: true,
        };
      } else {
        // reset state action
        return {
          ...initialLoaderState,
          value: reducer ? reducer(initialLoaderState.value, action) : initialLoaderState.value,
        };
      }
    }

    if (reducer) {
      const newValue = reducer(state.value, action);
      if (newValue === undefined || (typeof newValue === 'object' && Object.keys(newValue).length === 0)) {
        return { ...state, value: undefined };
      }
      if (newValue !== state.value) {
        return { ...state, value: newValue };
      }
    }
    return state;
  };
}
