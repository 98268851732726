import { Injectable, OnDestroy } from '@angular/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Observable, Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

export interface CanDeactivateComponent {
  showLeavePageConfirmationModal: boolean;
  leavePageGuardModalData: {
    modalConfirmed$: Subject<void>;
    modalDismissed$: Subject<void>;
    // Index signature for additional optional fields
    [key: string]: any;
  };
  leavePageGuardModalCaller: LAUNCH_CALLER;
}

/*
 * Custom guard which is responsible for displaying confirmation modal before user leave page (route change).
 * Condition (showLeavePageConfirmationModal field from component view) is used to decide if confirmation modal should be displayed.
 * Component, for which guard is assigned, should implement CanDeactivateComponent interface to make sure all required fields by guard are acessible from component.
 * Guard requires providing modalConfirmed$ and modalDismissed$ Subjects within the modal data fields,
 * These Subjects should be then used to emit confirmed and dismissed modal events that are to appropriately handled here in the guard.
 * Confirmation modal can be any modal component, but should handle emitting these two observables.
 */
@Injectable({ providedIn: 'root' })
export class LeavePageModalGuard implements OnDestroy {
  private subscriptions = new Subscription();
  private shouldDeactivate$ = new Subject<boolean>();

  constructor(private launchDialogService: LaunchDialogService) {}

  canDeactivate(component: CanDeactivateComponent): Observable<boolean> | boolean {
    if (!component.showLeavePageConfirmationModal) {
      return true;
    }

    this.launchDialogService.openDialogAndSubscribe(
      component.leavePageGuardModalCaller,
      undefined,
      component.leavePageGuardModalData
    );

    const modalSubscriptions: Subscription = new Subscription();

    modalSubscriptions.add(
      component.leavePageGuardModalData.modalConfirmed$.pipe(take(1)).subscribe(() => {
        this.shouldDeactivate$.next(true);
        modalSubscriptions.unsubscribe();
      })
    );

    modalSubscriptions.add(
      component.leavePageGuardModalData.modalDismissed$.pipe(take(1)).subscribe(() => {
        this.shouldDeactivate$.next(false);
        modalSubscriptions.unsubscribe();
      })
    );

    return this.shouldDeactivate$.asObservable();
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
