import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResetPasswordEmailUserInputCheck, UserInputCheck } from '../../model/misc.model';
import { CredentialsConnector } from '../connector/credentials-connector';

@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  constructor(private connector: CredentialsConnector) {}

  isUsernameAvailable(username: string): Observable<UserInputCheck> {
    return this.connector.isUsernameAvailable(username);
  }

  validatePassword(password: string, username: string): Observable<UserInputCheck> {
    return this.connector.isPasswordValid(password, username);
  }

  isEmailRegistered(email: string): Observable<ResetPasswordEmailUserInputCheck> {
    return this.connector.isEmailRegistered(email);
  }
}
