import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { SmartEditLauncherService } from '@spartacus/smartedit/root';
import { BaseTmsModule, TmsService } from '@spartacus/tracking/tms/core';
import { ApptusModule } from './apptus';
import { TrackingEventModule } from './events/tracking-events.module';
import { GaModule } from './ga';
import { GtmModule } from './gtm';
import { UmamiModule } from './umami';

export function tmsFactory(service: TmsService, smartEditLauncherService: SmartEditLauncherService): () => void {
  return smartEditLauncherService.isLaunchedInSmartEdit() ? () => {} : () => service.collect();
}

@NgModule({})
export class PyBaseTmsModule {
  static forRoot(): ModuleWithProviders<BaseTmsModule> {
    return {
      ngModule: BaseTmsModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: tmsFactory,
          deps: [TmsService, SmartEditLauncherService],
          multi: true,
        },
      ],
    };
  }
}

@NgModule({
  declarations: [],
  imports: [PyBaseTmsModule.forRoot(), GtmModule, GaModule, UmamiModule, TrackingEventModule, ApptusModule],
})
export class TrackingFeatureModule {}
