import { CxEvent } from '@spartacus/core';
import {
  CartModification,
  CartType,
  FailedAddToCartArticle,
  FullPalletUpgrade,
  HttpErrorModel,
  Unit,
} from '../../../../core/model';
import { OrderEntry } from '../../../../core/model/order.model';

export abstract class CartEvent extends CxEvent {
  cartType: CartType;
  cartId: string;
  userId: string;
}

// =====================================================================

export class AddCartEntryEvent extends CartEvent {
  static readonly type = 'AddCartEntryEvent';
  articleCode: string;
  quantity: number;
  unit: Unit;
  note?: string;
}

export class AddCartEntrySuccessEvent extends CartEvent {
  static readonly type = 'AddCartEntrySuccessEvent';
  articleCode: string;
  quantity: number;
  unit: Unit;
  note?: string;
  entry: OrderEntry;
  quantityAdded: number;
  fullPalletUpgrade?: FullPalletUpgrade;
  eSalesTicket?: string;
}

export class AddCartEntryFailEvent extends CartEvent {
  static readonly type = 'AddCartEntryFailEvent';
  articleCode: string;
  quantity: number;
  unit: Unit;
  note?: string;
  error?: HttpErrorModel;
}

export class AddA4SampleCartEntryEvent extends CartEvent {
  static readonly type = 'AddA4SampleCartEntryEvent';
  productCode: string;
  grammage: number;
  color: string;
  quantity: number;
}

export class AddA4SampleCartEntrySuccessEvent extends CartEvent {
  static readonly type = 'AddA4SampleCartEntrySuccessEvent';
  productCode: string;
  grammage: number;
  color: string;
  quantity: number;
  entry: OrderEntry;
  quantityAdded: number;
}

export class AddA4SampleCartEntryFailEvent extends CartEvent {
  static readonly type = 'AddA4SampleCartEntryFailEvent';
  productCode: string;
  grammage: number;
  color: string;
  quantity: number;
  error?: HttpErrorModel;
}

export class AddCartEntriesEvent extends CartEvent {
  static readonly type = 'AddCartEntriesEvent';
  entries: OrderEntry[];
}

export class AddCartEntriesSuccessEvent extends CartEvent {
  static readonly type = 'AddCartEntriesSuccessEvent';
  entries: OrderEntry[];
  message: string;
  success: boolean;
  itemsAdded: number;
  failedArticlesData?: FailedAddToCartArticle[];
}

export class AddCartEntriesFailEvent extends CartEvent {
  static readonly type = 'AddCartEntriesFailEvent';
  entries: OrderEntry[];
  error?: HttpErrorModel;
}

export class RemoveCartEntrySuccessEvent extends CartEvent {
  static readonly type = 'RemoveCartEntrySuccessEvent';
  entry: OrderEntry;
  cartModification?: CartModification;
}

export class RemoveCartEntryFailEvent extends CartEvent {
  static readonly type = 'RemoveCartEntryFailEvent';
  entry: OrderEntry;
  error?: HttpErrorModel;
}

export class RemoveCartEntriesSuccessEvent extends CartEvent {
  static readonly type = 'RemoveCartEntriesSuccessEvent';
  entries?: OrderEntry[];
}

export class RemoveCartEntriesFailEvent extends CartEvent {
  static readonly type = 'RemoveCartEntriesFailEvent';
  error?: HttpErrorModel;
}

export class UpdateCartEntrySuccessEvent extends CartEvent {
  static readonly type = 'UpdateCartEntrySuccessEvent';
  quantity: number;
  unit: Unit;
  note?: string;
  entryNumber?: string;
  cartModification?: CartModification;
  entry: OrderEntry;
}

export class UpdateCartEntryFailEvent extends CartEvent {
  static readonly type = 'UpdateCartEntryFailEvent';
  quantity: number;
  unit: Unit;
  note?: string;
  entry: OrderEntry;
  error?: HttpErrorModel;
}
