import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SoldToFacade } from '../../../features/sold-to-base';

@Component({
  selector: 'py-credit-blocked',
  templateUrl: './credit-blocked.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreditBlockedComponent implements OnInit {
  isBlocked$: Observable<boolean>;

  constructor(private soldToService: SoldToFacade) {}

  ngOnInit(): void {
    this.isBlocked$ = this.soldToService.isCreditBlocked().pipe(filter((blocked) => !!blocked));
  }
}
