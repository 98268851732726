import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService, StateUtils } from '@spartacus/core';
import { EMPTY, Observable, combineLatest } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap, share, switchMap, take, tap } from 'rxjs/operators';
import { UserIdService } from '../../../auth/user-auth/facade';
import { PriceFileEntry, PriceFileFormat, PriceFileListPage, PriceFileRequest } from '../../../model/price-file.model';
import { Pagination, SearchParams } from '../../../model/search.model';
import { withdrawOn } from '../../../util';
import { SearchAction } from '../../store/actions/search.actions';
import {
  getUserInteractionErrorFactory,
  getUserInteractionLoadingFactory,
  getUserInteractionSuccessFactory,
} from '../../store/selectors/user-interaction.selectors';
import { PriceFileConnector } from '../connector/price-file.connector';
import { PriceFileActions, PriceFilesActions } from '../store/actions';
import { LoadPriceFileFormats } from '../store/actions/price-file-format.actions';
import { RequestPriceFile } from '../store/actions/price-file-request.actions';
import { AutoOrderPriceFile } from '../store/actions/price-file.actions';
import { PRICE_FILE, StateWithPriceFile } from '../store/price-file-state';
import { getPriceFileFormatsLoader } from '../store/selectors/price-file-format.selectors';
import {
  getPriceFileRequestFailure,
  getPriceFileRequestLoading,
  getPriceFileRequestSuccess,
} from '../store/selectors/price-file-request.selectors';
import {
  getPriceFilesState,
  getSearchPageResultsSelector,
  getSearchPaginationSelector,
  getSearchParamsSelector,
  getSearchResultEntries,
} from '../store/selectors/price-file.selectors';

@Injectable({
  providedIn: 'root',
})
export class PriceFileService {
  constructor(
    private store: Store<StateWithPriceFile>,
    private connector: PriceFileConnector,
    private userIdService: UserIdService,
    private authService: AuthService
  ) {}

  search(searchParams: SearchParams) {
    this.userIdService.takeUserId(true).subscribe(
      (userId) => this.store.dispatch(new PriceFilesActions.LoadPriceFilesAction(userId, searchParams)),
      () => {}
    );
  }

  updateSearchParameters(searchParams: SearchParams) {
    this.store.dispatch(new SearchAction(searchParams, PRICE_FILE));
  }

  getSearchParameters(): Observable<SearchParams> {
    return combineLatest([this.store.select(getSearchParamsSelector), this.store.select(getSearchPaginationSelector)]).pipe(
      map(([params, pagination]) => {
        // Sync search parameters to the pagination data, which can get out of
        // sync when deleting the last item of the last page.
        if (params && pagination && params.page > 0 && params.page >= pagination.totalPages) {
          const newParams = { ...params, page: Math.max(0, pagination.totalPages - 1) };
          this.updateSearchParameters(newParams);
          return newParams as SearchParams;
        }
        return params as SearchParams;
      }),
      distinctUntilChanged(),
      tap((params: SearchParams) => {
        if (!params || !params.key) {
          this.updateSearchParameters({
            key: 'latest',
            page: 0,
            count: 11,
          });
        }
      }),
      filter((params) => params && !!params.key)
    );
  }

  getPriceFiles(searchParams): Observable<Array<PriceFileEntry>> {
    const isUserLoggedIn$ = this.authService.isUserLoggedIn().pipe(share());
    return isUserLoggedIn$.pipe(
      filter(Boolean),
      switchMap(() => this.store.select(getSearchPageResultsSelector)),
      filter((loaderState) => {
        if (!loaderState || (loaderState.success && !loaderState.value.lastUpdateTime)) {
          this.search(searchParams);
          return false;
        }
        return loaderState.success;
      }),
      map((loaderState) => loaderState.value.results),
      mergeMap((results) => this.store.select(getSearchResultEntries, { keys: results })),
      withdrawOn(isUserLoggedIn$.pipe(filter((isLoggedIn) => !isLoggedIn)))
    );
  }

  getPriceFilesByStatus(statuses: Array<string>): Observable<Array<PriceFileEntry>> {
    return this.userIdService.takeUserId(true).pipe(
      switchMap((userId) =>
        this.connector
          .loadPage(userId, {
            key: 'latest',
            page: 0,
            count: 9999,
          })
          .pipe(
            map((priceFilesResult: PriceFileListPage) =>
              priceFilesResult?.results.filter((priceFile) => {
                return statuses.includes(priceFile?.status);
              })
            )
          )
      ),
      catchError(() => EMPTY)
    );
  }

  priceFilesLoading(): Observable<boolean> {
    return this.store.select(getSearchPageResultsSelector).pipe(map((loaderState) => loaderState?.loading ?? false));
  }

  deletePriceFile(priceFileEntry: PriceFileEntry, searchParams: SearchParams) {
    this.userIdService.takeUserId(true).subscribe(
      (userId) => this.store.dispatch(new PriceFileActions.DeletePriceFile(userId, priceFileEntry.code, searchParams)),
      () => {}
    );
  }

  cancelPriceFile(priceFileEntry: PriceFileEntry, searchParams: SearchParams) {
    this.userIdService.takeUserId(true).subscribe(
      (userId) => this.store.dispatch(new PriceFileActions.CancelPriceFile(userId, priceFileEntry.code, searchParams)),
      () => {}
    );
  }

  deletePriceFileReset(priceFileEntry: PriceFileEntry) {
    if (priceFileEntry?.code) {
      this.store.dispatch(new PriceFileActions.DeletePriceFileReset(priceFileEntry.code));
    }
  }

  cancelPriceFileReset(priceFileEntry: PriceFileEntry) {
    if (priceFileEntry?.code) {
      this.store.dispatch(new PriceFileActions.CancelPriceFileReset(priceFileEntry.code));
    }
  }

  getPriceFilePagination(): Observable<Pagination> {
    return this.store.select(getSearchPaginationSelector);
  }

  private loadAvailableFormats(): void {
    this.userIdService.takeUserId(true).subscribe(
      (userId) => this.store.dispatch(new LoadPriceFileFormats(userId)),
      () => {}
    );
  }

  getAvailableFormats(): Observable<Array<PriceFileFormat>> {
    const isUserLoggedIn$ = this.authService.isUserLoggedIn().pipe(share());
    return isUserLoggedIn$.pipe(
      filter(Boolean),
      switchMap(() => this.store.select(getPriceFileFormatsLoader)),
      tap((loaderState) => {
        if (this.needsToLoad(loaderState)) {
          this.loadAvailableFormats();
        }
      }),
      map((loaderState) => loaderState?.value?.entities),
      filter((items) => !!items),
      take(1),
      withdrawOn(isUserLoggedIn$.pipe(filter((isLoggedIn) => !isLoggedIn)))
    );
  }

  requestPriceFile(priceFileRequest: PriceFileRequest, searchParams: SearchParams) {
    this.userIdService.takeUserId(true).subscribe(
      (userId) => this.store.dispatch(new RequestPriceFile(userId, priceFileRequest, searchParams)),
      () => {}
    );
  }

  requestPriceFileLoading() {
    return this.store.select(getPriceFileRequestLoading).pipe(filter((res) => !!res));
  }

  requestPriceFileFailure() {
    return this.store.select(getPriceFileRequestFailure).pipe(filter((res) => !!res));
  }

  requestPriceFileSuccess() {
    return this.store.select(getPriceFileRequestSuccess).pipe(filter((res) => !!res));
  }

  cancelPriceFileLoading(priceFileEntry: PriceFileEntry) {
    return this.store.select(getUserInteractionLoadingFactory(getPriceFilesState, 'cancel', priceFileEntry.code));
  }

  cancelPriceFileFailure(priceFileEntry: PriceFileEntry) {
    return this.store.select(getUserInteractionErrorFactory(getPriceFilesState, 'cancel', priceFileEntry.code));
  }

  cancelPriceFileSuccess(priceFileEntry: PriceFileEntry) {
    return this.store.select(getUserInteractionSuccessFactory(getPriceFilesState, 'cancel', priceFileEntry.code));
  }

  deletePriceFileLoading(priceFileEntry: PriceFileEntry) {
    if (priceFileEntry?.code) {
      return this.store.select(getUserInteractionLoadingFactory(getPriceFilesState, 'delete', priceFileEntry.code));
    }
  }

  deletePriceFileFailure(priceFileEntry: PriceFileEntry) {
    return this.store.select(getUserInteractionErrorFactory(getPriceFilesState, 'delete', priceFileEntry.code));
  }

  deletePriceFileSuccess(priceFileEntry: PriceFileEntry) {
    return this.store.select(getUserInteractionSuccessFactory(getPriceFilesState, 'delete', priceFileEntry.code));
  }

  downloadPriceFile(code: string): Observable<Blob> {
    return this.userIdService.takeUserId(true).pipe(
      switchMap((userId) => this.connector.download(userId, code)),
      catchError(() => EMPTY)
    );
  }

  autoOrderPriceFile(): void {
    this.userIdService.takeUserId(true).subscribe(
      (userId) => this.store.dispatch(new AutoOrderPriceFile(userId)),
      () => {}
    );
  }

  private needsToLoad(loaderState: StateUtils.LoaderState<any>) {
    return !loaderState || (!loaderState.success && !loaderState.error && !loaderState.loading);
  }
}
