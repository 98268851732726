<py-modal
  [actions]="templateActions"
  [showCancelButton]="false"
  [blockDismissCoreAction]="true"
  [centered]="true"
  [dismissable]="dismissable"
  [title]="'checkout.accountingCodes.modal_title' | cxTranslate"
  [cxFocus]="focusConfig"
>
  <div>
    <div class="col-12">
      <div class="py-2">
        <py-radio [showLabel]="true">
          <input type="radio" checked="true" name="chooseAccountingCode" (change)="setOption('setAll')" />
          <span slot="label">{{ 'checkout.accountingCodes.selectAll_heading' | cxTranslate }}</span>
        </py-radio>
      </div>
    </div>
    <div class="col-12">
      <div class="py-2">
        <py-radio [showLabel]="true">
          <input type="radio" name="chooseAccountingCode" (change)="setOption('setAllAndDelete')" />
          <span slot="label">{{ 'checkout.accountingCodes.selectAllAndDelete_heading' | cxTranslate }}</span>
        </py-radio>
      </div>
    </div>
    <div class="col-12">
      <div class="py-2">
        <py-radio [showLabel]="true">
          <input type="radio" name="chooseAccountingCode" (change)="setOption('noAction')" />
          <span slot="label">{{ 'checkout.accountingCodes.noAction_heading' | cxTranslate }}</span>
        </py-radio>
      </div>
    </div>
    <div class="col-12 mt-12 mb-5">
      <div class="py-2">
        <py-checkbox [showLabel]="true">
          <input type="checkbox" [checked]="modalDismissed" (change)="updateModalDismissed()" />
          <span slot="label">{{ 'checkout.accountingCodes.dismiss_action' | cxTranslate }}</span>
        </py-checkbox>
      </div>
    </div>
  </div>
</py-modal>

<ng-template #templateActions>
  <py-action-button
    [buttonClass]="'btn btn-primary'"
    [type]="'button'"
    [buttonLabel]="'common.confirm_action' | cxTranslate"
    (click)="close()"
  >
  </py-action-button>
</ng-template>
