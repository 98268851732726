import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../core/i18n';
import { PrincipalConfigurationDirectiveModule, RemoveHostModule } from '../../directives';
import { PipesModule } from '../../pipes/pipes.module';
import { OrderEntryModule } from '../order-entry';
import { OrderPriceSummaryModule } from '../order-price-summary';
import { ResponsiveTableModule } from '../responsive-table/responsive-table.module';
import { OrderLinesTableComponent } from './order-lines-table.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    NzIconModule,
    PipesModule,
    ResponsiveTableModule,
    OrderEntryModule,
    OrderPriceSummaryModule,
    RemoveHostModule,
    PrincipalConfigurationDirectiveModule,
    NgbTooltipModule,
  ],
  declarations: [OrderLinesTableComponent],
  exports: [OrderLinesTableComponent],
})
export class OrderLinesTableModule {}
