import { Currency } from '../../../model/misc.model';
import { CurrenciesActionTypes } from '../actions/currencies.action';
import { SiteContextActions } from '../actions/index';
import { CurrenciesState } from '../site-context-state';

export const initialState: CurrenciesState = {
  entities: null,
  activeCurrency: null,
};

export function reducer(state = initialState, action: SiteContextActions.CurrenciesActions): CurrenciesState {
  switch (action.type) {
    case CurrenciesActionTypes.LoadCurrenciesSuccess: {
      const currencies: Currency[] = action.payload;
      const entities = currencies.reduce(
        (currEntities: { [isocode: string]: Currency }, currency: Currency) => {
          return {
            ...currEntities,
            [currency.isocode]: currency,
          };
        },
        {
          ...state.entities,
        }
      );

      return {
        ...state,
        entities,
      };
    }

    case CurrenciesActionTypes.SetActiveCurrency: {
      const isocode: string = action.payload;

      return {
        ...state,
        activeCurrency: isocode,
      };
    }
  }

  return state;
}
