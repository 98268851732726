import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'py-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadMoreComponent implements AfterViewInit {
  @ViewChild('contentElement', { static: false }) contentElement: ElementRef<HTMLElement>;

  @Input() isReadMoreExpanded = false;
  @Input() showExpandDispandIcon = true;
  @Input() isDynamic = false;

  minContainerHeight = 150;
  readMoreEnabled = true;

  private _content: string;

  get content() {
    return this._content;
  }

  @Output()
  isReadMoreExpandedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() class: string;
  @Input() set content(c: string) {
    this._content = c;
    this.updateReadMoreDynamic();
  }

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    if (this.isDynamic) {
      this.updateReadMoreDynamic();
    }
  }

  updateReadMoreDynamic() {
    setTimeout(() => {
      const el = this.contentElement?.nativeElement;
      this.readMoreEnabled = el?.offsetHeight > this.minContainerHeight;
      this.cd.detectChanges();
    }, 50);
  }

  onButtonClick($event) {
    $event.preventDefault();
    this.isReadMoreExpanded = !this.isReadMoreExpanded;
    this.isReadMoreExpandedChange.emit(this.isReadMoreExpanded);
  }
}
