import { ChangeDetectionStrategy, Component, ElementRef, Renderer2 } from '@angular/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { BaseAbstractModalComponent, ModalCloseReason } from '../../../core/modal';
import { CheckoutFacade } from '../../../features/checkout/base';

@Component({
  selector: 'py-active-order-process-modal',
  templateUrl: './active-order-process-modal.component.html',
  styleUrls: ['./active-order-process-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActiveOrderProcessModalComponent extends BaseAbstractModalComponent {
  dismissable = false;
  proceedInProgress$ = new BehaviorSubject<boolean>(false);
  cancelInProgress$ = new BehaviorSubject<boolean>(false);
  inProgress$ = combineLatest([this.proceedInProgress$, this.cancelInProgress$]).pipe(map((x) => x.some(Boolean)));

  constructor(
    protected launchDialogService: LaunchDialogService,
    protected el: ElementRef,
    protected renderer: Renderer2,
    protected checkoutService: CheckoutFacade
  ) {
    super(el, launchDialogService, renderer);
  }

  isActiveOrderProcessModal = true;

  onProceed(): void {
    this.proceedInProgress$.next(true);
    this.checkoutService
      .resumeActiveOrderProcess()
      .subscribe(() => this.launchDialogService.closeDialog(ModalCloseReason.ACTIVE_ORDER_PROCESS_PROCEEDED));
  }

  onCancel(): void {
    this.cancelInProgress$.next(true);
    this.checkoutService
      .cancelActiveOrderProcess()
      .subscribe(() => this.launchDialogService.closeDialog(ModalCloseReason.ACTIVE_ORDER_PROCESS_CANCELLED));
  }
}
