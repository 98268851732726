import { Component, HostBinding, Input } from '@angular/core';
import { SpinnerType } from '../../../core/model';

// TODO: Improve a11y with better text appropriate to usage (example: loading cart spinner)

@Component({
  selector: 'cx-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  @Input() type: SpinnerType = SpinnerType.Default;

  @HostBinding('class.dark')
  get applyDarkClass(): boolean {
    return this.type === SpinnerType.Dark;
  }

  @HostBinding('class.light')
  get applyLightClass(): boolean {
    return this.type === SpinnerType.Light;
  }

  constructor() {}
}
