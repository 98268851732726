import { Injectable } from '@angular/core';
import { AuthService, WindowRef, withdrawOn } from '@spartacus/core';
import { TmsCollector, TmsCollectorConfig, WindowObject } from '@spartacus/tracking/tms/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { AnalyticsMetadataFacade, UserConsentFacade } from '../../../core/user';
import { ApptusFrontendConfiguration } from '../models/tracking.model';
import { ApptusNotificationEventHandler } from './apptus-notification';

@Injectable({ providedIn: 'root' })
export class ApptusCollectorService implements TmsCollector {
  constructor(
    private winRef: WindowRef,
    private userConsentService: UserConsentFacade,
    private analyticsMetadataService: AnalyticsMetadataFacade,
    private authService: AuthService
  ) {}
  api: any;
  isPersonalizationAllowed = false;
  isScriptLoaded = false;
  contextChange$: Observable<boolean> = this.authService.isUserLoggedIn().pipe(filter((loggedIn) => !!loggedIn));

  customerKey: string;
  clusterId: string;
  market: string;

  init(): void {
    this.analyticsMetadataService
      .getAnalyticsMetadata()
      .pipe(
        filter((meta) => !!meta?.apptusConfigurations[0]),
        map((meta) => meta.apptusConfigurations[0]),
        //When the user loggs in, we need to replace our temp customerKey with the correct one from BE.
        withdrawOn(this.contextChange$)
      )
      .subscribe(({ market, clusterId, customerKey }: ApptusFrontendConfiguration) => {
        this.market = market;
        this.clusterId = clusterId;

        if (customerKey) {
          this.winRef.document.cookie = `apptus.customerKey=${customerKey};path=/;secure=true`;
        }

        if (!this.isScriptLoaded) {
          this.installScript();
          this.isScriptLoaded = true;
        }
      });

    this.userConsentService
      .isPersonalizationAllowed()
      .pipe(distinctUntilChanged())
      .subscribe((isPersonalizationAllowed) => {
        this.isPersonalizationAllowed = isPersonalizationAllowed;
      });
  }

  private installScript(): void {
    const node = this.winRef.document.createElement('script');
    node.src = `https://cdn.esales.apptus.com/api/apptus-esales-api-2.2.4.min.js`;
    node.type = 'text/javascript';
    node.async = true;
    node.defer = true;
    this.winRef.document.getElementsByTagName('body')[0].appendChild(node);
  }

  pushEvent(_config: TmsCollectorConfig, _windowObject: WindowObject, event: any): void {
    if (this.isPersonalizationAllowed) {
      const esalesAPI = (this.winRef.nativeWindow as any)?.esalesAPI;

      if (!this.api && esalesAPI) {
        this.api = esalesAPI({ market: this.market, clusterId: this.clusterId });
      }

      if (this.api?.notify) {
        ApptusNotificationEventHandler.send(this.api?.notify, event);
      }
    }
  }
}
