import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../core/i18n';
import { KeyboardFocusModule } from '../../../layout/a11y';
import { PipesModule } from '../../pipes';
import { ReactiveFormInputFieldModule } from '../reactive-forms-input/reactive-form-input-field/reactive-form-input-field.module';
import { OrderLineInputComponent } from './order-line-input.component';

@NgModule({
  imports: [
    CommonModule,
    NzIconModule,
    ReactiveFormsModule,
    I18nModule,
    ReactiveFormInputFieldModule,
    NgbTooltipModule,
    PipesModule,
    KeyboardFocusModule,
  ],
  declarations: [OrderLineInputComponent],
  exports: [OrderLineInputComponent],
})
export class OrderLineInputModule {}
