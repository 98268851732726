import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ClientAuthenticationTokenService, ClientToken } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { normalizeHttpError } from '../../../../util/normalize-http-error';
import { ClientTokenActionTypes } from '../actions/client-token.action';
import { ClientAuthActions } from '../actions/index';

@Injectable()
export class ClientTokenEffect {
  loadClientToken$: Observable<ClientAuthActions.ClientTokenActions> = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientTokenActionTypes.LoadClientToken),
      exhaustMap(() => {
        return this.clientAuthenticationTokenService.loadClientAuthenticationToken().pipe(
          map((token: ClientToken) => {
            return new ClientAuthActions.LoadClientTokenSuccess(token);
          }),
          catchError((error) => of(new ClientAuthActions.LoadClientTokenFail(normalizeHttpError(error))))
        );
      })
    )
  );

  constructor(private actions$: Actions, private clientAuthenticationTokenService: ClientAuthenticationTokenService) {}
}
