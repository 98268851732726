import { ModuleWithProviders, NgModule } from '@angular/core';
import { PageMetaModule as CxPageMetaModule, PageMetaResolver, provideDefaultConfig } from '@spartacus/core';
import { defaultPageMetaConfig } from './config/default-page-meta.config';
import { ContentPageMetaResolver } from './content-page-meta.resolver';

@NgModule({
  providers: [
    {
      provide: PageMetaResolver,
      useExisting: ContentPageMetaResolver,
      multi: true,
    },
  ],
})
export class PageMetaModule extends CxPageMetaModule {
  static forRoot(): ModuleWithProviders<PageMetaModule> {
    return {
      ngModule: PageMetaModule,
      providers: [provideDefaultConfig(defaultPageMetaConfig)],
    };
  }
}
