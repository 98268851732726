import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig } from '@spartacus/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { provideDefaultConfig } from '../../../../core/config';
import { I18nModule } from '../../../../core/i18n';
import { GenericLinkModule } from '../../../../shared/components/generic-link/generic-link.module';
import { MediaIconModule } from '../../../../shared/components/media-icon';
import { NavigationComponent } from './navigation.component';

@NgModule({
  imports: [CommonModule, RouterModule, NzIconModule, GenericLinkModule, I18nModule, MediaIconModule],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        NavigationComponent: {
          component: NavigationComponent,
        },
      },
    }),
  ],
  declarations: [NavigationComponent],
  exports: [],
})
export class NavigationModule {}
