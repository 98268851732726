import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        productDocuments: {
          module: () => import('./product-documents.module').then((m) => m.ProductDocumentsModule),
          cmsComponents: ['CmsProductDocumentsFlexComponent'],
          dependencies: [() => import('../documents-base/documents-base.module').then((m) => m.DocumentsBaseModule)],
        },
      },
    }),
  ],
})
export class ProductDocumentsFeatureModule {}
