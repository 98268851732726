import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { OverviewListItemComponent } from './overview-list-item.component';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [OverviewListItemComponent],
  exports: [OverviewListItemComponent],
})
export class OverviewListItemModule {}
