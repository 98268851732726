import { Pipe, PipeTransform } from '@angular/core';
import { MediaService } from '../../media';

@Pipe({ name: 'mediaUrl' })
export class MediaUrlPipe implements PipeTransform {
  constructor(private mediaService: MediaService) {}

  transform(url?: string): string {
    if (!url) {
      return url;
    }
    return this.mediaService.resolveAbsoluteUrl(url);
  }
}
