import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { mergeMap, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { AreaOfUseService } from '../../../../../core/catalog';
import { Category } from '../../../../../core/model';
import { FeedFacade } from '../../../../../core/user';
import { prepareUrlForLink } from '../../../../../core/util';
import { DrawerPlacement, DrawerService } from '../../../../../shared';

@Component({
  selector: 'py-menu-area-of-use-dropdown',
  templateUrl: './menu-area-of-use-dropdown.component.html',
  styleUrls: ['./menu-area-of-use-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuAreaOfUseDropdownComponent implements OnInit, OnDestroy {
  @ViewChild(NgbDropdown, { static: true }) dropdown: NgbDropdown;
  @ViewChild('drawerTemplate') drawerTemplate: TemplateRef<any>;
  @Output() showChange = new EventEmitter<boolean>();
  @ViewChild('megamenuContainer', { read: ElementRef }) megamenuContainer: ElementRef<HTMLDivElement>;

  @Input() show: boolean;

  loading$: Observable<boolean>;
  categories$: Observable<Category[]>;
  activeCategory$ = new BehaviorSubject<Category>(undefined);

  subscription = new Subscription();

  constructor(
    private areaOfUseService: AreaOfUseService,
    private feedService: FeedFacade,
    private drawerService: DrawerService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const feedData$ = this.feedService.getFeedData().pipe(shareReplay({ bufferSize: 1, refCount: false }));

    this.subscription.add(
      feedData$.pipe(this.areaOfUseService.shouldRefreshAreasOfUseOperator()).subscribe((shouldRefreshAreasOfUse) => {
        if (shouldRefreshAreasOfUse) {
          this.areaOfUseService.reloadAreasOfUse();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setActiveCategory(category: Category): void {
    if (category?.code !== this.activeCategory$.value?.code) {
      this.activeCategory$.next(category);
      this.megamenuContainer?.nativeElement?.scrollTo(0, 0);
    }
  }

  getUrl(url: string): string {
    return prepareUrlForLink(url);
  }

  toggleDrawer(): void {
    this.showChange.emit(true);

    this.subscription.add(
      this.drawerService
        .open({
          template: this.drawerTemplate,
          placement: DrawerPlacement.Top,
        })
        .pipe(
          take(1),
          mergeMap((drawer) => {
            const observables = [];

            observables.push(
              drawer?.afterClose?.pipe(
                take(1),
                tap(() => {
                  this.setActiveCategory(undefined);
                  this.showChange.emit(false);
                  this.cd.detectChanges();
                })
              )
            );

            observables.push(
              drawer?.afterOpen?.pipe(
                take(1),
                switchMap(() =>
                  this.areaOfUseService.getAreasOfUse().pipe(
                    take(1),
                    tap((categories) => {
                      if (categories?.length > 0) {
                        this.setActiveCategory(categories[0]);
                      } else {
                        this.setActiveCategory(undefined);
                      }
                    })
                  )
                )
              )
            );

            return combineLatest(observables);
          })
        )
        .subscribe()
    );
  }
}
