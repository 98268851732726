import { DIALOG_TYPE, LAUNCH_CALLER, LayoutConfig } from '@spartacus/storefront';
import { NameMarkingModalComponent } from './name-marking-modal.component';

export const nameMarkingModalLayoutConfig: LayoutConfig = {
  launch: {
    [LAUNCH_CALLER.NAME_MARKING]: {
      inlineRoot: true,
      component: NameMarkingModalComponent,
      dialogType: DIALOG_TYPE.DIALOG,
      dialogOptions: {
        onTop: true,
      },
    },
  },
};
