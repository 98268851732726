import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AuthConfigService,
  AuthStorageService,
  TokenRevocationInterceptor as CxTokenRevocationInterceptor,
} from '@spartacus/core';

/**
 * This interceptor is dedicated for Hybris OAuth server which requires `Authorization` header for revoke token calls.
 */
@Injectable({ providedIn: 'root' })
export class TokenRevocationInterceptor extends CxTokenRevocationInterceptor {
  constructor(protected authStorageService: AuthStorageService, protected authConfigService: AuthConfigService) {
    super(authStorageService, authConfigService);
  }

  protected isTokenRevocationRequest(request: HttpRequest<any>): boolean {
    return request.url === this.authConfigService.getRevokeEndpoint() && request.body?.get('token_type_hint') !== 'refresh_token';
  }
}
