import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ContentTag } from '../../../core/model';
import { CmsContentTagService } from '../../../features/cms-content-tag-base';

@Component({
  selector: 'py-puff-collection-content-tags',
  templateUrl: './puff-collection-content-tags.component.html',
  styleUrls: ['./puff-collection-content-tags.component.scss'],
})
export class PuffCollectionContentTagsComponent {
  @Input()
  contentTagUids: string[] = [];

  @Output()
  update = new EventEmitter<string[]>();

  _activeTags: string[] = [];

  constructor(public cmsContentTagService: CmsContentTagService) {}

  toggleActiveTags(tag: string): void {
    this.isActive(tag) ? this._activeTags.splice(this._activeTags.indexOf(tag), 1) : this._activeTags.push(tag);

    this.update.emit(this._activeTags);
  }

  isActive(tag: string): boolean {
    return this._activeTags.includes(tag);
  }

  getContentTag(uid: string): Observable<ContentTag> {
    return this.cmsContentTagService.getContentTag(uid).pipe(take(1));
  }
}
