import { Action } from '@ngrx/store';
import { Language } from '../../../model/misc.model';

export enum LanguagesActionTypes {
  LoadLanguages = '[Site-context] Load Languages',
  LoadLanguagesSuccess = '[Site-context] Load Languages Success',
  LoadLanguagesFail = '[Site-context] Load Languages Fail',

  SetActiveLanguage = '[Site-context] Set Active Language',
  LanguageChange = '[Site-context] Language Change',
}

export class LoadLanguages implements Action {
  readonly type = LanguagesActionTypes.LoadLanguages;
}

export class LoadLanguagesFail implements Action {
  readonly type = LanguagesActionTypes.LoadLanguagesFail;
  constructor(public payload: any) {}
}

export class LoadLanguagesSuccess implements Action {
  readonly type = LanguagesActionTypes.LoadLanguagesSuccess;
  constructor(public payload: Language[]) {}
}

export class SetActiveLanguage implements Action {
  readonly type = LanguagesActionTypes.SetActiveLanguage;
  constructor(public payload: string) {}
}

export class LanguageChange implements Action {
  readonly type = LanguagesActionTypes.LanguageChange;
  constructor(public payload: { previous: string; current: string }) {}
}

export type LanguagesActions = LoadLanguages | LoadLanguagesFail | LoadLanguagesSuccess | SetActiveLanguage | LanguageChange;
