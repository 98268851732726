import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        settings: {
          module: () =>
            import('./containers/settings-navigation/settings-navigation.module').then((m) => m.SettingsNavigationModule),
          cmsComponents: ['SettingsNavigationComponent'],
        },
      },
    }),
  ],
})
export class SettingsFeatureModule {}
