import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { isAfter, parseISO } from 'date-fns';
import { Observable, OperatorFunction, UnaryFunction, pipe } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { UserIdService } from '../../../auth';
import { Category, CategoryRef } from '../../../model/catalog.model';
import { Feed } from '../../../model/feed.model';
import { StateWithCatalog } from '../../store/catalog-state';
import { CatalogSelectors } from '../../store/selectors';
import { AreaOfUseActions } from '../store/actions';
@Injectable({
  providedIn: 'root',
})
export class AreaOfUseService {
  constructor(private store: Store<StateWithCatalog>, private userIdService: UserIdService) {}

  getAreasOfUseLoading(): Observable<boolean> {
    return this.store.select(CatalogSelectors.getAreasOfUseLoading);
  }

  getAreasOfUseLoaded(): Observable<boolean> {
    return this.store.select(CatalogSelectors.getAreasOfUseLoaded);
  }

  getAreasOfUseLastUpdateTime(): Observable<string> {
    return this.store.select(CatalogSelectors.getAreasOfUseLastUpdateTime);
  }

  getAreasOfUse(): Observable<Array<Category>> {
    return this.store.pipe(
      select(CatalogSelectors.areasOfUseSelector),
      this.loadIfNotPresentPipe(),
      switchMap((refs) => this.getAreasOfUseByRefs(refs))
    );
  }

  getAreaOfUse(ref: CategoryRef): Observable<Category> {
    return this.store.pipe(select(CatalogSelectors.areaOfUseByRefSelector, { ref: ref }), this.loadIfNotPresentPipe());
  }

  getAreasOfUseByRefs(refs: Array<CategoryRef>): Observable<Array<Category>> {
    return this.store.pipe(select(CatalogSelectors.areasOfUseForRefsSelector, { refs: refs }), this.loadIfNotPresentPipe());
  }

  getAreasOfUseByRefsAndLevel(level: number, refs: Array<CategoryRef>): Observable<Array<Category>> {
    return this.store.pipe(
      select(CatalogSelectors.areasOfUseForRefsAndLevelSelector, { level: level, refs: refs }),
      this.loadIfNotPresentPipe()
    );
  }

  getAreasOfUseByRefsAndSuperCategoryRef(superCategoryRef: CategoryRef, refs: Array<CategoryRef>): Observable<Array<Category>> {
    return this.store.pipe(
      select(CatalogSelectors.areasOfUseForRefsAndSuperCategoryRefSelector, { superCategoryRef: superCategoryRef, refs: refs }),
      this.loadIfNotPresentPipe()
    );
  }

  /**
   * Operator for checking if Area of Use categories should be refreshed.
   * Basing on the values from feedData, it returns true if there was a new Area of Use categories sync that happened after loading currently displayed Area of Use categories.
   */
  shouldRefreshAreasOfUseOperator(): OperatorFunction<Feed, boolean> {
    return pipe(
      filter((feedData) => !!feedData),
      map((feedData) => feedData?.lastAreaOfUseSyncEndTime),
      distinctUntilChanged(),
      withLatestFrom(this.getAreasOfUseLoaded(), this.getAreasOfUseLastUpdateTime()),
      map(
        ([lastAreaOfUseSyncEndTime, areasOfUseLoaded, lastUpdateTime]) =>
          areasOfUseLoaded && lastAreaOfUseSyncEndTime && isAfter(parseISO(lastAreaOfUseSyncEndTime), parseISO(lastUpdateTime))
      )
    );
  }

  reloadAreasOfUse(): void {
    this.userIdService.takeUserId(true).subscribe(
      (userId) => this.loadAreasOfUse(userId),
      () => {}
    );
  }

  private loadIfNotPresentPipe<T>(): UnaryFunction<Observable<T>, Observable<T>> {
    return pipe(
      withLatestFrom(this.userIdService.getUserId(), this.store.select(CatalogSelectors.getAreasOfUseLoading)),
      tap(([areasOfUse, userId, isLoading]) => {
        if (!areasOfUse && !isLoading) {
          this.loadAreasOfUse(userId);
        }
      }),
      filter(([areasOfUse, _]) => !!areasOfUse),
      map(([areasOfUse, _]) => areasOfUse)
    );
  }

  private loadAreasOfUse(userId: string): void {
    this.store.dispatch(new AreaOfUseActions.LoadAreasOfUse(userId));
  }
}
