import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { provideDefaultConfigFactory, StateConfig, StateModule, StateTransferType } from '@spartacus/core';
import { metaReducers, reducerProvider, reducerToken } from '../../store/reducers';
import { USER_FEATURE } from '../../store/user-state';
import { effects } from './effects';

export function userStoreConfigFactory(): StateConfig {
  const config: StateConfig = {
    state: {
      ssrTransfer: {
        keys: {
          [USER_FEATURE]: StateTransferType.TRANSFER_STATE,
        },
      },
    },
  };
  return config;
}

@NgModule({
  imports: [StateModule, StoreModule.forFeature(USER_FEATURE, reducerToken, { metaReducers }), EffectsModule.forFeature(effects)],
  providers: [reducerProvider, provideDefaultConfigFactory(userStoreConfigFactory)],
})
export class UserRootStoreModule {}
