import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'li[py-megamenu-list-item]',
  templateUrl: './megamenu-list-item.component.html',
  styleUrls: ['./megamenu-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MegamenuListItemComponent {
  @Input()
  @HostBinding('class.is-direct-link')
  isDirectLink? = false;

  @Input() showLinkArrow = true;
}
