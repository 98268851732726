import { OccConfig } from '@spartacus/core';

const users = 'py/users/${userId}';
const accountingCodes = users + '/accounting-codes';
const invoices = users + '/invoices';
const priceFiles = users + '/price-files';
const orders = users + '/orders';
const articles = users + '/catalog/articles';
const order = orders + '/${orderCode}';
const orderApproval = users + '/approval';
const orderApprovalOrders = orderApproval + '/orders';
const orderApprovalAdminOrders = orderApproval + '/admin/orders';
const orderCards = users + '/order-cards';
const orderCardsAdmin = users + '/admin/order-cards';
const payment = order + '/payment';
const orderHistories = users + '/order-histories';
const orderReturn = users + '/returns/${sapOrderNumber}';
const soldTos = users + '/sold-tos';
const customShipTos = users + '/ship-tos/custom';
const carts = users + '/carts/${cartCodeOrType}';
const cartEntries = carts + '/entries';
const cartEntry = cartEntries + '/${entryNumber}';
const tinyCarts = users + '/tiny-carts';
const shoppingLists = users + '/cart-templates';
const shoppingList = shoppingLists + '/${shoppingListCode}';
const shoppingListEntries = shoppingList + '/entries';
const shoppingListEntry = shoppingListEntries + '/${entryNumber}';
const accountingCodesAdmin = users + '/admin/accounting-codes';
const feed = users + '/feed';
const dataImports = users + '/data-imports';
const areasOfUseAdmin = users + '/catalog/admin/areas-of-use';
const priceCampaigns = 'py/priceCampaigns';
const orderRules = users + '/admin/order-rules';

export const defaultOccConfig: OccConfig = {
  backend: {
    occ: {
      prefix: '/occ/v2/',
      useWithCredentials: true, // We need this to set "ROUTE cookie".
      endpoints: {
        // AccountingCodes
        accountingCodes: accountingCodes,
        accountingCodesForArticle: accountingCodes + '/article',

        // CMS
        contentTags: users + '/cms/content-tags',
        contentTagsTaggedItems: users + '/cms/content-tags/${uid}/tagged-items',
        mailFormFields: users + '/cms/form-fields',
        mailForm: users + '/cms/mail-forms',
        publishedDate: users + '/cms/content-page/published-date',
        featuredArticles: users + '/cms/featured-articles',
        featuredProducts: users + '/cms/featured-products',
        linkComponentsProducts: users + '/cms/link-components/products',

        // Site context
        countriesByType: users + '/countries/type', // overriding the value in default-occ-site-context-config.ts
        countries: users + '/countries',

        // Logging
        logging: 'py/logs',

        // Alerts
        operationalMessage: 'py/operational-message',

        // Translation
        translation: 'py/translations',

        // Invoice
        invoiceHistory: invoices,
        invoiceHistoryByCode: invoices + '/${invoiceCode}',
        invoicePdf: invoices + '/${invoiceCode}/pdf',
        invoiceHistoryListXlsxByInvoiceCode: invoices + '/${invoiceCode}/excel-export',
        invoiceHistoryListXlsxByDatesAndPaymentStatus: invoices + '/excel-export',

        // Sold-to
        soldTos: soldTos,
        soldTo: soldTos + '/${soldToId}',
        deliveryAddresses: soldTos + '/ship-tos',
        paymentOptions: soldTos + '/payment-options',

        // Price file
        priceFiles: priceFiles,
        priceFileStatus: priceFiles + '/${code}/status',
        priceFile: priceFiles + '/${code}',
        priceFileDelete: priceFiles + '/${code}',
        priceFileDownload: priceFiles + '/${code}/file',
        priceFileTemplates: priceFiles + '/templates',
        priceFileTemplate: priceFiles + '/templates/${code}',
        priceFileTemplateDelete: priceFiles + '/templates/${code}',
        priceFileTemplateUpdate: priceFiles + '/templates/${code}',
        priceFileTemplateFormats: priceFiles + '/templates/formats',
        priceFileAutoOrder: priceFiles + '/auto',

        // Cart
        carts: carts,
        buyerData: carts + '/buyer-data',
        addCartEntry: carts + '/article',
        addA4SampleCartEntry: carts + '/a4-sample',
        addCartEntries: carts + '/articles',
        cartEntries: cartEntries,
        cartEntry: cartEntry,
        cartEntriesMultiple: cartEntries + '/multiple',
        cartEntriesMultipleAccountingCode: cartEntries + '/multiple/accounting-code',
        quickAddCartEntry: carts + '/quick-add',
        cartEntryNote: cartEntry + '/note',
        cartEntryNameMarking: cartEntry + '/configuration/${qualifier}/${ruleId}/${extendedIdentifier}',
        cartEntryStatisticsCode: cartEntry + '/statistics-code',
        cartEntryAccountingCode: cartEntry + '/accounting-code',
        cartEntryCutting: cartEntry + '/cutting',
        cartEntryCuttingPrice: cartEntry + '/cutting/price',
        cartEntryCuttingTemplate: cartEntry + '/cutting/template',
        cartEntryReelCutting: cartEntry + '/reel-cutting',
        cartEntryReelCuttingPrice: cartEntry + '/reel-cutting/price',
        cartEntryReaming: cartEntry + '/reaming',
        cartEntryPalletFlags: cartEntry + '/pallet-flags',
        cartEntryFullPalletNote: cartEntry + '/full-pallet-note',
        tinyCarts: tinyCarts,
        tinyCart: tinyCarts + '/${cartCodeOrType}',
        cartComment: carts + '/comments',
        fullPalletUpgrade: cartEntry + '/full-pallet-upgrade',
        prePrintedLabelOptions: cartEntry + '/preprinted-labels/options',
        prePrintedLabelInnerPackageQuantityRequired: cartEntry + '/preprinted-labels/inner-package-quantity-required',
        prePrintedLabels: cartEntry + '/preprinted-labels',

        // Checkout
        placeOrder: orders,
        placeOrderWithMerge: orders + '/merge',
        activeOrderProcess: orders + '/active-online',
        cancelActiveOrderProcess: order + '/cancel',
        simulateCart: carts,
        setResolveOption: cartEntry,
        setDeliveryAddress: carts + '/ship-to',
        setDeliveryNote: carts + '/delivery-note',
        setOrderNote: carts + '/order-note',
        setOrUpdateComment: carts + '/comments',
        deleteComment: carts + '/comments',
        setPaymentOption: carts + '/payment-option',
        setInvoiceNote: carts + '/invoice-note',
        setCostPlace: carts + '/cost-place',
        setMessage: carts + '/message',
        setGoodsMarking: carts + '/goods-marking',
        setCustomerReference: carts + '/customer-reference',
        setPhoneNumber: carts + '/phone-number',
        setReinvoicing: carts + '/reinvoicing',
        holidays: users + '/holidays',
        getDeliveryDateInfo: users + '/ship-tos/${shipToId}/delivery-date',
        setShippingOption: carts + '/${shippingOptionType}',
        resetRequestedDeliveryDate: carts + '/requested-delivery-date/reset',
        setServiceOption: carts + '/${serviceOptionType}',
        getPaymentSetup: payment,
        proceedPayment: payment + '/${paymentProvider}',

        // Order
        orderHistory: orderHistories,
        orderHistoryByCode: orderHistories + '/${orderCode}',
        orderHistoryShipTos: orderHistories + '/ship-tos',
        orderHistoryUsers: orderHistories + '/users',
        orderDetails: order,
        orderProcessStatus: order + '/status',
        orderTracking: order + '/track-and-trace',
        lastOrderedArticles: users + '/last-ordered-articles',
        mostOrderedArticles: users + '/most-ordered-articles',
        deliveryNotePdf: orders + '/deliveries/${deliveryNoteCode}/pdf',
        orderHistoryListXlsxByOrderCode: orders + '/${orderCode}/excel-export',
        orderHistoryListXlsxByDates: orders + '/excel-export',

        // Order return
        orderReturn: orderReturn,
        orderReturnDraft: orderReturn + '/draft',
        orderReturnMedias: orderReturn + '/medias',
        orderReturnReasons: '/py/configs/returns',

        // Order approval
        orderApprovals: orderApprovalOrders,
        orderApprovalDetails: orderApprovalOrders + '/${orderNumber}/approval-details',
        orderApprovalsAdmin: orderApprovalAdminOrders,
        orderApprovalsBuyers: orderApproval + '/buyers',
        orderApproval: orderApprovalOrders + '/${orderNumber}',
        pendingApprovals: orderApprovalOrders + '/pending',
        pendingApproval: orderApprovalOrders + '/pending/${orderNumber}',
        b2bComments: orderApprovalOrders + '/${orderNumber}/comments',
        abortHijack: orderApproval + '/carts/${cartId}',
        recoverOrder: orderApprovalOrders + '/${orderNumber}/carts/${cartId}',

        //Purchase limits
        userOrderRules: users + '/orderRules',
        buyerOrderRules: users + '/order-rules',
        buyerOrderRule: users + '/order-rules/${permissionCode}',
        buyerOrderRuleUsage: users + '/order-rules/${permissionCode}/buyer-usage',
        buyerOrderRuleGeneralUsage: users + '/order-rules/${permissionCode}/general-usage',
        buyerOrderRuleOrders: users + '/order-rules/${permissionCode}/orders',
        buyerOrderRuleShipTos: users + '/order-rules/${permissionCode}/shiptos',
        orderRules: orderRules,
        orderRule: orderRules + '/${permissionCode}',
        orderRuleOrders: orderRules + '/${permissionCode}/orders',
        orderRuleCreate: orderRules + '/${permissionCode}/users',
        orderRuleUsers: orderRules + '/${permissionCode}/users',
        orderRuleSoldTos: orderRules + '/${permissionCode}/soldtos',
        orderRuleShipTos: orderRules + '/${permissionCode}/shiptos',
        orderRuleUpdateContactPerson: orderRules + '/${permissionCode}/contact-person/${contactPersonRef}',
        orderRulePublication: orderRules + '/${permissionCode}/publication',

        // Polling data
        feed: feed,

        // Order cards
        orderCards: orderCards,
        orderCard: orderCards + '/${code}',
        orderCardConfiguration: orderCards + '/${code}/configuration',
        orderCardConfigurations: orderCards + '/${code}/configurations',

        adminOrderCards: orderCardsAdmin,
        adminOrderCard: orderCardsAdmin + '/${code}',
        updateAdminOrderCardStatus: orderCardsAdmin + '/${code}/status',
        addOrDeleteAdminOrderCardPrincipal: orderCardsAdmin + '/${code}/principal',
        updateAdminOrderCardPrincipals: orderCardsAdmin + '/${code}/principals',
        updateAdminOrderCardProducts: orderCardsAdmin + '/${code}/products',
        addOrDeleteAdminOrderCardProduct: orderCardsAdmin + '/${code}/product',
        replaceAdminOrderCardProduct: orderCardsAdmin + '/${code}/product/${oldProductCode}',
        addOrDeleteAdminOrderCardArticles: orderCardsAdmin + '/${code}/articles',
        orderCardAdminConfiguration: orderCardsAdmin + '/${code}/configuration',
        orderCardAdminConfigurations: orderCardsAdmin + '/${code}/configurations',

        // Accounting codes
        accountingCodesAdmin: accountingCodesAdmin,
        accountingCodeAdmin: accountingCodesAdmin + '/${code}',
        accountingCodesExportXlsAdmin: accountingCodesAdmin + '/export/xls',
        accountingCodesImportXlsAdmin: accountingCodesAdmin + '/import/xls',
        accountingCodeUserInfo: accountingCodesAdmin + '/metadata',

        // User
        user: users,
        userRegister: 'py/signup',
        userForgotPassword: 'py/password/reset-email',
        userForgotUsername: 'py/remind/username',
        userResetPassword: 'py/password',
        credentials: users + '/credentials',
        principalConfigurations: users + '/principal-configurations',
        statistics: users + '/statistics/${query}',
        statisticsDownload: users + '/statistics/${type}/xls',
        customDeliveryAddresses: customShipTos,
        customDeliveryAddress: customShipTos + '/${id}',
        changeSoldTo: users + '/sold-to',
        availableShipTosWithSoldTo: users + '/ship-tos/with-sold-to-assignment',
        availableShipTos: users + '/ship-tos',
        anonymousConsentTemplates: 'users/anonymous/consenttemplates',
        consentTemplates: 'users/${userId}/consenttemplates',
        consents: 'users/${userId}/consents',
        consentDetail: 'users/${userId}/consents/${consentId}',
        consentDenial: users + '/consents/consent-templates/${consentTemplateId}/${consentTemplateVersion}/deny',
        punchOutData: users + '/punchout/data',
        analyticsMetadata: users + '/analytics',
        punchOut: carts + '/punchout',

        // Shopping list
        shoppingLists: shoppingLists,
        writableShoppingLists: shoppingLists + '/writable',
        shoppingList: shoppingList,
        shoppingListPrincipals: shoppingList + '/principals',
        shoppingListName: shoppingList + '/name',
        shoppingListDescription: shoppingList + '/description',
        shoppingListContactPerson: shoppingList + '/contact-person/${customerId}',
        shoppingListEntry: shoppingListEntry,
        shoppingListEntriesMultiple: shoppingListEntries + '/multiple',
        shoppingListEntryNote: shoppingListEntry + '/note',
        shoppingListEntryStatisticsCode: shoppingListEntry + '/statistics-code',
        shoppingListPdf: shoppingList + '/pdf-export',
        shoppingListXlsx: shoppingList + '/excel-export',

        // Catalog
        addonGroups: users + '/catalog/addon-groups',
        addonGroup: users + '/catalog/addon-groups/${addonGroupId}',
        addonNode: users + '/catalog/addon-groups/${addonGroupId}/nodes/${addonNodeId}',
        areasOfUse: users + '/catalog/areas-of-use/nav',
        articles: articles,
        catalogKeys: users + '/catalog/keys',
        categories: users + '/catalog/categories',
        products: users + '/catalog/products',
        a4SampleColors: users + '/catalog/products/${productCode}/a4-sample-colors',
        substituteProducts: users + '/catalog/products/${productCode}/substitutes',
        productDocuments: users + '/catalog/product/${productCode}/documents',
        productDetails: users + '/catalog/products/${productCode}/details/${topic}',

        price: articles + '/price',
        prices: articles + '/prices-async',
        stockInfo: articles + '/${articleNumber}/stock-info',
        stockInfos: articles + '/stock-info',
        similarArticles: articles + '/${articleNumber}/alternatives',
        substitutesArticles: articles + '/${articleCode}/substitutes',

        // Non-sellable product
        nonSellableProductInquiry: users + '/catalog/products/inquiry',

        // Declaration Of Conformity
        conformanceEcBrands: '/py/conformance/ec/brands',
        conformanceEcSearch: '/py/conformance/ec/search',
        // recommendations
        topProducts: users + '/recommendation/products/top',
        apptusPanel: users + '/recommendation/panel',
        userProducts: users + '/recommendation/products',

        // Search
        search: users + '/catalog/search',
        searchArticles: users + '/catalog/search/articles',
        searchProducts: users + '/catalog/search/products',
        searchHints: users + '/catalog/omnisearch-hints',

        // Area of Use admin
        areasOfUseAdmin: areasOfUseAdmin,
        areasOfUseAdminPublish: areasOfUseAdmin + '/publish',
        areaOfUseAdmin: areasOfUseAdmin + '/${code}',
        areasOfUseProduct: areasOfUseAdmin + '/product/${productCode}',
        areaOfUseProductReplace: areasOfUseAdmin + '/${categoryCode}/product/replace/${oldProductCode}/${newProductCode}',
        areaOfUseProductAdd: areasOfUseAdmin + '/${categoryCode}/product/${productCode}',
        areaOfUseProductsRemove: areasOfUseAdmin + '/${categoryCode}/products',
        areaOfUseExcludeArticles: areasOfUseAdmin + '/${categoryCode}/articles/exclude',
        areaOfUseAdminDownload: areasOfUseAdmin + '/${areaOfUseId}/export/xls',
        areaOfUseAdminImport: areasOfUseAdmin + '/${areaOfUseId}/import/xls',
        areaOfUseAdminDownloadTemplate: areasOfUseAdmin + '/${areaOfUseId}/template/xls',

        // Price campaign
        priceCampaigns: priceCampaigns,
        priceCampaign: priceCampaigns + '/${priceCampaignCode}',

        // News
        news: 'py/news',

        // Assortment
        customerFreeStock: users + '/assortment/customer-free-stock',
        consignmentStockArticles: users + '/assortment/customer-consignment-stock',
        consignmentStockArticleStockInformation:
          users + '/assortment/customer-consignment-stock' + '/${articleNumber}/stock-info',

        // Misc
        usernameCheck: 'py/validation/username',
        passwordCheck: 'py/validation/password',
        postalCodeCheck: 'py/validation/postal-code',
        phoneNumberCheck: 'py/validation/phone',
        emailCheck: 'py/validation/emails/multiple-users',
        nameCheck: 'py/validation/name',
        changeCustomerSessionLanguage: users + '/language',
        registrationNumberCheck: 'py/validation/registration-number',
        registrationEmailCheck: 'py/validation/email',
        registrationUsernameDuplicateCheck: 'py/validation/username',
        signupWorksites: '/py/signup/worksites',
        signupWorksite: '/py/signup/worksites/${id}',
        dataImportXlsReport: dataImports + '/${dataImportId}/export/xls',

        // Site
        siteFamilyConfig: '/py/configs/site-family',
        siteConfig: '/py/configs/site',
      },
    },
    media: {},
  },
};
