import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { SapPriceCampaignDetails, SapPriceCampaignList } from '../../../model';
import { Occ } from '../../../occ';
import { ConverterService } from '../../../util/converter.service';
import { PRICE_CAMPAIGN_DETAILS_NORMALIZER } from './converters';

@Injectable({
  providedIn: 'root',
})
export class PriceCampaignConnector {
  constructor(
    private httpClient: HttpClient,
    private occEndpointsService: OccEndpointsService,
    private converterService: ConverterService
  ) {}

  loadPriceCampaignDetails(priceCampaignCode: string): Observable<SapPriceCampaignDetails> {
    return this.httpClient
      .get<Occ.SapPriceCampaignDetails>(
        this.occEndpointsService.buildUrl('priceCampaign', {
          urlParams: {
            priceCampaignCode: priceCampaignCode,
          },
        })
      )
      .pipe(this.converterService.pipeable(PRICE_CAMPAIGN_DETAILS_NORMALIZER));
  }

  loadAvailablePriceCampaigns(): Observable<SapPriceCampaignList> {
    return this.httpClient.get<Occ.SapPriceCampaignList>(this.occEndpointsService.buildUrl('priceCampaigns'));
  }
}
