import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * Temporary fix to our routing configuration with CMS-components and child-routes.
 */
@Component({
  selector: 'py-router-outlet-wrapper',
  template: `<router-outlet></router-outlet>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouterOutletWrapperComponent {}
