<ng-container *ngIf="loading; else loaded">
  <div class="d-flex flex-column align-items-center">
    <cx-spinner class="mt-3 h1"></cx-spinner>
    <h5>{{ translationKeyPrefix + '.loadingSpinner_hint' | cxTranslate }}</h5>
  </div>
</ng-container>
<ng-template #loaded>
  <div
    *ngIf="hasDeliveryTab"
    class="delivery-section d-flex no-gutters py-3"
    [class.open]="showDeliverySection"
    (click)="onShowDeliveryToggle()"
  >
    <h4 class="col-11">{{ translationKeyPrefix + '.delivery_action' | cxTranslate }}</h4>
    <i nz-icon nzType="down" class="col-1 d-flex justify-content-end"></i>
  </div>

  <ul class="fields" *ngIf="showDeliverySection">
    <!-- SHIPPING CONDITION -->
    <ng-container
      *ngTemplateOutlet="
        optionTemplate;
        context: {
          $implicit: shippingConditionTextBodyTemplate,
          active: deliveryTab?.fields?.shippingCondition?.description,
          label: translationKeyPrefix + '.deliveryMethod_heading' | cxTranslate
        }
      "
    ></ng-container>
    <ng-template #shippingConditionTextBodyTemplate>
      <div *ngIf="deliveryTab?.fields?.shippingCondition?.description">
        {{ deliveryTab.fields.shippingCondition.description }}
      </div>
      <p *ngIf="deliveryTab?.fields?.sapTimeWindowTimeCode | timeCodeLabel as timeWindowCodeLabel">
        {{ 'checkout.timeWindowCodeDelivery.selectedTimeSlot_hint' | cxTranslate : { label: timeWindowCodeLabel } }}
      </p>
      <ng-container *pyPrincipalConfiguration="'enableCarryBeyondLoadingBay'">
        <div *ngIf="!!deliveryTab?.fields?.carryBeyondLoadingBayOption">
          {{ translationKeyPrefix + '.carryBeyondLoadingBay_hint' | cxTranslate }}
        </div>
      </ng-container>
    </ng-template>

    <!-- DELIVERY ADDRESS -->
    <ng-container
      *ngTemplateOutlet="
        optionTemplate;
        context: {
          $implicit: deliveryAddressBodyTemplate,
          active: deliveryTab?.fields?.deliveryAddress,
          label: translationKeyPrefix + '.deliveryAddress_heading' | cxTranslate
        }
      "
    ></ng-container>
    <ng-template #deliveryAddressBodyTemplate>
      <div>{{ deliveryTab?.fields?.deliveryAddress?.name1 }}</div>
      <div>{{ deliveryTab?.fields?.deliveryAddress?.name2 }}</div>
      <div>{{ deliveryTab?.fields?.deliveryAddress?.name3 }}</div>
      <div>{{ deliveryTab?.fields?.deliveryAddress?.name4 }}</div>
      <div>{{ deliveryTab?.fields?.deliveryAddress?.street }}</div>
      <div>{{ deliveryTab?.fields?.deliveryAddress?.postalCode }} {{ deliveryTab?.fields?.deliveryAddress?.city }}</div>
      <div *pyDisplayCountries>{{ deliveryTab?.fields?.deliveryAddress?.country?.name }}</div>
      <div *ngIf="!deliveryTab?.fields?.deliveryAddress?.custom && deliveryTab?.fields?.deliveryAddress?.id">
        ({{ deliveryTab?.fields?.deliveryAddress?.id }})
      </div>
    </ng-template>

    <!-- CUSTOMER NUMBER -->
    <ng-container
      *ngTemplateOutlet="
        optionTemplate;
        context: {
          $implicit: customerNumberBodyTemplate,
          active: deliveryTab?.fields?.soldToObject,
          label: translationKeyPrefix + '.customerNumber_hint' | cxTranslate
        }
      "
    ></ng-container>
    <ng-template #customerNumberBodyTemplate>
      <ng-container *ngIf="deliveryTab?.fields?.soldToObject">
        <div *ngIf="deliveryTab.fields.soldToObject?.customerAddress?.name1">
          {{ deliveryTab.fields.soldToObject.customerAddress.name1 }}
        </div>
        <div *ngIf="deliveryTab.fields.soldToObject?.customerAddress?.name2">
          {{ deliveryTab.fields.soldToObject.customerAddress.name2 }}
        </div>
        <div *ngIf="deliveryTab.fields.soldToObject?.customerAddress?.city">
          {{ deliveryTab.fields.soldToObject.customerAddress.city }}
        </div>
        <div *ngIf="deliveryTab.fields.soldToObject?.uid">({{ deliveryTab.fields.soldToObject.uid }})</div>
      </ng-container>
    </ng-template>

    <!-- ORDER CREATED DATE -->
    <ng-container
      *ngTemplateOutlet="
        optionTemplate;
        context: {
          $implicit: orderDateBodyTemplate,
          active: deliveryTab?.fields?.creationDate,
          label: translationKeyPrefix + '.orderCreated_heading' | cxTranslate
        }
      "
    ></ng-container>
    <ng-template #orderDateBodyTemplate>
      <ng-container *ngIf="deliveryTab?.fields?.creationDate">
        <div>{{ deliveryTab.fields.creationDate | cxDate : 'dateTime' }}</div>
      </ng-container>
    </ng-template>

    <!-- WEIGHT -->
    <ng-container
      *ngTemplateOutlet="
        optionTemplate;
        context: {
          $implicit: weightBodyTemplate,
          active: deliveryTab?.fields?.totalWeight,
          label: translationKeyPrefix + '.totalWeight_heading' | cxTranslate
        }
      "
    ></ng-container>
    <ng-template #weightBodyTemplate>
      <div *ngIf="deliveryTab?.fields?.totalWeight?.value && deliveryTab?.fields?.totalWeight?.unit?.code">
        {{ deliveryTab.fields.totalWeight.value }} {{ deliveryTab.fields.totalWeight.unit.code }}
      </div>
    </ng-template>
  </ul>

  <div
    *ngIf="hasNotesTab"
    class="note-section d-flex no-gutters py-3"
    [class.open]="showNotesSection"
    (click)="onShowNotesToggle()"
  >
    <h4 class="col-11">{{ translationKeyPrefix + '.notes_title' | cxTranslate }}</h4>
    <i nz-icon nzType="down" class="col-1 d-flex justify-content-end"></i>
  </div>

  <ul class="fields" *ngIf="showNotesSection">
    <!-- YOUR REFERENCE -->
    <ng-container *pyPrincipalConfiguration="'enableCustomerReference'">
      <ng-container
        *ngTemplateOutlet="
          optionTemplate;
          context: {
            $implicit: customerReferenceBodyTemplate,
            active: notesTab?.fields?.customerReference,
            label:
              ('customerReferenceLabel' | principalConfigValue) ||
              (translationKeyPrefix + '.customerReference_heading' | cxTranslate),
            tooltip: translationKeyPrefix + '.customerReference_tooltip' | cxTranslate
          }
        "
      ></ng-container>
      <ng-template #customerReferenceBodyTemplate>
        <div *ngIf="notesTab?.fields?.customerReference">{{ notesTab.fields.customerReference }}</div>
      </ng-template>
    </ng-container>

    <!-- ORDER NOTE -->
    <ng-container *pyPrincipalConfiguration="'enableOrderNote'">
      <ng-container
        *ngTemplateOutlet="
          optionTemplate;
          context: {
            $implicit: orderNoteBodyTemplate,
            active: notesTab?.fields?.orderNote,
            label: ('orderNoteLabel' | principalConfigValue) || (translationKeyPrefix + '.orderNote_heading' | cxTranslate)
          }
        "
      ></ng-container>
      <ng-template #orderNoteBodyTemplate>
        <div *ngIf="notesTab?.fields?.orderNote">{{ notesTab.fields.orderNote }}</div>
      </ng-template>
    </ng-container>

    <!-- PHONE NUMBER -->
    <ng-container *pyPrincipalConfiguration="'enablePhoneNumberInCheckout'">
      <ng-container
        *ngTemplateOutlet="
          optionTemplate;
          context: {
            $implicit: phoneNumberBodyTemplate,
            active: notesTab?.fields?.phoneNumber,
            label: translationKeyPrefix + '.phoneNumber_heading' | cxTranslate
          }
        "
      ></ng-container>
      <ng-template #phoneNumberBodyTemplate>
        <div *ngIf="notesTab?.fields?.phoneNumber">{{ notesTab.fields.phoneNumber }}</div>
      </ng-template>
    </ng-container>

    <!-- GOODS MARKING -->
    <ng-container *pyPrincipalConfiguration="'enableGoodsMarking'">
      <ng-container
        *ngTemplateOutlet="
          optionTemplate;
          context: {
            $implicit: goodsMarkingBodyTemplate,
            active: notesTab?.fields?.goodsMarking,
            label:
              ('mandatoryGoodsmarkingLabel' | principalConfigValue) ||
              (translationKeyPrefix + '.goodsMarking_heading' | cxTranslate),
            tooltip: translationKeyPrefix + '.goodsMarking_tooltip' | cxTranslate
          }
        "
      ></ng-container>
      <ng-template #goodsMarkingBodyTemplate>
        <div *ngIf="notesTab?.fields?.goodsMarking">{{ notesTab.fields.goodsMarking }}</div>
      </ng-template>
    </ng-container>

    <!-- INVOICE NOTE -->
    <ng-container *pyPrincipalConfiguration="'enableInvoiceNote'">
      <ng-container
        *ngTemplateOutlet="
          optionTemplate;
          context: {
            $implicit: invoiceNoteBodyTemplate,
            active: notesTab?.fields?.invoiceNote,
            label: ('invoiceNoteLabel' | principalConfigValue) || (translationKeyPrefix + '.invoiceNote_heading' | cxTranslate),
            tooltip: translationKeyPrefix + '.invoiceNote_tooltip' | cxTranslate
          }
        "
      ></ng-container>
      <ng-template #invoiceNoteBodyTemplate>
        <div *ngIf="notesTab?.fields?.invoiceNote">{{ notesTab.fields.invoiceNote }}</div>
      </ng-template>
    </ng-container>

    <!-- SHIPPING NOTE -->
    <ng-container *pyPrincipalConfiguration="'enableDeliveryNote'">
      <ng-container
        *ngTemplateOutlet="
          optionTemplate;
          context: {
            $implicit: deliveryNoteBodyTemplate,
            active: notesTab?.fields?.deliveryNote,
            label: translationKeyPrefix + '.transportNote_heading' | cxTranslate,
            tooltip: translationKeyPrefix + '.transportNote_tooltip' | cxTranslate
          }
        "
      ></ng-container>
      <ng-template #deliveryNoteBodyTemplate>
        <div *ngIf="notesTab?.fields?.deliveryNote">{{ notesTab.fields.deliveryNote }}</div>
      </ng-template>
    </ng-container>

    <!-- CHECKOUT MESSAGE -->
    <ng-container *ngIf="!!notesTab?.fields?.message">
      <ng-container
        *ngTemplateOutlet="
          optionTemplate;
          context: {
            $implicit: checkoutMessageBodyTemplate,
            active: notesTab?.fields?.message,
            label: translationKeyPrefix + '.message_heading' | cxTranslate
          }
        "
      ></ng-container>
      <ng-template #checkoutMessageBodyTemplate>
        <div *ngIf="notesTab?.fields?.message">{{ notesTab.fields.message }}</div>
      </ng-template>
    </ng-container>

    <!-- COST PLACE -->
    <ng-container *pyPrincipalConfiguration="'enableCostPlace'">
      <ng-container
        *ngTemplateOutlet="
          optionTemplate;
          context: {
            $implicit: costPlaceBodyTemplate,
            active: notesTab?.fields?.costPlace,
            label: translationKeyPrefix + '.costPlace_heading' | cxTranslate
          }
        "
      ></ng-container>
      <ng-template #costPlaceBodyTemplate>
        <div *ngIf="notesTab?.fields?.costPlace">{{ notesTab.fields.costPlace }}</div>
      </ng-template>
    </ng-container>

    <ng-container *pyPrincipalConfiguration="'enableReinvoicing'">
      <!-- CONTRACT NUMBER -->
      <ng-container
        *ngTemplateOutlet="
          optionTemplate;
          context: {
            $implicit: contractNumberBodyTemplate,
            active: notesTab?.fields?.reinvoicing?.contractNumber,
            label:
              ('reinvoicingIdLabel' | principalConfigValue) || (translationKeyPrefix + '.contractNumber_heading' | cxTranslate)
          }
        "
      ></ng-container>
      <ng-template #contractNumberBodyTemplate>
        <div *ngIf="notesTab?.fields?.reinvoicing?.contractNumber">{{ notesTab.fields.reinvoicing.contractNumber }}</div>
      </ng-template>

      <!-- CUSTOMER NUMBER -->
      <ng-container
        *ngTemplateOutlet="
          optionTemplate;
          context: {
            $implicit: customerNumberBodyTemplate,
            active: notesTab?.fields?.reinvoicing?.customerNumber,
            label:
              ('reinvoicingCustomerNumberLabel' | principalConfigValue) ||
              (translationKeyPrefix + '.customerNumber_heading' | cxTranslate)
          }
        "
      ></ng-container>
      <ng-template #customerNumberBodyTemplate>
        <div *ngIf="notesTab?.fields?.reinvoicing?.customerNumber">{{ notesTab.fields.reinvoicing.customerNumber }}</div>
      </ng-template>

      <!-- REFERENCE NUMBER 1 -->
      <ng-container
        *ngTemplateOutlet="
          optionTemplate;
          context: {
            $implicit: referenceNumber1BodyTemplate,
            active: notesTab?.fields?.reinvoicing?.referenceNumber1,
            label:
              ('reinvoicingReferenceNumberLabel' | principalConfigValue) ||
              (translationKeyPrefix + '.referenceNumber1_heading' | cxTranslate)
          }
        "
      ></ng-container>
      <ng-template #referenceNumber1BodyTemplate>
        <div *ngIf="notesTab?.fields?.reinvoicing?.referenceNumber1">{{ notesTab.fields.reinvoicing.referenceNumber1 }}</div>
      </ng-template>

      <!-- REFERENCE NUMBER 2 -->
      <ng-container
        *ngTemplateOutlet="
          optionTemplate;
          context: {
            $implicit: referenceNumber2BodyTemplate,
            active: notesTab?.fields?.reinvoicing?.referenceNumber2,
            label:
              ('reinvoicingReferenceNumberLabel2' | principalConfigValue) ||
              (translationKeyPrefix + '.referenceNumber2_heading' | cxTranslate)
          }
        "
      ></ng-container>
      <ng-template #referenceNumber2BodyTemplate>
        <div *ngIf="notesTab?.fields?.reinvoicing?.referenceNumber2">{{ notesTab.fields.reinvoicing.referenceNumber2 }}</div>
      </ng-template>
    </ng-container>
  </ul>

  <div
    *ngIf="hasPaymentTab"
    class="payment-section d-flex no-gutters py-3"
    [class.open]="showPaymentSection"
    (click)="onShowPaymentToggle()"
  >
    <h4 class="col-11">{{ translationKeyPrefix + '.payment_title' | cxTranslate }}</h4>
    <i nz-icon nzType="down" class="col-1 d-flex justify-content-end"></i>
  </div>

  <ul class="fields" *ngIf="showPaymentSection">
    <!-- PAYMENT METHOD -->
    <ng-container
      *ngTemplateOutlet="
        optionTemplate;
        context: {
          $implicit: paymentMethodBodyTemplate,
          active: paymentTab?.fields?.paymentMethod,
          label: translationKeyPrefix + '.paymentMethod_heading' | cxTranslate
        }
      "
    ></ng-container>
    <ng-template #paymentMethodBodyTemplate>
      <div *ngIf="paymentTab?.fields?.paymentMethod">
        {{ translationKeyPrefix + '.paymentTerms' | cxTranslate : { context: paymentTab.fields.paymentMethod } }}
      </div>
    </ng-template>

    <!-- Order value -->
    <ng-container
      *ngTemplateOutlet="
        optionTemplate;
        context: {
          active: paymentTab?.fields?.totalPrice,
          label: translationKeyPrefix + '.totalOrderValue_heading' | cxTranslate,
          price: paymentTab?.fields?.totalPrice
        }
      "
    ></ng-container>

    <!-- Sap Order Conditions -->
    <ng-container *ngFor="let condition of paymentTab?.fields?.conditions">
      <ng-container
        *ngTemplateOutlet="
          optionTemplate;
          context: {
            active: condition?.value,
            label: condition?.name,
            price: condition?.value
          }
        "
      ></ng-container>
    </ng-container>

    <!-- Small Order Fee -->
    <ng-container *ngIf="paymentTab?.fields?.showFees">
      <ng-container
        *ngTemplateOutlet="
          optionTemplate;
          context: {
            active: paymentTab?.fields?.totalFeePrice,
            label: translationKeyPrefix + '.totalOrderLineFees_heading' | cxTranslate,
            price: paymentTab?.fields?.totalFeePrice
          }
        "
      ></ng-container>
    </ng-container>

    <!-- Total value -->
    <ng-container
      *ngTemplateOutlet="
        optionTemplate;
        context: {
          active: paymentTab?.fields?.totalPriceWithTax,
          label: translationKeyPrefix + '.totalOrderValueWithTax_heading' | cxTranslate,
          emphasize: true,
          price: paymentTab?.fields?.totalPriceWithTax
        }
      "
    ></ng-container>
  </ul>

  <ng-template
    #optionTemplate
    let-body
    let-active="active"
    let-label="label"
    let-price="price"
    let-discountPrice="discountPrice"
    let-emphasize="emphasize"
    let-tooltip="tooltip"
  >
    <li *ngIf="active" class="py-3">
      <div class="item-label">
        <span>{{ label }}</span
        ><i *ngIf="tooltip" nz-icon nzType="question-circle" class="ml-2" [ngbTooltip]="tooltip"></i>
      </div>

      <div class="item-value">
        <div class="body">
          <ng-container *ngTemplateOutlet="body"></ng-container>
        </div>
        <ng-container *pyPrincipalConfiguration="'enableDisplayOfPrices'">
          <div *ngIf="price?.value > 0">
            <span
              class="price d-block"
              [class.discounted]="discountPrice && discountPrice.value < price.value"
              [class.emphasize]="emphasize"
              >{{ price | price : 'symbol-narrow' : true }}</span
            >
            <span class="discount" *ngIf="discountPrice && discountPrice.value < price.value">{{
              discountPrice | price : 'symbol-narrow' : true
            }}</span>
          </div>
        </ng-container>
      </div>
    </li>
  </ng-template>
</ng-template>
