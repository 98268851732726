import { Occ } from '../occ';
import { Category } from './catalog.model';
import { Pagination, Sort } from './search.model';

export import PriceFileStatus = Occ.PriceFileStatusEnum;

export interface PriceFileListPage extends Omit<Occ.PriceFileListPage, 'results'> {
  results?: PriceFileEntry[];
}

export interface PriceFileRequestTemplateSearchResult {
  results?: PriceFileRequestTemplate[];
  sorts?: Sort[];
}

export interface PriceFileFormatsSearchResult {
  results?: PriceFileFormat[];
  pagination?: Pagination;
  sorts?: Sort[];
}

export interface PriceFileEntry extends Omit<Occ.PriceFile, 'delivered'> {
  delivered?: Date;
  categories?: Array<Category>;
}

export interface PriceFileRequest extends Occ.PriceFileRequest {
  code: string;
  selectedCategories: Array<Category>;
}

export interface PriceFileRequestTemplate extends PriceFileRequest {
  code: string;
  name: string;
  type?: string;
}

export import PriceFileFormat = Occ.PriceFileTemplate;
