import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule } from '../../../core/i18n';
import { PrincipalConfigurationDirectiveModule } from '../../directives';
import { ArticleImageModule } from '../article';
import { BadgeModule } from '../badge';
import { CarouselModule } from '../carousel';
import { GenericLinkModule } from '../generic-link';
import { MediaModule } from '../media';
import { ProductCarouselComponent } from './product-carousel.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MediaModule,
    GenericLinkModule,
    I18nModule,
    ArticleImageModule,
    CarouselModule,
    BadgeModule,
    PrincipalConfigurationDirectiveModule,
  ],
  declarations: [ProductCarouselComponent],
  exports: [ProductCarouselComponent],
})
export class ProductCarouselModule {}
