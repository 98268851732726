import { Action } from '@ngrx/store';
import { PaginationState, SearchPageResultState } from '../../../features/store/base-feature-state';
import { ShipToPage } from '../../../model';
import { loaderReducer } from '../../../state/utils/loader';
import { UserActions } from '../actions';
import { SHIP_TOS } from '../user-state';
import { reducer as shipTosEntriesReducer } from './ship-tos-entries.reducer';

export const initialState: PaginationState<SearchPageResultState> = {
  lastUpdateTime: undefined,
  pages: {},
  pagination: undefined,
  sorts: [],
};

export function reducer(state = initialState, action: Action): any {
  switch (action.type) {
    case UserActions.LOAD_USER_SHIPTOS:
    case UserActions.LOAD_USER_SHIPTOS_FAIL: {
      const shipTosAction = <UserActions.LoadUserShipTos>action;
      const searchParams = shipTosAction.searchParams;
      const loaderState = loaderReducer<SearchPageResultState>(SHIP_TOS, shipTosEntriesReducer)(undefined, shipTosAction);
      return {
        ...state,
        pages: {
          ...state.pages,
          [searchParams.page]: loaderState,
        },
      };
    }
    case UserActions.LOAD_USER_SHIPTOS_SUCCESS: {
      const successAction = <UserActions.LoadUserShipTosSuccess>action;
      const payload: ShipToPage = successAction.payload;
      const searchParams = successAction.searchParams;
      const loaderState = loaderReducer<SearchPageResultState>(SHIP_TOS, shipTosEntriesReducer)(
        { ...state.pages[searchParams.page] },
        successAction
      );
      return {
        ...state,
        lastUpdateTime: new Date().toISOString(),
        pagination: payload.pagination,
        sorts: payload.sorts,
        pages: {
          ...state.pages,
          [searchParams.page]: loaderState,
        },
      };
    }
    default:
      return state;
  }
}
