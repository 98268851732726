<ng-container *ngIf="hasSingleItem; else multipleItems">
  <button
    class="btn btn-ternary single-item d-flex justify-content-center align-items-center align-self-center"
    (click)="downloadSingleItem()"
  >
    <i nz-icon nzType="download" nzTheme="outline"></i>
    <span>{{ buttonText }}</span>
  </button>
</ng-container>

<ng-template #multipleItems>
  <button
    #popOver="ngbPopover"
    class="btn btn-ternary multiple-items d-flex justify-content-center align-items-center align-self-center"
    [ngbPopover]="popContent"
    [popoverClass]="'download-dropdown-popover'"
    [autoClose]="'outside'"
    [placement]="placement"
    container="body"
  >
    <i nz-icon nzType="download" nzTheme="outline"></i>
    <span>{{ buttonText }}</span>
  </button>

  <ng-template #popContent>
    <div class="content-wrapper">
      <div class="header-wrapper d-flex justify-content-center align-items-center pl-4">
        <div class="title col-10 float-left px-0">
          {{ dropdownTitle }}
        </div>
        <button class="col-auto" (click)="closeDropdown()">
          <i
            class="d-flex align-items-end justify-content-end"
            nz-icon
            pyIconThemeReverse
            nzType="close-circle"
            nzTheme="outline"
          ></i>
        </button>
      </div>

      <ul *ngIf="downloadItems$ | async as downloadItems">
        <li
          class="py-2 px-4 d-flex align-items-center"
          *ngFor="let downloadItem of downloadItems"
          [ngClass]="{ disabled: !(downloadEnabled$ | async) || isActionInProgress }"
          (click)="download(downloadItem)"
        >
          <i
            *ngIf="downloadItem?.icon"
            class="d-flex align-items-center justify-content-center"
            nz-icon
            [nzType]="downloadItem.icon"
            nzTheme="outline"
          ></i>
          <span class="pl-3">{{ downloadItem?.name }}</span>
          <span class="pl-3 d-flex ml-auto">
            <cx-spinner *ngIf="isActionInProgress && clickedItem?.code === downloadItem?.code"></cx-spinner>
          </span>
        </li>
      </ul>
    </div>
  </ng-template>
</ng-template>
