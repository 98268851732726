import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CartType, OrderEntry } from '../../../core/model';
import { ActiveCartFacade } from '../../../features/cart/base';

@Component({
  selector: 'py-pallet-flag-note',
  templateUrl: './pallet-flag-note.component.html',
  styleUrls: ['./pallet-flag-note.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PalletFlagNoteComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  private entry: OrderEntry;

  @Input() cartType: CartType;
  @Input() entryNumber: number;
  @Input() entryId: string;

  fullPalletNote: boolean;

  constructor(private activeCartService: ActiveCartFacade, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.subscription.add(
      this.activeCartService.getEntryById(this.entryId, this.cartType).subscribe((entry) => {
        this.entry = entry;
        this.fullPalletNote = entry.fullPalletNote;
        this.cd.detectChanges();
      })
    );
  }

  toggleEntryFullPalletNote(): void {
    this.fullPalletNote
      ? this.activeCartService.removeEntryFullPalletNote(this.entry, this.cartType)
      : this.activeCartService.addEntryFullPalletNote(this.entry, this.cartType);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
