import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InterceptorUtil, OccEndpointsService, USE_CLIENT_TOKEN } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { catchError, map, mapTo } from 'rxjs/operators';
import { CredentialsValidation, ResetPasswordEmailUserInputCheck, UserInputCheck } from '../../model';

@Injectable({
  providedIn: 'root',
})
export class CredentialsConnector {
  constructor(private httpClient: HttpClient, private occEndpointsService: OccEndpointsService) {}

  isUsernameAvailable(username: string): Observable<UserInputCheck> {
    const url = this.occEndpointsService.buildUrl('usernameCheck', { queryParams: { username: username } });
    const headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true);
    return this.httpClient.get(url, { headers }).pipe(
      mapTo({ valid: true, message: '' }),
      catchError((res) => {
        return of({ valid: false, message: res.error?.errors[0]?.message });
      })
    );
  }

  isPasswordValid(password: string, username: string): Observable<UserInputCheck> {
    const url = this.occEndpointsService.buildUrl('passwordCheck');
    const headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true);
    return this.httpClient.post<CredentialsValidation>(url, { uid: username, password: password }, { headers }).pipe(
      mapTo({ valid: true, message: '' }),
      catchError((res) => {
        return of({ valid: false, message: res.error?.errors[0]?.message });
      })
    );
  }

  isEmailRegistered(email: string): Observable<ResetPasswordEmailUserInputCheck> {
    const url = this.occEndpointsService.buildUrl('emailCheck', { queryParams: { email: email } });
    const headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true);
    return this.httpClient.get<boolean>(url, { headers }).pipe(
      map((value) => {
        return { valid: true, multipleUsers: value, message: '' };
      }),
      catchError((res) => {
        return of({ valid: false, multipleUsers: undefined, message: res.error?.errors[0]?.message });
      })
    );
  }
}
