import { Injectable } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { RoutingService } from '@spartacus/core';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  shareReplay,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { CatalogRouterStateService } from '../../../../core/catalog';
import {
  Facet,
  SolrSearchConfig,
  SolrSearchResult,
  SolrSearchSortingDefaults,
  SolrSearchType,
  SortModel,
} from '../../../../core/model';
import { SearchService } from '../../../../core/search';
import { CurrencyService, LanguageService } from '../../../../core/site-context';
import { PrincipalConfigurationService } from '../../../../core/user';
import { findLastIndex, shallowEqualObjects } from '../../../../core/util';

interface ProductListRouteParams {
  c?: string; // Category ref
  a?: string; // Article ref
  p?: string; // Product ref
  context?: string;
  query?: string;
  tab?: string;
  t?: string; // Tendered
  o?: string; // Outlet
  mya?: string; // My assortment
  aou?: string; // Area of use
  sort?: string; // Default: score
  pageSize?: number; // Default: 12
  all?: boolean; // True will fetch all articles for product
  discontinued?: boolean;
  currentPage?: number;
}

export enum SortPrefix {
  Product = 'p_',
  Article = 'a_',
}

export enum CatalogTabTypes {
  Products = 'p',
  Articles = 'a',
  PDP = 'pdp',
}

export interface Sort {
  value: string;
  label: string;
  selected: boolean;
  disabled?: boolean;
  description?: string;
  pageSize?: number;
}

@Injectable({ providedIn: 'root' })
export class CatalogSearchService {
  private currentPage$ = new BehaviorSubject<number>(0);
  previousScrollPosition$ = new BehaviorSubject<number>(0);
  wasScrolledToPreviousPosition$ = new Subject<boolean>();

  readonly searchQueryByUrl$: Observable<{ queryParams: ProductListRouteParams; searchQuery: string; type: SolrSearchType }> =
    this.routingService.getRouterState().pipe(
      distinctUntilChanged((x, y) => x.state.url === y.state.url),
      map((routerState) => {
        const queryParams = this.prepareQueryParams(routerState.state);
        return {
          queryParams,
          searchQuery: this.getQueryFromRouteParams(queryParams),
          type: [CatalogTabTypes.Articles, CatalogTabTypes.PDP].includes(queryParams?.tab as CatalogTabTypes)
            ? SolrSearchType.ARTICLE
            : SolrSearchType.PRODUCT,
        };
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

  readonly loading$: Observable<boolean> = this.searchQueryByUrl$.pipe(
    switchMap(({ searchQuery, type }) => this.searchService.getSearchLoading(type, searchQuery)),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  readonly loaded$: Observable<boolean> = this.searchQueryByUrl$.pipe(
    switchMap(({ searchQuery, type }) => this.searchService.getSearchLoaded(type, searchQuery)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  readonly results$: Observable<SolrSearchResult> = this.searchQueryByUrl$.pipe(
    switchMap(({ searchQuery, type }) => this.searchService.getResults(type, searchQuery)),
    tap((results) => {
      this.fixCurrentPage(this.currentPage$.value, results, this.activatedRoute.snapshot.queryParams);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  readonly productHits$: Observable<number> = this.searchQueryByUrl$.pipe(
    distinctUntilChanged(shallowEqualObjects),
    switchMap(({ searchQuery }) => this.searchService.getTotalHits(SolrSearchType.PRODUCT, searchQuery)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  readonly articleHits$: Observable<number> = this.searchQueryByUrl$.pipe(
    distinctUntilChanged(shallowEqualObjects),
    switchMap(({ searchQuery }) => this.searchService.getTotalHits(SolrSearchType.ARTICLE, searchQuery)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  readonly searchByRouting$: Observable<any> = combineLatest([this.searchQueryByUrl$, this.currentPage$]).pipe(
    withLatestFrom(this.results$),
    debounceTime(10),
    map(([[{ searchQuery, queryParams, type }, currentPage], results]) => {
      let page = currentPage;
      if (!results?.pagination && currentPage > 0) {
        page = 0;
        this.currentPage$.next(page);
      }
      return { searchQuery, queryParams, type, currentPage: page, results };
    }),
    filter(({ searchQuery, currentPage, results }) => {
      return (
        this.searchService.sortSearchQuery(searchQuery) !== this.searchService.sortSearchQuery(results?.searchQuery) ||
        currentPage !== results?.pagination?.currentPage
      );
    }),
    switchMap(({ queryParams, currentPage }) => {
      return this.search(queryParams, currentPage);
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
    finalize(() => {
      this.currentPage$.next(0);
    })
  );

  readonly enablePriceSorting$ = this.principalConfigurationService.isEnabled('enablePriceSorting');
  readonly priceSortThreshold$ = this.principalConfigurationService
    .getValue('priceSortThreshold')
    .pipe(map((priceSortThreshold) => (priceSortThreshold?.value as number) ?? 10));

  constructor(
    protected searchService: SearchService,
    protected principalConfigurationService: PrincipalConfigurationService,
    protected routingService: RoutingService,
    protected activatedRoute: ActivatedRoute,
    protected currencyService: CurrencyService,
    protected languageService: LanguageService,
    protected router: Router,
    protected ngMeta: Meta,
    protected catalogRouterStateService: CatalogRouterStateService
  ) {}

  setPreviousScrollPosition(topPosition?: number) {
    this.previousScrollPosition$.next(topPosition);
  }

  setSearchType(tab: CatalogTabTypes, sort: string): void {
    this.setQueryParams({ tab, sort });
  }

  updateRobotsMetaTag(query: string): void {
    if (query) {
      const robotsNoIndexTag: MetaDefinition = {
        name: 'robots',
        content: 'noindex',
      };
      this.ngMeta.updateTag(robotsNoIndexTag);
    } else {
      this.ngMeta.removeTag("name='robots'");
    }
  }

  sort(sort: string): void {
    this.setQueryParams({ sort });
  }

  setPageSize(pageSize: number): void {
    this.setQueryParams({ pageSize }, true);
  }

  loadMore(nextPage: number) {
    this.currentPage$.next(nextPage);
  }

  setQuery(query: string, skipLocationChange = false): void {
    this.setQueryParams({ query }, skipLocationChange);
  }

  // eslint-disable-next-line import/no-deprecated
  getSorts(type?: SolrSearchType): Observable<SortModel[]> {
    const searchQueryByUrl: string = this.getQueryFromRouteParams(
      this.prepareQueryParams(this.activatedRoute.snapshot.queryParams)
    );
    return this.searchService.getSort(type, searchQueryByUrl);
  }

  private fixCurrentPage(currentPage: number, results: SolrSearchResult, queryParams: ProductListRouteParams): void {
    if (
      !!results?.pagination &&
      (!queryParams?.pageSize || Number(queryParams?.pageSize) === results?.pagination?.pageSize) &&
      results?.pagination?.currentPage !== currentPage
    ) {
      this.currentPage$.next(results?.pagination?.currentPage);
    } else if (!results && currentPage > 0) {
      this.currentPage$.next(0);
    }
  }

  private search(queryParams: ProductListRouteParams, currentPage: number): Observable<SolrSearchConfig> {
    return this.getSearchConfig(queryParams, currentPage).pipe(
      withLatestFrom(this.priceSortThreshold$),
      map(([searchConfig, priceSortThreshold]) => {
        if (searchConfig.sort === SolrSearchSortingDefaults.PDP_TYPE_ARTICLES_PRICE) {
          return { ...searchConfig, pageSize: priceSortThreshold };
        }
        return searchConfig;
      }),
      tap((searchConfig) => {
        const query = this.getQueryFromRouteParams(queryParams);
        if (currentPage === 0 && !queryParams.p) {
          if (searchConfig.searchType === SolrSearchType.PRODUCT) {
            this.searchService.searchTotalHits(query, { ...searchConfig, searchType: SolrSearchType.ARTICLE });
          } else {
            this.searchService.searchTotalHits(query, { ...searchConfig, searchType: SolrSearchType.PRODUCT });
          }
        }
        this.searchService.search(query, searchConfig, queryParams.all);
      })
    );
  }

  private prepareQueryParams(state: Params): ProductListRouteParams {
    const categoryCode = this.catalogRouterStateService.getLeafCategoryCode(state?.params, state?.queryParams);
    const productCode = this.catalogRouterStateService.getProductCode(state?.params, state?.queryParams);
    const queryParams = Object.assign({}, state?.queryParams);

    queryParams['c'] = categoryCode;

    if (!!productCode) {
      queryParams['p'] = productCode;
      queryParams['tab'] = CatalogTabTypes.PDP;
    }

    // ESVCX-5015: Make solr to perform search for articles when dealing with discontinued article
    if (state?.queryParams?.discontinued) {
      queryParams['tab'] = 'a';
    }

    return queryParams as ProductListRouteParams;
  }

  private queryParamsNeedUpdate(params: ProductListRouteParams): boolean {
    const oldParams = this.activatedRoute.snapshot.queryParams;
    return Object.keys(params).reduce((needUpdate, key) => needUpdate || params[key] !== (oldParams[key] ?? null), false);
  }

  private getQueryFromRouteParams({ c, t, o, aou, query, sort, mya, p, a }: ProductListRouteParams): string {
    const s = sort || '';

    let uri = `:${s}:`;
    if (query) {
      const newQuery = this.updateSortInQueryString(s, query);
      if (newQuery) {
        uri = newQuery;
      } else {
        uri = query;
      }
    } else if (p && c) {
      uri = `:${s}:${!!c ? 'allCategories:' + c : ''}${!!p ? ':productCode:' + p : ''}`;
    } else if (c) {
      uri = `:${s}:${!!c ? 'allCategories:' + c : ''}`;
    } else if (p) {
      uri = `:${s}:productCode:${p}`;
    } else if (aou) {
      const re = new RegExp('([:])articleAreasOfUse:.*?(&|$)', 'i');

      if (uri.match(re)) {
        uri = uri.replace(re, `$1articleAreasOfUse:${aou}$2`);
      } else {
        uri = uri + `${uri.endsWith(':') ? '' : ':'}articleAreasOfUse:${aou}`;
      }
    }

    if (a) {
      const re = new RegExp('([:])articleCode:.*?(&|$)', 'i');

      if (uri.match(re)) {
        uri = uri.replace(re, `$1articleCode:${a}$2`);
      } else {
        uri = uri + `${uri.endsWith(':') ? '' : ':'}articleCode:${a}`;
      }
    }

    if (t) {
      const re = new RegExp('([:])t:.*?(&|$)', 'i');

      if (uri.match(re)) {
        uri = uri.replace(re, `$1t:${t}$2`);
      } else {
        uri = uri + `${uri.endsWith(':') ? '' : ':'}t:${t}`;
      }
    }

    if (o) {
      const re = new RegExp('([:])o:.*?(&|$)', 'i');

      if (uri.match(re)) {
        uri = uri.replace(re, `$1o:${o}$2`);
      } else {
        uri = uri + `${uri.endsWith(':') ? '' : ':'}o:${o}`;
      }
    }

    if (typeof mya !== 'undefined') {
      const re = new RegExp('([:])mya:.*?(&|$)', 'i');

      if (uri.match(re)) {
        uri = uri.replace(re, `$1mya:${mya}$2`);
      } else {
        uri = uri + `${uri.endsWith(':') ? '' : ':'}mya:${mya}`;
      }
    }

    return uri;
  }

  private getSearchConfig(queryParams: ProductListRouteParams, currentPage: number): Observable<SolrSearchConfig> {
    const activeTab = queryParams.tab || CatalogTabTypes.Products;
    let searchType: SolrSearchType;

    switch (activeTab) {
      case CatalogTabTypes.Articles:
      case CatalogTabTypes.PDP:
        searchType = SolrSearchType.ARTICLE;
        break;
      default:
        searchType = SolrSearchType.PRODUCT;
        break;
    }

    const searchConfig: SolrSearchConfig = {
      currentPage: currentPage || 0,
      pageSize: queryParams.pageSize,
      sort: queryParams.sort || '',
      mya: queryParams.mya === '1',
      searchType: searchType,
    };

    if (searchType !== SolrSearchType.ARTICLE) {
      return of({ ...searchConfig, pageSize: searchConfig.pageSize || 24 });
    }

    return combineLatest([of(searchConfig), this.principalConfigurationService.getValue('articleFetchRowsForSearch')]).pipe(
      map(([config, pc]) => {
        return { ...config, pageSize: config.pageSize || (pc?.value as number) || 12 };
      })
    );
  }

  setQueryParams(queryParams: ProductListRouteParams, skipLocationChange = false): void {
    if (this.queryParamsNeedUpdate(queryParams)) {
      this.router.navigate([], {
        queryParams,
        queryParamsHandling: 'merge',
        skipLocationChange: skipLocationChange,
        relativeTo: this.activatedRoute,
        state: {
          preserveScrollPosition: true,
        },
      });
    }
  }

  resetPDPFilters() {
    this.setQueryParams({ query: undefined, a: undefined });
  }

  private updateSortInQueryString(sort: string, query: string) {
    let newQuery = null;
    if (query) {
      const re = new RegExp('(:)(.*?)(:)', 'i');
      const match = query.match(re);
      if (match && match[0] && match[0] !== `:${sort}:`) {
        newQuery = query.replace(match[0], `:${sort || ''}:`);
      } else {
        newQuery = query;
      }
    }

    return newQuery;
  }

  isPdpVersionSkuPicker(): Observable<boolean> {
    return this.principalConfigurationService.getValue('productDetailPageType').pipe(map((pc) => pc?.value === 'sku-picker'));
  }

  getFacets(searchResults?: SolrSearchResult): Facet[] {
    let value: Facet[] = [];

    if (searchResults?.facets) {
      value = searchResults.facets.map((f) => {
        return {
          ...f,
          values: f.values.map((fv) => ({ ...fv })),
        };
      });
    }

    const breadcrumbs = (searchResults?.breadcrumbs || []).filter(
      (breadcrumb) => !['allCategories', 'productCode', 'articleCode'].includes(breadcrumb.facetCode)
    );

    // Facets with `count = 0` are not returned in searchResults, we may have to insert
    // it to the list ourselves so the user can see the selection.
    breadcrumbs.forEach((breadcrumb) => {
      const facet = value.find((f) => f.code === breadcrumb.facetCode);
      if (facet) {
        const facetValue = facet.values.find((fv) => fv.code === breadcrumb.facetValueCode);
        if (!facetValue) {
          const lastSelectedIndex = findLastIndex(facet.values, ({ selected }) => selected);
          const insertIndex = lastSelectedIndex < 0 ? 0 : lastSelectedIndex + 1;
          const newFacet = {
            ...facet,
            values: [
              ...facet.values.slice(0, insertIndex),
              {
                code: breadcrumb.facetValueCode,
                name: breadcrumb.facetValueName,
                query: breadcrumb.removeQuery,
                selected: true,
                count: 0,
              },
              ...facet.values.slice(insertIndex),
            ],
          };
          value = value.map((f) => (f === facet ? newFacet : f));
        }
      } else {
        value.push({
          category: false,
          code: breadcrumb.facetCode,
          multiSelect: false,
          name: breadcrumb.facetName,
          values: [
            {
              code: breadcrumb.facetValueCode,
              name: breadcrumb.facetValueName,
              query: breadcrumb.removeQuery,
              selected: true,
              count: 0,
            },
          ],
          visible: true,
        });
      }
    });

    return value;
  }
}
