<ng-container *ngIf="{ carouselItems: carouselItems$ | async, carouselItemsLoading: carouselItemsLoading$ | async } as data">
  <div class="similar-articles active">
    <ng-container *ngIf="data.carouselItemsLoading || articlesLoading; else details">
      <div class="text-center mt-2 similar-articles-spinner">
        <cx-spinner></cx-spinner>
      </div>
    </ng-container>

    <ng-template #details>
      <py-article-carousel
        *ngIf="data.carouselItems?.length"
        class="mt-2 mb-3"
        [actionLabel]="'common.show_action'"
        [carouselItems]="data.carouselItems || []"
        [substituteRefs]="substituteRefs"
        [enableSecondaryArticleRowVariant]="enableSecondaryArticleRowVariant"
        [loading]="articlesLoading"
        [showPaginationAtTheBottom]="showPaginationAtTheBottom"
        [showAlternativeArticleBadge]="showAlternativeArticleBadge"
        [useDefaultArticleQuantityAndUnit]="useDefaultArticleQuantityAndUnit"
        [carouselActionMode]="carouselActionMode"
        (articleInteraction)="onClick($event)"
      >
        <h3 *ngIf="showTitle">{{ 'catalog.similarArticles_title' | cxTranslate }}</h3>
      </py-article-carousel>

      <py-notification
        *ngIf="showMessageWhenEmpty && !data.carouselItems?.length"
        class="my-2"
        type="info"
        [tiny]="true"
        [message]="'catalog.noSimilarArticlesFound_text' | cxTranslate"
      ></py-notification>
    </ng-template>
  </div>
</ng-container>
