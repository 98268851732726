import { DIALOG_TYPE, LAUNCH_CALLER, LayoutConfig } from '@spartacus/storefront';
import { SearchScoreModalWrapperComponent } from './search-score-modal-wrapper.component';

export const searchScoreModalLayoutConfig: LayoutConfig = {
  launch: {
    [LAUNCH_CALLER.SEARCH_SCORE]: {
      inlineRoot: true,
      component: SearchScoreModalWrapperComponent,
      dialogType: DIALOG_TYPE.DIALOG,
      dialogOptions: {
        onTop: true,
      },
    },
  },
};
