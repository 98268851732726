import { InjectionToken, Provider } from '@angular/core';
import { Action, ActionReducer, ActionReducerMap, MetaReducer, combineReducers } from '@ngrx/store';
import { NodeItem, StateUtils } from '@spartacus/core';
import { LoginLogoutActionTypes } from '../../../auth/user-auth/store/actions/login-logout.action';
import { PageType } from '../../../model/cms.model';
import { LanguagesActionTypes } from '../../../site-context/store/actions/languages.action';
import { entityLoaderReducer } from '../../../state/utils/entity-loader/entity-loader.reducer';
import { COMPONENT, CmsState, NAVIGATION_ENTRY_ITEM, StateWithCms } from '../cms-state';
import * as fromComponentsReducer from './components.reducer';
import * as fromNavigationReducer from './navigation-entry-item.reducer';
import * as fromPageReducer from './page-data.reducer';
import * as fromPageIndexReducer from './page-index.reducer';

export function getReducers(): ActionReducerMap<CmsState, any> {
  return {
    page: combineReducers({
      pageData: fromPageReducer.reducer,
      index: combineReducers({
        content: entityLoaderReducer<string>(PageType.CONTENT_PAGE, fromPageIndexReducer.reducer(PageType.CONTENT_PAGE)),
        product: entityLoaderReducer<string>(PageType.PRODUCT_PAGE, fromPageIndexReducer.reducer(PageType.PRODUCT_PAGE)),
        category: entityLoaderReducer<string>(PageType.CATEGORY_PAGE, fromPageIndexReducer.reducer(PageType.CATEGORY_PAGE)),
        catalog: entityLoaderReducer<string>(PageType.CATALOG_PAGE, fromPageIndexReducer.reducer(PageType.CATALOG_PAGE)),
      }),
    }),
    components: StateUtils.entityReducer(COMPONENT, fromComponentsReducer.reducer as any),
    navigation: entityLoaderReducer<NodeItem>(NAVIGATION_ENTRY_ITEM, fromNavigationReducer.reducer),
  };
}

export const reducerToken: InjectionToken<ActionReducerMap<CmsState>> = new InjectionToken<ActionReducerMap<CmsState>>(
  'CmsReducers'
);

export const reducerProvider: Provider = {
  provide: reducerToken,
  useFactory: getReducers,
};

export function clearCmsState(reducer: ActionReducer<StateWithCms, Action>): ActionReducer<StateWithCms, Action> {
  return function (state, action) {
    if (
      action.type === LanguagesActionTypes.LanguageChange ||
      action.type === LoginLogoutActionTypes.Logout ||
      action.type === LoginLogoutActionTypes.Login
    ) {
      state = undefined;
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<StateWithCms>[] = [clearCmsState];
