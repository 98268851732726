import { ValidationErrors, ValidatorFn } from '@angular/forms';
import { AccountingCode } from '../../../core/model';

export abstract class AccountingCodeValidator {
  static validate(formArray: any, validAccountingCodes: AccountingCode[]): ValidatorFn {
    return (control): ValidationErrors | null => {
      if (!formArray?.controls || !control.value) {
        return null;
      }

      if (
        !validAccountingCodes?.filter((validAccountingCode: AccountingCode) => validAccountingCode.code === control.value).length
      ) {
        return { invalidAccountingCodeAssigned: { valid: false } };
      }

      const isReinvoicing =
        formArray.controls.filter((c) => c.controls.accountingCode.value === control.value)[0]?.controls.reinvoicing.value ||
        false;

      const formHasReinvoicingEntries = formArray.controls.filter((c) => c.controls.reinvoicing?.value).length > 0;

      //If there are reinvoicing fields, and this is not a reinvoicing field, the field is not valid
      if (formHasReinvoicingEntries && !isReinvoicing) {
        return { notReinvoicingFieldValues: { valid: false } };
      }

      return null;
    };
  }
}
