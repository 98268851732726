import { AnonymousConsentsActions as CxAnonymousConsentsActions } from '@spartacus/core';

export const initialState = false;

export function reducer(
  state = initialState,
  action: CxAnonymousConsentsActions.ToggleAnonymousConsentTemplatesUpdated
): boolean {
  switch (action.type) {
    case CxAnonymousConsentsActions.TOGGLE_ANONYMOUS_CONSENT_TEMPLATES_UPDATED: {
      return action.updated;
    }
  }
  return state;
}
