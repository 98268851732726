import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../core/i18n';
import { NoResultsComponent } from './no-results.component';

@NgModule({
  declarations: [NoResultsComponent],
  imports: [CommonModule, I18nModule, NzIconModule],
  providers: [],
  exports: [NoResultsComponent],
})
export class NoResultsModule {}
