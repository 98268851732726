<ng-container
  *ngIf="b2bUser.status?.code === b2bUserStatusEnum.INACTIVE; then inactiveUserProfileIcon; else notInactive"
></ng-container>

<ng-template #notInactive>
  <ng-container
    *ngIf="b2bUser.roles && b2bUser.roles.indexOf(adminRole) !== -1; then adminUserProfileIcon; else regularUserProfileIcon"
  ></ng-container>
</ng-template>

<ng-template #regularUserProfileIcon>
  <i class="user-icon" nz-icon nzType="user-profile"></i>
</ng-template>

<ng-template #inactiveUserProfileIcon>
  <i class="user-icon" nz-icon nzType="user-profile-inactive"></i>
</ng-template>

<ng-template #adminUserProfileIcon>
  <i class="user-icon" nz-icon nzType="user-profile-admin"></i>
</ng-template>
