import { Action } from '@ngrx/store';
import { EntriesState } from '../../../features/store/base-feature-state';
import { OrderCard, OrderCardPage } from '../../../model';
import { UserActions } from '../actions';
import { OrderCardsAdminActionTypes } from '../actions/order-cards-admin.action';

export const initialState: EntriesState<OrderCard> = {};

export function reducer(state = initialState, action: Action): EntriesState<OrderCard> {
  switch (action.type) {
    case OrderCardsAdminActionTypes.SearchOrderCardsAdminSuccess: {
      const _action = <UserActions.SearchOrderCardsAdminSuccess>action;
      const payload: OrderCardPage = _action.payload.searchResult;
      const results = payload.results;
      return results.reduce(
        (entities: { [id: number]: OrderCard }, orderCard: OrderCard) => {
          return {
            ...entities,
            [orderCard.code]: orderCard,
          };
        },
        {
          ...state,
        }
      );
    }
    case OrderCardsAdminActionTypes.CreateOrderCardAdminSuccess: {
      const _action = <UserActions.CreateOrderCardAdminSuccess>action;
      const orderCard: OrderCard = _action.payload.orderCard;
      const tempId: string = _action.payload.tempId;
      return {
        ...state,
        [orderCard.code]: { ...orderCard, tempId },
      };
    }
    case OrderCardsAdminActionTypes.UpdateOrderCardAdminSuccess: {
      const _action = <UserActions.UpdateOrderCardAdminSuccess>action;
      const orderCard: OrderCard = _action.payload.orderCard;
      return {
        ...state,
        [orderCard.code]: orderCard,
      };
    }
    case OrderCardsAdminActionTypes.UpdateOrderCardAdminStatusSuccess: {
      const _action = <UserActions.UpdateOrderCardAdminStatusSuccess>action;
      const orderCard: OrderCard = _action.payload.orderCard;
      return {
        ...state,
        [orderCard.code]: orderCard,
      };
    }

    case OrderCardsAdminActionTypes.UpdateOrderCardAdminProductsSuccess: {
      const _action = <UserActions.UpdateOrderCardAdminProductsSuccess>action;
      const orderCard: OrderCard = _action.payload.orderCard;
      return {
        ...state,
        [orderCard.code]: orderCard,
      };
    }
    case OrderCardsAdminActionTypes.AddOrderCardAdminPrincipalSuccess: {
      const _action = <UserActions.AddOrderCardAdminPrincipalSuccess>action;
      const orderCard: OrderCard = _action.payload;
      return {
        ...state,
        [orderCard.code]: orderCard,
      };
    }

    case OrderCardsAdminActionTypes.AddOrderCardAdminProductSuccess: {
      const _action = <UserActions.AddOrderCardAdminProductSuccess>action;
      const orderCard: OrderCard = _action.payload;
      return {
        ...state,
        [orderCard.code]: orderCard,
      };
    }
    case OrderCardsAdminActionTypes.ReplaceOrderCardAdminProductSuccess: {
      const _action = <UserActions.ReplaceOrderCardAdminProductSuccess>action;
      const orderCard: OrderCard = _action.payload;
      return {
        ...state,
        [orderCard.code]: orderCard,
      };
    }
    case OrderCardsAdminActionTypes.AddOrderCardAdminArticlesSuccess: {
      const _action = <UserActions.AddOrderCardAdminArticlesSuccess>action;
      const orderCard: OrderCard = _action.payload;
      return {
        ...state,
        [orderCard.code]: orderCard,
      };
    }
    case OrderCardsAdminActionTypes.DeleteOrderCardAdminProductSuccess: {
      const _action = <UserActions.DeleteOrderCardAdminProductSuccess>action;
      const orderCard: OrderCard = _action.payload;
      return {
        ...state,
        [orderCard.code]: orderCard,
      };
    }
    case OrderCardsAdminActionTypes.DeleteOrderCardAdminSuccess: {
      const _action = <UserActions.DeleteOrderCardAdminSuccess>action;
      const code = _action.payload.code;
      const newState = { ...state };
      delete newState[code];
      return newState;
    }
    default:
      return state;
  }
}
