import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { CmsPageGuard } from '@spartacus/storefront';
import { PageLayoutComponent } from '../../cms-structure';
import { LogoutGuard } from './logout.guard';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: null,
        canActivate: [LogoutGuard, CmsPageGuard],
        component: PageLayoutComponent,
        data: { cxRoute: 'logout' },
      },
    ]),
  ],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        logout: {
          module: () => import('./logout.module').then((m) => m.LogoutModule),
          cmsComponents: ['DesktopLogoutComponent'],
        },
      },
    }),
  ],
})
export class LogoutFeatureModule {}
