import { Injectable } from '@angular/core';
import { User, facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { Credentials, LoaderError, NameInputCheckResult, UserInputCheck, UserSignUp } from '../../model';
import { USER_BASE_FEATURE } from '../store/user-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: UserFacade,
      feature: USER_BASE_FEATURE,
      async: true,
      methods: [
        'get',
        'isAdmin',
        'isApprovalAdmin',
        'isApprover',
        'isApproverOrAdmin',
        'isBuyer',
        'isPunchOut',
        'isOci',
        'isCxml',
        'hasAddons',
        'hasTendered',
        'hasCustomerAssortment',
        'hasUniqueArticles',
        'register',
        'getRegisterUserResult',
        'getRegisterUserResultLoading',
        'getRegisterUserResultSuccess',
        'getRegisterUserResultError',
        'resetRegisterUserProcessState',
        'remove',
        'updateCredentials',
        'getUpdateCredentialsResultLoading',
        'getUpdateCredentialsResultError',
        'getUpdateCredentialsResultSuccess',
        'resetUpdateCredentialsProcessingState',
        'getRemoveUserResultLoading',
        'getRemoveUserResultError',
        'getRemoveUserResultSuccess',
        'resetRemoveUserProcessState',
        'isPasswordReset',
        'updatePersonalDetails',
        'getUpdatePersonalDetailsResultLoading',
        'getUpdatePersonalDetailsResultError',
        'getUpdatePersonalDetailsResultSuccess',
        'resetUpdatePersonalDetailsProcessingState',
        'resetPassword',
        'requestForgotPasswordEmail',
        'getRequestForgotPasswordEmailLoading',
        'getRequestForgotPasswordEmailError',
        'getRequestForgotPasswordEmailSuccess',
        'resetRequestForgotPasswordEmailProcessingState',
        'requestForgotUsernameEmail',
        'getRequestForgotUsernameEmailLoading',
        'getRequestForgotUsernameEmailError',
        'getRequestForgotUsernameEmailSuccess',
        'resetRequestForgotUsernameEmailProcessingState',
        'getUpdatePasswordResultLoading',
        'getUpdatePasswordResultError',
        'getUpdatePasswordResultSuccess',
        'resetUpdatePasswordProcessState',
        'validateUserEmail',
        'checkDuplicatedUsername',
        'validateName',
        'changeCustomerSessionLanguage',
      ],
    }),
})
export abstract class UserFacade {
  abstract get(): Observable<User>;
  abstract isAdmin(): Observable<boolean>;
  abstract isApprovalAdmin(): Observable<boolean>;
  abstract isApprover(): Observable<boolean>;
  abstract isApproverOrAdmin(): Observable<boolean>;
  abstract isBuyer(): Observable<boolean>;
  abstract isPunchOut(): Observable<boolean>;
  abstract isOci(): Observable<boolean>;
  abstract isCxml(): Observable<boolean>;
  abstract hasAddons(): Observable<boolean>;
  abstract hasTendered(): Observable<boolean>;
  abstract hasCustomerAssortment(): Observable<boolean>;
  abstract hasUniqueArticles(): Observable<boolean>;
  abstract register(userRegisterFormData: UserSignUp): void;
  abstract getRegisterUserResult(): Observable<User | undefined>;
  abstract getRegisterUserResultLoading(): Observable<boolean>;
  abstract getRegisterUserResultSuccess(): Observable<boolean>;
  abstract getRegisterUserResultError(): Observable<LoaderError | undefined>;
  abstract resetRegisterUserProcessState(): void;
  abstract remove(password): void;
  abstract updateCredentials(credentials: Credentials): void;
  abstract getUpdateCredentialsResultLoading(): Observable<boolean>;
  abstract getUpdateCredentialsResultError(): Observable<LoaderError | undefined>;
  abstract getUpdateCredentialsResultSuccess(): Observable<boolean>;
  abstract resetUpdateCredentialsProcessingState(): void;
  abstract getRemoveUserResultLoading(): Observable<boolean>;
  abstract getRemoveUserResultError(): Observable<LoaderError | undefined>;
  abstract getRemoveUserResultSuccess(): Observable<boolean>;
  abstract resetRemoveUserProcessState(): void;
  abstract isPasswordReset(): Observable<boolean>;
  abstract updatePersonalDetails(userDetails: User): void;
  abstract getUpdatePersonalDetailsResultLoading(): Observable<boolean>;
  abstract getUpdatePersonalDetailsResultError(): Observable<LoaderError | undefined>;
  abstract getUpdatePersonalDetailsResultSuccess(): Observable<boolean>;
  abstract resetUpdatePersonalDetailsProcessingState(): void;
  abstract resetPassword(token: string, password: string): void;
  abstract requestForgotPasswordEmail(email: string, username): void;
  abstract getRequestForgotPasswordEmailLoading(): Observable<boolean>;
  abstract getRequestForgotPasswordEmailError(): Observable<LoaderError | undefined>;
  abstract getRequestForgotPasswordEmailSuccess(): Observable<boolean>;
  abstract resetRequestForgotPasswordEmailProcessingState(): void;
  abstract requestForgotUsernameEmail(email: string): void;
  abstract getRequestForgotUsernameEmailLoading(): Observable<boolean>;
  abstract getRequestForgotUsernameEmailError(): Observable<LoaderError | undefined>;
  abstract getRequestForgotUsernameEmailSuccess(): Observable<boolean>;
  abstract resetRequestForgotUsernameEmailProcessingState(): void;
  abstract getUpdatePasswordResultLoading(): Observable<boolean>;
  abstract getUpdatePasswordResultError(): Observable<LoaderError | undefined>;
  abstract getUpdatePasswordResultSuccess(): Observable<boolean>;
  abstract resetUpdatePasswordProcessState(): void;
  abstract validateUserEmail(email: string): Observable<UserInputCheck>;
  abstract checkDuplicatedUsername(username: string): Observable<UserInputCheck>;
  abstract validateName(name: string): Observable<NameInputCheckResult>;
  abstract changeCustomerSessionLanguage(languageCode: string): void;
}
