import { DIALOG_TYPE, LAUNCH_CALLER, LayoutConfig } from '@spartacus/storefront';
import { ActiveOrderProcessModalComponent } from './active-order-process-modal.component';

export const activeOrderProcessModalLayoutConfig: LayoutConfig = {
  launch: {
    [LAUNCH_CALLER.ACTIVE_ORDER_PROCESS]: {
      inlineRoot: true,
      component: ActiveOrderProcessModalComponent,
      dialogType: DIALOG_TYPE.DIALOG,
      dialogOptions: {
        autoDismissOnRouteChange: false,
      },
    },
  },
};
