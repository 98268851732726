import { Component, HostBinding, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'py-list-insert',
  templateUrl: './list-insert.component.html',
  styleUrls: ['./list-insert.component.scss'],
})
export class ListInsertComponent implements OnInit {
  @Input()
  public title: string;

  @HostBinding('class.clickable')
  @Input()
  public clickable = false;

  @Input() iconTemplate?: TemplateRef<any>;

  public get hasLongTitle(): boolean {
    return this.title?.length > 20;
  }

  constructor() {}

  ngOnInit(): void {}
}
