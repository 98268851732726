<ng-container
  *ngIf="{
    activeCategory: activeCategory$ | async,
    mya: mya$ | async,
    activeSoldTo: activeSoldTo$ | async,
    areCategoriesEmpty: areCategoriesEmpty$ | async
  } as data"
>
  <ng-container *ngIf="!data.areCategoriesEmpty; else errorTemplate">
    <ng-container *ngIf="data.activeCategory">
      <li py-megamenu-list-item [isDirectLink]="true">
        <cx-generic-link [url]="getUrl(data.activeCategory?.url, { mya: data.mya ? '1' : '0' })" class="view-all">
          {{ 'navigation.viewAllInCategory_action' | cxTranslate : { category: data.activeCategory?.name } }}
        </cx-generic-link>
      </li>
    </ng-container>
    <ng-container *ngFor="let category of data.activeCategory?.subCategories || []">
      <li py-megamenu-list-item [showLinkArrow]="false">
        <cx-generic-link [url]="getUrl(category.url, { mya: data.mya ? '1' : '0' })">
          {{ category.name }}
        </cx-generic-link>
      </li>
    </ng-container>

    <ng-container *pyPrincipalConfiguration="'enableDisplayOutgoingArea'">
      <ng-container *ngIf="data.activeSoldTo?.outgoingAssortmentAllowed || !data.activeSoldTo">
        <li py-megamenu-list-item [isDirectLink]="true">
          <cx-generic-link
            [url]="getUrl(data.activeCategory?.url, { tab: 'a', o: '1', mya: data.mya ? '1' : '0' })"
            class="outlet"
          >
            {{ 'navigation.outlet_action' | cxTranslate }}
          </cx-generic-link>
        </li>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #errorTemplate>
    <py-no-results [title]="'navigation.noCategoriesFound_text'" [iconType]="'search'"></py-no-results>
  </ng-template>
</ng-container>
