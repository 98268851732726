import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { PipesModule } from '../../pipes';
import { CounterInputComponent } from './counter-input.component';

@NgModule({
  imports: [CommonModule, NzIconModule, FormsModule, NgbTooltipModule, PipesModule],
  declarations: [CounterInputComponent],
  exports: [CounterInputComponent],
})
export class CounterInputModule {}
