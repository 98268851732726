import { Action } from '@ngrx/store';
import * as SoldToActions from './sold-to.actions';

export { SoldToActions };

export enum SoldToModuleLoadedActions {
  SOLDTO_MODULE_LOADED = '[SoldTo] Module loaded',
}

export class SoldToModuleLoaded implements Action {
  readonly type = SoldToModuleLoadedActions.SOLDTO_MODULE_LOADED;

  constructor() {}
}
