import { Injectable } from '@angular/core';
import { SubstituteRef, SubstituteRefType, SubstituteType, Substitutes } from '../../../model';
import { Occ } from '../../../occ/occ-models';
import { Converter } from '../../../util';

@Injectable({ providedIn: 'root' })
export class SubstitutesNormalizer implements Converter<Occ.Substitutes, Substitutes> {
  convert(source: Occ.Substitutes, target?: Substitutes): Substitutes {
    if (source) {
      target = this.convertSubstitutes(source);
    }

    return target;
  }

  private convertSubstitutes(source: Occ.Substitutes): Substitutes {
    if (source) {
      return {
        refs: this.convertSubstituteRefs(source.refs),
        substituteTo: source.substituteTo,
        substitutesCount: source.substitutesCount,
      };
    } else {
      return undefined;
    }
  }

  private convertSubstituteRefs(source: Occ.SubstituteRef[]): SubstituteRef[] {
    return source?.map((s) => this.convertSubstituteRef(s));
  }

  private convertSubstituteRef(source: Occ.SubstituteRef): SubstituteRef {
    return {
      code: source.ref,
      substituteType: this.convertSubstituteType(source),
      refType: this.convertSubstituteRefType(source.type),
    };
  }

  private convertSubstituteType(source: Occ.SubstituteRef): SubstituteType {
    if (source.alternative) {
      return SubstituteType.Alternative;
    } else if (source.replacement) {
      return SubstituteType.Replacement;
    } else if (source.recommendation) {
      return SubstituteType.Recommendation;
    }
  }

  private convertSubstituteRefType(refType: string): SubstituteRefType {
    switch (refType) {
      case 'PRODUCT':
        return SubstituteRefType.Product;
      case 'ARTICLE':
        return SubstituteRefType.Article;
    }
  }
}
