import { Component, Input, OnInit } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { CUSTOMER_UNIQUE_CATEGORY_CODE } from '../../../../core/model';
import { PrincipalConfigurationService, UserFacade } from '../../../../core/user';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ul[py-my-assortment-sub-navigation]',
  templateUrl: 'my-assortment-sub-navigation.component.html',
})
export class MyAssortmentSubNavigationComponent implements OnInit {
  @Input() mya: boolean;

  hasTendered$: Observable<boolean>;
  hasUniqueArticles$: Observable<boolean>;

  get customer_unique_category_code(): string {
    return CUSTOMER_UNIQUE_CATEGORY_CODE;
  }

  constructor(private userService: UserFacade, private principalConfigurationService: PrincipalConfigurationService) {}

  ngOnInit(): void {
    this.hasTendered$ = combineLatest([
      this.principalConfigurationService.isEnabled('enableTendered'),
      this.userService.hasTendered(),
    ]).pipe(map(([enableTendered, hasTendered]) => Boolean(enableTendered && hasTendered)));
    this.hasUniqueArticles$ = this.userService.hasUniqueArticles();
  }
}
