import { StateUtils } from '@spartacus/core';
import { PagedSearchAction } from '../../../../core/features/store/actions/search.actions';
import {
  EcConformityProductDocumentsPage,
  EcConformityProductDocumentsSearchParams,
  HttpErrorModel,
  ProductBrand,
  ProductDocumentsWrapper,
} from '../../../../core/model';
import { PyStateUtils } from '../../../../core/state/utils';
import { CONFORMANCE_EC_BRANDS, CONFORMANCE_EC_SEARCH, PRODUCT_DOCUMENTS } from '../documents-state';

export enum DocumentActionTypes {
  LoadProductDocuments = '[Document] Load Product Documents',
  LoadProductDocumentsSuccess = '[Document] Load Product Documents Success',
  LoadProductDocumentsFail = '[Document] Load Product Documents Fail',

  SearchConformanceEc = '[Document] Search Conformance Ec',
  SearchConformanceEcFail = '[Document] Search Conformance Ec Fail',
  SearchConformanceEcSuccess = '[Document] Search Conformance Ec Success',
  SearchConformanceEcSearchParams = '[Document] Search Conformance Ec Search Params',

  LoadConformanceEcBrands = '[Document] Load Conformance Ec Brands',
  LoadConformanceEcBrandsFail = '[Document] Load Conformance Ec Brands Fail',
  LoadConformanceEcBrandsSuccess = '[Document] Load Conformance Ec Brands Success',
}

interface SearchConformanceEcPayload {
  searchParams?: EcConformityProductDocumentsSearchParams;
}

interface SearchConformanceEcSuccessPayload extends SearchConformanceEcPayload {
  searchResult: EcConformityProductDocumentsPage;
}

interface SearchConformanceEcFailPayload extends SearchConformanceEcPayload {
  error?: HttpErrorModel;
}

interface SearchConformanceEcSearchParamsPayload extends SearchConformanceEcPayload {
  oldSearchParams?: EcConformityProductDocumentsSearchParams;
}

export class LoadProductDocuments extends StateUtils.EntityLoadAction {
  readonly type = DocumentActionTypes.LoadProductDocuments;

  constructor(public userId: string, public productCode, public children: boolean) {
    super(PRODUCT_DOCUMENTS, productCode);
  }
}

export class LoadProductDocumentsSuccess extends StateUtils.EntitySuccessAction {
  readonly type = DocumentActionTypes.LoadProductDocumentsSuccess;

  constructor(public productCode, public payload: ProductDocumentsWrapper) {
    super(PRODUCT_DOCUMENTS, productCode, payload);
  }
}

export class LoadProductDocumentsFail extends PyStateUtils.EntityFailAction {
  readonly type = DocumentActionTypes.LoadProductDocumentsFail;

  constructor(public productCode: string, public payload: any) {
    super(PRODUCT_DOCUMENTS, productCode, payload);
  }
}

export class SearchConformanceEc extends StateUtils.LoaderLoadAction implements PagedSearchAction {
  readonly type = DocumentActionTypes.SearchConformanceEc;
  readonly searchType = CONFORMANCE_EC_SEARCH;
  public searchParams: EcConformityProductDocumentsSearchParams;

  constructor(public payload: SearchConformanceEcPayload) {
    super(CONFORMANCE_EC_SEARCH);
    this.searchParams = payload.searchParams;
  }
}

export class SearchConformanceEcSuccess extends StateUtils.LoaderSuccessAction implements PagedSearchAction {
  readonly type = DocumentActionTypes.SearchConformanceEcSuccess;
  readonly searchType = CONFORMANCE_EC_SEARCH;
  public searchParams: EcConformityProductDocumentsSearchParams;

  constructor(public payload: SearchConformanceEcSuccessPayload) {
    super(CONFORMANCE_EC_SEARCH);
    this.searchParams = payload.searchParams;
  }
}

export class SearchConformanceEcFail extends PyStateUtils.LoaderFailAction implements PagedSearchAction {
  readonly type = DocumentActionTypes.SearchConformanceEcFail;
  readonly searchType = CONFORMANCE_EC_SEARCH;
  public searchParams: EcConformityProductDocumentsSearchParams;

  constructor(public payload?: SearchConformanceEcFailPayload) {
    super(CONFORMANCE_EC_SEARCH, payload);
    this.searchParams = payload?.searchParams;
  }
}

export class SearchConformanceEcSearchParams implements PagedSearchAction {
  readonly type = DocumentActionTypes.SearchConformanceEcSearchParams;
  public searchParams: EcConformityProductDocumentsSearchParams;

  constructor(public payload: SearchConformanceEcSearchParamsPayload) {}
}

export class LoadConformanceEcBrands extends StateUtils.LoaderLoadAction {
  readonly type = DocumentActionTypes.LoadConformanceEcBrands;

  constructor() {
    super(CONFORMANCE_EC_BRANDS);
  }
}

export class LoadConformanceEcBrandsSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = DocumentActionTypes.LoadConformanceEcBrandsSuccess;

  constructor(public payload: ProductBrand[]) {
    super(CONFORMANCE_EC_BRANDS);
  }
}

export class LoadConformanceEcBrandsFail extends PyStateUtils.LoaderFailAction {
  readonly type = DocumentActionTypes.LoadConformanceEcBrandsFail;

  constructor(public payload: any) {
    super(CONFORMANCE_EC_BRANDS, payload);
  }
}

export type DocumentActions =
  | LoadProductDocuments
  | LoadProductDocumentsSuccess
  | LoadProductDocumentsFail
  | SearchConformanceEc
  | SearchConformanceEcSuccess
  | SearchConformanceEcFail
  | SearchConformanceEcSearchParams
  | LoadConformanceEcBrands
  | LoadConformanceEcBrandsSuccess
  | LoadConformanceEcBrandsFail;
