import { AlertType } from '../../../../core/model';
import { AlertsConfig } from './alerts-config';

export const defaultAlertsConfig: AlertsConfig = {
  alerts: {
    reappearableAlerts: [AlertType.Warning],
    reappearTime: 900,
    operationalMessageCheckInitialDelay: 10,
    operationalMessageCheckInterval: 600,
  },
};
