<ng-container *ngIf="showButton; else textTemplate" [ngTemplateOutlet]="buttonTemplate"></ng-container>

<ng-template #buttonTemplate>
  <div class="wrapper">
    <div class="label" *ngIf="!!entry.reelCutting" [class.disabled]="disabled">
      {{ translationKeyPrefix + '.reelCutting.cuttingButton_heading' | cxTranslate }}
    </div>

    <span
      [ngbTooltip]="translationKeyPrefix + '.reelCutting.bothCuttingAndReaming_tooltip' | cxTranslate"
      [disableTooltip]="!entry?.reaming?.reamed"
    >
      <button
        (click)="open()"
        [disabled]="disabled"
        class="add-or-edit-button px-2"
        [ngClass]="{ add: !entry.reelCutting, edit: !!entry.reelCutting }"
      >
        <span>
          <i nz-icon nzType="scissor" nzTheme="outline" class="mr-2"></i>
          <ng-container [ngTemplateOutlet]="buttonLabel"></ng-container>
        </span>
        <div
          class="icon-holder edit"
          *ngIf="!disabled && !!entry.reelCutting"
          [ngbTooltip]="translationKeyPrefix + '.reelCutting.editCutting_tooltip' | cxTranslate"
        >
          <i nz-icon nzType="edit" nzTheme="outline"></i>
        </div>
      </button>
    </span>
  </div>
</ng-template>

<ng-template #buttonLabel>
  <span *ngIf="!entry.reelCutting; else buttonValueLabel">
    {{ 'cart.reelCutting.addCutting_heading' | cxTranslate }}
  </span>
</ng-template>

<ng-template #textTemplate>
  <div class="text-wrapper d-flex align-items-start" *ngIf="!!entry.reelCutting">
    <i nz-icon nzType="scissor" nzTheme="outline" class="mx-2"></i>
    <div class="d-flex flex-column w-100">
      <span class="text-label">{{ translationKeyPrefix + '.reelCutting.cuttingButton_heading' | cxTranslate }}</span>
      <ul>
        <li>
          <ng-container [ngTemplateOutlet]="buttonValueLabel"></ng-container>
        </li>
        <ng-container *pyPrincipalConfiguration="'enableNetPrices'">
          <li *ngIf="showPrice" class="d-flex justify-content-between align-items-center">
            <span>{{ translationKeyPrefix + '.reelCutting.calculatedCuttingCost_heading' | cxTranslate }}</span>
            <span>{{ (entry.reelCutting?.cuttingPrice | price : 'symbol-narrow' : true) || '-' }}</span>
          </li>
          <li *ngIf="showPrice" class="d-flex justify-content-between align-items-center">
            <span>{{ translationKeyPrefix + '.reelCutting.packagingCost_heading' | cxTranslate }}</span>
            <span>{{ (entry.reelCutting?.packagingPrice | price : 'symbol-narrow' : true) || '-' }}</span>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</ng-template>

<ng-template #buttonValueLabel>
  {{
    'cart.reelCutting.buttonValue_hint'
      | cxTranslate : { numberOfTargetReels: entry.reelCutting.widths?.length, widths: entry.reelCutting.widths.join(', ') }
  }}
</ng-template>
