import { StateUtils } from '@spartacus/core';
import { SearchTermFacetMappingHint } from '../../../model/solr-search-config';
import { PyStateUtils } from '../../../state/utils';
import { SEARCH_HINTS } from '../solr-search-state';

export enum SearchHintsActionTypes {
  LoadSearchHints = '[SearchHints] Load Search Hints',
  LoadSearchHintsSuccess = '[SearchHints] Load Search Hints Success',
  LoadSearchHintsFail = '[SearchHints] Load Search Hints Fail',
}

export class LoadSearchHints extends StateUtils.LoaderLoadAction {
  readonly type = SearchHintsActionTypes.LoadSearchHints;

  constructor(public userId: string) {
    super(SEARCH_HINTS);
  }
}

export class LoadSearchHintsSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = SearchHintsActionTypes.LoadSearchHintsSuccess;

  constructor(public payload: SearchTermFacetMappingHint[]) {
    super(SEARCH_HINTS);
  }
}

export class LoadSearchHintsFail extends PyStateUtils.LoaderFailAction {
  readonly type = SearchHintsActionTypes.LoadSearchHintsFail;

  constructor(public payload: any) {
    super(SEARCH_HINTS, payload);
  }
}

export type SearchHintsActions = LoadSearchHints | LoadSearchHintsSuccess | LoadSearchHintsFail;
