import { animate, style, transition, trigger } from '@angular/animations';

export const fadeInFadeOutAnimation = trigger('fadeInFadeOutAnimation', [
  transition(':enter', [
    style({
      opacity: 0,
    }),
    animate(
      '0.3s cubic-bezier(0.7, 0.3, 0.1, 1)',
      style({
        opacity: 1,
      })
    ),
  ]),
  transition(':leave', [
    style({
      opacity: 1,
    }),
    animate(
      '0.3s cubic-bezier(0.7, 0.3, 0.1, 1)',
      style({
        opacity: 0,
      })
    ),
  ]),
]);
