import { NgModule } from '@angular/core';
import { PyDatePipe, PyNumericPipe, TranslatePipe } from './pipe';
import { CxCurrencyPipe } from './pipe/currency.pipe';
import { TranslatePipe2 } from './pipe/translate2.pipe';

@NgModule({
  declarations: [PyNumericPipe, TranslatePipe, TranslatePipe2, PyDatePipe, CxCurrencyPipe],
  exports: [PyNumericPipe, TranslatePipe, TranslatePipe2, PyDatePipe, CxCurrencyPipe],
})
export class I18nModule {}
