import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '../../../../core/i18n';
import { ResponsiveTableModule } from '../../responsive-table';
import { CatalogArticleModule } from '../catalog-article.module';
import { CatalogArticleListComponent } from './catalog-article-list.component';

/**
 * This could probably be moved to shared later and reused in other places.
 * However since it now depends on CatalogArticleListItemModule this would create
 * a circular dependency outside of catalog.
 */
@NgModule({
  imports: [CommonModule, I18nModule, ResponsiveTableModule, CatalogArticleModule],
  declarations: [CatalogArticleListComponent],
  exports: [CatalogArticleListComponent],
})
export class CatalogArticleListModule {}
