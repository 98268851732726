import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WindowRef } from '../../../core/window';

@Injectable({
  providedIn: 'root',
})
export class ArticleListItemsVisibilityService {
  private _fullyRenderableArticleListItemsMap$ = new BehaviorSubject(new Map<string, boolean>());
  readonly surplus: number = 5;

  public get fullyRenderableArticleListItemsMap$() {
    return this._fullyRenderableArticleListItemsMap$;
  }

  constructor(private windowRef: WindowRef) {}

  private setFullyRenderableArticleListItemsMap$(value): void {
    this._fullyRenderableArticleListItemsMap$.next(value);
  }

  private isPartiallyOrFullyInViewport(position: DOMRect): boolean {
    return position.top < this.windowRef.nativeWindow.innerHeight && position.bottom >= 0;
  }

  getFullyRenderableArticleListItemsMap$(): BehaviorSubject<Map<string, boolean>> {
    return this._fullyRenderableArticleListItemsMap$;
  }

  updateFullyRenderableArticleListItemsMap(articleListItemLoaderElementRefs: ElementRef[], articleRefs: string[]): void {
    if (this.windowRef.isBrowser()) {
      let firstAndLastInViewportArticleRefs = [];

      if (articleListItemLoaderElementRefs?.length) {
        const articleLoaderElementsInViewportArray = articleListItemLoaderElementRefs.filter((item: ElementRef) => {
          return this.isPartiallyOrFullyInViewport(item.nativeElement.getBoundingClientRect());
        });

        if (articleLoaderElementsInViewportArray.length) {
          const firstAndLastInViewportArticleLoaderElements = [
            articleLoaderElementsInViewportArray[0],
            articleLoaderElementsInViewportArray[articleLoaderElementsInViewportArray.length - 1],
          ];

          firstAndLastInViewportArticleRefs = firstAndLastInViewportArticleLoaderElements.map(
            (item) => item.nativeElement.dataset.articleref
          );

          const indexOfFirstInViewportArticle = articleRefs.indexOf(firstAndLastInViewportArticleRefs[0]);
          const indexOfLastInViewportArticle = articleRefs.indexOf(firstAndLastInViewportArticleRefs[1]);

          const fullyRenderableArticleListItemsMap = new Map();

          articleRefs.forEach((articleRef, index) => {
            fullyRenderableArticleListItemsMap.set(
              articleRef,
              index >= indexOfFirstInViewportArticle - this.surplus && index <= indexOfLastInViewportArticle + this.surplus
            );
          });

          this.setFullyRenderableArticleListItemsMap$(fullyRenderableArticleListItemsMap);
        }
      }
    }
  }
}
