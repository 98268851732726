import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        shoppingLists: {
          module: () => import('./cms-shopping-lists.module').then((m) => m.CmsShoppingListsModule),
          cmsComponents: ['CmsShoppingListsComponent'],
        },
      },
    }),
  ],
})
export class ShoppingListsFeatureModule {}
