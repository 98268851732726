import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CxNumericPipe } from '@spartacus/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../core/i18n';
import { UrlModule } from '../../../core/routing';
import { PrincipalConfigurationDirectiveModule } from '../../directives';
import { PipesModule } from '../../pipes';
import { ArticleImageModule, ArticleInfoModule } from '../article';
import { ArticlePriceModule } from '../article-price';
import { ArticleInfoAttributesModule } from '../article/article-info-attributes/article-info-attributes.module';
import { CartOverlayMessageModule } from '../cart-overlay-message';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { ConfirmationOverlayMessageModule } from '../confirmation-overlay-message';
import { CounterInputModule } from '../counter-input/counter-input.module';
import { CuttingButtonModule } from '../cutting/cutting-button';
import { DeleteButtonModule } from '../delete-button';
import { DetailedStockInfoModule } from '../detailed-stock-info';
import { DropdownModule } from '../dropdown';
import { NameMarkingButtonModule } from '../name-marking';
import { NameMarkingTextModule } from '../name-marking/name-marking-text';
import { NotificationModule } from '../notification';
import { OrderEntryAccountingCodesModalModule } from '../order-entry-accounting-codes-modal/order-entry-accounting-codes-modal.module';
import { OrderEntryAccountingCodesModule } from '../order-entry-accounting-codes/order-entry-accounting-codes.module';
import { OrderEntryFullPalletUpgradeWrapperModule } from '../order-entry-full-pallet-upgrade-wrapper/order-entry-full-pallet-upgrade-wrapper.module';
import { OrderEntryResolveOptionsModule } from '../order-entry-resolve-options/order-entry-resolve-options.module';
import { OrderEntrySimilarArticlesModule } from '../order-entry-similar-articles';
import { OrderEntrySubstitutesArticlesModule } from '../order-entry-substitutes-articles';
import { OrderLineInputModule } from '../order-line-input/order-line-input.module';
import { PalletFlagNoteModule } from '../pallet-flag-note';
import { PalletFlagModule } from '../pallet-flag/pallet-flag.module';
import { PrePrintedButtonModule } from '../pre-printed';
import { QuantityAndUnitSelectorModule } from '../quantity-and-unit-selector';
import { ReamingButtonModule } from '../reaming-button';
import { ReelCuttingButtonModule } from '../reel-cutting/reel-cutting-button';
import { OrderEntryListItemLoadingComponent } from './order-entry-list-item-loading/order-entry-list-item-loading.component';
import { OrderEntryListItemComponent } from './order-entry-list-item/order-entry-list-item.component';
import { OrderEntryRowLoadingComponent } from './order-entry-row-loading/order-entry-row-loading.component';
import { OrderEntryRowComponent } from './order-entry-row/order-entry-row.component';
import { OrderReturnEntryFormModule } from './order-return-entry-form/order-return-entry-form.module';
import { OrderReturnEntryInfoModule } from './order-return-entry-info/order-return-entry-info.module';
import { SampleOrderEntryRowComponent } from './sample-order-entry-row/sample-order-entry-row.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    I18nModule,
    NzIconModule,
    CheckboxModule,
    ArticleInfoModule,
    ArticleImageModule,
    ArticlePriceModule,
    CounterInputModule,
    DropdownModule,
    CartOverlayMessageModule,
    ConfirmationOverlayMessageModule,
    PipesModule,
    OrderLineInputModule,
    NameMarkingButtonModule,
    CuttingButtonModule,
    ReelCuttingButtonModule,
    ReamingButtonModule,
    OrderEntryAccountingCodesModule,
    OrderEntryAccountingCodesModalModule,
    DetailedStockInfoModule,
    NotificationModule,
    OrderEntryResolveOptionsModule,
    OrderEntrySimilarArticlesModule,
    OrderEntrySubstitutesArticlesModule,
    PrincipalConfigurationDirectiveModule,
    NameMarkingTextModule,
    NgbTooltipModule,
    NgbPopoverModule,
    QuantityAndUnitSelectorModule,
    DeleteButtonModule,
    UrlModule,
    RouterModule,
    PalletFlagModule,
    OrderEntryFullPalletUpgradeWrapperModule,
    ArticleInfoAttributesModule,
    PalletFlagNoteModule,
    OrderReturnEntryFormModule,
    OrderReturnEntryInfoModule,
    PrePrintedButtonModule,
  ],
  providers: [CxNumericPipe],
  declarations: [
    OrderEntryRowComponent,
    OrderEntryListItemComponent,
    OrderEntryRowLoadingComponent,
    OrderEntryListItemLoadingComponent,
    SampleOrderEntryRowComponent,
  ],
  exports: [
    OrderEntryRowComponent,
    OrderEntryListItemComponent,
    OrderEntryRowLoadingComponent,
    OrderEntryListItemLoadingComponent,
    SampleOrderEntryRowComponent,
  ],
})
export class OrderEntryModule {}
