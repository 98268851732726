import { Pagination } from '../../../model/search.model';
import { PagesState, PaginationState, SearchPageResultState } from '../base-feature-state';

/**
 * When a new key is provided to this function it will assume that it is
 * used in the context of a search pagination context.
 */
export class SearchPaginationUtil {
  /**
   * The key will pop an element off of the state and shift the trailing elements up the affected pages.
   *
   * If the last loaded page in the state becomes empty after this operation it is removed in the process.
   *
   * @param key of the entry to pop off a page
   * @param startAtPage the start page
   * @param found key found already
   * @param state with entries to shift
   **/
  public static popEntry<T extends SearchPageResultState>(
    key: any,
    state: PaginationState<T>,
    startAtPage: number = 0,
    found: boolean = false
  ) {
    const pageKeys = Object.keys(state.pages);
    if (pageKeys.length === 0) {
      return state;
    }

    // Initial object clones
    let pagination = { ...state.pagination };
    const pages = { ...state.pages };

    const indices = this.getPageIndices(pageKeys);

    for (let pageIndex = startAtPage; pageIndex < pagination.totalPages; pageIndex++) {
      // Continue if page is not loaded
      if (indices.indexOf(pageIndex) < 0) {
        continue;
      }
      const page = pages[pageIndex];
      const nextPageIndex = pageIndex + 1;
      const hasNextPage = !!pages[nextPageIndex];

      const onLastLoadedPage = pageIndex === indices[indices.length - 1];

      if (page.value.results.includes(key)) {
        const results = pages[pageIndex].value.results.filter((e) => e !== key);
        if (hasNextPage) {
          const firstElement = [...pages[nextPageIndex].value.results].shift();
          results.push(firstElement);
          pages[pageIndex] = this.createNewPageState<T>(pages, pageIndex, results, pages[nextPageIndex].value.lastUpdateTime);
          return this.popEntry(
            firstElement,
            {
              ...state,
              pagination,
              pages,
            },
            nextPageIndex,
            true
          );
        } else {
          if (pageIndex === pagination.totalPages - 1) {
            if (results.length === 0) {
              delete pages[pageIndex];
              pagination = this.updatePaginationAfterRemoval(pagination);
            } else {
              pages[pageIndex] = this.createNewPageState<T>(pages, pageIndex, results, pages[pageIndex].value.lastUpdateTime);
            }
          } else {
            // Update result list after filtering, but set to invalidated (mark for reload)
            pages[pageIndex] = this.createNewPageState<T>(pages, pageIndex, results, undefined);
          }
          if (onLastLoadedPage) {
            pagination.totalCount -= 1;
          }
          return this.popEntry(
            undefined,
            {
              ...state,
              pagination,
              pages,
            },
            nextPageIndex,
            true
          );
        }
      } else if (found) {
        if (onLastLoadedPage) {
          pagination.totalCount -= 1;
        }

        if (pages[pageIndex].value.results.length === 1) {
          delete pages[pageIndex];
          pagination = this.updatePaginationAfterRemoval(pagination);
        } else {
          pages[pageIndex] = this.invalidatePageState<T>(pages, pageIndex);
        }
      }
    }

    return {
      ...state,
      pagination,
      pages,
    };
  }

  /**
   * The key will be push an element onto the first page of the state and shift the last element of each page down the pages.
   *
   * @param key of the entry to push to the first page
   * @param state with entries to shift
   **/
  public static pushEntry<T extends SearchPageResultState>(key: any, state: PaginationState<T>) {
    const pageKeys = Object.keys(state.pages);
    if (pageKeys.length === 0) {
      return state;
    }

    // Initial object clones
    const pagination = { ...state.pagination };
    const pages = { ...state.pages };

    if (pagination.totalCount % pagination.count === 0) {
      pagination.totalPages += 1;
    }

    // Increment total count
    pagination.totalCount += 1;

    const indices = this.getPageIndices(pageKeys);
    const lastPageIndex = indices[indices.length - 1];
    const lastPage = pages[lastPageIndex];

    // Last loaded page is full and last loaded page is also last page in server db
    // Then we can safely add a new loaded page and update the pagination accordingly
    if (this.canAddNewPage(lastPage, state, lastPageIndex)) {
      // Create new page
      const newPageIndex = lastPageIndex + 1;
      pages[newPageIndex] = this.createNewPage(lastPage);
      pagination.hasNext = true;
      indices.push(newPageIndex);
    }

    const page = { ...pages[0] };
    const results = [...page.value.results];

    if (this.isSinglePage(results, pagination)) {
      // We only have one page
      results.unshift(key);

      page.value = {
        ...page.value,
        results,
      };
      pages[0] = page;
    } else {
      // We have multiple pages and must shift entries across pages
      this.shiftDownOverPages(indices, key, pages, pagination.count);
    }

    return {
      ...state,
      pagination,
      pages,
    };
  }

  private static updatePaginationAfterRemoval(pagination: Pagination) {
    pagination.totalPages -= 1;
    if (pagination.page === pagination.totalPages) {
      pagination.page -= 1;
    }
    if (pagination.page + 1 === pagination.totalPages) {
      pagination.hasNext = false;
    }
    return pagination;
  }

  private static isSinglePage(results: string[], pagination: Pagination) {
    return results.length < pagination.count;
  }

  private static canAddNewPage(lastPage, state: PaginationState<any>, lastPageIndex) {
    return lastPage.value.results.length === state.pagination.count && state.pagination.totalPages === lastPageIndex + 1;
  }

  private static invalidatePageState<T extends SearchPageResultState>(pages: PagesState<T>, pageIndex) {
    return {
      ...pages[pageIndex],
      value: {
        ...pages[pageIndex].value,
        lastUpdateTime: undefined,
        results: [],
      },
    };
  }

  private static createNewPageState<T extends SearchPageResultState>(pages: PagesState<T>, pageIndex, results, lastUpdateTime) {
    return {
      ...pages[pageIndex],
      value: {
        ...pages[pageIndex].value,
        lastUpdateTime: lastUpdateTime,
        results: results,
      },
    };
  }

  private static shiftDownOverPages(indices, key, pages, pageSize) {
    let lastElem = key;

    for (let i = 0; i < indices.length; i++) {
      const pageIndex = indices[i];
      const page = { ...pages[pageIndex] };
      if (pageIndex === i) {
        const oldPageResults = page.value.results;
        const results = [...oldPageResults];
        if (results.length === pageSize) {
          results.pop();
        }
        results.unshift(lastElem);
        if (oldPageResults.length > 0) {
          lastElem = oldPageResults[oldPageResults.length - 1];
        }
        page.value = {
          ...page.value,
          results,
        };
      } else {
        page.value = {
          lastUpdateTime: undefined,
          results: [],
        };
      }

      pages[pageIndex] = page;
    }
    return pages;
  }

  private static createNewPage(lastPage) {
    return {
      ...lastPage,
      value: {
        ...lastPage.value,
        results: [],
      },
    };
  }

  private static getPageIndices(pageKeys) {
    return pageKeys.map((k) => parseInt(k, 10)).sort();
  }
}
