import { BaseSiteEffects } from './base-site.effect';
import { CurrenciesEffects } from './currencies.effect';
import { LanguagesEffects } from './languages.effect';
import { SiteCountryEffects } from './site-country.effect';

export const effects: any[] = [LanguagesEffects, CurrenciesEffects, BaseSiteEffects, SiteCountryEffects];

export * from './base-site.effect';
export * from './currencies.effect';
export * from './languages.effect';
export * from './site-country.effect';
