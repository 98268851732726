<ng-container *ngIf="isLoading">
  <div class="d-flex justify-content-center my-3 w-100">
    <div class="row col-12 col-xl-8 results-wrapper">
      <ul
        class="d-flex flex-md-wrap categories-tabs px-0 px-md-3 gap-3 side-scroll"
        pyNavSideScroll
        [listCount]="4"
        [skipNavScrollArrows]="true"
      >
        <li class="py-1">
          <div class="category-label">{{ 'catalog.goToCategory_heading' | cxTranslate }}</div>
        </li>

        <li class="skeleton category-placeholder py-1" *ngFor="let i of [].constructor(3)"></li>
      </ul>
    </div>
  </div>

  <div class="d-flex justify-content-center w-100 mt-5">
    <div class="row col-12 col-xl-8 results-wrapper">
      <div class="col-12 px-0 px-md-3 pb-3">
        <ul
          ngbNav
          pyNavSideScroll
          #nav="ngbNav"
          [activeId]="activeTab ? activeTab : initialActiveTab"
          class="nav-tabs nav active-tab-border dark-border side-scroll hide-on-print border-bottom"
        >
          <li [ngbNavItem]="catalogTabTypes.Products">
            <a ngbNavLink>
              {{ 'catalog.products_tab' | cxTranslate }}
            </a>
            <ng-template ngbNavContent>
              <div class="row">
                <div *ngFor="let i of [].constructor(4)" class="col-12 col-sm-6 col-md-3 mb-2 pr-sm-0">
                  <div class="skeleton w-100 product-placeholder"></div>
                </div>
              </div>
            </ng-template>
          </li>

          <li [ngbNavItem]="catalogTabTypes.Articles">
            <a ngbNavLink>
              {{ 'catalog.articles_tab' | cxTranslate }}
            </a>
            <ng-template ngbNavContent>
              <ng-container *ngTemplateOutlet="articleLoadingSkeleton"></ng-container>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center w-100 view-all py-6">
    <div class="row col-12 col-xl-8 mr-md-2 results-wrapper justify-content-end">
      <py-action-button [buttonClass]="'btn btn-primary w-100'" [loading]="true">
        <i nz-icon nzType="arrow-right"></i>
      </py-action-button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="showResults && (articleResultRefs?.length || products$?.length)">
  <div class="d-flex justify-content-center my-3">
    <div class="row col-12 col-xl-8 results-wrapper">
      <div class="col-12 px-0 px-md-3">
        <div class="d-flex flex-column flex-md-row">
          <div class="d-flex flex-column w-100 gap-3">
            <!-- Search Hints -->
            <ng-container *pyPrincipalConfiguration="'enableDisplaySearchHint'">
              <ng-container *ngIf="searchFormValue | searchHints : searchHints as hints">
                <div *ngIf="hints.length" class="hint-wrapper">
                  <div class="d-flex justify-content-start w-100">
                    <div class="col-12 col-xl-8 px-0 d-flex align-items-center results-wrapper">
                      <py-search-hints [form]="form" [hints]="hints" [singleRow]="true"></py-search-hints>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>

            <!-- Search Categories -->
            <div class="categories-wrapper position-relative" *ngIf="categories?.length">
              <ul
                class="d-flex flex-md-wrap gap-2 categories-tabs side-scroll"
                pyNavSideScroll
                [listCount]="categories?.length + 1"
                [skipNavScrollArrows]="true"
              >
                <li class="py-1">
                  <div class="category-label">{{ 'catalog.goToCategory_heading' | cxTranslate }}</div>
                </li>

                <li class="category py-1" *ngFor="let category of categories">
                  <cx-generic-link [url]="getUrl(category, catalogItemQueryParam)">
                    {{ category.name }}
                  </cx-generic-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center w-100 mt-5">
    <div class="row col-12 col-xl-8 results-wrapper">
      <div class="col-12 px-0 px-md-3 pb-3">
        <ul
          ngbNav
          pyNavSideScroll
          #nav="ngbNav"
          [activeId]="activeTab ? activeTab : initialActiveTab"
          (activeIdChange)="selectTab.emit($event)"
          class="nav-tabs nav active-tab-border dark-border side-scroll hide-on-print border-bottom"
        >
          <li [ngbNavItem]="catalogTabTypes.Products" *ngIf="products$?.length">
            <a ngbNavLink
              >{{ 'catalog.products_tab' | cxTranslate }}
              <py-badge class="ml-2" [tag]="true">{{ searchResults?.pagination?.totalResults }}</py-badge>
            </a>
            <ng-template ngbNavContent>
              <py-product-list-item-loader
                [products]="products$"
                [productResultRefs]="searchResults?.productResultRefs || []"
                [queryParams]="catalogItemQueryParam"
                [isSecondaryVersion]="true"
                class="search-results"
              ></py-product-list-item-loader>
            </ng-template>
          </li>

          <li [ngbNavItem]="catalogTabTypes.Articles" *ngIf="articleResultRefs?.length">
            <a ngbNavLink>
              {{ 'catalog.articles_tab' | cxTranslate }}
              <py-badge class="ml-2" [tag]="true">{{ searchResults?.totalArticleResults }}</py-badge>
            </a>
            <ng-template ngbNavContent>
              <py-catalog-article-list
                [showHeader]="false"
                [articleResultRefs]="articleResultRefs || []"
                [queryParams]="catalogItemQueryParam"
                [freeTextSearch]="searchFormValue"
              ></py-catalog-article-list>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center w-100 view-all py-6">
    <div class="row col-12 col-xl-8 mr-md-2 results-wrapper justify-content-end">
      <py-action-button
        [buttonClass]="'btn btn-primary w-100'"
        [buttonLabel]="'catalog.viewAllResults_action' | cxTranslate"
        (click)="onSubmit()"
      >
        <i nz-icon nzType="arrow-right"></i>
      </py-action-button>
    </div>
  </div>
</ng-container>

<ng-template #articleLoadingSkeleton>
  <div class="row mt-4 mx-0">
    <div *ngFor="let i of [].constructor(4)" class="col-12 d-flex mx-0 px-0 pb-3 mb-3 border-bottom">
      <div class="col-8 pl-0">
        <div class="d-flex">
          <div class="skeleton article-placeholder"></div>

          <div class="d-flex flex-column w-100 ml-2">
            <div class="skeleton info-section-skeleton mb-1"></div>
            <div class="skeleton info-section-skeleton"></div>
          </div>
        </div>
      </div>

      <div class="col-4 px-0">
        <div class="d-flex flex-column">
          <div class="d-flex mb-2">
            <div class="skeleton w-100 mr-2"></div>
            <div class="skeleton w-100 ml-2"></div>
          </div>
          <div class="skeleton w-100"></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
