import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../core/i18n';
import { PrincipalConfigurationDirectiveModule } from '../../directives';
import { PipesModule } from '../../pipes';
import { SpinnerModule } from '../spinner';
import { InformationTabComponent } from './information-tab.component';

@NgModule({
  declarations: [InformationTabComponent],
  imports: [
    CommonModule,
    I18nModule,
    SpinnerModule,
    NzIconModule,
    PipesModule,
    PrincipalConfigurationDirectiveModule,
    NgbTooltipModule,
  ],
  exports: [InformationTabComponent],
})
export class InformationTabModule {}
