import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import { B2BComment, CartOwnerDetails, HttpErrorModel, Reinvoicing } from '../../../../../core/model';
import { PyStateUtils } from '../../../../../core/state';
import {
  DELETE_COMMENT_PROCESS as DELETE_APPROVER_COMMENT_PROCESS,
  DELETE_COMMENT_PROCESS as DELETE_BUYER_COMMENT_PROCESS,
  SET_COMMENT_PROCESS as SET_APPROVER_COMMENT_PROCESS,
  SET_COMMENT_PROCESS as SET_BUYER_COMMENT_PROCESS,
  SET_CART_OWNER_DETAILS_PROCESS,
  SET_COST_PLACE_PROCESS,
  SET_CUSTOMER_REFERENCE_PROCESS,
  SET_DELIVERY_NOTE_PROCESS,
  SET_GOODS_MARKING_PROCESS,
  SET_INVOICE_NOTE_PROCESS,
  SET_MESSAGE_PROCESS,
  SET_ORDER_NOTE_PROCESS,
  SET_PHONE_NUMBER_PROCESS,
  SET_REINVOICING_PROCESS,
} from '../checkout-state';
import { CheckoutStepsEntityAction, PayloadWithCheckoutKey } from './checkout-steps-entity.action';

export enum CheckoutReviewActionTypes {
  SetOrderNote = '[Checkout] Set order note',
  SetOrderNoteFail = '[Checkout] Set order note fail',
  SetOrderNoteSuccess = '[Checkout] Set order note success',
  ResetSetOrderNote = '[Checkout] Reset set order note',

  SetDeliveryNote = '[Checkout] Set delivery note',
  SetDeliveryNoteFail = '[Checkout] Set delivery note fail',
  SetDeliveryNoteSuccess = '[Checkout] Set delivery note success',
  ResetSetDeliveryNote = '[Checkout] Reset set delivery note',

  SetMessage = '[Checkout] Set message',
  SetMessageFail = '[Checkout] Set message fail',
  SetMessageSuccess = '[Checkout] Set message success',
  ResetSetMessage = '[Checkout] Reset set message',

  SetGoodsMarking = '[Checkout] Set goods marking',
  SetGoodsMarkingFail = '[Checkout] Set goods marking fail',
  SetGoodsMarkingSuccess = '[Checkout] Set goods marking success',
  ResetSetGoodsMarking = '[Checkout] Reset set goods marking',

  SetCustomerReference = '[Checkout] Set customer reference',
  SetCustomerReferenceFail = '[Checkout] Set customer reference fail',
  SetCustomerReferenceSuccess = '[Checkout] Set customer reference success',
  ResetSetCustomerReference = '[Checkout] Reset set customer reference',

  SetPhoneNumber = '[Checkout] Set phone number',
  SetPhoneNumberFail = '[Checkout] Set phone number fail',
  SetPhoneNumberSuccess = '[Checkout] Set phone number success',
  ResetSetPhoneNumber = '[Checkout] Reset set phone number',

  SetReinvoicing = '[Checkout] Set reinvoicing',
  SetReinvoicingFail = '[Checkout] Set reinvoicing fail',
  SetReinvoicingSuccess = '[Checkout] Set reinvoicing success',
  ResetSetReinvoicing = '[Checkout] Reset set reinvoicing',

  SetInvoiceNote = '[Checkout] Set invoice note',
  SetInvoiceNoteFail = '[Checkout] Set invoice note fail',
  SetInvoiceNoteSuccess = '[Checkout] Set invoice note success',
  ResetSetInvoiceNote = '[Checkout] Reset set invoice note',

  SetCostPlace = '[Checkout] Set cost place',
  SetCostPlaceFail = '[Checkout] Set cost place fail',
  SetCostPlaceSuccess = '[Checkout] Set cost place success',
  ResetSetCostPlace = '[Checkout] Reset set cost place',

  SetApproverComment = '[Checkout] Set approver comment',
  SetApproverCommentFail = '[Checkout] Set approver comment fail',
  SetApproverCommentSuccess = '[Checkout] Set approver comment success',

  DeleteApproverComment = '[Checkout] Delete approver comment',
  DeleteApproverCommentFail = '[Checkout] Delete approver comment fail',
  DeleteApproverCommentSuccess = '[Checkout] Delete approver comment success',

  SetBuyerComment = '[Checkout] Set buyer comment',
  SetBuyerCommentFail = '[Checkout] Set buyer comment fail',
  SetBuyerCommentSuccess = '[Checkout] Set buyer comment success',

  DeleteBuyerComment = '[Checkout] Delete buyer comment',
  DeleteBuyerCommentFail = '[Checkout] Delete buyer comment fail',
  DeleteBuyerCommentSuccess = '[Checkout] Delete buyer comment success',

  SetCartOwnerDetails = '[Checkout] Set cart owner details',
}

interface SetOrderNotePayload extends PayloadWithCheckoutKey {
  userId: string;
  note: string;
}

interface SetOrderNoteFailPayload extends SetOrderNotePayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SetOrderNoteSuccessPayload extends SetOrderNotePayload {}

interface SetApproverCommentPayload extends PayloadWithCheckoutKey {
  userId: string;
  comment: B2BComment;
}

interface SetApproverCommentFailPayload extends SetApproverCommentPayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SetApproverCommentSuccessPayload extends SetApproverCommentPayload {}

interface SetBuyerCommentPayload extends PayloadWithCheckoutKey {
  userId: string;
  comment: B2BComment;
}

interface SetBuyerCommentFailPayload extends SetBuyerCommentPayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SetBuyerCommentSuccessPayload extends SetBuyerCommentPayload {}

interface DeleteCommentPayload extends PayloadWithCheckoutKey {
  userId: string;
  comment: B2BComment;
}

interface DeleteCommentFailPayload extends DeleteCommentPayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DeleteCommentSuccessPayload extends DeleteCommentPayload {}

interface SetDeliveryNotePayload extends PayloadWithCheckoutKey {
  userId: string;
  note: string;
}

interface SetDeliveryNoteFailPayload extends SetDeliveryNotePayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SetDeliveryNoteSuccessPayload extends SetDeliveryNotePayload {}

interface SetMessagePayload extends PayloadWithCheckoutKey {
  userId: string;
  message: string;
}

interface SetMessageFailPayload extends SetMessagePayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SetMessageSuccessPayload extends SetMessagePayload {}

interface SetGoodsMarkingPayload extends PayloadWithCheckoutKey {
  userId: string;
  goodsMarking: string;
}

interface SetGoodsMarkingFailPayload extends SetGoodsMarkingPayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SetGoodsMarkingSuccessPayload extends SetGoodsMarkingPayload {}

interface SetCustomerReferencePayload extends PayloadWithCheckoutKey {
  userId: string;
  customerReference: string;
}

interface SetCustomerReferenceFailPayload extends SetCustomerReferencePayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SetCustomerReferenceSuccessPayload extends SetCustomerReferencePayload {}

interface SetPhoneNumberPayload extends PayloadWithCheckoutKey {
  userId: string;
  phoneNumber: string;
}

interface SetCartOwnerDetailsPayload extends PayloadWithCheckoutKey {
  cartOwnerDetails: CartOwnerDetails;
}

interface SetPhoneNumberFailPayload extends SetPhoneNumberPayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SetPhoneNumberSuccessPayload extends SetPhoneNumberPayload {}

interface SetReinvoicingPayload extends PayloadWithCheckoutKey {
  userId: string;
  reinvoicing: Reinvoicing;
}

interface SetReinvoicingFailPayload extends SetReinvoicingPayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SetReinvoicingSuccessPayload extends SetReinvoicingPayload {}

interface SetInvoiceNotePayload extends PayloadWithCheckoutKey {
  userId: string;
  note: string;
}

interface SetInvoiceNoteFailPayload extends SetInvoiceNotePayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SetInvoiceNoteSuccessPayload extends SetInvoiceNotePayload {}

interface SetCostPlacePayload extends PayloadWithCheckoutKey {
  userId: string;
  costPlace: string;
}

interface SetCostPlaceFailPayload extends SetCostPlacePayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SetCostPlaceSuccessPayload extends SetCostPlacePayload {}
export class SetOrderNote extends StateUtils.EntityLoadAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetOrderNote;

  constructor(public payload: SetOrderNotePayload) {
    super(PROCESS_FEATURE, SET_ORDER_NOTE_PROCESS);
  }
}

export class SetOrderNoteFail extends PyStateUtils.EntityFailAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetOrderNoteFail;

  constructor(public payload: SetOrderNoteFailPayload) {
    super(PROCESS_FEATURE, SET_ORDER_NOTE_PROCESS, payload.error);
  }
}

export class SetOrderNoteSuccess extends StateUtils.EntitySuccessAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetOrderNoteSuccess;

  constructor(public payload: SetOrderNoteSuccessPayload) {
    super(PROCESS_FEATURE, SET_ORDER_NOTE_PROCESS);
  }
}

export class ResetSetOrderNote extends StateUtils.EntityLoaderResetAction {
  readonly type = CheckoutReviewActionTypes.ResetSetOrderNote;

  constructor() {
    super(PROCESS_FEATURE, SET_ORDER_NOTE_PROCESS);
  }
}

export class SetApproverComment extends StateUtils.EntityLoadAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetApproverComment;

  constructor(public payload: SetApproverCommentPayload) {
    super(PROCESS_FEATURE, SET_APPROVER_COMMENT_PROCESS);
  }
}

export class SetApproverCommentFail extends PyStateUtils.EntityFailAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetApproverCommentFail;

  constructor(public payload: SetApproverCommentFailPayload) {
    super(PROCESS_FEATURE, SET_APPROVER_COMMENT_PROCESS, payload.error);
  }
}

export class SetApproverCommentSuccess extends StateUtils.EntitySuccessAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetApproverCommentSuccess;

  constructor(public payload: SetApproverCommentSuccessPayload) {
    super(PROCESS_FEATURE, SET_APPROVER_COMMENT_PROCESS);
  }
}

export class DeleteApproverComment extends StateUtils.EntityLoadAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.DeleteApproverComment;

  constructor(public payload: DeleteCommentPayload) {
    super(PROCESS_FEATURE, DELETE_APPROVER_COMMENT_PROCESS);
  }
}

export class DeleteApproverCommentFail extends PyStateUtils.EntityFailAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.DeleteApproverCommentFail;

  constructor(public payload: DeleteCommentFailPayload) {
    super(PROCESS_FEATURE, DELETE_APPROVER_COMMENT_PROCESS, payload.error);
  }
}

export class DeleteApproverCommentSuccess extends StateUtils.EntitySuccessAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.DeleteApproverCommentSuccess;

  constructor(public payload: DeleteCommentSuccessPayload) {
    super(PROCESS_FEATURE, DELETE_APPROVER_COMMENT_PROCESS);
  }
}

export class SetBuyerComment extends StateUtils.EntityLoadAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetBuyerComment;

  constructor(public payload: SetBuyerCommentPayload) {
    super(PROCESS_FEATURE, SET_BUYER_COMMENT_PROCESS);
  }
}

export class SetBuyerCommentFail extends PyStateUtils.EntityFailAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetBuyerCommentFail;

  constructor(public payload: SetBuyerCommentFailPayload) {
    super(PROCESS_FEATURE, SET_BUYER_COMMENT_PROCESS, payload.error);
  }
}

export class SetBuyerCommentSuccess extends StateUtils.EntitySuccessAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetBuyerCommentSuccess;

  constructor(public payload: SetBuyerCommentSuccessPayload) {
    super(PROCESS_FEATURE, SET_BUYER_COMMENT_PROCESS);
  }
}

export class DeleteBuyerComment extends StateUtils.EntityLoadAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.DeleteBuyerComment;

  constructor(public payload: DeleteCommentPayload) {
    super(PROCESS_FEATURE, DELETE_BUYER_COMMENT_PROCESS);
  }
}

export class DeleteBuyerCommentFail extends PyStateUtils.EntityFailAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.DeleteBuyerCommentFail;

  constructor(public payload: DeleteCommentFailPayload) {
    super(PROCESS_FEATURE, DELETE_BUYER_COMMENT_PROCESS, payload.error);
  }
}

export class DeleteBuyerCommentSuccess extends StateUtils.EntitySuccessAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.DeleteBuyerCommentSuccess;

  constructor(public payload: DeleteCommentSuccessPayload) {
    super(PROCESS_FEATURE, DELETE_BUYER_COMMENT_PROCESS);
  }
}

export class SetDeliveryNote extends StateUtils.EntityLoadAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetDeliveryNote;

  constructor(public payload: SetDeliveryNotePayload) {
    super(PROCESS_FEATURE, SET_DELIVERY_NOTE_PROCESS);
  }
}

export class SetDeliveryNoteFail extends PyStateUtils.EntityFailAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetDeliveryNoteFail;

  constructor(public payload: SetDeliveryNoteFailPayload) {
    super(PROCESS_FEATURE, SET_DELIVERY_NOTE_PROCESS, payload.error);
  }
}

export class SetDeliveryNoteSuccess extends StateUtils.EntitySuccessAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetDeliveryNoteSuccess;

  constructor(public payload: SetDeliveryNoteSuccessPayload) {
    super(PROCESS_FEATURE, SET_DELIVERY_NOTE_PROCESS);
  }
}

export class ResetSetDeliveryNote extends StateUtils.EntityLoaderResetAction {
  readonly type = CheckoutReviewActionTypes.ResetSetDeliveryNote;

  constructor() {
    super(PROCESS_FEATURE, SET_DELIVERY_NOTE_PROCESS);
  }
}

export class SetMessage extends StateUtils.EntityLoadAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetMessage;

  constructor(public payload: SetMessagePayload) {
    super(PROCESS_FEATURE, SET_MESSAGE_PROCESS);
  }
}

export class SetMessageFail extends PyStateUtils.EntityFailAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetMessageFail;

  constructor(public payload: SetMessageFailPayload) {
    super(PROCESS_FEATURE, SET_MESSAGE_PROCESS, payload.error);
  }
}

export class SetMessageSuccess extends StateUtils.EntitySuccessAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetMessageSuccess;

  constructor(public payload: SetMessageSuccessPayload) {
    super(PROCESS_FEATURE, SET_MESSAGE_PROCESS);
  }
}

export class ResetSetMessage extends StateUtils.EntityLoaderResetAction {
  readonly type = CheckoutReviewActionTypes.ResetSetMessage;

  constructor() {
    super(PROCESS_FEATURE, SET_MESSAGE_PROCESS);
  }
}

export class SetGoodsMarking extends StateUtils.EntityLoadAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetGoodsMarking;

  constructor(public payload: SetGoodsMarkingPayload) {
    super(PROCESS_FEATURE, SET_GOODS_MARKING_PROCESS);
  }
}

export class SetGoodsMarkingFail extends PyStateUtils.EntityFailAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetGoodsMarkingFail;

  constructor(public payload: SetGoodsMarkingFailPayload) {
    super(PROCESS_FEATURE, SET_GOODS_MARKING_PROCESS, payload.error);
  }
}

export class SetGoodsMarkingSuccess extends StateUtils.EntitySuccessAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetGoodsMarkingSuccess;

  constructor(public payload: SetGoodsMarkingSuccessPayload) {
    super(PROCESS_FEATURE, SET_GOODS_MARKING_PROCESS);
  }
}

export class ResetSetGoodsMarking extends StateUtils.EntityLoaderResetAction {
  readonly type = CheckoutReviewActionTypes.ResetSetGoodsMarking;

  constructor() {
    super(PROCESS_FEATURE, SET_GOODS_MARKING_PROCESS);
  }
}

export class SetCustomerReference extends StateUtils.EntityLoadAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetCustomerReference;

  constructor(public payload: SetCustomerReferencePayload) {
    super(PROCESS_FEATURE, SET_CUSTOMER_REFERENCE_PROCESS);
  }
}

export class SetCustomerReferenceFail extends PyStateUtils.EntityFailAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetCustomerReferenceFail;

  constructor(public payload: SetCustomerReferenceFailPayload) {
    super(PROCESS_FEATURE, SET_CUSTOMER_REFERENCE_PROCESS, payload.error);
  }
}

export class SetCustomerReferenceSuccess extends StateUtils.EntitySuccessAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetCustomerReferenceSuccess;

  constructor(public payload: SetCustomerReferenceSuccessPayload) {
    super(PROCESS_FEATURE, SET_CUSTOMER_REFERENCE_PROCESS);
  }
}

export class ResetSetCustomerReference extends StateUtils.EntityLoaderResetAction {
  readonly type = CheckoutReviewActionTypes.ResetSetCustomerReference;

  constructor() {
    super(PROCESS_FEATURE, SET_CUSTOMER_REFERENCE_PROCESS);
  }
}

export class SetPhoneNumber extends StateUtils.EntityLoadAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetPhoneNumber;

  constructor(public payload: SetPhoneNumberPayload) {
    super(PROCESS_FEATURE, SET_PHONE_NUMBER_PROCESS);
  }
}

export class SetPhoneNumberFail extends PyStateUtils.EntityFailAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetPhoneNumberFail;

  constructor(public payload: SetPhoneNumberFailPayload) {
    super(PROCESS_FEATURE, SET_PHONE_NUMBER_PROCESS, payload.error);
  }
}

export class SetPhoneNumberSuccess extends StateUtils.EntitySuccessAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetPhoneNumberSuccess;

  constructor(public payload: SetPhoneNumberSuccessPayload) {
    super(PROCESS_FEATURE, SET_PHONE_NUMBER_PROCESS);
  }
}

export class ResetSetPhoneNumber extends StateUtils.EntityLoaderResetAction {
  readonly type = CheckoutReviewActionTypes.ResetSetPhoneNumber;

  constructor() {
    super(PROCESS_FEATURE, SET_PHONE_NUMBER_PROCESS);
  }
}

export class SetCartOwnerDetails extends StateUtils.EntityLoadAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetCartOwnerDetails;

  constructor(public payload: SetCartOwnerDetailsPayload) {
    super(PROCESS_FEATURE, SET_CART_OWNER_DETAILS_PROCESS);
  }
}

export class SetReinvoicing extends StateUtils.EntityLoadAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetReinvoicing;

  constructor(public payload: SetReinvoicingPayload) {
    super(PROCESS_FEATURE, SET_REINVOICING_PROCESS);
  }
}

export class SetReinvoicingFail extends PyStateUtils.EntityFailAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetReinvoicingFail;

  constructor(public payload: SetReinvoicingFailPayload) {
    super(PROCESS_FEATURE, SET_REINVOICING_PROCESS, payload.error);
  }
}

export class SetReinvoicingSuccess extends StateUtils.EntitySuccessAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetReinvoicingSuccess;

  constructor(public payload: SetReinvoicingSuccessPayload) {
    super(PROCESS_FEATURE, SET_REINVOICING_PROCESS);
  }
}

export class ResetSetReinvoicing extends StateUtils.EntityLoaderResetAction {
  readonly type = CheckoutReviewActionTypes.ResetSetReinvoicing;

  constructor() {
    super(PROCESS_FEATURE, SET_REINVOICING_PROCESS);
  }
}

export class SetInvoiceNote extends StateUtils.EntityLoadAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetInvoiceNote;

  constructor(public payload: SetInvoiceNotePayload) {
    super(PROCESS_FEATURE, SET_INVOICE_NOTE_PROCESS);
  }
}

export class SetInvoiceNoteFail extends PyStateUtils.EntityFailAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetInvoiceNoteFail;

  constructor(public payload: SetInvoiceNoteFailPayload) {
    super(PROCESS_FEATURE, SET_INVOICE_NOTE_PROCESS, payload.error);
  }
}

export class SetInvoiceNoteSuccess extends StateUtils.EntitySuccessAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetInvoiceNoteSuccess;

  constructor(public payload: SetInvoiceNoteSuccessPayload) {
    super(PROCESS_FEATURE, SET_INVOICE_NOTE_PROCESS);
  }
}

export class ResetSetInvoiceNote extends StateUtils.EntityLoaderResetAction {
  readonly type = CheckoutReviewActionTypes.ResetSetInvoiceNote;

  constructor() {
    super(PROCESS_FEATURE, SET_INVOICE_NOTE_PROCESS);
  }
}

export class SetCostPlace extends StateUtils.EntityLoadAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetCostPlace;

  constructor(public payload: SetCostPlacePayload) {
    super(PROCESS_FEATURE, SET_COST_PLACE_PROCESS);
  }
}

export class SetCostPlaceFail extends PyStateUtils.EntityFailAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetCostPlaceFail;

  constructor(public payload: SetCostPlaceFailPayload) {
    super(PROCESS_FEATURE, SET_COST_PLACE_PROCESS, payload.error);
  }
}

export class SetCostPlaceSuccess extends StateUtils.EntitySuccessAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutReviewActionTypes.SetCostPlaceSuccess;

  constructor(public payload: SetCostPlaceSuccessPayload) {
    super(PROCESS_FEATURE, SET_COST_PLACE_PROCESS);
  }
}

export class ResetSetCostPlace extends StateUtils.EntityLoaderResetAction {
  readonly type = CheckoutReviewActionTypes.ResetSetCostPlace;

  constructor(public payload: PayloadWithCheckoutKey) {
    super(PROCESS_FEATURE, SET_COST_PLACE_PROCESS);
  }
}

export type CheckoutReviewActions =
  | SetOrderNote
  | SetOrderNoteFail
  | SetOrderNoteSuccess
  | ResetSetOrderNote
  | SetDeliveryNote
  | SetDeliveryNoteFail
  | SetDeliveryNoteSuccess
  | ResetSetDeliveryNote
  | SetApproverComment
  | SetApproverCommentFail
  | SetApproverCommentSuccess
  | DeleteApproverComment
  | DeleteApproverCommentFail
  | DeleteApproverCommentSuccess
  | SetBuyerComment
  | SetBuyerCommentFail
  | SetBuyerCommentSuccess
  | DeleteBuyerComment
  | DeleteBuyerCommentFail
  | DeleteBuyerCommentSuccess
  | SetMessage
  | SetMessageFail
  | SetMessageSuccess
  | ResetSetMessage
  | SetGoodsMarking
  | SetGoodsMarkingFail
  | SetGoodsMarkingSuccess
  | ResetSetGoodsMarking
  | SetCustomerReference
  | SetCustomerReferenceFail
  | SetCustomerReferenceSuccess
  | ResetSetCustomerReference
  | SetPhoneNumber
  | SetPhoneNumberFail
  | SetPhoneNumberSuccess
  | SetCartOwnerDetails
  | ResetSetPhoneNumber
  | SetReinvoicing
  | SetReinvoicingFail
  | SetReinvoicingSuccess
  | ResetSetReinvoicing
  | SetInvoiceNote
  | SetInvoiceNoteFail
  | SetInvoiceNoteSuccess
  | ResetSetInvoiceNote
  | SetCostPlace
  | SetCostPlaceFail
  | SetCostPlaceSuccess
  | ResetSetCostPlace;
