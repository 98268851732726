import { Action } from '@ngrx/store';
import { SearchParams } from '../../../model/search.model';

export enum SearchActionTypes {
  SearchAction = '[Search] Store search params',
  ResetSearchAction = '[Search] Reset search params',
}

export interface BaseSearchAction extends Action {
  searchType?: string;
}

export interface PagedSearchAction extends BaseSearchAction {
  searchParams?: SearchParams;
}

export class SearchAction implements PagedSearchAction {
  readonly type = SearchActionTypes.SearchAction;

  constructor(public searchParams: SearchParams, public searchType?: string) {}
}

export class ResetSearchAction implements BaseSearchAction {
  readonly type = SearchActionTypes.ResetSearchAction;

  constructor(public searchType?: string) {}
}

export type SearchActions = SearchAction | ResetSearchAction;
