import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';
import {
  BadRequestHandler as CxBadRequestHandler,
  ForbiddenHandler as CxForbiddenHandler,
  errorHandlers as cxErrorHandlers,
  httpErrorInterceptors as cxHttpErrorInterceptors,
} from '@spartacus/core';
import {
  BadRequestHandler,
  ForbiddenHandler,
  GoneHandler,
  HttpErrorHandler,
  ServiceUnavailableHandler,
  UnauthorizedHandler,
} from './handlers/index';
import { HttpStopRequestsInterceptor } from './http-stop-requests.interceptor';

export const errorHandlers: Provider[] = [
  ...cxErrorHandlers,
  { provide: CxBadRequestHandler, useExisting: BadRequestHandler },
  { provide: CxForbiddenHandler, useExisting: ForbiddenHandler },
  {
    provide: HttpErrorHandler,
    useExisting: GoneHandler,
    multi: true,
  },
  {
    provide: HttpErrorHandler,
    useExisting: ServiceUnavailableHandler,
    multi: true,
  },
  {
    provide: HttpErrorHandler,
    useExisting: UnauthorizedHandler,
    multi: true,
  },
];

export const httpErrorInterceptors: Provider[] = [
  {
    provide: HTTP_INTERCEPTORS,
    useExisting: HttpStopRequestsInterceptor,
    multi: true,
  },
  ...cxHttpErrorInterceptors,
];
