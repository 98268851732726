import { StateUtils } from '@spartacus/core';
import {
  EntriesState,
  SearchPageResultState,
  SearchParamsState,
  SearchState,
} from '../../../core/features/store/base-feature-state';
import { AssignedShipTo, DeliveryAddress } from '../../../core/model';
import { SoldTo } from '../../../core/model/user.model';

export const SOLD_TO_CORE_FEATURE = 'sold-to';
export const SOLD_TO_FEATURE = 'sold-to';

export const SOLD_TO = '[SoldTo] Sold-to';
export const SOLD_TOS = '[SoldTo] Sold-tos';
export const SOLD_TOS_ON_USER_DETAILS = '[SoldTo] Sold-tos on user details page';
export const DELIVERY_ADDRESS = '[SoldTo] Delivery address';
export const AVAILABLE_SHIP_TOS = '[SoldTo] Available Ship Tos';

export interface StateWithSoldTo {
  [SOLD_TO_FEATURE]: SoldToState;
}

export interface SoldToState {
  active: StateUtils.LoaderState<SoldTo>;
  searchParams: SearchParamsState;
  available: AvailableState;
  availableOnUserDetails: AvailableState;
  deliveryAddresses: DeliveryAddressState;
  availableShipTos: AvailableShipTosState;
}

export interface DeliveryAddressSearchParamsState extends SearchParamsState {
  text?: string;
}

export interface AvailableShipTosSearchParamsState extends SearchParamsState {
  text?: string;
  userUid?: string;
  onlyConnected?: boolean;
  soldTos?: string[];
}

export interface AvailableSearchParamsState extends SearchParamsState {
  text?: string;
}

export interface DeliveryAddressState {
  search: SearchState<SearchPageResultState>;
  searchParams: DeliveryAddressSearchParamsState;
  entities: EntriesState<DeliveryAddress>;
}

export interface AvailableShipTosState {
  search: SearchState<SearchPageResultState>;
  searchParams: AvailableShipTosSearchParamsState;
  entities: EntriesState<AssignedShipTo>;
}

export interface AvailableState {
  search: SearchState<SearchPageResultState>;
  searchParams: AvailableSearchParamsState;
  entities: EntriesState<SoldTo>;
}
