import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter, take } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

export interface AsyncReturnToken<_T> {
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class AsyncReturnService {
  private tokens = new Map<AsyncReturnToken<any>, BehaviorSubject<any>>();

  private resolveSubject<T>(subject$: BehaviorSubject<T>, value: T): void {
    // only resolve 'unresolved' subjects
    if (subject$.value === undefined) {
      subject$.next(value);
    }
  }

  /**
   * Return values from other contexts, such as inside a subcriber.
   * Allows side effects to happen without an active subscription on the returned observable.
   * Call the supplied resolve function to yield a value.
   * @param func Function which takes a resolve callback as argument
   * @returns Observable which yields a value when the resolve callback is called
   */
  asyncReturn<T>(func: (resolve: (value: T) => void) => void): Observable<T> {
    const subject$ = new BehaviorSubject<T>(undefined);
    func((value) => this.resolveSubject(subject$, value));
    return subject$.pipe(
      filter((value) => value !== undefined),
      take(1)
    );
  }

  /**
   * Return values from other contexts, such as inside an effect.
   * Allows side effects to happen without an active subscription on the returned observable.
   * Call `resolveToken()` with the supplied token to yield a value.
   * @param func Function which takes a serializable token as argument
   * @returns Observable which yields a value when `resolveToken()` with the supplied token is called
   */
  asyncReturnSerializable<T>(func: (token: AsyncReturnToken<T>) => void): Observable<T> {
    const subject$ = new BehaviorSubject<T>(undefined);
    const token = { id: uuidv4() };
    this.tokens.set(token, subject$);
    func(token);
    return subject$.pipe(
      filter((value) => value !== undefined),
      take(1)
    );
  }

  /**
   * Do all the side effects from an observable, and return another observable which can optionally be subscribed to.
   * Only the first value is carried over to the returned observable.
   * @param observable$ Observable to subscribe to
   * @returns Observable which returns first value from the original observable, and does not need to be subscribed to
   */
  subscribeAndReturn<T>(observable$: Observable<T>): Observable<T> {
    return this.asyncReturn((resolve) => observable$.subscribe((value) => resolve(value)));
  }

  /**
   * Resolve a token to yield a value from `asyncReturnSerializable()`.
   * @param token Token returned from `asyncReturnSerializable()`
   * @param value Value to yield from `asyncReturnSerializable()`
   */
  resolveToken<T>(token: AsyncReturnToken<T>, value: T): void {
    if (this.tokens.has(token)) {
      this.resolveSubject(this.tokens.get(token), value);
      this.tokens.delete(token);
    }
  }
}
