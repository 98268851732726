import { NgModule } from '@angular/core';
import { provideDefaultConfig } from '@spartacus/core';
import { NavigationEvent } from '@spartacus/storefront';
import { TmsConfig } from '@spartacus/tracking/tms/core';
import { UmamiCollectorService } from './umami-collector.service';

@NgModule({
  providers: [
    provideDefaultConfig(<TmsConfig>{
      tagManager: {
        umami: {
          collector: UmamiCollectorService,
          events: [NavigationEvent],
        },
      },
    }),
  ],
})
export class UmamiModule {
  constructor() {}
}
