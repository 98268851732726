import { MemoizedSelector, MemoizedSelectorWithProps, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { EntriesState, PaginationState, SearchPageResultState, SearchState } from '../../../features/store/base-feature-state';
import { Country, DeliveryAddress, LoaderError, Pagination } from '../../../model';
import { PyStateUtils } from '../../../state';
import { loaderErrorDetailsSelector } from '../../../state/utils/loader';
import { AddressSearchParamsState, DeliveryAddressState, StateWithUser, UserState } from '../user-state';
import { getUserState } from './feature.selector';

export const getDeliveryAddressState: MemoizedSelector<StateWithUser, DeliveryAddressState> = createSelector(
  getUserState,
  (state: UserState) => state && state.deliveryAddresses
);

export const getSearchResultEntities: MemoizedSelector<StateWithUser, EntriesState<DeliveryAddress> | undefined> = createSelector(
  getDeliveryAddressState,
  (state: DeliveryAddressState) => (state && state.entities) || undefined
);

export const getSearchParamsSelector: MemoizedSelector<StateWithUser, AddressSearchParamsState | undefined> = createSelector(
  getDeliveryAddressState,
  (state: DeliveryAddressState) => (state && state.searchParams) || undefined
);

export const getSearchSelector: MemoizedSelector<StateWithUser, SearchState<SearchPageResultState> | undefined> = createSelector(
  getDeliveryAddressState,
  (state: DeliveryAddressState) => (state && state.search) || undefined
);

export const getSearchPaginationStateSelector: MemoizedSelector<
  StateWithUser,
  PaginationState<SearchPageResultState> | undefined
> = createSelector(
  getSearchSelector,
  getSearchParamsSelector,
  (state: SearchState<SearchPageResultState>, searchParams: AddressSearchParamsState) => {
    return (searchParams && state && state[searchParams.key]) || undefined;
  }
);

export const getSearchPageResultsSelector: MemoizedSelector<
  StateWithUser,
  StateUtils.LoaderState<SearchPageResultState>
> = createSelector(
  getSearchParamsSelector,
  getSearchPaginationStateSelector,
  (queryParams: AddressSearchParamsState, paginationState: PaginationState<SearchPageResultState>) => {
    return (
      (queryParams && paginationState && paginationState.pages && paginationState.pages[queryParams.page]) ||
      PyStateUtils.initialLoaderState
    );
  }
);

export const getSearchPaginationSelector: MemoizedSelector<StateWithUser, Pagination | undefined> = createSelector(
  getSearchPaginationStateSelector,
  (state) => (state && state.pagination) || undefined
);

export const getSearchResultEntries: MemoizedSelectorWithProps<StateWithUser, any, DeliveryAddress[] | undefined> =
  createSelector(getSearchResultEntities, (entries: EntriesState<DeliveryAddress>, props) => {
    return (props.keys && entries && props.keys.map((i) => entries[i])) || undefined;
  });

export const getDeliveryAddressLoading: MemoizedSelector<StateWithUser, boolean> = createSelector(
  getDeliveryAddressState,
  (state: DeliveryAddressState) => state && StateUtils.loaderLoadingSelector(state.save)
);

export const getDeliveryAddressFailure: MemoizedSelector<StateWithUser, LoaderError> = createSelector(
  getDeliveryAddressState,
  (state: DeliveryAddressState) => (state ? loaderErrorDetailsSelector(state.save) : undefined)
);

export const getDeliveryAddressSuccess: MemoizedSelector<StateWithUser, boolean> = createSelector(
  getDeliveryAddressState,
  (state: DeliveryAddressState) => state && StateUtils.loaderSuccessSelector(state.save)
);

export const getDeliveryAddressDeleteSuccess: MemoizedSelector<StateWithUser, boolean> = createSelector(
  getDeliveryAddressState,
  (state: DeliveryAddressState) => state && StateUtils.loaderSuccessSelector(state.delete)
);

export const getDeliveryCountriesLoaderState: MemoizedSelector<StateWithUser, StateUtils.LoaderState<Country[]>> = createSelector(
  getDeliveryAddressState,
  (state: DeliveryAddressState) => (state && state.countries) || PyStateUtils.initialLoaderState
);

export const getDeliveryCountries: MemoizedSelector<StateWithUser, Country[] | undefined> = createSelector(
  getDeliveryCountriesLoaderState,
  (state: StateUtils.LoaderState<Country[]>) => StateUtils.loaderValueSelector(state) || undefined
);
