import { CommonModule } from '@angular/common';
import { Injectable, NgModule } from '@angular/core';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import * as hammer from 'hammerjs';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../core/i18n';
import { GenericLinkModule } from '../generic-link';
import { CarouselComponent } from './carousel.component';

@Injectable()
class CarouselHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { direction: hammer.DIRECTION_HORIZONTAL, pointers: 1 },
    pinch: { enable: false },
    rotate: { enable: false },
  };
}

@NgModule({
  imports: [CommonModule, NzIconModule, I18nModule, GenericLinkModule],
  declarations: [CarouselComponent],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: CarouselHammerConfig,
    },
  ],
  exports: [CarouselComponent],
})
export class CarouselModule {}
