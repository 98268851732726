import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ReloadService } from '../../reload';

@Injectable({ providedIn: 'root' })
export class DetectNewDeployInterceptor implements HttpInterceptor {
  private lastKnownServerBuildId: string;

  constructor(private reloadService: ReloadService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          const buildId = event.headers.get('x-opti-build-id');
          if (buildId) {
            if (this.lastKnownServerBuildId && this.lastKnownServerBuildId !== buildId) {
              this.reloadService.requestReload(buildId);
            }
            this.lastKnownServerBuildId = buildId;
          }
        }
      })
    );
  }
}
