import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ScriptLoader as CxScriptLoader } from '@spartacus/core';
@Injectable({
  providedIn: 'root',
})
export class ScriptLoader extends CxScriptLoader {
  constructor(@Inject(DOCUMENT) protected document: any, @Inject(PLATFORM_ID) protected platformId: Object) {
    super(document, platformId);
  }

  // Exposing method as public to use it outside of this file (the original method is protected)
  hasScript(src?: string): boolean {
    return super.hasScript(src);
  }
}
