import { ComponentRef, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { SiteContextConfig } from '@spartacus/core';
import { ThemeService as CxThemeService } from '@spartacus/storefront';

@Injectable({ providedIn: 'root' })
export class ThemeService extends CxThemeService {
  protected rootComponent: ComponentRef<any>;
  protected renderer: Renderer2;
  protected existingTheme: string;

  constructor(protected config: SiteContextConfig, protected rendererFactory: RendererFactory2) {
    super(config, rendererFactory);
  }

  getTheme(): string {
    return this.existingTheme;
  }
}
