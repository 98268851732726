import { Inject, Injectable } from '@angular/core';
import { Config, ConfigInitializer, ConfigInitializerService, OccConfig } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { BaseSite } from '../../../model/misc.model';
import { BaseSiteService } from '../../../site-context/facade/base-site.service';

@Injectable({ providedIn: 'root' })
export class OccConfigInitializer implements ConfigInitializer {
  readonly scopes = ['backend'];
  readonly configFactory = () => this.resolveConfig().toPromise();

  constructor(
    @Inject(Config) protected config: OccConfig,
    protected baseSiteService: BaseSiteService,
    protected configInit: ConfigInitializerService
  ) {}

  /**
   * Emits the site context config basing on the current base site data.
   *
   * Completes after emitting the value.
   */
  protected resolveConfig(): Observable<OccConfig> {
    return this.baseSiteService.get().pipe(
      map((baseSite) => this.getConfig(baseSite)),
      take(1)
    );
  }

  protected getConfig(source: BaseSite): OccConfig {
    const backendConfig = this.config?.backend;
    return {
      backend: {
        ...backendConfig,
        media: {
          ...backendConfig.media,
          baseUrl: source.cdnBaseUrl,
        },
      },
    };
  }
}
