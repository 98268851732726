import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { Article, CartType, ConsignmentArticleStock, LoaderError, NewFreeStock } from '../../model';
import { QuantityAndUnitValue } from '../services/assortment.service';
import { USER_BASE_FEATURE } from '../store/user-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: AssortmentFacade,
      feature: USER_BASE_FEATURE,
      async: true,
      methods: [
        'requestNewFreeArticles',
        'getConsignmentStockArticlesLoading',
        'getConsignmentStockArticlesLoaded',
        'getConsignmentStockArticlesError',
        'getConsignmentStockArticlesRefs',
        'loadConsignmentStockArticles',
        'resetConsignmentStockArticles',
        'resetConsignmentStockArticlesStockInfo',
        'getConsignmentStockArticleStockInfo',
        'loadConsignmentStockArticleStockInfo',
        'getConsignmentStockArticleStockInfoLoading',
        'getConsignmentStockArticleStockInfoSuccess',
        'getConsignmentStockArticleStockInfoError',
        'addAssortmentEntryToCart',
      ],
    }),
})
export abstract class AssortmentFacade {
  abstract requestNewFreeArticles(requestedNewFreeArticles: NewFreeStock[]): Observable<Blob>;
  abstract getConsignmentStockArticlesLoading(): Observable<boolean>;
  abstract getConsignmentStockArticlesLoaded(): Observable<boolean>;
  abstract getConsignmentStockArticlesError(): Observable<LoaderError | undefined>;
  abstract getConsignmentStockArticlesRefs(): Observable<string[]>;
  abstract loadConsignmentStockArticles(): void;
  abstract resetConsignmentStockArticles(): void;
  abstract resetConsignmentStockArticlesStockInfo(): void;
  abstract getConsignmentStockArticleStockInfo(articleNumber: string): Observable<ConsignmentArticleStock>;
  abstract loadConsignmentStockArticleStockInfo(articleNumber: string): void;
  abstract getConsignmentStockArticleStockInfoLoading(articleRef: string): Observable<boolean>;
  abstract getConsignmentStockArticleStockInfoSuccess(articleRef: string): Observable<boolean>;
  abstract getConsignmentStockArticleStockInfoError(articleRef: string): Observable<LoaderError>;
  abstract addAssortmentEntryToCart(article: Article, quantityAndUnitValue: QuantityAndUnitValue, cartType: CartType): void;
}
