import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ArticleService } from '../../../core/catalog';
import {
  Article,
  ArticleRef,
  BasicOrderLine,
  CartType,
  DetailedOrderLine,
  Price,
  SimulatedOrder,
  Unit,
} from '../../../core/model';
import { PrincipalConfigurationService } from '../../../core/user';
import { getTextToDisplayInsteadOfPrice } from '../../utils/price-utility';
import { ORDER_ENTRY_CONFIG, OrderEntryConfig } from '../order-entry/order-entry.config';

export interface OrderLinesTableEntry {
  articleRef: ArticleRef;
  quantity: number;
  unit: Unit;
  netPrice: Price;
}

interface TableData {
  orderLine: DetailedOrderLine;
  article$: Observable<Article>;
}

@Component({
  selector: 'py-order-lines-table',
  templateUrl: './order-lines-table.component.html',
  styleUrls: ['./order-lines-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ORDER_ENTRY_CONFIG,
      useValue: <OrderEntryConfig>{
        deletable: false,
        enableNoteControl: false,
        enableFullPalletNoteControl: false,
        enableQuantityControl: false,
        enableStockInfo: false,
        enableCutting: true,
        showCuttingButton: false,
        showCuttingPerpendicular: false,
        showCuttingDustFree: false,
        enableReelCutting: true,
        enableReaming: true,
        showReamingButton: false,
        translationKeyPrefix: 'orderHistory',
        showReelCuttingButton: false,
        selectable: false,
        showPalletFlags: false,
        enableFullPalletUpgrade: false,
        showWarehouseName: true,
        showOrderReturnEntryBadge: false,
        enablePrePrintedLabels: true,
        showPrePrintedLabelsButton: false,
      },
    },
  ],
})
export class OrderLinesTableComponent implements OnInit {
  // Style class name to apply alternative styles for confirmation page
  readonly SECONDARY_STYLING_CLASS_NAME = 'secondary-styling';

  @Input() set entries(entries: OrderLinesTableEntry[] | BasicOrderLine[]) {
    this._entries = entries;
    this.setupTableData();
  }
  @Input() deliveryDate?: string | Date;
  @Input() loading = false;
  @Input() order?: SimulatedOrder;
  @Input() showPrice: boolean = true;
  @Input() additionalStyleClass?: string;
  @Input() cartType: CartType;

  tableData: TableData[];
  enableSecondaryArticleRowVariant$: Observable<boolean>;

  private _entries: OrderLinesTableEntry[] | BasicOrderLine[];

  get entries(): OrderLinesTableEntry[] | BasicOrderLine[] | undefined {
    return this._entries;
  }

  get textInsteadOfPrice(): string | undefined {
    return getTextToDisplayInsteadOfPrice(this.cartType);
  }

  constructor(private articleService: ArticleService, private principalConfigurationService: PrincipalConfigurationService) {}

  ngOnInit(): void {
    this.enableSecondaryArticleRowVariant$ = this.principalConfigurationService
      .isEnabled('enableSecondaryArticleRowVariant')
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /* For type coercion in template */
  asTableData = (entry: any): TableData => entry;

  private setupTableData(): void {
    this.tableData = this.entries?.map((orderLine) => {
      const article$ = this.articleService.getArticle(orderLine.articleRef);
      return {
        orderLine,
        article$,
      };
    });
  }
}
