import { Action } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { PagedSearchAction } from '../../../features/store/actions/search.actions';
import { AddressSearchParams, DeliveryAddress, HttpErrorModel, SearchParams } from '../../../model';
import { PyStateUtils } from '../../../state/utils';
import { DELIVERY_ADDRESS, DELIVERY_ADDRESS_DELETE, DELIVERY_ADDRESS_SAVE } from '../user-state';

export enum DeliveryAddressActionTypes {
  SaveDeliveryAddress = '[User] Save Delivery Address',
  SaveDeliveryAddressSuccess = '[User] Save Delivery Address Success',
  SaveDeliveryAddressFail = '[User] Save Delivery Address Fail',
  SaveDeliveryAddressReset = '[User] Save Delivery Address Reset',

  DeleteDeliveryAddress = '[User] Delete Delivery Address',
  DeleteDeliveryAddressSuccess = '[User] Delete Delivery Address Success',
  DeleteDeliveryAddressFail = '[User] Delete Delivery Address Fail',
  DeleteDeliveryAddressReset = '[User] Delete Delivery Address Reset',

  LoadDeliveryAddresses = '[User] Load Delivery Addresses',
  LoadDeliveryAddressesSuccess = '[User] Load Delivery Addresses Success',
  LoadDeliveryAddressesFail = '[User] Load Delivery Addresses Fail',

  UpdateTempDeliveryAddress = '[User] Update Temporary Delivery Address',
  RemoveDeliveryAddress = '[User] Remove Delivery Address',
}

export class SaveDeliveryAddress extends StateUtils.LoaderLoadAction {
  readonly type = DeliveryAddressActionTypes.SaveDeliveryAddress;

  constructor(
    public address: DeliveryAddress,
    public userId: string,
    public searchParams: SearchParams,
    public tempCode?: string
  ) {
    super(DELIVERY_ADDRESS_SAVE);
  }
}

export class SaveDeliveryAddressSuccess extends StateUtils.LoaderSuccessAction implements PagedSearchAction {
  readonly type = DeliveryAddressActionTypes.SaveDeliveryAddressSuccess;
  readonly searchType = DELIVERY_ADDRESS;

  constructor(public payload: DeliveryAddress, public searchParams: SearchParams, public isNew?: boolean) {
    super(DELIVERY_ADDRESS_SAVE);
  }
}

export class SaveDeliveryAddressFail extends PyStateUtils.LoaderFailAction {
  readonly type = DeliveryAddressActionTypes.SaveDeliveryAddressFail;

  constructor(public error?: HttpErrorModel) {
    super(DELIVERY_ADDRESS_SAVE, error);
  }
}

export class SaveDeliveryAddressReset extends StateUtils.LoaderResetAction {
  readonly type = DeliveryAddressActionTypes.SaveDeliveryAddressReset;

  constructor() {
    super(DELIVERY_ADDRESS_SAVE);
  }
}

export class DeleteDeliveryAddress extends StateUtils.LoaderLoadAction {
  readonly type = DeliveryAddressActionTypes.DeleteDeliveryAddress;

  constructor(public id: string, public userId: string, public searchParams: SearchParams) {
    super(DELIVERY_ADDRESS_DELETE);
  }
}

export class DeleteDeliveryAddressSuccess extends StateUtils.LoaderSuccessAction implements PagedSearchAction {
  readonly type = DeliveryAddressActionTypes.DeleteDeliveryAddressSuccess;
  readonly searchType = DELIVERY_ADDRESS;

  constructor(
    public payload: string, // Removed address id
    public searchParams: SearchParams
  ) {
    super(DELIVERY_ADDRESS_DELETE);
  }
}

export class DeleteDeliveryAddressFail extends PyStateUtils.LoaderFailAction {
  readonly type = DeliveryAddressActionTypes.DeleteDeliveryAddressFail;

  constructor(public error?: HttpErrorModel) {
    super(DELIVERY_ADDRESS_DELETE, error);
  }
}

export class DeleteDeliveryAddressReset extends StateUtils.LoaderResetAction {
  readonly type = DeliveryAddressActionTypes.DeleteDeliveryAddressReset;

  constructor() {
    super(DELIVERY_ADDRESS_DELETE);
  }
}

export class LoadDeliveryAddresses extends StateUtils.LoaderLoadAction implements PagedSearchAction {
  readonly type = DeliveryAddressActionTypes.LoadDeliveryAddresses;
  readonly searchType = DELIVERY_ADDRESS;

  constructor(public searchParams: AddressSearchParams, public userId: string) {
    super(DELIVERY_ADDRESS);
  }
}

export class LoadDeliveryAddressesFail extends PyStateUtils.LoaderFailAction implements PagedSearchAction {
  readonly type = DeliveryAddressActionTypes.LoadDeliveryAddressesFail;
  readonly searchType = DELIVERY_ADDRESS;

  constructor(public searchParams: AddressSearchParams, public payload?: any) {
    super(DELIVERY_ADDRESS, payload);
  }
}

export class LoadDeliveryAddressesSuccess extends StateUtils.LoaderSuccessAction implements PagedSearchAction {
  readonly type = DeliveryAddressActionTypes.LoadDeliveryAddressesSuccess;
  readonly searchType = DELIVERY_ADDRESS;

  constructor(public payload: any, public searchParams: AddressSearchParams) {
    super(DELIVERY_ADDRESS);
  }
}

export class UpdateTempDeliveryAddress implements Action {
  readonly type = DeliveryAddressActionTypes.UpdateTempDeliveryAddress;

  constructor(public payload: DeliveryAddress, public tempCode: string) {}
}

/**
 * Used for cleaning delivery address in local state.
 */
export class RemoveDeliveryAddress implements Action {
  readonly type = DeliveryAddressActionTypes.RemoveDeliveryAddress;

  constructor(public code: string) {}
}

export type DeliveryAddressActions =
  | DeleteDeliveryAddress
  | DeleteDeliveryAddressSuccess
  | DeleteDeliveryAddressFail
  | SaveDeliveryAddress
  | SaveDeliveryAddressSuccess
  | SaveDeliveryAddressFail
  | SaveDeliveryAddressReset
  | UpdateTempDeliveryAddress
  | RemoveDeliveryAddress;

export type DeliveryAddressesActions = LoadDeliveryAddresses | LoadDeliveryAddressesSuccess | LoadDeliveryAddressesFail;
