import { Component, Input, OnInit } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { PriceCampaignService } from '../../../../core/catalog';
import { EntityMap, SapPriceCampaignList } from '../../../../core/model';
import { prepareUrlForLink } from '../../../../core/util';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ul[py-campaigns-sub-navigation]',
  templateUrl: './campaigns-sub-navigation.component.html',
})
export class CampaignsSubNavigationComponent implements OnInit {
  campaignList$: Observable<SapPriceCampaignList>;
  mya$ = new ReplaySubject<boolean>();

  @Input() set mya(mya: boolean) {
    this.mya$.next(mya);
  }

  constructor(private priceCampaignService: PriceCampaignService) {}

  ngOnInit(): void {
    this.campaignList$ = this.priceCampaignService.getAvailablePriceCampaigns();
  }

  getUrl(url: string, queryParams?: EntityMap<string>): string {
    return prepareUrlForLink(url, queryParams);
  }
}
