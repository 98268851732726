import { StateUtils } from '@spartacus/core';
import { PriceFileEntry, PriceFileFormat, PriceFileRequestTemplate } from '../../../model/price-file.model';
import {
  ArrayState,
  EntriesState,
  SearchPageResultState,
  SearchParamsState,
  SearchState,
  UserActionState,
  VirtualPaginationState,
} from '../../store/base-feature-state';

export const PRICE_FILE_FEATURE = 'price-file';

export const PRICE_FILE = '[PriceFile] Price file';
export const PRICE_FILE_CANCEL = '[PriceFile] Price file cancellation';
export const PRICE_FILE_DELETE = '[PriceFile] Price file deletion';
export const PRICE_FILE_REQUEST = '[PriceFile] Price file request';
export const PRICE_FILE_REQUEST_TEMPLATE = '[PriceFile] Price file request template';
export const PRICE_FILE_FORMAT = '[PriceFile] Price file format';
export const PRICE_FILE_STATUS = '[PriceFile] Price file status';

export interface StateWithPriceFile {
  [PRICE_FILE_FEATURE]: PriceFileState;
}

export interface PriceFileState {
  priceFiles: PriceFilesState;
  templates: PriceFileRequestTemplateState;
  formats: StateUtils.LoaderState<ArrayState<PriceFileFormat>>;
  soldTos: SearchState<SearchPageResultState>;
}

export interface PriceFilesState {
  search: SearchState<SearchPageResultState>;
  searchParams: SearchParamsState;
  entities: EntriesState<PriceFileEntry>;
  request: StateUtils.LoaderState<PriceFileEntry>;
  cancel: UserActionState;
  delete: UserActionState;
}

export interface PriceFileRequestTemplateState {
  searchParams: SearchParamsState;
  entities: StateUtils.LoaderState<VirtualPaginationState<PriceFileRequestTemplate>>;
  template: StateUtils.LoaderState<PriceFileRequestTemplate>;
}
