import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig, provideDefaultConfig } from '@spartacus/core';
import { defaultAlertsConfig } from './base/config/default-alerts-config';

@NgModule({
  imports: [],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        operationalMessage: {
          module: () =>
            import('./container/operational-message/operational-message.module').then((m) => m.OperationalMessageModule),
          cmsComponents: ['OperationalMessageComponent'],
          dependencies: [() => import('./base/store/alerts-store.module').then((m) => m.AlertsStoreModule)],
        },
        salesOrgAlertMessage: {
          module: () =>
            import('./container/sales-org-alert-message/sales-org-alert-message.module').then(
              (m) => m.SalesOrgAlertMessageModule
            ),
          cmsComponents: ['SalesOrgAlertMessageComponent'],
          dependencies: [() => import('./base/store/alerts-store.module').then((m) => m.AlertsStoreModule)],
        },
        serviceUnavailableAlert: {
          module: () =>
            import('./container/service-unavailable-alert/service-unavailable-alert.module').then(
              (m) => m.ServiceUnavailableAlertModule
            ),
          cmsComponents: ['ServiceUnavailableAlertComponent'],
        },
      },
    }),
    provideDefaultConfig(defaultAlertsConfig),
  ],
})
export class AlertsFeatureModule {}
