import { ConsentsConfig } from './consents-config';

export const defaultConsentsConfig: ConsentsConfig = {
  consents: {
    anonymous: {
      requiredConsents: ['STRICTLY_NECESSARY'],
      consents: ['ANONYMOUS_PERSONALIZATION', 'ANONYMOUS_TRACKING', 'STRICTLY_NECESSARY', 'ANONYMOUS_MARKETING_THIRD_PARTY'],
      hiddenConsents: [],
      trackingConsent: 'ANONYMOUS_TRACKING',
      personalizationConsent: 'ANONYMOUS_PERSONALIZATION',
      marketingThirdPartyConsent: 'ANONYMOUS_MARKETING_THIRD_PARTY',
    },
    user: {
      requiredConsents: ['TERMS_CONDITIONS', 'TERMS_DELIVERY', 'STRICTLY_NECESSARY'],
      consents: [
        'PERSONALIZATION',
        'TRACKING',
        'MARKETING',
        'TERMS_CONDITIONS',
        'TERMS_DELIVERY',
        'STRICTLY_NECESSARY',
        'MARKETING_THIRD_PARTY',
      ],
      hiddenConsents: ['TERMS_CONDITIONS', 'TERMS_DELIVERY', 'STRICTLY_NECESSARY'],
      hiddenLoginConsents: ['TERMS_CONDITIONS', 'TERMS_DELIVERY', 'MARKETING'],
      trackingConsent: 'TRACKING',
      personalizationConsent: 'PERSONALIZATION',
      transferredConsents: ['STRICTLY_NECESSARY'],
      marketingThirdPartyConsent: 'MARKETING_THIRD_PARTY',
    },
  },
};
