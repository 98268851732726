import { Action } from '@ngrx/store';
import { ArrayState } from '../../../store/base-feature-state';
import { LoadPriceFileFormatsSuccess, PriceFileFormatActionTypes } from '../actions/price-file-format.actions';

export const initialState: ArrayState<any> = {
  lastUpdateTime: undefined,
  entities: undefined,
};

export function reducer(state = initialState, action: Action): ArrayState<any> {
  switch (action.type) {
    case PriceFileFormatActionTypes.LoadPriceFileFormatsSuccess: {
      const successAction = <LoadPriceFileFormatsSuccess>action;
      return {
        lastUpdateTime: new Date().toISOString(),
        entities: successAction.payload,
      };
    }
    default: {
      return state;
    }
  }
}
