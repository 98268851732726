import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GenericLinkModule } from '../../../../shared';
import { HeaderNavigationItemComponent } from './header-navigation-item.component';

@NgModule({
  imports: [CommonModule, GenericLinkModule],
  declarations: [HeaderNavigationItemComponent],
  exports: [HeaderNavigationItemComponent],
})
export class HeaderNavigationItemModule {}
