import { StateUtils } from '@spartacus/core';
import { AlternativeArticles, Article, Substitutes } from '../../../../model';
import { PyStateUtils } from '../../../../state';
import { ARTICLE, SIMILAR_ARTICLES, SUBSTITUTES_ARTICLES } from '../../../store/catalog-state';

export enum ArticleActionTypes {
  LoadArticle = '[Catalog] Load Article',
  LoadArticleSuccess = '[Catalog] Load Article Success',
  LoadArticleFail = '[Catalog] Load Article Fail',
  LoadArticleReset = '[Catalog] Load Article Reset',
  LoadSimilarArticles = '[Catalog] Load Similar Articles',
  LoadSimilarArticlesSuccess = '[Catalog] Load Similar Articles Success',
  LoadSimilarArticlesFail = '[Catalog] Load Similar Articles Fail',
  LoadSubstitutesArticles = '[Catalog] Load Substitutes Articles',
  LoadSubstitutesArticlesSuccess = '[Catalog] Load Substitutes Articles Success',
  LoadSubstitutesArticlesFail = '[Catalog] Load Substitutes Articles Fail',
}

export class LoadArticle extends StateUtils.EntityLoadAction {
  readonly type = ArticleActionTypes.LoadArticle;

  constructor(public payload: string, public userId: string) {
    super(ARTICLE, payload);
  }
}

export class LoadArticleSuccess extends StateUtils.EntitySuccessAction {
  readonly type = ArticleActionTypes.LoadArticleSuccess;

  constructor(public payload: Article) {
    super(ARTICLE, payload.code, payload);
  }
}

export class LoadArticleFail extends PyStateUtils.EntityFailAction {
  readonly type = ArticleActionTypes.LoadArticleFail;

  constructor(public code: string, public payload: any) {
    super(ARTICLE, code, payload);
  }
}

export class LoadArticleReset extends StateUtils.EntityLoaderResetAction {
  readonly type = ArticleActionTypes.LoadArticleReset;

  constructor(public code: string) {
    super(ARTICLE, code);
  }
}

export class LoadSimilarArticles extends StateUtils.EntityLoadAction {
  readonly type = ArticleActionTypes.LoadSimilarArticles;

  constructor(public code: string, public userId: string) {
    super(SIMILAR_ARTICLES, code);
  }
}

export class LoadSimilarArticlesSuccess extends StateUtils.EntitySuccessAction {
  readonly type = ArticleActionTypes.LoadSimilarArticlesSuccess;

  constructor(public payload: AlternativeArticles) {
    super(SIMILAR_ARTICLES, payload.alternativesTo, payload);
  }
}

export class LoadSimilarArticlesFail extends PyStateUtils.EntityFailAction {
  readonly type = ArticleActionTypes.LoadSimilarArticlesFail;

  constructor(public code: string, public payload: any) {
    super(SIMILAR_ARTICLES, code, payload);
  }
}

export class LoadSubstitutesArticles extends StateUtils.EntityLoadAction {
  readonly type = ArticleActionTypes.LoadSubstitutesArticles;

  constructor(public code: string, public userId: string) {
    super(SUBSTITUTES_ARTICLES, code);
  }
}

export class LoadSubstitutesArticlesSuccess extends StateUtils.EntitySuccessAction {
  readonly type = ArticleActionTypes.LoadSubstitutesArticlesSuccess;

  constructor(public payload: Substitutes) {
    super(SUBSTITUTES_ARTICLES, payload.substituteTo, payload);
  }
}

export class LoadSubstitutesArticlesFail extends PyStateUtils.EntityFailAction {
  readonly type = ArticleActionTypes.LoadSubstitutesArticlesFail;

  constructor(public code: string, public payload: any) {
    super(SUBSTITUTES_ARTICLES, code, payload);
  }
}

export type ArticleActions =
  | LoadArticle
  | LoadArticleSuccess
  | LoadArticleFail
  | LoadArticleReset
  | LoadSimilarArticles
  | LoadSimilarArticlesSuccess
  | LoadSimilarArticlesFail
  | LoadSubstitutesArticles
  | LoadSubstitutesArticlesSuccess
  | LoadSubstitutesArticlesFail;
