import { v4 as uuidv4 } from 'uuid';
import {
  Cart,
  CuttingRequest,
  CuttingTemplateRequest,
  ErrorModel,
  OrderEntry,
  ReelCuttingRequest,
  SimulatedCart,
  Unit,
} from '../../core/model';
import { stringToHash } from '../../core/util/string-utils';

/**
 * What is a temporary cart?
 * - frontend only cart entity!
 * - can be identified in store by `temp-` prefix with some unique id (multiple carts can be created at the same time eg. active cart, wishlist)
 *
 * Why we need temporary carts?
 * - to have information about cart creation process (meta flags: loading, error - for showing loader, error message)
 * - to know if there is currently a cart creation process in progress (eg. so, we don't create more than one active cart at the same time)
 * - cart identifiers are created in the backend, so those are only known after cart is created
 *
 * Temporary cart life cycle
 * - create cart method invoked
 * - new `temp-${uuid}` cart is created with `loading=true` state
 * - backend returns created cart
 * - normal cart entity is saved under correct id (eg. for logged user under cart `code` key)
 * - temporary cart value is set to backend response (anyone observing this cart can read code/guid from it and switch selector to normal cart)
 * - in next tick temporary cart is removed
 */
export function isTempCartId(cartId: string): boolean {
  return (cartId && cartId.startsWith('temp-')) || false;
}

/**
 * Check if the returned error is of type notFound.
 */
export function isCartNotFoundError(error: ErrorModel): boolean {
  return error.reason === 'notFound' && error.subjectType === 'cart';
}

type NonOptionalKeys<T> = { [K in keyof T]-?: T extends { [K1 in K]: any } ? K : never }[keyof T];

/**
 * Check if cart is of type SimulatedCart
 */
export function isSimulatedCart(cart?: Cart | SimulatedCart): cart is SimulatedCart {
  const keys: NonOptionalKeys<SimulatedCart>[] = [
    'messages',
    'conditions',
    'deliveryOrderGroups',
    'orderMergeProposals',
    'shippingOptions',
  ];
  return cart ? Object.keys(cart).some((key) => (keys as string[]).includes(key)) : false;
}

export function makeCuttingKey(payload: { articleCode: string; quantity: number; unit: Unit; cutting: CuttingRequest }): string {
  return stringToHash(`${payload.articleCode}-${payload.quantity}-${payload.unit.code}-${JSON.stringify(payload.cutting)}`);
}

export function makeCuttingTemplateKey(payload: { articleCode: string; cuttingTemplate: CuttingTemplateRequest }): string {
  return stringToHash(`${payload.articleCode}-${JSON.stringify(payload.cuttingTemplate)}`);
}

export function makeReelCuttingKey(payload: {
  articleCode: string;
  quantity: number;
  unit: Unit;
  reelCutting: ReelCuttingRequest;
}): string {
  return stringToHash(`${payload.articleCode}-${payload.quantity}-${payload.unit.code}-${JSON.stringify(payload.reelCutting)}`);
}

/**
 * Returns true if year in the provided date string is "9999", which means that the date is invalid and items with such delivery date are undeliverable.
 */
export function isInvalidDeliveryDate(date: string): boolean {
  return date.startsWith('9999');
}

/**
 * Enrich cart entries with unique ids
 *
 * @param cart The new cart object
 * @param state The old cart object, if available
 * @returns A cart object where the entries has generated unique ids
 */
export function cartWithOrderEntryIds<T extends Cart | SimulatedCart>(cart: T, state?: Cart): T {
  const orderNumberIdMap = new Map<number, string>();

  if (state?.code === cart.code) {
    // Map old entry ids to the "closest" entries with matching article number
    [...(state?.entries ?? [])].forEach((oldEntry) => {
      const closestEntry = cart.entries
        .filter((entry) => entry.id === undefined)
        .filter((entry) => !orderNumberIdMap.has(entry.entryNumber))
        .reduce(
          (closestEntry, entry) => {
            if (entry.articleRef === oldEntry.articleRef) {
              const distance = Math.abs(entry.entryNumber - oldEntry.entryNumber);
              if (
                closestEntry.distance === undefined ||
                distance < closestEntry.distance ||
                (distance === closestEntry.distance && entry.entryNumber < closestEntry.entryNumber)
              ) {
                return { entryNumber: entry.entryNumber, distance };
              }
            }
            return closestEntry;
          },
          { entryNumber: undefined, distance: undefined } as { entryNumber: number; distance: number }
        );
      if (closestEntry.entryNumber !== undefined) {
        orderNumberIdMap.set(closestEntry.entryNumber, oldEntry.id);
      }
    });
  }

  const entries = cart.entries?.map((entry: OrderEntry) => {
    const id = entry.id ?? (orderNumberIdMap.has(entry.entryNumber) ? orderNumberIdMap.get(entry.entryNumber) : uuidv4());
    return { ...entry, id };
  });

  // map entry ids for delivery order groups
  const deliveryOrderGroups =
    'deliveryOrderGroups' in cart
      ? cart.deliveryOrderGroups?.map((deliveryOrderGroup) => ({
          ...deliveryOrderGroup,
          entries: deliveryOrderGroup.entries.map((deliveryOrderGroupEntry) => ({
            ...deliveryOrderGroupEntry,
            id: entries.find((cartEntry) => deliveryOrderGroupEntry.entryNumber === cartEntry.entryNumber)?.id,
          })),
        }))
      : undefined;

  // map entry ids for non-deliverable entries
  const nonDeliverableEntries =
    'nonDeliverableEntries' in cart
      ? cart.nonDeliverableEntries?.map((nonDeliverableEntry) => ({
          ...nonDeliverableEntry,
          id: entries.find((cartEntry) => nonDeliverableEntry.entryNumber === cartEntry.entryNumber)?.id,
        }))
      : undefined;

  return {
    ...cart,
    entries,
    ...(deliveryOrderGroups ? { deliveryOrderGroups } : {}),
    ...(nonDeliverableEntries ? { nonDeliverableEntries } : {}),
  };
}

/**
 *  Match by id if available in both entries, otherwise match by entry number
 */
export function matchEntry(a: OrderEntry, b: OrderEntry): boolean {
  return !a || !b ? false : !!a.id && !!b.id ? a.id === b.id : a.entryNumber === b.entryNumber;
}
