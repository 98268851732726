import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AsyncSubject, Subject } from 'rxjs';
import { concatMapTo, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { GlobalMessageService, GlobalMessageType } from '../../../core/global-message';
import { PrincipalConfiguration } from '../../../core/model';
import { PrincipalConfigurationService } from '../../../core/user';
import { shallowEqualArrays } from '../../../core/util';

@Directive({
  selector: '[pyPrincipalConfiguration]',
})
export class PrincipalConfigurationDirective implements OnDestroy, OnInit {
  private unsubscribe = new Subject<boolean>();
  private init = new AsyncSubject<void>();
  private elseTemplateRef?: TemplateRef<any>;

  constructor(
    private _viewContainer: ViewContainerRef,
    private _templateRef: TemplateRef<any>,
    private _cd: ChangeDetectorRef,
    private _globalMessageService: GlobalMessageService,
    private _principalConfigurationService: PrincipalConfigurationService
  ) {}

  @Input() set pyPrincipalConfiguration(config: string) {
    const configKeys = config?.split(',');

    if (!configKeys || !configKeys?.length) {
      return;
    }

    if (configKeys.length === 1) {
      this.checkSingleConfiguration(configKeys[0]);
    } else {
      this.checkMultipleConfigurations(configKeys);
    }
  }

  private checkSingleConfiguration(configKey: string): void {
    this.init
      .pipe(
        concatMapTo(this._principalConfigurationService.getValue(configKey)),
        distinctUntilChanged((a, b) => a?.code === b?.code && a?.value === b?.value),
        takeUntil(this.unsubscribe)
      )
      .subscribe(
        (c) => {
          this.setContainerView(c?.value ? this._templateRef : this.elseTemplateRef, c);
        },
        () => {
          this._globalMessageService.add({ key: 'errors.unexpectedError_message' }, GlobalMessageType.MSG_TYPE_ERROR, 3000);
        }
      );
  }

  private checkMultipleConfigurations(configKeys: string[]): void {
    this.init
      .pipe(
        concatMapTo(this._principalConfigurationService.getValues()),
        map((allPrincipalConfigurations) => {
          if (!allPrincipalConfigurations) {
            return undefined;
          }
          return configKeys.map((config) => allPrincipalConfigurations[config]);
        }),
        distinctUntilChanged(shallowEqualArrays),
        takeUntil(this.unsubscribe)
      )
      .subscribe(
        (principalConfigurations) => {
          const canView = principalConfigurations?.every((config) => config?.value);
          this.setContainerView(canView ? this._templateRef : this.elseTemplateRef, principalConfigurations);
        },
        () => {
          this._globalMessageService.add({ key: 'errors.unexpectedError_message' }, GlobalMessageType.MSG_TYPE_ERROR, 3000);
        }
      );
  }

  @Input() set pyPrincipalConfigurationElse(ref: TemplateRef<any>) {
    this.elseTemplateRef = ref;
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
  }

  ngOnInit() {
    this.init.next();
    this.init.complete();
  }

  private setContainerView(template?: TemplateRef<any>, value?: PrincipalConfiguration | PrincipalConfiguration[]) {
    this._viewContainer.clear();
    if (template !== undefined) {
      this._viewContainer.createEmbeddedView(template, { $implicit: value });
    }
    this._cd.markForCheck();
  }
}
