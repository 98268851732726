import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CustomerUploadedMedia, OrderReturn, OrderReturnEntry, ReturnEntryForm, ReturnReason } from '../../../core/model';
import { ORDER_CORE_FEATURE } from '../store/order-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: OrderReturnFacade,
      feature: ORDER_CORE_FEATURE,
      async: true,
      methods: [
        'getOrderReturn',
        'startOrderReturnDraft',
        'cancelOrderReturnDraft',
        'getOrderReturnDraft',
        'isOrderReturnDraftActiveForOrder',
        'updateOrderReturnDraftEntry',
        'removeOrderReturnDraftEntry',
        'getReturnReasons',
        'submitOrderReturnDraft',
        'getOrderReturnDraftEntries',
        'loadOrderReturn',
        'resetOrderReturn',
        'uploadFileForOrderReturnDraftEntry',
        'removeUploadedFiles',
        'getOrderReturnDraftUploadedFilesForItemNumber',
      ],
    }),
})
export abstract class OrderReturnFacade {
  abstract getOrderReturn(): Observable<OrderReturn>;
  abstract startOrderReturnDraft(sapOrderNumber: string): void;
  abstract loadOrderReturnDraft(sapOrderNumber: string): void;
  abstract cancelOrderReturnDraft(sapOrderNumber: string): void;
  abstract getOrderReturnDraft(): Observable<OrderReturn>;
  abstract isOrderReturnDraftActiveForOrder(sapOrderNumber: string): Observable<boolean>;
  abstract updateOrderReturnDraftEntry(itemNumber: number, returnEntryForm: ReturnEntryForm): void;
  abstract removeOrderReturnDraftEntry(itemNumber: number): void;
  abstract getReturnReasons(): Observable<ReturnReason[]>;
  abstract submitOrderReturnDraft(sapOrderNumber: string): void;
  abstract getOrderReturnDraftEntries(): Observable<OrderReturnEntry[]>;
  abstract loadOrderReturn(sapOrderNumber: string): void;
  abstract resetOrderReturn(): void;
  abstract uploadFileForOrderReturnDraftEntry(
    sapOrderNumber: string,
    itemNumber: number,
    file: File
  ): Observable<CustomerUploadedMedia>;
  abstract removeUploadedFiles(sapOrderNumber: string, itemNumber: number, qualifier?: string): void;
  abstract getOrderReturnDraftUploadedFilesForItemNumber(itemNumber: number): Observable<CustomerUploadedMedia[]>;
}
