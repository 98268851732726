import { StateUtils } from '@spartacus/core';
import { PrincipalSharedShoppingListFlagsPage } from '../../../model';
import { UserActions } from '../actions';
import { ShoppingListActionTypes } from '../actions/shopping-list.action';

export const initialState: any = {
  lastUpdateTime: undefined,
  results: [],
};

export function reducer<T>(state: T = initialState, action: UserActions.ShoppingListActions | StateUtils.LoaderAction): T {
  switch (action.type) {
    case ShoppingListActionTypes.LoadShoppingListPrincipalsSuccess: {
      const _action = <UserActions.LoadShoppingListPrincipalsSuccess>action;
      const payload: PrincipalSharedShoppingListFlagsPage = _action.payload;
      const results = payload.results.map((r) => r.principal?.uid);

      return {
        ...state,
        lastUpdateTime: new Date().toISOString(),
        results,
      };
    }
    case ShoppingListActionTypes.LoadShoppingListPrincipalsFail: {
      return {
        ...state,
        lastUpdateTime: new Date().toISOString(),
      };
    }
  }

  return state;
}
