import { SearchAction, SearchActionTypes } from '../../../features/store/actions/search.actions';
import { SHIP_TOS, ShipTosSearchParamsState } from '../user-state';

export const initialState: ShipTosSearchParamsState = {
  key: undefined,
  count: 0,
  page: 0,
  text: '',
  onlyConnected: false,
  soldTos: undefined,
};

export function reducer(state = initialState, action: SearchAction | any): ShipTosSearchParamsState {
  if (action.searchType !== SHIP_TOS) {
    return state;
  }
  switch (action.type) {
    case SearchActionTypes.SearchAction:
      return action.searchParams;
    default:
      return state;
  }
}
