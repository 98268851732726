import { MemoizedSelector, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { LoaderError } from '../../../model';
import { loaderErrorDetailsSelector } from '../../../state/utils/loader';
import { UserActionState } from '../base-feature-state';

export function getUserInteractionStateFactory(
  basePathSelector: MemoizedSelector<any, any>,
  interactionId: string
): MemoizedSelector<any, UserActionState> {
  return createSelector(basePathSelector, (state) => state[interactionId]);
}

export function getUserInteractionErrorFactory(
  basePathSelector: MemoizedSelector<any, any>,
  interactionId: string,
  id: string
): MemoizedSelector<any, LoaderError | undefined> {
  return createSelector(
    getUserInteractionStateFactory(basePathSelector, interactionId),
    (state) => (state && state[id] && loaderErrorDetailsSelector(state[id])) || undefined
  );
}

export function getUserInteractionSuccessFactory(
  basePathSelector: MemoizedSelector<any, any>,
  interactionId: string,
  id: string
): MemoizedSelector<any, boolean> {
  return createSelector(
    getUserInteractionStateFactory(basePathSelector, interactionId),
    (state) => state && state[id] && StateUtils.loaderSuccessSelector(state[id])
  );
}

export function getUserInteractionLoadingFactory(
  basePathSelector: MemoizedSelector<any, any>,
  interactionId: string,
  id: string
): MemoizedSelector<any, boolean> {
  return createSelector(
    getUserInteractionStateFactory(basePathSelector, interactionId),
    (state) => state && state[id] && StateUtils.loaderLoadingSelector(state[id])
  );
}
