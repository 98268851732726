import { Occ } from '../occ';
import { B2BUnit, ShipToList, SoldToList } from './org-unit.model';
import { UserList } from './user.model';

export import PermissionConnectionTypeEnum = Occ.PermissionConnectionTypeEnum;

export interface PermissionConnections extends UserList, ShipToList, SoldToList {}

export enum PermissionTypeCodeEnum {
  B2BOrderThresholdTimespanPermission = 'B2BOrderThresholdTimespanPermission',
  B2BShipToOrderTimespanPermission = 'B2BShipToOrderTimespanPermission',
  B2BSoldToOrderTimespanPermission = 'B2BSoldToOrderTimespanPermission',
  B2BOrderThresholdPermission = 'B2BOrderThresholdPermission',
  B2BAlwaysApprovePermission = 'B2BAlwaysApprovePermission',
}

export import PermissionType = Occ.PermissionType;

export import PermissionStatusEnum = Occ.PermissionStatusEnum;

export import PermissionPeriodRangeEnum = Occ.PermissionPeriodRangeEnum;

export import PermissionResolutionTypeEnum = Occ.PermissionResolutionTypeEnum;

export import PermissionAssortmentScope = Occ.PermissionAssortmentScope;

export interface Permission extends Omit<Occ.Permission, 'orgUnit'> {
  orgUnit?: B2BUnit;
  orderRulePermissionType?: PermissionType;
}

export interface PermissionResult extends Omit<Occ.PermissionResult, 'permissionDetails'> {
  permissionDetails?: Permission;
}

export interface PermissionUsage extends Omit<Occ.B2BPermissionUsage, 'permission'> {
  permission?: Permission;
}

export import B2BPermissionOrderListItem = Occ.B2BPermissionOrderListItem;

export enum ConnectedToPermissionCodeOptionsEnum {
  ALL = 'ALL',
  CONNECTED = 'CONNECTED',
  NOT_CONNECTED = 'NOT_CONNECTED',
}

export interface ConnectedToPermissionCodeParams {
  connectedToPermissionCode: boolean;
  permissionCode: string;
}
