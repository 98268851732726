import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { SiteContextConfig } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { getContextParameterValues } from '../config';
import { SITE_PREFIX_CONTEXT_ID } from '../providers/context-ids';
import { SiteContextActions } from '../store/actions';
import { SiteContextSelectors } from '../store/selectors';
import { StateWithSiteContext } from '../store/site-context-state';
import { SiteContext } from '.';

@Injectable({ providedIn: 'root' })
export class SitePrefixService implements SiteContext<string> {
  constructor(protected store: Store<StateWithSiteContext>, protected config: SiteContextConfig) {}

  getAll(): Observable<string[]> {
    return of([]);
  }

  getActive(): Observable<string> {
    return this.store.pipe(
      select(SiteContextSelectors.getSitePrefix),
      map((prefix) => {
        return prefix;
      })
    );
  }

  setActive(sitePrefix: string): void {
    this.store.pipe(select(SiteContextSelectors.getSitePrefix), take(1)).subscribe((currentSitePrefix) => {
      if (sitePrefix && currentSitePrefix !== sitePrefix) {
        this.store.dispatch(new SiteContextActions.SetActiveSitePrefix(sitePrefix));
      }
    });
  }

  /**
   * Tells whether the value of the site prefix has been already initialized
   */
  isInitialized(): boolean {
    let valueInitialized = false;
    this.getActive()
      .subscribe(() => (valueInitialized = true))
      .unsubscribe();

    return valueInitialized;
  }

  /**
   * Tells whether the given site prefix is allowed.
   *
   * The list of allowed site prefixes can be configured in the `context` config of Spartacus.
   */
  protected isValid(value: string): boolean {
    return !!value && getContextParameterValues(this.config, SITE_PREFIX_CONTEXT_ID).includes(value);
  }
}
