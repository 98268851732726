import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FullPalletUpgrade } from '../../../core/model';

@Component({
  selector: 'py-full-pallet-upgrade',
  templateUrl: './full-pallet-upgrade.component.html',
  styleUrls: ['./full-pallet-upgrade.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullPalletUpgradeComponent {
  @Input() isAlreadyUpdated: boolean;
  @Input() fullPalletUpgrade: FullPalletUpgrade;

  @Output() update = new EventEmitter<void>();
  @Output() skipUpdate = new EventEmitter<void>();

  onPalletUpgrade(): void {
    this.update.emit();
  }

  onPalletUpgradeSkip(): void {
    this.skipUpdate.emit();
  }
}
