import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '../../../core/i18n';
import { NotificationModule } from '../notification';
import { FullPalletUpgradeComponent } from './full-pallet-upgrade.component';

@NgModule({
  declarations: [FullPalletUpgradeComponent],
  imports: [CommonModule, NotificationModule, I18nModule],
  exports: [FullPalletUpgradeComponent],
})
export class FullPalletUpgradeModule {}
