import { Injectable } from '@angular/core';
import { CartType, OrderEntryType, OrderType } from '../../model';

@Injectable({
  providedIn: 'root',
})
export class OrderTypeToCartTypeMapper {
  getCartTypeFromOrderType(orderType: OrderType): CartType {
    switch (orderType) {
      case OrderType.STOCK:
        return CartType.stock;
      case OrderType.SAMPLE:
        return CartType.sample;
      case OrderType.FREE_STOCK:
        return CartType.freeStock;
      case OrderType.FREE_STOCK_FILLUP:
        return CartType.freeStockFillup;
      case OrderType.INVOICED_STOCK:
        return CartType.invoicedStock;
      case OrderType.INVOICED_STOCK_FILLUP:
        return CartType.invoicedStockFillup;
      case OrderType.CONSIGNMENT_STOCK:
        return CartType.consignmentStock;
      case OrderType.CONSIGNMENT_STOCK_FILLUP:
        return CartType.consignmentStockFillup;
      default:
        return CartType.stock;
    }
  }

  getCartTypeFromOrderEntryType(orderType: OrderEntryType): CartType {
    switch (orderType) {
      case OrderEntryType.STOCK_ITEM:
        return CartType.stock;
      case OrderEntryType.A4SAMPLE:
        return CartType.sample;
      case OrderEntryType.FREE_STOCK_ITEM:
        return CartType.freeStock;
      case OrderEntryType.FREE_STOCK_FILLUP_ITEM:
        return CartType.freeStockFillup;
      case OrderEntryType.INVOICED_STOCK_ITEM:
        return CartType.invoicedStock;
      case OrderEntryType.INVOICED_STOCK_FILLUP_ITEM:
        return CartType.invoicedStockFillup;
      case OrderEntryType.CONSIGNMENT_STOCK_ITEM:
        return CartType.consignmentStock;
      case OrderEntryType.CONSIGNMENT_STOCK_FILLUP_ITEM:
        return CartType.consignmentStockFillup;
      default:
        return CartType.stock;
    }
  }
}
