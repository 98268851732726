<div class="cart-message-modal-header-title">
  <div>
    <i nz-icon nzType="shopping-cart" class="mr-3"></i>
    {{ title }}
  </div>

  <div class="ml-auto close-button">
    <i nz-icon pyIconThemeReverse nzType="close-circle" nzTheme="outline" (click)="onModalClose()"></i>
  </div>
</div>
