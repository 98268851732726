import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { SOLD_TO_CORE_FEATURE } from './store/sold-to-state';

@NgModule({
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        [SOLD_TO_CORE_FEATURE]: {
          module: () => import('./sold-to.module').then((m) => m.SoldToModule),
        },
      },
    }),
  ],
})
export class SoldToFeatureModule {}
