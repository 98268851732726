import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { I18nModule } from '../../../core/i18n';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { PalletFlagNoteComponent } from './pallet-flag-note.component';

@NgModule({
  imports: [CommonModule, FormsModule, I18nModule, CheckboxModule],
  declarations: [PalletFlagNoteComponent],
  exports: [PalletFlagNoteComponent],
})
export class PalletFlagNoteModule {}
