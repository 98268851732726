<tr>
  <td class="image">
    <py-article-image [loading]="true"></py-article-image>
  </td>
  <td class="article" [attr.colspan]="articleColumnSpan">
    <py-article-info></py-article-info>
  </td>
  <td colspan="2">
    <span class="price h5 skeleton"></span>
  </td>
</tr>
