import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { AccountingCode, AccountingCodeList, OrderEntry } from '../../../../core/model';
import { CartType } from '../../../../core/model/cart.model';
import { AccountingCodesState, CART_CORE_FEATURE } from '../store/cart-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: CartAccountingCodesFacade,
      feature: CART_CORE_FEATURE,
      async: true,
      methods: [
        'isAccountingCodeModalOpen',
        'getAccountingCodesState',
        'getActiveAccountingCode',
        'setAccountingCodesModalOpen',
        'isAccountingCodesModalDismissed',
        'setAccountingCodesModalDismissed',
        'setAccountingCodeForAllEntries',
        'setAccountingCodeForAllEntriesAndRemoveIneligibleEntries',
        'updateEntryAccountingCode',
        'getUpdateEntryAccountingCode',
        'loadAccountingCodeList',
        'getAccountingCodeList',
        'getAccountingCodeListLoading',
        'getAccountingCodesMandatoryButNoneAvailable',
      ],
    }),
})
export abstract class CartAccountingCodesFacade {
  abstract isAccountingCodeModalOpen(): Observable<boolean>;
  abstract getAccountingCodesState(): Observable<AccountingCodesState>;
  abstract getActiveAccountingCode(): Observable<AccountingCode>;
  abstract setAccountingCodesModalOpen(cartType: CartType, accountingCode: AccountingCode, isModalOpen: boolean): void;
  abstract isAccountingCodesModalDismissed(): Observable<boolean>;
  abstract setAccountingCodesModalDismissed(isDismissed: boolean, cartType: CartType): void;
  abstract setAccountingCodeForAllEntries(cartType: CartType): void;
  abstract setAccountingCodeForAllEntriesAndRemoveIneligibleEntries(cartType: CartType): void;
  abstract updateEntryAccountingCode(entry: OrderEntry, accountingCode: string, cartType: CartType): void;
  abstract getUpdateEntryAccountingCode(entry: OrderEntry, cartType: CartType): Observable<AccountingCode | undefined>;
  abstract loadAccountingCodeList(): void;
  abstract getAccountingCodeList(): Observable<AccountingCodeList | undefined>;
  abstract getAccountingCodeListLoading(): Observable<boolean>;
  abstract getAccountingCodesMandatoryButNoneAvailable(): Observable<boolean>;
}
