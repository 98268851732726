import { DIALOG_TYPE, LAUNCH_CALLER, LayoutConfig } from '@spartacus/storefront';
import { ReloadModalBodyComponent } from './reload-modal-body.component';

export const reloadModalBodyLayoutConfig: LayoutConfig = {
  launch: {
    [LAUNCH_CALLER.RELOAD_MODAL_BODY]: {
      inlineRoot: true,
      component: ReloadModalBodyComponent,
      dialogType: DIALOG_TYPE.DIALOG,
      dialogOptions: {
        onTop: true,
      },
    },
  },
};
