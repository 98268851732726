import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Subscription, combineLatest } from 'rxjs';
import { filter, map, take, tap, withLatestFrom } from 'rxjs/operators';
import { UserIdService } from '../../../core/auth';
import { ModalCloseReason, isModalCloseReasonWithPayload } from '../../../core/modal';
import { CartType, PalletFlag, PalletFlagType, PalletFlags, PalletFlagsRequest } from '../../../core/model';
import { ActiveCartFacade, CartFacade } from '../../../features/cart/base';
import {
  PalletFlagFormModalCloseReason,
  PalletFlagFormModalData,
} from './components/pallet-flag-form-modal/pallet-flag-form-modal.component';

@Component({
  selector: 'py-pallet-flag',
  templateUrl: './pallet-flag.component.html',
  styleUrls: ['./pallet-flag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PalletFlagComponent implements OnInit, OnDestroy {
  @Input() cartType: CartType;
  @Input() entryNumber: number;
  @Input() entryId: string;
  @Input() viewMode: boolean;
  @Input() initValue: PalletFlags;
  @Input() disabled: boolean;

  shouldDisplayEditMode = false;
  palletFlags: PalletFlags;

  private subscriptions = new Subscription();

  constructor(
    private activeCartService: ActiveCartFacade,
    private userIdService: UserIdService,
    private cartService: CartFacade,
    private cd: ChangeDetectorRef,
    private launchDialogService: LaunchDialogService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.activeCartService
        .getEntries(this.cartType)
        .pipe(
          filter((entries) => Boolean(entries.length)),
          map((entries) => entries.find((entry) => entry.id === this.entryId)),
          map((entry) => entry?.palletFlags),
          tap((palletFlags) => (this.shouldDisplayEditMode = this.checkIfPalletFlagsNotEmpty(palletFlags)))
        )
        .subscribe((palletFlags) => {
          this.palletFlags = palletFlags;
          this.cd.detectChanges();
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  openModal(): void {
    const modalData: PalletFlagFormModalData = {
      isEditMode: this.checkIfPalletFlagsNotEmpty(this.palletFlags),
      palletFlags: this.palletFlags,
    };

    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.PALLET_FLAG_FORM, undefined, modalData);

    this.subscriptions.add(
      this.launchDialogService.dialogClose
        .pipe(
          filter((reason) => reason !== undefined),
          filter((reason) => isModalCloseReasonWithPayload(reason)),
          map((reasonWithPayload) => reasonWithPayload as PalletFlagFormModalCloseReason),
          take(1)
        )
        .subscribe(({ reason, payload: palletFlags }) => {
          if (reason === ModalCloseReason.PALLET_FLAG_FORM_SAVED) {
            this.updatePalletFlags(palletFlags);
          }
        })
    );
  }

  private checkIfPalletFlagsNotEmpty(palletFlags: PalletFlags): boolean {
    return palletFlags && Object.keys(palletFlags).some((key) => !!palletFlags[key]);
  }

  private updatePalletFlags(palletFlags: PalletFlags): void {
    combineLatest([this.activeCartService.getActiveCartId(this.cartType), this.userIdService.getUserId()])
      .pipe(take(1), withLatestFrom(this.activeCartService.getEntryById(this.entryId, this.cartType)))
      .subscribe(([[cartId, userId], entry]) => {
        this.cartService.updateEntryPalletFlags(userId, this.cartType, cartId, entry, this.convertPalletFlags(palletFlags));
      });
  }

  private convertPalletFlags(palletFlags: PalletFlags): PalletFlagsRequest {
    const palletFlagsConverted: PalletFlag[] = [];

    for (const [key, value] of Object.entries(palletFlags)) {
      switch (key) {
        case 'customer':
          palletFlagsConverted.push({ type: PalletFlagType.CUSTOMER, value: value?.trim() });
          break;
        case 'machine':
          palletFlagsConverted.push({ type: PalletFlagType.MACHINE_NO, value: value?.trim() });
          break;
        case 'object':
          palletFlagsConverted.push({ type: PalletFlagType.OBJECT, value: value?.trim() });
          break;
        default:
          continue;
      }
    }

    return { flags: palletFlagsConverted };
  }
}
