import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../core/i18n';
import { SpinnerModule } from '../spinner/spinner.module';
import { EditableComponent } from './editable.component';

@NgModule({
  imports: [CommonModule, NzIconModule, FormsModule, SpinnerModule, NgbTooltipModule, I18nModule],
  declarations: [EditableComponent],
  exports: [EditableComponent],
})
export class EditableModule {}
