import { CmsActions, StateUtils } from '@spartacus/core';
import { Page } from '../../model/page.model';

export const initialState: StateUtils.EntityState<Page> = { entities: {} };

export function reducer(state = initialState, action: CmsActions.LoadCmsPageDataSuccess): StateUtils.EntityState<Page> {
  switch (action.type) {
    case CmsActions.LOAD_CMS_PAGE_DATA_SUCCESS: {
      const page: Page = action.payload;
      return { ...state, entities: { ...state.entities, [page?.pageId]: page } };
    }
  }
  return state;
}
