import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AuthService, StateUtils } from '@spartacus/core';
import { Observable, UnaryFunction, of, pipe } from 'rxjs';
import { filter, map, share, switchMap, tap } from 'rxjs/operators';
import { UserIdService } from '../../../auth';
import { AddonGroup, AddonGroupRef, AddonNode } from '../../../model';
import { withdrawOn } from '../../../util';
import { StateWithCatalog } from '../../store/catalog-state';
import { CatalogSelectors } from '../../store/selectors';
import { AddonGroupsActions, AddonNodeActions } from '../store/actions';

@Injectable({
  providedIn: 'root',
})
export class AddonService {
  constructor(private userIdService: UserIdService, private authService: AuthService, private store: Store<StateWithCatalog>) {}

  getAddonGroups(): Observable<AddonGroup[]> {
    const isUserLoggedIn$ = this.authService.isUserLoggedIn().pipe(share());

    return isUserLoggedIn$.pipe(
      filter(Boolean),
      switchMap(() => this.store.select(CatalogSelectors.getAddonGroupsState)),
      tap((loaderState) => {
        if (this.needsToLoad(loaderState)) {
          this.loadAddonGroups();
        }
      }),
      filter((loaderState) => loaderState.value && loaderState.value.tree && Object.values(loaderState.value.tree).length !== 0),
      map((loaderState) => Object.values(loaderState.value.tree).sort((a1, a2) => (a1.title > a2.title ? 1 : -1))),
      withdrawOn(isUserLoggedIn$.pipe(filter((isLoggedIn) => !isLoggedIn)))
    );
  }

  getAddonGroupsLoading(): Observable<boolean> {
    return this.store.select(CatalogSelectors.getAddonGroupsLoading);
  }

  getAddonGroupsLoaded(): Observable<boolean> {
    return this.store.select(CatalogSelectors.getAddonGroupsLoaded);
  }

  getAddonGroup(addonGroupId: AddonGroupRef): Observable<AddonGroup> {
    if (!addonGroupId) {
      return of(undefined);
    }
    return this.store.pipe(select(CatalogSelectors.addonGroupByIdSelector, { id: addonGroupId }), this.loadIfNotPresentPipe());
  }

  getAddonGroupDetails(addonGroupId: AddonGroupRef, sort: string): Observable<AddonGroup> {
    if (!addonGroupId) {
      return of(undefined);
    }

    return this.store.pipe(
      select(CatalogSelectors.addonGroupByIdSelector, { id: addonGroupId }),
      this.loadAddonGroupDetailsIfAddonGroupLoadedPipe(addonGroupId, sort)
    );
  }

  loadAddonGroup(addonGroupId: string, sort?: string): void {
    this.userIdService.takeUserId(true).subscribe(
      (userId) => this.store.dispatch(new AddonGroupsActions.LoadAddonGroup(userId, addonGroupId, sort)),
      () => {}
    );
  }

  getAddonNode(addonGroupId: string, addonNodeId: string, sort: string): Observable<AddonNode> {
    if (!addonGroupId || !addonNodeId) {
      return of(undefined);
    }

    return this.store.pipe(
      select(CatalogSelectors.getAddonNode(addonGroupId, addonNodeId)),
      this.loadAddonNodeIfNotPresentPipe(addonGroupId, addonNodeId, sort)
    );
  }

  getAddonNodeLoading(addonGroupId: string, addonNodeId: string): Observable<boolean> {
    return this.store.select(CatalogSelectors.getAddonNodeLoading(addonGroupId, addonNodeId));
  }

  getAddonNodeLoaded(addonGroupId: string, addonNodeId: string): Observable<boolean> {
    return this.store.select(CatalogSelectors.getAddonNodeLoaded(addonGroupId, addonNodeId));
  }

  loadAddonNode(addonGroupId: string, addonNodeId: string, sort?: string) {
    this.userIdService.takeUserId(true).subscribe(
      (userId) => this.store.dispatch(new AddonNodeActions.LoadAddonNode(userId, addonGroupId, addonNodeId, sort)),
      () => {}
    );
  }

  private loadIfNotPresentPipe<T>(): UnaryFunction<Observable<T>, Observable<T>> {
    return pipe(
      tap((addonGroups) => {
        if (!addonGroups) {
          this.loadAddonGroups();
        }
      }),
      filter((addonGroups) => !!addonGroups),
      map((addonGroups) => addonGroups)
    );
  }

  private loadAddonGroupDetailsIfAddonGroupLoadedPipe(
    addonGroupId: string,
    sort: string
  ): UnaryFunction<Observable<AddonGroup>, Observable<AddonGroup>> {
    return pipe(
      tap((addonGroup) => {
        if (addonGroup && (!addonGroup.articleRefs || addonGroup.sort !== sort)) {
          this.loadAddonGroup(addonGroupId, sort);
        }
      }),
      filter((addonGroup) => addonGroup && Boolean(addonGroup?.articleRefs)),
      map((addonGroup) => addonGroup)
    );
  }

  private loadAddonNodeIfNotPresentPipe(
    addonGroupId: string,
    addonNodeId: string,
    sort: string
  ): UnaryFunction<Observable<AddonNode>, Observable<AddonNode>> {
    return pipe(
      tap((addonNode) => {
        if (!addonNode) {
          this.loadAddonNode(addonGroupId, addonNodeId, sort);
        }
      }),
      filter((addonNode) => !!addonNode),
      map((addonNode) => addonNode)
    );
  }

  private loadAddonGroups(): void {
    this.userIdService.takeUserId(true).subscribe(
      (userId) => this.store.dispatch(new AddonGroupsActions.LoadAddonGroups(userId)),
      () => {}
    );
  }

  private needsToLoad(loaderState: StateUtils.LoaderState<any>) {
    return !loaderState.success && !loaderState.error && !loaderState.loading;
  }
}
