import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CheckboxSliderModule } from '../../checkbox-slider/checkbox-slider.module';
import { ReactiveFormCheckboxSliderComponent } from './reactive-form-checkbox-slider.component';

@NgModule({
  declarations: [ReactiveFormCheckboxSliderComponent],
  imports: [CommonModule, ReactiveFormsModule, CheckboxSliderModule],
  exports: [ReactiveFormCheckboxSliderComponent],
})
export class ReactiveFormCheckboxSliderModule {}
