import { Action } from '@ngrx/store';
import { Currency } from '../../../model/misc.model';

export enum CurrenciesActionTypes {
  LoadCurrencies = '[Site-context] Load Currencies',
  LoadCurrenciesSuccess = '[Site-context] Load Currencies Success',
  LoadCurrenciesFail = '[Site-context] Load Currencies Fail',

  SetActiveCurrency = '[Site-context] Set Active Currency',
  CurrencyChange = '[Site-context] Currency Change',
}

export class LoadCurrencies implements Action {
  readonly type = CurrenciesActionTypes.LoadCurrencies;
}

export class LoadCurrenciesFail implements Action {
  readonly type = CurrenciesActionTypes.LoadCurrenciesFail;
  constructor(public payload: any) {}
}

export class LoadCurrenciesSuccess implements Action {
  readonly type = CurrenciesActionTypes.LoadCurrenciesSuccess;
  constructor(public payload: Currency[]) {}
}

export class SetActiveCurrency implements Action {
  readonly type = CurrenciesActionTypes.SetActiveCurrency;
  constructor(public payload: string) {}
}

export class CurrencyChange implements Action {
  readonly type = CurrenciesActionTypes.CurrencyChange;
  constructor(public payload: { previous: string; current: string }) {}
}

export type CurrenciesActions = LoadCurrencies | LoadCurrenciesFail | LoadCurrenciesSuccess | SetActiveCurrency | CurrencyChange;
