import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { AlertMessageStatusComponent } from './alert-message-status.component';

@NgModule({
  imports: [CommonModule, NzIconModule],
  declarations: [AlertMessageStatusComponent],
  exports: [AlertMessageStatusComponent],
})
export class AlertMessageStatusModule {}
