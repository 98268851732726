import { InjectionToken, Provider } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { SiteConfig } from '../../../model';
import { PyStateUtils } from '../../../state';
import { SITE_CONFIG, SiteContextState } from '../site-context-state';
import * as fromBaseSite from './base-site.reducer';
import * as fromCurrencies from './currencies.reducer';
import * as fromLanguages from './languages.reducer';
import * as fromSiteCountry from './site-country.reducer';
import * as fromSitePrefix from './site-prefix-reducer';

export function getReducers(): ActionReducerMap<SiteContextState, any> {
  return {
    languages: fromLanguages.reducer,
    currencies: fromCurrencies.reducer,
    activeCountry: fromSiteCountry.reducer,
    baseSite: fromBaseSite.reducer,
    sitePrefix: fromSitePrefix.reducer,
    siteConfig: PyStateUtils.loaderReducer<SiteConfig>(SITE_CONFIG),
  };
}

export const reducerToken: InjectionToken<ActionReducerMap<SiteContextState>> = new InjectionToken<
  ActionReducerMap<SiteContextState>
>('SiteContextReducers');

export const reducerProvider: Provider = {
  provide: reducerToken,
  useFactory: getReducers,
};
