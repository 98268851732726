import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { UserIconComponent } from './user-icon.component';

@NgModule({
  declarations: [UserIconComponent],
  imports: [CommonModule, NzIconModule],
  exports: [UserIconComponent],
})
export class UserIconModule {}
