import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CreateOrUpdateOrderCardConfiguration, OrderCard, OrderCardConfiguration, Pagination, SearchParams } from '../../model';
import { USER_BASE_FEATURE } from '../store/user-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: OrderCardsFacade,
      feature: USER_BASE_FEATURE,
      async: true,
      methods: [
        'getOrderCard',
        'searchOrderCards',
        'getOrderCardsSearchResult',
        'getSearchResultPagination',
        'getSearchPageResultsLoading',
        'loadOrderCardConfigurations',
        'getOrderCardConfiguration',
        'getOrderCardConfigurationLoading',
        'updateOrderCardConfiguration',
        'isOrderCardsUserEnabled',
      ],
    }),
})
export abstract class OrderCardsFacade {
  abstract getOrderCard(code: string): Observable<OrderCard>;
  abstract searchOrderCards(searchParams: SearchParams): void;
  abstract getOrderCardsSearchResult(searchParams: SearchParams): Observable<OrderCard[] | undefined>;
  abstract getSearchResultPagination(searchParams: SearchParams): Observable<Pagination | undefined>;
  abstract getSearchPageResultsLoading(searchParams: SearchParams): Observable<boolean>;
  abstract loadOrderCardConfigurations(code: string): void;
  abstract getOrderCardConfiguration(code: string, productCode: string): Observable<OrderCardConfiguration>;
  abstract getOrderCardConfigurationLoading(code: string, productCode: string): Observable<boolean>;
  abstract updateOrderCardConfiguration(code: string, productCode: string, params: CreateOrUpdateOrderCardConfiguration): void;
  abstract isOrderCardsUserEnabled(): Observable<boolean>;
}
