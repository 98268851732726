<ng-container *ngIf="showButton; else textTemplate" [ngTemplateOutlet]="buttonTemplate"></ng-container>

<ng-template #buttonTemplate>
  <div class="wrapper">
    <ng-container *ngIf="!!buttonLabel" [ngTemplateOutlet]="headingTemplate"></ng-container>

    <button
      (click)="open()"
      [disabled]="disabled"
      class="add-or-edit-button px-2"
      [ngClass]="{ edit: namesCount > 0, add: namesCount === 0 }"
    >
      <span>
        <i nz-icon nzType="idcard" nzTheme="fill" class="mr-2"></i>
        <span>{{ buttonLabel ? buttonLabel : ('common.nameMarking.addNameMarking_action' | cxTranslate) }}</span>
      </span>
      <div class="icon-holder delete" *ngIf="!disabled && namesCount > 0" [ngbTooltip]="'common.edit_tooltip' | cxTranslate">
        <i nz-icon nzType="edit" nzTheme="outline"></i>
      </div>
    </button>
  </div>
</ng-template>

<ng-template #textTemplate>
  <py-name-marking-text [names]="names || []"></py-name-marking-text>
</ng-template>

<ng-template #headingTemplate>
  <div class="label" [class.disabled]="disabled">
    {{ 'cart.nameMarking_heading' | cxTranslate }}
  </div>
</ng-template>
