import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../core/i18n';
import { ScreenSizeModule } from '../../directives';
import { AlertMessageStatusModule } from '../alert-message-status';
import { AlertMessageComponent } from './alert-message.component';

@NgModule({
  imports: [CommonModule, I18nModule, AlertMessageStatusModule, NzIconModule, ScreenSizeModule],
  declarations: [AlertMessageComponent],
  exports: [AlertMessageComponent],
})
export class AlertMessageModule {}
