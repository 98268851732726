import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../../core/i18n';
import { PrincipalConfigurationDirectiveModule } from '../../../directives';
import { PipesModule } from '../../../pipes';
import { ArticlePriceModule } from '../../article-price';
import { BadgeModule } from '../../badge';
import { SpinnerModule } from '../../spinner';
import { CatalogArticlePriceComponent } from './catalog-article-price.component';
import { ParsePricePipe } from './parse-price.pipe';

@NgModule({
  imports: [
    CommonModule,
    NgbTooltipModule,
    I18nModule,
    NzIconModule,
    PipesModule,
    SpinnerModule,
    BadgeModule,
    ArticlePriceModule,
    PrincipalConfigurationDirectiveModule,
  ],
  declarations: [CatalogArticlePriceComponent, ParsePricePipe],
  exports: [CatalogArticlePriceComponent, ParsePricePipe],
})
export class CatalogArticlePriceModule {}
