import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { LaunchDialogService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseAbstractModalComponent, ModalCloseReason, ModalCloseReasonWithPayload } from '../../../../../core/modal';
import { PalletFlags } from '../../../../../core/model';
import { AtLeastOneValueValidator } from '../../../../../shared/validators/at-least-one-value-validator';
import { WhiteSpacesValidator } from '../../../../../shared/validators/white-spaces.validator';

export interface PalletFlagFormModalData {
  isEditMode: boolean;
  palletFlags: PalletFlags;
}

export interface PalletFlagFormModalCloseReason extends ModalCloseReasonWithPayload {
  reason: string;
  payload: PalletFlags;
}

@Component({
  selector: 'py-pallet-flag-form-modal',
  templateUrl: './pallet-flag-form-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PalletFlagFormModalComponent extends BaseAbstractModalComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  isEditMode = false;
  palletFlags: PalletFlags;

  private subscriptions = new Subscription();

  constructor(
    private fb: UntypedFormBuilder,
    protected launchDialogService: LaunchDialogService,
    protected el: ElementRef,
    protected renderer: Renderer2
  ) {
    super(el, launchDialogService, renderer);
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.launchDialogService.data$.pipe(take(1)).subscribe((data: PalletFlagFormModalData) => {
        this.isEditMode = data.isEditMode;
        this.palletFlags = data.palletFlags;

        this.createForm();
      })
    );
  }

  onClear(): void {
    const reasonWithPayload: PalletFlagFormModalCloseReason = {
      reason: ModalCloseReason.PALLET_FLAG_FORM_SAVED,
      payload: {
        customer: '',
        machine: '',
        object: '',
      },
    };

    this.launchDialogService.closeDialog(reasonWithPayload);
  }

  onSave(): void {
    const reasonWithPayload: ModalCloseReasonWithPayload = {
      reason: ModalCloseReason.PALLET_FLAG_FORM_SAVED,
      payload: this.form.value,
    };

    this.launchDialogService.closeDialog(reasonWithPayload);
  }

  private createForm(): void {
    this.form = this.fb.group({
      customer: [this.palletFlags?.customer || '', [WhiteSpacesValidator.validator]],
      machine: [this.palletFlags?.machine || '', [WhiteSpacesValidator.validator]],
      object: [this.palletFlags?.object || '', [WhiteSpacesValidator.validator]],
    });

    if (!this.isEditMode) {
      this.form.setValidators(AtLeastOneValueValidator.validator);
      this.form.updateValueAndValidity();
    }
  }

  dismissModal(): void {
    this.launchDialogService.closeDialog(ModalCloseReason.AREA_OF_USE_ADMIN_OVERVIEW_HELP_DISMISSED);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
