import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { KeyboardFocusModule } from '@spartacus/storefront';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../core/i18n';
import { LaunchDialogService } from '../../../core/modal';
import { ActionButtonModule } from '../action-button';
import { ModalModule } from '../modal';
import { ReactiveFormInputFieldModule } from '../reactive-forms-input/reactive-form-input-field/reactive-form-input-field.module';
import { createShoppingListModalLayoutConfig } from './create-shopping-list-modal-layout.config';
import { CreateShoppingListModalComponent } from './create-shopping-list-modal.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ReactiveFormInputFieldModule,
    ModalModule,
    NzIconModule,
    I18nModule,
    ActionButtonModule,
    KeyboardFocusModule,
  ],
  declarations: [CreateShoppingListModalComponent],
  exports: [CreateShoppingListModalComponent],
})
export class CreateShoppingListModalModule {
  constructor(private launchDialogService: LaunchDialogService) {
    this.launchDialogService.registerModalLayoutConfig(createShoppingListModalLayoutConfig);
  }
}
