import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserIdService } from '../../../auth';
import { NonSellableProductInquiryFormData } from '../../../model';
import { StateWithCatalog } from '../../store/catalog-state';
import { CatalogSelectors } from '../../store/selectors';
import { NonSellableProductActions } from '../store/actions';

@Injectable({
  providedIn: 'root',
})
export class NonSellableProductService {
  constructor(private store: Store<StateWithCatalog>, private userIdService: UserIdService) {}

  sendNonSellableProductInquiry(inquiryFormData: NonSellableProductInquiryFormData): void {
    this.userIdService
      .getUserId()
      .pipe(take(1))
      .subscribe((userId) =>
        this.store.dispatch(new NonSellableProductActions.SendNonSellableProductInquiry(inquiryFormData, userId))
      );
  }

  getSendNonSellableProductInquiryLoading(): Observable<boolean> {
    return this.store.select(CatalogSelectors.getSendNonSellableProductInquiryLoading);
  }

  getSendNonSellableProductInquirySuccess(): Observable<boolean> {
    return this.store.select(CatalogSelectors.getSendNonSellableProductInquirySuccess);
  }

  resetSendNonSellableProductInquiryState(): void {
    this.store.dispatch(new NonSellableProductActions.SendNonSellableProductInquiryReset());
  }
}
