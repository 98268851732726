<button
  class="btn btn-ternary btn-icon"
  [ngbPopover]="confirmRemoveContent"
  #popOver="ngbPopover"
  container="body"
  [popoverClass]="'delete-button-popover'"
  [placement]="['bottom', 'top', 'left-bottom', 'left-top', 'top-left']"
  [ngbTooltip]="'common.delete_tooltip' | cxTranslate"
>
  <i nz-icon nzType="delete"></i>
</button>
<ng-template #confirmRemoveContent>
  <div class="d-flex flex-column">
    <div class="d-flex justify-content-between border-bottom p-3">
      <h5>{{ 'common.delete_action' | cxTranslate }}</h5>
      <button (click)="popOver.close()" class="close">
        <i nz-icon pyIconThemeReverse nzType="close-circle" nzTheme="outline"></i>
      </button>
    </div>
    <div class="d-flex flex-column p-3">
      <button class="btn btn-danger mb-2" (click)="confirmDeletion.emit()">
        {{ 'common.yesAndRemove_action' | cxTranslate }}
      </button>
      <button class="btn btn-plain-secondary" (click)="popOver.close()">
        {{ 'common.noAndCancel_action' | cxTranslate }}
      </button>
    </div>
  </div>
</ng-template>
