import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { EventService } from '@spartacus/core';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, merge } from 'rxjs';
import { auditTime, debounceTime, filter, map, mapTo, mergeMap, scan, share, shareReplay, startWith } from 'rxjs/operators';
import { ArticleService } from '../../../../core/catalog';
import { CartType, OrderEntry, PrePrintedSpecial, Price, TinyCart } from '../../../../core/model';
import {
  ActiveCartFacade,
  AddCartEntriesSuccessEvent,
  AddCartEntrySuccessEvent,
  TinyCartFacade,
  UpdateCartEntryFailEvent,
  UpdateCartEntrySuccessEvent,
} from '../../../../features/cart/base';
import { CART_TYPE_PARAM_KEY, matchEntry } from '../../../../shared';
import { CartModalEntry, UpdateEntry } from '../components/cart-message-modal/cart-message-modal.component';

export interface EntryPalletInfoUpdate {
  palletUpgradeEntry: OrderEntry;
  price?: Price;
  isAlreadyUpdated?: boolean;
  isSkipped?: boolean;
  isUpgradePending?: boolean;
}

@Component({
  selector: 'py-cart-message',
  templateUrl: './cart-message.component.html',
  styleUrls: ['./cart-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartMessageComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  entries$: Observable<CartModalEntry[]>;
  hasEntries$: Observable<boolean>;
  remainingCart$: Observable<TinyCart>;
  showRemainingCartMessage$ = new BehaviorSubject<boolean>(false);
  entryUpdate$ = new Subject<EntryPalletInfoUpdate>();
  forceClear$ = new Subject<void>();
  queryParams$: Observable<Record<string, CartType>>;

  constructor(
    private eventService: EventService,
    private articleService: ArticleService,
    private activeCartService: ActiveCartFacade,
    private tinyCartService: TinyCartFacade,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initObservables();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.forceClear$.complete();
  }

  onCartEntryUpdate({ entry, quantity, unit, cartType, isUpgradePending }: UpdateEntry): void {
    if (isUpgradePending) {
      return;
    }

    this.activeCartService.updateEntry(entry, quantity, unit, cartType);

    this.entryUpdate$.next({
      palletUpgradeEntry: entry,
      isUpgradePending: true,
    });
  }

  onCartEntryUpdateSkip(entry: OrderEntry): void {
    this.entryUpdate$.next({ palletUpgradeEntry: entry, isSkipped: true });
  }

  onModalClose(): void {
    this.forceClear$.next();
  }

  private initObservables(): void {
    this.listenToUpdateEntryEvents();
    this.listenToRemainingCartOnOrderConfirmationPage();

    const entry$: Observable<CartModalEntry> = merge(
      this.eventService.get(AddCartEntrySuccessEvent).pipe(
        map((event) => {
          const article$ = this.articleService.getArticle(event.articleCode);
          let articleNumber: string;

          if (event.entry?.articleRef === PrePrintedSpecial.VOLVO) {
            articleNumber = event.entry?.prePrintedMaterialNumber || event.entry?.prePrintedLabel?.materialNumber;
          }

          return {
            price: event.entry.totalPrice || event.entry.cartPrice || event.entry.netPrice,
            article$,
            articleNumber: articleNumber,
            hasNameMarking: event.entry.configurationInfos?.some((ci) => ci.qualifier === 'name-marking'),
            fullPalletUpgrade: event.fullPalletUpgrade,
            entryNumber: event.entry.entryNumber,
            id: event.entry.id,
            cartType: event.cartType,
          };
        })
      ),
      this.eventService.get(AddCartEntriesSuccessEvent).pipe(
        mergeMap((event) => {
          const successfullyAddedEntries = event.entries.filter(
            (entry) => !event.failedArticlesData?.some((failedArticle) => failedArticle.articleNumber === entry.articleRef)
          );

          return successfullyAddedEntries.map((entry) => {
            const article$ = this.articleService.getArticle(entry.articleRef);
            let articleNumber: string;

            if (entry?.articleRef === PrePrintedSpecial.VOLVO) {
              articleNumber = entry?.prePrintedMaterialNumber || entry?.prePrintedLabel?.materialNumber;
            }

            return {
              price: entry.totalPrice || entry.cartPrice || entry.netPrice,
              article$,
              articleNumber: articleNumber,
              hasNameMarking: entry.configurationInfos?.some((ci) => ci.qualifier === 'name-marking'),
              cartType: event.cartType,
              id: entry.id,
            };
          });
        })
      )
    ).pipe(share());
    const clear$: Observable<void> = merge(
      merge(entry$, this.entryUpdate$).pipe(debounceTime(5000), mapTo(null)),
      this.forceClear$.asObservable()
    );

    const entries$ = merge(entry$, this.entryUpdate$, clear$).pipe(
      scan((events, event) => {
        if (!event) {
          return [];
        }

        if (event.hasOwnProperty('palletUpgradeEntry')) {
          return events.map((entry) => {
            if (matchEntry(entry, (event as EntryPalletInfoUpdate).palletUpgradeEntry)) {
              return {
                ...entry,
                ...event,
              };
            }

            return entry;
          });
        }

        return [...events, event];
      }, []),
      shareReplay({ bufferSize: 1, refCount: true })
    );
    this.entries$ = entries$;
    this.hasEntries$ = entries$.pipe(
      map((entries) => entries?.length > 0),
      startWith(false)
    );

    this.queryParams$ = this.remainingCart$.pipe(
      filter((tinyCart) => !!tinyCart),
      map((tinyCart) => ({ [CART_TYPE_PARAM_KEY]: tinyCart.cartType }))
    );
  }

  private listenToUpdateEntryEvents(): void {
    this.subscription.add(
      this.eventService.get(UpdateCartEntrySuccessEvent).subscribe((payload) => {
        this.entryUpdate$.next({
          palletUpgradeEntry: payload.entry,
          price: payload.cartModification?.entry.totalPrice,
          isAlreadyUpdated: true,
        });
      })
    );

    this.subscription.add(
      this.eventService.get(UpdateCartEntryFailEvent).subscribe((payload) => {
        this.entryUpdate$.next({
          palletUpgradeEntry: payload.entry,
          isUpgradePending: false,
        });
      })
    );
  }

  private listenToRemainingCartOnOrderConfirmationPage(): void {
    const isOnOrderConfirmationPage$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event) => event as NavigationEnd),
      map((event) => (event.url.includes('/order-confirmation') && !event.url.includes('merge=true') ? true : false))
    );

    const tinyCarts$ = this.tinyCartService.getTinyCarts().pipe(
      map((tinyCarts) => tinyCarts || []),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.remainingCart$ = tinyCarts$.pipe(
      map((tinyCarts) => tinyCarts?.find((tinyCart) => tinyCart.numberOfEntries > 0)),
      shareReplay({ bufferSize: 1, refCount: true }),
      startWith(undefined as TinyCart)
    );

    this.subscription.add(
      combineLatest([this.remainingCart$, isOnOrderConfirmationPage$])
        .pipe(
          map(([remainingTinyCart, isOnOrderConfirmationPage]) => isOnOrderConfirmationPage && !!remainingTinyCart),
          auditTime(1000)
        )
        .subscribe((decision) => this.showRemainingCartMessage$.next(decision))
    );
  }

  onModalSkip(): void {
    this.showRemainingCartMessage$.next(false);
  }
}
