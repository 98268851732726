import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        orderApprovals: {
          module: () => import('./container/order-approvals.module').then((m) => m.OrderApprovalsModule),
          dependencies: [
            () =>
              import('../../../features/purchase-limits-base/purchase-limits-base.module').then(
                (m) => m.PurchaseLimitsBaseModule
              ),
          ],
          cmsComponents: ['CmsOrderApprovalsComponent'],
        },
      },
    }),
  ],
})
export class OrderApprovalFeatureModule {}
