import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { B2BUserSearchParams, DataImport } from '../../../../../core/model';
import { UsersImportExportAdapter } from '../connectors';

@Injectable({
  providedIn: 'root',
})
export class OccUsersImportExportAdapter implements UsersImportExportAdapter {
  constructor(protected http: HttpClient, protected occEndpoints: OccEndpointsService) {}

  getExportedUsersFile(userId: string, searchParams: B2BUserSearchParams, totalCount: number): Observable<Blob> {
    return this.http.get(
      this.occEndpoints.buildUrl('usersExportXls', {
        urlParams: { userId },
        queryParams: {
          sort: searchParams.sort ? searchParams.sort : null,
          orderCard: searchParams?.orderCard ? searchParams.orderCard : '',
          roles: searchParams?.roles?.length ? searchParams.roles.join(',') : '',
          soldTos: searchParams?.soldTos?.length ? searchParams.soldTos.join(',') : '',
          statuses: searchParams?.statuses?.length ? searchParams.statuses.join(',') : '',
          search: searchParams?.searchText ? searchParams.searchText : '',
          totalCount: totalCount.toString(),
        },
      }),
      {
        responseType: 'blob',
      }
    );
  }

  sendInsertImportUsersFile(userId: string, file: File): Observable<DataImport> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<DataImport>(
      this.occEndpoints.buildUrl('usersInsertImportXls', {
        urlParams: {
          userId,
        },
      }),
      formData
    );
  }

  sendUpdateImportUsersFile(userId: string, file: File): Observable<DataImport> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<DataImport>(
      this.occEndpoints.buildUrl('usersUpdateImportXls', {
        urlParams: {
          userId,
        },
      }),
      formData
    );
  }

  downloadImportUsersFileTemplate(userId: string): Observable<Blob> {
    return this.http.get(this.occEndpoints.buildUrl('usersImportFileTemplateXls', { urlParams: { userId } }), {
      responseType: 'blob',
    });
  }
}
