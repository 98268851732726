import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { DrawerFooterModule } from './drawer-footer/drawer-footer.module';
import { DrawerHeaderModule } from './drawer-header/drawer-header.module';
import { DrawerTemplateComponent } from './drawer-template.component';

@NgModule({
  imports: [CommonModule, NzIconModule, DrawerHeaderModule, DrawerFooterModule],
  declarations: [DrawerTemplateComponent],
  exports: [DrawerTemplateComponent],
})
export class DrawerModule {}
