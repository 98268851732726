import { InjectionToken, Provider } from '@angular/core';
import { ActionReducer, ActionReducerMap, MetaReducer, combineReducers } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { SoldToActions } from '../../../../features/sold-to-base/store/actions';
import { LoginLogoutActionTypes } from '../../../auth/user-auth/store/actions/login-logout.action';
import {
  A4SampleColor,
  AddonNode,
  AlternativeArticles,
  AlternativeProducts,
  Article,
  EntityMap,
  Product,
  SapPriceCampaignDetails,
  SapPriceCampaignList,
  Substitutes,
} from '../../../model';
import { CurrenciesActionTypes } from '../../../site-context/store/actions/currencies.action';
import { LanguagesActionTypes } from '../../../site-context/store/actions/languages.action';
import { PyStateUtils } from '../../../state';
import { AddonNodeActionTypes } from '../../addon/store/actions/addon-node.action';
import { reducer as addonGroupReducer } from '../../addon/store/reducers/addon-group.reducer';
import { reducer as areasOfUseReducer } from '../../area-of-use/store/reducers/area-of-use.reducer';
import { CategoryActions } from '../../category/store/actions';
import { CategoryActionTypes } from '../../category/store/actions/category.action';
import { reducer as categoryReducer } from '../../category/store/reducers/category.reducer';
import { ProductActionTypes } from '../../product/store/actions/product.action';
import {
  A4_SAMPLE_COLOR,
  ADDON_GROUP,
  ADDON_NODE,
  AREAS_OF_USE,
  ARTICLE,
  AddonGroupEntries,
  AddonNodeViewState,
  CATALOG_KEYS,
  CATEGORY,
  CatalogState,
  CategoryEntries,
  CategoryViewState,
  NON_SELLABLE_PRODUCT_INQUIRY,
  PRICE_CAMPAIGNS,
  PRICE_CAMPAIGN_DETAILS,
  PRODUCT,
  ProductViewState,
  SIMILAR_ARTICLES,
  SUBSTITUTES_ARTICLES,
  SUBSTITUTE_PRODUCTS,
} from '../catalog-state';
import { reducer as catalogKeysReducer } from './catalog-keys.reducer';

export function getReducers(): ActionReducerMap<CatalogState, any> {
  return {
    catalogKeys: clearStateOnContextChange(PyStateUtils.loaderReducer<EntityMap<string>>(CATALOG_KEYS, catalogKeysReducer)),
    areasOfUse: clearStateOnContextChange(PyStateUtils.loaderReducer<CategoryEntries>(AREAS_OF_USE, areasOfUseReducer)),
    articles: clearStateOnContextChange(
      StateUtils.entityReducer<StateUtils.LoaderState<Article>>(ARTICLE, PyStateUtils.loaderReducer<Article>(ARTICLE))
    ),
    similarArticles: clearStateOnContextChange(
      StateUtils.entityReducer<StateUtils.LoaderState<AlternativeArticles>>(
        SIMILAR_ARTICLES,
        PyStateUtils.loaderReducer<AlternativeArticles>(SIMILAR_ARTICLES)
      )
    ),
    substitutesArticles: clearStateOnContextChange(
      StateUtils.entityReducer<StateUtils.LoaderState<Substitutes>>(
        SUBSTITUTES_ARTICLES,
        PyStateUtils.loaderReducer<Substitutes>(SUBSTITUTES_ARTICLES)
      )
    ),
    priceCampaignDetails: clearStateOnContextChange(
      StateUtils.entityReducer<StateUtils.LoaderState<SapPriceCampaignDetails>>(
        PRICE_CAMPAIGN_DETAILS,
        PyStateUtils.loaderReducer<SapPriceCampaignDetails>(PRICE_CAMPAIGN_DETAILS)
      )
    ),
    priceCampaigns: clearStateOnContextChange(PyStateUtils.loaderReducer<SapPriceCampaignList>(PRICE_CAMPAIGNS)),
    products: clearStateOnContextChange(
      combineReducers({
        products: productViewReducer(
          PRODUCT,
          StateUtils.entityReducer<StateUtils.LoaderState<Product>>(PRODUCT, PyStateUtils.loaderReducer<Product>(PRODUCT))
        ),
        a4SampleColors: clearStateOnContextChange(
          StateUtils.entityReducer<StateUtils.LoaderState<A4SampleColor[]>>(
            A4_SAMPLE_COLOR,
            PyStateUtils.loaderReducer<A4SampleColor[]>(A4_SAMPLE_COLOR)
          )
        ),
        substituteProducts: clearStateOnContextChange(
          StateUtils.entityReducer<StateUtils.LoaderState<AlternativeProducts>>(
            SUBSTITUTE_PRODUCTS,
            PyStateUtils.loaderReducer<AlternativeProducts>(SUBSTITUTE_PRODUCTS)
          )
        ),
      })
    ),
    categories: clearStateOnContextChange(
      categoryViewReducer(CATEGORY, PyStateUtils.loaderReducer<CategoryEntries>(CATEGORY, categoryReducer))
    ),
    addonGroups: clearStateOnContextChange(PyStateUtils.loaderReducer<AddonGroupEntries>(ADDON_GROUP, addonGroupReducer)),
    addonNodes: clearStateOnContextChange(
      addonNodeViewReducer(
        ADDON_NODE,
        StateUtils.entityReducer<StateUtils.LoaderState<AddonNode>>(ADDON_NODE, PyStateUtils.loaderReducer<AddonNode>(ADDON_NODE))
      )
    ),
    nonSellableProducts: clearStateOnContextChange(
      combineReducers({
        inquiry: PyStateUtils.loaderReducer(NON_SELLABLE_PRODUCT_INQUIRY),
      })
    ),
  };
}

export const reducerToken = new InjectionToken<ActionReducerMap<CatalogState>>('CatalogReducers');

export const reducerProvider: Provider = {
  provide: reducerToken,
  useFactory: getReducers,
};

export function clearStateOnContextChange(reducer: ActionReducer<any>, extraActionTypes: string[] = []): ActionReducer<any> {
  return function (state, action) {
    if (
      action.type === LoginLogoutActionTypes.Login ||
      action.type === LoginLogoutActionTypes.Logout ||
      action.type === SoldToActions.ChangeSoldToActionTypes.ChangeSoldToSuccess ||
      action.type === LanguagesActionTypes.LanguageChange ||
      action.type === CurrenciesActionTypes.CurrencyChange ||
      extraActionTypes?.includes(action.type)
    ) {
      state = undefined;
    }
    // clear state on login or is changesoldtosuccess sent on login after soldto is received?
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [clearStateOnContextChange];

export function categoryViewReducer(
  entityType: string,
  reducer: (
    state: StateUtils.LoaderState<CategoryEntries>,
    action: StateUtils.LoaderAction
  ) => StateUtils.LoaderState<CategoryEntries>
) {
  return (state: CategoryViewState = {}, action: StateUtils.LoaderAction): CategoryViewState => {
    if (action.meta && action.meta.entityType === entityType) {
      switch (action.type) {
        case CategoryActionTypes.LoadCategories:
        case CategoryActionTypes.LoadCategoriesSuccess:
        case CategoryActionTypes.LoadCategoriesFail:
          const categoryAction = <
            CategoryActions.LoadCategories | CategoryActions.LoadCategoriesSuccess | CategoryActions.LoadCategoriesFail
          >action;
          const current = { ...(state[categoryAction.key] ?? PyStateUtils.initialLoaderState) };
          return {
            ...state,
            [categoryAction.key]: reducer(current, categoryAction),
          };
      }
    }
    return state;
  };
}

export function productViewReducer(
  entityType: string,
  reducer: (
    state: StateUtils.EntityState<StateUtils.LoaderState<Product>>,
    action: StateUtils.EntityAction
  ) => StateUtils.EntityState<StateUtils.LoaderState<Product>>
) {
  return (state: ProductViewState = {}, action: StateUtils.EntityAction): ProductViewState => {
    if (action.meta && action.meta.entityType === entityType && action.meta.entityId !== undefined) {
      switch (action.type) {
        case ProductActionTypes.LoadProduct:
        case ProductActionTypes.LoadProductSuccess:
        case ProductActionTypes.LoadProductFail:
          const current = { ...(state[action['key']] ?? StateUtils.initialEntityState) };
          return {
            ...state,
            [action['key']]: reducer(current, action),
          };
      }
    }
    return state;
  };
}

export function addonNodeViewReducer(
  entityType: string,
  reducer: (
    state: StateUtils.EntityState<StateUtils.LoaderState<AddonNode>>,
    action: StateUtils.EntityAction
  ) => StateUtils.EntityState<StateUtils.LoaderState<AddonNode>>
) {
  return (state: AddonNodeViewState = {}, action: StateUtils.EntityAction): AddonNodeViewState => {
    if (action.meta && action.meta.entityType === entityType && action.meta.entityId !== undefined) {
      switch (action.type) {
        case AddonNodeActionTypes.LoadAddonNode:
        case AddonNodeActionTypes.LoadAddonNodeSuccess:
        case ProductActionTypes.LoadProductFail:
          const current = { ...(state[action['addonGroupId']] ?? StateUtils.initialEntityState) };
          return {
            ...state,
            [action['addonGroupId']]: reducer(current, action),
          };
      }
    }
    return state;
  };
}
