<div class="text-left envelope-info" *ngIf="renderFully">
  <button class="link show-envelope-info-link" (click)="showEnvelopeInfo = !showEnvelopeInfo">
    <span class="mr-2">{{ 'catalog.viewEnvelopeWindowPlacement_action' | cxTranslate }}</span>
    <i nz-icon [nzType]="showEnvelopeInfo ? 'minus-circle' : 'plus-circle'" nzTheme="outline"></i>
  </button>
  <ng-container *ngIf="showEnvelopeInfo" [ngTemplateOutlet]="envelopeInfoTemplate"></ng-container>
</div>

<!-- Placeholder elements are used to preserve the height of the component, due to "renderFully=false"  -->
<div *ngIf="!renderFully" class="placeholder"></div>

<ng-template #envelopeInfoTemplate>
  <div class="d-flex pt-2 pb-2 w-100">
    <div class="mr-4">
      <img
        [src]="article.envelopeData.imageUrl | mediaUrl"
        alt="{{ 'catalog.envelopeWindowPlacement_hint' | cxTranslate }}"
        loading="lazy"
      />
    </div>
    <ul class="w-100">
      <li *ngIf="article.envelopeData.windowInfoDescription">
        <h6 class="d-inline">{{ 'catalog.envelopeWindowType_heading' | cxTranslate }}:</h6>
        <span> {{ article.envelopeData.windowInfoDescription | titlecase }}</span>
      </li>
      <li *ngIf="article.envelopeData.windowPositionDescription">
        <h6 class="d-inline">{{ 'catalog.envelopeWindowPosition_heading' | cxTranslate }}:</h6>
        <span> {{ article.envelopeData.windowPositionDescription | titlecase }}</span>
      </li>
      <li><span>A: </span>{{ article.envelopeData.measurementA }} {{ article.envelopeData.measurementAUnit }}</li>
      <li><span>B: </span>{{ article.envelopeData.measurementB }} {{ article.envelopeData.measurementBUnit }}</li>
      <li><span>C: </span>{{ article.envelopeData.measurementC }} {{ article.envelopeData.measurementCUnit }}</li>
      <li><span>D: </span>{{ article.envelopeData.measurementD }} {{ article.envelopeData.measurementDUnit }}</li>
    </ul>
  </div>
</ng-template>
