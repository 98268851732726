import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AuthActions,
  AuthConfigService,
  AuthRedirectStorageService,
  AuthService,
  OCC_USER_ID_CURRENT,
  StateWithProcess,
  WindowRef,
} from '@spartacus/core';
import { LoginOptions, OAuthErrorEvent, ReceivedTokens } from 'angular-oauth2-oidc';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { StateWithClientAuth } from '../../../auth';
import { PunchOutData } from '../../../model';
import { PunchOutFacade } from '../../../user';
import { AuthRedirectService, OAuthLibWrapperService } from '../services';
import { UserIdService } from './user-id.service';

@Injectable({
  providedIn: 'root',
})
export class AuthWrapperService implements OnDestroy {
  private subscriptions = new Subscription();

  constructor(
    private authService: AuthService,
    protected oAuthLibWrapperService: OAuthLibWrapperService,
    protected userIdService: UserIdService,
    protected store: Store<StateWithClientAuth & StateWithProcess<PunchOutData>>,
    protected authConfigService: AuthConfigService,
    protected authRedirectStorageService: AuthRedirectStorageService,
    protected authRedirectService: AuthRedirectService,
    protected punchOutService: PunchOutFacade,
    private winRef: WindowRef
  ) {
    this.subscriptions.add(
      this.authService.isUserLoggedIn().subscribe((isUserLoggedIn) => {
        if (this.winRef.isBrowser()) {
          // store logged in state in a session cookie so we can decide to client-side render logged-in users
          if (isUserLoggedIn) {
            this.winRef.document.cookie = `pyAuthenticated=true; path=/; SameSite=strict`;
          } else {
            this.winRef.document.cookie = `pyAuthenticated=false; path=/; SameSite=strict; Max-Age=0`;
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Check params in url and if there is an code/token then try to login with those.
   */
  async checkOAuthParamsInUrl(): Promise<void> {
    try {
      await this.oAuthLibWrapperService.tryLogin({
        onTokenReceived: (receivedTokens: ReceivedTokens) => {
          if (receivedTokens.accessToken) {
            this.userIdService.setUserId(OCC_USER_ID_CURRENT);
            this.store.dispatch(new AuthActions.Login());
            this.punchOutService.loadPunchOutData();
            const redirectUrl = this.authConfigService.getOAuthLibConfig()?.redirectUri ?? '/my-page/dashboard';
            this.authRedirectStorageService.setRedirectUrl(redirectUrl);
            this.authRedirectService.redirect();
          }
        },
      } as LoginOptions);
    } catch {}
  }

  /**
   * Delegates to AuthService.loginWithCredentials, except it does not hide errors.
   * @param userId
   * @param password
   */
  async loginWithCredentials(userId: string, password: string): Promise<void> {
    return new Promise((resolve, reject) => {
      let errorEvent: OAuthErrorEvent | undefined;
      const subscription = this.oAuthLibWrapperService.events$
        .pipe(
          filter((event) => event instanceof OAuthErrorEvent),
          take(1)
        )
        .subscribe((event) => {
          errorEvent = event as OAuthErrorEvent;
        });

      this.authService
        .loginWithCredentials(userId, password)
        .then(() => (errorEvent ? reject(errorEvent.reason) : resolve()))
        .finally(() => subscription.unsubscribe());
    });
  }

  /**
   * Loads a new user token with Resource Owner Password Flow without sending actions or redirecting.
   * @param userId
   * @param password
   */
  async silentAuthorize(userId: string, password: string): Promise<void> {
    try {
      await this.oAuthLibWrapperService.authorizeWithPasswordFlow(userId, password);
    } catch {}
  }
}
