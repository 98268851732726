import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import { Observable, Subscription, asyncScheduler, fromEvent } from 'rxjs';
import { debounceTime, shareReplay, startWith, take, throttleTime } from 'rxjs/operators';
import { CartType, SolrResultEntityRef, SubstituteRef, SubstituteType } from '../../../../core/model';
import { PrincipalConfigurationService } from '../../../../core/user';
import { WindowRef } from '../../../../core/window';
import { CatalogSearchService } from '../../../../features/catalog/container/catalog/catalog-search.service';
import { ArticleListItemsVisibilityService } from '../../../services/article-list-items-visibility/article-list-items-visibility.service';

@Component({
  selector: 'py-catalog-article-list',
  templateUrl: './catalog-article-list.component.html',
  styleUrls: ['./catalog-article-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogArticleListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren('catalogArticleListComponent', { read: ElementRef }) catalogArticleListComponents: QueryList<ElementRef>;
  private _articleResultRefs: SolrResultEntityRef[];
  private subscription = new Subscription();

  @Input() showHeader? = true;
  @Input() showDiscontinuedArticleHeader? = false;
  @Input() expandedByDefault? = false;
  @Input() partialRenderingEnabled = false;
  @Input() cartType?: CartType;
  @Input() additionalArticleActions?: TemplateRef<any>;
  @Input() hideAddToShoppingListButton = false;
  @Input() showConsignmentStockLevel = false;
  @Input() useArticleCartTypeInsteadOfGivenToAddToCart = false;
  @Input() set articleRefs(articleRefs: string[]) {
    this._articleResultRefs = articleRefs?.map((articleRef) => ({ ref: articleRef, ts: '', score: 0 }));

    if (this.partialRenderingEnabled && this.windowRef.isBrowser()) {
      this.articleListItemsVisibilityService.updateFullyRenderableArticleListItemsMap(
        this.catalogArticleListComponents.toArray(),
        articleRefs
      );
    }
  }

  @Input() set articleResultRefs(articleResultRefs: SolrResultEntityRef[]) {
    this._articleResultRefs = articleResultRefs;

    if (this.partialRenderingEnabled && this.windowRef.isBrowser()) {
      this.articleListItemsVisibilityService.updateFullyRenderableArticleListItemsMap(
        this.catalogArticleListComponents.toArray(),
        articleResultRefs?.map((articleResultRef) => articleResultRef.ref)
      );
    }
  }
  @Input() freeTextSearch?: string;

  get articleResultRefs() {
    return this._articleResultRefs;
  }

  @Input() substituteRefs: SubstituteRef[];

  @Input() queryParams: {
    [k: string]: any;
  };

  @Input() showAttributesInSeparateColumn = false;

  enableSecondaryArticleRowVariant$: Observable<boolean>;

  constructor(
    private windowRef: WindowRef,
    private articleListItemsVisibilityService: ArticleListItemsVisibilityService,
    private catalogSearchService: CatalogSearchService,
    private principalConfigurationService: PrincipalConfigurationService
  ) {}

  ngOnInit(): void {
    if (this.partialRenderingEnabled && this.windowRef.isBrowser()) {
      // Subscription that handles calling articleListItemsVisibilityService when scrolling manually
      this.subscription.add(
        fromEvent(this.windowRef.nativeWindow, 'scroll')
          .pipe(throttleTime(100, asyncScheduler, { leading: true, trailing: true }))
          .subscribe(() => {
            this.articleListItemsVisibilityService.updateFullyRenderableArticleListItemsMap(
              this.catalogArticleListComponents.toArray(),
              this.articleResultRefs?.map((articleResultRef) => articleResultRef.ref)
            );
          })
      );
    }

    this.enableSecondaryArticleRowVariant$ = this.principalConfigurationService
      .isEnabled('enableSecondaryArticleRowVariant')
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  ngAfterViewInit(): void {
    if (this.partialRenderingEnabled && this.windowRef.isBrowser()) {
      // Subscription that handles calling articleListItemsVisibilityService initially when component is rendered
      // and when article list was scrolled automatically to previous position (after going back to the list e.g. from PDP)
      this.subscription.add(
        this.catalogSearchService.wasScrolledToPreviousPosition$
          .pipe(startWith(true), debounceTime(100), take(1))
          .subscribe(() => {
            this.articleListItemsVisibilityService.updateFullyRenderableArticleListItemsMap(
              this.catalogArticleListComponents.toArray(),
              this.articleResultRefs?.map((articleResultRef) => articleResultRef.ref)
            );
          })
      );
    }
  }

  getSubstituteBadgeType(substituteCode: string): SubstituteType {
    return this.substituteRefs?.find((substitute) => substitute.code === substituteCode)?.substituteType;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
