import { MemoizedSelector, createSelector } from '@ngrx/store';
import { StateUtils, StateWithProcess } from '@spartacus/core';
import { LoaderError } from '../../../model';
import { PyStateUtils } from '../../../state/utils';
import { loaderErrorDetailsSelector } from '../../../state/utils/loader/loader.selectors';
import { getProcessState } from './feature.selector';

export function getProcessStateFactory<T>(processId: string): MemoizedSelector<StateWithProcess<T>, StateUtils.LoaderState<T>> {
  return createSelector(getProcessState(), (entityState) => PyStateUtils.entityLoaderStateSelector(entityState, processId));
}

export function getProcessLoadingFactory<T>(processId: string): MemoizedSelector<StateWithProcess<T>, boolean> {
  return createSelector(getProcessStateFactory(processId), (loaderState) => StateUtils.loaderLoadingSelector(loaderState));
}

export function getProcessSuccessFactory<T>(processId: string): MemoizedSelector<StateWithProcess<T>, boolean> {
  return createSelector(getProcessStateFactory(processId), (loaderState) => StateUtils.loaderSuccessSelector(loaderState));
}

export function getProcessErrorFactory<T>(processId: string): MemoizedSelector<StateWithProcess<T>, LoaderError | undefined> {
  return createSelector(getProcessStateFactory(processId), (loaderState) => loaderErrorDetailsSelector(loaderState));
}
