import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Option } from './../dropdown/dropdown.component';

@Component({
  selector: 'py-show-per-page',
  templateUrl: './show-per-page.component.html',
  styleUrls: ['./show-per-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowPerPageComponent {
  @Input() controlName: string;
  @Input() formGroup: UntypedFormGroup;
  @Input() basicOptionsMode = false;
  @Input() options: Option[];

  basicOptions: Option[] = [
    { label: '10', value: '10' },
    { label: '25', value: '25' },
  ];

  standardOptions: Option[] = [
    { label: '10', value: '10' },
    { label: '50', value: '50' },
    { label: '250', value: '250' },
    { label: '500', value: '500' },
    { label: '1000', value: '1000' },
  ];
}
