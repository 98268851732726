import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ChangeSoldToActionTypes } from '../../../../../features/sold-to-base/store/actions/sold-to-change.action';
import { GlobalMessageActions, GlobalMessageType, isErrorGloballyHandled } from '../../../../global-message';
import { normalizeHttpError, withdrawOn } from '../../../../util';
import { AppActions } from '../../../../util/store/actions';
import { NonSellableProductConnector } from '../../connector/non-sellable-product.connector';
import { NonSellableProductActions } from '../actions';
import { NonSellableProductActionTypes } from '../actions/non-sellable-product.action';

@Injectable()
export class NonSellableProductEffects {
  private contextChange$ = this.actions$.pipe(ofType(ChangeSoldToActionTypes.ChangeSoldToSuccess));

  sendNonSellableProductInquiry$: Observable<
    NonSellableProductActions.SendNonSellableProductInquirySuccess | NonSellableProductActions.SendNonSellableProductInquiryFail
  > = createEffect(() =>
    this.actions$.pipe(
      ofType<NonSellableProductActions.SendNonSellableProductInquiry>(
        NonSellableProductActionTypes.SendNonSellableProductInquiry
      ),
      mergeMap((action) =>
        this.nonSellableProductConnector.sendNonSellableProductInquiry(action.inquiryFormData, action.userId).pipe(
          map(() => new NonSellableProductActions.SendNonSellableProductInquirySuccess(action.inquiryFormData)),
          catchError((error) => of(new NonSellableProductActions.SendNonSellableProductInquiryFail(normalizeHttpError(error))))
        )
      ),
      withdrawOn(this.contextChange$)
    )
  );

  handleSuccessForSendNonSellableProductInquiry$: Observable<GlobalMessageActions.AddMessage> = createEffect(() =>
    this.actions$.pipe(
      ofType(NonSellableProductActionTypes.SendNonSellableProductInquirySuccess),
      map((_action: NonSellableProductActions.SendNonSellableProductInquirySuccess) => {
        return new GlobalMessageActions.AddMessage({
          text: {
            key: 'product.inquiry.modal.sendSuccess_message',
          },
          type: GlobalMessageType.MSG_TYPE_CONFIRMATION,
        });
      })
    )
  );

  handleFailureForSendNonSellableProductInquiry$: Observable<GlobalMessageActions.AddMessage | AppActions.NoOpAction> =
    createEffect(() =>
      this.actions$.pipe(
        ofType(NonSellableProductActionTypes.SendNonSellableProductInquiryFail),
        map((action: NonSellableProductActions.SendNonSellableProductInquiryFail) => {
          if (!isErrorGloballyHandled(action.error?.status)) {
            return new GlobalMessageActions.AddMessage({
              text: {
                key: 'product.inquiry.modal.sendFailure_message',
              },
              type: GlobalMessageType.MSG_TYPE_ERROR,
            });
          } else {
            return new AppActions.NoOpAction();
          }
        })
      )
    );

  constructor(private actions$: Actions, private nonSellableProductConnector: NonSellableProductConnector) {}
}
