import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'includes' })
export class IncludesPipe implements PipeTransform {
  transform(value: string[], key: string) {
    if (!value?.length || !key) {
      return false;
    }

    return value.includes(key);
  }
}
