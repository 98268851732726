import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { BackToTopComponent } from './back-to-top.component';

@NgModule({
  declarations: [BackToTopComponent],
  imports: [CommonModule, NzIconModule],
  exports: [BackToTopComponent],
})
export class BackToTopModule {}
