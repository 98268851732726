import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AuthService, AuthGuard as CxAuthGuard } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SemanticPathService } from '../../../routing/configurable-routes/url-translation/semantic-path.service';
import { AuthRedirectService } from '../services/auth-redirect.service';

/**
 * Checks if there is currently logged in user.
 * Use to protect pages dedicated only for logged in users.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends CxAuthGuard {
  constructor(
    protected authService: AuthService,
    protected authRedirectService: AuthRedirectService,
    protected router: Router,
    protected semanticPathService: SemanticPathService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    super(authService, authRedirectService, router, semanticPathService);
  }

  canActivate(): Observable<boolean | UrlTree> {
    return this.authService.isUserLoggedIn().pipe(
      map((isLoggedIn) => {
        if (!isLoggedIn) {
          this.authRedirectService.saveCurrentNavigationUrl();
          if (isPlatformServer(this.platformId)) {
            return this.router.parseUrl(this.semanticPathService.get('loading'));
          } else {
            return this.router.parseUrl(this.semanticPathService.get('login'));
          }
        }
        return isLoggedIn;
      })
    );
  }
}
