import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Article, ArticleCarouselActionMode, ArticleCarouselItem } from '../../../core/model';

@Component({
  selector: 'py-order-entry-similar-articles',
  templateUrl: './order-entry-similar-articles.component.html',
  styleUrls: ['./order-entry-similar-articles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderEntrySimilarArticlesComponent implements OnInit {
  carouselItems: ArticleCarouselItem[];
  _articles?: Article[];

  @Input() set articles(articles: Article[]) {
    this._articles = articles;
    this.carouselItems = articles?.map((article) => ({ article } as ArticleCarouselItem));
  }

  @Input() loading = false;
  @Input() action: string;
  @Input() enableSecondaryArticleRowVariant = false;
  @Input() showNotification = true;
  @Input() showTitle = true;
  @Input() showPaginationAtTheBottom = false;
  @Input() showAlternativeArticleBadge = false;
  @Input() useDefaultArticleQuantityAndUnit = false;
  @Input() carouselActionMode?: ArticleCarouselActionMode;

  @Output() delete = new EventEmitter<void>();
  @Output() replace = new EventEmitter<Article>();

  canDelete = true;
  canReplace = true;

  get articles(): Article[] {
    return this._articles;
  }

  get hasArticles(): boolean {
    return this.articles?.length > 0;
  }

  constructor() {}

  ngOnInit(): void {}

  onDelete(): void {
    this.delete.emit();
    this.canDelete = false;
    this.canReplace = false;
  }

  onReplace(carouselItem: ArticleCarouselItem): void {
    this.replace.emit(carouselItem.article);
    this.canReplace = false;
  }
}
