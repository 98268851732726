<py-cart-message-modal-header
  [title]="'cart.remainingCartMessage_title' | cxTranslate"
  (closeModal)="skip()"
></py-cart-message-modal-header>

<py-notification
  class="mt-3 notification-modal w-100"
  type="info"
  [tiny]="true"
  [showBorder]="false"
  [message]="'cart.remainingCartMessage_text' | cxTranslate"
></py-notification>

<div class="message-body">
  <div class="row">
    <div class="col-12 col-md-6 pr-md-1">
      <button class="btn btn-plain-secondary w-100" (click)="skip()">
        {{ 'cart.remainingCartMessageSkip_action' | cxTranslate }}
      </button>
    </div>

    <div class="col-12 col-md-6 pl-md-1 mt-2 mt-md-0">
      <button
        class="btn btn-primary w-100"
        [routerLink]="{ cxRoute: 'cart' } | cxUrl"
        [queryParams]="queryParams"
        (click)="skip()"
      >
        {{ 'cart.remainingCartMessageGoToCart_action' | cxTranslate }}
      </button>
    </div>
  </div>
</div>
