import { StateUtils } from '@spartacus/core';
import { PyStateUtils } from '../../../state/utils';
import { initialLoaderState } from '../loader/loader.reducer';
import { hasPendingProcessesSelector, isStableSelector } from '../processes-loader/processes-loader.selectors';

const initialProcessesLoaderState = {
  ...initialLoaderState,
  ...StateUtils.initialProcessesState,
};

export function entityHasPendingProcessesSelector<T>(state: StateUtils.EntityProcessesLoaderState<T>, id: string): boolean {
  const entityState = PyStateUtils.entityLoaderStateSelector(state, id);
  return hasPendingProcessesSelector(entityState);
}

export function entityIsStableSelector<T>(state: StateUtils.EntityProcessesLoaderState<T>, id: string): boolean {
  const entityState = PyStateUtils.entityLoaderStateSelector(state, id);
  return isStableSelector(entityState);
}

export function entityProcessesLoaderStateSelector<T>(
  state: StateUtils.EntityProcessesLoaderState<T>,
  id: string
): StateUtils.ProcessesLoaderState<T> {
  return state.entities[id] || initialProcessesLoaderState;
}
