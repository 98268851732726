import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { normalizeHttpError } from '../../../util';
import { SiteConnector } from '../../connectors/site.connector';
import { BaseSiteActionTypes } from '../actions/base-site.action';
import { SiteContextActions } from '../actions/index';

@Injectable()
export class BaseSiteEffects {
  loadBaseSite$: Observable<SiteContextActions.LoadBaseSiteSuccess | SiteContextActions.LoadBaseSiteFail> = createEffect(() =>
    this.actions$.pipe(
      ofType(BaseSiteActionTypes.LoadBaseSite),
      exhaustMap(() => {
        return this.siteConnector.getBaseSite().pipe(
          map((baseSite) => new SiteContextActions.LoadBaseSiteSuccess(baseSite)),
          catchError((error) => of(new SiteContextActions.LoadBaseSiteFail(normalizeHttpError(error))))
        );
      })
    )
  );

  loadBaseSites$: Observable<SiteContextActions.LoadBaseSitesSuccess | SiteContextActions.LoadBaseSitesFail> = createEffect(() =>
    this.actions$.pipe(
      ofType(BaseSiteActionTypes.LoadBaseSites),
      exhaustMap(() => {
        return this.siteConnector.getBaseSites().pipe(
          map((baseSites) => new SiteContextActions.LoadBaseSitesSuccess(baseSites)),
          catchError((error) => of(new SiteContextActions.LoadBaseSitesFail(normalizeHttpError(error))))
        );
      })
    )
  );

  loadSiteConfig$: Observable<SiteContextActions.LoadSiteConfigSuccess | SiteContextActions.LoadSiteConfigFail> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BaseSiteActionTypes.LoadSiteConfig),
        switchMap(() =>
          this.siteConnector.getSiteConfig().pipe(
            map((siteConfig) => new SiteContextActions.LoadSiteConfigSuccess(siteConfig)),
            catchError((error) => of(new SiteContextActions.LoadSiteConfigFail(normalizeHttpError(error))))
          )
        )
      )
  );

  constructor(private actions$: Actions, private siteConnector: SiteConnector) {}
}
