import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ConsentTemplate } from '../../../../core/model';
import { UserUtilService } from '../../../../core/user/services/user-util.service';

@Component({
  selector: 'py-consent-settings',
  templateUrl: './consent-settings.component.html',
  styleUrls: ['./consent-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsentSettingsComponent implements OnInit {
  @Input() consentTemplates: ConsentTemplate[];
  @Input() showCloseButton: boolean;

  @Output() showBanner = new EventEmitter<void>();
  @Output() saveSettings = new EventEmitter<{ [key: string]: { consentGiven: boolean; template: ConsentTemplate } }>();
  @Output() hideSettings = new EventEmitter<void>();

  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private userUtilService: UserUtilService) {}

  ngOnInit(): void {
    const consents = {};
    this.consentTemplates.forEach((consentTemplate) => {
      consents[consentTemplate.id] = [this.isConsentGiven(consentTemplate.id)];
    });

    this.form = this.fb.group(consents);

    Object.keys(this.form.controls).forEach((id) => {
      if (this.isRequiredConsent(id)) {
        this.form.controls[id].disable();
      }
    });
  }

  isConsentGiven(consentTemplateId: string) {
    const consent = this.consentTemplates.find((template) => template.id === consentTemplateId)?.currentConsent;
    const isConsentGiven = this.userUtilService.isConsentGiven(consent);

    return isConsentGiven !== undefined ? isConsentGiven : false;
  }

  onBack(): void {
    this.showBanner.emit();
  }

  onDone(): void {
    this.saveSettings.emit(
      Object.keys(this.form.value).reduce(
        (settings, key) => ({
          ...settings,
          [key]: { consentGiven: this.form.value[key], template: this.consentTemplates.find((template) => template.id === key) },
        }),
        {}
      )
    );
  }

  onClose(): void {
    this.hideSettings.emit();
  }

  isRequiredConsent(consentTemplateId: string): boolean {
    return this.userUtilService.isConsentRequired(consentTemplateId);
  }
}
