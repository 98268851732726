import { Action } from '@ngrx/store';
import { PaginationState, SearchPageResultState } from '../../../features/store/base-feature-state';
import { SearchPaginationUtil } from '../../../features/store/util/search-pagination.util';
import { Pagination, WritableShoppingListSearchResult } from '../../../model';
import { loaderReducer } from '../../../state/utils/loader';
import { UserActions } from '../actions';
import { ShoppingListActionTypes } from '../actions/shopping-list.action';
import { WRITABLE_SHOPPING_LIST } from '../user-state';
import { reducer as writableShoppingListEntryReducer } from './writable-shopping-list-entries.reducer';

export const initialSearchState: PaginationState<SearchPageResultState> = {
  lastUpdateTime: undefined,
  pages: {},
  pagination: undefined,
  sorts: [],
};

export const WRITABLE_SHOPPING_LIST_PAGE_SIZE = 9;
export const singleResultPagination: Pagination = {
  count: WRITABLE_SHOPPING_LIST_PAGE_SIZE,
  hasNext: false,
  hasPrevious: false,
  page: 0,
  totalCount: 1,
  totalPages: 0,
};

export function reducer(state: PaginationState<SearchPageResultState> = initialSearchState, action: Action) {
  switch (action.type) {
    case ShoppingListActionTypes.SearchWritableShoppingLists:
    case ShoppingListActionTypes.SearchWritableShoppingListsFail: {
      const _action = <UserActions.SearchWritableShoppingLists>action;
      const searchParams = _action.payload.searchParams;
      const loaderState = loaderReducer<SearchPageResultState>(WRITABLE_SHOPPING_LIST, writableShoppingListEntryReducer)(
        undefined,
        _action
      );
      return {
        ...state,
        pages: {
          ...state.pages,
          [searchParams.page]: loaderState,
        },
      };
    }
    case ShoppingListActionTypes.SearchWritableShoppingListsSuccess: {
      const _action = <UserActions.SearchWritableShoppingListsSuccess>action;
      const payload: WritableShoppingListSearchResult = _action.payload.searchResult;
      const searchParams = _action.searchParams;
      const loaderState = loaderReducer<SearchPageResultState>(WRITABLE_SHOPPING_LIST, writableShoppingListEntryReducer)(
        { ...state.pages[searchParams.page] },
        _action
      );
      return {
        ...state,
        lastUpdateTime: new Date().toISOString(),
        pagination: payload.pagination,
        sorts: payload.sorts,
        pages: {
          ...state.pages,
          [searchParams.page]: loaderState,
        },
      };
    }
    case ShoppingListActionTypes.DeleteShoppingListSuccess: {
      const removeAction = <UserActions.DeleteShoppingListSuccess>action;
      const id = [].concat(removeAction.payload.code).join();
      return SearchPaginationUtil.popEntry(id, state);
    }
  }

  return state;
}
