import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { LaunchDialogService } from '@spartacus/storefront';
import { Subject, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { BaseAbstractModalComponent, ModalCloseReason } from '../../../../core/modal';
import { CustomerUploadedMedia, FilePreview, FileUploadEvent } from '../../../../core/model';
import { OrderReturnFacade } from '../../../../features/order-base';
import { MediaService } from '../../media';

export interface OrderReturnUploadedFilesGalleryModalData {
  isReturnModeActive: boolean;
  uploadedFiles: CustomerUploadedMedia[];
  initialActiveSlideIndex: number;
  filesMap?: Map<string, FilePreview>;
  sapOrderNumber?: string;
  itemNumber?: number;
  maxFilesNumber?: number;
  uploadFile$?: Subject<FileUploadEvent>;
  removeFile$?: Subject<string>;
}

@Component({
  selector: 'py-order-return-uploaded-files-gallery-modal',
  templateUrl: './order-return-uploaded-files-gallery-modal.component.html',
  styleUrls: ['./order-return-uploaded-files-gallery-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderReturnUploadedFilesGalleryModalComponent
  extends BaseAbstractModalComponent
  implements OnInit, OnDestroy, AfterViewInit, OnDestroy
{
  @ViewChild('ngbcarousel', { static: false }) ngbcarousel: NgbCarousel;

  private subscriptions = new Subscription();
  private _uploadedFiles: CustomerUploadedMedia[];
  private _initialActiveSlideIndex: number;

  set uploadedFiles(files: CustomerUploadedMedia[]) {
    this._uploadedFiles = files;
    this.cd.markForCheck();
  }

  set initialActiveSlideIndex(index: number) {
    this.activeSlideId = index;
    this._initialActiveSlideIndex = index;
  }

  isReturnModeActive: boolean;
  filesMap?: Map<string, FilePreview>;
  sapOrderNumber?: string;
  itemNumber?: number;
  maxFilesNumber?: number;
  uploadFile$?: Subject<FileUploadEvent>;
  removeFile$?: Subject<string>;

  get uploadedFiles(): CustomerUploadedMedia[] {
    return this._uploadedFiles;
  }

  get initialActiveSlideIndex(): number {
    return this._initialActiveSlideIndex;
  }

  activeSlideId = 0;

  constructor(
    private orderReturnService: OrderReturnFacade,
    private mediaService: MediaService,
    private cd: ChangeDetectorRef,
    protected launchDialogService: LaunchDialogService,
    protected el: ElementRef,
    protected renderer: Renderer2
  ) {
    super(el, launchDialogService, renderer);
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.launchDialogService.data$.pipe(take(1)).subscribe((data: OrderReturnUploadedFilesGalleryModalData) => {
        this.isReturnModeActive = data.isReturnModeActive;
        this.uploadedFiles = data.uploadedFiles;
        this.initialActiveSlideIndex = data.initialActiveSlideIndex;

        if (data.filesMap !== undefined) {
          this.filesMap = data.filesMap;
        }

        if (data.sapOrderNumber !== undefined) {
          this.sapOrderNumber = data.sapOrderNumber;
        }

        if (data.itemNumber !== undefined) {
          this.itemNumber = data.itemNumber;
        }

        if (data.maxFilesNumber !== undefined) {
          this.maxFilesNumber = data.maxFilesNumber;
        }

        if (data.uploadFile$ !== undefined) {
          this.uploadFile$ = data.uploadFile$;
        }

        if (data.removeFile$ !== undefined) {
          this.removeFile$ = data.removeFile$;
        }
      })
    );
  }

  ngAfterViewInit(): void {
    if (this.ngbcarousel && this.initialActiveSlideIndex) {
      this.navigateToSlide(this.initialActiveSlideIndex);
    }
  }

  navigateToSlide(slideId: number): void {
    this.ngbcarousel.select(slideId.toString());
    this.activeSlideId = slideId;
  }

  getFilePreview(qualifier: string): FilePreview {
    return this.filesMap?.get(qualifier);
  }

  hasImageType(type: string): boolean {
    return type.startsWith('image/');
  }

  hasPdfType(fileName: string): boolean {
    return fileName?.split('.').pop() === 'pdf';
  }

  getFileAbsoluteUrl(url: string): string {
    return this.mediaService.resolveAbsoluteUrl(url);
  }

  onFileUpload(event: any): void {
    const file: File = event.target.files[0];

    if (!file) {
      return;
    }

    this.subscriptions.add(
      this.orderReturnService
        .uploadFileForOrderReturnDraftEntry(this.sapOrderNumber, this.itemNumber, file)
        .pipe(
          tap((uploadedFile) => {
            const qualifier = uploadedFile.qualifier;

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              const fileUrl = reader.result as string;

              if (!file.type.includes('tiff')) {
                this.filesMap.set(qualifier, { file: file, fileUrl: fileUrl });
              }

              this.uploadedFiles = [...this.uploadedFiles, { ...uploadedFile }];

              this.cd.detectChanges();

              this.activeSlideId = this.uploadedFiles.length - 1;
              this.navigateToSlide(this.uploadedFiles.length - 1);

              this.uploadFile$.next({ uploadedFile, fileUrl, file });
            };
          })
        )
        .subscribe()
    );
  }

  onFileRemove(qualifier: string): void {
    const uploadedFiles: CustomerUploadedMedia[] = [...this.uploadedFiles];
    const fileIndex = uploadedFiles.findIndex((file) => file.qualifier === qualifier);

    if (fileIndex !== -1) {
      uploadedFiles.splice(fileIndex, 1);
      this.uploadedFiles = uploadedFiles;

      this.filesMap.delete(qualifier);
      this.removeFile$.next(qualifier);

      uploadedFiles.length === 0
        ? this.launchDialogService.closeDialog(ModalCloseReason.ORDER_RETURN_UPLOADED_FILES_GALLERY_CLOSED_ON_LAST_FILE_REMOVED)
        : (this.activeSlideId = 0);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
