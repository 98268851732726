import { StateUtils } from '@spartacus/core';
import { UserActions } from '../actions';
import { LoadStockInfoDetailsSuccess } from '../actions/stock-info.action';
import { StockInfoDetailsState } from '../user-state';

export const initialState: StockInfoDetailsState = {
  lastUpdateTime: undefined,
  stockInfo: undefined,
};

export function reducer(state = initialState, action: StateUtils.LoaderAction): StockInfoDetailsState {
  switch (action.type) {
    case UserActions.StockInfoActionTypes.LoadStockInfoDetailsSuccess: {
      const a = <LoadStockInfoDetailsSuccess>action;
      return {
        lastUpdateTime: new Date().toISOString(),
        stockInfo: a.payload,
      };
    }
  }
  return state;
}
