import { MemoizedSelector, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { User } from '../../../model';
import { PyStateUtils } from '../../../state';
import { StateWithUser, UserState } from '../user-state';
import { getUserState } from './feature.selector';

export const getUserDetailsLoaderState: MemoizedSelector<StateWithUser, StateUtils.LoaderState<User>> = createSelector(
  getUserState,
  (state: UserState) => state.account.details || PyStateUtils.initialLoaderState
);
