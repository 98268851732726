import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, debounceTime, exhaustMap, map, switchMap } from 'rxjs/operators';
import { ChangeSoldToActionTypes } from '../../../../features/sold-to-base/store/actions/sold-to-change.action';
import { UserIdService } from '../../../auth';
import { LoginLogoutActionTypes } from '../../../auth/user-auth/store/actions/login-logout.action';
import { BaseSiteActionTypes } from '../../../site-context/store/actions/base-site.action';
import { CurrenciesActionTypes } from '../../../site-context/store/actions/currencies.action';
import { LanguagesActionTypes } from '../../../site-context/store/actions/languages.action';
import { normalizeHttpError } from '../../../util';
import { CatalogConnector } from '../../connector/catalog.connector';
import { CatalogActions } from '../actions';
import { LoadCatalogKeysFail, LoadCatalogKeysSuccess } from '../actions/catalog.actions';

@Injectable({ providedIn: 'root' })
export class CatalogEffects {
  triggerLoadOnContextChange$: Observable<CatalogActions.LoadCatalogKeys> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        LoginLogoutActionTypes.Login,
        LoginLogoutActionTypes.Logout,
        ChangeSoldToActionTypes.ChangeSoldToSuccess,
        BaseSiteActionTypes.SetActiveBaseSite,
        LanguagesActionTypes.LanguageChange,
        CurrenciesActionTypes.CurrencyChange
      ),
      debounceTime(0),
      exhaustMap(() =>
        this.userIdService.takeUserId().pipe(
          map((userId) => new CatalogActions.LoadCatalogKeys(userId)),
          catchError(() => EMPTY)
        )
      )
    )
  );

  loadCatalogKeys$: Observable<LoadCatalogKeysSuccess | LoadCatalogKeysFail> = createEffect(() =>
    this.actions$.pipe(
      ofType(CatalogActions.CatalogKeyActionTypes.LoadKeys),
      switchMap((action: CatalogActions.LoadCatalogKeys) =>
        this.connector.loadCatalogKeys(action.userId).pipe(
          map((data) => new CatalogActions.LoadCatalogKeysSuccess(data)),
          catchError((error) => of(new CatalogActions.LoadCatalogKeysFail(normalizeHttpError(error))))
        )
      )
    )
  );

  constructor(private actions$: Actions, private connector: CatalogConnector, private userIdService: UserIdService) {}
}
