import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { I18nModule } from '../../../core/i18n';
import { CancelOrderReturnDraftModalData } from '../../../core/model';
import { OrderReturnFacade } from '../../../features/order-base';
import { MessageBannerModule } from '../../../shared';

@Component({
  selector: 'py-return-order-message',
  templateUrl: './return-order-message.component.html',
  styleUrls: ['./return-order-message.component.scss'],
  standalone: true,
  imports: [CommonModule, I18nModule, NzIconModule, MessageBannerModule],
})
export class ReturnOrderMessageComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  orderReturnDraftCode$: Observable<string>;

  constructor(private orderReturnService: OrderReturnFacade, private launchDialogService: LaunchDialogService) {}

  onCancelOrderReturnDraft(orderCode: string): void {
    this.orderReturnService
      .getOrderReturnDraftEntries()
      .pipe(take(1))
      .subscribe((orderReturnDraftEntries) => {
        orderReturnDraftEntries.length > 0
          ? this.openCancelOrderReturnDraftModal()
          : this.orderReturnService.cancelOrderReturnDraft(orderCode);
      });
  }

  openCancelOrderReturnDraftModal(): void {
    const modalConfirmed$ = new Subject<void>();
    const modalDismissed$ = new Subject<void>();

    const modalData: CancelOrderReturnDraftModalData = {
      modalConfirmed$: modalConfirmed$,
      modalDismissed$: modalDismissed$,
    };

    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.CANCEL_ORDER_RETURN_DRAFT, undefined, modalData);
  }

  ngOnInit(): void {
    this.orderReturnDraftCode$ = this.orderReturnService
      .getOrderReturnDraft()
      .pipe(map((orderReturn) => orderReturn?.sapOrderNumber));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
