import { MemoizedSelector, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { IndexedState } from '../../../features/store/base-feature-state';
import { PrincipalConfiguration } from '../../../model';
import { PyStateUtils } from '../../../state';
import { StateWithUser, UserState } from '../user-state';
import { getUserState } from './feature.selector';

export const getPrincipalConfigurationLoaderState: MemoizedSelector<
  StateWithUser,
  StateUtils.LoaderState<IndexedState<PrincipalConfiguration>>
> = createSelector(
  getUserState,
  (state: UserState) => (state && state.principalConfiguration) || PyStateUtils.initialLoaderState
);

export const getPrincipalConfigurationLoading: MemoizedSelector<StateWithUser, boolean> = createSelector(
  getPrincipalConfigurationLoaderState,
  (state: StateUtils.LoaderState<IndexedState<PrincipalConfiguration>>) => StateUtils.loaderLoadingSelector(state)
);

export const getPrincipalConfigurationLoaded: MemoizedSelector<StateWithUser, boolean> = createSelector(
  getPrincipalConfigurationLoaderState,
  (state: StateUtils.LoaderState<IndexedState<PrincipalConfiguration>>) => StateUtils.loaderSuccessSelector(state)
);

export const getPrincipalConfigurationValueState: MemoizedSelector<
  StateWithUser,
  IndexedState<PrincipalConfiguration>
> = createSelector(getPrincipalConfigurationLoaderState, (state: StateUtils.LoaderState<IndexedState<PrincipalConfiguration>>) =>
  StateUtils.loaderValueSelector(state)
);

export const getPrincipalConfigurationValues: MemoizedSelector<
  StateWithUser,
  { [code: string]: PrincipalConfiguration } | undefined
> = createSelector(
  getPrincipalConfigurationValueState,
  (state: IndexedState<PrincipalConfiguration>) => (state && state.entities) || undefined
);

export const getSelectedPrincipalConfigurationValueFactory = (
  code: string
): MemoizedSelector<StateWithUser, PrincipalConfiguration | undefined> => {
  return createSelector(getPrincipalConfigurationValues, (state) => (state && state[code]) || undefined);
};
