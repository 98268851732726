import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InterceptorUtil } from '@spartacus/core';
import { Observable } from 'rxjs';
import { REMOVE_AUTH_HEADER } from '..';

@Injectable({
  providedIn: 'root',
})
export class RemoveAuthHeaderInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(REMOVE_AUTH_HEADER)) {
      req = InterceptorUtil.removeHeader(REMOVE_AUTH_HEADER, req);
      req = InterceptorUtil.removeHeader('Authorization', req);
    }
    return next.handle(req);
  }
}
