import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ConsentTemplate } from '@spartacus/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { BehaviorSubject, Observable, Subscription, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { TranslationService } from '../../../../core/i18n';
import { ModalCloseReason } from '../../../../core/modal';
import { UserConsentFacade } from '../../../../core/user';
import { UserUtilService } from '../../../../core/user/services/user-util.service';
import { LegalModalData } from '../../../../shared/components/legal-modal/legal-modal.component';

@Component({
  selector: 'py-consent-management',
  templateUrl: './consent-management.component.html',
  styleUrls: ['./consent-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsentManagementComponent implements OnInit, OnDestroy {
  requiredConsentsNotGiven: ConsentTemplate[] = [];
  protected subscription = new Subscription();

  hasRequiredConsentsNotGiven$ = new BehaviorSubject(true);
  bannerVisible$: Observable<boolean> = this.userConsentService.isBannerVisible();
  getConsentsResultSuccess$: Observable<boolean> = this.userConsentService.getConsentsResultSuccess();
  settingsVisible$: Observable<boolean> = this.userConsentService.isSettingsVisible();
  consentTemplates$: Observable<ConsentTemplate[]> = this.userConsentService.getOptionalConsents();

  constructor(
    private userConsentService: UserConsentFacade,
    private launchDialogService: LaunchDialogService,
    private translationService: TranslationService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private userUtilService: UserUtilService
  ) {}

  ngOnInit(): void {
    const navigationEnd$ = this.router.events.pipe(
      filter((routerEvent) => routerEvent instanceof NavigationEnd),
      map((event) => event as NavigationEnd)
    );

    this.subscription.add(
      navigationEnd$
        .pipe(
          switchMap((navigationEnd) =>
            this.userConsentService.getRequiredConsentsNotGiven().pipe(
              startWith([]),
              filter((consentsNotGiven) => {
                if (!!consentsNotGiven && consentsNotGiven?.length > 0) {
                  this.hasRequiredConsentsNotGiven$.next(true);
                  return true;
                }
                this.hasRequiredConsentsNotGiven$.next(false);
                this.cd.detectChanges();
                return false;
              }),
              withLatestFrom(
                this.userConsentService.getConsents().pipe(
                  distinctUntilChanged(),
                  filter((allConsentTemplates) => !!allConsentTemplates?.length),
                  take(1)
                )
              ),
              //Map each required consent to corresponing consent template
              map(([consentsNotGiven, allConsentTemplates]) =>
                allConsentTemplates.filter((consentTemplate) =>
                  consentsNotGiven.find((consentNotGiven) => consentTemplate.id === consentNotGiven.templateId)
                )
              ),
              //If the current page is a legal page with the full version of any required consent not given we dont show the modals. This will allow the user to read the consent whithout first having to accept them.
              filter((consentTemplatesNotGiven) => {
                if (this.isCurrentPageLegal(consentTemplatesNotGiven, navigationEnd)) {
                  this.requiredConsentsNotGiven = [];
                  this.launchDialogService.closeDialog(ModalCloseReason.LEGAL_CLOSED_AUTOMATICALLY_ON_LEGAL_PAGE);
                  this.hasRequiredConsentsNotGiven$.next(false);
                  this.cd.detectChanges();
                  return false;
                }
                return true;
              }),
              //Que one modal for each consent (if it wasent qued already)
              tap((consentTemplatesNotGiven) => {
                consentTemplatesNotGiven
                  .filter((consentTemplateNotGiven) => !this.requiredConsentsNotGiven.find(() => consentTemplateNotGiven.id))
                  .forEach((consent) => {
                    this.queueModal(consent);
                  });
                this.cd.detectChanges();
              })
            )
          )
        )
        .subscribe()
    );
  }

  isCurrentPageLegal(consentTemplatesNotGiven: ConsentTemplate[], navigationEnd: NavigationEnd) {
    return consentTemplatesNotGiven.find((template) => {
      return (
        (template.description?.includes && template.description.includes(decodeURIComponent(navigationEnd.urlAfterRedirects))) ||
        (template.name?.includes && template.name.includes(decodeURIComponent(navigationEnd.urlAfterRedirects)))
      );
    });
  }

  queueModal(template: ConsentTemplate): void {
    if (template) {
      if (this.requiredConsentsNotGiven.length === 0) {
        this.openModal(template);
      }
      this.requiredConsentsNotGiven.push(template);
    }
  }

  openModal(template: ConsentTemplate): void {
    if (!template) {
      return;
    }

    const modalData: LegalModalData = {
      forceConsent: true,
      closeHandler: (data) => {
        this.giveConsent(data);
      },
      component$: combineLatest([
        of(template.changesDescription),
        this.translationService.translate('settings.consents.updatedConsent', { context: template.id + '_title' }),
        of(template),
      ]).pipe(
        map(([changesDescription, title, consentTemplate]) => {
          return {
            content: changesDescription,
            readMore: consentTemplate?.description,
            name: title,
            data: consentTemplate,
          };
        })
      ),
    };

    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.LEGAL_DISMISSABLE_ON_LOGOUT, undefined, modalData);
  }

  giveConsent(template: ConsentTemplate): void {
    this.userConsentService.giveConsent(template.id, template.version);
    this.requiredConsentsNotGiven = this.requiredConsentsNotGiven.filter((t) => t !== template);

    if (this.requiredConsentsNotGiven[0]) {
      this.openModal(this.requiredConsentsNotGiven[0]);
    } else {
      //When there are no more required consents not given, reload the consents to get fresh data for the consent banner
      this.userConsentService.resetConsentsProcessState();
      this.hasRequiredConsentsNotGiven$.next(false);
      this.cd.detectChanges();
    }
  }

  onViewSettings() {
    this.userConsentService.toggleSettingsModalVisible(true);
  }

  onAllowAll(templates: ConsentTemplate[]) {
    templates.forEach((template) => {
      const consentGiven = this.userUtilService.isConsentGiven(template.currentConsent);
      if (!consentGiven) {
        this.userConsentService.giveConsent(template.id, template.version);
      }
    });
    this.userConsentService.toggleBannerVisible(false);
  }

  hideSettings() {
    this.userConsentService.toggleSettingsModalVisible(false);
    this.userConsentService.toggleBannerVisible(false);
  }

  hideSettingsAndShowBanner() {
    this.userConsentService.toggleBannerVisible(true);
    this.userConsentService.toggleSettingsModalVisible(false);
  }

  saveSettings(values: { [key: string]: { consentGiven: boolean; template: ConsentTemplate } }) {
    Object.entries(values).forEach(([key, { consentGiven, template }]) => {
      if (this.userUtilService.isConsentGiven(template.currentConsent)) {
        if (!consentGiven) {
          this.userConsentService.withdrawConsent(template.currentConsent.code);
        }
      } else {
        if (consentGiven) {
          this.userConsentService.giveConsent(key, template?.version);
        } else if (!this.userUtilService.isConsentAcknowledged(template)) {
          this.userConsentService.denyConsent(key, template?.version);
        }
      }
    });
    this.hideSettings();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
