<ng-container *ngIf="{ units: units$ | async } as data">
  <form [formGroup]="form" *ngIf="data.units?.length > 0" class="d-flex">
    <py-counter-input
      #popOver="ngbPopover"
      [class.w-50]="mobile"
      [tooltipContainer]="container"
      [formControlName]="'quantity'"
      [min]="minimumQuantity"
      [minimumOrderIndication]="minimumOrderIndication"
      [minDisabledTooltipText]="
        minDisabledTooltipTextKey
          | cxTranslate
            : { minimumQuantity: minimumQuantity | cxNumeric : '1.0-0', selectedUnit: selectedUnit | unit : minimumQuantity }
      "
      [max]="maximumQuantity"
      [step]="quantityStep"
      [ngbPopover]="popContent"
      [popoverClass]="'minimum-order'"
      [disablePopover]="!minimumOrderIndication"
      [autoClose]="false"
      [isForceInvalid]="popover?.isOpen()"
      triggers="manual"
      placement="bottom"
    ></py-counter-input>
    <py-dropdown
      class="flex-grow-1"
      [class.w-50]="mobile"
      [dropdownContainer]="container"
      [formControlName]="'unitCode'"
      [options]="data.units"
      [unselectableOption]="
        showUnselectableOption &&
        minimumOrderIndication &&
        article && {
          key: 'common.minimumOrderQuantityIndication_hint' | cxTranslate,
          value:
            (article.minimumOrderQuantity | cxNumeric : '1.0-0') + ' ' + (article.units[0] | unit : article.minimumOrderQuantity)
        }
      "
      [title]="'orderCards.unit_heading' | cxTranslate"
    ></py-dropdown>
  </form>
</ng-container>
<ng-template #popContent>
  {{
    minDisabledTooltipTextKey
      | cxTranslate
        : { minimumQuantity: minimumQuantity | cxNumeric : '1.0-0', selectedUnit: selectedUnit | unit : minimumQuantity }
  }}
</ng-template>
