import { StateUtils } from '@spartacus/core';
import { AddonNode } from '../../../../model';
import { PyStateUtils } from '../../../../state';
import { ADDON_NODE } from '../../../store/catalog-state';

export enum AddonNodeActionTypes {
  LoadAddonNode = '[Catalog] Load Addon node',
  LoadAddonNodeSuccess = '[Catalog] Load Addon node Success',
  LoadAddonNodeFail = '[Catalog] Load Addon node Fail',
}

export class LoadAddonNode extends StateUtils.EntityLoadAction {
  readonly type = AddonNodeActionTypes.LoadAddonNode;

  constructor(public userId: string, public addonGroupId: string, public addonNodeId: string, public sort: string) {
    super(ADDON_NODE, addonNodeId);
  }
}

export class LoadAddonNodeSuccess extends StateUtils.EntitySuccessAction {
  readonly type = AddonNodeActionTypes.LoadAddonNodeSuccess;

  constructor(public payload: AddonNode, public addonGroupId: string, public addonNodeId: string) {
    super(ADDON_NODE, payload.id, payload);
  }
}

export class LoadAddonNodeFail extends PyStateUtils.EntityFailAction {
  readonly type = AddonNodeActionTypes.LoadAddonNodeFail;

  constructor(public payload: any, public addonGroupId: string, public addonNodeId: string) {
    super(ADDON_NODE, addonNodeId, payload);
  }
}

export type AddonNodeActions = LoadAddonNode | LoadAddonNodeSuccess | LoadAddonNodeFail;
