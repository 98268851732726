import { MemoizedSelector, MemoizedSelectorWithProps, createSelector } from '@ngrx/store';
import { StateUtils, StateWithProcess } from '@spartacus/core';
import {
  EntriesState,
  PaginationState,
  SearchPageResultState,
  SearchParamsState,
  SearchState,
} from '../../../features/store/base-feature-state';
import {
  B2BComment,
  CartSimulation,
  LoaderError,
  Order,
  OrderApproval,
  OrderDetails,
  OrderProcessStatus,
  Pagination,
  SimpleCustomer,
  Sort,
} from '../../../model';
import { ProcessSelectors } from '../../../process/store';
import { PyStateUtils } from '../../../state';
import {
  APPROVED_ORDER_APPROVAL_DETAILS_PROCESS,
  AvailableBuyerState,
  AvailableSearchParamsState,
  B2B_COMMENTS_PROCESS,
  ORDER_APPROVAL_ABORT_HIJACK_PROCESS,
  ORDER_APPROVAL_DELETE_PROCESS,
  ORDER_APPROVAL_DETAILS_PROCESS,
  ORDER_APPROVAL_MAKE_DECISION_PROCESS,
  ORDER_APPROVAL_PROCESS,
  ORDER_APPROVAL_PROCESSING_PROCESS,
  ORDER_APPROVAL_RECOVER_PROCESS,
  ORDER_APPROVAL_SIMULATION_PROCESS,
  OrderApprovalsAdminState,
  OrderApprovalsState,
  StateWithUser,
  UserState,
} from '../user-state';
import { ProcessIdBuilder } from '../util/process-id-builder';
import { getUserState } from './feature.selector';

export const getOrderApprovalState: MemoizedSelector<StateWithUser, OrderApprovalsState> = createSelector(
  getUserState,
  (state: UserState) => state.orderApproval
);

export const getSearchResultEntities: MemoizedSelector<StateWithUser, EntriesState<OrderApproval>> = createSelector(
  getOrderApprovalState,
  (state: OrderApprovalsState) => state && state.entities
);

export const getSearchParamsSelector: MemoizedSelector<StateWithUser, SearchParamsState> = createSelector(
  getOrderApprovalState,
  (state: OrderApprovalsState) => state && state.searchParams
);

export const getSearchSelector: MemoizedSelector<StateWithUser, SearchState<SearchPageResultState>> = createSelector(
  getOrderApprovalState,
  (state: OrderApprovalsState) => state && state.search
);

export const getSearchPaginationStateSelector: MemoizedSelector<
  StateWithUser,
  PaginationState<SearchPageResultState>
> = createSelector(
  getSearchSelector,
  getSearchParamsSelector,
  (state: SearchState<SearchPageResultState>, searchParams: SearchParamsState) => {
    return searchParams && state && state[searchParams.key];
  }
);

export const getSearchPaginationSelector: MemoizedSelector<StateWithUser, Pagination> = createSelector(
  getSearchPaginationStateSelector,
  (state) => state && state.pagination
);

export const getSearchPageResultsSelector: MemoizedSelector<
  StateWithUser,
  StateUtils.LoaderState<SearchPageResultState>
> = createSelector(
  getSearchParamsSelector,
  getSearchPaginationStateSelector,
  (queryParams: SearchParamsState, paginationState: PaginationState<SearchPageResultState>) => {
    return queryParams && paginationState && paginationState.pages && paginationState.pages[queryParams.page];
  }
);

export const getSearchResultEntries: MemoizedSelectorWithProps<StateWithUser, any, OrderApproval[]> = createSelector(
  getSearchResultEntities,
  (entries: EntriesState<OrderApproval>, props) => {
    return props.keys && entries && props.keys.map((i) => entries[i]);
  }
);

export const getSearchResultEntry: MemoizedSelectorWithProps<StateWithUser, any, OrderApproval> = createSelector(
  getSearchResultEntities,
  (entries: EntriesState<OrderApproval>, props) => {
    return props.key && entries && entries[props.key];
  }
);

export const getSearchPageResultsLoading: MemoizedSelector<StateWithUser, boolean> = createSelector(
  getSearchPageResultsSelector,
  (loaderState) => (loaderState && StateUtils.loaderLoadingSelector(loaderState)) || false
);

export const getApprovedOrderApprovalDetails = (
  orderApprovalCode: string
): MemoizedSelector<StateWithProcess<OrderDetails>, OrderDetails> =>
  createSelector(
    ProcessSelectors.getProcessStateFactory(
      ProcessIdBuilder.generateId(APPROVED_ORDER_APPROVAL_DETAILS_PROCESS, orderApprovalCode)
    ),
    (state: StateUtils.LoaderState<OrderDetails>) => {
      return StateUtils.loaderValueSelector(state);
    }
  );

export const getApprovedOrderApprovalDetailsLoading = (
  sapOrderNumber: string
): MemoizedSelector<StateWithProcess<any>, boolean> =>
  ProcessSelectors.getProcessLoadingFactory(ProcessIdBuilder.generateId(APPROVED_ORDER_APPROVAL_DETAILS_PROCESS, sapOrderNumber));

export const getApprovedOrderApprovalDetailsError = (
  sapOrderNumber: string
): MemoizedSelector<StateWithProcess<any>, LoaderError | undefined> =>
  ProcessSelectors.getProcessErrorFactory(ProcessIdBuilder.generateId(APPROVED_ORDER_APPROVAL_DETAILS_PROCESS, sapOrderNumber));

export const getApprovedOrderApprovalDetailsSuccess = (
  sapOrderNumber: string
): MemoizedSelector<StateWithProcess<any>, boolean> =>
  ProcessSelectors.getProcessSuccessFactory(ProcessIdBuilder.generateId(APPROVED_ORDER_APPROVAL_DETAILS_PROCESS, sapOrderNumber));

export const getOrderApprovalRecoveryLoading = (orderApprovalCode: string): MemoizedSelector<StateWithProcess<any>, boolean> =>
  ProcessSelectors.getProcessLoadingFactory(ProcessIdBuilder.generateId(ORDER_APPROVAL_RECOVER_PROCESS, orderApprovalCode));

export const getOrderApprovalRecoveryError = (
  orderApprovalCode: string
): MemoizedSelector<StateWithProcess<any>, LoaderError | undefined> =>
  ProcessSelectors.getProcessErrorFactory(ProcessIdBuilder.generateId(ORDER_APPROVAL_RECOVER_PROCESS, orderApprovalCode));

export const getOrderApprovalRecoverySuccess = (orderApprovalCode: string): MemoizedSelector<StateWithProcess<any>, boolean> =>
  ProcessSelectors.getProcessSuccessFactory(ProcessIdBuilder.generateId(ORDER_APPROVAL_RECOVER_PROCESS, orderApprovalCode));

export const getMakeDecisionLoading = (orderApprovalCode: string): MemoizedSelector<StateWithProcess<any>, boolean> =>
  ProcessSelectors.getProcessLoadingFactory(ProcessIdBuilder.generateId(ORDER_APPROVAL_MAKE_DECISION_PROCESS, orderApprovalCode));

export const getMakeDecisionError = (
  orderApprovalCode: string
): MemoizedSelector<StateWithProcess<any>, LoaderError | undefined> =>
  ProcessSelectors.getProcessErrorFactory(ProcessIdBuilder.generateId(ORDER_APPROVAL_MAKE_DECISION_PROCESS, orderApprovalCode));

export const getMakeDecisionSuccess = (orderApprovalCode: string): MemoizedSelector<StateWithProcess<any>, boolean> =>
  ProcessSelectors.getProcessSuccessFactory(ProcessIdBuilder.generateId(ORDER_APPROVAL_MAKE_DECISION_PROCESS, orderApprovalCode));

export const getOrderApproval = (orderApprovalCode: string): MemoizedSelector<StateWithProcess<Order>, Order> =>
  createSelector(
    ProcessSelectors.getProcessStateFactory(ProcessIdBuilder.generateId(ORDER_APPROVAL_PROCESS, orderApprovalCode)),
    (state: StateUtils.LoaderState<Order>) => {
      return StateUtils.loaderValueSelector(state);
    }
  );

export const getB2BComments = (
  orderApprovalCode: string
): MemoizedSelector<StateWithProcess<Array<B2BComment>>, Array<B2BComment>> =>
  createSelector(
    ProcessSelectors.getProcessStateFactory(ProcessIdBuilder.generateId(B2B_COMMENTS_PROCESS, orderApprovalCode)),
    (state: StateUtils.LoaderState<Array<B2BComment>>) => {
      return StateUtils.loaderValueSelector(state);
    }
  );

export const getOrderProcessing = (
  orderApprovalCode: string
): MemoizedSelector<StateWithProcess<OrderProcessStatus>, OrderProcessStatus> =>
  createSelector(
    ProcessSelectors.getProcessStateFactory(ProcessIdBuilder.generateId(ORDER_APPROVAL_PROCESSING_PROCESS, orderApprovalCode)),
    (state: StateUtils.LoaderState<OrderProcessStatus>) => {
      return StateUtils.loaderValueSelector(state);
    }
  );

export const getOrderApprovalLoading = (orderApprovalCode: string): MemoizedSelector<StateWithProcess<any>, boolean> =>
  ProcessSelectors.getProcessLoadingFactory(ProcessIdBuilder.generateId(ORDER_APPROVAL_PROCESS, orderApprovalCode));

export const getOrderApprovalError = (
  orderApprovalCode: string
): MemoizedSelector<StateWithProcess<any>, LoaderError | undefined> =>
  ProcessSelectors.getProcessErrorFactory(ProcessIdBuilder.generateId(ORDER_APPROVAL_PROCESS, orderApprovalCode));

export const getOrderApprovalSuccess = (orderApprovalCode: string): MemoizedSelector<StateWithProcess<any>, boolean> =>
  ProcessSelectors.getProcessSuccessFactory(ProcessIdBuilder.generateId(ORDER_APPROVAL_PROCESS, orderApprovalCode));

export const getOrderApprovalSimulation = (
  orderApprovalCode: string
): MemoizedSelector<StateWithProcess<CartSimulation>, CartSimulation> =>
  createSelector(
    ProcessSelectors.getProcessStateFactory(ProcessIdBuilder.generateId(ORDER_APPROVAL_SIMULATION_PROCESS, orderApprovalCode)),
    (state: StateUtils.LoaderState<CartSimulation>) => {
      return StateUtils.loaderValueSelector(state);
    }
  );

export const getOrderApprovalSimulationLoading = (orderApprovalCode: string): MemoizedSelector<StateWithProcess<any>, boolean> =>
  ProcessSelectors.getProcessLoadingFactory(ProcessIdBuilder.generateId(ORDER_APPROVAL_SIMULATION_PROCESS, orderApprovalCode));

export const getOrderApprovalSimulationError = (
  orderApprovalCode: string
): MemoizedSelector<StateWithProcess<any>, LoaderError | undefined> =>
  ProcessSelectors.getProcessErrorFactory(ProcessIdBuilder.generateId(ORDER_APPROVAL_SIMULATION_PROCESS, orderApprovalCode));

export const getOrderApprovalSimulationSuccess = (orderApprovalCode: string): MemoizedSelector<StateWithProcess<any>, boolean> =>
  ProcessSelectors.getProcessSuccessFactory(ProcessIdBuilder.generateId(ORDER_APPROVAL_SIMULATION_PROCESS, orderApprovalCode));

export const getHasApprovalOrderLineErrors = (
  orderApprovalCode: string
): MemoizedSelector<StateWithProcess<CartSimulation>, boolean> => {
  return createSelector(getOrderApprovalSimulation(orderApprovalCode), (simulation) => {
    return Boolean(simulation?.hasOrderLineErrors);
  });
};

export const getB2BCommentsLoading = (orderApprovalCode: string): MemoizedSelector<StateWithProcess<any>, boolean> =>
  ProcessSelectors.getProcessLoadingFactory(ProcessIdBuilder.generateId(B2B_COMMENTS_PROCESS, orderApprovalCode));

export const getB2BCommentsError = (
  orderApprovalCode: string
): MemoizedSelector<StateWithProcess<any>, LoaderError | undefined> =>
  ProcessSelectors.getProcessErrorFactory(ProcessIdBuilder.generateId(B2B_COMMENTS_PROCESS, orderApprovalCode));

export const getB2BCommentsSuccess = (orderApprovalCode: string): MemoizedSelector<StateWithProcess<any>, boolean> =>
  ProcessSelectors.getProcessSuccessFactory(ProcessIdBuilder.generateId(B2B_COMMENTS_PROCESS, orderApprovalCode));

export const getAbortOrderApprovalHijackLoading = (cartId: string): MemoizedSelector<StateWithProcess<any>, boolean> =>
  ProcessSelectors.getProcessLoadingFactory(ProcessIdBuilder.generateId(ORDER_APPROVAL_ABORT_HIJACK_PROCESS, cartId));

export const getAbortOrderApprovalHijackError = (
  cartId: string
): MemoizedSelector<StateWithProcess<any>, LoaderError | undefined> =>
  ProcessSelectors.getProcessErrorFactory(ProcessIdBuilder.generateId(ORDER_APPROVAL_ABORT_HIJACK_PROCESS, cartId));

export const getAbortOrderApprovalHijackSuccess = (cartId: string): MemoizedSelector<StateWithProcess<any>, boolean> =>
  ProcessSelectors.getProcessSuccessFactory(ProcessIdBuilder.generateId(ORDER_APPROVAL_ABORT_HIJACK_PROCESS, cartId));

export const getDeleteOrderApprovalLoading = (orderApprovalCode: string): MemoizedSelector<StateWithProcess<any>, boolean> =>
  ProcessSelectors.getProcessLoadingFactory(ProcessIdBuilder.generateId(ORDER_APPROVAL_DELETE_PROCESS, orderApprovalCode));

export const getSortsSelector: MemoizedSelector<StateWithUser, Sort[]> = createSelector(
  getSearchParamsSelector,
  getSearchPaginationStateSelector,
  (queryParams: SearchParamsState, paginationState: PaginationState<SearchPageResultState>) => {
    return queryParams && paginationState && paginationState.sorts;
  }
);

export const getAvailableBuyerSelector: MemoizedSelector<StateWithUser, AvailableBuyerState> = createSelector(
  getOrderApprovalState,
  (state: OrderApprovalsState) => (state && state.buyers) || undefined
);

export const getAvailableBuyerSearchParamsSelector: MemoizedSelector<StateWithUser, AvailableSearchParamsState> = createSelector(
  getAvailableBuyerSelector,
  (state: AvailableBuyerState) => (state && state.searchParams) || undefined
);

export const getAvailableBuyerSearchSelector: MemoizedSelector<StateWithUser, SearchState<SearchPageResultState> | undefined> =
  createSelector(getAvailableBuyerSelector, (state: AvailableBuyerState) => (state && state.search) || undefined);

export const getAvailableBuyerSearchPaginationStateSelector: MemoizedSelector<
  StateWithUser,
  PaginationState<SearchPageResultState> | undefined
> = createSelector(
  getAvailableBuyerSearchSelector,
  getAvailableBuyerSearchParamsSelector,
  (state: SearchState<SearchPageResultState>, searchParams: AvailableSearchParamsState) => {
    return (searchParams && state && state[searchParams.key]) || undefined;
  }
);

export const getAvailableBuyerSearchPageResultsSelector: MemoizedSelector<
  StateWithUser,
  StateUtils.LoaderState<SearchPageResultState>
> = createSelector(
  getAvailableBuyerSearchParamsSelector,
  getAvailableBuyerSearchPaginationStateSelector,
  (queryParams: AvailableSearchParamsState, paginationState: PaginationState<SearchPageResultState>) => {
    return (
      (queryParams && paginationState && paginationState.pages && paginationState.pages[queryParams.page]) ||
      PyStateUtils.initialLoaderState
    );
  }
);

export const getAvailableBuyerSearchResultEntities: MemoizedSelector<StateWithUser, EntriesState<any> | undefined> =
  createSelector(getAvailableBuyerSelector, (state: AvailableBuyerState) => (state && state.entities) || undefined);

export const getAvailableBuyerSearchResultEntries: MemoizedSelectorWithProps<StateWithUser, any, SimpleCustomer[] | undefined> =
  createSelector(getAvailableBuyerSearchResultEntities, (entries: EntriesState<SimpleCustomer>, props) => {
    return (props.keys && entries && props.keys.map((i) => entries[i])) || undefined;
  });

export const getAvailableBuyerSearchPaginationSelector: MemoizedSelector<StateWithUser, Pagination | undefined> = createSelector(
  getAvailableBuyerSearchPaginationStateSelector,
  (state) => (state && state.pagination) || undefined
);

export const getOrderApprovalAdminState: MemoizedSelector<StateWithUser, OrderApprovalsAdminState> = createSelector(
  getUserState,
  (state: UserState) => state.orderApprovalAdmin
);

export const getSearchResultAdminEntities: MemoizedSelector<StateWithUser, EntriesState<OrderApproval>> = createSelector(
  getOrderApprovalAdminState,
  (state: OrderApprovalsAdminState) => state && state.entities
);

export const getPendingOrdersAdminCount: MemoizedSelector<StateWithUser, number | undefined> = createSelector(
  getOrderApprovalAdminState,
  (state: OrderApprovalsAdminState) => state && StateUtils.loaderValueSelector(state.pending)
);

export const getSearchParamsAdminSelector: MemoizedSelector<StateWithUser, SearchParamsState> = createSelector(
  getOrderApprovalAdminState,
  (state: OrderApprovalsAdminState) => state && state.searchParams
);

export const getSearchAdminSelector: MemoizedSelector<StateWithUser, SearchState<SearchPageResultState>> = createSelector(
  getOrderApprovalAdminState,
  (state: OrderApprovalsAdminState) => state && state.search
);

export const getSearchPaginationAdminStateSelector: MemoizedSelector<
  StateWithUser,
  PaginationState<SearchPageResultState>
> = createSelector(
  getSearchAdminSelector,
  getSearchParamsAdminSelector,
  (state: SearchState<SearchPageResultState>, searchParams: SearchParamsState) => {
    return searchParams && state && state[searchParams.key];
  }
);

export const getSearchPaginationAdminSelector: MemoizedSelector<StateWithUser, Pagination> = createSelector(
  getSearchPaginationAdminStateSelector,
  (state) => state && state.pagination
);

export const getSearchPageResultsAdminSelector: MemoizedSelector<
  StateWithUser,
  StateUtils.LoaderState<SearchPageResultState>
> = createSelector(
  getSearchParamsAdminSelector,
  getSearchPaginationAdminStateSelector,
  (queryParams: SearchParamsState, paginationState: PaginationState<SearchPageResultState>) => {
    return queryParams && paginationState && paginationState.pages && paginationState.pages[queryParams.page];
  }
);

export const getSearchResultAdminEntries: MemoizedSelectorWithProps<StateWithUser, any, OrderApproval[]> = createSelector(
  getSearchResultAdminEntities,
  (entries: EntriesState<OrderApproval>, props) => {
    return props.keys && entries && props.keys.map((i) => entries[i]);
  }
);

export const getSearchResultAdminEntry: MemoizedSelectorWithProps<StateWithUser, any, OrderApproval> = createSelector(
  getSearchResultAdminEntities,
  (entries: EntriesState<OrderApproval>, props) => {
    return props.key && entries && entries[props.key];
  }
);

export const getSearchPageResultsAdminLoading: MemoizedSelector<StateWithUser, boolean> = createSelector(
  getSearchPageResultsAdminSelector,
  (loaderState) => (loaderState && StateUtils.loaderLoadingSelector(loaderState)) || false
);

export const getSortsAdminSelector: MemoizedSelector<StateWithUser, Sort[]> = createSelector(
  getSearchParamsAdminSelector,
  getSearchPaginationAdminStateSelector,
  (queryParams: SearchParamsState, paginationState: PaginationState<SearchPageResultState>) => {
    return queryParams && paginationState && paginationState.sorts;
  }
);

export const getOrderApprovalDetails = (
  orderApprovalCode: string
): MemoizedSelector<StateWithProcess<OrderApproval>, OrderApproval> =>
  createSelector(
    ProcessSelectors.getProcessStateFactory(ProcessIdBuilder.generateId(ORDER_APPROVAL_DETAILS_PROCESS, orderApprovalCode)),
    (state: StateUtils.LoaderState<OrderApproval>) => {
      return StateUtils.loaderValueSelector(state);
    }
  );

export const getOrderApprovalDetailsLoading = (sapOrderNumber: string): MemoizedSelector<StateWithProcess<any>, boolean> =>
  ProcessSelectors.getProcessLoadingFactory(ProcessIdBuilder.generateId(ORDER_APPROVAL_DETAILS_PROCESS, sapOrderNumber));

export const getOrderApprovalDetailsError = (
  sapOrderNumber: string
): MemoizedSelector<StateWithProcess<any>, LoaderError | undefined> =>
  ProcessSelectors.getProcessErrorFactory(ProcessIdBuilder.generateId(ORDER_APPROVAL_DETAILS_PROCESS, sapOrderNumber));

export const getOrderApprovalDetailsSuccess = (sapOrderNumber: string): MemoizedSelector<StateWithProcess<any>, boolean> =>
  ProcessSelectors.getProcessSuccessFactory(ProcessIdBuilder.generateId(ORDER_APPROVAL_DETAILS_PROCESS, sapOrderNumber));
