import { MemoizedSelector, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { EntriesState, PaginationState, SearchPageResultState, SearchState } from '../../../features/store/base-feature-state';
import { OrderCard, OrderCardConfiguration, Pagination, SearchParams } from '../../../model';
import { PyStateUtils } from '../../../state';
import { OrderCardConfigurationState, OrderCardState, OrderCardsState, StateWithUser, UserState } from '../user-state';
import { getUserState } from './feature.selector';

export const getOrderCardState: MemoizedSelector<StateWithUser, OrderCardState> = createSelector(
  getUserState,
  (state: UserState) => state && state.orderCard
);

export const getOrderCardsState: MemoizedSelector<StateWithUser, OrderCardsState> = createSelector(
  getOrderCardState,
  (state: OrderCardState) => state.orderCards || undefined
);

export const getSearchResultEntities: MemoizedSelector<StateWithUser, EntriesState<OrderCard> | undefined> = createSelector(
  getOrderCardsState,
  (state: OrderCardsState) => (state && state.searchEntities) || undefined
);

export const getSearchSelector: MemoizedSelector<StateWithUser, SearchState<SearchPageResultState> | undefined> = createSelector(
  getOrderCardsState,
  (state: OrderCardsState) => (state && state.search) || undefined
);

export const getSearchPaginationStateSelector = (
  searchParams: SearchParams
): MemoizedSelector<StateWithUser, PaginationState<SearchPageResultState> | undefined> =>
  createSelector(getSearchSelector, (state: SearchState<SearchPageResultState>) => {
    return state[searchParams.key] || undefined;
  });

export const getSearchPaginationSelector = (
  searchParams: SearchParams
): MemoizedSelector<StateWithUser, Pagination | undefined> =>
  createSelector(getSearchPaginationStateSelector(searchParams), (state: PaginationState<SearchPageResultState>) => {
    return state?.pagination;
  });

export const getSearchPageResultsLoading = (searchParams: SearchParams): MemoizedSelector<StateWithUser, boolean> =>
  createSelector(
    getSearchPageResultsSelectorFactory(searchParams),
    (loaderState) => (loaderState && StateUtils.loaderLoadingSelector(loaderState)) || false
  );

export const getSearchPageResultsSelectorFactory = (
  searchParams: SearchParams
): MemoizedSelector<StateWithUser, StateUtils.LoaderState<SearchPageResultState> | undefined> =>
  createSelector(getSearchPaginationStateSelector(searchParams), (paginationState: PaginationState<SearchPageResultState>) => {
    return (searchParams && paginationState && paginationState.pages && paginationState.pages[searchParams.page]) || undefined;
  });

export const getSearchResultFactory = (
  searchParams: SearchParams
): MemoizedSelector<StateWithUser, SearchPageResultState | undefined> =>
  createSelector(
    getSearchPageResultsSelectorFactory(searchParams),
    (loaderState: StateUtils.LoaderState<SearchPageResultState>) => {
      return (loaderState && StateUtils.loaderValueSelector(loaderState)) || undefined;
    }
  );

export const getSearchResultPage = (searchParams: SearchParams): MemoizedSelector<StateWithUser, Array<string> | undefined> =>
  createSelector(getSearchResultFactory(searchParams), (state: SearchPageResultState) => {
    return (state && state.results) || undefined;
  });

export const getSearchResultEntries = (searchParams: SearchParams): MemoizedSelector<StateWithUser, OrderCard[] | undefined> =>
  createSelector(
    getSearchResultPage(searchParams),
    getSearchResultEntities,
    (codes: Array<string>, entries: EntriesState<OrderCard>) => {
      return (codes && entries && codes.map((i) => entries[i])) || undefined;
    }
  );

export const getOrderCardByCodeState = (code: string): MemoizedSelector<StateWithUser, StateUtils.LoaderState<OrderCard>> =>
  createSelector(
    getOrderCardsState,
    (state) => (state.entities && PyStateUtils.entityLoaderStateSelector(state.entities, code)) || PyStateUtils.initialLoaderState
  );

export const getOrderCardConfigurantionState: MemoizedSelector<StateWithUser, OrderCardConfigurationState> = createSelector(
  getOrderCardsState,
  (state: OrderCardsState) => state.orderCardConfiguration || undefined
);

export const getOrderCardConfigurantionEntityState = (
  key: string
): MemoizedSelector<StateWithUser, StateUtils.LoaderState<OrderCardConfiguration>> =>
  createSelector(
    getOrderCardConfigurantionState,
    (state) =>
      (state && state.entities && PyStateUtils.entityLoaderStateSelector(state.entities, key)) || PyStateUtils.initialLoaderState
  );

export const getOrderCardConfigurantionListState = (key: string): MemoizedSelector<StateWithUser, StateUtils.LoaderState<any>> =>
  createSelector(
    getOrderCardConfigurantionState,
    (state) => (state && state.list && PyStateUtils.entityLoaderStateSelector(state.list, key)) || PyStateUtils.initialLoaderState
  );

export const getOrderCardConfigurantionLoadingState = (key: string): MemoizedSelector<StateWithUser, boolean> =>
  createSelector(
    getOrderCardConfigurantionEntityState(key),
    (loaderState) => (loaderState && StateUtils.loaderLoadingSelector(loaderState)) || false
  );
