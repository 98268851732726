import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '../../../core/i18n';
import { ProgressbarComponent } from './progressbar.component';

@NgModule({
  imports: [CommonModule, I18nModule],
  declarations: [ProgressbarComponent],
  exports: [ProgressbarComponent],
})
export class ProgressbarModule {}
