import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InterceptorUtil, OCC_USER_ID_ANONYMOUS, OccEndpointsService, USE_CLIENT_TOKEN } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { KeyedResultArray } from '../../../model';
import { Category } from '../../../model/catalog.model';
import { Occ } from '../../../occ/occ-models';
import { ConverterService } from '../../../util/converter.service';
import { CATEGORY_NORMALIZER } from './converters';

@Injectable({
  providedIn: 'root',
})
export class CategoryConnector {
  constructor(
    private converterService: ConverterService,
    private httpClient: HttpClient,
    private occEndpointsService: OccEndpointsService
  ) {}

  load(userId: string, myAssortment?: string): Observable<KeyedResultArray<Category>> {
    const queryParams = {};
    if (!!myAssortment) {
      queryParams['mya'] = myAssortment;
    }
    let headers = new HttpHeaders();
    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }
    return this.httpClient
      .get<Occ.CategoryList>(
        this.occEndpointsService.buildUrl('categories', {
          urlParams: { userId: userId },
          queryParams,
        }),
        {
          headers,
          observe: 'response',
        }
      )
      .pipe(
        map((response) => {
          return {
            key: response.headers.get('x-opti-catalog-key'),
            result: this.converterService.convertMany(response.body['categories'], CATEGORY_NORMALIZER),
          };
        })
      );
  }
}
