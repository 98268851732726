import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { TranslationService } from '../../../core/i18n';
import { AccountingCode, AccountingCodeList, CartType, OrderEntry } from '../../../core/model';
import { PrincipalConfigurationService } from '../../../core/user';
import { CartAccountingCodesFacade } from '../../../features/cart/base';
import { CART_TYPE_PARAM_KEY } from '../../guards';
import { AccountingCodeValidator } from './order-entry-accounting-codes-validator';

export interface Option {
  value: string;
  label: string;
  description?: string;
  disabled?: boolean;
}

@Component({
  selector: 'py-order-entry-accounting-codes',
  templateUrl: './order-entry-accounting-codes.component.html',
  styleUrls: ['./order-entry-accounting-codes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderEntryAccountingCodesComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  private enableMandatoryAccountingCodes$: Observable<boolean>;
  private readonly BLANK_CHARACTER = ' ';

  options: Option[];
  originalOptions: Option[];
  accountingCode: string;
  cartType: CartType;
  showFullAccountingCodeList: boolean = false;
  accountingCodeList: AccountingCodeList;
  enableMandatoryAccountingCodes: boolean;

  isCollapsed = true;
  readonlyValue$: Observable<string>;

  @Output() accountingCodeUpdated = new EventEmitter();

  _entry: OrderEntry;

  @Input() entryFormGroup: UntypedFormGroup;
  @Input() entryFormArray: UntypedFormArray;
  @Input() accountingCodeList$: Observable<AccountingCodeList>;
  @Input() showButton: boolean = false;
  @Input() disabled: boolean = false;
  @Input() showTooltip: boolean = true;
  @Input() providedCartType: CartType;

  @Input() set entry(entry: OrderEntry) {
    this._entry = entry;
    this.accountingCode = entry.accountingCode?.code;
  }

  dropdownSearchForm = new UntypedFormGroup({
    searchInput: new UntypedFormControl(''),
  });

  get entry() {
    return this._entry;
  }

  get control() {
    return this.entryFormGroup?.controls.accountingCode;
  }

  get reinvoicingControl() {
    return this.entryFormGroup?.controls.reinvoicing;
  }

  get selectedOption(): Option {
    return this.originalOptions?.find((option) => option.value === this.control.value);
  }

  get accountingCodes(): AccountingCode[] {
    return this.showFullAccountingCodeList ? this.accountingCodeList?.accountingCodes : this.entry?.accountingCodes;
  }

  get invalid(): boolean {
    return this.control.invalid && (this.control.dirty || this.control.touched);
  }

  get errors(): ValidationErrors {
    if (this.invalid) {
      return this.control.errors;
    }
    return undefined;
  }

  constructor(
    private cartAccountingCodesService: CartAccountingCodesFacade,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private principalConfigurationService: PrincipalConfigurationService,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.cartType = CartType[this.route.snapshot.queryParamMap.get(CART_TYPE_PARAM_KEY)] || this.providedCartType;
    this.entryFormGroup?.addControl('reinvoicing', new UntypedFormControl());

    this.readonlyValue$ = this.translationService.translate('accountingCodes.reInvoicingMarker_hint').pipe(
      map((reinvoicingTranslation) => {
        if (!this.entry?.accountingCode) {
          return undefined;
        }
        if (this.entry?.accountingCode?.reinvoicing) {
          return `${this.entry?.accountingCode.code}: ${this.entry?.accountingCode.description} ${reinvoicingTranslation}`;
        }
        return `${this.entry?.accountingCode.code}: ${this.entry?.accountingCode.description}`;
      })
    );

    if (this.showButton) {
      this.setOptions();
      this.setInitialValue();
      this.initUpdateAccountingCode();
      this.initAccountingCodeSuccess();

      this.subscriptions.add(
        this.dropdownSearchForm.get('searchInput').valueChanges.subscribe((searchText) => {
          this.onSearchInDropdown(searchText);
        })
      );
    }
  }

  markAsDirty(): void {
    this.control.markAsDirty();
    this.control.updateValueAndValidity({ emitEvent: false });
    this.cd.detectChanges();
  }

  onSearchInDropdown(searchText: string): void {
    if (!searchText) {
      this.options = this.originalOptions;
      return;
    }

    this.options = this.originalOptions.filter(
      (option: Option) => this.termMatchesText(searchText, option.label) || this.termMatchesText(searchText, option.description)
    );

    this.cd.detectChanges();
  }

  updateValue(value: string): void {
    const isReinvoicing = this.accountingCodes?.find((accountingCode) => accountingCode.code === value)?.reinvoicing;
    this.reinvoicingControl.setValue(isReinvoicing);
    this.control.setValue(value);
  }

  onResetSearchInput(): void {
    this.dropdownSearchForm.reset();
  }

  onResetValue(): void {
    this.cartAccountingCodesService.updateEntryAccountingCode(this.entry, this.BLANK_CHARACTER, this.cartType);
    this.control.reset();
    this.reinvoicingControl.reset();
    this.accountingCodeUpdated.emit();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initAccountingCodeSuccess(): void {
    this.subscriptions.add(
      this.cartAccountingCodesService.getUpdateEntryAccountingCode(this.entry, this.cartType).subscribe((d) => {
        if (this.accountingCode !== d.code) {
          this.accountingCode = d.code;
          this.control.setValue(this.accountingCode, { emitEvent: false });
          this.reinvoicingControl.setValue(
            this.accountingCodes.filter((a: AccountingCode) => a.code === d.code)[0]?.reinvoicing,
            {
              emitEvent: false,
            }
          );
        }
        this.accountingCodeUpdated.emit();
      })
    );
  }

  private setValidators(): void {
    this.enableMandatoryAccountingCodes$ = this.principalConfigurationService.isEnabled('enableMandatoryAccountingCodes');

    const validatorsArray = [AccountingCodeValidator.validate(this.entryFormArray, this.accountingCodes)];

    this.subscriptions.add(
      this.enableMandatoryAccountingCodes$.pipe(take(1)).subscribe((enableMandatoryAccountingCodes) => {
        this.enableMandatoryAccountingCodes = enableMandatoryAccountingCodes;

        if (enableMandatoryAccountingCodes) {
          validatorsArray.push(Validators.required);
        }

        this.control.setValidators(validatorsArray);
      })
    );
  }

  private setOptions(): void {
    if (this.accountingCodes?.length) {
      this.prepareDropdownOptions(this.accountingCodes);
      this.setValidators();
    } else {
      this.showFullAccountingCodeList = true;

      this.subscriptions.add(
        this.accountingCodeList$.subscribe((accountingCodeList) => {
          this.accountingCodeList = accountingCodeList;
          this.prepareDropdownOptions(this.accountingCodeList.accountingCodes);
          this.setValidators();
        })
      );
    }
  }

  private setInitialValue(): void {
    if (this.accountingCode) {
      this.control.setValue(this.accountingCode);
      this.reinvoicingControl.setValue(this.entry.accountingCode?.reinvoicing);
    }

    this.cd.detectChanges();
  }

  private initUpdateAccountingCode(): void {
    this.subscriptions.add(
      this.control.valueChanges
        .pipe(distinctUntilChanged(), withLatestFrom(this.cartAccountingCodesService.isAccountingCodesModalDismissed()))
        .subscribe(([val, isModalDismissed]) => {
          if (val && val !== '' && this.accountingCode !== val) {
            if (this.options.length > 0 && !isModalDismissed) {
              this.cartAccountingCodesService.setAccountingCodesModalOpen(
                this.cartType,
                this.accountingCodes.filter((a) => a.code === val)[0],
                true
              );
            }
            this.cartAccountingCodesService.updateEntryAccountingCode(this.entry, val, this.cartType);
          }
        })
    );
  }

  private prepareDropdownOptions(accountingCodes: AccountingCode[]): void {
    this.translationService
      .translate('accountingCodes.reInvoicingMarker_hint')
      .pipe(
        take(1),
        switchMap((reinvoicingTranslation) => {
          const options = accountingCodes.map((accountingCode) => {
            return {
              value: accountingCode.code,
              description: accountingCode.reinvoicing && reinvoicingTranslation,
              label: `${accountingCode.code}: ${accountingCode.description}`,
            };
          });

          return of(options);
        })
      )
      .subscribe((options: Option[]) => {
        this.options = options;
        this.originalOptions = this.options;
        this.cd.detectChanges();
      });
  }

  private termMatchesText(searchTerm: string, searchableText: string): boolean {
    return (searchableText || '')?.toLowerCase().includes(searchTerm?.toLowerCase());
  }
}
