<div [formGroup]="group">
  <py-checkbox-slider
    [addMargin]="addMargin"
    [leftLabel]="leftLabel"
    [rightLabel]="rightLabel"
    [rightMargin]="rightMargin"
    [centerLabel]="centerLabel"
    [tooltip]="tooltip"
  >
    <input hidden type="checkbox" [formControlName]="fieldName" [attr.disabled]="disabled || null" />
  </py-checkbox-slider>
</div>
