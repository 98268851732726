import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'py-pallet-flag-form',
  templateUrl: './pallet-flag-form.component.html',
  styleUrls: ['./pallet-flag-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PalletFlagFormComponent {
  @Input() form: UntypedFormGroup;

  clearField(fieldName: string): void {
    this.form.get(fieldName).reset();
    this.form.get(fieldName).markAsDirty();
  }
}
