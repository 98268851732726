import { StateUtils } from '@spartacus/core';
import { HttpErrorModel, LoaderError } from '../../../model';

declare module '@spartacus/core/src/state/utils/loader' {
  interface LoaderMeta {
    errorDetails?: LoaderError;
  }
}

export function failMeta(entityType: string, error?: HttpErrorModel): StateUtils.LoaderMeta {
  return {
    entityType: entityType,
    loader: {
      error: error ? error : true,
    },
    errorDetails: error ? error : true,
  };
}

export class LoaderFailAction implements StateUtils.LoaderAction {
  type = StateUtils.LOADER_FAIL_ACTION;
  readonly meta: StateUtils.LoaderMeta;
  constructor(entityType: string, error?: HttpErrorModel) {
    this.meta = failMeta(entityType, error);
  }
}
