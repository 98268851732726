import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AuthService, EventService } from '@spartacus/core';
import { NavigationEvent } from '@spartacus/storefront';
import { Observable, forkJoin, of, race, using } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  share,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { UserIdService } from '../../../auth';
import {
  AlternativeArticle,
  Article,
  ArticleRef,
  ArticleStatus,
  LoaderError,
  SubstituteRef,
  SubstituteRefType,
} from '../../../model';
import { withdrawOn } from '../../../util/rxjs';
import { StateWithCatalog } from '../../store/catalog-state';
import { CatalogSelectors } from '../../store/selectors';
import { ArticleActions } from '../store/actions';

@Injectable({
  providedIn: 'root',
})
export class ArticleService {
  isUserLoggedIn$ = this.authService.isUserLoggedIn().pipe(share());

  constructor(
    private userIdService: UserIdService,
    private store: Store<StateWithCatalog>,
    private authService: AuthService,
    private eventService: EventService
  ) {}

  getArticles(codes: Array<ArticleRef>): Observable<Array<Observable<Article>>> {
    return this.userIdService.getUserId().pipe(
      take(1),
      map((userId) => {
        return codes.map((code) => this.loadArticle(code, userId));
      }),
      withdrawOn(this.eventService.get(NavigationEvent))
    );
  }

  getArticlesFully(codes: Array<ArticleRef>): Observable<Array<Article>> {
    return this.userIdService.getUserId().pipe(
      take(1),
      map((userId) =>
        codes.map((code) => {
          const article$ = this.loadArticle(code, userId).pipe(filter((article) => !!article));
          const articleFailure$ = this.getArticleFailure(code).pipe(
            filter((failure) => !!failure),
            mapTo(undefined)
          );

          return race(article$, articleFailure$).pipe(take(1));
        })
      ),
      switchMap((articles: Array<Observable<Article>>) => forkJoin(articles)),
      map((articles: Array<Article>) => articles.filter((article) => !!article)),
      withdrawOn(this.eventService.get(NavigationEvent))
    );
  }

  getArticle(code: ArticleRef): Observable<Article> {
    if (!code) {
      return of(undefined);
    }
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap((userId) => {
        const article$ = this.loadArticle(code, userId).pipe(filter((article) => !!article));
        const articleFailure$ = this.getArticleFailure(code).pipe(
          filter((failure) => !!failure),
          mapTo(undefined)
        );

        return race(article$, articleFailure$).pipe(take(1));
      }),
      withdrawOn(this.eventService.get(NavigationEvent))
    );
  }

  getArticleLoading(code: ArticleRef): Observable<boolean> {
    return this.store.select(CatalogSelectors.getArticleLoading(code));
  }

  getArticleFailure(code: ArticleRef): Observable<LoaderError | undefined> {
    return this.store.select(CatalogSelectors.getArticleFailure(code));
  }

  resetArticles(codes: string[]): void {
    codes.forEach((code) => this.resetArticle(code));
  }

  resetArticle(code: string): void {
    this.store.dispatch(new ArticleActions.LoadArticleReset(code));
  }

  getSimilarArticles(code: ArticleRef): Observable<AlternativeArticle[] | undefined> {
    return this.isUserLoggedIn$.pipe(
      filter((isUserLoggedIn) => !!isUserLoggedIn),
      switchMap(() =>
        this.store.pipe(
          select(CatalogSelectors.getSimilarArticlesLoaderState(code)),
          tap((loaderState) => {
            if (!loaderState.success && !loaderState.error && !loaderState.loading) {
              this.loadSimilarArticles(code);
            }
          }),
          map((loaderState) => loaderState.value?.alternatives),
          shareReplay({ bufferSize: 1, refCount: true })
        )
      )
    );
  }

  getSimilarArticlesLoading(code: ArticleRef): Observable<boolean> {
    return this.store.select(CatalogSelectors.getSimilarArticlesLoading(code));
  }

  getSubstitutesArticles(code: ArticleRef, refType?: SubstituteRefType): Observable<SubstituteRef[] | undefined> {
    return this.store.pipe(
      select(CatalogSelectors.getSubstitutesArticlesLoaderState(code)),
      tap((loaderState) => {
        if (!loaderState.success && !loaderState.error && !loaderState.loading) {
          this.loadSubstitutesArticles(code);
        }
      }),
      map((loaderState) => loaderState.value?.refs),
      map((refs) => (refType ? refs?.filter((ref) => ref.refType === refType) : refs)),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  getSubstitutesArticlesLoading(code: ArticleRef): Observable<boolean> {
    return this.store.select(CatalogSelectors.getSubstitutesArticlesLoading(code));
  }

  getSubstitutesArticlesLoaded(code: ArticleRef): Observable<boolean> {
    return this.store.select(CatalogSelectors.getSubstitutesArticlesLoaded(code));
  }

  getProductsCodesFromSubstitutes(substituteRefs: SubstituteRef[]): string[] {
    return substituteRefs
      .filter((substitute) => substitute.refType === SubstituteRefType.Product)
      .map((substitute) => substitute.code);
  }

  getArticlesCodesFromSubstitutes(substituteRefs: SubstituteRef[]): string[] {
    return substituteRefs
      .filter((substitute) => substitute.refType === SubstituteRefType.Article)
      .map((substitute) => substitute.code);
  }

  isArticleDiscontinued(article: Article): boolean {
    return !!(article?.articleStatus === ArticleStatus.ZN || article?.invisible || article?.localDeletion);
  }

  private loadSubstitutesArticles(code: ArticleRef): void {
    this.userIdService.takeUserId().subscribe(
      (userId) => this.store.dispatch(new ArticleActions.LoadSubstitutesArticles(code, userId)),
      () => {}
    );
  }

  private loadSimilarArticles(code: ArticleRef): void {
    this.userIdService.takeUserId().subscribe(
      (userId) => this.store.dispatch(new ArticleActions.LoadSimilarArticles(code, userId)),
      () => {}
    );
  }

  /**
   * Creates observable for providing specified article data for the scope
   *
   * @param code
   */
  private loadArticle(code: string, userId: string): Observable<Article> {
    const shouldLoad$ = this.store.pipe(
      select(CatalogSelectors.getArticleLoaderState(code)),
      map((articleState) => !articleState.loading && !articleState.success && !articleState.error),
      distinctUntilChanged(),
      filter((x) => x)
    );

    const isLoading$ = this.store.pipe(select(CatalogSelectors.getArticleLoading(code)));

    const articleLoadLogic$ = shouldLoad$.pipe(
      debounceTime(0),
      withLatestFrom(isLoading$),
      tap(([, isLoading]) => {
        if (!isLoading) {
          this.store.dispatch(new ArticleActions.LoadArticle(code, userId));
        }
      }),
      takeUntil(this.authService.logoutInProgress$.pipe(filter((logout) => !!logout)))
    );

    const articleData$ = this.store.pipe(select(CatalogSelectors.getArticle(code)));

    return using(
      () => articleLoadLogic$.subscribe(),
      () => articleData$
    ).pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }
}
