import { Observable } from 'rxjs';
import { B2BUserSearchParams, DataImport } from '../../../../../core/model';

export abstract class UsersImportExportAdapter {
  abstract getExportedUsersFile(userId: string, searchParams: B2BUserSearchParams, totalCount: number): Observable<Blob>;

  abstract sendInsertImportUsersFile(userId: string, file: File): Observable<DataImport>;

  abstract sendUpdateImportUsersFile(userId: string, file: File): Observable<DataImport>;

  abstract downloadImportUsersFileTemplate(userId: string): Observable<Blob>;
}
