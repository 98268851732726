import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { WindowRef } from '../../core/window';

@Injectable({
  providedIn: 'root',
})
export class WindowUtils {
  constructor(private windowRef: WindowRef, @Inject(DOCUMENT) protected document: any) {}

  public scrollIntoView(htmlElement: HTMLElement, scrollableElement?: HTMLElement): void {
    //Scroll into view but remove the page header offset
    if (this.windowRef.isBrowser()) {
      const elementToScroll = scrollableElement || this.windowRef.nativeWindow;
      const scrollYDistance = scrollableElement ? scrollableElement.scrollTop : this.windowRef.nativeWindow.scrollY;
      const headerHeight = this.document.getElementById('header')?.offsetHeight || 90; // Header height may have two values: 90px - desktop, 130px - mobile

      elementToScroll.scrollTo({
        top: htmlElement.getBoundingClientRect().top + scrollYDistance - headerHeight,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  getElement(selector: string): HTMLElement {
    if (this.windowRef.isBrowser()) {
      return this.document.querySelector(selector) as HTMLElement;
    }
  }

  addStyleClassToElement(selector: string, styleClass: string): void {
    if (this.windowRef.isBrowser()) {
      const el = this.document.querySelector(selector) as HTMLElement;
      if (!el?.classList?.contains(styleClass)) {
        el?.classList?.add(styleClass);
      }
    }
  }

  removeStyleClassFromElement(selector: string, styleClass: string): void {
    if (this.windowRef.isBrowser()) {
      const el = this.document.querySelector(selector) as HTMLElement;
      if (el?.classList?.contains(styleClass)) {
        el.classList.remove(styleClass);
      }
    }
  }

  private doActionPreserveWidth(htmlElement: HTMLElement, action: () => void) {
    const widthBefore = htmlElement.clientWidth;
    action();
    const widthAfter = htmlElement.clientWidth;
    const widthCompensation = widthAfter - widthBefore;

    if (widthCompensation) {
      if (htmlElement.nodeName === 'BODY' && this.windowRef.isBrowser()) {
        // Storing the calculated compensation width in CSS variable. It is used in header styling to cover the scrollbar gap that is visible when preserving width on body.
        htmlElement.style.setProperty('--py-compensation-width', `${Math.max(0, widthCompensation)}px`);
      }

      const previousPadding = this.windowRef.isBrowser()
        ? parseInt(this.windowRef.nativeWindow.getComputedStyle(htmlElement).getPropertyValue('padding-right') || '0px', 10)
        : 0;
      const newPadding = Math.max(0, previousPadding + widthCompensation);
      htmlElement.style.setProperty('padding-right', `${newPadding}px`);
    }
  }

  addClassPreserveWidth(htmlElement: HTMLElement, className: string) {
    this.doActionPreserveWidth(htmlElement, () => htmlElement.classList.add(className));
  }

  removeClassPreserveWidth(htmlElement: HTMLElement, className: string) {
    this.doActionPreserveWidth(htmlElement, () => htmlElement.classList.remove(className));
  }
}
