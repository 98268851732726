import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { I18nModule } from '../../../core/i18n';
import { GenericLinkModule } from '../generic-link';
import { SpinnerModule } from '../spinner';
import { AddToOrderCardComponent } from './add-to-order-card.component';

@NgModule({
  declarations: [AddToOrderCardComponent],
  imports: [
    CommonModule,
    I18nModule,
    NgbPopoverModule,
    NzIconModule,
    InfiniteScrollModule,
    SpinnerModule,
    GenericLinkModule,
    NgbTooltipModule,
  ],
  exports: [AddToOrderCardComponent],
})
export class AddToOrderCardModule {}
