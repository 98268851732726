import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { RecommendationPanel } from '../../model';
import { PanelParameters } from '../services/recommendations.service';
import { USER_BASE_FEATURE } from '../store/user-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: RecommendationsFacade,
      feature: USER_BASE_FEATURE,
      async: true,
      methods: [
        'getRecommendedProductsForUser',
        'getRecommendedArticlesForUser',
        'getTopProducts',
        'getTopArticles',
        'getPanel',
        'loadPanel',
        'getRecommendedProductsBasedOnProduct',
        'getRecommendedProductsBasedOnArticle',
        'getRecommendedArticlesBasedOnArticle',
      ],
    }),
})
export abstract class RecommendationsFacade {
  abstract getRecommendedProductsForUser(parameters?: PanelParameters): Observable<RecommendationPanel>;
  abstract getRecommendedArticlesForUser(parameters?: PanelParameters): Observable<RecommendationPanel>;
  abstract getTopProducts(parameters?: PanelParameters): Observable<RecommendationPanel>;
  abstract getTopArticles(parameters?: PanelParameters): Observable<RecommendationPanel>;
  abstract getPanel(panelName: string, parameters?: PanelParameters): Observable<RecommendationPanel>;
  abstract loadPanel(panelName: string, parameters?: PanelParameters): void;
  abstract getRecommendedProductsBasedOnProduct(
    productCode: string,
    parameters?: PanelParameters
  ): Observable<RecommendationPanel>;
  abstract getRecommendedProductsBasedOnArticle(
    articleCode: string,
    parameters?: PanelParameters
  ): Observable<RecommendationPanel>;
  abstract getRecommendedArticlesBasedOnArticle(
    articleCode: string,
    parameters?: PanelParameters
  ): Observable<RecommendationPanel>;
}
