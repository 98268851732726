import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../../core/i18n';
import { ReactiveFormTextareaModule } from '../../../../shared/components/reactive-forms-input/reactive-form-textarea/reactive-form-textarea.module';
import { DropdownModule } from '../../dropdown';
import { FileUploadModule } from '../../file-upload/file-upload.module';
import { ListInsertModule } from '../../list-insert';
import { MediaModule } from '../../media';
import { QuantityAndUnitSelectorModule } from '../../quantity-and-unit-selector';
import { RadioModule } from '../../radio/radio.module';
import { ReactiveFormInputFieldModule } from '../../reactive-forms-input/reactive-form-input-field/reactive-form-input-field.module';
import { SpinnerModule } from '../../spinner';
import { OrderReturnUploadedFilesGalleryModalModule } from '../order-return-uploaded-files-gallery-modal/order-return-uploaded-files-gallery-modal.module';
import { OrderReturnEntryFormComponent } from './order-return-entry-form.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ReactiveFormInputFieldModule,
    ReactiveFormTextareaModule,
    I18nModule,
    NzIconModule,
    RouterModule,
    RadioModule,
    NgbTooltipModule,
    QuantityAndUnitSelectorModule,
    DropdownModule,
    ListInsertModule,
    FileUploadModule,
    MediaModule,
    OrderReturnUploadedFilesGalleryModalModule,
    SpinnerModule,
  ],
  declarations: [OrderReturnEntryFormComponent],
  exports: [OrderReturnEntryFormComponent],
})
export class OrderReturnEntryFormModule {}
