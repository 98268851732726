import { Component, Input, OnInit } from '@angular/core';
import { Observable, ReplaySubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, shareReplay, switchMap } from 'rxjs/operators';
import { CategoryService } from '../../../../core/catalog';
import { Category, EntityMap, SoldTo } from '../../../../core/model';
import { PrincipalConfigurationService } from '../../../../core/user';
import { prepareUrlForLink } from '../../../../core/util';
import { SoldToFacade } from '../../../sold-to-base';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ul[py-sub-categories-navigation]',
  templateUrl: 'sub-categories-navigation.component.html',
})
export class SubCategoriesNavigationComponent implements OnInit {
  activeCategory$: Observable<Category>;
  activeCategoryCode$ = new ReplaySubject<string>();
  mya$ = new ReplaySubject<boolean>();
  activeSoldTo$: Observable<SoldTo>;
  areCategoriesEmpty$: Observable<boolean>;

  @Input() set activeCategoryCode(activeCategoryId: string) {
    this.activeCategoryCode$.next(activeCategoryId);
  }

  @Input() set mya(mya: boolean) {
    this.mya$.next(mya);
  }

  enableTogglingOfCustomerAssortment$: Observable<boolean>;

  constructor(
    private principalConfigurationService: PrincipalConfigurationService,
    private categoryService: CategoryService,
    private soldToService: SoldToFacade
  ) {}

  ngOnInit(): void {
    this.enableTogglingOfCustomerAssortment$ = this.principalConfigurationService.isEnabled('enableTogglingOfCustomerAssortment');
    this.areCategoriesEmpty$ = this.categoryService.getAreCategoriesEmpty();

    this.activeCategory$ = combineLatest([this.activeCategoryCode$, this.enableTogglingOfCustomerAssortment$, this.mya$]).pipe(
      distinctUntilChanged(),
      switchMap(([activeCategoryCode, enableTogglingOfCustomerAssortment, mya]) => {
        const key = mya ? 'mya:true' : enableTogglingOfCustomerAssortment ? 'mya:false' : 'default';
        return this.categoryService.getCategory(activeCategoryCode, key);
      })
    );

    this.activeSoldTo$ = this.soldToService.getActiveSoldTo().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  getUrl(url: string, queryParams?: EntityMap<string>): string {
    return prepareUrlForLink(url, queryParams);
  }
}
