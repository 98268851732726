import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../core/i18n';
import { PipesModule } from '../../pipes';
import { ReamingButtonComponent } from './reaming-button.component';

@NgModule({
  imports: [CommonModule, NzIconModule, I18nModule, NgbTooltipModule, PipesModule],
  declarations: [ReamingButtonComponent],
  exports: [ReamingButtonComponent],
})
export class ReamingButtonModule {}
