import { BaseSearchAction, PagedSearchAction, SearchActionTypes } from '../actions/search.actions';
import { PaginationState, SearchState } from '../base-feature-state';

const initialSearchState: SearchState<any> = {};

export function reducer(
  searchType: string,
  delegateReducer?: (state: PaginationState<any>, action: PagedSearchAction | BaseSearchAction) => any
) {
  return (state: SearchState<any> = initialSearchState, action: PagedSearchAction | BaseSearchAction) => {
    if (!delegateReducer || searchType !== action.searchType) {
      return state;
    }
    if (action.type === SearchActionTypes.ResetSearchAction) {
      return { ...initialSearchState };
    }
    const searchAction = <PagedSearchAction>action;
    const key = searchAction.searchParams && searchAction.searchParams.key;
    if (!key) {
      return state;
    }
    switch (searchAction.type) {
      case SearchActionTypes.SearchAction: {
        const newState = { ...state };
        const stateItem = state[key];
        newState[key] = {
          searchParams: searchAction.searchParams,
          lastUpdateTime: stateItem?.lastUpdateTime,
          pages: stateItem?.pages ?? {},
          pagination: stateItem?.pagination,
          sorts: stateItem?.sorts ?? [],
        };
        return newState;
      }
      default: {
        return {
          ...state,
          [key]: delegateReducer(state[key], searchAction),
        };
      }
    }
  };
}
