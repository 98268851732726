import { Observable } from 'rxjs';
import {
  B2BPermissionOrderListItem,
  EntitiesModel,
  Permission,
  PermissionConnectionTypeEnum,
  PermissionConnections,
  PermissionOrdersSearchParams,
  SearchConfig,
  SoldTo,
} from '../../../model';

export abstract class PermissionAdapter {
  /**
   * Abstract method used to load permissionManagement's details data.
   * Permission's data can be loaded from alternative sources, as long as the structure
   * converts to the `Permission`.
   *
   * @param userId The `userId` for given permissionManagement
   * @param permissionCode The `permissionCode` for given permissionManagement
   */
  abstract load(userId: string, permissionCode: string): Observable<Permission>;

  abstract loadList(userId: string, params?: SearchConfig): Observable<EntitiesModel<Permission>>;

  abstract getPermissionOrders(
    permissionCode: string,
    userId: string,
    params?: PermissionOrdersSearchParams
  ): Observable<EntitiesModel<B2BPermissionOrderListItem>>;

  abstract getUserPermissions(userId: string, params?: SearchConfig): Observable<EntitiesModel<Permission>>;

  abstract create(userId: string, permission: Permission): Observable<Permission>;

  abstract update(userId: string, permissionCode: string, permission: Permission): Observable<Permission>;

  abstract updatePermissionContactPerson(userId: string, permissionCode: string, contactPersonUid: string): Observable<void>;

  abstract publishPermission(userId: string, permissionCode: string): Observable<Permission>;

  abstract unpublishPermission(userId: string, permissionCode: string): Observable<Permission>;

  abstract delete(userId: string, permissionCode: string): Observable<void>;

  abstract getPermissionConnections(
    connectionType: PermissionConnectionTypeEnum,
    userId: string,
    permissionCode: string
  ): Observable<PermissionConnections>;

  abstract updatePermissionConnections(
    connectionType: PermissionConnectionTypeEnum,
    userId: string,
    permissionCode: string,
    connections: PermissionConnections
  ): Observable<void>;

  abstract deletePermissionConnections(
    connectionType: PermissionConnectionTypeEnum,
    userId: string,
    permissionCode: string,
    connections: PermissionConnections
  ): Observable<void>;

  abstract getFilteredSoldTos(searchParams: SearchConfig, userId: string): Observable<EntitiesModel<SoldTo>>;
}
