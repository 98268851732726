import { MemoizedSelector, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { PriceFileFormat } from '../../../../model/price-file.model';
import { ArrayState } from '../../../store/base-feature-state';
import { PriceFileState, StateWithPriceFile } from '../price-file-state';
import { getPriceFileState } from './feature.selector';

export const getPriceFileFormatsLoader: MemoizedSelector<
  StateWithPriceFile,
  StateUtils.LoaderState<ArrayState<PriceFileFormat>>
> = createSelector(getPriceFileState, (state: PriceFileState) => state && state.formats);

export const getPriceFileFormatsState: MemoizedSelector<StateWithPriceFile, ArrayState<PriceFileFormat>> = createSelector(
  getPriceFileFormatsLoader,
  (state: StateUtils.LoaderState<ArrayState<PriceFileFormat>>) => state && StateUtils.loaderValueSelector(state)
);

export const getPriceFileFormats: MemoizedSelector<StateWithPriceFile, Array<PriceFileFormat>> = createSelector(
  getPriceFileFormatsState,
  (state: ArrayState<PriceFileFormat>) => state && state.entities
);
