import { BaseSite } from '../../../model';
import { BaseSiteActionTypes } from '../actions/base-site.action';
import { SiteContextActions } from '../actions/index';
import { BaseSiteState } from '../site-context-state';

export const initialState: BaseSiteState = {
  entities: null,
  details: {},
  activeSite: '',
  isFallback: false,
};

export function reducer(state = initialState, action: SiteContextActions.BaseSiteActions): BaseSiteState {
  switch (action.type) {
    case BaseSiteActionTypes.LoadBaseSiteSuccess: {
      return {
        ...state,
        details: action.payload,
      };
    }

    case BaseSiteActionTypes.SetActiveBaseSite: {
      // if active base site is updated,
      // the active base site details data should also be updated
      let details = {};
      if (state.entities) {
        details = state.entities[action.payload];
      }
      return {
        ...state,
        details,
        activeSite: action.payload,
      };
    }

    case BaseSiteActionTypes.LoadBaseSitesSuccess: {
      const sites: BaseSite[] = action.payload;
      const entities = sites.reduce(
        (siteEntities: { [uid: string]: BaseSite }, site: BaseSite) => {
          return {
            ...siteEntities,
            [site.uid]: site,
          };
        },
        {
          ...state.entities,
        }
      );
      // after base sites entities are populated,
      // the active base site details data is also populated
      const details = entities[state.activeSite];

      return {
        ...state,
        details,
        entities,
      };
    }

    case BaseSiteActionTypes.SetIsFallbackBaseSite: {
      const isFallbackSite: boolean = action.payload;
      return {
        ...state,
        isFallback: isFallbackSite,
      };
    }
  }

  return state;
}
