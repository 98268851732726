import { APP_INITIALIZER, Provider } from '@angular/core';
import { ConfigInitializerService } from '@spartacus/core';
import { tap } from 'rxjs/operators';
import { BaseSiteService } from '../facade/base-site.service';
import { CurrencyService } from '../facade/currency.service';
import { LanguageService } from '../facade/language.service';
import { SitePrefixService } from '../facade/site-prefix.service';
import { SiteContextRoutesHandler } from '../services/site-context-routes-handler';

export function initializeContext(configInit: ConfigInitializerService, siteContextRoutesHandler: SiteContextRoutesHandler) {
  return () => {
    return configInit
      .getStable('context')
      .pipe(
        tap(() => {
          // TODO(#12351): move it to the logic of specific context initializers
          siteContextRoutesHandler.init();
        })
      )
      .toPromise();
  };
}

export const contextServiceProviders: Provider[] = [
  BaseSiteService,
  LanguageService,
  CurrencyService,
  SitePrefixService,
  {
    provide: APP_INITIALIZER,
    useFactory: initializeContext,
    deps: [ConfigInitializerService, SiteContextRoutesHandler],
    multi: true,
  },
];
