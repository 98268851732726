import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../../core/i18n';
import { PipesModule } from '../../../pipes';
import { CatalogArticleEnvelopeComponent } from './catalog-article-envelope.component';
import { MediaUrlPipe } from './media-url.pipe';

@NgModule({
  imports: [CommonModule, NgbTooltipModule, I18nModule, NzIconModule, PipesModule],
  declarations: [CatalogArticleEnvelopeComponent, MediaUrlPipe],
  exports: [CatalogArticleEnvelopeComponent, MediaUrlPipe],
})
export class CatalogArticleEnvelopeModule {}
