import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AddonGroup, Category } from '../../../../core/model';
import { PrincipalConfigurationService, UserFacade } from '../../../../core/user';
import { DrawerPlacement, DrawerService } from '../../../../shared';

@Component({
  selector: 'py-menu-products',
  templateUrl: './menu-products.component.html',
  styleUrls: ['./menu-products.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuProductsComponent implements OnInit {
  @ViewChild('dropdown', { static: true }) dropdown: NgbDropdown;
  @ViewChild('drawerTemplate') drawerTemplate: TemplateRef<any>;
  @ViewChild('megamenuContainer', { read: ElementRef }) megamenuContainer: ElementRef<HTMLDivElement>;

  @Output() showChange = new EventEmitter<boolean>();

  @Input() show: boolean;
  @Input() title: string;

  activeAddonGroupId$ = new BehaviorSubject<string>('');
  activeCategoryCode$ = new BehaviorSubject<string>('');

  showMyAssortmentSubmenu: boolean;
  showCampaignsSubMenu: boolean;
  mya: boolean;

  enableTogglingOfCustomerAssortment$: Observable<boolean>;
  enableTogglingOfAddons$: Observable<boolean>;

  hasCustomerAssortment$: Observable<boolean>;
  hasAddons$: Observable<boolean>;

  constructor(
    private principalConfigurationService: PrincipalConfigurationService,
    private userService: UserFacade,
    private drawerService: DrawerService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.enableTogglingOfCustomerAssortment$ = this.principalConfigurationService.isEnabled('enableTogglingOfCustomerAssortment');
    this.enableTogglingOfAddons$ = this.principalConfigurationService.isEnabled('enableTogglingOfAddons');
    this.hasCustomerAssortment$ = this.userService.hasCustomerAssortment();
    this.hasAddons$ = this.userService.hasAddons();
  }

  setActiveCategoryCode(value: { item: Category; mya: boolean }) {
    this.cd.detach(); // avoid ExpressionChangedAfterItHasBeenCheckedError
    this.mya = value?.mya;
    this.activeCategoryCode$.next(value?.item.code);
    this.activeAddonGroupId$.next('');
    this.showMyAssortmentSubmenu = false;
    this.showCampaignsSubMenu = false;
    this.cd.detectChanges();
    this.cd.reattach();
    this.megamenuContainer?.nativeElement?.scrollTo(0, 0);
  }

  setActiveAddonGroupId(addonGroup: AddonGroup) {
    this.cd.detach(); // avoid ExpressionChangedAfterItHasBeenCheckedError
    this.activeAddonGroupId$.next(addonGroup?.id);
    this.activeCategoryCode$.next('');
    this.showMyAssortmentSubmenu = false;
    this.showCampaignsSubMenu = false;
    this.cd.detectChanges();
    this.cd.reattach();
  }

  toggleDrawer(): void {
    this.showChange.emit(true);

    this.drawerService
      .open({
        template: this.drawerTemplate,
        placement: DrawerPlacement.Top,
      })
      .pipe(take(1))
      .subscribe((drawer) => {
        drawer?.afterClose?.pipe(take(1)).subscribe(() => {
          this.showChange.emit(false);
          this.cd.detectChanges();
        });
      });
  }
}
