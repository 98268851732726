import { Injectable } from '@angular/core';
import { Occ } from '../../../../core/occ';
import { Converter } from '../../../../core/util';

@Injectable({ providedIn: 'root' })
export class CollectionValueNormalizer implements Converter<Occ.CollectionValue<string>, Array<string>> {
  convert(source: Occ.CollectionValue<any>, target?: any): any {
    if (source) {
      return source.value.map((a) => a.value);
    }

    return target;
  }
}
