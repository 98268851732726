import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SearchScoreModalComponent } from './search-score-modal.component';

@Component({
  selector: 'py-search-score-modal-wrapper',
  template: `@defer (on viewport) {
    <py-search-score-modal></py-search-score-modal>
    } @placeholder {
    <div></div>
    } `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, SearchScoreModalComponent],
})
export class SearchScoreModalWrapperComponent {
  constructor() {}
}
