import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SiteAdapter as CxSiteAdapter, SiteContextOccModule as CxSiteContextOccModule } from '@spartacus/core';
import { provideDefaultConfig } from '../../../config/config-providers';
import { SiteAdapter } from '../../../site-context/connectors/site.adapter';
import { defaultOccSiteContextConfig } from './default-occ-site-context-config';
import { OccSiteAdapter } from './occ-site.adapter';

@NgModule({
  imports: [CommonModule],
  exports: [CxSiteContextOccModule],
  providers: [
    provideDefaultConfig(defaultOccSiteContextConfig),
    {
      provide: SiteAdapter,
      useClass: OccSiteAdapter,
    },
    { provide: CxSiteAdapter, useExisting: OccSiteAdapter },
  ],
})
export class SiteContextOccModule {}
