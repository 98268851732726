import { Component, Input } from '@angular/core';

@Component({
  selector: 'py-non-returnable',
  templateUrl: './non-returnable.component.html',
  styleUrls: ['./non-returnable.component.scss'],
})
export class NonReturnableComponent {
  @Input() articleStatus: string;
  @Input() outOfStock: boolean;
}
