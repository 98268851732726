import { MemoizedSelector, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { EntriesState, PaginationState, SearchPageResultState, SearchState } from '../../../features/store/base-feature-state';
import { OrderCard, OrderCardAdminSearchParams, OrderCardConfiguration, Pagination } from '../../../model';
import { PyStateUtils } from '../../../state';
import { OrderCardConfigurationState, OrderCardState, OrderCardsAdminState, StateWithUser, UserState } from '../user-state';
import { getUserState } from './feature.selector';

export const getOrderCardState: MemoizedSelector<StateWithUser, OrderCardState> = createSelector(
  getUserState,
  (state: UserState) => state && state.orderCard
);

export const getOrderCardsAdminState: MemoizedSelector<StateWithUser, OrderCardsAdminState> = createSelector(
  getOrderCardState,
  (state: OrderCardState) => state.orderCardsAdmin || undefined
);

export const getAdminSearchResultEntities: MemoizedSelector<StateWithUser, EntriesState<OrderCard> | undefined> = createSelector(
  getOrderCardsAdminState,
  (state: OrderCardsAdminState) => (state && state.searchEntities) || undefined
);

export const getAdminSearchSelector: MemoizedSelector<StateWithUser, SearchState<SearchPageResultState> | undefined> =
  createSelector(getOrderCardsAdminState, (state: OrderCardsAdminState) => (state && state.search) || undefined);

export const getOrderCardAdminByCodeState = (code: string): MemoizedSelector<StateWithUser, StateUtils.LoaderState<OrderCard>> =>
  createSelector(
    getOrderCardsAdminState,
    (state) => (state.entities && PyStateUtils.entityLoaderStateSelector(state.entities, code)) || PyStateUtils.initialLoaderState
  );

export const getOrderCardAdminByCodeLoading = (code: string): MemoizedSelector<StateWithUser, boolean> =>
  createSelector(
    getOrderCardAdminByCodeState(code),
    (loaderState) => (loaderState && StateUtils.loaderLoadingSelector(loaderState)) || false
  );

export const getOrderCardAdminByCodeSuccess = (code: string): MemoizedSelector<StateWithUser, boolean> =>
  createSelector(
    getOrderCardAdminByCodeState(code),
    (loaderState) => (loaderState && StateUtils.loaderSuccessSelector(loaderState)) || false
  );

export const getAdminSearchPaginationStateSelector = (
  searchParams: OrderCardAdminSearchParams
): MemoizedSelector<StateWithUser, PaginationState<SearchPageResultState> | undefined> =>
  createSelector(getAdminSearchSelector, (state: SearchState<SearchPageResultState>) => state[searchParams.key] || undefined);

export const getAdminSearchPaginationSelector = (
  searchParams: OrderCardAdminSearchParams
): MemoizedSelector<StateWithUser, Pagination | undefined> =>
  createSelector(getAdminSearchPaginationStateSelector(searchParams), (state: PaginationState<SearchPageResultState>) => {
    return state?.pagination;
  });

export const getSearchPageResultsLoading = (searchParams: OrderCardAdminSearchParams): MemoizedSelector<StateWithUser, boolean> =>
  createSelector(
    getAdminSearchPageResultsSelectorFactory(searchParams),
    (loaderState) => (loaderState && StateUtils.loaderLoadingSelector(loaderState)) || false
  );

export const getAdminSearchPageResultsSelectorFactory = (
  searchParams: OrderCardAdminSearchParams
): MemoizedSelector<StateWithUser, StateUtils.LoaderState<SearchPageResultState> | undefined> =>
  createSelector(
    getAdminSearchPaginationStateSelector(searchParams),
    (paginationState: PaginationState<SearchPageResultState>) => {
      return (searchParams && paginationState && paginationState.pages && paginationState.pages[searchParams.page]) || undefined;
    }
  );

export const getAdminSearchResultFactory = (
  searchParams: OrderCardAdminSearchParams
): MemoizedSelector<StateWithUser, SearchPageResultState | undefined> =>
  createSelector(
    getAdminSearchPageResultsSelectorFactory(searchParams),
    (loaderState: StateUtils.LoaderState<SearchPageResultState>) => {
      return (loaderState && StateUtils.loaderValueSelector(loaderState)) || undefined;
    }
  );

export const getAdminSearchResultPage = (
  searchParams: OrderCardAdminSearchParams
): MemoizedSelector<StateWithUser, Array<string> | undefined> =>
  createSelector(getAdminSearchResultFactory(searchParams), (state: SearchPageResultState) => {
    return (state && state.results) || undefined;
  });

export const getAdminSearchResultEntries = (
  searchParams: OrderCardAdminSearchParams
): MemoizedSelector<StateWithUser, OrderCard[] | undefined> =>
  createSelector(
    getAdminSearchResultPage(searchParams),
    getAdminSearchResultEntities,
    (codes: Array<string>, entries: EntriesState<OrderCard>) => {
      return (codes && entries && codes.map((i) => entries[i])) || undefined;
    }
  );

export const getOrderCardConfigurantionState: MemoizedSelector<StateWithUser, OrderCardConfigurationState> = createSelector(
  getOrderCardsAdminState,
  (state: OrderCardsAdminState) => state.orderCardConfiguration || undefined
);

export const getOrderCardAdminConfigurantionEntityState = (
  key: string
): MemoizedSelector<StateWithUser, StateUtils.LoaderState<OrderCardConfiguration>> =>
  createSelector(
    getOrderCardConfigurantionState,
    (state) =>
      (state && state.entities && PyStateUtils.entityLoaderStateSelector(state.entities, key)) || PyStateUtils.initialLoaderState
  );

export const getOrderCardAdminConfigurantionListState = (
  key: string
): MemoizedSelector<StateWithUser, StateUtils.LoaderState<any>> =>
  createSelector(
    getOrderCardConfigurantionState,
    (state) => (state && state.list && PyStateUtils.entityLoaderStateSelector(state.list, key)) || PyStateUtils.initialLoaderState
  );

export const getOrderCardAdminConfigurantionLoadingState = (key: string): MemoizedSelector<StateWithUser, boolean> =>
  createSelector(
    getOrderCardAdminConfigurantionEntityState(key),
    (loaderState) => (loaderState && StateUtils.loaderLoadingSelector(loaderState)) || false
  );
