import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { DrawerFooterComponent } from './drawer-footer.component';

@NgModule({
  imports: [CommonModule, NzIconModule],
  declarations: [DrawerFooterComponent],
  exports: [DrawerFooterComponent],
})
export class DrawerFooterModule {}
