import { AuthActions } from '@spartacus/core';

export enum LoginLogoutActionTypes {
  Login = '[Auth] Login',
  Logout = '[Auth] Logout',
}

export import Login = AuthActions.Login;
export import Logout = AuthActions.Logout;

export import LoginLogoutActions = AuthActions.LoginLogoutAction;
