import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import { PyStateUtils } from '../../../state';
import { FORGOT_PASSWORD_PROCESS } from '../user-state';

export enum ForgotPasswordActionTypes {
  RequestForgottenPasswordEmail = '[User] Request forgotten password email',
  RequestForgottenPasswordEmailSuccess = '[User] Request forgotten password email success',
  RequestForgottenPasswordEmailFail = '[User] Request forgotten password email fail',
  RequestForgottenPasswordEmailReset = '[User] Request forgotten password email reset',
}

export class ForgotPasswordEmailRequest extends StateUtils.EntityLoadAction {
  readonly type = ForgotPasswordActionTypes.RequestForgottenPasswordEmail;

  constructor(public payload: { email: string; username?: string }) {
    super(PROCESS_FEATURE, FORGOT_PASSWORD_PROCESS);
  }
}

export class ForgotPasswordEmailRequestFail extends PyStateUtils.EntityFailAction {
  readonly type = ForgotPasswordActionTypes.RequestForgottenPasswordEmailFail;

  constructor(public payload?: any) {
    super(PROCESS_FEATURE, FORGOT_PASSWORD_PROCESS, payload);
  }
}

export class ForgotPasswordEmailRequestSuccess extends StateUtils.EntitySuccessAction {
  readonly type = ForgotPasswordActionTypes.RequestForgottenPasswordEmailSuccess;

  constructor() {
    super(PROCESS_FEATURE, FORGOT_PASSWORD_PROCESS);
  }
}

export class ForgotPasswordEmailRequestReset extends StateUtils.EntityLoaderResetAction {
  readonly type = ForgotPasswordActionTypes.RequestForgottenPasswordEmailReset;

  constructor() {
    super(PROCESS_FEATURE, FORGOT_PASSWORD_PROCESS);
  }
}

export type ForgotPasswordEmailRequestAction =
  | ForgotPasswordEmailRequest
  | ForgotPasswordEmailRequestFail
  | ForgotPasswordEmailRequestSuccess
  | ForgotPasswordEmailRequestReset;
