import { Injectable } from '@angular/core';
import { Product } from '../../../model';
import { Occ } from '../../../occ';
import { Converter } from '../../../util/converter.service';

@Injectable({ providedIn: 'root' })
export class ProductNormalizer implements Converter<Occ.Product, Product> {
  convert(source: Occ.Product, target?: Product): Product {
    if (source) {
      const { articles, displayCategoryCode, displayNewProductOverlay, ...src } = source;
      return {
        ...src,
        articles: articles ? articles.map((c) => c.code) : [],
        categoryRef: displayCategoryCode,
        overlayNew: displayNewProductOverlay,
      };
    }

    return target;
  }
}
