import { Action } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { SolrSearchResult, SolrSearchType } from '../../../model/solr-search-config';
import { PyStateUtils } from '../../../state';
import { SolrSearchActions } from '../actions';
import { SearchByQueryState } from '../solr-search-state';
import { initialState as initialTotalCountState, reducer as totalCountReducer } from './solr-search-total-count.reducer';

export const initialState: SolrSearchResult = {};

export function reducer(
  articleReducer: (state: StateUtils.LoaderState<SolrSearchResult>, action: Action) => StateUtils.LoaderState<SolrSearchResult>,
  productReducer: (state: StateUtils.LoaderState<SolrSearchResult>, action: Action) => StateUtils.LoaderState<SolrSearchResult>,
  defaultReducer: (state: StateUtils.LoaderState<SolrSearchResult>, action: Action) => StateUtils.LoaderState<SolrSearchResult>
) {
  return (state: SearchByQueryState = {}, action: Action): SearchByQueryState => {
    switch (action.type) {
      case SolrSearchActions.SolrSearchActionTypes.LoadSolrSearch:
      case SolrSearchActions.SolrSearchActionTypes.LoadSolrSearchSuccess:
      case SolrSearchActions.SolrSearchActionTypes.LoadSolrSearchFail: {
        const searchAction: SolrSearchActions.LoadSolrSearchSuccess = <SolrSearchActions.LoadSolrSearchSuccess>action;
        let query = searchAction.query;
        let searchType: SolrSearchType = searchAction?.searchType;

        if (action.type === SolrSearchActions.SolrSearchActionTypes.LoadSolrSearch) {
          const loadAction: SolrSearchActions.LoadSolrSearch = <SolrSearchActions.LoadSolrSearch>action;
          searchType = loadAction.config.searchType;
          query = loadAction.query;
        }

        const queryState = state[query];
        let newState: SearchByQueryState = state;
        switch (searchType) {
          case SolrSearchType.ARTICLE: {
            newState = {
              ...state,
              [query]: {
                ...queryState,
                totalCount: !queryState?.totalCount ? initialTotalCountState : queryState.totalCount,
                article: articleReducer({ ...(queryState?.article ?? PyStateUtils.initialLoaderState) }, action),
              },
            };
            break;
          }
          case SolrSearchType.PRODUCT: {
            newState = {
              ...state,
              [query]: {
                ...queryState,
                totalCount: !queryState?.totalCount ? initialTotalCountState : queryState.totalCount,
                product: productReducer({ ...(queryState?.product ?? PyStateUtils.initialLoaderState) }, action),
              },
            };
            break;
          }
          case SolrSearchType.DEFAULT: {
            newState = {
              ...state,
              [query]: {
                ...queryState,
                totalCount: !queryState?.totalCount ? initialTotalCountState : queryState.totalCount,
                default: defaultReducer({ ...(queryState?.default ?? PyStateUtils.initialLoaderState) }, action),
              },
            };
            break;
          }
        }
        return onlyStore50Results(newState);
      }
      case SolrSearchActions.SolrSearchActionTypes.ClearSearch: {
        return {};
      }
      case SolrSearchActions.SolrSearchActionTypes.LoadSolrSearchTotalCountSuccess: {
        const successAction: SolrSearchActions.LoadSolrSearchTotalCountSuccess = <
          SolrSearchActions.LoadSolrSearchTotalCountSuccess
        >action;
        const queryState = state[successAction.query];
        return {
          ...state,
          [successAction.query]: {
            ...queryState,
            totalCount: totalCountReducer()(queryState?.totalCount, action),
          },
        };
      }
    }

    return state;
  };
}

const onlyStore50Results = (state: SearchByQueryState): SearchByQueryState => {
  const newState: SearchByQueryState = {};
  Object.entries(state)
    .slice(-50)
    .map(([key, value]) => {
      newState[key] = value;
    });
  return newState;
};
