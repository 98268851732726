import { StateUtils } from '@spartacus/core';
import { SearchTermFacetMappingHint, SolrSearchResult } from '../../model/solr-search-config';

export const SOLR_SEARCH_FEATURE = 'search';
export const SEARCH_HINTS = '[Search] hints';

export interface StateWithSolrSearch {
  [SOLR_SEARCH_FEATURE]: SolrSearchState;
}

export interface SolrSearchState {
  search: SearchByQueryState;
  suggestions: any; // TODO: ESVCX-677
  hints: StateUtils.LoaderState<SearchTermFacetMappingHint[]>;
}

export interface SearchByQueryState {
  [key: string]: {
    totalCount: TotalCountState;
    article: StateUtils.LoaderState<SolrSearchResult>;
    product: StateUtils.LoaderState<SolrSearchResult>;
    default: StateUtils.LoaderState<SolrSearchResult>;
  };
}

export interface TotalCountState {
  articles: number;
  products: number;
}
