import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PageContext, RoutingService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RoutingHelperService {
  constructor(protected router: Router, protected routingService: RoutingService) {}
  /**
   * Is search visible
   */

  searchBoxVisible(): Observable<boolean> {
    return this.routingService.getPageContext().pipe(
      filter((pageContext) => !!pageContext.type),
      map((pageContext) => this.isSearchVisible(pageContext))
    );
  }

  canShowActiveOrderProcessModal(): Observable<boolean> {
    return this.routingService.getPageContext().pipe(
      filter((pageContext) => !!pageContext.type),
      map((pageContext) => !['/checkout', '/payment-processing', '/order-confirmation'].includes(pageContext.id))
    );
  }

  languageSelectorVisible(): Observable<boolean> {
    return this.routingService.getPageContext().pipe(
      filter((pageContext) => !!pageContext.type),
      map((pageContext) => this.isLanguageSelectorVisible(pageContext))
    );
  }

  getCurrentUrlWithoutParams(): string {
    const urlTree = this.router.parseUrl(this.router.url);

    urlTree.queryParams = {};
    urlTree.fragment = null;

    return urlTree.toString();
  }

  private isSearchVisible(pageContext: PageContext): boolean {
    switch (pageContext.id) {
      case '/loading':
      case '/checkout':
      case '/order-confirmation':
      case '/payment-processing':
      case '/reset-password':
      case '/register': {
        return false;
      }
    }
    return !pageContext?.id?.includes('/g/legal');
  }

  private isLanguageSelectorVisible(pageContext: PageContext): boolean {
    switch (pageContext.id) {
      case '/checkout': {
        return false;
      }
    }
    return !pageContext?.id?.includes('/g/legal');
  }
}
