import { StateUtils } from '@spartacus/core';
import { AnalyticsMetadata } from '../../../occ/occ-models/occ-generated.models';
import { AnalyticsMetadataActionTypes } from '../actions/analytics-metadata.action';
import { UserActions } from '../actions/index';

export const initialState: AnalyticsMetadata = {};

export function reducer(
  state = initialState,
  action: UserActions.AnalyticsMetadataActions | StateUtils.LoaderAction
): AnalyticsMetadata {
  switch (action.type) {
    case AnalyticsMetadataActionTypes.LoadAnalyticsMetadataSuccess: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}
