import { NgModule } from '@angular/core';
import { provideDefaultConfig } from '@spartacus/core';
import { NavigationEvent } from '@spartacus/storefront';
import { TmsConfig } from '@spartacus/tracking/tms/core';
import { GaCollectorService } from './ga-collector.service';

@NgModule({
  providers: [
    provideDefaultConfig(<TmsConfig>{
      tagManager: {
        ga: {
          collector: GaCollectorService,
          events: [NavigationEvent],
        },
      },
    }),
  ],
})
export class GaModule {
  constructor() {}
}
