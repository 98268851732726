import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AuthService, RoutingService } from '@spartacus/core';
import { Observable, of, zip } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ArticleService } from '../../../core/catalog';
import {
  Article,
  ArticleCarouselActionMode,
  ArticleCarouselItem,
  ArticleStatus,
  SubstituteRef,
  SubstituteRefType,
} from '../../../core/model';
import { PrincipalConfigurationService, StockInfoFacade } from '../../../core/user';
import { prepareUrlForLink } from '../../../core/util';
import { resolveDefaultArticleQuantity } from '../../utils/resolve-default-article-quantity';
import { resolveDefaultArticleUnit } from '../../utils/resolve-default-article-unit';
import { allWarehousesHaveZeroStock } from '../../utils/stock-utils';

@Component({
  selector: 'py-catalog-similar-articles',
  templateUrl: './catalog-similar-articles.component.html',
  styleUrls: ['./catalog-similar-articles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogSimilarArticlesComponent implements OnInit {
  carouselItems$: Observable<ArticleCarouselItem[]>;
  carouselItemsLoading$: Observable<Boolean>;
  articlesLoading = false;
  substituteRefs: SubstituteRef[] = [];

  @Input() articleRef: string;
  @Input() queryParams: {
    [k: string]: any;
  };
  @Input() showMessageWhenEmpty: boolean = true;
  @Input() enableSecondaryArticleRowVariant = false;
  @Input() showTitle = true;
  @Input() showPaginationAtTheBottom = false;
  @Input() showAlternativeArticleBadge = false;
  @Input() useDefaultArticleQuantityAndUnit = false;
  @Input() carouselActionMode?: ArticleCarouselActionMode;

  constructor(
    private articleService: ArticleService,
    private routingService: RoutingService,
    private authService: AuthService,
    private stockInfoService: StockInfoFacade,
    private principalConfigurationService: PrincipalConfigurationService
  ) {}

  ngOnInit(): void {
    this.carouselItemsLoading$ = this.articleService.getSubstitutesArticlesLoading(this.articleRef);

    this.carouselItems$ = this.principalConfigurationService.isEnabled('enableDisplayAlternativeArticles').pipe(
      distinctUntilChanged(),
      switchMap((isDisplayAlternativeArticlesEnabled) => {
        return isDisplayAlternativeArticlesEnabled ? this.getCarouselItems() : of([]);
      })
    );
  }

  getCarouselItems(): Observable<ArticleCarouselItem[]> {
    return this.articleService.getSubstitutesArticles(this.articleRef, SubstituteRefType.Article).pipe(
      filter((substitutesArticles) => Boolean(substitutesArticles)),
      tap((substitutesArticles) => {
        this.substituteRefs = substitutesArticles;
      }),
      switchMap((substitutesArticles) =>
        substitutesArticles.length > 0
          ? zip(
              ...substitutesArticles.map((substitutesArticle) => {
                this.articlesLoading = true;

                return this.articleService.getArticle(substitutesArticle.code).pipe(
                  switchMap((article: Article) => {
                    // Don't show articles on failure etc, but still return _something_ so we always reset articlesLoading
                    if (!Boolean(article)) {
                      return of(null);
                    }
                    // We don't want to show discontinued articles.
                    if (this.articleService.isArticleDiscontinued(article)) {
                      return of(null);
                    }
                    // We allow all articles to be shown if the article status is not ZA or ZT.
                    if (![ArticleStatus.ZA, ArticleStatus.ZT].includes(article.articleStatus)) {
                      return of(article);
                    }

                    const defaultUnit = resolveDefaultArticleUnit(article, this.useDefaultArticleQuantityAndUnit);
                    const defaultQuantity = resolveDefaultArticleQuantity(
                      article,
                      defaultUnit,
                      this.useDefaultArticleQuantityAndUnit
                    );

                    // If the article status is ZA or ZT, we only show it if it is still in stock in at least one warehouse.
                    return this.stockInfoService
                      .getStockInfo(article.code, defaultQuantity, defaultUnit)
                      .pipe(map((stockInfo) => (allWarehousesHaveZeroStock(stockInfo) ? null : article)));
                  })
                );
              })
            ).pipe(
              tap(() => (this.articlesLoading = false)),
              map((articles) => articles.filter(Boolean))
            )
          : of([])
      ),
      map((articles: Article[]) => articles.filter((article) => Boolean(article))),
      map((articles) => articles?.map((article) => ({ article } as ArticleCarouselItem))),
      takeUntil(this.authService.logoutInProgress$.pipe(filter((logout) => !!logout)))
    );
  }

  onClick(carouselItem: ArticleCarouselItem): void {
    if (carouselItem.article.url) {
      this.routingService.goByUrl(prepareUrlForLink(carouselItem.article.url, this.queryParams));
    }
  }
}
