import { MemoizedSelector, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { LoaderError } from '../../../../model';
import { PriceFileEntry } from '../../../../model/price-file.model';
import { loaderErrorDetailsSelector } from '../../../../state/utils/loader';
import { PriceFileState, StateWithPriceFile } from '../price-file-state';
import { getPriceFileState } from './feature.selector';

export const getPriceFileRequestLoader: MemoizedSelector<
  StateWithPriceFile,
  StateUtils.LoaderState<PriceFileEntry>
> = createSelector(getPriceFileState, (state: PriceFileState) => state && state.priceFiles.request);

export const getPriceFileRequestFailure: MemoizedSelector<StateWithPriceFile, LoaderError | undefined> = createSelector(
  getPriceFileRequestLoader,
  (state: StateUtils.LoaderState<PriceFileEntry>) => (state ? loaderErrorDetailsSelector(state) : undefined)
);

export const getPriceFileRequestSuccess: MemoizedSelector<StateWithPriceFile, boolean> = createSelector(
  getPriceFileRequestLoader,
  (state: StateUtils.LoaderState<PriceFileEntry>) => state && StateUtils.loaderSuccessSelector(state)
);

export const getPriceFileRequestLoading: MemoizedSelector<StateWithPriceFile, boolean> = createSelector(
  getPriceFileRequestLoader,
  (state: StateUtils.LoaderState<PriceFileEntry>) => state && StateUtils.loaderLoadingSelector(state)
);
