import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import {
  EntriesState,
  PaginationState,
  SearchPageResultState,
  SearchState,
} from '../../../../core/features/store/base-feature-state';
import {
  EcConformityProductDocument,
  EcConformityProductDocumentsSearchParams,
  Pagination,
  ProductBrand,
  ProductDocument,
  ProductDocumentsWrapper,
} from '../../../../core/model';
import { PyStateUtils } from '../../../../core/state';
import { loaderErrorDetailsSelector } from '../../../../core/state/utils/loader';
import { ConformanceEcState, DOCUMENTS_FEATURE, DocumentsState, StateWithDocuments } from '../documents-state';
import { initialConformanceEcStateSearchParamsState } from '../reducers/search-conformance-ec-search-params.reducer';

export const getDocumentsState: MemoizedSelector<StateWithDocuments, DocumentsState> =
  createFeatureSelector<DocumentsState>(DOCUMENTS_FEATURE);

// Product Documents
const getProductDocumentsState: MemoizedSelector<
  StateWithDocuments,
  StateUtils.EntityLoaderState<ProductDocumentsWrapper>
> = createSelector(getDocumentsState, (state: DocumentsState) => state && state.productDocuments);

const getProductDocumentsLoaderState = (
  code: string
): MemoizedSelector<StateWithDocuments, StateUtils.LoaderState<ProductDocumentsWrapper>> => {
  return createSelector(
    getProductDocumentsState,
    (details) => PyStateUtils.entityLoaderStateSelector(details, code) || PyStateUtils.initialLoaderState
  );
};

const getProductDocumentsSuccess = (productCode: string): MemoizedSelector<StateWithDocuments, boolean> => {
  return createSelector(getProductDocumentsLoaderState(productCode), (state) => StateUtils.loaderSuccessSelector(state));
};

const getProductDocumentsLoading = (productCode: string): MemoizedSelector<StateWithDocuments, boolean> => {
  return createSelector(getProductDocumentsLoaderState(productCode), (state) => StateUtils.loaderLoadingSelector(state));
};

const getProductDocumentsWrapper = (productCode: string): MemoizedSelector<StateWithDocuments, ProductDocumentsWrapper> => {
  return createSelector(getProductDocumentsLoaderState(productCode), (state) => StateUtils.loaderValueSelector(state));
};

export const getProductDocuments = (
  productCode: string,
  articleCode?: string
): MemoizedSelector<StateWithDocuments, ProductDocument[]> => {
  return createSelector(getProductDocumentsWrapper(productCode), (wrapper) => {
    if (!articleCode) {
      return wrapper?.documents;
    }
    return wrapper?.documents?.filter((document) => {
      return (
        !document.entities?.articleRefs?.length ||
        document.entities?.articleRefs?.some((articleRef) => articleRef === articleCode)
      );
    });
  });
};

export const getProductDocumentsShouldLoad = (productCode: string): MemoizedSelector<StateWithDocuments, boolean> => {
  return createSelector(
    getProductDocumentsSuccess(productCode),
    getProductDocumentsLoading(productCode),
    (success, loading) => !success && !loading
  );
};

// Declaration Of Conformity
const getConformanceEcState: MemoizedSelector<StateWithDocuments, ConformanceEcState> = createSelector(
  getDocumentsState,
  (state: DocumentsState) => state && state.conformanceEc
);

const getConformanceEcSearchSelector: MemoizedSelector<StateWithDocuments, SearchState<SearchPageResultState> | undefined> =
  createSelector(getConformanceEcState, (state: ConformanceEcState) => (state && state.search) || undefined);

export const getConformanceEcEntities: MemoizedSelector<
  StateWithDocuments,
  EntriesState<EcConformityProductDocument> | undefined
> = createSelector(getConformanceEcState, (state: ConformanceEcState) => (state && state.entities) || undefined);

export const getConformanceEcSearchParams: MemoizedSelector<StateWithDocuments, EcConformityProductDocumentsSearchParams> =
  createSelector(
    getConformanceEcState,
    (state: ConformanceEcState) => (state && state.searchParams) || initialConformanceEcStateSearchParamsState
  );

const getConformanceEcBrandsState: MemoizedSelector<StateWithDocuments, StateUtils.LoaderState<ProductBrand[]>> = createSelector(
  getConformanceEcState,
  (state: ConformanceEcState) => (state && state.brands) || undefined
);

const getConformanceEcSearchPaginationStateSelector: MemoizedSelector<
  StateWithDocuments,
  PaginationState<SearchPageResultState> | undefined
> = createSelector(
  getConformanceEcSearchSelector,
  getConformanceEcSearchParams,
  (state: SearchState<SearchPageResultState>, searchParams: EcConformityProductDocumentsSearchParams) =>
    state[searchParams.key] || undefined
);

const getConformanceEcSearchPageResultsSelectorFactory: MemoizedSelector<
  StateWithDocuments,
  StateUtils.LoaderState<SearchPageResultState> | undefined
> = createSelector(
  getConformanceEcSearchPaginationStateSelector,
  getConformanceEcSearchParams,
  (paginationState: PaginationState<SearchPageResultState>, searchParams: EcConformityProductDocumentsSearchParams) => {
    return (searchParams && paginationState && paginationState.pages && paginationState.pages[searchParams.page]) || undefined;
  }
);

const getConformanceEcSearchResultFactory: MemoizedSelector<StateWithDocuments, SearchPageResultState | undefined> =
  createSelector(
    getConformanceEcSearchPageResultsSelectorFactory,
    (loaderState: StateUtils.LoaderState<SearchPageResultState>) => {
      return (loaderState && StateUtils.loaderValueSelector(loaderState)) || undefined;
    }
  );

const getAdminSearchResultPage: MemoizedSelector<StateWithDocuments, Array<string> | undefined> = createSelector(
  getConformanceEcSearchResultFactory,
  (state: SearchPageResultState) => {
    return (state && state.results) || undefined;
  }
);

export const getConformanceEcSearchPagination: MemoizedSelector<StateWithDocuments, Pagination | undefined> = createSelector(
  getConformanceEcSearchPaginationStateSelector,
  (state: PaginationState<SearchPageResultState>) => {
    return state?.pagination;
  }
);

export const getConformanceEcSearchPageResultsLoading: MemoizedSelector<StateWithDocuments, boolean> = createSelector(
  getConformanceEcSearchPageResultsSelectorFactory,
  (loaderState) => (loaderState && StateUtils.loaderLoadingSelector(loaderState)) || false
);

export const getConformanceEcSearchPageResultsLoaded: MemoizedSelector<StateWithDocuments, boolean> = createSelector(
  getConformanceEcSearchPageResultsSelectorFactory,
  (state) => {
    if (!state) {
      return false;
    }
    return !!StateUtils.loaderSuccessSelector(state) || !!loaderErrorDetailsSelector(state);
  }
);

export const getConformanceEcSearchResultEntries: MemoizedSelector<
  StateWithDocuments,
  EcConformityProductDocument[] | undefined
> = createSelector(
  getAdminSearchResultPage,
  getConformanceEcEntities,
  (codes: Array<string>, entries: EntriesState<EcConformityProductDocument>) => {
    return (codes && entries && codes.map((i) => entries[i])) || undefined;
  }
);

export const shouldSearchConformanceEc = (
  newSearchParams: EcConformityProductDocumentsSearchParams,
  oldSearchParams: EcConformityProductDocumentsSearchParams
): MemoizedSelector<StateWithDocuments, boolean> =>
  createSelector(getConformanceEcSearchSelector, (state: SearchState<SearchPageResultState>) => {
    const newSearchValue = JSON.stringify({ q: newSearchParams.q, brands: newSearchParams.brands });
    const oldSearchValue = JSON.stringify({ q: oldSearchParams.q, brands: oldSearchParams.brands });
    if (newSearchValue !== oldSearchValue) {
      return true;
    }

    const paginationState = state[newSearchParams.key];
    if (!!paginationState && paginationState && paginationState.pages && paginationState.pages[newSearchParams.page]) {
      return false;
    }

    return true;
  });

export const getConformanceEcBrands: MemoizedSelector<StateWithDocuments, ProductBrand[]> = createSelector(
  getConformanceEcBrandsState,
  (state) => (state && StateUtils.loaderValueSelector(state)) || undefined
);

export const getConformanceEcBrandsLoading: MemoizedSelector<StateWithDocuments, boolean> = createSelector(
  getConformanceEcBrandsState,
  (state) => (state && StateUtils.loaderLoadingSelector(state)) || false
);

const getConformanceEcBrandsSuccess: MemoizedSelector<StateWithDocuments, boolean> = createSelector(
  getConformanceEcBrandsState,
  (state) => (state && StateUtils.loaderSuccessSelector(state)) || false
);

export const getShouldLoadConformanceEcBrands: MemoizedSelector<StateWithDocuments, boolean> = createSelector(
  getConformanceEcBrandsLoading,
  getConformanceEcBrandsSuccess,
  (loading, success) => !loading && !success
);
