import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  AuthFlowRoutesService,
  AuthRedirectStorageService,
  AuthRedirectService as CxAuthRedirectService,
  RoutingService,
  StateWithProcess,
} from '@spartacus/core';
import { combineLatest, filter, take } from 'rxjs';
import { PrincipalConfigurationSelectors } from '../../../user/store/selectors/user.selectors';
import { StateWithUser } from '../../../user/store/user-state';
import { ViewLoadedService } from './view-loaded.service';

/**
 * Responsible for saving last accessed page (or attempted) before login and for redirecting to that page after login.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthRedirectService extends CxAuthRedirectService {
  /**
   * This service is responsible for remembering the last page before authentication. "The last page" can be:
   * 1. Just the previously opened page; or
   * 2. The page that we just tried to open, but AuthGuard cancelled it
   *
   * Then, after successful authentication it allows for redirecting to that remembered page via the `redirect()` method.
   *
   * For example:
   * 1. The user opens the product page, then clicks /login link and signs in
   *    -> Then we should redirect to the product page; or
   * 2. The user opens the product page, then he clicks /my-account link,
   *    but is automatically redirected to the login page by the AuthGuard, and he signs in
   *    -> Then we should redirect to the my-account page, not the product page
   */
  constructor(
    protected routing: RoutingService,
    protected router: Router,
    protected authRedirectStorageService: AuthRedirectStorageService,
    protected authFlowRoutesService: AuthFlowRoutesService,
    protected store: Store<StateWithUser & StateWithProcess<any>>,
    private viewLoadedService: ViewLoadedService
  ) {
    super(routing, router, authRedirectStorageService, authFlowRoutesService);
  }

  /**
   * Redirect to saved url (homepage if nothing is saved).
   */
  redirect() {
    combineLatest([
      this.authRedirectStorageService.getRedirectUrl(),
      this.store.pipe(
        select(PrincipalConfigurationSelectors.getSelectedPrincipalConfigurationValueFactory('redirectAfterLogin')),
        filter((redirectAfterLogin) => !!redirectAfterLogin)
      ),
      this.viewLoadedService.viewLoaded(),
    ])
      .pipe(take(1))
      .subscribe(([redirectUrl, redirectAfterLogin]) => {
        const regExCatalog = new RegExp(/.*mya=.*/);

        if (redirectUrl === undefined || !regExCatalog.test(redirectUrl)) {
          this.routing.go({ cxRoute: redirectAfterLogin?.value || 'dashboard' });
        } else {
          this.routing.goByUrl(redirectUrl);
        }
        this.clearRedirectUrl();
      });
  }
}
