import { Action } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { Cart, CartType } from '../../../../../core/model';
import { PyStateUtils } from '../../../../../core/state';
import { CART } from '../cart-state';

export enum CartActionTypes {
  LoadCart = '[Cart] Load cart',
  LoadCartSuccess = '[Cart] Load cart success',
  LoadCartFail = '[Cart] Load cart fail',

  RemoveCart = '[Cart] Remove cart',
  ResetCartDetails = '[Cart] Reset cart details',
  SetActiveCartId = '[Cart] Set active cart',
  ClearCartsState = '[Cart] Clear carts state',
}

interface LoadCartPayload {
  userId: string;
  cartType: CartType;
  cartId: string;
  extraData?: {
    active?: boolean;
  };
}

interface LoadCartFailPayload extends LoadCartPayload {
  error: any;
}

interface LoadCartSuccessPayload extends LoadCartPayload {
  cart: Cart;
}

interface RemoveCartPayload {
  cartType: CartType;
  cartId: string;
}

interface SetActiveCartIdPayload {
  cartType: CartType;
  cartId: string;
}

export class LoadCart extends StateUtils.EntityLoadAction {
  readonly type = CartActionTypes.LoadCart;

  constructor(public payload: LoadCartPayload) {
    super(CART, payload.cartId);
  }
}

export class LoadCartFail extends PyStateUtils.EntityFailAction {
  readonly type = CartActionTypes.LoadCartFail;

  constructor(public payload: LoadCartFailPayload) {
    super(CART, payload.cartId, payload.error);
  }
}

export class LoadCartSuccess extends StateUtils.EntitySuccessAction {
  readonly type = CartActionTypes.LoadCartSuccess;

  constructor(public payload: LoadCartSuccessPayload) {
    super(CART, payload.cartId);
  }
}

/**
 * Used for cleaning cart in local state, when we get information that it no longer exists in the backend.
 */
export class RemoveCart extends StateUtils.EntityRemoveAction {
  readonly type = CartActionTypes.RemoveCart;

  constructor(public payload: RemoveCartPayload) {
    super(CART, payload.cartId);
  }
}

export class SetActiveCartId implements Action {
  readonly type = CartActionTypes.SetActiveCartId;

  constructor(public payload: SetActiveCartIdPayload) {}
}

export class ResetCartDetails extends StateUtils.ProcessesLoaderResetAction {
  readonly type = CartActionTypes.ResetCartDetails;

  constructor() {
    super(CART);
  }
}

export class ClearCartsState extends StateUtils.EntityRemoveAllAction {
  readonly type = CartActionTypes.ClearCartsState;

  constructor() {
    super(CART);
  }
}

export type CartActions =
  | LoadCart
  | LoadCartFail
  | LoadCartSuccess
  | RemoveCart
  | SetActiveCartId
  | ResetCartDetails
  | ClearCartsState;
