import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { I18nModule } from '../../../core/i18n';
import { IconThemeReverseModule } from '../../directives';
import { ReactiveFormInputFieldModule } from '../reactive-forms-input/reactive-form-input-field/reactive-form-input-field.module';
import { SpinnerModule } from '../spinner';
import { AddToShoppingListComponent } from './add-to-shopping-list.component';

@NgModule({
  declarations: [AddToShoppingListComponent],
  imports: [
    CommonModule,
    I18nModule,
    NgbPopoverModule,
    NzIconModule,
    ReactiveFormInputFieldModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    SpinnerModule,
    NgbTooltipModule,
    IconThemeReverseModule,
  ],
  providers: [],
  exports: [AddToShoppingListComponent],
})
export class AddToShoppingListModule {}
