import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        passwordReset: {
          module: () => import('./password-reset.module').then((m) => m.PasswordResetModule),
          cmsComponents: ['CmsPasswordResetComponent'],
        },
      },
    }),
  ],
})
export class PasswordResetFeatureModule {}
