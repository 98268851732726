import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import {
  AvailableBuyersSearchParams,
  B2BComment,
  CartSimulation,
  LoaderError,
  Order,
  OrderApproval,
  OrderApprovalDecision,
  OrderApprovalSearchParams,
  OrderDetails,
  OrderProcessStatus,
  Pagination,
  SimpleCustomer,
  Sort,
} from '../../model';
import { USER_BASE_FEATURE } from '../store/user-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: OrderApprovalFacade,
      feature: USER_BASE_FEATURE,
      async: true,
      methods: [
        'loadApprovedOrderApprovalDetails',
        'getApprovedOrderApprovalDetails',
        'loadOrderApproval',
        'getOrderApproval',
        'recoverOrderApproval',
        'abortOrderApprovalHijack',
        'deleteOrderApproval',
        'getDeleteOrderApprovalLoading',
        'loadOrderApprovalDetail',
        'getOrderApprovalDetail',
        'getOrderApprovalDetailLoading',
        'loadB2BComments',
        'getB2BComments',
        'getHasApprovalOrderLineErrors',
        'loadOrderProcessing',
        'getOrderProcessing',
        'loadOrderApprovalSimulation',
        'getOrderApprovalSimulation',
        'updateSearchParameters',
        'getSearchParameters',
        'getPagination',
        'loadOrderApprovals',
        'getOrderApprovals',
        'getOrderApprovalsLoading',
        'makeDecision',
        'getMakeDecisionResultLoading',
        'getMakeDecisionResultError',
        'getMakeDecisionResultSuccess',
        'resetMakeDecisionProcessState',
        'getOrderApprovalDetailsLoading',
        'getOrderApprovalDetailsError',
        'getOrderApprovalDetailsSuccess',
        'resetApprovedOrderApprovalDetailsState',
        'getOrderApprovalLoading',
        'getOrderApprovalError',
        'getOrderApprovalSuccess',
        'resetOrderApprovalState',
        'getRecoverOrderApprovalLoading',
        'getRecoverOrderApprovalError',
        'getRecoverOrderApprovalSuccess',
        'resetRecoverOrderApprovalState',
        'getOrderApprovalSimulationLoading',
        'getOrderApprovalSimulationError',
        'getOrderApprovalSimulationSuccess',
        'resetOrderApprovalSimulationState',
        'getB2BCommentsLoading',
        'getB2BCommentsError',
        'getB2BCommentsSuccess',
        'resetB2BCommentsState',
        'getAbortOrderApprovalHijackLoading',
        'getAbortOrderApprovalHijackError',
        'getAbortOrderApprovalHijackSuccess',
        'resetAbortOrderApprovalHijackState',
        'resetOrderApprovalDetailsState',
        'getSorts',
        'searchForAvailableBuyers',
        'getSearchParametersForAvailableBuyers',
        'updateSearchParametersForAvailableBuyers',
        'getAvailableBuyers',
        'getAvailableBuyersLoading',
        'getAvailableBuyersPagination',
        'getStatusForOrderApprovalCode',
      ],
    }),
})
export abstract class OrderApprovalFacade {
  abstract loadApprovedOrderApprovalDetails(sapOrderNumber: string): void;
  abstract getApprovedOrderApprovalDetails(sapOrderNumber: string): Observable<OrderDetails>;
  abstract loadOrderApproval(orderApprovalCode: string): void;
  abstract getOrderApproval(orderApprovalCode: string): Observable<Order>;
  abstract recoverOrderApproval(orderApprovalCode: string, cartId: string): void;
  abstract abortOrderApprovalHijack(orderApprovalCode: string, cartId: string): void;
  abstract deleteOrderApproval(orderApprovalCode: string): void;
  abstract getDeleteOrderApprovalLoading(orderApprovalCode: string): Observable<boolean>;
  abstract loadOrderApprovalDetail(orderNumber: string): void;
  abstract getOrderApprovalDetail(orderNumber: string): Observable<OrderApproval>;
  abstract getOrderApprovalDetailLoading(orderNumber: string): Observable<boolean>;
  abstract loadB2BComments(code: string, sapOrder: boolean): void;
  abstract getB2BComments(code: string): Observable<Array<B2BComment>>;
  abstract getHasApprovalOrderLineErrors(code: string): Observable<boolean>;
  abstract loadOrderProcessing(orderApprovalCode: string): void;
  abstract getOrderProcessing(orderApprovalCode: string): Observable<OrderProcessStatus>;
  abstract loadOrderApprovalSimulation(orderApprovalCode: string): void;
  abstract getOrderApprovalSimulation(orderApprovalCode: string): Observable<CartSimulation>;
  abstract updateSearchParameters(searchParams: OrderApprovalSearchParams): void;
  abstract getSearchParameters(): Observable<OrderApprovalSearchParams>;
  abstract getPagination(): Observable<Pagination>;
  abstract loadOrderApprovals(params: OrderApprovalSearchParams): void;
  abstract getOrderApprovals(_params?: OrderApprovalSearchParams): Observable<Array<Observable<OrderApproval>>>;
  abstract getOrderApprovalsLoading(): Observable<boolean>;
  abstract makeDecision(orderApprovalCode: string, orderApprovalDecision: OrderApprovalDecision): void;
  abstract getMakeDecisionResultLoading(orderApprovalCode: string): Observable<boolean>;
  abstract getMakeDecisionResultError(orderApprovalCode: string): Observable<LoaderError | undefined>;
  abstract getMakeDecisionResultSuccess(orderApprovalCode: string): Observable<boolean>;
  abstract resetMakeDecisionProcessState(orderApprovalCode: string): void;
  abstract getOrderApprovalDetailsLoading(orderApprovalCode: string): Observable<boolean>;
  abstract getOrderApprovalDetailsError(orderApprovalCode: string): Observable<LoaderError | undefined>;
  abstract getOrderApprovalDetailsSuccess(orderApprovalCode: string): Observable<boolean>;
  abstract resetApprovedOrderApprovalDetailsState(orderApprovalCode: string): void;
  abstract getOrderApprovalLoading(orderApprovalCode: string): Observable<boolean>;
  abstract getOrderApprovalError(orderApprovalCode: string): Observable<LoaderError | undefined>;
  abstract getOrderApprovalSuccess(orderApprovalCode: string): Observable<boolean>;
  abstract resetOrderApprovalState(orderApprovalCode: string): void;
  abstract getRecoverOrderApprovalLoading(orderApprovalCode: string): Observable<boolean>;
  abstract getRecoverOrderApprovalError(orderApprovalCode: string): Observable<LoaderError | undefined>;
  abstract getRecoverOrderApprovalSuccess(orderApprovalCode: string): Observable<boolean>;
  abstract resetRecoverOrderApprovalState(orderApprovalCode: string): void;
  abstract getOrderApprovalSimulationLoading(orderApprovalCode: string): Observable<boolean>;
  abstract getOrderApprovalSimulationError(orderApprovalCode: string): Observable<LoaderError | undefined>;
  abstract getOrderApprovalSimulationSuccess(orderApprovalCode: string): Observable<boolean>;
  abstract resetOrderApprovalSimulationState(orderApprovalCode: string): void;
  abstract getB2BCommentsLoading(orderApprovalCode: string): Observable<boolean>;
  abstract getB2BCommentsError(orderApprovalCode: string): Observable<LoaderError | undefined>;
  abstract getB2BCommentsSuccess(orderApprovalCode: string): Observable<boolean>;
  abstract resetB2BCommentsState(orderApprovalCode: string): void;
  abstract getAbortOrderApprovalHijackLoading(cartId: string): Observable<boolean>;
  abstract getAbortOrderApprovalHijackError(cartId: string): Observable<LoaderError | undefined>;
  abstract getAbortOrderApprovalHijackSuccess(cartId: string): Observable<boolean>;
  abstract resetAbortOrderApprovalHijackState(cartId: string): void;
  abstract resetOrderApprovalDetailsState(orderApprovalCode: string): void;
  abstract getSorts(): Observable<Sort[]>;
  abstract searchForAvailableBuyers(searchParams: AvailableBuyersSearchParams): void;
  abstract getSearchParametersForAvailableBuyers(): Observable<AvailableBuyersSearchParams>;
  abstract updateSearchParametersForAvailableBuyers(searchParams: AvailableBuyersSearchParams): void;
  abstract getAvailableBuyers(): Observable<Array<SimpleCustomer>>;
  abstract getAvailableBuyersLoading(): Observable<boolean>;
  abstract getAvailableBuyersPagination(): Observable<Pagination | undefined>;
  abstract getStatusForOrderApprovalCode(orderCode: string): Observable<string>;
}
