import { Injectable } from '@angular/core';
import {
  BreadcrumbMeta,
  CanonicalPageResolver,
  PageBreadcrumbResolver,
  PageDescriptionResolver,
  PageLinkService,
  PageMetaResolver,
  PageRobotsMeta,
  PageRobotsResolver,
  PageTitleResolver,
  RouterState,
  RoutingService,
} from '@spartacus/core';
import { Observable, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, expand, map, shareReplay, switchMap } from 'rxjs/operators';
import { OpengraphMeta, TwitterCardType, TwitterMeta } from '../../../cms/model';
import { AltHrefLangPageResolver, PageOpengraphResolver, PageTwitterResolver } from '../../../cms/page';
import { BasePageMetaResolver } from '../../../cms/page/base-page-meta.resolver';
import { TranslationService } from '../../../i18n/services';
import { Category, CategoryRef, PageType, Product } from '../../../model';
import { BaseSite } from '../../../model/misc.model';
import { AltHrefLang } from '../../../occ/occ-models/occ-generated.models';
import { SemanticPathService } from '../../../routing';
import { BaseSiteService, SitePrefixService } from '../../../site-context';
import { shallowEqualObjects } from '../../../util';
import { CategoryService } from '../../category/services';
import { CatalogRouterStateService } from '../../services';
import { ProductService } from './product.service';

@Injectable({
  providedIn: 'root',
})
export class ProductPageMetaResolver
  extends PageMetaResolver
  implements
    PageTitleResolver,
    PageDescriptionResolver,
    PageBreadcrumbResolver,
    PageRobotsResolver,
    CanonicalPageResolver,
    PageOpengraphResolver,
    AltHrefLangPageResolver,
    PageTwitterResolver
{
  protected product$: Observable<Product> = this.routingService.getRouterState().pipe(
    map((routerState) => routerState?.state),
    map((state) => this.catalogRouterStateService.getProductCode(state.params, state.queryParams)),
    switchMap((code) => (code !== undefined ? this.productService.getProduct(code) : of(undefined))),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  protected category$: Observable<Category> = this.routingService.getRouterState().pipe(
    map((routerState) => routerState?.state),
    map((state) => this.catalogRouterStateService.getLeafCategoryCode(state.params, state.queryParams)),
    switchMap((code) => (code !== undefined ? this.categoryService.getCategory(code) : of(undefined)))
  );
  protected activeTabLabel$: Observable<string> = this.routingService.getRouterState().pipe(
    map((state) => state.state.queryParams['tab']),
    map((activeTab) => (activeTab === 'a' ? 'catalog.articles_tab' : 'catalog.products_tab')),
    switchMap((translationKey) => this.translation.translate(translationKey))
  );

  constructor(
    protected routingService: RoutingService,
    protected productService: ProductService,
    protected categoryService: CategoryService,
    protected translation: TranslationService,
    protected basePageMetaResolver: BasePageMetaResolver,
    protected pageLinkService: PageLinkService,
    protected sitePrefixService: SitePrefixService,
    protected semanticPathService: SemanticPathService,
    protected catalogRouterStateService: CatalogRouterStateService,
    protected baseSiteService: BaseSiteService
  ) {
    super();
    this.pageType = PageType.CONTENT_PAGE;
    this.pageTemplate = 'CatalogPageTemplate';
  }

  resolveTitle(): Observable<string | undefined> {
    return combineLatest([
      this.category$.pipe(distinctUntilChanged(shallowEqualObjects)),
      this.product$.pipe(distinctUntilChanged(shallowEqualObjects)),
      this.activeTabLabel$.pipe(distinctUntilChanged()),
      this.translation.translate('common.brandName_hint'),
    ]).pipe(
      map(([c, p, activeTabLabel, brandName]) => {
        let name = p?.name || c?.name || activeTabLabel || undefined;
        if (!name) {
          return brandName;
        }
        const maxLength = 60;
        const brandNameValue = ` | ${brandName}`;
        const ellipsis = '...';

        let value = `${name}${brandNameValue}`;

        if (value.length > maxLength) {
          name = name.slice(0, maxLength - brandNameValue.length - ellipsis.length);
          value = `${name}${ellipsis}${brandNameValue}`;
        }

        return value;
      })
    );
  }

  resolveDescription(): Observable<string | undefined> {
    return combineLatest([this.category$.pipe(), this.product$.pipe()]).pipe(
      map(([c, p]) => {
        const value = p?.description || c?.description || undefined;

        if (value?.length > 160) {
          return `${value.slice(0, 157)}...`;
        }

        return value;
      })
    );
  }

  resolveBreadcrumbs(): Observable<BreadcrumbMeta[] | undefined> {
    return combineLatest([
      this.category$.pipe(),
      this.product$.pipe(),
      this.translation.translate('catalog.categories_heading'),
      this.sitePrefixService.getActive(),
    ]).pipe(
      map(([c, p, label, activeSitePrefix]: [Category, Product, string, string]) => {
        const breadcrumbs = [];
        if (c !== undefined || p !== undefined) {
          this.resolveCategoryBreadcrumbs(p?.categoryRef || c.code).subscribe((data) => {
            breadcrumbs.push({
              label: data.name || data.code,
              link: `/${activeSitePrefix}/${data.url}`,
            });
          });
          const catalogKeyword = this.semanticPathService.transform({ cxRoute: 'catalog' })[1];
          breadcrumbs.reverse();
          breadcrumbs.unshift({ label: label, link: `/${activeSitePrefix}/${catalogKeyword}` });
        }
        return breadcrumbs;
      })
    );
  }

  private resolveCategoryBreadcrumbs(categoryCode: CategoryRef): Observable<{ name: string; code: string; url: string }> {
    return this.resolveCategoryBreadcrumb(categoryCode).pipe(
      expand(({ superCategoryRef }) => {
        return superCategoryRef ? this.resolveCategoryBreadcrumb(superCategoryRef as CategoryRef) : of();
      })
    );
  }

  private resolveCategoryBreadcrumb(
    categoryCode: CategoryRef
  ): Observable<{ name: string; code: string; url: string; superCategoryRef: string }> {
    return this.categoryService.getCategory(categoryCode).pipe(
      map((category) => {
        return {
          name: category.name,
          code: category.code,
          url: category.url,
          superCategoryRef: category.superCategoryRef,
        };
      })
    );
  }

  resolveCanonicalUrl(): Observable<string | undefined> {
    return this.basePageMetaResolver.resolveCanonicalUrl({ removeQueryParams: true });
  }

  resolveAltHrefLangs(): Observable<AltHrefLang[]> {
    return combineLatest([this.category$, this.product$]).pipe(
      map(([category, product]) => {
        const value = product?.seo?.altHrefLangs || category?.seo?.altHrefLangs;

        return value;
      })
    );
  }

  resolveRobots(): Observable<PageRobotsMeta[] | undefined> {
    return this.basePageMetaResolver.resolveRobots();
  }

  resolveOpengraph(): Observable<OpengraphMeta> {
    return combineLatest([
      this.product$.pipe(),
      this.translation.translate('socialMeta.siteName_hint'),
      this.routingService.getRouterState().pipe(),
      this.baseSiteService.get(),
    ]).pipe(
      map(([p, siteName, routerState, baseSite]: [Product, string, RouterState, BaseSite]) => {
        const cdnBaseUrl = baseSite.cdnBaseUrl ?? '';
        return {
          type: 'website',
          title: p?.name || undefined,
          siteName: siteName,
          description: p?.description || undefined,
          image: (p?.images?.[0]?.url && `${cdnBaseUrl}${p?.images[0]?.url}`) || undefined,
          altImage: p?.images?.[0]?.altText,
          url: routerState.state.url,
        };
      })
    );
  }

  resolveTwitter(): Observable<TwitterMeta> {
    return combineLatest([
      this.product$.pipe(),
      this.translation.translate('socialMeta.siteName_hint'),
      this.translation.translate('socialMeta.siteCreator_hint'),
      this.baseSiteService.get(),
    ]).pipe(
      map(([p, siteName, siteCreator, baseSite]: [Product, string, string, BaseSite]) => {
        const cdnBaseUrl = baseSite.cdnBaseUrl ?? '';
        return {
          card: p?.images && p?.images[0]?.url ? TwitterCardType.SummaryLargeImage : TwitterCardType.Summary,
          site: siteName,
          creator: siteCreator,
          title: p?.name || undefined,
          description: p?.description || undefined,
          image: (p?.images?.[0]?.url && `${cdnBaseUrl}${p?.images[0]?.url}`) || undefined,
          altImage: p?.images?.[0]?.altText,
        };
      })
    );
  }
}
