import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

export enum DrawerselectTemplateType {
  DEFAULT = 'DEFAULT',
  ORDERS_LIST = 'ORDERS_LIST',
}

interface Option {
  value: string;
  label: string;
  additionalLabels?: string[];
}

@Component({
  selector: 'py-drawerselect',
  templateUrl: './drawerselect.component.html',
  styleUrls: ['./drawerselect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DrawerselectComponent),
      multi: true,
    },
  ],
})
export class DrawerselectComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() title?: string;
  @Input() label = '';
  @Input() options: Option[];
  @Input() enableSearch = true;
  @Input() showOptionsCount = true;
  @Input() set initialCollapsed(initialCollapsed: boolean) {
    this.isCollapsed = initialCollapsed;
  }
  @Input() loading?: boolean;
  @Input() additionalLabelsAmount = 0;
  @Input() customSearchHandling = false;
  @Input() customSearchPlaceholder?: string;
  @Input() enableShowMore = false;
  @Input() initialSearchInputText?: string;
  @Input() multi?: boolean = true;
  @Input() templateType?: DrawerselectTemplateType = DrawerselectTemplateType.DEFAULT;

  @Output() search = new EventEmitter<string>();
  @Output() showMore = new EventEmitter<string>();

  drawerselectTemplateTypes = DrawerselectTemplateType;
  isCollapsed = true;

  searchFormGroup: UntypedFormGroup = new UntypedFormGroup({
    text: new UntypedFormControl(''),
  });

  get searchValueControl(): UntypedFormControl {
    return this.searchFormGroup.get('text') as UntypedFormControl;
  }

  private value?: string | string[];
  private onChange?: (value: string | string[]) => void;
  private subscriptions = new Subscription();

  constructor() {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.searchValueControl.valueChanges
        .pipe(
          filter(() => this.customSearchHandling),
          debounceTime(800)
        )
        .subscribe((value: string) => {
          this.search.emit(value);
        })
    );
  }

  writeValue(value: string[]): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(_fn: any): void {}

  isActive(value): boolean {
    if (this.multi) {
      return this.value?.includes(value);
    }
    return this.value === value;
  }

  updateSingleValue(optionValue: string) {
    this.value = optionValue;

    if (this.onChange) {
      this.onChange(optionValue);
    }
  }

  updateValue(optionValue: string) {
    if (this.isActive(optionValue)) {
      this.value = [...this.value].filter((v) => v !== optionValue);
    } else {
      this.value = [...(this.value || []), optionValue];
    }

    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  onShowMore(): void {
    this.showMore.emit(this.searchValueControl.value);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get filteredOptions(): Option[] {
    if (!this.customSearchHandling && this.searchValueControl.value !== '') {
      return this.options.filter((option) => option.label.toLowerCase().includes(this.searchValueControl.value.toLowerCase()));
    }
    return this.options;
  }
}
