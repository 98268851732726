import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LaunchDialogService } from '@spartacus/storefront';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, take, tap } from 'rxjs/operators';
import { BaseAbstractModalComponent, ModalCloseReason } from '../../../../core/modal';
import { AlertType, CartType, OrderEntry, PrePrintedLabel, PrePrintedSpecial } from '../../../../core/model';
import { UserFacade } from '../../../../core/user';
import { shallowEqualArrays } from '../../../../core/util';
import { CartPrePrintedLabelsFacade } from '../../../../features/cart/base';

export interface PrePrintedModalData {
  entry: OrderEntry;
  articleTitle: string;
}

@Component({
  selector: 'py-pre-printed-modal',
  templateUrl: './pre-printed-modal.component.html',
  styleUrls: ['./pre-printed-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrePrintedModalComponent extends BaseAbstractModalComponent implements OnInit, OnDestroy {
  entry: OrderEntry;
  articleTitle: string;

  formGroup = new FormGroup({
    search: new FormControl(''),
    code: new FormControl('', [Validators.required]),
    partNumber: new FormControl('', [Validators.required, Validators.maxLength(40)]),
    innerPackageQuantity: new FormControl(0, [Validators.maxLength(40)]),
  });

  notificationType: AlertType = AlertType.Warning;

  subscriptions = new Subscription();

  showSearchField: boolean = false;

  options: PrePrintedLabel[];
  options$: Observable<PrePrintedLabel[]>;
  allOptions$: Observable<PrePrintedLabel[]>;
  loadingOptions$: Observable<boolean>;
  loadingSave$: Observable<boolean>;
  loadingDelete$: Observable<boolean>;

  showInnerPackageQuantity$: Observable<boolean>;

  constructor(
    private cartPrePrintedLabelsService: CartPrePrintedLabelsFacade,
    private userService: UserFacade,
    protected launchDialogService: LaunchDialogService,
    protected el: ElementRef,
    protected renderer: Renderer2
  ) {
    super(el, launchDialogService, renderer);
  }

  get showPartNumberField(): boolean {
    return this.entry?.articleRef !== PrePrintedSpecial.VOLVO;
  }

  get selectedOption(): PrePrintedLabel {
    return this.options?.find((option) => option.code === this.formGroup.value?.code);
  }

  get searchControl(): FormControl {
    return this.formGroup.get('search') as FormControl;
  }

  get innerPackageQuantityControl(): FormControl {
    return this.formGroup.get('innerPackageQuantity') as FormControl;
  }

  get partNumberControl(): FormControl {
    return this.formGroup.get('partNumber') as FormControl;
  }

  get codeControl(): FormControl {
    return this.formGroup.get('code') as FormControl;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.launchDialogService.data$.pipe(take(1)).subscribe((data: PrePrintedModalData) => {
        this.entry = data.entry;
        this.articleTitle = data.articleTitle;
      })
    );

    this.initLoadingObservables();
    this.initOptionsObservables();
    this.setInitialFormValue();
    this.initInnerPackageQuantityObservables();
  }

  private initLoadingObservables(): void {
    this.loadingOptions$ = this.cartPrePrintedLabelsService.getLoadingOptions(CartType.stock, this.entry);
    this.loadingSave$ = this.cartPrePrintedLabelsService.getUpdateLabelLoading();
    this.loadingDelete$ = this.cartPrePrintedLabelsService.getDeleteLabelLoading();
  }

  private initOptionsObservables(): void {
    const searchValue$ = this.searchControl.valueChanges.pipe(startWith(''), distinctUntilChanged());
    this.allOptions$ = this.cartPrePrintedLabelsService.getOptions(CartType.stock, this.entry).pipe(
      tap((options) => {
        this.options = options;
        this.showSearchField = options?.length > 5;
      }),
      map((options) => {
        // Move the initial selected option to the top.
        if (!!options) {
          const arr = [...options];
          return arr?.sort((a, b) =>
            a.code === this.formGroup.value?.code ? -1 : b.code === this.formGroup.value?.code ? 1 : 0
          );
        }
        return options;
      }),
      distinctUntilChanged(shallowEqualArrays)
    );

    this.options$ = combineLatest([searchValue$, this.allOptions$]).pipe(
      map(([searchValue, allOptions]) => {
        if (!searchValue) {
          return allOptions;
        }
        return allOptions.filter((option) => option.label.toLowerCase().includes(searchValue.toLowerCase()));
      })
    );
  }

  private setInitialFormValue() {
    // If the entry has a pre-printed label, update form values with the pre-printed label.
    // Otherwise, check if the user has a country iso code and see if we can find the option and pre-select it.
    if (!!this.entry?.prePrintedLabel?.code) {
      this.setFormValues(this.entry?.prePrintedLabel);
    } else {
      this.subscriptions.add(
        combineLatest([this.userService.get(), this.allOptions$])
          .pipe(
            filter(([user, allOptions]) => user && !!Object.keys(user).length && allOptions?.length > 0),
            take(1)
          )
          .subscribe(([user]) => {
            const isoCode = user?.address?.country?.isocode;
            const option = this.options?.find((option) => option.code === isoCode);

            if (!!isoCode && !!option) {
              this.setFormValues(option);
            }
          })
      );
    }
  }

  private setFormValues(prePrintedLabel: PrePrintedLabel) {
    this.innerPackageQuantityControl.setValue(prePrintedLabel?.innerPackageQuantity || 0, { emitEvent: false });
    if (this.showPartNumberField) {
      this.partNumberControl.setValue(prePrintedLabel?.part, { emitEvent: false });
      this.partNumberControl.setValidators([Validators.required, Validators.maxLength(40)]);
      this.partNumberControl.updateValueAndValidity();
    }
    this.codeControl.setValue(prePrintedLabel?.code);
  }

  private initInnerPackageQuantityObservables(): void {
    const innerPackageQuantityRequired$ = this.cartPrePrintedLabelsService.getInnerPackageQuantityRequired(
      CartType.stock,
      this.entry
    );
    // If innerPackageQuantityRequired$ is true or innerPackageQuantity from the selected option is more than 0,
    // then the innerPackageQuantity field is required.
    this.showInnerPackageQuantity$ = combineLatest([
      innerPackageQuantityRequired$,
      this.codeControl.valueChanges.pipe(startWith('')),
    ]).pipe(
      map(([innerPackageQuantityRequired]) => {
        const required = innerPackageQuantityRequired || this.selectedOption?.innerPackageQuantity > 0;

        this.innerPackageQuantityControl.setValidators(
          required ? [Validators.required, Validators.min(1), Validators.maxLength(40)] : []
        );
        this.innerPackageQuantityControl.updateValueAndValidity();
        return required;
      })
    );
  }

  deleteLabel(): void {
    this.cartPrePrintedLabelsService.deleteLabel(CartType.stock, this.entry);
    // Wait to dismiss the modal until we have a successful response from backend.
    this.subscriptions.add(
      this.cartPrePrintedLabelsService
        .getDeleteLabelResult()
        .pipe(
          filter((result) => !!result),
          take(1)
        )
        .subscribe((_result) => {
          this.launchDialogService.closeDialog(ModalCloseReason.PRE_PRINTED_REMOVED);
          this.cartPrePrintedLabelsService.reseDeleteLabelProcessingStates();
        })
    );
  }

  save(): void {
    const option: PrePrintedLabel = {
      ...this.selectedOption,
      part: this.partNumberControl?.value,
      innerPackageQuantity: this.innerPackageQuantityControl?.value,
    };
    this.cartPrePrintedLabelsService.updateLabel(CartType.stock, this.entry, option);

    // Wait to dismiss the modal until we have a successful response from backend.
    this.subscriptions.add(
      this.cartPrePrintedLabelsService
        .getUpdateLabelResult()
        .pipe(
          filter((result) => !!result),
          take(1)
        )
        .subscribe((_result) => {
          this.launchDialogService.closeDialog(ModalCloseReason.PRE_PRINTED_SAVED);
          this.cartPrePrintedLabelsService.reseUpdateLabelProcessingStates();
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
