import { StateUtils } from '@spartacus/core';
import { LoaderError } from '../../../model';
import { PyStateUtils } from '../../../state/utils';
import { loaderErrorDetailsSelector } from '../loader';

export function entityLoaderStateSelector<T>(state: StateUtils.EntityLoaderState<T>, id: string): StateUtils.LoaderState<T> {
  return state.entities[id] || PyStateUtils.initialLoaderState;
}

export function entityErrorDetailsSelector<T>(state: StateUtils.EntityLoaderState<T>, id: string): LoaderError | undefined {
  const entityState = PyStateUtils.entityLoaderStateSelector(state, id);
  return loaderErrorDetailsSelector(entityState);
}
