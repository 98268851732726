import { StateUtils } from '@spartacus/core';
import { ContentTag } from '../../../core/occ/occ-models/occ-generated.models';

export const CMS_CONTENT_TAG_FEATURE = 'cmsContentTag';
export const CONTENT_TAG = '[Cms] Content tag';

export interface StateWithCmsContentTag {
  [CMS_CONTENT_TAG_FEATURE]: CmsContentTagState;
}

export interface CmsContentTagState {
  contentTags: StateUtils.EntityLoaderState<ContentTag>;
}
