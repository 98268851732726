import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'py-drawer-footer',
  templateUrl: './drawer-footer.component.html',
  styleUrls: ['./drawer-footer.component.scss'],
})
export class DrawerFooterComponent {
  @Input() template: TemplateRef<any>;
}
