import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { IconThemeReverseDirective } from './icon-theme-reverse.directive';

@NgModule({
  imports: [NzIconModule.forChild([])],
  declarations: [IconThemeReverseDirective],
  exports: [IconThemeReverseDirective],
})
export class IconThemeReverseModule {}
