import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsPageGuardService } from '@spartacus/storefront';
import { CmsPageGuardService as PyCmsPageGuardService } from './cms-page-guard.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: CmsPageGuardService,
      useExisting: PyCmsPageGuardService,
    },
  ],
})
export class CmsStructureGuardsModule {}
