import { Action } from '@ngrx/store';
import { EntriesState } from '../../../features/store/base-feature-state';
import { DeliveryAddress, DeliveryAddressSearchResult } from '../../../model/address.model';
import { UserActions } from '../actions';
import {
  DeleteDeliveryAddressSuccess,
  DeliveryAddressActionTypes,
  RemoveDeliveryAddress,
  SaveDeliveryAddressSuccess,
  UpdateTempDeliveryAddress,
} from '../actions/delivery-address.action';

export const initialState: EntriesState<DeliveryAddress> = {};

export function reducer(state = initialState, action: Action): EntriesState<DeliveryAddress> {
  switch (action.type) {
    case DeliveryAddressActionTypes.LoadDeliveryAddressesSuccess: {
      const addressesSuccess = <UserActions.LoadDeliveryAddressesSuccess>action;
      const payload: DeliveryAddressSearchResult = addressesSuccess.payload;

      const results = payload.results;

      return results.reduce(
        (entities: { [id: string]: DeliveryAddress }, entry: DeliveryAddress) => {
          return {
            ...entities,
            [entry.id]: entry,
          };
        },
        {
          ...state,
        }
      );
    }
    case DeliveryAddressActionTypes.SaveDeliveryAddressSuccess: {
      const successAction = <SaveDeliveryAddressSuccess>action;
      const address = successAction.payload;
      return { ...state, [address.id]: address };
    }
    case DeliveryAddressActionTypes.UpdateTempDeliveryAddress: {
      const updateAction = <UpdateTempDeliveryAddress>action;
      const address = updateAction.payload;
      return { ...state, [updateAction.tempCode]: address };
    }
    case DeliveryAddressActionTypes.RemoveDeliveryAddress: {
      const removeAction = <RemoveDeliveryAddress>action;
      const id = [].concat(removeAction.code).join();
      const newState = { ...state };
      delete newState[id];
      return newState;
    }
    case DeliveryAddressActionTypes.DeleteDeliveryAddressSuccess: {
      const successAction = <DeleteDeliveryAddressSuccess>action;
      const id = [].concat(successAction.payload).join();
      const newState = { ...state };
      delete newState[id];
      return newState;
    }
    default:
      return state;
  }
}
