import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Article } from '../../../../core/model';

@Component({
  selector: 'py-catalog-article-envelope',
  templateUrl: './catalog-article-envelope.component.html',
  styleUrls: ['./catalog-article-envelope.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogArticleEnvelopeComponent {
  showEnvelopeInfo = false;

  @Input() article: Article;
  @Input() renderFully: boolean;

  @Input() set expandedByDefault(expanded) {
    this.showEnvelopeInfo = !!expanded;
  }

  constructor() {}
}
