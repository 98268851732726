import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { LaunchDialogService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseAbstractModalComponent } from '../../../core/modal';
import { Image } from '../../../core/model';
import { BrowserService } from '../../services/browser/browser.service';

export interface ImageModalData {
  images: Image[];
  alternativeText?: string;
  initialActiveSlideIndex?: number;
  imagesAsIndicators?: boolean;
}

@Component({
  selector: 'py-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageModalComponent extends BaseAbstractModalComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('ngbcarousel', { static: false }) ngbcarousel: NgbCarousel;
  private _images: Image[];
  private _initialActiveSlideIndex?: number;
  private subscriptions = new Subscription();

  alternativeText: string;
  imagesAsIndicators: boolean = true;
  activeSlideId = 0;
  isEdgeBrowser: boolean = false;

  set images(images: Image[]) {
    this._images = images;
    this.cd.markForCheck();
  }

  get images(): Image[] {
    return this._images;
  }

  set initialActiveSlideIndex(index: number) {
    this.activeSlideId = index;
    this._initialActiveSlideIndex = index;
  }

  get initialActiveSlideIndex(): number {
    return this._initialActiveSlideIndex;
  }

  constructor(
    private cd: ChangeDetectorRef,
    private browserService: BrowserService,
    protected launchDialogService: LaunchDialogService,
    protected el: ElementRef,
    protected renderer: Renderer2
  ) {
    super(el, launchDialogService, renderer);
  }

  ngAfterViewInit(): void {
    if (this.ngbcarousel && this.initialActiveSlideIndex) {
      this.navigateToSlide(this.initialActiveSlideIndex);
    }
  }

  ngOnInit(): void {
    this.isEdgeBrowser = this.browserService.isEdgeBrowser();

    this.subscriptions.add(
      this.launchDialogService.data$.pipe(take(1)).subscribe((data: ImageModalData) => {
        this.alternativeText = data.alternativeText;
        this.images = data.images;
        this.initialActiveSlideIndex = data.initialActiveSlideIndex;
        this.imagesAsIndicators = data.imagesAsIndicators;
      })
    );
  }

  navigateToSlide(slideId: number): void {
    this.ngbcarousel.select(slideId.toString());
    this.activeSlideId = slideId;
  }

  dismissModal(reason?: any): void {
    this.launchDialogService.closeDialog(reason);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
