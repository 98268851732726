import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class StopRequests {
  WILL_REDIRECT = new Semaphore();
}

export class Semaphore {
  private value = false;

  raise() {
    this.value = true;
  }

  lower() {
    this.value = false;
  }

  isRaised(): boolean {
    return this.value;
  }
}
