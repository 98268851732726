import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '../../../core/i18n';
import { AlertMessageModule } from '../alert-message';
import { PasswordMessageComponent } from './password-message.component';

@NgModule({
  declarations: [PasswordMessageComponent],
  imports: [CommonModule, AlertMessageModule, I18nModule],
  exports: [PasswordMessageComponent],
})
export class PasswordMessageModule {}
