import { Injectable } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { CatalogRouterStateService, ProductService } from '../../../core/catalog';
import { Product } from '../../../core/model';

@Injectable({ providedIn: 'root' })
export class CurrentProductService {
  constructor(
    private routingService: RoutingService,
    private productService: ProductService,
    private catalogRouterStateService: CatalogRouterStateService
  ) {}

  getProduct(): Observable<Product | null> {
    return this.routingService.getRouterState().pipe(
      map((state) => state.state),
      map((state) => this.catalogRouterStateService.getProductCode(state.params, state.queryParams)),
      switchMap((code: string) => (code ? this.productService.getProduct(code) : of(undefined))),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
}
