import { PagedSearchAction } from '../../../features/store/actions/search.actions';
import { PaginationState, SearchPageResultState } from '../../../features/store/base-feature-state';
import { PrincipalSharedShoppingListFlagsPage } from '../../../model';
import { loaderReducer } from '../../../state/utils/loader';
import { UserActions } from '../actions';
import { ShoppingListActionTypes } from '../actions/shopping-list.action';
import { SHOPPING_LIST_PRINCIPALS } from '../user-state';
import { reducer as shoppingListEntriesReducer } from './shopping-list-principals-entries.reducer';

export const initialSearchState: PaginationState<SearchPageResultState> = {
  lastUpdateTime: undefined,
  pages: {},
  pagination: undefined,
  sorts: [],
};
export function reducer(state: PaginationState<SearchPageResultState> = initialSearchState, action: PagedSearchAction) {
  switch (action.type) {
    case ShoppingListActionTypes.LoadShoppingListPrincipals: {
      const _action = <UserActions.LoadShoppingListPrincipals>action;
      const searchParams = _action.searchParams;
      const loaderState = loaderReducer<SearchPageResultState>(SHOPPING_LIST_PRINCIPALS, shoppingListEntriesReducer)(
        { ...state.pages[searchParams.page] },
        _action
      );
      return {
        ...state,
        pages: {
          ...state.pages,
          [searchParams.page]: loaderState,
        },
      };
    }
    case ShoppingListActionTypes.LoadShoppingListPrincipalsFail: {
      const _action = <UserActions.LoadShoppingListPrincipalsFail>action;
      const searchParams = _action.searchParams;
      const loaderState = loaderReducer<SearchPageResultState>(SHOPPING_LIST_PRINCIPALS, shoppingListEntriesReducer)(
        undefined,
        _action
      );

      return {
        ...state,
        pages: {
          ...state.pages,
          [searchParams.page]: loaderState,
        },
      };
    }
    case ShoppingListActionTypes.LoadShoppingListPrincipalsSuccess: {
      const _action = <UserActions.LoadShoppingListPrincipalsSuccess>action;
      const searchResult: PrincipalSharedShoppingListFlagsPage = _action.payload;
      const loaderState = loaderReducer<SearchPageResultState>(SHOPPING_LIST_PRINCIPALS, shoppingListEntriesReducer)(
        { ...state.pages[searchResult.pagination.page] },
        _action
      );

      const pages = { ...state.pages };
      return {
        ...state,
        lastUpdateTime: new Date().toISOString(),
        pagination: searchResult.pagination,
        sorts: searchResult.sorts,
        pages: {
          ...pages,
          [searchResult.pagination.page]: loaderState,
        },
      };
    }
  }
  return state;
}
