import { Component, Input, OnInit } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { AddonService } from '../../../../core/catalog';
import { AddonGroup } from '../../../../core/model';
import { MegamenuListItemComponent } from '../../../../shared';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ul[py-sub-addon-group-navigation]',
  templateUrl: './sub-addon-group-navigation.component.html',
})
export class SubAddonGroupNavigationComponent extends MegamenuListItemComponent implements OnInit {
  addonGroup$: Observable<AddonGroup>;
  addonGroupId$ = new ReplaySubject<string>();

  @Input() set addonGroupId(addonGroupId: string) {
    this.addonGroupId$.next(addonGroupId);
  }

  constructor(private addonService: AddonService) {
    super();
  }

  ngOnInit(): void {
    this.addonGroup$ = this.addonGroupId$.pipe(
      filter((addonGroupId) => !!addonGroupId),
      switchMap((addonGroupId) => this.addonService.getAddonGroup(addonGroupId))
    );
  }
}
