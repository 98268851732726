import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'py-cart-message-modal-header',
  templateUrl: './cart-message-modal-header.component.html',
  styleUrls: ['./cart-message-modal-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartMessageModalHeaderComponent {
  @Input() title: string;
  @Output() closeModal = new EventEmitter<void>();

  closeButtonTheme: 'fill' | undefined;

  onModalClose(): void {
    this.closeModal.emit();
  }
}
