import { NgModule } from '@angular/core';
import { provideDefaultConfig } from '@spartacus/core';
import { TmsConfig } from '@spartacus/tracking/tms/core';
import {
  TrackingEventAddEntriesToCart,
  TrackingEventAddToWishlist,
  TrackingEventLogout,
  TrackingEventPayment,
  TrackingEventRemoveFromWishlist,
  TrackingEventSelectItem,
} from '../events';
import { ApptusCollectorService } from './apptus-collector.service';

@NgModule({
  providers: [
    provideDefaultConfig(<TmsConfig>{
      tagManager: {
        apptus: {
          collector: ApptusCollectorService,
          events: [
            TrackingEventAddEntriesToCart,
            TrackingEventAddToWishlist,
            TrackingEventRemoveFromWishlist,
            TrackingEventSelectItem,
            TrackingEventPayment,
            TrackingEventLogout,
          ],
        },
      },
    }),
  ],
})
export class ApptusModule {
  constructor() {}
}
