import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import { CartType, OrderEntry, PrePrintedLabel } from '../../../../../core/model';
import { PyStateUtils } from '../../../../../core/state';
import { DELETE_PRE_PRINTED_LABEL_PROCESS, PRE_PRINTED_LABEL, UPDATE_PRE_PRINTED_LABEL_PROCESS } from '../cart-state';

export enum CartEntryPrePrintedLabelActionTypes {
  LoadPrePrintedOptions = '[Cart][pre-printed] Load options',
  LoadPrePrintedOptionsFail = '[Cart][pre-printed] Load options fail',
  LoadPrePrintedOptionsSuccess = '[Cart][pre-printed] Load options success',

  LoadPrePrintedInnerPackageQuantityRequired = '[Cart][pre-printed] Load inner package quantity required',
  LoadPrePrintedInnerPackageQuantityRequiredFail = '[Cart][pre-printed] Load inner package quantity required fail',
  LoadPrePrintedInnerPackageQuantityRequiredSuccess = '[Cart][pre-printed] Load inner package quantity required success',

  UpdatePrePrintedLabel = '[Cart][pre-printed] Update label',
  UpdatePrePrintedLabelFail = '[Cart][pre-printed] Update label fail',
  UpdatePrePrintedLabelSuccess = '[Cart][pre-printed] Update label success',
  UpdatePrePrintedLabelReset = '[Cart][pre-printed] Update label reset',

  DeletePrePrintedLabel = '[Cart][pre-printed] Delete label',
  DeletePrePrintedLabelFail = '[Cart][pre-printed] Delete label fail',
  DeletePrePrintedLabelSuccess = '[Cart][pre-printed] Delete label success',
  DeletePrePrintedLabelReset = '[Cart][pre-printed] Delete label reset',

  ClearPrePrintedLabels = '[Cart][pre-printed] Clear labels',
}

interface PrePrintedLabelPayload {
  cartType: CartType;
  cartId: string;
  userId: string;
  entry: OrderEntry;
}
interface PrePrintedFailPayload extends PrePrintedLabelPayload {
  error: any;
}

// Options
interface LoadPrePrintedOptionsSuccessPayload extends PrePrintedLabelPayload {
  options: PrePrintedLabel[];
}

export class LoadPrePrintedOptions extends StateUtils.LoaderLoadAction {
  readonly type = CartEntryPrePrintedLabelActionTypes.LoadPrePrintedOptions;

  constructor(public payload: PrePrintedLabelPayload) {
    super(PRE_PRINTED_LABEL);
  }
}
export class LoadPrePrintedOptionsFail extends PyStateUtils.LoaderFailAction {
  readonly type = CartEntryPrePrintedLabelActionTypes.LoadPrePrintedOptionsFail;

  constructor(public payload: PrePrintedFailPayload) {
    super(PRE_PRINTED_LABEL, payload);
  }
}
export class LoadPrePrintedOptionsSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = CartEntryPrePrintedLabelActionTypes.LoadPrePrintedOptionsSuccess;

  constructor(public payload: LoadPrePrintedOptionsSuccessPayload) {
    super(PRE_PRINTED_LABEL);
  }
}

// Inner package quantity required
interface LoadPrePrintedInnerPackageQuantityRequiredSuccessPayload extends PrePrintedLabelPayload {
  innerPackageQuantityRequired: boolean;
}

export class LoadPrePrintedInnerPackageQuantityRequired extends StateUtils.LoaderLoadAction {
  readonly type = CartEntryPrePrintedLabelActionTypes.LoadPrePrintedInnerPackageQuantityRequired;

  constructor(public payload: PrePrintedLabelPayload) {
    super(PRE_PRINTED_LABEL);
  }
}
export class LoadPrePrintedInnerPackageQuantityRequiredFail extends PyStateUtils.LoaderFailAction {
  readonly type = CartEntryPrePrintedLabelActionTypes.LoadPrePrintedInnerPackageQuantityRequiredFail;

  constructor(public payload: PrePrintedFailPayload) {
    super(PRE_PRINTED_LABEL, payload);
  }
}
export class LoadPrePrintedInnerPackageQuantityRequiredSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = CartEntryPrePrintedLabelActionTypes.LoadPrePrintedInnerPackageQuantityRequiredSuccess;

  constructor(public payload: LoadPrePrintedInnerPackageQuantityRequiredSuccessPayload) {
    super(PRE_PRINTED_LABEL);
  }
}

// Update selected label
interface UpdatePrePrintedLabelPayload extends PrePrintedLabelPayload {
  option: PrePrintedLabel;
}
interface UpdatePrePrintedLabelFailPayload extends UpdatePrePrintedLabelPayload {
  error: any;
}
interface UpdatePrePrintedLabelSuccessPayload extends UpdatePrePrintedLabelPayload {}

export class UpdatePrePrintedLabel extends StateUtils.EntityLoadAction {
  readonly type = CartEntryPrePrintedLabelActionTypes.UpdatePrePrintedLabel;

  constructor(public payload: UpdatePrePrintedLabelPayload) {
    super(PROCESS_FEATURE, UPDATE_PRE_PRINTED_LABEL_PROCESS);
  }
}
export class UpdatePrePrintedLabelFail extends PyStateUtils.EntityFailAction {
  readonly type = CartEntryPrePrintedLabelActionTypes.UpdatePrePrintedLabelFail;

  constructor(public payload: UpdatePrePrintedLabelFailPayload) {
    super(PROCESS_FEATURE, UPDATE_PRE_PRINTED_LABEL_PROCESS, payload);
  }
}
export class UpdatePrePrintedLabelSuccess extends StateUtils.EntitySuccessAction {
  readonly type = CartEntryPrePrintedLabelActionTypes.UpdatePrePrintedLabelSuccess;

  constructor(public payload: UpdatePrePrintedLabelSuccessPayload) {
    super(PROCESS_FEATURE, UPDATE_PRE_PRINTED_LABEL_PROCESS);
  }
}
export class UpdatePrePrintedLabelReset extends StateUtils.EntityLoaderResetAction {
  readonly type = CartEntryPrePrintedLabelActionTypes.UpdatePrePrintedLabelReset;
  constructor() {
    super(PROCESS_FEATURE, UPDATE_PRE_PRINTED_LABEL_PROCESS);
  }
}

// Delete selected label
interface DeletePrePrintedLabelSuccessPayload extends PrePrintedLabelPayload {}

export class DeletePrePrintedLabel extends StateUtils.EntityLoadAction {
  readonly type = CartEntryPrePrintedLabelActionTypes.DeletePrePrintedLabel;

  constructor(public payload: PrePrintedLabelPayload) {
    super(PROCESS_FEATURE, DELETE_PRE_PRINTED_LABEL_PROCESS);
  }
}
export class DeletePrePrintedLabelFail extends PyStateUtils.EntityFailAction {
  readonly type = CartEntryPrePrintedLabelActionTypes.DeletePrePrintedLabelFail;

  constructor(public payload: PrePrintedFailPayload) {
    super(PROCESS_FEATURE, DELETE_PRE_PRINTED_LABEL_PROCESS, payload);
  }
}
export class DeletePrePrintedLabelSuccess extends StateUtils.EntitySuccessAction {
  readonly type = CartEntryPrePrintedLabelActionTypes.DeletePrePrintedLabelSuccess;

  constructor(public payload: DeletePrePrintedLabelSuccessPayload) {
    super(PROCESS_FEATURE, DELETE_PRE_PRINTED_LABEL_PROCESS);
  }
}
export class DeletePrePrintedLabelReset extends StateUtils.EntityLoaderResetAction {
  readonly type = CartEntryPrePrintedLabelActionTypes.DeletePrePrintedLabelReset;
  constructor() {
    super(PROCESS_FEATURE, DELETE_PRE_PRINTED_LABEL_PROCESS);
  }
}

// Global
export class ClearPrePrintedLabels extends StateUtils.LoaderResetAction {
  readonly type = CartEntryPrePrintedLabelActionTypes.ClearPrePrintedLabels;
  constructor() {
    super(PRE_PRINTED_LABEL);
  }
}

export type CartEntryPrePrintedLabelActions =
  | LoadPrePrintedOptions
  | LoadPrePrintedOptionsFail
  | LoadPrePrintedOptionsSuccess
  | LoadPrePrintedInnerPackageQuantityRequired
  | LoadPrePrintedInnerPackageQuantityRequiredFail
  | LoadPrePrintedInnerPackageQuantityRequiredSuccess
  | UpdatePrePrintedLabel
  | UpdatePrePrintedLabelFail
  | UpdatePrePrintedLabelSuccess
  | UpdatePrePrintedLabelReset
  | DeletePrePrintedLabel
  | DeletePrePrintedLabelFail
  | DeletePrePrintedLabelSuccess
  | DeletePrePrintedLabelReset
  | ClearPrePrintedLabels;
