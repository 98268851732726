import { StateUtils } from '@spartacus/core';
import { AnalyticsMetadata } from '../../../occ/occ-models/occ-generated.models';
import { PyStateUtils } from '../../../state/utils';
import { ANALYTICS_METADATA } from '../user-state';

export enum AnalyticsMetadataActionTypes {
  LoadAnalyticsMetadata = '[User] Load analytics metadata',
  LoadAnalyticsMetadataFail = '[User] Load analytics metadata fail',
  LoadAnalyticsMetadataSuccess = '[User] Load analytics metadata success',
}

export class LoadAnalyticsMetadata extends StateUtils.LoaderLoadAction {
  readonly type = AnalyticsMetadataActionTypes.LoadAnalyticsMetadata;

  constructor(public payload: string) {
    super(ANALYTICS_METADATA);
  }
}

export class LoadAnalyticsMetadataFail extends PyStateUtils.LoaderFailAction {
  readonly type = AnalyticsMetadataActionTypes.LoadAnalyticsMetadataFail;

  constructor(public payload?: any) {
    super(ANALYTICS_METADATA, payload);
  }
}

export class LoadAnalyticsMetadataSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = AnalyticsMetadataActionTypes.LoadAnalyticsMetadataSuccess;

  constructor(public payload: AnalyticsMetadata) {
    super(ANALYTICS_METADATA);
  }
}

export type AnalyticsMetadataActions = LoadAnalyticsMetadata | LoadAnalyticsMetadataFail | LoadAnalyticsMetadataSuccess;
