import { Injectable } from '@angular/core';
import {
  AuthRedirectStorageService,
  AuthStorageService,
  AuthToken,
  AuthStatePersistenceService as CxAuthStatePersistenceService,
  StatePersistenceService,
  SyncedAuthState,
} from '@spartacus/core';
import { UserIdService } from '../facade/user-id.service';

/**
 * Responsible for saving the authorization data (userId, token, redirectUrl) in browser storage.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthStatePersistenceService extends CxAuthStatePersistenceService {
  constructor(
    protected statePersistenceService: StatePersistenceService,
    protected userIdService: UserIdService,
    protected authStorageService: AuthStorageService,
    protected authRedirectStorageService: AuthRedirectStorageService
  ) {
    super(statePersistenceService, userIdService, authStorageService, authRedirectStorageService);
  }
  /**
   * Function called on each browser storage read.
   * Used to update state from browser -> state.
   */
  protected onRead(state: SyncedAuthState | undefined) {
    if (state?.token && this.isValidAccessToken(state.token)) {
      this.authStorageService.setToken(state.token);
    }
    if (state?.redirectUrl) {
      this.authRedirectStorageService.setRedirectUrl(state.redirectUrl);
    }
    if (state?.userId && state?.token && this.isValidAccessToken(state.token)) {
      this.userIdService.setUserId(state.userId);
    } else {
      this.userIdService.clearUserId();
    }
  }

  /**
   * Check synchronously in browser storage if user is logged in (required by transfer state reducer).
   * For most cases `isUserLoggedIn` from the `AuthService` should be used instead of this.
   */
  public isUserLoggedIn(): boolean {
    const token: AuthToken = this.readStateFromStorage()?.token;
    return Boolean(token?.access_token && this.isValidAccessToken(token));
  }

  private isValidAccessToken(token: AuthToken) {
    if (token?.expires_at) {
      const expiresAt = token.expires_at;
      const now = new Date();
      if (expiresAt && parseInt(expiresAt, 10) < now.getTime()) {
        return false;
      }
      return true;
    }
    return false;
  }
}
