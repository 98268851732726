import { NgModule } from '@angular/core';
import { AlertsFeatureModule } from './alerts/alerts-feature.module';
import { AsmFeatureModule } from './asm/asm-feature.module';
import { BrandLandingPageFeatureModule } from './brand-landing-page/brand-landing-page-feature.module';
import { CartFeatureModule } from './cart/cart-feature.module';
import { CatalogFeatureModule } from './catalog/catalog-feature.module';
import { CheckoutConfirmationFeatureModule } from './checkout-confirmation/checkout-confirmation-feature.module';
import { CheckoutFeatureModule } from './checkout/checkout-feature.module';
import { ContentFeatureModule } from './content/content-feature.module';
import { LoginFeatureModule } from './login/login-feature.module';
import { LogoutFeatureModule } from './logout/logout-feature.module';
import { MyPageFeatureModule } from './my-page/my-page-feature.module';
import { NavigationFeatureModule } from './navigation/navigation-feature.module';
import { OnlineChatFeatureModule } from './online-chat/online-chat-feature.module';
import { OrderBaseFeatureModule } from './order-base/order-base-feature.module';
import { OrganizationBaseFeatureModule } from './organization-base/organization-base-feature.module';
import { PasswordResetFeatureModule } from './password-reset/password-reset-feature.module';
import { PaymentProcessingFeatureModule } from './payment-processing/payment-processing-feature.module';
import { ProductDocumentsFeatureModule } from './product-documents/product-documents-feature.module';
import { RegistrationFeatureModule } from './registration/registration-feature.module';
import { ShowcaseFeatureModule } from './showcase/showcase-feature.module';
import { SmartEditFeatureModule } from './smartedit/smart-edit-feature.module';
import { SoldToFeatureModule } from './sold-to-base/sold-to-feature.module';
import { TrackingFeatureModule } from './tracking/tracking-feature.module';
import { UserAccountFeatureModule } from './user-account/user-account-feature.module';
import { UserBaseFeatureModule } from './user-base/user-feature.module';

@NgModule({
  declarations: [],
  imports: [
    AsmFeatureModule,
    AlertsFeatureModule,
    TrackingFeatureModule,
    SmartEditFeatureModule,
    ShowcaseFeatureModule,
    CheckoutConfirmationFeatureModule,
    MyPageFeatureModule,
    CartFeatureModule,
    ContentFeatureModule,
    CatalogFeatureModule,
    BrandLandingPageFeatureModule,
    CheckoutFeatureModule,
    NavigationFeatureModule,
    OnlineChatFeatureModule,
    RegistrationFeatureModule,
    LoginFeatureModule,
    LogoutFeatureModule,
    PasswordResetFeatureModule,
    ProductDocumentsFeatureModule,
    PaymentProcessingFeatureModule,
    OrderBaseFeatureModule,
    OrganizationBaseFeatureModule,
    SoldToFeatureModule,
    UserAccountFeatureModule,
    UserBaseFeatureModule,
  ],
})
export class FeaturesModule {}
