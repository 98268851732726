import { MemoizedSelector, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { AlternativeArticles, Article, LoaderError, Substitutes } from '../../../../model';
import { PyStateUtils } from '../../../../state';
import { loaderErrorDetailsSelector } from '../../../../state/utils/loader';
import { CatalogState, StateWithCatalog } from '../../../store/catalog-state';
import { getCatalogState } from '../../../store/selectors/feature.selector';

export const getArticlesState: MemoizedSelector<StateWithCatalog, StateUtils.EntityLoaderState<Article>> = createSelector(
  getCatalogState,
  (state: CatalogState) => state && state.articles
);

export const getArticleLoaderState = (code: string): MemoizedSelector<StateWithCatalog, StateUtils.LoaderState<Article>> => {
  return createSelector(
    getArticlesState,
    (details) => PyStateUtils.entityLoaderStateSelector(details, code) || PyStateUtils.initialLoaderState
  );
};

export const getArticleLoading = (code: string): MemoizedSelector<StateWithCatalog, boolean> => {
  return createSelector(getArticleLoaderState(code), (articleState) => StateUtils.loaderLoadingSelector(articleState));
};

export const getArticleSuccess = (code: string): MemoizedSelector<StateWithCatalog, boolean> => {
  return createSelector(getArticleLoaderState(code), (articleState) => StateUtils.loaderSuccessSelector(articleState));
};

export const getArticleFailure = (code: string): MemoizedSelector<StateWithCatalog, LoaderError> => {
  return createSelector(getArticleLoaderState(code), (articleState) => loaderErrorDetailsSelector(articleState));
};

export const getArticle = (code: string): MemoizedSelector<StateWithCatalog, Article> => {
  return createSelector(getArticleLoaderState(code), (articleState) => StateUtils.loaderValueSelector(articleState));
};

export const getSimilarArticlesState: MemoizedSelector<
  StateWithCatalog,
  StateUtils.EntityLoaderState<AlternativeArticles>
> = createSelector(getCatalogState, (state: CatalogState) => state && state.similarArticles);

export const getSimilarArticlesLoaderState = (
  code: string
): MemoizedSelector<StateWithCatalog, StateUtils.LoaderState<AlternativeArticles>> => {
  return createSelector(
    getSimilarArticlesState,
    (details) => PyStateUtils.entityLoaderStateSelector(details, code) || PyStateUtils.initialLoaderState
  );
};

export const getSimilarArticlesLoading = (code: string): MemoizedSelector<StateWithCatalog, boolean> => {
  return createSelector(getSimilarArticlesLoaderState(code), (similarArticlesState) =>
    StateUtils.loaderLoadingSelector(similarArticlesState)
  );
};

export const getSimilarArticlesSuccess = (code: string): MemoizedSelector<StateWithCatalog, boolean> => {
  return createSelector(getSimilarArticlesLoaderState(code), (similarArticlesState) =>
    StateUtils.loaderSuccessSelector(similarArticlesState)
  );
};

export const getSimilarArticles = (code: string): MemoizedSelector<StateWithCatalog, AlternativeArticles> => {
  return createSelector(getSimilarArticlesLoaderState(code), (similarArticlesState) =>
    StateUtils.loaderValueSelector(similarArticlesState)
  );
};

export const getSubstitutesArticlesState: MemoizedSelector<
  StateWithCatalog,
  StateUtils.EntityLoaderState<Substitutes>
> = createSelector(getCatalogState, (state: CatalogState) => state && state.substitutesArticles);

export const getSubstitutesArticlesLoaderState = (
  code: string
): MemoizedSelector<StateWithCatalog, StateUtils.LoaderState<Substitutes>> => {
  return createSelector(
    getSubstitutesArticlesState,
    (details) => PyStateUtils.entityLoaderStateSelector(details, code) || PyStateUtils.initialLoaderState
  );
};

export const getSubstitutesArticlesLoading = (code: string): MemoizedSelector<StateWithCatalog, boolean> => {
  return createSelector(getSubstitutesArticlesLoaderState(code), (substitutesArticlesState) =>
    StateUtils.loaderLoadingSelector(substitutesArticlesState)
  );
};

export const getSubstitutesArticlesLoaded = (code: string): MemoizedSelector<StateWithCatalog, boolean> => {
  return createSelector(
    getSubstitutesArticlesLoaderState(code),
    (substitutesArticlesState) =>
      StateUtils.loaderSuccessSelector(substitutesArticlesState) && !StateUtils.loaderLoadingSelector(substitutesArticlesState)
  );
};

export const getSubstitutesArticles = (code: string): MemoizedSelector<StateWithCatalog, Substitutes> => {
  return createSelector(getSubstitutesArticlesLoaderState(code), (substitutesArticlesState) =>
    StateUtils.loaderValueSelector(substitutesArticlesState)
  );
};
