<ng-container *ngTemplateOutlet="iconTemplate || defaultIconTemplate"></ng-container>
<ng-container *ngIf="hasLongTitle; else h4">
  <h5>{{ title }}</h5>
</ng-container>
<ng-template #h4>
  <h4>{{ title }}</h4>
</ng-template>

<ng-content></ng-content>

<ng-template #defaultIconTemplate>
  <i nz-icon nzType="plus-circle"></i>
</ng-template>
