import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { NonSellableProductInquiryFormData } from '../../../model';

@Injectable({
  providedIn: 'root',
})
export class NonSellableProductConnector {
  constructor(private httpClient: HttpClient, private occEndpointsService: OccEndpointsService) {}

  sendNonSellableProductInquiry(inquiryFormData: NonSellableProductInquiryFormData, userId: string): Observable<any> {
    return this.httpClient.post<void>(
      this.occEndpointsService.buildUrl('nonSellableProductInquiry', {
        urlParams: {
          userId,
        },
      }),
      inquiryFormData
    );
  }
}
