import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../core/i18n';
import { LaunchDialogService } from '../../../core/modal';
import { PrincipalConfigurationDirectiveModule } from '../../directives';
import { AddToShoppingListModule } from '../add-to-shopping-list/add-to-shopping-list.module';
import { ArticleImageModule, ArticleInfoModule } from '../article';
import { ArticleInfoAttributesModule } from '../article/article-info-attributes/article-info-attributes.module';
import { BadgeModule } from '../badge';
import { CatalogSimilarArticlesModule } from '../catalog-similar-articles';
import { DetailedStockInfoModule } from '../detailed-stock-info';
import { DropdownModule } from '../dropdown';
import { NotificationModule } from '../notification';
import { OverlayMessageModule } from '../overlay-message';
import { QuantityAndUnitSelectorModule } from '../quantity-and-unit-selector';
import { searchScoreModalLayoutConfig } from '../search-score-modal/search-score-modal-layout.config';
import { SpinnerModule } from '../spinner';
import { CatalogArticleEnvelopeModule } from './catalog-article-envelope';
import { CatalogArticleItemComponent } from './catalog-article-item/catalog-article-item.component';
import { CatalogArticleOverlayMessageModule } from './catalog-article-overlay-message';
import { CatalogArticlePriceModule } from './catalog-article-price';
import { CatalogArticleRowComponent } from './catalog-article-row/catalog-article-row.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    NzIconModule,
    NgbTooltipModule,
    AddToShoppingListModule,
    PrincipalConfigurationDirectiveModule,
    ArticleInfoModule,
    ArticleImageModule,
    QuantityAndUnitSelectorModule,
    DetailedStockInfoModule,
    CatalogArticlePriceModule,
    CatalogArticleEnvelopeModule,
    OverlayMessageModule,
    NotificationModule,
    CatalogArticleOverlayMessageModule,
    CatalogSimilarArticlesModule,
    ArticleInfoAttributesModule,
    SpinnerModule,
    BadgeModule,
    DropdownModule,
    ReactiveFormsModule,
  ],
  declarations: [CatalogArticleRowComponent, CatalogArticleItemComponent],
  exports: [CatalogArticleRowComponent, CatalogArticleItemComponent],
})
export class CatalogArticleModule {
  constructor(private launchDialogService: LaunchDialogService) {
    this.launchDialogService.registerModalLayoutConfig(searchScoreModalLayoutConfig);
  }
}
