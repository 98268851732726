import {
  BaseSiteEntities,
  BaseSiteState,
  CurrenciesState,
  CurrencyEntities,
  LanguagesEntities,
  LanguagesState,
  SITE_CONTEXT_FEATURE,
  SiteContextState,
  StateUtils,
  StateWithSiteContext,
} from '@spartacus/core';
import { SiteConfig } from '../../model/misc.model';

declare module '@spartacus/core' {
  interface BaseSiteState {
    isFallback: boolean;
  }

  interface SiteContextState {
    activeCountry: Country;
    sitePrefix: string;
    siteConfig: StateUtils.LoaderState<SiteConfig>;
  }
}

export {
  BaseSiteEntities,
  BaseSiteState,
  CurrenciesState,
  CurrencyEntities,
  LanguagesEntities,
  LanguagesState,
  SITE_CONTEXT_FEATURE,
  SiteContextState,
  StateWithSiteContext,
};

export const SITE_CONFIG = 'site-config';
