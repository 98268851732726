import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../core/i18n';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { RadioModule } from '../radio/radio.module';
import { ReactiveFormInputFieldModule } from '../reactive-forms-input/reactive-form-input-field/reactive-form-input-field.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { DrawerselectComponent } from './drawerselect.component';

@NgModule({
  imports: [
    CommonModule,
    NzIconModule,
    I18nModule,
    NgbCollapseModule,
    CheckboxModule,
    SpinnerModule,
    RadioModule,
    ReactiveFormsModule,
    ReactiveFormInputFieldModule,
  ],
  declarations: [DrawerselectComponent],
  exports: [DrawerselectComponent],
})
export class DrawerselectModule {}
