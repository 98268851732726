import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../../core/i18n';
import { NameMarkingModalModule } from '../name-marking-modal';
import { NameMarkingTextModule } from '../name-marking-text';
import { NameMarkingButtonComponent } from './name-marking-button.component';

@NgModule({
  imports: [CommonModule, NzIconModule, I18nModule, NameMarkingTextModule, NgbTooltipModule, NameMarkingModalModule],
  declarations: [NameMarkingButtonComponent],
  exports: [NameMarkingButtonComponent],
})
export class NameMarkingButtonModule {}
