import { InjectionToken, Provider } from '@angular/core';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { SearchTermFacetMappingHint, SolrSearchResult, SolrSearchType } from '../../../model/solr-search-config';
import { PyStateUtils } from '../../../state';
import { SEARCH_HINTS, SOLR_SEARCH_FEATURE, SolrSearchState } from '../solr-search-state';
import { reducer as searchByQueryReducer } from './solr-search-by-query.reducer';
import * as fromSolrSearchReducer from './solr-search.reducer';
import { reducer as solrSearchReducer } from './solr-search.reducer';

export function getReducers(): ActionReducerMap<SolrSearchState, any> {
  return {
    search: searchByQueryReducer(
      PyStateUtils.loaderReducer<SolrSearchResult>(
        `${SOLR_SEARCH_FEATURE}:${SolrSearchType.ARTICLE}`,
        fromSolrSearchReducer.reducer(SolrSearchType.ARTICLE)
      ),
      PyStateUtils.loaderReducer<SolrSearchResult>(
        `${SOLR_SEARCH_FEATURE}:${SolrSearchType.PRODUCT}`,
        fromSolrSearchReducer.reducer(SolrSearchType.PRODUCT)
      ),
      PyStateUtils.loaderReducer<SolrSearchResult>(
        `${SOLR_SEARCH_FEATURE}:${SolrSearchType.DEFAULT}`,
        fromSolrSearchReducer.reducer(SolrSearchType.DEFAULT)
      )
    ),
    suggestions: solrSearchReducer(SolrSearchType.SUGGEST),
    hints: PyStateUtils.loaderReducer<SearchTermFacetMappingHint[]>(SEARCH_HINTS),
  };
}

export const reducerToken = new InjectionToken<ActionReducerMap<SolrSearchState>>('SolrSearchReducers');

export const reducerProvider: Provider = {
  provide: reducerToken,
  useFactory: getReducers,
};

export const metaReducers: MetaReducer<any>[] = [];
