import { Component, Input } from '@angular/core';

@Component({
  selector: 'py-checkbox-slider',
  templateUrl: './checkbox-slider.component.html',
  styleUrls: ['./checkbox-slider.component.scss'],
})
export class CheckboxSliderComponent {
  @Input()
  leftLabel?: string;

  @Input()
  rightLabel?: string;

  @Input()
  addMargin? = true;

  @Input()
  rightMargin? = false;

  @Input()
  centerLabel? = false;

  @Input()
  tooltip?: string;
}
