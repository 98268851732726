<tr class="main py-0 py-md-7" #main>
  <td class="w-100" colspan="9">
    <div class="entry-row">
      <div class="p-0 d-flex">
        <py-article-image
          [images]="entry && [entry?.a4SampleProduct?.thumbnail]"
          [loading]="!entry"
          [altText]="entry?.a4SampleProduct?.name"
        ></py-article-image>
        <h6 class="ml-3" [innerText]="entry?.a4SampleProduct?.name + '\n' + entry?.a4SampleDescription"></h6>
      </div>
      <div class="quantity-form p-0">
        <form [formGroup]="sampleProductForm" class="sample-product-form no-gutters d-flex">
          <py-counter-input
            class="flex-grow-1 flex-lg-grow-0"
            [formControlName]="'quantity'"
            [step]="1"
            [min]="1"
            [max]="entry.quantity + sampleProductsLimit - sampleProductQuantity"
          >
          </py-counter-input>
        </form>
      </div>
      <py-delete-button (confirmDeletion)="onConfirmedDelete()"></py-delete-button>
      <div class="quantity-label">
        <div>
          <h5 class="text-left text-lg-right">{{ 'common.freePrice_hint' | cxTranslate }}</h5>
          <div>
            <span>
              {{
                'common.quantityWithParams_heading'
                  | cxTranslate
                    : { quantity: sampleProductForm.get('quantity').value, unit: ('cart.sampleCartSheetUnit_hint' | cxTranslate) }
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </td>
</tr>
