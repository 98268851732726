import { StateUtils } from '@spartacus/core';
import { HttpErrorModel, NonSellableProductInquiryFormData } from '../../../../model';
import { PyStateUtils } from '../../../../state/utils';
import { NON_SELLABLE_PRODUCT_INQUIRY } from '../../../store/catalog-state';

export enum NonSellableProductActionTypes {
  SendNonSellableProductInquiry = '[Product] Send Non-Sellable Product Inquiry',
  SendNonSellableProductInquirySuccess = '[Product] Send Non-Sellable Product Inquiry Success',
  SendNonSellableProductInquiryFail = '[Product] Send Non-Sellable Product Inquiry Fail',
  SendNonSellableProductInquiryReset = '[Product] Send Non-Sellable Product Inquiry Reset',
}

export class SendNonSellableProductInquiry extends StateUtils.LoaderLoadAction {
  readonly type = NonSellableProductActionTypes.SendNonSellableProductInquiry;

  constructor(public inquiryFormData: NonSellableProductInquiryFormData, public userId: string) {
    super(NON_SELLABLE_PRODUCT_INQUIRY);
  }
}

export class SendNonSellableProductInquirySuccess extends StateUtils.LoaderSuccessAction {
  readonly type = NonSellableProductActionTypes.SendNonSellableProductInquirySuccess;

  constructor(public payload: NonSellableProductInquiryFormData) {
    super(NON_SELLABLE_PRODUCT_INQUIRY);
  }
}

export class SendNonSellableProductInquiryFail extends PyStateUtils.LoaderFailAction {
  readonly type = NonSellableProductActionTypes.SendNonSellableProductInquiryFail;

  constructor(public error?: HttpErrorModel) {
    super(NON_SELLABLE_PRODUCT_INQUIRY, error);
  }
}

export class SendNonSellableProductInquiryReset extends StateUtils.LoaderResetAction {
  readonly type = NonSellableProductActionTypes.SendNonSellableProductInquiryReset;

  constructor() {
    super(NON_SELLABLE_PRODUCT_INQUIRY);
  }
}

export type NonSellableProductActions =
  | SendNonSellableProductInquiry
  | SendNonSellableProductInquirySuccess
  | SendNonSellableProductInquiryFail
  | SendNonSellableProductInquiryReset;
