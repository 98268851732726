import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ChangeSoldToActionTypes } from '../../../../../features/sold-to-base/store/actions/sold-to-change.action';
import { BaseSiteActionTypes } from '../../../../site-context/store/actions/base-site.action';
import { LanguagesActionTypes } from '../../../../site-context/store/actions/languages.action';
import { normalizeHttpError, withdrawOn } from '../../../../util';
import { AreaOfUseConnector } from '../../connector/area-of-use.connector';
import { AreaOfUseActions } from '../actions';

@Injectable({ providedIn: 'root' })
export class AreaOfUseEffects {
  private contextChange$: Observable<Action> = this.actions$.pipe(
    ofType(BaseSiteActionTypes.BaseSiteChange, LanguagesActionTypes.LanguageChange, ChangeSoldToActionTypes.ChangeSoldToSuccess)
  );

  loadAreasOfUse$: Observable<AreaOfUseActions.LoadAreasOfUseSuccess | AreaOfUseActions.LoadAreasOfUseFail> = createEffect(() =>
    this.actions$.pipe(
      ofType(AreaOfUseActions.AreaOfUseActionTypes.LoadAreasOfUse),
      switchMap((action: AreaOfUseActions.LoadAreasOfUse) => {
        return this.connector.load(action.userId).pipe(
          map((data) => new AreaOfUseActions.LoadAreasOfUseSuccess(data)),
          catchError((error) => of(new AreaOfUseActions.LoadAreasOfUseFail(normalizeHttpError(error))))
        );
      }),
      withdrawOn(this.contextChange$)
    )
  );

  constructor(private actions$: Actions, private connector: AreaOfUseConnector) {}
}
