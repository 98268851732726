import {
  ChangeDetectorRef,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { DynamicAttributeService, EventService } from '@spartacus/core';
import {
  CmsComponentsService,
  CmsInjectorService,
  ComponentCreateEvent,
  ComponentDestroyEvent,
  ComponentEvent,
  ComponentHandlerService,
} from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { ContentSlotComponentData } from '../../../core/cms';

/**
 * Directive used to facilitate instantiation of CMS driven dynamic components
 */
@Directive({
  selector: '[cxComponentWrapper]',
})
export class ComponentWrapperDirective implements OnInit, OnDestroy {
  @Input() cxComponentWrapper: ContentSlotComponentData;
  @Output() cxComponentRef = new EventEmitter<ComponentRef<any>>();
  @Input() styleClassesOverride?: string;

  private launcherResource?: Subscription;

  constructor(
    protected vcr: ViewContainerRef,
    protected cmsComponentsService: CmsComponentsService,
    protected injector: Injector,
    protected dynamicAttributeService: DynamicAttributeService,
    protected renderer: Renderer2,
    protected componentHandler: ComponentHandlerService,
    protected cmsInjector: CmsInjectorService,
    protected eventService: EventService
  ) {}

  ngOnInit() {
    this.cmsComponentsService.determineMappings([this.cxComponentWrapper.flexType]).subscribe(() => {
      if (this.cmsComponentsService.shouldRender(this.cxComponentWrapper.flexType)) {
        this.launchComponent();
      }
    });
  }

  private launchComponent() {
    const componentMapping = this.cmsComponentsService.getMapping(this.cxComponentWrapper.flexType);

    if (!componentMapping) {
      return;
    }

    this.launcherResource = this.componentHandler
      .getLauncher(
        componentMapping,
        this.vcr,
        this.cmsInjector.getInjector(this.cxComponentWrapper.flexType, this.cxComponentWrapper.uid, this.injector),
        this.cmsComponentsService.getModule(this.cxComponentWrapper.flexType)
      )
      .pipe(
        tap(({ elementRef, componentRef }) => {
          this.cxComponentRef.emit(componentRef);
          this.dispatchEvent(ComponentCreateEvent, elementRef);
          this.decorate(elementRef);
          this.injector.get(ChangeDetectorRef).markForCheck();
        }),
        finalize(() => this.dispatchEvent(ComponentDestroyEvent))
      )
      .subscribe();
  }

  /**
   * Dispatch the component event.
   *
   * The event is dispatched during creation and removal of the component.
   */
  protected dispatchEvent(event: Type<ComponentEvent>, elementRef?: ElementRef) {
    const payload = {
      typeCode: this.cxComponentWrapper.typeCode,
      id: this.cxComponentWrapper.uid,
    } as ComponentEvent;
    if (event === ComponentCreateEvent) {
      (payload as ComponentCreateEvent).host = elementRef?.nativeElement;
    }
    this.eventService?.dispatch(payload, event);
  }

  private decorate(elementRef: ElementRef): void {
    if (this.styleClassesOverride?.split) {
      this.styleClassesOverride.split(' ').forEach((cssClass) => {
        if (cssClass.trim()) {
          this.renderer.addClass(elementRef.nativeElement, cssClass.trim());
        }
      });
    }
    this.dynamicAttributeService.addAttributesToComponent(elementRef.nativeElement, this.renderer, this.cxComponentWrapper);
  }

  ngOnDestroy() {
    if (this.launcherResource) {
      this.launcherResource.unsubscribe();
    }
  }
}
