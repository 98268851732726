import { Inject, Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { LanguageService as CxLanguageService, LANGUAGE_CONTEXT_ID, SiteContextConfig } from '@spartacus/core';
import { i18n } from 'i18next';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { I18NEXT_INSTANCE } from '../../i18n/i18next/i18next-instance';
import { Language } from '../../model/misc.model';
import { getContextParameterValues } from '../config/context-config-utils';
import { SiteContextActions } from '../store/actions/index';
import { SiteContextSelectors } from '../store/selectors/index';
import { StateWithSiteContext } from '../store/site-context-state';
import { SiteContext } from './site-context.interface';

/**
 * Facade that provides easy access to language state, actions and selectors.
 */
@Injectable()
export class LanguageService extends CxLanguageService implements SiteContext<Language> {
  constructor(
    protected store: Store<StateWithSiteContext>,
    protected config: SiteContextConfig,
    @Inject(I18NEXT_INSTANCE) protected i18next: i18n
  ) {
    super(store, config);
  }

  /**
   * Represents all the languages supported by the current store.
   */
  getAll(): Observable<Language[]> {
    return this.store.pipe(
      select(SiteContextSelectors.getAllLanguages),
      tap((languages) => {
        if (!languages) {
          this.store.dispatch(new SiteContextActions.LoadLanguages());
        }
      }),
      filter((languages) => Boolean(languages))
    );
  }

  /**
   * Represents the isocode of the active language.
   */
  getActive(): Observable<string> {
    return this.store.pipe(
      select(SiteContextSelectors.getActiveLanguage),
      filter((active) => Boolean(active))
    );
  }

  /**
   * Get language details by language isocode
   */
  getLanguage(isocode: string): Observable<Language> {
    return this.getAll().pipe(map((languages) => languages.find((language) => language.isocode === isocode)));
  }

  /**
   * Sets the active language.
   */
  setActive(isocode: string): void {
    this.store.pipe(select(SiteContextSelectors.getActiveLanguage), take(1)).subscribe((activeLanguage) => {
      if (activeLanguage !== isocode && this.isValid(isocode)) {
        this.store.dispatch(new SiteContextActions.SetActiveLanguage(isocode));
      }
    });
  }

  /**
   * Tells whether the value of the active language has been already initialized
   */
  isInitialized$(): Observable<boolean> {
    return this.store.pipe(select(SiteContextSelectors.getActiveLanguage)).pipe(map((active) => !!active));
  }

  /**
   * Tells whether the language was changed, i18next event is emitted when chunks are loaded
   */
  listenOnLanguageChange(): Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      this.i18next.on('languageChanged', () => subscriber.next(true));
    });
  }

  /**
   * Tells whether the given iso code is allowed.
   *
   * The list of allowed iso codes can be configured in the `context` config of Spartacus.
   */
  protected isValid(value: string): boolean {
    return !!value && getContextParameterValues(this.config, LANGUAGE_CONTEXT_ID).includes(value);
  }
}
