<div ngbDropdown [open]="isOpen" (openChange)="isOpen = !isOpen" [autoClose]="false" placement="bottom-left">
  <span ngbDropdownToggle>
    <i nz-icon [nzType]="isOpen ? 'minus-circle' : 'plus-circle'"></i>
    {{ title }}</span
  >
  <div ngbDropdownMenu class="py-0">
    <ul *ngIf="options">
      <li ngbDropdownItem *ngFor="let option of options" [class.active]="isActive(option.value)">
        <div class="option" (click)="onChange(option)">
          <span class="label">{{ option.label }} </span>
        </div>
        <div class="option-icon" [class]="optionIconClass" *ngIf="optionIcon" (click)="onOptionIconAction(option)">
          <span>
            <i nz-icon [nzType]="optionIcon" nzTheme="outline"></i>
          </span>
        </div>
      </li>
      <li *ngIf="options.length === 0">
        <div class="option">
          <span class="label">{{ noItemsText }} </span>
        </div>
      </li>
    </ul>
    <div class="loading" *ngIf="loading">
      <cx-spinner></cx-spinner>
    </div>
  </div>
</div>
