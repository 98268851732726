import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        statistics: {
          module: () => import('./container/statistics.module').then((m) => m.StatisticsModule),
          dependencies: [() => import('./base/statistics-base.module').then((m) => m.StatisticsBaseModule)],
          cmsComponents: ['CmsStatisticsComponent'],
        },
      },
    }),
  ],
})
export class StatisticsfeatureModule {}
