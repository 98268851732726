import { MemoizedSelector, MemoizedSelectorWithProps, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import {
  EntriesState,
  PagesState,
  PaginationState,
  SearchPageResultState,
  SearchState,
} from '../../../features/store/base-feature-state';
import { DeliveryAddress, Pagination } from '../../../model';
import { PyStateUtils } from '../../../state';
import { ShipTosSearchParamsState, ShipTosState, StateWithUser, UserState } from '../user-state';
import { getUserState } from './feature.selector';

export const getShipTosState: MemoizedSelector<StateWithUser, ShipTosState> = createSelector(
  getUserState,
  (state: UserState) => state && state.shipTos
);

export const getShipTosSearchResultEntities: MemoizedSelector<StateWithUser, EntriesState<DeliveryAddress> | undefined> =
  createSelector(getShipTosState, (state: ShipTosState) => (state && state.entities) || undefined);

export const getShipTosSearchResultEntries: MemoizedSelectorWithProps<StateWithUser, any, DeliveryAddress[] | undefined> =
  createSelector(getShipTosSearchResultEntities, (entries: EntriesState<DeliveryAddress>, props) => {
    return (props.keys && entries && props.keys.map((i: string) => entries[i])) || undefined;
  });

export const getShipTosSearchParamsSelector: MemoizedSelector<StateWithUser, ShipTosSearchParamsState> = createSelector(
  getShipTosState,
  (state: ShipTosState) => (state && state.searchParams) || undefined
);

export const getShipTosSearchSelector: MemoizedSelector<StateWithUser, SearchState<SearchPageResultState> | undefined> =
  createSelector(getShipTosState, (state: ShipTosState) => (state && state.search) || undefined);

export const getShipTosSearchPaginationStateSelector: MemoizedSelector<
  StateWithUser,
  PaginationState<SearchPageResultState> | undefined
> = createSelector(
  getShipTosSearchSelector,
  getShipTosSearchParamsSelector,
  (state: SearchState<SearchPageResultState>, searchParams: ShipTosSearchParamsState) => {
    return (searchParams && state && state[searchParams.key]) || undefined;
  }
);

export const getShipTosSearchPaginationSelector: MemoizedSelector<StateWithUser, Pagination | undefined> = createSelector(
  getShipTosSearchPaginationStateSelector,
  (state) => (state && state.pagination) || undefined
);

export const getShipTosSearchPagesSelector: MemoizedSelector<StateWithUser, PagesState<SearchPageResultState> | undefined> =
  createSelector(
    getShipTosSearchParamsSelector,
    getShipTosSearchPaginationStateSelector,
    (queryParams: ShipTosSearchParamsState, paginationState: PaginationState<SearchPageResultState>) => {
      return (queryParams && paginationState && paginationState.pages) || undefined;
    }
  );

// Gets results only from the current pagination page
export const getShipTosSearchPageResultsSelector: MemoizedSelector<
  StateWithUser,
  StateUtils.LoaderState<SearchPageResultState>
> = createSelector(
  getShipTosSearchParamsSelector,
  getShipTosSearchPagesSelector,
  (queryParams: ShipTosSearchParamsState, pagesState: PagesState<SearchPageResultState>) => {
    return (queryParams && pagesState && pagesState[queryParams.page]) || PyStateUtils.initialLoaderState;
  }
);

// Gets array of IDs of all results between first and current pagination pages
export const getShipTosCombinedSearchPageResultsIdsSelector: MemoizedSelector<StateWithUser, Array<string>> = createSelector(
  getShipTosSearchParamsSelector,
  getShipTosSearchPagesSelector,
  (queryParams: ShipTosSearchParamsState, pagesState: PagesState<SearchPageResultState>) => {
    const combinedResults: Array<string> = [];
    if (queryParams && pagesState) {
      [...Array(queryParams.page + 1).keys()].forEach((pageNumber) => {
        if (pagesState[pageNumber]) {
          combinedResults.push(...StateUtils.loaderValueSelector(pagesState[pageNumber]).results);
        }
      });
    }
    return combinedResults;
  }
);
