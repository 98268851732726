import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { PrincipalConfigurationService } from '../../core/user';
import { OnlineChatService } from '../../shared/services/online-chat/online-chat.service';

@Injectable({
  providedIn: 'root',
})
export class OnlineChatFeatureService implements OnDestroy {
  subscriptions = new Subscription();

  constructor(
    private principalConfigurationService: PrincipalConfigurationService,
    private onlineChatService: OnlineChatService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  init(): void {
    this.subscriptions.add(
      this.principalConfigurationService
        .isEnabled('enableOnlineChat')
        .pipe(
          filter((isEnabled) => isEnabled),
          switchMap(() => this.onlineChatService.initOnlineChat())
        )
        .subscribe()
    );
  }
}
