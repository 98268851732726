import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'py-editable',
  templateUrl: './editable.component.html',
  styleUrls: ['./editable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableComponent implements AfterViewChecked {
  @ViewChild('inputRef', { read: ElementRef }) public inputRef: ElementRef;
  @ViewChild('hiddenTextRef', { read: ElementRef }) public hiddenTextRef: ElementRef;

  @Output() save = new EventEmitter();
  @Output() editing = new EventEmitter<boolean>();

  @Input() set content(content: string) {
    this.value = this._content = content;
  }

  @HostBinding('class.description')
  @Input()
  isDescription: boolean;

  @HostBinding('class.title')
  @Input()
  isTitle: boolean;

  @HostBinding('class.loading')
  @Input()
  loading = false;

  @HostBinding('class.saving')
  @Input()
  saving = false;

  @Input() maxlength = 35;
  @Input() placeholder?: string;
  @Input() disableEditing = false;
  @Input() cancellable = false;

  @HostBinding('class.secondary-variant')
  @Input()
  secondaryVariant: boolean = false;

  @Input()
  set editingAtStart(editingAtStart: boolean) {
    if (editingAtStart) {
      this.shouldEdit = true;
      this.shouldFocus = true;
      this.editing.emit(true);
    }
  }

  shouldEdit = false;
  value?: string;

  private _content: string;
  private shouldFocus = false;
  private cachedInputContentWidth: number | null = null;

  get content(): string {
    return this._content;
  }

  private get inputContentWidth(): number {
    const textLength = this.hiddenTextRef?.nativeElement.offsetWidth;
    return textLength;
  }

  constructor(private renderer: Renderer2) {}

  ngAfterViewChecked(): void {
    this.resizeInput();

    if (this.shouldFocus) {
      this.shouldFocus = false;
      this.focusInput();
    }
  }

  onEdit(): void {
    this.editing.emit(true);

    this.cachedInputContentWidth = null;
    this.shouldEdit = true;
    this.shouldFocus = true;
  }

  @HostListener('keyup.enter')
  onSave(): void {
    if (!this.value?.trim()) {
      return;
    }
    if (this.content !== this.value) {
      this.save.emit(this.value);
    }
    this.shouldEdit = false;
    this.editing.emit(false);
  }

  @HostListener('keyup.escape')
  onCancel(): void {
    this.value = this.content;
    this.shouldEdit = false;
    this.editing.emit(false);
  }

  private focusInput() {
    this.inputRef.nativeElement.focus();
  }

  private resizeInput() {
    if (!this.inputRef) {
      return;
    }
    if (this.cachedInputContentWidth === this.inputContentWidth) {
      return;
    }
    this.cachedInputContentWidth = this.inputContentWidth;
    this.renderer.setStyle(this.inputRef.nativeElement, 'width', `${this.inputContentWidth}px`);
  }
}
