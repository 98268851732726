import { Action } from '@ngrx/store';
import { GIVE_CONSENT_PROCESS_ID, PROCESS_FEATURE, StateUtils, USER_CONSENTS } from '@spartacus/core';
import { HttpErrorModel } from '../../../model';
import { Consent, ConsentTemplate } from '../../../model/consent.model';
import { PyStateUtils } from '../../../state';

export enum UserConsentsActionTypes {
  DenyUserConsent = '[User] Deny user consent',
  DenyUserConsentFail = '[User] Deny user consent fail',
  DenyUserConsentSuccess = '[User] Deny user consent success',
  ResetDenyUserConsent = '[User] Reset deny user consent',

  RequiredConsentsNotGiven = '[User] A required consent is not given by user',

  SetConsentsBannerVisible = '[User] Set consents banner visible',
  SetConsentsSettingsModalVisible = '[User] Set consents settings modal visible',
}

export class RequiredConsentsNotGiven extends StateUtils.LoaderLoadAction {
  readonly type = UserConsentsActionTypes.RequiredConsentsNotGiven;

  constructor(public payload: Consent[]) {
    super(USER_CONSENTS);
  }
}

export class DenyUserConsent extends StateUtils.EntityLoadAction {
  readonly type = UserConsentsActionTypes.DenyUserConsent;

  constructor(
    public payload: {
      userId: string;
      consentTemplateId: string;
      consentTemplateVersion: number;
    }
  ) {
    super(PROCESS_FEATURE, GIVE_CONSENT_PROCESS_ID);
  }
}

export class DenyUserConsentFail extends PyStateUtils.EntityFailAction {
  readonly type = UserConsentsActionTypes.DenyUserConsentFail;

  constructor(payload?: HttpErrorModel) {
    super(PROCESS_FEATURE, GIVE_CONSENT_PROCESS_ID, payload);
  }
}

export class DenyUserConsentSuccess extends StateUtils.EntitySuccessAction {
  readonly type = UserConsentsActionTypes.DenyUserConsentSuccess;

  constructor(public consentTemplate: ConsentTemplate) {
    super(PROCESS_FEATURE, GIVE_CONSENT_PROCESS_ID);
  }
}

export class ResetDenyUserConsent extends StateUtils.EntityLoaderResetAction {
  readonly type = UserConsentsActionTypes.ResetDenyUserConsent;

  constructor() {
    super(PROCESS_FEATURE, GIVE_CONSENT_PROCESS_ID);
  }
}

export class SetConsentsBannerVisible implements Action {
  readonly type = UserConsentsActionTypes.SetConsentsBannerVisible;
  constructor(public visible: boolean) {}
}

export class SetConsentsSettingsModalVisible implements Action {
  readonly type = UserConsentsActionTypes.SetConsentsSettingsModalVisible;
  constructor(public visible: boolean, public showCloseButton: boolean) {}
}

export type ConsentsActions =
  | DenyUserConsent
  | DenyUserConsentFail
  | DenyUserConsentSuccess
  | ResetDenyUserConsent
  | RequiredConsentsNotGiven
  | SetConsentsBannerVisible
  | SetConsentsSettingsModalVisible;
