import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, NgZone, PLATFORM_ID, inject } from '@angular/core';
import { LoggerService } from '@spartacus/core';
import { ResizeEvent } from './resize-event';

function elementIsHTMLElement(element: HTMLElement): boolean {
  return element instanceof HTMLElement;
}

/**
 * Wrapping the ResizeObserver in service to enable mocking in consumer tests
 */
@Injectable({
  providedIn: 'root',
})
export class ResizeSensorService {
  protected logger = inject(LoggerService);
  constructor(private zone: NgZone, @Inject(PLATFORM_ID) private platformId: string) {}

  initialize(element: HTMLElement, cb: (event: ResizeEvent) => void): ResizeObserver {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    if (!elementIsHTMLElement(element)) {
      this.logger.error(element);
      throw new Error('Unexpected non-element, unable to attach resize observer');
    }
    const observer = new ResizeObserver((entries) => {
      this.zone.run(() => {
        const contentRect = entries[0].contentRect as DOMRectReadOnly;
        const event: ResizeEvent = {
          width: contentRect.width,
        };
        cb(event);
      });
    });
    observer.observe(element);
    return observer;
  }
}
