import { Injector } from '@angular/core';
import { Route, Router } from '@angular/router';
import { CmsPageGuard } from '@spartacus/storefront';
import { PageLayoutComponent } from '../../page/page-layout/page-layout.component';

const cmsRoute: Route = {
  path: '**',
  canActivate: [CmsPageGuard],
  component: PageLayoutComponent,
};

export function addCmsRoute(injector: Injector): () => Promise<void> {
  const result = async () => {
    const router = injector.get(Router);
    router.config.push(cmsRoute);
  };
  return result;
}
