import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import { PyStateUtils } from '../../../state';
import { FORGOT_USERNAME_PROCESS } from '../user-state';

export enum ForgotUsernameActionTypes {
  RequestForgottenUsernameEmail = '[User] Request forgotten Username email',
  RequestForgottenUsernameEmailSuccess = '[User] Request forgotten Username email success',
  RequestForgottenUsernameEmailFail = '[User] Request forgotten Username email fail',
  RequestForgottenUsernameEmailReset = '[User] Request forgotten Username email reset',
}

export class ForgotUsernameEmailRequest extends StateUtils.EntityLoadAction {
  readonly type = ForgotUsernameActionTypes.RequestForgottenUsernameEmail;

  constructor(public payload: { email: string; username?: string }) {
    super(PROCESS_FEATURE, FORGOT_USERNAME_PROCESS);
  }
}

export class ForgotUsernameEmailRequestFail extends PyStateUtils.EntityFailAction {
  readonly type = ForgotUsernameActionTypes.RequestForgottenUsernameEmailFail;

  constructor(public payload?: any) {
    super(PROCESS_FEATURE, FORGOT_USERNAME_PROCESS, payload);
  }
}

export class ForgotUsernameEmailRequestSuccess extends StateUtils.EntitySuccessAction {
  readonly type = ForgotUsernameActionTypes.RequestForgottenUsernameEmailSuccess;

  constructor() {
    super(PROCESS_FEATURE, FORGOT_USERNAME_PROCESS);
  }
}

export class ForgotUsernameEmailRequestReset extends StateUtils.EntityLoaderResetAction {
  readonly type = ForgotUsernameActionTypes.RequestForgottenUsernameEmailReset;

  constructor() {
    super(PROCESS_FEATURE, FORGOT_USERNAME_PROCESS);
  }
}

export type ForgotUsernameEmailRequestAction =
  | ForgotUsernameEmailRequest
  | ForgotUsernameEmailRequestFail
  | ForgotUsernameEmailRequestSuccess
  | ForgotUsernameEmailRequestReset;
