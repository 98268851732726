import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FullPalletUpgradeModule } from '../full-pallet-upgrade';
import { OrderEntryFullPalletUpgradeWrapperComponent } from './order-entry-full-pallet-upgrade-wrapper.component';

@NgModule({
  declarations: [OrderEntryFullPalletUpgradeWrapperComponent],
  imports: [CommonModule, FullPalletUpgradeModule],
  exports: [OrderEntryFullPalletUpgradeWrapperComponent],
})
export class OrderEntryFullPalletUpgradeWrapperModule {}
