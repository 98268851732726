import { Pipe, PipeTransform } from '@angular/core';
import { CxDatePipe } from '@spartacus/core';
import { BaseSite } from '../../model/misc.model';
import { BaseSiteService, LanguageService } from '../../site-context/facade';
import { TimeUtils } from '../../util';

@Pipe({
  name: 'cxDate',
})
export class PyDatePipe extends CxDatePipe implements PipeTransform {
  private readonly DATE_WITH_TIME = 'dateTime';

  constructor(protected language: LanguageService, protected baseSiteService: BaseSiteService) {
    super(language);
  }

  transform(value: any, format?: string, timezone?: string): string | null;
  transform(value: null | undefined, format?: string, timezone?: string): null;
  transform(value: any, format?: string, timezone?: string): string | null {
    const site = this.getBaseSite();
    return super.transform(value, this.getProperDateFormat(site, format), timezone);
  }

  private getBaseSite(): BaseSite {
    let result: BaseSite;
    this.baseSiteService
      .get()
      .subscribe((baseSite) => (result = baseSite))
      .unsubscribe();
    return result;
  }

  private getProperDateFormat(site: BaseSite, format: string | undefined): string {
    if (!format) {
      return site.dateFormat || TimeUtils.defaultDateFormat();
    }

    if (format === this.DATE_WITH_TIME) {
      return site.dateTimeFormat || TimeUtils.defaultDateTimeFormat();
    }

    return format;
  }
}
