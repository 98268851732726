<ng-template [ngIf]="loading" [ngIfElse]="loaded">
  <button
    *ngIf="!!buttonId; else loadingButtonWithoutId"
    [id]="buttonId"
    class="loader"
    [ngClass]="buttonClass"
    [class.cursor-wait]="true"
    [disabled]="true"
    [type]="type"
  >
    <!-- We changed the ant-icon loader svg to cx-spinner because there seems to be some scenarios where the loader gets stuck, cx-spinner however is a css loader and seems to work better -->
    <cx-spinner></cx-spinner>
  </button>
</ng-template>

<ng-template #loadingButtonWithoutId>
  <button [ngClass]="buttonClass" [disabled]="true" [type]="type" [class.cursor-wait]="true">
    <cx-spinner [type]="spinnerType"></cx-spinner>
  </button>
</ng-template>

<ng-template #loaded>
  <ng-content></ng-content>
  <button
    *ngIf="!!buttonId; else loadedButtonWithoutId"
    [id]="buttonId"
    class="animated"
    [ngClass]="buttonClass"
    [disabled]="disabled"
    [class.shakeX]="error"
    [type]="type"
  >
    <ng-container *ngTemplateOutlet="loadedButtonContent"></ng-container>
  </button>
</ng-template>

<ng-template #loadedButtonWithoutId>
  <button class="animated" [ngClass]="buttonClass" [disabled]="disabled" [class.shakeX]="error" [type]="type">
    <ng-content></ng-content>
    <ng-container *ngTemplateOutlet="loadedButtonContent"></ng-container>
  </button>
</ng-template>

<ng-template #loadedButtonContent>
  <ng-template [ngIf]="success" [ngIfElse]="save">
    <i nz-icon nzType="check"></i>
    <ng-template [ngIf]="successText">{{ successText }}</ng-template>
  </ng-template>
  <ng-template #save>
    <ng-container *ngIf="buttonIcon; else templateLabel">
      <i nz-icon [nzType]="buttonIcon"></i>
    </ng-container>
    <ng-template #templateLabel>{{ buttonLabel }}</ng-template>
  </ng-template>
</ng-template>
