import { NgModule } from '@angular/core';
import { CmsConfig } from '@spartacus/core';
import { provideConfig } from '../../../core/config';

@NgModule({
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        pageNotFound: {
          module: () => import('./error-not-found-page/error-not-found-module').then((m) => m.ErrorNotFoundModule),
          cmsComponents: ['CmsErrorNotFoundFlexComponent'],
        },
      },
    }),
  ],
})
export class CMSPagesFeatureModule {}
