import { Category } from './catalog.model';

export interface AreaOfUse extends Category {
  productsCount?: number;
  readyToPublish?: boolean;
  subCategories?: Array<AreaOfUse>;
  productCodes?: string[];
  position?: number;
  unavailableProductsCount?: number;
  excludedArticleCodes?: string[];
}

export interface AreaOfUseList {
  areasOfUse?: AreaOfUse[];
}

export interface AreaOfUseEditParams {
  name?: string;
  description?: string;
  position?: number;
  readyToPublish?: boolean;
}

export enum AreaOfUseStatusEnum {
  NOT_SYNCED = 'NOT_SYNCED',
  SYNCING = 'SYNCING',
  SYNCED = 'SYNCED',
}
