export interface RegExpRule {
  key: string;
  value: RegExp;
  translationKey?: string;
}

export abstract class RegExpRules {
  static readonly AT_LEAST_ONE_LOWER_CASE: RegExpRule = {
    key: 'lowerCase',
    value: RegExp(/^(?=.*?[a-z])/),
  };
  static readonly AT_LEAST_ONE_UPPER_CASE: RegExpRule = {
    key: 'upperCase',
    value: RegExp(/^(?=.*?[A-Z])/),
  };
  static readonly AT_LEAST_ONE_DIGIT: RegExpRule = {
    key: 'digit',
    value: RegExp(/^(?=.*?[0-9])/),
  };
  static readonly AT_LEAST_ONE_SPECIAL_CHAR: RegExpRule = {
    key: 'specialChar',
    value: RegExp(/^(?=.*?["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"])/),
  };
  static readonly AT_LEAST_ONE_DIGIT_OR_CHAR: RegExpRule = {
    key: 'digitOrChar',
    value: RegExp(/^(?=.*?[0-9a-zA-Z])/),
  };
  static readonly AT_LEAST_TWO_WORDS: RegExpRule = {
    key: 'twoWords',
    value: RegExp(/^(\S+\s).+/),
  };
}
