import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AnonymousConsent } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AnonymousConsentsService } from '../../../../core/anonymous-consents';
import { ConsentTemplate } from '../../../../core/model';
import { SitePrefixService } from '../../../../core/site-context';
import { fadeInFadeOutAnimation } from '../../../../shared';

@Component({
  selector: 'py-anonymous-consent-management',
  templateUrl: './anonymous-consent-management.component.html',
  styleUrls: ['./anonymous-consent-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInFadeOutAnimation],
})
export class AnonymousConsentManagementComponent implements OnInit, OnDestroy {
  anonymousConsentTemplates$: Observable<ConsentTemplate[]>;
  anonymousConsents$: Observable<AnonymousConsent[]>;

  private subscriptions = new Subscription();

  bannerVisible$: Observable<boolean> = this.anonymousConsentsService.isBannerVisible();
  settingsVisible$: Observable<boolean> = this.anonymousConsentsService.isSettingsVisible();
  showCloseButtin$: Observable<boolean> = this.anonymousConsentsService.showCloseButton();
  hasPageSitePrefix$: Observable<boolean>;

  constructor(private anonymousConsentsService: AnonymousConsentsService, private sitePrefixService: SitePrefixService) {}

  ngOnInit(): void {
    this.anonymousConsentTemplates$ = this.anonymousConsentsService.getTemplates();
    this.anonymousConsents$ = this.anonymousConsentsService.getConsents();
    this.hasPageSitePrefix$ = this.sitePrefixService.getActive().pipe(map((sitePrefix) => sitePrefix !== ''));
  }

  onViewSettings(): void {
    this.hideBanner();
    this.showSettings();
  }

  hideSettingsAndShowBanner(): void {
    this.hideSettings();
    this.showBanner();
  }

  onAllowAll(): void {
    this.subscriptions.add(
      this.anonymousConsentsService
        .giveAllConsents()
        .pipe(take(1))
        .subscribe(() => this.hideBanner())
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  hideBanner(): void {
    this.anonymousConsentsService.toggleBannerDismissed(true);
  }

  showBanner(): void {
    this.anonymousConsentsService.toggleBannerDismissed(false);
  }

  showSettings() {
    this.anonymousConsentsService.toggleSettingsVisible(true);
  }

  hideSettings() {
    this.anonymousConsentsService.toggleSettingsVisible(false);
  }

  saveSettings(values: { [key: string]: boolean }) {
    Object.entries(values).forEach((value) => {
      if (value[1]) {
        this.anonymousConsentsService.giveConsent(value[0]);
      } else {
        this.anonymousConsentsService.withdrawConsent(value[0]);
      }
    });
    this.hideSettings();
  }
}
