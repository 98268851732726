import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ModalEffects } from './store/effects/modal.effect';

@NgModule({
  imports: [EffectsModule.forFeature([ModalEffects])],
  providers: [],
})
export class ModalModule {
  static forRoot(): ModuleWithProviders<ModalModule> {
    return {
      ngModule: ModalModule,
      providers: [],
    };
  }
}
