import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KeyboardFocusModule } from '@spartacus/storefront';
import { I18nModule } from '../../../core/i18n';
import { LaunchDialogService } from '../../../core/modal';
import { ActionButtonModule } from '../action-button';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { ModalModule } from '../modal';
import { RadioModule } from '../radio/radio.module';
import { OrderEntryAccountingCodesModalComponentModalLayoutConfig } from './order-entry-accounting-codes-modal-layout.config';
import { OrderEntryAccountingCodesModalComponent } from './order-entry-accounting-codes-modal.component';

@NgModule({
  imports: [CommonModule, I18nModule, ModalModule, RadioModule, CheckboxModule, KeyboardFocusModule, ActionButtonModule],
  declarations: [OrderEntryAccountingCodesModalComponent],
  exports: [OrderEntryAccountingCodesModalComponent],
})
export class OrderEntryAccountingCodesModalModule {
  constructor(private launchDialogService: LaunchDialogService) {
    this.launchDialogService.registerModalLayoutConfig(OrderEntryAccountingCodesModalComponentModalLayoutConfig);
  }
}
