import { InjectionToken, Provider } from '@angular/core';
import { ActionReducer, ActionReducerMap, MetaReducer, combineReducers } from '@ngrx/store';
import { LoginLogoutActionTypes } from '../../../../auth/user-auth/store/actions/login-logout.action';
import { PriceFileEntry, PriceFileFormat, PriceFileRequestTemplate } from '../../../../model/price-file.model';
import { CurrenciesActionTypes } from '../../../../site-context/store/actions/currencies.action';
import { LanguagesActionTypes } from '../../../../site-context/store/actions/languages.action';
import { PyStateUtils } from '../../../../state';
import { ArrayState, VirtualPaginationState } from '../../../store/base-feature-state';
import { reducer as searchParamsReducer } from '../../../store/reducers/search-params.reducer';
import { reducer as searchReducer } from '../../../store/reducers/search.reducer';
import { reducer as userInteractionReducer } from '../../../store/reducers/user-interaction.reducer';
import { PriceFileActionTypes } from '../actions/price-file.actions';
import {
  PRICE_FILE,
  PRICE_FILE_CANCEL,
  PRICE_FILE_DELETE,
  PRICE_FILE_FORMAT,
  PRICE_FILE_REQUEST,
  PRICE_FILE_REQUEST_TEMPLATE,
  PriceFileState,
} from '../price-file-state';
import { reducer as priceFileEntitiesReducer } from './price-file-entities.reducer';
import { reducer as priceFileFormatReducer } from './price-file-format.reducer';
import { reducer as priceFileRequestTemplateEntitiesReducer } from './price-file-request-template.reducer';
import { clearUserInteractionState } from './price-file-user-interaction-meta.reducer';
import { reducer as priceFilesReducer } from './price-file.reducer';

export function getReducers(): ActionReducerMap<PriceFileState, any> {
  return {
    priceFiles: combineReducers({
      search: searchReducer(PRICE_FILE, priceFilesReducer),
      searchParams: searchParamsReducer(PRICE_FILE),
      entities: priceFileEntitiesReducer,
      request: PyStateUtils.loaderReducer<PriceFileEntry>(PRICE_FILE_REQUEST),
      cancel: userInteractionReducer(PRICE_FILE_CANCEL),
      delete: userInteractionReducer(PRICE_FILE_DELETE),
    }),
    templates: combineReducers({
      searchParams: searchParamsReducer(PRICE_FILE_REQUEST_TEMPLATE),
      entities: PyStateUtils.loaderReducer<VirtualPaginationState<PriceFileRequestTemplate>>(
        PRICE_FILE_REQUEST_TEMPLATE,
        priceFileRequestTemplateEntitiesReducer
      ),
      template: PyStateUtils.loaderReducer<PriceFileRequestTemplate>(PRICE_FILE_REQUEST_TEMPLATE),
    }),
    formats: PyStateUtils.loaderReducer<ArrayState<PriceFileFormat>>(PRICE_FILE_FORMAT, priceFileFormatReducer),
    soldTos: undefined,
  };
}

export const reducerToken = new InjectionToken<ActionReducerMap<PriceFileState>>('PriceFileReducers');

export const reducerProvider: Provider = {
  provide: reducerToken,
  useFactory: getReducers,
};

export function clearPriceFileState(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (
      action.type === LoginLogoutActionTypes.Logout ||
      action.type === CurrenciesActionTypes.CurrencyChange ||
      action.type === LanguagesActionTypes.LanguageChange ||
      action.type === PriceFileActionTypes.ClearPriceFile
    ) {
      state = undefined;
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [clearPriceFileState, clearUserInteractionState];
