import { StateUtils } from '@spartacus/core';
import { ArticleRef, HttpErrorModel, OrderEntry, Unit } from '../../../model';
import { SHOPPING_LIST } from '../user-state';

export enum ShoppingListEntryActionTypes {
  AddShoppingListEntry = '[User] Add shopping list entry',
  AddShoppingListEntrySuccess = '[User] Add shopping list entry success',
  AddShoppingListEntryFail = '[User] Add shopping list entry fail',

  RemoveShoppingListEntry = '[User] Remove shopping list entry',
  RemoveShoppingListEntrySuccess = '[User] Remove shopping list entry success',
  RemoveShoppingListEntryFail = '[User] Remove shopping list entry fail',

  RemoveShoppingListEntries = '[User] Remove shopping list entries',
  RemoveShoppingListEntriesSuccess = '[User] Remove shopping list entries success',
  RemoveShoppingListEntriesFail = '[User] Remove shopping list entries fail',

  UpdateShoppingListEntry = '[User] Update shopping list entry',
  UpdateShoppingListEntrySuccess = '[User] Update shopping list entry success',
  UpdateShoppingListEntryFail = '[User] Update shopping list entry fail',

  ReplaceShoppingListEntry = '[User] Replace shopping list entry',
  ReplaceShoppingListEntrySuccess = '[User] Replace shopping list entry success',
  ReplaceShoppingListEntryFail = '[User] Replace shopping list entry fail',
}

interface AddShoppingListEntryPayload {
  userId: string;
  code: string;
  articleCode: string;
  quantity: number;
  unit: Unit;
  note?: string;
  displayNumber?: string;
}

interface AddShoppingListEntryFailPayload extends AddShoppingListEntryPayload {
  error?: HttpErrorModel;
}

interface AddShoppingListEntrySuccessPayload extends AddShoppingListEntryPayload {
  entry: OrderEntry;
  quantityAdded: number;
  statusCode: string;
  statusMessage: string;
}

interface RemoveShoppingListEntryPayload {
  userId: string;
  code: string;
  entry: OrderEntry;
}

interface RemoveShoppingListEntryFailPayload extends RemoveShoppingListEntryPayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RemoveShoppingListEntrySuccessPayload extends RemoveShoppingListEntryPayload {}

interface RemoveShoppingListEntriesPayload {
  userId: string;
  code: string;
  entries: OrderEntry[];
}

interface RemoveShoppingListEntriesFailPayload extends RemoveShoppingListEntriesPayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RemoveShoppingListEntriesSuccessPayload extends RemoveShoppingListEntriesPayload {}

interface UpdateShoppingListEntryPayload {
  userId: string;
  code: string;
  entry: OrderEntry;
  quantity: number;
  unit: Unit;
  note?: string;
  statisticsCode?: string;
}

interface UpdateShoppingListEntryFailPayload extends UpdateShoppingListEntryPayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UpdateShoppingListEntrySuccessPayload extends UpdateShoppingListEntryPayload {}

interface ReplaceShoppingListEntryPayload {
  userId: string;
  code: string;
  entry: OrderEntry;
  articleNumber: ArticleRef;
  quantity: number;
  unit: Unit;
}

interface ReplaceShoppingListEntryFailPayload extends ReplaceShoppingListEntryPayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ReplaceShoppingListEntrySuccessPayload extends ReplaceShoppingListEntryPayload {}

export class AddShoppingListEntry extends StateUtils.EntityProcessesIncrementAction {
  readonly type = ShoppingListEntryActionTypes.AddShoppingListEntry;

  constructor(public payload: AddShoppingListEntryPayload) {
    super(SHOPPING_LIST, payload.code);
  }
}

export class AddShoppingListEntryFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = ShoppingListEntryActionTypes.AddShoppingListEntryFail;

  constructor(public payload: AddShoppingListEntryFailPayload) {
    super(SHOPPING_LIST, payload.code);
  }
}

export class AddShoppingListEntrySuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = ShoppingListEntryActionTypes.AddShoppingListEntrySuccess;

  constructor(public payload: AddShoppingListEntrySuccessPayload) {
    super(SHOPPING_LIST, payload.code);
  }
}

export class RemoveShoppingListEntry extends StateUtils.EntityProcessesIncrementAction {
  readonly type = ShoppingListEntryActionTypes.RemoveShoppingListEntry;

  constructor(public payload: RemoveShoppingListEntryPayload) {
    super(SHOPPING_LIST, payload.code);
  }
}

export class RemoveShoppingListEntryFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = ShoppingListEntryActionTypes.RemoveShoppingListEntryFail;

  constructor(public payload: RemoveShoppingListEntryFailPayload) {
    super(SHOPPING_LIST, payload.code);
  }
}

export class RemoveShoppingListEntrySuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = ShoppingListEntryActionTypes.RemoveShoppingListEntrySuccess;

  constructor(public payload: RemoveShoppingListEntrySuccessPayload) {
    super(SHOPPING_LIST, payload.code);
  }
}

export class RemoveShoppingListEntries extends StateUtils.EntityProcessesIncrementAction {
  readonly type = ShoppingListEntryActionTypes.RemoveShoppingListEntries;

  constructor(public payload: RemoveShoppingListEntriesPayload) {
    super(SHOPPING_LIST, payload.code);
  }
}

export class RemoveShoppingListEntriesFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = ShoppingListEntryActionTypes.RemoveShoppingListEntriesFail;

  constructor(public payload: RemoveShoppingListEntriesFailPayload) {
    super(SHOPPING_LIST, payload.code);
  }
}

export class RemoveShoppingListEntriesSuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = ShoppingListEntryActionTypes.RemoveShoppingListEntriesSuccess;

  constructor(public payload: RemoveShoppingListEntriesSuccessPayload) {
    super(SHOPPING_LIST, payload.code);
  }
}

export class UpdateShoppingListEntry extends StateUtils.EntityProcessesIncrementAction {
  readonly type = ShoppingListEntryActionTypes.UpdateShoppingListEntry;

  constructor(public payload: UpdateShoppingListEntryPayload) {
    super(SHOPPING_LIST, payload.code);
  }
}

export class UpdateShoppingListEntryFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = ShoppingListEntryActionTypes.UpdateShoppingListEntryFail;

  constructor(public payload: UpdateShoppingListEntryFailPayload) {
    super(SHOPPING_LIST, payload.code);
  }
}

export class UpdateShoppingListEntrySuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = ShoppingListEntryActionTypes.UpdateShoppingListEntrySuccess;

  constructor(public payload: UpdateShoppingListEntrySuccessPayload) {
    super(SHOPPING_LIST, payload.code);
  }
}

export class ReplaceShoppingListEntry extends StateUtils.EntityProcessesIncrementAction {
  readonly type = ShoppingListEntryActionTypes.ReplaceShoppingListEntry;

  constructor(public payload: ReplaceShoppingListEntryPayload) {
    super(SHOPPING_LIST, payload.code);
  }
}

export class ReplaceShoppingListEntryFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = ShoppingListEntryActionTypes.ReplaceShoppingListEntryFail;

  constructor(public payload: ReplaceShoppingListEntryFailPayload) {
    super(SHOPPING_LIST, payload.code);
  }
}

export class ReplaceShoppingListEntrySuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = ShoppingListEntryActionTypes.ReplaceShoppingListEntrySuccess;

  constructor(public payload: ReplaceShoppingListEntrySuccessPayload) {
    super(SHOPPING_LIST, payload.code);
  }
}

export type ShoppingListEntryActions =
  | AddShoppingListEntry
  | AddShoppingListEntryFail
  | AddShoppingListEntrySuccess
  | RemoveShoppingListEntry
  | RemoveShoppingListEntryFail
  | RemoveShoppingListEntrySuccess
  | UpdateShoppingListEntry
  | UpdateShoppingListEntryFail
  | UpdateShoppingListEntrySuccess
  | ReplaceShoppingListEntry
  | ReplaceShoppingListEntryFail
  | ReplaceShoppingListEntrySuccess;
