<ng-template
  [cxPageTemplateStyle]="layoutName$ | async"
  [cxOutlet]="layoutName$ | async"
  [cxOutletContext]="{
    templateName$: templateName$,
    slots$: slots$,
    section$: section$
  }"
>
  <ng-content></ng-content>

  <cx-page-slot
    *ngFor="let slot of slots$ | async"
    [position]="slot"
    [isPageFold]="slot === (pageFoldSlot$ | async)"
  ></cx-page-slot>
</ng-template>
