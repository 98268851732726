import { Injectable } from '@angular/core';
import { BehaviorSubject, delay, distinctUntilChanged, filter, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ViewLoadedService {
  private loaded$ = new BehaviorSubject<boolean>(false);

  setLoaded(): void {
    if (!this.loaded$.value) {
      this.loaded$.next(true);
    }
  }

  viewLoaded(): Observable<boolean> {
    return this.loaded$.pipe(
      filter((value) => Boolean(value)),
      delay(300),
      distinctUntilChanged()
    );
  }
}
