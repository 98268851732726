import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../../../core/i18n';
import { GenericLinkModule, MegamenuListItemsModule } from '../../../../../shared';
import { NoResultsModule } from '../../../../../shared/components/no-results/no-results.module';
import { MenuAreaOfUseSubCategoriesComponent } from './menu-area-of-use-sub-categories.component';

@NgModule({
  imports: [CommonModule, I18nModule, GenericLinkModule, NzIconModule, NoResultsModule, MegamenuListItemsModule],
  declarations: [MenuAreaOfUseSubCategoriesComponent],
  exports: [MenuAreaOfUseSubCategoriesComponent],
})
export class MenuAreaOfUseSubCategoriesModule {}
