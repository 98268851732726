import { StateUtils } from '@spartacus/core';
import { PyStateUtils } from '../../../../state/utils';
import { PRICE_FILE_FORMAT } from '../price-file-state';

export enum PriceFileFormatActionTypes {
  LoadPriceFileFormats = '[PriceFile] Load Price File Formats',
  LoadPriceFileFormatsSuccess = '[PriceFile] Load Price File Formats Success',
  LoadPriceFileFormatsFail = '[PriceFile] Load Price File Formats Fail',
}

export class LoadPriceFileFormats extends StateUtils.LoaderLoadAction {
  readonly type = PriceFileFormatActionTypes.LoadPriceFileFormats;

  constructor(public userId: string) {
    super(PRICE_FILE_FORMAT);
  }
}

export class LoadPriceFileFormatsSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = PriceFileFormatActionTypes.LoadPriceFileFormatsSuccess;

  constructor(public payload: any) {
    super(PRICE_FILE_FORMAT);
  }
}

export class LoadPriceFileFormatsFail extends PyStateUtils.LoaderFailAction {
  readonly type = PriceFileFormatActionTypes.LoadPriceFileFormatsFail;

  constructor(public payload?: any) {
    super(PRICE_FILE_FORMAT, payload);
  }
}

export type PriceFileFormatActions = LoadPriceFileFormats | LoadPriceFileFormatsSuccess | LoadPriceFileFormatsFail;
