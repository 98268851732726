import { HttpResponseStatus } from '@spartacus/core';

declare module '@spartacus/core' {
  enum HttpResponseStatus {
    NO_CONTENT = 204,
    NOT_MODIFIED = 304,
    GONE = 410,
    UNPROCESSABLE_ENTITY = 422,
    SERVICE_UNAVAILABLE = 503,
  }
}

// I couldn't use `const enum` above (linting errors), so we need to provide
// values for the underlying object as per "Augmenting Enums" documentation
// in the Storefront Development Guide.
(HttpResponseStatus as any)['NO_CONTENT'] = 204;
(HttpResponseStatus as any)['NOT_MODIFIED'] = 304;
(HttpResponseStatus as any)['GONE'] = 410;
(HttpResponseStatus as any)['UNPROCESSABLE_ENTITY'] = 422;
(HttpResponseStatus as any)['SERVICE_UNAVAILABLE'] = 503;

export { HttpResponseStatus };
