import { InjectionToken, Provider } from '@angular/core';
import { Action, ActionReducer, ActionReducerMap, MetaReducer, combineReducers } from '@ngrx/store';
import { ANONYMOUS_CONSENTS } from '@spartacus/core';
import { LoginLogoutActionTypes } from '../../../auth/user-auth/store/actions/login-logout.action';
import { ConsentTemplate } from '../../../model/consent.model';
import { LanguagesActionTypes } from '../../../site-context/store/actions/languages.action';
import { PyStateUtils } from '../../../state';
import { AnonymousConsentsState } from '../anonymous-consents-state';
import * as fromAnonymousConsentsBanner from './anonymous-consents-banner.reducer';
import * as fromAnonymousConsentsSettings from './anonymous-consents-settings.reducer';
import * as fromAnonymousConsentsShowCloseButton from './anonymous-consents-show-close-button.reducer';
import * as fromAnonymousConsentsUpdate from './anonymous-consents-update.reducer';
import * as fromAnonymousConsents from './anonymous-consents.reducer';

export function getReducers(): ActionReducerMap<AnonymousConsentsState, any> {
  return {
    templates: PyStateUtils.loaderReducer<ConsentTemplate[]>(ANONYMOUS_CONSENTS),
    consents: fromAnonymousConsents.reducer,
    ui: combineReducers({
      bannerDismissed: fromAnonymousConsentsBanner.reducer,
      settingsVisible: fromAnonymousConsentsSettings.reducer,
      showCloseButton: fromAnonymousConsentsShowCloseButton.reducer,
      updated: fromAnonymousConsentsUpdate.reducer,
    }),
  };
}

export const reducerToken: InjectionToken<ActionReducerMap<AnonymousConsentsState>> = new InjectionToken<
  ActionReducerMap<AnonymousConsentsState>
>('AnonymousConsentsReducers');

export const reducerProvider: Provider = {
  provide: reducerToken,
  useFactory: getReducers,
};

export function clearAnonymousConsentTemplates(
  reducer: ActionReducer<AnonymousConsentsState, Action>
): ActionReducer<AnonymousConsentsState, Action> {
  return function (state, action) {
    if (action.type === LoginLogoutActionTypes.Logout || action.type === LanguagesActionTypes.LanguageChange) {
      state = {
        ...state,
        templates: undefined,
      };
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [clearAnonymousConsentTemplates];
