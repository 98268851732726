import { MemoizedSelector, createSelector } from '@ngrx/store';
import { StateWithAnonymousConsents } from '../anonymous-consents-state';
import { getAnonymousConsentState } from './feature.selector';

export const getAnonymousConsentsSettingsVisible: MemoizedSelector<StateWithAnonymousConsents, boolean> = createSelector(
  getAnonymousConsentState,
  (state) => state.ui.settingsVisible
);

export const getAnonymousConsentsShowCloseButtonVisible: MemoizedSelector<StateWithAnonymousConsents, boolean> = createSelector(
  getAnonymousConsentState,
  (state) => state.ui.showCloseButton
);
