import { LAUNCH_CALLER } from '@spartacus/storefront';

export interface ModalCloseReasonWithPayload {
  reason: string;
  payload: any;
}

export function isModalCloseReasonWithPayload(value: any): boolean {
  return !!value && typeof value === 'object' && 'reason' in value && 'payload' in value;
}

export const ModalCloseReason = {
  ADDRESS_EDITOR_SAVED: `${LAUNCH_CALLER.ADDRESS_EDITOR} - saved`,
  CREATE_SHOPPING_LIST_CREATE_NEW_CONFIRMED: `${LAUNCH_CALLER.CREATE_SHOPPING_LIST} - create new confirmed`,
  CREATE_SHOPPING_LIST_CREATE_NEW_WITH_ENTRY_CONFIRMED: `${LAUNCH_CALLER.CREATE_SHOPPING_LIST} - create new with entry confirmed`,
  CREATE_SHOPPING_LIST_CREATE_FROM_CART_CONFIRMED: `${LAUNCH_CALLER.CREATE_SHOPPING_LIST} - create from cart confirmed`,
  CREATE_SHOPPING_LIST_EDIT_CONFIRMED: `${LAUNCH_CALLER.CREATE_SHOPPING_LIST} - edit confirmed`,
  CREATE_SHOPPING_LIST_DUPLICATE_SUCCESS: `${LAUNCH_CALLER.CREATE_SHOPPING_LIST} - duplicate success`,
  DELETE_SHOPPING_LIST_SUCCESS: `${LAUNCH_CALLER.DELETE_SHOPPING_LIST} - delete success`,
  EXPORT_SHOPPING_LIST_SUCCESS: `${LAUNCH_CALLER.EXPORT_SHOPPING_LIST} - export success`,
  REMOVE_SHOPPING_LIST_ENTRIES_CONFIRMED: `${LAUNCH_CALLER.REMOVE_SHOPPING_LIST_ENTRIES} - confirmed`,
  CONNECT_ALL_DISMISSED: `${LAUNCH_CALLER.CONNECT_ALL} - dismissed`,
  CONNECT_ALL_CONFIRMED: `${LAUNCH_CALLER.CONNECT_ALL} - confirmed`,
  REMOVE_PRINCIPALS_SUCCESS: `${LAUNCH_CALLER.REMOVE_PRINCIPALS} - remove success`,
  CHANGE_CONTACT_PERSON_DISMISSED: `${LAUNCH_CALLER.CHANGE_CONTACT_PERSON} - dismissed`,
  CHANGE_CONTACT_PERSON_CONFIRMED: `${LAUNCH_CALLER.CHANGE_CONTACT_PERSON} - confirmed`,
  EMPTY_CART_CONFIRMED: `${LAUNCH_CALLER.EMPTY_CART} - confirmed`,
  ORDER_ENTRY_ACCOUNTING_CODES_CONFIRMED: `${LAUNCH_CALLER.ORDER_ENTRY_ACCOUNTING_CODES} - confirmed`,
  SAMPLE_ORDER_CONFIRMED: `${LAUNCH_CALLER.SAMPLE_ORDER} - confirmed`,
  SAMPLE_ORDER_DISMISSED: `${LAUNCH_CALLER.SAMPLE_ORDER} - dismissed`,
  AREA_OF_USE_ADMIN_OVERVIEW_HELP_DISMISSED: `${LAUNCH_CALLER.AREA_OF_USE_ADMIN_OVERVIEW_HELP} - dismissed`,
  AREA_OF_USE_ADMIN_IMPORT_DISMISSED: `${LAUNCH_CALLER.AREA_OF_USE_ADMIN_IMPORT} - dismissed`,
  AREA_OF_USE_REMOVE_PRODUCTS_CONFIRMED: `${LAUNCH_CALLER.AREA_OF_USE_REMOVE_PRODUCTS} - confirmed`,
  PDP_INQUIRY_SUCCESS: `${LAUNCH_CALLER.PDP_INQUIRY} - success`,
  USERS_IMPORT_INFO_DISMISSED: `${LAUNCH_CALLER.USERS_IMPORT_INFO} - dismissed`,
  USER_DETAILS_DELETE_CONFIRMATION_DISMISSED: `${LAUNCH_CALLER.USER_DETAILS_DELETE_CONFIRMATION} - dismissed`,
  USER_DETAILS_DELETE_CONFIRMATION_CONFIRMED: `${LAUNCH_CALLER.USER_DETAILS_DELETE_CONFIRMATION} - confirmed`,
  USER_DETAILS_DEACTIVATE_CONFIRMATION_DISMISSED: `${LAUNCH_CALLER.USER_DETAILS_DEACTIVATE_CONFIRMATION} - dismissed`,
  USER_DETAILS_DEACTIVATE_CONFIRMATION_CONFIRMED: `${LAUNCH_CALLER.USER_DETAILS_DEACTIVATE_CONFIRMATION} - confirmed`,
  USER_DETAILS_EDIT_USER_INFORMATION_CONFIRMED: `${LAUNCH_CALLER.USER_DETAILS_EDIT_USER_INFORMATION} - confirmed`,
  USER_DETAILS_EDIT_USER_INFORMATION_DISMISSED: `${LAUNCH_CALLER.USER_DETAILS_EDIT_USER_INFORMATION} - dismissed`,
  USER_DETAILS_REJECT_USER_CONFIRMATION_CONFIRMED: `${LAUNCH_CALLER.USER_DETAILS_REJECT_CONFIRMATION} - confirmed`,
  USER_DETAILS_REJECT_USER_CONFIRMATION_DISMISSED: `${LAUNCH_CALLER.USER_DETAILS_REJECT_CONFIRMATION} - dismissed`,
  PDP_LIST_VARIANTS_DISMISSED: `${LAUNCH_CALLER.PDP_LIST_VARIANTS} - dismissed`,
  PDP_LIST_VARIANTS_NAVIGATION_CHANGED: `${LAUNCH_CALLER.PDP_LIST_VARIANTS} - navigation changed`,
  REMOVE_ACCOUNT_DISMISSED: `${LAUNCH_CALLER.REMOVE_ACCOUNT} - dismissed`,
  PRICE_FILE_REQUEST_TEMPLATE_SAVER_SAVED: `${LAUNCH_CALLER.PRICE_FILE_REQUEST_TEMPLATE_SAVER} - saved`,
  ORDER_CARD_DELETE_SUCCESS: `${LAUNCH_CALLER.ORDER_CARD_DELETE} - success`,
  ORDER_CARD_UPDATE_STATUS_CONFIRMED: `${LAUNCH_CALLER.ORDER_CARD_UPDATE_STATUS} - confirmed`,
  ORDER_CARD_DELETE_USER_CONFIRMED: `${LAUNCH_CALLER.ORDER_CARD_DELETE_USER} - confirmed`,
  ORDER_CARD_DELETE_USERS_CONFIRMED: `${LAUNCH_CALLER.ORDER_CARD_DELETE_USERS} - confirmed`,
  ORDER_CARD_DELETE_PRODUCTS_CONFIRMED: `${LAUNCH_CALLER.ORDER_CARD_DELETE_PRODUCTS} - confirmed`,
  ORDER_CARD_ORDER_CARD_SKU_PICKER_DISMISSED: `${LAUNCH_CALLER.ORDER_CARD_SKU_PICKER} - dismissed`,
  PALLET_FLAG_FORM_SAVED: `${LAUNCH_CALLER.PALLET_FLAG_FORM} - saved`,
  CUTTING_REMOVED: `${LAUNCH_CALLER.CUTTING} - removed`,
  CUTTING_SAVED: `${LAUNCH_CALLER.CUTTING} - saved`,
  REEL_CUTTING_REMOVED: `${LAUNCH_CALLER.REEL_CUTTING} - removed`,
  REEL_CUTTING_SAVED: `${LAUNCH_CALLER.REEL_CUTTING} - saved`,
  PRE_PRINTED_SAVED: `${LAUNCH_CALLER.PRE_PRINTED} - saved`,
  PRE_PRINTED_REMOVED: `${LAUNCH_CALLER.PRE_PRINTED} - remove`,
  ACCOUNTING_CODE_DELETE_CONFIRMATION_DISMISSED: `${LAUNCH_CALLER.ACCOUNTING_CODE_DELETE_CONFIRMATION} - dismissed`,
  ACCOUNTING_CODE_DELETE_CONFIRMATION_CONFIRMED: `${LAUNCH_CALLER.ACCOUNTING_CODE_DELETE_CONFIRMATION} - confirmed`,
  NAME_MARKING_SAVED: `${LAUNCH_CALLER.NAME_MARKING} - saved`,
  PUBLISH_PURCHASE_LIMIT_SUCCESS: `${LAUNCH_CALLER.PUBLISH_PURCHASE_LIMIT} - success`,
  UNPUBLISH_PURCHASE_LIMIT_SUCCESS: `${LAUNCH_CALLER.UNPUBLISH_PURCHASE_LIMIT} - success`,
  DELETE_PURCHASE_LIMIT_SUCCESS: `${LAUNCH_CALLER.DELETE_PURCHASE_LIMIT} - success`,
  PERMISSION_TYPE_CREATED: `${LAUNCH_CALLER.PERMISSION_TYPE} - create`,
  NO_MORE_CONNECTIONS_WARNING_CONFIRMED: `${LAUNCH_CALLER.NO_MORE_CONNECTIONS_WARNING} - confirmed`,
  ORDER_APPROVAL_ABORT_HIJACK_CONFIRMED: `${LAUNCH_CALLER.ORDER_APPROVAL_ABORT_HIJACK} - confirmed`,
  ORDER_APPROVAL_ABORT_HIJACK_DISMISSED: `${LAUNCH_CALLER.ORDER_APPROVAL_ABORT_HIJACK} - dismissed`,
  ORDER_APPROVAL_COMMENT_CONFIRMED: `${LAUNCH_CALLER.ORDER_APPROVAL_COMMENT} - confirmed`,
  ORDER_APPROVAL_COMMENT_DISMISSED: `${LAUNCH_CALLER.ORDER_APPROVAL_COMMENT} - dismissed`,
  ORDER_APPROVAL_DELETE_CONFIRMATION_DISMISSED: `${LAUNCH_CALLER.ORDER_APPROVAL_DELETE_CONFIRMATION} - dismissed`,
  ORDER_APPROVAL_DELETE_CONFIRMATION_CONFIRMED: `${LAUNCH_CALLER.ORDER_APPROVAL_DELETE_CONFIRMATION} - confirmed`,
  MY_ASSORTMENT_NEW_FREE_ARTICLE_CONFIRMED: `${LAUNCH_CALLER.MY_ASSORTMENT_NEW_FREE_ARTICLE} - confirmed`,
  EXCLUDE_ARTICLES_CONFIRMED: `${LAUNCH_CALLER.EXCLUDE_ARTICLES} - confirmed`,
  FIND_MY_COMPANY_INFORMATION_DISMISSED: `${LAUNCH_CALLER.FIND_MY_COMPANY_INFORMATION} - dismissed`,
  CONFIRM_EMAIL_CONTINUE: `${LAUNCH_CALLER.CONFIRM_EMAIL} - continue`,
  CONFIRM_EMAIL_CHANGE_EMAIL: `${LAUNCH_CALLER.CONFIRM_EMAIL} - change email`,
  CONFIRM_EMAIL_FORGOT_PASSWORD: `${LAUNCH_CALLER.CONFIRM_EMAIL} - forgot password`,
  CONFIRM_EMAIL_GO_TO_LOGIN: `${LAUNCH_CALLER.CONFIRM_EMAIL} - go to login`,
  CANCEL_ORDER_RETURN_DRAFT_CONFIRMED: `${LAUNCH_CALLER.CANCEL_ORDER_RETURN_DRAFT} - confirmed`,
  CANCEL_ORDER_RETURN_DRAFT_DISMISSED: `${LAUNCH_CALLER.CANCEL_ORDER_RETURN_DRAFT} - dismissed`,
  LEGAL_CLOSED: `${LAUNCH_CALLER.LEGAL} - closed`,
  LEGAL_CLOSED_AUTOMATICALLY_ON_LEGAL_PAGE: `${LAUNCH_CALLER.LEGAL} - closed automatically on legal page`,
  ORDER_RETURN_UPLOADED_FILES_GALLERY_CLOSED_ON_LAST_FILE_REMOVED: `${LAUNCH_CALLER.ORDER_RETURN_UPLOADED_FILES_GALLERY} - closed on last file removed `,
  CHECKOUT_PAYMENT_DISMISSED: `${LAUNCH_CALLER.CHECKOUT_PAYMENT} - dismissed`,
  ACTIVE_ORDER_PROCESS_PROCEEDED: `${LAUNCH_CALLER.ACTIVE_ORDER_PROCESS} - proceeded`,
  ACTIVE_ORDER_PROCESS_CANCELLED: `${LAUNCH_CALLER.ACTIVE_ORDER_PROCESS} - cancelled`,
  ACTIVE_ORDER_PROCESS_DISMISSED_AUTOMATICALLY: `${LAUNCH_CALLER.ACTIVE_ORDER_PROCESS} - dismissed automatically`,
  ORDER_MERGE_PROPOSALS_CONFIRMED: `${LAUNCH_CALLER.ORDER_MERGE_PROPOSALS} - confirmed`,
  ORDER_MERGE_PROPOSALS_REJECTED: `${LAUNCH_CALLER.ORDER_MERGE_PROPOSALS} - rejected`,
};
