import { Injectable } from '@angular/core';
import { ErrorModel, facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import {
  CompanySignUp,
  CompanySignUpPage,
  CompanySignUpRequest,
  LoaderError,
  RegistrationNumberInputCheckResult,
} from '../../model';
import { USER_BASE_FEATURE } from '../store/user-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: CompanyFacade,
      feature: USER_BASE_FEATURE,
      async: true,
      methods: [
        'searchCompany',
        'getSearchCompanyResult',
        'getSearchCompanyResultLoading',
        'getSearchCompanyResultError',
        'getSearchCompanyResultErrorDetails',
        'getSearchCompanyResultSuccess',
        'resetSearchCompanyProcessingState',
        'loadCompany',
        'getLoadCompanyResult',
        'getLoadCompanyLoading',
        'getLoadCompanyError',
        'getLoadCompanySuccess',
        'validateRegistrationNumber',
      ],
    }),
})
export abstract class CompanyFacade {
  abstract searchCompany(props: CompanySignUpRequest): void;
  abstract getSearchCompanyResult(): Observable<CompanySignUpPage | undefined>;
  abstract getSearchCompanyResultLoading(): Observable<boolean>;
  abstract getSearchCompanyResultError(): Observable<LoaderError | undefined>;
  abstract getSearchCompanyResultErrorDetails(): Observable<ErrorModel[] | undefined>;
  abstract getSearchCompanyResultSuccess(): Observable<boolean>;
  abstract resetSearchCompanyProcessingState(): void;
  abstract loadCompany(id: string, companySearchServiceType?: string): void;
  abstract getLoadCompanyResult(): Observable<CompanySignUp | undefined>;
  abstract getLoadCompanyLoading(): Observable<boolean>;
  abstract getLoadCompanyError(): Observable<LoaderError | undefined>;
  abstract getLoadCompanySuccess(): Observable<boolean>;
  abstract validateRegistrationNumber(registrationNumber: string): Observable<RegistrationNumberInputCheckResult>;
}
