import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../core/i18n';
import { PipesModule } from '../../pipes';
import { ArticleInfoAttributesModule } from '../article/article-info-attributes/article-info-attributes.module';
import { ArticleImageModule } from '../article/image';
import { ArticleInfoModule } from '../article/info';
import { CarouselModule } from '../carousel';
import { CatalogArticlePriceModule } from '../catalog-article/catalog-article-price';
import { DetailedStockInfoModule } from '../detailed-stock-info';
import { QuantityAndUnitSelectorModule } from '../quantity-and-unit-selector';
import { SpinnerModule } from '../spinner';
import { ArticleCarouselItemCustomizableComponent } from './article-carousel-item-customizable/article-carousel-item-customizable.component';
import { ArticleCarouselComponent } from './article-carousel.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    CarouselModule,
    ArticleInfoModule,
    ArticleImageModule,
    NzIconModule,
    NgbTooltipModule,
    ArticleInfoAttributesModule,
    PipesModule,
    QuantityAndUnitSelectorModule,
    CatalogArticlePriceModule,
    SpinnerModule,
    DetailedStockInfoModule,
  ],
  declarations: [ArticleCarouselComponent, ArticleCarouselItemCustomizableComponent],
  exports: [ArticleCarouselComponent],
})
export class ArticleCarouselModule {}
