import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StateModule } from '@spartacus/core';
import { effects } from './effects';
import { PRICE_FILE_FEATURE } from './price-file-state';
import { metaReducers, reducerProvider, reducerToken } from './reducers';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StateModule,
    StoreModule.forFeature(PRICE_FILE_FEATURE, reducerToken, { metaReducers }),
    EffectsModule.forFeature(effects),
  ],
  providers: [reducerProvider],
})
export class PriceFileStoreModule {}
