import { MemoizedSelector, MemoizedSelectorWithProps, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { EntriesState } from '../../../features/store/base-feature-state';
import { Country } from '../../../model';
import { PyStateUtils } from '../../../state';
import { StateWithUser, UserState } from '../user-state';
import { getUserState } from './feature.selector';

export const getProfileCountriesLoaderState: MemoizedSelector<
  StateWithUser,
  StateUtils.LoaderState<EntriesState<Country>>
> = createSelector(getUserState, (state: UserState) => (state && state.profileCountries) || PyStateUtils.initialLoaderState);

export const getProfileCountriesEntries: MemoizedSelector<StateWithUser, EntriesState<Country> | undefined> = createSelector(
  getProfileCountriesLoaderState,
  (state: StateUtils.LoaderState<EntriesState<Country>>) =>
    (state &&
      !StateUtils.loaderLoadingSelector(state) &&
      StateUtils.loaderSuccessSelector(state) &&
      StateUtils.loaderValueSelector(state)) ||
    undefined
);

export const getProfileCountries: MemoizedSelector<StateWithUser, Country[] | undefined> = createSelector(
  getProfileCountriesEntries,
  (state: EntriesState<Country>) => (state && Object.keys(state).map((k) => state[k])) || undefined
);

export const getProfileCountry: MemoizedSelectorWithProps<StateWithUser, any, Country | undefined> = createSelector(
  getProfileCountriesEntries,
  (state: EntriesState<Country>, props) => (state && state[props.isocode]) || undefined
);
