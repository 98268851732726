import { StateUtils } from '@spartacus/core';
import { SearchPageResultState } from '../../../features/store/base-feature-state';
import { DeliveryAddressSearchResult } from '../../../model';
import { UserActions } from '../actions';
import { DeliveryAddressActionTypes } from '../actions/delivery-address.action';

export const initialState: SearchPageResultState = {
  lastUpdateTime: undefined,
  results: [],
};

export function reducer(state = initialState, action: UserActions.DeliveryAddressesActions | StateUtils.LoaderAction): any {
  switch (action.type) {
    case DeliveryAddressActionTypes.LoadDeliveryAddressesSuccess: {
      const payload: DeliveryAddressSearchResult = action.payload;

      const results = payload.results.map((file) => file.id);
      return {
        ...state,
        lastUpdateTime: new Date().toISOString(),
        results,
      };
    }
    default: {
      return state;
    }
  }
}
