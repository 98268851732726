import { ChangeDetectionStrategy, Component, HostBinding, Input, TemplateRef } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'div[py-megamenu]',
  templateUrl: './megamenu.component.html',
  styleUrls: ['./megamenu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MegamenuComponent {
  @Input() templateColumnLeft: TemplateRef<any>;
  @Input() templateColumnRight: TemplateRef<any>;
  @Input() columnsAreEqualWidth = false;

  @HostBinding('class.show-border')
  @Input()
  showBorder = true;
}
