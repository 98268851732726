import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { IconThemeReverseModule } from '../../../directives';
import { DrawerHeaderComponent } from './drawer-header.component';

@NgModule({
  imports: [CommonModule, NzIconModule, IconThemeReverseModule],
  declarations: [DrawerHeaderComponent],
  exports: [DrawerHeaderComponent],
})
export class DrawerHeaderModule {}
