import { MediaConfig } from './media.config';
import { ImageLoadingStrategy } from './media.model';

export const defaultMediaConfig: MediaConfig = {
  mediaFormats: {
    mobile: {
      width: 400,
    },
    tablet: {
      width: 770,
    },
    desktop: {
      width: 1140,
    },
    widescreen: {
      width: 1400,
    },
  },
  imageLoadingStrategy: ImageLoadingStrategy.LAZY,
};
