import { PagedSearchAction } from '../../../features/store/actions/search.actions';
import { PaginationState, SearchPageResultState } from '../../../features/store/base-feature-state';
import { OrderCardPage } from '../../../model';
import { loaderReducer } from '../../../state/utils/loader';
import { UserActions } from '../actions';
import { OrderCardsAdminActionTypes } from '../actions/order-cards-admin.action';
import { ORDER_CARDS_ADMIN } from '../user-state';
import { reducer as orderCardAdminEntryReducer } from './order-card-admin-entries.reducer';

export const initialSearchState: PaginationState<SearchPageResultState> = {
  lastUpdateTime: undefined,
  pages: {},
  pagination: undefined,
  sorts: [],
};
export function reducer(state: PaginationState<SearchPageResultState> = initialSearchState, action: PagedSearchAction) {
  switch (action.type) {
    case OrderCardsAdminActionTypes.SearchOrderCardsAdmin: {
      const _action = <UserActions.SearchOrderCardsAdmin>action;
      const searchParams = _action.payload.searchParams;
      const loaderState = loaderReducer<SearchPageResultState>(ORDER_CARDS_ADMIN, orderCardAdminEntryReducer)(
        { ...state.pages[searchParams.page] },
        _action
      );

      return {
        ...state,
        pages: {
          ...state.pages,
          [searchParams.page]: loaderState,
        },
      };
    }
    case OrderCardsAdminActionTypes.SearchOrderCardsAdminFail: {
      const _action = <UserActions.SearchOrderCardsAdmin>action;
      const searchParams = _action.payload.searchParams;
      const loaderState = loaderReducer<SearchPageResultState>(ORDER_CARDS_ADMIN, orderCardAdminEntryReducer)(undefined, _action);

      return {
        ...state,
        pages: {
          ...state.pages,
          [searchParams.page]: loaderState,
        },
      };
    }
    case OrderCardsAdminActionTypes.SearchOrderCardsAdminSuccess: {
      const _action = <UserActions.SearchOrderCardsAdminSuccess>action;
      const payload = _action.payload;
      const searchResult: OrderCardPage = payload.searchResult;
      const loaderState = loaderReducer<SearchPageResultState>(ORDER_CARDS_ADMIN, orderCardAdminEntryReducer)(
        { ...state.pages[searchResult.pagination.page] },
        _action
      );

      const pages = { ...state.pages };
      if (payload.deleteInvalidPages) {
        Object.keys(pages).forEach((_page, key) => {
          if (key > payload.searchParams.page) {
            delete pages[key];
          }
        });
      }

      return {
        ...state,
        lastUpdateTime: new Date().toISOString(),
        pagination: searchResult.pagination,
        sorts: searchResult.sorts,
        pages: {
          ...pages,
          [searchResult.pagination.page]: loaderState,
        },
      };
    }
  }
  return state;
}
