import { Action } from '@ngrx/store';

export enum ResetPasswordActionTypes {
  ResetPassword = '[User] Reset password',
  ResetPasswordSuccess = '[User] Reset password success',
  ResetPasswordFail = '[User] Reset password fail',
  ResetPasswordReset = '[User] Reset password reset',
}

export class ResetPassword implements Action {
  readonly type = ResetPasswordActionTypes.ResetPassword;
  constructor(public payload: { token: string; password: string }) {}
}

export class ResetPasswordFail implements Action {
  readonly type = ResetPasswordActionTypes.ResetPasswordFail;
  constructor(public payload: any) {}
}

export class ResetPasswordSuccess implements Action {
  readonly type = ResetPasswordActionTypes.ResetPasswordSuccess;
}

export class ResetPasswordReset implements Action {
  readonly type = ResetPasswordActionTypes.ResetPasswordReset;
}
export type ResetPasswordAction = ResetPassword | ResetPasswordFail | ResetPasswordSuccess | ResetPasswordReset;
