import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { KeyboardFocusModule } from '@spartacus/storefront';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../../core/i18n';
import { LaunchDialogService } from '../../../../core/modal';
import { IconThemeReverseModule, ScrollStateModule } from '../../../directives';
import { PipesModule } from '../../../pipes';
import { ActionButtonModule } from '../../action-button';
import { ModalModule } from '../../modal';
import { NotificationModule } from '../../notification';
import { RadioModule } from '../../radio/radio.module';
import { ReactiveFormInputFieldModule } from '../../reactive-forms-input/reactive-form-input-field/reactive-form-input-field.module';
import { SpinnerModule } from '../../spinner';
import { prePrintedModalLayoutConfig } from './pre-printed-modal-layout.config';
import { PrePrintedModalComponent } from './pre-printed-modal.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    NzIconModule,
    ReactiveFormInputFieldModule,
    ReactiveFormsModule,
    PipesModule,
    NotificationModule,
    IconThemeReverseModule,
    RadioModule,
    ScrollStateModule,
    SpinnerModule,
    ActionButtonModule,
    ModalModule,
    KeyboardFocusModule,
  ],
  declarations: [PrePrintedModalComponent],
  exports: [PrePrintedModalComponent],
})
export class PrePrintedModalModule {
  constructor(private launchDialogService: LaunchDialogService) {
    this.launchDialogService.registerModalLayoutConfig(prePrintedModalLayoutConfig);
  }
}
