import { LOCALE_ID, ModuleWithProviders, NgModule } from '@angular/core';
import {
  CONFIG_INITIALIZER,
  ConfigInitializer,
  CxDatePipe,
  TranslationService as CxTranslationService,
  I18nConfig,
  I18nConfigInitializer,
} from '@spartacus/core';
import { provideDefaultConfig } from '../config/config-providers';
import { LanguageService } from '../site-context';
import { defaultI18nConfig } from './config/default-i18n-config';
import { DynamicLocaleId } from './dynamic-locale-id';
import { i18nextProviders } from './i18next/i18next-providers';
import { I18nextTranslationService } from './i18next/i18next-translation.service';
import { PyDatePipe } from './pipe';
import { TranslationService as PyTranslationService } from './services/translation.service';

export function initI18nConfig(configInitializer: I18nConfigInitializer, config: I18nConfig): ConfigInitializer | null {
  /**
   * If `fallbackLang` was already configured statically
   */
  if (config?.i18n?.fallbackLang !== undefined) {
    return null;
  }
  return configInitializer;
}

@NgModule({})
export class I18nRootModule {
  static forRoot(): ModuleWithProviders<I18nRootModule> {
    return {
      ngModule: I18nRootModule,
      providers: [
        provideDefaultConfig(defaultI18nConfig),
        { provide: PyTranslationService, useExisting: I18nextTranslationService },
        { provide: CxTranslationService, useExisting: PyTranslationService },
        { provide: CxDatePipe, useExisting: PyDatePipe },
        ...i18nextProviders,
        {
          provide: CONFIG_INITIALIZER,
          useFactory: initI18nConfig,
          deps: [I18nConfigInitializer, I18nConfig],
          multi: true,
        },
        {
          provide: LOCALE_ID,
          useClass: DynamicLocaleId,
          deps: [LanguageService],
        },
      ],
    };
  }
}
