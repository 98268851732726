import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class RestoreScrollPositionResolver {
  constructor(private router: Router) {}

  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
    const currentNavigation = this.router.getCurrentNavigation();
    return currentNavigation?.previousNavigation && currentNavigation?.trigger === 'popstate';
  }
}
