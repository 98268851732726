import { StateUtils } from '@spartacus/core';
import { ContentTag } from '../../../../core/model';
import { ContentTagActions } from '../actions';
import { ContentTagActionTypes } from '../actions/content-tag.action';

export const initialState: ContentTag = undefined;

export function reducer(state = initialState, action: ContentTagActions.ContentTagActions | StateUtils.LoaderAction): ContentTag {
  switch (action.type) {
    case ContentTagActionTypes.LoadContentTagFail: {
      return initialState;
    }
    case ContentTagActionTypes.LoadContentTagSuccess: {
      return action.payload ? action.payload : initialState;
    }
  }

  return state;
}
