import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InterceptorUtil, OccEndpointsService, USE_CUSTOMER_SUPPORT_AGENT_TOKEN } from '@spartacus/core';
import { Observable } from 'rxjs';
import { AsmImpersonationAdapter } from '../../../asm-impersonation/connectors/asm-impersonation.adapter';
import {
  CustomerImpersonationSearchParams,
  ImpersonationCustomerPage,
} from '../../../asm-impersonation/models/asm-impersonation.models';

@Injectable()
export class OccAsmImpersonationAdapter implements AsmImpersonationAdapter {
  constructor(protected http: HttpClient, protected occEndpointsService: OccEndpointsService) {}

  customerImpersonationSearch(options: CustomerImpersonationSearchParams): Observable<ImpersonationCustomerPage> {
    const headers = InterceptorUtil.createHeader(USE_CUSTOMER_SUPPORT_AGENT_TOKEN, true, new HttpHeaders());

    const url = this.occEndpointsService.buildUrl('asmCustomerImpersonationSearch', {
      queryParams: {
        query: options.query,
        mode: options.mode,
        pageSize: options.pageSize,
        currentPage: options.page,
      },
    });

    return this.http.get<ImpersonationCustomerPage>(url, { headers });
  }
}
