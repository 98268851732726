import { InjectionToken } from '@angular/core';
import {
  ArticlePrice,
  ArticleStockInfoList,
  B2BComment,
  OrderApproval,
  OrderApprovalDecision,
  OrderApprovalSearchResult,
  OrderCard,
  OrderCardConfiguration,
  OrderCardPage,
  PrincipalConfiguration,
  PrincipalSharedShoppingListFlagsPage,
  PunchOutData,
  ShoppingList,
  ShoppingListPermission,
  StockInfoDetails,
} from '../../model';
import { Feed } from '../../model/feed.model';
import { Occ } from '../../occ/occ-models';
import { Converter } from '../../util/converter.service';

export const FEED_NORMALIZER = new InjectionToken<Converter<Occ.Feed, Feed>>('FeedNormalizer');

export const PRINCIPAL_CONFIGURATION_NORMALIZER = new InjectionToken<
  Converter<Occ.PrincipalConfiguration, PrincipalConfiguration>
>('PrincipalConfigurationNormalizer');

export const PUNCH_OUT_DATA_NORMALIZER = new InjectionToken<Converter<any, PunchOutData>>('PunchOutDataNormalizer');

export const SHOPPING_LIST_NORMALIZER = new InjectionToken<Converter<Occ.CartTemplate, ShoppingList>>('ShoppingListNormalizer');

export const ARTICLE_PRICE_NORMALIZER = new InjectionToken<Converter<Occ.PriceResponse, ArticlePrice>>('ArticlePriceNormalizer');

export const PRINCIPALS_NORMALIZER = new InjectionToken<
  Converter<Occ.PrincipalSharedCartTemplateFlagsPage, PrincipalSharedShoppingListFlagsPage>
>('PrincipalsNormalizer');

export const STOCK_INFO_DETAIL_NORMALIZER = new InjectionToken<Converter<any, StockInfoDetails>>('StockInfoNormalizer');

export const STOCK_INFO_NORMALIZER = new InjectionToken<Converter<Occ.ArticleStockInfoList, ArticleStockInfoList>>(
  'StockInfoNormalizer'
);

export const SHOPPING_LIST_PERMISSIONS_SERIALIZER = new InjectionToken<
  Converter<ShoppingListPermission[], Occ.PrincipalSharedCartTemplateFlags[]>
>('ShoppingListPermissionsSerializer');

export const ORDER_APPROVAL_DECISION_NORMALIZER = new InjectionToken<Converter<any, OrderApprovalDecision>>(
  'OrderApprovalDecisionNormalizer'
);

export const ORDER_APPROVALS_NORMALIZER = new InjectionToken<Converter<any, OrderApprovalSearchResult>>(
  'OrderApprovalsNormalizer'
);

export const B2B_COMMENTS_NORMALIZER = new InjectionToken<Converter<any, Array<B2BComment>>>('B2BCommentsNormalizer');

export const ORDER_CARD_NORMALIZER = new InjectionToken<Converter<Occ.OrderCard, OrderCard>>('OrderCardNormalizer');
export const ORDER_CARDS_NORMALIZER = new InjectionToken<Converter<Occ.OrderCardPage, OrderCardPage>>('OrderCardsNormalizer');
export const ORDER_CARD_CONFIGURATION_NORMALIZER = new InjectionToken<
  Converter<Occ.OrderCardConfiguration, OrderCardConfiguration>
>('OrderCardConfigurationNormalizer');

export const ORDER_APPROVAL_DETAILS_NORMALIZER = new InjectionToken<Converter<Occ.OrderApproval, OrderApproval>>(
  'OrderApprovalDetailsNormalizer'
);
