<ng-container
  *ngIf="{
    comments: comments$ | async,
    loadingComments: loadingComments$ | async
  } as data"
>
  <ng-container *ngIf="data.loadingComments; else loaded">
    <div class="d-flex flex-column align-items-center">
      <cx-spinner class="mt-3 h1"></cx-spinner>
      <h5>{{ 'orderApproval.loadingSpinner_hint' | cxTranslate }}</h5>
    </div>
  </ng-container>
  <ng-template #loaded>
    <ng-container *ngIf="getFilteredComments(data?.comments) as filteredComments">
      <ng-container *ngIf="filteredComments.length > 0; else noAvailableComments">
        <div class="chat-messages">
          <ng-container *ngFor="let comment of filteredComments">
            <ng-container *ngIf="comment.fromCustomer; else approverMessage">
              <div class="message-box-container justify-content-end">
                <div class="d-flex flex-column pr-2">
                  <div class="message-author align-self-end">
                    <span class="author-name">{{ comment.author?.name }}</span>
                    <span class="message-date pl-2">{{ comment.creationDate | cxDate : 'dateTime' }}</span>
                    <div class="author-email d-flex justify-content-end">{{ comment?.authorEmail }}</div>
                  </div>
                  <div class="message-box customer align-self-end mt-1 mr-2">{{ comment.text }}</div>
                </div>
                <div class="d-flex pt-2">
                  <py-user-icon [b2bUser]="comment.author"></py-user-icon>
                </div>
              </div>
            </ng-container>
            <ng-template #approverMessage>
              <div class="message-box-container justify-content-start">
                <div class="d-flex pt-2">
                  <py-user-icon [b2bUser]="comment.author"></py-user-icon>
                </div>
                <div class="d-flex flex-column pl-2">
                  <div class="message-author align-self-start">
                    <span class="author-name">{{ comment.author?.name }}</span>
                    <span class="message-date pl-2">{{ comment.creationDate | cxDate : 'dateTime' }}</span>
                    <div class="author-email d-flex justify-content-start">{{ comment?.authorEmail }}</div>
                  </div>
                  <div class="message-box approver align-self-start mt-1 ml-2">{{ comment.text }}</div>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </div>

        <hr class="w-100" />
      </ng-container>
      <ng-template #noAvailableComments>
        <py-notification
          class="my-3"
          [type]="'info'"
          [message]="'orderApproval.noAvailableMessages_message' | cxTranslate"
        ></py-notification>
      </ng-template>
    </ng-container>
  </ng-template>
</ng-container>
