<ng-container *ngIf="searchBoxVisible$ | async">
  <py-search class="px-3 tablet-view" [hidden]="!(tabletVisible$ | async)"></py-search>

  <py-search class="search container pl-9 pr-3" [hidden]="!(desktopVisible$ | async)"></py-search>
</ng-container>

<ng-container *ngIf="{ activeLinkUid: activeLinkUid$ | async } as data">
  <ul>
    <ng-container *ngFor="let link of links$ | async">
      <ng-container [ngSwitch]="link.uid">
        <li *ngSwitchCase="'header_products_link'">
          <py-menu-products
            [title]="link.title"
            [show]="data.activeLinkUid === link.uid"
            (showChange)="onSelectLink(link.uid, $event)"
          ></py-menu-products>
        </li>

        <li *ngSwitchCase="'header_services_link'">
          <py-menu-services
            [show]="data.activeLinkUid === link.uid"
            [isDirectServicesLink]="link.styleClasses?.includes(directServicesLinkStyleClass)"
            (showChange)="onSelectLink(link.uid, $event)"
          ></py-menu-services>
        </li>

        <ng-container *ngSwitchCase="'header_area_of_use_link'">
          <li *pyPrincipalConfiguration="'enableDisplayAreaOfUse'">
            <py-menu-area-of-use-dropdown
              class="area-of-use"
              [show]="data.activeLinkUid === link.uid"
              (showChange)="onSelectLink(link.uid, $event)"
            ></py-menu-area-of-use-dropdown>
          </li>
        </ng-container>

        <li *ngSwitchDefault>
          <py-header-navigation-item
            [routerLink]="link.url"
            routerLinkActive
            #rla="routerLinkActive"
            [active]="rla.isActive && !(hasActiveLinkUid$ | async)"
            [url]="link.url"
            [target]="link.target"
          >
            {{ link.title }}
          </py-header-navigation-item>
        </li>
      </ng-container>
    </ng-container>
  </ul>
  <div class="separator mx-2 mx-lg-6 mx-xl-8"></div>
</ng-container>
