<div class="mobile-drawerselect">
  <button
    class="pt-4 pb-4 mobile-drawerselect-button"
    (click)="isCollapsed = !isCollapsed"
    [attr.aria-expanded]="!isCollapsed"
    aria-controls="collapse-mobile-drawerselect"
  >
    {{ title }} <ng-container *ngIf="options && showOptionsCount">({{ options.length }})</ng-container>
    <i nz-icon [nzType]="isCollapsed ? 'down' : 'up'" nzTheme="outline"></i>
  </button>
  <div class="pt-2 pb-4 mobile-drawerselect-container" id="collapse-mobile-drawerselect" [ngbCollapse]="isCollapsed">
    <div *ngIf="enableSearch" class="pl-2 pr-2 pb-2">
      <form [formGroup]="searchFormGroup">
        <py-reactive-form-input-field
          class="w-100 d-block"
          [group]="searchFormGroup"
          [fieldName]="'text'"
          [placeholder]="customSearchPlaceholder ? customSearchPlaceholder : ('common.search_placeholder' | cxTranslate)"
          [icon]="'search'"
        ></py-reactive-form-input-field>
      </form>
    </div>
    <div
      *ngFor="let option of filteredOptions"
      class="mobile-drawerselect-item p-2"
      [class.orders-list]="templateType !== drawerselectTemplateTypes.ORDERS_LIST"
    >
      <py-checkbox class="mr-2" *ngIf="multi">
        <input type="checkbox" [checked]="isActive(option.value)" (change)="updateValue(option.value)" />
      </py-checkbox>

      <div class="d-flex">
        <py-radio class="mr-4" *ngIf="!multi">
          <input type="radio" [checked]="isActive(option.value)" (change)="updateSingleValue(option.value)" />
        </py-radio>
      </div>

      <div *ngIf="templateType === drawerselectTemplateTypes.DEFAULT; else templateCustomDrawerType">
        <p>{{ option.label }}</p>
        <div *ngFor="let e of [].constructor(additionalLabelsAmount); let i = index">
          <p *ngIf="option.additionalLabels" class="label">{{ option.additionalLabels[i] }}</p>
        </div>
      </div>

      <ng-template #templateCustomDrawerType>
        <div class="order-approval-buyer-template d-flex align-items-center">
          <div class="pr-2">
            <p>
              {{ option.label === 'ALL' ? ('common.all_hint' | cxTranslate) : option.label }}
            </p>
            <ng-container *ngFor="let e of [].constructor(additionalLabelsAmount); let i = index">
              <p *ngIf="option.additionalLabels" class="label">{{ option.additionalLabels[i] }}</p>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="py-2 text-center" *ngIf="loading">
      <cx-spinner></cx-spinner>
    </div>
    <div *ngIf="enableSearch && !loading && filteredOptions?.length === 0" class="p-2 text-center">
      {{ 'common.noResultsFound_hint' | cxTranslate }}
    </div>
    <button *ngIf="enableShowMore && !loading" (click)="onShowMore()" class="py-color-secondary mt-2">
      <span>{{ 'common.showMore_action' | cxTranslate }}</span>
    </button>
  </div>
</div>
