import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderEntry } from '../../../../core/model';
import { UpdateQuantityEvent } from '../order-entry.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tbody[py-sample-order-entry-row]',
  templateUrl: './sample-order-entry-row.component.html',
  styleUrls: ['./sample-order-entry-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SampleOrderEntryRowComponent implements OnInit, OnDestroy {
  @Input() entry: OrderEntry;
  @Input() sampleProductsLimit: number;
  @Input() sampleProductQuantity: number;
  @Output() updateQuantity = new EventEmitter<UpdateQuantityEvent>();
  @Output() delete = new EventEmitter();
  sampleProductForm: UntypedFormGroup = new UntypedFormGroup({
    quantity: new UntypedFormControl(1),
  });
  subscription = new Subscription();

  constructor() {}

  ngOnInit(): void {
    this.sampleProductForm.controls['quantity'].setValue(this.entry.quantity);
    this.subscription.add(
      this.sampleProductForm
        .get('quantity')
        .valueChanges.pipe(
          map((quantity) => {
            this.updateProductQuantity({ quantity });
          })
        )
        .subscribe()
    );
  }

  updateProductQuantity(values: { quantity: number }): void {
    this.updateQuantity.emit(values);
  }

  onConfirmedDelete(): void {
    this.delete.emit();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
