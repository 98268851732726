import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Article, DiscontinuedArticleViewType, SubstituteRef } from '../../../../core/model';

@Component({
  selector: 'py-discontinued-article-header',
  templateUrl: './discontinued-article-header.component.html',
  styleUrls: ['./discontinued-article-header.component.scss'],
})
export class DiscontinuedArticleHeaderComponent {
  discontinuedArticleViewTypes: typeof DiscontinuedArticleViewType = DiscontinuedArticleViewType;

  @Input() discontinuedArticleViewType: DiscontinuedArticleViewType;
  @Input() discontinuedArticleSubstituteRefs: SubstituteRef[];
  @Input() discontinuedArticle: Article;
  @Input() enableTogglingOfCustomerAssortment: boolean;
  @Input() form: UntypedFormGroup;

  @Output() submitEvent = new EventEmitter<void>();

  onDiscontinuedArticleSubmit(): void {
    this.submitEvent.emit();
  }
}
