import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../../core/i18n';
import { ImageModalModule } from '../../image-modal/image-modal.module';
import { MediaModule } from '../../media';
import { ArticleImageComponent } from './image.component';

@NgModule({
  imports: [CommonModule, NzIconModule, MediaModule, ImageModalModule, I18nModule, NgbTooltipModule],
  declarations: [ArticleImageComponent],
  exports: [ArticleImageComponent],
})
export class ArticleImageModule {}
