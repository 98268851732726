import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { RECAPTCHA_BASE_URL, RECAPTCHA_LANGUAGE, RECAPTCHA_NONCE, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { Observable, ReplaySubject, Subject, of } from 'rxjs';

@Injectable()
export class DeferredRecaptchaService {
  public ready: Observable<ReCaptchaV2.ReCaptcha>;
  private loaded = false;
  private readonly subject: ReplaySubject<ReCaptchaV2.ReCaptcha>;

  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private readonly platformId: string,
    private windowRef: WindowRef,
    @Optional() @Inject(RECAPTCHA_BASE_URL) private readonly baseUrl?: string,
    @Optional() @Inject(RECAPTCHA_NONCE) private readonly nonce?: string,
    @Optional() @Inject(RECAPTCHA_V3_SITE_KEY) private readonly v3SiteKey?: string,
    @Optional() @Inject(RECAPTCHA_LANGUAGE) private readonly language?: string
  ) {
    this.platformId = platformId;
    this.language = language ?? 'en';
    this.baseUrl = baseUrl;
    this.nonce = nonce;
    this.v3SiteKey = v3SiteKey;
    this.subject = new ReplaySubject<ReCaptchaV2.ReCaptcha>();
    this.ready = isPlatformBrowser(this.platformId) ? this.subject.asObservable() : of();
    this.windowRef = windowRef;
  }

  public init() {
    if (isPlatformBrowser(this.platformId) && !this.loaded) {
      const langParam = this.language ? '&hl=' + this.language : '';
      const renderMode = this.v3SiteKey || 'explicit';
      this.load(renderMode, this.subject, langParam);
    }
  }

  private load(renderMode: string, subject: Subject<ReCaptchaV2.ReCaptcha>, langParam: string) {
    const nativeWindow: any = this.windowRef.nativeWindow;
    nativeWindow.ng2recaptchaloaded = () => {
      subject.next(grecaptcha);
    };
    const script = document.createElement('script');
    script.innerHTML = '';
    const baseUrl = this.baseUrl || 'https://www.google.com/recaptcha/api.js';
    script.src = `${baseUrl}?render=${renderMode}&onload=ng2recaptchaloaded${langParam}`;
    if (this.nonce) {
      script.nonce = this.nonce;
    }
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    this.loaded = true;
  }
}
