import { NgModule } from '@angular/core';
import {
  LaunchDialogService as CxLaunchDialogService,
  InlineRootRenderStrategy,
  LaunchDialogModule,
  OutletRefModule,
  ThemeService,
} from '@spartacus/storefront';
import { LaunchDialogService as PyLaunchDialogService } from '../core/modal/services/launch-dialog.service';
import { DirectionModule } from './direction/direction.module';
import { InlineRootRenderStrategy as PyInlineRootRenderStrategy } from './launch-dialog/services';
import { ThemeService as PyThemeService } from './theme';
import { ThemeModule } from './theme/theme.module';

@NgModule({
  imports: [OutletRefModule, LaunchDialogModule.forRoot(), DirectionModule, ThemeModule],
  exports: [OutletRefModule],
  providers: [
    {
      provide: ThemeService,
      useExisting: PyThemeService,
    },
    {
      provide: InlineRootRenderStrategy,
      useExisting: PyInlineRootRenderStrategy,
    },
    {
      provide: CxLaunchDialogService,
      useExisting: PyLaunchDialogService,
    },
  ],
})
export class LayoutModule {}
