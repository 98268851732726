import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Priority } from '@spartacus/core';
import { GlobalMessageService } from '../../../facade';
import { GlobalMessageType } from '../../../models/global-message.model';
import { HttpResponseStatus } from '../../../models/response-status.model';
import { HttpErrorHandler } from '../http-error.handler';

@Injectable({
  providedIn: 'root',
})
export class GoneHandler extends HttpErrorHandler {
  responseStatus = HttpResponseStatus.GONE;

  constructor(protected globalMessageService: GlobalMessageService) {
    super(globalMessageService);
  }

  handleError(_request: HttpRequest<any>, response: HttpErrorResponse) {
    if (response.error?.error_description || response.message) {
      this.globalMessageService.add(
        {
          raw: response.error?.error_description || response.message,
        },
        GlobalMessageType.MSG_TYPE_WARNING
      );
    } else {
      this.globalMessageService.add({ key: 'errors.gone_message' }, GlobalMessageType.MSG_TYPE_WARNING);
    }
  }

  getPriority(): Priority {
    return Priority.LOW;
  }
}
