import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PRICE_CAMPAIGN_DETAILS_NORMALIZER } from './connector/converters';
import { PriceCampaignDetailsNormalizer } from './connector/price-campaign-details.normalizer';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: PRICE_CAMPAIGN_DETAILS_NORMALIZER,
      useClass: PriceCampaignDetailsNormalizer,
      multi: true,
    },
  ],
})
export class PriceCampaignModule {}
