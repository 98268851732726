import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { TranslatePipe } from '../../core/i18n';

@NgModule({
  providers: [
    TranslatePipe,
    provideConfig(<CmsConfig>{
      featureModules: {
        navigation: {
          module: () => import('./cms-libs/navigation/navigation.module').then((m) => m.NavigationModule),
          cmsComponents: ['NavigationComponent'],
        },
        myPageMenu: {
          module: () => import('./cms-libs/my-page-menu/my-page-menu.module').then((m) => m.MyPageMenuModule),
          cmsComponents: ['CmsMyPageMenuComponent'],
        },
        mobileNavigation: {
          module: () =>
            import('./cms-libs/mobile-navigation/mobile-navigation.module').then((m) => m.HeaderMobileNavigationModule),
          cmsComponents: ['CmsMobileNavigationComponent'],
          dependencies: [
            () => import('../cms-content-tag-base/cms-content-tag-base.module').then((m) => m.CmsContentTagBaseModule),
          ],
        },
        menuSiteContext: {
          module: () => import('./cms-libs/menu-site-context/menu-site-context.module').then((m) => m.MenuSiteContextModule),
          cmsComponents: ['MenuSiteContextComponent'],
        },
        menuRegistration: {
          module: () => import('./cms-libs/menu-registration/menu-registration.module').then((m) => m.MenuRegistrationModule),
          cmsComponents: ['MenuRegistrationComponent'],
        },
        menuLogin: {
          module: () => import('./cms-libs/menu-login/menu-login.module').then((m) => m.MenuLoginModule),
          cmsComponents: ['MenuLoginComponent'],
        },
        menuCart: {
          module: () => import('./cms-libs/menu-cart/menu-cart.module').then((m) => m.MenuCartModule),
          cmsComponents: ['MenuCartComponent'],
        },
        menuAccount: {
          module: () => import('./cms-libs/menu-account/menu-account.module').then((m) => m.MenuAccountModule),
          cmsComponents: ['MenuAccountComponent'],
        },
        headerMainNavigation: {
          module: () =>
            import('./cms-libs/header-main-navigation/header-main-navigation.module').then((m) => m.HeaderMainNavigationModule),
          cmsComponents: ['HeaderMainNavigationComponent'],
          dependencies: [
            () => import('../cms-content-tag-base/cms-content-tag-base.module').then((m) => m.CmsContentTagBaseModule),
          ],
        },
        footerNavigation: {
          module: () => import('./cms-libs/footer-navigation/footer-navigation.module').then((m) => m.FooterNavigationModule),
          cmsComponents: ['FooterNavigationComponent'],
        },
        countrySelector: {
          module: () => import('./cms-libs/country-selector/country-selector.module').then((m) => m.CountrySelectorModule),
          cmsComponents: ['CmsFooterCountrySelectorComponent'],
        },
        myPageCurrentCustomer: {
          module: () =>
            import('./cms-libs/my-page-current-customer/my-page-current-customer.module').then(
              (m) => m.MyPageCurrentCustomerModule
            ),
          cmsComponents: ['CmsMyPageCurrentCustomerComponent'],
        },
      },
    }),
  ],
})
export class NavigationFeatureModule {}
