import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, map, shareReplay, take, tap } from 'rxjs/operators';
import {
  Article,
  CustomerUploadedMedia,
  FilePreview,
  FileUploadEvent,
  ReturnEntryForm,
  ReturnReason,
  ReturnType,
  Unit,
} from '../../../../core/model';
import { OrderReturnFacade } from '../../../../features/order-base';
import { Option } from '../../../../shared/components/dropdown/dropdown.component';
import { OrderReturnEntriesNotifierService } from '../../../services';
import { OrderEntryComponentEntry } from '../order-entry.component';
import { OrderReturnUploadedFilesGalleryModalData } from '../order-return-uploaded-files-gallery-modal/order-return-uploaded-files-gallery-modal.component';

const RETURN_TYPES: Option[] = [
  { value: ReturnType[ReturnType.FULL_QUANTITY], label: 'orderHistory.orderReturn.fullQuantity_heading' },
  { value: ReturnType[ReturnType.PARTIAL_QUANTITY], label: 'orderHistory.orderReturn.partialQuantity_heading' },
];

const initialReturnType: ReturnType = ReturnType[ReturnType.PARTIAL_QUANTITY];
@Component({
  selector: 'py-order-return-entry-form',
  templateUrl: './order-return-entry-form.component.html',
  styleUrls: ['./order-return-entry-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderReturnEntryFormComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  uploadingFile: boolean = false;

  filesMap: Map<string, FilePreview> = new Map();

  readonly maxFilesNumber = 10;
  readonly commentMaxLength = 240;
  readonly commentMinLength = 10;

  @Input() entry: OrderEntryComponentEntry;
  @Input() article: Article;

  returnTypesOptions$: Observable<Option[]>;
  returnReasonsOptions$: Observable<Option[]>;
  uploadedFiles$: Observable<CustomerUploadedMedia[]>;

  sapOrderCode: string;
  returnTypes: typeof ReturnType = ReturnType;
  returnReasons: ReturnReason[];
  returnEntryForm: UntypedFormGroup;
  isFilesUploadOptional = true;

  get returnTypeFormControl(): UntypedFormControl {
    return this.returnEntryForm.get('returnType') as UntypedFormControl;
  }

  get commentFormControl(): UntypedFormControl {
    return this.returnEntryForm.get('comment') as UntypedFormControl;
  }

  get returnReasonFormControl(): UntypedFormControl {
    return this.returnEntryForm.get('returnReason') as UntypedFormControl;
  }

  private get quantityAndUnitFormControl(): UntypedFormControl {
    return this.returnEntryForm.get('quantityAndUnit') as UntypedFormControl;
  }

  private get quantityTextFormControl(): UntypedFormControl {
    return this.returnEntryForm.get('quantityText') as UntypedFormControl;
  }

  get filesFormControl(): UntypedFormArray {
    return this.returnEntryForm.get('files') as UntypedFormArray;
  }

  get isFileFormControlInvalid(): boolean {
    return (this.filesFormControl.dirty || this.filesFormControl.touched) && this.filesFormControl.invalid;
  }

  constructor(
    private orderReturnService: OrderReturnFacade,
    private fb: UntypedFormBuilder,
    private orderReturnEntriesNotifierService: OrderReturnEntriesNotifierService,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private launchDialogService: LaunchDialogService
  ) {}

  ngOnInit(): void {
    this.sapOrderCode = this.route.snapshot.params['orderCode'];

    this.returnEntryForm = this.fb.group({
      returnType: new UntypedFormControl('', Validators.required),
      quantityAndUnit: this.fb.group({
        quantity: new UntypedFormControl(null),
        unitCode: new UntypedFormControl(null),
      }),
      quantityText: new UntypedFormControl(null),
      returnReason: new UntypedFormControl('', Validators.required),
      comment: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(this.commentMinLength),
        Validators.maxLength(this.commentMaxLength),
      ]),
      files: new UntypedFormControl([]),
    });

    this.initUploadedFiles();
    this.initReturnTypesOptions();
    this.initReturnReasonsOptions();

    this.subscribeToFormValueChanges();
    this.subscribeToReturnTypeChanges();
    this.subscribeToReturnReasonChanges();
    this.listenToOrderReturnEntriesNotifyChanges();
  }

  updateQuantityAndUnit(values: { quantity: number; unitCode: string }): void {
    this.returnEntryForm.patchValue({ quantityAndUnit: { quantity: values.quantity, unitCode: values.unitCode } });
  }

  onDropdownClose(): void {
    this.returnReasonFormControl.markAsTouched();
  }

  onFileUpload(event: Event): void {
    const target = event.target as HTMLInputElement;
    const file: File = target.files[0];

    if (!file) {
      this.uploadingFile = false;
      return;
    }
    this.uploadingFile = true;

    this.subscriptions.add(
      this.orderReturnService
        .uploadFileForOrderReturnDraftEntry(this.sapOrderCode, this.entry.itemNumber, file)
        .pipe(
          tap((uploadedFile) => {
            if (!file.type.includes('tiff')) {
              const reader = new FileReader();
              reader.onload = () => {
                this.filesMap.set(uploadedFile.qualifier, { file: file, fileUrl: reader.result as string });
                this.cd.detectChanges();
              };

              reader.readAsDataURL(file);
            }

            const uploadedFiles: CustomerUploadedMedia[] = this.filesFormControl.value || [];
            this.returnEntryForm.patchValue({ files: [...uploadedFiles, { ...uploadedFile }] });
          })
        )
        .subscribe(() => {
          this.uploadingFile = false;
        })
    );
  }

  getFilePreview(qualifier: string): FilePreview {
    return this.filesMap.get(qualifier);
  }

  hasImageType(type: string): boolean {
    return type.startsWith('image/');
  }

  hasPdfType(fileName: string): boolean {
    return fileName?.split('.').pop() === 'pdf';
  }

  removeFile(qualifier: string): void {
    const uploadedFiles: CustomerUploadedMedia[] = [...this.filesFormControl.value] || [];
    const fileIndex = uploadedFiles.findIndex((file) => file.qualifier === qualifier);

    if (fileIndex !== -1) {
      uploadedFiles.splice(fileIndex, 1);
      this.returnEntryForm.patchValue({ files: [...uploadedFiles] });
      this.orderReturnService.removeUploadedFiles(this.sapOrderCode, this.entry.itemNumber, qualifier);

      setTimeout(() => this.filesMap.delete(qualifier), 1000);
    }
  }

  openUploadedFilesGallery(uploadedFiles: CustomerUploadedMedia[], fileIndex: number): void {
    const uploadFile$ = new Subject<FileUploadEvent>();
    const removeFile$ = new Subject<string>();

    const modalData: OrderReturnUploadedFilesGalleryModalData = {
      uploadedFiles: uploadedFiles,
      filesMap: this.filesMap,
      sapOrderNumber: this.sapOrderCode,
      itemNumber: this.entry.itemNumber,
      maxFilesNumber: this.maxFilesNumber,
      initialActiveSlideIndex: fileIndex,
      isReturnModeActive: true,
      uploadFile$: uploadFile$,
      removeFile$: removeFile$,
    };

    this.subscriptions.add(
      uploadFile$
        .pipe(filter((fileUploadEvent) => fileUploadEvent !== undefined))
        .subscribe((fileUploadEvent: FileUploadEvent) => {
          const files: CustomerUploadedMedia[] = this.filesFormControl.value || [];
          this.returnEntryForm.patchValue({
            files: [...files, { ...fileUploadEvent.uploadedFile }],
          });

          if (!fileUploadEvent.file.type.includes('tif')) {
            this.filesMap.set(fileUploadEvent.uploadedFile.qualifier, {
              file: fileUploadEvent.file,
              fileUrl: fileUploadEvent.fileUrl,
            });
          }
        })
    );

    this.subscriptions.add(
      removeFile$.pipe(filter((qualifier) => qualifier !== undefined)).subscribe((qualifier: string) => {
        this.removeFile(qualifier);
      })
    );

    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.ORDER_RETURN_UPLOADED_FILES_GALLERY, undefined, modalData);
  }

  isQuantityAndUnitSelectionPossible(article: Article, entry: OrderEntryComponentEntry): boolean {
    return article.units?.some((unit) => unit.code === entry.unit.code);
  }

  private initReturnTypesOptions(): void {
    this.returnTypesOptions$ = of(RETURN_TYPES).pipe(
      take(1),
      tap(() => this.returnEntryForm.patchValue({ returnType: initialReturnType }))
    );
  }

  private initReturnReasonsOptions(): void {
    this.returnReasonsOptions$ = this.orderReturnService.getReturnReasons().pipe(
      tap((reasons) => (this.returnReasons = reasons)),
      map((reasons) => reasons.map((reason) => ({ value: reason.reasonCode, label: reason.reasonText })))
    );
  }

  private initUploadedFiles(): void {
    const uploadedFiles$ = this.orderReturnService
      .getOrderReturnDraftUploadedFilesForItemNumber(this.entry.itemNumber)
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.uploadedFiles$ = uploadedFiles$;

    // Initialize files form control with files from loaded order return draft
    this.subscriptions.add(
      uploadedFiles$
        .pipe(
          take(1),
          filter((files) => !!files)
        )
        .subscribe((files) => {
          this.returnEntryForm.patchValue({ files: files });
        })
    );
  }

  private subscribeToFormValueChanges(): void {
    this.subscriptions.add(
      this.returnEntryForm.valueChanges.pipe(debounceTime(400)).subscribe((formData: ReturnEntryForm) => {
        this.orderReturnService.updateOrderReturnDraftEntry(this.entry.itemNumber, {
          ...formData,
          isValid: this.returnEntryForm.valid,
        });
      })
    );
  }

  private subscribeToReturnTypeChanges(): void {
    this.subscriptions.add(
      this.returnEntryForm.get('returnType').valueChanges.subscribe((returnType) => {
        const isQuantityAndUnitSelectionPossible = this.isQuantityAndUnitSelectionPossible(this.article, this.entry);
        if (returnType === ReturnType.PARTIAL_QUANTITY) {
          if (isQuantityAndUnitSelectionPossible) {
            this.returnEntryForm.patchValue({
              quantityAndUnit: { quantity: 1, unitCode: this.entry.unit.code },
            });
            this.quantityAndUnitFormControl.get('quantity').setValidators([Validators.required]);
            this.quantityAndUnitFormControl.get('unitCode').setValidators([Validators.required]);
          } else {
            this.quantityTextFormControl.reset();
            this.quantityTextFormControl.setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(255)]);
          }
        } else {
          if (isQuantityAndUnitSelectionPossible) {
            this.quantityAndUnitFormControl.reset();
            this.quantityAndUnitFormControl.get('quantity').clearValidators();
            this.quantityAndUnitFormControl.get('quantity').setErrors(null);
            this.quantityAndUnitFormControl.get('unitCode').clearValidators();
            this.quantityAndUnitFormControl.get('unitCode').setErrors(null);
          } else {
            this.quantityTextFormControl.reset();
            this.quantityTextFormControl.clearValidators();
            this.quantityTextFormControl.setErrors(null);
          }
        }

        this.quantityAndUnitFormControl.updateValueAndValidity();
        this.quantityTextFormControl.updateValueAndValidity();
      })
    );
  }

  private subscribeToReturnReasonChanges(): void {
    this.subscriptions.add(
      this.returnEntryForm.get('returnReason').valueChanges.subscribe((returnReason) => {
        // Set required validator for files if return reason requires that
        const newReason = this.returnReasons.find((reason) => reason.reasonCode === returnReason);
        if (newReason?.fileUploadMandatory) {
          this.filesFormControl.setValidators([Validators.required]);
          this.isFilesUploadOptional = false;
        } else {
          this.filesFormControl.setValidators([]);
          this.isFilesUploadOptional = true;
        }
        this.filesFormControl.updateValueAndValidity();
      })
    );
  }

  private listenToOrderReturnEntriesNotifyChanges(): void {
    this.subscriptions.add(
      this.orderReturnEntriesNotifierService.listenOnNotify().subscribe(() => {
        Object.values(this.returnEntryForm.controls).forEach((control) => control.markAsDirty());
        this.cd.detectChanges();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  calculateInitialMaximumQuantityInSalesUnit(units: Unit[], entryUnitCode: string, entryQuantity: number): number {
    const entryUnit = units.find((unit) => unit.code === entryUnitCode);
    return entryUnit.inEcommerceUnit * entryQuantity;
  }
}
