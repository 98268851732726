import { MemoizedSelector, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { CatalogState, NonSellableProductsState, StateWithCatalog } from '../../../store/catalog-state';
import { getCatalogState } from '../../../store/selectors/feature.selector';

export const getNonSellableProductsState: MemoizedSelector<StateWithCatalog, NonSellableProductsState> = createSelector(
  getCatalogState,
  (state: CatalogState) => state && state.nonSellableProducts
);

export const getSendNonSellableProductInquiryLoading: MemoizedSelector<StateWithCatalog, boolean> = createSelector(
  getNonSellableProductsState,
  (state: NonSellableProductsState) => state && StateUtils.loaderLoadingSelector(state.inquiry)
);

export const getSendNonSellableProductInquirySuccess: MemoizedSelector<StateWithCatalog, boolean> = createSelector(
  getNonSellableProductsState,
  (state: NonSellableProductsState) => state && StateUtils.loaderSuccessSelector(state.inquiry)
);
