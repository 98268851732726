import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, Provider } from '@angular/core';
import {
  BaseSiteService,
  ConfigInitializerService,
  I18NEXT_INSTANCE,
  LanguageService,
  OccEndpointsService,
} from '@spartacus/core';
import { I18nStateTransferService } from '../services';
import { SiteContextI18nextSynchronizer, i18nextInit } from './i18next-init';

export const i18nextProviders: Provider[] = [
  {
    provide: APP_INITIALIZER,
    useFactory: i18nextInit,
    deps: [
      I18NEXT_INSTANCE,
      ConfigInitializerService,
      LanguageService,
      BaseSiteService,
      OccEndpointsService,
      HttpClient,
      SiteContextI18nextSynchronizer,
      I18nStateTransferService,
    ],
    multi: true,
  },
];
