import { Occ } from '../occ';

export enum PdpTabTypes {
  ArticleList = 'ArticleList',
  ProductInformation = 'ProductInformation',
  CertificateInformation = 'CertificateInformation',
  Applications = 'Applications',
  TechnicalCapabilities = 'TechnicalCapabilities',
  PrintingTechnologies = 'PrintingTechnologies',
  TechnicalAttributes = 'TechnicalAttributes',
  Assortment = 'Assortment',
  Documents = 'Documents',
}

export enum NonSellableProductInquiryFormContactType {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

export interface NonSellableProductInquiryFormData extends Occ.ProductInquiryForm {
  contactType: NonSellableProductInquiryFormContactType;
}
