import { Injectable } from '@angular/core';
import { JsonLdBuilder } from '@spartacus/storefront';
import { Observable, of } from 'rxjs';
import { Product } from '../../../../../core/model';
import { prepareUrlForLink } from '../../../../../core/util';
import { MediaService } from '../../../../../shared';
import { JsonLdProductData } from './product-schema.builder';

/**
 * Builds the basic structured data for the product, see https://schema.org/Product.
 * This builder includes data for sku number, name, description and first image.
 */
@Injectable({
  providedIn: 'root',
})
export class JsonLdBaseProductBuilder implements JsonLdBuilder<JsonLdProductData> {
  constructor(private mediaService: MediaService) {}

  build({ product, sitePrefix }: JsonLdProductData): Observable<Record<string, string>> {
    const splittedUrl = product.seo?.altHrefLangs?.[0]?.url.split(/(com)/);
    const origin = splittedUrl && splittedUrl[0] + splittedUrl[1];

    return of({
      ...this.getProductBase(product, `${origin || ''}/${sitePrefix}`),
      ...this.getProductImage(product),
    });
  }

  /**
   * Returns product name,description and url.
   * */
  private getProductBase(product: Product, originWithPrefix: string): Record<string, string> {
    return {
      ...(product.name && { name: product.name }),
      ...(product.description && { description: product.description }),
      ...(product.url && { url: `${originWithPrefix}/${prepareUrlForLink(product.url)}` }),
    };
  }

  /**
   * Returns the full image url with the first product image url.
   *
   * If the image is not available, an empty object is returned.
   */
  private getProductImage(product: Product): { image?: string } {
    const image = product.images && product.images[0]?.url;

    return image ? { image: this.mediaService.resolveAbsoluteUrl(image) } : {};
  }
}
