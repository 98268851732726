import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { SCHEMA_BUILDER, SchemaBuilder } from '@spartacus/storefront';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { JsonLdScriptFactory } from './json-ld-script.factory';

/**
 * Factory service that is used to build the structured data for
 * all configured schema builders.
 */
@Injectable({
  providedIn: 'root',
})
export class StructuredDataFactory implements OnDestroy {
  constructor(
    private scriptBuilder: JsonLdScriptFactory,
    @Optional()
    @Inject(SCHEMA_BUILDER)
    private builders: SchemaBuilder[]
  ) {}

  protected subscription: Subscription = new Subscription();

  /**
   * Initiates the build of structured data by collecting all schema
   * builders.
   */
  build(): void {
    if (this.scriptBuilder.isJsonLdRequired() && this.builders) {
      this.subscription.add(
        this.collectSchemas()
          .pipe(map((schemas) => schemas.filter((schema) => !!schema)))
          .subscribe((schema: {}[]) => this.scriptBuilder.build(schema))
      );
    }
  }

  /**
   * Collects all schema builders and observe their structured data.
   */
  protected collectSchemas(): Observable<any[]> {
    return combineLatest(this.builders.map((builder) => builder.build()));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
