import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { OutletModule, OutletRefModule } from '@spartacus/storefront';
import { RecaptchaLoaderService, RecaptchaModule } from 'ng-recaptcha';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { ConsentManagementModule } from '../../cms-components/consent-management/consent-management.module';
import { OrderApprovalAbortHijackModalModule } from '../../cms-components/misc/abort-hijack-modal/order-approval-abort-hijack-modal.module';
import { ActiveOrderProcessModalModule } from '../../cms-components/misc/active-order-process-modal';
import { CartMessageModule } from '../../cms-components/misc/cart-message/cart-message.module';
import { GlobalMessageComponentModule } from '../../cms-components/misc/global-message';
import { ReturnOrderMessageComponent } from '../../cms-components/misc/return-order-message/return-order-message.component';
import { PageSlotModule } from '../../cms-structure/page';
import { PageLayoutModule } from '../../cms-structure/page/page-layout';
import { SeoModule } from '../../cms-structure/seo';
import { HeaderMainNavigationModule } from '../../features/navigation/cms-libs/header-main-navigation/header-main-navigation.module';
import { DrawerServiceModule, PipesModule } from '../../shared';
import { BackToTopModule } from '../../shared/components/back-to-top/back-to-top.module';
import { CurrentCustomerBannerComponent } from '../../shared/components/current-customer-banner/current-customer-banner.component';
import { DebugMenuComponent } from '../../shared/components/debug/debug-menu/debug-menu.component';
import { PasswordMessageModule } from '../../shared/components/password-message/password-message.module';
import { ReloadModalModule } from '../../shared/components/reload-modal/reload-modal.module';
import { SearchModule } from '../../shared/components/search/search.module';
import { UnsupportedMessageModule } from '../../shared/components/unsupported-message';
import { DeferredRecaptchaService } from '../../shared/services/recaptcha/deferred-recaptcha.service';
import { KeyboardFocusModule } from '../a11y/keyboard-focus';
import { SkipLinkModule } from '../a11y/skip-link';
import { StorefrontComponent } from './storefront.component';
import { SuperStorefrontComponent } from './super-storefront.component';

@NgModule({
  declarations: [StorefrontComponent, SuperStorefrontComponent],
  exports: [SuperStorefrontComponent],
  imports: [
    CommonModule,
    RouterModule,
    GlobalMessageComponentModule,
    ReloadModalModule,
    CartMessageModule,
    UnsupportedMessageModule,
    OrderApprovalAbortHijackModalModule,
    OutletModule,
    OutletRefModule,
    PageLayoutModule,
    SeoModule,
    PageSlotModule,
    SkipLinkModule,
    KeyboardFocusModule,
    SearchModule,
    HeaderMainNavigationModule,
    NzIconModule,
    DrawerServiceModule,
    ConsentManagementModule,
    PasswordMessageModule,
    BackToTopModule,
    ActiveOrderProcessModalModule,
    PipesModule,
    DebugMenuComponent,
    ReturnOrderMessageComponent,
    CurrentCustomerBannerComponent,
    RecaptchaModule,
  ],
  providers: [
    {
      provide: RecaptchaLoaderService,
      useClass: DeferredRecaptchaService,
    },
  ],
})
export class MainModule {}
