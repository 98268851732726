import { StateUtils } from '@spartacus/core';
import { ContentTag } from '../../../../core/model';
import { PyStateUtils } from '../../../../core/state';
import { CONTENT_TAG } from '../cms-content-tag-state';

export enum ContentTagActionTypes {
  LoadContentTag = '[Cms] Load content tag',
  LoadContentTagFail = '[Cms] Load content tag fail',
  LoadContentTagSuccess = '[Cms] Load content tag success',
}

export class LoadContentTag extends StateUtils.EntityLoadAction {
  readonly type = ContentTagActionTypes.LoadContentTag;

  constructor(public payload: { userId: string; uid: string }) {
    super(CONTENT_TAG, payload.uid);
  }
}

export class LoadContentTagFail extends PyStateUtils.EntityFailAction {
  readonly type = ContentTagActionTypes.LoadContentTagFail;

  constructor(uid: string, public payload: any) {
    super(CONTENT_TAG, uid, payload);
  }
}

export class LoadContentTagSuccess extends StateUtils.EntitySuccessAction {
  readonly type = ContentTagActionTypes.LoadContentTagSuccess;

  constructor(public payload: ContentTag) {
    super(CONTENT_TAG, payload.uid);
  }
}

export type ContentTagActions = LoadContentTag | LoadContentTagFail | LoadContentTagSuccess;
