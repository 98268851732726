import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { B2BUserSearchParams, DataImport, UsersImportMode } from '../../../../../core/model';
import { UsersImportExportAdapter } from './users-import-export.adapter';

@Injectable({
  providedIn: 'root',
})
export class UsersImportExportConnector {
  constructor(protected adapter: UsersImportExportAdapter) {}

  getExportedUsersFile(userId: string, searchParams: B2BUserSearchParams, totalCount: number): Observable<Blob> {
    return this.adapter.getExportedUsersFile(userId, searchParams, totalCount);
  }

  sendImportUsersFile(userId: string, file: File, mode: UsersImportMode): Observable<DataImport> {
    return mode === UsersImportMode.INSERT
      ? this.adapter.sendInsertImportUsersFile(userId, file)
      : this.adapter.sendUpdateImportUsersFile(userId, file);
  }

  downloadImportUsersFileTemplate(userId: string): Observable<Blob> {
    return this.adapter.downloadImportUsersFileTemplate(userId);
  }
}
