import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService } from '@spartacus/core';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, share, switchMap, tap } from 'rxjs/operators';
import { UserIdService } from '../../../auth/user-auth/facade';
import { PriceFileRequest, PriceFileRequestTemplate } from '../../../model/index';
import { withdrawOn } from '../../../util';
import { PriceFileRequestTemplateActions } from '../store/actions';
import {
  LoadPriceFileRequestTemplate,
  LoadPriceFileRequestTemplates,
} from '../store/actions/price-file-request-template.actions';
import { StateWithPriceFile } from '../store/price-file-state';
import {
  getPriceFileRequestTemplate,
  getPriceFileRequestTemplates,
  getPriceFileRequestTemplatesLoading,
  getPriceFileTemplateSuccess,
  getPriceFileTemplateValue,
} from '../store/selectors/price-file-request-templates.selectors';

@Injectable({
  providedIn: 'root',
})
export class PriceFileRequestTemplateService {
  constructor(private store: Store<StateWithPriceFile>, private authService: AuthService, private userIdService: UserIdService) {}

  getPriceFileRequestTemplatesLoading(): Observable<boolean> {
    return this.store.select(getPriceFileRequestTemplatesLoading);
  }

  savePriceFileRequestTemplate(priceFileRequest: PriceFileRequest) {
    this.userIdService.takeUserId(true).subscribe(
      (userId) => this.store.dispatch(new PriceFileRequestTemplateActions.SavePriceFileRequestTemplate(userId, priceFileRequest)),
      () => {}
    );
  }

  savePriceFileRequestTemplateReset() {
    this.store.dispatch(new PriceFileRequestTemplateActions.SavePriceFileRequestTemplateReset());
  }

  savePriceFileRequestTemplateSuccess(): Observable<PriceFileRequestTemplate> {
    return this.store.select(getPriceFileTemplateSuccess).pipe(
      filter((res) => !!res),
      switchMap(() =>
        combineLatest([this.store.select(getPriceFileTemplateValue), this.store.select(getPriceFileRequestTemplates)]).pipe(
          map(([template, templates]) => {
            return template?.code && templates.filter((t) => t.code === template.code).length === 0 ? template : undefined;
          })
        )
      ),
      filter((template) => !!template)
    );
  }

  private loadRequestTemplate(code: string): void {
    this.userIdService.takeUserId(true).subscribe(
      (userId) => this.store.dispatch(new LoadPriceFileRequestTemplate(userId, code)),
      () => {}
    );
  }

  getPriceFileRequestTemplate(code: string): Observable<PriceFileRequestTemplate> {
    const isUserLoggedIn$ = this.authService.isUserLoggedIn().pipe(share());
    return isUserLoggedIn$.pipe(
      filter(Boolean),
      switchMap(() => this.store.select(getPriceFileRequestTemplate, { code })),
      tap((template) => {
        if (!template) {
          this.loadRequestTemplate(code);
        }
      }),
      filter((template) => !!template),
      withdrawOn(isUserLoggedIn$.pipe(filter((isLoggedIn) => !isLoggedIn)))
    );
  }

  private loadRequestTemplates(): void {
    this.userIdService.takeUserId(true).subscribe(
      (userId) => this.store.dispatch(new LoadPriceFileRequestTemplates(userId)),
      () => {}
    );
  }

  getPriceFileRequestTemplates(): Observable<Array<PriceFileRequestTemplate>> {
    const isUserLoggedIn$ = this.authService.isUserLoggedIn().pipe(share());
    return isUserLoggedIn$.pipe(
      filter(Boolean),
      switchMap(() => this.store.select(getPriceFileRequestTemplates)),
      tap((templates) => {
        if (!templates) {
          this.loadRequestTemplates();
        }
      }),
      filter((templates) => !!templates),
      withdrawOn(isUserLoggedIn$.pipe(filter((isLoggedIn) => !isLoggedIn)))
    );
  }

  delete(code) {
    this.userIdService.takeUserId(true).subscribe(
      (userId) => this.store.dispatch(new PriceFileRequestTemplateActions.DeletePriceFileRequestTemplate(userId, code)),
      () => {}
    );
  }
}
