import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import { HttpErrorModel, OrderProcessStatus } from '../../../../../core/model';
import { PyStateUtils } from '../../../../../core/state';
import { ProcessIdBuilder } from '../../../../../core/user/store/util/process-id-builder';
import { GET_ORDER_PROCESS_STATUS_PROCESS } from '../checkout-state';
import { CheckoutStepsEntityAction, PayloadWithCheckoutKey } from './checkout-steps-entity.action';

export enum CheckoutProcessingActionTypes {
  GetOrderProcessStatus = '[Checkout] Get order process status',
  GetOrderProcessStatusFail = '[Checkout] Get order process status fail',
  GetOrderProcessStatusSuccess = '[Checkout] Get order process status success',
  ResetOrderProcessStatus = '[Checkout] Reset order process status',
  SetOrderProcessStatusPolling = '[Checkout] Set order process polling',
}

interface GetOrderProcessStatusPayload extends PayloadWithCheckoutKey {
  userId: string;
  orderCode: string;
}

interface GetOrderProcessStatusFailPayload extends GetOrderProcessStatusPayload {
  error?: HttpErrorModel;
}

interface GetOrderProcessStatusSuccessPayload extends GetOrderProcessStatusPayload {
  orderProcessStatus: OrderProcessStatus;
}

interface SetOrderProcessStatusPollingPayload extends PayloadWithCheckoutKey {
  isPolling: boolean;
}

export class GetOrderProcessStatus extends StateUtils.EntityLoadAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutProcessingActionTypes.GetOrderProcessStatus;

  constructor(public payload: GetOrderProcessStatusPayload) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(GET_ORDER_PROCESS_STATUS_PROCESS, payload.key));
  }
}

export class GetOrderProcessStatusFail extends PyStateUtils.EntityFailAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutProcessingActionTypes.GetOrderProcessStatusFail;

  constructor(public payload: GetOrderProcessStatusFailPayload) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(GET_ORDER_PROCESS_STATUS_PROCESS, payload.key), payload.error);
  }
}

export class GetOrderProcessStatusSuccess extends StateUtils.EntitySuccessAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutProcessingActionTypes.GetOrderProcessStatusSuccess;

  constructor(public payload: GetOrderProcessStatusSuccessPayload) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(GET_ORDER_PROCESS_STATUS_PROCESS, payload.key));
  }
}

export class ResetOrderProcessStatus extends StateUtils.EntityLoaderResetAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutProcessingActionTypes.ResetOrderProcessStatus;

  constructor(public payload: PayloadWithCheckoutKey) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(GET_ORDER_PROCESS_STATUS_PROCESS, payload.key));
  }
}

export class SetOrderProcessStatusPolling implements CheckoutStepsEntityAction {
  readonly type = CheckoutProcessingActionTypes.SetOrderProcessStatusPolling;

  constructor(public payload: SetOrderProcessStatusPollingPayload) {}
}

export type CheckoutProcessingActions =
  | GetOrderProcessStatus
  | GetOrderProcessStatusFail
  | GetOrderProcessStatusSuccess
  | ResetOrderProcessStatus
  | SetOrderProcessStatusPolling;
