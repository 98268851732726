import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../../core/i18n';
import { UrlModule } from '../../../../core/routing';
import { GenericLinkModule, MegamenuListItemsModule } from '../../../../shared';
import { SubAddonGroupNavigationComponent } from './sub-addon-group-navigation.component';

@NgModule({
  imports: [CommonModule, I18nModule, NzIconModule, GenericLinkModule, MegamenuListItemsModule, UrlModule],
  declarations: [SubAddonGroupNavigationComponent],
  exports: [SubAddonGroupNavigationComponent],
})
export class SubAddonGroupNavigationModule {}
