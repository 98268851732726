import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { PageMetaResolver } from '@spartacus/core';
import { MyPageMetaResolver } from './my-page/services/my-page-meta.resolver';
import { PriceFileCoreModule } from './price-file/price-file-core.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, PriceFileCoreModule],
})
export class FeaturesModule {
  static forRoot(): ModuleWithProviders<FeaturesModule> {
    return {
      ngModule: FeaturesModule,
      providers: [
        {
          provide: PageMetaResolver,
          useExisting: MyPageMetaResolver,
          multi: true,
        },
      ],
    };
  }
}
