import { Injectable } from '@angular/core';
import { EventService, createFrom } from '@spartacus/core';
import { Observable, merge } from 'rxjs';
import { distinctUntilChanged, map, skipWhile } from 'rxjs/operators';
import { UserConsentFacade } from '../../user/facades';
import { ConsentCode, ConsentEvent } from './consent.events';

/**
 * A builder for the ConsentEvent
 */
@Injectable({
  providedIn: 'root',
})
export class ConsentEventBuilder {
  constructor(protected eventService: EventService, protected userConsentService: UserConsentFacade) {
    this.register();
  }

  /**
   * Registers events
   */
  protected register(): void {
    this.eventService.register(ConsentEvent, this.buildConsentEvent());
  }

  /**
   * Builds the consent events
   */
  protected buildConsentEvent(): Observable<ConsentEvent> {
    return merge(
      this.getConsent(ConsentCode.MARKETING_THIRD_PARTY, this.userConsentService.isMarketingThirdPartyAllowed()),
      this.getConsent(ConsentCode.PERSONALIZATION, this.userConsentService.isPersonalizationAllowed()),
      this.getConsent(ConsentCode.TRACKING, this.userConsentService.isTrackingAllowed())
    );
  }

  private getConsent(code: ConsentCode, isGiven$: Observable<boolean>): Observable<ConsentEvent> {
    return isGiven$.pipe(
      distinctUntilChanged(),
      skipWhile((isGiven) => !isGiven),
      map((isGiven) => createFrom(ConsentEvent, { code, isGiven }))
    );
  }
}
