<ng-container *ngIf="showFees">
  <ng-container *ngTemplateOutlet="totalEntryPriceTemplate"></ng-container>
  <ng-container
    *ngTemplateOutlet="
      template;
      context: {
        label: 'checkout.summary.totalOrderLineFees_heading' | cxTranslate,
        price: order.totalFeePrice,
        emphasize: shouldEmphasizeProperty('totalFeePrice')
      }
    "
  ></ng-container>

  <ng-container
    *ngTemplateOutlet="
      template;
      context: {
        label: 'checkout.summary.totalOrderLineValueIncludingFees_heading' | cxTranslate,
        price: order.totalEntryPriceIncludingFees,
        emphasize: shouldEmphasizeProperty('totalEntryPriceIncludingFees')
      }
    "
  ></ng-container>
</ng-container>

<ng-container *ngIf="!showFees">
  <ng-container *ngTemplateOutlet="totalEntryPriceTemplate"></ng-container>
</ng-container>
<ng-container *ngFor="let condition of order.conditions">
  <ng-container
    *ngTemplateOutlet="
      template;
      context: {
        label: condition.name,
        price: condition.priceData,
        emphasize: shouldEmphasizeProperty('conditions'),
        tooltipText: condition.tooltipText
      }
    "
  ></ng-container>
</ng-container>

<ng-container
  *ngTemplateOutlet="
    template;
    context: {
      label: 'checkout.summary.totalOrderValue_heading' | cxTranslate,
      price: order.totalPrice,
      emphasize: shouldEmphasizeProperty('totalPrice')
    }
  "
></ng-container>

<ng-container *ngIf="showTax">
  <ng-container
    *ngTemplateOutlet="
      template;
      context: {
        label: 'checkout.summary.totalTax_heading' | cxTranslate,
        price: order.totalTax,
        emphasize: shouldEmphasizeProperty('totalTax')
      }
    "
  ></ng-container>

  <ng-container
    *ngTemplateOutlet="
      template;
      context: {
        label: 'checkout.summary.totalOrderValueWithTax_heading' | cxTranslate,
        price: order.totalPriceWithTax,
        emphasize: shouldEmphasizeProperty('totalPriceWithTax')
      }
    "
  ></ng-container>
</ng-container>

<ng-template #totalEntryPriceTemplate>
  <ng-container *ngIf="cart?.subTotal; else orderTotalEntryPriceTemplate">
    <ng-container
      *ngTemplateOutlet="
        template;
        context: {
          label: 'checkout.summary.totalOrderLineValue_heading' | cxTranslate,
          price: cart.subTotal,
          discountPrice: order.totalEntryPrice,
          emphasize: shouldEmphasizeProperty('totalEntryPrice'),
          tooltipText: undefined
        }
      "
    ></ng-container>
  </ng-container>
  <ng-template #orderTotalEntryPriceTemplate>
    <ng-container
      *ngTemplateOutlet="
        template;
        context: {
          label: 'checkout.summary.totalOrderLineValue_heading' | cxTranslate,
          price: order.totalEntryPrice,
          emphasize: shouldEmphasizeProperty('totalEntryPrice'),
          tooltipText: undefined
        }
      "
    ></ng-container>
  </ng-template>
</ng-template>
