import { Injectable } from '@angular/core';
import { ConsentsConfig } from '../../anonymous-consents/config/consents-config';
import { ShoppingListAccessType } from '../../model/cart.model';
import { Consent, ConsentTemplate } from '../../model/consent.model';
import { FeedBadgeType, OrdersCountBadge } from '../../model/feed.model';
import { AlertType } from '../../model/misc.model';
import { OrderApprovalStatus, OrderCompletionStatus } from '../../model/order.model';

@Injectable({ providedIn: 'root' })
export class UserUtilService {
  constructor(private config: ConsentsConfig) {}

  /**
   * Returns `true` if the consent is truthy and if `consentWithdrawnDate` doesn't exist.
   * Otherwise, `false` is returned.
   *
   * @param consent to check
   */
  isConsentGiven(consent: Consent): boolean {
    return Boolean(consent) && Boolean(consent.consentGivenDate) && !Boolean(consent.consentWithdrawnDate);
  }

  isConsentWithdrawn(consent: Consent): boolean {
    return !Boolean(consent) || Boolean(consent.consentWithdrawnDate);
  }

  isConsentRequired(templateCode: string) {
    return this.config.consents.user.requiredConsents.includes(templateCode);
  }

  getRequiredConsents(): string[] {
    return this.config.consents.user.requiredConsents;
  }

  getHiddenConsents(): string[] {
    return this.config.consents.user.hiddenConsents;
  }

  getHiddenLoginConsents(): string[] {
    return this.config.consents.user.hiddenLoginConsents;
  }

  isConsentAcknowledged(template: ConsentTemplate) {
    return template.version < 230101 || !!template.currentConsent?.code;
  }

  getCountForBadgeType(badges: OrdersCountBadge[], badgeType: FeedBadgeType): number {
    const foundBadge = badges.find((badge) => badge.type === badgeType);
    return foundBadge ? foundBadge.count : 0;
  }

  getOrderApprovalStatusBadgeType(orderApprovalStatus: string): AlertType {
    switch (orderApprovalStatus) {
      case OrderApprovalStatus.NEW:
      case OrderApprovalStatus.UPDATED:
        return AlertType.Info;
      case OrderApprovalStatus.REJECTED:
        return AlertType.Danger;
      case OrderApprovalStatus.RETURNED_TO_USER:
      case OrderApprovalStatus.ASSIGNED_TO_ADMIN:
        return AlertType.Warning;
      case OrderApprovalStatus.APPROVER_EDITING:
        return AlertType.Processing;
      case OrderApprovalStatus.APPROVED:
        return AlertType.Success;
      default:
        return AlertType.Info;
    }
  }

  getOrderCompletionStatusBadgeType(orderStatus: string): AlertType {
    switch (orderStatus) {
      case OrderCompletionStatus.Completed:
        return AlertType.Success;
      case OrderCompletionStatus.PartiallyCompleted:
        return AlertType.Warning;
      case OrderCompletionStatus.NotDelivered:
        return AlertType.Danger;
    }
  }

  getShoppingListBadgeAlertType(accessType?: ShoppingListAccessType): AlertType {
    switch (accessType?.toUpperCase()) {
      case ShoppingListAccessType.PRIVATE:
        return AlertType.Notification;
      case ShoppingListAccessType.SHARED:
        return AlertType.Success;
      case ShoppingListAccessType.INVITED_TO:
        return AlertType.Processing;
      case ShoppingListAccessType.EVERYONE:
        return AlertType.Warning;
      default:
        return AlertType.Notification;
    }
  }
}
