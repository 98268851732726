import { InjectionToken } from '@angular/core';
import {
  B2BUser,
  OrgCustomerCreation,
  OrgCustomerModification,
  OrgCustomerShipToAssignment,
  OrgUnitUserPage,
} from '../../../../core/model';
import { Converter } from '../../../../core/util';

export const B2B_USER_NORMALIZER = new InjectionToken<Converter<any, B2BUser>>('B2BUserNormalizer');

export const B2B_USER_SEARCH_NORMALIZER = new InjectionToken<Converter<any, OrgUnitUserPage>>('B2BUserSearchNormalizer');

export const ORG_CUSTOMER_CREATION_SERIALIZER = new InjectionToken<Converter<OrgCustomerCreation, any>>(
  'OrgCustomerCreationSerializer'
);

export const ORG_CUSTOMER_MODIFICATION_SERIALIZER = new InjectionToken<Converter<OrgCustomerModification, any>>(
  'OrgCustomerModificationSerializer'
);

export const ORG_CUSTOMER_SHIP_TO_ASSIGNMENT_SERIALIZER = new InjectionToken<Converter<OrgCustomerShipToAssignment, any>>(
  'OrgCustomerShipToAssignmentSerializer'
);

export const ORG_CUSTOMER_ROLE_SERIALIZER = new InjectionToken<Converter<string, any>>('OrgCustomerRoleSerializer');

export const ORG_CUSTOMER_SOLDTOS_SERIALIZER = new InjectionToken<Converter<string[], any>>('OrgCustomerSoldTosSerializer');
