import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  CMS_COMPONENT_NORMALIZER,
  CmsOccModule as CxCmsOccModule,
  OccCmsPageNormalizer as CxOccCmsPageNormalizer,
} from '@spartacus/core';
import { OccCmsComponentNormalizer } from './converters/occ-cms-component-normalizer';
import { OccCmsPageNormalizer } from './converters/occ-cms-page-normalizer';

@NgModule({
  imports: [CommonModule],
  exports: [CxCmsOccModule],
  providers: [
    { provide: CxOccCmsPageNormalizer, useExisting: OccCmsPageNormalizer },
    {
      provide: CMS_COMPONENT_NORMALIZER,
      useExisting: OccCmsComponentNormalizer,
      multi: true,
    },
  ],
})
export class CmsOccModule {}
