<ng-container
  *ngIf="columnAttributes?.length > 0"
  [ngTemplateOutlet]="enableSecondaryArticleRowVariant ? secondaryArticleRowVariant : defaultArticleRowVariant"
></ng-container>

<ng-template #defaultArticleRowVariant>
  <div class="d-flex flex-wrap attributes-wrapper-default-article-row">
    <span class="mr-4" *ngFor="let columnAttribute of columnAttributes" container="body" [ngbTooltip]="columnAttribute.name">{{
      columnAttribute.value
    }}</span>
  </div>
</ng-template>

<ng-template #secondaryArticleRowVariant>
  <div
    class="attributes-wrapper-secondary-article-row d-flex flex-wrap"
    [ngClass]="showAttributesInSeparateColumn ? 'separate-attributes-column' : 'mb-2'"
    [class.multiple-per-row]="multipleAttributesPerRow"
  >
    <div
      class="d-flex"
      [ngClass]="multipleAttributesPerRow ? 'flex-column' : 'flex-row justify-content-between mb-1'"
      *ngFor="let columnAttribute of columnAttributes"
    >
      <span class="attribute-name">{{ columnAttribute.name }}</span>
      <span class="attribute-value">{{ columnAttribute.value }}</span>
    </div>
  </div>
</ng-template>
