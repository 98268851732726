<button
  [ngbTooltip]="'orderCards.addToOrderCard_tooltip' | cxTranslate"
  [disableTooltip]="disableTooltip"
  #popOver="ngbPopover"
  class="btn btn-ternary btn-icon"
  [disabled]="disabled"
  [class.open]="popOver.isOpen()"
  [ngbPopover]="popContent"
  [popoverClass]="'add-to-order-card-popover'"
  [autoClose]="'outside'"
  [placement]="placement"
  (click)="initializeDropdown()"
  container="body"
>
  <i nz-icon nzType="solution"></i>
  <span class="pl-2" *ngIf="showButton">{{ 'orderCards.addProductToOrderCard_action' | cxTranslate }}</span>
</button>

<ng-template #popContent>
  <ng-container *ngIf="{ orderCards: orderCards$ | async, pagination: pagination$ | async, loading: loading$ | async } as data">
    <h5 class="px-4 py-3 border-bottom">
      {{ 'orderCards.addProductToOrderCard_title' | cxTranslate }}
    </h5>
    <ul
      *ngIf="data.orderCards"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      [infiniteScrollDisabled]="!data.pagination?.hasNext"
      [alwaysCallback]="false"
      (scrolled)="onScroll(data.pagination?.page + 1)"
      [scrollWindow]="false"
    >
      <li
        class="py-2 px-4 d-flex border-bottom justify-content-between align-items-center"
        *ngFor="let orderCard of data.orderCards"
      >
        {{ orderCard.name }}
        <button class="btn btn-icon ml-2 p-0" (click)="productCode ? addProduct(orderCard.code) : addArticles(orderCard.code)">
          <i nz-icon nzType="plus" nzTheme="outline"></i>
        </button>
      </li>
      <li class="py-3 px-4 d-flex border-bottom border-top justify-content-between align-items-center">
        <cx-generic-link (click)="productCode ? addProductToNewOrderCard() : addArticlesToNewOrderCard()"
          >{{ 'orderCards.createNewOrderCard_action' | cxTranslate }}
        </cx-generic-link>
      </li>
      <li class="py-2 px-4 d-flex justify-content-center align-items-center" *ngIf="data.loading">
        <cx-spinner></cx-spinner>
      </li>
    </ul>
  </ng-container>
</ng-template>
