import { StateUtils } from '@spartacus/core';
import { EntityMap } from '../../../model';
import { PyStateUtils } from '../../../state/utils';
import { CATALOG_KEYS } from '../catalog-state';

export * from '../../addon/store/actions/addon-group.action';
export * from '../../addon/store/actions/addon-node.action';
export * from '../../area-of-use/store/actions/area-of-use.action';
export * from '../../article/store/actions/article.action';
export * from '../../category/store/actions/category.action';
export * from '../../product/store/actions/product.action';

export enum CatalogKeyActionTypes {
  LoadKeys = '[Catalog] Load Keys',
  LoadKeysSuccess = '[Catalog] Load Keys Success',
  LoadKeysFail = '[Catalog] Load Keys Fail',
}

export class LoadCatalogKeys extends StateUtils.LoaderLoadAction {
  readonly type = CatalogKeyActionTypes.LoadKeys;

  constructor(public userId: string) {
    super(CATALOG_KEYS);
  }
}

export class LoadCatalogKeysSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = CatalogKeyActionTypes.LoadKeysSuccess;

  constructor(public payload: EntityMap<string>) {
    super(CATALOG_KEYS);
  }
}

export class LoadCatalogKeysFail extends PyStateUtils.LoaderFailAction {
  readonly type = CatalogKeyActionTypes.LoadKeysFail;

  constructor(public payload?: any) {
    super(CATALOG_KEYS, payload);
  }
}

export type CatalogKeyActions = LoadCatalogKeys | LoadCatalogKeysSuccess | LoadCatalogKeysFail;
