import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { OrderApproval, OrderApprovalSearchParams, Pagination, Sort } from '../../model';
import { USER_BASE_FEATURE } from '../store/user-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: OrderApprovalAdminFacade,
      feature: USER_BASE_FEATURE,
      async: true,
      methods: [
        'updateSearchParameters',
        'getSearchParameters',
        'getPagination',
        'getOrderApprovals',
        'getSorts',
        'getOrderApprovalsLoading',
      ],
    }),
})
export abstract class OrderApprovalAdminFacade {
  abstract updateSearchParameters(searchParams: OrderApprovalSearchParams): void;
  abstract getSearchParameters(): Observable<OrderApprovalSearchParams>;
  abstract getPagination(): Observable<Pagination>;
  abstract getOrderApprovals(_params?: OrderApprovalSearchParams): Observable<Array<Observable<OrderApproval>>>;
  abstract getSorts(): Observable<Sort[]>;
  abstract getOrderApprovalsLoading(): Observable<boolean>;
}
