import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { USER_BASE_FEATURE } from '../store/user-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: ExperimentalFeaturesFacade,
      feature: USER_BASE_FEATURE,
      async: true,
      methods: ['hasSearchScore', 'hasDebugMenu', 'hasIdleDetection'],
    }),
})
export abstract class ExperimentalFeaturesFacade {
  abstract hasSearchScore(): Observable<boolean>;
  abstract hasDebugMenu(): Observable<boolean>;
  abstract hasIdleDetection(): Observable<boolean>;
}
