<ng-template cxOutlet="py-storefront" cxPageTemplateStyle>
  <aside>
    @defer (on immediate) {
    <cx-page-layout section="alerts"></cx-page-layout>
    <py-unsupported-message section="alerts"></py-unsupported-message>
    } @defer (when isUserLoggedIn$ | async) {
    <py-return-order-message></py-return-order-message>
    }
  </aside>

  <ng-template cxOutlet="cx-header">
    @defer (on immediate) {
    <cx-global-message></cx-global-message>
    } @defer (when isUserLoggedIn$ | async) {
    <py-password-message></py-password-message>
    <py-reload-modal></py-reload-modal>
    <div class="cart-message-wrapper">
      <py-cart-message></py-cart-message>
    </div>
    <py-current-customer-banner></py-current-customer-banner>
    } @placeholder {
    <div #currentCustomerBannerElement id="current-customer-banner" class="current-customer-banner placeholder"></div>
    }

    <header
      id="header"
      class="storefront-header"
      [ngClass]="{
        'short-header': !(searchBoxVisible$ | async)
      }"
    >
      <cx-page-layout section="header"></cx-page-layout>
    </header>

    <cx-page-layout section="heroBanner"></cx-page-layout>
  </ng-template>

  <main id="mainElement" cxSkipLink="cx-main" [cxFocus]="{ disableMouseFocus: true }">
    <router-outlet></router-outlet>
  </main>

  @defer (when hasDebugMenu$ | async) {
  <py-debug-menu *ngIf="hasDebugMenu$ | async"></py-debug-menu>
  } @defer (on immediate) {
  <ng-template cxOutlet="cx-footer">
    <footer class="storefront-footer">
      <py-consent-management *ngIf="isUserLoggedIn$ | async"></py-consent-management>
      <py-anonymous-consent-management *ngIf="!(isUserLoggedIn$ | async)"></py-anonymous-consent-management>
      <cx-page-layout section="uniqueSellingPointServicesFooter"></cx-page-layout>
      <cx-page-layout section="extendedFooter"></cx-page-layout>
      <cx-page-layout section="footer"></cx-page-layout>
    </footer>
  </ng-template>
  }

  <ng-container *ngIf="captchaPublicKey$ | async as publicKey">
    <re-captcha
      #captchaRef="reCaptcha"
      (resolved)="reCaptchaComplete($event)"
      (error)="reCaptchaError($event)"
      errorMode="handled"
      size="invisible"
      [tabIndex]="-1"
      badge="bottomleft"
      [siteKey]="publicKey"
    >
    </re-captcha>
  </ng-container>
</ng-template>
