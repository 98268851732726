import { B2BUnit, B2BUser } from '@spartacus/core';
import { Occ } from '../occ';
import { PrincipalConfigurationGroup } from './misc.model';
import { Pagination, Sort } from './search.model';
import { Principal, SoldTo } from './user.model';

export { B2BUnit, B2BUser, B2BUserRole, CostCenter } from '@spartacus/core';

export const NO_ROLE = 'NO_ROLE';

export interface CustomerStatus {
  code: CustomerStatusEnum;
}

export interface CustomerStatusList {
  customerStatuses?: CustomerStatus[];
}

export enum CustomerStatusEnum {
  ACTIVE = 'ACTIVE',
  NEW = 'NEW',
  PENDING = 'PENDING',
  INACTIVE = 'INACTIVE',
  LOCKED_OUT = 'LOCKED_OUT',
  REJECTED = 'REJECTED',
  DELETED = 'DELETED',
  UNDETERMINED = 'UNDETERMINED',
  LOGIN_BLOCKED = 'LOGIN_BLOCKED',
}

export interface UpdateStatusOptions {
  eachActivationRequiresEmailConfirmation?: boolean;
}

export type UpdateStatusValue = CustomerStatusEnum.ACTIVE | CustomerStatusEnum.INACTIVE | CustomerStatusEnum.REJECTED;

export interface UserRole {
  activationRequiresEmailConfirmation?: boolean;
  configurationGroups?: PrincipalConfigurationGroup[];
  description?: string;
  displayName?: string;
  name?: string;
  uid?: string;
}

export interface UserRoleList {
  roles?: UserRole[];
  key: string;
}

export interface OrgCustomerCreation {
  email?: string;
  displayUid?: string;
  firstName?: string;
  lastName?: string;
  orgUnit?: B2BUnit;
  phone?: string;
  titleCode?: string;
}

export interface OrgCustomerModification {
  active?: boolean;
  approvalRoles?: string[];
  assignedSoldTos?: SoldTo[];
  userRole?: Principal;
  email?: string;
  firstName?: string;
  isAssignedToApprovers?: boolean;
  lastName?: string;
  orgUnit?: B2BUnit;
  password?: string;
  phone?: string;
  titleCode?: string;
  uid?: string;
}

export interface OrgCustomerShipToAssignment {
  assignedShipTos?: Array<{ key: string; value: string[] }>;
}

export interface OrgUnitUserPage {
  pagination?: Pagination;
  results?: B2BUser[];
  sorts?: Sort[];
}

export import SimpleCustomer = Occ.SimpleCustomer;

export import SimpleCustomerPage = Occ.SimpleCustomerPage;

export import ShipToList = Occ.ShipToList;

export interface SoldToList {
  soldTos?: SoldTo[];
}

export import ShipTo = Occ.ShipTo;

export import UnloadingPoint = Occ.UnloadingPoint;
