import { MemoizedSelector, createSelector } from '@ngrx/store';
import { GlobalMessageSelectors } from '@spartacus/core';
import { Translatable } from '../../../i18n/translatable';
import { GlobalMessageType } from '../../models/global-message.model';
import { StateWithGlobalMessage } from '../global-message-state';

export const getGlobalMessageEntityByUid = (
  type: GlobalMessageType,
  uid: string
): MemoizedSelector<StateWithGlobalMessage, Translatable> => {
  return createSelector(
    GlobalMessageSelectors.getGlobalMessageEntitiesByType(type),
    (entities) => entities && entities.find((entity) => entity.uid === uid)
  );
};
