import { Injectable } from '@angular/core';
import {
  SemanticPathService as CxSemanticPathService,
  RoutingConfigService,
  UrlCommandRoute,
  UrlParsingService,
} from '@spartacus/core';
import { take } from 'rxjs/operators';
import { TranslationService } from '../../../i18n/services';

@Injectable({ providedIn: 'root' })
export class SemanticPathService extends CxSemanticPathService {
  private cxGenerateUrlPart: (command: UrlCommandRoute) => string[] | null;

  constructor(
    protected routingConfigService: RoutingConfigService,
    protected urlParser: UrlParsingService,
    protected translationService: TranslationService
  ) {
    super(routingConfigService, urlParser);

    // This is an ugly workaround because we have modified a private method.
    // It would be easier if it was marked 'protected' upstream, but here we are.
    this.cxGenerateUrlPart = (this as any).generateUrlPart;
    (this as any).generateUrlPart = this.pyGenerateUrlPart;
  }

  private pyGenerateUrlPart(command: UrlCommandRoute): string[] | null {
    const result = this.cxGenerateUrlPart(command);
    if (result) {
      const routeConfig = this.routingConfigService.getRouteConfig(command.cxRoute);

      if (!!routeConfig.translationKey) {
        return this.translateFirstSegment(routeConfig.translationKey, result);
      }
    }

    return result;
  }

  private translateFirstSegment(translationKey: string, segments: string[]): string[] {
    let replaceWith: string;

    this.translationService
      .translate(translationKey)
      .pipe(take(1))
      .subscribe((value) => (replaceWith = value));

    // Use translation from cache if not immediately available from above
    if (!replaceWith) {
      replaceWith = this.translationService.translateFromCache(translationKey);
    }

    if (!!replaceWith) {
      return [replaceWith, ...segments.slice(1)];
    }

    return segments;
  }
}
