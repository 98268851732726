import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { CheckboxSliderComponent } from './checkbox-slider.component';

@NgModule({
  declarations: [CheckboxSliderComponent],
  imports: [CommonModule, ReactiveFormsModule, NzIconModule, NgbTooltipModule],
  exports: [CheckboxSliderComponent],
})
export class CheckboxSliderModule {}
