import { UserActions } from '../actions/index';
import { ResetPasswordActionTypes } from '../actions/reset-password.action';

export const initialState = false;

export function reducer(state = initialState, action: UserActions.ResetPasswordAction): boolean {
  switch (action.type) {
    case ResetPasswordActionTypes.ResetPasswordSuccess: {
      return true;
    }
    case ResetPasswordActionTypes.ResetPasswordReset: {
      return false;
    }
  }
  return state;
}
