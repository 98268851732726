import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, take, withLatestFrom } from 'rxjs/operators';
import { AvailableSoldTosSearchParams, SoldTo } from '../../../core/model';
import { shallowEqualArrays, shallowEqualObjects } from '../../../core/util';
import { SoldToFacade } from '../../../features/sold-to-base';
import { infinitelyScrolling } from '../../utils/infinitely-scrolling';

@Component({
  selector: 'py-sold-to-selector',
  templateUrl: './sold-to-selector.component.html',
  styleUrls: ['./sold-to-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SoldToSelectorComponent implements OnInit, OnDestroy {
  @ViewChild('dropdown', { static: false }) dropdown: NgbDropdown;

  @Input()
  soldTo: string;

  @Input()
  label?: string;

  @Input()
  position?: string[] = ['auto'];

  @Input()
  showSingleResult?: boolean = false;

  @Input() type?: string = 'select-sold-to';

  @Input() showActiveSoldToName?: boolean = false;

  @Output()
  update = new EventEmitter<string>();

  form = new UntypedFormGroup({
    text: new UntypedFormControl(''),
  });

  availableSoldTos$: Observable<SoldTo[]>;
  availableSoldTosLoading$: Observable<boolean>;
  availableSearchResultEntitiesCount$: Observable<number>;
  currentPage$: Observable<number>;

  private subscriptions = new Subscription();

  constructor(private soldToService: SoldToFacade) {}

  ngOnInit(): void {
    this.availableSearchResultEntitiesCount$ = this.soldToService.getAvailableSearchResultEntitiesCount();
    this.availableSoldTosLoading$ = this.soldToService.getAvailableSoldTosLoading();

    const searchParametersForAvailableSoldTos$: Observable<AvailableSoldTosSearchParams> = this.soldToService
      .getSearchParametersForAvailableSoldTos()
      .pipe(distinctUntilChanged(shallowEqualObjects), shareReplay({ bufferSize: 1, refCount: true }));

    this.currentPage$ = searchParametersForAvailableSoldTos$.pipe(
      map((params) => params.page),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    const availableSoldTos$: Observable<SoldTo[]> = this.soldToService
      .getAvailableSoldTos()
      .pipe(distinctUntilChanged(shallowEqualArrays));

    this.availableSoldTos$ = infinitelyScrolling(availableSoldTos$, this.currentPage$, 'uid').pipe(
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.subscriptions.add(
      searchParametersForAvailableSoldTos$.subscribe((searchParams) => {
        this.soldToService.searchForAvailableSoldTos(searchParams);
      })
    );

    this.subscriptions.add(
      this.form
        .get('text')
        .valueChanges.pipe(debounceTime(400))
        .subscribe((value: string) => {
          this.triggerSearch(value);
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  changeSoldTo(soldTo: SoldTo) {
    if (this.showActiveSoldToName) {
      this.label = soldTo.name;
    }
    this.soldTo = soldTo.uid;
    this.update.emit(soldTo.uid);
    this.dropdown?.close();
  }

  dropdownOpenChange($event) {
    if ($event) {
      this.triggerSearch();
    } else {
      this.form.get('text').setValue('', { emitEvent: false });
    }
  }

  fetchMoreSoldTos() {
    this.soldToService
      .getAvailableSoldTosPagination()
      .pipe(
        take(1),
        withLatestFrom(this.currentPage$, this.availableSoldTosLoading$),
        filter(([pagination, currentPage, soldTosLoading]) => !soldTosLoading && currentPage + 1 < pagination.totalPages),
        map(([_pagination, currentPage]) => currentPage + 1)
      )
      .subscribe((page) => {
        this.triggerSearch(this.form.get('text').value, page);
      });
  }

  private triggerSearch(text?: string, page?: number) {
    this.soldToService.updateSearchParametersForAvailableSoldTos({
      key: text || 'latest',
      text,
      count: 25,
      page: page || 0,
    });
  }
}
