import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { EventService } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { LanguageSetEvent } from '../../../core/site-context';
import { WindowRef } from '../../../core/window';

@Directive({
  selector: '[pyNavSideScroll]',
})
export class NavSideScrollDirective implements AfterViewInit, OnChanges, OnDestroy {
  showShadowRight: boolean = false;
  showShadowLeft: boolean = false;

  readonly shadowClassRight = 'show-shadow-right';
  readonly shadowClassLeft = 'show-shadow-left';

  @Input() listCount: number;
  @Input() skipNavScrollArrows?: boolean = false;

  subscriptions = new Subscription();

  constructor(
    private el: ElementRef,
    private cd: ChangeDetectorRef,
    private eventService: EventService,
    protected winRef: WindowRef
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit(): void {
    if (this.winRef.isBrowser()) {
      this.el.nativeElement?.parentNode?.classList?.add('position-relative');

      setTimeout(() => {
        if (!this.skipNavScrollArrows) {
          this.addArrows();
        }
        this.setShowShadow();
        this.cd.detectChanges();
      }, 1000);

      this.subscriptions.add(
        this.eventService.get(LanguageSetEvent).subscribe(() => {
          this.updateShowArrow();
        })
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.listCount?.currentValue && changes.listCount?.previousValue) {
      this.updateShowArrow();
    }
  }

  updateShowArrow(): void {
    if (this.winRef.isBrowser()) {
      setTimeout(() => {
        this.setShowShadow();
        this.cd.markForCheck();
      }, 200);
    }
  }

  @HostListener('click', ['$event'])
  onMouseDown(event) {
    if (event.target?.className?.includes('arrow-left')) {
      this.scroll(-100, event);
    } else if (event.target?.className?.includes('arrow-right')) {
      this.scroll(100, event);
    }
  }

  private scroll(movementX: number, event: Event, smooth: boolean = true) {
    event.preventDefault();
    this.el.nativeElement.scrollBy({
      left: movementX,
      behavior: smooth ? 'smooth' : 'instant',
    });
  }

  @HostBinding('class')
  get elementClass(): string {
    const classes = [];

    if (this.showShadowRight) {
      classes.push(this.shadowClassRight);
    }

    if (this.showShadowLeft) {
      classes.push(this.shadowClassLeft);
    }

    return classes.join(' ');
  }

  @HostListener('scroll', ['$event'])
  setShowShadow(): void {
    const seenSpace = this.el.nativeElement.scrollLeft + this.el.nativeElement.offsetWidth;
    const totalSpace = this.el.nativeElement.scrollWidth;
    const spaceLeftToSee = totalSpace - seenSpace;

    this.showShadowRight = spaceLeftToSee > 10;
    this.showShadowLeft = this.el.nativeElement.scrollLeft > 10;
  }

  @HostListener('wheel', ['$event'])
  onScrollByMouseWheel(event: WheelEvent): void {
    this.scroll(event.deltaY, event, false);
  }

  @HostListener('mousedown', ['$event'])
  onScrollByMouseDrag(event: MouseEvent) {
    if (event.button !== 0) {
      return;
    }

    const CLICK_MOVEMENT_THRESHOLD = 5;
    let totalMovementX = 0;

    const mouseMoveCallback = (event: MouseEvent) => {
      this.scroll(-event.movementX, event, false);
      totalMovementX += Math.abs(event.movementX);
      if (totalMovementX > CLICK_MOVEMENT_THRESHOLD) {
        this.el.nativeElement.classList.add('dragging');
      }
    };

    const mouseUpCallback = () => {
      document.removeEventListener('mousemove', mouseMoveCallback);
      document.removeEventListener('mouseup', mouseUpCallback);
      this.el.nativeElement.classList.remove('dragging');
    };

    this.winRef.document.addEventListener('mousemove', mouseMoveCallback);
    this.winRef.document.addEventListener('mouseup', mouseUpCallback);
  }

  private addArrows() {
    const arrowLeft = document.createElement('div');
    const arrowRight = document.createElement('div');
    const arrowLeftBackground = document.createElement('div');
    const arrowRightBackground = document.createElement('div');

    arrowLeft.appendChild(arrowLeftBackground);
    arrowRight.appendChild(arrowRightBackground);
    arrowLeft.setAttribute('class', 'arrow-left');
    arrowRight.setAttribute('class', 'arrow-right');
    arrowLeftBackground.setAttribute('class', 'arrow-left-background');
    arrowRightBackground.setAttribute('class', 'arrow-right-background');

    this.el.nativeElement?.prepend(arrowLeft);
    this.el.nativeElement?.prepend(arrowLeftBackground);
    this.el.nativeElement?.appendChild(arrowRight);
    this.el.nativeElement?.appendChild(arrowRightBackground);
  }
}
