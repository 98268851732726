import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

export enum FileUploadHeaderType {
  STANDARD = 'STANDARD',
  USER_HEADER = 'USER_HEADER',
}
@Component({
  selector: 'py-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  @ViewChild('fileDropRef') fileDropRef: ElementRef;
  files: File[] = [];
  disabled: boolean = false;

  @Input() multiple = false;
  @Input() accept?: string;
  @Input() templateUrl?: string;
  @Input() headerType?: FileUploadHeaderType = FileUploadHeaderType.STANDARD;
  @Input() displayCustomContent?: boolean = false;
  @Input() removeFiles?: boolean = false;
  @Input() removeAllFilesEvent$?: Subject<void> = new Subject();
  @Input() showDownloadTemplateButton?: boolean = false;

  @Output() emitFiles: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadTemplate: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() dropFileEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() deleteFileEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}
  private subscriptions = new Subscription();

  ngOnInit(): void {
    this.subscriptions.add(this.removeAllFilesEvent$.subscribe(() => this.removeAllFiles()));
  }

  onDisabled(): boolean {
    this.disabled = !this.multiple && this.files.length > 0;
    return this.disabled;
  }

  onFileDropped($event) {
    if (!this.disabled) {
      this.dropFileEvent.emit(true);
      this.prepareFilesList($event);
    }
  }

  fileBrowseHandler(files) {
    this.dropFileEvent.emit(true);
    this.prepareFilesList(files);
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.deleteFileEvent.emit();
  }

  onDownloadTemplate() {
    this.downloadTemplate.emit(true);
  }

  prepareFilesList(files: File[]) {
    for (const item of files) {
      this.files.push(item);
      this.emitFiles.emit(this.files);

      if (!!this.fileDropRef) {
        this.fileDropRef.nativeElement.value = '';
      }
    }

    if (this.removeFiles) {
      this.files = [];
    }
  }

  private removeAllFiles() {
    this.files = [];
    this.disabled = false;
  }
}
