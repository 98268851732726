import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { ORDER_CORE_FEATURE } from './store/order-state';

@NgModule({
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        [ORDER_CORE_FEATURE]: {
          module: () => import('./order-base.module').then((m) => m.OrderBaseModule),
        },
      },
    }),
  ],
})
export class OrderBaseFeatureModule {}
