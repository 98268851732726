import { MemoizedSelector, MemoizedSelectorWithProps, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { PriceFileRequestTemplate } from '../../../../model/price-file.model';
import { ArrayState, VirtualPaginationState } from '../../../store/base-feature-state';
import { PriceFileState, StateWithPriceFile } from '../price-file-state';
import { getPriceFileState } from './feature.selector';

export const getPriceFileRequestTemplatesLoader: MemoizedSelector<
  StateWithPriceFile,
  StateUtils.LoaderState<VirtualPaginationState<PriceFileRequestTemplate>>
> = createSelector(getPriceFileState, (state: PriceFileState) => state && state.templates.entities);

export const getPriceFileRequestTemplatesResultState: MemoizedSelector<
  StateWithPriceFile,
  ArrayState<PriceFileRequestTemplate>
> = createSelector(
  getPriceFileRequestTemplatesLoader,
  (state: StateUtils.LoaderState<ArrayState<PriceFileRequestTemplate>>) => state && StateUtils.loaderValueSelector(state)
);

export const getPriceFileRequestTemplates: MemoizedSelector<StateWithPriceFile, Array<PriceFileRequestTemplate>> = createSelector(
  getPriceFileRequestTemplatesResultState as any,
  (state: VirtualPaginationState<PriceFileRequestTemplate>) => {
    return state && state.results;
  }
);

export const getPriceFileRequestTemplate: MemoizedSelectorWithProps<StateWithPriceFile, any, PriceFileRequestTemplate> =
  createSelector(
    getPriceFileRequestTemplatesResultState as any,
    (state: VirtualPaginationState<PriceFileRequestTemplate>, props: any) => {
      return state && state.results && state.results.filter((res) => res.code === props.code && res.selectedCategories)[0];
    }
  );

export const getPriceFileRequestTemplatesLoading: MemoizedSelector<StateWithPriceFile, boolean> = createSelector(
  getPriceFileRequestTemplatesLoader,
  (state: StateUtils.LoaderState<ArrayState<PriceFileRequestTemplate>>) => state && state.loading
);

export const getPriceFileTemplateLoader: MemoizedSelector<
  StateWithPriceFile,
  StateUtils.LoaderState<PriceFileRequestTemplate>
> = createSelector(getPriceFileState, (state: PriceFileState) => state && state.templates.template);

export const getPriceFileTemplateSuccess: MemoizedSelector<StateWithPriceFile, boolean> = createSelector(
  getPriceFileTemplateLoader,
  (state: StateUtils.LoaderState<PriceFileRequestTemplate>) => state && StateUtils.loaderSuccessSelector(state)
);

export const getPriceFileTemplateValue: MemoizedSelector<StateWithPriceFile, PriceFileRequestTemplate> = createSelector(
  getPriceFileTemplateLoader,
  (state: StateUtils.LoaderState<PriceFileRequestTemplate>) => state && StateUtils.loaderValueSelector(state)
);
