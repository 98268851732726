import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        purchaseLimitsAdmin: {
          module: () => import('./admin/purchase-limits-admin.module').then((m) => m.CmsPurchaseLimitsAdminModule),
          dependencies: [
            () =>
              import('../../../features/purchase-limits-base/purchase-limits-base.module').then(
                (m) => m.PurchaseLimitsBaseModule
              ),
          ],
          cmsComponents: ['CmsPurchaseLimitsAdminComponent'],
        },
      },
    }),
  ],
})
export class PurchaseLimitsAdminFeatureModule {}
