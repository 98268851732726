import { Action } from '@ngrx/store';
import { UserActions as CxUserActions } from '@spartacus/core';
import { ConsentTemplate } from '../../../model/consent.model';

export const initialState: ConsentTemplate[] = [];

export function reducer(state = initialState, action: Action): ConsentTemplate[] {
  switch (action.type) {
    case CxUserActions.LOAD_USER_CONSENTS_SUCCESS: {
      const _action = <CxUserActions.LoadUserConsentsSuccess>action;
      const consents = _action.payload;
      return consents ? consents : initialState;
    }
    case CxUserActions.GIVE_USER_CONSENT_SUCCESS: {
      const _action = <CxUserActions.GiveUserConsentSuccess>action;
      const updatedConsentTemplate = _action.consentTemplate;
      return state.map((consentTemplate) =>
        consentTemplate.id === updatedConsentTemplate.id ? updatedConsentTemplate : consentTemplate
      );
    }
  }

  return state;
}
