import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PriceFileRequestTemplate, PriceFileRequestTemplateSearchResult } from '../../../model/price-file.model';

@Injectable({
  providedIn: 'root',
})
export class PriceFileRequestTemplateConnector {
  constructor(private httpClient: HttpClient, private occEndpointsService: OccEndpointsService) {}

  load(userId: string): Observable<PriceFileRequestTemplateSearchResult> {
    return this.httpClient
      .get<PriceFileRequestTemplateSearchResult>(
        this.occEndpointsService.buildUrl('priceFileTemplates', {
          urlParams: { userId: userId },
          queryParams: {
            fields: 'FULL',
            page: 0,
            count: 9999,
          },
        })
      )
      .pipe(
        map((d) => {
          return { results: [...d['templates']] || [] };
        })
      );
  }

  loadTemplate(userId: string, code: string): Observable<PriceFileRequestTemplate> {
    return this.httpClient.get<PriceFileRequestTemplate>(
      this.occEndpointsService.buildUrl('priceFileTemplate', {
        urlParams: {
          userId,
          code,
        },
      })
    );
  }

  save(userId: string, payload: PriceFileRequestTemplate): Observable<PriceFileRequestTemplate> {
    if (payload.code) {
      return this.httpClient.put<PriceFileRequestTemplate>(
        this.occEndpointsService.buildUrl('priceFileTemplateUpdate', {
          urlParams: {
            userId: userId,
            code: payload.code,
          },
        }),
        payload
      );
    } else {
      return this.httpClient.post<PriceFileRequestTemplate>(
        this.occEndpointsService.buildUrl('priceFileTemplates', { urlParams: { userId: userId } }),
        payload
      );
    }
  }

  delete(userId: string, code: string): Observable<any> {
    return this.httpClient.delete(
      this.occEndpointsService.buildUrl('priceFileTemplateDelete', {
        urlParams: {
          userId,
          code,
        },
      })
    );
  }
}
