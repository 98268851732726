import { CxEvent } from '@spartacus/core';
import { UserSelectContentTypes } from '../../model/user.model';

export class UpdateCredentialsSuccessEvent extends CxEvent {
  static readonly type = 'UpdateCredentialsSuccessEvent';
}

export class RemoveShoppingListEntriesSuccess extends CxEvent {
  static readonly type = 'RemoveShoppingListEntriesSuccess';
}

export class UserHeaderMenuNavigationEvent extends CxEvent {
  static readonly type = 'UserHeaderMenuNavigationEvent';

  constructor(public items: string[]) {
    super();
  }
}

export class UserCustomAnalyticsEvent extends CxEvent {
  static readonly type = 'UserCustomEvent';

  constructor(public code: string, public parameters: { [key: string]: string }) {
    super();
  }
}

export class UserDropdownEvent extends CxEvent {
  static readonly type = 'UserDropdownEvent';

  constructor(public items: string[]) {
    super();
  }
}

export class UserCatalogTabTypeEvent extends CxEvent {
  static readonly type = 'UserCatalogTabTypeEvent';

  constructor(public items: string[]) {
    super();
  }
}

export class UserSelectContentEvent extends CxEvent {
  static readonly type = 'UserSelectContentEvent';

  constructor(public target: HTMLElement, public type: UserSelectContentTypes, public id: string) {
    super();
  }
}

export class SelectEcommerceItemEvent extends CxEvent {
  static readonly type = 'SelectEcommerceItemEvent';

  constructor(public ticket?: string) {
    super();
  }
}

export class ViewMostOrderedArticlesEvent extends CxEvent {
  static readonly type = 'ViewMostOrderedArticlesEvent';

  constructor(public articleRefs: string[]) {
    super();
  }
}

export class ViewLastOrderedArticlesEvent extends CxEvent {
  static readonly type = 'ViewLastOrderedArticlesEvent';

  constructor(public articleRefs: string[]) {
    super();
  }
}

export class ViewCatalogArticlesEvent extends CxEvent {
  static readonly type = 'ViewCatalogArticlesEvent';

  constructor(public articleRefs: string[]) {
    super();
  }
}

export class ExecuteRecaptchaEvent extends CxEvent {
  static readonly type = 'ExecuteRecaptcha';

  constructor() {
    super();
  }
}

export class RecaptchaCompleteEvent extends CxEvent {
  static readonly type = 'RecaptchaCompleteEvent';

  constructor(public ref: string) {
    super();
  }
}
