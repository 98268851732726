import { StateUtils } from '@spartacus/core';
import { AddonGroup } from '../../../../model';
import { AddonGroupEntries } from '../../../store/catalog-state';
import { AddonGroupsActions } from '../actions';
import { AddonGroupActionTypes, LoadAddonGroupSuccess, LoadAddonGroupsSuccess } from '../actions/addon-group.action';

export const initialState: AddonGroupEntries = {
  entities: undefined,
  tree: [],
};
export function reducer(
  state = initialState,
  action: AddonGroupsActions.AddonGroupsActions | StateUtils.LoaderAction
): AddonGroupEntries {
  function unpackPayloadEntities(categories: AddonGroup[]): StateUtils.EntityState<AddonGroup> {
    return {
      entities: categories.reduce(
        (entities: { [id: string]: AddonGroup }, addonGroup: AddonGroup) => {
          return {
            ...entities,
            [addonGroup.id]: {
              ...addonGroup,
            },
          };
        },
        {
          // Always replace since this is loaded once
        }
      ),
    };
  }

  function getAddonGroups(addonGroups: AddonGroup[]): AddonGroup[] {
    const addonGroupList = new Array<AddonGroup>();
    addonGroups.forEach((addonGroup) => {
      if (!!addonGroup.children) {
        addonGroupList.push(...getAddonGroups(addonGroup.children));
      }
      addonGroupList.push(addonGroup);
    });
    return addonGroupList;
  }

  function updateAddonGroup(entities: { [id: string]: AddonGroup }, group: AddonGroup): StateUtils.EntityState<AddonGroup> {
    return {
      entities: { ...entities, [group.id]: { ...group } },
    };
  }

  function updateAddonGroupInTree(tree: AddonGroup[], group: AddonGroup): AddonGroup[] {
    return tree.map((currentGroup) => {
      if (currentGroup.id === group.id) {
        return group;
      }
      return currentGroup;
    });
  }

  switch (action.type) {
    case AddonGroupActionTypes.LoadAddonGroupsSuccess: {
      const successAction = <LoadAddonGroupsSuccess>action;
      const addonGroups = getAddonGroups(successAction.payload);
      return {
        ...state,
        ...unpackPayloadEntities(addonGroups),
        ...{ tree: successAction.payload },
      };
    }

    case AddonGroupActionTypes.LoadAddonGroupSuccess: {
      const successAction = <LoadAddonGroupSuccess>action;
      const group = successAction.payload;
      return {
        ...state,
        ...updateAddonGroup(state.entities, group),
        ...{ tree: updateAddonGroupInTree([...state.tree], group) },
      };
    }
  }
  return state;
}
