import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { ContentTag } from '../../../../core/model';
import { PyStateUtils } from '../../../../core/state/utils';
import { CMS_CONTENT_TAG_FEATURE, CmsContentTagState, StateWithCmsContentTag } from '../cms-content-tag-state';

export const getCmsContentTagsState: MemoizedSelector<StateWithCmsContentTag, CmsContentTagState> =
  createFeatureSelector<CmsContentTagState>(CMS_CONTENT_TAG_FEATURE);

export const getContentTagsState: MemoizedSelector<
  StateWithCmsContentTag,
  StateUtils.EntityLoaderState<ContentTag>
> = createSelector(getCmsContentTagsState, (state: CmsContentTagState) => state.contentTags);

export const getSelectedContentTagState = (
  uid: string
): MemoizedSelector<StateWithCmsContentTag, StateUtils.LoaderState<ContentTag>> => {
  return createSelector(getContentTagsState, (state) => PyStateUtils.entityLoaderStateSelector(state, uid));
};

export const getSelectedContentTag = (uid: string): MemoizedSelector<StateWithCmsContentTag, ContentTag> => {
  return createSelector(getSelectedContentTagState(uid), (state) => StateUtils.loaderValueSelector(state));
};
