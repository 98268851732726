import { Injectable } from '@angular/core';
import { EntitiesModel, Permission } from '../../../../model';
import { PERMISSION_NORMALIZER } from '../../../../permission/connectors';
import { Converter, ConverterService } from '../../../../util/converter.service';

@Injectable({
  providedIn: 'root',
})
export class OccPermissionListNormalizer implements Converter<any, EntitiesModel<Permission>> {
  constructor(private converter: ConverterService) {}

  convert(source: any, target?: EntitiesModel<Permission>): EntitiesModel<Permission> {
    if (target === undefined) {
      target = { ...(source as any) };
    }

    target.values = (source.orderPermissions || source.results || []).map((permission) => ({
      ...this.converter.convert(permission, PERMISSION_NORMALIZER),
    }));

    return target;
  }
}
