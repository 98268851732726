import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import {
  Cart,
  CartSimulation,
  CartType,
  CheckoutKey,
  LoaderError,
  Order,
  OrderEntry,
  OrderMergeProposal,
  ResolveOptionActionType,
} from '../../../../core/model';
import { CHECKOUT_CORE_FEATURE, LastFullPalletUpgradeEntry } from '../store/checkout-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: CheckoutFacade,
      feature: CHECKOUT_CORE_FEATURE,
      async: true,
      methods: [
        'isCheckoutEnabled',
        'initCheckout',
        'getCart',
        'placeOrder',
        'getOrder',
        'getPlaceOrderLoading',
        'getPlaceOrderLoaded',
        'getPlaceOrderError',
        'resetPlaceOrderState',
        'simulateCart',
        'getCartSimulation',
        'getEarliestDeliveryDate',
        'getSimulateCartLoading',
        'getSimulateCartLoaded',
        'getSimulateCartError',
        'resetSimulateCartState',
        'resolve',
        'getResolveLoading',
        'getResolveLoaded',
        'getResolveError',
        'resetResolveState',
        'getHolidays',
        'isOrderReviewed',
        'setOrderReviewed',
        'setLastFullPalletUpgrade',
        'resetLastFullPalletUpgrade',
        'getLastFullPalletUpgrade',
        'hasActiveOrderProcess',
        'getActiveOrderProcess',
        'resumeActiveOrderProcess',
        'cancelActiveOrderProcess',
        'clearCheckoutSteps',
      ],
    }),
})
export abstract class CheckoutFacade {
  abstract isCheckoutEnabled(cartType: CartType): Observable<boolean>;
  abstract initCheckout(cartType: CartType): void;
  abstract getCart(key: CheckoutKey): Observable<Cart | undefined>;
  abstract placeOrder(key: CheckoutKey, orderToMergeWith?: OrderMergeProposal): void;
  abstract getOrder(key: CheckoutKey): Observable<Order | undefined>;
  abstract getPlaceOrderLoading(key: CheckoutKey): Observable<boolean>;
  abstract getPlaceOrderLoaded(key: CheckoutKey): Observable<boolean>;
  abstract getPlaceOrderError(key: CheckoutKey): Observable<LoaderError | undefined>;
  abstract resetPlaceOrderState(key: CheckoutKey): void;
  abstract simulateCart(key: CheckoutKey): void;
  abstract getCartSimulation(key: CheckoutKey): Observable<CartSimulation | undefined>;
  abstract getEarliestDeliveryDate(key: CheckoutKey): Observable<Date | undefined>;
  abstract getSimulateCartLoading(): Observable<boolean>;
  abstract getSimulateCartLoaded(): Observable<boolean>;
  abstract getSimulateCartError(): Observable<LoaderError | undefined>;
  abstract resetSimulateCartState(): void;
  abstract resolve(entry: OrderEntry, type: ResolveOptionActionType, key: CheckoutKey): void;
  abstract getResolveLoading(): Observable<boolean>;
  abstract getResolveLoaded(): Observable<boolean>;
  abstract getResolveError(): Observable<LoaderError>;
  abstract resetResolveState(): void;
  abstract getHolidays(): Observable<Date[]>;
  abstract isOrderReviewed(key: CheckoutKey): Observable<boolean>;
  abstract setOrderReviewed(key: CheckoutKey, isReviewed?: boolean): void;
  abstract setLastFullPalletUpgrade(quantityAdded: number, entry: OrderEntry, key: CheckoutKey): void;
  abstract resetLastFullPalletUpgrade(key: CheckoutKey): void;
  abstract getLastFullPalletUpgrade(key: CheckoutKey): Observable<LastFullPalletUpgradeEntry | undefined>;
  abstract hasActiveOrderProcess(): Observable<boolean>;
  abstract getActiveOrderProcess(): Observable<Order>;
  abstract resumeActiveOrderProcess(): Observable<boolean>;
  abstract cancelActiveOrderProcess(orderCode?: string): Observable<boolean>;
  abstract clearCheckoutSteps(key: CheckoutKey): void;
}
