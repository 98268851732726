import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CartMessageModalModule } from './components/cart-message-modal/cart-message-modal.module';
import { RemainingCartMessageModalModule } from './components/remaining-cart-message-modal';
import { CartMessageComponent } from './container/cart-message.component';

@NgModule({
  imports: [CommonModule, CartMessageModalModule, RemainingCartMessageModalModule],
  declarations: [CartMessageComponent],
  exports: [CartMessageComponent],
})
export class CartMessageModule {}
