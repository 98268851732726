<ng-container *ngIf="!loading; else loadingTemplate">
  <py-article-carousel
    [carouselItems]="(carouselItems$ | async) || []"
    [substituteRefs]="substituteRefs"
    [disabled]="!canReplace"
    [loading]="!canReplace"
    [hideOverflow]="true"
    [actionLabel]="isCartWriter ? 'common.chooseAndReplace_action' : 'product.addToCart_action'"
    [iconType]="!isCartWriter ? 'shopping-cart' : ''"
    [tooltipMessage]="
      isCartWriter ? ('common.chooseAndReplace_tooltip' | cxTranslate) : ('product.addToCart_tooltip' | cxTranslate)
    "
    [enableSecondaryArticleRowVariant]="enableSecondaryArticleRowVariant"
    (articleInteraction)="isCartWriter ? onReplace($event) : onAddToCart($event)"
  >
    <h6>{{ 'cart.replacementAlternatives_title' | cxTranslate }}</h6>
  </py-article-carousel>
</ng-container>

<ng-template #loadingTemplate>
  <div class="skeleton py-6"></div>
</ng-template>
