<button
  [ngbTooltip]="'catalog.addToShoppingList_placeholder' | cxTranslate"
  [disableTooltip]="disableTooltip"
  #popOver="ngbPopover"
  class="btn btn-ternary btn-icon"
  [class.open]="popOver.isOpen()"
  [ngbPopover]="popContent"
  [popoverClass]="'add-to-shopping-list-popover'"
  [autoClose]="'outside'"
  [placement]="placement"
  [disabled]="!articleCode || !inEcommerceQuantity || disabled"
  [disablePopover]="!(isUserLoggedIn$ | async) || !articleCode || !inEcommerceQuantity || disabled"
  (click)="onOpen()"
  container="body"
>
  <i nz-icon nzType="heart"></i>
  <span class="pl-2" *ngIf="showButton">{{ 'catalog.addToShoppingList_action' | cxTranslate }}</span>
</button>

<ng-template #popContent>
  <div class="header d-flex align-items-center justify-content-between p-3">
    <h6>{{ 'catalog.addToShoppingList_action' | cxTranslate }}</h6>
    <button type="button" class="close" [attr.aria-label]="'common.close_action' | cxTranslate" (click)="closePopover()">
      <i nz-icon pyIconThemeReverse nzType="close-circle" nzTheme="outline"></i>
    </button>
  </div>
  <button class="new-list-button my-2 mx-4 d-flex align-items-center" (click)="openModal()">
    <i nz-icon nzType="plus" class="mr-3"></i>
    <span>{{ 'shoppingLists.createNewList_action' | cxTranslate }}</span>
  </button>
  <ng-container *ngIf="{ loading: loading$ | async, items: items$ | async } as observables">
    <ul
      *ngIf="observables.items"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScroll()"
      [scrollWindow]="false"
      class="hide-scrollbar"
    >
      <ng-container *ngIf="showListItems">
        <li class="py-2 px-4 d-flex justify-content-between align-items-center text-break" *ngFor="let item of observables.items">
          {{ item.name }}
          <button class="btn btn-icon ml-2 p-0" (click)="addEntry(item.code)">
            <i nz-icon nzType="plus" nzTheme="outline"></i>
          </button>
        </li>
      </ng-container>
      <li class="py-2 px-4 d-flex justify-content-center align-items-center" *ngIf="observables.loading">
        <cx-spinner></cx-spinner>
      </li>
    </ul>
  </ng-container>
</ng-template>
