<div class="container">
  <div class="row">
    <div class="col d-flex align-items-start" [ngClass]="{ 'align-items-center': !body }">
      <ng-container *pyScreenSize="{ min: 'md' }">
        <ng-container *ngTemplateOutlet="status"></ng-container>
      </ng-container>
      <div class="flex-grow-1">
        <div class="d-flex align-items-center">
          <ng-container *pyScreenSize="{ max: 'sm' }">
            <ng-container *ngTemplateOutlet="status"></ng-container>
          </ng-container>
          <h5 class="flex-grow-1">{{ title }}</h5>
          <ng-container *ngIf="dismissable">
            <button class="ml-2" [attr.aria-label]="'common.close_action' | cxTranslate" (click)="onDismiss()">
              <i nz-icon nzType="close"></i>
            </button>
          </ng-container>
        </div>
        <div *ngIf="body" class="mt-2 flex-grow-1">
          <ng-container *ngIf="isBodyTemplate; else paragraphTemplate">
            <ng-container *ngTemplateOutlet="body"></ng-container>
          </ng-container>
          <ng-template #paragraphTemplate>
            <p>{{ body }}</p>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #status>
  <py-alert-message-status class="mr-2 mr-md-4" [type]="type"></py-alert-message-status>
</ng-template>
