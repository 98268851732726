<ul *ngIf="messages$ | async as messages" class="container">
  <li *ngFor="let message of messages" class="message-holder">
    <div class="message" [ngClass]="message.status">
      <i nz-icon class="reset status" [nzType]="message.icon" nzTheme="fill"></i>
      <div class="w-100">
        <p>{{ message.text | cxTranslate }}</p>
        <ul *ngIf="message.details">
          <li *ngFor="let detail of message.details">{{ detail }}</li>
        </ul>
      </div>
      <button [attr.aria-label]="'common.close_action' | cxTranslate" (click)="onClose(message)">
        <i nz-icon class="reset" nzType="close" nzTheme="outline"></i>
      </button>
    </div>
  </li>
</ul>
