<ng-container
  *ngIf="{
    articlePrice: articlePrice$ | async,
    articlePriceLoading: articlePriceLoading$ | async,
    quantityAndUnitValue: quantityAndUnitValue$ | async,
    initialMinimumQuantity: initialMinimumQuantity$ | async,
    isAddToCartInProgress: isAddToCartInProgress$ | async,
    disableActionButtons: disableActionButtons$ | async
  } as data"
>
  <py-detailed-stock-info
    *ngIf="carouselActionMode === carouselActionModes.ADD_TO_CART_MODE && !!article"
    class="mb-3"
    [small]="true"
    [article]="article"
    [quantity]="data.quantityAndUnitValue.quantity"
    [unitCode]="data.quantityAndUnitValue.unitCode"
    [showStockInfoButton]="false"
  ></py-detailed-stock-info>

  <py-quantity-and-unit-selector
    *ngIf="carouselActionMode === carouselActionModes.ADD_TO_CART_MODE && !!article"
    class="mb-3"
    [article]="article"
    [values]="data.quantityAndUnitValue"
    [initialMinimumQuantity]="data.initialMinimumQuantity"
    [container]="'body'"
    (valueChanges)="updateQuantityAndUnit($event)"
  ></py-quantity-and-unit-selector>

  <div
    class="d-flex flex-row"
    [ngClass]="carouselActionMode === carouselActionModes.ADD_TO_CART_MODE ? 'justify-content-between' : 'justify-content-end'"
  >
    <button
      *ngIf="carouselActionMode === carouselActionModes.ADD_TO_CART_MODE"
      class="btn btn-primary btn-icon d-flex align-self-start add-to-cart-button"
      (click)="addToCart()"
      [disabled]="data.disableActionButtons || data.isAddToCartInProgress"
    >
      <i *ngIf="!data.isAddToCartInProgress" nz-icon nzType="shopping-cart" nzTheme="outline"></i>
      <cx-spinner *ngIf="data.isAddToCartInProgress"></cx-spinner>
    </button>

    <py-catalog-article-price
      *ngIf="!!article"
      [article]="article"
      [quantityAndUnitValue]="data.quantityAndUnitValue"
      [articlePrice]="data.articlePrice"
      [loadingPrice]="data.articlePriceLoading"
      (loadPrice)="loadPrice()"
    ></py-catalog-article-price>
  </div>
</ng-container>
