import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { KeyboardFocusModule } from '@spartacus/storefront';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../core/i18n';
import { LaunchDialogService } from '../../../core/modal';
import { ModalModule } from '../../../shared/components/modal';
import { ActionButtonModule } from '../action-button';
import { NotificationModule } from '../notification';
import { ReactiveFormInputFieldModule } from '../reactive-forms-input/reactive-form-input-field/reactive-form-input-field.module';
import { palletFlagFormModalComponentlLayoutConfig } from './components/pallet-flag-form-modal/pallet-flag-form-modal-layout.config';
import { PalletFlagFormModalComponent } from './components/pallet-flag-form-modal/pallet-flag-form-modal.component';
import { PalletFlagFormComponent } from './components/pallet-flag-form/pallet-flag-form.component';
import { PalletFlagComponent } from './pallet-flag.component';

@NgModule({
  declarations: [PalletFlagComponent, PalletFlagFormModalComponent, PalletFlagFormComponent],
  imports: [
    CommonModule,
    I18nModule,
    NzIconModule,
    ActionButtonModule,
    ModalModule,
    ReactiveFormInputFieldModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    NotificationModule,
    KeyboardFocusModule,
  ],
  exports: [PalletFlagComponent],
})
export class PalletFlagModule {
  constructor(private launchDialogService: LaunchDialogService) {
    this.launchDialogService.registerModalLayoutConfig(palletFlagFormModalComponentlLayoutConfig);
  }
}
