import { Component, Input } from '@angular/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Article, OrderEntry, PrePrintedLabel } from '../../../../core/model';
import { PrePrintedModalData } from '../pre-printed-modal';

@Component({
  selector: 'py-pre-printed-button',
  templateUrl: './pre-printed-button.component.html',
  styleUrls: ['./pre-printed-button.component.scss'],
})
export class PrePrintedButtonComponent {
  @Input() article: Article;
  @Input() entry: OrderEntry;
  @Input() disabled: boolean = false;
  @Input() translationKeyPrefix: string = 'cart';
  @Input() showButton: boolean = false;

  constructor(private launchDialogService: LaunchDialogService) {}

  get hasPrePrintedLabel(): boolean {
    return !!this.entry?.prePrintedLabel?.code;
  }

  get prePrintedLabel(): PrePrintedLabel {
    return this.entry?.prePrintedLabel;
  }

  open(): void {
    const modalData: PrePrintedModalData = {
      articleTitle: this.article?.displayName,
      entry: this.entry,
    };

    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.PRE_PRINTED, undefined, modalData);
  }
}
