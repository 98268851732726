import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../core/i18n';
import { UrlModule } from '../../../core/routing';
import { ArticleCarouselModule } from '../article-carousel';
import { NotificationModule } from '../notification';
import { OverlayMessageModule } from '../overlay-message';
import { SpinnerModule } from '../spinner';
import { CatalogSimilarArticlesComponent } from './catalog-similar-articles.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    NzIconModule,
    NotificationModule,
    ArticleCarouselModule,
    OverlayMessageModule,
    RouterModule,
    UrlModule,
    SpinnerModule,
    NotificationModule,
  ],
  declarations: [CatalogSimilarArticlesComponent],
  exports: [CatalogSimilarArticlesComponent],
})
export class CatalogSimilarArticlesModule {}
