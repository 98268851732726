import { DIALOG_TYPE, LAUNCH_CALLER, LayoutConfig } from '@spartacus/storefront';
import { OrderReturnUploadedFilesGalleryModalComponent } from './order-return-uploaded-files-gallery-modal.component';

export const orderReturnUploadedFilesGalleryModalLayoutConfig: LayoutConfig = {
  launch: {
    [LAUNCH_CALLER.ORDER_RETURN_UPLOADED_FILES_GALLERY]: {
      inlineRoot: true,
      component: OrderReturnUploadedFilesGalleryModalComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};
