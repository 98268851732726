import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../../core/i18n';
import { GenericLinkModule, MegamenuModule } from '../../../../shared';
import { CampaignsSubNavigationModule } from '../campaigns-sub-navigation/campaigns-sub-navigation.module';
import { CategoriesNavigationModule } from '../categories-navigation/categories-navigation.module';
import { HeaderNavigationItemModule } from '../header-navigation-item';
import { MyAssortmentSubNavigationModule } from '../my-assortment-sub-navigation/my-assortment-sub-navigation.module';
import { SubAddonGroupNavigationModule } from '../sub-addon-group-navigation/sub-addon-group-navigation.module';
import { SubCategoriesNavigationModule } from '../sub-categories-navigation/sub-categories-navigation.module';
import { MenuProductsComponent } from './menu-products.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    HeaderNavigationItemModule,
    GenericLinkModule,
    NgbDropdownModule,
    NzIconModule,
    CategoriesNavigationModule,
    SubCategoriesNavigationModule,
    MyAssortmentSubNavigationModule,
    RouterModule,
    SubAddonGroupNavigationModule,
    CampaignsSubNavigationModule,
    MegamenuModule,
  ],
  declarations: [MenuProductsComponent],
  exports: [MenuProductsComponent],
})
export class MenuProductsModule {}
