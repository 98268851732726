<!-- 
Defer the view to prevent the loadmore button to load before puffs are rendered to avoid layout shifts. Best practice is not to use timer, viewport etc. for initially loading views https://angular.dev/guide/defer#avoiding-layout-shifts. But in this case it will help our CLS score alot. 
-->
@defer (on timer(2s)) {
<div *ngIf="showPagination && pagination" class="col-8 offset-2 col-sm-6 offset-sm-3 my-4 puff-collection-load-more">
  <py-load-more
    [totalItemCount]="pagination.totalCount"
    [totalPageCount]="pagination.totalPages"
    [currentPageNumber]="pagination.page - 1"
    [itemCount]="pagination.count"
    (dispatch)="setPage($event + 1)"
    [totalItemCountName]="'content.typeName_hint' | cxTranslate : { count: pagination.totalCount }"
  ></py-load-more>
</div>
}
