import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '../../../core/i18n';
import { DropdownModule } from '../dropdown';
import { ShowPerPageComponent } from './show-per-page.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, I18nModule, DropdownModule],
  declarations: [ShowPerPageComponent],
  exports: [ShowPerPageComponent],
})
export class ShowPerPageModule {}
