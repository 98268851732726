import { StateUtils } from '@spartacus/core';
import { PanelLookup, PanelResponse } from '../../../model';
import { PyStateUtils } from '../../../state';
import { RECOMMENDATIONS } from '../user-state';
import { recommendationsEntityKeyBuilder } from '../util/recommendations-entity-key-builder';

export enum RecommendationActionTypes {
  LoadRecommendation = '[User] Load Recommendations',
  LoadRecommendationFail = '[User] Load Recommendations fail',
  LoadRecommendationSuccess = '[User] Load Recommendations success',
}

const deriveKey = (lookup: PanelLookup) =>
  recommendationsEntityKeyBuilder(lookup.panelName, lookup.productCode, lookup.articleCode);

export class LoadRecommendation extends StateUtils.EntityLoadAction {
  readonly type = RecommendationActionTypes.LoadRecommendation;

  constructor(public lookup: PanelLookup) {
    super(RECOMMENDATIONS, deriveKey(lookup));
  }
}

export class LoadRecommendationSuccess extends StateUtils.EntitySuccessAction {
  readonly type = RecommendationActionTypes.LoadRecommendationSuccess;

  constructor(public payload: PanelResponse, public lookup: PanelLookup) {
    super(RECOMMENDATIONS, deriveKey(lookup));
  }
}

export class LoadRecommendationFail extends PyStateUtils.EntityFailAction {
  readonly type = RecommendationActionTypes.LoadRecommendationFail;

  constructor(public lookup: PanelLookup, public payload?: any) {
    super(RECOMMENDATIONS, deriveKey(lookup), payload);
  }
}

export type RecommendationActions = LoadRecommendation | LoadRecommendationFail | LoadRecommendationSuccess;
