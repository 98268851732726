import { StateUtils } from '@spartacus/core';
import { AccountingCode } from '../../../model';
import { AccountingCodesAdminActionTypes } from '../actions/accounting-codes-admin.action';
import { UserActions } from '../actions/index';

export const initialState: AccountingCode | undefined = undefined;

export function reducer(
  state: AccountingCode = initialState,
  action: UserActions.AccountingCodesAdminActions | StateUtils.LoaderAction
): AccountingCode {
  switch (action.type) {
    case AccountingCodesAdminActionTypes.CreateAccountingCodeAdminSuccess: {
      const _action = <UserActions.CreateAccountingCodeAdminSuccess>action;
      return _action.payload.accountingCode;
    }

    case AccountingCodesAdminActionTypes.UpdateAccountingCodeAdminSuccess: {
      const _action = <UserActions.UpdateAccountingCodeAdminSuccess>action;
      return _action.payload.accountingCode;
    }

    case AccountingCodesAdminActionTypes.DeleteAccountingCodeAdminSuccess: {
      const successAction = <UserActions.DeleteAccountingCodeAdminSuccess>action;
      const id = [].concat(successAction.meta.entityId).join();
      const newState = { ...state };
      delete newState[id];
      return newState;
    }
  }

  return state;
}
