import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { DrawerPlacement, DrawerService } from '../../../../shared';
import { CmsContentTagService } from '../../../cms-content-tag-base';
import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'py-menu-services',
  templateUrl: './menu-services.component.html',
  styleUrls: ['./menu-services.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuServicesComponent implements OnInit {
  @ViewChild(NgbDropdown, { static: true }) dropdown: NgbDropdown;
  @ViewChild('drawerTemplate') drawerTemplate: TemplateRef<any>;
  @Output() showChange = new EventEmitter<boolean>();

  @Input() show: boolean;
  @Input() isDirectServicesLink: boolean = false;

  hasActiveMenuItem$: Observable<boolean>;
  isServicesActive$: Observable<boolean>;
  taggedServices$: Observable<string[]>;
  taggedEducations$: Observable<string[]>;

  constructor(
    private cmsContentTagService: CmsContentTagService,
    private menuService: MenuService,
    private drawerService: DrawerService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.hasActiveMenuItem$ = this.menuService.hasActiveLinkUid();
    this.isServicesActive$ = this.menuService.getIsServicesActive();

    this.taggedServices$ = this.cmsContentTagService.findUIDsForMasterTagAndFilterTags(
      'services',
      'PuffComponent',
      'priority-desc,name-asc',
      'services-menu'
    );

    this.taggedEducations$ = this.cmsContentTagService.findUIDsForMasterTagAndFilterTags(
      'educations',
      'PuffComponent',
      'priority-desc,name-asc',
      'services-menu'
    );
  }

  toggleDrawer(): void {
    this.showChange.emit(true);

    this.drawerService
      .open({
        template: this.drawerTemplate,
        placement: DrawerPlacement.Top,
      })
      .pipe(take(1))
      .subscribe((drawer) => {
        drawer?.afterClose?.pipe(take(1)).subscribe(() => {
          this.showChange.emit(false);
          this.cd.detectChanges();
        });
      });
  }
}
