import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PageMetaResolver } from '@spartacus/core';
import { PRODUCT_NORMALIZER } from './connector/converters';
import { ProductNormalizer } from './connector/product-normalizer';
import { ProductPageMetaResolver } from './services/product-page-meta.resolver';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: PageMetaResolver,
      useExisting: ProductPageMetaResolver,
      multi: true,
    },
    {
      provide: PRODUCT_NORMALIZER,
      useClass: ProductNormalizer,
      multi: true,
    },
  ],
})
export class ProductModule {}
