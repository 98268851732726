import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { bufferCount, catchError, exhaustMap, filter, map } from 'rxjs/operators';
import { normalizeHttpError } from '../../../util';
import { SiteConnector } from '../../connectors/site.connector';
import { CurrenciesActionTypes } from '../actions/currencies.action';
import { SiteContextActions } from '../actions/index';
import { getActiveCurrency } from '../selectors/currencies.selectors';
import { StateWithSiteContext } from '../site-context-state';

@Injectable()
export class CurrenciesEffects {
  loadCurrencies$: Observable<SiteContextActions.LoadCurrenciesSuccess | SiteContextActions.LoadCurrenciesFail> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CurrenciesActionTypes.LoadCurrencies),
        exhaustMap(() => {
          return this.siteConnector.getCurrencies().pipe(
            map((currencies) => new SiteContextActions.LoadCurrenciesSuccess(currencies)),
            catchError((error) => of(new SiteContextActions.LoadCurrenciesFail(normalizeHttpError(error))))
          );
        })
      )
  );

  activateCurrency$: Observable<SiteContextActions.CurrencyChange> = createEffect(() =>
    this.state.select(getActiveCurrency).pipe(
      bufferCount(2, 1),

      // avoid dispatching `change` action when we're just setting the initial value:
      filter(([previous]) => !!previous),
      map(([previous, current]) => new SiteContextActions.CurrencyChange({ previous, current }))
    )
  );

  constructor(private actions$: Actions, private siteConnector: SiteConnector, private state: Store<StateWithSiteContext>) {}
}
