import { Component, Input, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownComponent } from './../dropdown/dropdown.component';

enum CollapsedDirectionEnum {
  DOWN = 'down',
  UP = 'up',
}
@Component({
  selector: 'py-user-dropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserDropdownComponent),
      multi: true,
    },
  ],
})
export class UserDropdownComponent extends DropdownComponent implements ControlValueAccessor, OnInit, OnDestroy {
  readonly ALL: string = 'ALL';
  readonly collapsedDirection = { DOWN: CollapsedDirectionEnum.DOWN, UP: CollapsedDirectionEnum.UP };

  @Input() filterPlaceholder?: string;
}
