import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CmsActivatedRouteSnapshot, CmsService, PageContext, RoutingService } from '@spartacus/core';
import {
  CmsComponentsService,
  CmsGuardsService,
  CmsI18nService,
  CmsPageGuardService as CxCmsPageGuardService,
} from '@spartacus/storefront';
import { Observable, of } from 'rxjs';
import { Page } from '../../core/cms';
import { SemanticPathService } from '../../core/routing';
import { BaseSiteService } from '../../core/site-context';
import { prepareUrlForLink } from '../../core/util';
import { CmsRoutesService } from '../services/cms-routes.service';

/**
 * Helper service for `CmsPageGuard`
 */
@Injectable({
  providedIn: 'root',
})
export class CmsPageGuardService extends CxCmsPageGuardService {
  constructor(
    protected semanticPathService: SemanticPathService,
    protected cmsService: CmsService,
    protected cmsRoutes: CmsRoutesService,
    protected cmsI18n: CmsI18nService,
    protected cmsGuards: CmsGuardsService,
    protected cmsComponentsService: CmsComponentsService,
    protected routing: RoutingService,
    protected router: Router,
    protected baseSiteService: BaseSiteService
  ) {
    super(semanticPathService, cmsService, cmsRoutes, cmsI18n, cmsGuards, cmsComponentsService, routing);
  }

  canActivatePage(
    pageContext: PageContext,
    pageData: Page,
    route: CmsActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    if (this.baseSiteService.isFallback()) {
      if (state.url !== '/') {
        const urlTree = this.router.parseUrl(prepareUrlForLink('/'));
        return of(urlTree);
      }
    }
    return super.canActivatePage(pageContext, pageData, route, state);
  }
}
