import { Occ } from '../occ';
import * as Generated from '../occ/occ-models/occ-generated.models';
import { B2BUser } from './org-unit.model';

export enum OrderCardUpdateStatusModalMode {
  Publish = 'Publish',
  Unpublish = 'Unpublish',
}

export interface OrderCard extends Occ.OrderCard {
  assignedAdminPrincipals?: OrderCardPrincipal[];
  tempId?: string;
}

export import OrderCardConfiguration = Occ.OrderCardConfiguration;

export import OrderCardConfigurationList = Occ.OrderCardConfigurationList;

export import OrderCardContactPerson = Occ.OrderCardContactPerson;

export interface OrderCardPage extends Occ.OrderCardPage {
  results?: OrderCard[];
}

/**
 * OrderCardPrincipal
 */
export interface OrderCardPrincipal {
  name?: string;
  soldTo?: OrderCardUserSoldTo;
  type?: string;
  uid?: string;
}

/**
 * OrderCardUserSoldTo
 */
export interface OrderCardUserSoldTo {
  name?: string;
  uid?: string;
}

export import CreateOrUpdateOrderCardConfiguration = Occ.CreateOrUpdateOrderCardConfiguration;

/**
 * CreateOrderCard
 */
export interface CreateOrderCard {
  /**
   * Uids for admin principals to assign to order card
   */
  adminPrincipals?: string[];
  /**
   * Order card contact person id
   */
  contactPerson?: string;
  /**
   * Order card description
   */
  description?: string;
  /**
   * Order card name
   */
  name: string;
  /**
   * Uids for user principals to assign to order card
   */
  principals?: string[];
}

export enum OrderCardErrorReason {
  ORDER_CARD_LOOKUP_FAILED = 'ORDER_CARD_LOOKUP_FAILED',
  ORDER_CARD_LOOKUP_FOR_CUSTOMER_FAILED = 'ORDER_CARD_LOOKUP_FOR_CUSTOMER_FAILED',
  ORDER_CARD_CONFIGURATION_LOOKUP_FAILED = 'ORDER_CARD_CONFIGURATION_LOOKUP_FAILED',
  ORDER_CARD_CONFIGURATION_UPDATE_FAILED = 'ORDER_CARD_CONFIGURATION_UPDATE_FAILED',
  ORDER_CARD_PRINCIPAL_LOOKUP_FAILED = 'ORDER_CARD_PRINCIPAL_LOOKUP_FAILED',
  UNDEFINED_ORDER_CARD_LOOKUP_FAILURE = 'UNDEFINED_ORDER_CARD_LOOKUP_FAILURE',
}

export enum UpdateOrderCardActionType {
  ADD = 'add',
  REMOVE = 'remove',
}

export interface AssignedPrincipalsSearchProps {
  searchText: string;
  page: number;
  count: number;
}

export import OrderCardStatus = Generated.OrderCardStatusEnum;

export interface OrderCardAdminAvailableContacts {
  contacts?: B2BUser[];
}

export enum OrderCardConfigurations {
  enableOrderCards = 'enableOrderCards',
  enableOrderCardsUser = 'enableOrderCardsUser',
  enableOrderCardsAdmin = 'enableOrderCardsAdmin',
}
