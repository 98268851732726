import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { LAUNCH_CALLER } from '../../../../core/modal';
import { Article, CustomerUploadedMedia, OrderReturnEntry } from '../../../../core/model';
import { OrderReturnUploadedFilesGalleryModalData } from '../order-return-uploaded-files-gallery-modal/order-return-uploaded-files-gallery-modal.component';

@Component({
  selector: 'py-order-return-entry-info',
  templateUrl: './order-return-entry-info.component.html',
  styleUrls: ['./order-return-entry-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderReturnEntryInfoComponent {
  @Input() orderReturnEntry: OrderReturnEntry;
  @Input() article: Article;

  constructor(private launchDialogService: LaunchDialogService) {}

  getQuantityInSalesUnit(orderReturnEntry: OrderReturnEntry): number {
    const unit = this.article.units.find((u) => u.code === orderReturnEntry.unit.code);
    return unit?.inEcommerceUnit ? unit.inEcommerceUnit * orderReturnEntry.quantity : undefined;
  }

  openUploadedFilesGallery(uploadedFiles: CustomerUploadedMedia[], fileIndex: number): void {
    const modalData: OrderReturnUploadedFilesGalleryModalData = {
      uploadedFiles: uploadedFiles,
      initialActiveSlideIndex: fileIndex,
      isReturnModeActive: false,
    };

    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.ORDER_RETURN_UPLOADED_FILES_GALLERY, undefined, modalData);
  }

  hasPdfType(fileName: string): boolean {
    return fileName?.split('.').pop() === 'pdf';
  }
}
