import { StateUtils } from '@spartacus/core';
import { PriceFileEntry, PriceFileRequest } from '../../../../model/price-file.model';
import { SearchParams } from '../../../../model/search.model';
import { PyStateUtils } from '../../../../state/utils';
import { PagedSearchAction } from '../../../store/actions/search.actions';
import { PRICE_FILE, PRICE_FILE_REQUEST } from '../price-file-state';

export enum PriceFileRequestActionTypes {
  RequestPriceFile = '[PriceFile] Request Price File',
  RequestPriceFileSuccess = '[PriceFile] Request Price File Success',
  RequestPriceFileFail = '[PriceFile] Request Price File Fail',
  RequestPriceFileStatusSuccess = '[PriceFile] Request Price File Status Success',
  RequestPriceFileStatusFail = '[PriceFile] Request Price File Status Fail',
}

export class RequestPriceFile extends StateUtils.LoaderLoadAction {
  readonly type = PriceFileRequestActionTypes.RequestPriceFile;

  constructor(public userId: string, public payload: PriceFileRequest, public searchParams: SearchParams) {
    super(PRICE_FILE_REQUEST);
  }
}

export class RequestPriceFileSuccess extends StateUtils.LoaderSuccessAction implements PagedSearchAction {
  readonly type = PriceFileRequestActionTypes.RequestPriceFileSuccess;
  readonly searchType = PRICE_FILE;

  constructor(public payload: PriceFileEntry, public searchParams: SearchParams) {
    super(PRICE_FILE_REQUEST);
  }
}

export class RequestPriceFileFail extends PyStateUtils.LoaderFailAction {
  readonly type = PriceFileRequestActionTypes.RequestPriceFileFail;

  constructor(public payload?: any) {
    super(PRICE_FILE_REQUEST, payload);
  }
}

export type PriceFileRequestActions = RequestPriceFile | RequestPriceFileSuccess | RequestPriceFileFail;
