<py-overlay-message>
  <span class="info"
    ><i nz-icon nzType="info-circle" nzTheme="fill"></i><span *ngIf="!!iconLabel">{{ iconLabel }}</span></span
  >
  <h5 class="text-center">{{ message }}</h5>
  <ng-container *ngIf="expandable; else cartLinkTemplate">
    <button class="link" [class.expanded]="expanded" (click)="onToggleExpanded()">
      <span>{{ 'cart.showSimilarItems_action' | cxTranslate }}</span>
      <i class="ml-2" nz-icon nzType="down-circle"></i>
    </button>
  </ng-container>
  <ng-template #cartLinkTemplate>
    <ng-container *ngIf="!hideCartLink">
      <a [routerLink]="{ cxRoute: 'cart' } | cxUrl" [queryParams]="cartLinkQueryParams">
        <span>{{ 'cart.showSimilarItems_action' | cxTranslate }}</span>
        <i class="ml-2" nz-icon nzType="arrow-right"></i>
      </a>
    </ng-container>
  </ng-template>
</py-overlay-message>
