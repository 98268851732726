import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { AddonNode, AddonSortTypes } from '../../../model';
import { ConverterService } from '../../../util/converter.service';
import { ADDON_NODE_NORMALIZER } from './converters';

@Injectable({
  providedIn: 'root',
})
export class AddonNodeConnector {
  constructor(
    private converterService: ConverterService,
    private httpClient: HttpClient,
    private occEndpointsService: OccEndpointsService
  ) {}

  load(userId: string, addonGroupId: string, addonNodeId: string, sort?: string): Observable<AddonNode> {
    const queryParams = {};
    if (sort && !sort.includes(AddonSortTypes.ADDONS_DEFAULT)) {
      queryParams['sort'] = sort;
    }

    return this.httpClient
      .get<AddonNode>(
        this.occEndpointsService.buildUrl('addonNode', { urlParams: { userId, addonGroupId, addonNodeId }, queryParams })
      )
      .pipe(this.converterService.pipeable(ADDON_NODE_NORMALIZER));
  }
}
