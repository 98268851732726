<py-modal
  [title]="modalTitle | cxTranslate"
  [showCancelButton]="true"
  [cancelButtonText]="'common.cancel_action' | cxTranslate"
  [actions]="templateActions"
  [centered]="true"
  [scrollable]="true"
  [cxFocus]="focusConfig"
  (esc)="closeModal('escape pressed')"
>
  <div class="row">
    <div class="col">
      <form [formGroup]="form">
        <py-reactive-form-input-field
          [fieldName]="'name'"
          [label]="'shoppingLists.name_heading' | cxTranslate"
          [placeholder]="'shoppingLists.name_placeholder' | cxTranslate"
          [group]="form"
        ></py-reactive-form-input-field>

        <py-reactive-form-input-field
          class="d-block mt-3"
          [fieldName]="'description'"
          [label]="'shoppingLists.description_heading' | cxTranslate"
          [placeholder]="'shoppingLists.description_placeholder' | cxTranslate"
          [group]="form"
        ></py-reactive-form-input-field>
      </form>
    </div>
  </div>
</py-modal>

<ng-template #templateActions>
  <py-action-button
    [buttonClass]="'w-100 btn btn-primary'"
    [buttonLabel]="
      (modalData?.modalType === createShoppingListModalTypeEnum.EDIT ? 'common.save_action' : 'common.create_action')
        | cxTranslate
    "
    [disabled]="form.invalid && formSubmitted"
    [loading]="loading"
    (click)="onFormSubmit()"
  >
  </py-action-button>
</ng-template>
