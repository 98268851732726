import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import { HttpErrorModel, PaymentOption, PaymentProviderType, PaymentSetup } from '../../../../../core/model';
import { PyStateUtils } from '../../../../../core/state';
import { ProcessIdBuilder } from '../../../../../core/user/store/util/process-id-builder';
import {
  GET_PAYMENT_SETUP_PROCESS,
  LOAD_PAYMENT_OPTIONS_PROCESS,
  PROCEED_PAYMENT_PROCESS,
  SET_PAYMENT_OPTION_PROCESS,
} from '../checkout-state';
import { CheckoutStepsEntityAction, PayloadWithCheckoutKey } from './checkout-steps-entity.action';

export enum CheckoutPaymentActionTypes {
  LoadPaymentOptions = '[Checkout] Load payment options',
  LoadPaymentOptionsSuccess = '[Checkout] Load payment options success',
  LoadPaymentOptionsFail = '[Checkout] Load payment options fail',
  LoadPaymentOptionsReset = '[Checkout] Load payment options reset',

  SetPaymentOption = '[Checkout] Set payment option',
  SetPaymentOptionFail = '[Checkout] Set payment option fail',
  SetPaymentOptionSuccess = '[Checkout] Set payment option success',
  ResetSetPaymentOption = '[Checkout] Reset set payment option',

  GetPaymentSetup = '[Checkout] Get payment setup',
  GetPaymentSetupFail = '[Checkout] Get payment setup fail',
  GetPaymentSetupSuccess = '[Checkout] Get payment setup success',
  ResetGetPaymentSetup = '[Checkout] Reset get payment setup',

  ProceedNetsPayment = '[Checkout] Proceed Nets payment',
  ProceedNetsPaymentFail = '[Checkout] Proceed Nets payment fail',
  ProceedNetsPaymentSuccess = '[Checkout] Proceed Nets payment success',
  ProceedGpWebPayPayment = '[Checkout] Proceed GpWebPay payment',
  ProceedGpWebPayPaymentFail = '[Checkout] Proceed GpWebPay payment fail',
  ProceedGpWebPayPaymentSuccess = '[Checkout] Proceed GpWebPay payment success',
  ProceedSiaVPosPayment = '[Checkout] Proceed SiaVPos payment',
  ProceedSiaVPosPaymentFail = '[Checkout] Proceed SiaVPos payment fail',
  ProceedSiaVPosPaymentSuccess = '[Checkout] Proceed SiaVPos payment success',
  ResetProceedPayment = '[Checkout] Reset proceed payment',
  ClearPaymentStep = '[Checkout] Clear payment step',
}

interface LoadPaymentOptionsPayload extends PayloadWithCheckoutKey {
  userId: string;
  cartId: string;
}

interface LoadPaymentOptionsFailPayload extends LoadPaymentOptionsPayload {
  error?: HttpErrorModel;
}

interface LoadPaymentOptionsSuccessPayload extends LoadPaymentOptionsPayload {
  paymentOptions: PaymentOption[];
}

interface SetPaymentOptionPayload extends PayloadWithCheckoutKey {
  userId: string;
  paymentOption: PaymentOption;
}

interface SetPaymentOptionFailPayload extends SetPaymentOptionPayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SetPaymentOptionSuccessPayload extends SetPaymentOptionPayload {}

interface GetPaymentSetupPayload extends PayloadWithCheckoutKey {
  userId: string;
  orderCode: string;
  paymentProvider: PaymentProviderType;
}

interface GetPaymentSetupFailPayload extends GetPaymentSetupPayload {
  error?: HttpErrorModel;
}

interface GetPaymentSetupSuccessPayload extends GetPaymentSetupPayload {
  paymentSetup: PaymentSetup;
}

interface ProceedPaymentPayload extends PayloadWithCheckoutKey {
  userId: string;
  orderCode: string;
}

interface ProceedNetsPaymentPayload extends ProceedPaymentPayload {
  paymentId: string;
}

interface ProceedNetsPaymentFailPayload extends ProceedNetsPaymentPayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProceedNetsPaymentSuccessPayload extends ProceedNetsPaymentPayload {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProceedGpWebPayPaymentPayload extends ProceedPaymentPayload {}

interface ProceedGpWebPayPaymentFailPayload extends ProceedGpWebPayPaymentPayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProceedGpWebPayPaymentSuccessPayload extends ProceedGpWebPayPaymentPayload {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProceedSiaVPosPaymentPayload extends ProceedPaymentPayload {}

interface ProceedSiaVPosPaymentFailPayload extends ProceedSiaVPosPaymentPayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProceedSiaVPosPaymentSuccessPayload extends ProceedSiaVPosPaymentPayload {}

export class LoadPaymentOptions extends StateUtils.EntityLoadAction {
  readonly type = CheckoutPaymentActionTypes.LoadPaymentOptions;

  constructor(public payload: LoadPaymentOptionsPayload) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(LOAD_PAYMENT_OPTIONS_PROCESS, payload.cartId));
  }
}

export class LoadPaymentOptionsFail extends PyStateUtils.EntityFailAction {
  readonly type = CheckoutPaymentActionTypes.LoadPaymentOptionsFail;

  constructor(public payload?: LoadPaymentOptionsFailPayload) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(LOAD_PAYMENT_OPTIONS_PROCESS, payload.cartId), payload.error);
  }
}

export class LoadPaymentOptionsSuccess extends StateUtils.EntitySuccessAction {
  readonly type = CheckoutPaymentActionTypes.LoadPaymentOptionsSuccess;

  constructor(payload: LoadPaymentOptionsSuccessPayload) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(LOAD_PAYMENT_OPTIONS_PROCESS, payload.cartId), {
      paymentOptions: payload.paymentOptions,
      key: payload.key,
    });
  }
}

export class LoadPaymentOptionsReset extends StateUtils.EntityLoaderResetAction {
  readonly type = CheckoutPaymentActionTypes.LoadPaymentOptionsReset;

  constructor(payload: { cartId: string }) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(LOAD_PAYMENT_OPTIONS_PROCESS, payload.cartId));
  }
}

export class SetPaymentOption extends StateUtils.EntityLoadAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutPaymentActionTypes.SetPaymentOption;

  constructor(public payload: SetPaymentOptionPayload) {
    super(PROCESS_FEATURE, SET_PAYMENT_OPTION_PROCESS);
  }
}

export class SetPaymentOptionFail extends PyStateUtils.EntityFailAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutPaymentActionTypes.SetPaymentOptionFail;

  constructor(public payload: SetPaymentOptionFailPayload) {
    super(PROCESS_FEATURE, SET_PAYMENT_OPTION_PROCESS, payload.error);
  }
}

export class SetPaymentOptionSuccess extends StateUtils.EntitySuccessAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutPaymentActionTypes.SetPaymentOptionSuccess;

  constructor(public payload: SetPaymentOptionSuccessPayload) {
    super(PROCESS_FEATURE, SET_PAYMENT_OPTION_PROCESS);
  }
}

export class ResetSetPaymentOption extends StateUtils.EntityLoaderResetAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutPaymentActionTypes.ResetSetPaymentOption;

  constructor(public payload: PayloadWithCheckoutKey) {
    super(PROCESS_FEATURE, SET_PAYMENT_OPTION_PROCESS);
  }
}

export class GetPaymentSetup extends StateUtils.EntityLoadAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutPaymentActionTypes.GetPaymentSetup;

  constructor(public payload: GetPaymentSetupPayload) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(GET_PAYMENT_SETUP_PROCESS, payload.key));
  }
}

export class GetPaymentSetupFail extends PyStateUtils.EntityFailAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutPaymentActionTypes.GetPaymentSetupFail;

  constructor(public payload: GetPaymentSetupFailPayload) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(GET_PAYMENT_SETUP_PROCESS, payload.key), payload.error);
  }
}

export class GetPaymentSetupSuccess extends StateUtils.EntitySuccessAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutPaymentActionTypes.GetPaymentSetupSuccess;

  constructor(public payload: GetPaymentSetupSuccessPayload) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(GET_PAYMENT_SETUP_PROCESS, payload.key));
  }
}

export class ResetGetPaymentSetup extends StateUtils.EntityLoaderResetAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutPaymentActionTypes.ResetGetPaymentSetup;

  constructor(public payload: PayloadWithCheckoutKey) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(GET_PAYMENT_SETUP_PROCESS, payload.key));
  }
}

export class ProceedNetsPayment extends StateUtils.EntityLoadAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutPaymentActionTypes.ProceedNetsPayment;

  constructor(public payload: ProceedNetsPaymentPayload) {
    super(PROCESS_FEATURE, PROCEED_PAYMENT_PROCESS);
  }
}

export class ProceedNetsPaymentFail extends PyStateUtils.EntityFailAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutPaymentActionTypes.ProceedNetsPaymentFail;

  constructor(public payload: ProceedNetsPaymentFailPayload) {
    super(PROCESS_FEATURE, PROCEED_PAYMENT_PROCESS, payload.error);
  }
}

export class ProceedNetsPaymentSuccess extends StateUtils.EntitySuccessAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutPaymentActionTypes.ProceedNetsPaymentSuccess;

  constructor(public payload: ProceedNetsPaymentSuccessPayload) {
    super(PROCESS_FEATURE, PROCEED_PAYMENT_PROCESS);
  }
}

export class ProceedGpWebPayPayment extends StateUtils.EntityLoadAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutPaymentActionTypes.ProceedGpWebPayPayment;

  constructor(public payload: ProceedGpWebPayPaymentPayload) {
    super(PROCESS_FEATURE, PROCEED_PAYMENT_PROCESS);
  }
}

export class ProceedGpWebPayPaymentFail extends PyStateUtils.EntityFailAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutPaymentActionTypes.ProceedGpWebPayPaymentFail;

  constructor(public payload: ProceedGpWebPayPaymentFailPayload) {
    super(PROCESS_FEATURE, PROCEED_PAYMENT_PROCESS, payload.error);
  }
}

export class ProceedGpWebPayPaymentSuccess extends StateUtils.EntitySuccessAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutPaymentActionTypes.ProceedGpWebPayPaymentSuccess;

  constructor(public payload: ProceedGpWebPayPaymentSuccessPayload) {
    super(PROCESS_FEATURE, PROCEED_PAYMENT_PROCESS);
  }
}

export class ProceedSiaVPosPayment extends StateUtils.EntityLoadAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutPaymentActionTypes.ProceedSiaVPosPayment;

  constructor(public payload: ProceedSiaVPosPaymentPayload) {
    super(PROCESS_FEATURE, PROCEED_PAYMENT_PROCESS);
  }
}

export class ProceedSiaVPosPaymentFail extends PyStateUtils.EntityFailAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutPaymentActionTypes.ProceedSiaVPosPaymentFail;

  constructor(public payload: ProceedSiaVPosPaymentFailPayload) {
    super(PROCESS_FEATURE, PROCEED_PAYMENT_PROCESS, payload.error);
  }
}

export class ProceedSiaVPosPaymentSuccess extends StateUtils.EntitySuccessAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutPaymentActionTypes.ProceedSiaVPosPaymentSuccess;

  constructor(public payload: ProceedSiaVPosPaymentSuccessPayload) {
    super(PROCESS_FEATURE, PROCEED_PAYMENT_PROCESS);
  }
}

export class ResetProceedPayment extends StateUtils.EntityLoaderResetAction {
  readonly type = CheckoutPaymentActionTypes.ResetProceedPayment;

  constructor() {
    super(PROCESS_FEATURE, PROCEED_PAYMENT_PROCESS);
  }
}

export class ClearPaymentStep implements CheckoutStepsEntityAction {
  readonly type = CheckoutPaymentActionTypes.ClearPaymentStep;

  constructor(public payload: PayloadWithCheckoutKey) {}
}

export class ProceedNetsPaymentRedirectionSuccess extends StateUtils.EntitySuccessAction implements CheckoutStepsEntityAction {
  readonly type = CheckoutPaymentActionTypes.ProceedNetsPaymentSuccess;

  constructor(public payload: ProceedNetsPaymentSuccessPayload) {
    super(PROCESS_FEATURE, PROCEED_PAYMENT_PROCESS);
  }
}

export type CheckoutPaymentActions =
  | LoadPaymentOptions
  | LoadPaymentOptionsSuccess
  | LoadPaymentOptionsFail
  | LoadPaymentOptionsReset
  | SetPaymentOption
  | SetPaymentOptionFail
  | SetPaymentOptionSuccess
  | ResetSetPaymentOption
  | GetPaymentSetup
  | GetPaymentSetupFail
  | GetPaymentSetupSuccess
  | ResetGetPaymentSetup
  | ProceedNetsPayment
  | ProceedNetsPaymentFail
  | ProceedNetsPaymentSuccess
  | ProceedGpWebPayPayment
  | ProceedGpWebPayPaymentFail
  | ProceedGpWebPayPaymentSuccess
  | ProceedSiaVPosPayment
  | ProceedSiaVPosPaymentFail
  | ProceedSiaVPosPaymentSuccess
  | ResetProceedPayment
  | ClearPaymentStep;
