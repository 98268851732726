import { Component, Input } from '@angular/core';
import { SpinnerType } from '../../../core/model';

@Component({
  selector: 'py-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss'],
})
export class ActionButtonComponent {
  @Input()
  disabled: boolean;

  @Input()
  loading? = false;

  @Input()
  error? = false;

  @Input()
  success? = false;

  @Input()
  successText?: string;

  @Input()
  buttonLabel: string;

  @Input()
  buttonIcon: string;

  @Input()
  type: string = 'button';

  @Input()
  buttonId: string;

  @Input()
  buttonClass: string = 'btn btn-primary';

  @Input()
  center: boolean;

  @Input()
  spinnerType: SpinnerType = SpinnerType.Default;
}
