import { CmsConfig } from '@spartacus/core';

export const defaultOccCmsModuleConfig: CmsConfig = {
  backend: {
    occ: {
      endpoints: {
        component: 'cms/components/${id}',
        components: 'cms/components',
        pages: 'cms/pages',
        page: 'cms/pages/${id}',
      },
    },
  },
  cmsComponents: {},
};
