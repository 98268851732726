import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'py-toggle-password-visibility',
  templateUrl: './toggle-password-visibility.component.html',
  styleUrls: ['./toggle-password-visibility.component.scss'],
})
export class TogglePasswordVisibilityComponent implements OnInit {
  @Input()
  isVisible = false;

  @Output()
  toggled = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  toggle() {
    this.isVisible = !this.isVisible;
    this.toggled.emit();
  }
}
