import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SEARCH_HINT_NORMALIZER, SOLR_SEARCH_NORMALIZER, SOLR_SEARCH_RESULT_REF_NORMALIZER } from './connectors/converters';
import { SearchHintNormalizer } from './connectors/search-hint-normalizer';
import { SolrSearchNormalizer } from './connectors/solr-search-normalizer';
import { SolrSearchResultRefNormalizer } from './connectors/solr-search-result-ref-normalizer';
import { SearchStoreModule } from './store/search-store.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, SearchStoreModule],
})
export class SolrSearchModule {
  static forRoot(): ModuleWithProviders<SolrSearchModule> {
    return {
      ngModule: SolrSearchModule,
      providers: [
        {
          provide: SOLR_SEARCH_NORMALIZER,
          useClass: SolrSearchNormalizer,
          multi: true,
        },
        {
          provide: SOLR_SEARCH_RESULT_REF_NORMALIZER,
          useClass: SolrSearchResultRefNormalizer,
          multi: true,
        },
        {
          provide: SEARCH_HINT_NORMALIZER,
          useClass: SearchHintNormalizer,
          multi: true,
        },
      ],
    };
  }
}
