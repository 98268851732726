import { CommonModule } from '@angular/common';
import { APP_BOOTSTRAP_LISTENER, NgModule } from '@angular/core';
import { WindowRef } from '../../../core/window';
import { ActiveOrderProcessService } from './active-order-process.service';

export function activeOrderProcessServiceFactory(
  activeOrderProcessModule: ActiveOrderProcessService,
  windowRef: WindowRef
): () => void {
  return windowRef.isBrowser() ? () => activeOrderProcessModule.init() : () => {};
}

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: APP_BOOTSTRAP_LISTENER,
      useFactory: activeOrderProcessServiceFactory,
      deps: [ActiveOrderProcessService, WindowRef],
      multi: true,
    },
  ],
})
export class ActiveOrderProcessModule {}
