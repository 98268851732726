import { StateUtils } from '@spartacus/core';
import { processesLoaderReducer } from '../processes-loader';

/**
 * Higher order reducer that wraps ProcessesLoaderReducer and EntityReducer enhancing
 * single state reducer to support multiple entities with generic processesCount flag
 */
export function entityProcessesLoaderReducer<T>(
  entityType: string,
  reducer?: (state: T, action: StateUtils.ProcessesLoaderAction) => T
): (
  state: StateUtils.EntityProcessesLoaderState<T>,
  action: StateUtils.EntityProcessesLoaderAction
) => StateUtils.EntityProcessesLoaderState<T> {
  return StateUtils.entityReducer(entityType, processesLoaderReducer(entityType, reducer));
}
