import { SearchAction, SearchActionTypes } from '../../../features/store/actions/search.actions';
import { AddressSearchParamsState, DELIVERY_ADDRESS } from '../user-state';

export const initialState: AddressSearchParamsState = {
  key: undefined,
  count: 0,
  page: 0,
  text: '',
};

export function reducer(state = initialState, action: SearchAction): AddressSearchParamsState {
  if (action.searchType !== DELIVERY_ADDRESS) {
    return state;
  }
  switch (action.type) {
    case SearchActionTypes.SearchAction:
      return action.searchParams;
    default:
      return state;
  }
}
