import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BREAKPOINT, BreakpointService } from '@spartacus/storefront';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';
import {
  AddonService,
  CatalogKeysService,
  CategoryLevelType,
  CategoryService,
  PriceCampaignService,
} from '../../../../core/catalog';
import { AddonGroup, CUSTOMER_UNIQUE_CATEGORY_CODE, Category, LoaderError, SoldTo } from '../../../../core/model';
import { PrincipalConfigurationService, UserFacade } from '../../../../core/user';
import { SoldToFacade } from '../../../sold-to-base';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ul[py-categories-navigation]',
  templateUrl: './categories-navigation.component.html',
  styleUrls: ['./categories-navigation.component.scss'],
})
export class CategoriesNavigationComponent implements OnInit, OnDestroy {
  @Input() mobile: boolean = false;
  @Input() activeCategoryCode: string;
  @Input() showMyAssortmentSubmenu: boolean;
  @Input() showCampaignsSubMenu: boolean;
  @Input() activeAddonGroupId: string;

  @Output() setActiveCategoryCode = new EventEmitter<{ item: Category; mya: boolean }>();
  @Output() setShowMyAssortmentSubmenu = new EventEmitter<boolean>();
  @Output() setActiveAddonGroup = new EventEmitter<AddonGroup>();
  @Output() setShowCampaignsSubMenu = new EventEmitter<boolean>();

  myaCategories$: Observable<Category[]>;
  categories$: Observable<Category[]>;
  addonGroups$: Observable<AddonGroup[]>;
  areCategoriesEmpty$: Observable<boolean>;
  activeTabIndex = 0;
  getCategoriesByRefsLoading = false;

  enableTogglingOfCustomerAssortment$: Observable<boolean>;
  enableDisplayOutgoingArea$: Observable<boolean>;
  defaultCustomerAssortmentToggleState$: Observable<boolean>;
  defaultAddonToggleState$: Observable<boolean>;
  enableTogglingOfAddons$: Observable<boolean>;
  hasTendered$: Observable<boolean>;
  showCampaignMenu$: Observable<boolean>;
  hasAddons$: Observable<boolean>;
  hasCustomerAssortment$: Observable<boolean>;
  hasUniqueArticles$: Observable<boolean>;
  catalogKeysChanged$: Observable<boolean>;
  success$: Observable<boolean>;
  error$: Observable<LoaderError | boolean>;
  activeSoldTo$: Observable<SoldTo>;

  assortmentForm = new UntypedFormGroup({
    customerAssortment: new UntypedFormControl(false),
    addons: new UntypedFormControl(false),
  });

  get customer_unique_category_code(): string {
    return CUSTOMER_UNIQUE_CATEGORY_CODE;
  }

  get myaValue(): string {
    return this.assortmentForm.get('customerAssortment').value ? '1' : '0';
  }

  private subscription = new Subscription();

  constructor(
    private categoryService: CategoryService,
    private catalogKeysService: CatalogKeysService,
    private principalConfigurationService: PrincipalConfigurationService,
    private userService: UserFacade,
    private breakpointService: BreakpointService,
    private addonService: AddonService,
    private priceCampaignService: PriceCampaignService,
    private soldToService: SoldToFacade
  ) {}

  ngOnInit(): void {
    this.enableTogglingOfCustomerAssortment$ = this.principalConfigurationService.isEnabled('enableTogglingOfCustomerAssortment');
    this.enableTogglingOfAddons$ = this.principalConfigurationService.isEnabled('enableTogglingOfAddons');
    this.enableDisplayOutgoingArea$ = this.principalConfigurationService.isEnabled('enableDisplayOutgoingArea');

    this.hasAddons$ = this.userService.hasAddons();
    this.hasTendered$ = combineLatest([
      this.principalConfigurationService.isEnabled('enableTendered'),
      this.userService.hasTendered(),
    ]).pipe(map(([enableTendered, hasTendered]) => Boolean(enableTendered && hasTendered)));

    this.hasCustomerAssortment$ = this.userService.hasCustomerAssortment();
    this.hasUniqueArticles$ = this.userService.hasUniqueArticles();
    this.catalogKeysChanged$ = this.catalogKeysService.getKeysChanged();
    this.areCategoriesEmpty$ = this.categoryService.getAreCategoriesEmpty();

    this.defaultCustomerAssortmentToggleState$ = combineLatest([
      this.principalConfigurationService.isEnabled('defaultCustomerAssortmentToggleState'),
      this.enableTogglingOfCustomerAssortment$,
      this.hasCustomerAssortment$,
    ]).pipe(
      map(([defaultCustomerAssortmentToggleState, enableTogglingOfCustomerAssortment, hasCustomerAssortment]) =>
        Boolean(defaultCustomerAssortmentToggleState && enableTogglingOfCustomerAssortment && hasCustomerAssortment)
      )
    );

    this.defaultAddonToggleState$ = combineLatest([
      this.principalConfigurationService.isEnabled('defaultAddonToggleState'),
      this.enableTogglingOfAddons$,
      this.hasAddons$,
    ]).pipe(
      map(([defaultAddonToggleState, enableTogglingOfAddons, hasAddons]) =>
        Boolean(defaultAddonToggleState && enableTogglingOfAddons && hasAddons)
      )
    );

    this.subscription.add(
      combineLatest([this.defaultCustomerAssortmentToggleState$, this.defaultAddonToggleState$])
        .pipe(
          filter(
            ([defaultCustomerAssortmentToggleState, defaultAddonToggleState]) =>
              defaultCustomerAssortmentToggleState || defaultAddonToggleState
          ),
          tap(([defaultCustomerAssortmentToggleState, defaultAddonToggleState]) => {
            if (defaultCustomerAssortmentToggleState) {
              this.activeTabIndex = 1;
              this.assortmentForm.get('customerAssortment').setValue(true, { emitEvent: false });
            } else if (defaultAddonToggleState) {
              this.activeTabIndex = 2;
              this.assortmentForm.get('addons').setValue(true, { emitEvent: false });
            } else {
              this.activeTabIndex = 0;
            }
          })
        )
        .subscribe()
    );

    this.addonGroups$ = combineLatest([
      this.hasAddons$,
      this.breakpointService.isUp(BREAKPOINT.md).pipe(distinctUntilChanged()),
    ]).pipe(
      filter(([hasAddons, _isDesktop]) => Boolean(hasAddons)),
      switchMap(([_hasAddons, isDesktop]) =>
        this.addonService.getAddonGroups().pipe(
          take(1),
          tap((addonGroups) => {
            if (isDesktop && this.activeTabIndex === 2) {
              this.setActiveAddonGroup.emit(addonGroups[0]);
            }
          })
        )
      )
    );

    this.categories$ = combineLatest([
      this.catalogKeysChanged$,
      this.enableTogglingOfCustomerAssortment$,
      this.breakpointService.isUp(BREAKPOINT.md),
    ]).pipe(
      switchMap(([_, enableTogglingOfCustomerAssortment, isDesktop]) => {
        const key = enableTogglingOfCustomerAssortment ? 'mya:false' : 'default';
        return this.categoryService.getCategoryRefsByLevel(CategoryLevelType.ROOT, key).pipe(
          startWith([]),
          filter((categoryRefs) => {
            this.getCategoriesByRefsLoading = !categoryRefs.length;
            return !!categoryRefs.length;
          }),
          switchMap((categoryRefs) => this.categoryService.getCategoriesByRefs(categoryRefs, key)),
          take(1),
          tap((categories) => {
            if (isDesktop && this.activeTabIndex === 0) {
              this.setActiveCategoryCode.emit({ item: categories[0], mya: false });
            }
          })
        );
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.myaCategories$ = combineLatest([
      this.catalogKeysChanged$,
      this.enableTogglingOfCustomerAssortment$,
      this.hasCustomerAssortment$,
      this.breakpointService.isUp(BREAKPOINT.md),
    ]).pipe(
      filter(
        ([_catalogKeysChanged, enableTogglingOfCustomerAssortment, hasCustomerAssortment]) =>
          enableTogglingOfCustomerAssortment && hasCustomerAssortment
      ),
      map(([_catalogKeysChanged, _enableTogglingOfCustomerAssortment, _hasCustomerAssortment, isDesktop]) => isDesktop),
      switchMap((isDesktop) => {
        const key = 'mya:true';
        return this.categoryService.getCategoryRefsByLevel(CategoryLevelType.ROOT, key).pipe(
          startWith([]),
          filter((categoryRefs) => {
            this.getCategoriesByRefsLoading = !categoryRefs.length;
            return !!categoryRefs.length;
          }),
          switchMap((categoryRefs) => {
            return this.categoryService.getCategoriesByRefs(categoryRefs, key);
          }),
          take(1),
          tap((myaCategories) => {
            if (isDesktop && this.activeTabIndex === 1) {
              this.setActiveCategoryCode.emit({ item: myaCategories[0], mya: true });
            }
          })
        );
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.showCampaignMenu$ = this.priceCampaignService
      .getAvailablePriceCampaignsSize()
      .pipe(map((campaignsSize) => (campaignsSize > 0 ? true : false)));

    this.success$ = this.categoryService.getCategoriesSuccess();
    this.error$ = this.categoryService.getCategoriesError();

    this.activeSoldTo$ = this.soldToService.getActiveSoldTo().pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.subscription.add(
      this.assortmentForm.valueChanges.subscribe((form) => {
        if (form?.customerAssortment && this.activeTabIndex !== 1) {
          this.assortmentForm.get('addons').setValue(false, { emitEvent: false });
          this.setActiveTabIndex(1);
        } else if (form?.addons && this.activeTabIndex !== 2) {
          this.assortmentForm.get('customerAssortment').setValue(false, { emitEvent: false });
          this.setActiveTabIndex(2);
        } else {
          this.setActiveTabIndex(0);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setActiveTabIndex(newIndex: number) {
    this.activeTabIndex = newIndex;
    if (newIndex === 0) {
      combineLatest([this.categories$, this.breakpointService.isUp(BREAKPOINT.md)])
        .pipe(
          filter(([categories, isDesktop]) => !!categories && isDesktop),
          tap(([categories]) => {
            this.setActiveCategoryCode.emit({ item: categories[0], mya: false });
          })
        )
        .subscribe()
        .unsubscribe();
    } else if (newIndex === 1) {
      combineLatest([this.myaCategories$, this.breakpointService.isUp(BREAKPOINT.md)])
        .pipe(
          filter(([myaCategories, isDesktop]) => !!myaCategories && isDesktop),
          tap(([myaCategories]) => {
            this.setActiveCategoryCode.emit({ item: myaCategories[0], mya: true });
          })
        )
        .subscribe()
        .unsubscribe();
    } else if (newIndex === 2) {
      combineLatest([this.addonGroups$, this.breakpointService.isUp(BREAKPOINT.md)])
        .pipe(
          filter(([addonGroups, isDesktop]) => !!addonGroups && isDesktop),
          tap(([addonGroups]) => {
            this.setActiveAddonGroup.emit(addonGroups[0]);
          })
        )
        .subscribe()
        .unsubscribe();
    }
  }
}
