import { ViewportScroller } from '@angular/common';
import { AfterViewInit, Directive, Input, OnDestroy } from '@angular/core';
import { ResolveEnd, Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { filter, take } from 'rxjs/operators'; // continue to use this entrypoint for rxjs v6 support
import { ScrollPositionService } from '../../services';

// Copy and Paste from https://gitlab.com/service-work/is-loading/-/tree/master/projects/scroll-position/src/scroll-position/module/directive

function invalidKeyError() {
  return new Error(
    'Must set a valid scroll position IsLoadingService key. ' + 'Use "default" for the default IsLoadingService key.'
  );
}

@Directive({
  selector: '[pyScrollPosition]',
  exportAs: 'pyScrollPosition',
  providers: [ScrollPositionService],
})
export class ScrollPositionDirective implements AfterViewInit, OnDestroy {
  private _key?: string | string[];

  @Input('pyScrollPosition')
  get key() {
    if (!this._key) {
      throw invalidKeyError();
    }

    return this._key;
  }
  set key(value: string | string[]) {
    if (!value || (Array.isArray(value) && value.length === 0)) {
      throw invalidKeyError();
    }

    this._key = value;
  }

  private delay = 0;
  @Input() set pyScrollPositionDelay(value: number | string) {
    this.delay = typeof value === 'number' ? value : parseInt(value, 10);

    if (!Number.isInteger(this.delay) || this.delay < 0) {
      throw new Error(`invalid pyScrollPositionDelay: ${value}`);
    }
  }

  @Input() pyScrollPositionSaveMode: 'OnNavigate' | 'OnDestroy' = 'OnNavigate';

  private subscriptions: Subscription[] = [];

  constructor(
    private service: ScrollPositionService,
    private router: Router, // private isLoadingService: IsLoadingService,
    private viewportScroller: ViewportScroller
  ) {}

  ngAfterViewInit() {
    this.subscriptions.push(
      timer(this.delay)
        .pipe(take(1))
        .subscribe(() => {
          this.refresh();
        })
    );

    this.subscriptions.push(
      this.router.events
        // ResolveEnd comes right before the current view is destroyed
        .pipe(filter((event) => this.pyScrollPositionSaveMode === 'OnNavigate' && event instanceof ResolveEnd))
        .subscribe(() => this.save())
    );
  }

  ngOnDestroy() {
    if (this.pyScrollPositionSaveMode === 'OnDestroy') {
      this.save(); // in case a component is destroyed via, e.g., an ngIf
    }

    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private save() {
    this.service.save(this.key, this.viewportScroller.getScrollPosition()[1]);
  }

  private refresh() {
    this.service.refresh(this.key);
  }
}
