import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import { PyStateUtils } from '../../../state';
import { PUNCHOUT_PROCESS } from '../user-state';

export enum PunchOutActionTypes {
  PunchOut = '[User] PunchOut',
  PunchOutFail = '[User] PunchOut fail',
  PunchOutSuccess = '[User] PunchOut success',
}

export class PunchOut extends StateUtils.EntityLoadAction {
  readonly type = PunchOutActionTypes.PunchOut;

  constructor(public payload: string, public userId: string) {
    super(PROCESS_FEATURE, PUNCHOUT_PROCESS);
  }
}

export class PunchOutFail extends PyStateUtils.EntityFailAction {
  readonly type = PunchOutActionTypes.PunchOutFail;

  constructor(public payload?: any) {
    super(PROCESS_FEATURE, PUNCHOUT_PROCESS);
  }
}

export class PunchOutSuccess extends StateUtils.EntitySuccessAction {
  readonly type = PunchOutActionTypes.PunchOutSuccess;

  constructor() {
    super(PROCESS_FEATURE, PUNCHOUT_PROCESS);
  }
}

export type PunchOutActions = PunchOut | PunchOutFail | PunchOutSuccess;
