import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  B2BPermissionOrderListItem,
  EntitiesModel,
  Permission,
  PermissionConnectionTypeEnum,
  PermissionConnections,
  PermissionOrdersSearchParams,
  SearchConfig,
  SoldTo,
  SoldToFilterParams,
  SoldToSearchResult,
} from '../../../model';
import {
  PERMISSIONS_NORMALIZER,
  PERMISSION_NORMALIZER,
  PERMISSION_SERIALIZER,
  PermissionAdapter,
} from '../../../permission/connectors';
import { ConverterService } from '../../../util';

@Injectable()
export class OccPermissionAdapter implements PermissionAdapter {
  constructor(protected http: HttpClient, protected occEndpoints: OccEndpointsService, protected converter: ConverterService) {}

  load(userId: string, permissionCode: string): Observable<Permission> {
    return this.http
      .get<any>(this.getPermissionEndpoint(userId, permissionCode))
      .pipe(this.converter.pipeable(PERMISSION_NORMALIZER));
  }

  loadList(userId: string, params?: SearchConfig): Observable<EntitiesModel<Permission>> {
    return this.http.get<any>(this.getPermissionsEndpoint(userId, params)).pipe(this.converter.pipeable(PERMISSIONS_NORMALIZER));
  }

  getPermissionOrders(
    permissionCode: string,
    userId: string,
    params?: PermissionOrdersSearchParams
  ): Observable<EntitiesModel<B2BPermissionOrderListItem>> {
    const url = this.occEndpoints.buildUrl('orderRuleOrders', {
      urlParams: { userId, permissionCode },
      queryParams: params,
    });
    return this.http.get<any>(url).pipe(this.converter.pipeable(PERMISSIONS_NORMALIZER));
  }

  getFilteredSoldTos(searchParams: SoldToFilterParams, userId: string): Observable<EntitiesModel<SoldTo>> {
    const url = this.occEndpoints.buildUrl('soldTos', {
      urlParams: { userId },
      queryParams: {
        page: searchParams.currentPage,
        count: searchParams.pageSize,
        connectedToPermissionCode: searchParams.connectedToPermissionCode,
        search: searchParams.search,
        permissionCode: searchParams.permissionCode,
        sort: searchParams.sort || undefined,
      },
    });
    return this.http.get<SoldToSearchResult>(url).pipe(map((res) => ({ ...res, values: res.results })));
  }

  getUserPermissions(userId: string, params?: SearchConfig): Observable<EntitiesModel<Permission>> {
    const url = this.occEndpoints.buildUrl('userOrderRules', {
      urlParams: { userId },
      queryParams: params,
    });
    return this.http.get<any>(url).pipe(this.converter.pipeable(PERMISSIONS_NORMALIZER));
  }

  create(userId: string, permission: Permission): Observable<Permission> {
    permission = this.converter.convert(permission, PERMISSION_SERIALIZER);
    return this.http
      .post<any>(this.getPermissionsEndpoint(userId), permission)
      .pipe(this.converter.pipeable(PERMISSION_NORMALIZER));
  }

  delete(userId: string, permissionCode: string): Observable<void> {
    return this.http.delete<any>(this.getPermissionEndpoint(userId, permissionCode));
  }

  update(userId: string, permissionCode: string, permission: Permission): Observable<Permission> {
    permission = this.converter.convert(permission, PERMISSION_SERIALIZER);
    return this.http
      .patch<any>(this.getPermissionEndpoint(userId, permissionCode), permission)
      .pipe(this.converter.pipeable(PERMISSION_NORMALIZER));
  }

  updatePermissionContactPerson(userId: string, permissionCode: string, contactPersonRef: string): Observable<void> {
    const url = this.occEndpoints.buildUrl('orderRuleUpdateContactPerson', {
      urlParams: {
        userId,
        permissionCode,
        contactPersonRef,
      },
    });
    return this.http.patch<any>(url, {});
  }

  publishPermission(userId: string, permissionCode: string): Observable<Permission> {
    const url = this.occEndpoints.buildUrl('orderRulePublication', {
      urlParams: {
        userId,
        permissionCode,
      },
    });
    return this.http.post<any>(url, {});
  }

  unpublishPermission(userId: string, permissionCode: string): Observable<Permission> {
    const url = this.occEndpoints.buildUrl('orderRulePublication', {
      urlParams: {
        userId,
        permissionCode,
      },
    });
    return this.http.delete<any>(url, {});
  }

  getPermissionConnections(
    connectionType: PermissionConnectionTypeEnum,
    userId: string,
    permissionCode: string
  ): Observable<any> {
    const url = this.occEndpoints.buildUrl(this.getPermissionConnectionUrlKey(connectionType), {
      urlParams: { userId, permissionCode },
    });

    return this.http.get<any>(url);
  }

  updatePermissionConnections(
    connectionType: PermissionConnectionTypeEnum,
    userId: string,
    permissionCode: string,
    connections: PermissionConnections
  ): Observable<void> {
    const url = this.occEndpoints.buildUrl(this.getPermissionConnectionUrlKey(connectionType), {
      urlParams: { userId, permissionCode },
    });
    switch (connectionType) {
      case PermissionConnectionTypeEnum.USERS:
        return this.http.patch<any>(url, connections);
      case PermissionConnectionTypeEnum.SOLDTOS:
      case PermissionConnectionTypeEnum.SHIPTOS:
        return this.http.post<any>(url, connections);
    }
  }

  deletePermissionConnections(
    connectionType: PermissionConnectionTypeEnum,
    userId: string,
    permissionCode: string,
    connections: PermissionConnections
  ): Observable<any> {
    const url = this.occEndpoints.buildUrl(this.getPermissionConnectionUrlKey(connectionType), {
      urlParams: { userId, permissionCode },
    });

    return this.http.request('delete', url, {
      body: connections,
    });
  }

  protected getPermissionEndpoint(userId: string, permissionCode: string): string {
    return this.occEndpoints.buildUrl('orderRule', {
      urlParams: {
        userId,
        permissionCode,
      },
    });
  }

  protected getPermissionsEndpoint(userId: string, params?: SearchConfig): string {
    return this.occEndpoints.buildUrl('orderRules', {
      urlParams: { userId },
      queryParams: params,
    });
  }

  protected getPermissionConnectionUrlKey(type: PermissionConnectionTypeEnum): string {
    switch (type) {
      case PermissionConnectionTypeEnum.USERS:
        return 'orderRuleUsers';
      case PermissionConnectionTypeEnum.SOLDTOS:
        return 'orderRuleSoldTos';
      case PermissionConnectionTypeEnum.SHIPTOS:
        return 'orderRuleShipTos';
    }
  }
}
