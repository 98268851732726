import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe } from '../../core/i18n';
import { NO_TIME_WINDOW_CODE, TimeWindowDeliveryOption } from '../../core/model';

@Pipe({
  name: 'timeCodeLabel',
})
export class TimeCodeLabelPipe implements PipeTransform {
  constructor(protected translatePipe: TranslatePipe) {}

  transform(input?: TimeWindowDeliveryOption): string | undefined {
    if (!input) {
      return undefined;
    }

    if (input.code === NO_TIME_WINDOW_CODE) {
      return this.translatePipe.transform('checkout.timeWindowCodeDelivery.noTimeSlot_hint');
    }

    let label = input.description;
    if (!label) {
      if (input.fromTime && input.toTime) {
        label = this.translatePipe.transform('checkout.timeWindowCodeDelivery.betweenTime_hint', {
          fromTime: input.fromTime.slice(0, 4),
          toTime: input.toTime.slice(0, 4),
        });
      } else if (!input.fromTime && input.toTime) {
        label = this.translatePipe.transform('checkout.timeWindowCodeDelivery.beforeTime_hint', {
          toTime: input.toTime.slice(0, 4),
        });
      } else if (input.fromTime && !input.toTime) {
        label = this.translatePipe.transform('checkout.timeWindowCodeDelivery.afterTime_hint', {
          fromTime: input.fromTime.slice(0, 4),
        });
      }
    }

    return label;
  }
}
