import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  COUNTRY_NORMALIZER,
  OccSiteAdapter as CxOccSiteAdapter,
  InterceptorUtil,
  OCC_USER_ID_ANONYMOUS,
  OccEndpointsService,
  USE_CLIENT_TOKEN,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Country, CountryType } from '../../../model/address.model';
import { SiteConfig } from '../../../model/misc.model';
import { SiteAdapter } from '../../../site-context/connectors/site.adapter';
import { ConverterService } from '../../../util/converter.service';
import { Occ } from '../../occ-models';

@Injectable()
export class OccSiteAdapter extends CxOccSiteAdapter implements SiteAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService,
    protected converterService: ConverterService
  ) {
    super(http, occEndpointsService, converterService);
  }

  loadCountriesByType(userId: string, countryType?: CountryType): Observable<Country[]> {
    let headers = new HttpHeaders();
    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }

    return this.http
      .get<Occ.CountryList>(
        this.occEndpointsService.buildUrl('countriesByType', {
          urlParams: { userId: userId },
          queryParams: {
            type: countryType || undefined,
            fields: 'FULL',
          },
        }),
        { headers }
      )
      .pipe(
        map((countryList) => countryList.countries),
        this.converterService.pipeableMany(COUNTRY_NORMALIZER)
      );
  }

  loadCountriesByCountryCodes(userId: string, countryCodes: string[]): Observable<Country[]> {
    let headers = new HttpHeaders();
    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }

    return this.http
      .get<Occ.CountryList>(
        this.occEndpointsService.buildUrl('countries', {
          urlParams: { userId: userId },
          queryParams: {
            countryCodes: countryCodes,
          },
        }),
        { headers }
      )
      .pipe(
        map((countryList) => countryList.countries),
        this.converterService.pipeableMany(COUNTRY_NORMALIZER)
      );
  }

  loadCountries(type?: CountryType): Observable<Country[]> {
    return this.loadCountriesByType(OCC_USER_ID_ANONYMOUS, type);
  }

  loadSiteConfig(): Observable<SiteConfig> {
    return this.http.get<Occ.SiteConfig>(this.occEndpointsService.buildUrl('siteConfig'));
  }
}
