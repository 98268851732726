import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import {
  B2BUser,
  CreateOrUpdateOrderCardConfiguration,
  OrderCard,
  OrderCardAdminSearchParams,
  OrderCardConfiguration,
  OrderCardStatus,
  Pagination,
  UpdateOrderCardActionType,
} from '../../model';
import { USER_BASE_FEATURE } from '../store/user-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: OrderCardsAdminFacade,
      feature: USER_BASE_FEATURE,
      async: true,
      methods: [
        'getOrderCard',
        'getOrderCardLoading',
        'getOrderCardSuccess',
        'resetOrderCardState',
        'searchOrderCards',
        'getOrderCardsSearchResult',
        'getSearchResultPagination',
        'getSearchPageResultsLoading',
        'updateOrderCard',
        'updateOrderCardStatus',
        'addOrderCardPrincipal',
        'deleteOrderCardPrincipal',
        'updateOrderCardPrincipals',
        'addOrderCardProduct',
        'replaceOrderCardProduct',
        'addOrderCardArticles',
        'deleteOrderCardProduct',
        'updateOrderCardProducts',
        'loadOrderCardConfigurations',
        'deleteOrderCard',
        'deleteOrderCardSuccess',
        'createOrderCard',
        'createOrderCardsWithTempId',
        'getOrderCardConfigurationLoading',
        'updateOrderCardConfiguration',
        'clearOrderCardConfigurations',
        'getOrderCardUsers',
        'isOrderCardsAdminEnabled',
      ],
    }),
})
export abstract class OrderCardsAdminFacade {
  abstract getOrderCard(code: string): Observable<OrderCard>;
  abstract getOrderCardLoading(code: string): Observable<boolean>;
  abstract getOrderCardSuccess(code: string): Observable<boolean>;
  abstract resetOrderCardState(code: string): void;
  abstract searchOrderCards(searchParams: OrderCardAdminSearchParams): void;
  abstract getOrderCardsSearchResult(searchParams: OrderCardAdminSearchParams): Observable<OrderCard[] | undefined>;
  abstract getSearchResultPagination(searchParams: OrderCardAdminSearchParams): Observable<Pagination | undefined>;
  abstract getSearchPageResultsLoading(searchParams: OrderCardAdminSearchParams): Observable<boolean>;
  abstract updateOrderCard(code: string, params: { name?: string; description?: string; contactPerson?: string }): void;
  abstract updateOrderCardStatus(code: string, status: OrderCardStatus): void;
  abstract addOrderCardPrincipal(code: string, principal: string): void;
  abstract deleteOrderCardPrincipal(code: string, principal: string): void;
  abstract updateOrderCardPrincipals(code: string, principals: string[], action: UpdateOrderCardActionType): void;
  abstract addOrderCardProduct(code: string, product: string): void;
  abstract replaceOrderCardProduct(code: string, oldProductCode: string, newProductCode: string): void;
  abstract addOrderCardArticles(code: string, articles: string[]): void;
  abstract deleteOrderCardProduct(code: string, product: string): void;
  abstract updateOrderCardProducts(code: string, products: string[], action: UpdateOrderCardActionType): void;
  abstract loadOrderCardConfigurations(code: string, customer: string): void;
  abstract deleteOrderCard(code: string): void;
  abstract deleteOrderCardSuccess(): Observable<boolean>;
  abstract createOrderCard(): Observable<string>;
  abstract createOrderCardsWithTempId(): Observable<OrderCard[]>;
  abstract getOrderCardConfigurationLoading(code: string, productCode: string): Observable<boolean>;
  abstract getOrderCardConfiguration(code: string, productCode: string, customer: string): Observable<OrderCardConfiguration>;
  abstract updateOrderCardConfiguration(
    code: string,
    productCode: string,
    customer: string,
    params: CreateOrUpdateOrderCardConfiguration
  ): void;
  abstract clearOrderCardConfigurations(): void;
  abstract getOrderCardUsers(params): Observable<B2BUser[]>;
  abstract isOrderCardsAdminEnabled(): Observable<boolean>;
}
