import { Component, Input, TemplateRef } from '@angular/core';
import { Article, LoaderError, StockInfoDetails, StockStatus, WarehouseStockInfo } from '../../../../core/model';

@Component({
  selector: 'py-extended-detailed-stock-info',
  templateUrl: './extended-detailed-stock-info.component.html',
  styleUrls: ['./extended-detailed-stock-info.component.scss'],
})
export class ExtendedDetailedStockInfoComponent {
  stockStatusType: typeof StockStatus = StockStatus;

  @Input() stockInfoDetails: StockInfoDetails;
  @Input() loadingStockInfoDetails: boolean;
  @Input() stockInfoDetailsError: LoaderError;
  @Input() article: Article;
  @Input() articleStockStatus: StockStatus;
  @Input() stockStatusTemplate: TemplateRef<any>;
  @Input() errorTemplate: TemplateRef<any>;
  @Input() similarArticlesTemplate: TemplateRef<any>;
  @Input() nonReturnableTemplate: TemplateRef<any>;
  @Input() warehouses: WarehouseStockInfo[];
}
