import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'py-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioComponent {
  @Input()
  showLabel: boolean;
}
