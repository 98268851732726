import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RoutingService } from '@spartacus/core';
import { FocusConfig, LaunchDialogService } from '@spartacus/storefront';
import { Observable, Subscription, merge, switchMap } from 'rxjs';
import { filter, map, take, tap, withLatestFrom } from 'rxjs/operators';
import { BaseAbstractModalComponent, ModalCloseReason } from '../../../core/modal';
import { CartType, Unit, WritableShoppingListsSearchParams } from '../../../core/model';
import { ShoppingListFacade } from '../../../core/user';
import { WritableShoppingListSearchParamsState } from '../../../core/user/store/user-state';

export enum CreateShoppingListModalType {
  CREATE_NEW = 'create_new',
  CREATE_NEW_WITH_ENTRY = 'create_with_entry',
  CREATE_FROM_CART = 'create_from_cart',
  EDIT = 'edit',
  DUPLICATE = 'duplicate',
}

export interface CreateShoppingListModalData {
  modalType?: CreateShoppingListModalType;
  cartType?: CartType;
  listCode?: string;
  listName?: string;
  listDescription?: string;
  key?: string;
  articleCode?: string;
  articleQuantity?: number;
  articleUnit?: Unit;
  articleNumber?: string;
}
@Component({
  selector: 'py-create-shopping-list-modal',
  templateUrl: './create-shopping-list-modal.component.html',
  styleUrls: ['./create-shopping-list-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateShoppingListModalComponent extends BaseAbstractModalComponent implements OnInit, OnDestroy {
  modalData: CreateShoppingListModalData;
  createShoppingListModalTypeEnum = CreateShoppingListModalType;
  form: UntypedFormGroup;
  formSubmitted = false;
  loading = false;
  writableShoppingListsSearchParams$: Observable<WritableShoppingListSearchParamsState>;
  focusConfig: FocusConfig = {
    trap: true,
    block: true,
    autofocus: 'input',
  };

  private createTitleLabel = 'shoppingLists.createShoppingListModal_title';
  private subscriptions = new Subscription();

  constructor(
    private fb: UntypedFormBuilder,
    private shoppingListService: ShoppingListFacade,
    private routingService: RoutingService,
    protected el: ElementRef,
    protected launchDialogService: LaunchDialogService,
    protected renderer: Renderer2
  ) {
    super(el, launchDialogService, renderer);
  }

  get modalTitle(): string {
    switch (this.modalData?.modalType) {
      case CreateShoppingListModalType.CREATE_NEW:
      case CreateShoppingListModalType.CREATE_NEW_WITH_ENTRY:
      case CreateShoppingListModalType.CREATE_FROM_CART:
        return this.createTitleLabel;
      case CreateShoppingListModalType.EDIT:
        return 'shoppingLists.editShoppingListModal_title';
      case CreateShoppingListModalType.DUPLICATE:
        return 'shoppingLists.duplicateShoppingListModal_title';
      default:
        return this.createTitleLabel;
    }
  }

  private get nameFormControl(): UntypedFormControl {
    return this.form.get('name') as UntypedFormControl;
  }

  private get descriptionFormControl(): UntypedFormControl {
    return this.form.get('description') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(255)]],
      description: ['', [Validators.required]],
    });

    this.subscriptions.add(
      this.launchDialogService.data$.pipe(take(1), filter(Boolean)).subscribe((data: CreateShoppingListModalData) => {
        this.modalData = data;
        this.nameFormControl.setValue(this.modalData?.listName || '');
        this.descriptionFormControl.setValue(this.modalData?.listDescription || '');
      })
    );
  }

  onFormSubmit(): void {
    this.formSubmitted = true;

    if (this.form.invalid) {
      Object.values(this.form.controls).forEach((control) => control.markAsDirty());
      this.form.updateValueAndValidity();
      return;
    }

    switch (this.modalData?.modalType) {
      case CreateShoppingListModalType.CREATE_NEW:
        this.onCreateNew();
        break;
      case CreateShoppingListModalType.CREATE_NEW_WITH_ENTRY:
        this.onCreateNewWithEntry();
        break;
      case CreateShoppingListModalType.CREATE_FROM_CART:
        this.onCreateFromCart();
        break;
      case CreateShoppingListModalType.EDIT:
        this.onEdit();
        break;
      case CreateShoppingListModalType.DUPLICATE:
        this.onDuplicate();
        break;
      default:
        this.onCreateNew();
    }
  }

  private onCreateNew(): void {
    this.loading = true;

    this.subscriptions.add(
      this.shoppingListService
        .createShoppingList(this.nameFormControl.value, this.descriptionFormControl.value)
        .pipe(
          switchMap((tempCode) =>
            this.shoppingListService.getShoppingList(tempCode).pipe(
              filter((code) => !!code),
              take(1)
            )
          )
        )
        .subscribe((shoppingList) => {
          this.launchDialogService.closeDialog(ModalCloseReason.CREATE_SHOPPING_LIST_CREATE_NEW_CONFIRMED);
          this.routingService.go([{ cxRoute: 'shoppingLists' }, shoppingList.code]);
          this.loading = false;
        })
    );
  }

  private onCreateNewWithEntry(): void {
    this.writableShoppingListsSearchParams$ = this.shoppingListService.getWritableShoppingListsSearchParams(this.modalData?.key);

    const tempCode$ = this.shoppingListService.createShoppingListAndAddEntry(
      this.nameFormControl.value,
      this.descriptionFormControl.value,
      this.modalData?.articleCode,
      this.modalData?.articleQuantity,
      this.modalData?.articleUnit,
      this.modalData?.articleNumber
    );

    this.loading = true;

    this.subscriptions.add(
      tempCode$
        .pipe(
          switchMap((tempCode) =>
            this.shoppingListService.getShoppingList(tempCode).pipe(
              filter((shoppingList) => !!shoppingList),
              take(1),
              withLatestFrom(this.writableShoppingListsSearchParams$),
              map(([_, searchParams]) => searchParams)
            )
          )
        )
        .subscribe((searchParams) => {
          this.shoppingListService.searchWritableShoppingLists(searchParams as WritableShoppingListsSearchParams); // Refreshing writeable shopping lists after shopping list was created
          this.launchDialogService.closeDialog(ModalCloseReason.CREATE_SHOPPING_LIST_CREATE_NEW_WITH_ENTRY_CONFIRMED);
          this.loading = false;
        })
    );
  }

  private onCreateFromCart(): void {
    const tempCode$ = this.shoppingListService.createShoppingListFromCart(
      this.modalData?.cartType,
      this.nameFormControl.value,
      this.descriptionFormControl.value
    );

    this.loading = true;

    this.subscriptions.add(
      tempCode$
        .pipe(
          switchMap((tempCode) =>
            this.shoppingListService.getShoppingList(tempCode).pipe(
              filter((shoppingList) => !!shoppingList),
              take(1)
            )
          )
        )
        .subscribe(() => {
          this.launchDialogService.closeDialog(ModalCloseReason.CREATE_SHOPPING_LIST_CREATE_FROM_CART_CONFIRMED);
          this.loading = false;
        })
    );
  }

  private onEdit(): void {
    this.shoppingListService.resetUpdateShoppingListNameState();
    this.shoppingListService.resetUpdateShoppingListDescriptionState();

    const nameChanged = this.modalData?.listName !== this.nameFormControl.value;
    const descriptionChanged = this.modalData?.listDescription !== this.descriptionFormControl.value;

    if (nameChanged) {
      this.shoppingListService.updateShoppingListName(this.modalData?.listCode, this.nameFormControl.value);
    }

    if (descriptionChanged) {
      this.shoppingListService.updateShoppingListDescription(this.modalData?.listCode, this.descriptionFormControl.value);
    }

    if (this.form.valid && !nameChanged && !descriptionChanged) {
      this.launchDialogService.closeDialog(ModalCloseReason.CREATE_SHOPPING_LIST_EDIT_CONFIRMED);
    }

    const updateShoppingListNameSuccess$: Observable<boolean> = this.shoppingListService.updateShoppingListNameSuccess();
    const updateShoppingListDescriptionSuccess$: Observable<boolean> =
      this.shoppingListService.updateShoppingListDescriptionSuccess();

    this.subscriptions.add(
      merge(updateShoppingListNameSuccess$, updateShoppingListDescriptionSuccess$)
        .pipe(
          tap(() => {
            this.loading = true;
          }),
          filter((success) => success),
          take(1)
        )
        .subscribe(() => {
          this.launchDialogService.closeDialog(ModalCloseReason.CREATE_SHOPPING_LIST_EDIT_CONFIRMED);
          this.loading = false;
        })
    );
  }

  private onDuplicate(): void {
    const tempCode$ = this.shoppingListService.createShoppingListFromAnotherShoppingList(
      this.modalData?.listCode,
      this.nameFormControl.value,
      this.descriptionFormControl.value
    );

    this.loading = true;

    this.subscriptions.add(
      tempCode$
        .pipe(
          switchMap((tempCode) =>
            this.shoppingListService.getShoppingList(tempCode).pipe(
              filter((shoppingList) => !!shoppingList),
              take(1)
            )
          )
        )
        .subscribe((shoppingList) => {
          this.launchDialogService.closeDialog(ModalCloseReason.CREATE_SHOPPING_LIST_DUPLICATE_SUCCESS);
          this.routingService.go([{ cxRoute: 'shoppingLists' }, shoppingList.code]);
          this.loading = false;
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
