import { StateUtils } from '@spartacus/core';
import { HttpErrorModel } from '../../../../model';
import { PriceFileRequestTemplateSearchResult } from '../../../../model/price-file.model';
import { PyStateUtils } from '../../../../state/utils';
import { PRICE_FILE_REQUEST_TEMPLATE } from '../price-file-state';

export enum PriceFileRequestTemplateActionTypes {
  LoadPriceFileRequestTemplate = '[PriceFile] Load Price File Request Template',
  LoadPriceFileRequestTemplateSuccess = '[PriceFile] Load Price File Request Template Success',
  LoadPriceFileRequestTemplateFail = '[PriceFile] Load Price File Request Template Fail',
  LoadPriceFileRequestTemplates = '[PriceFile] Load Price File Request Templates',
  LoadPriceFileRequestTemplatesSuccess = '[PriceFile] Load Price File Request Templates Success',
  LoadPriceFileRequestTemplatesFail = '[PriceFile] Load Price File Request Templates Fail',

  SavePriceFileRequestTemplate = '[PriceFile] Save Price File Request Template',
  SavePriceFileRequestTemplateSuccess = '[PriceFile] Save Price File Request Template Success',
  SavePriceFileRequestTemplateFail = '[PriceFile] Save Price File Request Template Fail',
  SavePriceFileRequestTemplateReset = '[PriceFile] Save Price File Request Template Reset',

  DeletePriceFileRequestTemplate = '[PriceFile] Delete Price File Request Template',
  DeletePriceFileRequestTemplateSuccess = '[PriceFile] Delete Price File Request Template Success',
  DeletePriceFileRequestTemplateFail = '[PriceFile] Delete Price File Request Template Fail',
}

export class LoadPriceFileRequestTemplate extends StateUtils.LoaderLoadAction {
  readonly type = PriceFileRequestTemplateActionTypes.LoadPriceFileRequestTemplate;

  constructor(public userId: string, public code: any) {
    super(PRICE_FILE_REQUEST_TEMPLATE);
  }
}
export class LoadPriceFileRequestTemplateSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = PriceFileRequestTemplateActionTypes.LoadPriceFileRequestTemplateSuccess;

  constructor(public payload: any) {
    super(PRICE_FILE_REQUEST_TEMPLATE);
  }
}
export class LoadPriceFileRequestTemplateFail extends PyStateUtils.LoaderFailAction {
  readonly type = PriceFileRequestTemplateActionTypes.LoadPriceFileRequestTemplateFail;

  constructor(public payload?: any) {
    super(PRICE_FILE_REQUEST_TEMPLATE, payload);
  }
}
export class LoadPriceFileRequestTemplates extends StateUtils.LoaderLoadAction {
  readonly type = PriceFileRequestTemplateActionTypes.LoadPriceFileRequestTemplates;

  constructor(public userId: string) {
    super(PRICE_FILE_REQUEST_TEMPLATE);
  }
}

export class LoadPriceFileRequestTemplatesSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = PriceFileRequestTemplateActionTypes.LoadPriceFileRequestTemplatesSuccess;

  constructor(public payload: PriceFileRequestTemplateSearchResult) {
    super(PRICE_FILE_REQUEST_TEMPLATE);
  }
}

export class LoadPriceFileRequestTemplatesFail extends PyStateUtils.LoaderFailAction {
  readonly type = PriceFileRequestTemplateActionTypes.LoadPriceFileRequestTemplatesFail;

  constructor(public payload?: any) {
    super(PRICE_FILE_REQUEST_TEMPLATE, payload);
  }
}

export class SavePriceFileRequestTemplate extends StateUtils.LoaderLoadAction {
  readonly type = PriceFileRequestTemplateActionTypes.SavePriceFileRequestTemplate;

  constructor(public userId: string, public payload: any) {
    super(PRICE_FILE_REQUEST_TEMPLATE);
  }
}

export class SavePriceFileRequestTemplateSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = PriceFileRequestTemplateActionTypes.SavePriceFileRequestTemplateSuccess;

  constructor(public payload: any) {
    super(PRICE_FILE_REQUEST_TEMPLATE);
  }
}

export class SavePriceFileRequestTemplateFail extends PyStateUtils.LoaderFailAction {
  readonly type = PriceFileRequestTemplateActionTypes.SavePriceFileRequestTemplateFail;

  constructor(public payload?: any) {
    super(PRICE_FILE_REQUEST_TEMPLATE, payload);
  }
}

export class SavePriceFileRequestTemplateReset extends StateUtils.LoaderResetAction {
  readonly type = PriceFileRequestTemplateActionTypes.SavePriceFileRequestTemplateReset;

  constructor() {
    super(PRICE_FILE_REQUEST_TEMPLATE);
  }
}

export class DeletePriceFileRequestTemplate extends StateUtils.EntityLoadAction {
  readonly type = PriceFileRequestTemplateActionTypes.DeletePriceFileRequestTemplate;

  constructor(public userId: string, id: string) {
    super(PRICE_FILE_REQUEST_TEMPLATE, id);
  }
}

export class DeletePriceFileRequestTemplateSuccess extends StateUtils.EntitySuccessAction {
  readonly type = PriceFileRequestTemplateActionTypes.DeletePriceFileRequestTemplateSuccess;

  constructor(id: string) {
    super(PRICE_FILE_REQUEST_TEMPLATE, id);
  }
}

export class DeletePriceFileRequestTemplateFail extends PyStateUtils.EntityFailAction {
  readonly type = PriceFileRequestTemplateActionTypes.DeletePriceFileRequestTemplateFail;

  constructor(id: string, public error?: HttpErrorModel) {
    super(PRICE_FILE_REQUEST_TEMPLATE, id, error);
  }
}

export type PriceFileRequestTemplateActions =
  | LoadPriceFileRequestTemplates
  | LoadPriceFileRequestTemplatesSuccess
  | LoadPriceFileRequestTemplatesFail
  | SavePriceFileRequestTemplate
  | SavePriceFileRequestTemplateSuccess
  | SavePriceFileRequestTemplateFail
  | LoadPriceFileRequestTemplate
  | LoadPriceFileRequestTemplateSuccess
  | LoadPriceFileRequestTemplateFail
  | DeletePriceFileRequestTemplateSuccess
  | DeletePriceFileRequestTemplateFail
  | DeletePriceFileRequestTemplate;
