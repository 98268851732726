import { Action } from '@ngrx/store';
import { PriceFileEntry, PriceFileListPage, PriceFileStatus } from '../../../../model/price-file.model';
import { EntriesState } from '../../../store/base-feature-state';
import { PriceFilesActions } from '../actions';
import { PriceFileRequestActionTypes, RequestPriceFileSuccess } from '../actions/price-file-request.actions';
import {
  CancelPriceFileSuccess,
  DeletePriceFileSuccess,
  LoadPriceFileSuccess,
  PriceFileActionTypes,
} from '../actions/price-file.actions';
import { PriceFilesActionTypes } from '../actions/price-files.action';

export const initialState: EntriesState<PriceFileEntry> = {};

export function reducer(state = initialState, action: Action): EntriesState<PriceFileEntry> {
  switch (action.type) {
    case PriceFilesActionTypes.LoadPriceFilesSuccess: {
      const priceFilesActionSuccess = <PriceFilesActions.LoadPriceFilesActionSuccess>action;
      const payload: PriceFileListPage = priceFilesActionSuccess.payload;

      const results = payload.results;

      return results.reduce(
        (entities: { [id: string]: PriceFileEntry }, fileEntry: PriceFileEntry) => {
          return {
            ...entities,
            [fileEntry.code]: fileEntry,
          };
        },
        {
          ...state,
        }
      );
    }
    case PriceFileRequestActionTypes.RequestPriceFileSuccess: {
      const successAction = <RequestPriceFileSuccess>action;
      const priceFileEntry = successAction.payload;
      return { ...state, [priceFileEntry.code]: priceFileEntry };
    }
    case PriceFileActionTypes.DeletePriceFileSuccess: {
      const successAction = <DeletePriceFileSuccess>action;
      const id = [].concat(successAction.meta.entityId).join();
      const newState = { ...state };
      delete newState[id];
      return newState;
    }
    case PriceFileActionTypes.CancelPriceFileSuccess: {
      const successAction = <CancelPriceFileSuccess>action;
      const id = [].concat(successAction.meta.entityId).join();
      return {
        ...state,
        [id]: {
          ...state[id],
          status: PriceFileStatus.CANCELED,
        },
      };
    }
    case PriceFileActionTypes.LoadPriceFileSuccess: {
      const successAction = <LoadPriceFileSuccess>action;
      const id = [].concat(successAction.meta.entityId).join();
      return {
        ...state,
        [id]: {
          ...successAction.payload,
        },
      };
    }

    default:
      return state;
  }
}
