import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../core/i18n';
import { LaunchDialogService } from '../../../core/modal';
import { PrincipalConfigurationDirectiveModule } from '../../directives';
import { PipesModule } from '../../pipes';
import { BadgeModule } from '../badge';
import { GenericLinkModule } from '../generic-link';
import { MediaModule } from '../media';
import { searchScoreModalLayoutConfig } from '../search-score-modal/search-score-modal-layout.config';
import { CatalogProductListItemComponent } from './catalog-product-list-item.component';

@NgModule({
  declarations: [CatalogProductListItemComponent],
  exports: [CatalogProductListItemComponent],
  imports: [
    CommonModule,
    I18nModule,
    GenericLinkModule,
    NzIconModule,
    MediaModule,
    PrincipalConfigurationDirectiveModule,
    BadgeModule,
    PipesModule,
  ],
})
export class CatalogProductListItemModule {
  constructor(private launchDialogService: LaunchDialogService) {
    this.launchDialogService.registerModalLayoutConfig(searchScoreModalLayoutConfig);
  }
}
