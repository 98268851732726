import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AuthService, EventService } from '@spartacus/core';
import { NavigationEvent } from '@spartacus/storefront';
import { Observable, combineLatest, forkJoin, of, race, using } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { UserIdService } from '../../../auth';
import { A4SampleColor, AlternativeProductRef, LoaderError, Product } from '../../../model';
import { withdrawOn } from '../../../util';
import { CatalogKeysService } from '../../services/catalog-keys.service';
import { StateWithCatalog } from '../../store/catalog-state';
import { CatalogSelectors } from '../../store/selectors';
import { ProductActions } from '../store/actions';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(
    private userIdService: UserIdService,
    private store: Store<StateWithCatalog>,
    private catalogKeysService: CatalogKeysService,
    private authService: AuthService,
    private eventService: EventService
  ) {}

  /**
   * Search product codes by codes and search text; can be passed an explicit catalogUsageKey, for example 'mya:false'
   * If this is not explicitly passed, the catalogUsageKey will be retrieved via CatalogKeysService.getCatalogUsageKey()
   */
  searchProductCodes(codes: Array<string>, searchText: string, catalogUsageKey?: string): Observable<string[]> {
    if (!searchText) {
      return of(codes);
    }
    if (!!catalogUsageKey) {
      return this.store.pipe(select(CatalogSelectors.searchProductCodes(codes, searchText, catalogUsageKey)));
    } else {
      return this.catalogKeysService.getCatalogUsageKey().pipe(
        switchMap((usageKey) => {
          return this.store.pipe(select(CatalogSelectors.searchProductCodes(codes, searchText, usageKey)));
        })
      );
    }
  }

  /**
   * Get products by codes; can be passed an explicit catalogUsageKey, for example 'mya:false'
   * If this is not explicitly passed, the catalogUsageKey will be retrieved via CatalogKeysService.getCatalogUsageKey()
   */
  getProducts(codes: Array<string>, catalogUsageKey?: string): Observable<Observable<Product>[]> {
    if (!!catalogUsageKey) {
      return of(codes.map((code) => this.loadProduct(code, catalogUsageKey)));
    } else {
      return this.catalogKeysService.getCatalogUsageKey().pipe(
        map((usageKey) => {
          return codes.map((code) => this.loadProduct(code, usageKey));
        }),
        withdrawOn(this.eventService.get(NavigationEvent))
      );
    }
  }

  getProductsFully(codes: Array<string>, catalogUsageKey?: string): Observable<Array<Product>> {
    if (!codes.length) {
      return of([]);
    }

    const key$ = !!catalogUsageKey ? of(catalogUsageKey) : this.catalogKeysService.getCatalogUsageKey();

    return key$.pipe(
      take(1),
      map((key) =>
        codes.map((code) => {
          const product$ = this.loadProduct(code, key).pipe(filter((product) => !!product));
          const productFailure$ = this.getProductFailure(code, key).pipe(
            filter((failure) => !!failure),
            mapTo(undefined)
          );

          return race(product$, productFailure$).pipe(take(1));
        })
      ),
      switchMap((products: Array<Observable<Product>>) => forkJoin(products)),
      map((products: Array<Product>) => products.filter((product) => !!product)),
      withdrawOn(this.eventService.get(NavigationEvent))
    );
  }

  getProductFailure(code: string, catalogUsageKey?: string): Observable<LoaderError | undefined> {
    return this.store.select(CatalogSelectors.getProductFailure(code, catalogUsageKey));
  }

  getProductLoading(code: string, catalogUsageKey?: string): Observable<boolean> {
    return this.store.select(CatalogSelectors.getProductLoading(code, catalogUsageKey));
  }

  /**
   * Get product by code; can be passed an explicit catalogUsageKey, for example 'mya:false'
   * If this is not explicitly passed, the catalogUsageKey will be retrieved via CatalogKeysService.getCatalogUsageKey()
   */
  getProduct(code: string, catalogUsageKey?: string): Observable<Product> {
    if (!code) {
      return of(undefined);
    }

    const key$ = !!catalogUsageKey ? of(catalogUsageKey) : this.catalogKeysService.getCatalogUsageKey();

    return key$.pipe(
      take(1),
      switchMap((usageKey) => {
        const product$ = this.loadProduct(code, usageKey).pipe(filter((product) => !!product));
        const productFailure$ = this.getProductFailure(code, usageKey).pipe(
          filter((failure) => !!failure),
          mapTo(undefined)
        );

        return race(product$, productFailure$).pipe(take(1));
      }),
      withdrawOn(this.eventService.get(NavigationEvent))
    );
  }

  private loadProduct(code: string, catalogUsageKey: string): Observable<Product> {
    const shouldLoad$ = this.store.pipe(
      select(CatalogSelectors.getProductLoaderState(code, catalogUsageKey)),
      map((productState) => !productState.loading && !productState.success && !productState.error),
      distinctUntilChanged(),
      filter((x) => x)
    );

    const isLoading$ = this.store.pipe(select(CatalogSelectors.getProductLoading(code, catalogUsageKey)));
    const catalogKey$ = this.catalogKeysService.getKey(catalogUsageKey);
    const userId$ = this.userIdService.getUserId();
    const productLoadLogic$ = combineLatest([shouldLoad$, catalogKey$]).pipe(
      debounceTime(0),
      map(([_shouldLoad, catalogKey]) => catalogKey),
      withLatestFrom(isLoading$, userId$),
      tap(([catalogKey, isLoading, userId]) => {
        if (!isLoading) {
          const myAssortment =
            (catalogUsageKey && catalogUsageKey.startsWith('mya:') && catalogUsageKey.substring(4)) || undefined;
          this.store.dispatch(new ProductActions.LoadProduct(code, userId, catalogKey, myAssortment));
        }
      }),
      takeUntil(this.authService.logoutInProgress$.pipe(filter((logout) => !!logout)))
    );

    const productData$ = this.store.pipe(select(CatalogSelectors.getProduct(code, catalogUsageKey)));

    return using(
      () => productLoadLogic$.subscribe(),
      () => productData$
    ).pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  getSubstituteProductRefs(code: string): Observable<AlternativeProductRef[] | undefined> {
    return this.store.pipe(
      select(CatalogSelectors.getSubstituteProductsLoaderState(code)),
      tap((loaderState) => {
        if (!loaderState.success && !loaderState.error && !loaderState.loading) {
          this.loadSubstituteProducts(code);
        }
      }),
      map((loaderState) => loaderState.value?.products),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  private loadSubstituteProducts(code: string): void {
    this.userIdService.takeUserId().subscribe(
      (userId) => this.store.dispatch(new ProductActions.LoadSubstituteProducts(code, userId)),
      () => {}
    );
  }

  getSubstituteProductsLoading(code: string): Observable<boolean> {
    return this.store.select(CatalogSelectors.getSubstituteProductsLoading(code));
  }

  getA4SampleColors(productCode: string, grammage: number): Observable<A4SampleColor[] | undefined> {
    return this.store.select(CatalogSelectors.getA4SampleColorsLoaderState(productCode, grammage)).pipe(
      filter((loaderState) => {
        if (!loaderState.success && !loaderState.error && !loaderState.loading) {
          this.loadA4SampleColors(productCode, grammage);
          return false;
        }
        return loaderState.success;
      }),
      map((loaderState) => loaderState.value),
      take(1)
    );
  }

  private loadA4SampleColors(productCode: string, grammage: number) {
    this.userIdService.takeUserId().subscribe(
      (userId) => this.store.dispatch(new ProductActions.LoadA4SampleColors(userId, productCode, grammage)),
      () => {}
    );
  }
}
