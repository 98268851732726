import { StateUtils } from '@spartacus/core';
import {
  A4SampleColor,
  AddonGroup,
  AddonNode,
  AlternativeArticles,
  AlternativeProducts,
  Article,
  Category,
  CategoryRef,
  EntityMap,
  NonSellableProductInquiryFormData,
  Product,
  SapPriceCampaignDetails,
  SapPriceCampaignList,
  Substitutes,
} from '../../model';

export const CATALOG_FEATURE = 'catalog';

export const ARTICLE = '[Catalog] Article';
export const SIMILAR_ARTICLES = '[Catalog] Similar articles';
export const SUBSTITUTES_ARTICLES = '[Catalog] Substitutes articles';
export const ADDON_GROUP = '[Catalog] Addon group';
export const ADDON_NODE = '[Catalog] Addon node';
export const CATEGORY = '[Catalog] Category';
export const CATALOG_KEYS = '[Catalog] Keys';
export const PRODUCT = '[Catalog] Product';
export const SUBSTITUTE_PRODUCTS = '[Catalog] Substitute products';
export const A4_SAMPLE_COLOR = '[Catalog] A4 sample color';
export const AREAS_OF_USE = '[Catalog] Areas of use';
export const PRICE_CAMPAIGN_DETAILS = '[Catalog] Price Campaign Details';
export const PRICE_CAMPAIGNS = '[Catalog] Price Campaigns';
export const NON_SELLABLE_PRODUCT_INQUIRY = '[Catalog] Non-sellable product inquiry';

export interface StateWithCatalog {
  [CATALOG_FEATURE]: CatalogState;
}

export interface CatalogState {
  catalogKeys: StateUtils.LoaderState<EntityMap<string>>;
  articles: StateUtils.EntityLoaderState<Article>;
  similarArticles: StateUtils.EntityLoaderState<AlternativeArticles>;
  substitutesArticles: StateUtils.EntityLoaderState<Substitutes>;
  priceCampaignDetails: StateUtils.EntityLoaderState<SapPriceCampaignDetails>;
  priceCampaigns: StateUtils.LoaderState<SapPriceCampaignList>;
  products: ProductsState;
  categories: CategoryViewState;
  addonGroups: StateUtils.LoaderState<AddonGroupEntries>;
  addonNodes: AddonNodeViewState;
  areasOfUse: StateUtils.LoaderState<CategoryEntries>;
  nonSellableProducts: NonSellableProductsState;
}

export interface ProductsState {
  products: ProductViewState;
  a4SampleColors: StateUtils.EntityLoaderState<A4SampleColor[]>;
  substituteProducts: StateUtils.EntityLoaderState<AlternativeProducts>;
}

export interface ProductViewState {
  [viewKey: string]: StateUtils.EntityLoaderState<Product>;
}

export interface CategoryViewState {
  error?: any;
  [viewKey: string]: StateUtils.LoaderState<CategoryEntries>;
}

export interface CategoryEntries extends StateUtils.EntityState<Category> {
  areCategoriesEmpty: boolean;
  tree: {
    [level: string]: Array<CategoryRef>;
  };
  lastUpdateTime?: string;
}

export interface AddonGroupEntries extends StateUtils.EntityState<AddonGroup> {
  tree: AddonGroup[];
}

export interface AddonNodeViewState {
  [addonGroupId: string]: StateUtils.EntityLoaderState<AddonNode>;
}

export interface NonSellableProductsState {
  inquiry: StateUtils.LoaderState<NonSellableProductInquiryFormData>;
}
