import { CartType } from '../../core/model';

export function getTextToDisplayInsteadOfPrice(cartType: CartType): string | undefined {
  switch (cartType) {
    case CartType.freeStock:
    case CartType.freeStockFillup:
      return 'common.freePrice_hint';
    case CartType.invoicedStock:
      return 'common.prepaidPrice_hint';
    default:
      return undefined;
  }
}
