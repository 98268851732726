import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ArticleNormalizer } from './connector/article-normalizer';
import { ClassificationAttributeNormalizer } from './connector/classification-attribute-normalizer';
import {
  ARTICLE_NORMALIZER,
  CLASSIFICATION_ATTRIBUTE_NORMALIZER,
  SIMILAR_ARTICLES_NORMALIZER,
  SUBSTITUTES_NORMALIZER,
} from './connector/converters';
import { SimilarArticlesNormalizer } from './connector/similar-articles-normalizer';
import { SubstitutesNormalizer } from './connector/substitutes-normalizer';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: ARTICLE_NORMALIZER,
      useClass: ArticleNormalizer,
      multi: true,
    },
    {
      provide: SIMILAR_ARTICLES_NORMALIZER,
      useClass: SimilarArticlesNormalizer,
      multi: true,
    },
    {
      provide: SUBSTITUTES_NORMALIZER,
      useClass: SubstitutesNormalizer,
      multi: true,
    },
    {
      provide: CLASSIFICATION_ATTRIBUTE_NORMALIZER,
      useClass: ClassificationAttributeNormalizer,
      multi: true,
    },
  ],
})
export class ArticleModule {}
