import { SiteAdapter as CxSiteAdapter } from '@spartacus/core';
import { Observable } from 'rxjs';
import { Country, CountryType } from '../../model/address.model';
import { SiteConfig } from '../../model/misc.model';
export abstract class SiteAdapter extends CxSiteAdapter {
  /**
   * Abstract method used to get countries with optional type.
   */
  abstract loadCountriesByType(userId: string, type?: CountryType): Observable<Country[]>;

  /**
   * Abstract method used to get countries by isocode
   */
  abstract loadCountriesByCountryCodes(userId: string, countryCodes: string[]): Observable<Country[]>;

  /**
   * Abstract method used to get site config.
   */
  abstract loadSiteConfig(): Observable<SiteConfig>;
}
