import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../../core/i18n';
import { GenericLinkModule, MegamenuListItemsModule } from '../../../../shared';
import { CampaignsSubNavigationComponent } from './campaigns-sub-navigation.component';

@NgModule({
  imports: [CommonModule, I18nModule, NzIconModule, GenericLinkModule, MegamenuListItemsModule],
  declarations: [CampaignsSubNavigationComponent],
  exports: [CampaignsSubNavigationComponent],
})
export class CampaignsSubNavigationModule {}
