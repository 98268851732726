import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        trackAndTrace: {
          module: () => import('./container/track-and-trace.module').then((m) => m.TrackAndTraceModule),
          cmsComponents: ['CmsTrackAndTraceComponent'],
        },
      },
    }),
  ],
})
export class TrackAndTraceFeatureModule {}
