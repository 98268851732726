import { MemoizedSelector, MemoizedSelectorWithProps, createSelector } from '@ngrx/store';
import { EntityMap } from '../../../model';
import { CatalogState, StateWithCatalog } from '../catalog-state';
import { getCatalogState } from './feature.selector';

/** Requires property catalogUsageKey */
export const getCatalogKey: MemoizedSelectorWithProps<StateWithCatalog, any, string> = createSelector(
  getCatalogState,
  (state: CatalogState, props: any) => {
    return state.catalogKeys?.value && state.catalogKeys.value[props.catalogUsageKey ?? 'default'];
  }
);

export const getCatalogKeys: MemoizedSelector<StateWithCatalog, EntityMap<string>> = createSelector(
  getCatalogState,
  (state: CatalogState) => state.catalogKeys?.value
);

export const getCatalogKeysReady: MemoizedSelector<StateWithCatalog, boolean> = createSelector(
  getCatalogState,
  (state: CatalogState) => {
    return !state.catalogKeys?.loading && !state.catalogKeys?.error && state.catalogKeys?.success;
  }
);
