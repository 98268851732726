import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { B2BComment } from '../../../core/model';
import { OrderApprovalFacade, PrincipalConfigurationService } from '../../../core/user';

@Component({
  selector: 'py-messages-tab',
  templateUrl: './messages-tab.component.html',
  styleUrls: ['./messages-tab.component.scss'],
})
export class MessagesTabComponent implements OnInit, OnDestroy {
  @Input() orderCode: string;
  @Input() sapOrderNumber: string;
  @Input() enableDisplayInternalOrderComments: boolean;

  comments$: Observable<Array<B2BComment>>;
  loadingComments$: Observable<boolean>;

  subscription: Subscription = new Subscription();

  constructor(
    private orderApprovalService: OrderApprovalFacade,
    private principalConfigurationService: PrincipalConfigurationService
  ) {}

  ngOnInit(): void {
    this.initB2bCommentsObservable(this.orderCode);
  }

  initB2bCommentsObservable(orderNumber: string): void {
    this.subscription.add(
      this.principalConfigurationService
        .isEnabled('enableDisplayApprovalOrders')
        .pipe(filter(Boolean))
        .subscribe((_) => this.orderApprovalService.loadB2BComments(orderNumber, !!this.sapOrderNumber))
    );

    this.loadingComments$ = this.orderApprovalService.getB2BCommentsLoading(orderNumber);

    this.comments$ = this.orderApprovalService.getB2BComments(orderNumber).pipe(
      filter((comments) => !!comments),
      map((comments) => [...comments].reverse())
    );
  }

  getFilteredComments(comments: Array<B2BComment>): Array<B2BComment> {
    return (
      comments?.filter((comment) => (comment.internal && this.enableDisplayInternalOrderComments) || !comment.internal) || []
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
