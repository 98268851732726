import { Injectable, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { RoutingService, StateUtils } from '@spartacus/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { UserIdService } from '../../auth/user-auth/facade';
import { SortModel } from '../../model/misc.model';
import {
  SearchTermFacetMappingHint,
  SolrSearchConfig,
  SolrSearchResult,
  SolrSearchSortingDefaults,
  SolrSearchType,
} from '../../model/solr-search-config';
import { SearchHintsActions } from '../store/actions';
import { ClearSearch, LoadSolrSearch, LoadSolrSearchTotalCount } from '../store/actions/solr-search.actions';
import { getHintsLoading, getHintsSuccess, getHintsValue } from '../store/selectors/search-hints.selector';
import {
  getArticleSearch,
  getArticleSearchLoaded,
  getArticleSearchLoaderState,
  getArticleSearchLoading,
  getArticleSorts,
  getArticleTotalCount,
  getDefaultSearch,
  getDefaultSearchLoaded,
  getDefaultSearchLoaderState,
  getDefaultSearchLoading,
  getProductSearch,
  getProductSearchLoaded,
  getProductSearchLoaderState,
  getProductSearchLoading,
  getProductSorts,
  getProductTotalCount,
  getTotalCountState,
} from '../store/selectors/solr-search.selector';
import { StateWithSolrSearch, TotalCountState } from '../store/solr-search-state';

@Injectable({
  providedIn: 'root',
})
export class SearchService implements OnDestroy {
  private textFromSearchInput$ = new BehaviorSubject<string>('');
  private showSearchResults$ = new BehaviorSubject<boolean>(false);
  private subscriptions = new Subscription();
  private userInputSearchInProgress$ = new BehaviorSubject<boolean>(false);

  /**
   * Updates text from search input when the query in routing state changes to reflect the searched value in search input field
   */
  private updateTextFromSearchInputOnRouteQueryChange$ = this.routingService.getRouterState().pipe(
    map((state) => state.state.queryParams['query']),
    tap((query) => {
      if (!query) {
        this.updateTextFromSearchInput$('');
      } else {
        const queryText = query.split(':')[0];
        this.updateTextFromSearchInput$(queryText);
      }
    }),
    distinctUntilChanged()
  );

  constructor(
    private userIdService: UserIdService,
    private store: Store<StateWithSolrSearch>,
    private routingService: RoutingService
  ) {
    this.subscriptions.add(this.updateTextFromSearchInputOnRouteQueryChange$.subscribe());
  }

  /**
   * Sort the facets alphabetically and not change free text search and the sort.
   * example: "text:score:b:1:a:1" -> "text:score:a:1:b:1"
   */
  sortSearchQuery(query: string): string {
    if (!query) {
      return '';
    }
    const values = query.split(':');
    const freeTextAndSort = values.slice(0, 2).join(':');
    const keysAndValues = values.slice(2).join(':');

    const result = keysAndValues
      .match(/([^:]+:[^:]+)/g)
      ?.sort()
      ?.join(':');

    return `${freeTextAndSort}:${result || ''}`;
  }

  /**
   * dispatch the search
   */
  search(
    query: string,
    searchConfig: SolrSearchConfig = {
      pageSize: 10,
      currentPage: 0,
      sort: SolrSearchSortingDefaults.PDP_TYPE_SKU_PICKER,
      searchType: SolrSearchType.ARTICLE,
    },
    loadAllProductArticles = false
  ): void {
    const searchQuery = this.sortSearchQuery(query);
    this.userIdService
      .takeUserId()
      .pipe(withLatestFrom(this.getSearchLoaderState(searchConfig.searchType, searchQuery)))
      .subscribe(([userId, searchLoaderState]) => {
        if (this.needsToSearch(searchLoaderState, searchConfig) || loadAllProductArticles) {
          this.store.dispatch(new LoadSolrSearch(searchQuery, searchConfig, userId, loadAllProductArticles));
        } else {
          this.updateUserInputSearchInProgress$(false);
        }
      });
  }

  private needsToSearch(loaderState: StateUtils.LoaderState<SolrSearchResult>, searchConfig: SolrSearchConfig): boolean {
    if (!!loaderState?.value?.lastUpdateTime) {
      if (
        (searchConfig.currentPage > 0 && searchConfig.currentPage !== loaderState.value?.pagination?.currentPage) ||
        (loaderState?.success && searchConfig.pageSize !== loaderState.value?.pagination?.pageSize)
      ) {
        return true;
      }
      const lastUpdateTime = new Date(loaderState.value?.lastUpdateTime);
      const currentTime = new Date();
      currentTime.setMinutes(currentTime.getMinutes() - 10);
      const timeTenMinutesAgo = currentTime;

      if (loaderState.success && lastUpdateTime < timeTenMinutesAgo) {
        return true;
      }
    }

    return !loaderState?.success && !loaderState?.error && !loaderState?.loading;
  }

  searchTotalHits(query: string, searchConfig: SolrSearchConfig): void {
    const searchQuery = this.sortSearchQuery(query);

    this.userIdService
      .takeUserId()
      .pipe(withLatestFrom(this.store.select(getTotalCountState, { searchQuery: searchQuery })))
      .subscribe(([userId, totalCountState]) => {
        if (this.needsToFetchTotalHits(totalCountState, searchConfig.searchType)) {
          this.store.dispatch(new LoadSolrSearchTotalCount(searchQuery, searchConfig, userId));
        }
      });
  }

  private needsToFetchTotalHits(totalCountState: TotalCountState, searchType: SolrSearchType): boolean {
    if (searchType === SolrSearchType.ARTICLE) {
      return !totalCountState?.articles && totalCountState?.articles !== 0;
    } else if (searchType === SolrSearchType.PRODUCT) {
      return !totalCountState?.products && totalCountState?.products !== 0;
    }

    return true;
  }

  clearSearch(): void {
    this.store.dispatch(new ClearSearch());
  }

  getSearchLoaderState(searchType: SolrSearchType, query: string): Observable<StateUtils.LoaderState<SolrSearchResult>> {
    const searchQuery = this.sortSearchQuery(query);

    switch (searchType) {
      case SolrSearchType.PRODUCT:
        return this.store.select(getProductSearchLoaderState, { searchQuery: searchQuery });
      case SolrSearchType.ARTICLE:
        return this.store.select(getArticleSearchLoaderState, { searchQuery: searchQuery });
      default:
        return this.store.select(getDefaultSearchLoaderState, { searchQuery: searchQuery });
    }
  }

  getSearchLoading(searchType: SolrSearchType, query: string): Observable<boolean> {
    const searchQuery = this.sortSearchQuery(query);

    switch (searchType) {
      case SolrSearchType.PRODUCT:
        return this.store.select(getProductSearchLoading, { searchQuery: searchQuery });
      case SolrSearchType.ARTICLE:
        return this.store.select(getArticleSearchLoading, { searchQuery: searchQuery });
      default:
        return this.store.select(getDefaultSearchLoading, { searchQuery: searchQuery });
    }
  }

  getSearchLoaded(searchType: SolrSearchType, query: string): Observable<boolean> {
    const searchQuery = this.sortSearchQuery(query);

    switch (searchType) {
      case SolrSearchType.PRODUCT:
        return this.store.select(getProductSearchLoaded, { searchQuery: searchQuery });
      case SolrSearchType.ARTICLE:
        return this.store.select(getArticleSearchLoaded, { searchQuery: searchQuery });
      default:
        return this.store.select(getDefaultSearchLoaded, { searchQuery: searchQuery });
    }
  }

  getTotalHits(searchType: SolrSearchType, query: string): Observable<number> {
    const searchQuery = this.sortSearchQuery(query);

    if (searchType === SolrSearchType.ARTICLE) {
      return this.store.select(getArticleTotalCount, { searchQuery });
    }
    return this.store.select(getProductTotalCount, { searchQuery });
  }

  getResults(searchType: SolrSearchType, query: string): Observable<SolrSearchResult> {
    const searchQuery = this.sortSearchQuery(query);

    switch (searchType) {
      case SolrSearchType.PRODUCT:
        return this.store.select(getProductSearch, { searchQuery: searchQuery });
      case SolrSearchType.ARTICLE:
        return this.store.select(getArticleSearch, { searchQuery: searchQuery });
      default:
        return this.store.select(getDefaultSearch, { searchQuery: searchQuery });
    }
  }

  updateTextFromSearchInput$(text: string): void {
    this.textFromSearchInput$.next(text);
  }

  getTextFromSearchInput$(): BehaviorSubject<string> {
    return this.textFromSearchInput$;
  }

  updateShowSearchResults$(showSearchResults: boolean): void {
    this.showSearchResults$.next(showSearchResults);
  }

  getShowSearchResults$(): BehaviorSubject<boolean> {
    return this.showSearchResults$;
  }

  updateUserInputSearchInProgress$(userInputSearchInProgress: boolean): void {
    this.userInputSearchInProgress$.next(userInputSearchInProgress);
  }

  getUserInputSearchInProgress$(): BehaviorSubject<boolean> {
    return this.userInputSearchInProgress$;
  }

  getSort(searchType = SolrSearchType.ARTICLE, query: string): Observable<SortModel[]> {
    const searchQuery = this.sortSearchQuery(query);

    switch (searchType) {
      case SolrSearchType.PRODUCT:
        return this.store.select(getProductSorts, { searchQuery: searchQuery });
      case SolrSearchType.ARTICLE:
        return this.store.select(getArticleSorts, { searchQuery: searchQuery });
    }
  }

  private getHintsLoading(): Observable<boolean> {
    return this.store.select(getHintsLoading);
  }

  private getHintsSuccess(): Observable<boolean> {
    return this.store.select(getHintsSuccess);
  }

  getHints(): Observable<SearchTermFacetMappingHint[]> {
    return this.store.pipe(
      select(getHintsValue),
      withLatestFrom(this.userIdService.getUserId(), this.getHintsLoading(), this.getHintsSuccess()),
      tap(([hints, userId, loading, success]) => {
        if (!hints?.length && !loading && !success) {
          this.store.dispatch(new SearchHintsActions.LoadSearchHints(userId));
        }
      }),
      filter(([hints, _]) => !!hints?.length),
      map(([hints, _userId, _loading, _success]) => hints)
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
