import { InjectionToken, Provider } from '@angular/core';
import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { LoginLogoutActionTypes } from '../../../../core/auth/user-auth/store/actions/login-logout.action';
import { ContentTag } from '../../../../core/occ/occ-models/occ-generated.models';
import { LanguagesActionTypes } from '../../../../core/site-context/store/actions/languages.action';
import { entityLoaderReducer } from '../../../../core/state/utils/entity-loader/entity-loader.reducer';
import * as fromContentTagReducer from '../../../../features/cms-content-tag-base/store/reducers/content-tag.reducer';
import { CONTENT_TAG, CmsContentTagState, StateWithCmsContentTag } from '../cms-content-tag-state';

export function getReducers(): ActionReducerMap<CmsContentTagState, any> {
  return {
    contentTags: entityLoaderReducer<ContentTag>(CONTENT_TAG, fromContentTagReducer.reducer),
  };
}

export const reducerToken: InjectionToken<ActionReducerMap<CmsContentTagState>> = new InjectionToken<
  ActionReducerMap<StateWithCmsContentTag>
>('CmsContentTagReducers');

export const reducerProvider: Provider = {
  provide: reducerToken,
  useFactory: getReducers,
};

export function clearCmsContentTagState(
  reducer: ActionReducer<StateWithCmsContentTag, Action>
): ActionReducer<StateWithCmsContentTag, Action> {
  return function (state, action) {
    if (
      action.type === LanguagesActionTypes.LanguageChange ||
      action.type === LoginLogoutActionTypes.Logout ||
      action.type === LoginLogoutActionTypes.Login
    ) {
      state = undefined;
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<StateWithCmsContentTag>[] = [clearCmsContentTagState];
