import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../core/i18n';
import { SpinnerModule } from '../spinner';
import { DropdownListComponent } from './dropdown-list.component';

@NgModule({
  imports: [CommonModule, NzIconModule, NgbDropdownModule, I18nModule, SpinnerModule],
  declarations: [DropdownListComponent],
  exports: [DropdownListComponent],
})
export class DropdownListModule {}
