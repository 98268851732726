<form [formGroup]="group">
  <label *ngIf="label" [for]="'input-' + fieldName">{{ label }}</label>
  <div class="form-control input-type-effects-text" [class.invalid]="group?.get(fieldName)?.invalid">
    <i class="reset search" nz-icon nzType="search"></i>
    <input [id]="'input-' + fieldName" [formControlName]="fieldName" [placeholder]="placeholder" />

    <ng-container *ngIf="!!getField().value">
      <button class="form-reset" *pyScreenSize="{ min: 'sm' }" type="button" (click)="handleReset.emit()">
        <i nz-icon class="reset" nzType="close-circle"></i>
      </button>
    </ng-container>
  </div>
  <div *ngIf="errors()" [id]="'errors-' + fieldName" class="invalid-feedback">
    <span>{{ errors() | inputError }}</span>
  </div>
</form>
