<py-cart-message-modal-header
  [title]="'cart.addedToCart_message' | cxTranslate"
  (closeModal)="onModalClose()"
></py-cart-message-modal-header>

<py-notification
  class="mt-3 notification-modal w-100"
  *ngIf="hasNameMarking || (cuttableOrReamable$ | async)"
  type="info"
  [tiny]="true"
  [showBorder]="false"
  [message]="'cart.addedCustomizableToCart_message' | cxTranslate"
></py-notification>

<ul [ngClass]="{ 'include-header': hasNameMarking || (cuttableOrReamable$ | async) }">
  <li *ngFor="let entry of entriesToDisplay" class="order-entry d-flex flex-column">
    <div class="entry-container d-flex flex-column" *ngIf="{ article: entry.article$ | async } as data">
      <div class="d-flex flex-row">
        <py-article-image
          [images]="data.article && [data.article.image]"
          [loading]="!data.article"
          [altText]="data?.article?.articleName || data?.article?.productName"
        ></py-article-image>

        <div class="d-flex flex-column">
          <py-article-info [article]="data.article" [articleNumber]="entry.articleNumber"></py-article-info>
          <py-article-price
            class="h6 mt-1"
            [price]="entry.price"
            [horizontalAlignment]="horizontalAlignments.LEFT"
            [cartType]="entry.cartType"
          ></py-article-price>
        </div>
      </div>

      <ng-container *pyPrincipalConfiguration="'enableFullPalletUpgrade'">
        <py-full-pallet-upgrade
          *ngIf="!entry.isSkipped && entry.fullPalletUpgrade?.upgradable"
          [isAlreadyUpdated]="entry.isAlreadyUpdated"
          [fullPalletUpgrade]="entry.fullPalletUpgrade"
          (update)="onPalletUpgrade(entry, data.article)"
          (skipUpdate)="onPalletUpgradeSkip(entry)"
        ></py-full-pallet-upgrade>
      </ng-container>
    </div>
  </li>

  <li *ngIf="entriesHasExceedingLength" class="exceeding-message">
    <div class="d-flex justify-content-center py-2">
      <span class="badge">
        {{ 'cart.numberOfAddedItemsNotShown_hint' | cxTranslate : { count: numberOfEntriesNotDisplayed } }}
      </span>
    </div>
  </li>
  <li class="actions">
    <div class="py-3" [class.actions-border]="!entriesHasExceedingLength">
      <div class="row">
        <div class="col-12 col-md-6 pr-md-1">
          <button
            [routerLink]="{ cxRoute: 'cart' } | cxUrl"
            [queryParams]="queryParams"
            (click)="onModalClose()"
            class="btn btn-secondary w-100 px-md-1"
          >
            {{ 'cart.viewCart_action' | cxTranslate }}
          </button>
        </div>

        <div class="col-12 col-md-6 pl-md-1 mt-2 mt-md-0">
          <button
            [routerLink]="{ cxRoute: 'checkout' } | cxUrl"
            [queryParams]="queryParams"
            (click)="onModalClose()"
            class="btn btn-primary w-100 px-md-1"
          >
            {{ 'cart.goToCheckout_action' | cxTranslate }}
          </button>
        </div>
      </div>
    </div>
  </li>
</ul>
