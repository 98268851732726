import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LoginLogoutActionTypes } from '../../../../auth/user-auth/store/actions/login-logout.action';
import { normalizeHttpError, withdrawOn } from '../../../../util';
import { PriceFileFormatConnector } from '../../connector/price-file-format.connector';
import { PriceFileFormatActions } from '../actions';
import {
  LoadPriceFileFormatsFail,
  LoadPriceFileFormatsSuccess,
  PriceFileFormatActionTypes,
} from '../actions/price-file-format.actions';

@Injectable()
export class PriceFileFormatEffects {
  private contextChange$ = this.actions$.pipe(ofType(LoginLogoutActionTypes.Logout));

  loadPriceFileFormats$: Observable<
    PriceFileFormatActions.LoadPriceFileFormatsSuccess | PriceFileFormatActions.LoadPriceFileFormatsFail
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(PriceFileFormatActionTypes.LoadPriceFileFormats),
      switchMap((action: PriceFileFormatActions.LoadPriceFileFormats) =>
        this.connector.load(action.userId).pipe(
          map((data) => new LoadPriceFileFormatsSuccess(data)),
          catchError((error) => of(new LoadPriceFileFormatsFail(normalizeHttpError(error))))
        )
      ),
      withdrawOn(this.contextChange$)
    )
  );

  constructor(private actions$: Actions, private connector: PriceFileFormatConnector) {}
}
