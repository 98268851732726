import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { BaseAbstractModalComponent, ModalCloseReason } from '../../../core/modal';

export interface LegalModalData {
  component$: Observable<any>;
  closeHandler?: Function;
  forceConsent?: boolean;
}

@Component({
  selector: 'py-legal-modal',
  styleUrls: ['./legal-modal.component.scss'],
  templateUrl: './legal-modal.component.html',
})
export class LegalModalComponent extends BaseAbstractModalComponent implements OnInit, OnDestroy {
  component: any;
  closeHandler: Function;
  forceConsent = false;

  private dismissed: boolean = false;
  private subscriptions = new Subscription();

  constructor(protected launchDialogService: LaunchDialogService, protected el: ElementRef, protected renderer: Renderer2) {
    super(el, launchDialogService, renderer);
    this.handlerForDismissOnClickOutside = this.dismissable ? this.close : undefined;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.launchDialogService.data$
        .pipe(
          take(1),
          switchMap((data: LegalModalData) => {
            this.closeHandler = data.closeHandler;

            if (data.forceConsent !== undefined) {
              this.forceConsent = data.forceConsent;
            }

            this.dismissable = !this.forceConsent;

            return data.component$;
          })
        )
        .subscribe((component: any) => {
          this.component = component;
        })
    );
  }

  close(): void {
    if (!this.dismissed) {
      this.dismissed = true;
      this.launchDialogService.closeDialog(ModalCloseReason.LEGAL_CLOSED);

      if (this.closeHandler) {
        this.dismissOnClickOutsideHandlerArgs = this.component?.data;
        this.closeHandler(this.component?.data);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
