import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArticleService } from '../../../core/catalog';
import { Article, ArticleCarouselItem, SubstituteRef } from '../../../core/model';

@Component({
  selector: 'py-order-entry-substitutes-articles',
  templateUrl: './order-entry-substitutes-articles.component.html',
  styleUrls: ['./order-entry-substitutes-articles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderEntrySubstitutesArticlesComponent {
  carouselItems$: Observable<ArticleCarouselItem[]>;
  _substituteRefs: SubstituteRef[];

  @Input() set substituteRefs(refs: SubstituteRef[]) {
    this._substituteRefs = refs;
    if (refs) {
      this.setCarouselItems(refs);
    }
  }

  @Input() isCartWriter: boolean = false;
  @Input() loading = false;
  @Input() enableSecondaryArticleRowVariant = false;

  @Output() replace = new EventEmitter<Article>();
  @Output() addToCart = new EventEmitter<Article>();

  get substituteRefs(): SubstituteRef[] {
    return this._substituteRefs;
  }

  canReplace = true;

  constructor(private articleService: ArticleService) {}

  setCarouselItems(substituteRefs: SubstituteRef[]): void {
    const articleCodes = substituteRefs.map((substituteRef) => substituteRef.code);

    this.carouselItems$ = this.articleService
      .getArticlesFully(articleCodes)
      .pipe(map((articles) => articles?.map((article) => ({ article } as ArticleCarouselItem))));
  }

  onReplace(carouselItem: ArticleCarouselItem): void {
    this.replace.emit(carouselItem.article);
    this.canReplace = false;
  }

  onAddToCart(carouselItem: ArticleCarouselItem): void {
    this.addToCart.emit(carouselItem.article);
  }
}
