import { SitePrefixActionTypes, SitePrefixActions } from '../actions/site-prefix-action';

export function reducer(state = '', action: SitePrefixActions): string {
  switch (action.type) {
    case SitePrefixActionTypes.SetActiveSitePrefix: {
      return action.payload;
    }
  }

  return state;
}
