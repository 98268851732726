import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../core/i18n';
import { IconThemeReverseModule } from '../../directives';
import { ActionButtonModule } from '../action-button';
import { DeleteButtonComponent } from './delete-button.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    NzIconModule,
    NgbPopoverModule,
    NgbTooltipModule,
    ActionButtonModule,
    IconThemeReverseModule,
  ],
  declarations: [DeleteButtonComponent],
  exports: [DeleteButtonComponent],
})
export class DeleteButtonModule {}
