import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { LoaderError, PunchOutData } from '../../model';
import { USER_BASE_FEATURE } from '../store/user-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: PunchOutFacade,
      feature: USER_BASE_FEATURE,
      async: true,
      methods: [
        'loadPunchOutData',
        'getPunchOutEntity',
        'punchOut',
        'cancelPunchOut',
        'logOut',
        'punchOutReset',
        'punchOutLoading',
        'punchOutError',
        'punchOutSuccess',
      ],
    }),
})
export abstract class PunchOutFacade {
  abstract loadPunchOutData(): void;
  abstract getPunchOutEntity(): Observable<PunchOutData>;
  abstract punchOut(cartId: string): void;
  abstract cancelPunchOut(): Observable<any>;
  abstract logOut(punchOutData: PunchOutData): void;
  abstract punchOutReset(): void;
  abstract punchOutLoading(): Observable<boolean>;
  abstract punchOutError(): Observable<LoaderError | undefined>;
  abstract punchOutSuccess(): Observable<boolean>;
}
