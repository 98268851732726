<ng-container *ngIf="content">
  <div class="read-more-container" [ngClass]="{ expanded: isReadMoreExpanded, enabled: readMoreEnabled }">
    <div #contentElement [class]="class" [innerHTML]="content"></div>
    <div class="read-more-overlay"></div>
  </div>
  <button *ngIf="readMoreEnabled" class="link" (click)="onButtonClick($event)">
    <ng-container *ngIf="!isReadMoreExpanded">
      {{ 'common.readMore_action' | cxTranslate }}<i *ngIf="showExpandDispandIcon" nz-icon nzType="plus-circle"></i>
    </ng-container>
    <ng-container *ngIf="isReadMoreExpanded">
      {{ 'common.readLess_action' | cxTranslate }}<i *ngIf="showExpandDispandIcon" nz-icon nzType="minus-circle"></i>
    </ng-container>
  </button>
</ng-container>
