import { Injectable } from '@angular/core';
import { Consent, ConsentTemplate, facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { LoaderError } from '../../model/misc.model';
import { USER_BASE_FEATURE } from '../store/user-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: UserConsentFacade,
      feature: USER_BASE_FEATURE,
      async: true,
      methods: [
        'loadConsents',
        'getConsents',
        'getRequiredConsentsNotGiven',
        'getOptionalConsents',
        'getConsentsResultLoading',
        'getConsentsResultSuccess',
        'getConsentsResultErrorDetails',
        'getConsent',
        'giveConsent',
        'getGiveConsentResultLoading',
        'getGiveConsentResultSuccess',
        'getGiveConsentResultErrorDetails',
        'withdrawConsent',
        'denyConsent',
        'getWithdrawConsentResultLoading',
        'getWithdrawConsentResultSuccess',
        'getWithdrawConsentResultErrorDetails',
        'isTrackingAllowed',
        'isPersonalizationAllowed',
        'isMarketingThirdPartyAllowed',
        'isBannerVisible',
        'isSettingsVisible',
        'toggleBannerVisible',
        'toggleSettingsModalVisible',
        'filterConsentTemplates',
        'resetConsentsProcessState',
      ],
    }),
})
export abstract class UserConsentFacade {
  abstract resetConsentsProcessState(): void;
  abstract filterConsentTemplates(templateList: ConsentTemplate[], hideTemplateIds?: string[]): Observable<ConsentTemplate[]>;
  abstract loadConsents(): void;
  abstract getConsents(loadIfMissing?: boolean): Observable<ConsentTemplate[]>;
  abstract getRequiredConsentsNotGiven(): Observable<Consent[]>;
  abstract getOptionalConsents(): Observable<ConsentTemplate[]>;
  abstract getConsentsResultLoading(): Observable<boolean>;
  abstract getConsentsResultSuccess(): Observable<boolean>;
  abstract getConsentsResultErrorDetails(): Observable<LoaderError | undefined>;
  abstract getConsent(templateId: string): Observable<Consent | undefined>;
  abstract giveConsent(consentTemplateId: string, consentTemplateVersion: number): void;
  abstract getGiveConsentResultLoading(): Observable<boolean>;
  abstract getGiveConsentResultSuccess(): Observable<boolean>;
  abstract getGiveConsentResultErrorDetails(): Observable<LoaderError | undefined>;
  abstract withdrawConsent(consentCode: string): void;
  abstract denyConsent(consentTemplateId: string, consentTemplateVersion: number): void;
  abstract getWithdrawConsentResultLoading(): Observable<boolean>;
  abstract getWithdrawConsentResultSuccess(): Observable<boolean>;
  abstract getWithdrawConsentResultErrorDetails(): Observable<LoaderError | undefined>;
  abstract isTrackingAllowed(): Observable<boolean>;
  abstract isPersonalizationAllowed(): Observable<boolean>;
  abstract isMarketingThirdPartyAllowed(): Observable<boolean>;
  abstract isBannerVisible(): Observable<boolean>;
  abstract isSettingsVisible(): Observable<boolean>;
  abstract toggleBannerVisible(dismissed: boolean): void;
  abstract toggleSettingsModalVisible(visible: boolean, showCloseButton?: boolean): void;
}
