import { Injectable } from '@angular/core';
import { SolrPagination, SolrResultEntityRef, SolrSearchResult } from '../../model';
import { Converter, ConverterService } from '../../util/converter.service';
import { SOLR_SEARCH_RESULT_REF_NORMALIZER } from './converters';

@Injectable({ providedIn: 'root' })
export class SolrSearchNormalizer implements Converter<any, SolrSearchResult> {
  constructor(private converter: ConverterService) {}

  convert(source: any, target?: SolrSearchResult): SolrSearchResult {
    if (source) {
      const articleResultRefs = this.converter.convertMany(source.articleResultRefs, SOLR_SEARCH_RESULT_REF_NORMALIZER);
      const productResultRefs = this.converter.convertMany(source.productResultRefs, SOLR_SEARCH_RESULT_REF_NORMALIZER);
      target = {
        ...source,
        articleResultRefs: this.markTopScores(source.pagination, articleResultRefs),
        productResultRefs: this.markTopScores(source.pagination, productResultRefs),
      };
    }
    return target;
  }

  private markTopScores(pagination: SolrPagination, resultRefs: SolrResultEntityRef[]): SolrResultEntityRef[] {
    if (!resultRefs?.length) {
      return resultRefs;
    }

    const refs = [...this.roundScores(resultRefs)];

    if (pagination.currentPage !== 0 || pagination.sort !== 'score' || resultRefs?.length <= 2) {
      return refs;
    }

    const maxDifferenceIndex = this.findMaxDiffIndex(refs);
    refs.slice(0, maxDifferenceIndex).forEach((item, index) => {
      refs[index] = {
        ...item,
        topScore: true,
      };
    });

    return refs;
  }

  private roundScores(resultRefs: SolrResultEntityRef[]): SolrResultEntityRef[] {
    return resultRefs?.map((item) => ({
      ...item,
      score: Number(item.score.toFixed(2)),
    }));
  }

  private findMaxDiffIndex(resultRefs: SolrResultEntityRef[]): number {
    let maxDifference = 0;

    return resultRefs.reduce((maxDiffIndex: number, resultRef: SolrResultEntityRef, currentIndex: number) => {
      if (currentIndex > 0) {
        const difference = resultRefs[currentIndex - 1].score - resultRef.score;
        if (difference > maxDifference) {
          maxDifference = difference;
          return currentIndex;
        }
      }
      return maxDiffIndex;
    }, 0);
  }
}
