import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import { PagedSearchAction } from '../../../features/store/actions/search.actions';
import {
  AvailableBuyersSearchParams,
  B2BComment,
  CartSimulation,
  HttpErrorModel,
  Order,
  OrderApproval,
  OrderApprovalDecision,
  OrderApprovalSearchParams,
  OrderApprovalSearchResult,
  OrderDetails,
  OrderProcessStatus,
} from '../../../model';
import { PyStateUtils } from '../../../state/utils';
import {
  APPROVED_ORDER_APPROVAL_DETAILS_PROCESS,
  B2B_COMMENTS_PROCESS,
  ORDER_APPROVALS,
  ORDER_APPROVALS_ADMIN,
  ORDER_APPROVAL_ABORT_HIJACK_PROCESS,
  ORDER_APPROVAL_BUYERS,
  ORDER_APPROVAL_DELETE_PROCESS,
  ORDER_APPROVAL_DETAILS_PROCESS,
  ORDER_APPROVAL_MAKE_DECISION_PROCESS,
  ORDER_APPROVAL_PROCESS,
  ORDER_APPROVAL_PROCESSING_PROCESS,
  ORDER_APPROVAL_RECOVER_PROCESS,
  ORDER_APPROVAL_SIMULATION_PROCESS,
} from '../user-state';
import { ProcessIdBuilder } from '../util/process-id-builder';

export const initialOrderApprovalSearchParams: OrderApprovalSearchParams = {
  key: 'latest',
  page: 0,
  count: 10,
  sort: 'creationtime:asc',
  searchText: '',
};

export enum OrderApprovalActionTypes {
  LOAD_ORDER_APPROVAL = '[OrderApproval] Load OrderApproval Data',
  LOAD_ORDER_APPROVAL_FAIL = '[OrderApproval] Load OrderApproval Data Fail',
  LOAD_ORDER_APPROVAL_SUCCESS = '[OrderApproval] Load OrderApproval Data Success',
  LOAD_ORDER_APPROVAL_RESET = '[OrderApproval] Load OrderApproval Data Reset',

  LOAD_B2B_COMMENTS = '[OrderApproval] Load B2B comments Data',
  LOAD_B2B_COMMENTS_FAIL = '[OrderApproval] Load B2B comments Data Fail',
  LOAD_B2B_COMMENTS_SUCCESS = '[OrderApproval] Load B2B comments Data Success',
  LOAD_B2B_COMMENTS_RESET = '[OrderApproval] Load B2B comments Data Reset',

  LOAD_ORDER_PROCESSING = '[OrderApproval] Load Order Processing Data',
  LOAD_ORDER_PROCESSING_FAIL = '[OrderApproval] Load Order Processing Data Fail',
  LOAD_ORDER_PROCESSING_SUCCESS = '[OrderApproval] Load Order Processing Data Success',
  LOAD_ORDER_PROCESSING_RESET = '[OrderApproval] Load Order Processing Data Reset',

  RECOVER_ORDER_APPROVAL = '[OrderApproval] Recover Order Data',
  RECOVER_ORDER_APPROVAL_FAIL = '[OrderApproval] Recover Order Data Fail',
  RECOVER_ORDER_APPROVAL_SUCCESS = '[OrderApproval] Recover Order Data Success',
  RECOVER_ORDER_APPROVAL_RESET = '[OrderApproval] Recover Order Data Reset',

  ABORT_ORDER_APPROVAL_HIJACK = '[OrderApproval] Abort hijack',
  ABORT_ORDER_APPROVAL_HIJACK_FAIL = '[OrderApproval] Abort hijack Fail',
  ABORT_ORDER_APPROVAL_HIJACK_SUCCESS = '[OrderApproval] Abort hijack Success',
  ABORT_ORDER_APPROVAL_HIJACK_RESET = '[OrderApproval] Abort hijack Reset',

  DELETE_ORDER_APPROVAL = '[OrderApproval] Delete Order Approval',
  DELETE_ORDER_APPROVAL_FAIL = '[OrderApproval] Delete Order Approval Fail',
  DELETE_ORDER_APPROVAL_SUCCESS = '[OrderApproval] Delete Order Approval Success',

  LOAD_APPROVED_ORDER_APPROVAL_DETAILS = '[OrderApproval] Load Approved OrderApproval Details Data',
  LOAD_APPROVED_ORDER_APPROVAL_DETAILS_FAIL = '[OrderApproval] Load Approved OrderApproval Details Data Fail',
  LOAD_APPROVED_ORDER_APPROVAL_DETAILS_SUCCESS = '[OrderApproval] Load Approved OrderApproval Details Data Success',
  LOAD_APPROVED_ORDER_APPROVAL_DETAILS_RESET = '[OrderApproval] Load Approved OrderApproval Details Data Reset',

  LOAD_ORDER_APPROVAL_DETAILS = '[OrderApproval] Load OrderApproval Details Data',
  LOAD_ORDER_APPROVAL_DETAILS_FAIL = '[OrderApproval] Load OrderApproval Details Data Fail',
  LOAD_ORDER_APPROVAL_DETAILS_SUCCESS = '[OrderApproval] Load OrderApproval Details Data Success',
  LOAD_ORDER_APPROVAL_DETAILS_RESET = '[OrderApproval] Load OrderApproval Details Data Reset',

  LOAD_ORDER_APPROVAL_SIMULATION = '[OrderApproval] Load OrderApproval Simulation Data',
  LOAD_ORDER_APPROVAL_SIMULATION_FAIL = '[OrderApproval] Load OrderApproval Simulation Data Fail',
  LOAD_ORDER_APPROVAL_SIMULATION_SUCCESS = '[OrderApproval] Load OrderApproval Simulation Data Success',
  LOAD_ORDER_APPROVAL_SIMULATION_RESET = '[OrderApproval] Load OrderApproval Simulation Data Reset',

  LOAD_ORDER_APPROVALS = '[OrderApproval] Load OrderApprovals',
  LOAD_ORDER_APPROVALS_FAIL = '[OrderApproval] Load OrderApprovals Fail',
  LOAD_ORDER_APPROVALS_SUCCESS = '[OrderApproval] Load OrderApprovals Success',
  LOAD_ORDER_APPROVALS_RESET = '[OrderApproval] Load OrderApprovals Reset',

  LOAD_ORDER_APPROVALS_ADMIN = '[OrderApproval] Load OrderApprovals Admin',
  LOAD_ORDER_APPROVALS_ADMIN_FAIL = '[OrderApproval] Load OrderApprovals Admin Fail',
  LOAD_ORDER_APPROVALS_ADMIN_SUCCESS = '[OrderApproval] Load OrderApprovals Admin Success',
  LOAD_ORDER_APPROVALS_ADMIN_RESET = '[OrderApproval] Load OrderApprovals Admin Reset',

  MAKE_DECISION = '[OrderApproval] Make OrderApproval Decision',
  MAKE_DECISION_FAIL = '[OrderApproval] Make OrderApproval Decision Fail',
  MAKE_DECISION_SUCCESS = '[OrderApproval] Make OrderApproval Decision Success',
  MAKE_DECISION_RESET = '[OrderApproval] Make OrderApproval Decision Reset',

  LOAD_AVAILABLE_ORDER_APPROVALS_BUYERS = '[OrderApproval] Load available Order Approvals Buyers',
  LOAD_AVAILABLE_ORDER_APPROVALS_BUYERS_SUCCESS = '[OrderApproval] Load available Order Approvals Buyers success',
  LOAD_AVAILABLE_ORDER_APPROVALS_BUYERS_FAIL = '[OrderApproval] Load available Order Approvals Buyers fail',
}

export class LoadOrderApproval extends StateUtils.EntityLoadAction {
  readonly type = OrderApprovalActionTypes.LOAD_ORDER_APPROVAL;

  constructor(public payload: { userId: string; orderApprovalCode: string }) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_PROCESS, payload.orderApprovalCode));
  }
}

export class LoadOrderApprovalFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderApprovalActionTypes.LOAD_ORDER_APPROVAL_FAIL;

  constructor(public payload: { orderApprovalCode: string; error?: HttpErrorModel }) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_PROCESS, payload.orderApprovalCode));
  }
}

export class LoadOrderApprovalSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderApprovalActionTypes.LOAD_ORDER_APPROVAL_SUCCESS;

  constructor(public payload: Order) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_PROCESS, payload.code));
  }
}

export class LoadOrderApprovalReset extends StateUtils.EntityLoaderResetAction {
  readonly type = OrderApprovalActionTypes.LOAD_ORDER_APPROVAL_RESET;

  constructor(orderApprovalCode: string) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_PROCESS, orderApprovalCode));
  }
}

export class LoadB2BComments extends StateUtils.EntityLoadAction {
  readonly type = OrderApprovalActionTypes.LOAD_B2B_COMMENTS;

  constructor(public payload: { userId: string; code: string; sapOrder: boolean }) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(B2B_COMMENTS_PROCESS, payload.code));
  }
}

export class LoadB2BCommentsFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderApprovalActionTypes.LOAD_B2B_COMMENTS_FAIL;

  constructor(public payload: { code: string; error?: HttpErrorModel }) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(B2B_COMMENTS_PROCESS, payload.code));
  }
}

export class LoadB2BCommentsSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderApprovalActionTypes.LOAD_B2B_COMMENTS_SUCCESS;

  constructor(public payload: Array<B2BComment>, code: string) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(B2B_COMMENTS_PROCESS, code));
  }
}

export class LoadB2BCommentsReset extends StateUtils.EntityLoaderResetAction {
  readonly type = OrderApprovalActionTypes.LOAD_B2B_COMMENTS_RESET;

  constructor(orderApprovalCode: string) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(B2B_COMMENTS_PROCESS, orderApprovalCode));
  }
}

export class LoadOrderProcessing extends StateUtils.EntityLoadAction {
  readonly type = OrderApprovalActionTypes.LOAD_ORDER_PROCESSING;

  constructor(public payload: { userId: string; orderApprovalCode: string }) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_PROCESSING_PROCESS, payload.orderApprovalCode));
  }
}

export class LoadOrderProcessingFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderApprovalActionTypes.LOAD_ORDER_PROCESSING_FAIL;

  constructor(public payload: { orderApprovalCode: string; error?: HttpErrorModel }) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_PROCESSING_PROCESS, payload.orderApprovalCode));
  }
}

export class LoadOrderProcessingSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderApprovalActionTypes.LOAD_ORDER_PROCESSING_SUCCESS;

  constructor(public payload: OrderProcessStatus, approvalOrderCode: string) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_PROCESSING_PROCESS, approvalOrderCode));
  }
}

export class LoadOrderProcessingReset extends StateUtils.EntityLoaderResetAction {
  readonly type = OrderApprovalActionTypes.LOAD_ORDER_PROCESSING_RESET;

  constructor(orderApprovalCode: string) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_PROCESSING_PROCESS, orderApprovalCode));
  }
}

export class RecoverOrderApproval extends StateUtils.EntityLoadAction {
  readonly type = OrderApprovalActionTypes.RECOVER_ORDER_APPROVAL;

  constructor(public payload: { userId: string; orderApprovalCode: string; cartId: string }) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_RECOVER_PROCESS, payload.orderApprovalCode));
  }
}

export class RecoverOrderApprovalFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderApprovalActionTypes.RECOVER_ORDER_APPROVAL_FAIL;

  constructor(public payload: { orderApprovalCode: string; error?: HttpErrorModel }) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_RECOVER_PROCESS, payload.orderApprovalCode));
  }
}

export class RecoverOrderApprovalSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderApprovalActionTypes.RECOVER_ORDER_APPROVAL_SUCCESS;

  constructor(public payload: string) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_RECOVER_PROCESS, payload));
  }
}

export class RecoverOrderApprovalReset extends StateUtils.EntityLoaderResetAction {
  readonly type = OrderApprovalActionTypes.RECOVER_ORDER_APPROVAL_RESET;

  constructor(orderApprovalCode: string) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_RECOVER_PROCESS, orderApprovalCode));
  }
}

export class AbortOrderApprovalHijack extends StateUtils.EntityLoadAction {
  readonly type = OrderApprovalActionTypes.ABORT_ORDER_APPROVAL_HIJACK;

  constructor(public payload: { userId: string; cartId: string; orderApprovalCode: string }) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_ABORT_HIJACK_PROCESS, payload.cartId));
  }
}

export class AbortOrderApprovalHijackFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderApprovalActionTypes.ABORT_ORDER_APPROVAL_HIJACK_FAIL;

  constructor(public payload: { cartId: string; error?: HttpErrorModel }) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_ABORT_HIJACK_PROCESS, payload.cartId));
  }
}

export class AbortOrderApprovalHijackSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderApprovalActionTypes.ABORT_ORDER_APPROVAL_HIJACK_SUCCESS;

  constructor(public payload: string) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_ABORT_HIJACK_PROCESS, payload));
  }
}

export class AbortOrderApprovalHijackReset extends StateUtils.EntityLoaderResetAction {
  readonly type = OrderApprovalActionTypes.ABORT_ORDER_APPROVAL_HIJACK_RESET;

  constructor(orderApprovalCode: string) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_ABORT_HIJACK_PROCESS, orderApprovalCode));
  }
}

export class DeleteOrderApproval extends StateUtils.EntityLoadAction {
  readonly type = OrderApprovalActionTypes.DELETE_ORDER_APPROVAL;
  constructor(public payload: { userId: string; orderApprovalCode: string }) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_DELETE_PROCESS, payload.orderApprovalCode));
  }
}

export class DeleteOrderApprovalFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderApprovalActionTypes.DELETE_ORDER_APPROVAL_FAIL;

  constructor(public payload: { orderApprovalCode: string; error?: HttpErrorModel }) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_DELETE_PROCESS, payload.orderApprovalCode));
  }
}

export class DeleteOrderApprovalSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderApprovalActionTypes.DELETE_ORDER_APPROVAL_SUCCESS;

  constructor(public payload: { userId: string; orderApprovalCode: string }) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_DELETE_PROCESS, payload.orderApprovalCode));
  }
}

export class LoadApprovedOrderApprovalDetails extends StateUtils.EntityLoadAction {
  readonly type = OrderApprovalActionTypes.LOAD_APPROVED_ORDER_APPROVAL_DETAILS;

  constructor(public payload: { userId: string; sapOrderNumber: string }) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(APPROVED_ORDER_APPROVAL_DETAILS_PROCESS, payload.sapOrderNumber));
  }
}

export class LoadApprovedOrderApprovalDetailsFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderApprovalActionTypes.LOAD_APPROVED_ORDER_APPROVAL_DETAILS_FAIL;

  constructor(public payload: { sapOrderNumber: string; error?: HttpErrorModel }) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(APPROVED_ORDER_APPROVAL_DETAILS_PROCESS, payload.sapOrderNumber));
  }
}

export class LoadApprovedOrderApprovalDetailsSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderApprovalActionTypes.LOAD_APPROVED_ORDER_APPROVAL_DETAILS_SUCCESS;

  constructor(public payload: OrderDetails) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(APPROVED_ORDER_APPROVAL_DETAILS_PROCESS, payload.code));
  }
}

export class LoadApprovedOrderApprovalDetailsReset extends StateUtils.EntityLoaderResetAction {
  readonly type = OrderApprovalActionTypes.LOAD_APPROVED_ORDER_APPROVAL_DETAILS_RESET;

  constructor(orderApprovalCode: string) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(APPROVED_ORDER_APPROVAL_DETAILS_PROCESS, orderApprovalCode));
  }
}

export class LoadOrderApprovalDetails extends StateUtils.EntityLoadAction {
  readonly type = OrderApprovalActionTypes.LOAD_ORDER_APPROVAL_DETAILS;

  constructor(public payload: { userId: string; orderNumber: string }) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_DETAILS_PROCESS, payload.orderNumber));
  }
}

export class LoadOrderApprovalDetailsFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderApprovalActionTypes.LOAD_ORDER_APPROVAL_DETAILS_FAIL;

  constructor(public payload: { orderNumber: string; error?: HttpErrorModel }) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_DETAILS_PROCESS, payload.orderNumber));
  }
}

export class LoadOrderApprovalDetailsSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderApprovalActionTypes.LOAD_ORDER_APPROVAL_DETAILS_SUCCESS;

  constructor(public payload: OrderApproval) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_DETAILS_PROCESS, payload.code));
  }
}

export class LoadOrderApprovalDetailsReset extends StateUtils.EntityLoaderResetAction {
  readonly type = OrderApprovalActionTypes.LOAD_ORDER_APPROVAL_DETAILS_RESET;

  constructor(orderApprovalCode: string) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_DETAILS_PROCESS, orderApprovalCode));
  }
}

export class LoadOrderApprovalSimulation extends StateUtils.EntityLoadAction {
  readonly type = OrderApprovalActionTypes.LOAD_ORDER_APPROVAL_SIMULATION;

  constructor(public payload: { userId: string; orderApprovalCode: string }) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_SIMULATION_PROCESS, payload.orderApprovalCode));
  }
}

export class LoadOrderApprovalSimulationFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderApprovalActionTypes.LOAD_ORDER_APPROVAL_SIMULATION_FAIL;

  constructor(public payload: { orderApprovalCode: string; error?: HttpErrorModel }) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_SIMULATION_PROCESS, payload.orderApprovalCode));
  }
}

export class LoadOrderApprovalSimulationSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderApprovalActionTypes.LOAD_ORDER_APPROVAL_SIMULATION_SUCCESS;

  constructor(public payload: CartSimulation, orderApprovalCode: string) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_SIMULATION_PROCESS, orderApprovalCode));
  }
}

export class LoadOrderApprovalSimulationReset extends StateUtils.EntityLoaderResetAction {
  readonly type = OrderApprovalActionTypes.LOAD_ORDER_APPROVAL_SIMULATION_RESET;

  constructor(orderApprovalCode: string) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_SIMULATION_PROCESS, orderApprovalCode));
  }
}

export class LoadOrderApprovals extends StateUtils.LoaderLoadAction implements PagedSearchAction {
  readonly type = OrderApprovalActionTypes.LOAD_ORDER_APPROVALS;
  readonly searchType = ORDER_APPROVALS;

  constructor(public userId: string, public searchParams: OrderApprovalSearchParams) {
    super(ORDER_APPROVALS);
  }
}

export class LoadOrderApprovalsFail extends PyStateUtils.LoaderFailAction implements PagedSearchAction {
  readonly type = OrderApprovalActionTypes.LOAD_ORDER_APPROVALS_FAIL;
  readonly searchType = ORDER_APPROVALS;

  constructor(public searchParams: OrderApprovalSearchParams, public payload?: any) {
    super(ORDER_APPROVALS, payload);
  }
}

export class LoadOrderApprovalsSuccess extends StateUtils.LoaderSuccessAction implements PagedSearchAction {
  readonly type = OrderApprovalActionTypes.LOAD_ORDER_APPROVALS_SUCCESS;
  readonly searchType = ORDER_APPROVALS;

  constructor(public payload: OrderApprovalSearchResult, public searchParams: OrderApprovalSearchParams) {
    super(ORDER_APPROVALS);
  }
}

export class LoadOrderApprovalsReset extends StateUtils.LoaderResetAction implements PagedSearchAction {
  readonly type = OrderApprovalActionTypes.LOAD_ORDER_APPROVALS_RESET;
  readonly searchType = ORDER_APPROVALS;

  constructor(public searchParams = initialOrderApprovalSearchParams) {
    super(ORDER_APPROVALS);
  }
}

export class LoadOrderApprovalsAdmin extends StateUtils.LoaderLoadAction implements PagedSearchAction {
  readonly type = OrderApprovalActionTypes.LOAD_ORDER_APPROVALS_ADMIN;
  readonly searchType = ORDER_APPROVALS_ADMIN;

  constructor(public userId: string, public searchParams: OrderApprovalSearchParams) {
    super(ORDER_APPROVALS_ADMIN);
  }
}

export class LoadOrderApprovalsAdminFail extends PyStateUtils.LoaderFailAction implements PagedSearchAction {
  readonly type = OrderApprovalActionTypes.LOAD_ORDER_APPROVALS_ADMIN_FAIL;
  readonly searchType = ORDER_APPROVALS_ADMIN;

  constructor(public searchParams: OrderApprovalSearchParams, public payload?: any) {
    super(ORDER_APPROVALS_ADMIN, payload);
  }
}

export class LoadOrderApprovalsAdminSuccess extends StateUtils.LoaderSuccessAction implements PagedSearchAction {
  readonly type = OrderApprovalActionTypes.LOAD_ORDER_APPROVALS_ADMIN_SUCCESS;
  readonly searchType = ORDER_APPROVALS_ADMIN;

  constructor(public payload: OrderApprovalSearchResult, public searchParams: OrderApprovalSearchParams) {
    super(ORDER_APPROVALS_ADMIN);
  }
}

export class LoadOrderApprovalsAdminReset extends StateUtils.LoaderResetAction implements PagedSearchAction {
  readonly type = OrderApprovalActionTypes.LOAD_ORDER_APPROVALS_ADMIN_RESET;
  readonly searchType = ORDER_APPROVALS_ADMIN;

  constructor(public searchParams = initialOrderApprovalSearchParams) {
    super(ORDER_APPROVALS_ADMIN);
  }
}

export class MakeDecision extends StateUtils.EntityLoadAction {
  readonly type = OrderApprovalActionTypes.MAKE_DECISION;

  constructor(
    public payload: {
      userId: string;
      orderApprovalCode: string;
      orderApprovalDecision: OrderApprovalDecision;
    }
  ) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_MAKE_DECISION_PROCESS, payload.orderApprovalCode));
  }
}

export class MakeDecisionFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderApprovalActionTypes.MAKE_DECISION_FAIL;

  constructor(public payload: { orderApprovalCode: string; showErrors: boolean; error?: HttpErrorModel }) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_MAKE_DECISION_PROCESS, payload.orderApprovalCode));
  }
}

export class MakeDecisionSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderApprovalActionTypes.MAKE_DECISION_SUCCESS;

  constructor(
    public payload: {
      orderApprovalCode: string;
      orderApprovalDecision: OrderApprovalDecision;
    }
  ) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_MAKE_DECISION_PROCESS, payload.orderApprovalCode));
  }
}

export class MakeDecisionReset extends StateUtils.EntityLoaderResetAction {
  readonly type = OrderApprovalActionTypes.MAKE_DECISION_RESET;

  constructor(orderApprovalCode: string) {
    super(PROCESS_FEATURE, ProcessIdBuilder.generateId(ORDER_APPROVAL_MAKE_DECISION_PROCESS, orderApprovalCode));
  }
}

export class LoadAvailableOrderApprovalsBuyers extends StateUtils.LoaderLoadAction implements PagedSearchAction {
  readonly type = OrderApprovalActionTypes.LOAD_AVAILABLE_ORDER_APPROVALS_BUYERS;
  readonly searchType = ORDER_APPROVAL_BUYERS;

  constructor(public searchParams: AvailableBuyersSearchParams, public userId: string) {
    super(ORDER_APPROVAL_BUYERS);
  }
}

export class LoadAvailableOrderApprovalsBuyersFail extends PyStateUtils.LoaderFailAction implements PagedSearchAction {
  readonly type = OrderApprovalActionTypes.LOAD_AVAILABLE_ORDER_APPROVALS_BUYERS_FAIL;
  readonly searchType = ORDER_APPROVAL_BUYERS;

  constructor(public searchParams: AvailableBuyersSearchParams, public payload?: any) {
    super(ORDER_APPROVAL_BUYERS, payload);
  }
}

export class LoadAvailableOrderApprovalsBuyersSuccess extends StateUtils.LoaderSuccessAction implements PagedSearchAction {
  readonly type = OrderApprovalActionTypes.LOAD_AVAILABLE_ORDER_APPROVALS_BUYERS_SUCCESS;
  readonly searchType = ORDER_APPROVAL_BUYERS;

  constructor(public payload: any, public searchParams: AvailableBuyersSearchParams) {
    super(ORDER_APPROVAL_BUYERS);
  }
}

export type OrderApprovalAction =
  | LoadOrderApproval
  | LoadOrderApprovalFail
  | LoadOrderApprovalSuccess
  | LoadOrderApprovalReset
  | RecoverOrderApproval
  | RecoverOrderApprovalFail
  | RecoverOrderApprovalSuccess
  | RecoverOrderApprovalReset
  | AbortOrderApprovalHijack
  | AbortOrderApprovalHijackFail
  | AbortOrderApprovalHijackSuccess
  | AbortOrderApprovalHijackReset
  | DeleteOrderApproval
  | DeleteOrderApprovalFail
  | DeleteOrderApprovalSuccess
  | LoadB2BComments
  | LoadB2BCommentsFail
  | LoadB2BCommentsSuccess
  | LoadB2BCommentsReset
  | LoadOrderProcessing
  | LoadOrderProcessingFail
  | LoadOrderProcessingSuccess
  | LoadOrderProcessingReset
  | LoadApprovedOrderApprovalDetails
  | LoadApprovedOrderApprovalDetailsFail
  | LoadApprovedOrderApprovalDetailsSuccess
  | LoadApprovedOrderApprovalDetailsReset
  | LoadOrderApprovalDetails
  | LoadOrderApprovalDetailsFail
  | LoadOrderApprovalDetailsSuccess
  | LoadOrderApprovalSimulation
  | LoadOrderApprovalSimulationFail
  | LoadOrderApprovalSimulationSuccess
  | LoadOrderApprovalSimulationReset
  | LoadOrderApprovals
  | LoadOrderApprovalsFail
  | LoadOrderApprovalsSuccess
  | LoadOrderApprovalsReset
  | LoadOrderApprovalsAdmin
  | LoadOrderApprovalsAdminFail
  | LoadOrderApprovalsAdminSuccess
  | LoadOrderApprovalsAdminReset
  | MakeDecision
  | MakeDecisionFail
  | MakeDecisionSuccess
  | MakeDecisionReset
  | LoadAvailableOrderApprovalsBuyers
  | LoadAvailableOrderApprovalsBuyersFail
  | LoadAvailableOrderApprovalsBuyersSuccess;
