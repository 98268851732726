import { CommonModule } from '@angular/common';
import { APP_BOOTSTRAP_LISTENER, NgModule } from '@angular/core';
import { I18nModule } from '../../../core/i18n';
import { WindowRef } from '../../../core/window';
import { PageTimeCreatedComponent } from './page-time-created.component';
import { PageTimeCreatedFeatureService } from './page-time-created.service';

export function checkoutFeatureServiceFactory(
  pageTimeCreatedFeatureService: PageTimeCreatedFeatureService,
  windowRef: WindowRef
): () => void {
  return windowRef.isBrowser() ? () => pageTimeCreatedFeatureService.init() : () => {};
}

@NgModule({
  imports: [CommonModule, I18nModule],
  declarations: [PageTimeCreatedComponent],
  providers: [
    {
      provide: APP_BOOTSTRAP_LISTENER,
      useFactory: checkoutFeatureServiceFactory,
      deps: [PageTimeCreatedFeatureService, WindowRef],
      multi: true,
    },
  ],
})
export class PageTimeCreatedFeatureModule {}
