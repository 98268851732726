import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BadRequestHandler as CxBadRequestHandler } from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class BadRequestHandler extends CxBadRequestHandler {
  handleError(request: HttpRequest<any>, response: HttpErrorResponse): void {
    // NOTE: We purposefully only handle BadPassword here as we have separate handling of validation errors etc.
    this.handleBadPassword(request, response);
  }
}
