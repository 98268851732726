import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  OccBuyerPermissionAdapter,
  OccPermissionAdapter,
  OccPermissionListNormalizer,
  OccPermissionNormalizer,
} from '../occ/adapters';
import { OccPermissionOrderNormalizer } from '../occ/adapters/b2b/converters/occ-permission-order-normalizer';
import { OccPermissionOrdersListNormalizer } from '../occ/adapters/b2b/converters/occ-permission-orders-list-normalizer';
import { CurrencyService } from '../site-context';
import {
  PERMISSIONS_NORMALIZER,
  PERMISSION_NORMALIZER,
  PERMISSION_ORDERS_NORMALIZER,
  PERMISSION_ORDER_NORMALIZER,
  PermissionAdapter,
} from './connectors';
import { BuyerPermissionAdapter } from './connectors/buyer-permission';

@NgModule({
  declarations: [],
  imports: [],
})
export class PermissionModule {
  static forRoot(): ModuleWithProviders<PermissionModule> {
    return {
      ngModule: PermissionModule,
      providers: [
        {
          provide: PermissionAdapter,
          useClass: OccPermissionAdapter,
        },
        {
          provide: BuyerPermissionAdapter,
          useClass: OccBuyerPermissionAdapter,
        },
        {
          provide: PERMISSION_NORMALIZER,
          useExisting: OccPermissionNormalizer,
          multi: true,
        },
        {
          provide: PERMISSIONS_NORMALIZER,
          useExisting: OccPermissionListNormalizer,
          multi: true,
        },
        {
          provide: PERMISSION_ORDER_NORMALIZER,
          useExisting: OccPermissionOrderNormalizer,
          multi: true,
        },
        {
          provide: PERMISSION_ORDERS_NORMALIZER,
          useExisting: OccPermissionOrdersListNormalizer,
          multi: true,
        },
        CurrencyService,
      ],
    };
  }
}
