import { Pipe, PipeTransform } from '@angular/core';
import { Unit } from '../../core/model';

@Pipe({
  name: 'unit',
})
export class UnitPipe implements PipeTransform {
  transform(input?: Unit, quantity = 0): string {
    if (!input) {
      return '';
    }
    if (quantity === 1) {
      return input.displayNameInSingular;
    } else {
      return input.displayNameInPlural;
    }
  }
}
