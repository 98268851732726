<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="success">
    <div class="success" [ngClass]="title ? 'd-block' : 'd-flex'">
      <ng-container *ngIf="title; else noTitleContent">
        <div class="d-flex"><i nz-icon nzType="check-circle" nzTheme="fill"></i> {{ title }}</div>
        <div><ng-container *ngTemplateOutlet="content"></ng-container></div>
      </ng-container>
      <ng-template #noTitleContent>
        <i nz-icon nzType="check-circle" nzTheme="fill"></i>
        <p><ng-container *ngTemplateOutlet="content"></ng-container></p>
      </ng-template>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="danger">
    <div class="danger" [ngClass]="title ? 'd-block' : 'd-flex'">
      <ng-container *ngIf="title; else noTitleContent">
        <div class="d-flex"><i nz-icon nzType="warning" nzTheme="fill"></i> {{ title }}</div>
        <div><ng-container *ngTemplateOutlet="content"></ng-container></div>
      </ng-container>
      <ng-template #noTitleContent>
        <i nz-icon nzType="warning" nzTheme="fill"></i>
        <p><ng-container *ngTemplateOutlet="content"></ng-container></p>
      </ng-template>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="warning">
    <div class="warning" [ngClass]="title ? 'd-block' : 'd-flex'">
      <ng-container *ngIf="title; else noTitleContent">
        <div class="d-flex"><i nz-icon nzType="exclamation-circle" nzTheme="fill"></i> {{ title }}</div>
        <div><ng-container *ngTemplateOutlet="content"></ng-container></div>
      </ng-container>
      <ng-template #noTitleContent>
        <i nz-icon nzType="exclamation-circle" nzTheme="fill"></i>
        <p><ng-container *ngTemplateOutlet="content"></ng-container></p>
      </ng-template>
    </div>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <div class="info" [ngClass]="title ? 'd-block' : 'd-flex'">
      <ng-container *ngIf="title; else noTitleContent">
        <div class="d-flex"><i nz-icon nzType="info-circle" nzTheme="fill"></i> {{ title }}</div>
        <div><ng-container *ngTemplateOutlet="content"></ng-container></div>
      </ng-container>
      <ng-template #noTitleContent>
        <i nz-icon nzType="info-circle" nzTheme="fill"></i>
        <p><ng-container *ngTemplateOutlet="content"></ng-container></p>
      </ng-template>
    </div>
  </ng-container>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
