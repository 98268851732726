import { StateUtils } from '@spartacus/core';
import { EntriesState, SearchPageResultState, SearchState } from '../../../core/features/store/base-feature-state';
import {
  EcConformityProductDocument,
  EcConformityProductDocumentsSearchParams,
  ProductBrand,
  ProductDocumentsWrapper,
} from '../../../core/model';

export const DOCUMENTS_FEATURE = 'documents';

export const PRODUCT_DOCUMENTS = '[Documents] Product Documents';
export const CONFORMANCE_EC_SEARCH = '[Documents] Conformance Ec Search';
export const CONFORMANCE_EC_BRANDS = '[Documents] Conformance Ec Brands';

export interface StateWithDocuments {
  [DOCUMENTS_FEATURE]: DocumentsState;
}

export interface DocumentsState {
  productDocuments: StateUtils.EntityLoaderState<ProductDocumentsWrapper>;
  conformanceEc: ConformanceEcState;
}

export interface ConformanceEcState {
  search: SearchState<SearchPageResultState>;
  searchParams: EcConformityProductDocumentsSearchParams;
  entities: EntriesState<EcConformityProductDocument>;
  brands: StateUtils.LoaderState<ProductBrand[]>;
}
