import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  B2BPermissionOrderListItem,
  EntitiesModel,
  Permission,
  PermissionConnectionTypeEnum,
  PermissionConnections,
  PermissionOrdersSearchParams,
  SearchConfig,
  SoldTo,
} from '../../../model';
import { PermissionAdapter } from './permission.adapter';

@Injectable({
  providedIn: 'root',
})
export class PermissionConnector {
  constructor(protected adapter: PermissionAdapter) {}

  get(userId: string, permissionCode: string): Observable<Permission> {
    return this.adapter.load(userId, permissionCode);
  }

  getList(userId: string, params?: SearchConfig): Observable<EntitiesModel<Permission>> {
    return this.adapter.loadList(userId, params);
  }

  getPermissionOrders(
    permissionCode: string,
    userId: string,
    params?: PermissionOrdersSearchParams
  ): Observable<EntitiesModel<B2BPermissionOrderListItem>> {
    return this.adapter.getPermissionOrders(permissionCode, userId, params);
  }

  getUserPermissions(userId: string, params?: SearchConfig): Observable<EntitiesModel<Permission>> {
    return this.adapter.getUserPermissions(userId, params);
  }

  create(userId: string, permission: Permission): Observable<Permission> {
    return this.adapter.create(userId, permission);
  }

  update(userId: string, permissionCode: string, permission: Permission): Observable<Permission> {
    return this.adapter.update(userId, permissionCode, permission);
  }

  updatePermissionContactPerson(userId: string, permissionCode: string, contactPersonUid: string): Observable<void> {
    return this.adapter.updatePermissionContactPerson(userId, permissionCode, contactPersonUid);
  }

  publishPermission(userId: string, permissionCode: string): Observable<Permission> {
    return this.adapter.publishPermission(userId, permissionCode);
  }

  unpublishPermission(userId: string, permissionCode: string): Observable<Permission> {
    return this.adapter.unpublishPermission(userId, permissionCode);
  }

  delete(userId: string, permissionCode: string): Observable<void> {
    return this.adapter.delete(userId, permissionCode);
  }

  getPermissionConnections(
    connectionType: PermissionConnectionTypeEnum,
    userId: string,
    permissionCode: string
  ): Observable<PermissionConnections> {
    return this.adapter.getPermissionConnections(connectionType, userId, permissionCode);
  }

  updatePermissionConnections(
    connectionType: PermissionConnectionTypeEnum,
    userId: string,
    permissionCode: string,
    connections: PermissionConnections
  ): Observable<void> {
    return this.adapter.updatePermissionConnections(connectionType, userId, permissionCode, connections);
  }

  deletePermissionConnections(
    connectionType: PermissionConnectionTypeEnum,
    userId: string,
    permissionCode: string,
    connections: PermissionConnections
  ): Observable<void> {
    return this.adapter.deletePermissionConnections(connectionType, userId, permissionCode, connections);
  }

  getFilteredSoldTos(searchParams: SearchConfig, userId: string): Observable<EntitiesModel<SoldTo>> {
    return this.adapter.getFilteredSoldTos(searchParams, userId);
  }
}
