import { MemoizedSelector, createSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';
import { SiteFamilyConfig } from '../../../model';
import { PyStateUtils } from '../../../state';
import { StateWithUser, UserState } from '../user-state';
import { getUserState } from './feature.selector';

export const getSiteFamilyConfigLoaderState: MemoizedSelector<
  StateWithUser,
  StateUtils.LoaderState<SiteFamilyConfig>
> = createSelector(getUserState, (state: UserState) => (state && state.siteFamilyConfig) || PyStateUtils.initialLoaderState);

export const getSiteFamilyConfigLoading: MemoizedSelector<StateWithUser, boolean> = createSelector(
  getSiteFamilyConfigLoaderState,
  (state: StateUtils.LoaderState<SiteFamilyConfig>) => StateUtils.loaderLoadingSelector(state)
);

export const getSiteFamilyConfigLoaded: MemoizedSelector<StateWithUser, boolean> = createSelector(
  getSiteFamilyConfigLoaderState,
  (state: StateUtils.LoaderState<SiteFamilyConfig>) => StateUtils.loaderSuccessSelector(state)
);

export const getSiteFamilyConfig: MemoizedSelector<StateWithUser, SiteFamilyConfig> = createSelector(
  getSiteFamilyConfigLoaderState,
  (state: StateUtils.LoaderState<SiteFamilyConfig>) => StateUtils.loaderValueSelector(state)
);
