<ng-container [formGroup]="group">
  <div *ngIf="tooltip || label" class="mb-2 d-flex align-items-baseline">
    <label class="input-label-content" *ngIf="label" [htmlFor]="'input-' + fieldName">
      {{ label }}
      {{ optional && showOptionalHint ? ('common.optionalWithinParentheses_hint' | cxTranslate) : '' }}
    </label>
    <ng-content select="[slot=label-extra-content]"></ng-content>
    <div class="flex-grow-0"></div>
    <div class="ml-2 tooltip-icon input-label-content" [id]="'tooltip-' + fieldName" *ngIf="tooltip">
      <i nz-icon nzType="question-circle" [ngbTooltip]="tooltip"></i>
    </div>
  </div>
  <div
    tabindex="-1"
    class="form-control p-0 d-flex align-items-center justify-content-between reactive-form-input-field-holder"
    [class.mb-2]="!hideErrorMessages"
    [class.hide-focus]="!showFocusState"
    [class.is-valid]="showSuccessState && !isInvalid() && group.controls[fieldName].dirty"
    [class.is-invalid]="isInvalid() || (showTouchedFieldAsInvalid && isInvalidForTouchedField())"
    [class.is-disabled]="isDisabled()"
    [ngClass]="'input-type-effects-' + inputType"
    (focus)="isFocused = true"
    (blur)="isFocused = false"
  >
    <ng-container *ngIf="originalType === 'password'">
      <py-toggle-password-visibility [isVisible]="inputType !== 'password'" (toggled)="togglePasswordType()">
      </py-toggle-password-visibility>
    </ng-container>
    <ng-container *ngIf="originalType !== 'password' && icon">
      <i class="pl-3 pr-2 py-color-black input-icon" nz-icon [nzType]="icon"></i>
    </ng-container>
    <input
      [id]="'input-' + fieldName"
      [type]="inputType"
      class="border-0 w-100 h-100"
      [class.is-invalid]="isInvalid()"
      [class.pl-3]="!icon"
      [class.pl-1]="icon"
      [formControlName]="fieldName"
      [attr.maxLength]="maxLength || null"
      (keydown.enter)="enterPressed($event)"
      [placeholder]="placeholder"
      [attr.aria-describedby]="description ? 'description-' + fieldName : null"
    />
    <button
      *ngIf="resettable && !!getField().value"
      type="button"
      class="btn-icon resettable-button"
      [class.focused]="isFocused"
      (click)="handleReset.emit()"
      tabindex="-1"
    >
      <i nz-icon nzType="close-circle"></i>
    </button>
    <span *ngIf="subtext" class="subtext">{{ subtext }}</span>
  </div>
  <small [id]="'description-' + fieldName" *ngIf="description" class="form-text text-muted">
    {{ description }}
  </small>
  <div
    *ngIf="!hideErrorMessages"
    [id]="'errors-' + fieldName"
    class="invalid-feedback"
    [ngClass]="{ 'instant-feedback': showInstantErrors || (showTouchedFieldAsInvalid && group.controls[fieldName].touched) }"
  >
    {{ errors() | inputError }}
  </div>
  <span *ngIf="maxLength && fieldValueLength && showMaxLengthHint" class="max-length"
    >{{ 'common.maxCharsCount_hint' | cxTranslate }} :
    <span [ngClass]="{ 'text-danger': fieldValueLength >= maxLength }">{{ fieldValueLength }}</span> ({{ maxLength }})</span
  >
</ng-container>
