import { Injectable } from '@angular/core';
import {
  Article,
  ArticleInlineAttribute,
  ArticleStatus,
  CartType,
  ClassificationAttribute,
  EntityMap,
  PackagingType,
  PrePrintedConfigurationWithCountry,
} from '../../../model';
import { Occ } from '../../../occ';
import { hasRequiredProperties } from '../../../util';
import { Converter, ConverterService } from '../../../util/converter.service';
import { CLASSIFICATION_ATTRIBUTE_NORMALIZER } from './converters';

@Injectable({ providedIn: 'root' })
export class ArticleNormalizer implements Converter<Occ.Article, Article> {
  constructor(private converter: ConverterService) {}

  convert(source: Occ.Article, target?: Article): Article {
    if (source) {
      const { classificationAttributes, inlineAttributes, units, displayNewArticleOverlay, ...src } = source;
      const convertedAttributes = this.converter.convertMany(classificationAttributes, CLASSIFICATION_ATTRIBUTE_NORMALIZER) || [];
      const attributeMap = {} as EntityMap<ClassificationAttribute>;
      convertedAttributes.forEach((attribute) => (attributeMap[attribute.code] = attribute));

      return {
        ...src,
        units: units
          ? units.reduce(
              (uniqueUnits, unit) =>
                uniqueUnits.find((uniqueUnit) => uniqueUnit.code === unit.code) ? uniqueUnits : [...uniqueUnits, unit],
              []
            )
          : [],
        articleStatus: src.articleStatus as ArticleStatus,
        classificationAttributes: attributeMap,
        inlineAttributes: this.convertInlineAttributes(inlineAttributes),
        overlayNew: displayNewArticleOverlay,
        replaces: source.replaces,
        alternatives: source.alternatives,
        cartType: this.getArticleCartType(source),
        packaging: PackagingType[source.packaging],
        articlePrePrintedConfigurationWithCountry: this.convertArticlePrePrintedConfigurationWithCountry(
          source.articlePrePrintedConfigurationWithCountry
        ),
      };
    }

    return target;
  }

  private convertArticlePrePrintedConfigurationWithCountry(data: string[]): PrePrintedConfigurationWithCountry[] {
    return data?.map((config) => {
      const [configuration, country] = config.split('|');
      return { label: country, value: configuration };
    });
  }

  private convertInlineAttributes(source: Occ.PresentationAttribute[]): ArticleInlineAttribute[] | undefined {
    return source?.map((attribute) => this.convertInlineAttribute(attribute)).filter((attribute) => attribute != null);
  }

  private convertInlineAttribute(source: Occ.PresentationAttribute): ArticleInlineAttribute | undefined {
    return hasRequiredProperties(source, ['code']) ? source : undefined;
  }

  private getArticleCartType(article: Occ.Article): CartType {
    if (article.consignmentStock) {
      return CartType.consignmentStock;
    } else if (article.freeStock) {
      return CartType.freeStock;
    } else if (article.invoicedStock) {
      return CartType.invoicedStock;
    } else {
      return CartType.stock;
    }
  }
}
