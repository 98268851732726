import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, makeStateKey, PLATFORM_ID, TransferState } from '@angular/core';
import { I18nConfig, OccEndpointsService, TranslationResources } from '@spartacus/core';

/**
 * Transfer translations from SSR context to browser so we can initialize i18next with real data as early as possible.
 */
@Injectable({ providedIn: 'root' })
export class I18nStateTransferService {
  private stateKey = makeStateKey<TranslationResources>('i18n');

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private transferState: TransferState,
    private occEndpointsService: OccEndpointsService,
    private config: I18nConfig
  ) {}

  updateTranslationResourceData(url: string, dataJson: string): void {
    if (isPlatformServer(this.platformId)) {
      // extract language and namespace from URL
      const loadPath = `${this.occEndpointsService.buildUrl('translation')}/${this.config.i18n.backend.loadPath}?lang={{lng}}`;
      const loadPathRegexString = loadPath
        .replace(/[\-\[\]\\()^$*+?|]/g, '\\$&')
        .replace('{{ns}}', '(?<ns>\\w+)')
        .replace('{{lng}}', '(?<lng>\\w+)')
        .replace(/[{}]/g, '\\$&');
      const translationUrlRegex = new RegExp(`^${loadPathRegexString}`);
      const result = translationUrlRegex.exec(url);
      if (result?.groups) {
        const { ns, lng } = result.groups;
        const state = this.transferState.get(this.stateKey, {});
        this.transferState.set(this.stateKey, { ...state, [lng]: { ...(state[lng] ?? {}), [ns]: JSON.parse(dataJson) } });
      }
    }
  }

  getTranslationResources(): TranslationResources {
    return this.transferState.get(this.stateKey, {});
  }
}
