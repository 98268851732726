import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Product, SolrResultEntityRef, SubstituteRef, SubstituteType } from '../../../core/model';

@Component({
  selector: 'py-product-list-item-loader',
  templateUrl: './product-list-item-loader.component.html',
  styleUrls: ['./product-list-item-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductListItemLoaderComponent implements OnInit {
  products$: Observable<Array<Product>>;
  private indexMap;

  constructor() {}

  @Input() productResultRefs: SolrResultEntityRef[];

  @Input()
  set products(products: Array<Observable<Product>> | Observable<Array<Product>>) {
    if (products instanceof Observable) {
      this.products$ = products.pipe(tap(this.populateIndexMap()));
    } else {
      this.products$ = combineLatest(products).pipe(tap(this.populateIndexMap())) as Observable<Array<Product>>;
    }
  }

  @Input() queryParams: {
    [k: string]: any;
  };

  @Input() substituteRefs: SubstituteRef[];

  @HostBinding('class.product-list-item-loader-secondary')
  @Input()
  isSecondaryVersion: boolean;

  private populateIndexMap() {
    return (list) => {
      this.indexMap = {};
      for (let i = 0; i < list.length; i++) {
        if (!list[i]) {
          this.indexMap[i] = Object.values(this.indexMap).length + 1;
        }
      }
    };
  }

  ngOnInit(): void {}

  calculateDelay(index: number): number {
    return this.indexMap[index] ?? 0;
  }

  getSubstituteBadgeType(substituteCode: string): SubstituteType {
    return this.substituteRefs?.find((substitute) => substitute.code === substituteCode)?.substituteType;
  }

  getProductResultRef(productCode: string): SolrResultEntityRef {
    return this.productResultRefs?.find((productResultRef) => productResultRef.ref === productCode);
  }
}
