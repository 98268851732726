<header class="mb-4">
  <div>
    <ng-content></ng-content>
  </div>
  <ng-container *ngIf="!showPaginationAtTheBottom" [ngTemplateOutlet]="paginationTemplate"></ng-container>
</header>

<main #main>
  <div *ngIf="hideOverflow" [class.gradient-left]="canScrollBack"></div>
  <div class="h-100 position-relative" [class.hide-overflow]="hideOverflow">
    <ul #list (swipeleft)="onSwipeLeft()" (swiperight)="onSwipeRight()">
      <li
        [attr.ticket]="item?.ticket"
        *ngFor="let item of items; let i = index"
        [style.margin-right]="listItemMarginPx + 'px'"
        #listItem
      >
        <ng-container *ngTemplateOutlet="listItemContentTemplate; context: { $implicit: item, index: i }"></ng-container>
      </li>
      <li *ngIf="!!browseReplacementUrl" [style.margin-right]="listItemMarginPx + 'px'" #listItem>
        <div class="browse-replacement-item">
          <i class="no-options-icon" nz-icon nzType="no-options"></i>
          <h5 class="my-3">{{ 'common.browseReplacement_title' | cxTranslate }}</h5>
          <p class="mb-3">
            {{ 'common.browseReplacement_text' | cxTranslate }}
          </p>
          <cx-generic-link [url]="browseReplacementUrl">
            {{ 'common.browseReplacement_action' | cxTranslate }}
            <i class="ml-1" nz-icon nzType="arrow-right"></i>
          </cx-generic-link>
        </div>
      </li>
    </ul>
  </div>
  <div *ngIf="hideOverflow" [class.gradient-right]="canScrollForward"></div>
</main>

<footer *ngIf="showPaginationAtTheBottom">
  <ng-container [ngTemplateOutlet]="paginationTemplate"></ng-container>
</footer>

<ng-template #paginationTemplate>
  <div class="pagination">
    <button #backPagination class="pagination-control btn btn-icon" disabled (click)="onScrollBack()">
      <i nz-icon nzType="left-circle"></i>
    </button>
    <button #forwardPagination class="pagination-control btn btn-icon" disabled (click)="onScrollForward()">
      <i nz-icon nzType="right-circle"></i>
    </button>
  </div>
</ng-template>
