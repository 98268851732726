<!-- Discontinued Article Header -->
<ng-container *ngIf="discontinuedArticleViewType === discontinuedArticleViewTypes.Article">
  <div class="d-flex flex-column flex-md-row py-2">
    <ng-container [ngTemplateOutlet]="discontinuedArticleSubmitTemplate"></ng-container>

    <div
      *ngIf="enableTogglingOfCustomerAssortment"
      class="d-flex align-items-center justify-content-start justify-content-md-end flex-grow-1 contracted-articles-toggle"
    >
      <label [htmlFor]="'showOnlyStarred'"
        >{{ 'article.onlyContractedArticles_heading' | cxTranslate }}
        <i nz-icon nzType="contracted-article" class="ml-1"></i>
      </label>

      <py-reactive-form-checkbox-slider [group]="form" [fieldName]="'showOnlyStarred'"></py-reactive-form-checkbox-slider>
    </div>
  </div>
</ng-container>

<!-- Discontinued Product Header -->
<ng-container *ngIf="discontinuedArticleViewType === discontinuedArticleViewTypes.Product">
  <div class="d-flex flex-column flex-md-row py-2">
    <ng-container [ngTemplateOutlet]="discontinuedArticleSubmitTemplate"></ng-container>
  </div>
</ng-container>

<py-notification [type]="'info'" [showBorder]="false">
  <span
    [innerHTML]="
      'catalog.hasDiscontinued_hint' | cxTranslate : { code: discontinuedArticle?.code, name: discontinuedArticle?.displayName }
    "
  ></span>
</py-notification>

<ng-template #discontinuedArticleSubmitTemplate>
  <div class="d-flex justify-content-start discontinued-article-header">
    <button (click)="onDiscontinuedArticleSubmit()" class="btn-link py-3">
      {{ 'catalog.viewAllResults_action' | cxTranslate }}
      <i nz-icon nzType="arrow-right" class="ml-2"></i>
    </button>
  </div>
</ng-template>
