import { Action } from '@ngrx/store';
import { EntriesState } from '../../../features/store/base-feature-state';
import { OrderCard, OrderCardPage } from '../../../model';
import { UserActions } from '../actions';
import { OrderCardsActionTypes } from '../actions/order-cards.action';

export const initialState: EntriesState<OrderCard> = {};

export function reducer(state = initialState, action: Action): EntriesState<OrderCard> {
  switch (action.type) {
    case OrderCardsActionTypes.SearchOrderCardsSuccess: {
      const _action = <UserActions.SearchOrderCardsSuccess>action;
      const payload: OrderCardPage = _action.payload.searchResult;
      const results = payload.results;
      return results.reduce(
        (entities: { [id: number]: OrderCard }, orderCard: OrderCard) => {
          return {
            ...entities,
            [orderCard.code]: orderCard,
          };
        },
        {
          ...state,
        }
      );
    }
    default:
      return state;
  }
}
