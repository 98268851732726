import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CartType } from '../../../../../core/model';

@Component({
  selector: 'py-remaining-cart-message-modal',
  templateUrl: './remaining-cart-message-modal.component.html',
  styleUrls: ['./remaining-cart-message-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemainingCartMessageModalComponent {
  @Input() queryParams: Record<string, CartType>;
  @Output() skipModal = new EventEmitter<void>();

  skip(): void {
    this.skipModal.emit();
  }
}
