import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ComponentHandler, DefaultComponentHandler, LazyComponentHandler } from '@spartacus/storefront';
import { ComponentWrapperDirective } from './component-wrapper.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ComponentWrapperDirective],
  exports: [ComponentWrapperDirective],
})
export class PageComponentModule {
  static forRoot(): ModuleWithProviders<PageComponentModule> {
    return {
      ngModule: PageComponentModule,
      providers: [
        {
          provide: ComponentHandler,
          useExisting: DefaultComponentHandler,
          multi: true,
        },
        {
          provide: ComponentHandler,
          useExisting: LazyComponentHandler,
          multi: true,
        },
      ],
    };
  }
}
