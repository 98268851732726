import { StateUtils } from '@spartacus/core';
import { OrderCardConfiguration } from '../../../model';
import { UserActions } from '../actions/index';
import { OrderCardsAdminActionTypes } from '../actions/order-cards-admin.action';
import { OrderCardsActionTypes } from '../actions/order-cards.action';

export const initialState: OrderCardConfiguration | undefined = undefined;

export function reducer(
  state: OrderCardConfiguration = initialState,
  action: UserActions.OrderCardsActions | UserActions.OrderCardsAdminActions | StateUtils.LoaderAction
): OrderCardConfiguration {
  switch (action.type) {
    case OrderCardsActionTypes.LoadOrderCardConfigurationFail:
    case OrderCardsAdminActionTypes.LoadOrderCardAdminConfigurationFail: {
      return initialState;
    }
    case OrderCardsActionTypes.LoadOrderCardConfigurationSuccess:
    case OrderCardsAdminActionTypes.LoadOrderCardAdminConfigurationSuccess: {
      return action.payload ? action.payload : initialState;
    }
    case OrderCardsActionTypes.UpdateOrderCardConfigurationSuccess:
    case OrderCardsAdminActionTypes.UpdateOrderCardAdminConfigurationSuccess: {
      return action.payload;
    }
  }

  return state;
}
