<div class="d-flex align-items-start">
  <i nz-icon nzType="idcard" nzTheme="fill" class="mx-2"></i>
  <div class="d-flex flex-column w-100" [ngbTooltip]="'common.nameMarking.nameMarking_tooltip' | cxTranslate">
    <span class="label">{{ 'common.nameMarking_hint' | cxTranslate }}</span>
    <ul>
      <ng-container *ngIf="names?.length > 0; else noNamesTemplate">
        <li *ngFor="let name of names">{{ name }}</li>
      </ng-container>
    </ul>
  </div>
</div>

<ng-template #noNamesTemplate>
  <li class="py-color-danger">
    <span>{{ 'common.nameMarking.noneAdded_hint' | cxTranslate }}</span>
    <i nz-icon nzType="warning" nzTheme="fill" class="ml-2"></i>
  </li>
</ng-template>
