import { Component, Input } from '@angular/core';
import { SolrSearchResult } from '../../../../../core/model';
import { SearchResultsContentBaseComponent } from '../search-results-content-base/search-results-content-base.component';

@Component({
  selector: 'py-search-results-content-primary',
  templateUrl: './search-results-content-primary.component.html',
  styleUrls: ['./search-results-content-primary.component.scss'],
})
export class SearchResultsContentPrimaryComponent extends SearchResultsContentBaseComponent {
  @Input() searchResults: SolrSearchResult;

  constructor() {
    super();
  }
}
