import { DeferLoadingStrategy } from '@spartacus/core';
import { LayoutConfig } from '@spartacus/storefront';

export const defaultLayoutConfig: LayoutConfig = {
  deferredLoading: {
    strategy: DeferLoadingStrategy.DEFER,
    intersectionMargin: '50px',
  },
  breakpoints: {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: {
      min: 1200,
    },
  },
  layoutSlots: {
    alerts: {
      slots: ['OperationalMessage', 'Alerts'],
    },
    header: {
      md: {
        pageFold: 'DesktopNavigation',
        slots: ['BrandLogo', 'MobileNavigation', 'DesktopNavigation'],
      },
      sm: {
        pageFold: 'MobileNavigation',
        slots: ['BrandLogo', 'MobileNavigation', 'DesktopNavigation'],
      },
      xs: {
        pageFold: 'MobileNavigation',
        slots: ['BrandLogo', 'MobileNavigation', 'DesktopNavigation'],
      },
    },
    footer: {
      slots: ['FooterLogo'],
    },
    heroBanner: {
      slots: ['Banner'],
    },
    // Py templates below which are mapped to the bazaar world, pascal case!
    LandingPageTemplate: {
      pageFold: 'StartBanner',
      slots: ['StartBanner', 'StartCategoryPuffs', 'StartContent', 'EditableStartContent'],
    },
    LoginPageTemplate: {
      slots: ['Section1'],
    },
    LogoutPageTemplate: {
      slots: ['Section1'],
    },
    BlankPageTemplate: {
      slots: ['Splash'],
    },
    MyPageTemplate: {
      md: {
        slots: ['Section1A', 'Section1B'],
      },
      sm: {
        slots: ['Section1B'],
      },
      xs: {
        slots: ['Section1B'],
      },
    },
    ContentPageTemplate: {
      slots: ['Content', 'Section1'],
    },
    ContentPageTemplate2ColsWide: {
      slots: ['Content_1', 'Content_2', 'BannerWide_1', 'Content_3', 'BannerWide_2', 'Content_4'],
    },
    ContentPageTemplateWide: {
      slots: ['Content', 'BannerWide_1', 'Content_2', 'BannerWide_2', 'Content_3'],
    },
    ContentPageTemplate2Cols: {
      slots: ['Content_1', 'Content_2', 'Section1A', 'Section1B'],
    },
    ContentPageTemplate3Cols: {
      slots: ['Content_1', 'Content_2', 'Content_3', 'Section1A', 'Section1B', 'Section1C'],
    },
    CatalogPageTemplate: {
      slots: ['Section1'],
    },
    PasswordResetPageTemplate: {
      slots: ['Section1'],
    },
    RegistrationPageTemplate: {
      slots: ['Section1'],
    },
    BrandLandingPageTemplate: {
      slots: ['Section1', 'Section2A', 'Section2B', 'Section3'],
    },
    ErrorPageTemplate: {
      slots: ['Content'],
    },
    CheckoutPageTemplate: {
      slots: ['Section1'],
    },
    OrderConfirmationPageTemplate: {
      slots: ['Section1'],
    },
    PaymentProcessingPageTemplate: {
      slots: ['Section1'],
    },
    CartPageTemplate: {
      slots: ['Section1'],
    },
    // Py sections, camel case!
    uniqueSellingPointServicesFooter: {
      slots: ['slot1', 'slot2', 'slot3', 'slot4'],
    },
    extendedFooter: {
      slots: ['Footer'],
    },
  },
};
