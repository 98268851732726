import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CartType } from '../../../core/model';

@Component({
  selector: 'py-cart-overlay-message',
  templateUrl: './cart-overlay-message.component.html',
  styleUrls: ['./cart-overlay-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartOverlayMessageComponent implements OnInit {
  @Input() expanded = false;
  @Input() expandable = true;
  @Input() message: string;
  @Input() hideCartLink = false;
  @Input() iconLabel: string;

  @Output() expandedChange = new EventEmitter<boolean>();

  constructor() {}

  get cartLinkQueryParams() {
    return { cartType: CartType.stock };
  }

  ngOnInit(): void {}

  onToggleExpanded() {
    if (!this.expandable) {
      return;
    }
    this.expandedChange.emit(!this.expanded);
  }
}
