import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../core/i18n';
import { IconThemeReverseModule, ScrollStateModule } from '../../directives';
import { ActionButtonModule } from '../action-button';
import { ModalComponent } from './modal.component';

@NgModule({
  imports: [CommonModule, NzIconModule, I18nModule, ActionButtonModule, ScrollStateModule, IconThemeReverseModule],
  declarations: [ModalComponent],
  exports: [ModalComponent],
})
export class ModalModule {}
