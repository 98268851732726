import { StateUtils } from '@spartacus/core';
import { OrderCard } from '../../../model';
import { UserActions } from '../actions/index';
import { OrderCardsAdminActionTypes } from '../actions/order-cards-admin.action';

export const initialState: OrderCard | undefined = undefined;

export function reducer(
  state: OrderCard = initialState,
  action: UserActions.OrderCardsAdminActions | StateUtils.LoaderAction
): OrderCard {
  switch (action.type) {
    case OrderCardsAdminActionTypes.LoadOrderCardAdminFail: {
      return initialState;
    }
    case OrderCardsAdminActionTypes.LoadOrderCardAdminSuccess: {
      return action.payload ? action.payload : initialState;
    }

    case OrderCardsAdminActionTypes.AddOrderCardAdminPrincipalSuccess:
    case OrderCardsAdminActionTypes.DeleteOrderCardAdminPrincipalSuccess:
    case OrderCardsAdminActionTypes.AddOrderCardAdminProductSuccess:
    case OrderCardsAdminActionTypes.ReplaceOrderCardAdminProductSuccess:
    case OrderCardsAdminActionTypes.AddOrderCardAdminArticlesSuccess:
    case OrderCardsAdminActionTypes.DeleteOrderCardAdminProductSuccess: {
      return action.payload;
    }

    case OrderCardsAdminActionTypes.UpdateOrderCardAdminSuccess: {
      const _action = <UserActions.UpdateOrderCardAdminSuccess>action;
      const orderCard: OrderCard = _action.payload.orderCard;
      return orderCard;
    }

    case OrderCardsAdminActionTypes.UpdateOrderCardAdminStatusSuccess: {
      const _action = <UserActions.UpdateOrderCardAdminStatusSuccess>action;
      const orderCard: OrderCard = _action.payload.orderCard;
      return orderCard;
    }

    case OrderCardsAdminActionTypes.UpdateOrderCardAdminPrincipalsSuccess: {
      const _action = <UserActions.UpdateOrderCardAdminPrincipalsSuccess>action;
      const orderCard: OrderCard = _action.payload.orderCard;
      return orderCard;
    }

    case OrderCardsAdminActionTypes.UpdateOrderCardAdminProductsSuccess: {
      const _action = <UserActions.UpdateOrderCardAdminProductsSuccess>action;
      const orderCard: OrderCard = _action.payload.orderCard;
      return orderCard;
    }
  }

  return state;
}
