import { AsmConfig } from '@spartacus/asm/root';

export const defaultAsmConfig: AsmConfig = {
  asm: {
    agentSessionTimer: {
      startingDelayInSeconds: 1200,
    },
    customerSearch: {
      maxResults: 20,
    },
  },
};
