<ng-container *ngIf="products$ | async as products">
  <ng-container *ngFor="let product of products; let i = index">
    <py-catalog-product-list-item
      *ngIf="!!product"
      [id]="product.code"
      [product]="product"
      [productResultRef]="getProductResultRef(product.code)"
      [substituteBadgeType]="getSubstituteBadgeType(product.code)"
      [queryParams]="queryParams"
      [isSecondaryVersion]="isSecondaryVersion"
      class="catalog-list-item"
      [ngClass]="isSecondaryVersion ? 'catalog-list-item-secondary mb-2' : 'px-4 mb-8'"
    ></py-catalog-product-list-item>
    <div
      *ngIf="!product"
      class="catalog-list-item"
      style="min-height: 200px"
      [ngClass]="isSecondaryVersion ? 'catalog-list-item-secondary mb-2' : 'px-4 mb-4'"
    >
      <div [class]="'skeleton' + calculateDelay(i)" class="skeleton h-100 w-100"></div>
    </div>
  </ng-container>
</ng-container>
