import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'randomId' })
export class RandomIdPipe implements PipeTransform {
  transform(input: string): string {
    const prefix = input ? `${input}-` : '';
    const id = prefix + Math.random().toString(36).substr(2, 9);
    return id;
  }
}
