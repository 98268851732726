import { InjectionToken } from '@angular/core';
import { AlternativeArticles, Article, ClassificationAttribute, Substitutes } from '../../../model';
import { Occ } from '../../../occ';
import { Converter } from '../../../util/converter.service';

export const ARTICLE_NORMALIZER = new InjectionToken<Converter<Occ.Article, Article>>('ArticleNormalizer');
export const SIMILAR_ARTICLES_NORMALIZER = new InjectionToken<Converter<Occ.AlternativeArticles, AlternativeArticles>>(
  'SimilarArticlesNormalizer'
);
export const SUBSTITUTES_NORMALIZER = new InjectionToken<Converter<Occ.Substitutes, Substitutes>>('SubstitutesNormalizer');

export const CLASSIFICATION_ATTRIBUTE_NORMALIZER = new InjectionToken<
  Converter<Occ.ClassificationAttribute, ClassificationAttribute>
>('ClassificationAttributeNormalizer');
