<ng-container *ngIf="carouselItems; else loadingTemplate">
  <py-carousel
    [items]="carouselItems"
    [listItemContentTemplate]="carouselItem"
    [hideOverflow]="hideOverflow"
    [showPaginationAtTheBottom]="showPaginationAtTheBottom"
  >
    <ng-content></ng-content>
  </py-carousel>

  <ng-template #carouselItem let-item let-index="index">
    <div class="article d-flex flex-column">
      <div class="article-data d-flex flex-column mb-2">
        <py-article-image
          [images]="item.article?.image && [item.article.image]"
          [loading]="loading || !item.article"
          [altText]="item?.article?.articleName || item?.article?.productName"
          class="skeleton w-auto mb-3 px-0"
        ></py-article-image>
        <div class="skeleton flex-grow-1 d-flex flex-column justify-content-between">
          <ng-container *ngIf="!loading && item.article">
            <div>
              <py-article-info
                class="d-block mb-2"
                [article]="item.article"
                [substituteBadgeType]="getSubstituteBadgeType(item.article.code)"
                [enableSecondaryArticleRowVariant]="enableSecondaryArticleRowVariant"
              ></py-article-info>
              <ng-container
                *ngIf="{
                  columnAttributes: getColumnAttributesForArticle(item.article) | async
                } as data"
              >
                <py-article-info-attributes
                  *ngIf="data.columnAttributes?.length > 0"
                  class="align-self-stretch d-block"
                  [columnAttributes]="data.columnAttributes"
                  [enableSecondaryArticleRowVariant]="enableSecondaryArticleRowVariant"
                  [multipleAttributesPerRow]="false"
                ></py-article-info-attributes>
              </ng-container>
            </div>

            <ng-container [ngTemplateOutlet]="qunatityAndPriceTemplate"></ng-container>
          </ng-container>
        </div>
      </div>
      <button
        *ngIf="carouselActionMode === carouselActionModes.ENTRY_INTERACTION_MODE"
        class="btn btn-primary btn-block"
        [disabled]="disabled"
        [ngbTooltip]="tooltipMessage"
        [disableTooltip]="!tooltipMessage"
        (click)="onArticleInteraction(item)"
      >
        <i *ngIf="iconType" nz-icon [nzType]="iconType"></i>
        {{ actionLabel | cxTranslate }}
      </button>
    </div>

    <ng-template #qunatityAndPriceTemplate>
      <ng-container *ngIf="useDefaultArticleQuantityAndUnit; else entryQuantityAndUnitTemplate">
        <py-article-carousel-item-customizable
          [article]="item.article"
          [carouselActionMode]="carouselActionMode"
        ></py-article-carousel-item-customizable>
      </ng-container>

      <ng-template #entryQuantityAndUnitTemplate>
        <ng-container *ngIf="item.entry && item.entry.totalPrice && item.entry.quantity && item.entry.unit">
          <div class="d-flex flex-column align-items-end mb-1">
            <p class="quantity">
              {{
                'common.quantityWithParams_heading'
                  | cxTranslate : { quantity: item.entry.quantity, unit: item.entry.unit | unit : item.entry.quantity }
              }}
            </p>

            <p class="price">{{ item.entry.totalPrice | price : 'symbol-narrow' : true }}</p>
          </div>
        </ng-container>
      </ng-template>
    </ng-template>
  </ng-template>
</ng-container>

<ng-template #loadingTemplate>
  <div class="skeleton w-100"></div>
</ng-template>
