import { Country } from '../../../model/address.model';
import { SiteContextActions } from '../actions/index';
import { SiteCountryActionTypes } from '../actions/site-country.action';

export function reducer(state = null, action: SiteContextActions.SiteCountryActions): Country {
  switch (action.type) {
    case SiteCountryActionTypes.LoadSiteCountrySuccess: {
      return action.payload;
    }
  }
  return state;
}
