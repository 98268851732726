import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { CategoryRef } from '../../model';

@Injectable({
  providedIn: 'root',
})
export class CatalogRouterStateService {
  constructor() {}

  getProductCode(params?: Params, queryParams?: Params): string {
    return this.getCode('p', params, queryParams?.p);
  }

  getLeafCategoryCode(params?: Params, queryParams?: Params): CategoryRef {
    return this.getCode('c', params, queryParams?.c) as CategoryRef;
  }

  private getCode(key: string, params?: Params, defaultValue?: string): string {
    if (!params && !defaultValue) {
      return undefined;
    }

    // `params` may look like `product-name--pF-1234` or `category-name--c01_02_03`, or even
    // `product-name-with--plenty--of--confusing-double-dashes-in-them--pF-1234`
    //
    // Assumption of current structure:
    // If double dashes[0] exist, then the part after the _last_ double dash contains a one-letter key
    // plus some identifier (or code).
    // [0]: "double dashes" == sequence of at least two dashes (accounting for trailing whitespace/punctuation/etc which map to '-')
    const leafParam = Object.values(params || {})
      .map((param) => `${param}`.split(/--+/))
      .filter((parts) => parts.length > 1)
      .map((parts) => ({ key: parts.at(-1)?.slice(0, 1), code: parts.at(-1)?.slice(1) }))
      .filter(({ key: _key }) => _key === key)
      .pop();

    return leafParam?.code || defaultValue;
  }
}
