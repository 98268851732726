import { Article, Unit } from '../../core/model';
import { resolveDefaultArticleUnit } from './resolve-default-article-unit';

export function resolveDefaultArticleQuantity(article?: Article, unit?: Unit, useArticleUnitAsDefault: boolean = false): number {
  if (!unit) {
    unit = resolveDefaultArticleUnit(article, useArticleUnitAsDefault);
  }
  const result = Math.ceil(article?.deliveryQuantity / (unit?.inEcommerceUnit || 1));
  return Number.isInteger(result) ? result : 1;
}
