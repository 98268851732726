import { ArticleStockInfo } from '../../core/model';

export function allWarehousesHaveZeroStock(stockInfo: ArticleStockInfo): boolean {
  const { stockInfos } = stockInfo;
  if (!stockInfos) {
    return true;
  }
  const zeroStockCount = stockInfos.filter((warehouse) => warehouse.quantity === 0).length;
  return zeroStockCount === stockInfos.length;
}
