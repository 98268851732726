import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { CMSPagesFeatureModule } from './cms-pages/cms-pages-feature.module';
import { PageTimeCreatedFeatureModule } from './page-time-created/page-time-created.module';

@NgModule({
  imports: [PageTimeCreatedFeatureModule, CMSPagesFeatureModule],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        articlesTiles: {
          module: () => import('./articles-tiles/articles-tiles.module').then((m) => m.CmsArticlesTileModule),
          cmsComponents: ['ArticlesTileComponent'],
        },
        banner: {
          module: () => import('./banner/banner.module').then((m) => m.BannerModule),
          cmsComponents: ['SimpleResponsiveBannerComponent', 'BannerComponent', 'SimpleBannerComponent'],
        },
        bannerCollection: {
          module: () => import('./banner-collection/banner-collection.module').then((m) => m.BannerCollectionModule),
          cmsComponents: ['BannerCollectionComponent'],
          dependencies: ['banner'],
        },
        timedBannerCollection: {
          module: () =>
            import('./timed-banner-collection/timed-banner-collection.module').then((m) => m.TimedBannerCollectionModule),
          cmsComponents: ['TimedBannerCollectionComponent'],
          dependencies: ['banner'],
        },
        embeddedVideo: {
          module: () => import('./embedded-video/embedded-video.module').then((m) => m.CmsEmbeddedVideoModule),
          cmsComponents: ['CMSEmbeddedVideoComponent'],
        },
        mailForm: {
          module: () => import('./mail-form/mail-form.module').then((m) => m.CmsMailFormModule),
          cmsComponents: ['CMSMailFormComponent'],
        },
        paragraph: {
          module: () => import('./paragraph/paragraph.module').then((m) => m.CmsParagraphModule),
          cmsComponents: ['CMSParagraphComponent', 'CMSTabParagraphComponent'],
        },
        image: {
          module: () => import('./image/image.module').then((m) => m.CmsImageModule),
          cmsComponents: ['CMSImageComponent'],
        },
        puff: {
          module: () => import('./puff/puff.module').then((m) => m.CmsPuffModule),
          cmsComponents: ['PuffComponent'],
          dependencies: [
            () => import('../cms-content-tag-base/cms-content-tag-base.module').then((m) => m.CmsContentTagBaseModule),
          ],
        },
        dynamicPuffCollection: {
          module: () =>
            import('./dynamic-puff-collection/dynamic-puff-collection.module').then((m) => m.CmsDynamicPuffCollectionModule),
          cmsComponents: ['DynamicPuffCollectionComponent'],
          dependencies: ['puff'],
        },
        puffCollection: {
          module: () => import('./puff-collection/puff-collection.module').then((m) => m.CmsPuffCollectionModule),
          cmsComponents: ['PuffCollectionComponent'],
          dependencies: ['puff'],
        },
        puffLink: {
          module: () => import('./puff-link/puff-link.module').then((m) => m.CmsPuffLinkModule),
          cmsComponents: ['PuffLinkComponent'],
        },
        puffWithLinks: {
          module: () => import('./puff-with-links/puff-with-links.module').then((m) => m.CmsPuffWithLinksModule),
          cmsComponents: ['PuffWithLinksComponent'],
          dependencies: ['puffLink'],
        },
        puffWithLinksCollection: {
          module: () =>
            import('./puff-with-links-collection/puff-with-links-collection.module').then(
              (m) => m.CmsPuffWithLinksCollectionModule
            ),
          cmsComponents: ['CMSPuffWithLinksCollectionComponent'],
          dependencies: ['puffWithLinks'],
        },
        news: {
          module: () => import('./news/component/news.module').then((m) => m.CmsNewsModule),
          dependencies: [() => import('./news/base/news-base.module').then((m) => m.NewsBaseModule)],
          cmsComponents: ['NewsComponent'],
        },
        labelText: {
          module: () => import('./label-text/label-text.module').then((m) => m.CmsLabelTextModule),
          cmsComponents: ['FlexLabelText'],
        },
        mediaLink: {
          module: () => import('./media-link/media-link.module').then((m) => m.CmsMediaLinkModule),
          cmsComponents: ['CMSMediaLinkComponent'],
        },
        socialLink: {
          module: () => import('./social-link/social-link.module').then((m) => m.CmsSocialLinkModule),
          cmsComponents: ['SocialLinkComponent'],
        },
        alphabeticalLinkContainer: {
          module: () =>
            import('./alphabetical-link-container/alphabetical-link-container.module').then(
              (m) => m.CmsAlphabeticalLinkContainerModule
            ),
          cmsComponents: ['AlphabeticalLinkContainerComponent'],
        },
        linkCollection: {
          module: () => import('./link-collection/link-collection.module').then((m) => m.CmsLinkCollectionModule),
          cmsComponents: ['CMSLinkCollectionComponent'],
        },
        link: {
          module: () => import('./link/link.module').then((m) => m.CmsLinkModule),
          cmsComponents: ['CMSLinkComponent'],
        },
        productsTiles: {
          module: () => import('./products-tiles/products-tiles.module').then((m) => m.CmsProductsTileModule),
          cmsComponents: ['ProductsTileComponent'],
        },
        tabContainer: {
          module: () => import('./tab-container/tab-container.module').then((m) => m.CmsTabContainerModule),
          cmsComponents: ['TabContainerComponent'],
        },
        splash: {
          module: () => import('./splash/splash.module').then((m) => m.SplashModule),
          cmsComponents: ['CMSSplashComponent'],
        },
      },
    }),
  ],
})
export class ContentFeatureModule {}
