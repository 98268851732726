import { StateUtils } from '@spartacus/core';
import { ArticlePrice, ArticlePriceLookup } from '../../../model';
import { PyStateUtils } from '../../../state';
import { ARTICLE_PRICE } from '../user-state';

export enum PriceActionTypes {
  LoadArticlePrice = '[User] Load Article Price',
  LoadArticlePriceSuccess = '[User] Load Article Price Success',
  LoadArticlePriceFail = '[User] Load Article Price Fail',
}

export class LoadArticlePrice extends StateUtils.EntityLoadAction {
  readonly type = PriceActionTypes.LoadArticlePrice;

  constructor(public lookup: ArticlePriceLookup, public async: boolean) {
    super(ARTICLE_PRICE, `${lookup.articleNumber}|${lookup.quantity}`);
  }
}

export class LoadArticlePriceSuccess extends StateUtils.EntitySuccessAction {
  readonly type = PriceActionTypes.LoadArticlePriceSuccess;

  constructor(public payload: ArticlePrice) {
    super(ARTICLE_PRICE, `${payload.articleNumber}|${payload.quantity}`);
  }
}

export class LoadArticlePriceFail extends PyStateUtils.EntityFailAction {
  readonly type = PriceActionTypes.LoadArticlePriceFail;

  constructor(public lookup: ArticlePriceLookup, public articlePrice = {}, public payload?: any) {
    super(ARTICLE_PRICE, `${lookup.articleNumber}|${lookup.quantity}`, payload);
  }
}

export type PriceActions = LoadArticlePrice | LoadArticlePriceSuccess | LoadArticlePriceFail;
