<label class="d-flex align-items-center" *ngIf="showLabel || showLeftLabel; else withoutLabelTemplate">
  <ng-container *ngTemplateOutlet="checkboxTemplate"></ng-container>
  <div *ngIf="showLeftLabel" class="label-wrapper left-label mr-2">
    <ng-content select="[slot=left-label]"></ng-content>
  </div>
  <div *ngIf="showLabel" class="label-wrapper right-label ml-2">
    <ng-content select="[slot=label]"></ng-content>
  </div>
</label>

<ng-template #withoutLabelTemplate>
  <label class="checkbox-wrapper">
    <ng-container *ngTemplateOutlet="checkboxTemplate"></ng-container>
  </label>
</ng-template>

<ng-template #checkboxTemplate>
  <ng-content select="input[type=checkbox]"></ng-content>
  <span class="checkmark"></span>
</ng-template>
