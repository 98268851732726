import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfigModule } from '@spartacus/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { I18nModule } from '../../../../core/i18n';
import { PrincipalConfigurationDirectiveModule, ScreenSizeModule, SearchModule } from '../../../../shared';
import { HeaderNavigationItemModule } from '../../components/header-navigation-item';
import { MenuAreaOfUseDropdownModule } from '../../components/menu-area-of-use';
import { MenuProductsModule } from '../../components/menu-products';
import { MenuServicesModule } from '../../components/menu-services/menu-services.module';
import { HeaderMainNavigationComponent } from './header-main-navigation.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    NzIconModule,
    MenuProductsModule,
    MenuServicesModule,
    MenuAreaOfUseDropdownModule,
    RouterModule,
    ScreenSizeModule,
    SearchModule,
    HeaderNavigationItemModule,
    PrincipalConfigurationDirectiveModule,
    ConfigModule.withConfig({
      cmsComponents: {
        HeaderMainNavigationComponent: {
          component: HeaderMainNavigationComponent,
        },
      },
    }),
  ],
  declarations: [HeaderMainNavigationComponent],
  exports: [HeaderMainNavigationComponent],
})
export class HeaderMainNavigationModule {}
