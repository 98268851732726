import { StateUtils } from '@spartacus/core';
import { PagedSearchAction } from '../../../features/store/actions/search.actions';
import { HttpErrorModel, SearchParams } from '../../../model';
import { AccountingCode, AccountingCodeUserInfo, AccountingCodesSearchResult } from '../../../model/accounting-code.model';
import { PyStateUtils } from '../../../state/utils';
import { ACCOUNTING_CODES_ADMIN } from '../user-state';

export enum AccountingCodesAdminActionTypes {
  SearchAccountingCodesAdmin = '[User] Search accounting codes admin',
  SearchAccountingCodesAdminSuccess = '[User] Search accounting codes admin success',
  SearchAccountingCodesAdminFail = '[User] Search accounting codes admin fail',

  CreateAccountingCodeAdmin = '[User] Create accounting code admin',
  CreateAccountingCodeAdminSuccess = '[User] Create accounting code admin success',
  CreateAccountingCodeAdminFail = '[User] Create accounting code admin fail',

  UpdateAccountingCodeAdmin = '[User] Update accounting code admin',
  UpdateAccountingCodeAdminSuccess = '[User] Update accounting code admin success',
  UpdateAccountingCodeAdminFail = '[User] Update accounting code admin fail',

  DeleteAccountingCodeAdmin = '[User] Delete accounting code admin',
  DeleteAccountingCodeAdminSuccess = '[User] Delete accounting code admin success',
  DeleteAccountingCodeAdminFail = '[User] Delete accounting code admin fail',

  LoadAccountingCodeUserInfo = '[User] Load accounting code user info',
  LoadAccountingCodeUserInfoSuccess = '[User] Load accounting code user info success',
}

interface SearchAccountingCodesAdminPayload {
  userId: string;
  searchParams?: SearchParams;
}

interface SearchAccountingCodesAdminFailPayload extends SearchAccountingCodesAdminPayload {
  error?: HttpErrorModel;
}

interface SearchAccountingCodesAdminSuccessPayload extends SearchAccountingCodesAdminPayload {
  searchResult: AccountingCodesSearchResult;
}

interface CreateAccountingCodeAdminPayload {
  userId: string;
  accountingCode: AccountingCode;
  searchParams: SearchParams;
}

interface CreateAccountingCodeAdminFailPayload extends CreateAccountingCodeAdminPayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CreateAccountingCodeAdminSuccessPayload extends CreateAccountingCodeAdminPayload {}

interface UpdateAccountingCodeAdminPayload {
  userId: string;
  accountingCode: AccountingCode;
}

interface UpdateAccountingCodeAdminFailPayload extends UpdateAccountingCodeAdminPayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UpdateAccountingCodeAdminSuccessPayload extends UpdateAccountingCodeAdminPayload {}

interface DeleteAccountingCodeAdminPayload {
  userId: string;
  code: string;
  searchParams: SearchParams;
}

interface DeleteAccountingCodeAdminFailPayload extends DeleteAccountingCodeAdminPayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DeleteAccountingCodeAdminSuccessPayload extends DeleteAccountingCodeAdminPayload {}

export class SearchAccountingCodesAdmin extends StateUtils.LoaderLoadAction implements PagedSearchAction {
  readonly type = AccountingCodesAdminActionTypes.SearchAccountingCodesAdmin;
  readonly searchType = ACCOUNTING_CODES_ADMIN;
  public searchParams;

  constructor(public payload: SearchAccountingCodesAdminPayload) {
    super(ACCOUNTING_CODES_ADMIN);
    this.searchParams = payload.searchParams;
  }
}

export class SearchAccountingCodesAdminFail extends PyStateUtils.LoaderFailAction implements PagedSearchAction {
  readonly type = AccountingCodesAdminActionTypes.SearchAccountingCodesAdminFail;
  readonly searchType = ACCOUNTING_CODES_ADMIN;
  public searchParams;

  constructor(public payload?: SearchAccountingCodesAdminFailPayload) {
    super(ACCOUNTING_CODES_ADMIN, payload);
    this.searchParams = payload?.searchParams;
  }
}

export class SearchAccountingCodesAdminSuccess extends StateUtils.LoaderSuccessAction implements PagedSearchAction {
  readonly type = AccountingCodesAdminActionTypes.SearchAccountingCodesAdminSuccess;
  readonly searchType = ACCOUNTING_CODES_ADMIN;
  public searchParams;

  constructor(public payload: SearchAccountingCodesAdminSuccessPayload) {
    super(ACCOUNTING_CODES_ADMIN);
    this.searchParams = payload.searchParams;
  }
}

export class CreateAccountingCodeAdmin extends StateUtils.EntityLoadAction {
  readonly type = AccountingCodesAdminActionTypes.CreateAccountingCodeAdmin;

  constructor(public payload: CreateAccountingCodeAdminPayload) {
    super(ACCOUNTING_CODES_ADMIN, payload.accountingCode.code);
  }
}

export class CreateAccountingCodeAdminFail extends PyStateUtils.EntityFailAction {
  readonly type = AccountingCodesAdminActionTypes.CreateAccountingCodeAdminFail;

  constructor(public payload: CreateAccountingCodeAdminFailPayload) {
    super(ACCOUNTING_CODES_ADMIN, payload.accountingCode.code, payload.error);
  }
}

export class CreateAccountingCodeAdminSuccess extends StateUtils.EntitySuccessAction {
  readonly type = AccountingCodesAdminActionTypes.CreateAccountingCodeAdminSuccess;

  constructor(public payload: CreateAccountingCodeAdminSuccessPayload) {
    super(ACCOUNTING_CODES_ADMIN, payload.accountingCode.code);
  }
}

export class UpdateAccountingCodeAdmin extends StateUtils.EntityLoadAction {
  readonly type = AccountingCodesAdminActionTypes.UpdateAccountingCodeAdmin;

  constructor(public payload: UpdateAccountingCodeAdminPayload) {
    super(ACCOUNTING_CODES_ADMIN, payload.accountingCode.code);
  }
}

export class UpdateAccountingCodeAdminFail extends PyStateUtils.EntityFailAction {
  readonly type = AccountingCodesAdminActionTypes.UpdateAccountingCodeAdminFail;

  constructor(public payload: UpdateAccountingCodeAdminFailPayload) {
    super(ACCOUNTING_CODES_ADMIN, payload.accountingCode.code, payload.error);
  }
}

export class UpdateAccountingCodeAdminSuccess extends StateUtils.EntitySuccessAction {
  readonly type = AccountingCodesAdminActionTypes.UpdateAccountingCodeAdminSuccess;

  constructor(public payload: UpdateAccountingCodeAdminSuccessPayload) {
    super(ACCOUNTING_CODES_ADMIN, payload.accountingCode.code);
  }
}

export class DeleteAccountingCodeAdmin extends StateUtils.EntityLoadAction {
  readonly type = AccountingCodesAdminActionTypes.DeleteAccountingCodeAdmin;

  constructor(public payload: DeleteAccountingCodeAdminPayload) {
    super(ACCOUNTING_CODES_ADMIN, payload.code);
  }
}

export class DeleteAccountingCodeAdminFail extends PyStateUtils.EntityFailAction {
  readonly type = AccountingCodesAdminActionTypes.DeleteAccountingCodeAdminFail;

  constructor(public payload: DeleteAccountingCodeAdminFailPayload) {
    super(ACCOUNTING_CODES_ADMIN, payload.code, payload.error);
  }
}

export class DeleteAccountingCodeAdminSuccess extends StateUtils.EntitySuccessAction {
  readonly type = AccountingCodesAdminActionTypes.DeleteAccountingCodeAdminSuccess;

  constructor(public payload: DeleteAccountingCodeAdminSuccessPayload) {
    super(ACCOUNTING_CODES_ADMIN, payload.code);
  }
}

export class LoadAccountingCodeUserInfo extends StateUtils.LoaderLoadAction {
  readonly type = AccountingCodesAdminActionTypes.LoadAccountingCodeUserInfo;

  constructor(public payload: string) {
    super(ACCOUNTING_CODES_ADMIN);
  }
}

export class LoadAccountingCodeUserInfoSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = AccountingCodesAdminActionTypes.LoadAccountingCodeUserInfoSuccess;

  constructor(public payload: AccountingCodeUserInfo) {
    super(ACCOUNTING_CODES_ADMIN);
  }
}

export type AccountingCodesAdminActions =
  | SearchAccountingCodesAdmin
  | SearchAccountingCodesAdminFail
  | SearchAccountingCodesAdminSuccess
  | CreateAccountingCodeAdmin
  | CreateAccountingCodeAdminFail
  | CreateAccountingCodeAdminSuccess
  | UpdateAccountingCodeAdmin
  | UpdateAccountingCodeAdminFail
  | UpdateAccountingCodeAdminSuccess
  | DeleteAccountingCodeAdmin
  | DeleteAccountingCodeAdminFail
  | DeleteAccountingCodeAdminSuccess
  | LoadAccountingCodeUserInfo
  | LoadAccountingCodeUserInfoSuccess;
