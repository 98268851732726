import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { UserRootModule } from '../../core/user/root/user-root.module';
import { USER_BASE_FEATURE } from '../../core/user/store/user-state';

@NgModule({
  imports: [UserRootModule],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        [USER_BASE_FEATURE]: {
          module: () => import('./user-base.module').then((m) => m.UserBaseModule),
        },
      },
    }),
  ],
})
export class UserBaseFeatureModule {}
