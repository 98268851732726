import { Directive, ElementRef, HostListener, Input, OnChanges, Renderer2 } from '@angular/core';
import { take } from 'rxjs/operators';
import { TranslationService } from '../../../core/i18n';
import { Image } from '../../../core/model';
import { ImageLoadingStrategy, MediaContainer } from './media.model';
import { MediaService } from './media.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[pyMedia]',
})
export class MediaDirective implements OnChanges {
  /**
   * The media container can hold multiple media items, so that
   * a specific media (by format) can be used or multiple media
   * can be provided in a `srcset` so the browser will figure out
   * the best media for the device.
   */
  @Input() pyMedia: MediaContainer | Image;

  @Input() loading: 'lazy' | 'eager';

  /**
   * If the image should be used as background for the nativeElement
   */
  @Input() useAsBackgroundImage: boolean;

  /**
   * if a media format is given, a media for the given format will be rendered
   */
  @Input() format: string;

  /**
   * A specific alt text for an image, which overrules the alt text
   * from the container data.
   */
  @Input() alt: string;

  private didHandleError = false;

  /**
   * Indicates whether the browser should lazy load the image.
   */
  private get loadingStrategy(): string | null {
    if (!!this.loading) {
      return this.loading;
    }

    return this.mediaService.loadingStrategy === ImageLoadingStrategy.LAZY ? 'lazy' : null;
  }

  constructor(
    private mediaService: MediaService,
    private renderer: Renderer2,
    private el: ElementRef,
    private translationService: TranslationService
  ) {}

  ngOnChanges(): void {
    this.create();
  }

  /**
   * Whenever an error happens during load, we mark the component
   * with css classes to have a missing media.
   */
  @HostListener('error')
  errorHandler(): void {
    if (!this.didHandleError) {
      this.handleMissing();
      this.didHandleError = true;
    }
  }

  /**
   * Creates the `Media` object
   */
  private create(): void {
    const media = this.mediaService.getMedia(this.pyMedia, this.format, this.alt);

    if (!media?.src) {
      this.handleMissing();
      return;
    }
    if (this.loadingStrategy) {
      this.renderer.setAttribute(this.el.nativeElement, 'loading', this.loadingStrategy);
    }

    if (this.useAsBackgroundImage) {
      this.renderer.setStyle(this.el.nativeElement, 'background-image', `url(${media.src})`);
    } else {
      if (media.src) {
        this.renderer.setAttribute(this.el.nativeElement, 'src', media.src);
      }
      if (media.srcset) {
        this.renderer.setAttribute(this.el.nativeElement, 'srcset', media.srcset);
      }
    }

    this.renderer.setAttribute(this.el.nativeElement, 'alt', media.alt || '');
  }

  private handleMissing() {
    this.translationService
      .translate('common.missingImageAltText_hint')
      .pipe(take(1))
      .subscribe((missingImageAltText) => {
        this.renderer.setAttribute(this.el.nativeElement, 'src', 'assets/outline/no-image.svg');
        this.renderer.setAttribute(this.el.nativeElement, 'alt', missingImageAltText);
        this.renderer.setAttribute(this.el.nativeElement, 'class', 'missing-image');
      });
  }
}
