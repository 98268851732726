import { StateUtils } from '@spartacus/core';
import { SoldTo } from '../../../../core/model/user.model';
import { PyStateUtils } from '../../../../core/state/utils';
import { SOLD_TO } from '../sold-to-state';

export enum ActiveSoldToActionTypes {
  LoadActiveSoldTo = '[SoldTo] Load active sold-to',
  LoadActiveSoldToFail = '[SoldTo] Load active sold-to fail',
  LoadActiveSoldToSuccess = '[SoldTo] Load active sold-to success',
  LoadActiveSoldToCancel = '[SoldTo] Load active sold-to cancel',
  ClearActiveSoldTo = '[SoldTo] Clear active sold-to',
}

export class LoadActiveSoldTo extends StateUtils.LoaderLoadAction {
  readonly type = ActiveSoldToActionTypes.LoadActiveSoldTo;

  constructor(public payload: string, public oldSoldToId?: string) {
    super(SOLD_TO);
  }
}

export class LoadActiveSoldToFail extends PyStateUtils.LoaderFailAction {
  readonly type = ActiveSoldToActionTypes.LoadActiveSoldToFail;

  constructor(public payload?: any) {
    super(SOLD_TO, payload);
  }
}

export class LoadActiveSoldToSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = ActiveSoldToActionTypes.LoadActiveSoldToSuccess;

  constructor(public payload: SoldTo) {
    super(SOLD_TO);
  }
}

export class LoadActiveSoldToCancel extends StateUtils.LoaderSuccessAction {
  readonly type = ActiveSoldToActionTypes.LoadActiveSoldToCancel;

  constructor(public payload: SoldTo) {
    super(SOLD_TO);
  }
}

export class ClearActiveSoldTo extends StateUtils.LoaderResetAction {
  readonly type = ActiveSoldToActionTypes.ClearActiveSoldTo;

  constructor() {
    super(SOLD_TO);
  }
}

export type ActiveSoldToActions =
  | LoadActiveSoldTo
  | LoadActiveSoldToFail
  | LoadActiveSoldToSuccess
  | LoadActiveSoldToCancel
  | ClearActiveSoldTo;
