import { StateUtils } from '@spartacus/core';
import { CartType, TinyCart } from '../../../../../core/model';
import { PyStateUtils } from '../../../../../core/state';
import { TINY_CART } from '../cart-state';

export enum TinyCartActionTypes {
  LoadTinyCarts = '[Cart] Load tiny carts',
  LoadTinyCartsSuccess = '[Cart] Load tiny carts success',
  LoadTinyCartsFail = '[Cart] Load tiny carts fail',

  LoadTinyCart = '[Cart] Load tiny cart',
  LoadTinyCartSuccess = '[Cart] Load tiny cart success',
  LoadTinyCartFail = '[Cart] Load tiny cart fail',

  RemoveTinyCart = '[Cart] Remove tiny cart',
}

interface LoadTinyCartPayload {
  userId: string;
  cartId: string;
}

interface RemoveTinyCartPayload {
  cartType: CartType;
  cartId?: string;
}

export class LoadTinyCarts extends StateUtils.LoaderLoadAction {
  readonly type = TinyCartActionTypes.LoadTinyCarts;

  constructor(public payload: string) {
    super(TINY_CART);
  }
}

export class LoadTinyCartsFail extends PyStateUtils.LoaderFailAction {
  readonly type = TinyCartActionTypes.LoadTinyCartsFail;

  constructor(public payload?: any) {
    super(TINY_CART, payload);
  }
}

export class LoadTinyCartsSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = TinyCartActionTypes.LoadTinyCartsSuccess;

  constructor(public payload: TinyCart[]) {
    super(TINY_CART);
  }
}

export class LoadTinyCart extends StateUtils.LoaderLoadAction {
  readonly type = TinyCartActionTypes.LoadTinyCart;

  constructor(public payload: LoadTinyCartPayload) {
    super(TINY_CART);
  }
}

export class LoadTinyCartFail extends PyStateUtils.LoaderFailAction {
  readonly type = TinyCartActionTypes.LoadTinyCartFail;

  constructor(public payload?: any) {
    super(TINY_CART, payload);
  }
}

export class LoadTinyCartSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = TinyCartActionTypes.LoadTinyCartSuccess;

  constructor(public payload: TinyCart) {
    super(TINY_CART);
  }
}

export class RemoveTinyCart extends StateUtils.EntityRemoveAction {
  readonly type = TinyCartActionTypes.RemoveTinyCart;

  constructor(public payload: RemoveTinyCartPayload) {
    super(TINY_CART, payload.cartId);
  }
}

export type TinyCartActions =
  | LoadTinyCarts
  | LoadTinyCartsFail
  | LoadTinyCartsSuccess
  | LoadTinyCart
  | LoadTinyCartFail
  | LoadTinyCartSuccess
  | RemoveTinyCart;
