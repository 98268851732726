<ng-container
  *ngIf="{
    renderFully: renderFully$ | async,
    article: article$ | async,
    articleLoading: articleLoading$ | async,
    articleFailure: articleFailure$ | async,
    showArticleError: showArticleError$ | async,
    articlePrice: articlePrice$ | async,
    articlePriceLoading: articlePriceLoading$ | async,
    quantityAndUnitValue: quantityAndUnitValue$ | async,
    initialMinimumQuantity: initialMinimumQuantity$ | async,
    disableActionButtons: disableActionButtons$ | async,
    columnAttributes: columnAttributes$ | async,
    isAddToCartInProgress: isAddToCartInProgress$ | async,
    hasSearchScore: hasSearchScore$ | async,
    showPrePrintedLabelDropdown: showPrePrintedLabelDropdown$ | async
  } as data"
>
  <div class="d-flex flex-column position-relative">
    <ng-container *ngIf="!!data.article || data.articleLoading">
      <!-- min-height-80 class is used to preserve the height of the element, due to "renderFully=false"  -->
      <div class="d-flex align-items-start">
        <py-badge
          *ngIf="data.hasSearchScore && articleResultRef?.score > 0"
          [type]="articleResultRef.topScore ? 'success' : 'info'"
          (click)="openSearchScoreModal()"
          class="searchScoreBadge"
        >
          <i nz-icon nzType="trophy" nzTheme="fill" *ngIf="articleResultRef.topScore"></i>
          {{ articleResultRef.score }}
        </py-badge>
      </div>
      <div class="d-flex w-100" [class.min-height-80]="!data.renderFully">
        <ng-container *ngIf="!enableSecondaryArticleRowVariant; else imageSecondaryRowVariant">
          <py-article-image
            *ngIf="data.renderFully"
            [images]="
              data.article && !!(data.article.image || data.article.articleGroupImage)
                ? [data.article.image || data.article.articleGroupImage]
                : []
            "
            [loading]="data.articleLoading"
            [small]="true"
            [altText]="data?.article?.articleName || data?.article?.productName"
            class="mr-4"
          ></py-article-image>
        </ng-container>

        <py-article-info
          class="w-100"
          [article]="data.article"
          [queryParams]="queryParams"
          [outOfStock]="articleOutOfStock"
          [substituteBadgeType]="substituteBadgeType"
          [enableSecondaryArticleRowVariant]="enableSecondaryArticleRowVariant"
          [articleNumber]="articleNumber"
        ></py-article-info>
      </div>
      <div class="d-flex flex-column mt-2 mb-2 align-items-start" [class.mb-3]="!!data.article">
        <py-article-info-attributes
          class="align-self-stretch"
          *ngIf="!!data.article && enableSecondaryArticleRowVariant && data.columnAttributes?.length > 0"
          [columnAttributes]="data.columnAttributes"
          [enableSecondaryArticleRowVariant]="enableSecondaryArticleRowVariant"
        ></py-article-info-attributes>
        <py-detailed-stock-info
          *ngIf="!!data.article"
          [cssClasses]="'my-2'"
          [small]="true"
          [article]="data.article"
          [quantity]="data.quantityAndUnitValue?.quantity"
          [unitCode]="data.quantityAndUnitValue?.unitCode"
          [showConsignmentStockLevel]="showConsignmentStockLevel"
          [enableSecondaryArticleRowVariant]="enableSecondaryArticleRowVariant"
          [similarArticlesTemplate]="similarArticlesTemplate"
          [expandedByDefault]="expandedByDefault"
          (articleOutOfStockChange)="setArticleOutOfStock($event)"
        ></py-detailed-stock-info>

        <py-catalog-article-envelope
          *ngIf="!!data.article && data.article?.hasOwnProperty('envelopeData') && (data.article?.envelopeData | json) != '{}'"
          [article]="data.article"
          [renderFully]="data.renderFully"
          class="w-100"
          [expandedByDefault]="expandedByDefault"
        ></py-catalog-article-envelope>
      </div>

      <div class="skeleton hide-on-print">
        <div *ngIf="!!data.article">
          <form [formGroup]="prePrintedCountryForm" *ngIf="data.showPrePrintedLabelDropdown">
            <py-dropdown
              class="flex-grow-1 country-dropdown"
              [formControlName]="'configuration'"
              [options]="data.article.articlePrePrintedConfigurationWithCountry"
              [title]="'catalog.specialPrePrintingArticleLabel_heading' | cxTranslate"
              [titleIcon]="'tags'"
              [resetable]="true"
              [dropdownContainer]="'body'"
              [resetLabel]="'common.resetDropdown_action' | cxTranslate"
              (resetValue)="prePrintedConfigurationControl.reset()"
            ></py-dropdown>
          </form>
          <py-quantity-and-unit-selector
            [mobile]="true"
            [article]="data.article"
            [values]="data.quantityAndUnitValue"
            [initialMinimumQuantity]="data.initialMinimumQuantity"
            (valueChanges)="updateQuantityAndUnit($event)"
            (belowMinimum)="onBelowMinimum($event)"
            container="body"
          ></py-quantity-and-unit-selector>
        </div>
      </div>

      <div
        class="d-flex align-items-center mt-4"
        [ngClass]="enableSecondaryArticleRowVariant ? 'justify-content-between' : 'justify-content-end'"
        *ngIf="!!data.article"
      >
        <py-catalog-article-price
          *ngIf="!!data.article"
          [article]="data.article"
          [quantityAndUnitValue]="data.quantityAndUnitValue"
          [articlePrice]="data.articlePrice"
          [loadingPrice]="data.articlePriceLoading"
          [horizontalAlignment]="enableSecondaryArticleRowVariant ? horizontalAlignments.LEFT : horizontalAlignments.RIGHT"
          [cartType]="cartType"
          [showOutgoingBadge]="enableSecondaryArticleRowVariant && data.article?.outgoingOnSale"
          (loadPrice)="loadPrice()"
        ></py-catalog-article-price>

        <div class="d-flex align-items-center hide-on-print">
          <button
            class="btn btn-icon btn-primary ml-4"
            (click)="addToCart()"
            [disabled]="data.disableActionButtons || data.isAddToCartInProgress"
          >
            <i *ngIf="!data.isAddToCartInProgress" nz-icon nzType="shopping-cart" nzTheme="outline"></i>
            <cx-spinner *ngIf="data.isAddToCartInProgress"></cx-spinner>
          </button>
          <ng-container *pyPrincipalConfiguration="'enableOrderTemplates'">
            <py-add-to-shopping-list
              *ngIf="data.renderFully && !hideAddToShoppingListButton && data.article?.cartType === stockCartType"
              class="ml-2"
              [articleCode]="data.article?.code"
              [quantity]="data.quantityAndUnitValue?.quantity"
              [unit]="resolveActiveUnit(data.article, data.quantityAndUnitValue?.unitCode)"
              [placement]="['left', 'left-bottom', 'left-top', 'top', 'top-left', 'top-right']"
              [disabled]="data.disableActionButtons"
              [articleNumber]="shoppingListArticleNumber"
            ></py-add-to-shopping-list>
          </ng-container>
          <div *ngIf="additionalArticleActions" class="ml-2">
            <ng-container
              *ngTemplateOutlet="
                additionalArticleActions;
                context: {
                  article: data.article,
                  quantityAndUnitValue: data.quantityAndUnitValue,
                  disabled: data.disableActionButtons
                }
              "
            ></ng-container>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Error handling -->
    <ng-container *ngIf="data.showArticleError && !data.articleLoading">
      <py-catalog-article-overlay-message
        *ngIf="!!data.article; else articleFailedTemplate"
        [expanded]="isSimilarArticlesExpanded"
        [articleRef]="articleResultRef?.ref"
        (expandedChange)="isSimilarArticlesExpanded = $event"
      ></py-catalog-article-overlay-message>

      <ng-template #articleFailedTemplate>
        <py-notification type="danger">
          <div class="content">
            {{ 'catalog.articleNoLongerAvailable_text' | cxTranslate : { articleRef: articleResultRef?.ref } }}
          </div>
        </py-notification>
      </ng-template>
    </ng-container>
  </div>

  <ng-template
    *ngIf="isSimilarArticlesExpanded && data.renderFully"
    [ngTemplateOutlet]="similarArticlesTemplate"
    [ngTemplateOutletContext]="{
      carouselData: {
        showTitle: true,
        showAlternativeArticleBadge: true,
        showPaginationAtTheBottom: true,
        useDefaultArticleQuantityAndUnit: true
      }
    }"
  ></ng-template>

  <ng-template #imageSecondaryRowVariant>
    <!-- When using secondary article row variant we display two images - articleGroup image (e.g. paper color, if available) and article image -->
    <div *ngIf="enableSecondaryArticleRowVariant" class="d-flex flex-column mr-4">
      <py-article-image
        *ngIf="data.renderFully && !!data.article?.articleGroupImage"
        class="mb-2"
        [images]="data.article && !!data.article.articleGroupImage ? [data.article.articleGroupImage] : []"
        [loading]="data.articleLoading"
        [small]="true"
        [altText]="data?.article?.articleName || data?.article?.productName"
      ></py-article-image>

      <py-article-image
        *ngIf="data.renderFully"
        [images]="data.article && !!data.article.image ? [data.article.image] : []"
        [loading]="data.articleLoading"
        [small]="true"
        [altText]="data?.article?.articleName || data?.article?.productName"
      ></py-article-image>
    </div>
  </ng-template>

  <ng-template #similarArticlesTemplate let-carouselData="carouselData">
    <py-catalog-similar-articles
      [articleRef]="articleResultRef?.ref"
      [queryParams]="queryParams"
      [showMessageWhenEmpty]="data.showArticleError && !!data.article"
      [enableSecondaryArticleRowVariant]="enableSecondaryArticleRowVariant"
      [showTitle]="carouselData?.showTitle"
      [showAlternativeArticleBadge]="carouselData?.showAlternativeArticleBadge"
      [showPaginationAtTheBottom]="carouselData?.showPaginationAtTheBottom"
      [useDefaultArticleQuantityAndUnit]="carouselData?.useDefaultArticleQuantityAndUnit"
      [carouselActionMode]="carouselActionModes.ADD_TO_CART_MODE"
    ></py-catalog-similar-articles>
  </ng-template>
</ng-container>
