import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ORDER_ENTRY_CONFIG, OrderEntryConfig } from '../order-entry.config';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tbody[py-order-entry-row-loading]',
  templateUrl: './order-entry-row-loading.component.html',
  styleUrls: ['./order-entry-row-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderEntryRowLoadingComponent {
  get articleColumnSpan(): number {
    return this.config.selectable ? 3 : 2;
  }

  constructor(@Inject(ORDER_ENTRY_CONFIG) private config: OrderEntryConfig) {}
}
