import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mapTo, switchMap } from 'rxjs/operators';
import { LoginLogoutActionTypes } from '../../../../auth/user-auth/store/actions/login-logout.action';
import { GlobalMessageType } from '../../../../global-message/models/global-message.model';
import { GlobalMessageActions } from '../../../../global-message/store/actions';
import { normalizeHttpError, withdrawOn } from '../../../../util';
import { PriceFileRequestTemplateConnector } from '../../connector/price-file-request-template.connector';
import { PriceFileRequestTemplateActions } from '../actions';
import {
  DeletePriceFileRequestTemplate,
  LoadPriceFileRequestTemplate,
  LoadPriceFileRequestTemplateFail,
  LoadPriceFileRequestTemplateSuccess,
  LoadPriceFileRequestTemplates,
  LoadPriceFileRequestTemplatesFail,
  LoadPriceFileRequestTemplatesSuccess,
  PriceFileRequestTemplateActionTypes,
  SavePriceFileRequestTemplate,
  SavePriceFileRequestTemplateFail,
  SavePriceFileRequestTemplateSuccess,
} from '../actions/price-file-request-template.actions';

@Injectable()
export class PriceFileRequestTemplateEffects {
  private contextChange$ = this.actions$.pipe(ofType(LoginLogoutActionTypes.Logout));

  loadPriceFileRequestTemplates$: Observable<
    | PriceFileRequestTemplateActions.LoadPriceFileRequestTemplatesSuccess
    | PriceFileRequestTemplateActions.LoadPriceFileRequestTemplatesFail
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(PriceFileRequestTemplateActionTypes.LoadPriceFileRequestTemplates),
      switchMap((action: LoadPriceFileRequestTemplates) =>
        this.connector.load(action.userId).pipe(
          map((data) => new LoadPriceFileRequestTemplatesSuccess(data)),
          catchError((error) => of(new LoadPriceFileRequestTemplatesFail(normalizeHttpError(error))))
        )
      ),
      withdrawOn(this.contextChange$)
    )
  );

  loadPriceFileRequestTemplate$: Observable<
    | PriceFileRequestTemplateActions.LoadPriceFileRequestTemplateSuccess
    | PriceFileRequestTemplateActions.LoadPriceFileRequestTemplateFail
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(PriceFileRequestTemplateActionTypes.LoadPriceFileRequestTemplate),
      switchMap((action: LoadPriceFileRequestTemplate) =>
        this.connector.loadTemplate(action.userId, action.code).pipe(
          map((data) => new LoadPriceFileRequestTemplateSuccess(data)),
          catchError((error) => of(new LoadPriceFileRequestTemplateFail(normalizeHttpError(error))))
        )
      ),
      withdrawOn(this.contextChange$)
    )
  );

  savePriceFileRequestTemplate$: Observable<
    | PriceFileRequestTemplateActions.SavePriceFileRequestTemplateSuccess
    | PriceFileRequestTemplateActions.SavePriceFileRequestTemplateFail
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(PriceFileRequestTemplateActionTypes.SavePriceFileRequestTemplate),
      switchMap((action: SavePriceFileRequestTemplate) =>
        this.connector.save(action.userId, action.payload).pipe(
          map((requestTemplate) => new SavePriceFileRequestTemplateSuccess(requestTemplate)),
          catchError((error) => of(new SavePriceFileRequestTemplateFail(normalizeHttpError(error))))
        )
      ),
      withdrawOn(this.contextChange$)
    )
  );

  handleSuccessForSavePriceFileRequestTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PriceFileRequestTemplateActionTypes.SavePriceFileRequestTemplateSuccess),
      mapTo(
        new GlobalMessageActions.AddMessage({
          text: {
            key: 'priceFiles.template.saveSuccess_message',
          },
          type: GlobalMessageType.MSG_TYPE_CONFIRMATION,
        })
      )
    )
  );

  handleFailureForSavePriceFileRequestTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PriceFileRequestTemplateActionTypes.SavePriceFileRequestTemplateFail),
      mapTo(
        new GlobalMessageActions.AddMessage({
          text: {
            key: 'priceFiles.template.saveFailure_message',
          },
          type: GlobalMessageType.MSG_TYPE_CONFIRMATION,
        })
      )
    )
  );

  deletePriceFileTemplate$: Observable<
    | PriceFileRequestTemplateActions.DeletePriceFileRequestTemplateSuccess
    | PriceFileRequestTemplateActions.DeletePriceFileRequestTemplateFail
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(PriceFileRequestTemplateActionTypes.DeletePriceFileRequestTemplate),
      switchMap((action: DeletePriceFileRequestTemplate) => {
        const code = [].concat(action.meta.entityId).join();
        return this.connector.delete(action.userId, code).pipe(
          map(() => new PriceFileRequestTemplateActions.DeletePriceFileRequestTemplateSuccess(code)),
          catchError((error) =>
            of(new PriceFileRequestTemplateActions.DeletePriceFileRequestTemplateFail(code, normalizeHttpError(error)))
          )
        );
      }),
      withdrawOn(this.contextChange$)
    )
  );

  constructor(private actions$: Actions, private connector: PriceFileRequestTemplateConnector) {}
}
