import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import { PyStateUtils } from '../../../state';
import { PUNCHOUT_DATA_PROCESS } from '../user-state';

export enum PunchOutDataActionTypes {
  LoadPunchOutData = '[User] Load PunchOut data',
  LoadPunchOutDataFail = '[User] Load PunchOut data fail',
  LoadPunchOutDataSuccess = '[User] Load PunchOut data success',
  LoadPunchOutDataReset = '[User] Clear PunchOut data',
}

export class LoadPunchOutData extends StateUtils.EntityLoadAction {
  readonly type = PunchOutDataActionTypes.LoadPunchOutData;

  constructor(public payload: string) {
    super(PROCESS_FEATURE, PUNCHOUT_DATA_PROCESS);
  }
}

export class LoadPunchOutDataFail extends PyStateUtils.EntityFailAction {
  readonly type = PunchOutDataActionTypes.LoadPunchOutDataFail;

  constructor(public payload?: any) {
    super(PROCESS_FEATURE, PUNCHOUT_DATA_PROCESS, payload);
  }
}

export class LoadPunchOutDataSuccess extends StateUtils.EntitySuccessAction {
  readonly type = PunchOutDataActionTypes.LoadPunchOutDataSuccess;

  constructor(public payload: any) {
    super(PROCESS_FEATURE, PUNCHOUT_DATA_PROCESS, payload);
  }
}

export class LoadPunchOutDataReset extends StateUtils.EntityLoaderResetAction {
  readonly type = PunchOutDataActionTypes.LoadPunchOutDataReset;

  constructor() {
    super(PROCESS_FEATURE, PUNCHOUT_DATA_PROCESS);
  }
}

export type PunchOutDataActions = LoadPunchOutData | LoadPunchOutDataFail | LoadPunchOutDataSuccess | LoadPunchOutDataReset;
