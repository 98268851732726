import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import { delay, filter, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { WindowRef } from '../../window';
import { ReloadRequest } from '../models';

@Injectable({ providedIn: 'root' })
export class ReloadService {
  private readonly REJECT_RETRY_TIMEOUT = 3 * 60 * 1000;
  private id$: ReplaySubject<string> = new ReplaySubject<string>();
  private reject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private silenced$: Observable<boolean> = this.reject$.pipe(
    switchMap((rejected) => (rejected ? of(false).pipe(delay(this.REJECT_RETRY_TIMEOUT), startWith(true)) : of(false)))
  );
  private reloadRequests$: Observable<ReloadRequest> = combineLatest([this.id$, this.silenced$]).pipe(
    filter(([id, silenced]) => this.winRef.isBrowser() && id && !silenced),
    map(([id]) => ({ accept: () => this.reload(id), reject: () => this.reject$.next(true) } as ReloadRequest)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  constructor(protected winRef: WindowRef) {}

  getReloadRequests(): Observable<ReloadRequest> {
    return this.reloadRequests$;
  }

  requestReload(id: string): void {
    this.id$.next(id);
  }

  reload(id: string): void {
    const url = new URL(this.winRef.document.location.href);
    url.searchParams.set('reload', id);
    this.winRef.document.location.assign(url.toString());
  }
}
