import { TimeUtils as CxTimeUtils } from '@spartacus/core';

export class TimeUtils extends CxTimeUtils {
  static defaultDateTimeFormat(): string {
    return 'yyyy-MM-dd HH:mm';
  }

  static defaultDownloadDateTimeFormat(): string {
    return 'yyyy-MM-dd_HH.mm.ss';
  }

  static defaultDateFormat(): string {
    return 'yyyy-MM-dd';
  }
}
