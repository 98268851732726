import { Injectable } from '@angular/core';
import { Priority } from '@spartacus/core';
import { GlobalMessageService } from '../../../facade';
import { GlobalMessageType } from '../../../models/global-message.model';
import { HttpResponseStatus } from '../../../models/response-status.model';
import { HttpErrorHandler } from '../http-error.handler';

@Injectable({
  providedIn: 'root',
})
export class ServiceUnavailableHandler extends HttpErrorHandler {
  responseStatus = HttpResponseStatus.SERVICE_UNAVAILABLE;

  constructor(protected globalMessageService: GlobalMessageService) {
    super(globalMessageService);
  }

  handleError() {
    this.globalMessageService.add({ key: 'errors.serviceUnavailable_message' }, GlobalMessageType.MSG_TYPE_ERROR);
  }

  getPriority(): Priority {
    return Priority.LOW;
  }
}
