import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import { CompanySignUp, CompanySignUpPage, CompanySignUpRequest, HttpErrorModel } from '../../../model';
import { PyStateUtils } from '../../../state';
import { LOAD_COMPANY_PROCESS, SEARCH_COMPANY_PROCESS } from '../user-state';

export enum CompanyActionTypes {
  SearchCompany = '[SignUp] Search company',
  SearchCompanyFail = '[SignUp] Search company fail',
  SearchCompanySuccess = '[SignUp] Search company success',
  SearchCompanyReset = '[SignUp] Reset search company',

  LoadCompany = '[SignUp] Load company',
  LoadCompanyFail = '[SignUp] Load company fail',
  LoadCompanySuccess = '[SignUp] Load company success',
}

export class SearchCompany extends StateUtils.EntityLoadAction {
  readonly type = CompanyActionTypes.SearchCompany;

  constructor(public payload: CompanySignUpRequest) {
    super(PROCESS_FEATURE, SEARCH_COMPANY_PROCESS);
  }
}

export class SearchCompanyFail extends PyStateUtils.EntityFailAction {
  readonly type = CompanyActionTypes.SearchCompanyFail;

  constructor(public payload?: HttpErrorModel) {
    super(PROCESS_FEATURE, SEARCH_COMPANY_PROCESS, payload);
  }
}

export class SearchCompanySuccess extends StateUtils.EntitySuccessAction {
  readonly type = CompanyActionTypes.SearchCompanySuccess;

  constructor(payload: CompanySignUpPage) {
    super(PROCESS_FEATURE, SEARCH_COMPANY_PROCESS, payload);
  }
}

export class SearchCompanyReset extends StateUtils.EntityLoaderResetAction {
  readonly type = CompanyActionTypes.SearchCompanyReset;

  constructor() {
    super(PROCESS_FEATURE, SEARCH_COMPANY_PROCESS);
  }
}

export class LoadCompany extends StateUtils.EntityLoadAction {
  readonly type = CompanyActionTypes.LoadCompany;

  constructor(public id: string, public companySearchServiceType?: string) {
    super(PROCESS_FEATURE, LOAD_COMPANY_PROCESS);
  }
}

export class LoadCompanyFail extends PyStateUtils.EntityFailAction {
  readonly type = CompanyActionTypes.LoadCompanyFail;

  constructor(public payload?: HttpErrorModel) {
    super(PROCESS_FEATURE, LOAD_COMPANY_PROCESS, payload);
  }
}

export class LoadCompanySuccess extends StateUtils.EntitySuccessAction {
  readonly type = CompanyActionTypes.LoadCompanySuccess;

  constructor(payload: CompanySignUp) {
    super(PROCESS_FEATURE, LOAD_COMPANY_PROCESS, payload);
  }
}

export type CompanyActions =
  | SearchCompany
  | SearchCompanyFail
  | SearchCompanySuccess
  | SearchCompanyReset
  | LoadCompany
  | LoadCompanyFail
  | LoadCompanySuccess;
