import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { AbstractReactiveFormInput } from '../reactive-forms-input/abstract-reactive-form-input';

@Component({
  selector: 'py-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFieldComponent extends AbstractReactiveFormInput implements OnInit {
  @Input()
  placeholder = '';

  @Input()
  fieldName: string;

  @Input()
  group: UntypedFormGroup;

  @Input()
  label: string;

  @Output()
  handleReset = new EventEmitter<void>();

  constructor() {
    super();
  }

  getField(): AbstractControl {
    return this.getGroup().get(this.fieldName);
  }

  getGroup(): UntypedFormGroup {
    return this.group;
  }

  isIncludeGroupErrors(): boolean {
    return false;
  }

  ngOnInit(): void {}
}
