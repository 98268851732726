import { StateUtils } from '@spartacus/core';
import { A4SampleColor, AlternativeProducts, Product } from '../../../../model';
import { PyStateUtils } from '../../../../state';
import { A4_SAMPLE_COLOR, PRODUCT, SUBSTITUTE_PRODUCTS } from '../../../store/catalog-state';

export enum ProductActionTypes {
  LoadProduct = '[Product] Load Product',
  LoadProductSuccess = '[Product] Load Product Success',
  LoadProductFail = '[Product] Load Product Fail',

  LoadSubstituteProducts = '[Catalog] Load Substitute Products',
  LoadSubstituteProductsSuccess = '[Catalog] Load Substitute Products Success',
  LoadSubstituteProductsFail = '[Catalog] Load Substitute Products Fail',

  LoadA4SampleColors = '[Product] Load A4 Sample Colors',
  LoadA4SampleColorsSuccess = '[Product] Load A4 Sample Colors Success',
  LoadA4SampleColorsFail = '[Product] Load A4 Sample Colors Fail',
}

export class LoadProduct extends StateUtils.EntityLoadAction {
  readonly type = ProductActionTypes.LoadProduct;

  constructor(public payload: string, public userId: string, public key: string, public myAssortment?: string) {
    super(PRODUCT, payload);
  }
}

export class LoadProductSuccess extends StateUtils.EntitySuccessAction {
  readonly type = ProductActionTypes.LoadProductSuccess;

  constructor(public payload: Product, public key: string) {
    super(PRODUCT, payload.code, payload);
  }
}

export class LoadProductFail extends PyStateUtils.EntityFailAction {
  readonly type = ProductActionTypes.LoadProductFail;

  constructor(public code: string, public key: string, public payload: any) {
    super(PRODUCT, code, payload);
  }
}

export class LoadSubstituteProducts extends StateUtils.EntityLoadAction {
  readonly type = ProductActionTypes.LoadSubstituteProducts;

  constructor(public code: string, public userId: string) {
    super(SUBSTITUTE_PRODUCTS, code);
  }
}

export class LoadSubstituteProductsSuccess extends StateUtils.EntitySuccessAction {
  readonly type = ProductActionTypes.LoadSubstituteProductsSuccess;

  constructor(public payload: AlternativeProducts) {
    super(SUBSTITUTE_PRODUCTS, payload.alternativeTo, payload);
  }
}

export class LoadSubstituteProductsFail extends PyStateUtils.EntityFailAction {
  readonly type = ProductActionTypes.LoadSubstituteProductsFail;

  constructor(public code: string, public payload: any) {
    super(SUBSTITUTE_PRODUCTS, code, payload);
  }
}

export class LoadA4SampleColors extends StateUtils.EntityLoadAction {
  readonly type = ProductActionTypes.LoadA4SampleColors;

  constructor(public userId: string, public productCode: string, public grammage: number) {
    super(A4_SAMPLE_COLOR, `${productCode}#${grammage}`);
  }
}

export class LoadA4SampleColorsSuccess extends StateUtils.EntitySuccessAction {
  readonly type = ProductActionTypes.LoadA4SampleColorsSuccess;

  constructor(public productCode: string, public grammage: number, public payload: A4SampleColor[]) {
    super(A4_SAMPLE_COLOR, `${productCode}#${grammage}`, payload);
  }
}

export class LoadA4SampleColorsFail extends PyStateUtils.EntityFailAction {
  readonly type = ProductActionTypes.LoadA4SampleColorsFail;

  constructor(public productCode: string, public grammage: number, public payload: any) {
    super(A4_SAMPLE_COLOR, `${productCode}#${grammage}`, payload);
  }
}

export type ProductActions =
  | LoadProduct
  | LoadProductSuccess
  | LoadProductFail
  | LoadSubstituteProducts
  | LoadSubstituteProductsSuccess
  | LoadSubstituteProductsFail
  | LoadA4SampleColors
  | LoadA4SampleColorsSuccess
  | LoadA4SampleColorsFail;
