import { StateUtils } from '@spartacus/core';
import { Feed, FeedBadgeType, FeedRequestList } from '../../../model/feed.model';
import { PyStateUtils } from '../../../state/utils';
import { FEED_DATA } from '../user-state';

export enum FeedDataActionTypes {
  LOAD_FEED_DATA = '[User] Load Feed Data',
  LOAD_FEED_DATA_FAIL = '[User] Load Feed Data Fail',
  LOAD_FEED_DATA_SUCCESS = '[User] Load Feed Data Success',
  LOAD_PARTIALLY_FEED_DATA_SUCCESS = '[User] Load Partially Feed Data Success',
  UPDATE_BADGE_COUNT_FOR_FEED_DATA = '[User] Update Badge Count For Feed Data',
  UPDATE_AREAS_OF_USE_SYNC_DATES_FOR_FEED_DATA = '[User] Update Areas Of Use Sync Dates For Feed Data',
  UPDATE_HAS_ONGOING_SOLD_TO_PRICE_FILE_REQUEST_FOR_FEED_DATA = '[User] Update Has Ongoing Sold To Price File Request For Feed Data',
}

export class LoadFeedData extends StateUtils.LoaderLoadAction {
  readonly type = FeedDataActionTypes.LOAD_FEED_DATA;

  constructor(public userId: string, public feedRequestList?: FeedRequestList) {
    super(FEED_DATA);
  }
}

export class LoadFeedDataSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = FeedDataActionTypes.LOAD_FEED_DATA_SUCCESS;

  constructor(public payload: Feed) {
    super(FEED_DATA);
  }
}

export class LoadFeedDataFail extends PyStateUtils.LoaderFailAction {
  readonly type = FeedDataActionTypes.LOAD_FEED_DATA_FAIL;

  constructor(public payload?: any) {
    super(FEED_DATA, payload);
  }
}

export class LoadPartiallyFeedDataSuccess {
  readonly type = FeedDataActionTypes.LOAD_PARTIALLY_FEED_DATA_SUCCESS;

  constructor(public payload: Feed) {}
}

export class UpdateBadgeCountForFeedData {
  readonly type = FeedDataActionTypes.UPDATE_BADGE_COUNT_FOR_FEED_DATA;

  constructor(public payload: { amount: number; badgeType: FeedBadgeType; shouldDecrement: boolean }) {}
}

export class UpdateAreaOfUseSyncDatesForFeedData {
  readonly type = FeedDataActionTypes.UPDATE_AREAS_OF_USE_SYNC_DATES_FOR_FEED_DATA;

  constructor(public payload: { lastAreaOfUseSyncStartTime?: string; lastAreaOfUseSyncEndTime?: string }) {}
}

export class UpdateHasOngoingSoldToPriceFileRequestForFeedData {
  readonly type = FeedDataActionTypes.UPDATE_HAS_ONGOING_SOLD_TO_PRICE_FILE_REQUEST_FOR_FEED_DATA;

  constructor(public payload: boolean) {}
}

export type FeedDataAction =
  | LoadFeedData
  | LoadFeedDataSuccess
  | LoadFeedDataFail
  | LoadPartiallyFeedDataSuccess
  | UpdateBadgeCountForFeedData
  | UpdateAreaOfUseSyncDatesForFeedData
  | UpdateHasOngoingSoldToPriceFileRequestForFeedData;
