import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'py-consent-banner',
  templateUrl: './consent-banner.component.html',
  styleUrls: ['./consent-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsentBannerComponent {
  @Output()
  viewSettings = new EventEmitter<void>();

  @Output()
  allowAll = new EventEmitter<void>();

  constructor() {}

  onViewSettings() {
    this.viewSettings.emit();
  }

  onAllowAll() {
    this.allowAll.emit();
  }
}
