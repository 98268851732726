import { StateUtils } from '@spartacus/core';
import {
  ActionTokenPayload,
  CartType,
  Cutting,
  CuttingRequest,
  CuttingTemplate,
  CuttingTemplateRequest,
  HttpErrorModel,
  OrderEntry,
} from '../../../../../core/model';
import { PyStateUtils } from '../../../../../core/state';
import { CART, CUTTING_PRICE, CUTTING_TEMPLATE } from '../cart-state';

export enum CartEntryCuttingActionTypes {
  AddCartEntryCutting = '[Cart] Add cutting for cart entry',
  AddCartEntryCuttingSuccess = '[Cart] Add cutting for cart entry success',
  AddCartEntryCuttingFail = '[Cart] Add cutting for cart entry fail',

  RemoveCartEntryCutting = '[Cart] Remove cutting for cart entry',
  RemoveCartEntryCuttingSuccess = '[Cart] Remove cutting for cart entry success',
  RemoveCartEntryCuttingFail = '[Cart] Remove cutting for cart entry fail',

  CalculateCuttingPrice = '[Cart] Calculate cutting price for cart entry',
  CalculateCuttingPriceSuccess = '[Cart] Calculate cutting price for cart entry success',
  CalculateCuttingPriceFail = '[Cart] Calculate cutting price for cart entry fail',

  CalculateCuttingTemplate = '[Cart] Calculate cutting template for cart entry',
  CalculateCuttingTemplateSuccess = '[Cart] Calculate cutting template for cart entry success',
  CalculateCuttingTemplateFail = '[Cart] Calculate cutting template for cart entry fail',
}

interface AddCartEntryCuttingPayloadBase {
  cartType: CartType;
  cartId: string;
  userId: string;
  entry: OrderEntry;
  cutting: CuttingRequest;
}

interface AddCartEntryCuttingPayload extends AddCartEntryCuttingPayloadBase, ActionTokenPayload<boolean> {}

interface AddCartEntryCuttingFailPayload extends AddCartEntryCuttingPayloadBase {
  error?: HttpErrorModel;
}

interface AddCartEntryCuttingSuccessPayload extends AddCartEntryCuttingPayloadBase {}

interface RemoveCartEntryCuttingPayloadBase {
  cartType: CartType;
  cartId: string;
  userId: string;
  entry: OrderEntry;
}

interface RemoveCartEntryCuttingPayload extends RemoveCartEntryCuttingPayloadBase, ActionTokenPayload<boolean> {}

interface RemoveCartEntryCuttingFailPayload extends RemoveCartEntryCuttingPayloadBase {
  error?: HttpErrorModel;
}

interface RemoveCartEntryCuttingSuccessPayload extends RemoveCartEntryCuttingPayloadBase {}

interface CalculateCuttingPricePayload {
  cartType: CartType;
  cartId: string;
  userId: string;
  entry: OrderEntry;
  cutting: CuttingRequest;
  key: string;
}

interface CalculateCuttingPriceFailPayload extends CalculateCuttingPricePayload {
  error?: HttpErrorModel;
}

interface CalculateCuttingPriceSuccessPayload extends CalculateCuttingPricePayload {
  result: Cutting;
}

interface CalculateCuttingTemplatePayload {
  cartType: CartType;
  cartId: string;
  userId: string;
  entry: OrderEntry;
  cuttingTemplate: CuttingTemplateRequest;
  key: string;
}

interface CalculateCuttingTemplateFailPayload extends CalculateCuttingTemplatePayload {
  error?: HttpErrorModel;
}

interface CalculateCuttingTemplateSuccessPayload extends CalculateCuttingTemplatePayload {
  result: CuttingTemplate;
}

export class AddCartEntryCutting extends StateUtils.EntityProcessesIncrementAction {
  readonly type = CartEntryCuttingActionTypes.AddCartEntryCutting;

  constructor(public payload: AddCartEntryCuttingPayload) {
    super(CART, payload.cartId);
  }
}

export class AddCartEntryCuttingFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryCuttingActionTypes.AddCartEntryCuttingFail;

  constructor(public payload: AddCartEntryCuttingFailPayload) {
    super(CART, payload.cartId);
  }
}

export class AddCartEntryCuttingSuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryCuttingActionTypes.AddCartEntryCuttingSuccess;

  constructor(public payload: AddCartEntryCuttingSuccessPayload) {
    super(CART, payload.cartId);
  }
}

export class RemoveCartEntryCutting extends StateUtils.EntityProcessesIncrementAction {
  readonly type = CartEntryCuttingActionTypes.RemoveCartEntryCutting;

  constructor(public payload: RemoveCartEntryCuttingPayload) {
    super(CART, payload.cartId);
  }
}

export class RemoveCartEntryCuttingFail extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryCuttingActionTypes.RemoveCartEntryCuttingFail;

  constructor(public payload: RemoveCartEntryCuttingFailPayload) {
    super(CART, payload.cartId);
  }
}

export class RemoveCartEntryCuttingSuccess extends StateUtils.EntityProcessesDecrementAction {
  readonly type = CartEntryCuttingActionTypes.RemoveCartEntryCuttingSuccess;

  constructor(public payload: RemoveCartEntryCuttingSuccessPayload) {
    super(CART, payload.cartId);
  }
}

export class CalculateCuttingPrice extends StateUtils.EntityLoadAction {
  readonly type = CartEntryCuttingActionTypes.CalculateCuttingPrice;

  constructor(public payload: CalculateCuttingPricePayload) {
    super(CUTTING_PRICE, payload.key);
  }
}

export class CalculateCuttingPriceFail extends PyStateUtils.EntityFailAction {
  readonly type = CartEntryCuttingActionTypes.CalculateCuttingPriceFail;

  constructor(public payload: CalculateCuttingPriceFailPayload) {
    super(CUTTING_PRICE, payload.key, payload.error);
  }
}

export class CalculateCuttingPriceSuccess extends StateUtils.EntitySuccessAction {
  readonly type = CartEntryCuttingActionTypes.CalculateCuttingPriceSuccess;

  constructor(public payload: CalculateCuttingPriceSuccessPayload) {
    super(CUTTING_PRICE, payload.key, payload);
  }
}

export class CalculateCuttingTemplate extends StateUtils.EntityLoadAction {
  readonly type = CartEntryCuttingActionTypes.CalculateCuttingTemplate;

  constructor(public payload: CalculateCuttingTemplatePayload) {
    super(CUTTING_TEMPLATE, payload.key);
  }
}

export class CalculateCuttingTemplateFail extends PyStateUtils.EntityFailAction {
  readonly type = CartEntryCuttingActionTypes.CalculateCuttingTemplateFail;

  constructor(public payload: CalculateCuttingTemplateFailPayload) {
    super(CUTTING_TEMPLATE, payload.key, payload.error);
  }
}

export class CalculateCuttingTemplateSuccess extends StateUtils.EntitySuccessAction {
  readonly type = CartEntryCuttingActionTypes.CalculateCuttingTemplateSuccess;

  constructor(public payload: CalculateCuttingTemplateSuccessPayload) {
    super(CUTTING_TEMPLATE, payload.key, payload);
  }
}

export type CartEntryCuttingActions =
  | AddCartEntryCutting
  | AddCartEntryCuttingFail
  | AddCartEntryCuttingSuccess
  | RemoveCartEntryCutting
  | RemoveCartEntryCuttingFail
  | RemoveCartEntryCuttingSuccess
  | CalculateCuttingPrice
  | CalculateCuttingPriceFail
  | CalculateCuttingPriceSuccess
  | CalculateCuttingTemplate
  | CalculateCuttingTemplateFail
  | CalculateCuttingTemplateSuccess;
