import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { LoginLogoutActionTypes } from '../../../../auth/user-auth/store/actions/auth-group.actions';
import { GlobalMessageType, isErrorGloballyHandled } from '../../../../global-message';
import { GlobalMessageActions } from '../../../../global-message/store/actions';
import { normalizeHttpError, withdrawOn } from '../../../../util';
import { AppActions } from '../../../../util/store/actions';
import { AddonNodeConnector } from '../../connector/addon-node-connector';
import { AddonNodeActions } from '../actions';
import { AddonNodeActionTypes } from '../actions/addon-node.action';

@Injectable({ providedIn: 'root' })
export class AddonNodeEffects {
  private contextChange$ = this.actions$.pipe(ofType(LoginLogoutActionTypes.Logout));

  loadAddonNode$: Observable<AddonNodeActions.LoadAddonNodeSuccess | AddonNodeActions.LoadAddonNodeFail> = createEffect(() =>
    this.actions$.pipe(
      ofType(AddonNodeActionTypes.LoadAddonNode),
      map((action) => <AddonNodeActions.LoadAddonNode>action),
      mergeMap((action: AddonNodeActions.LoadAddonNode) => {
        return this.connector.load(action.userId, action.addonGroupId, action.addonNodeId, action.sort).pipe(
          map(
            (data) =>
              new AddonNodeActions.LoadAddonNodeSuccess({ ...data, sort: action.sort }, action.addonGroupId, action.addonNodeId)
          ),
          catchError((error) =>
            of(new AddonNodeActions.LoadAddonNodeFail(normalizeHttpError(error), action.addonGroupId, action.addonNodeId))
          )
        );
      }),
      withdrawOn(this.contextChange$)
    )
  );

  handleFailure$: Observable<GlobalMessageActions.AddMessage | AppActions.NoOpAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(AddonNodeActions.AddonNodeActionTypes.LoadAddonNodeFail),
      map((action: AddonNodeActions.LoadAddonNodeFail) => {
        if (!isErrorGloballyHandled(action.payload?.status)) {
          return new GlobalMessageActions.AddMessage({
            text: {
              key: 'addon.addonNodeNotFound_message',
              params: {
                addonGroupId: action.addonGroupId,
                addonNodeId: action.addonNodeId,
              },
            },
            type: GlobalMessageType.MSG_TYPE_ERROR,
          });
        } else {
          return new AppActions.NoOpAction();
        }
      })
    )
  );

  constructor(private actions$: Actions, private connector: AddonNodeConnector) {}
}
