import { StateUtils } from '@spartacus/core';
import { Feed, FeedBadgeType, FeedOperationalMessageType } from '../../../model';
import { UserActions } from '../actions';
import {
  UpdateAreaOfUseSyncDatesForFeedData,
  UpdateBadgeCountForFeedData,
  UpdateHasOngoingSoldToPriceFileRequestForFeedData,
} from '../actions/feed.action';

export function reducer(state: Feed, action: UserActions.FeedDataAction | StateUtils.LoaderAction): Feed {
  switch (action.type) {
    case UserActions.FeedDataActionTypes.UPDATE_BADGE_COUNT_FOR_FEED_DATA: {
      const updateAction = <UpdateBadgeCountForFeedData>action;
      if (state && state?.badges) {
        return getStateWithUpdatedBadgeCount(
          state,
          updateAction.payload.badgeType,
          updateAction.payload.amount,
          updateAction.payload.shouldDecrement
        );
      } else {
        return state;
      }
    }
    case UserActions.FeedDataActionTypes.UPDATE_AREAS_OF_USE_SYNC_DATES_FOR_FEED_DATA: {
      const updateAction = <UpdateAreaOfUseSyncDatesForFeedData>action;
      return {
        ...state,
        lastAreaOfUseSyncStartTime: updateAction.payload.lastAreaOfUseSyncStartTime,
        lastAreaOfUseSyncEndTime: updateAction.payload.lastAreaOfUseSyncEndTime,
      };
    }
    case UserActions.FeedDataActionTypes.UPDATE_HAS_ONGOING_SOLD_TO_PRICE_FILE_REQUEST_FOR_FEED_DATA: {
      const updateAction = <UpdateHasOngoingSoldToPriceFileRequestForFeedData>action;
      return {
        ...state,
        hasOngoingSoldToPriceFileRequest: updateAction.payload,
      };
    }
    case UserActions.FeedDataActionTypes.LOAD_PARTIALLY_FEED_DATA_SUCCESS: {
      const { operationalMessage, badges, ...payload } = action.payload as Feed;
      return {
        ...state,
        ...payload,
        ...(operationalMessage?.status !== undefined && operationalMessage.status !== FeedOperationalMessageType.NOT_MODIFIED
          ? { operationalMessage }
          : {}),
        badges: [...(badges || []), ...(state?.badges?.filter((badge) => !badges?.find((b) => b.type === badge.type)) || [])],
      };
    }
    case UserActions.FeedDataActionTypes.LOAD_FEED_DATA_SUCCESS: {
      return action.payload;
    }
  }
  return state;
}

function getStateWithUpdatedBadgeCount(state: Feed, badgeType: FeedBadgeType, amount: number, shouldDecrement: boolean): Feed {
  const badges = [...state.badges];
  return {
    ...state,
    badges: badges.map((badge) => {
      if (badge.type === badgeType) {
        const badgeCopy = { ...badge };
        badgeCopy.count = shouldDecrement ? badge.count - amount : badge.count + amount;
        return badgeCopy;
      } else {
        return badge;
      }
    }),
  };
}
