import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Observable, Subscription, zip } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ArticleService } from '../../../core/catalog';
import { TranslationService } from '../../../core/i18n';
import {
  AlternativeMaterialKey,
  Article,
  ArticleCarouselItem,
  CartType,
  OrderEntry,
  ResolveOption,
  ResolveOptionActionType,
} from '../../../core/model';
import { CheckoutFacade } from '../../../features/checkout/base';

interface DefaultResolveOptionContext extends ResolveOption {
  title$: Observable<string>;
}

interface ReplacementResolveOptionContext extends DefaultResolveOptionContext {
  alternatives$: Observable<ArticleCarouselItem[]>;
}

@Component({
  selector: 'py-order-entry-resolve-options',
  templateUrl: './order-entry-resolve-options.component.html',
  styleUrls: ['./order-entry-resolve-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderEntryResolveOptionsComponent implements OnInit, OnDestroy {
  readonly stockCartType = CartType.stock;

  private subscription: Subscription = new Subscription();
  private _entry: OrderEntry;

  @Input() set entry(orderEntry: OrderEntry) {
    this._entry = orderEntry;

    const resolveOptionsWithTitle = orderEntry.resolveOptions.map((resolveOption) => ({
      ...resolveOption,
      title$: this.translationService.translate('checkout.review.resolveOption', { context: `${resolveOption.action}_title` }),
    }));
    this.defaultResolveOptions = resolveOptionsWithTitle.filter((resolveOption) =>
      this.filterDefaultResolveOptions(resolveOption, orderEntry)
    );
    const replacementResolveOption = resolveOptionsWithTitle.find(
      (resolveOption) => resolveOption.action === ResolveOptionActionType.ALTERNATIVE_ARTICLE
    );
    this.replacementResolveOption = replacementResolveOption && {
      ...replacementResolveOption,
      alternatives$: zip(
        ...replacementResolveOption.alternativeArticles.map((entry) =>
          this.articleService.getArticle(entry.articleRef).pipe(map((article) => ({ article, entry })))
        )
      ),
    };
  }

  get entry(): OrderEntry {
    return this._entry;
  }

  @Input() stockQuantityWarning: string;
  @Input() enableSecondaryArticleRowVariant = false;

  @Output() resolve = new EventEmitter<ResolveOptionActionType>();

  isResolveOptionsExpanded = false;
  defaultResolveOptions: DefaultResolveOptionContext[];
  replacementResolveOption?: ReplacementResolveOptionContext;
  resolved = false;

  constructor(
    private articleService: ArticleService,
    private translationService: TranslationService,
    private checkoutService: CheckoutFacade,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.checkoutService
        .getResolveError()
        .pipe(filter(Boolean))
        .subscribe((_) => {
          this.resolved = false;
          this.cd.detectChanges();
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private filterDefaultResolveOptions(resolveOption: ResolveOption, orderEntry: OrderEntry): boolean {
    if (resolveOption.action === ResolveOptionActionType.ALTERNATIVE_ARTICLE) {
      return false;
    }

    if (resolveOption.action === ResolveOptionActionType.ALTERNATIVE_DATE) {
      if (!orderEntry?.autoResolveChange?.confirmedQuantity && !orderEntry?.autoResolveChange?.confirmedUnit) {
        return false;
      }
    }

    return true;
  }

  onSelectReplacementArticle(carouselItem: ArticleCarouselItem): void {
    const resolveOptionAlternativeType = this.getAlternativeArticleResolveOptionTypeForSelectedArticle(carouselItem.article);

    if (resolveOptionAlternativeType) {
      this.resolved = true;
      this.resolve.emit(resolveOptionAlternativeType);
    }
  }

  onDefaultResolveOptionSelect(resolveOption: ResolveOption): void {
    this.resolved = true;
    this.resolve.emit(resolveOption.action);
  }

  onToggleResolveOptions(): void {
    this.isResolveOptionsExpanded = !this.isResolveOptionsExpanded;
  }

  private getAlternativeArticleResolveOptionTypeForSelectedArticle(replacementArticle: Article): ResolveOptionActionType | null {
    const originalSchedule = this.entry.autoResolveChange?.originalSchedule;

    // Gets object key (i.e. 'alternativeMaterial', 'alternativeMaterial2' etc.) that holds the code of the selected alternative article
    const alternativeMaterialKey = Object.keys(originalSchedule || {})
      .filter((key) => key.startsWith('alternativeMaterial'))
      .find((key) => originalSchedule[key] === replacementArticle.code);

    // Mapping the above key to respective ResolveOptionType
    switch (alternativeMaterialKey) {
      case AlternativeMaterialKey.ALTERNATIVE_MATERIAL:
        return ResolveOptionActionType.ALTERNATIVE_ARTICLE1;
      case AlternativeMaterialKey.ALTERNATIVE_MATERIAL2:
        return ResolveOptionActionType.ALTERNATIVE_ARTICLE2;
      case AlternativeMaterialKey.ALTERNATIVE_MATERIAL3:
        return ResolveOptionActionType.ALTERNATIVE_ARTICLE3;
      case AlternativeMaterialKey.ALTERNATIVE_MATERIAL4:
        return ResolveOptionActionType.ALTERNATIVE_ARTICLE4;
      default:
        return null;
    }
  }
}
