import { DIALOG_TYPE, LAUNCH_CALLER, LayoutConfig } from '@spartacus/storefront';
import { LegalModalComponent } from './legal-modal.component';

export const legalModalLayoutConfig: LayoutConfig = {
  launch: {
    [LAUNCH_CALLER.LEGAL]: {
      inlineRoot: true,
      component: LegalModalComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};

export const legalModalDismissOnLogoutLayoutConfig: LayoutConfig = {
  launch: {
    [LAUNCH_CALLER.LEGAL_DISMISSABLE_ON_LOGOUT]: {
      inlineRoot: true,
      component: LegalModalComponent,
      dialogType: DIALOG_TYPE.DIALOG,
      dialogOptions: {
        autoDismissOnRouteChange: false,
        autoDismissOnLogout: true,
      },
    },
  },
};
