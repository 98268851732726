<py-modal
  *ngIf="uploadedFiles"
  [showCancelButton]="false"
  [centered]="true"
  [hideFooter]="true"
  [cxFocus]="focusConfig"
  [hasWidePaddings]="false"
  (esc)="closeModal('escape pressed')"
>
  <div class="image-modal-cover d-flex flex-column justify-content-center align-items-center">
    <div class="wrapper d-flex flex-column">
      <div class="d-flex images justify-content-center">
        <ngb-carousel
          class="w-100"
          #ngbcarousel
          [interval]="0"
          [showNavigationArrows]="false"
          [showNavigationIndicators]="false"
          [activeId]="activeSlideId.toString()"
        >
          <ng-template ngbSlide *ngFor="let uploadedFile of uploadedFiles; let i = index" [id]="i.toString()">
            <ng-container *ngIf="uploadedFile?.alternativeUrl || uploadedFile?.downloadUrl; else placeholderTemplate">
              <!-- Image received from backend -->
              <div class="image-modal-carousel-media">
                <ng-container *ngIf="hasPdfType(uploadedFile.filename); else imageTypeTemplate">
                  <img [pyMedia]="uploadedFile.thumbnail" [alt]="uploadedFile.filename" />
                </ng-container>
                <ng-template #imageTypeTemplate>
                  <img
                    [src]="getFileAbsoluteUrl(uploadedFile?.alternativeUrl || uploadedFile?.downloadUrl)"
                    [alt]="uploadedFile.filename"
                  />
                </ng-template>
              </div>
            </ng-container>
            <ng-template #placeholderTemplate>
              <div class="image-modal-carousel-media">
                <img
                  [src]="hasPdfType(uploadedFile.filename) ? 'assets/outline/no-pdf.svg' : 'assets/outline/no-image.svg'"
                  [alt]="uploadedFile.filename"
                />
              </div>
            </ng-template>
          </ng-template>
        </ngb-carousel>
      </div>

      <div class="row mt-auto">
        <div class="col-12 d-flex flex-wrap justify-content-center mt-4 mb-2 px-2">
          <ng-container *ngFor="let uploadedFile of uploadedFiles; let fileIndex = index">
            <div class="position-relative">
              <ng-container *ngIf="uploadedFile?.thumbnail; else noUploadedThumbnailTemplate">
                <img
                  class="mr-2 mb-2 file-preview"
                  [class.p-2]="hasPdfType(uploadedFile.filename)"
                  [class.active]="fileIndex === activeSlideId"
                  [pyMedia]="uploadedFile.thumbnail"
                  [alt]="uploadedFile.filename"
                  [ngbTooltip]="uploadedFile.filename"
                  [placement]="['right', 'bottom']"
                  (click)="navigateToSlide(fileIndex)"
                />

                <ng-container *ngTemplateOutlet="removeFileButtonTemplate"></ng-container>
              </ng-container>
            </div>

            <ng-template #noUploadedThumbnailTemplate>
              <ng-container *ngIf="getFilePreview(uploadedFile.qualifier) as filePreview; else placeholderTemplate">
                <!-- Thumbnail from file uploaded into browser -->
                <img
                  class="mr-2 mb-2 file-preview"
                  [ngClass]="{ 'p-3': !hasImageType(filePreview.file.type) }"
                  [src]="hasImageType(filePreview.file.type) ? filePreview.fileUrl : 'assets/outline/no-pdf.svg'"
                  [alt]="filePreview.file.name"
                  [ngbTooltip]="filePreview.file.name"
                  [placement]="['right', 'bottom']"
                  [class.active]="fileIndex === activeSlideId"
                  (click)="navigateToSlide(fileIndex)"
                />

                <ng-container *ngTemplateOutlet="removeFileButtonTemplate"></ng-container>
              </ng-container>
            </ng-template>

            <ng-template #placeholderTemplate>
              <!-- Placeholder for image file, for pdf file thumbnail is returned immediately -->
              <img
                class="mr-2 mb-2 file-preview"
                [src]="'assets/outline/no-image.svg'"
                [alt]="uploadedFile.filename"
                [ngbTooltip]="
                  'orderHistory.orderReturn.convertingUploadedFile_tooltip' | cxTranslate : { filename: uploadedFile.filename }
                "
                [placement]="['right', 'bottom']"
                [class.active]="fileIndex === activeSlideId"
                (click)="navigateToSlide(fileIndex)"
              />

              <ng-container *ngTemplateOutlet="removeFileButtonTemplate"></ng-container>
            </ng-template>

            <ng-template #removeFileButtonTemplate>
              <button
                *ngIf="isReturnModeActive"
                type="button"
                class="delete-button"
                (click)="onFileRemove(uploadedFile.qualifier)"
                [ngbTooltip]="'orderHistory.orderReturn.removeFile_tooltip' | cxTranslate"
                [placement]="'top'"
              >
                <i nz-icon nzType="close-circle" nzTheme="fill" class="d-flex"></i>
              </button>
            </ng-template>
          </ng-container>

          <span
            *ngIf="isReturnModeActive"
            class="d-inline-block"
            tabindex="0"
            [ngbTooltip]="'orderHistory.orderReturn.maximumUpload_tooltip' | cxTranslate"
            [placement]="['right', 'bottom']"
            [disableTooltip]="uploadedFiles.length < maxFilesNumber"
          >
            <py-list-insert
              class="h-100 w-100 position-relative mb-2"
              [ngClass]="{
                disabled: uploadedFiles?.length >= maxFilesNumber
              }"
              [clickable]="true"
              [iconTemplate]="uploadFileIconTemplate"
              [ngbTooltip]="'orderHistory.orderReturn.uploadFile_tooltip' | cxTranslate"
              [placement]="['right', 'bottom']"
              (click)="uploadFileInput.click()"
            >
              <input
                #uploadFileInput
                style="display: none"
                type="file"
                [accept]="'.pdf, .jpg, .jpeg, .png, .tif, .tiff'"
                (change)="onFileUpload($event)"
              />
            </py-list-insert>
          </span>
        </div>
      </div>
    </div>
  </div>

  <ng-template #uploadFileIconTemplate>
    <i nz-icon nzType="plus" class="list-insert-icon"></i>
  </ng-template>
</py-modal>
