import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AnonymousConsent } from '@spartacus/core';
import { AnonymousConsentsService } from '../../../../core/anonymous-consents/facade/anonymous-consents.service';
import { ConsentTemplate } from '../../../../core/model';

@Component({
  selector: 'py-anonymous-consent-settings',
  templateUrl: './anonymous-consent-settings.component.html',
  styleUrls: ['./anonymous-consent-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnonymousConsentSettingsComponent implements OnInit {
  @Input() consentTemplates: ConsentTemplate[];
  @Input() anonymousConsents: AnonymousConsent[];
  @Input() showCloseButton: boolean;

  @Output() showBanner = new EventEmitter<void>();
  @Output() saveSettings = new EventEmitter<{ [key: string]: boolean }>();
  @Output() hideSettings = new EventEmitter<void>();

  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private anonymousConsentsService: AnonymousConsentsService) {}

  ngOnInit(): void {
    const consents = {};
    this.consentTemplates.forEach((consentTemplate) => {
      consents[consentTemplate.id] = [this.isConsentGiven(consentTemplate.id)];
    });

    this.form = this.fb.group(consents);

    Object.keys(this.form.controls).forEach((id) => {
      if (this.isRequiredConsent(id)) {
        this.form.controls[id].disable();
      }
    });
  }

  isConsentGiven(consentTemplateId: string) {
    const anonymousConsent = this.anonymousConsents.find((consent) => consent.templateCode === consentTemplateId);
    const isConsentGiven = this.anonymousConsentsService.isConsentGiven(anonymousConsent);

    return isConsentGiven !== undefined ? isConsentGiven : false;
  }

  onBack(): void {
    this.showBanner.emit();
  }

  onDone(): void {
    this.saveSettings.emit(this.form.value);
  }

  onClose(): void {
    this.hideSettings.emit();
  }

  isRequiredConsent(consentTemplateId: string): boolean {
    const anonymousConsent = this.anonymousConsents.find((consent) => consent.templateCode === consentTemplateId);
    return this.anonymousConsentsService.isConsentRequired(anonymousConsent?.templateCode);
  }
}
