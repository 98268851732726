<ng-container *pyPrincipalConfiguration="'enableDisplayOfPrices'; else noPriceTemplate">
  <ng-container *ngIf="!loading; else loadingTemplate">
    <div
      [ngbTooltip]="tooltip$ | async"
      class="d-flex flex-column"
      [ngClass]="{
        'align-items-start': horizontalAlignment === horizontalAlignments.LEFT,
        'align-items-end': horizontalAlignment === horizontalAlignments.RIGHT
      }"
    >
      <span *ngIf="textInsteadOfPrice" class="price">{{ textInsteadOfPrice | cxTranslate }}</span>

      <ng-container *ngIf="!textInsteadOfPrice">
        <ng-container *ngIf="!showArticlePriceInsteadOfEntryPrice; else articlePriceTemplate">
          <ng-container *ngIf="!!price && price?.value > 0; else noPriceTemplate">
            <div
              *ngIf="showContractedPrice || priceCampaign || showOutgoingBadge"
              class="d-flex align-items-center justify-content-end mb-1 ml-n1"
              [class.flex-row-reverse]="horizontalAlignment === horizontalAlignments.LEFT"
            >
              <span class="d-block discounted ml-1" *ngIf="showContractedPrice">
                {{ contractedPrice | price : 'symbol-narrow' : true }}
              </span>
              <py-badge *ngIf="priceCampaign" class="ml-1" [tag]="true" [type]="badgeType.Campaign">
                {{ 'common.campaign_badge' | cxTranslate }}
              </py-badge>

              <ng-container *pyPrincipalConfiguration="'enableDisplayOutgoingArea'">
                <py-badge *ngIf="showOutgoingBadge" class="ml-1" [type]="badgeType.Outlet" [tag]="true">
                  {{ 'article.outlet_badge' | cxTranslate }}
                </py-badge>
              </ng-container>
            </div>
            <span class="price">{{ price | price : 'symbol-narrow' : true }}</span>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-template #articlePriceTemplate>
  <div
    *ngIf="showContractedPrice || priceCampaign || showOutgoingBadge"
    class="d-flex flex-gap-1 align-items-center justify-content-end mb-1 ml-n1"
    [class.flex-row-reverse]="horizontalAlignment === horizontalAlignments.LEFT"
  >
    <span class="d-block discounted ml-1" *ngIf="showContractedPrice">
      {{ contractedPrice | price : 'symbol-narrow' }}
    </span>
    <py-badge *ngIf="priceCampaign" class="ml-1" [tag]="true" [type]="badgeType.Campaign">
      {{ 'common.campaign_badge' | cxTranslate }}
    </py-badge>
    <ng-container *pyPrincipalConfiguration="'enableDisplayOutgoingArea'">
      <py-badge *ngIf="showOutgoingBadge" class="ml-1" [type]="badgeType.Outlet" [tag]="true">
        {{ 'article.outlet_badge' | cxTranslate }}
      </py-badge>
    </ng-container>
  </div>
  <ng-container *ngIf="!!articlePrice?.formattedPrice && parsePrice(articlePrice?.price) !== 0">
    <span class="price d-block">{{ articlePrice.formattedPrice }}</span>
  </ng-container>
  <ng-container
    *ngIf="!articlePrice?.formattedPrice && articlePrice?.attempts === 5"
    [ngTemplateOutlet]="loadPriceTemplate"
  ></ng-container>
  <ng-container
    *ngIf="articlePrice?.attempts > 5 || (!!articlePrice && parsePrice(articlePrice?.price) === 0) || !articlePrice"
    [ngTemplateOutlet]="noPriceTemplate"
  ></ng-container>
</ng-template>

<ng-template #loadPriceTemplate>
  <button class="load-price-button" (click)="loadPrice.emit()">
    {{ 'catalog.getPrice_action' | cxTranslate }}
  </button>
</ng-template>

<ng-template #loadingTemplate>
  <span class="price d-block loading-price-spinner">
    <cx-spinner [type]="spinnerType.Dark"></cx-spinner>
  </span>
</ng-template>

<ng-template #noPriceTemplate>
  <span class="price d-block">{{ 'catalog.priceNotAvailable_hint' | cxTranslate }}</span>
</ng-template>
