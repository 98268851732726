import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, UnaryFunction, pipe } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { SapPriceCampaignDetails, SapPriceCampaignList } from '../../../model';
import { StateWithCatalog } from '../../store/catalog-state';
import { CatalogSelectors } from '../../store/selectors';
import { PriceCampaignActions } from '../store/actions';

@Injectable({
  providedIn: 'root',
})
export class PriceCampaignService {
  constructor(private store: Store<StateWithCatalog>) {}

  getPriceCampaignDetails(campaignCode: string): Observable<SapPriceCampaignDetails | undefined> {
    return this.store.pipe(
      select(CatalogSelectors.getPriceCampaignDetailsLoaderState(campaignCode)),
      tap((loaderState) => {
        if (!loaderState.success && !loaderState.error && !loaderState.loading) {
          this.loadPriceCampaignDetails(campaignCode);
        }
      }),
      map((loaderState) => loaderState.value),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  getDefaultPriceCampaignDetails(): Observable<SapPriceCampaignDetails | undefined> {
    return this.store.pipe(
      select(CatalogSelectors.getDefaultPriceCampaign),
      filter((campaign) => !!campaign),
      switchMap((campaign) => this.getPriceCampaignDetails(campaign.code))
    );
  }

  getAvailablePriceCampaigns(): Observable<SapPriceCampaignList> {
    return this.store.pipe(
      select(CatalogSelectors.getPriceCampaigns),
      this.loadIfNotPresentPipe(),
      map((campaignList) => {
        const campaigns = campaignList?.entries?.filter((campaign) => !campaign.fallbackCampaign);
        return { allCampaignsLink: campaignList.allCampaignsLink, entries: campaigns };
      })
    );
  }

  getAvailablePriceCampaignsSize(): Observable<number> {
    return this.getAvailablePriceCampaigns().pipe(map((data) => data?.entries?.length));
  }

  private loadPriceCampaignDetails(code: string): void {
    this.store.dispatch(new PriceCampaignActions.LoadPriceCampaignDetails(code));
  }

  private loadIfNotPresentPipe<T>(): UnaryFunction<Observable<T>, Observable<T>> {
    return pipe(
      withLatestFrom(this.store.select(CatalogSelectors.getPriceCampaignsLoading)),
      tap(([data, isLoading]) => {
        if (!data && !isLoading) {
          this.loadPriceCampaigns();
        }
      }),
      filter(([data, _]) => !!data),
      map(([data, _]) => data)
    );
  }

  private loadPriceCampaigns(): void {
    this.store.dispatch(new PriceCampaignActions.LoadAvailablePriceCampaigns());
  }
}
