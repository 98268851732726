import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigModule } from '@spartacus/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { AuthGuard } from '../../../core/auth';
import { I18nModule } from '../../../core/i18n';
import { NotificationModule } from '../../../shared/components/notification';
import { CreditBlockedComponent } from './credit-blocked.component';

@NgModule({
  declarations: [CreditBlockedComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CmsCreditBlockedComponent: {
          component: CreditBlockedComponent,
          guards: [AuthGuard],
        },
      },
    }),
    NotificationModule,
    I18nModule,
    NzIconModule,
  ],
  exports: [CreditBlockedComponent],
})
export class CreditBlockedModule {}
