import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AreaOfUseModule } from './area-of-use/area-of-use.module';
import { ArticleModule } from './article/article.module';
import { CategoryModule } from './category/category.module';
import { CatalogKeysNormalizer } from './connector/catalog-keys-normalizer';
import { CATALOG_KEYS_NORMALIZER } from './connector/converters';
import { PriceCampaignModule } from './price-campaign/price-campaign.module';
import { ProductModule } from './product/product.module';
import { CatalogStoreModule } from './store/catalog-store.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ArticleModule,
    AreaOfUseModule,
    ProductModule,
    PriceCampaignModule,
    CategoryModule.forRoot(),
    CatalogStoreModule,
  ],
  providers: [
    {
      provide: CATALOG_KEYS_NORMALIZER,
      useClass: CatalogKeysNormalizer,
      multi: true,
    },
  ],
})
export class CatalogModule {}
