import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LaunchDialogService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseAbstractModalComponent, ModalCloseReason } from '../../../core/modal';
import { CartType } from '../../../core/model';
import { CartAccountingCodesFacade } from '../../../features/cart/base';
import { CART_TYPE_PARAM_KEY } from '../../guards';

@Component({
  selector: 'py-order-entry-accounting-codes-modal',
  templateUrl: './order-entry-accounting-codes-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderEntryAccountingCodesModalComponent extends BaseAbstractModalComponent implements OnInit, OnDestroy {
  cartType: CartType;
  providedCartType: CartType;
  option: string;
  subscription: Subscription = new Subscription();
  modalDismissed: boolean = false;

  readonly dismissable = false; //declared in class to enable proper handling in BaseAbstractModalComponent

  constructor(
    private cartAccountingCodesService: CartAccountingCodesFacade,
    private route: ActivatedRoute,
    protected launchDialogService: LaunchDialogService,
    protected el: ElementRef,
    protected renderer: Renderer2
  ) {
    super(el, launchDialogService, renderer);
  }

  ngOnInit(): void {
    const param = this.route.snapshot.queryParamMap.get(CART_TYPE_PARAM_KEY);
    this.option = 'setAll';

    this.subscription.add(
      this.launchDialogService.data$.pipe(take(1)).subscribe((data: CartType) => {
        this.cartType = CartType[param] || data;
      })
    );
  }

  close(): void {
    switch (this.option) {
      case 'setAll':
        this.cartAccountingCodesService.setAccountingCodeForAllEntries(this.cartType);
        break;
      case 'setAllAndDelete':
        this.cartAccountingCodesService.setAccountingCodeForAllEntriesAndRemoveIneligibleEntries(this.cartType);
        break;
      case 'noAction':
        break;
    }

    this.cartAccountingCodesService.setAccountingCodesModalOpen(this.cartType, undefined, false);
    this.launchDialogService.closeDialog(ModalCloseReason.ADDRESS_EDITOR_SAVED);
  }

  updateModalDismissed(): void {
    this.modalDismissed = !this.modalDismissed;
    this.cartAccountingCodesService.setAccountingCodesModalDismissed(this.modalDismissed, this.cartType);
  }

  setOption(option: string): void {
    this.option = option;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
