import { StateUtils } from '@spartacus/core';
import { SearchPageResultState, SearchParamsState, SearchState } from '../../../core/features/store/base-feature-state';
import { B2BUser, CustomerStatusEnum, CustomerStatusList, UserRoleList } from '../../../core/model';

export const ORGANIZATION_CORE_FEATURE = 'organizationCore';
export const ORGANIZATION_FEATURE = 'organization';

export const B2B_USER_FEATURE = 'b2bUser';
export const B2B_USER_ENTITIES = 'b2bUser-entities';
export const B2B_USER_SEARCH = 'b2bUser-search';

export const B2B_USER_STATUSES = 'b2bUser-statuses';
export const USER_ROLES = 'user-roles';

export const CREATE_B2B_USER_PROCESS = 'create-b2bUser-process';
export const UPDATE_B2B_USER_STATUS_PROCESS = 'update-b2bUser-status-process';

export const B2B_USERS_DELETE = 'b2bUsers-delete';

export interface Management<Type> extends StateUtils.EntityListState<Type> {}

export interface B2BUserManagement {
  entities: StateUtils.EntityLoaderState<B2BUser>;
  search: SearchState<SearchPageResultState>;
  statuses: StateUtils.LoaderState<CustomerStatusList>;
  roles: StateUtils.EntityLoaderState<UserRoleList>;
  delete: StateUtils.LoaderState<string[]>;
}

export interface StateWithOrganization {
  [ORGANIZATION_FEATURE]: OrganizationState;
}

export interface OrganizationState {
  [B2B_USER_FEATURE]: B2BUserManagement;
}

export interface B2BUserSearchSearchParamsState extends SearchParamsState {
  searchText?: string;
  roles?: string[];
  statuses?: CustomerStatusEnum[];
  orderCard?: string;
  configurations?: string[];
}
