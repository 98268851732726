import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { SpinnerModule } from '../spinner/spinner.module';
import { ActionButtonComponent } from './action-button.component';

@NgModule({
  imports: [CommonModule, NzIconModule, NgbTooltipModule, SpinnerModule],
  declarations: [ActionButtonComponent],
  exports: [ActionButtonComponent],
})
export class ActionButtonModule {}
