import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideDefaultConfig } from '../../config';
import { defaultPriceFileConfig } from './config/default-price-file-config';
import { PriceFileStoreModule } from './store/price-file-store.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, PriceFileStoreModule],
  providers: [provideDefaultConfig(defaultPriceFileConfig)],
})
export class PriceFileCoreModule {}
