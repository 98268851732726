import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SpinnerModule } from '../spinner';
import { BadgeComponent } from './badge.component';

@NgModule({
  imports: [CommonModule, SpinnerModule],
  declarations: [BadgeComponent],
  exports: [BadgeComponent],
})
export class BadgeModule {}
