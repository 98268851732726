import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StateConfig, StateModule, StateTransferType } from '@spartacus/core';
import { provideDefaultConfigFactory } from '../../config';
import { CATALOG_FEATURE } from './catalog-state';
import { effects } from './effects';
import { reducerProvider, reducerToken } from './reducers';

export function catalogStoreConfigFactory(): StateConfig {
  const config: StateConfig = {
    state: {
      ssrTransfer: {
        keys: {
          [CATALOG_FEATURE]: StateTransferType.TRANSFER_STATE,
        },
      },
    },
  };
  return config;
}

@NgModule({
  declarations: [],
  imports: [CommonModule, StateModule, StoreModule.forFeature(CATALOG_FEATURE, reducerToken), EffectsModule.forFeature(effects)],
  providers: [reducerProvider, provideDefaultConfigFactory(catalogStoreConfigFactory)],
})
export class CatalogStoreModule {}
