import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'py-overlay-message',
  templateUrl: './overlay-message.component.html',
  styleUrls: ['./overlay-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverlayMessageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
