import { ModuleWithProviders, NgModule } from '@angular/core';
import { RoutingModule as CxRoutingModule, SemanticPathService as CxSemanticPathService } from '@spartacus/core';
import { SemanticPathService as PySemanticPathService } from './configurable-routes';

@NgModule({
  imports: [CxRoutingModule.forRoot(), CxRoutingModule],
  exports: [CxRoutingModule],
  providers: [{ provide: CxSemanticPathService, useExisting: PySemanticPathService }],
})
export class RoutingModule {
  static forRoot(): ModuleWithProviders<RoutingModule> {
    return {
      ngModule: RoutingModule,
      providers: [],
    };
  }
}
