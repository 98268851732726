import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { BREAKPOINT, BreakpointService } from '@spartacus/storefront';
import { AsyncSubject, Subject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

/**
 * Add the template content to the DOM if breakpoint constraints are met
 *
 * <ng-container *pyScreenSize="{ min: 'md', max: 'lg' }; else otherTemplate">
 *   <!-- content goes here -->
 * </ng-container>
 *
 * <ng-template #otherTemplate></ng-template>
 */
@Directive({ selector: '[pyScreenSize]' })
export class ScreenSizeDirective implements OnInit, OnDestroy {
  @Input()
  public set pyScreenSize(options: { min?: BREAKPOINT; max?: BREAKPOINT }) {
    combineLatest([
      options.min ? this.breakpointService.isUp(options.min) : of(true),
      options.max ? this.breakpointService.isDown(options.max) : of(true),
      this.init,
    ])
      .pipe(
        takeUntil(this.unsubscribe),
        map(([isMinConstraintFulfilled, isMaxConstraintFulfilled]) => isMinConstraintFulfilled && isMaxConstraintFulfilled),
        distinctUntilChanged()
      )
      .subscribe((shouldRenderMainTemplate) => {
        this.setContainerView(shouldRenderMainTemplate ? this.templateRef : this.elseTemplateRef);
      });
  }

  @Input()
  public set pyScreenSizeElse(ref: TemplateRef<any> | undefined) {
    this.elseTemplateRef = ref;
  }

  private unsubscribe = new Subject<boolean>();
  private init = new AsyncSubject<void>();
  private elseTemplateRef?: TemplateRef<any>;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private breakpointService: BreakpointService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.init.next();
    this.init.complete();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
  }

  private setContainerView(ref?: TemplateRef<any>): void {
    this.viewContainer.clear();
    if (ref !== undefined) {
      this.viewContainer.createEmbeddedView(ref);
    }
    this.cd.markForCheck();
  }
}
