<ng-container *ngIf="icon">
  <i nz-icon [nzType]="customIcon ? customIcon : iconType" [nzTheme]="themeType"></i>
</ng-container>

<ng-container *ngIf="hasMessage; else contentTemplate">
  <p [innerHTML]="message"></p>
</ng-container>

<ng-template #contentTemplate>
  <div class="flex-grow-1">
    <ng-content></ng-content>
  </div>
</ng-template>
