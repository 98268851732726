import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { AlertType } from '../../../core/model';

@Component({
  selector: 'py-alert-message-status',
  templateUrl: './alert-message-status.component.html',
  styleUrls: ['./alert-message-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertMessageStatusComponent implements OnInit {
  @Input()
  type: AlertType;

  @HostBinding('class.warning')
  get isWarning(): boolean {
    return this.type === AlertType.Warning;
  }

  @HostBinding('class.danger')
  get isDanger(): boolean {
    return this.type === AlertType.Danger;
  }

  @HostBinding('class.info')
  get isInfo(): boolean {
    return this.type === AlertType.Info;
  }

  constructor() {}

  ngOnInit(): void {}
}
