import { StateUtils } from '@spartacus/core';
import { SearchPageResultState } from '../../../features/store/base-feature-state';
import { ShipToPage } from '../../../model';
import { UserActions } from '../actions';

export const initialState: SearchPageResultState = {
  lastUpdateTime: undefined,
  results: [],
};

export function reducer(state = initialState, action: any | StateUtils.LoaderAction): any {
  switch (action.type) {
    case UserActions.LOAD_USER_SHIPTOS_SUCCESS: {
      const payload: ShipToPage = action.payload;

      const results = payload.results.map((shipTo) => shipTo.id);

      return {
        ...state,
        lastUpdateTime: new Date().toISOString(),
        results,
      };
    }
    default: {
      return state;
    }
  }
}
