import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '../../../core/i18n';
import { OrderPriceSummaryComponent } from './order-price-summary.component';

@NgModule({
  imports: [CommonModule, I18nModule],
  declarations: [OrderPriceSummaryComponent],
  exports: [OrderPriceSummaryComponent],
})
export class OrderPriceSummaryModule {}
