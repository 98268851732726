import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Priority } from '@spartacus/core';
import { GlobalMessageService } from '../../../facade';
import { GlobalMessageType } from '../../../models/global-message.model';
import { HttpResponseStatus } from '../../../models/response-status.model';
import { HttpErrorHandler, OAUTH_ENDPOINT } from '../http-error.handler';

@Injectable({
  providedIn: 'root',
})
export class UnauthorizedHandler extends HttpErrorHandler {
  responseStatus = HttpResponseStatus.UNAUTHORIZED;

  constructor(protected globalMessageService: GlobalMessageService) {
    super(globalMessageService);
  }

  handleError(request: HttpRequest<any>, response: HttpErrorResponse): void {
    this.handleIncompleteAccount(request, response);
  }

  protected handleIncompleteAccount(request: HttpRequest<any>, response: HttpErrorResponse): void {
    if (response.url?.includes(OAUTH_ENDPOINT)) {
      if (response.error?.error === 'unauthorized' && request.body?.get('grant_type') === 'password') {
        this.globalMessageService.add({ key: 'errors.incompleteAccount_message' }, GlobalMessageType.MSG_TYPE_ERROR);
        this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_CONFIRMATION);
      }
      if (response.error?.error === 'no_password_account') {
        this.globalMessageService.add({ key: 'errors.noPasswordAccount_message' }, GlobalMessageType.MSG_TYPE_ERROR);
        this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_CONFIRMATION);
      }
    }
  }

  getPriority(): Priority {
    return Priority.LOW;
  }
}
