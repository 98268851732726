import { Action } from '@ngrx/store';
import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';
import { PagedSearchAction } from '../../../features/store/actions/search.actions';
import {
  B2BUser,
  CreateOrUpdateOrderCardConfiguration,
  CreateOrderCard,
  HttpErrorModel,
  OrderCard,
  OrderCardAdminSearchParams,
  OrderCardConfiguration,
  OrderCardPage,
  OrderCardStatus,
  UpdateOrderCardActionType,
} from '../../../model';
import { PyStateUtils } from '../../../state';
import {
  CONTACTS_ORDER_CARD_PROCESS,
  CREATE_ORDER_CARD_PROCESS,
  DELETE_ORDER_CARD_PROCESS,
  ORDER_CARDS_ADMIN,
  ORDER_CARD_ADMIN_CONFIGURATION,
  ORDER_CARD_ADMIN_CONFIGURATIONS,
} from '../user-state';

interface SearchOrderCardsAdminPayload {
  userId: string;
  searchParams?: OrderCardAdminSearchParams;
  deleteInvalidPages?: boolean;
}

interface SearchOrderCardsAdminFailPayload extends SearchOrderCardsAdminPayload {
  error?: HttpErrorModel;
}

interface SearchOrderCardsAdminSuccessPayload extends SearchOrderCardsAdminPayload {
  searchResult: OrderCardPage;
}

interface CreateOrderCardAdminPayload {
  userId: string;
  orderCardParams: CreateOrderCard;
  tempId: string;
}

interface CreateOrderCardAdminFailPayload extends CreateOrderCardAdminPayload {
  error?: HttpErrorModel;
}

interface CreateOrderCardAdminSuccessPayload extends CreateOrderCardAdminPayload {
  orderCard: OrderCard;
}

interface LoadAvailableContactPersonsPayload {
  orderCardCode: string;
  userId: string;
  roles: string[];
}

interface UpdateOrderCardAdminPayload {
  userId: string;
  code: string;
  orderCardParams: { name?: string; description?: string; contactPerson?: string };
}

interface UpdateOrderCardAdminFailPayload extends UpdateOrderCardAdminPayload {
  error?: HttpErrorModel;
}

interface UpdateOrderCardAdminSuccessPayload extends UpdateOrderCardAdminPayload {
  orderCard: OrderCard;
}

interface UpdateOrderCardAdminStatusPayload {
  userId: string;
  code: string;
  status: OrderCardStatus;
}

interface UpdateOrderCardAdminStatusFailPayload extends UpdateOrderCardAdminStatusPayload {
  error?: HttpErrorModel;
}

interface UpdateOrderCardAdminStatusSuccessPayload extends UpdateOrderCardAdminStatusPayload {
  orderCard: OrderCard;
}

interface UpdateOrderCardAdminPrincipalsPayload {
  userId: string;
  code: string;
  principals: string[];
  action: UpdateOrderCardActionType;
}

interface UpdateOrderCardAdminPrincipalsFailPayload extends UpdateOrderCardAdminPrincipalsPayload {
  error?: HttpErrorModel;
}

interface UpdateOrderCardAdminPrincipalsSuccessPayload extends UpdateOrderCardAdminPrincipalsPayload {
  orderCard: OrderCard;
}

interface UpdateOrderCardAdminProductsPayload {
  userId: string;
  code: string;
  products: string[];
  action: UpdateOrderCardActionType;
}

interface UpdateOrderCardAdminProductsFailPayload extends UpdateOrderCardAdminProductsPayload {
  error?: HttpErrorModel;
}

interface UpdateOrderCardAdminProductsSuccessPayload extends UpdateOrderCardAdminProductsPayload {
  orderCard: OrderCard;
}

interface DeleteOrderCardAdminPayload {
  userId: string;
  code: string;
}

interface DeleteOrderCardAdminFailPayload extends DeleteOrderCardAdminPayload {
  error?: HttpErrorModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DeleteOrderCardAdminSuccessPayload extends DeleteOrderCardAdminPayload {}

export enum OrderCardsAdminActionTypes {
  LoadOrderCardAdmin = '[User] Load admin order card',
  LoadOrderCardAdminFail = '[User] Load admin order card fail',
  LoadOrderCardAdminSuccess = '[User] Load admin order card success',
  LoadOrderCardAdminReset = '[User] Load admin order card reset',

  SearchOrderCardsAdmin = '[User] Search order cards admin',
  SearchOrderCardsAdminFail = '[User] Search order cards admin fail',
  SearchOrderCardsAdminSuccess = '[User] Search order cards admin success',

  CreateOrderCardAdmin = '[User] Create admin order card',
  CreateOrderCardAdminFail = '[User] Create admin order card fail',
  CreateOrderCardAdminSuccess = '[User] Create admin order card success',

  UpdateOrderCardAdmin = '[User] Update admin order card',
  UpdateOrderCardAdminFail = '[User] Update admin order card fail',
  UpdateOrderCardAdminSuccess = '[User] Update admin order card success',

  UpdateOrderCardAdminStatus = '[User] Update admin order card status',
  UpdateOrderCardAdminStatusFail = '[User] Update admin order card status fail',
  UpdateOrderCardAdminStatusSuccess = '[User] Update admin order card status success',

  AddOrderCardAdminPrincipal = '[User] Add order card principal',
  AddOrderCardAdminPrincipalFail = '[User] Add order card principal fail',
  AddOrderCardAdminPrincipalSuccess = '[User] Add order card principal success',

  DeleteOrderCardAdminPrincipal = '[User] Delete order card principal',
  DeleteOrderCardAdminPrincipalFail = '[User] Delete order card principal fail',
  DeleteOrderCardAdminPrincipalSuccess = '[User] Delete order card principal success',

  UpdateOrderCardAdminPrincipals = '[User] Update admin order Card principals',
  UpdateOrderCardAdminPrincipalsFail = '[User] Update admin order Card principals fail',
  UpdateOrderCardAdminPrincipalsSuccess = '[User] Update admin order Card principals success',

  AddOrderCardAdminProduct = '[User] Add order card product',
  AddOrderCardAdminProductFail = '[User] Add order card product fail',
  AddOrderCardAdminProductSuccess = '[User] Add order card product success',

  ReplaceOrderCardAdminProduct = '[User] Replace order card product',
  ReplaceOrderCardAdminProductFail = '[User] Replace order card product fail',
  ReplaceOrderCardAdminProductSuccess = '[User] Replace order card product success',

  AddOrderCardAdminArticles = '[User] Add order card articles',
  AddOrderCardAdminArticlesFail = '[User] Add order card articles fail',
  AddOrderCardAdminArticlesSuccess = '[User] Add order card articles success',

  DeleteOrderCardAdminProduct = '[User] Delete order card product',
  DeleteOrderCardAdminProductFail = '[User] Delete order card product fail',
  DeleteOrderCardAdminProductSuccess = '[User] Delete order card product success',

  UpdateOrderCardAdminProducts = '[User] Update admin order Card products',
  UpdateOrderCardAdminProductsFail = '[User] Update admin order Card products fail',
  UpdateOrderCardAdminProductsSuccess = '[User] Update admin order Card products success',

  LoadOrderCardAdminConfigurations = '[User] Load order card admin configurations',
  LoadOrderCardAdminConfigurationsFail = '[User] Load order card admin configurations fail',
  LoadOrderCardAdminConfigurationsSuccess = '[User] Load order card admin configurations success',

  LoadOrderCardAdminConfiguration = '[User] Load order card admin configuration',
  LoadOrderCardAdminConfigurationFail = '[User] Load order card admin configuration fail',
  LoadOrderCardAdminConfigurationSuccess = '[User] Load order card admin configuration success',

  UpdateOrderCardAdminConfiguration = '[User] Update order card admin configuration',
  UpdateOrderCardAdminConfigurationFail = '[User] Update order card admin configuration fail',
  UpdateOrderCardAdminConfigurationSuccess = '[User] Update order card admin configuration success',

  ClearOrderCardAdminConfigurations = '[User] Clear order card admin configurations',

  DeleteOrderCardAdmin = '[User] Delete admin order Card',
  DeleteOrderCardAdminSuccess = '[User] Delete admin order Card success',
  DeleteOrderCardAdminFail = '[User] Delete admin order Card fail',

  LoadAvailableContactPersons = '[User] Load available order card contact persons',
  LoadAvailableContactPersonsFail = '[User] Load available order card contact persons fail',
  LoadAvailableContactPersonsSuccess = '[User] Load available order card contact persons success',
}

export class LoadOrderCardAdmin extends StateUtils.EntityLoadAction {
  readonly type = OrderCardsAdminActionTypes.LoadOrderCardAdmin;

  constructor(public code: string, public userId: string) {
    super(ORDER_CARDS_ADMIN, code);
  }
}

export class LoadOrderCardAdminFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderCardsAdminActionTypes.LoadOrderCardAdminFail;

  constructor(public code: string, public payload: any) {
    super(ORDER_CARDS_ADMIN, code, payload.error);
  }
}

export class LoadOrderCardAdminSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderCardsAdminActionTypes.LoadOrderCardAdminSuccess;

  constructor(public code: string, public payload: OrderCard) {
    super(ORDER_CARDS_ADMIN, code, payload);
  }
}

export class LoadOrderCardAdminReset extends StateUtils.EntityLoaderResetAction {
  readonly type = OrderCardsAdminActionTypes.LoadOrderCardAdminReset;

  constructor(public code: string) {
    super(ORDER_CARDS_ADMIN, code);
  }
}

export class SearchOrderCardsAdmin extends StateUtils.LoaderLoadAction implements PagedSearchAction {
  readonly type = OrderCardsAdminActionTypes.SearchOrderCardsAdmin;
  readonly searchType = ORDER_CARDS_ADMIN;
  public searchParams;

  constructor(public payload: SearchOrderCardsAdminPayload) {
    super(ORDER_CARDS_ADMIN);
    this.searchParams = payload.searchParams;
  }
}

export class SearchOrderCardsAdminFail extends PyStateUtils.LoaderFailAction implements PagedSearchAction {
  readonly type = OrderCardsAdminActionTypes.SearchOrderCardsAdminFail;
  readonly searchType = ORDER_CARDS_ADMIN;
  public searchParams;

  constructor(public payload?: SearchOrderCardsAdminFailPayload) {
    super(ORDER_CARDS_ADMIN, payload);
    this.searchParams = payload?.searchParams;
  }
}

export class SearchOrderCardsAdminSuccess extends StateUtils.LoaderSuccessAction implements PagedSearchAction {
  readonly type = OrderCardsAdminActionTypes.SearchOrderCardsAdminSuccess;
  readonly searchType = ORDER_CARDS_ADMIN;
  public searchParams;

  constructor(public payload: SearchOrderCardsAdminSuccessPayload) {
    super(ORDER_CARDS_ADMIN);
    this.searchParams = payload.searchParams;
  }
}

export class UpdateOrderCardAdmin extends StateUtils.EntityLoadAction {
  readonly type = OrderCardsAdminActionTypes.UpdateOrderCardAdmin;

  constructor(public payload: UpdateOrderCardAdminPayload) {
    super(ORDER_CARDS_ADMIN, payload.code);
  }
}

export class UpdateOrderCardAdminFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderCardsAdminActionTypes.UpdateOrderCardAdminFail;

  constructor(public payload: UpdateOrderCardAdminFailPayload) {
    super(ORDER_CARDS_ADMIN, payload.code, payload.error);
  }
}

export class UpdateOrderCardAdminSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderCardsAdminActionTypes.UpdateOrderCardAdminSuccess;

  constructor(public payload: UpdateOrderCardAdminSuccessPayload) {
    super(ORDER_CARDS_ADMIN, payload.code);
  }
}

export class UpdateOrderCardAdminStatus extends StateUtils.EntityLoadAction {
  readonly type = OrderCardsAdminActionTypes.UpdateOrderCardAdminStatus;

  constructor(public payload: UpdateOrderCardAdminStatusPayload) {
    super(ORDER_CARDS_ADMIN, payload.code);
  }
}

export class UpdateOrderCardAdminStatusFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderCardsAdminActionTypes.UpdateOrderCardAdminStatusFail;

  constructor(public payload: UpdateOrderCardAdminStatusFailPayload) {
    super(ORDER_CARDS_ADMIN, payload.code, payload.error);
  }
}

export class UpdateOrderCardAdminStatusSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderCardsAdminActionTypes.UpdateOrderCardAdminStatusSuccess;

  constructor(public payload: UpdateOrderCardAdminStatusSuccessPayload) {
    super(ORDER_CARDS_ADMIN, payload.code);
  }
}

export class AddOrderCardAdminPrincipal extends StateUtils.EntityLoadAction {
  readonly type = OrderCardsAdminActionTypes.AddOrderCardAdminPrincipal;

  constructor(public code: string, public userId: string, public principal: string) {
    super(ORDER_CARDS_ADMIN, code);
  }
}

export class AddOrderCardAdminPrincipalFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderCardsAdminActionTypes.AddOrderCardAdminPrincipalFail;

  constructor(public code: string, public error: any) {
    super(ORDER_CARDS_ADMIN, code, error);
  }
}

export class AddOrderCardAdminPrincipalSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderCardsAdminActionTypes.AddOrderCardAdminPrincipalSuccess;

  constructor(public code: string, public payload: OrderCard) {
    super(ORDER_CARDS_ADMIN, code);
  }
}

export class DeleteOrderCardAdminPrincipal extends StateUtils.EntityLoadAction {
  readonly type = OrderCardsAdminActionTypes.DeleteOrderCardAdminPrincipal;

  constructor(public code: string, public userId: string, public principal: string) {
    super(ORDER_CARDS_ADMIN, code);
  }
}

export class DeleteOrderCardAdminPrincipalFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderCardsAdminActionTypes.DeleteOrderCardAdminPrincipalFail;

  constructor(public code: string, public error: any) {
    super(ORDER_CARDS_ADMIN, code, error);
  }
}

export class DeleteOrderCardAdminPrincipalSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderCardsAdminActionTypes.DeleteOrderCardAdminPrincipalSuccess;

  constructor(public code: string, public payload: OrderCard) {
    super(ORDER_CARDS_ADMIN, code);
  }
}

export class UpdateOrderCardAdminPrincipals extends StateUtils.EntityLoadAction {
  readonly type = OrderCardsAdminActionTypes.UpdateOrderCardAdminPrincipals;

  constructor(public payload: UpdateOrderCardAdminPrincipalsPayload) {
    super(ORDER_CARDS_ADMIN, payload.code);
  }
}

export class UpdateOrderCardAdminPrincipalsFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderCardsAdminActionTypes.UpdateOrderCardAdminPrincipalsFail;

  constructor(public payload: UpdateOrderCardAdminPrincipalsFailPayload) {
    super(ORDER_CARDS_ADMIN, payload.code, payload.error);
  }
}

export class UpdateOrderCardAdminPrincipalsSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderCardsAdminActionTypes.UpdateOrderCardAdminPrincipalsSuccess;

  constructor(public payload: UpdateOrderCardAdminPrincipalsSuccessPayload) {
    super(ORDER_CARDS_ADMIN, payload.code);
  }
}

export class AddOrderCardAdminProduct extends StateUtils.EntityLoadAction {
  readonly type = OrderCardsAdminActionTypes.AddOrderCardAdminProduct;

  constructor(public code: string, public userId: string, public product: string) {
    super(ORDER_CARDS_ADMIN, code);
  }
}

export class AddOrderCardAdminProductFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderCardsAdminActionTypes.AddOrderCardAdminProductFail;

  constructor(public code: string, public error: any) {
    super(ORDER_CARDS_ADMIN, code, error);
  }
}

export class AddOrderCardAdminProductSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderCardsAdminActionTypes.AddOrderCardAdminProductSuccess;

  constructor(public code: string, public payload: OrderCard) {
    super(ORDER_CARDS_ADMIN, code);
  }
}

export class ReplaceOrderCardAdminProduct extends StateUtils.EntityLoadAction {
  readonly type = OrderCardsAdminActionTypes.ReplaceOrderCardAdminProduct;

  constructor(public code: string, public userId: string, public oldProductCode: string, public newProductCode: string) {
    super(ORDER_CARDS_ADMIN, code);
  }
}

export class ReplaceOrderCardAdminProductFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderCardsAdminActionTypes.ReplaceOrderCardAdminProductFail;

  constructor(public code: string, public error: any) {
    super(ORDER_CARDS_ADMIN, code, error);
  }
}

export class ReplaceOrderCardAdminProductSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderCardsAdminActionTypes.ReplaceOrderCardAdminProductSuccess;

  constructor(public code: string, public payload: OrderCard, public newProductCode: string) {
    super(ORDER_CARDS_ADMIN, code);
  }
}

export class AddOrderCardAdminArticles extends StateUtils.EntityLoadAction {
  readonly type = OrderCardsAdminActionTypes.AddOrderCardAdminArticles;

  constructor(public code: string, public userId: string, public articles: string[]) {
    super(ORDER_CARDS_ADMIN, code);
  }
}

export class AddOrderCardAdminArticlesFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderCardsAdminActionTypes.AddOrderCardAdminArticlesFail;

  constructor(public code: string, public error: any) {
    super(ORDER_CARDS_ADMIN, code, error);
  }
}

export class AddOrderCardAdminArticlesSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderCardsAdminActionTypes.AddOrderCardAdminArticlesSuccess;

  constructor(public code: string, public payload: OrderCard) {
    super(ORDER_CARDS_ADMIN, code);
  }
}

export class DeleteOrderCardAdminProduct extends StateUtils.EntityLoadAction {
  readonly type = OrderCardsAdminActionTypes.DeleteOrderCardAdminProduct;

  constructor(public code: string, public userId: string, public product: string) {
    super(ORDER_CARDS_ADMIN, code);
  }
}

export class DeleteOrderCardAdminProductFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderCardsAdminActionTypes.DeleteOrderCardAdminProductFail;

  constructor(public code: string, public error: any) {
    super(ORDER_CARDS_ADMIN, code, error);
  }
}

export class DeleteOrderCardAdminProductSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderCardsAdminActionTypes.DeleteOrderCardAdminProductSuccess;

  constructor(public code: string, public payload: OrderCard) {
    super(ORDER_CARDS_ADMIN, code);
  }
}

export class UpdateOrderCardAdminProducts extends StateUtils.EntityLoadAction {
  readonly type = OrderCardsAdminActionTypes.UpdateOrderCardAdminProducts;

  constructor(public payload: UpdateOrderCardAdminProductsPayload) {
    super(ORDER_CARDS_ADMIN, payload.code);
  }
}

export class UpdateOrderCardAdminProductsFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderCardsAdminActionTypes.UpdateOrderCardAdminProductsFail;

  constructor(public payload: UpdateOrderCardAdminProductsFailPayload) {
    super(ORDER_CARDS_ADMIN, payload.code, payload.error);
  }
}

export class UpdateOrderCardAdminProductsSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderCardsAdminActionTypes.UpdateOrderCardAdminProductsSuccess;

  constructor(public payload: UpdateOrderCardAdminProductsSuccessPayload) {
    super(ORDER_CARDS_ADMIN, payload.code);
  }
}

export class LoadOrderCardAdminConfigurations extends StateUtils.EntityLoadAction {
  readonly type = OrderCardsAdminActionTypes.LoadOrderCardAdminConfigurations;

  constructor(public code: string, public userId: string, public customer: string) {
    super(ORDER_CARD_ADMIN_CONFIGURATIONS, code);
  }
}

export class LoadOrderCardAdminConfigurationsFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderCardsAdminActionTypes.LoadOrderCardAdminConfigurationsFail;

  constructor(public code: string, public payload: any) {
    super(ORDER_CARD_ADMIN_CONFIGURATIONS, code, payload);
  }
}

export class LoadOrderCardAdminConfigurationsSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderCardsAdminActionTypes.LoadOrderCardAdminConfigurationsSuccess;

  constructor(public code: string, public payload: OrderCardConfiguration[]) {
    super(ORDER_CARD_ADMIN_CONFIGURATIONS, code);
  }
}

export class LoadOrderCardAdminConfiguration extends StateUtils.EntityLoadAction {
  readonly type = OrderCardsAdminActionTypes.LoadOrderCardAdminConfiguration;

  constructor(public code: string, public userId: string, public productCode: string, public customer: string) {
    super(ORDER_CARD_ADMIN_CONFIGURATION, `${code}|${productCode}`);
  }
}

export class LoadOrderCardAdminConfigurationFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderCardsAdminActionTypes.LoadOrderCardAdminConfigurationFail;

  constructor(public code: string, public productCode: string, public payload: any) {
    super(ORDER_CARD_ADMIN_CONFIGURATION, `${code}|${productCode}`, payload);
  }
}

export class LoadOrderCardAdminConfigurationSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderCardsAdminActionTypes.LoadOrderCardAdminConfigurationSuccess;

  constructor(public code: string, public productCode: string, public payload: OrderCardConfiguration) {
    super(ORDER_CARD_ADMIN_CONFIGURATION, `${code}|${productCode}`, payload);
  }
}

export class UpdateOrderCardAdminConfiguration extends StateUtils.EntityLoadAction {
  readonly type = OrderCardsAdminActionTypes.UpdateOrderCardAdminConfiguration;

  constructor(
    public code: string,
    public userId: string,
    public productCode: string,
    public customer: string,
    public params: CreateOrUpdateOrderCardConfiguration
  ) {
    super(ORDER_CARD_ADMIN_CONFIGURATION, `${code}|${productCode}`);
  }
}

export class UpdateOrderCardAdminConfigurationFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderCardsAdminActionTypes.UpdateOrderCardAdminConfigurationFail;

  constructor(public code: string, public productCode: string, public payload: any) {
    super(ORDER_CARD_ADMIN_CONFIGURATION, `${code}|${productCode}`, payload);
  }
}

export class UpdateOrderCardAdminConfigurationSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderCardsAdminActionTypes.UpdateOrderCardAdminConfigurationSuccess;

  constructor(public code: string, public productCode: string, public payload: OrderCardConfiguration) {
    super(ORDER_CARD_ADMIN_CONFIGURATION, `${code}|${productCode}`, payload);
  }
}

export class ClearOrderCardAdminConfigurations implements Action {
  readonly type = OrderCardsAdminActionTypes.ClearOrderCardAdminConfigurations;

  constructor() {}
}

export class DeleteOrderCardAdmin extends StateUtils.EntityLoadAction {
  readonly type = OrderCardsAdminActionTypes.DeleteOrderCardAdmin;

  constructor(public payload: DeleteOrderCardAdminPayload) {
    super(PROCESS_FEATURE, DELETE_ORDER_CARD_PROCESS);
  }
}

export class DeleteOrderCardAdminFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderCardsAdminActionTypes.DeleteOrderCardAdminFail;

  constructor(public payload: DeleteOrderCardAdminFailPayload) {
    super(PROCESS_FEATURE, DELETE_ORDER_CARD_PROCESS, payload.error);
  }
}

export class DeleteOrderCardAdminSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderCardsAdminActionTypes.DeleteOrderCardAdminSuccess;

  constructor(public payload: DeleteOrderCardAdminSuccessPayload) {
    super(PROCESS_FEATURE, DELETE_ORDER_CARD_PROCESS);
  }
}

export class CreateOrderCardAdmin extends StateUtils.EntityLoadAction {
  readonly type = OrderCardsAdminActionTypes.CreateOrderCardAdmin;

  constructor(public payload: CreateOrderCardAdminPayload) {
    super(PROCESS_FEATURE, CREATE_ORDER_CARD_PROCESS);
  }
}

export class CreateOrderCardAdminFail extends PyStateUtils.EntityFailAction {
  readonly type = OrderCardsAdminActionTypes.CreateOrderCardAdminFail;

  constructor(public payload: CreateOrderCardAdminFailPayload) {
    super(PROCESS_FEATURE, CREATE_ORDER_CARD_PROCESS, payload.error);
  }
}

export class CreateOrderCardAdminSuccess extends StateUtils.EntitySuccessAction {
  readonly type = OrderCardsAdminActionTypes.CreateOrderCardAdminSuccess;

  constructor(public payload: CreateOrderCardAdminSuccessPayload) {
    super(PROCESS_FEATURE, CREATE_ORDER_CARD_PROCESS);
  }
}

export class LoadAvailableContactPersons extends StateUtils.LoaderLoadAction {
  readonly type = OrderCardsAdminActionTypes.LoadAvailableContactPersons;

  constructor(public payload: LoadAvailableContactPersonsPayload) {
    super(CONTACTS_ORDER_CARD_PROCESS);
  }
}

export class LoadAvailableContactPersonsFail extends PyStateUtils.LoaderFailAction {
  readonly type = OrderCardsAdminActionTypes.LoadAvailableContactPersonsFail;

  constructor(public payload?: HttpErrorModel) {
    super(CONTACTS_ORDER_CARD_PROCESS, payload);
  }
}

export class LoadAvailableContactPersonsSuccess extends StateUtils.LoaderSuccessAction {
  readonly type = OrderCardsAdminActionTypes.LoadAvailableContactPersonsSuccess;

  constructor(public payload: B2BUser[]) {
    super(CONTACTS_ORDER_CARD_PROCESS);
  }
}

export type OrderCardsAdminActions =
  | LoadOrderCardAdmin
  | LoadOrderCardAdminFail
  | LoadOrderCardAdminSuccess
  | LoadOrderCardAdminReset
  | SearchOrderCardsAdmin
  | SearchOrderCardsAdminFail
  | SearchOrderCardsAdminSuccess
  | CreateOrderCardAdmin
  | CreateOrderCardAdminFail
  | CreateOrderCardAdminSuccess
  | UpdateOrderCardAdmin
  | UpdateOrderCardAdminFail
  | UpdateOrderCardAdminSuccess
  | UpdateOrderCardAdminStatus
  | UpdateOrderCardAdminStatusFail
  | UpdateOrderCardAdminStatusSuccess
  | AddOrderCardAdminPrincipal
  | AddOrderCardAdminPrincipalFail
  | AddOrderCardAdminPrincipalSuccess
  | DeleteOrderCardAdminPrincipal
  | DeleteOrderCardAdminPrincipalFail
  | DeleteOrderCardAdminPrincipalSuccess
  | UpdateOrderCardAdminPrincipals
  | UpdateOrderCardAdminPrincipalsFail
  | UpdateOrderCardAdminPrincipalsSuccess
  | AddOrderCardAdminProduct
  | AddOrderCardAdminProductFail
  | AddOrderCardAdminProductSuccess
  | ReplaceOrderCardAdminProduct
  | ReplaceOrderCardAdminProductFail
  | ReplaceOrderCardAdminProductSuccess
  | DeleteOrderCardAdminProduct
  | DeleteOrderCardAdminProductFail
  | DeleteOrderCardAdminProductSuccess
  | UpdateOrderCardAdminProducts
  | UpdateOrderCardAdminProductsFail
  | UpdateOrderCardAdminProductsSuccess
  | LoadOrderCardAdminConfiguration
  | LoadOrderCardAdminConfigurationFail
  | LoadOrderCardAdminConfigurationSuccess
  | LoadOrderCardAdminConfigurations
  | LoadOrderCardAdminConfigurationsFail
  | LoadOrderCardAdminConfigurationsSuccess
  | UpdateOrderCardAdminConfiguration
  | UpdateOrderCardAdminConfigurationFail
  | UpdateOrderCardAdminConfigurationSuccess
  | ClearOrderCardAdminConfigurations
  | DeleteOrderCardAdmin
  | DeleteOrderCardAdminFail
  | DeleteOrderCardAdminSuccess
  | LoadAvailableContactPersons
  | LoadAvailableContactPersonsFail
  | LoadAvailableContactPersonsSuccess;
