import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CatalogProductListItemModule } from '../catalog-product-list-item/catalog-product-list-item.module';
import { ProductListItemLoaderComponent } from './product-list-item-loader.component';

/**
 * This could probably be moved to shared later and reused in other places.
 * However since it now depends on CatalogArticleListItemModule this would create
 * a circular dependency outside of catalog.
 */
@NgModule({
  imports: [CommonModule, CatalogProductListItemModule],
  declarations: [ProductListItemLoaderComponent],
  exports: [ProductListItemLoaderComponent],
})
export class ProductListItemLoaderModule {}
