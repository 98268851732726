import { Occ } from '../occ';

export enum ReturnType {
  FULL_QUANTITY = 'FULL_QUANTITY',
  PARTIAL_QUANTITY = 'PARTIAL_QUANTITY',
}

export import ReasonCodeType = Occ.OrderReturnEntryReasonCodeEnum;

export import CustomerUploadedMedia = Occ.CustomerUploadedMedia;

export import ReturnReason = Occ.ReturnReason;

export interface OrderReturn extends Occ.OrderReturn {
  entries?: OrderReturnEntry[];
}

export interface OrderReturnEntry extends Occ.OrderReturnEntry {
  isValid?: boolean;
}

export interface OrderReturnDraftEntryForm {
  itemNumber: number;
  returnEntryForm: ReturnEntryForm;
}

export interface ReturnEntryForm {
  isValid?: boolean;
  returnType?: ReturnType;
  returnReason?: ReasonCodeType;
  quantityAndUnit?: {
    quantity?: number;
    unitCode?: string;
  };
  quantityText?: string;
  comment?: string;
  files?: CustomerUploadedMedia[];
}

export interface FilePreview {
  file?: File;
  fileUrl?: string;
}

export interface FileUploadEvent {
  uploadedFile: CustomerUploadedMedia;
  file?: File;
  fileUrl?: string;
}
