import { Action } from '@ngrx/store';

export enum AppActionTypes {
  NoOpAction = '[App] NoOp',
}

export class NoOpAction implements Action {
  readonly type = AppActionTypes.NoOpAction;
}

export type AppActions = NoOpAction;
