import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        accountingCodesAdmin: {
          module: () => import('./container/accounting-codes.module').then((m) => m.AccountingCodesModule),
          cmsComponents: ['CmsAccountingCodesAdminComponent'],
        },
      },
    }),
  ],
})
export class AccountingCodesFeatureModule {}
