<div
  [ngbTooltip]="minDisabledTooltipText"
  [disableTooltip]="!minimumOrderIndication || canDecrement"
  [container]="tooltipContainer"
>
  <button
    class="modifier subtract h-100"
    type="button"
    (click)="onDecrement()"
    [disabled]="disabled || readOnly || !canDecrement"
    tabindex="-1"
  >
    <i nz-icon nzType="minus"></i>
  </button>
</div>

<input
  #inputRef
  type="number"
  inputmode="numeric"
  [id]="'' | randomId"
  pattern="[0-9]*"
  [min]="min"
  [max]="max"
  [ngModel]="value"
  (ngModelChange)="onInputChange($event)"
  (focus)="onInputFocus()"
  (blur)="onInputBlur()"
  [readOnly]="readOnly"
  [disabled]="disabled"
/>
<button
  class="modifier add"
  type="button"
  (click)="onIncrement()"
  [disabled]="disabled || readOnly || !canIncrement"
  tabindex="-1"
>
  <i nz-icon nzType="plus"></i>
</button>
