import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InterceptorUtil, OCC_USER_ID_ANONYMOUS, OccEndpointsService, USE_CLIENT_TOKEN } from '@spartacus/core';
import { Observable } from 'rxjs';
import { EntityMap } from '../../model';
import { Occ } from '../../occ';
import { ConverterService } from '../../util';
import { CATALOG_KEYS_NORMALIZER } from './converters';

@Injectable({
  providedIn: 'root',
})
export class CatalogConnector {
  constructor(
    private converterService: ConverterService,
    private httpClient: HttpClient,
    private occEndpointsService: OccEndpointsService
  ) {}

  loadCatalogKeys(userId: string): Observable<EntityMap<string>> {
    let headers = new HttpHeaders();
    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }
    return this.httpClient
      .get<Occ.Map<string>>(this.occEndpointsService.buildUrl('catalogKeys', { urlParams: { userId: userId } }), { headers })
      .pipe(this.converterService.pipeable(CATALOG_KEYS_NORMALIZER));
  }
}
