import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { Observable } from 'rxjs';
import { ArticleRef, ArticleStockInfo, LoaderError, StockInfoDetails, Unit } from '../../model';
import { USER_BASE_FEATURE } from '../store/user-state';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: StockInfoFacade,
      feature: USER_BASE_FEATURE,
      async: true,
      methods: [
        'getStockInfo',
        'getStockInfoDetails',
        'loadingStockInfo',
        'getStockInfoError',
        'loadStockInfo',
        'loadingStockInfoDetails',
        'getStockInfoDetailsError',
        'loadStockInfoDetails',
      ],
    }),
})
export abstract class StockInfoFacade {
  abstract getStockInfo(articleRef: ArticleRef, quantity: number, customerSelectedUnit?: Unit): Observable<ArticleStockInfo>;
  abstract getStockInfoDetails(
    articleRef: ArticleRef,
    quantity: number,
    customerSelectedUnit?: Unit
  ): Observable<StockInfoDetails>;
  abstract loadingStockInfo(articleRef: ArticleRef, quantity: number, customerSelectedUnit?: Unit): Observable<boolean>;
  abstract getStockInfoError(articleRef: ArticleRef, quantity: number, customerSelectedUnit?: Unit): Observable<LoaderError>;
  abstract loadStockInfo(articleRef: ArticleRef, quantity: number, customerSelectedUnit?: Unit): void;
  abstract loadingStockInfoDetails(articleRef: ArticleRef, quantity: number, customerSelectedUnit?: Unit): Observable<boolean>;
  abstract getStockInfoDetailsError(
    articleRef: ArticleRef,
    quantity: number,
    customerSelectedUnit?: Unit
  ): Observable<LoaderError>;
  abstract loadStockInfoDetails(articleRef: ArticleRef, quantity: number, customerSelectedUnit?: Unit): void;
}
