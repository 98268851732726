import { Occ } from '../occ';
import { PrincipalConfiguration } from './misc.model';

export const FEED_POLLING_PERIOD = 60000;

export import FeedBadgeType = Occ.BadgeTypeEnum;

export import FeedOperationalMessageType = Occ.OperationalMessageWrapperStatusEnum;

export import OrdersCountBadge = Occ.Badge;

export import FeedRequestList = Occ.FeedRequestListDTO;

export interface Feed extends Omit<Occ.Feed, 'feedRequestListDTO'> {
  configurations?: PrincipalConfiguration[];
  feedRequestList?: FeedRequestList;
}

export import OperationalMessageWrapper = Occ.OperationalMessageWrapper;
